import React from "react";
import {useModal} from "react-modal-hook";
import {useChartStateStoreContext, useSelectChartType} from "../../../pages/chart/highcharts/simple-new/store/hooks/useStoreContext";
import OverlayOtherEquipmentModal from "../OtherEquipmentModal";
import {useChartSelectedEquipmentStore} from "../../../stores/zustand/ChartSelectedEquipmentStore";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {shallow} from "zustand/shallow";

export const useOtherEquipmentModal = () => {
    const {setOverlayPoints} = useChartStateStoreContext((state) => state.actions);
    const overlayPoints = useChartStateStoreContext((state) => state.settings.overlayPoints, shallow);
    const isNameplatesOverlay = useChartStateStoreContext((state) => state.settings.isNameplatesOverlay);
    const equipmentSelected = useChartSelectedEquipmentStore();
    const {equipmentItem: equipment} = useEquipmentByParams();
    const selectedPoints = equipmentSelected[equipment.id];
    const chartType = useSelectChartType();

    const [showModal, hideModal] = useModal(() => {
        return (
            <OverlayOtherEquipmentModal
                onSubmit={setOverlayPoints}
                onClose={hideModal}
                selectedPoints={selectedPoints}
                overlayPoints={overlayPoints}
                byNameplate={isNameplatesOverlay}
                chartType={chartType}
            />
        );
    }, [setOverlayPoints, selectedPoints, overlayPoints]);

    return [showModal, hideModal];
};
