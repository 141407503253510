import {useQuery} from "@tanstack/react-query";
import {ROUTES_API} from "./constants";
import RoutesApi from "../../../api/routes";

export const useEquipmentListByAssetIdQuery = (assetId) => {
    return useQuery({
        enabled: !!assetId,
        queryKey: [ROUTES_API.GET_EQUIPMENT_BY_ASSET_ID, assetId],
        queryFn: () => RoutesApi.getEquipmentListByAsset(assetId),
        select: (res) => res.list,
    });
};
