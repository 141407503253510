import {useMutation, useQueryClient} from "@tanstack/react-query";
import EquipmentInstallationPointApi from "../../../api/equipmentInstallationPoint";
import EquipmentApi from "../../../api/equipment";
// eslint-disable-next-line
import {getEquipmentQueryKeys} from "../equipment/useEquipmentQuery";

export const useCreateInstallationPointMutation = () => {
    // eslint-disable-next-line
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({equipmentId, installationPoint}) =>
            EquipmentApi.checkService(equipmentId).then(() => EquipmentInstallationPointApi.create(equipmentId, installationPoint)),
        onSuccess: (data, variables) => {
            queryClient.setQueriesData(getEquipmentQueryKeys(variables.equipmentId), (prevData) => ({
                ...prevData,
                installationPoints: [...prevData.installationPoints, data.installationPoint],
            }));
        },
    });
};
