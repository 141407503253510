import LinearTab from "../components/linearTab/linearTab";
import DeltaTab from "../components/deltaTab/deltaTab";

export const ALERT_TABS_NAME = {
    LINEAR: "linear",
    DELTA_T: "delta-t",
    TACHOMETER_RPM: "tachometer-rpm",
};

export const ALERT_TABS = [
    {
        title: "Linear",
        key: ALERT_TABS_NAME.LINEAR,
        component: LinearTab,
    },
    {
        title: "Delta T",
        key: ALERT_TABS_NAME.DELTA_T,
        component: DeltaTab,
    },
    {
        title: "Tachometer-rpm",
        key: ALERT_TABS_NAME.TACHOMETER_RPM,
        component: LinearTab,
    },
];
