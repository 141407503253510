export const customStyles = {
    ...{
        control: base => ({
            ...base,
            minHeight: 32,
            ...(localStorage.getItem("lightMode") === "true" ? {backgroundColor: "rgb(56, 56, 46)"} : {})
        }),
        valueContainer: base => ({...base, padding: "0px 14px"}),
        dropdownIndicator: base => ({...base, padding: 4}),
        clearIndicator: base => ({...base, padding: 4}),
        input: base => ({...base, margin: 0, padding: 0, ...(localStorage.getItem("lightMode") === "true") ? {color: "#dadada"} : {color: "#000000"}})
    },
    ...(
        localStorage.getItem("lightMode") === "true"
            ? {
                multiValue: base => ({...base, backgroundColor: "#777",color: "#ccc"}),
                multiValueLabel: base => ({...base, color: "#ccc"}),
                menu: base => ({...base, backgroundColor: "#36342a"}),
                singleValue: base => ({...base, color: "#ddd"}),
                option: (styles, {isFocused}) => ({
                    ...styles,
                    backgroundColor: isFocused
                        ? "#aaa"
                        : null,
                    color: isFocused
                        ? "#333"
                        : "#ddd",
                })
            } : {}
    )
};