import Api from "./api";

const NameplateApi = {
    list: (params) => Api.get("nameplate", {...params}),
    listForSelect: () => Api.get("nameplate/select"),
    getOne: (id) => Api.get(`nameplate/${id}`),
    create: (data) => Api.post("nameplate", data),
    update: (id, data) => Api.patch(`nameplate/${id}`, data),
    delete: id => Api.delete(`nameplate/${id}`),
    listForOverlay: (query) => Api.get("nameplate/overlay", {query}),
};

export default NameplateApi;