import React, { useState } from "react";
import {Modal} from "../../shared";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../stores/GlobalStore";
import {
    get as _get,
} from "lodash";
import {useAlarmListInvalidate} from "../../hooks/api/Alarm/UseAlarmListInvalidate";
import { useParams } from "react-router";

const AcknowledgeModal = (props) => {
    const [data, setData] = useState({notes: props.note || ""});
    const [inProgress, setInProgress] = useState(false);
    const params = useParams();
    const invalidateAlarmList = useAlarmListInvalidate(params.equipmentId);
    const onChange = (e) => {
        const data = Object.assign({}, data, {[_get(e, "target.name", "")]: _get(e, "target.value", "")});
        if (typeof props.clearErrorsForm === "function") {
            props.clearErrorsForm();
        }
        setData(data);
    };

    const handleSubmitModal = () => {
        setInProgress(true);
        props.onSubmit(data).then(() => {
            invalidateAlarmList();
            setInProgress(false);
        });
    };

    const {auth} = props;

    if (!auth.userCan("editAlarms")) {
        return "";
    }

    return (
        <Modal
            {...props}
            onSubmit={handleSubmitModal}
            inProgress={inProgress}
        >
            <div className="form-group row">
                <div className="col-12">
                    <label className="text-right form-label">Notes:</label>
                    <textarea
                        rows={7}
                        className={"form-control"}
                        name="notes"
                        value={data.notes}
                        onChange={onChange}
                        disabled={inProgress}
                    />
                </div>
            </div>
        </Modal>
    );
};

AcknowledgeModal.propTypes = {
    auth: PropTypes.object,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    showModal: PropTypes.bool,
    customStyles: PropTypes.object,
    children: PropTypes.any,
    title: PropTypes.string,
    submitTitle: PropTypes.string,
    cancelTitle: PropTypes.string,
    note: PropTypes.string,
    formErrors: PropTypes.object,
    clearErrorsForm: PropTypes.func,
};

export default withGlobalStore(AcknowledgeModal);