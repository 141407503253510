import {useMutation} from "@tanstack/react-query";
import Api from "../../../api/api";

const exportHistoricalAlerts = ({from, to, assetBranchId, chartType = "", type = "", axis = "", level = "", query = ""} = {}) =>
    Api.get("alarms/history-export", {
        query: {filter: {date: {from, to}, location: assetBranchId, chartType, type, axis, level, query}},
        parseAsText: true,
    });

export const useExportHistoricalAlertMutation = ({onSuccess}) =>
    useMutation({
        mutationFn: exportHistoricalAlerts,
        onSuccess,
    });
