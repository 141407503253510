import Api from "./api";

const AlertCondition = {
    getList: (query) => {
        return Api.get("alert-conditions", {query});
    },
    create: (data) => {
        return Api.post("alert-conditions/create", data);
    },
    updateMultiple: (data) => {
        return Api.post("alert-conditions/update-multiple", data);
    },
    update: (data) => {
        return Api.post("alert-conditions/update", data);
    },
    delete: (data) => {
        return Api.post("alert-conditions/delete", data);
    },
    get: (id) => {
        return Api.get(`alert-conditions/${id}`);
    },
    getGroupEmailUsers: (query) => {
        return Api.get("alert-conditions/group-email-users", {query});
    },
};

export default AlertCondition;
