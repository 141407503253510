import AlertApi from "../../../api/alertLevel";
import {useQuery} from "@tanstack/react-query";
import {ALERT_API} from "./constants";

const useAlertLevelsList = (params) => {
    const {
        isSuccess,
        data: alertLevelsList,
        ...rest
    } = useQuery([ALERT_API.LEVEL_LIST], () => AlertApi.getList(params, (response) => response.data));

    return {
        isSuccess,
        alertLevelsList: alertLevelsList?.list || [],
        ...rest,
    };
};

export default useAlertLevelsList;
