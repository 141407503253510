import Api from "./api";

const RoutesApi = {
    list: (params) => {
        return Api.get("route", {...params});
    },
    routeCollectorLocations: (params) => {
        return Api.get("route/route-collector-locations", {...params});
    },
    getEquipmentListByAsset: (locationId) => Api.get(`route/get-equipment-list-by-asset/${locationId}`),
    getView: (id) => Api.get(`route/${id}`),
    create: (data) => Api.post("route", data),
    update: (id, data) => Api.patch(`route/${id}`, data),
    delete: (id) => Api.delete(`route/${id}`),
};

export default RoutesApi;
