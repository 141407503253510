import React from "react";
import PropTypes from "prop-types";
import SensorHexTab from "./sensorHexTab";
import InstallationPointsTab from "./installationPointsTab";
import EquipmentTab from "./equipmentTab";
import LocationsTab from "./locationsTab";
import NodeSerialTab from "./nodeSerialTab";
import MoteTab from "./moteTab";
import GatewayTab from "./gatewayTab";
import RouterTab from "./routerTab";

const listItems = {
    Sensor: {title: "Sensor", component: SensorHexTab},
    InstallationPoints: {title: "Installation points", component: InstallationPointsTab},
    Equipments: {title: "Equipments", component: EquipmentTab},
    Locations: {title: "Asset Tree Branch", component: LocationsTab},
    Node: {title: "Node", component: NodeSerialTab},
    Mote: {title: "Sensor Mote", component: MoteTab},
    Gateway: {title: "Gateway", component: GatewayTab},
    Router: {title: "Repeater", component: RouterTab}
};

const AllListItemsTab = ({list, query}) => {

    return (
        <React.Fragment>
            {(Object.keys(list) || []).map(key => {
                const Component = listItems[key].component;

                return (
                    <div key={key} className="">
                        {(list[key] || []).length &&
                            <React.Fragment>
                                <h1>{listItems[key].title}</h1>
                                <Component list={list[key]} queru={query}/>
                            </React.Fragment>
                        }
                    </div>
                );
            })}
        </React.Fragment>
    );
};

AllListItemsTab.propTypes = {
    list: PropTypes.object,
    query: PropTypes.string
};

export default AllListItemsTab;