import React from "react";
import auth from "../../../../../../services/auth";
import {useChartContext} from "../../hooks/chartHooks";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useAlertConditionListQuery} from "../../../../../../hooks/api/alertCondition/useAlertConditionListQuery";

const ShowAlertsButton = () => {
    const {chartProps} = useChartContext();
    const {chartType} = chartProps;

    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    const showAlerts = useChartStateStoreContext((state) => state.settings.showAlerts);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint, shallow);

    const {alertConditionsCount} = useAlertConditionListQuery(chartType, selectedPoint);
    const {toggleAlerts} = useChartStateStoreContext((state) => state.actions);

    if (!auth.userCan("showAlarms")) {
        return <></>;
    }

    if (alertConditionsCount <= 0) {
        return <></>;
    }

    return (
        <span>
            <span
                className="alert-link"
                onClick={toggleAlerts}
            >
                <label className="switch">
                    <input
                        readOnly={true}
                        type="checkbox"
                        disabled={!!isLoading}
                        checked={showAlerts ? "checked" : false}
                    />
                    <span className="slider round" />
                </label>
                Show Alerts
            </span>
        </span>
    );
};

export default ShowAlertsButton;
