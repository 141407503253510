import React, {Component} from "react";
import PropTypes from "prop-types";

class CompletionFooter extends Component
{
    render() {
        const {onSubmit, onCancelCompletion, inProgress} = this.props;

        return (
            <React.Fragment>
                <div className={"action-item-footer"}>
                    <button
                        onClick={onCancelCompletion}
                        className="btn v2-btn blue-border mr-2"
                        disabled={inProgress}
                    >
                        {inProgress ? <span><i className="fa fa-spinner" /> Processing</span> : "Back"}
                    </button>
                    <button
                        onClick={onSubmit}
                        className="btn v2-btn blue"
                        disabled={inProgress}
                    >
                        {inProgress ? <span><i className="fa fa-spinner" /> Processing</span> : "Submit"}
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

CompletionFooter.propTypes = {
    onSubmit: PropTypes.func,
    onCancelCompletion: PropTypes.func,
    inProgress: PropTypes.bool
};

export default CompletionFooter;