import React from "react";
import {useSelectedTab} from "../../../stores/zustand/AlertConditionStore";
import InfoAlertText from "./InfoAlertText";
import {ALERT_TABS_NAME} from "../constants/tabs";
import LossPacketAlert from "./LossPacketAlert";
import PropTypes from "prop-types";

const AlertModalHeader = ({chartTypes, equipment}) => {
    const currentTab = useSelectedTab();
    return (
        <>
            <InfoAlertText>
                {currentTab === ALERT_TABS_NAME.DELTA_T && (
                    <>
                        <br />
                        You can add an alert group to compare readings from multiple sensors.
                    </>
                )}
            </InfoAlertText>
            <LossPacketAlert
                chartTypes={chartTypes}
                equipment={equipment}
            />
        </>
    );
};
AlertModalHeader.propTypes = {chartTypes: PropTypes.object, equipment: PropTypes.object};
export default AlertModalHeader;
