import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get,
    find as _find
} from "lodash";
import {withGlobalStore} from "../../stores/GlobalStore";
import {ResetSortButton} from "../shared/resetSortButton";
//import {Link} from "react-router-dom";
import {ValidationError} from "../../shared";
import Helper from "../../helpers/helper";
import NoteFile from "../../modals/notes/components/file";

const headersList = {
    "note_type": {title: "Type", sort: true},
    "note": {title: "Note", sort: false, style: {width: 300}},
    "user_name": {title: "Username", sort: true},
    "created_at": {title: "Created", sort: true},
    "timestamp": {title: "Reading Timestamp", sort: false},
    "location_name": {title: "Asset Tree Branch", sort: true},
    "equipment_name": {title: "Equipment", sort: true},
    "arrow": {title: "Actions", sort: false, style: {width: 20}, additionalClasses: "table-buttons-th", component: ResetSortButton}
};

class Table extends Component
{
    constructor(props) {
        super(props);

        this.noteTextArea = null;

        this.state = {
            openNoteId: null,
            editNote: null,
        };
    }

    openNote = (id) => {
        const {removeErrors} = this.props;

        this.setViewed(id, () => {
            this.setState({
                openNoteId: +id,
                editNote: null
            }, () => removeErrors());
        });
    }

    setViewed = (id, callback = null) => {
        const {list, setViewed} = this.props;

        const note = _find(list, ["id", id]);

        if (!(note || {}).viewed) {
            setViewed(note, callback);
            return;
        }

        if (callback && typeof callback === "function") {
            callback();
        }
    }

    closeNote = () => {
        this.setState({
            openNoteId: null,
            editNote: null
        });
    }

    setNoteTextArea = (noteTextArea) => {
        this.noteTextArea = noteTextArea;
    }

    setOnEdit = (id) => {
        const {list, removeErrors} = this.props;

        removeErrors();

        const note = _find(list, ["id", id]);

        if (note) {
            this.setState({
                editNote: note
            });
        } else {
            this.setState({
                editNote: null,
            });
        }

        if (this.noteTextArea && id)
            this.noteTextArea.focus();
    }

    editNoteText = (event) => {
        const {removeErrors} = this.props;
        const editNoteState = this.state.editNote;

        if (editNoteState) {
            const editNote = {...editNoteState, text: event.target.value};

            this.setState({editNote}, () => removeErrors("text"));
        }
    }

    saveEditNote = () => {
        const editNote = this.state.editNote;
        const {onSubmitNoteEdit} = this.props;

        if (editNote) {
            onSubmitNoteEdit(editNote).then(() => {
                this.checkForCloseEdit();
            });
        }
    }

    checkForCloseEdit = () => {
        const {formErrors} = this.props;

        if (!Object.keys(formErrors).length) {
            this.setState({
                editNote: null
            });
        }
    }

    onAttachFile = (fileObject) => {
        const editNote = {...this.state.editNote, ...fileObject};
        const {removeErrors} = this.props;

        this.setState({editNote}, () => removeErrors("file"));
    }

    onRemoveFile = () => {
        const fileObject = {
            file: "",
            fileName: ""
        };
        const editNote = {...this.state.editNote, ...fileObject};

        this.setState({editNote});
    }

    render() {
        const {openNoteId, editNote} = this.state;
        const {list, sort, onSortChange, query, user, auth, onDeleteNote, isModal, toChartMark, history, formErrors} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <div className="table-scroll-wrapp">
                <table className="table table-hover tl-fixed global-notes-table">
                    <thead>
                        <tr>
                            {Object.keys(headersList).map((key) => {
                                if (!isModal || (key !== "location_name" && key !== "equipment_name" )) {
                                    let component = "";
                                    const RowComponent = _get(headersList, [key, "component"], false);
                                    if (RowComponent) {
                                        component = <RowComponent sort={sort} resetSort={() => onSortChange("")}/>;
                                    }
                                    return (
                                        listLen && headersList[key].sort
                                            ?
                                            <th className={headersList[key].additionalClasses || ""} key={key} style={headersList[key].style || {}} onClick={() => onSortChange(key)}>
                                                {headersList[key].title}
                                                {sort.field === key ? <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")}/> : <i className={"fa fa-sort"} />}
                                            </th>
                                            :
                                            <th className={headersList[key].additionalClasses || ""} key={key} style={headersList[key].style || {}}>{headersList[key].title} {component}</th>
                                    );
                                }
                            }
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {list.length > 0
                            ?
                            list.map((note) =>
                                <NoteRow
                                    key={note.id}
                                    note={note}
                                    openNoteId={openNoteId}
                                    query={query}
                                    user={user}
                                    auth={auth}
                                    history={history}
                                    onOpen={this.openNote}
                                    onClose={this.closeNote}
                                    onDeleteNote={onDeleteNote}
                                    onEditNote={this.setOnEdit}
                                    setNoteTextArea={this.setNoteTextArea}
                                    formErrors={formErrors}
                                    editNoteText={this.editNoteText}
                                    editNote={editNote}
                                    saveEditNote={this.saveEditNote}
                                    isModal={isModal}
                                    toChartMark={toChartMark}
                                    setViewed={this.setViewed}
                                    onAttachFile={this.onAttachFile}
                                    onRemoveFile={this.onRemoveFile}
                                />
                            )
                            :
                            <tr>
                                <td colSpan={listLen} className="text-center text-info">
                                    {query !== "" ? "No items match your search." : "No notes exist."}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

class NoteRow extends Component {

    render() {
        const {
            note,
            openNoteId,
            onOpen,
            onClose,
            user,
            onDeleteNote,
            onEditNote,
            setNoteTextArea,
            formErrors,
            editNoteText,
            editNote,
            saveEditNote,
            isModal,
            /*toChartMark,*/
            setViewed,
            history,
            onAttachFile,
            onRemoveFile,
            auth
        } = this.props;

        const isEdit = +(editNote || {}).id === +note.id;

        let toEquipmentLink = "/chart/" + note.equipment_id;
        if (note.note_type === "chart_mark") {
            toEquipmentLink += "#chart_mark_id=" + note.note_entity_id + (note.installation_point_id ? "&sensors=" + note.installation_point_id : "");
        }

        const createdAtFormat = Helper.dateToUserFormat(note.created_at, user);
        const createdAt = Helper.explodeDate(createdAtFormat);

        const timestampFormat = Helper.dateToUserFormat(note.timestamp, user);
        const timestamp = Helper.explodeDate(timestampFormat);

        const textNote = isEdit ? editNote : note;
        const text = _get(textNote, "text") ? _get(textNote, "text") : _get(textNote, "name", "");

        return (
            <React.Fragment>
                <tr
                    style={{fontWeight: note.viewed ? "normal" : "bold", borderTop: "1px solid grey", cursor: "pointer", }}
                    onClick={() => onOpen(note.id)}
                >
                    <td style={{fontSize: "20px"}}>
                        {note.note_type === "chart_mark"
                            ?
                            <React.Fragment>
                                {+_get(note, "mark_type") === 1
                                    ?
                                    <div style={{color: "#e3bb31"}}>
                                        <i className="fa fa-info-circle" title="Chart Mark Info"/>
                                    </div>
                                    :
                                    <div>
                                        <i className="flaticon2-chart color-success" title="Chart Mark"/>
                                    </div>
                                }
                            </React.Fragment>
                            :
                            <div>
                                <i className="fa fa-file color-primary" title="Note"/>
                            </div>
                        }
                    </td>
                    <td style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: "50px", maxWidth: "300px"}}>
                        {text}
                    </td>
                    <td>{note.user_name}</td>
                    <td>{createdAt.date} <br/> {createdAt.time}</td>
                    <td>
                        {note.note_type === "chart_mark"
                            ?
                            <span>
                                {timestamp.date} <br/> {timestamp.time}
                            </span>
                            :
                            "---"
                        }
                    </td>
                    {!isModal &&
                        <React.Fragment>
                            <td>{note.location_name}</td>
                            <td>
                                <span
                                    className={"link link-primary"}
                                    onClick={() => {setViewed(note.id, () => {
                                        history.push(toEquipmentLink);
                                    });}}
                                >
                                    {note.equipment_name}
                                </span>
                            </td>
                        </React.Fragment>
                    }
                    <td style={{fontSize: "1.5rem", color: "#449AFF"}}>
                        {openNoteId !== +note.id
                            ?
                            <i className="fa fa-angle-down"/>
                            :
                            <i
                                className="fa fa-angle-up"
                                id={"close-notes"}
                                onClick={(event) => {
                                    if(event.target === event.currentTarget) {
                                        event.stopPropagation();
                                        onClose();
                                    }
                                }}
                                style={{fontSize: "1.5rem", color: "#449AFF",  cursor: "pointer", paddingLeft: "20px"}}
                            />
                        }
                    </td>
                </tr>
                {openNoteId === +note.id &&
                    <tr>
                        <td style={{fontSize: "20px"}} />
                        <td colSpan={isModal ? 2 : 3} style={{height: "100%"}}>
                            <div
                                className={"form-group mb-0" + (formErrors.text ? " is-invalid" : "")}
                                style={{height: "100%"}}
                            >
                                <textarea
                                    ref={(noteText) => { setNoteTextArea(noteText); }}
                                    rows={5}
                                    className={"note-textarea" + (isEdit ? "" : " readonly")}
                                    value={text}
                                    onChange={editNoteText}
                                />
                                <ValidationError message={formErrors.text}/>
                            </div>
                            {(!!note.file || isEdit) &&
                                <div>
                                    <NoteFile
                                        fileName={isEdit ? (editNote.file || {}).name : (note.file || {}).name}
                                        fileType={isEdit ? (editNote.file || {}).type : (note.file || {}).type}
                                        fileUrl={isEdit ? (editNote.file || {}).url : (note.file || {}).url}
                                        withoutEdit={!isEdit}
                                        errorMsg={formErrors.file}
                                        onAttachFile={onAttachFile}
                                        onRemoveFile={onRemoveFile}
                                    />
                                </div>
                            }
                        </td>
                        {!!isModal &&
                            <React.Fragment>
                                <td colSpan={2}/>
                            </React.Fragment>
                        }
                        {!isModal &&
                            <React.Fragment>
                                <td colSpan={3}/>
                            </React.Fragment>
                        }
                        <td>
                            <div className="btn-group btn-group-sm">
                                {(+user.id === +note.user_id && +_get(note, "mark_type") !== 1 && auth.userCan("editAlarms")) &&
                                <React.Fragment>
                                    {isEdit
                                        ?
                                        <React.Fragment>
                                            <button
                                                className="link link-danger"
                                                onClick={() => onEditNote(null)}
                                            >
                                                <i className="fa fa-times"/> <span>Cancel</span>
                                            </button>
                                            <button
                                                className="link link-primary"
                                                onClick={saveEditNote}
                                            >
                                                <i className="fa fa-save"/> <span>Save</span>
                                            </button>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <button
                                                className="link link-danger"
                                                onClick={() => onDeleteNote(note.id)}
                                            >
                                                <i className="fa fa-times"/> <span>Delete</span>
                                            </button>
                                            <button
                                                className="link link-primary"
                                                onClick={() => onEditNote(note.id)}
                                            >
                                                <i className="fa fa-pen"/> <span>Edit</span>
                                            </button>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                                }
                            </div>
                        </td>
                    </tr>
                }
            </React.Fragment>
        );
    }
}

NoteRow.propTypes = {
    note: PropTypes.object,
    query: PropTypes.string,
    user: PropTypes.object,
    auth: PropTypes.object,
    history: PropTypes.object,
    openNoteId: PropTypes.number,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onDeleteNote: PropTypes.func,
    onEditNote: PropTypes.func,
    setNoteTextArea: PropTypes.func,
    formErrors: PropTypes.object,
    editNoteText: PropTypes.func,
    editNote: PropTypes.object,
    saveEditNote: PropTypes.func,
    isModal: PropTypes.bool,
    toChartMark: PropTypes.func,
    setViewed: PropTypes.func,
    onAttachFile: PropTypes.func,
    onRemoveFile: PropTypes.func
};

Table.propTypes = {
    list: PropTypes.array,
    sort: PropTypes.object,
    onShowBatteryVoltageModal: PropTypes.func,
    onSortChange: PropTypes.func,
    onDeleteNode: PropTypes.func,
    query: PropTypes.string,
    user: PropTypes.object,
    auth: PropTypes.object,
    history: PropTypes.object,
    setViewed: PropTypes.func,
    onDeleteNote: PropTypes.func,
    onSubmitNoteEdit: PropTypes.func,
    isModal: PropTypes.bool,
    toChartMark: PropTypes.func,
    formErrors: PropTypes.object,
    removeErrors: PropTypes.func
};

export default withGlobalStore(Table);