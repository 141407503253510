import React, {useState} from "react";
import PropTypes from "prop-types";
import {Modal} from "../../../shared";
import SweetAlert from "react-bootstrap-sweetalert";
import FrequencyConverter from "../../../helpers/frequency-converter";
import SpeedNotSetLabel from "../../../pages/chart/components/speedNotSetLabel";
import { useEquipmentByParams } from "../../../hooks/api/equipment/useEquipmentQuery";

const List = (props) => {
    const {faultFrequencies, currentSpeed, currentSpeedFrom, onClose, editHandler, addHandler, isDeleting} = props;
    const [deleteFaultFrequencyId, setDeleteFaultFrequencyId] = useState(null);
    const {equipmentItem} = useEquipmentByParams();
    const deleteHandler = () => {
        props.deleteHandler(deleteFaultFrequencyId, () => setDeleteFaultFrequencyId(null));
    };

    return (
        <Modal
            {...props}
            size="lg"
            title={false}
            withoutFooter={true}
        >
            <React.Fragment>
                <div className="row pseudo-modal-header">
                    <div className={"modal-title w-100"}>
                        <span className="text-uppercase mr-2">Manage Fault Frequencies</span>
                        {currentSpeed <= 0 && (
                            <SpeedNotSetLabel
                                onClick={() => props.handleSetSpeedModal("manually")}
                                equipment={equipmentItem}
                                currentSpeed={currentSpeed}
                                currentSpeedFrom={currentSpeedFrom}
                            />
                        )}
                    </div>
                    <div className="right-modal-title-block justify-content-end">
                        <button
                            className="link link-primary mr-4"
                            onClick={addHandler}
                        >
                            <span className="badge badge-primary round-badge badge-icon">
                                <i className="fa fa-plus" />
                            </span>
                            <span>Add</span>
                        </button>

                        <button
                            onClick={onClose}
                            className={"close pull-right custom-close-btn"}
                            type={"button"}
                            aria-label={"Close"}
                        >
                            <span aria-hidden="true" />
                        </button>
                    </div>
                </div>
                <div className="row">
                    <table className={"table"}>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Frequency</th>
                                <th>Units</th>
                                <th>Plot Lines</th>
                                <th style={{width: "150px"}}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {faultFrequencies.length ? (
                                faultFrequencies.map((faultFrequency) => (
                                    <tr key={faultFrequency.id}>
                                        <td>{faultFrequency.name}</td>
                                        <td>
                                            {FrequencyConverter.fromType(
                                                faultFrequency.frequency_type,
                                                faultFrequency.frequency,
                                                currentSpeed
                                            ).numberFormat(2, {withX: true})}
                                        </td>
                                        <td>{faultFrequency.frequency_type}</td>
                                        <td>{faultFrequency.plot_lines_count || 0}</td>
                                        <td>
                                            <div className="btn-group">
                                                <button
                                                    onClick={() => editHandler(faultFrequency.id)}
                                                    className="link link-primary"
                                                >
                                                    <i className="fa fa-pen" /> <span>Edit</span>
                                                </button>
                                                <button
                                                    onClick={() => setDeleteFaultFrequencyId(faultFrequency.id)}
                                                    className="link link-danger"
                                                >
                                                    <i className="fa fa-times" /> <span>Delete</span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan={9}
                                        className={"text-center"}
                                    >
                                        Fault frequencies not set
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {deleteFaultFrequencyId && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title="DELETE FAULT FREQUENCY"
                        onConfirm={deleteHandler}
                        onCancel={() => setDeleteFaultFrequencyId(null)}
                        disabled={isDeleting}
                    >
                        Are you sure you want to delete the fault frequency?
                    </SweetAlert>
                )}
            </React.Fragment>
        </Modal>
    );
};

List.propTypes = {
    equipment: PropTypes.object,
    selectedPoint: PropTypes.number,
    faultFrequencies: PropTypes.array,
    editHandler: PropTypes.func,
    deleteHandler: PropTypes.func,
    addHandler: PropTypes.func,
    currentSpeed: PropTypes.number,
    currentSpeedFrom: PropTypes.string,
    onClose: PropTypes.func,
    handleSetSpeedModal: PropTypes.func,
    updateFaultFrequency: PropTypes.func,
    isDeleting:PropTypes.bool
};

export default List;
