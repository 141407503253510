import { useEffect } from "react";

export const useThemeSync = () => {
    useEffect(() => {
        const localVal = localStorage.getItem("lightMode");
        if (localVal === "true") {
            document.querySelector("BODY").classList.add("dark");
        }
    }, []);
};
