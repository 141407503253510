import Api from "./api";

const AlertDefaultCondition = {
    getList: (query) => {
        return Api.get("alert-conditions/default-levels", {...query});
    },
    export: (query) => {
        return Api.get("alert-conditions/default-levels-export", {...query});
    },
    update: (data) => {
        return Api.post("alert-conditions/default-update", data);
    },
    delete: (data) => {
        return Api.delete("alert-conditions/default-delete", data);
    },
};

export default AlertDefaultCondition;
