import React from "react";
import PropTypes from "prop-types";
import {get, includes} from "lodash";

const SearchSelect = React.forwardRef(({name, onChange, list, defaultValue, optName, valueKey, htmlContent, disabled, blackList, validator, size = 10}, ref) => {

    const validation = (validator || {})[name];

    return (
        <select
            ref={ref}
            disabled={disabled}
            onChange={onChange}
            name={name}
            value={defaultValue}
            className={`form-control k_selectpicker ${(validation && validation.isInvalid) ? "is-invalid" : ""}`}
            data-live-search="true"
            data-size={size}
        >
            <option value="0">Select Parent</option>
            {(list.length || [])
                ?
                list.map(item => htmlContent
                    ?
                    <option disabled={includes(blackList, get(item, valueKey))} key={get(item, valueKey)} value={get(item, valueKey)} dangerouslySetInnerHTML={{__html: get(item, optName)}}/>
                    :
                    <option disabled={includes(blackList, get(item, valueKey))} key={get(item, valueKey)} value={get(item, valueKey)}>{get(item, optName)}</option>)
                :
                <option disabled={true} value="">Data is empty</option>
            }
        </select>
    );
});

SearchSelect.propTypes = {
    list: PropTypes.array,
    blackList: PropTypes.array,
    name: PropTypes.string,
    onChange: PropTypes.func,
    valueKey: PropTypes.string,
    optName: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    htmlContent: PropTypes.bool,
    disabled: PropTypes.bool,
    validator: PropTypes.object,
    size: PropTypes.number
};

SearchSelect.displayName = "Search";

export default SearchSelect;