import ChartHelper from "../../../../../helpers/chart";

export const toggleBandPoint = (points, chartRef) => {
    ChartHelper.clearAdditionalPoints(chartRef.current.chart);

    points.map((point) => {
        let left = point.series.chart.xAxis[0].toPixels(point.x) - 6,
            top = point.series.chart.yAxis[0].toPixels(point.y) - 6;

        let renderedPoint = point.series.chart.renderer
            .rect(left, top, 12, 12, 6)
            .attr({
                fill: "green",
                stroke: "green",
                "stroke-width": 1,
                zIndex: 7,
            })
            .add();

        renderedPoint.element.classList.add("point-sideband");
    });
};

export const toggleBCursorPoint = (points, chartRef) => {
    if (!points.length && !chartRef.current.chart.isPointBPaused) {
        chartRef.current.chart.isPointBPaused = true;
        return;
    }

    if (chartRef.current.chart.isPointBPaused) {
        chartRef.current.chart.isPointBPaused = false;

        return [];
    }

    ChartHelper.clearAdditionalPoints(chartRef.current.chart);
    chartRef.current.chart.isShowBCursor = true;
    points.map((point) => {
        let left = point.series.chart.xAxis[0].toPixels(point.x) - 6,
            top = point.series.chart.yAxis[0].toPixels(point.y) - 6;

        let renderedPoint = point.series.chart.renderer
            .rect(left, top, 12, 12, 6)
            .attr({
                fill: "black",
                stroke: "black",
                "stroke-width": 1,
                zIndex: 7,
            })
            .add();
        renderedPoint.element.classList.add("point-b-cursor");
    });
    return points;
};
