import Api from "./api";

const SelfBearing = {
    list: (params) => {
        return Api.get("bearing/self-list", {...params});
    },
    get: (selfBearingId) => {
        return Api.get("bearing/self-get/" + selfBearingId);
    },
    add: (params) => {
        return Api.post("bearing/self-add", {...params});
    },
    delete: (selfBearingId) => {
        return Api.delete(`bearing/self-delete/${selfBearingId}`);
    },
    update: (selfBearingId, params) => {
        return Api.post(`bearing/self-update/${selfBearingId}`, {...params});
    },
};

export default SelfBearing;