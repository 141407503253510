import {useEffect, useMemo} from "react";
import Highcharts from "highcharts";
import ChartHelper from "../../../../../helpers/chart";
import {sum as _sum, get as _get, cloneDeep as _cloneDeep} from "lodash";
import Helper from "../../../../../helpers/helper";
import {useChartStateStoreContext} from "../store/hooks/useStoreContext";

import {useDeleteReadingsSimple} from "../../../../../modals/deleteReadingsSimple/hooks/useDeleteReadingsSimple";
import {useChartRef} from "./useChartRef";
import {useCurrentPointsRef} from "./useCurrentPointsRef";
import {useChartHoverEffect} from "../../hooks/keyboard/useChartHoverEffect";
import {useChartHoldEffect} from "../../hooks/keyboard/useChartHoldEffect";
import {useChartLegendEffect} from "../../hooks/keyboard/useChartLegendEffect";

export const useKeyDownEffect = () => {
    const state = useChartStateStoreContext();
    const [showDeleteReadingModalSimple] = useDeleteReadingsSimple();
    const chartRef = useChartRef();
    const currentPoints = useCurrentPointsRef();

    const isModalOpen = useMemo(
        () => Helper.getHashParams().modal || _sum(state.modal) || state.setSpeedModal?.show,
        [state.modal, state.setSpeedModal?.show]
    );

    const handleKeyDown = (e) => {
        if (
            !_get(chartRef.current, "chart.hoverPoint", false) ||
            Highcharts.charts[Highcharts.hoverChartIndex] !== chartRef.current.chart ||
            isModalOpen
        ) {
            return;
        }

        if ((e.keyCode === 37 || e.keyCode === 39) && !chartRef.current.chart.isPaused) {
            // left/right arrow
            ChartHelper.handleNavigationSimple(e, chartRef.current.chart);
        } else if (e.keyCode === 46 || e.keyCode === 8) {
            // delete || backspace
            currentPoints.current = _cloneDeep(_get(chartRef, "current.chart.hoverPoints", {}));
            showDeleteReadingModalSimple();
        }
    };

    useChartHoverEffect(chartRef);
    useChartHoldEffect(chartRef);
    useChartLegendEffect(chartRef);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);
};
