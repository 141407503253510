import React, {useEffect} from "react";
import {useChartComputed, useChartType} from "../hooks/helpers/chart";
import {useAlertConditionStoreActions, useCurrentAlertGroup} from "../../../stores/zustand/AlertConditionStore";
import {useInstallationPointIds} from "../../../hooks/helpers/useInstallationPointIds";
import {useAlertConditionListQuery} from "../../../hooks/api/alertCondition/useAlertConditionListQuery";
import PropTypes from "prop-types";
import {get as _get, find as _find} from "lodash";
import {Loader} from "../../../shared";

const AlertGroupManagement = ({chartTypes, equipment}) => {
    // eslint-disable-next-line no-unused-vars
    const {chartType} = useChartType(chartTypes);
    const {axisId} = useChartComputed(chartTypes, equipment);

    const currentGroup = useCurrentAlertGroup();

    const installationPointIds = useInstallationPointIds(equipment);

    const {alertConditionsGroupNormalized: alertGroupsNormalized, isSuccess: isAlertGroupSuccess} = useAlertConditionListQuery(chartType, installationPointIds, null, equipment);

    const {setCurrentAlertGroup, setDeleteId, setConfirmRemove} = useAlertConditionStoreActions();

    const removeBadge = (id) => {
        setDeleteId(id);
        setConfirmRemove(true);
    };

    useEffect(() => {
        setCurrentAlertGroupItem();
    }, [axisId]);


    const changeAlertGroup = (groupID) => {
        if (groupID !== currentGroup.id) {
            const selectedAlertGroup = _get(alertGroupsNormalized, [axisId, groupID], {points: [], id: false, names: []});
            setCurrentAlertGroup(selectedAlertGroup);
        }
    };

    const setCurrentAlertGroupItem = () => {
        if(Object.keys(alertGroupsNormalized).length > 0) {
            if(!_find(alertGroupsNormalized, axisId) && !_get(alertGroupsNormalized, [axisId])) {
                setCurrentAlertGroup({points: [], id: false, names: []});
            } else {
                setCurrentAlertGroup(Object.values(_get(alertGroupsNormalized, [axisId]))[0]);
            }
        } else {
            setCurrentAlertGroup({points: [], id: false, names: []});
        }
    };

    if (!isAlertGroupSuccess) {
        return <Loader />;
    }

    return (
        <div>
            {Object.keys(alertGroupsNormalized[axisId] || {}).length === 0 && (
                <React.Fragment>
                    <div className="secondary-text mb-2">
                        <i className="fa fa-info-circle mr-1" />
                        No alert groups exist.
                    </div>

                    <h4 className="title-group-delta">Add Alert Group</h4>
                </React.Fragment>
            )}
            <div className="row">
                {Object.keys(alertGroupsNormalized[axisId] || {}).map((groupID) => (
                    <span
                        onClick={() => changeAlertGroup(+groupID)}
                        key={groupID}
                        className={"col-12 alert-group-block alert-level-block" + (+groupID === currentGroup.id ? " current" : "")}
                    >
                        <span className="title-sensor-include">
                            {(alertGroupsNormalized[axisId][groupID]?.names || []).map((item) => (
                                <span key={item}>{item}</span>
                            ))}
                        </span>
                        <div className="list-labels-alerts">
                            {Object.keys(alertGroupsNormalized[axisId][groupID]?.alerts || {}).map((levelId) => {
                                const alert = alertGroupsNormalized[axisId][groupID].alerts[levelId] || {};
                                return (
                                    <div
                                        key={levelId}
                                        className={`label-alert-item label-alert-item-${(
                                            (alert.alertLevel || {}).name || ""
                                        ).toLowerCase()}`}
                                    >
                                        <span>{(alert.alertLevel || {}).name}</span>
                                        <button
                                            onClick={() => removeBadge(alert.id)}
                                            className="pull-right clearfix fa fa-times ml-2"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </span>
                ))}
            </div>

            {Object.keys(alertGroupsNormalized[axisId] || {}).length !== 0 && (
                <span
                    onClick={() => changeAlertGroup(false)}
                    className="col-12 add-new-link-manage mb-2"
                >
                    <i className="fa fa-plus-circle active" />
                    <span>Add New Alert Group</span>
                </span>
            )}
        </div>
    );
};
AlertGroupManagement.propTypes = {
    chartTypes: PropTypes.array,
    equipment: PropTypes.object,
};
export default AlertGroupManagement;