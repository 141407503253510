import React from "react";
import PropTypes from "prop-types";

const CheckBox = (props) =>  {

    const {label, name, onChange, value, className = "", checked = false, color = "", disabled = false} = props;

    if (color.length > 0) {
        return (
            <label className={`form-checkbox ${className} ${disabled === true ? "disabled-label": ""}`}>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    name={name}
                    value={value}
                    disabled={disabled}
                />
                {label}
                {
                    checked ?
                        <span style={{backgroundColor: color, borderColor: color}}/>
                        :
                        <span style={{borderColor: color}}/>
                }
            </label>
        );
    }

    return (
        <label className={`form-checkbox ${className}`}>
            <input type="checkbox" checked={checked} onChange={onChange} name={name} value={value} disabled={disabled}/> {label} <span/>
        </label>
    );
};

CheckBox.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    color: PropTypes.string
};

export default CheckBox;