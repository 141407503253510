import React, {useEffect, useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {CURSORS, FREQUENCY_TYPES} from "../../../../../../constants/constants";
import ChartHelper from "../../../../../../helpers/chart";
import $ from "jquery";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useLockPoints} from "../../hooks/useLockPoints";
import {useSpeedSettings} from "../../hooks/helpers/useSpeedSettings";
import {useUserFftChartFrequency, useUserLocalSettingsStoreActions} from "../../../../../../stores/UserLocalSettingsStore";
import {useChartRef} from "../../hooks/useChartRef";
import {isChartPaused, restorePoint} from "../../../../../../helpers/holdMode";

const FrequencyUnitButton = () => {
    const chartRef = useChartRef();

    const {currentSpeed} = useSpeedSettings();
    const {setFftChartFrequency} = useUserLocalSettingsStoreActions();
    const currentFrequency = useUserFftChartFrequency();
    const {chartMode, fftCursor, delayedInputValues} = useChartStateStoreContext((state) => state.settings, shallow);
    const {setChartCursor} = useChartStateStoreContext((state) => state.actions, shallow);

    const [isCursorOpen, setIsCursorOpen] = useState(false);
    const [isFrequencyOpen, setIsFrequencyOpen] = useState(false);

    const lockPoints = useLockPoints();

    const updateShow3d = () => {
        console.log("updateShow3d");
    };

    useEffect(() => {
        if (isChartPaused(chartRef.current)) {
            restorePoint(chartRef.current);
        }
    }, [currentFrequency]);

    const changeCursorHandlerWaterfallList = (fftCursor) => {
        $(document).find(".chart-block-fft-waterfall-wrapper .point-sideband").remove();
        $(document).find(".chart-block-fft-waterfall-wrapper .point-b-cursor").remove();
        $(document).find(".chart-block-fft-waterfall-wrapper .harmonic").remove();
        $(document).find(".chart-block-fft-waterfall-wrapper .sideBand").remove();
        setChartCursor(fftCursor);
    };

    const changeCursorHandler = (fftCursor) => {
        ChartHelper.clearAdditionalPoints(chartRef.current.chart);
        chartRef.current.chart.isPointBPaused = false;
        lockPoints.current = [];
        setChartCursor(fftCursor);
    };

    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    return (
        <>
            <Dropdown
                // className="pull-right-label"
                isOpen={isFrequencyOpen}
                disabled={!!isLoading}
                toggle={() => setIsFrequencyOpen((prevState) => !prevState)}
            >
                <DropdownToggle
                    tag="span"
                    caret
                    className="frequency-types-link"
                >
                    Frequency Unit ({currentFrequency})
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => setFftChartFrequency(FREQUENCY_TYPES.HZ)}>{FREQUENCY_TYPES.HZ}</DropdownItem>
                    <DropdownItem onClick={() => setFftChartFrequency(FREQUENCY_TYPES.CPM)}>{FREQUENCY_TYPES.CPM}</DropdownItem>
                    <DropdownItem
                        onClick={() => setFftChartFrequency(FREQUENCY_TYPES.ORDERS)}
                        disabled={currentSpeed <= 0}
                    >
                        {FREQUENCY_TYPES.ORDERS}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>

            {chartMode === "fft" && (
                <Dropdown
                    className="pull-left-label"
                    isOpen={isCursorOpen}
                    toggle={() => setIsCursorOpen((prevState) => !prevState)}
                >
                    <DropdownToggle
                        tag="span"
                        caret
                        className="frequency-types-link"
                    >
                        {CURSORS[fftCursor]}
                    </DropdownToggle>
                    <DropdownMenu>
                        {Object.keys(CURSORS).map((cursor) => (
                            <DropdownItem
                                key={cursor}
                                onClick={() => changeCursorHandler(cursor)}
                                disabled={fftCursor === cursor}
                            >
                                {CURSORS[cursor]}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            )}

            {chartMode === "waterfall-list" && (
                <Dropdown
                    className="pull-left-label"
                    isOpen={isCursorOpen}
                    toggle={() => setIsCursorOpen((prevState) => !prevState)}
                >
                    <DropdownToggle
                        tag="span"
                        caret
                        className="frequency-types-link"
                    >
                        {CURSORS[fftCursor]}
                    </DropdownToggle>
                    <DropdownMenu>
                        {Object.keys(CURSORS).map((cursor) => (
                            <DropdownItem
                                key={cursor}
                                onClick={() => changeCursorHandlerWaterfallList(cursor)}
                                disabled={fftCursor === cursor}
                            >
                                {CURSORS[cursor]}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            )}

            {chartMode === "3d" && (
                <div className="pull-left-label k-form__group mr-0">
                    <div className="d-inline-block mr-2 k-form__label">
                        <label>Frequency Range: From</label>
                    </div>
                    <div className="d-inline-block mr-2 k-form__control input-group-sm">
                        <input
                            type="number"
                            name="show3dFrom"
                            className={"form-control request-form-control"}
                            value={delayedInputValues.show3dFrom}
                            onChange={(ev) =>
                                updateShow3d({
                                    show3dFrom: ev.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="d-inline-block mr-2 k-form__label">
                        <label>To</label>
                    </div>
                    <div className="d-inline-block mr-2 k-form__control input-group-sm">
                        <input
                            type="number"
                            name="show3dTo"
                            className={"form-control request-form-control"}
                            value={delayedInputValues.show3dTo}
                            onChange={(ev) => updateShow3d({show3dTo: ev.target.value})}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default FrequencyUnitButton;
