import React from "react";
import ScaleButton from "../../../../../components/buttons/scaleButton";
import SeriesMenuMultiple from "../../../../../components/buttons/seriesMenuMultiple";
import ZoomButton from "../../../../../components/buttons/zoomButton";
import FullscreenButton from "../../../../../components/buttons/fullscreenButton";
import ManageAlertButtons from "../../../../../components/buttons/manageAlertButtons";
import ChartHeaderWrapper from "./layouts/ChartHeaderWrapper";
import ShowAlertsButton from "../../../../../components/buttons/showAlertsButton";
import BulkReadingsButton from "../../../../../components/buttons/bulkReadingsButton";
import OverMaxRpmControl from "../../../../../components/buttons/overMaxRpmControl";
import {useIsTachometer} from "../../../../../../hooks/useChartTypes";
import {useChartStateStoreContext} from "../../../../../store/hooks/useStoreContext";

const SimpleHeader = () => {
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isTachometer = useIsTachometer(chartType);

    return (
        <ChartHeaderWrapper>
            <div className={"chart-actions-wrapper"}>
                <SeriesMenuMultiple />
                {!isTachometer && <ScaleButton />}
                <ZoomButton />
                <BulkReadingsButton />
            </div>
            <div className={"chart-actions-right"}>
                <FullscreenButton />
                <ManageAlertButtons />
                <ShowAlertsButton />
                {isTachometer && <OverMaxRpmControl />}
            </div>
        </ChartHeaderWrapper>
    );
};

export default SimpleHeader;
