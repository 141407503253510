import Api from "./api";

const ApiChartMarkFft = {
    create: (params) => {
        return Api.post(`equipment/${params.equipmentId}/chart-mark-fft`, {...params});
    },
    update: (equipmentId, chartMarkFftId, params) => {
        return Api.patch(`equipment/${equipmentId}/chart-mark-fft/${chartMarkFftId}`, {...params});
    },
    get: (equipmentId) => {
        return Api.get(`equipment/${equipmentId}/chart-mark-fft`);
    },
    delete: (equipmentId, chartMarkFftId) => {
        return Api.delete(`equipment/${equipmentId}/chart-mark-fft/${chartMarkFftId}`);
    },
};

export default ApiChartMarkFft;
