import {find as _find} from "lodash";

export const wrapViewTypeChange = (Highcharts) => {
    // don't remove hover point on context menu or modal open
    Highcharts.wrap(Highcharts.Pointer.prototype, "onContainerMouseLeave", function (proceed, e) {
        const chart = _find(Highcharts.charts, (chart) => chart && chart.container === this);
        if (
            !chart ||
            !chart.isInsidePlot(
                e.pageX - chart.container.getBoundingClientRect().left + window.pageXOffset,
                e.pageY - chart.container.getBoundingClientRect().top + window.pageYOffset
            )
        ) {
            return proceed.apply(this, [e]);
        }
    });

    const onChangeViewType = () => {
        setTimeout(() => {
            Highcharts.charts.forEach((chart) => {
                if (chart) {
                    chart.isMoved = false;
                    chart.isPointBPaused = false;
                    chart.moveMode = false;
                    chart.reflow();
                }
            });
        }, 300);
    };

    window.addEventListener("changeViewType", onChangeViewType);

    return () => window.removeEventListener("changeViewType", onChangeViewType);
};
