import React, {Component} from "react";
import PropTypes from "prop-types";
import SearchInput from "../shared/search-input";
import {Loader} from "../../shared";
import {
    get as _get,
} from "lodash";
import BatchIntervalsTable from "./table";
import {withGlobalStore} from "../../stores/GlobalStore";
import InstallationPointSettingsApi from "../../api/InstallationPointSettings";
import BachIntervalsModal from "./modal";
import {withLocationSelectStore} from "../../stores/LocationSelectStore";
import CollapseLocationSelect from "../../shared/collapseLocationSelect/collapseLocationSelect";
import { HeaderSimple } from "../../shared/header";
import HighPassModal from "./highPassModal";
import {ExportToCsv} from "export-to-csv";

class BatchIntervalsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{name: "Sensor Intervals"}],
            loader: true,
            refresh: true,
            globalSearchString: "",
            list: [],
            filter: {
                search: "",
                location: props.getLocationId()
            },
            pagination: {
                page: 1,
                limit: 5,
                hasMore: true
            },
            settingIntervalModal: {
                show: false,
                equipment: {},
                installationPoint: {}
            },
            highPassModalData: {
                show: false,
                all: false,
                locationId: null,
                equipmentId: null,
                installationPointId: null,
            }
        };

        this.loadProgress = false;
    }

    componentDidMount() {
        this.handleFetch();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loader === true && prevState.loader === false) {
            if(this.state.refresh === true) {
                this.handleFetch();
            }
        }
    }

    handleFetch = (isLoadMore = false) => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        return InstallationPointSettingsApi
            .getList({
                query: {
                    filter: this.state.filter,
                    pagination: isLoadMore ? this.state.pagination : {
                        page: 1,
                        limit: 5,
                        hasMore: true
                    }
                },
                signal: this.signal
            })
            .then((response) => {
                const list = _get(response, "list");
                this.setState({
                    loader: false,
                    list: isLoadMore ? [...this.state.list, ...list] : list,
                    pagination: isLoadMore
                        ?
                        {
                            ...this.state.pagination,
                            hasMore: list.length === this.state.pagination.limit
                        }
                        :
                        {
                            page: 1,
                            limit: 5,
                            hasMore: true
                        }
                }, () => {this.loadProgress = false;});
            });
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    handleFilterChange = (obj = {}) => {
        this.finished = false;
        this.setState({
            filter: {
                ...this.state.filter,
                ...obj,
            },
            loader: true,
            pagination: {
                page: 1,
                limit: 5,
                hasMore: false
            }
        });
    }

    filtersApplied = () => {
        return this.state.filter.location !== "";
    };

    reset = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                location: ""
            },
            loader: true
        });
    };

    handleSearch = (value) => {
        const {filter} = {...this.state};

        this.setState({
            filter: {
                ...filter,
                search: value
            },
            loader: true,
            pagination: {
                page: 1,
                limit: 5,
                hasMore: false
            }
        });
    };

    onNextPage = () => {
        const {pagination} = {...this.state};

        if (!this.loadProgress) {
            this.loadProgress = true;
            this.setState({
                pagination: {
                    ...pagination,
                    page: pagination.page + 1,
                }
            }, () => this.handleFetch(true));
        }
    }

    setSettingIntervalModal = (data) => {
        this.setState({settingIntervalModal: data});
    }

    setHighPassModalData = (data) => {
        this.setState({highPassModalData: data});
    }

    handleExport = () => {
        this.setState({ loader: true, refresh: false });
        this.signal = this.controller.signal;

        InstallationPointSettingsApi
            .export({
                query: {
                    filter: this.state.filter
                },
                signal: this.signal
            })
            .then((response) => {
                 const csvExporter = new ExportToCsv({
                    filename: "Sensor Intervals",
                    showLabels: true,
                    headers: [
                        "Equipment ID",
                        "Equipment Name",
                        "Point ID",
                        "Point Name",
                        "Temperature Interval",
                        "RMS Interval",
                        "FFT Interval",
                        "X-axes",
                        "Y-axes",
                        "Z-axes",
                        "X-lor",
                        "Y-lor",
                        "Z-lor",
                        "ImpactVUE™ FMAX",
                        "ImpactVUE™ LOR",
                        "RMS HIGH-PASS",
                    ],
                });
                let list = _get(response, "data", []);
                this.setState({ loader: false, refresh: true });
                csvExporter.generateCsv(list);
            } );
    }

    render() {
        const {breadcrumbs, globalSearchString, loader, list, filter, pagination, settingIntervalModal, highPassModalData} = this.state;
        const {auth} = this.props;

        return (
            <div className={"interval-page"}>
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">Sensor Intervals</div>
                </div>
                <div className="block">
                    <div className="block-header">
                        <div className="block-label">
                            <div className="form-list form-list--row">
                                <div className="form-group form-group--inline">
                                    <div className="form-label"><label>Asset Tree Branch:</label></div>
                                    <CollapseLocationSelect
                                        className={"form-control form-control-sm"}
                                        selectName={"location"}
                                        value={+_get(filter, ["location"], 0)}
                                        onChange={(e) => {
                                            this.handleFilterChange({
                                                location: _get(e, "target.value", "")
                                            });
                                        }}
                                        emptyOptionLabel={"All"}
                                    />
                                </div>
                                <div className="form-group form-group--inline">
                                    <button
                                        className="btn btn-primary btn-sm"
                                        onClick={this.handleExport}
                                    >
                                        Export
                                    </button>
                                </div>
                                {this.filtersApplied() &&
                                    <div className="form-group form-group--inline">
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={this.reset}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <SearchSensorIntervals
                                    filter={filter}
                                    handleSearch={this.handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="block-body">
                        {loader && <div className="loader-fullscreen"><Loader/></div>}
                        <BatchIntervalsTable
                            loader={loader}
                            list={list}
                            search={filter.search}
                            location={filter.location}
                            auth={auth}
                            onNextPage={this.onNextPage}
                            hasMore={pagination.hasMore}
                            setSettingIntervalModal={this.setSettingIntervalModal}
                            updateSettings={this.handleFetch}
                            highPassModalData={highPassModalData}
                            setHighPassModalData={this.setHighPassModalData}
                        />
                    </div>
                </div>
                {settingIntervalModal.show &&
                    <BachIntervalsModal
                        onClose={() => this.setSettingIntervalModal({
                            show: false,
                            equipment: {},
                            installationPoint: {}
                        })}
                        installationPoint={settingIntervalModal.installationPoint}
                        equipment={settingIntervalModal.equipment}
                        updateSettings={this.handleFetch}
                    />
                }
                {highPassModalData.show &&
                    <HighPassModal
                        modalData={highPassModalData}
                        onClose={() => this.setHighPassModalData({
                            show: false
                        })}
                        handleFetch={this.handleFetch}
                    />
                }
            </div>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item global-search">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

class SearchSensorIntervals extends Component {
    constructor(props){
        super(props);

        this.state = {
            value: _get(props.filter, ["search"], "")
        };

        this.timeout = null;

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(value){
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }

        this.setState({
            value: value
        }, () => {
            this.timeout = setTimeout(() => {
                this.props.handleSearch(this.state.value);
            }, 300);
        });
    }

    render(){
        const {value} = this.state;
        return (
            <div style={{minWidth: "200px"}}>
                <SearchInput
                    onChange={this.handleChange}
                    disabled={false}
                    query={value}
                    placeholder="Search Sensor Intervals"
                />
            </div>
        );
    }
}

SearchSensorIntervals.propTypes = {
    filter: PropTypes.object,
    handleSearch: PropTypes.func
};

BatchIntervalsPage.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    auth: PropTypes.object,

    // LocationSelectStore
    getLocationId: PropTypes.func
};

export default withLocationSelectStore(withGlobalStore(BatchIntervalsPage));