import React, {useEffect, useReducer, useRef} from "react";
import {Modal, ValidationError} from "../shared";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import ApiEquipment from "../api/equipment";
import Helper from "../helpers/helper";
import FormValidator from "../helpers/form-validator";
import CollapseLocationSelect from "../shared/collapseLocationSelect/collapseLocationSelect";
import {get as _get} from "lodash";
import Loader from "../shared/loader/loader";
import {useDashboardListQuery} from "../hooks/api/dashboard/useDashboardListQuery";

const rules = [
    {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "name",
        method: (name, options, data) => {
            const matchingLocations = data.dashboardList.filter((item) => +item.location_id === +data.locationId);
            const isNameDuplicate = matchingLocations.some((item) => item.name.trim().toLowerCase() === name.trim().toLowerCase());

            return !isNameDuplicate;
        },
        validWhen: true,
        message: "The cloned equipment must have a unique name within the selected asset branch.",
    },
    {
        field: "locationId",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
];

const validator = new FormValidator(rules);

const initialState = {
    data: {
        name: "",
        options: {
            installationPoints: true,
            bearings: true,
            alertConditions: true,
            photos: false,
        },
        locationId: null,
        dashboardList: [],
        originalId: null,
        assetCode: null,
    },
    validation: validator.valid(),
    inProgress: false,
};

const reducer = (state, action) => ({...state, ...action});

const EquipmentEditPhotoModal = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {data, validation, inProgress} = state;
    const {equipment = {}, history} = props;

    const {data: dashboardList, isLoading: isDashboardLoading} = useDashboardListQuery();

    const inputNameRef = useRef(null);

    useEffect(() => {
        if (!isDashboardLoading) {
            dispatch({
                data: {
                    ...data,
                    name: equipment.name || "",
                    originalId: equipment.id,
                    locationId: equipment.location_id,
                    dashboardList: dashboardList.list.working.equipments,
                },
            });
        }
    }, [isDashboardLoading]);

    useEffect(() => {
        inputNameRef.current && inputNameRef.current.focus();
    }, [inputNameRef.current]);

    const updateData = (key, value) => {
        const updatedData = {...data, [key]: value};
        const updatedValidation = validator.validate(updatedData);
        dispatch({
            data: updatedData,
            validation: updatedValidation,
        });
    };

    const submit = () => {
        const validation = validator.validate(data);
        if (validation.isValid) {
            dispatch({inProgress: true});
            ApiEquipment.clone({
                name: data.name,
                options: data.options,
                locationId: data.locationId,
                originalId: data.originalId,
                assetCode: data.assetCode,
            }).then((resp) => {
                if (resp.status === "error") {
                    const validation = state.validation;
                    Object.keys(resp.errors).map((key) => {
                        validation[key].isValid = false;
                        validation[key].message = resp.errors[key];
                    });
                    dispatch({validation, inProgress: false});
                } else {
                    window.location = `/chart/${resp.equipment.id}`;
                }
            });
        } else {
            dispatch({validation});
        }
    };

    return (
        <Modal
            size="md"
            {...props}
            showModal={true}
            submitTitle="Clone"
            title="Clone Equipment"
            className="custom-modal"
            inProgress={inProgress}
            onSubmit={submit}
            onClose={() => history.push(Helper.deleteHashParams(["modal"]))}
        >
            {isDashboardLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="mb-2" />
                    <div className="form-group">
                        <label>Asset Tree Branch:</label>
                        <div>
                            <CollapseLocationSelect
                                className={"form-control"}
                                selectName={"location_id"}
                                addClassName={validation.locationId.isValid || !validation.locationId.message ? "" : " is-invalid"}
                                value={+_get(data, "locationId", null)}
                                onChange={(ev) => updateData("locationId", ev.target.value)}
                                emptyOptionLabel={"Select Location"}
                                disabled={inProgress}
                                style={{width: "auto"}}
                            />
                            <ValidationError message={validation.locationId.message} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>
                            Name: <span className="color-danger">*</span>
                        </label>
                        <input
                            ref={inputNameRef}
                            type="text"
                            className={"form-control" + (validation.name.isValid || !validation.name.message ? "" : " is-invalid")}
                            onChange={(ev) => updateData("name", ev.target.value)}
                            value={data.name}
                            disabled={inProgress}
                        />
                        <ValidationError message={validation.name.message} />
                    </div>
                    <div className="form-group">
                        <label>Asset Сode:</label>
                        <div>
                            <input
                                className="form-control"
                                type="text"
                                name="asset_code"
                                defaultValue={props.equipment.asset_code}
                                onChange={(ev) => updateData("assetCode", ev.target.value)}
                                disabled={inProgress}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Options:</label>
                        <div>
                            <label className="form-checkbox modal-checkbox">
                                <input
                                    type="checkbox"
                                    onChange={(ev) =>
                                        updateData("options", {
                                            ...data.options,
                                            installationPoints: ev.target.checked,
                                            alertConditions: !ev.target.checked ? false : data.options.alertConditions,
                                            bearings: !ev.target.checked ? false : data.options.bearings,
                                        })
                                    }
                                    checked={!!(data.options || {}).installationPoints}
                                    disabled={inProgress}
                                />{" "}
                                With installation points
                                <span />
                            </label>
                        </div>
                        <div>
                            <label className="form-checkbox modal-checkbox">
                                <input
                                    type="checkbox"
                                    onChange={(ev) =>
                                        updateData("options", {
                                            ...data.options,
                                            bearings: ev.target.checked,
                                        })
                                    }
                                    checked={!!(data.options || {}).bearings}
                                    disabled={inProgress || !(data.options || {}).installationPoints}
                                />{" "}
                                With bearings for installation points
                                <span />
                            </label>
                        </div>
                        <div>
                            <label className="form-checkbox modal-checkbox">
                                <input
                                    type="checkbox"
                                    onChange={(ev) =>
                                        updateData("options", {
                                            ...data.options,
                                            alertConditions: ev.target.checked,
                                        })
                                    }
                                    checked={!!(data.options || {}).alertConditions}
                                    disabled={inProgress || !(data.options || {}).installationPoints}
                                />{" "}
                                With alert conditions for installation points
                                <span />
                            </label>
                        </div>
                        <div>
                            <label className="form-checkbox modal-checkbox">
                                <input
                                    type="checkbox"
                                    onChange={(ev) =>
                                        updateData("options", {
                                            ...data.options,
                                            photos: ev.target.checked,
                                        })
                                    }
                                    checked={!!(data.options || {}).photos}
                                    disabled={inProgress}
                                />{" "}
                                With photos
                                <span />
                            </label>
                        </div>
                    </div>
                </>
            )}
        </Modal>
    );
};

EquipmentEditPhotoModal.propTypes = {
    equipment: PropTypes.object,
    history: PropTypes.object,
};

export default withRouter(EquipmentEditPhotoModal);
