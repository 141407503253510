import React from "react";
import PropTypes from "prop-types";
import { useIsFFTVelocityChartType } from "../highcharts/hooks/useChartTypes";

const HPFDiffLabel = ({chartType, className, pointData, chartHPF}) => {
    const isFFTTWFVelocityChart = useIsFFTVelocityChartType(chartType);

    const showHPFDifferLabel = isFFTTWFVelocityChart
        && pointData.sensor_id !== null
        && chartHPF !== pointData.settings.high_pass;

    return showHPFDifferLabel ? (
        <div className={`badge badge-warning cursor-pointer ${className}`}>
            HPF differs than original setting
        </div>
    ) : false;
};

HPFDiffLabel.propTypes = {
    chartType: PropTypes.number,
    className: PropTypes.string,
    pointData: PropTypes.object,
    chartHPF: PropTypes.number
};

export default HPFDiffLabel;
