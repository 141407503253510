import moment from "moment/moment";

const DAY_DISTANCE_FOR_LOAD = 20;
const LOAD_MONTH_DISTANCE = 2;

export const getCalendarChunkByMonth = ({date, unixFrom, unixTo}) => {
    const startDate = moment(date).utc().startOf("month").unix();
    const endDate = moment(date).utc().endOf("month").unix();

    const monthStart = moment.unix(startDate);
    const monthEnd = moment.unix(endDate);

    const differenceStart = moment.unix(unixFrom).diff(moment.unix(startDate), "days");
    const differenceEnd = moment.unix(unixTo).diff(moment.unix(endDate), "days");

    const isHigherThanNow = moment.unix(unixTo).add(DAY_DISTANCE_FOR_LOAD, "day").unix() > moment().unix();

    if (differenceStart > -DAY_DISTANCE_FOR_LOAD || (differenceEnd < DAY_DISTANCE_FOR_LOAD && !isHigherThanNow)) {
        return {
            unixFrom: monthStart.subtract(LOAD_MONTH_DISTANCE, "month").unix(),
            unixTo: monthEnd.add(LOAD_MONTH_DISTANCE, "month").unix(),
            isChanged: true,
        };
    }

    return {unixFrom, unixTo, isChanged: true};
};
