import {useModal} from "react-modal-hook";
import React from "react";
import {useChartContext} from "../../../pages/chart/highcharts/simple-new/hooks/chartHooks";
import {useChartStateStoreContext} from "../../../pages/chart/highcharts/simple-new/store/hooks/useStoreContext";
import DeleteReadingModal from "../components/delete-reading";
import {useCurrentPointsRef} from "../../../pages/chart/highcharts/simple-new/hooks/useCurrentPointsRef";
import EquipmentApi from "../../../api/equipment";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import {shallow} from "zustand/shallow";
import {useChartSelectedEquipmentStore} from "../../../stores/zustand/ChartSelectedEquipmentStore";
import {useChartSelectedRangeStore} from "../../../stores/zustand/ChartSelectedRangeStore";
import {useSimpleChartDeleteReadingsMutation} from "../../../hooks/api/simpleChartReadings/useSimpleChartDeleteReadingsMutation";
import Toast from "../../../pages/shared/toast";
import {useQueryClient} from "@tanstack/react-query";
import {TACHOMETER_API} from "../../../hooks/api/tachometer/constants";
import {SIMPLE_CHART_READING_API} from "../../../hooks/api/simpleChartReadings/constants";

export const useDeleteReadingsSimple = () => {
    const {chartProps} = useChartContext();
    const {equipmentItem} = useEquipmentByParams();
    const chartTypes = useChartTypesStore();
    const currentPoints = useCurrentPointsRef();
    const {chartType} = chartProps;
    const {mutate} = useSimpleChartDeleteReadingsMutation();
    const selectedEquipment = useChartSelectedEquipmentStore();
    const rangeSelector = useChartSelectedRangeStore();
    const queryClient = useQueryClient();

    const {bulkDeleteReadingsMode, bulkDeleteReadingsPoints, currentChart} = useChartStateStoreContext((state) => state.settings, shallow);
    const {clearBulkDeleteReadingPoints} = useChartStateStoreContext((state) => state.actions);

    const onHide = () => {
        hideModal();
        bulkDeleteReadingsPoints.length > 0 && clearBulkDeleteReadingPoints();
    };

    const submitDeleteReadingModal = (data) => {
        mutate(data, {
            onSuccess: () => {
                EquipmentApi.checkService(equipmentItem.id).then(() => {
                    data.readings.map((reading) => {
                        queryClient.refetchQueries({
                            queryKey: [SIMPLE_CHART_READING_API.GET_LIST, +reading.installationPointID, +chartType, +reading.axisID, rangeSelector],
                        });
                    });

                    queryClient.refetchQueries({queryKey: [TACHOMETER_API.GET_TACHOMETER, equipmentItem.id]});
                    bulkDeleteReadingsPoints.length > 0 && clearBulkDeleteReadingPoints();

                    hideModal();

                    Toast.success(`The reading${data.readings.length > 1 ? "s" : ""} has been deleted.`);
                });
            },
        });
    };

    const [showModal, hideModal] = useModal(() => {
        return (
            <DeleteReadingModal
                showModal={true}
                onClose={onHide}
                points={bulkDeleteReadingsMode === true ? bulkDeleteReadingsPoints : currentPoints.current}
                chartType={chartType}
                chartTypes={chartTypes}
                isBatch={bulkDeleteReadingsMode === true ? true : false}
                onSubmit={submitDeleteReadingModal}
            />
        );
    }, [bulkDeleteReadingsMode, bulkDeleteReadingsPoints, currentChart, selectedEquipment, submitDeleteReadingModal]);

    return [showModal, hideModal];
};
