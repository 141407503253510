import PropTypes from "prop-types";
import React from "react";
import "../../assets/scss/components/personal-info/personal-info.scss";


const TwoFactorAuthentication = (props) => {
    const {user} = props;

    if (user.use_2fa) {
        return enabledAuthentication();
    }

    return disabledAuthentication();
};

const enabledAuthentication = () => {
    return (
        <div>
            <div className={"enabled-auth-info"}>
                Two-Factor Authentication Enabled. Now, when logging in from a new device, we will request an entry code. You receive the entry code through the authentication app.
            </div>
            <div className="text-right">
                <button
                    type="button"
                    onClick={twoFactorAuthenticationRedirect}
                    className="btn btn-secondary btn-sm">
                    Disable Two-Factor Authentication
                </button>
            </div>
        </div>
    );
};

const disabledAuthentication = () => {
    return (
        <div>
            <div className={"disabled-auth-info"}>
                Two-Factor Authentication helps safeguard your account by requiring an additional code when logging in from a new device.
            </div>
            <div className="text-right">
                <button
                    type="button"
                    onClick={twoFactorAuthenticationRedirect}
                    className="btn btn-primary btn-sm">
                    Enable Two-Factor Authentication
                </button>
            </div>
        </div>
    );
};

const twoFactorAuthenticationRedirect = ()=> {
    return window.location.href = process.env.SSO_URL + "/2FA/settings";
};

TwoFactorAuthentication.propTypes = {
    user: PropTypes.object
};

export default TwoFactorAuthentication;
