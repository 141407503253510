import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import ImageDropLayout from "../modals/equipment/assign-points/components/image-drop-layout";
import Dropzone from "react-dropzone";
import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {get as _get} from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import "../assets/scss/imageToolbar.scss";

const ImageToolbar = (props) => {
    const {
        images,
        onUploadImages,
        onDelete,
        title,
        withDropzone,
        canEdit,
        onFlipTurnImage
        } = props;

    const imageRef = useRef(null);
    const [current, setCurrent] = useState(0);
    const [flipTurns, setFlipTurns] = useState({});
    const [isZoomed, setIsZoomed] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const showToolbarPanel = !!images.length && canEdit;

    useEffect(() => {
        initFlipTurns();
    }, [images]);


    const initFlipTurns = () => {
        const flipTurnsData = {};
        images.map((file, index) => {
            flipTurnsData[index] = _get(file, "flipturn", 0);
        });

        setFlipTurns(flipTurnsData);
    };

    const onFlipImage = (flipTurn) => {
        const flipTurnValue = (flipTurns[current] || 0) + flipTurn;

        setFlipTurns({ ...flipTurns, [current]: flipTurnValue });
        onFlipTurnImage(current, flipTurnValue);
    };

    const setZoom = () => {
        setIsZoomed(!isZoomed);
    };

    const onSetCurrent = (index) => {
        setCurrent(index);
    };

    const attachPhotos = (files) => {
        Promise.all(
            Object.keys(files).map(
                (key) =>
                    new Promise((resolve, reject) => {
                        if (files[key]) {
                            let reader = new FileReader();
                            reader.readAsDataURL(files[key]);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = (error) => reject(error);
                        } else {
                            reject("File is empty");
                        }
                    })
            )
        ).then((images) => onUploadImages(images));
    };

    const detachPhoto = () => {
        onDelete(_get(images, current));
        setCurrent(current > 0 ? current - 1 : 0);
        setDeleteAlert(false);
    };

    return (<div className="row">
        <DndProvider backend={ HTML5Backend }>
            <div className="col-md-12 text-left">
                <div className={"toolbar-image-block"}>
                    {(images.length ? <ImageDropLayout
                        flipTurns={ flipTurns }
                        imageIndex={+current}
                        images={ images }
                        setCurrent={ onSetCurrent }
                        isZoomed={ isZoomed }
                        handleZoomChange={ setZoom }
                        imageRef={imageRef}
                    /> : <div className="assign-photo-main-wrapper">
                        <div className="assign-photo-main-block">
                            <div className="assign-photo-item">
                                <div className="assign-photo">
                                    <img className="img-fluid" src="/assets/img/default-equipment-bg.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>) }
                </div>

                { showToolbarPanel && <div className={"align-btn-block"}>
                    <button
                        onClick={ () => onFlipImage(-0.25) }
                        disabled={!canEdit}
                        className="btn btn-sm btn-primary btn-margin-t-10"
                        style={ { marginRight: 5 } }
                    >
                        <i className="fa fa-undo"/> Rotate
                    </button>
                    <button
                        onClick={ () => onFlipImage(0.25) }
                        disabled={!canEdit}
                        className="btn btn-sm btn-primary btn-margin-t-10"
                        style={ { marginRight: 5 } }
                    >
                        <i className="fa fa-redo"/> Rotate
                    </button>
                    <button
                        onClick={ () => setZoom() }
                        className="btn btn-sm btn-primary btn-margin-t-10"
                        style={ { marginRight: 5 } }
                    >
                        <i className="fa fa-search-plus"/> Zoom
                    </button>
                    <button
                        onClick={() => setDeleteAlert(true)}
                        disabled={!canEdit}
                        className="btn btn-sm btn-danger btn-margin-t-10"
                    >
                        <i className="fa fa-trash"/> Delete Image
                    </button>
                </div> }
                <div className="assign-photo-list-wrapper">
                    {withDropzone &&<Dropzone
                        onDrop={attachPhotos}
                        accept="image/*"
                    >
                        { ({ getRootProps, getInputProps }) => (
                            <div { ...getRootProps() } className="assign-photo-list-item add-image-block" title={ title ?? "Add Photo"}>
                                <input { ...getInputProps() }/>
                                <button
                                    onClick={ () => {
                                    } }
                                    className="btn btn-secondary btn-circle btn-icon btn-add-image"
                                    style={ { width: "2.5rem" } }
                                >
                                    <i className="fa fa-plus"/>
                                </button>
                            </div>) }
                    </Dropzone>}
                    { images.map((image, index) => <React.Fragment key={ index }>
                        <div
                            onClick={ () => onSetCurrent(index) }
                            key={ index }
                            id={ "image-popover-" + index }
                            className={ `assign-photo-list-item ${ index === current && "active-image" }` }
                            style={ {
                                background: "#fff", transform: `rotate(${ flipTurns[index] || 0 }turn)`
                            } }
                        >
                            <img src={_get(image, "url") || image}/>
                        </div>
                    </React.Fragment>) }
                </div>
            </div>
        </DndProvider>

        {deleteAlert &&
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnBsStyle="default"
                btnSize="xs"
                title="DELETE PHOTO"
                onConfirm={detachPhoto}
                onCancel={() => setDeleteAlert(false)}
            >
                Are you sure you want to delete this photo?
            </SweetAlert>
        }

    </div>);
};

ImageToolbar.propTypes = {
    images: PropTypes.array,
    title: PropTypes.string,
    onFlipTurnImage: PropTypes.func,
    onUploadImages: PropTypes.func,
    onDelete: PropTypes.func,
    positions: PropTypes.object,
    withDropzone: PropTypes.bool,
    canEdit: PropTypes.bool,
};

export default ImageToolbar;