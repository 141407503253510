import { useEffect, useState } from "react";
import { useCurrentAlertScrolled, useCurrentAlertTarget } from "../../../stores/CurrentAlertStore";

//TODO REWORK bodyEquipmentComponent to remove hardcoded retries
export const useAlertScrollEffect = () => {
    const targetSelector = useCurrentAlertTarget();
    const isScrolled = useCurrentAlertScrolled();
    const [retries, setRetry] = useState(0);
    useEffect(() => {
        const target = document.getElementById(targetSelector);
        if(isScrolled === false && target !== null){
            target.scrollIntoView({ behavior: "instant" });
            setRetry(0);
        }else if(isScrolled === false){
            if(retries < 5){
                setTimeout(() => setRetry(retries+1), 1000);
            }
        }
    }, [targetSelector, isScrolled, retries]);
};
