import {
    get as _get,
    set as _set,
    each as _each,
    find as _find
} from "lodash";

class ActionItemHelper {

    static getOtherReasonTypeId(info) {
        const otherType = _find(_get(info, "ww_action_list_reason_groups.0"), ["name", "Other Action"]);
        return +_get(otherType, "id");
    }

    static getReasonGroups(info, sort, type) {
        const otherTypeId = this.getOtherReasonTypeId(info);

        if (sort) {
            let reasonGroups = [];

            _each(_get(info, "ww_action_list_reason_groups.0"), (parent) => {
                if (!type || type === parent.name.toLowerCase() || (type === "mote" && parent.name.toLowerCase() === "sensor")) {
                    _each(_get(info, "ww_action_list_reason_groups."+_get(parent, "id")), (children) => {
                        reasonGroups.push({
                            id: children.id,
                            name: parent.name + " - " + children.name
                        });
                    });
                }
            });

            reasonGroups.push({id: otherTypeId, name: "Other Action"});
            return reasonGroups;
        }

        let reasonGroups = {};

        _each(_get(info, "ww_action_list_reason_groups.0"), (parent) => {
            _each(_get(info, "ww_action_list_reason_groups."+_get(parent, "id")), (children) => {
                _set(reasonGroups, _get(children, "id"), _get(parent, "name") + "-" + _get(children, "name"));
            });
        });

        reasonGroups[otherTypeId] = "Other Action";

        return reasonGroups;
    }

}

export default ActionItemHelper;