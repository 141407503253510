import React, {useReducer, useState} from "react";
import {Modal} from "../../shared";
import PropTypes from "prop-types";
import IntervalSettingsTab from "../../pages/chart/aside/components/intervalSettingsTab/intervalSettingsTab";
import SweetAlert from "react-bootstrap-sweetalert";
import Helper from "../../helpers/helper";
import {withRouter} from "react-router";
const reducer = (state, action) => ({...state, ...action});

const IntervalsModal = props => {
    const {history, equipment, invalidateEquipment} = props;

    const [title, setTitle] = useState(`Sensor Intervals for ${equipment.name}`);
    // const hashParams = Helper.getHashParams();
    const initialState = {
        currentIsChanged: false,
        confirmCancel: false,
        isDataLoaded: false,
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const {currentIsChanged, confirmCancel} = state;

    const currentChanged = (changed) => {
        dispatch({currentIsChanged: changed});
    };


    const onClose = () => {
        if (currentIsChanged) {
            setConfirm(true);
        } else {
            closeModal();
        }
    };
    const setConfirm = (confirm) => dispatch({confirmCancel: confirm});
    const closeModal = () => history.push(Helper.deleteHashParams(["modal", "type", "axisId", "installationPointId", "fftAlertType"]));
    return (
        <Modal
            {...props}
            size="xl"
            title={title}
            withoutFooter={true}
            onClose={onClose}
        >
            <IntervalSettingsTab
                setModalTitle={setTitle}
                history={history}
                currentChanged={currentChanged}
                invalidateEquipment={invalidateEquipment}
            />
            {confirmCancel && (
                <SweetAlert
                    warning
                    showCancel
                    cancelBtnText="Cancel"
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title=""
                    onConfirm={closeModal}
                    onCancel={() => setConfirm(false)}
                >
                    Are you sure you want to navigate away from this page? If you proceed, ALL of your changes will be lost. Click Yes to continue, or
                    click Cancel to stay on the current page
                </SweetAlert>
            )}
        </Modal>
    );
};

IntervalsModal.propTypes = {
    onClose: PropTypes.func,
    history: PropTypes.object,
    equipment:PropTypes.object,
    invalidateEquipment: PropTypes.func
};

export default withRouter(IntervalsModal);