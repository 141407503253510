import React from "react";
import {get as _get, remove as _remove} from "lodash";
import PropTypes from "prop-types";

const groupsData = {
    alertGroup: {
        key: "subscriptionGroups",
        label: "Alert Groups",
        link: "alert-groups"
    },
    deviceAlertGroup: {
        key: "deviceSubscriptionGroups",
        label: "Device Alert Groups",
        link: "device-alert-groups"
    }
};

const UserAlertGroup = ({userSubscriptions, unsubscribe, updateUnsubscribe, inProgress, labelClass, disabled}) => {
    return (
        <UserGroup
            type={"alertGroup"}
            userSubscriptions={userSubscriptions}
            unsubscribe={unsubscribe}
            updateUnsubscribe={updateUnsubscribe}
            inProgress={inProgress}
            labelClass={labelClass}
            disabled={disabled}
        />
    );
};

UserAlertGroup.propTypes = {
    userSubscriptions: PropTypes.object,
    unsubscribe: PropTypes.array,
    updateUnsubscribe: PropTypes.func,
    inProgress: PropTypes.bool,
    labelClass: PropTypes.string,
    disabled: PropTypes.bool
};

const UserDeviceAlertGroup = ({userSubscriptions, unsubscribe, updateUnsubscribe, inProgress, labelClass, disabled}) => {
    return (
        <UserGroup
            type={"deviceAlertGroup"}
            userSubscriptions={userSubscriptions}
            unsubscribe={unsubscribe}
            updateUnsubscribe={updateUnsubscribe}
            inProgress={inProgress}
            labelClass={labelClass}
            disabled={disabled}
        />
    );
};

UserDeviceAlertGroup.propTypes = {
    userSubscriptions: PropTypes.object,
    unsubscribe: PropTypes.array,
    updateUnsubscribe: PropTypes.func,
    inProgress: PropTypes.bool,
    labelClass: PropTypes.string,
    disabled: PropTypes.bool
};

const UserGroup = ({type, userSubscriptions, unsubscribe, updateUnsubscribe, inProgress, labelClass, disabled}) => {
    const groupData = _get(groupsData, type);
    const subscriptionGroups = _get(userSubscriptions, groupData.key, []);

    const onChangeGroup = (event) => {
        const value = parseInt(_get(event, "target.value", ""));
        let newUnsubscribe = unsubscribe;

        if (newUnsubscribe.indexOf(value) === -1) {
            newUnsubscribe.push(value);
        } else {
            _remove(newUnsubscribe, (el) => el === value);
        }

        updateUnsubscribe(newUnsubscribe);
    };

    return (
        <React.Fragment>
            {subscriptionGroups.length > 0 &&
                <div className="form-group row align-items-center">
                    <label className={labelClass + " form-label pull-right-label mb-0 pr-3"}>
                        {groupData.label}:
                    </label>
                    <div className="col-10 col-lg-5">
                        <div className="form-list form-list--column">
                            {subscriptionGroups.map(group =>
                                <div className="mt-2" key={group.id} title={disabled ? "You do not have permission to change it." : ""}>
                                    <label className={"form-checkbox" + (disabled ? " not-allowed" : "")}>
                                        <input
                                            type="checkbox"
                                            disabled={inProgress || disabled}
                                            value={group.id}
                                            onChange={onChangeGroup}
                                            defaultChecked={unsubscribe.indexOf(group.id) === -1}
                                        /> {group.name} <span/>
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

UserGroup.propTypes = {
    type: PropTypes.string,
    userSubscriptions: PropTypes.object,
    unsubscribe: PropTypes.array,
    updateUnsubscribe: PropTypes.func,
    inProgress: PropTypes.bool,
    labelClass: PropTypes.string,
    disabled: PropTypes.bool
};

export {
    UserAlertGroup,
    UserDeviceAlertGroup,
};