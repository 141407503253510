import React from "react";
import ChartHelper from "../../../../../../helpers/chart";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useLockPoints} from "../../hooks/useLockPoints";
import {useChartRef} from "../../hooks/useChartRef";
import {shallow} from "zustand/shallow";
import {zoomPausedChart} from "../../../../../../helpers/holdMode";

const ZoomButton = () => {
    const chartRef = useChartRef();
    const {setZoomed, setMinMax} = useChartStateStoreContext((state) => state.actions, shallow);
    const {zoomed, valueInitMax, valueInitMin} = useChartStateStoreContext((state) => state.settings);
    const lockPoints = useLockPoints();

    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    const customResetZoom = () => {
        setZoomed(false);
        chartRef.current.chart.xAxis[0].zoom();
        chartRef.current.chart.yAxis[0].zoom();
        chartRef.current.chart.redraw();

        setMinMax({
            valueMax: +valueInitMax,
            valueMin: +valueInitMin,
        });

        chartRef.current.chart.isPointBPaused = false;
        ChartHelper.clearAdditionalPoints(chartRef.current.chart);
        lockPoints.current = [];
        if (chartRef.current.chart.holdPoints) {
            setTimeout(() => zoomPausedChart(chartRef.current), 0);
        }
    };

    if (!zoomed) {
        return <></>;
    }
    return (
        <button
            className="highcharts-reset-zoom-custom"
            onClick={customResetZoom}
            disabled={!!isLoading}
        >
            Reset Zoom
        </button>
    );
};

export default ZoomButton;
