import {ResetSortButton} from "../../shared/resetSortButton";
import React, {Component} from "react";
import PropTypes from "prop-types";
import Row from "./row";
import {get as _get} from "lodash";

const headersList = {
    "alertLevelName": {title: "Level", sort: true},
    "title": {title: "Title", sort: true},
    "axis": {title: "Axis", sort: false},
    "equipmentName": {title: "Equipment", sort: true},
    "installationPointName": {title: "Installed On", sort: true},
    "sensor": {title: "Sensor", sort: true},
    "action": {title: "Action Type", sort: false},
    "created_at": {title: "Action At", sort: true},
    "user_name": {title: "Action By", sort: true},
    "data": {title: "Data", sort: false},
    "actions": {title: "",  additionalClasses: "table-buttons-th d-flex", sort: false, component: ResetSortButton}
};

class Table extends Component
{
    render() {
        const {auth, list, sort, onSortChange, query} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <div className="table-scroll-wrapp tachometers">
                <table className="table table-hover tl-fixed">
                    <thead>
                        <tr>
                            {Object.keys(headersList).map(key => {
                                let component = "";
                                const RowComponent = _get(headersList, [key, "component"], false);
                                if (RowComponent) {
                                    component = <RowComponent sort={sort} resetSort={() => onSortChange("")}/>;
                                }
                                return (
                                    listLen && headersList[key].sort
                                        ? (
                                            <th className={headersList[key].additionalClasses || ""} key={key} onClick={() => onSortChange(key)}>
                                                <div className="title">
                                                    <span>{headersList[key].title}</span>
                                                    {sort.field === key ? <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")}/> : <i className={"fa fa-sort"} />}
                                                </div>
                                            </th>
                                        )
                                        : <th className={headersList[key].additionalClasses || ""} width={_get(headersList[key], "width", "")} key={key}>
                                            <span className="title">{headersList[key].title}</span> {component}
                                        </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {list.length > 0
                            ?
                            list.map((item) =>
                                <Row
                                    query={query}
                                    key={item.id}
                                    item={item}
                                    auth={auth}
                                />
                            )
                            :
                            <tr>
                                <td colSpan={listLen} className="text-center text-info">
                                    {query !== "" ? "No items match your search." : "No logs were found."}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

Table.propTypes = {
    auth: PropTypes.object,
    list: PropTypes.array,
    sort: PropTypes.object,
    onSortChange: PropTypes.func,
    query: PropTypes.string
};

export default Table;