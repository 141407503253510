import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal} from "reactstrap";
import {ValidationError} from "../../../shared";
import {
    get as _get,
    set as _set,
    omit as _omit
} from "lodash";
import EquipmentProfileApi from "../../../api/equipmentProfile";
import {EQUIPMENT_SERVICE} from "../../../constants/constants";
import Helper from "../../../helpers/helper";
import EquipmentImageDropzone from "../../../shared/equipment/imagesDropzone";
import InstallationPointColorApi from "../../../api/installationPointColor";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import SelectWrapper from "../../../helpers/select-wrapper";

class CreateEquipmentModal extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            equipment: {
                name: "",
                locationId: "",
                assetCode: "",
                service: EQUIPMENT_SERVICE.SELF,
                images: []
            },
            formErrors: {},
            colors: [],
            points: [],
            loader: true,
            inProgress: false
        };
    }

    componentDidMount() {

        Promise.all([
            this.fetchProfile(),
            this.fetchPointsColors()
        ]).then(() => {
            this.setState({loader: false});
        });
    }

    fetchProfile = () => {
        const {profileId} = this.props;

        return EquipmentProfileApi
            .view(profileId)
            .then((response) => {
                if (response) {
                    const {profile = {}} = response;
                    this.setState({points: _get(profile, "installationPoints", [])});
                }
            });
    }

    fetchPointsColors = () => {
        return InstallationPointColorApi
            .list()
            .then((colors) => {
                this.setState({
                    colors: colors.list || [],
                });
            });
    }

    structurizeOptions(list, options = []) {
        (list || []).map((item) => {
            options.push({id: item.id, name: "&nbsp;".repeat(2 * item.level) + item.name});
            if ((item.children || []).length) {
                this.structurizeOptions(item.children, options);
            }
        });
        return options;
    }

    onChange = (event) => {
        let equipment = {...this.state.equipment};
        const key = _get(event, "target.name");
        const value = _get(event, "target.value");

        this.removeError(key);

        _set(equipment, key, value);

        this.setState({equipment});
    }

    removeError = (key) => {
        const oldFormErrors = {...this.state.formErrors};

        const formErrors = _omit(oldFormErrors, key);

        this.setState({formErrors});
    }

    onCreate = () => {
        const {equipment} = this.state;
        const {history, profileId} = this.props;
        this.setState({inProgress: true});

        return EquipmentProfileApi
            .createEquipment(profileId, equipment)
            .then((response) => {
                if (response.result && response.status === "ok") {
                    history.push("/chart/" + _get(response, "result.id"));
                }
                this.setState({inProgress: false});
            })
            .catch(response => {
                if (response.status === 422) {
                    this.setState({formErrors: response.errors || {}});
                }
                this.setState({inProgress: false});
            });
    }

    render() {
        const {equipment,  formErrors, colors, points, inProgress} = this.state;
        const {onClose, auth, user} = this.props;

        return (
            <React.Fragment>
                <Modal
                    isOpen={true}
                    toggle={onClose}
                    centered={false}
                    className={"profile-alerts-modal create-equipment"}
                >
                    <div>
                        <div className={"profile-modal-header modal-header"}>
                            <div className={"title"}>Add Equipment</div>
                            <div className={"close"} onClick={onClose}/>
                        </div>

                        <hr/>

                        <div className={"profile-modal-body"}>
                            <div className="sub-title sub-title--blue mb-2"><span>Equipment Information</span></div>
                            <div className="form-group">
                                <label className={"form-label"}>Name <span className="color-danger">*</span></label>
                                <input
                                    className={"form-control" + (_get(formErrors, "name") ? " is-invalid" : "")}
                                    type="text"
                                    placeholder="Enter Equipment Name"
                                    name="name"
                                    value={_get(equipment, "name")}
                                    onChange={this.onChange}
                                />
                                <ValidationError message={_get(formErrors, "name")}/>
                            </div>
                            <div className="form-group">
                                <label className={"form-label"}>Asset Code</label>
                                <input
                                    className={"form-control" + (_get(formErrors, "assetCode") ? " is-invalid" : "")}
                                    name="assetCode"
                                    type="text"
                                    placeholder="Enter Equipment Asset Code"
                                    value={_get(equipment, "assetCode")}
                                    onChange={this.onChange}
                                />
                                <ValidationError message={_get(formErrors, "assetCode")}/>
                            </div>
                            <div className="form-group">
                                <label className={"form-label"}>Asset Tree Branch <span className="color-danger">*</span></label>
                                <CollapseLocationSelect
                                    className={"form-control"  + (_get(formErrors, "locationId") ? " is-invalid" : "")}
                                    selectName={"locationId"}
                                    value={+_get(equipment, "locationId", null)}
                                    onChange={this.onChange}
                                    emptyOptionLabel={"Select Asset Tree Branch"}
                                    allowedLocationIds={_get(user, "allowedLocationIds", [])}
                                />
                                <ValidationError message={_get(formErrors, "locationId")}/>
                            </div>
                            {auth.userCan("superAdmin") && auth.isHybrid &&
                                <div className="form-group">
                                    <label className={"form-label"}>Service Type <span className="color-danger">*</span></label>
                                    <SelectWrapper
                                        name="service"
                                        className={(_get(formErrors, "service") ? " is-invalid" : "")}
                                        onChange={this.onChange}
                                        value={_get(equipment, "service")}
                                    >
                                        {Object.values(EQUIPMENT_SERVICE).map(service => (
                                            <option key={service} value={service}>
                                                {Helper.capitalize(service)}
                                            </option>
                                        ))}
                                    </SelectWrapper>
                                    <ValidationError message={_get(formErrors, "service")}/>
                                </div>
                            }

                            <div className="sub-title sub-title--blue mb-2"><span>Set Up Equipment Pictures</span></div>

                            <EquipmentImageDropzone
                                images={_get(equipment, "images", [])}
                                onChange={this.onChange}
                                colors={colors}
                                points={points}
                            />
                        </div>

                        <div className={"profile-modal-bottom"}>
                            <div
                                onClick={onClose}
                                className={"btn btn-secondary mr-4" + (inProgress ? " disabled" : "")}
                            >
                                Cancel
                                {!!inProgress && <i className="fa fa-spinner ml-2" />}
                            </div>
                            <div
                                className={"btn btn-primary" + (inProgress ? " disabled" : "")}
                                onClick={this.onCreate}
                            >
                                Save
                                {!!inProgress && <i className="fa fa-spinner ml-2" />}
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

CreateEquipmentModal.propTypes = {
    profileId: PropTypes.number,
    onClose: PropTypes.func,
    history: PropTypes.object,
    auth: PropTypes.object,
    user: PropTypes.object
};

export default CreateEquipmentModal;