import Helper from "../../helpers/helper";
import {useEffect, useRef} from "react";

export const useModalHistory = (modalName, showModal = () => {}, additionalHashParams = {}) => {
    const additionalParamsRef = useRef({});
    useEffect(() => {
        const isCurrentModal = Helper.getHashParams().modal === modalName;
        if (isCurrentModal) {
            showModal();
        }
    }, []);

    const pushHistory = (hashParams) => {
        additionalParamsRef.current = hashParams;
        window.history.pushState({modal: modalName}, "", Helper.setHashParams({modal: modalName, ...additionalHashParams, ...hashParams}));
    };

    const deleteHistory = () => {
        const additionalParamsName = Object.keys(additionalHashParams);

        window.history.pushState(
            {modal: modalName},
            "",
            Helper.deleteHashParams(["modal", ...additionalParamsName, ...Object.keys(additionalParamsRef.current || {})])
        );
        additionalParamsRef.current = {};
    };

    return {pushHistory, deleteHistory};
};
