import {useChartStateStoreContext} from "../store/hooks/useStoreContext";
import {useGetFFTReadings, useGetPointReadingParamsByDateTimeBatch} from "../../../../../hooks/api/fftReadings/ReadingsQuery";
import {defaults as defaultColors} from "plotly.js/src/components/color/attributes";
import {shallow} from "zustand/shallow";
import {FREQUENCY_TYPES} from "../../../../../constants/constants";
import ChartHelper from "../../../../../helpers/chart";
import Helper from "../../../../../helpers/helper";
import moment from "moment/moment";
import useUserProfile from "../../../../../hooks/api/Global/useUserProfile";
import FrequencyConverter from "../../../../../helpers/frequency-converter";
import {useIsDarkTheme} from "../../../../../hooks/helpers/theme";
import {useSpeedSettingsFft} from "../../../../../hooks/helpers/useSpeedSettingsFft";
import {usePointData} from "../../hooks/usePointsData";
import {useEffect, useMemo} from "react";
import {useIsImpactVue} from "../../hooks/useChartTypes";
import {useInterpolationSyncStoreByPoint} from "../../store/sync/InterpolationSuncStore";
import {useHighPassFilterSyncStoreByPoint} from "../../store/sync/HighPassFilterSyncStore";
import {useSelectedAxis} from "./helpers/useSelectedAxis";
import {useUserFftChartFrequency} from "../../../../../stores/UserLocalSettingsStore";
import {useAlertConditionListQuery} from "../../../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {getColorScale} from "../helpers/watrefall";

export const useDrawWaterfallChartEffect = () => {
    const options = {
        data: [],
        layout: {},
        isSuccess: false,
        maxFrequency: 0,
        maxAmplitude: 0,
    };

    const isDarkTheme = useIsDarkTheme();

    const isShowZoneOnWaterfall = useChartStateStoreContext((state) => state.settings.isShowZoneOnWaterfall);
    const {setLoader} = useChartStateStoreContext((state) => state.actions, shallow);
    const currentWaterfallDates = useChartStateStoreContext((state) => state.settings.currentWaterfallDates, shallow);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);
    const currentFrequency = useUserFftChartFrequency();
    const trend3dFrequency = useChartStateStoreContext((state) => state.settings.trend3dFrequency);
    const show3dFrom = useChartStateStoreContext((state) => state.settings.show3dFrom);
    const show3dTo = useChartStateStoreContext((state) => state.settings.show3dTo);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint, shallow);
    const pointData = usePointData(selectedPoint);
    const axisIds = useSelectedAxis();

    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList, shallow);

    const isInterpolation = useInterpolationSyncStoreByPoint(pointData.id);
    const highpassFilter = useHighPassFilterSyncStoreByPoint(pointData.id, pointData);

    const {alertConditions} = useAlertConditionListQuery(chartType, selectedPoint, selectedPoint);

    const axis = useSelectedAxis();

    const {data: user} = useUserProfile();
    const {params: readingsParams} = useGetPointReadingParamsByDateTimeBatch(
        pointData.equipment_id,
        pointData.id,
        axisIds[0],
        currentWaterfallDates,
        isImpactVue,
        highpassFilter,
        isInterpolation
    );

    const {data: readings, isSuccess, isFetched, isLoading} = useGetFFTReadings(chartType, readingsParams);

    useEffect(() => {
        if (isLoading) {
            setLoader(true);
        }
    }, [isLoading]);

    const {currentSpeed} = useSpeedSettingsFft(readings[0], selectedPoint);
    const availableColors = [...defaultColors];
    const convert = (y) => {
        if ((currentSpeed === 0 || isNaN(currentSpeed)) && currentFrequency === FREQUENCY_TYPES.ORDERS) {
            return y;
        }
        if (currentFrequency !== FREQUENCY_TYPES.HZ) {
            return FrequencyConverter.fromHz(y, currentSpeed).toType(currentFrequency).value;
        }
        return y;
    };

    const getFromTo = () => {
        let from = 0;
        let to = Infinity;

        if (show3dFrom.length) {
            from = parseFloat(show3dFrom);
        }

        if (show3dTo.length) {
            to = parseFloat(show3dTo);
        }

        from = Math.max(from, 0);
        to = Math.max(to, 0);

        if (from === to) {
            return [0, to || Infinity];
        } else if (from > to) {
            return [to, from];
        } else {
            return [from, to];
        }
    };

    useEffect(() => {
        isSuccess === true && isFetched && setLoader(false);
    }, [isSuccess, readings]);

    return useMemo(() => {
        if (!isSuccess) {
            return options;
        }
        let tickvals = [],
            ticktext = [];

        const sortedByDate = readings.sort((a, b) => {
            const firstDate = new Date(a.timestamp);
            const secondDate = new Date(b.timestamp);
            return firstDate - secondDate;
        });

        sortedByDate.forEach((readingData, seriaIndex) => {
            const seriesTimestamps = Helper.getDurationTimestamp(readingData.timestamp, user.timezone);

            const x = [];
            const y = [];
            const z = [];

            const seriesName = moment.utc(readingData.timestamp).format(Helper.getUserShortDateFormat(user));
            let [from, to] = getFromTo();

            let maxZValue = 0;

            readingData.data.forEach((readingItem, index) => {
                let freq = readingItem[0];
                let amp = readingItem[1];

                if (amp > maxZValue) {
                    maxZValue = amp;
                }

                freq = convert(freq);

                options.maxFrequency = Math.max(options.maxFrequency, freq);

                if (freq < from) {
                    const nextReading = readingData.data[index + 1];
                    if (nextReading) {
                        let [nextFreq, nextAmp] = nextReading;
                        nextFreq = convert(nextFreq);
                        if (nextFreq > from) {
                            [freq, amp] = ChartHelper.getPointBetween([freq, amp], [nextFreq, nextAmp], from);
                        } else {
                            return;
                        }
                    } else {
                        return;
                    }
                }
                if (freq > to) {
                    const prevReading = readingData.data[index - 1];
                    if (prevReading) {
                        let [prevFreq, prevAmp] = prevReading;
                        prevFreq = convert(prevFreq);
                        if (prevFreq < to) {
                            [freq, amp] = ChartHelper.getPointBetween([prevFreq, prevAmp], [freq, amp], to);
                        } else {
                            return;
                        }
                    } else {
                        return;
                    }
                }

                options.maxAmplitude = Math.max(options.maxAmplitude, amp);

                x.push(seriaIndex);
                y.push(freq);
                z.push(amp);
            });

            tickvals.push(seriaIndex);
            ticktext.push(seriesTimestamps);
            let color = availableColors.shift();
            options.data.push({
                x,
                y,
                z,
                type: "scatter3d",
                mode: "lines",
                line: {
                    color: isShowZoneOnWaterfall ? z : color,
                    colorscale: getColorScale(alertConditions, maxZValue, axis),
                },
                marker: {
                    symbol: "diamond",
                },
                name: `${seriesTimestamps} - ${seriesName}`,
                meta: {
                    categoryName: seriesName,
                },
                visible: true,

                // templating reference: https://plotly.com/javascript/reference/scatter3d/#scatter3d-hovertemplate
                hovertemplate: [
                    `Frequency: <b>%{y:.2f}${currentFrequency === FREQUENCY_TYPES.ORDERS ? "x" : ""}</b> ${currentFrequency}`,
                    `<span style="color:${color};">%{data.name}: </span> %{z:.${seriesList.precision}f} ${seriesList.units}`,
                    "<extra></extra>",
                ].join("<br>"),
            });
        });

        options.layout = {
            margin: {
                l: 0,
                r: 0,
                t: 0,
                b: 0,
            },
            scene: {
                xaxis: {
                    title: "",
                    nticks: 10,
                    tickmode: "array",
                    tickvals,
                    ticktext,
                },
                yaxis: {
                    title: "",
                    ticksuffix: currentFrequency === FREQUENCY_TYPES.ORDERS ? "x" : "",
                },
                zaxis: {
                    title: "",
                },
            },
            legend: {
                orientation: "h",
                y: 0,
            },
            hoverlabel: {
                bgcolor: "white",
            },
            autosize: true,
        };

        if (isDarkTheme === "true") {
            options.layout.scene.bgcolor = "#3a3934";
            options.layout.scene.xaxis.color = "#ccc";
            options.layout.scene.yaxis.color = "#ccc";
            options.layout.scene.zaxis.color = "#ccc";
            options.layout.bgcolor = "#3a3934";
            options.layout.paper_bgcolor = "#3a3934";
            options.layout.plot_bgcolor = "#3a3934";

            options.layout.legend = {
                ...options.layout.legend,
                bgcolor: "#3a3934",
                font: {
                    color: "#ccc",
                },
            };
        }

        return options;
    }, [isSuccess, currentWaterfallDates, show3dFrom, show3dTo, readings, trend3dFrequency, readingsParams, isShowZoneOnWaterfall]);
};
