import ChartHelper from "../helpers/chart";

export const CHART_CONFIG = {
    lang: {
        contextButtonTitle: "Print and download options",
        noData: "There is no data for the selected period.",
    },
    title: {
        text: null,
    },
    annotations: [],
    chart: {
        animation: false,
        // zoomType: "x",
        wrapZoomType: "x",
        resetZoomButton: {
            theme: {
                style: {
                    display: "none",
                },
            },
        },
        panning: false,
        backgroundColor: "#FFFFFF",
        // height: window.innerHeight * 0.5,
        events: {},
        selectionMarkerFill: "rgba(227, 187, 49, 0.22)",
        marginTop: 30,
    },
    credits: {
        enabled: false,
    },
    series: [],
    plotOptions: {
        line: {
            lineWidth: 1,
            states: {
                hover: {
                    lineWidth: 1,
                },
            },
        },
        series: {
            states: {
                inactive: {
                    opacity: 1,
                },
            },
            animation: false,
            connectNulls: false,
            events: {
                // afterDrawTracker: function () { // fix hover points on multiple series
                //     this.tracker && this.tracker.on("mouseover", function () {});
                //     this.markerGroup && this.markerGroup.on("mouseover", function () {});
                // }
            },
            point: {
                events: {
                    //     Binds events in hook UseChartEventEffect.js
                },
            },
        },
    },
    yAxis: [
        {
            startOnTick: false,
            endOnTick: false,
            opposite: false,
            title: {
                text: "",
                margin: 5,
            },
            plotLines: [],
            index: 0,
        },
        {
            opposite: true,
            title: {
                text: null,
            },
            index: 1,
        },
    ],
    xAxis: {
        ordinal: false,
        crosshair: true,
        type: "datetime",
        events: {
            setExtremes: ChartHelper.syncExtremes,
        },
        plotLines: [],
        range: null,
    },
    tooltip: {
        positioner: ChartHelper.tooltipPositioner,
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        animation: false,
        shape: "rect",
        useHTML: true,
        shared: true,
        style: {
            pointerEvents: "auto",
        },
    },
    legend: {
        enabled: false,
        itemStyle: {
            color: "",
        },
    },
    exporting: {
        allowHTML: true,
        buttons: {
            contextButton: {
                menuItems: ["printChart", "downloadPNG", "downloadCSV"],
            },
            help: {
                className: "charts-help-button",
                symbol: "text:\uf1c6", //fa-expand,
                symbolX: 14,
                symbolY: 14,
                symbolStrokeWidth: 1,
                titleKey: "helpButtonTitle",
            },
        },
        printMaxWidth: 1400,
        chartOptions: {
            xAxis: {
                plotLines: [],
            },
        },
    },
};
