import React, {useEffect, useState} from "react";
import {Modal} from "../shared";
import FormValidator from "../helpers/form-validator";
import {ValidationError} from "../shared";
import PropTypes from "prop-types";
import LocationApi from "../api/location";
import SearchSelect from "../pages/shared/search-select";

import {
    get,
    filter as _filter,
    each as _each,
    includes as _includes,
    find as _find
} from "lodash";

const rules = [
    {
        field: "name",
        method: name => !!name.replace(/<[^>]*>/gi, "").replace(/[\s?&nbsp;]+/, "").length,
        validWhen: true,
        message: "This field is required. "
    },
    {
        field: "name",
        method: (name, options, state, auxiliaryComponent) => {
            return validateAssetTreeBranchName(state, auxiliaryComponent);
        },
        validWhen: true,
        message: "A location with this name already exists for this parent branch."
    },
    {
        field: "parentId",
        method: parentId => +parentId === 0,
        validWhen: false,
        message: "This field is required. "
    }
];

const validateAssetTreeBranchName = (state, auxiliaryComponent) => {
    const {locationList, locationId} = auxiliaryComponent;
    let {name, parentId} = state;
    name = name.trim().toLowerCase();

    if (name) {
        const isLocation = _find(locationList, function(obj) {
            const locationName = obj.name.replace(/<[^>]*>/gi, "").replace(/[\s?&nbsp;]+/, "").toLowerCase();
            if (+obj.parentId === +parentId && +obj.id !== locationId && (name === locationName)) {
                return true;
            }});

        return !isLocation;
    }

    return true;
};

const LocationModal = (props) => {

    const validator = new FormValidator(rules);

    const [locationList, setLocationList] = useState([]);
    const [blackList, setBlackList] = useState([]);
    const [inProgress, setInProgress] = useState(props.inProgress);
    const [validation, setValidation] = useState(validator.valid());

    const [data, setData] = useState({
        parentId: 0,
        name: "",
    });

    useEffect(() => {
        const {locationId, list = []} = props;

        if (list.length) {
            const locations = drawOptions(list);
            setLocationList(locations);
            setInProgress(false);

            updateBlackList(locations);
        } else if (locationId) {
            Promise
                .all([
                    LocationApi.get(locationId),
                    LocationApi.list()
                ])
                .then(([{item}, {list}]) => {
                    const locations = drawOptions(_filter(get(list, "0.children") || [], {facility_id: item.facility_id}));

                    setData(Object.assign({}, data, item, {parentId: item.parent ? item.parent.id : 0}));
                    setLocationList(locations);
                    setInProgress(false);
                    updateBlackList(locations);
                });

        } else {
            LocationApi.list().then(({list}) => {
                const locations = drawOptions(get(list, "0.children") || []);

                setLocationList(locations);
                setInProgress(false);
                updateBlackList(locations);
            });
        }
    }, []);


    const onChange = (e) => {
        const {locationId} = props;
        const getData = Object.assign({}, data, {[get(e, "target.name", "")]: get(e, "target.value", "")});

        setData(getData);
        const auxiliaryComponent = {
            locationList: locationList,
            locationId:locationId
        };

        setValidation(validator.validate(getData, get(e, "target.name", ""), auxiliaryComponent));
    };


   const drawOptions = (list, storage = [], parentId = null) => {
        (list || []).map((item) => {
            storage.push({
                id: item.id,
                parentId: parentId,
                name: "&nbsp;".repeat(2 * item.level) + item.name
            });
            if ((item.children || []).length) {
                drawOptions(item.children, storage, item.id);
            }
        });
        return storage;
    };


    const onSubmitModal = () => {
        setInProgress(true);

        const validation = validator.validate(data);
        setValidation(validation);

        validation.isValid
            ? props.onSubmit(data)
            : setInProgress(false);
    };

    const updateBlackList = (locations) => {
        const {locationId, user} = props;
        let blackList = [+get(data, "parentId") !== 0 ? locationId : null];

        _each(locations, (location) => {
            if (!user.all_facilities && !_includes(user.allowedLocationIds, location.id)) {
                blackList.push(location.id);
            }
        });

        setBlackList(blackList);
    };

    return (
        <Modal
            {...props}
            disableSubmit={!validation.isValid}
            onSubmit={onSubmitModal}
            inProgress={inProgress}
        >
            <div className="form-group row">
                <label className={"col-3 text-right form-label"} htmlFor="">Parent:</label>
                <div className="col-9">
                    <SearchSelect
                        blackList={blackList}
                        list={locationList}
                        name={"parentId"}
                        htmlContent={true}
                        optName={"name"}
                        valueKey={"id"}
                        defaultValue={data.parentId}
                        onChange={onChange}
                        disabled={(+data.parentId === 0 && props.locationId) ? true : inProgress}
                        validator={validation}
                    />
                    <ValidationError message={validation.parentId.message}/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-3 text-right form-label">Name:</label>
                <div className="col-9">
                    <input
                        className={"form-control" + (validation.name.isValid || !validation.name.message ? "" : " is-invalid")}
                        type="text"
                        name="name"
                        value={data.name}
                        onChange={onChange}
                        disabled={inProgress}
                    />
                    <ValidationError message={validation.name.message}/>
                </div>
            </div>
        </Modal>
    );
};

LocationModal.propTypes = {
    locationId: PropTypes.number,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    showModal: PropTypes.bool,
    inProgress: PropTypes.bool,
    customStyles: PropTypes.object,
    children: PropTypes.any,
    title: PropTypes.string,
    submitTitle: PropTypes.string,
    cancelTitle: PropTypes.string,
    list: PropTypes.array,
    user: PropTypes.object
};

export default LocationModal;