import {useAxisSyncStoreActions} from "../../../store/sync/axisSyncStore";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";

export const useToggleActiveSeries = () => {
    const {toggleActiveSeries} = useAxisSyncStoreActions();
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const {chartModeList} = useChartStateStoreContext((state) => state.factory, shallow);
    const isWaterfall = chartMode === chartModeList.WATERFALL || chartMode === chartModeList.STACKED_WATERFALL;

    return (axis) => toggleActiveSeries(selectedPoint, axis, isWaterfall);
};
