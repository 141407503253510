import React, {Component, createRef} from "react";
import {Modal} from "../../../shared";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import Dropzone from "react-dropzone";
import Helper from "../../../helpers/helper";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";
import ImageDropLayout from "./components/image-drop-layout";
import InstPointPopover from "./components/inst-point-popover";
import SensorLocationBlock from "./components/sensor-location-block";
import {has as _has} from "lodash";
import "../../../assets/scss/components/modals/equipments/equipments.scss";

class CreateModal extends Component {
    constructor(props) {
        super(props);
        this.imageRef = createRef();
        this.state = {
            onGoFromImage: false,
            inProgress: false,
            openPopoverIndex: null,
        };
    }

    onOpenPopover = (index) => {
        this.setState({openPopoverIndex: index});
    };

    handleImageClick = (e, index) => {
        e.preventDefault();
        if (e.type === "click") {
            this.onSetCurrent(index);
        } else if (e.type === "contextmenu") {
            this.onOpenPopover(index);
        }
    };

    handleKeyDownEvents = ({code}) => {
        let {assignPointsModal, images} = this.props;

        if (code === "ArrowRight") {
            this.onSetCurrent(++assignPointsModal.current > images.length - 1 ? 0 : assignPointsModal.current);
        } else if (code === "ArrowLeft") {
            this.onSetCurrent(--assignPointsModal.current < 0 ? images.length - 1 : assignPointsModal.current);
        }
    };

    componentDidMount() {
        window.addEventListener("keydown", this.handleKeyDownEvents);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeyDownEvents);
    }

    onSetCurrent = (index) => {
        const {assignPointsModal, positions, onSetCurrent} = this.props;
        const {current} = assignPointsModal;

        if (!positions[current]) {
            this.setState({onGoFromImage: () => this.setState({onGoFromImage: false}, onSetCurrent(index))});
        } else {
            onSetCurrent(index);
        }
    };

    setZoom = () => {
        this.setState({isZoomed: !this.state.isZoomed});
    };

    render() {
        const {onGoFromImage, inProgress, openPopoverIndex, isZoomed} = this.state;
        const {
            auth,
            assignPointsModal,
            images,
            positions,
            flipTurns = {},
            onDrop,
            onRemove,
            onFlipFile,
            onSetPosition,
            onClose,
            setCurrentInstPointIndex,
            imageInstallationPoint,
        } = this.props;

        const points = {...this.props.points, 999: {id: 999, name: "Equipment Photo"}};
        const colors = {...this.props.colors, 999: "#ffcd05"};

        const {open, current} = assignPointsModal;

        if (!auth.userCan("editEquipment")) {
            return "";
        }

        return (
            <Modal
                showModal={open}
                className="custom-modal assign-points-modal"
                title="Equipment Photos"
                size="xl"
                onClose={() => {
                    if (!positions[current]) {
                        this.setState({onGoFromImage: () => this.setState({onGoFromImage: false}, onClose)});
                    } else {
                        onClose();
                    }
                }}
                withoutFooter={true}
                inProgress={inProgress}
            >
                <div className="row">
                    <DndProvider backend={HTML5Backend}>
                        <div className="col-md-8 text-left">
                            {images.length ? (
                                <ImageDropLayout
                                    colors={colors}
                                    points={points}
                                    flipTurns={flipTurns}
                                    positions={positions}
                                    imageIndex={+current}
                                    images={images}
                                    setPosition={onSetPosition}
                                    setCurrent={this.onSetCurrent}
                                    isZoomed={isZoomed}
                                    handleZoomChange={this.setZoom}
                                    imageRef={this.imageRef}
                                />
                            ) : (
                                <div className="assign-photo-main-wrapper">
                                    <div className="assign-photo-main-block">
                                        <div className="assign-photo-item">
                                            <div className="assign-photo">
                                                <img
                                                    className="img-fluid"
                                                    src="/assets/img/default-equipment-bg.png"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!!images.length && (
                                <div style={{textAlign: "center"}}>
                                    <button
                                        onClick={() => onFlipFile(current)}
                                        className="btn btn-sm btn-primary"
                                        style={{marginRight: 5}}
                                    >
                                        <i className="fa fa-undo" /> Rotate
                                    </button>
                                    <button
                                        onClick={() => onFlipFile(current, true)}
                                        className="btn btn-sm btn-primary"
                                        style={{marginRight: 5}}
                                    >
                                        <i className="fa fa-redo" /> Rotate
                                    </button>
                                    <button
                                        onClick={() => this.setZoom()}
                                        className="btn btn-sm btn-primary"
                                        style={{marginRight: 5}}
                                    >
                                        <i className="fa fa-search-plus" /> Zoom
                                    </button>
                                    <button
                                        onClick={() => onRemove(current)}
                                        className="btn btn-sm btn-danger"
                                    >
                                        <i className="fa fa-trash" /> Delete Image
                                    </button>
                                </div>
                            )}
                            <div className="assign-photo-list-wrapper">
                                <Dropzone
                                    onDrop={onDrop}
                                    accept="image/*"
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <div
                                            {...getRootProps()}
                                            className="assign-photo-list-item add-image-block"
                                            title={"Add Equipment Photo"}
                                        >
                                            <input {...getInputProps()} />
                                            <button
                                                className="btn btn-secondary btn-circle btn-icon btn-add-image"
                                                style={{width: "2.5rem"}}
                                            >
                                                <i className="fa fa-plus" />
                                            </button>
                                        </div>
                                    )}
                                </Dropzone>
                                {images.map((image, index) => (
                                    <React.Fragment key={index}>
                                        <div
                                            onClick={(e) => this.handleImageClick(e, index)}
                                            onContextMenu={(e) => this.handleImageClick(e, index)}
                                            key={index}
                                            id={"image-popover-" + index}
                                            className={`assign-photo-list-item ${index === current && "active-image"}`}
                                            style={{background: "#fff", transform: `rotate(${flipTurns[index] || 0}turn)`}}
                                        >
                                            <img src={image} />
                                        </div>
                                        <InstPointPopover
                                            points={points}
                                            colors={colors}
                                            setCurrentInstPointIndex={setCurrentInstPointIndex}
                                            currentInstPointIndex={imageInstallationPoint[index]}
                                            imageIndex={index}
                                            openPopoverIndex={openPopoverIndex}
                                            toggle={() => this.onOpenPopover(null)}
                                        />
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div className="sensors-marker-list col-md-4">
                            <span className="secondary-text mb-3">
                                <i className="fa fa-info-circle mr-1" /> Drag the marker to the location of the installation point
                            </span>
                            <div className="mt-2">
                                {_has(positions, [current, 999]) ? (
                                    <div
                                        className="sensors-location"
                                        style={{opacity: 0.5}}
                                    >
                                        <span
                                            className="index-icon-block"
                                            style={{backgroundColor: "#ffcd05"}}
                                        >
                                            <i
                                                className="fa fa-home"
                                                style={{margin: 0, fontSize: 12}}
                                            />
                                        </span>
                                        Equipment Photo
                                        <span
                                            className="pull-right cursor-pointer"
                                            onClick={() => onSetPosition({imageIndex: current, pointIndex: 999, clear: true})}
                                        >
                                            <i className="fa fa-times-circle" />
                                        </span>
                                    </div>
                                ) : (
                                    <SensorLocationBlock
                                        setPosition={onSetPosition}
                                        positions={positions}
                                        pointIndex={999}
                                        imageIndex={+current}
                                        name="Equipment Photo"
                                        color="#ffcd05"
                                        imageRef={this.imageRef}
                                    />
                                )}
                                {Object.keys(points).map(
                                    (index) =>
                                        +index !== 999 &&
                                        points[index].name &&
                                        (images.length ? (
                                            _has(positions, [current, index]) ? (
                                                <div
                                                    key={index}
                                                    className="sensors-location d-flex align-items-center justify-content-between"
                                                >
                                                    <div className={"d-flex"}>
                                                        <label
                                                            className="form-checkbox"
                                                            title={"Assign pictures at sensor level"}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                onChange={(e) => {
                                                                    setCurrentInstPointIndex(
                                                                        current,
                                                                        Helper.getInputValue(e.target) === "1" ? index : null
                                                                    );
                                                                }}
                                                                checked={imageInstallationPoint[current] === index}
                                                            />
                                                            <span />
                                                        </label>
                                                        <span
                                                            className="index-icon-block"
                                                            style={{backgroundColor: colors[index]}}
                                                        >
                                                            {+index + 1}
                                                        </span>
                                                        <span
                                                            className="point-name"
                                                            style={{opacity: 0.5}}
                                                        >
                                                            <span>{points[index].name}</span>
                                                        </span>
                                                    </div>
                                                    <span
                                                        className="pull-right cursor-pointer"
                                                        onClick={() => onSetPosition({imageIndex: current, pointIndex: index, clear: true})}
                                                        style={{opacity: 0.5}}
                                                    >
                                                        <i className="fa fa-times-circle" />
                                                    </span>
                                                </div>
                                            ) : (
                                                <SensorLocationBlock
                                                    key={index}
                                                    setPosition={onSetPosition}
                                                    positions={positions}
                                                    pointIndex={+index}
                                                    imageIndex={+current}
                                                    name={points[index].name}
                                                    color={colors[index]}
                                                    onChangeCheckbox={(e) => {
                                                        setCurrentInstPointIndex(current, Helper.getInputValue(e.target) === "1" ? index : null);
                                                    }}
                                                    checkedCheckbox={imageInstallationPoint[current] === index}
                                                />
                                            )
                                        ) : (
                                            <div
                                                key={index}
                                                className="sensors-location"
                                            >
                                                <span
                                                    className="index-icon-block"
                                                    style={{backgroundColor: colors[index]}}
                                                >
                                                    {+index + 1}
                                                </span>
                                                <span className="point-name">
                                                    <span>{points[index].name}</span>
                                                </span>
                                            </div>
                                        ))
                                )}
                            </div>
                        </div>
                    </DndProvider>
                </div>

                {onGoFromImage && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnText="Return to installation points"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title=""
                        openAnim={false}
                        closeAnim={false}
                        onConfirm={onGoFromImage}
                        onCancel={() => this.setState({onGoFromImage: false})}
                    >
                        Are you sure you don&apos;t want to assign sensor to equipment?
                    </SweetAlert>
                )}
            </Modal>
        );
    }
}

CreateModal.propTypes = {
    auth: PropTypes.object,
    assignPointsModal: PropTypes.object,
    images: PropTypes.array,
    points: PropTypes.array,
    positions: PropTypes.object,
    flipTurns: PropTypes.object,
    colors: PropTypes.array,
    imageInstallationPoint: PropTypes.array,
    setCurrentInstPointIndex: PropTypes.func,
    onDrop: PropTypes.func,
    onRemove: PropTypes.func,
    onFlipFile: PropTypes.func,
    onSetCurrent: PropTypes.func,
    onSetPosition: PropTypes.func,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    isZoomed: PropTypes.bool,
};
export default CreateModal;
