import $ from "jquery";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import arrayMove from "array-move";
import {isMobile} from "react-device-detect";
import {useChartSelectedChartTypeActions, useChartSelectedChartTypeStore} from "../../../../../stores/zustand/ChartSelectedChartTypeStore";

const SortableItem = SortableElement(({isChecked, value, label, updateSelected, styleAttrs}) => (
    <li
        className="sortable-list-horizontal-item"
        style={styleAttrs}
    >
        <label className="form-checkbox">
            <input
                type="checkbox"
                value={value}
                onChange={() => updateSelected(value)}
                checked={isChecked}
            />{" "}
            {label}
            <span className={"chart-checkbox-wrapper"} />
        </label>
    </li>
));

const SortableList = SortableContainer(({items, chartTypes, selected, updateSelected, equipment}) => (
    <ul
        id="sortable-list-wrapper"
        className="sortable-list-horizontal d-flex mb-0 mr-3"
    >
        {items.map((key, index) => {
            if (chartTypes[key]) {
                let styleAttrs = {};
                if (chartTypes[key].type === "rpm" && equipment.tachometer === null) {
                    styleAttrs = {
                        opacity: 0,
                        width: 0,
                        padding: 0,
                        margin: 0,
                        border: 0,
                    };
                }

                if (chartTypes[key].type === "tach" && equipment.tachometer === null) {
                    styleAttrs = {
                        opacity: 0,
                        width: 0,
                        padding: 0,
                        margin: 0,
                        border: 0,
                    };
                }

                if (chartTypes[key].type === "range" && equipment.existDistanceSensor === false) {
                    styleAttrs = {
                        opacity: 0,
                        width: 0,
                        padding: 0,
                        margin: 0,
                        border: 0,
                    };
                }

                return (
                    <SortableItem
                        key={index}
                        styleAttrs={styleAttrs}
                        index={index}
                        value={key}
                        updateSelected={updateSelected}
                        label={chartTypes[key].short}
                        isChecked={selected.indexOf(key) > -1}
                    />
                );
            }
        })}
    </ul>
));

const ReadingTypesSortable = (props) => {
    const {setCheckedChartType, setSelectedChartTypeSorted} = useChartSelectedChartTypeActions();
    const selectedChartTypes = useChartSelectedChartTypeStore();
    const [items, setItems] = useState(props.chartOrderSettingsList);

    useEffect(() => {
        setItems(props.chartOrderSettingsList);
    }, [props.chartOrderSettingsList]);

    const updateSelectedChartType = (value) => {
        setCheckedChartType(value);
    };

    const onSortEnd = ({oldIndex, newIndex}) => {
        let localItems = [...items];
        let newItems = arrayMove(localItems, oldIndex, newIndex);

        setSelectedChartTypeSorted(newItems);
        setItems(newItems);
    };

    return (
        <SortableList
            items={items}
            chartTypes={props.chartTypes}
            selected={selectedChartTypes.checked}
            updateSelected={updateSelectedChartType}
            equipment={props.equipment}
            axis="x"
            helperContainer={document.getElementById("sortable-list-wrapper")}
            hideSortableGhost={false}
            pressDelay={isMobile ? 150 : 0}
            onSortEnd={(obj) => {
                $($("#sortable-list-wrapper .sortable-list-horizontal-item")[obj.oldIndex]).css({
                    opacity: 1,
                    zIndex: 10000,
                });
                onSortEnd(obj);
            }}
            onSortStart={(obj) => $(obj.node).css({opacity: 0})}
            shouldCancelStart={(e) => {
                if (["chart-checkbox-wrapper"].indexOf(e.target.className.toLowerCase()) !== -1) {
                    return true; // Return true to cancel sorting
                }
            }}
        />
    );
};

ReadingTypesSortable.propTypes = {
    equipment: PropTypes.object,
    chartTypes: PropTypes.object,
    chartOrderSettingsList: PropTypes.object,
    orderedSelectedCharts: PropTypes.array,
};

export default ReadingTypesSortable;
