import React, {useEffect} from "react";
import Charts from "./charts";
import Highcharts from "highcharts";
import Annotations from "highcharts/modules/annotations";
import HcMore from "highcharts/highcharts-more";
import HcExporting from "highcharts/modules/exporting";
import HcExportData from "highcharts/modules/export-data";
import Highcharts3d from "highcharts/highcharts-3d";
import {NoDataToDisplay} from "react-highcharts-no-data-to-display";
import {ModalProvider} from "react-modal-hook";
import {wrapChartZoomMovement} from "./helpers/wrapChartZoomMovement";
import {wrapHoverPointSelection} from "./helpers/wrapHoverPointSelection";
import {wrapChartSymbols} from "./helpers/wrapChartSymbols";
import {wrapViewTypeChange} from "./helpers/wrapViewTypeChange";
import {wrapHoldMode} from "./helpers/wrapHoldMode";

export default function Chart() {
    useEffect(() => wrapChartZoomMovement(Highcharts), []);
    useEffect(() => wrapHoverPointSelection(Highcharts), []);
    useEffect(() => wrapChartSymbols(Highcharts), []);
    useEffect(() => wrapViewTypeChange(Highcharts), []);
    useEffect(() => wrapHoldMode(Highcharts), []);
    useEffect(() => NoDataToDisplay(Highcharts), []);
    useEffect(() => Annotations(Highcharts), []);
    useEffect(() => Highcharts3d(Highcharts), []);
    useEffect(() => HcExporting(Highcharts), []);
    useEffect(() => HcExportData(Highcharts), []);
    useEffect(() => HcMore(Highcharts), []);
    useEffect(() => {
        Highcharts.AST.bypassHTMLFiltering = true;
    }, []);

    return (
        <ModalProvider>
            <div id="date-picker-portal">
                <Charts />
            </div>
        </ModalProvider>
    );
}
