import React from "react";
import { useChartStateStoreContext } from "../../store/hooks/useStoreContext";

const FrequencyRange3d = () => {
    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const chartModeList = useChartStateStoreContext((state) => state.factory.chartModeList);
    const show3dFrom = useChartStateStoreContext((state) => state.settings.show3dFrom);
    const show3dTo = useChartStateStoreContext((state) => state.settings.show3dTo);
    const {updateShow3d} = useChartStateStoreContext(state => state.actions);

    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    if (chartMode !== chartModeList.WATERFALL) {
        return <></>;
    }

    return (
        <div className="pull-left-label k-form__group mr-0">
            <div className="d-inline-block mr-2 k-form__label">
                <label>Frequency Range: From</label>
            </div>
            <div className="d-inline-block mr-2 k-form__control input-group-sm">
                <input
                    type="number"
                    name="show3dFrom"
                    className={"form-control request-form-control"}
                    value={show3dFrom}
                    disabled={!!isLoading}
                    onChange={(ev) =>
                        updateShow3d({
                            show3dFrom: ev.target.value,
                        })
                    }
                />
            </div>
            <div className="d-inline-block mr-2 k-form__label">
                <label>To</label>
            </div>
            <div className="d-inline-block mr-2 k-form__control input-group-sm">
                <input
                    type="number"
                    name="show3dTo"
                    className={"form-control request-form-control"}
                    value={show3dTo}
                    disabled={!!isLoading}
                    onChange={(ev) => updateShow3d({ show3dTo: ev.target.value })}
                />
            </div>
        </div>
    );
};

export default FrequencyRange3d;
