import {
    get as _get,
} from "lodash";

class InstallationPointFormData {

    static getInstallationPointObject(data = {}) {
        return {
            id: _get(data, "id", null),
            name: _get(data, "name", ""),
            installation_point_custom_type_id: _get(data, "installation_point_custom_type_id", null),
            point_type: _get(data, "point_type"),
            installationPointCustomType: _get(data, "installationPointCustomType"),
            universalAdapterParameter: _get(data, "universalAdapterParameter"),
            universalAdapter: _get(data, "universalAdapter"),
            is_heatsink: _get(data, "is_heatsink", false),
            sensor: {
                sensor_hex: _get(data, "sensor.hex_id", null) ?? _get(data, "sensor_hex", null),
                is_hfdvue: _get(data, "sensor.is_hfdvue", false),
                confirmC1D1: false,
                confirmGatewayNotAssociated: false,
                duplicatedWay: null,
                supportHfdvue: _get(data, "sensor.supportHfdvue", false)
            }
        };
    }
}

export default InstallationPointFormData;