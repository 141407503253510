import React, {Component} from "react";
import PropTypes from "prop-types";
import {get as _get} from "lodash";
import Helper from "../../../helpers/helper";
import {Link} from "react-router-dom";

class Row extends Component
{
    render() {
        const {item, onDelete, query} = this.props;

        return (
            <React.Fragment>
                <tr>
                    <td dangerouslySetInnerHTML={{__html: Helper.highlight(_get(item, "name"), query)}} />
                    <td>
                        <Link
                            to={"/nameplates/parameters/edit/" + item.id}
                            className="link link-primary"
                        >
                            <i className="fa fa-pencil-alt"/>
                            <span>Edit</span>
                        </Link>
                        <button className="link link-danger" onClick={() => onDelete(item.id)}>
                            <i className="fa fa-times"/>
                            <span>Delete</span>
                        </button>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

Row.propTypes = {
    item: PropTypes.object,
    props: PropTypes.object,
    query: PropTypes.string,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};

export default Row;