import React, { useMemo } from "react";

export const usePagerPagination = (page, pages, onPagerChange) => {
    const handlePagerChange = (pageLocal = 1) => {
        if (pageLocal !== page && pageLocal > 0 && pageLocal <= pages) {
            onPagerChange(+pageLocal);
        }
    };

    const morePage = (key) => (
        <li key={key} className={"pager-item"}>
            <span className={"pager-link pager-link--more"}>
                <i className="la la-ellipsis-h" />
            </span>
        </li>
    );

    const Page = (key) => (
        <li key={key} className="pager-item">
            <a
                className={
                    "pager-link pager-link--number " + (+page === key ? "pager-link--active" : "")
                }
                onClick={() => handlePagerChange(key)}
            >
                {key}
            </a>
        </li>
    );

    const controlPage = (key, i, dis, icon) => (
        <li key={key} className={"pager-item"}>
            <a
                className={
                    "pager-link " +
                    ("pager-link--" + key) +
                    (+page === dis ? " pager-link--disabled" : "")
                }
                onClick={() => handlePagerChange(i)}
            >
                <i className={"la la-angle-" + icon} />
            </a>
        </li>
    );

    const pagerList = useMemo(() => {
        const pagerList = [];
        pagerList.push(controlPage("first", 1, 1, "double-left"));
        pagerList.push(controlPage("prev", +page - 1, 1, "left"));
        if (+pages <= 5) {
            for (let i = 1; i <= pages; i++) {
                pagerList.push(Page(i));
            }
        }
        if (+pages > 5) {
            pagerList.push(Page(1));
            if (+page < 4) {
                for (let i = 2; i < 5; i++) {
                    pagerList.push(Page(i));
                }
                pagerList.push(morePage("more-next"));
            } else if (+page >= 4 && +page <= +pages - 3) {
                pagerList.push(morePage("more-prev"));
                for (let i = page - 1; i <= page + 1; i++) {
                    pagerList.push(Page(i));
                }
                pagerList.push(morePage("more-next"));
            } else if (+page > +pages - 5) {
                pagerList.push(morePage("more-prev"));
                for (let i = 3; i >= 1; i--) {
                    pagerList.push(Page(pages - i));
                }
            }
            pagerList.push(Page(pages));
        }
        pagerList.push(controlPage("next", +page + 1, +pages, "right"));
        pagerList.push(controlPage("last", +pages, +pages, "double-right"));
        return pagerList;
    }, [page, pages]);

    return pagerList;
};
