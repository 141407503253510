import createPlotlyComponent from "react-plotly.js/factory";

import Plotly from "plotly.js/lib/core";

import Scatter3d from "plotly.js/lib/scatter3d";
import Surface from "plotly.js/lib/surface";
import { memo } from "react";

Plotly.register([
    Scatter3d,
    Surface,
    {
        moduleType: "locale",
        name: "en-US",
        dictionary: {
            "Toggle show closest data on hover": "Toggle display of closest data on hover",
        },
    },
]);
const Plot = createPlotlyComponent(Plotly);

export default memo(Plot);
