import React, {Component} from "react";
import {Modal} from "../../../shared";
import PropTypes from "prop-types";
import HelpSimple from "./simple";
import HelpFft from "./fft";
import HelpZoom from "../../../pages/chart/highcharts/zoom";
import Helper from "../../../helpers/helper";
import Help3d from "./3d";

import "../../../assets/scss/components/help/help-modal.scss";

const modalSettings = {
    titleDefault: "KEYBOARD SHORTCUTS",
    titleHelpFft: "FFT/TWF Charts",
    titleHelpSimple: "Temperature/RMS/Tachometer Charts",
    titleHelp3d: "Waterfall Plot",
    zoomAdditionalTitle: "ALL CHARTS"
};

class HelpIndex extends Component {

    constructor(props) {
        super(props);
    }

    getModalHeader = () => {
        const {withHelpFft, withHelpSimple, withHelp3d} = this.props;
        let type = "";

        if (withHelpFft && withHelpSimple) {
            type = "all";
        } else if (withHelpFft) {
            type = "fft";
        } else if (withHelpSimple) {
            type = "simple";
        } else if (withHelp3d) {
            type = "waterfall";
        }

        return <ModalHeader
            onClick={() => Helper.openHelpWindow(type)}
        />;
    }

    render() {
        const {withHelpFft, withHelpSimple, withHelp3d} = this.props;

        let currentSettings = {};

        if (withHelpFft && withHelpSimple && withHelp3d) {

            currentSettings = modalSettings;
            currentSettings.modalTitle = modalSettings.titleDefault;

        } else if (withHelpFft) {

            currentSettings.modalTitle = modalSettings.titleHelpFft;
            currentSettings.titleHelpFft = modalSettings.titleDefault;
            currentSettings.zoomAdditionalTitle = "";

        } else if (withHelpSimple) {

            currentSettings.modalTitle = modalSettings.titleHelpSimple;
            currentSettings.titleHelpSimple = modalSettings.titleDefault;
            currentSettings.zoomAdditionalTitle = "";

        } else if (withHelp3d) {

            currentSettings.modalTitle = modalSettings.titleHelp3d;
            currentSettings.titleHelp3d = modalSettings.titleDefault;
            currentSettings.zoomAdditionalTitle = "";

        }

        const is3dOnly = withHelp3d && !withHelpSimple && !withHelpFft;

        return (
            <Modal
                {...this.props}
                size="m"
                className={"help-modal"}
                title={currentSettings.modalTitle}
                withoutFooter={true}
                header={this.getModalHeader()}
            >
                {withHelpSimple &&
                <HelpSimple title={currentSettings.titleHelpSimple} />
                }
                {withHelpFft &&
                    <HelpFft title={currentSettings.titleHelpFft} />
                }
                {withHelp3d &&
                <Help3d title={currentSettings.titleHelp3d} />
                }
                {!is3dOnly && <HelpZoom additionalTitle={currentSettings.zoomAdditionalTitle} />}
            </Modal>
        );
    }
}

const ModalHeader = ({onClick}) => (
    <React.Fragment>
        <div className="new-window" onClick={onClick}>
            <i className="fa fa-external-link-alt"/>
        </div>
    </React.Fragment>
);

ModalHeader.propTypes = {
    onClick: PropTypes.func
};

HelpIndex.defaultProps = {
    withHelpFft: false,
    withHelpSimple: false,
    withHelp3d: false,
};

HelpIndex.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
    withHelpFft: PropTypes.bool,
    withHelpSimple: PropTypes.bool,
    withHelp3d: PropTypes.bool,
};

export default HelpIndex;