import React, {Component} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../stores/GlobalStore";
import Toast from "../shared/toast";
import ApiCustomer from "../../api/customerLogo";
import {
    get as _get
} from "lodash";

const propTypes = {
    user: PropTypes.object,
    chartTypes: PropTypes.object,
    auth: PropTypes.object,
    updateUser: PropTypes.func
};

class UploadLogoForm extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            data: {
                header_image: ""
            },
            logo: {
                base64: "",
                logoPicture: _get(props, "user.customer.header_image", ""),
                showRemove: _get(props, "user.customer.is_custom_header_image") ? true: false,
                showCancel: false
            },
            inProgress: false
        };

        this.onSave = this.onSave.bind(this);
    }

    onUploadUserLogo = event => {
        let logoUploaded = URL.createObjectURL(event.target.files[0]);

        this.getBase64(event.target.files[0], base64 => {
            this.setState({
                logo: {
                    base64,
                    logoPicture: logoUploaded,
                    showRemove: false,
                    showCancel: true
                }
            });
        });
    }

    onCancelUploadUserLogo = () => {
        this.setState({
            logo: {
                base64: "",
                logoPicture: _get(this.props, "user.customer.header_image", ""),
                showRemove: true,
                showCancel: false
            }
        });
    }

    onRemoveUserLogo = () => {
        const{data} = {...this.state.data};

        let logoSplash = process.env.SSO_URL + "/images/logo-new.png";

        this.setState({inProgress: true}, () => {
            ApiCustomer.removeCustomerLogo({...data, header_image: this.state.logo.base64}).then(response => {
                this.setState({
                    data:{
                        ...data,
                        header_image: response.header_image
                    },
                    logo: {
                        logoPicture: logoSplash,
                        showRemove: false
                    },
                    inProgress: false
                }, () => {
                    this.props.updateUser().then(() => {
                        Toast.success("Your logo has been deleted.");
                    });


                });
            }).catch(err => {
                if (err.errors) {
                    this.setState({inProgress: false});
                }
            });
        });
    }

    onSave() {
        const{data} = {...this.state.data};

        this.setState({inProgress: true}, () => {
            ApiCustomer.updateCustomerLogo({...data, header_image: this.state.logo.base64}).then(response => {
                this.setState({
                    data:{
                        ...data,
                        header_image: response.header_image
                    },
                    logo: {
                        logoPicture: response.header_image
                    },
                    inProgress: false
                }, () => {
                    this.props.updateUser().then(() => {
                        this.onCancelUploadUserLogo();
                        Toast.success("Your logo has been updated.");
                    });


                });
            }).catch(err => {
                if (err.errors) {
                    this.setState({inProgress: false});
                }
            });
        });
    }

    render() {
        const {inProgress, logo} = this.state;

        return (
            <div className="block">
                <div className="block-header">
                    <div className="block-label">
                        <h5 className="block-title mb-0">Upload Company Logo <small>(optional)</small></h5>
                    </div>
                </div>
                <form
                    ref={el => this.el = el}
                    className="form user-profile-form"
                    onSubmit={e => {e.preventDefault(); this.onSave();}}
                >
                    <div className="block-body">
                        <div className="form-group row">
                            <label className="col-3 form-label">Logo Picture</label>
                            <div className="col-9 col-lg-6">
                                <div className="user-profile-logo" id="k_profile_logo">
                                    <div className="user-profile-holder-logo" style={{backgroundImage: `url('${logo.logoPicture}')`}}/>
                                    <label className="user-profile-upload-logo" title="Update profile logo">
                                        <i className="fa fa-pen"/>
                                        <input
                                            type="file"
                                            name="userlogo"
                                            accept=".png,.jpg,.jpeg"
                                            onChange={this.onUploadUserLogo}
                                        />
                                    </label>
                                    {logo.showCancel &&
                                        <span
                                            className="user-profile-remove"
                                            title="Cancel logo"
                                            onClick={this.onCancelUploadUserLogo}
                                        >
                                            <i className="fa fa-times"/>
                                        </span>
                                    }
                                    {logo.showRemove &&
                                        <span
                                            className="user-profile-remove-logo"
                                            title="Remove logo"
                                            onClick={this.onRemoveUserLogo}
                                        >
                                            <i className="fa fa-trash"/>
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-3"></label>
                            <div className="col-9 col-lg-6 text-right">
                                <button
                                    type="button"
                                    className="btn btn-success btn-sm"
                                    onClick={this.onSave}
                                    disabled={inProgress}
                                >
                                    {inProgress ? <span><i className="fa fa-spinner"></i> Processing</span> : "Submit"}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    getBase64(file, cb) {
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => cb(reader.result);
            reader.onerror = error => console.log("Error: ", error);
        }
    }
}

UploadLogoForm.propTypes = propTypes;



export default withGlobalStore(UploadLogoForm);