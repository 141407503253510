import { get as _get, cloneDeep as _cloneDeep, each as _each, set as _set } from "lodash";


export const updateChartTypesMeasures = (units, chartTypes) => {

    const chartTypesLocal = _cloneDeep(chartTypes);

    _each(chartTypesLocal, (chartType, chartTypeId) => {
        _each(chartType.series, (series, axisId) => {

            const readingType = series.readingType || series.readingTypeMetric;
            let metric = _get(units, readingType);

            _set(chartTypesLocal, [ chartTypeId, "series", axisId, "units" ], _get(chartType, [ "unitsList", metric ]));
        });
    });

    return chartTypesLocal;
};