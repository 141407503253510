// import {useEquipmentByParams} from "../api/equipment/useEquipmentQuery";
// import {useOtherEquipmentPoints} from "./useOtherEquipmentPoints";

export const useGetPointById = (pointID, equipment) => {
    // const {equipmentItem: equipment} = useEquipmentByParams();
    // const otherEquipmentPoints = useOtherEquipmentPoints();

    let otherEquipmentPointsUpdate = [];
    let pointsData = [...equipment.installationPoints];

    let point = pointsData.find((point) => {
        if (point.id === pointID) {
            return point;
        }
    });

    if (!point) {
        point = otherEquipmentPointsUpdate.find((point) => {
            if (point.id === pointID) {
                return point;
            }
        });
    }

    return point || {};
};
