import React, {Component} from "react";
import PropTypes from "prop-types";
import BasePopover from "../../shared/base-popover";
import {Link} from "react-router-dom";
import {get as _get} from "lodash";

class InstallationPointsPopover extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }

    toggle = () => {
        this.setState({show: !this.state.show});
    };

    render() {
        const {show} = this.state;
        const {installationPoints} = this.props;

        return (
            <React.Fragment>
                <BasePopover
                    {...this.props.popoverProps}
                    isOpen={show}
                    toggle={this.toggle}
                >
                    <React.Fragment>
                        {installationPoints.length > 0 &&
                            installationPoints.map((installationPoint, index) =>
                                <div key={index}>
                                    {_get(installationPoint, "equipment_name")}
                                    <span className={"mr-2 ml-2"}>=&gt;</span>
                                    <Link
                                        to={`/chart/${_get(installationPoint, "equipment_id")}#sensors=${_get(installationPoint, "id")}`}
                                        className="link link-primary"
                                        title="View Equipment"
                                        style={{margin: 0}}
                                    >
                                        {_get(installationPoint, "name")}
                                    </Link>
                                </div>
                            )
                        }
                    </React.Fragment>
                </BasePopover>
            </React.Fragment>
        );
    }
}

InstallationPointsPopover.propTypes = {
    popoverProps: PropTypes.object,
    installationPoints: PropTypes.array,
};
export default InstallationPointsPopover;