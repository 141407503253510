import React, {useEffect, useReducer} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {Loader, Pager} from "../../../shared";
import {ResetSortButton} from "../../shared/resetSortButton";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import UserApi from "../../../api/user";
import UserSubscriptionsApi from "../../../api/userSubscriptions";
import {find as _find, includes as _includes, orderBy as _orderBy} from "lodash";
import Select from "react-select";
import SearchInput from "../../shared/search-input";
import {customStyles} from "../../../helpers/select";
import { HeaderSimple } from "../../../shared/header";

const title = "User Subscriptions";
const breadcrumbs = [{name: "User Subscriptions"}];

const initialState = {
    list: [],
    users: [],
    filter: {},
    sort: {
        column: "",
        order: "asc"
    },
    pagination: {
        page: 1,
        pages: 1,
        perpage: 15,
        total: 0
    },
    loader: true
};

const reducer = (state, action) => ({...state, ...action});

const UserSubscriptions = ({history, chartTypes}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {list, sort, filter, pagination, users, loader} = state;

    useEffect(() => {
        UserApi.getSelfUsers({
             query: {
                 filter: {status: 1},
                 excludeWCDUsers: 1
             }
        }).then(({users}) => dispatch({users}));
    }, []);

    useEffect(() => {
        if (loader) {
            UserSubscriptionsApi.list({sort, filter, pagination}).then(({list, meta}) =>
                dispatch({list, pagination: {...pagination, ...meta}, loader: false})
            );
        }
    }, [loader]);

    const updateFilter = (key, value) => dispatch({
        loader: true,
        filter: {...filter, [key]: value},
        pagination: {...pagination, page: 1}
    });

    const updateSort = column => dispatch({
        loader: true,
        sort: {column, order: column && column === sort.column ? (sort.order === "asc" ? "desc" : "asc") : "asc"}
    });

    const updatePager = (page = 1) => dispatch({
        loader: true,
        pagination: {...pagination, page}
    });

    const user = _find(users, user => +user.id === +filter.user);

    return (
        <div className="alert-groups">
            <HeaderSimple breadcrumbs={breadcrumbs} hideGlobalSearch />
            <div className="subheader">
                <div className="subheader-title">{title}</div>
            </div>
            <div className="block">
                <div className="block-header block-header-sticky">
                    <div className="block-label">
                        <div className="form-list form-list--row">
                            <div className="form-group form-group--inline">
                                <div className="form-label">
                                    <label><b>User:</b></label>
                                </div>
                                <Select
                                    className="form-control-select form-control-select--lg"
                                    isSearchable={false}
                                    styles={customStyles}
                                    value={user ? {value: user.id, label: user.full_name} : ""}
                                    options={users.map(user => ({value: user.id, label: user.full_name}))}
                                    onChange={({value}) => updateFilter("user", value)}
                                />
                            </div>
                            {!!filter.user &&
                                <div className="form-group form-group--inline">
                                    <button
                                        className="btn btn-primary btn-sm"
                                        onClick={() => dispatch({filter: {}, loader: true})}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            }
                        </div>
                        {pagination.pages > 1 &&
                            <div id="top-pagination-block" className="block-pagination">
                                <Pager
                                    page={pagination.page}
                                    pages={pagination.pages}
                                    onPagerChange={updatePager}
                                />
                            </div>
                        }
                    </div>
                    <div className="block-toolbar">
                        <div className="block-toolbar-wrapper">
                            <div style={{minWidth: "212px"}}>
                                <SearchInput
                                    onChange={value => updateFilter("query", value)}
                                    placeholder="Search User Subscriptions"
                                    query={filter.query || ""}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-body block-body--no-paddings">
                    {loader
                        ? <div className="loader-fullscreen"><Loader/></div>
                        : <Table
                            list={list}
                            sort={sort}
                            history={history}
                            chartTypes={chartTypes}
                            updateSort={updateSort}
                            dispatch={dispatch}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

UserSubscriptions.propTypes = {
    history: PropTypes.object,
    chartTypes: PropTypes.object
};

const headersList = {
    "name_first": {title: "Name", sort: true},
    "email": {title: "Email", sort: true},
    "receive_hardware_notify": {title: "Event Log Emails", sort: true},
    "deviceSubscriptionGroups": {title: "Device Alert Groups", sort: true},
    "subscriptionGroups": {title: "Alert Groups", sort: true},
    "alertConditions": {title: "Alert Conditions", sort: true},
    "alertLevels": {title: "Alert Emails", sort: true, additionalClasses: "table-buttons-th", component: ResetSortButton}
};

const Table = ({history, list, sort, chartTypes, updateSort, dispatch}) => {
    return (
        <React.Fragment>
            <table className="table table-hover tl-fixed">
                <thead>
                    <tr>
                        {Object.keys(headersList).map(key => {
                            let component = "";
                            const RowComponent = (headersList[key] || {}).component;
                            if (RowComponent) {
                                component = <RowComponent sort={sort} resetSort={() => updateSort("")}/>;
                            }
                            return (
                                Object.keys(headersList).length && headersList[key].sort
                                    ?
                                    <th
                                        key={key}
                                        className={headersList[key].additionalClasses || ""}
                                        style={headersList[key].style || {}}
                                    >
                                        <div className="cursor-pointer" onClick={() => updateSort(key)} style={{display: "inline-block"}}>
                                            {headersList[key].title}
                                            {<i className={`fa fa-sort${sort.column === key ? (sort.order === "asc" ? "-up" : "-down") : ""}`}/>}
                                        </div>
                                        {!!component && <div className="pull-right">{component}</div>}
                                    </th>
                                    :
                                    <th
                                        key={key}
                                        className={headersList[key].additionalClasses || ""}
                                        style={headersList[key].style || {}}
                                    >
                                        {headersList[key].title} {component}
                                    </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {list.length
                        ?
                        list.map(data =>
                            <Row
                                key={data.id}
                                data={data}
                                chartTypes={chartTypes}
                                history={history}
                                dispatch={dispatch}
                            />
                        )
                        :
                        <tr>
                            <td colSpan={Object.keys(headersList).length} className="text-center text-info">
                                User Subscriptions not found
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </React.Fragment>
    );
};

Table.propTypes = {
    list: PropTypes.array,
    sort: PropTypes.object,
    history: PropTypes.object,
    chartTypes: PropTypes.object,
    updateSort: PropTypes.func,
    dispatch: PropTypes.func
};

const rowInitialState = {
    isAlertConditionsOpen: false,
    isSubscriptionGroupsOpen: false,
    isDeviceSubscriptionGroupsOpen: false
};

const Row = ({history, chartTypes, data}) => {
    const [rowState, rowDispatch] = useReducer(reducer, rowInitialState);
    const {isAlertConditionsOpen, isSubscriptionGroupsOpen, isDeviceSubscriptionGroupsOpen} = rowState;

    return (
        <tr>
            <td>{data.name_first} {data.name_last}</td>
            <td>{data.email}</td>
            <td>
                {!!+data.receive_hardware_notify &&
                    <span
                        className="badge badge-secondary round-badge"
                        title="This user receives emails for all events in the Event log"
                        style={{background: "#777", color: "#FFF"}}
                    >
                        Yes
                    </span>
                }
            </td>
            <td>
                {!!(data.deviceSubscriptionGroups || []).length &&
                <Dropdown
                    className="dropdown-equipment"
                    size="sm"
                    direction="left"
                    isOpen={isDeviceSubscriptionGroupsOpen}
                    toggle={() => rowDispatch({isDeviceSubscriptionGroupsOpen: !isDeviceSubscriptionGroupsOpen})}
                >
                    <DropdownToggle>
                        <span className="menu-count badge badge-info"><span>{data.deviceSubscriptionGroups.length}</span></span>
                    </DropdownToggle>
                    <DropdownMenu className="custom-scroll custom-scroll-dropdown-menu">
                        {data.deviceSubscriptionGroups.map(group =>
                            <DropdownItem
                                key={group.id}
                                onClick={() => history.push("/device-alert-groups/" + group.id)}
                            >
                                {group.name}
                            </DropdownItem>
                        )}
                    </DropdownMenu>
                </Dropdown>
                }
            </td>
            <td>
                {!!(data.subscriptionGroups || []).length &&
                    <Dropdown
                        className="dropdown-equipment"
                        size="sm"
                        direction="left"
                        isOpen={isSubscriptionGroupsOpen}
                        toggle={() => rowDispatch({isSubscriptionGroupsOpen: !isSubscriptionGroupsOpen})}
                    >
                        <DropdownToggle>
                            <span className="menu-count badge badge-info"><span>{data.subscriptionGroups.length}</span></span>
                        </DropdownToggle>
                        <DropdownMenu className="custom-scroll custom-scroll-dropdown-menu">
                            {data.subscriptionGroups.map(group =>
                                <DropdownItem
                                    key={group.id}
                                    onClick={() => history.push("/alert-groups/" + group.id)}
                                >
                                    {group.name}
                                </DropdownItem>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                }
            </td>
            <td>
                {!!(data.alertConditions || []).length &&
                    <Dropdown
                        className="dropdown-equipment"
                        size="sm"
                        direction="left"
                        isOpen={isAlertConditionsOpen}
                        toggle={() => rowDispatch({isAlertConditionsOpen: !isAlertConditionsOpen})}
                    >
                        <DropdownToggle><span className="menu-count badge badge-info"><span>{data.alertConditions.length}</span></span></DropdownToggle>
                        <DropdownMenu className="custom-scroll custom-scroll-dropdown-menu">
                            {data.alertConditions.map(condition => {
                                let chartType = false;
                                (Object.keys(chartTypes) || []).map(chartIndex => {
                                    if (_includes(chartTypes[chartIndex]["readingTypes"], ""+condition.reading_type_id)) {
                                        chartType = chartIndex;
                                    }
                                });

                                return (
                                    <DropdownItem
                                        key={condition.id}
                                        onClick={() => history.push(
                                            `/chart/${condition.equipment_id}#sensors=${condition.installation_point_id}` +
                                            (chartType
                                                ? `&modal=alert&type=${chartType}&axisId=${condition.axis_id || 1}&installationPointId=${condition.installation_point_id}`
                                                : ""
                                            )
                                        )}
                                    >
                                        {condition.installation_point_name ? condition.installation_point_name + " - " : ""}
                                        {condition.equipment_name ? condition.equipment_name + " - " : ""}
                                        {condition.title}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                    </Dropdown>
                }
            </td>
            <td>
                {_orderBy(data.alertLevels || [], "priority", "desc").map(level =>
                    <span
                        key={level.id}
                        className="badge badge-warning round-badge"
                        title={`This user receives emails for all ${level.name} alerts`}
                        style={{background: level.color}}
                    >
                        {level.name}
                    </span>
                )}
            </td>
        </tr>
    );
};

Row.propTypes = {
    data: PropTypes.object,
    history: PropTypes.object,
    chartTypes: PropTypes.object,
    dispatch: PropTypes.func
};

export default withGlobalStore(UserSubscriptions);