import React from "react";
import PropTypes from "prop-types";
import {fftAlertTypes} from "../../../constants/constants";
import Helper from "../../../helpers/helper";
import { useHistory } from "react-router";

const FFTAlertTypeControl = ({axisId, fftAlertType, currentIsChanged, setConfirm, isImpactVue = false}) => {
    const history = useHistory();
    return (
        <div className="form-group row mb-2">
            <label className="col-4">{isImpactVue ? "FFT Alert Type ImpactVUE" : "FFT Alert Type"}:</label>
            <div className="col-8">
                <div className="axis-block-container">
                    <div
                        className={`axis-block ${fftAlertType === fftAlertTypes.BAND ? "active" : ""}`}
                        onClick={() => {
                            if (currentIsChanged) setConfirm(() => history.push(Helper.deleteHashParams(["fftAlertType"])));
                            else history.push(Helper.deleteHashParams(["fftAlertType"]));
                        }}
                    >
                        <span>Band</span>
                    </div>
                    <div
                        className={`axis-block ${fftAlertType === fftAlertTypes.ENVELOPE ? "active" : ""}`}
                        onClick={() => {
                            if (currentIsChanged)
                                setConfirm(() =>
                                    history.push(Helper.setHashParams({fftAlertType: fftAlertTypes.ENVELOPE, ...(axisId ? {} : {axisId: 1})}))
                                );
                            else history.push(Helper.setHashParams({fftAlertType: fftAlertTypes.ENVELOPE, ...(axisId ? {} : {axisId: 1})}));
                        }}
                    >
                        <span>Envelope</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

FFTAlertTypeControl.propTypes = {
    axisId: PropTypes.number,
    fftAlertType: PropTypes.number,
    currentIsChanged: PropTypes.bool,
    history: PropTypes.object,
    setConfirm: PropTypes.func,
    isImpactVue: PropTypes.bool
};

export default FFTAlertTypeControl;