export const FIRMWARE_TYPE_DEFAULT_VALUE = {label: "All", value: undefined};

export const FIRMWARE_TYPES = {
    sensor: {label: "Tethered Sensor", value: "sensor"},
    gateway: {label: "Gateway", value: "gateway"},
    node: {label: "Node", value: "node"},
    mote: {label: "Sensor Mote", value: "mote"},
    router: {label: "Repeater", value: "router"},
};

export const FIRMWARE_TYPES_LIST = [FIRMWARE_TYPE_DEFAULT_VALUE, ...Object.values(FIRMWARE_TYPES)];
