import {useMemo} from "react";
import {useEquipmentByParams} from "../api/equipment/useEquipmentQuery";
import {usePointData} from "../../pages/chart/highcharts/hooks/usePointsData";
import {getSpeedSettingsFft} from "../../helpers/reading";

export const useSpeedSettingsFft = (readings, selectedPoint, overlayPoints = []) => {
    const {equipmentItem} = useEquipmentByParams();
    const pointData = usePointData(selectedPoint, overlayPoints);

    return useMemo(
        () => getSpeedSettingsFft({equipment: equipmentItem, pointData, installationPointId: selectedPoint, reading: readings}),
        [readings, pointData, equipmentItem]
    );
};
