import React, {memo, useState} from "react";
import Plot from "./plotly.js";
import HelpIndex from "../../../../../../../../modals/help/components";
import {useChartContext, useChartHeight} from "../../../../hooks/chartHooks";
import {useChartStateStoreContext} from "../../../../store/hooks/useStoreContext";

import {shallow} from "zustand/shallow";
import {useDrawWaterfallChartEffect} from "../../../../hooks/useDrawWaterfallChartEffect";
import Trend3d from "./trend";
import {Loader} from "../../../../../../../../shared";

export const ChartWaterfallPlot = () => {
    const chartRef = React.createRef();
    const chartHeight = useChartHeight();
    const {showHelpModal, setShowHelpModal} = useState(false);
    const show3dTrend = useChartStateStoreContext((state) => state.settings.show3dTrend);
    const show3dFrom = useChartStateStoreContext((state) => state.settings.show3dFrom);
    const show3dTo = useChartStateStoreContext((state) => state.settings.show3dTo);
    const trend3dFrequency = useChartStateStoreContext((state) => state.settings.trend3dFrequency);
    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList, shallow);
    const {setTrend3dFrequency} = useChartStateStoreContext((state) => state.actions, shallow);
    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    const {chartProps} = useChartContext();
    const {fftFrequencyType} = chartProps;

    const {data, layout, maxFrequency} = useDrawWaterfallChartEffect();

    const onSliderChange = (val) => {
        setTrend3dFrequency(Array.isArray(val) ? val[0] : val);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div
            className="row"
            style={{height: chartHeight + "px"}}
        >
            <div className={show3dTrend ? "col-md-8" : "col-md-12"}>
                <div className="position-relative">
                    <Plot
                        ref={chartRef}
                        data={data}
                        layout={layout}
                        config={{
                            modeBarButtons: [
                                [
                                    {
                                        name: "help3d",
                                        title: "View keyboard shortcuts",

                                        icon: () => {
                                            const span = document.createElement("span");
                                            span.appendChild(document.createTextNode("\uf1c6"));
                                            span.setAttribute(
                                                "style",
                                                "font-family:Flaticon;font-size:14px;cursor:default;position:absolute;left:-10px;top:10px;"
                                            );
                                            return span;
                                        },
                                        click: () => {
                                            setShowHelpModal(true);
                                        },
                                    },
                                    "toImage",
                                    "zoom3d",
                                    "pan3d",
                                    "tableRotation",
                                    "resetCameraDefault3d",
                                    "hoverClosest3d",
                                ],
                            ],
                            responsive: true,
                            displaylogo: false,
                            showTips: false,
                            displayModeBar: true,
                        }}
                        style={{width: "inherit", height: chartHeight}}
                    />
                </div>
            </div>
            {show3dTrend && (
                <div className={"col-md-4"}>
                    <div className="trend3d-bg">
                        {!!maxFrequency && (
                            <Trend3d
                                onSliderChange={onSliderChange}
                                series={data}
                                chartHeight={chartHeight}
                                trendFrequency={trend3dFrequency}
                                frequencyType={fftFrequencyType}
                                precision={seriesList.precision}
                                units={seriesList.units}
                                from={show3dFrom || 0}
                                to={show3dTo || maxFrequency}
                            />
                        )}
                    </div>
                </div>
            )}
            <HelpIndex
                showModal={showHelpModal}
                onClose={() => setShowHelpModal(false)}
                withHelp3d={true}
            />
        </div>
    );
};

export default memo(ChartWaterfallPlot);
