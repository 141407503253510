import PropTypes from "prop-types";
import React from "react";

const EquipmentInfo = ({ equipment, showModal }) => {
    return (
        <div className={"equipment-header"}>
            <div className={"equipment-name"}>
                <span>{equipment?.name}</span>
                <button
                    className={"link equipment-info-btn color-primary"}
                    onClick={showModal}
                    title={"Edit Equipment Details"}
                >
                    <i className="fa fa-pen" style={{ margin: "0 5px" }}></i>
                    Edit
                </button>
            </div>
            <div>
                Type:{" "}
                <b>
                    {(equipment?.type || {}).name ? equipment.type.name : "N/A"}
                </b>
            </div>
        </div>
    );
};

EquipmentInfo.propTypes = {
    equipment: PropTypes.object,
    showModal: PropTypes.func,
};
export default EquipmentInfo;
