import React from "react";
import PropTypes from "prop-types";
import Helper from "../../helpers/helper";
import {Link} from "react-router-dom";
import {get as _get} from "lodash";


const SensorHexTab = ({list, query}) => {

    if (!(list || []).length) {
        return (<div className={"row"}>
            <div className="col">
                <span className="text-center text-info" style={{display: "block"}}>No items match your search.</span>
            </div>
        </div>);
    }

    return (<div className={"row"}>
        {list.map(item => {
            return (
                <div
                    key={item.id}
                    className="custom-card col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-4 equipment-card-link"
                >
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div className="k-bg-metal pt-60 mb-3 equpment-list-photo" style={{
                                        background: "#fff"
                                    }}>
                                        <img src={_get(item, ["image", "url"]) ?? "/assets/img/default-equipment-bg.png"} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <span title={_get(item, ["installationPointName"])}
                                       className="cart-text ellipsis-text">
                                        {_get(item, ["installationPointName"])}
                                    </span>

                                    Hex Code:
                                    <b
                                        dangerouslySetInnerHTML={{
                                            __html: Helper.highlight(" "+_get(item, ["hex_code"]), query)
                                        }}
                                    />

                                    <p title={item.description} className="cart-text ellipsis-text">
                                        Sensor Type: <b>{item.description}</b>
                                    </p>
                                </div>
                            </div>
                            {item.installationPointId &&
                                <Link
                                    to={`/chart/${item.equipmentId}#sensors=${item.installationPointId}`}
                                    className="btn btn-primary btn-sm"
                                >
                                    <i className="fa fa-eye"/> View
                                </Link>
                            }
                        </div>
                    </div>
                </div>
            );
        })}
    </div>);
};


SensorHexTab.propTypes = {
    list: PropTypes.array,
    query: PropTypes.string
};

export default SensorHexTab;