import React, {Component} from "react";
import PropTypes from "prop-types";
import Loader from "../../../shared/loader/loader";
import EquipmentForm from "./equipment-form";
import NameplateTemplateApi from "../../../api/nameplateTemplate";
import InstallationPointForm from "./installation-point-form";
import {
    get as _get
} from "lodash";
import NameplateParameterApi from "../../../api/nameplateParameter";
import NameplateApi from "../../../api/nameplate";

class NameplateModalForm extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            loader: true,
            equipmentTypes: [],
            templates: [],
            nameplates: [],
            equipmentNameplates: [],
            parametersList: []
        };
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        Promise.all([
            this.fetchTemplates(),
            this.handleFetchParametersList(),
            this.fetchNameplates()
        ]).then(() => {
            this.setState({loader: false});
        });
    }

    fetchTemplates = () => {
        return NameplateTemplateApi
            .list()
            .then(response => {
                if (response) {
                    const {list} = response;
                    const templates = list || [];
                    this.setState({templates});
                }
            });
    }

    fetchNameplates = () => {
        return NameplateApi
            .listForSelect()
            .then(response => {
                if (response) {
                    const {list} = response;
                    const nameplates = list || [];
                    this.setState({nameplates});
                }
            });
    }

    handleFetchParametersList = () => {
        return NameplateParameterApi
            .list()
            .then((response) => {
                if (response) {
                    const {list = []} = response;
                    this.setState({
                        parametersList: list
                    });
                }
            });
    }

    reloadEquipment = () => {
        const {reloadEquipment} = this.props;
        reloadEquipment();
    }

    render() {
        const {loader, equipmentTypes, templates, nameplates, parametersList} = this.state;
        const {equipment, reloadEquipment} = this.props;
        const {installationPoints} = equipment;

        return (
            <React.Fragment>
                {loader
                    ?
                    <Loader/>
                    :
                    <div className={"nameplate-modal-form"}>
                        <div className={"form-title-group"}>
                            <span>Equipment</span>
                        </div>
                        <EquipmentForm
                            equipment={equipment}
                            equipmentTypes={equipmentTypes}
                            reloadEquipment={reloadEquipment}
                            equipmentNameplates={_get(equipment, "nameplate", [])}
                            templates={templates}
                            nameplatesList={nameplates}
                            updateNameplatesList={this.fetchNameplates}
                            updateTemplates={this.fetchTemplates}
                            parametersList={parametersList}
                            updateParametersList={this.handleFetchParametersList}
                        />

                        {!!installationPoints.length &&
                            <React.Fragment>
                                <div className={"form-title-group"}>
                                    <span>Installation Points</span>
                                </div>
                                {installationPoints.map((installationPoint, index) =>
                                    <InstallationPointForm
                                        key={index}
                                        installationPoint={installationPoint}
                                        installationPointNameplates={_get(installationPoint, "nameplate", [])}
                                        nameplatesList={nameplates}
                                        updateNameplatesList={this.fetchNameplates}
                                        templates={templates}
                                        reloadEquipment={reloadEquipment}
                                        updateTemplates={this.fetchTemplates}
                                        parametersList={parametersList}
                                        updateParametersList={this.handleFetchParametersList}
                                    />
                                )}
                            </React.Fragment>
                        }
                    </div>
                }
            </React.Fragment>
        );
    }
}

NameplateModalForm.propTypes = {
    equipment: PropTypes.object,
    reloadEquipment: PropTypes.func
};

export default NameplateModalForm;