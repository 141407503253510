import React from "react";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {WosLabel} from "../../../../components/WosLabel";
import styles from "./labels.module.scss";
import {shallow} from "zustand/shallow";
import {usePointsData} from "../../../hooks/usePointsData";
import {useIsTachometer, useIsTemperature} from "../../../hooks/useChartTypes";
import {HeatsinkLabel} from "../../../../components/HeatsinkLabel";

export const Labels = () => {
    const settings = useChartStateStoreContext((state) => state.settings, shallow);
    const pointsData = usePointsData(settings.overlayPoints);

    const isWakeOnShakeActiveOnInstallationPoints = pointsData.reduce((res, item) => res || !!item.wosTrigger, false);
    const hasHeatsink = pointsData.reduce((res, item) => res || !!item.is_heatsink, false);

    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isTachometer = useIsTachometer(chartType);
    const isTemperature = useIsTemperature(chartType);

    const isShowWos = isWakeOnShakeActiveOnInstallationPoints && !isTachometer && !isTemperature;

    return <div className={styles.labelsWrapper}>
        {isShowWos && <WosLabel />}
        {hasHeatsink && <HeatsinkLabel/>}
    </div>;
};
