import React, {memo, useEffect, useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import {getEmptyImage} from "react-dnd-html5-backend";
import PropTypes from "prop-types";

const DraggableItem = ({children, elementIndex, onDragStart, onDrop, draggedElementRef, onDragEnd, name, dragFrom, isSorting}) => {
    const elementRef = useRef(null);

    // eslint-disable-next-line no-empty-pattern
    const [{}, dragRef, dragPreview] = useDrag({
        item: {
            type: name,
        },
        begin: () => {
            draggedElementRef.current.innerHTML = elementRef.current.innerHTML;
            draggedElementRef.current.style.maxWidth = elementRef.current.offsetWidth + "px";
            // Hiding element to div style broke drag when scrollbar position in bottom
            setTimeout(() => {
                elementRef.current.style.opacity = 0;
                elementRef.current.style.height = 0;
            }, 0);

            onDragStart(elementIndex);
        },
        end: () => {
            elementRef.current.style.opacity = 1;
            elementRef.current.style.height = "auto";

            draggedElementRef.current.innerHTML = "";
            onDragEnd();
        },
        canDrag: isSorting,
    });

    const [{isOver}, dropRef] = useDrop({
        accept: name,
        collect: (monitor) => ({isOver: !!monitor.isOver(), canDrop: !!monitor.canDrop()}),
        drop: () => onDrop(elementIndex),
    });

    useEffect(() => {
        // this useEffect hides the default preview
        dragPreview(getEmptyImage(), {captureDraggingState: true});
    }, []);

    return (
        <>
            <div
                ref={(e) => {
                    dragRef(e);
                    dropRef(e);
                    elementRef.current = e;
                }}
                style={{
                    paddingTop: isOver && elementIndex < dragFrom ? 50 : 0,
                    paddingBottom: isOver && elementIndex >= dragFrom ? 50 : 0,
                }}
            >
                {children}
            </div>
        </>
    );
};

DraggableItem.propTypes = {
    children: PropTypes.node,
    elementIndex: PropTypes.number,
    name: PropTypes.string,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    draggedElementRef: PropTypes.func,
    onDragEnd: PropTypes.func,
    dragFrom: PropTypes.number,
    isSorting: PropTypes.bool,
};

export default memo(DraggableItem);
