import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {shallow} from "zustand/shallow";
import {filter as _filter} from "lodash";
import {ALERT_TABS} from "../../modals/alert/constants/tabs";
import {indexOf as _indexOf} from "lodash";

const initialFormState = {};
const initialAlertGroup = {
    id: false,
    points: [],
};

const alertConditionStore = create(
    immer((set, get) => ({
        current: initialFormState,
        currentAlertGroup: initialAlertGroup,
        stdDeviations: false,
        tempAlertId: undefined,
        addBandTo: undefined,
        addTachometerRpmTo: undefined,
        confirmCancel: {},
        confirmRemove: false,
        reloadFlag: false,
        deleteId: null,
        selectedTab: ALERT_TABS[0].key,
        inProgress: false,
        currentIsChanged: false,
        actions: {
            setCurrent(current) {
                set((state) => {
                    state.current = current;
                });
            },
            setTab(tab) {
                set((state) => {
                    if (state.selectedTab === tab) {
                        return;
                    }
                    state.selectedTab = tab;
                });
            },
            setCurrentAlertGroup(currentAlertGroup) {
                set((state) => {
                    state.currentAlertGroup = currentAlertGroup;
                });
            },
            setPointsList(pointId, pointName) {
                const currentGroup = get().currentAlertGroup;
                let modifiedList = [...currentGroup.points];
                let modifiedNames = [...(currentGroup.names || [])];

                let value = parseInt(pointId);

                if (_indexOf(modifiedList, value) !== -1) {
                    modifiedList = _filter(modifiedList, (point) => {
                        return point !== value;
                    });
                } else {
                    modifiedList.push(value);
                }

                if (_indexOf(modifiedNames, pointName) !== -1) {
                    modifiedNames = _filter(modifiedNames, (pointNameCurrent) => {
                        return pointNameCurrent !== pointName;
                    });
                } else {
                    modifiedNames.push(pointName);
                }

                set((state) => {
                    state.currentAlertGroup = {...currentGroup, points: modifiedList, names: modifiedNames};
                });
            },
            setCurrentIsChanged(value) {
                set((state) => {
                    state.currentIsChanged = value;
                });
            },
            setDeleteId(id) {
                set((state) => {
                    state.deleteId = id;
                });
            },
            flushStore() {
                set((state) => {
                    state.currentAlertGroup = initialAlertGroup;
                    state.current = initialFormState;
                    state.selectedTab = ALERT_TABS[0].key;
                });
            },
            setStdDeviations(stdDeviations) {
                set((state) => {
                    state.stdDeviations = stdDeviations;
                });
            },
            setTempAlertId(id) {
                set((state) => {
                    state.tempAlertId = id;
                });
            },
            setInProgress(inProgress) {
                set((state) => {
                    state.inProgress = inProgress;
                });
            },
            setReloadFlag(reloadFlag) {
                set((state) => {
                    state.reloadFlag = reloadFlag;
                });
            },
            setAddBandTo(useAddBandTo) {
                set((state) => {
                    state.useAddBandTo = useAddBandTo;
                });
            },
            setAddTachometerRpmTo(addTachometerRpmTo) {
                set((state) => {
                    state.addTachometerRpmTo = addTachometerRpmTo;
                });
            },
            setConfirmCancel(confirmCancel) {
                set((state) => {
                    state.confirmCancel = confirmCancel;
                });
            },
            setConfirmRemove(confirmRemove) {
                set((state) => {
                    state.confirmRemove = confirmRemove;
                });
            },
            setRange({from, to}) {
                set((state) => {
                    state.current.fftBandTo = +to;
                    state.current.fftBandFrom = +from;
                });
            },
        },
    }))
);

export const useAlertConditionStoreActions = () => alertConditionStore((state) => state.actions, shallow);
export const useCurrentAlertCondition = () => alertConditionStore((state) => state.current, shallow);
export const useSelectedTab = () => alertConditionStore((state) => state.selectedTab, shallow);
export const useCurrentIsChanged = () => alertConditionStore((state) => state.currentIsChanged);
export const useCurrentAlertGroup = () => alertConditionStore((state) => state.currentAlertGroup, shallow);
export const useDeleteId = () => alertConditionStore((state) => state.deleteId, shallow);
export const useStdDeviations = () => alertConditionStore((state) => state.stdDeviations, shallow);
export const useTempAlertId = () => alertConditionStore((state) => state.tempAlertId, shallow);
export const useReloadFlag = () => alertConditionStore((state) => state.reloadFlag);
export const useAddBandTo = () => alertConditionStore((state) => state.useAddBandTo);
export const useAddTachometerRpmTo = () => alertConditionStore((state) => state.addTachometerRpmTo);
export const useConfirmCancel = () => alertConditionStore((state) => state.confirmCancel, shallow);
export const useConfirmRemove = () => alertConditionStore((state) => state.confirmRemove, shallow);
