export const ACTION = {
    SET_STATE: "setState",
    UPDATE_USER: "UPDATE USER",
};

export const reducer = (reducerState, action) => {
    const { state, type, callback = () => {} } = action;

    callback();
    switch (type) {
        case ACTION.SET_STATE:
            return { ...reducerState, ...state };
        case ACTION.UPDATE_USER:
            return { ...reducerState, user: {...reducerState.user, ...state}};
        default:
            throw new Error("Untyped dispatch call");
    }
};
