import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import { get as _get, find as _find, each as _each } from "lodash";
import EquipmentProfileApi from "../../../api/equipmentProfile";
import Loader from "../../../shared/loader/loader";
import Toast from "../../../pages/shared/toast";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectWrapper from "../../../helpers/select-wrapper";

class EquipmentProfileModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            equipments: [],
            profile: [],
            profileInfo: [],
            equipment: [],
            getValue: "",
            getValueEquipment: props.equipment.id,
            loader: true,
            selectedOption: {},
            getId: "",
            getSelectValue: null,
            inProgress: false,
            showEmptyConfirm: false,
        };

        this.handleValue = this.handleValue.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.associateEquipment = this.associateEquipment.bind(this);
    }

    componentDidMount() {
        Promise.all([this.fetchProfiles()]).then(() => this.setState({ loader: false }));
    }

    fetchProfiles() {
        return EquipmentProfileApi.list().then((response) => {
            if (response) {
                this.setState({
                    equipments: response,
                });
            }
        });
    }

    fetchProfileInfo() {
        this.setState({ loader: true });

        const { getValue } = this.state;

        return EquipmentProfileApi.getInfo(getValue).then((response) => {
            if (response) {
                const profileInstallationPoints = _get(
                    response,
                    "profile.profileInstallationPoints",
                    []
                );
                const selectedOption = {};
                const duplicateNames = {};

                for (let profileInstallationPoint of profileInstallationPoints) {
                    selectedOption[profileInstallationPoint.id] = null;

                    if (this.checkDuplicateName(_get(profileInstallationPoint, "name"))) {
                        duplicateNames[profileInstallationPoint.id] = true;
                    }
                }

                this.setState({
                    selectedOption,
                    duplicateNames,
                    profileInfo: _get(response, "profile", {}),
                    loader: false,
                });
            }
        });
    }

    checkDuplicateName = (name) => {
        const equipmentInstallationPoints = _get(this.props, "equipment.installationPoints", []);

        return !!_find(equipmentInstallationPoints, { name });
    };

    handleValue(e) {
        this.setState(
            {
                getValue: e.target.value,
            },
            () => {
                this.fetchProfileInfo();
            }
        );
    }

    selectChange(e) {
        const { ...selectedOption } = this.state.selectedOption;

        if (e.target.value) {
            selectedOption[e.target.name] = e.target.value;
        } else {
            selectedOption[e.target.name] = null;
        }

        this.setState({
            getId: e.target.name,
            getSelectValue: e.target.value,
            selectedOption,
        });
    }

    associateEquipment(confirmed = false) {
        const { onClose, invalidateEquipment } = this.props;
        const { getValueEquipment, getValue, selectedOption } = this.state;

        if (confirmed || !this.validateEmptyOptions()) {
            this.setState({ inProgress: true }, () => {
                EquipmentProfileApi.associateEquipmentProfile(getValue, getValueEquipment, {
                    installationPointsMap: selectedOption,
                }).then(() => {
                        invalidateEquipment();
                        Toast.success(
                            "The equipment has been associated with the equipment profile."
                        );
                        onClose();
                    })
                    .catch(() => {
                        this.setState({ inProgress: false });
                    });
            });
        }
    }

    validateEmptyOptions = () => {
        const { selectedOption } = this.state;

        let existEmpty = false;
        _each(selectedOption, (option) => {
            if (!option) {
                existEmpty = true;
            }
        });

        if (existEmpty) {
            this.setState({ showEmptyConfirm: true });
        }

        return existEmpty;
    };

    cancelEmptyOptionModal = () => {
        this.setState({ showEmptyConfirm: false });
    };

    render() {
        const { equipment, onClose } = this.props;
        const {
            equipments,
            getValue,
            profileInfo,
            loader,
            selectedOption,
            inProgress,
            showEmptyConfirm,
        } = this.state;

        return (
            <React.Fragment>
                <Modal
                    {...this.props}
                    size="lg"
                    toggle={onClose}
                    isOpen={true}
                    centered={false}
                    className={"custom-modal equipment-profile-modal"}
                    title="Associate Equipment with a Profile"
                >
                    <div>
                        <div className={"full-view-modal-header modal-header"}>
                            <h5 className={"modal-title"}>Associate Equipment with a Profile</h5>
                            <div className={"close"} onClick={onClose} />
                        </div>
                        <hr />
                        {loader ? (
                            <Loader />
                        ) : (
                            <React.Fragment>
                                <div className="d-flex justify-content-between pl-5 pr-5 pt-3">
                                    <SelectWrapper
                                        onChange={this.handleValue}
                                        value={getValue}
                                        name={"equipment-profile"}
                                        placeholder={"Select Equipment Profile"}
                                        style={{ width: "auto" }}
                                    >
                                        {/*<option>Select Equipment Profile</option>*/}
                                        {(equipments.list || []).map(
                                            (profile) =>
                                                !profile.isDraft && (
                                                    <option
                                                        key={profile.id}
                                                        id={profile.id}
                                                        value={profile.id}
                                                    >
                                                        {profile.name}
                                                    </option>
                                                )
                                        )}
                                    </SelectWrapper>
                                </div>

                                <div>
                                    {getValue && (
                                        <div className="d-flex justify-content-between px-5 custom-btn-pad">
                                            <div className="profile-block w-100 pb-3">
                                                <div className="d-flex justify-content-between align-items-center pt-1 mt-3 mb-3">
                                                    <div className={"w-50 font-weight-bold"}>
                                                        Equipment Profile Installation Points
                                                    </div>
                                                    <div className={"w-50 font-weight-bold"}>
                                                        Equipment Installation Points
                                                    </div>
                                                </div>

                                                {_get(
                                                    profileInfo,
                                                    "profileInstallationPoints",
                                                    []
                                                ).map((profileInstallationPoint, index) => (
                                                    <div
                                                        key={index}
                                                        className="d-flex justify-content-between align-items-center px-1 pb-1 mt-2 mb-4 form-group"
                                                    >
                                                        <div className={"w-50"}>
                                                            <div className="d-flex">
                                                                <div className="install-point-value">
                                                                    {profileInstallationPoint.name ||
                                                                        "---"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="equipment-block-installation w-50">
                                                            {equipment.installationPoints.length ===
                                                            0 ? (
                                                                <div>
                                                                    No Equipment Installation Points
                                                                </div>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <SelectWrapper
                                                                        name={
                                                                            profileInstallationPoint.id
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.selectChange(e)
                                                                        }
                                                                    >
                                                                        <option value={""}>
                                                                            Create New Installation
                                                                            Point
                                                                        </option>
                                                                        {(
                                                                            equipment.installationPoints ||
                                                                            []
                                                                        )
                                                                            .filter(
                                                                                (point) =>
                                                                                    _get(
                                                                                        point,
                                                                                        "point_type"
                                                                                    ) === "sensor"
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    installationPoint
                                                                                ) => (
                                                                                    <option
                                                                                        id={
                                                                                            installationPoint.id
                                                                                        }
                                                                                        key={
                                                                                            installationPoint.id
                                                                                        }
                                                                                        value={
                                                                                            installationPoint.id
                                                                                        }
                                                                                        disabled={
                                                                                            Object.values(
                                                                                                selectedOption
                                                                                            ).indexOf(
                                                                                                installationPoint.id.toString()
                                                                                            ) >=
                                                                                                0 &&
                                                                                            selectedOption[
                                                                                                profileInstallationPoint
                                                                                                    .id
                                                                                            ] !=
                                                                                                installationPoint.id
                                                                                        }
                                                                                    >
                                                                                        {installationPoint.name +
                                                                                            " (" +
                                                                                            installationPoint.sensor_hex +
                                                                                            ")"}
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                    </SelectWrapper>
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {getValue && (
                                    <div className="d-flex justify-content-end controls-btns-equipment">
                                        <button
                                            className={
                                                "btn btn-sm btn-success mr-1 mb-1" +
                                                (inProgress ? " disabled" : " ")
                                            }
                                            onClick={() => this.associateEquipment()}
                                        >
                                            Associate
                                            {!!inProgress && <i className="fa fa-spinner ml-2" />}
                                        </button>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    {showEmptyConfirm && (
                        <SweetAlert
                            info
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnBsStyle="default"
                            btnSize="xs"
                            title="ASSOCIATE EQUIPMENT"
                            openAnim={false}
                            closeAnim={false}
                            onConfirm={() => this.associateEquipment(true)}
                            onCancel={() => this.cancelEmptyOptionModal()}
                        >
                            Note that all unassociated installation points will be added to the
                            equipment. Do you want to continue?
                        </SweetAlert>
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}

EquipmentProfileModal.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
    profiles: PropTypes.array,
    equipment: PropTypes.object,
    invalidateEquipment: PropTypes.func,
};

export default EquipmentProfileModal;
