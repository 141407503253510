import React, {Component} from "react";
import SubscriptionDataHandler from "../network/action-items/subscription/data-handler";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../stores/GlobalStore";
import UserForm from "./form";
import ApiUser from "../../api/user";
import {
    get as _get,
    keys as _keys,
    each as _each
} from "lodash";
import ApiLocation from "../../api/location";
import Helper from "../../helpers/helper";

class UserFormWrapper extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            userId: this.props.match.params.userId ? this.props.match.params.userId : "",
            loader: true,
            user: {},
            facilityIds: [],
            assetTree: [],
            allFacility: false
        };
    }

    componentDidMount() {
        if (this.state.userId) {
            Promise.all([
                ApiUser.getSelfUser(this.props.match.params.userId),
                ApiLocation.list()
            ]).then(([{user}, assetTree]) => {
                assetTree = Helper.transformLocations(assetTree.list || []);
                this.setState({
                    user,
                    assetTree,
                    loader: false,
                    facilityIds: _keys(_get(user, "locations"), []),
                    allFacility: !!_get(user, "all_facilities", false)
                });
            });
        } else {
            Promise.all([
                ApiLocation.list()
            ]).then(([assetTree]) => {
                assetTree = Helper.transformLocations(assetTree.list || []);
                this.setState({
                    assetTree,
                    loader: false,
                });
            });
        }
    }

    updateFacilityIds = (data) => {
        const {user} = this.state;
        if (data === "all") {
            this.setState({facilityIds: [], allFacility: true});
        } else  {
            let facilityIds = [];
            let locations = {};
            _each(data, item => {
                if (item) {
                    facilityIds.push(""+_get(item, "value"));
                    locations[item.value] = _get(item, "label", "").replace("-", "");
                }
            });

            user.locations = locations;
            this.setState({facilityIds, user, allFacility: false});
        }
    }

    render() {
        const {user, loader, facilityIds, allFacility, assetTree} = this.state;
        const {updateUser, match, history} = this.props;

        if (loader) return "";

        return (
            <React.Fragment>
                <SubscriptionDataHandler
                    user={user}
                    updateUser={updateUser}
                    emailEvents={_get(this.props.user, "emailEvents")}
                    facilityIds={facilityIds}
                    assetTree={assetTree}
                    allFacility={allFacility}
                >
                    {(
                        loaderEmailEvents,
                        data,
                        urgencies,
                        facilities,
                        emailEvents,
                        selectedFacilityId,
                        changeFacility,
                        changeData,
                        onSave,
                        toggleAllForFacility,
                        toggleAll
                    ) =>
                        <UserForm
                            match={match}
                            history={history}
                            loaderEmailEvents={loaderEmailEvents || loader}
                            emailEventsData={data}
                            urgencies={urgencies}
                            facilities={facilities}
                            emailEvents={emailEvents}
                            assetTree={assetTree}
                            selectedFacilityId={selectedFacilityId}
                            changeFacility={changeFacility}
                            changeData={changeData}
                            onSave={onSave}
                            toggleAllForFacility={toggleAllForFacility}
                            toggleAll={toggleAll}
                            updateFacilityIds={this.updateFacilityIds}
                        />
                    }
                </SubscriptionDataHandler>
            </React.Fragment>
        );
    }
}

UserFormWrapper.propTypes = {
    user: PropTypes.object,
    auth: PropTypes.object,
    updateUser: PropTypes.func,
    match: PropTypes.object,
    history: PropTypes.object
};

export default withGlobalStore(UserFormWrapper);