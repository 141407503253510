import {useModal} from "react-modal-hook";
import SweetAlert from "react-bootstrap-sweetalert";
import {useChartStateStoreContext} from "../../../pages/chart/highcharts/simple-new/store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import React from "react";
import {useChartMarksDeleteMutation} from "../../../hooks/api/chartMarksSimple/useChartMarksDeleteMutation";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";

export const useDeleteChartMarkSimpleModal = () => {
    const {equipmentItem} = useEquipmentByParams();
    const {setChartMarkDeleteId} = useChartStateStoreContext((state) => state.actions);
    const deleteChartMarkId = useChartStateStoreContext((state) => state.settings.deleteChartMarkId, shallow);


    const [showModal, hideModal] = useModal(() => {
        const {mutateAsync: deleteChartMarkMutate, isLoading} = useChartMarksDeleteMutation(equipmentItem.id);
        const deleteChartMark = () => {
            deleteChartMarkMutate(deleteChartMarkId).then(() => {
                setChartMarkDeleteId(null);
                onHide();
            });
        };
        return (
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnBsStyle="default"
                btnSize="xs"
                title="DELETE MARKED TIME"
                onConfirm={deleteChartMark}
                onCancel={onHide}
                disabled={isLoading}
            >
                Are you sure you want to delete this marked time?
            </SweetAlert>
        );
    }, [deleteChartMarkId]);

    const onHide = () => {
        hideModal();
        setChartMarkDeleteId(null);
    };

    return [showModal, onHide];
};
