import React, {Component} from "react";
import PropTypes from "prop-types";
import TextEditor from "../../../../../shared/textEditor/textEditor";
import {
    get as _get,
    each as _each
} from "lodash";
import {ValidationError} from "../../../../../shared";
import Dropzone from "react-dropzone";
import FileManagerApi from "../../../../../api/fileManager";
import Toast from "../../../../shared/toast";


class CompletionForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDescription: true,
            inProgress: false
        };
    }

    onChangeOtherDescription = (text) => {
        const {onChangeDataWithReport} = this.props;

        onChangeDataWithReport({
            target: {
                name: "ww_action_list_other_reason_group_text",
                value: text
            }
        });
    }

    toggleDescription = () => {
        const {showDescription} = this.state;

        this.setState({showDescription: !showDescription});
    }

    onDrop = (files) => {
        const {onChangeDataWithReport, dataWithReport} = this.props;
        const reportFiles = [..._get(dataWithReport, "file_name", [])];

        this.setState({inProgress: true});
        Promise.all(Object.keys(files).map(file => new Promise((resolve, reject) => {
            if (files[file]) {
                let formData = new FormData();
                formData.append("file", files[file]);

                FileManagerApi
                    .uploadFile(formData)
                    .then((response) => {
                        if (_get(response, "status") === "error") {
                            reject(_get(response, "message", ""));
                        } else {
                            resolve(_get(response, "file", ""));
                        }

                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                reject("File is empty");
            }
        }))).then((files = []) => {
            _each(files, (file) => {
                reportFiles.push({
                    path: _get(file, "url"),
                    name: _get(file, "name") + "." + _get(file, "extension"),
                });
            });

            onChangeDataWithReport({
                target: {
                    name: "file_name",
                    value: reportFiles
                }
            });
            this.setState({inProgress: false});
        }).catch((error) => {
            Toast.error(error);
            this.setState({inProgress: false});
        });
    }

    removeFile = (index) => {
        const {onChangeDataWithReport, dataWithReport} = this.props;
        const reportFiles = [..._get(dataWithReport, "file_name", [])];

        reportFiles.splice(index, 1);
        onChangeDataWithReport({
            target: {
                name: "file_name",
                value: reportFiles
            }
        });
    }

    render() {
        const {showDescription, inProgress} = this.state;
        const {
            actionItem,
            noActions,
            dataWithoutReport,
            changeNoActions,
            changeWithActions,
            onChangeDescription,
            withActions,
            dataWithReport,
            validation,
        } = this.props;

        const descriptionError = !withActions ? _get(validation, "description.message", "") : _get(validation, "text.message", "");

        return (
            <React.Fragment>
                <div className={"action-item-body"}>
                    <div>
                        <div className={"ai-title-second action"} onClick={this.toggleDescription}>
                            <div>
                                <span className={"mr-2"}>Action Item Description </span>
                                <i className={showDescription ? "fa fa-angle-up" : "fa fa-angle-down"}/>
                            </div>
                        </div>
                    </div>
                    {showDescription &&
                    <div className={"mt-2 mb-2 ai-description"}>
                        <div dangerouslySetInnerHTML={{__html: _get(actionItem, "description")}}/>
                    </div>
                    }

                    <div className="form-group row completion-form">
                        <div className="col-12">
                            <label className="form-label">Describe any maintenance that was completed or actions
                                that were taken: <span className="color-danger">*</span></label>
                            <div className={"validation-control" + (descriptionError ? " is-invalid" : "")}>
                                <TextEditor
                                    content={noActions ? dataWithoutReport.description : dataWithReport.text}
                                    onChange={onChangeDescription}
                                />
                            </div>
                            <ValidationError message={descriptionError}/>
                        </div>

                        <div className="col-12">
                            <label className="form-label">Note it is REQUIRED to select one of these boxes: <span className="color-danger">*</span></label>
                            <div
                                className={"validation-control" + (_get(validation, "action.message", "") ? " is-invalid" : "")}>
                                <div>
                                    <label className="form-checkbox">
                                        <input
                                            type="checkbox"
                                            checked={noActions}
                                            onChange={changeNoActions}
                                        />No action required<span/>
                                    </label>
                                </div>
                                <div>
                                    <label className="form-checkbox">
                                        <input
                                            type="checkbox"
                                            checked={withActions}
                                            onChange={changeWithActions}
                                        />Maintenance was conducted on Waites equipment<span/>
                                    </label>
                                </div>
                            </div>
                            <ValidationError message={_get(validation, "action.message", "")}/>
                        </div>

                        {withActions &&
                            <React.Fragment>
                                <div className={"col-12"}>
                                    <label>Attach a File:</label>
                                    <div>
                                        {_get(dataWithReport, "file_name", []).map((file, key) =>
                                            <React.Fragment key={key}>
                                                <div className={"d-inline mr-3"}>
                                                    <a
                                                        href={file.path}
                                                        target={"_blank"}
                                                        rel={"noreferrer"}
                                                        className={"link link-primary mr-2"}
                                                    >
                                                        {file.name}
                                                    </a>
                                                    <i className={"fa fa-trash"} onClick={() => this.removeFile(key)}/>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <Dropzone onDrop={this.onDrop}>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div className="dropzone dropzone-custom" {...getRootProps()}>
                                                    {inProgress
                                                        ?
                                                        <div>
                                                            <span className="dropzone-icon"><i
                                                                className="fa fa-spinner" /></span>
                                                            <p><b>{"Uploading..."}</b></p>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span className="dropzone-icon"><i
                                                                className="fa fa-cloud-upload-alt"/></span>
                                                            <input {...getInputProps()} />
                                                            <p><b>{"Select a File to Upload."}</b></p>
                                                        </div>
                                                    }
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

CompletionForm.propTypes = {
    actionItem: PropTypes.object,
    info: PropTypes.object,
    noActions: PropTypes.bool,
    dataWithoutReport: PropTypes.object,
    withActions: PropTypes.bool,
    dataWithReport: PropTypes.object,
    changeNoActions: PropTypes.func,
    changeWithActions: PropTypes.func,
    onChangeDescription: PropTypes.func,
    onChangeDataWithReport: PropTypes.func,
    validation: PropTypes.object
};

export default CompletionForm;