import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal} from "../../../shared";
import NameplateModalForm from "../../../pages/forms/nameplate/modal-form";

class NameplateModal extends Component
{

    render() {
        {/*TODO: Reload Equipment add here invalidate hook equipment*/}
        const {equipment, reloadEquipment} = this.props;

        return (
            <React.Fragment>
                <Modal
                    {...this.props}
                    size="lg"
                    title={"Nameplates"}
                    withoutFooter={true}
                >
                    <NameplateModalForm
                        equipment={equipment}
                        reloadEquipment={reloadEquipment}
                    />
                </Modal>
            </React.Fragment>
        );
    }
}

NameplateModal.propTypes = {
    showModal: PropTypes.bool,
    equipment: PropTypes.object,
    reloadEquipment: PropTypes.func,
    onClose: PropTypes.func,
};

export default NameplateModal;