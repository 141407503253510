import {useQuery} from "@tanstack/react-query";
import ApiAlarm from "../../../api/alarm";
import {get as _get} from "lodash";
import {ALARM_API} from "./constants";

const useAlarmListQuery = (equipmentId) => {
    const {isSuccess, data: alarmList} = useQuery([ALARM_API.GET_ALARM_LIST, +equipmentId], () =>
        ApiAlarm.getList(
            {
                query: {
                    filter: {
                        equipmentId: +equipmentId,
                    },
                    count: 1000,
                },
            },
            (response) => response.data
        )
    );

    const activeAlarms = _get(alarmList, "list", []);
    const settingsStates = _get(alarmList, "settingsStates", {});
    const countActiveEquipmentAlarms = +_get(alarmList, "meta.total", 0);

    return {
        isSuccess,
        activeAlarms,
        settingsStates,
        countActiveEquipmentAlarms,
    };
};

export default useAlarmListQuery;
