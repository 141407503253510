import $ from "jquery";
import React, { Component, useRef, useState } from "react";
import PropTypes from "prop-types";
import SearchInput from "../../shared/search-input";
import ApiDeviceAlert from "../../../api/deviceAlert";
import ApiDeviceAlertFilter from "../../../api/deviceAlertFilter";
import { Loader, Pager } from "../../../shared";
import LimitSelect from "../../shared/limit-select";
import { ResetSortButton } from "../../shared/resetSortButton";
import { get as _get, each as _each } from "lodash";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    PopoverBody,
    UncontrolledDropdown,
    UncontrolledPopover,
    UncontrolledTooltip,
} from "reactstrap";
import AcknowledgeModal from "../../../modals/alarms/acknowledge";
import Helper from "../../../helpers/helper";
import IgnoreModal from "../../../modals/alarms/ignore";
import { withGlobalStore } from "../../../stores/GlobalStore";
import CommentsPopover from "./comments/comments-popover";
import { Link } from "react-router-dom";
import NotificationEmailsPopover from "./emails-popover";
import Toast from "../../shared/toast";
import { withLocationSelectStore } from "../../../stores/LocationSelectStore";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import SelectWrapper from "../../../helpers/select-wrapper";
import { HeaderSimple } from "../../../shared/header";

const devices = {
    all: "All Hardware",
    gateway: "Gateways",
    router: "Repeaters",
    node: "Nodes",
    sensor: "Tethered Sensors",
    mote: "Sensor Motes",
};

const deviceName = {
    gateway: "Gateway",
    router: "Repeater",
    node: "Node",
    sensor: "Tethered Sensor",
    mote: "Sensor Mote",
};

class ServiceNotifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{ name: "Event Log" }],
            globalSearchString: "",
            activeTab: Object.keys(devices)[0],
            list: [],
            pagination: {
                page: 1,
                pages: 1,
                perpage: Helper.getPerPage("event-log"),
                total: 0,
            },
            sort: {
                field: "",
                sort: "asc",
            },
            filter: {
                query: "",
                alertType: "",
                deviceType: "",
                locationId: props.getLocationId(),
            },
            isHistory: Helper.getHashParams().history ? 1 : 0,
            loader: true,
            filterOptions: [],
            selected: [],
            excluded: [],
            selectedType: null,
        };

        this.finished = false;
    }

    componentDidMount() {
        this.fetchFilters();
        this.fetch();
    }

    fetch = () => {
        const { activeTab, sort, filter, pagination, isHistory } = this.state;

        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        ApiDeviceAlert.getList({
            query: {
                type: activeTab,
                filter,
                sort,
                pagination,
                isHistory,
            },
            signal: this.signal,
        }).then((response) => {
            if (response) {
                this.setState(
                    {
                        loader: false,
                        pagination: { ...pagination, ...(response.meta || {}) },
                        list: response.list || [],
                        selected: [],
                        excluded: [],
                        selectedType: null,
                    },
                    () => {
                        if (!this.finished) this.finished = true;
                        this.listUpdated();
                    }
                );
            }
        });
    };

    export = () => {
        const { activeTab, isHistory } = this.state;

        ApiDeviceAlert.exportList({
            query: {
                type: activeTab,
                isHistory,
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "device-alert-list.csv");
                link.setAttribute("id", "test-file");
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    };

    listUpdated = () => {
        const { list, activeTab } = this.state;
        const { countActiveDeviceAlerts, updateCountActiveDeviceAlerts } = this.props;

        const key = Object.keys(devices).find((key) => devices[key] === activeTab);

        if (+countActiveDeviceAlerts[key] !== list.length + 1) {
            updateCountActiveDeviceAlerts();
        }
    };

    fetchFilters = () => {
        Promise.all([ApiDeviceAlertFilter.list()]).then(([filterResponse = {}]) => {
            let filterOptions = filterResponse;
            this.setState({ filterOptions });
        });
    };

    changeActiveTab = (activeTab) => {
        this.setState(
            {
                activeTab,
                pagination: {
                    page: 1,
                    pages: 1,
                    perpage: 10,
                    total: 0,
                },
                sort: {
                    field: "",
                    sort: "asc",
                },
                filter: {
                    query: "",
                    alertType: "",
                    deviceType: "",
                    locationId: this.props.getLocationId(),
                },
                isHistory: Helper.getHashParams().history ? 1 : 0,
                loader: true,
                selected: [],
                excluded: [],
                selectedType: null,
            },
            this.fetch
        );
    };

    changeSort = (field = "") => {
        this.setState(
            {
                sort: {
                    field,
                    sort:
                        field && field === this.state.sort.field
                            ? this.state.sort.sort === "asc"
                                ? "desc"
                                : "asc"
                            : "asc",
                },
                loader: true,
            },
            this.fetch
        );
    };

    changeLimit = (value) => {
        Helper.setPerPage("event-log", value);
        this.setState(
            {
                pagination: {
                    ...this.state.pagination,
                    page: 1,
                    perpage: value,
                },
                loader: true,
            },
            this.fetch
        );
    };

    changePager = (page) => {
        this.setState(
            {
                pagination: {
                    ...this.state.pagination,
                    page: page || 1,
                },
                loader: true,
            },
            this.fetch
        );
    };

    bindSearch = (value) => {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }
        this.changeFilter({ query: value });
    };

    changeFilter = (values) => {
        this.finished = false;
        this.setState(
            {
                pagination: { ...this.state.pagination, page: 1 },
                filter: { ...this.state.filter, ...values },
                loader: true,
            },
            this.fetch
        );
    };

    filtersApplied = () => {
        return (
            this.state.filter.deviceType !== "" ||
            this.state.filter.alertType !== "" ||
            this.state.filter.locationId !== ""
        );
    };

    reset = () => {
        this.changeFilter({
            alertType: "",
            deviceType: "",
            locationId: "",
        });
    };

    submitAcknowledgeModal = (data) => {
        const { selected } = Helper.getHashParams();
        const { selectedType, filter, excluded } = this.state;

        if (selected || selectedType === "full-all") {
            data["ids"] = selectedType === "full-all" ? "full-all" : this.state.selected;
            data["filters"] = filter;
            data["excluded"] = excluded;
        }

        return ApiDeviceAlert.acknowledge(data).then(() => {
            this.fetch();
            this.props.history.push(Helper.deleteHashParams(["modalAlarm", "id"]));
            this.props.updateCountActiveDeviceAlerts();
            Toast.success("Notification has been successfully acknowledge.");
        });
    };

    submitIgnoreModal = (data) => {
        const { selected } = Helper.getHashParams();
        const { selectedType, filter, excluded } = this.state;

        if (selected || selectedType === "full-all") {
            data["ids"] = selectedType === "full-all" ? "full-all" : this.state.selected;
            data["filters"] = filter;
            data["excluded"] = excluded;
        }

        data["datetime"] = new Date(_get(data, "datetime", "")).toISOString();

        return ApiDeviceAlert.ignore(data).then(() => {
            this.fetch();
            this.props.history.push(Helper.deleteHashParams(["modalAlarm", "id"]));
            this.props.updateCountActiveDeviceAlerts();
            Toast.success("Notification has been successfully ignored.");
        });
    };

    updateDeviceAlertComments = (id, comments) => {
        let { list } = this.state;

        const index = list.findIndex((x) => +x.id === +id);

        if (index > -1) {
            list[index].comments = comments || [];

            this.setState({ list });
        }
    };

    handleSelectedType = (selectedType) => {
        let { list, selected } = { ...this.state };
        selected = [];

        if (selectedType === "all" || selectedType === "full-all") {
            list.map((item) => selected.push(parseInt(item.id)));
        }

        this.setState({ selectedType, selected });
    };

    handleCheckboxes = (e) => {
        const { value } = e.target;
        let { selected, excluded, selectedType } = { ...this.state };

        if (selectedType === "full-all") {
            const index = excluded.indexOf(parseInt(value));
            selected = [];
            if (index !== -1) {
                excluded.splice(index, 1);
            } else {
                excluded.push(parseInt(value));
            }
        } else {
            const index = selected.indexOf(parseInt(value));

            if (index !== -1) {
                selected.splice(index, 1);
            } else {
                selected.push(parseInt(value));
            }
        }

        this.setState({
            selected,
            excluded,
        });
    };

    handleAcknowledgeModal = () => {
        this.props.history.push(Helper.setHashParams({ modalAlarm: "ack", selected: "all" }));
    };

    handleIgnoreModal = () => {
        this.props.history.push(Helper.setHashParams({ modalAlarm: "ignore", selected: "all" }));
    };

    handleHistory = () => {
        const isHistory = this.state.isHistory;

        if (!isHistory) {
            this.props.history.push(Helper.setHashParams({ history: "1" }));
        } else {
            this.props.history.push(Helper.deleteHashParams(["history"]));
        }

        this.setState(
            {
                isHistory: +!isHistory,
                pagination: {
                    page: 1,
                    pages: 1,
                    perpage: 10,
                    total: 0,
                },
                loader: true,
                selected: [],
                excluded: [],
                selectedType: null,
            },
            this.fetch
        );
    };

    getDeviceFilter = () => {
        const deviceFilter = [];

        _each(deviceName, (deviceName, key) => {
            deviceFilter.push({
                value: key,
                label: deviceName,
            });
        });

        return deviceFilter;
    };

    render() {
        const {
            sort,
            list,
            filter,
            loader,
            isHistory,
            activeTab,
            pagination,
            breadcrumbs,
            globalSearchString,
            filterOptions,
            selectedType,
            selected,
            excluded,
        } = this.state;

        const { auth, history, countActiveDeviceAlerts } = this.props;

        const searchParams = Helper.getHashParams();
        const deviceFilter = this.getDeviceFilter();

        return (
            <React.Fragment>
                <HeaderSimple
                    breadcrumbs={breadcrumbs}
                    globalSearchString={globalSearchString}
                    handleChangeGlobalSearch={(globalSearchString) =>
                        this.setState({ globalSearchString })
                    }
                />
                <div className="subheader">
                    <div className="subheader-title">Event Log</div>
                </div>
                <div className="block">
                    <div className="block-header">
                        <div className="block-label">
                            <div className="axis-block-container axis-block-container--network">
                                {Object.keys(devices).map((key) => (
                                    <div
                                        key={key}
                                        className={`axis-block${
                                            activeTab === key ? " active" : ""
                                        }`}
                                        onClick={() => this.changeActiveTab(key)}
                                    >
                                        <span>
                                            {countActiveDeviceAlerts[key] > 0 && (
                                                <span className="axis-block-count bg-danger color-white-c">
                                                    <span>{countActiveDeviceAlerts[key]}</span>
                                                </span>
                                            )}
                                            {devices[key]}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="block-header">
                        <div className="block-label">
                            <div className="form-list form-list--row">
                                {!isHistory && (
                                    <div className="form-group form-group--inline checkbox-drop">
                                        <label className="form-checkbox form-checkbox--filter">
                                            {selected.length > 0 ? (
                                                <input
                                                    className={"uncheck"}
                                                    type="checkbox"
                                                    checked="checked"
                                                    onChange={() => this.handleSelectedType(null)}
                                                />
                                            ) : (
                                                <input
                                                    checked={selectedType === "all"}
                                                    onChange={(e) =>
                                                        this.handleSelectedType(
                                                            $(e.target).prop("checked")
                                                                ? "all"
                                                                : null
                                                        )
                                                    }
                                                    type="checkbox"
                                                />
                                            )}
                                            <span />
                                        </label>
                                        <div
                                            onClick={() =>
                                                this.handleSelectedType(
                                                    selectedType === "all" || selected.length > 0
                                                        ? null
                                                        : "all"
                                                )
                                            }
                                        >
                                            Select
                                        </div>
                                    </div>
                                )}
                                <div className="form-group form-group--inline">
                                    <div className="form-label">
                                        <label>Asset Tree Branch:</label>
                                    </div>
                                    <CollapseLocationSelect
                                        className={"form-control form-control-sm"}
                                        selectName={"locationId"}
                                        value={+_get(filter, ["locationId"], 0)}
                                        onChange={(e) => {
                                            this.changeFilter({
                                                locationId: _get(e, "target.value"),
                                            });
                                        }}
                                        emptyOptionLabel={"All"}
                                        checkStoreValue={true}
                                        withFavorite={true}
                                    />
                                </div>
                                {((filterOptions.alertType || {})[activeTab] || []).length > 1 && (
                                    <NotificationFilter
                                        name="alertType"
                                        value={filter.alertType}
                                        label="Issue"
                                        options={filterOptions.alertType[activeTab]}
                                        selected={this.changeFilter}
                                    />
                                )}
                                {deviceFilter.length && activeTab === "all" && (
                                    <NotificationFilter
                                        name="deviceType"
                                        value={filter.deviceType}
                                        label="Hardware"
                                        options={deviceFilter}
                                        selected={this.changeFilter}
                                    />
                                )}

                                {this.filtersApplied() && (
                                    <div>
                                        <div className="form-group">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={this.reset}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div id="top-pagination-block" className="block-pagination">
                            {pagination.total > 10 && (
                                <div className="limit-select-block">
                                    <LimitSelect
                                        onChange={(e) => this.changeLimit(e.target.value)}
                                        defaultValue={pagination.perpage}
                                        limits={[10, 20, 50, 100]}
                                    />
                                </div>
                            )}
                            {pagination.pages > 1 && (
                                <Pager
                                    page={pagination.page}
                                    pages={pagination.pages}
                                    onPagerChange={this.changePager}
                                />
                            )}
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <div style={{ minWidth: "180px" }}>
                                    <SearchInput
                                        onChange={this.bindSearch}
                                        query={filter.query || ""}
                                        placeholder="Search Notifications"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-header">
                        <div className="custom-alert-block">
                            {(selectedType || selected.length > 0) && !isHistory && (
                                <div className="alert alert-outline-warning fade show" role="alert">
                                    <div className="alert-icon">
                                        <i className="flaticon-warning" />
                                    </div>
                                    <div className="alert-text">
                                        <strong>
                                            {selectedType === "full-all"
                                                ? pagination.total - excluded.length
                                                : selected.length}
                                        </strong>{" "}
                                        {selected.length < 2 ? "alert has" : "alerts have"} been
                                        selected.
                                        {selectedType === "full-all" ||
                                        selected.length === pagination.total ? (
                                            <span
                                                onClick={() => this.handleSelectedType(null)}
                                                className={
                                                    "font-weight-bold cursor-pointer text-underline"
                                                }
                                            >
                                                Clear selection
                                            </span>
                                        ) : (
                                            <span
                                                onClick={() => this.handleSelectedType("full-all")}
                                                className={
                                                    "font-weight-bold cursor-pointer text-underline"
                                                }
                                            >
                                                Click here to select all {pagination.total} alerts.
                                            </span>
                                        )}
                                    </div>
                                    <div className="alert-buttons">
                                        <button
                                            className="link color-success"
                                            onClick={this.handleAcknowledgeModal}
                                        >
                                            <i className="fa fa-check" />
                                            <span>Acknowledge Selected</span>
                                        </button>
                                        <button
                                            className="link link-secondary"
                                            onClick={this.handleIgnoreModal}
                                        >
                                            <i className="fa fa-times" />
                                            <span>Ignore Selected</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={"d-flex align-items-center"}>
                            <button
                                className="btn btn-sm btn-primary mr-2"
                                onClick={this.handleHistory}
                            >
                                {isHistory ? "Active" : "History"}
                            </button>
                            <UncontrolledDropdown className="d-inline-block">
                                <DropdownToggle
                                    tag={"span"}
                                    title={"Download"}
                                    style={{ padding: "5px", fontSize: "1.5rem" }}
                                >
                                    <i className="fa fa-bars" />
                                </DropdownToggle>
                                <DropdownMenu right={true} className={"notification-dropdown"}>
                                    <DropdownItem onClick={this.export}>Download CSV</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                    <div className="block-body">
                        {loader ? (
                            <Loader />
                        ) : (
                            <Table
                                history={history}
                                auth={auth}
                                list={list}
                                sort={sort}
                                type={activeTab}
                                isHistory={isHistory}
                                activeTab={activeTab}
                                onSortChange={this.changeSort}
                                updateDeviceAlertComments={this.updateDeviceAlertComments}
                                handleCheckbox={this.handleCheckboxes}
                                selected={selected}
                                excluded={excluded}
                                selectedType={selectedType}
                            />
                        )}
                    </div>
                </div>
                {searchParams.modalAlarm === "ack" && (
                    <AcknowledgeModal
                        title="Acknowledge Alert"
                        showModal={true}
                        submitTitle="Acknowledge"
                        onSubmit={(data) =>
                            this.submitAcknowledgeModal({ ...data, id: searchParams.id })
                        }
                        onClose={() =>
                            history.push(Helper.deleteHashParams(["id", "modalAlarm", "selected"]))
                        }
                    />
                )}
                {searchParams.modalAlarm === "ignore" && (
                    <IgnoreModal
                        title="Ignore Alert"
                        showModal={true}
                        submitTitle="Ignore"
                        onSubmit={(data) =>
                            this.submitIgnoreModal({ ...data, id: searchParams.id })
                        }
                        onClose={() =>
                            history.push(Helper.deleteHashParams(["id", "modalAlarm", "selected"]))
                        }
                    />
                )}
            </React.Fragment>
        );
    }
}

const headersList = {
    alert_type: { title: "Issue", sort: true },
    device_type: { title: "Hardware", sort: true },
    located: { title: "Location", sort: true },
    equipment_name: { title: "Equipment", sort: true },
    name: { title: "Installation Point", sort: true },
    serial: { title: "Serial Number", sort: true },
    status: { title: "Status", sort: true },
    last_seen: { title: "Last Seen", sort: true },
    created_at: { title: "Created", sort: true },
    comments: { title: "Comments", sort: false },
    sent_history: { title: "Send Email", sort: false },
    name_full: { title: "Acknowledged by", sort: true },
    acknowledged_at: { title: "Acknowledged Time", sort: true },
    actions: {
        title: "Actions",
        sort: false,
        additionalClasses: "table-buttons-th",
        component: ResetSortButton,
    },
};

const Table = ({
    history,
    auth,
    list,
    sort,
    type,
    isHistory,
    onSortChange,
    activeTab,
    updateDeviceAlertComments,
    handleCheckbox,
    selected,
    excluded,
    selectedType,
}) => {
    return (
        <div className="row">
            <div className="col-12">
                <table className="table table-hover tl-fixed">
                    <thead>
                        <tr>
                            {Object.keys(headersList).map((key) => {
                                if (
                                    (key !== "name" ||
                                        activeTab === "gateway" ||
                                        activeTab === "sensor") &&
                                    (key !== "device_type" || activeTab === "all") &&
                                    (key !== "equipment_name" ||
                                        activeTab === "mote" ||
                                        activeTab === "sensor")
                                ) {
                                    let component = "";
                                    const RowComponent = _get(
                                        headersList,
                                        [key, "component"],
                                        false
                                    );
                                    if (RowComponent) {
                                        component = (
                                            <RowComponent
                                                sort={sort}
                                                resetSort={() => onSortChange("")}
                                            />
                                        );
                                    }
                                    return headersList[key].sort ? (
                                        (["status", "name_full", "acknowledged_at"].indexOf(key) ===
                                            -1 ||
                                            !!isHistory) && (
                                            <th
                                                className={headersList[key].additionalClasses || ""}
                                                key={key}
                                                style={headersList[key].style || {}}
                                                onClick={() => onSortChange(key)}
                                            >
                                                <div className="cursor-pointer">
                                                    {type === "gateway" && key === "name"
                                                        ? "Name"
                                                        : headersList[key].title}
                                                    {sort.field === key ? (
                                                        <i
                                                            className={
                                                                "fa fa-sort" +
                                                                (sort.sort === "asc"
                                                                    ? "-up"
                                                                    : "-down")
                                                            }
                                                        />
                                                    ) : (
                                                        <i className="fa fa-sort" />
                                                    )}
                                                </div>
                                            </th>
                                        )
                                    ) : (
                                        <th
                                            className={headersList[key].additionalClasses || ""}
                                            key={key}
                                            style={{
                                                ...(headersList[key].style || {}),
                                                ...(key === "actions"
                                                    ? { maxWidth: !isHistory ? 250 : 70 }
                                                    : {}),
                                            }}
                                        >
                                            {headersList[key].title} {component}
                                        </th>
                                    );
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {(list || []).length > 0 ? (
                            list.map((item = {}) => (
                                <Row
                                    key={item.id}
                                    history={history}
                                    auth={auth}
                                    item={item}
                                    isHistory={isHistory}
                                    activeTab={activeTab}
                                    updateDeviceAlertComments={updateDeviceAlertComments}
                                    handleCheckbox={handleCheckbox}
                                    selected={selected}
                                    excluded={excluded}
                                    selectedType={selectedType}
                                />
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan={Object.keys(headersList).length}
                                    className="text-center text-info"
                                >
                                    No events exist.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

Table.propTypes = {
    history: PropTypes.object,
    auth: PropTypes.object,
    list: PropTypes.array,
    sort: PropTypes.object,
    type: PropTypes.string,
    isHistory: PropTypes.number,
    onSortChange: PropTypes.func,
    activeTab: PropTypes.string,
    updateDeviceAlertComments: PropTypes.func,
    handleCheckbox: PropTypes.func,
    selected: PropTypes.array,
    excluded: PropTypes.array,
    selectedType: PropTypes.string,
};

const alertType = ["Offline", "Battery", "Bad Signal", "Low Battery"];
const alertTypeTitle = [
    "Not reported for more than 1 hour",
    "Switched on battery power",
    "Weak signal from this :device",
    "Weak battery",
];
const alertLink = {
    gateway: "/network/gateways/",
    router: "/network/routers/",
    node: "/network/nodes/",
    mote: "/network/motes/",
    sensor: "/network/sensors/",
};

const Row = ({
    item,
    isHistory,
    history,
    auth,
    activeTab,
    updateDeviceAlertComments,
    handleCheckbox,
    selected,
    excluded,
    selectedType,
}) => {
    let title = alertTypeTitle[item.alert_type];

    if (+item.alert_type === 2) {
        const device = activeTab[0].toUpperCase() + activeTab.slice(1);
        title = title.replace(/:device/g, device);
    }

    let addToLocation = "";
    if (item.name)
        addToLocation =
            activeTab === "all" && (item.device_type === "gateway" || item.device_type === "sensor")
                ? " - " + item.name
                : "";

    let viewLink = "";
    if (item.device_type !== "sensor" || (!!item.equipment_id && !!item.installation_point_id)) {
        const serial = item.device_type === "sensor" ? "#serial=" + item.serial : item.serial;
        viewLink = alertLink[item.device_type] + serial;
    }

    const popover = useRef(null);

    let blocked = () => {
        return selected.length > 0 || selectedType;
    };

    return (
        <tr>
            <td title={title}>
                {!isHistory && (
                    <label className="form-checkbox">
                        <input
                            checked={
                                selected.indexOf(+item.id) !== -1 ||
                                (selectedType === "full-all" && excluded.indexOf(+item.id) === -1)
                            }
                            type="checkbox"
                            onChange={handleCheckbox}
                            value={item.id}
                        />
                        <span />
                    </label>
                )}
                {alertType[item.alert_type]}
            </td>
            {activeTab === "all" && <td>{_get(deviceName, item.device_type)}</td>}
            <td>
                {item.located}
                {addToLocation}
            </td>
            {!!(activeTab === "mote" || activeTab === "sensor") && (
                <td>{_get(item, "equipment_name")}</td>
            )}
            {!!(activeTab === "gateway" || activeTab === "sensor") && <td>{item.name}</td>}
            <td>
                {_get(item, "device_type") === "mote"
                    ? _get(item, "hex_serial")
                    : _get(item, "serial")}
            </td>
            {!!isHistory && <Status item={item} />}
            <td>{Helper.dateToUserFormat(item.last_seen, auth)}</td>
            <td>{Helper.dateToUserFormat(item.created_at, auth)}</td>
            <td>
                <div className="header-rules">
                    <button ref={popover} title="Comments" className="btn btn-sm btn-fa-icon">
                        <i className="fa fa-file" />
                    </button>
                    <span className="fa-layers-counter">{(item.comments || []).length}</span>

                    {!!(popover.current && (!isHistory || (item.comments || []).length)) && (
                        <CommentsPopover
                            popoverProps={{
                                target: popover.current,
                                placement: "bottom-end",
                            }}
                            deviceAlertId={+item.id}
                            user={auth}
                            history={history}
                            isHistory={isHistory}
                            comments={item.comments || []}
                            onActionCallback={updateDeviceAlertComments}
                        />
                    )}
                </div>
            </td>
            <td className={`emails-line-${item.id}`}>
                <span
                    className={"cursor-pointer emails-popover"}
                    id={`popover-${item.id}`}
                    title={"Show Emails"}
                >
                    <span className="fa-layers fa-fw">
                        &nbsp;
                        {/* <img className={"table-image-icon"} src={"/assets/pic/mail.svg"}/>&nbsp; */}
                        <MailIcon />
                        <span className="fa-layers-counter">{_get(item, "emails", []).length}</span>
                    </span>
                    {_get(item, "emails", []).length > 0 && (
                        <NotificationEmailsPopover
                            popoverProps={{
                                target: `popover-${item.id}`,
                                container: `.emails-line-${item.id}`,
                            }}
                            list={_get(item, "emails", [])}
                        />
                    )}
                </span>
            </td>
            {!!isHistory && (
                <React.Fragment>
                    <td>{item.name_full || ""}</td>
                    <td>
                        {item.acknowledged_at !== "---"
                            ? Helper.dateToUserFormat(item.acknowledged_at, auth)
                            : "---"}
                    </td>
                </React.Fragment>
            )}
            <td>
                {!isHistory && auth.userCan("editAlarms") && !blocked() && (
                    <React.Fragment>
                        <button
                            className="link alarm-btn link-success"
                            onClick={() =>
                                history.push(
                                    Helper.setHashParams({ modalAlarm: "ack", id: item.id })
                                )
                            }
                        >
                            <i className="fa fa-check" id={`acknowledge-${item.id}`} />
                            <UncontrolledTooltip
                                popperClassName="tooltip-md"
                                placement="top"
                                target={`acknowledge-${item.id}`}
                            >
                                Acknowledge
                            </UncontrolledTooltip>
                            <span>Acknowledge</span>
                        </button>
                        <button
                            className="link alarm-btn link-secondary"
                            onClick={() =>
                                history.push(
                                    Helper.setHashParams({ modalAlarm: "ignore", id: item.id })
                                )
                            }
                        >
                            <i className="fa fa-times" id={`ignore-${item.id}`} />
                            <UncontrolledTooltip
                                popperClassName="tooltip-md"
                                placement="top"
                                target={`ignore-${item.id}`}
                            >
                                Ignore
                            </UncontrolledTooltip>
                            <span>Ignore</span>
                        </button>
                    </React.Fragment>
                )}
                {!!viewLink.length && (
                    <Link to={viewLink} title="View" className="link alarm-btn color-primary">
                        <i className="fa fa-eye" id={`view-${item.id}`} />
                        <UncontrolledTooltip
                            popperClassName="tooltip-md"
                            placement="top"
                            target={`view-${item.id}`}
                        >
                            View
                        </UncontrolledTooltip>
                        <span>View</span>
                    </Link>
                )}
            </td>
        </tr>
    );
};

Row.propTypes = {
    history: PropTypes.object,
    auth: PropTypes.object,
    item: PropTypes.object,
    isHistory: PropTypes.number,
    activeTab: PropTypes.string,
    updateDeviceAlertComments: PropTypes.func,
    handleCheckbox: PropTypes.func,
    selected: PropTypes.array,
    excluded: PropTypes.array,
    selectedType: PropTypes.string,
};

const statusList = {
    0: { title: "New", class: "badge-info" },
    1: { title: "Ignored", class: "badge-danger" },
    2: { title: "Acknowledged", class: "badge-warning" },
    3: {
        title: "Restored",
        class: "badge-success",
        description: "This issue has been resolved automatically.",
    },
};

const Status = ({ item }) => {
    const [visible, setVisible] = useState(false);

    return (
        <td>
            <div
                id={`popover-${item.id}`}
                className={`badge _badge ${_get(
                    statusList,
                    [item.status, "class"],
                    ""
                )} round-badge mb-1`}
                onClick={() => (item.notes ? setVisible(!visible) : () => {})}
                style={{ cursor: item.notes ? "pointer" : "default" }}
                title={_get(statusList, [item.status, "description"], "")}
            >
                <span>{_get(statusList, [item.status, "title"], "-")}</span>
            </div>
            {!!item.notes && (
                <UncontrolledPopover
                    placement="bottom"
                    isOpen={visible}
                    target={`popover-${item.id}`}
                    trigger="legacy"
                    toggle={() => setVisible(!visible)}
                >
                    <PopoverBody
                        className="custom-scroll"
                        style={{ height: "auto", maxHeight: 450 }}
                    >
                        <span style={{ color: "initial" }}>{item.notes}</span>
                    </PopoverBody>
                </UncontrolledPopover>
            )}
        </td>
    );
};

Status.propTypes = {
    item: PropTypes.object,
};

const NotificationFilter = ({ name, value, label, options, selected }) => {
    return (
        <div className="form-group form-group--inline">
            <div className="form-label">
                <label>{label}:</label>
            </div>
            <SelectWrapper
                value={value}
                onChange={(e) => {
                    selected({
                        [name]: _get(e, "target.value", ""),
                    });
                }}
                name={name}
            >
                <option value="">All</option>
                {options.map((option) => (
                    <option key={"level-" + option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </SelectWrapper>
        </div>
    );
};

NotificationFilter.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    options: PropTypes.array,
    selected: PropTypes.func,
};

const MailIcon = () => {
    return (
        <span className="table-image-icon mail-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 384">
                <path d="M394.67,149.33a117.33,117.33,0,1,0,58.68,219,10.66,10.66,0,1,0-10.69-18.46,96,96,0,1,1,48-83.17v10.67a21.33,21.33,0,1,1-42.67,0V234.67A10.63,10.63,0,0,0,430,227a53.32,53.32,0,1,0,4.51,74.8A42.6,42.6,0,0,0,512,277.33V266.67A117.47,117.47,0,0,0,394.67,149.33Zm0,149.33a32,32,0,1,1,32-32A32,32,0,0,1,394.67,298.67Z" />
                <path d="M17.7,24.69l175.72,143a10.1,10.1,0,0,0,1.89,1.23,16.07,16.07,0,0,0,7.39,1.8c2.71,0,5.42-.71,9.38-3.1L387.65,24.7a10.66,10.66,0,0,0-.64-17A42.38,42.38,0,0,0,362.67,0h-320A42.34,42.34,0,0,0,18.33,7.67,10.66,10.66,0,0,0,17.7,24.69Z" />
                <path d="M256,266.67C256,190.21,318.21,128,394.67,128a10.66,10.66,0,0,0,10.67-10.67V60.24a10.67,10.67,0,0,0-6.08-9.64A10.78,10.78,0,0,0,387.94,52L224.28,185.15l-1.87,1.24c-12.3,7.55-27.42,7.94-41.3-1.23L17.4,52A10.66,10.66,0,0,0,0,60.23V256a42.71,42.71,0,0,0,42.67,42.67H247a10.66,10.66,0,0,0,10.56-12.18A138.44,138.44,0,0,1,256,266.67Z" />
            </svg>
        </span>
    );
};

ServiceNotifications.propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object,
    countActiveDeviceAlerts: PropTypes.object,
    updateCountActiveDeviceAlerts: PropTypes.func,

    // LocationSelectStore
    getLocationId: PropTypes.func
};

export default withLocationSelectStore(withGlobalStore(ServiceNotifications));