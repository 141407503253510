import {ResetSortButton} from "../../shared/resetSortButton";
import React, {Component} from "react";
import PropTypes from "prop-types";
import Helper from "../../../helpers/helper";
import {Link} from "react-router-dom";
import {get as _get} from "lodash";
import {withGlobalStore} from "../../../stores/GlobalStore";

const headersList = {
    "name": {title: "Name", sort: true},
    "facility_id": {title: "Facility", sort: true},
    "wap_name": {title: "Wireless AP Name", sort: true},
    "sync_start": {title: "Sync Start", sort: true},
    "total_window_size": {title: "Temperature Interval", sort: true},
    "rms_interval": {title: "RMS Interval", sort: true},
    "fft_interval": {title: "FFT Interval", sort: true},
    "is_rms_enabled": {title: "RMS Enabled", sort: true},
    "is_fft_enabled": {title: "FFT Enabled", sort: true},
    "actions": {title: "Actions",  additionalClasses: "table-buttons-th", sort: false, component: ResetSortButton}
};

class Table extends Component
{
    render() {
        const {auth, list, sort, onSortChange, onDelete, query} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <div className="table-scroll-wrapp tachometers">
                <table className="table table-hover tl-fixed">
                    <thead>
                        <tr>
                            {Object.keys(headersList).map(key => {
                                let component = "";
                                const RowComponent = _get(headersList, [key, "component"], false);
                                if (RowComponent) {
                                    component = <RowComponent sort={sort} resetSort={() => onSortChange("")}/>;
                                }
                                return (
                                    listLen && headersList[key].sort
                                        ? (
                                            <th className={headersList[key].additionalClasses || ""} key={key} onClick={() => onSortChange(key)}>
                                                <div className="title">
                                                    <span>{headersList[key].title}</span>
                                                    {sort.field === key ? <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")}/> : <i className={"fa fa-sort"} />}
                                                </div>
                                            </th>
                                        )
                                        : <th className={headersList[key].additionalClasses || ""} width={_get(headersList[key], "width", "")} key={key}>
                                            <span className="title">{headersList[key].title}</span> {component}
                                        </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {list.length > 0
                            ?
                            list.map((wifiGroup) =>
                                <Row
                                    query={query}
                                    key={wifiGroup.id}
                                    wifiGroup={wifiGroup}
                                    auth={auth}
                                    onDelete={onDelete}
                                />
                            )
                            :
                            <tr>
                                <td colSpan={listLen} className="text-center text-info">
                                    {query !== "" ? "No items match your search." : "No wifi sensor mote group were found."}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

Table.propTypes = {
    auth: PropTypes.object,
    list: PropTypes.array,
    sort: PropTypes.object,
    onSortChange: PropTypes.func,
    onDelete: PropTypes.func,
    query: PropTypes.string
};

const Row = ({wifiGroup, query, onDelete}) => {
    return (
        <tr>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(_get(wifiGroup, "name", ""), query)}}/>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(_get(wifiGroup, "facility.name", "---"), query)}}/>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(_get(wifiGroup, "wap_name", "---"), query)}}/>
            <td>{_get(wifiGroup, "sync_start", "---")}</td>
            <td>{_get(wifiGroup, "total_window_size", "---")}</td>
            <td>{_get(wifiGroup, "rms_interval", "---")}</td>
            <td>{_get(wifiGroup, "fft_interval", "---")}</td>
            <td>{+_get(wifiGroup, "is_rms_enabled", null) ? "Yes" : "No"}</td>
            <td>{+_get(wifiGroup, "is_fft_enabled", null) ? "Yes" : "No"}</td>
            <td style={{ textAlign: "left" }}>
                <div className="btn-group btn-group-sm">
                    <button
                        className="link link-danger"
                        onClick={() => onDelete(wifiGroup.id)}
                    >
                        <i className="fa fa-times"/> <span>Delete</span>
                    </button>
                    <Link to={`/network/wifi-groups/edit/${wifiGroup.id}`} className="link link-primary">
                        <i className="fa fa-pen"/> <span>Edit</span>
                    </Link>
                </div>
            </td>
        </tr>
    );
};

Row.propTypes = {
    wifiGroup: PropTypes.object,
    query: PropTypes.string,
    auth: PropTypes.object,
    onDelete: PropTypes.func
};

export default withGlobalStore(Table);