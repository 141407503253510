import FaultFrequencyList from "../components/list";
import React from "react";
import {useModal} from "react-modal-hook";
import {useChartContext} from "../../../pages/chart/highcharts/twf/hooks/chartHooks";
import Toast from "../../../pages/shared/toast";
import {useFaultFrequencyFormModal} from "./useFaultFrequencyFormModal";
import {useChartStateStoreContext} from "../../../pages/chart/highcharts/twf/store/hooks/useStoreContext";
import {useInstallPointFaultFrequency} from "../../../hooks/api/faultFrequency/useFaultFrequencyQuery";
import {useFaultFrequencyDeleteMutation} from "../../../hooks/api/faultFrequency/useFaultFrequencyDeleteMutation";
import {shallow} from "zustand/shallow";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useSpeedSettings} from "../../speedSettings";

export const useFaultFrequencyListModal = () => {
    const {chartProps} = useChartContext();
    const [showFaultFrequencyForm] = useFaultFrequencyFormModal();

    const currentSpeedFrom = useChartStateStoreContext((state) => state.settings.currentSpeedFrom, shallow);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint, shallow);
    const {currentSpeed} = useSpeedSettings();
    const {toggleFaultFrequency, setEditFaultFrequencyId} = useChartStateStoreContext((state) => state.actions);
    const {updateFaultFrequency} = chartProps;
    const {equipmentItem} = useEquipmentByParams();

    const {filteredFaultFrequencies} = useInstallPointFaultFrequency(equipmentItem.id, +selectedPoint);

    const setSpeedModal = useChartStateStoreContext((state) => state.setSpeedModal);

    const {mutateAsync: deleteFaultFrequencyMutation, isLoading} = useFaultFrequencyDeleteMutation(equipmentItem.id);

    const deleteFaultFrequency = (deleteFaultFrequencyId, onSuccess) => {
        deleteFaultFrequencyMutation(deleteFaultFrequencyId)
            .then(() => {
                toggleFaultFrequency({id: deleteFaultFrequencyId});
            })
            .then(() => {
                onSuccess();
                setEditFaultFrequencyId(null);
                Toast.success("The fault frequency has been deleted.");
            });
    };

    const showEditFaultFrequency = (faultFrequencyId) => {
        setEditFaultFrequencyId(faultFrequencyId);
        showFaultFrequencyForm();
    };

    const showAddFaultFrequencyModal = () => {
        setEditFaultFrequencyId(null);
        showFaultFrequencyForm();
    };

    const handleSetSpeedModal = (type) => {
        setSpeedModal({show: true, type});
    };

    const [showModal, hideModal] = useModal(() => {
        return (
            <FaultFrequencyList
                equipment={equipmentItem}
                faultFrequencies={filteredFaultFrequencies}
                updateFaultFrequency={updateFaultFrequency}
                currentSpeed={currentSpeed}
                currentSpeedFrom={currentSpeedFrom}
                editHandler={(faultFrequencyId) => showEditFaultFrequency(faultFrequencyId)}
                deleteHandler={deleteFaultFrequency}
                addHandler={showAddFaultFrequencyModal}
                showModal={true}
                onClose={hideModal}
                selectedPoint={+selectedPoint}
                handleSetSpeedModal={handleSetSpeedModal}
                isDeleting={isLoading}
            />
        );
    }, [filteredFaultFrequencies, isLoading]);
    return [showModal, hideModal];
};
