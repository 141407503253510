import {filter as _filter, find as _find, get as _get, groupBy as _groupBy} from "lodash";
import FrequencyConverter from "../../../../../../helpers/frequency-converter";
import {fftAlertTypes, FREQUENCY_TYPES} from "../../../../../../constants/constants";
import Highcharts from "highcharts";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useSpeedSettings} from "../helpers/useSpeedSettings";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useChartRef} from "../useChartRef";
import {useAlertConditionListQuery} from "../../../../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {useInstallationPointIds} from "../../../../../../hooks/helpers/useInstallationPointIds";
import {useMemo, useState} from "react";
import {useSelectedAxis} from "../helpers/useSelectedAxis";

export const useBandAlertBuilder = () => {
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const {currentSpeed} = useSpeedSettings();
    const {equipmentItem} = useEquipmentByParams();
    const installationPointIds = useInstallationPointIds(equipmentItem);
    const chartRef = useChartRef();
    const {alertConditions: alerts, isSuccess} = useAlertConditionListQuery(chartType, installationPointIds);
    const showAlerts = useChartStateStoreContext((state) => state.settings.showAlerts);
    const selectedAxis = useSelectedAxis();
    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    const fullScreenMode = useChartStateStoreContext((state) => state.settings.fullScreenMode);
    const [forceUpdate, setForceUpdate] = useState(false);
    const getBandAlerts = () => {
        // TODO: remake chart that chartRef doesn't disappear
        if (showAlerts && isSuccess && !chartRef.current) {
            setTimeout(() => setForceUpdate((prev) => !prev), 100);
            return [];
        }
        if (!isSuccess || !showAlerts) {
            return [];
        }
        const alertList = _filter(alerts, (alert) => {
            return (
                +alert.fft_alert_type === fftAlertTypes.BAND &&
                +selectedPoint === +alert.installation_point_id &&
                (!selectedAxis || !alert.axis_id || selectedAxis.indexOf(alert.axis_id + "") >= 0)
            );
        });

        const xExtremes = {
            max: chartRef.current.chart.xAxis[0].max,
        };

        const shapes = [],
            labels = [];

        for (let [value, alertistForValue] of Object.entries(_groupBy(alertList, "value"))) {
            let text = "";

            for (let alert of alertistForValue) {
                const opacityColor = new Highcharts.Color(alert.alertLevel.color).setOpacity(0.8).get();
                const fillColor = new Highcharts.Color(alert.alertLevel.color).setOpacity(0.1).get();

                if (alert.fft_band_frequency_type === FREQUENCY_TYPES.ORDERS && !(currentSpeed > 0)) {
                    continue;
                }

                const bandFrom = FrequencyConverter.fromType(alert.fft_band_frequency_type, alert.fft_band_from, currentSpeed).toHz().value;
                const bandTo = FrequencyConverter.fromType(alert.fft_band_frequency_type, alert.fft_band_to, currentSpeed).toHz().value;

                shapes.push({
                    type: "path",
                    dashStyle: "solid",
                    stroke: alert.alertLevel.color,
                    strokeWidth: 4,
                    fill: "rgba(0, 0, 0, 0)",
                    points: [
                        {x: bandFrom, y: alert.value, xAxis: 0, yAxis: 0},
                        {x: bandTo, y: alert.value, xAxis: 0, yAxis: 0},
                    ],
                });
                shapes.push({
                    type: "path",
                    dashStyle: "longdashdot",
                    stroke: opacityColor,
                    fill: false,
                    points: [
                        {x: bandFrom, y: 0, xAxis: 0, yAxis: 0},
                        {x: bandFrom, y: alert.value, xAxis: 0, yAxis: 0},
                    ],
                });
                shapes.push({
                    type: "path",
                    stroke: false,
                    fill: fillColor,
                    points: [
                        {x: bandFrom, y: 0, xAxis: 0, yAxis: 0},
                        {x: bandFrom, y: alert.value, xAxis: 0, yAxis: 0},
                        {x: bandTo, y: alert.value, xAxis: 0, yAxis: 0},
                        {x: bandTo, y: 0, xAxis: 0, yAxis: 0},
                    ],
                });
                shapes.push({
                    type: "path",
                    dashStyle: "longdashdot",
                    stroke: opacityColor,
                    fill: false,
                    points: [
                        {x: bandTo, y: 0, xAxis: 0, yAxis: 0},
                        {x: bandTo, y: alert.value, xAxis: 0, yAxis: 0},
                    ],
                });
                shapes.push({
                    type: "path",
                    dashStyle: "longdashdot",
                    stroke: opacityColor,
                    fill: false,
                    points: [
                        {x: bandTo, y: alert.value, xAxis: 0, yAxis: 0},
                        {x: xExtremes.max, y: alert.value, xAxis: 0, yAxis: 0},
                    ],
                });

                const style = `background: ${alert.alertLevel.color}; height: 10px; width: 10px; margin-top: 10px;`;
                const pointName = (_find(equipmentItem.installationPoints, ["id", alert.installation_point_id]) || {}).name || "";
                text =
                    text +
                    `<span class="badge badge-square alert-badge-${chartType}" data-alert-id="${alert.id}" ` +
                    `data-installation-point-id="${alert.installation_point_id}" data-axis-id="${alert.axis_id || (alert.readingType || {}).axis_id}" ` +
                    `data-fft-alert-type="${alert.fft_alert_type}" style="${style}" title="${pointName.replace(/"/g, "")}"/>`;
            }

            const xAxis = _get(chartRef, "current.chart.xAxis.0");

            labels.push({
                backgroundColor: "none",
                borderWidth: 0,
                distance: -10,
                padding: 0,
                useHTML: true,
                text,
                point: {x: xAxis ? xAxis.getExtremes().max : xExtremes.max, y: +value, xAxis: 0, yAxis: 0},
            });
        }

        return {
            draggable: "",
            shapes: shapes,
            labels: labels,
        };
    };

    return useMemo(
        () => getBandAlerts(),
        [alerts, showAlerts, selectedAxis, selectedPoint, equipmentItem, currentSpeed, isLoading, fullScreenMode, forceUpdate]
    );
};
