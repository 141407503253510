import useChartMarksQuery from "../../../../../../hooks/api/chartMarksSimple/useChartMarksQuery";
import {getChartMarksPlotLines} from "../../helpers/plotLines";
import {useMemo} from "react";
import {useChartSelectedEquipmentStore} from "../../../../../../stores/zustand/ChartSelectedEquipmentStore";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useCurrentChartMarkId} from "../../../../../../stores/zustand/ChartSelectedRangeStore";
import {useIsShowChartMarks} from "../../../store/sync/sharedStore";
import useChartMarksAlertChangeQuery from "../../../../../../hooks/api/chartMarksSimple/useChartMarksAlertChangeQuery";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";

export const useChartMarksBuilder = (props) => {
    const {equipmentItem} = useEquipmentByParams();
    let {chartMarksData, isSuccess} = useChartMarksQuery(equipmentItem.id);
    const {chartMarksAlertChange} = useChartMarksAlertChangeQuery(equipmentItem.id);
    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList, shallow);
    const selectedEquipment = useChartSelectedEquipmentStore();
    const currentChartMarkId = useCurrentChartMarkId();
    const isShowChartMarks = useIsShowChartMarks();

    if (isSuccess && chartMarksAlertChange?.length > 0) {
        chartMarksData = chartMarksData.concat(chartMarksAlertChange);
    }

    return useMemo(
        () => getChartMarksPlotLines({chartMarks: chartMarksData, props, selectedEquipment, equipmentItem, currentChartMarkId, isShowChartMarks, seriesList}),
        [selectedEquipment, chartMarksData, isSuccess, isShowChartMarks, seriesList.selected, chartMarksAlertChange]
    );
};
