import React, {Component} from "react";
import PropTypes from "prop-types";
import FormValidator from "../../../helpers/form-validator";
import {ValidationError} from "../../../shared";
import {Loader} from "../../../shared";
import {
    get as _get,
    set as _set
} from "lodash";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import ApiTachometers from "../../../api/tachometer";
import SearchInput from "../../shared/search-input";
import Autocomplete from "react-autocomplete";
import InfoTooltip from "../../../shared/infoTooltip/infoTooltip";
import { HeaderSimple } from "../../../shared/header";
import Toast from "../../shared/toast";

const propTypes = {
    tachometerId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ]),
    title: PropTypes.string,
    tachometer: PropTypes.object,
    handleSubmit: PropTypes.func,
    availablePermissionGroup: PropTypes.object,
    permissionList: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object
};

const rules = [
    {
        field: "serial",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "thresh_low",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "location_info",
        method: "isLength",
        options: {max: 255},
        validWhen: true,
        message: "Located At should contain at most 255 symbols"
    },
    {
        field: "thresh_high",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "poll_freq",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "min_period",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "max_rpm",
        method: "isFloat",
        options: {"min": 0},
        validWhen: true,
        skipOnEmpty: true,
        message: "Maximum RPM Value should be a numeric value and not less than 0."
    },
];

class TachometerForm extends Component
{
    constructor(props) {
        super(props);
        this.validator = new FormValidator(rules);
        this.state = {
            title: this.props.match.params.tachometerId ? "Edit Tachometer" : "ADD TACHOMETER",
            data: {
                serial: "",
                location_info: "",
                port: 5,
                thresh_low: "",
                thresh_high: "",
                poll_freq: "",
                min_period: "",
                max_rpm: null,
            },
            breadcrumbs: [
                {name: "Manage Tachometers", link: "/network/tachometers"},
                {name: this.props.match.params.tachometerId ? "Edit Tachometer" : "Add Tachometer"}
            ],
            loader: true,
            inProgress: false,
            validation: this.validator.valid(),
            tachometerId: this.props.match.params.tachometerId ? this.props.match.params.tachometerId : "",
            globalSearchString: "",
            nodes: []
        };

        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.handleSubmitAddTachometer = this.handleSubmitAddTachometer.bind(this);
        this.handleSubmitEditTachometer = this.handleSubmitEditTachometer.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
        this.searchNodes = this.searchNodes.bind(this);
    }

    handleChangeGlobalSearch (globalSearchString) {
        this.setState({globalSearchString: globalSearchString});
    }

    onChange(e) {
        const data = {...this.state.data};
        _set(data, _get(e, "target.name", ""), _get(e, "target.value", ""));
        const validation = this.validator.validate(data, _get(e, "target.name", ""));
        this.setState({data, validation});
    }

    componentDidMount() {
        if (this.state.tachometerId) {
            ApiTachometers.get(this.props.match.params.tachometerId).then((tachometer) => {
                this.setState({
                    loader: false,
                    data: {
                        serial: tachometer.node.serial,
                        location_info: tachometer.location_info || "",
                        port: tachometer.port,
                        thresh_low: tachometer.thresh_low,
                        thresh_high: tachometer.thresh_high,
                        poll_freq: tachometer.poll_freq,
                        min_period: tachometer.min_period,
                        max_rpm: tachometer.max_rpm,
                    },
                });
            });
        } else {
            this.setState({
                loader: false,
                data: {
                    ...this.state.data,
                }
            });
        }
        ApiTachometers.searchNodes({limit: 10}).then(({nodes}) => {
            if (nodes.length) {
                this.setState({nodes});
            }
        });
    }

    searchNodes () {
        const serial = this.state.data.serial;
        if (serial.toString().length > 2) {
            ApiTachometers.searchNodes({serial: serial, limit: 10}).then(({nodes}) => this.setState({nodes}));
        }
    }

    handleSubmit() {
        const validation = this.validator.validate(this.state.data);
        const {data} = Object.assign({}, this.state);
        this.setState(
            {inProgress: true},
            () => {
                this.setState({validation}, () => {
                    if (validation.isValid) {
                        if(this.state.tachometerId) {
                            this.handleSubmitEditTachometer(data).catch(err => {
                                if (err.errors) {
                                    const validation = this.state.validation;

                                    Object.keys(err.errors).map(key => {
                                        validation[key].isValid = false;
                                        validation[key].message = err.errors[key];
                                    });

                                    this.setState({
                                        validation,
                                        inProgress: false
                                    });
                                } else {
                                    this.setState({
                                        inProgress: false
                                    });
                                }
                            });
                        } else {
                            this.handleSubmitAddTachometer(data).catch(err => {
                                if (err.errors) {
                                    const validation = this.state.validation;
                                    Object.keys(err.errors).map(key => {
                                        validation[key].isValid = false;
                                        validation[key].message = err.errors[key];
                                    });

                                    this.setState({
                                        validation,
                                        inProgress: false
                                    });
                                } else {
                                    this.setState({
                                        inProgress: false
                                    });
                                }
                            });
                        }
                    } else {
                        this.setState({inProgress: false});
                    }
                });
            }
        );

    }

    handleSubmitAddTachometer(data) {
        return ApiTachometers.create({data}).then(() => {
            this.props.history.push("/network/tachometers");
            Toast.success("The tachometer has been added.");
        });
    }

    handleSubmitEditTachometer(data) {
        return ApiTachometers.update(this.state.tachometerId, {data}).then(() => {
            this.props.history.push("/network/tachometers");
            Toast.success("The tachometer has been updated.");
        });
    }

    searchChange(value) {
        this.handleFilterChange({query: value});
    }

    render() {
        const {
            breadcrumbs,
            loader,
            globalSearchString,
            title,
            tachometerId,
            inProgress,
            data,
            validation,
            nodes
        } = this.state;

        return (
            <div className="">
                 <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">{title}</div>
                </div>
                {loader
                    ?
                    <div className="loader-fullscreen"><Loader/></div>
                    :
                    <div>
                        <div className="block">
                            <div className="block-body">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label className="text-right form-label">Node Serial Number: <span className="color-danger">*</span></label>
                                            {tachometerId
                                                ?
                                                <div className="">
                                                    <input
                                                        className={"form-control"}
                                                        type="text"
                                                        name="serial"
                                                        defaultValue={data.serial}
                                                        disabled={true}
                                                    />
                                                </div>
                                                :
                                                <React.Fragment>
                                                        <Autocomplete
                                                            inputProps={{
                                                                className: "form-control" + (validation.serial.isValid || !validation.serial.message ? "" : " is-invalid"),
                                                                placeholder: "Enter the serial number to search"
                                                            }}
                                                            wrapperProps={{className: "autocomplete"}}
                                                            wrapperStyle={{}}
                                                            items={nodes}
                                                            getItemValue={item => item.serial + ""}
                                                            value={data.serial}
                                                            onChange={e => {
                                                                const updatedData = { ...data, serial: e.target.value };
                                                                this.setState({
                                                                    data: updatedData,
                                                                    validation: this.validator.validate(updatedData, "serial")
                                                                }, this.searchNodes);
                                                            }}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div
                                                                    className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                                                                    key={item.node_id}>{item.serial}</div>
                                                            )}
                                                            menuStyle={{
                                                                borderRadius: "3px",
                                                                boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                                                                background: "rgba(255, 255, 255, 1)",
                                                                padding: "2px 0",
                                                                fontSize: "90%",
                                                                position: "fixed",
                                                                overflow: "auto",
                                                                maxHeight: "50%",
                                                                zIndex: 2
                                                            }}
                                                            onSelect={(value) => {
                                                                const updatedData = { ...data, serial: value };
                                                                this.setState({
                                                                    data: updatedData,
                                                                    validation: this.validator.validate(updatedData, "serial")
                                                                });
                                                            }}
                                                            renderInput={(props) => {
                                                                return <React.Fragment>
                                                                    <input {...props} />
                                                                    <ValidationError message={validation.serial.message}/>
                                                                </React.Fragment>;
                                                            }}
                                                        />
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="form-group">
                                            <label className="text-right form-label">Port:</label>
                                            <div className="">
                                                <input
                                                    className={"form-control"}
                                                    type="text"
                                                    name="port"
                                                    defaultValue={+data.port + 1}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="text-right form-label">
                                                <InfoTooltip>
                                                    The maximum number of Revolutions Per
                                                    Minute (RPM) for
                                                    the tachometer. Any readings above this level are ignored;
                                                    however, you can use the
                                                    Show Over Max RPMs toggle to view the readings if needed.
                                                </InfoTooltip>
                                                Maximum RPM Value:</label>
                                            <div className="">
                                                <input
                                                    className={"form-control" + (validation.max_rpm.isValid || !validation.max_rpm.message ? "" : " is-invalid")}
                                                    type="text"
                                                    name="max_rpm"
                                                    defaultValue={data.max_rpm}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                />
                                                <ValidationError message={validation.max_rpm.message}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="text-right form-label">
                                                <InfoTooltip>
                                                    The minimum number of RPM required to
                                                    trigger a reading on tethered sensors that are connected to the
                                                    same node as the
                                                    tachometer.
                                                </InfoTooltip>
                                                RPM Trigger Threshold Low: <span className="color-danger">*</span></label>
                                            <div className="">
                                                <input
                                                    className={"form-control" + (validation.thresh_low.isValid || !validation.thresh_low.message ? "" : " is-invalid")}
                                                    type="text"
                                                    name="thresh_low"
                                                    defaultValue={data.thresh_low}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                />
                                                <ValidationError message={validation.thresh_low.message}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="text-right form-label">
                                                <InfoTooltip>
                                                    The maximum number of RPM required
                                                    to trigger a reading on tethered sensors that are connected to
                                                    the same node as the
                                                    tachometer.
                                                </InfoTooltip>
                                                RPM Trigger Threshold High: <span className="color-danger">*</span></label>
                                            <div className="">
                                                <input
                                                    className={"form-control" + (validation.thresh_high.isValid || !validation.thresh_high.message ? "" : " is-invalid")}
                                                    type="text"
                                                    name="thresh_high"
                                                    defaultValue={data.thresh_high}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                />
                                                <ValidationError message={validation.thresh_high.message}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="text-right form-label">
                                                <InfoTooltip>
                                                    The time period of frequency that the
                                                    tachometer should
                                                    check for an RPM in the target range to trigger a reading on
                                                    tethered sensors that are
                                                    connected to the same node as the tachometer.
                                                </InfoTooltip>
                                                Poll Frequency (Seconds): <span className="color-danger">*</span></label>
                                            <div className="">
                                                <input
                                                    className={"form-control" + (validation.poll_freq.isValid || !validation.poll_freq.message ? "" : " is-invalid")}
                                                    type="text"
                                                    name="poll_freq"
                                                    defaultValue={data.poll_freq}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                />
                                                <ValidationError message={validation.poll_freq.message}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="text-right form-label">
                                                <InfoTooltip>
                                                    The amount of time after an RPM trigger (on wired
                                                    sensors than are connected to the same node as the tachometer)
                                                    before the next
                                                    RPM trigger can occur.
                                                </InfoTooltip>
                                                Minimum Period (Seconds): <span className="color-danger">*</span></label>
                                            <div className="">
                                                <input
                                                    className={"form-control" + (validation.min_period.isValid || !validation.min_period.message ? "" : " is-invalid")}
                                                    type="text"
                                                    name="min_period"
                                                    defaultValue={data.min_period}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                />
                                                <ValidationError message={validation.min_period.message}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="text-right form-label">Location:</label>
                                            <div className="">
                                                <input
                                                    className={"form-control" + (validation.location_info.isValid || !validation.location_info.message ? "" : " is-invalid")}
                                                    type="text"
                                                    name="location_info"
                                                    defaultValue={data.location_info}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                />
                                                <ValidationError message={validation.location_info.message}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                            <Link to="/network/tachometers" className="btn btn-sm btn-secondary">Cancel</Link>
                            <Button color="primary" size="sm" className="ml-2" disabled={this.state.inProgress} onClick={this.handleSubmit}>Save</Button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item ml-2">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

TachometerForm.propTypes = propTypes;

export default TachometerForm;