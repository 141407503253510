import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";

const equipmentBreadcrumbsStore = create(
    immer((set) => ({
        breadcrumbs: [{name: "Equipment"}],
        actions: {
            fetchEquipmentData: (data) => {
                let breadcrumbs = [{name: "Equipment list", link: "/"}];

                Object.keys(data.locationPath).map((key) => {
                    breadcrumbs.push({
                        name: data.locationPath[key].name,
                        link: "/equipments/" + data.locationPath[key].id,
                    });
                });

                breadcrumbs.push({
                    name: data.name
                });

                set((state) => {
                    state.breadcrumbs = breadcrumbs;
                });
            }
        }
    })
));

export const useEquipmentBreadcrumbsStore = () => equipmentBreadcrumbsStore((state) => state.breadcrumbs, shallow);
export const useEquipmentBreadcrumbsStoreActions = () => equipmentBreadcrumbsStore((state) => state.actions, shallow);