import {useMemo} from "react";

export const useQueriesStatus = (results) => {
    const isSuccess = useMemo(() => results.every((data) => data.isSuccess === true), [results]);
    const isLoading = useMemo(() => results.some((data) => data.isLoading === true), [results]);
    const isFetching = useMemo(() => results.some((data) => data.isFetching === true), [results]);
    const isRefetching = useMemo(() => results.some((data) => data.isRefetching === true), [results]);
    const isFetched = useMemo(() => results.every((data) => data.isFetched === true), [results]);
    const isError = useMemo(() => results.some((data) => data.isError === true), [results]);
    const data = useMemo(() => results.map((data) => data?.data), [results]);
    return {isSuccess, isError, isLoading, isFetching, data, isFetched, isRefetching};
};
