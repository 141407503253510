import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {shallow} from "zustand/shallow";
import {getSyncSelector} from "./helpers/getSyncSelector";

const store = (set) => ({
    overlays: {},
    actions: {
        enableOverlay(installationPointId, overlayConfig, isImpactVue = false) {
            set((state) => {
                const selector = getSyncSelector(installationPointId, isImpactVue);
                state.overlays[selector] = overlayConfig;
            });
        },
        disableOverlay(installationPointId, isImpactVue = false) {
            set((state) => {
                const selector = getSyncSelector(installationPointId, isImpactVue);
                state.overlays[selector] = {};
            });
        },
    },
});

export const useOverlaySyncStore = create(immer(store));

export const useOverlaySyncStoreActions = () => useOverlaySyncStore((state) => state.actions, shallow);

export const useSyncedOverlay = (installationPointId, isImpactVue = false) => {
    const selector = getSyncSelector(installationPointId, isImpactVue);
    return useOverlaySyncStore((state) => state.overlays[selector], shallow) || {};
};
