import {useChartStateStoreContext} from "../store/hooks/useStoreContext";
import {useChartContext} from "./chartHooks";
import ChartHelper from "../../../../../helpers/chart";
import {zoomPausedChart} from "../../../../../helpers/holdMode";
import {useLockPoints} from "./useLockPoints";
import {resetZoomForGroup} from "../../helpers/resetZoomForGroup";

export const useZoomReset = () => {
    const {chartRef} = useChartContext();
    const {setZoomed, setMinMax} = useChartStateStoreContext((state) => state.actions);
    const {valueInitMax, valueInitMin} = useChartStateStoreContext((state) => state.settings);
    const lockPoints = useLockPoints();

    return () => {
        setZoomed(false);

        resetZoomForGroup(chartRef.current.chart.userOptions?.chart?.syncZoomGroup, "x");

        setMinMax({
            valueMax: +valueInitMax,
            valueMin: +valueInitMin,
        });
        chartRef.current.chart.xAxis.forEach((axis) => {
            axis.update({
                min: null,
                max: null,
            });
        });
        chartRef.current.chart.isPointBPaused = false;
        ChartHelper.clearAdditionalPoints(chartRef.current.chart);
        lockPoints.current = [];
        if (chartRef.current.chart.holdPoints) {
            setTimeout(() => zoomPausedChart(chartRef.current), 0);
        }
    };
};
