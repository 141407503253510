import React, {Component} from "react";
import PropTypes from "prop-types";
import GeneralStep from "./create/general";
import InstallationPointsStep from "./create/installationPoints";
import BearingStep from "./create/bearing";
import {
    set as _set,
    get as _get,
    omit as _omit,
    each as _each,
    filter as _filter,
    includes as _includes
} from "lodash";
import InstallationPointCustomTypeApi from "../../api/installationPointCustomType";
import AlertsStep from "./create/alerts";
import ApiReadingTypes from "../../api/readingTypes";
import AlertLevelApi from "../../api/alertLevel";
import EquipmentProfileValidationApi from "../../api/equipmentProfileValidation";
import BearingApi from "../../api/bearing";
import EquipmentProfileApi from "../../api/equipmentProfile";
import ApiEquipment from "../../api/equipment";

const steps = [
    {
        key: "general",
        name: "General",
        description: "",
        template: <GeneralStep />,
        isEnabled: () => {
            return true;
        },
        isValid: (profile, formErrors) => {
            return !_get(formErrors, "general");
        },
        isDone: (profile, currentStepIndex) => {
            return _get(profile, "id") || currentStepIndex > 0;
        }
    },
    {
        key: "installationPoints",
        name: "Installation Points",
        description: "",
        template: <InstallationPointsStep />,
        isEnabled: () => {
            return true;
        },
        isValid: (profile, formErrors) => {
            return !_get(formErrors, "installationPoints");
        },
        isDone: (profile) => {
            return _get(profile, "installationPoints", []).length;
        },
    },
    {
        key: "bearings",
        name: "Bearings",
        description: "",
        template: <BearingStep />,
        isEnabled: (profile) => {
            return _get(profile, "installationPoints", []).length;
        },
        isValid: (profile, formErrors) => {
            return !_get(formErrors, "bearings");
        },
        isDone: (profile) => {
            return _get(profile, "bearings", []).length;
        },
    },
    {
        key: "alerts",
        name: "Measurement Alerts",
        description: "Set alert levels by clicking the Configure button next to the corresponding item.",
        template: <AlertsStep />,
        isEnabled: (profile) => {
            return _get(profile, "installationPoints", []).length;
        },
        isValid: (profile, formErrors) => {
            return !_get(formErrors, "alertsConditions");
        },
        isDone: (profile) => {
            return Object.keys(_get(profile, "alertsConditions", {})).length;
        },
    }
];

class CreateDataHandler extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            profile: {
                general: {
                    equipmentTypeId: "",
                    equipmentTypeName: "",
                    name: "",
                    description: "",
                    idleThresholdType: "",
                    idleThreshold: "",
                    equipmentSpeedTypeId: 1,
                    currentSpeed: "",
                    speedMax: "",
                    speedMin: "",
                },
                installationPoints: [],
                bearings: [],
                alertsConditions: {},
            },
            formErrors: {},
            loader: true,
            currentStepIndex: 0,
            instPointCustomTypes: [],
            readingTypes: [],
            alertLevels: [],
            defaultBearings: [],
            conditions: {},
            inProgress: false,
            equipmentTypes: [],
            validationInProgress: false
        };
    }

    componentDidMount() {
        Promise.all([
            this.fetchProfile(),
            this.fetchEquipmentTypes(),
            this.fetchInstPointCustomType(),
            this.fetchReadingTypes(),
            this.fetchAlertLevels(),
            this.fetchBearings(),
            this.fetchConditions()
        ]).then(() => this.setState({loader: false}));
    }

    fetchProfile = () => {
        const {profileId} = this.props;

        if (profileId) {
            return EquipmentProfileApi
                .view(profileId)
                .then((response) => {
                    if (response) {
                        const {profile = {}} = response;
                        this.setState({profile});
                    }
                });
        }
    }

    fetchEquipmentTypes = () => {
        return ApiEquipment
            .getTypes()
            .then(response => {
                if (response) {
                    const {list} = response;
                    const equipmentTypes = list || [];
                    this.setState({equipmentTypes});
                }
            });
    }

    fetchInstPointCustomType = (callback = null) => {
        InstallationPointCustomTypeApi
            .list()
            .then(response => {
                if (response) {
                    const {list} = response;
                    const instPointCustomTypes = (list || []).map(type => {
                        return {label: type.name, value: +type.id};
                    });
                    this.setState({instPointCustomTypes}, () => {
                        if (callback && typeof callback === "function") {
                            callback();
                        }
                    });
                }
            });
    }

    fetchReadingTypes = () => {
        return ApiReadingTypes
            .forAlerts()
            .then((response) => {
                if (response) {
                    const {list} = response;
                    this.setState({readingTypes: _filter(list, (type) => !_includes(["dis", "cf"], _get(type, "alias")))});
                }
            });
    }

    fetchAlertLevels = () => {
        return AlertLevelApi
            .getList()
            .then((response) => {
                if (response) {
                    const {list} = response;
                    this.setState({alertLevels: list || []});
                }
            });
    }

    fetchBearings = (name = "") => {
        const filter = {};

        if (name) {
            filter.query = name;
        }

        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        return BearingApi
            .list({
                query: {
                    filter,
                    installation_point_id: 0,
                },
                signal: this.signal
            })
            .then((response) => {
                if (response) {
                    const {list} = response;
                    const defaultBearings = list || [];
                    this.setState({defaultBearings});

                    return defaultBearings;
                }
            });
    }

    fetchConditions = () => {
        return AlertLevelApi
            .getLevelsConditionsList()
            .then((response) => {
                if (response) {
                    const {list} = response;
                    this.setState({conditions: list || {}});
                }
            });
    }

    onChange = (event) => {
        const key = _get(event, "target.name");
        const value = _get(event, "target.value");
        this.removeError(key);

        this.updateProfile(key, value);
    }

    updateProfile = (key, value) => {
        let profile = {...this.state.profile};

        _set(profile, key, value);

        this.setState({profile});
    }

    validateStep = () => {
        this.setState({formErrors: {}, validationInProgress: true});

        return this
            .validate()
            .then(() => {
                this.setState({validationInProgress: false});
            })
            .catch(response => {
                this.setState({formErrors: response.errors || {}, validationInProgress: false});
            });
    }

    validate = () => {
        const {profile} = this.state;

        return _get(profile, "id")
            ? EquipmentProfileValidationApi.validateUpdate(profile, profile.id)
            : EquipmentProfileValidationApi.validateCreate(profile);
    }

    removeError = (key) => {
        const {currentStepIndex} = this.state;
        const oldFormErrors = {...this.state.formErrors};

        if (!_get(oldFormErrors, key)) {
            key = [_get(steps, currentStepIndex+".key"), key];
        }

        const formErrors = _omit(oldFormErrors, key);

        this.setState({formErrors});
    }

    setCurrentStepIndex = (index) => {
        const {currentStepIndex} = this.state;

        if (currentStepIndex >= index) {
            return this.setState({currentStepIndex: index});
        }

        this
            .validateStep()
            .then(() => {
                if (!Object.keys(_get(this.state.formErrors, _get(steps, currentStepIndex+".key"), {})).length) {
                    this.setState({currentStepIndex: index});
                }
            });
    }

    addInstallationPoint = () => {
        let profile = {...this.state.profile};

        profile.installationPoints.push({
            name: "",
            installation_point_custom_type_id: "",
            speed: "",
            speed_ratio: "",
        });

        this.setState({profile});
    }

    removeInstallationPoint = (index) => {
        let profile = {...this.state.profile};
        let installationPoints = profile.installationPoints;

        installationPoints.splice(index, 1);
        _set(profile, "installationPoints", installationPoints);

        const bearings = this.removeInstallationPointFromBearing(index);
        const alertsConditions = this.removeInstallationPointFromAlerts(index);

        _set(profile, "bearings", bearings);
        _set(profile, "alertsConditions", alertsConditions);

        this.setState({profile}, () => this.validateStep());
    }

    removeInstallationPointFromBearing = (removeIndex) => {
        let bearings = [...this.state.profile.bearings];

        _each(bearings, (bearing, bearingIndex) => {
            let indexes = _get(bearing, "installationPointsIndexes", []);
            let ids = _get(bearing, "ids", []);
            let newIndexes = [];

            _each(indexes, (index) => {
                if (+index !== +removeIndex) {
                    +index > +removeIndex ? newIndexes.push(index - 1) : newIndexes.push(index);
                } else {
                    ids.splice(removeIndex, 1);
                }
            });

            if (!newIndexes.length) {
                bearings.splice(bearingIndex, 1);
            } else {
                _set(bearings, bearingIndex+".installationPointsIndexes", newIndexes);
                _set(bearings, bearingIndex+".ids", ids);
            }
        });

        return bearings;
    }

    removeInstallationPointFromAlerts = (removeIndex) => {
        let alertsConditions = {...this.state.profile.alertsConditions};
        let newAlertsConditions = {};

        _each(alertsConditions, (conditions, index) => {
            if (+index !== +removeIndex) {
                +index > +removeIndex ? _set(newAlertsConditions, index - 1, conditions) : _set(newAlertsConditions, index, conditions);
            }
        });

        return newAlertsConditions;
    }

    addBearing = () => {
        let profile = {...this.state.profile};

        profile.bearings.push({
            installationPointsIndexes: [],
            bearing: {
                id: "",
                name: "",
                mfr: "",
                balls_rollers: "",
                ftf: "",
                bsf: "",
                bpfo: "",
                bpfi: "",
                plot_lines_count: 10
            }
        });

        this.setState({profile});
    }

    removeBearing = (index) => {
        let profile = {...this.state.profile};
        let bearings = profile.bearings;

        bearings.splice(index, 1);
        _set(profile, "bearings", bearings);

        this.setState({profile}, () => this.validateStep());
    }

    onSave = () => {
        const {profileId} = this.props;

        profileId ? this.onUpdate() : this.onCreate();
    }

    onSaveAsDraft = () => {
        const {profileId} = this.props;

        profileId ? this.onUpdate(true) : this.onCreate(true);
    }

    onCreate = (isDraft = false) => {
        let profile = {...this.state.profile};
        this.setState({formErrors: {}, inProgress: true}, () => {
            EquipmentProfileApi
                .create({...profile, isDraft})
                .then(() => {
                    this.props.history.push("/equipment-profile");
                })
                .catch(response => {
                    this.setState({formErrors: response.errors || {}, inProgress: false});
                });
        });
    }

    onUpdate = (isDraft = false) => {
        let profile = {...this.state.profile};
        this.setState({formErrors: {}, inProgress: true}, () => {
            EquipmentProfileApi
                .update(profile.id, {...profile, isDraft})
                .then(() => {
                    this.props.history.push("/equipment-profile#update="+profile.id);
                })
                .catch(response => {
                    this.setState({formErrors: response.errors || {}, inProgress: false});
                });
        });
    }

    render() {
        const {
            loader,
            currentStepIndex,
            profile,
            formErrors,
            instPointCustomTypes,
            readingTypes,
            alertLevels,
            defaultBearings,
            conditions,
            inProgress,
            equipmentTypes,
            validationInProgress
        } = this.state;
        const {profileId} = this.props;
        const isCreate = !profileId;

        return (
            <React.Fragment>
                {this.props.children(
                    loader,
                    steps,
                    currentStepIndex,
                    this.setCurrentStepIndex,
                    profile,
                    this.onChange,
                    formErrors,
                    this.addInstallationPoint,
                    this.removeInstallationPoint,
                    instPointCustomTypes,
                    this.fetchInstPointCustomType,
                    readingTypes,
                    alertLevels,
                    this.validateStep,
                    this.addBearing,
                    this.removeBearing,
                    defaultBearings,
                    this.fetchBearings,
                    this.onSave,
                    conditions,
                    this.onSaveAsDraft,
                    inProgress,
                    isCreate,
                    equipmentTypes,
                    this.fetchEquipmentTypes,
                    validationInProgress
                )}
            </React.Fragment>
        );
    }
}

CreateDataHandler.propTypes = {
    history: PropTypes.object,
    children: PropTypes.any,
    profileId: PropTypes.number
};

export default CreateDataHandler;