import React, {useState} from "react";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import PacketLossChart from "./PacketLossChart";
import PropTypes from "prop-types";
import classnames from "classnames";
import SignalStrength from "./signal/SignalStrength";
import SignalQuality from "./signal/SignalQuality";

export const PacketLossRepeater = ({serial, type}) => {
    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (<>
        <Nav tabs className={"nav-intervals"}>
            <NavItem>
                <NavLink
                    className={classnames({active: activeTab === "1"})}
                    onClick={() => {
                        toggleTab("1");
                    }}
                >
                    Packet Loss
                </NavLink>
            </NavItem>

            <NavItem>
                <NavLink
                    className={classnames({active: activeTab === "2"})}
                    onClick={() => {
                        toggleTab("2");
                    }}
                >
                    Signal Strength
                </NavLink>
            </NavItem>

            <NavItem>
                <NavLink
                    className={classnames({active: activeTab === "3"})}
                    onClick={() => {
                        toggleTab("3");
                    }}
                >
                    Signal Quality
                </NavLink>
            </NavItem>

        </Nav>

        <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
                <PacketLossChart
                    serial={serial}
                    type={type}/>
            </TabPane>

            <TabPane tabId="2">
                <SignalStrength
                    serial={serial}
                    type={type}
                />
            </TabPane>

            <TabPane tabId="3">
                <SignalQuality
                    serial={serial}
                    type={type}
                />
            </TabPane>
        </TabContent>
    </>);
};

PacketLossRepeater.propTypes = {
    serial: PropTypes.number,
    type: PropTypes.string
};
