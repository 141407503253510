import {useQuery} from "@tanstack/react-query";
import {ROUTES_API} from "./constants";
import RoutesApi from "../../../api/routes";

export const getRouteByIdQueryKeys = ({routeId}) => [ROUTES_API.GET_ROUTE_BY_ID, routeId];

export const useRouteByIdQuery = (routeId) => {
    return useQuery({
        queryFn: () => RoutesApi.getView(routeId),
        queryKey: getRouteByIdQueryKeys({routeId}),
        select: (res) => res.route,
        enabled: !!routeId,
    });
};
