import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import {isEmpty as _isEmpty} from "lodash";
import SurveyApi from "../../api/survey";
import cookies from "react-cookies";
import useUserProfile from "../../hooks/api/Global/useUserProfile";
import {useSurveyQuery} from "../../hooks/api/surveu/useSurveyQuery";

const Survey = () => {
    const [modalData, setModalData] = useState({
        showModal: false,
        hash: null,
        showModalLink: false,
        showModalError: false,
        modalErrorText: null
    });

    const {data: user} = useUserProfile();
    const {data: surveyAvailability} = useSurveyQuery();

    useEffect( () => {
        if(user && surveyAvailability) {
            SurveyApi.checkUserAnswer({
                user_id: user.id,
                user_type: "sso",
                answer_type: "short"
            }).then((res) => {
                if(res.status && _isEmpty(res.data)) {
                    SurveyApi.getUserHash({
                        id: user.id,
                        type: "sso"
                    }).then((res) => {
                        if(res.status) {
                            SurveyApi.checkUserAnswer({
                                user_id: user.id,
                                user_type: "sso",
                                answer_type: "long"
                            }).then((response) => {
                                if(response.status) {
                                    setModalData({...modalData, showModal: true, hash: res.hash});
                                } else {
                                    setModalData({...modalData, showModal: true, hash: false});
                                }
                            });
                        }
                    });
                }
            });
        }
    }, [surveyAvailability, user] );

    const saveAnswer = (answer) => {
        SurveyApi.newAnswer({
            answer_id: null,
            hash: modalData.hash,
            type: "short",
            data : {
                1: answer
            }
        }).then((response) => {
            if(!response.status) {
                setModalData({
                    ...modalData,
                    showModalError: true,
                    modalErrorText: response.errors
                });
            }
        });
    };

    const onClose = () => {
        saveAnswer("No");
        setModalData({...modalData, showModal: false, showModalLink: !!modalData.hash});
    };

    const handleDontShow = () => {
        const expires = new Date();
        expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 356);
        cookies.save( "short_survey_"+user.id, "1", {"expires": expires} );
        setModalData({...modalData, showModal: false, showModalLink: false});
    };

    const onSubmit = () => {
        saveAnswer("Yes");
        setModalData({...modalData, showModal: false, showModalLink: !!modalData.hash});
    };

    const handleLink = () => {
        setModalData({...modalData, showModal: false, showModalLink: false});
        window.open("https://"+process.env.SURVEY_LANDING_DOMAIN+"/customer_satisfaction_survey", "_blank");
    };

    return (
        <React.Fragment>
            {user && !cookies.load("short_survey_"+user.id) && <SweetAlert
                show={modalData.showModal}
                info
                showCancel
                closeOnClickOutside={false}
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnBsStyle="default"
                btnSize="xs"
                title={"Quick question…"}
                onConfirm={onSubmit}
                onCancel={onClose}
            >
                <p>Would you recommend Waites to a friend or colleague?</p>
                <p>
                    <a
                        className={"link link-primary"}
                        onClick={handleDontShow}
                    >
                        Don&apos;t show this message again.
                    </a>
                </p>
            </SweetAlert>}
            <SweetAlert
                show={modalData.showModalLink}
                showCancel
                cancelBtnText="No"
                cancelBtnBsStyle="default"
                confirmBtnText="Yes"
                btnSize="xs"
                title={"Satisfaction Customer Survey"}
                onConfirm={handleLink}
                onCancel={() => {
                    setModalData({...modalData, showModal: false, showModalLink: false});
                }}
            >
                We’d love to get your feedback so we can continue to improve our products and services. Your responses can be stored anonymously if desired. Would you like to take a quick, 2-minute survey?
            </SweetAlert>
        </React.Fragment>
    );
};

export default withRouter(Survey);