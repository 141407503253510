import ChartHelper from "../../../../../../helpers/chart";
import {useIsDarkTheme} from "../../../../../../hooks/helpers/theme";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useHelpModal} from "../../../../../../modals/help/hooks/useHelpModal";
import {useCallback, useMemo} from "react";
import {getSeriesList} from "../../../helpers/getSeriesList";
import {useChartTypesStore} from "../../../../../../stores/zustand/ChartSelectedChartTypeStore";
import {useChartHeight} from "../chartHooks";
import {useIsCircular} from "../../../hooks/useChartViewTypes";
import {useSpeedSettings} from "../helpers/useSpeedSettings";
import {useLockPoints} from "../useLockPoints";
import {useCurrentPointRef} from "../useCurrentPointRef";
import useUserProfile from "../../../../../../hooks/api/Global/useUserProfile";
import {useUserFftChartFrequency} from "../../../../../../stores/UserLocalSettingsStore";
import {useChartRef} from "../useChartRef";

export const useCommonChartOptionsBuilder = () => {
    const {currentSpeed} = useSpeedSettings();
    const chartTypes = useChartTypesStore();
    const isDarkTheme = useIsDarkTheme();

    const chartHeight = useChartHeight();

    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const fftCursor = useChartStateStoreContext((state) => state.settings.fftCursor);
    const currentFrequency = useUserFftChartFrequency();
    const chartViewType = useChartStateStoreContext((state) => state.settings.chartViewType);
    const countHarmonicPoints = useChartStateStoreContext((state) => state.settings.countHarmonicPoints);
    const isCircular = useIsCircular(chartViewType);
    const [showHelpModal] = useHelpModal(true, false);
    const {tickPositioner, xAxisLabelFormatter, chartTooltipFormatter, chartTypeName} = useChartStateStoreContext((state) => state.factory);
    const seriesListMemo = useMemo(() => getSeriesList(chartType, false, chartTypes), [chartType]);
    const chartRef = useChartRef();
    const {data: userSettings, isSuccess} = useUserProfile();

    const lockPoints = useLockPoints();
    const currentPointRef = useCurrentPointRef();

    const tooltipFormatter = useCallback(
        (e) => {
            return chartTooltipFormatter({
                hoverPoints: e?.chart.holdPoints || e?.chart.updatedHoverPoints,
                cursor: fftCursor,
                lockPointsRef: lockPoints,
                frequencyUnits: currentFrequency,
                countHarmonics: countHarmonicPoints,
                chartType,
                user: userSettings,
            });
        },
        [chartType, fftCursor, currentFrequency, lockPoints.current, countHarmonicPoints, isSuccess, currentSpeed]
    );

    const cursorMouseOverEventHandler = (chart) => {
        if (!chart.updatedHoverPoints || chart.moveMode) {
            return;
        }
        if (fftCursor === "harmonic" && !lockPoints.current.length) {
            ChartHelper.harmonicCursor(chart.updatedHoverPoints, chartTypeName, countHarmonicPoints);
        } else if (fftCursor === "sideband" && lockPoints.current?.length) {
            ChartHelper.sideBandCursor(lockPoints.current, chart.updatedHoverPoints);
        }
    };

    const mouseOverEvent = useCallback(
        (e) => {
            currentPointRef.current = {...e.target};
            if (e.chart?.isPaused && fftCursor === "harmonic") {
                return;
            }
            return cursorMouseOverEventHandler(e.target.series.chart);
        },
        [fftCursor, countHarmonicPoints]
    );

    return useMemo(
        () => ({
            lang: {
                contextButtonTitle: "Print and download options",
                noData: "There is no data for the selected period.",
            },
            title: {
                text: null,
            },
            chart: {
                animation: false,
                // zoomType: "xy",
                wrapZoomType: "xy",
                height: chartHeight,
                resetZoomButton: {
                    theme: {
                        style: {
                            display: "none",
                        },
                    },
                },
                selectionMarkerFill: "rgba(227, 187, 49, 0.22)",
                backgroundColor:
                    isDarkTheme === "true"
                        ? {
                              linearGradient: {x1: 0, y1: 0, x2: 1, y2: 1},
                              stops: [
                                  [0, "#3a3934"],
                                  [1, "#36342a"],
                              ],
                          }
                        : "#FFFFFF",
                events: {
                    afterPrint: function (e) {
                        e.chart.isPaused = false;
                    }.bind(this),
                },
                isPaused: false,
                polar: isCircular,
                isCircular: isCircular,
                chartType: false,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                line: {
                    lineWidth: 1,
                    marker: {
                        lineWidth: 0,
                        radius: 1,
                    },
                    states: {
                        hover: {
                            lineWidth: 1,
                        },
                    },
                },
                series: {
                    connectNulls: false,
                    animation: false,
                    states: {
                        inactive: {
                            opacity: 1,
                        },
                    },
                    events: {},
                    point: {
                        events: {
                            mouseOver: mouseOverEvent,
                        },
                    },
                },
            },
            tooltip: {
                positioner: ChartHelper.tooltipPositioner,
                formatter: tooltipFormatter,
                backgroundColor: null,
                borderWidth: 0,
                shadow: false,
                animation: false,
                shape: "rect",
                useHTML: true,
                shared: true,
                style: {
                    pointerEvents: "auto",
                },
            },
            legend: {
                enabled: false,
                itemStyle: {
                    color: isDarkTheme === "true" ? "#ffffff" : "#000000",
                },
            },
            xAxis: {
                currentSpeed: currentSpeed,
                currentFrequency: currentFrequency,
                crosshair: true,
                min: isCircular ? 0 : null,
                max: isCircular ? 360 : null,
                tickInterval: isCircular ? 30 : null,
                labels: {
                    formatter: xAxisLabelFormatter,
                },
                tickPositioner,
            },
            yAxis: {
                opposite: false,
                offset: -10,
                showFirstLabel: true,
                showLastLabel: true,
                title: {
                    text: seriesListMemo.label + (seriesListMemo.units !== "" ? `, ${seriesListMemo.units}` : ""),
                    margin: 5,
                    rotation: isCircular ? -90 : 270,
                    x: isCircular
                        ? chartRef?.current?.container?.current?.clientWidth
                            ? -(chartRef?.current?.container?.current?.clientWidth / 3)
                            : -400
                        : 0,
                },
                labels: {
                    x: isCircular
                        ? chartRef?.current?.container?.current?.clientWidth
                            ? -(chartRef?.current?.container?.current?.clientWidth / 3) + 40
                            : -360
                        : 0,
                },
                gridLineColor: isDarkTheme === "true" ? "rgba(200,200,200,0.4)" : "#e6e6e6",
                gridLineWidth: isDarkTheme === "true" ? 0.5 : "none",
            },
            series: [],
            exporting: {
                allowHTML: true,
                buttons: {
                    contextButton: {
                        menuItems: ["printChart", "downloadPNG", "downloadCSV"],
                    },
                    help: {
                        className: "charts-help-button",
                        symbol: "text:\uf1c6", //fa-expand,
                        symbolX: 14,
                        symbolY: 14,
                        symbolStrokeWidth: 1,
                        titleKey: "helpButtonTitle",
                        onclick: showHelpModal,
                    },
                },
                printMaxWidth: 1400,
                chartOptions: {
                    xAxis: {
                        plotLines: [],
                    },
                },
            },
        }),
        [seriesListMemo, currentFrequency, fftCursor, currentSpeed, isDarkTheme, chartType, chartViewType, chartHeight, countHarmonicPoints]
    );
};
