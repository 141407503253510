import React, {Component} from "react";
import PropTypes from "prop-types";
import BasePopover from "../shared/base-popover";
import Helper from "../../helpers/helper";
import {withGlobalStore} from "../../stores/GlobalStore";

class LogPopover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }
    toggle = () => {
        this.setState({show: !this.state.show});
    };

    render() {
        const {show} = this.state;
        const {user, list} = this.props;
        return (
            <React.Fragment>
                <BasePopover
                    {...this.props.popoverProps}
                    isOpen={show}
                    toggle={this.toggle}
                >
                    <div className="timeline">
                        {list.length > 0 &&
                            list.map( item =>
                                <div key={item.id} className="timeline-item timeline-item--custom">
                                    <div className="timeline-item__section">
                                        <div className="timeline-item__border bg-white">
                                            <div className="timeline-item__icon">
                                                <i className="fa fa-info-circle"/>
                                            </div>
                                        </div>
                                        <span className="timeline-item__datetime timeline-item__datetime--line ml-2">
                                            {Helper.dateToUserFormat(item.timestamp, user)}
                                        </span>
                                    </div>
                                    <span className="timeline-item__text ml-5">
                                        {item.description}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                </BasePopover>
            </React.Fragment>
        );
    }
}
LogPopover.propTypes = {
    popoverProps: PropTypes.object,
    list: PropTypes.array,
    user: PropTypes.object,
};
export default withGlobalStore(LogPopover);