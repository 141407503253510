import React, {Component} from "react";
import PropTypes from "prop-types";
import {get as _get} from "lodash";
import {Link} from "react-router-dom";
import Helper from "../../../helpers/helper";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {ResetSortButton} from "../../shared/resetSortButton";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";

const headersList = {
    "node.serial": {title: "Node Serial Number", sort: true},
    "location_info": {title: "Location", sort: true},
    "port": {title: "Port", sort: false},
    "max_rpm": {title: "Maximum RPM Value", sort: false},
    "thresh_low": {title: "RPM Trigger Threshold - Low", sort: true},
    "thresh_high": {title: "RPM Trigger Threshold - High", sort: true},
    "poll_freq": {title: "Poll Frequency", sort: true},
    "min_period": {title: "Minimum Period", sort: true},
    "actions": {title: "Actions",  additionalClasses: "table-buttons-th", sort: false, component: ResetSortButton}
};

class Table extends Component
{
    render() {
        const {auth, list, sort, onSortChange, onDeleteTachometer, query} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <div className="table-scroll-wrapp tachometers">
                <table className="table table-hover tl-fixed">
                    <thead>
                        <tr>
                            {Object.keys(headersList).map(key => {
                                let component = "";
                                const RowComponent = _get(headersList, [key, "component"], false);
                                if (RowComponent) {
                                    component = <RowComponent sort={sort} resetSort={() => onSortChange("")}/>;
                                }
                                return (
                                    listLen && headersList[key].sort
                                        ? (
                                            <th className={headersList[key].additionalClasses || ""} key={key} onClick={() => onSortChange(key)}>
                                                <div className="title">
                                                    <span>{headersList[key].title}</span>
                                                    {sort.field === key ? <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")}/> : <i className={"fa fa-sort"} />}
                                                </div>
                                            </th>
                                        )
                                        : (key !== "actions" || auth.userCan("editTachometers")) && (
                                            <th className={headersList[key].additionalClasses || ""} width={_get(headersList[key], "width", "")} key={key}>
                                                <span className="title">{headersList[key].title}</span> {component}
                                            </th>
                                        )
                                );
                            }
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {list.length > 0
                            ?
                            list.map((tachometer) => <TachometerRow query={query} key={tachometer.id} tachometer={tachometer} auth={auth} onDeleteTachometer={onDeleteTachometer}/>)
                            :
                            <tr>
                                <td colSpan={listLen} className="text-center text-info">
                                    {query !== "" ? "No items match your search." : "No tachometers were found."}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

Table.propTypes = {
    auth: PropTypes.object,
    list: PropTypes.array,
    sort: PropTypes.object,
    onSortChange: PropTypes.func,
    onDeleteTachometer: PropTypes.func,
    query: PropTypes.string
};

const TachometerRow = ({tachometer, query, auth, onDeleteTachometer}) => {

    const max_rpm = tachometer.max_rpm ? Helper.highlight(tachometer.max_rpm, query) : "---";

    return (
        <tr>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(tachometer.node.serial, query)}}/>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(tachometer.location_info || "---", query)}}/>
            <td><TachometerPort auth={auth} port={+tachometer.port + 1} tachometerPoints={tachometer.tachometerPoints} /></td>
            <td dangerouslySetInnerHTML={{__html: max_rpm}}/>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(tachometer.thresh_low, query)}}/>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(tachometer.thresh_high, query)}}/>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(tachometer.poll_freq, query)}}/>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(tachometer.min_period, query)}}/>
            {auth.userCan("editTachometers") &&
                <td style={{ textAlign: "left" }}>
                    <div className="btn-group btn-group-sm">
                        <button
                            className="link link-danger"
                            onClick={() => onDeleteTachometer(tachometer.id)}
                        >
                            <i className="fa fa-times"/> <span>Delete</span>
                        </button>
                        <Link to={`/network/tachometers/edit/${tachometer.id}`} className="link link-primary">
                            <i className="fa fa-pen"/> <span>Edit</span>
                        </Link>
                    </div>
                </td>
            }
        </tr>
    );
};

TachometerRow.propTypes = {
    tachometer: PropTypes.object,
    query: PropTypes.string,
    auth: PropTypes.object,
    onDeleteTachometer: PropTypes.func
};

const Port = ({port}) => {
    if (!port.sensor) {
        return <i className="badge badge-secondary">Empty Port</i>;
    }

    return <span>{_get(port.sensor, "installationPoint.name", "Unassigned sensor")}</span>;
};

Port.propTypes = {
    port: PropTypes.object
};

const TachometerPort = ({auth, port, tachometerPoints}) => {

    if (!(tachometerPoints || []).length) {
        return <span
            style={{minWidth: "1.5rem", borderRadius: 0, display: "inline-block", lineHeight: "1.6rem"}}
            title="No Equipment"
        >
            {port}
        </span>;
    } else if (tachometerPoints.length === 1) {

        if(auth.isHybrid && tachometerPoints[0].equipment.service === "full"){
            if(auth.userCan("showFullServiceEquipments")){
                return <Link
                    to={`/chart/${tachometerPoints[0].equipment_id}`}
                    className={"link link-primary"}
                    style={{minWidth: "1.5rem", borderRadius: 0, display: "inline-block", lineHeight: "1.6rem", marginLeft: 0}}
                    title={tachometerPoints[0].equipment.name + " - Full-Service Equipment"}
                >
                    {port}
                </Link>;
            }else{
                return <span
                    style={{minWidth: "1.5rem", borderRadius: 0, display: "inline-block", lineHeight: "1.6rem"}}
                    title={tachometerPoints[0].equipment.name + " - Full-Service Equipment"}
                >
                    {port}
                </span>;
            }
        }else{
            return <Link
                to={`/chart/${tachometerPoints[0].equipment_id}`}
                className={"link link-primary"}
                style={{minWidth: "1.5rem", borderRadius: 0, display: "inline-block", lineHeight: "1.6rem", marginLeft: 0}}
                title={tachometerPoints[0].equipment.name}
            >
                {port}
            </Link>;
        }
    } else {
        return <UncontrolledDropdown className="d-inline-block">
            <DropdownToggle
                tag={"button"}
                className={"link link-primary"}
                style={{minWidth: "1.5rem", borderRadius: 0, display: "inline-block", lineHeight: "1.6rem", cursor: "pointer", marginLeft: 0}}
                title={"Select Equipment"}
            >
                {port}
            </DropdownToggle>
            <DropdownMenu>
                {tachometerPoints.map((tachometerPoint, index) => {
                    if(auth.isHybrid && tachometerPoint.equipment.service === "full"){
                        if(auth.userCan("showFullServiceEquipments")){
                            return (
                                <DropdownItem key={index} tag={Link} to={`/chart/${tachometerPoint.equipment_id}`}>
                                    {tachometerPoint.equipment.name + " - Full-Service Equipment"}
                                </DropdownItem>
                            );
                        }else{
                            return (
                                <DropdownItem key={index}>
                                    {tachometerPoint.equipment.name + " - Full-Service Equipment"}
                                </DropdownItem>
                            );
                        }
                    }else{
                        return (
                            <DropdownItem key={index} tag={Link} to={`/chart/${tachometerPoint.equipment_id}`}>
                                {tachometerPoint.equipment.name}
                            </DropdownItem>
                        );
                    }
                })}
            </DropdownMenu>
        </UncontrolledDropdown>;
    }
};

TachometerPort.propTypes = {
    auth: PropTypes.object,
    port: PropTypes.number,
    tachometers: PropTypes.array
};

export default withGlobalStore(Table);