import PropTypes from "prop-types";
import {Modal} from "../../../../shared";
import React from "react";
import {
    orderBy as _orderBy,
} from "lodash";
import EquipmentInfoTable from "./table";

const EquipmentInfoModal = ({item, onCancel}) => {

    const list = _orderBy(item.universalAdapterParameters, "installationPoint.equipment.name");

    return (
        <React.Fragment>
            <Modal
                showModal={true}
                title={"Associated Equipment"}
                size={"xl"}
                onClose={onCancel}
                withoutFooter={true}
                withoutSubmit={true}
            >
                <EquipmentInfoTable list={list} />
            </Modal>
        </React.Fragment>
    );
};

EquipmentInfoModal.propTypes = {
    item: PropTypes.object,
    onCancel: PropTypes.func
};

export default EquipmentInfoModal;