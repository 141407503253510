import { useChartStateStoreContext } from "../../store/hooks/useStoreContext";
import { shallow } from "zustand/shallow";
import { useMemo } from "react";
import { getFaultFrequenciesPlotLines } from "../../helpers/plotLines";
import { useFaultFrequencyQuery } from "../../../../../../hooks/api/faultFrequency/useFaultFrequencyQuery";
import { useSpeedSettings } from "../helpers/useSpeedSettings";

export const useFaultFrequencyBuilder = (equipment) => {
    const {faultFrequency} = useFaultFrequencyQuery(equipment.id);
    const selectedFaultFrequencies = useChartStateStoreContext((state) => state.settings.selectedFaultFrequencies, shallow);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint, shallow);

    const {currentSpeed} = useSpeedSettings();

    return useMemo(() => {
        return getFaultFrequenciesPlotLines(
            faultFrequency,
            currentSpeed,
            selectedFaultFrequencies,
            selectedPoint
        );
    }, [faultFrequency, selectedFaultFrequencies, currentSpeed]);
};
