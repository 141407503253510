import {fftAlertTypes} from "../../../../constants/constants";
import {useHashParams} from "../../../../hooks/helpers/useHashParams";
import {find as _find, get as _get} from "lodash";
import {ALERT_TABS_NAME} from "../../constants/tabs";
import Helper from "../../../../helpers/helper";
import {useInstallationPoint} from "../useInstallationPoint";
import {useInstallationPointsListQuery} from "../../../../hooks/api/installationPoints/useInstallationPointsListQuery";
import {useMemo} from "react";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import {useHistory} from "react-router";

const noAxisChartTypes = [1, 13, 14, 45];

export const useIsChartFft = (chartTypes, chartType) => {
    // TODO memo
    return ((chartTypes || {})[chartType] || {}).type === "fft";
};

export const useIsTachometer = (alertType) => {
    let isTachometer = false;
    if (typeof alertType == "string") {
        alertType === ALERT_TABS_NAME.TACHOMETER_RPM;
    } else if (typeof alertType == "object") {
        alertType.key === ALERT_TABS_NAME.TACHOMETER_RPM;
    }
    return isTachometer;
};

export const useChartType = (chartTypes) => {
    const history = useHistory();
    const hashParams = useHashParams(history.location.hash);
    const chartType = hashParams.type || "1";

    const isTach = +chartType === 14;
    const isChartFFT = ((chartTypes || {})[chartType] || {}).type === "fft";
    const fftAlertType = isChartFFT ? +hashParams.fftAlertType || fftAlertTypes.BAND : undefined;
    const isBand = +fftAlertType === fftAlertTypes.BAND;
    const isEnvelope = +fftAlertType === fftAlertTypes.ENVELOPE;
    return {isBand, isEnvelope, fftAlertType, isChartFFT, isTach, chartType};
};

export const useChartComputed = (chartTypes) => {
    const {equipmentItem: equipment} = useEquipmentByParams();
    const hashParams = Helper.getHashParams();
    const chartType = hashParams.type || "1";

    const locationId = _get(equipment, "location_id");

    const alias = _get(chartTypes, [chartType, "alias"]) || "";
    const axisId = alias === "temp" ? 4 : +hashParams.axisId || 0;
    const units = _get(chartTypes, `${chartType}.series.${axisId || 1}.units`) || "";
    const readingType = +_get(chartTypes, `${chartType}.series.${axisId}.readingType`) || 0;
    const isTachometerExist = !!_find(equipment.installationPoints, (item) => item.point_type === "tach");
    const hasAxis = noAxisChartTypes.indexOf(+chartType) === -1;

    const {installationPoints, isSuccess: isInstallationPointSuccess} = useInstallationPointsListQuery(equipment.id);

    const installationPoint = useInstallationPoint(equipment, chartType);
    const versionType = +((installationPoint.sensor || {}).version_type || 0);

    const specs = useMemo(() => {
        let specs;
        if (installationPoints && isInstallationPointSuccess) {
            specs =
                _get(
                    _find(installationPoints, (point) => +point.id === +installationPoint.id),
                    "sensorParameters"
                ) || {};
        }
        return specs;
    }, [installationPoints]);

    return {alias, axisId, units, readingType, isTachometerExist, chartType, hasAxis, locationId, specs, versionType};
};
