export const MODALS = {
    SPEED_SETTINGS: "speed-settings",
    NAMEPLATE: "nameplate",
    REQUEST_ON_DEMAND: "request-on-demand",
    NOTES: "notes",
    ACTIVE_ALERTS: "active-alerts",
    IDLE_TRESHOLD: "idle-threshold",
    EQUIPMENT_PROFILE: "equipment-profile",
    EQUIPMENT_PROFILE_REMOVE: "equipment-profile-remove",
    UNIVERSAL_ADAPTER_TABLE: "universal-adapter-table",
    BEARING: "bearing",
    PEAKS: "peaks",
    MANAGE_AXIS: "manage-axis",
    CHART_MARKS_SIMPLE: "chart-mark-form",
    EDIT_CHART_MARKS_SIMPLE: "chart-mark-edit-form",
};
