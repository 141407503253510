import React, {Component} from "react";
import PropTypes from "prop-types";
import AsyncCreatableSelect from "react-select/async-creatable";
import {
    get as _get,
    find as _find
} from "lodash";

const selectStyles = {
    control: (provided) => ({
        ...provided,
        height: 32,
        minHeight: 32
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: "100000"
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#9E9E9E",
    }),
    option: (provided) => ({
        ...provided,
        padding: "0 7px"
    }),
};

const darkModeSelectStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: "rgb(56, 56, 46)",
        height: 32,
        minHeight: 32
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#ccc"
    }),
    singleValueLabel: (provided) => ({
        ...provided,
        color: "#ccc"
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#36342a"
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: "100000"
    }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused
                ? "#aaa"
                : null,
            color: isFocused
                ? "#333"
                : "#ddd",
            padding: "0 7px"
        };
    },
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    input: (provided) => ({
        ...provided,
        color: "#ccc"
    })
};

const isDarkTheme = localStorage.getItem("lightMode");

class NameSelect extends Component
{
    loadOptions = (inputValue) => {
        const {fetchBearings} = this.props;

        return fetchBearings(inputValue)
            .then((bearings) => {
                return (bearings || []).map(bearing => {
                    return {label: bearing.name, value: bearing.id};
                });
            });
    }

    onChangeSelect = (target, event) => {
        if (event.action === "select-option" && _get(target, "value")) {
            this.setSelectedBearingValue(_get(target, "value"));
        }
        if (event.action === "create-option" || !_get(target, "value")) {
            this.setSelectedBearingValue("", _get(target, "label", ""));
        }
    }

    setSelectedBearingValue = (id, name = "") => {
        const {index, defaultBearings, onChange} = this.props;
        const bearing = _find(defaultBearings, {id: id});

        onChange({
            target: {
                name: "bearings."+index+".bearing",
                value: {
                    id: _get(bearing, "id", ""),
                    name: id ? _get(bearing, "name") : name,
                    mfr: _get(bearing, "mfr", ""),
                    balls_rollers: _get(bearing, "balls_rollers", ""),
                    ftf: _get(bearing, "ftf", ""),
                    bsf: _get(bearing, "bsf", ""),
                    bpfo: _get(bearing, "bpfo", ""),
                    bpfi: _get(bearing, "bpfi", ""),
                    plot_lines_count: 10
                }
            }
        });
    }

    render() {
        const {defaultBearings, bearing, className, onBlur} = this.props;

        let defaultOptions = defaultBearings.map(bearing => {
            return {label: bearing.name, value: bearing.id};
        });

        if (_get(bearing, "name") && !_find(defaultOptions, {value: bearing.id})) {
            defaultOptions.push({label: bearing.name, value: bearing.id});
        }

        return (
            <React.Fragment>
                <AsyncCreatableSelect
                    className={"form-control-select " + className}
                    loadOptions={this.loadOptions}
                    defaultOptions={defaultOptions}
                    value={bearing ? {label: bearing.name, value: bearing.id} : null}
                    styles={ isDarkTheme === "true" ? darkModeSelectStyles : selectStyles }
                    isClearable={false}
                    placehoder={"Select Bearing Name"}
                    onChange={this.onChangeSelect}
                    closeMenuOnScroll={(e) => e && e.target && e.target.classList && e.target.classList.contains("custom-scroll")}
                    menuPortalTarget={document.body}
                    menuPlacement={"auto"}
                    formatCreateLabel={(label) => "Create \"" + label + "\" (press enter to create new type)"}
                    onBlur={onBlur}
                />
            </React.Fragment>
        );
    }

}

NameSelect.propTypes = {
    profile: PropTypes.object,
    index: PropTypes.number,
    removeBearing: PropTypes.func,
    defaultBearings: PropTypes.array,
    fetchBearings: PropTypes.func,
    onChange: PropTypes.func,
    bearing: PropTypes.object,
    className: PropTypes.string,
    onBlur: PropTypes.func
};

export default NameSelect;