import Api from "./api";

const DeviceAlertGroupsApi = {
    get: id => Api.get("device-subscription-groups/" + id),
    // tree: id => Api.get(`device-subscription-groups/${id}/tree`),
    list: query => Api.get("device-subscription-groups", {query}),
    store: data => Api.post("device-subscription-groups", data),
    update: (id, data) => Api.patch("device-subscription-groups/" + id, data),
    delete: id => Api.delete("device-subscription-groups/" + id),
    facilities: () => Api.get("device-subscription-group-options/facilities"),
    gateways: () => Api.get("device-subscription-group-options/gateways"),
    routers: () => Api.get("device-subscription-group-options/routers"),
    nodes: () => Api.get("device-subscription-group-options/nodes"),
    motes: () => Api.get("device-subscription-group-options/motes"),
    sensors: () => Api.get("device-subscription-group-options/sensors"),
    issues: () => Api.get("device-subscription-group-options/issues")
};

export default DeviceAlertGroupsApi;