import React, {Component} from "react";
import PersonalInfoForm from "./personal-info-form";
import ChangePasswordForm from "./change-password-form";
import PropTypes from "prop-types";
import Helper from "../../helpers/helper";
import {withRouter} from "react-router";
import {withGlobalStore} from "../../stores/GlobalStore";
import SearchInput from "../shared/search-input";
import NotificationsForm from "./notifications-form";
import UploadLogoForm from "./upload-logo-form";

import "../../assets/scss/components/users/user-profile.scss";
import { HeaderSimple } from "../../shared/header";

const tabs = [
    {key: "personalInfo", title: "Personal Information", icon: "fa-user"},
    {key: "changePassword", title: "Change Password", icon: "fa-cog"},
    {key: "notifications", title: "Notifications", icon: "fa-bell"},
    {key: "uploadLogo", title: "Upload Company Logo", icon: "fa-image"},
];


const propTypes = {
    user: PropTypes.object,
    chartTypes: PropTypes.object,
    auth: PropTypes.object,
    history: PropTypes.object,
    updateUser: PropTypes.func
};

class Profile extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{name: "Profile"}],
            globalSearchString: ""
        };

        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    render() {
        const {user, chartTypes, auth, history, updateUser} = this.props;
        const {breadcrumbs, globalSearchString} = this.state;
        const searchParams = Helper.getHashParams();
        let newTabs = [];

        if(!auth.userCan("editAssetsTree")) {
            newTabs = tabs.filter(item => item.key !== "uploadLogo");
        } else {
            newTabs = tabs;
        }

        return (
            <React.Fragment>
                 <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="block">
                    <div className="user-profile">
                        <div className="user-profile-content">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-xl-4">
                                    <div className="user-profile-info">
                                        <div className="user-profile-img">
                                            <img src={user.userpic} alt="Userpic" />
                                        </div>
                                        <div className="user-profile-description">
                                            <div className="user-profile-name">{user.name_first} {user.name_last}</div>
                                            <div className="user-profile-company">{user.company}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                    <div className="user-profile-contacts">
                                        <span className="user-profile-contacts-item">
                                            <span className="user-profile-contacts-icon color-success">
                                                <i className="fab fa-whatsapp"/>
                                            </span>
                                            <span className="">{user.phone}</span>
                                        </span>
                                        <span className="user-profile-contacts-item">
                                            <span className="user-profile-contacts-icon color-danger">
                                                <i className="fas fa-envelope"/>
                                            </span>
                                            <span className="">{user.email}</span>
                                        </span>
                                    </div>
                                </div>
                                {user.customer.is_custom_header_image &&
                                    <div className="col-lg-6 col-xl-4">
                                        <div className="wrapper-logo">
                                            <div className="user-profile-logo-block text-left">
                                                <span className="d-block mb-3">Company Logo:</span>
                                                <img src={user.customer.header_image || ""} style={{maxHeight: "30px"}} alt="Userpic" />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="user-profile-nav">
                            <ul className="nav nav-tabs nav-tabs-profile" role="tablist">
                                {newTabs.map(tab =>
                                    <li key={tab.key} className="nav-item">
                                        <a
                                            className={"nav-link" + ((!searchParams.tab && tab.key === tabs[0].key) || searchParams.tab === tab.key ? " active" : "")}
                                            onClick={() => history.push(Helper.setHashParams({tab: tab.key}))}
                                        >
                                            <span className="nav-link-icon"><i className={`fa ${tab.icon}`}/></span>
                                            <span className="nav-link-text">{tab.title}</span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="block">
                    <div className="tab-content">
                        <div className="tab-pane fade show active">
                            {(searchParams.tab === "personalInfo" || !searchParams.tab) && <PersonalInfoForm user={user} chartTypes={chartTypes} auth={auth}/>}
                            {(searchParams.tab === "changePassword") && <ChangePasswordForm/>}
                            {(searchParams.tab === "notifications") && <NotificationsForm user={user} auth={auth} updateUser={updateUser}/>}
                            {(auth.userCan("editAssetsTree") && searchParams.tab === "uploadLogo") && <UploadLogoForm user={user} auth={auth} updateUser={updateUser}/>}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const headerPropTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item ml-2">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = headerPropTypes;
Profile.propTypes = propTypes;

export default withGlobalStore(withRouter(Profile));