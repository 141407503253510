import React, {Component} from "react";
import PropTypes from "prop-types";
import sensorsApi from "../../../api/sensor";
import {Loader, Pager} from "../../../shared";
import Table from "./table";
import SearchInput from "../../shared/search-input";
import LimitSelect from "../../shared/limit-select";
import Helper from "../../../helpers/helper";
import {
    get as _get,
} from "lodash";

import "../../../assets/scss/components/tachometers/tachometers.scss";
import {withLocationSelectStore} from "../../../stores/LocationSelectStore";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import { HeaderSimple } from "../../../shared/header";

class Sensors extends Component
{
    constructor(props) {
        super(props);

        const serial = Helper.getHashParams().serial;
        let query = "";
        if (serial) {
            query = serial;
        }

        this.state = {
            breadcrumbs: [{name: "Manage Tethered Sensors"}],
            loader: true,
            list: [],
            pagination: {
                page: 1,
                pages: 1,
                perpage: Helper.getPerPage("sensors"),
                total: 0
            },
            sort: {
                field: "",
                sort: "asc"
            },
            filter: {
                query: query,
                locationId: props.getLocationId()
            },
            deleteSensorId: null,
            globalSearchString: ""
        };

        this.finished = false;

        this.handleFetch = this.handleFetch.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handlePagerChange = this.handlePagerChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.locationChange = this.locationChange.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    componentDidMount() {
        this.handleFetch();
    }

    handleFetch() {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        sensorsApi.sensorList({
            query: {
                sort: this.state.sort,
                filter: this.state.filter,
                pagination: this.state.pagination
            },
            signal: this.signal
        }).then((response) => {
            if (response) {
                const {meta = [], list = {}} = response;
                this.setState({
                    loader: false,
                    pagination: Object.assign({}, this.state.pagination, meta),
                    list
                }, () => {
                    if (!this.finished) {
                        this.finished = true;
                    }
                });
            }

        });
    }

    handleSortChange(field = "") {
        const {sort} = this.state;

        this.setState({
            sort: Object.assign({}, {
                field,
                sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"
            }),
            loader: true
        }, this.handleFetch);
    }

    handlePagerChange(page = 1) {
        this.setState({loader: true, pagination: Object.assign({}, this.state.pagination, {page})}, this.handleFetch);
    }

    handleFilterChange(obj = {}) {
        this.finished = false;
        this.setState({
            filter: Object.assign({}, this.state.filter, obj),
            pagination: Object.assign({}, this.state.pagination, {page: 1}),
            loader: true
        }, this.handleFetch);
    }

    locationChange(e) {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }
        this.handleFilterChange({locationId: e.target.value});
    }

    searchChange(value) {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }
        this.handleFilterChange({query: value});
    }
    handleLimitChange = e => {
        const {value} = e.target;
        Helper.setPerPage("sensors", value);
        this.setState({
            loader: true,
            pagination: {
                ...this.state.pagination,
                page: 1,
                perpage: value
            }
        }, this.handleFetch);
    };

    render() {
        const {
            breadcrumbs,
            loader,
            list,
            pagination,
            sort,
            filter,
            globalSearchString
        } = this.state;

        return (
            <div>
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">Manage Tethered Sensors</div>
                </div>
                <div className="block">
                    <div className="block-header">
                        <div className="block-label">
                            <div className="form-list form-list--row">
                                <div className="form-group form-group--inline">
                                    <React.Fragment>
                                        <div className="form-label"><label>Asset Tree Branch:</label></div>
                                        <CollapseLocationSelect
                                            className={"form-control form-control-sm"}
                                            selectName={"locationId"}
                                            value={+_get(filter, ["locationId"], 0)}
                                            onChange={this.locationChange}
                                            emptyOptionLabel={"All"}
                                            checkStoreValue={true}
                                            withFavorite={true}
                                        />
                                    </React.Fragment>
                                </div>
                            </div>
                        </div>
                        <div id="top-pagination-block" className="block-pagination">
                            {pagination.total > 10 &&
                            <div className="limit-select-block">
                                <LimitSelect
                                    name={"perpage"}
                                    onChange={this.handleLimitChange}
                                    defaultValue={pagination.perpage}
                                    limits={[10, 20, 50, 100]}
                                />
                            </div>
                            }
                            {pagination.pages > 1 &&
                            <Pager
                                page={pagination.page}
                                pages={pagination.pages}
                                perPage={pagination.perpage}
                                onPagerChange={this.handlePagerChange}
                                query={filter.query}
                            />
                            }
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <div style={{"width": "150px"}}>
                                    <SearchInput
                                        placeholder="Search Sensors"
                                        query={filter.query}
                                        onChange={this.searchChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-body">
                        {loader
                            ?
                            <Loader/>
                            :
                            <div className="tachometers">
                                <Table
                                    list={list}
                                    sort={sort}
                                    query={filter.query}
                                    onSortChange={this.handleSortChange}
                                />
                            </div>
                        }
                    </div>
                </div>
                <button
                    disabled={list.length > 0 ? false : true}
                    title={list.length > 0 ? "" : "There is no data to export at this time."}
                    onClick={ () => {
                        this.setState({ loader: true });
                        sensorsApi.export({
                            query: {
                                sort: this.state.sort,
                                filter: this.state.filter
                            },
                            signal: this.signal
                        }).then(response => response.blob()).then( (blob) => {
                            this.setState({ loader: false });
                            const url = window.URL.createObjectURL(new Blob([blob]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", "tethered sensors " + new Date().toLocaleString("en-US")+".csv");
                            link.setAttribute("id", "test-file");
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        } );
                    } }
                    className="btn v2-btn blue ml-3"
                    style={{width:100}}
                >
                    <img src={"/assets/pic/icon-download.svg"} alt=""/>
                    <span>Export</span>
                </button>
            </div>
        );
    }
}

Sensors.propTypes = {
    history: PropTypes.object,

    // LocationSelectStore
    getLocationId: PropTypes.func
};

export default withLocationSelectStore(Sensors);
