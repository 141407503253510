import {useModal} from "react-modal-hook";
import {useHistory} from "react-router";
import AddChartMarkModal from "../components/addChartMark";
import React from "react";
import useChartMarksQuery from "../../../hooks/api/chartMarksSimple/useChartMarksQuery";
import {useCurrentPointRef} from "../../../pages/chart/highcharts/simple-new/hooks/useCurrentPointRef";
import {useModalHistory} from "../../../hooks/helpers/useModalHistory";
import {MODALS} from "../../../constants/modals";
import Helper from "../../../helpers/helper";
import {useChartMarksCreateMutation} from "../../../hooks/api/chartMarksSimple/useChartMarksCreateMutation";
import {useChartMarksUpdateMutation} from "../../../hooks/api/chartMarksSimple/useChartMarksUpdateMutation";
import {useChartStateStoreContext} from "../../../pages/chart/highcharts/simple-new/store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";

import {useChartSelectedEquipmentStore} from "../../../stores/zustand/ChartSelectedEquipmentStore";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";

export const useAddChartMark = () => {
    const history = useHistory();

    const {setChartMarkId} = useChartStateStoreContext((state) => state.actions, shallow);
    const chartMarkId = useChartStateStoreContext((state) => state.settings.chartMarkId);

    const equipmentSelected = useChartSelectedEquipmentStore();
    const {equipmentItem} = useEquipmentByParams();

    const currentPoint = useCurrentPointRef();
    const {chartMarksData} = useChartMarksQuery(equipmentItem.id);

    const {mutateAsync: createChartMark} = useChartMarksCreateMutation(equipmentItem.id);
    const {mutateAsync: updateChartMark} = useChartMarksUpdateMutation(equipmentItem.id, chartMarkId);

    const [showModal, hideModal] = useModal(
        () => (
            <AddChartMarkModal
                history={history}
                chartMarks={chartMarksData}
                chartMarkId={+chartMarkId}
                equipmentSelected={equipmentSelected}
                onClose={onHide}
                onUpdate={updateChartMark}
                timestamp={+Helper.getHashParams().timestamp}
                onCreate={createChartMark}
                updateEquipmentNotesCount={() => {}}
                updateCountGlobalNotes={() => {}}
                equipment={equipmentItem}
            />
        ),

        [chartMarksData, chartMarkId]
    );

    const modalName = chartMarkId ? MODALS.EDIT_CHART_MARKS_SIMPLE : MODALS.CHART_MARKS_SIMPLE;

    const {pushHistory, deleteHistory} = useModalHistory(modalName, showModal, {
        timestamp: currentPoint.current?.x,
        "mark-id": chartMarkId,
    });
    const onShow = () => {
        pushHistory();
        showModal();
    };

    const onHide = () => {
        deleteHistory();
        hideModal();
        setChartMarkId(null);
    };

    return [onShow, hideModal];
};
