import Api from "./api";

const Router = {
    list: (params) => {
        return Api.get("routers", {...params});
    },
    view: (serial) => {
        return Api.get(`routers/${serial}`);
    },
    update: (serial, body) => {
        return Api.post(`routers/update/${serial}`, body);
    },
    associate: (body) => {
        return Api.post("routers/associate", body);
    },
    delete: (serial) => {
        return Api.delete(`routers/${serial}`);
    },
    attachImages: (serial, body) => {
        return Api.post(`routers/attach-images/${serial}`, body);
    },
    detachImage: (serial, body) => {
        return Api.post(`routers/detach-image/${serial}`, body);
    },
    defaultImage: () => {
        return Api.get("routers/default-image");
    },
    detachAllImages: (serial, body) => {
        return Api.post(`routers/detach-images/${serial}`, body);
    },
    export: (params) => {
        return Api.source("routers/export", {...params});
    },
    onFlipImage: (body) => {
        return Api.post("routers/flip-image", body);
    },
    packetLoss: (serial, params) => Api.get(`routers/${serial}/packet-loss`, {query: {...params}}),
    signalStrength: (serial, params) => Api.get(`routers/${serial}/signal-strength`, {query: {...params}}),
    signalQuality: (serial, params) => Api.get(`routers/${serial}/signal-quality`, {query: {...params}}),
};

export default Router;
