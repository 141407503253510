import React, {Component} from "react";
import {ValidationError} from "../../shared";
import FormValidator from "../../helpers/form-validator";
import PropTypes from "prop-types";
import {
    get as _get,
    set as _set,
} from "lodash";
import ApiTachometers from "../../api/tachometer";
import Toastr from "../../pages/shared/toast";
import {UncontrolledTooltip} from "reactstrap";

const rules = [
    {
        field: "thresh_low",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "thresh_low",
        method: "isInt",
        validWhen: true,
        message: "This field should be valid number."
    },
    {
        field: "thresh_high",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "thresh_high",
        method: "isInt",
        validWhen: true,
        message: "This field should be valid number."
    },
    {
        field: "poll_freq",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "poll_freq",
        method: "isInt",
        validWhen: true,
        message: "This field should be valid number."
    },
    {
        field: "min_period",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "min_period",
        method: "isInt",
        validWhen: true,
        message: "This field should be valid number."
    },
    {
        field: "max_rpm",
        method: "isFloat",
        options: {"min": 0},
        validWhen: true,
        skipOnEmpty: true,
        message: "Maximum RPM Value should be a numeric value and not less than 0."
    },
];

const propTypes = {
    nodeTriggerSettings: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool,
    ]),
    tachometerSpeedSettingsToggle: PropTypes.func,
    nodeSerial: PropTypes.number,
    updateValue: PropTypes.func,
    equipment: PropTypes.object,
    invalidateEquipment: PropTypes.func
};

class SettingsForm extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator(rules);

        this.state = {
            data: this.props.nodeTriggerSettings,
            validation: this.validator.valid(),
            inProgress: false,
            nodeSerial: this.props.nodeSerial
        };

        this.onChange = this.onChange.bind(this);
        this.updateTachometerSettings = this.updateTachometerSettings.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onChange(e) {
        const data = {...this.state.data};
        _set(data, _get(e, "target.name", ""), _get(e, "target.value", ""));
        const validation = this.validator.validate(data, _get(e, "target_name", ""));
        this.props.updateValue(data);
        this.setState({data, validation});
    }

    updateTachometerSettings(data) {
        data.serial = this.state.nodeSerial;
        return ApiTachometers.update(this.state.data.id, {data});
    }

    handleSubmit() {
        const validation = this.validator.validate(this.state.data);
        const {data} = Object.assign({}, this.state);

        this.setState(
            {inProgress: true},
            () => {
                this.setState({validation}, () => {
                    if (validation.isValid) {
                        this.updateTachometerSettings(data).then(({status, message}) => {
                            if (status === "ok") {
                                this.props.invalidateEquipment();
                                Toastr.success("Tachometer settings updated successfully");
                                this.setState({
                                    inProgress: false
                                });
                            } else {
                                Toastr.error(message);
                                this.setState({
                                    inProgress: false
                                });
                            }
                        }).catch(err => {
                            if (err.errors) {
                                const validation = this.state.validation;

                                Object.keys(err.errors).map(key => {
                                    validation[key].isValid = false;
                                    validation[key].message = err.errors[key];
                                });

                                this.setState({
                                    validation,
                                    inProgress: false
                                });
                            } else {
                                this.setState({
                                    inProgress: false
                                });
                            }
                        });
                    } else {
                        this.setState({inProgress: false});
                    }
                });
            }
        );
    }

    render() {

        const {
            validation,
            data,
            inProgress
        } = this.state;

        const {
            tachometerSpeedSettingsToggle
        } = this.props;

        return (
            <React.Fragment>
                <div className="form-group">
                    <div className="row mb-3">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="text-right form-label">
                                    <span className="mr-2" id="setting-rpmv-info">
                                        <i className="fa fa-info-circle"></i></span>
                                    <UncontrolledTooltip placement="top" target="setting-rpmv-info">
                                        <p className="tooltip-text">The maximum number of Revolutions Per Minute (RPM) for
                                            the tachometer. Any readings above this level are ignored; however, you can use the
                                            Show Over Max RPMs toggle to view the readings if needed.</p>
                                    </UncontrolledTooltip>
                                    Maximum RPM Value:
                                </label>
                                <div className="">
                                    <input
                                        className={"form-control" + (validation.max_rpm.isValid || !validation.max_rpm.message ? "" : " is-invalid")}
                                        type="text"
                                        name="max_rpm"
                                        defaultValue={data.max_rpm}
                                        onChange={this.onChange}
                                        disabled={inProgress}
                                    />
                                    <ValidationError message={validation.max_rpm.message}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="text-right form-label">
                                    <span className="mr-2" id="setting-rpml-info"><i className="fa fa-info-circle"></i></span>
                                    <UncontrolledTooltip placement="top" target="setting-rpml-info">
                                        <p className="tooltip-text">The minimum number of RPM required to
                                            trigger a reading on tethered sensors that are connected to the same node as the
                                            tachometer.</p>
                                    </UncontrolledTooltip>
                                    RPM Trigger Threshold Low:
                                    <span className="color-danger">*</span>
                                </label>
                                <div className="">
                                    <input
                                        className={"form-control" + (validation.thresh_low.isValid || !validation.thresh_low.message ? "" : " is-invalid")}
                                        type="text"
                                        name="thresh_low"
                                        defaultValue={data.thresh_low}
                                        onChange={this.onChange}
                                        disabled={inProgress}
                                    />
                                    <ValidationError message={validation.thresh_low.message}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="text-right form-label">
                                    <span className="mr-2" id="setting-rpmh-info"><i className="fa fa-info-circle"></i></span>
                                    <UncontrolledTooltip placement="top" target="setting-rpmh-info">
                                        <p className="tooltip-text">The maximum number of RPM required
                                            to trigger a reading on tethered sensors that are connected to the same node as the
                                            tachometer.</p>
                                    </UncontrolledTooltip>
                                    RPM Trigger Threshold High:
                                    <span className="color-danger">*</span></label>
                                <div className="">
                                    <input
                                        className={"form-control" + (validation.thresh_high.isValid || !validation.thresh_high.message ? "" : " is-invalid")}
                                        type="text"
                                        name="thresh_high"
                                        defaultValue={data.thresh_high}
                                        onChange={this.onChange}
                                        disabled={inProgress}
                                    />
                                    <ValidationError message={validation.thresh_high.message}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="text-right form-label">
                                    <span className="mr-2" id="setting-pf-info"><i className="fa fa-info-circle"></i></span>
                                    <UncontrolledTooltip placement="top" target="setting-pf-info">
                                        <p className="tooltip-text">The time period of frequency that the tachometer should
                                            check for an RPM in the target range to trigger a reading on tethered sensors that are
                                            connected to the same node as the tachometer.</p>
                                    </UncontrolledTooltip>
                                    Poll Frequency (Seconds):
                                    <span className="color-danger">*</span>
                                </label>
                                <div className="">
                                    <input
                                        className={"form-control" + (validation.poll_freq.isValid || !validation.poll_freq.message ? "" : " is-invalid")}
                                        type="text"
                                        name="poll_freq"
                                        defaultValue={data.poll_freq}
                                        onChange={this.onChange}
                                        disabled={inProgress}
                                    />
                                    <ValidationError message={validation.poll_freq.message}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="text-right form-label">
                                    <span className="mr-2" id="setting-mp-info"><i className="fa fa-info-circle"></i></span>
                                    <UncontrolledTooltip placement="top" target="setting-mp-info">
                                        <p className="tooltip-text">The amount of time after an RPM trigger (on wired
                                            sensors than are connected to the same node as the tachometer) before the next
                                            RPM trigger can occur.</p>
                                    </UncontrolledTooltip>
                                    Minimum Period (Seconds):
                                    <span className="color-danger">*</span></label>
                                <div className="">
                                    <input
                                        className={"form-control" + (validation.min_period.isValid || !validation.min_period.message ? "" : " is-invalid")}
                                        type="text"
                                        name="min_period"
                                        defaultValue={data.min_period}
                                        onChange={this.onChange}
                                        disabled={inProgress}
                                    />
                                    <ValidationError message={validation.min_period.message}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-8" />
                        <div className="form-group col-md-4">
                            <div className="mt-2">
                                <button
                                    className="btn btn-sm btn-primary pull-right ml-2"
                                    onClick={this.handleSubmit}
                                    disabled={inProgress}
                                >
                                    Save
                                </button>
                                <button
                                    className="btn btn-sm btn-secondary pull-right"
                                    onClick={tachometerSpeedSettingsToggle}
                                    disabled={inProgress}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

    }
}

SettingsForm.propTypes = propTypes;

export default SettingsForm;