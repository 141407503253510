import React, {Component} from "react";
import PropTypes from "prop-types";
import Table from "./table";
import ApiNodes from "../../../api/node";
import {Loader, Pager} from "../../../shared";
import SearchInput from "../../shared/search-input";
import BatteryVoltageModal from "../../../modals/battery-voltage";
import Helper from "../../../helpers/helper";
import {find as _find} from "lodash";
import {Link} from "react-router-dom";
import {withGlobalStore} from "../../../stores/GlobalStore";
import SweetAlert from "react-bootstrap-sweetalert";
import LimitSelect from "../../shared/limit-select";
import {
    get as _get
} from "lodash";

import "../../../assets/scss/components/nodes/nodes.scss";
import {withLocationSelectStore} from "../../../stores/LocationSelectStore";
import GatewayBatchAssignModal, { NODE_DEVICE } from "../../../modals/gateway-batch-assign";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import { HeaderSimple } from "../../../shared/header";

const portsLabels = [
    {
        title: "SENSOR READINGS SEEN IN THE PAST 4 HOURS",
        class: "badge-success"
    },
    {
        title: "SENSOR READINGS SEEN OVER 4 HOURS AGO",
        class: "badge-danger"
    },
    {
        title: "Installation point not installed",
        class: "badge-secondary"
    },
    {
        title: "Tachometer",
        class: "badge-primary",
        permission: "editTachometers"
    },
];

class Index extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{name: "Manage Nodes"}],
            loader: true,
            list: [],
            pagination: {
                page: 1,
                pages: 1,
                perpage: Helper.getPerPage("nodes"),
                total: 0
            },
            sort: {
                field: "",
                sort: "asc"
            },
            filter: {
                query: "",
                locationId: props.getLocationId()
            },
            globalSearchString: "",
            deleteNodeSerial: null,
            showGatewayBatchAssignModal: false,
            inProgress: false
        };


        this.finished = false;

        this.handleFetch = this.handleFetch.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handlePagerChange = this.handlePagerChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
        this.onShowBatteryVoltageModal = this.onShowBatteryVoltageModal.bind(this);
        this.onDeleteNode = this.onDeleteNode.bind(this);
        this.deleteNode = this.deleteNode.bind(this);
    }

    onDeleteNode(serial) {
        this.setState({
            deleteNodeSerial: serial,
            inProgress: false
        });
    }

    deleteNode() {
        const {inProgress, deleteNodeSerial} = this.state;

        if (!inProgress) {
            this.setState({inProgress: true}, () => {
                ApiNodes.delete(deleteNodeSerial).then(() => {
                    this.onDeleteNode(null);
                    this.handleFetch();
                });
            });
        }
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    componentDidMount() {
        Promise.all([
            this.handleFetch(),
        ]);
    }

    handleFetch() {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        return ApiNodes.list({
            query: {
                sort: this.state.sort,
                filter: this.state.filter,
                pagination: this.state.pagination
            },
            signal: this.signal
        }).then(response => {
            if (response) {
                const {list = [], meta = {}} = response;
                this.setState({
                    loader: false,
                    inProgress: false,
                    pagination: Object.assign({}, this.state.pagination, meta),
                    list
                }, () => {
                    if (!this.finished) {
                        this.finished = true;
                    }
                });
            }
        });
    }

    handleSortChange(field = "") {
        const {sort} = this.state;

        this.setState({
            sort: Object.assign({}, {
                field,
                sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"
            }),
            loader: true
        }, this.handleFetch);
    }

    handlePagerChange(page = 1) {
        this.setState({pagination: Object.assign({}, this.state.pagination, {page}), loader: true}, this.handleFetch);
    }

    handleFilterChange(obj = {}) {
        this.finished = false;
        this.setState({
            loader: true,
            filter: Object.assign({}, this.state.filter, obj),
            pagination: Object.assign({}, this.state.pagination, {page: 1})
        }, this.handleFetch);
    }

    handleSearch = (value) => {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }

        this.handleFilterChange({query: value});
    };

    onShowBatteryVoltageModal(serial) {
        this.props.history.push(Helper.setHashParams({modal: "battery-voltage", serial}));
    }

    handleLimitChange = e => {
        const {value} = e.target;
        Helper.setPerPage("nodes", value);
        this.setState({
            loader: true,
            pagination: {
                ...this.state.pagination,
                page: 1,
                perpage: value
            }
        }, this.handleFetch);
    };

    onShowGatewayBatchAssignModal = () => {
        this.setState({showGatewayBatchAssignModal: true});
    }

    onCloseGatewayBatchAssignModal = () => {
        this.setState({showGatewayBatchAssignModal: false});
    }

    render() {
        const {
            breadcrumbs,
            loader,
            list,
            pagination,
            sort,
            filter,
            globalSearchString,
            deleteNodeSerial,
            showGatewayBatchAssignModal,
            inProgress
        } = this.state;
        const {auth} = this.props;
        const searchParams = Helper.getHashParams();

        return (
            <div>
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">Manage Nodes</div>
                    {auth.userCan("editNodes") &&
                        <div className="subheader-controls d-flex">
                            <div className="subheader-toolbar">
                                <Link
                                    to="/network/nodes/add"
                                    className="btn btn-primary btn-sm"
                                >
                                    <i className="fa fa-plus"/> Add Node
                                </Link>
                            </div>
                            <div className="subheader-toolbar ml-2">
                                <div
                                    className="btn btn-primary btn-sm"
                                    onClick={this.onShowGatewayBatchAssignModal}
                                >
                                    <i className="fa fa-plus"/> Batch Assign Nodes
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="block">
                    <div className="block-header">
                        <div className="block-label">
                            <PortsLabels auth={auth} />
                        </div>
                        <div id="top-pagination-block" className="block-pagination">
                            {pagination.total > 10 &&
                            <div className="limit-select-block">
                                <LimitSelect
                                    name={"perpage"}
                                    onChange={this.handleLimitChange}
                                    defaultValue={pagination.perpage}
                                    limits={[10, 20, 50, 100]}
                                />
                            </div>
                            }
                            {pagination.pages > 1 &&
                            <Pager
                                page={pagination.page}
                                pages={pagination.pages}
                                perPage={pagination.perpage}
                                onPagerChange={this.handlePagerChange}
                            />
                            }
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <div style={{minWidth: "144px"}}>
                                    <SearchInput
                                        disabled={false}
                                        placeholder="Search Nodes"
                                        query={filter.query}
                                        onChange={this.handleSearch}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"block-header response-header"}>
                        <div className="form-list form-list--row">
                            <div className="form-group form-group--inline">
                                <div className="form-label"><label>Asset Tree Branch:</label></div>
                                <CollapseLocationSelect
                                    style={{textTransform: "capitalize"}}
                                    className={"form-control form-control-sm"}
                                    selectName={"locationId"}
                                    value={+_get(filter, ["locationId"], 0)}
                                    onChange={(e) => {
                                        this.handleFilterChange({
                                            locationId: _get(e, "target.value")
                                        });
                                    }}
                                    emptyOptionLabel={"All"}
                                    checkStoreValue={true}
                                    withFavorite={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="block-body">
                        {loader
                            ?
                            <Loader/>
                            :
                            <div>
                                <Table
                                    list={list}
                                    sort={sort}
                                    query={filter.query}
                                    onShowBatteryVoltageModal={this.onShowBatteryVoltageModal}
                                    onSortChange={this.handleSortChange}
                                    onDeleteNode={this.onDeleteNode}
                                />
                            </div>
                        }
                    </div>
                </div>
                <button
                    disabled={list.length > 0 ? false : true}
                    title={list.length > 0 ? "" : "There is no data to export at this time."}
                    onClick={ () => {
                        this.setState({ loader: true });
                        ApiNodes.export({
                            query: {
                                sort: this.state.sort,
                                filter: this.state.filter
                            },
                            signal: this.signal
                        }).then(response => response.blob()).then( (blob) => {
                            this.setState({ loader: false });
                            const url = window.URL.createObjectURL(new Blob([blob]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", "nodes " + new Date().toLocaleString("en-US")+".csv");
                            link.setAttribute("id", "test-file");
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        } );
                    } }
                    className="btn v2-btn blue ml-3"
                    style={{width:100}}
                >
                    <img src={"/assets/pic/icon-download.svg"} alt=""/>
                    <span>Export</span>
                </button>
                {searchParams.modal === "battery-voltage" && !!searchParams.serial &&
                    <BatteryVoltageModal node={_find(list, {serial: searchParams.serial})}/>
                }
                {deleteNodeSerial &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        disabled={inProgress}
                        title="DELETE NODE"
                        onConfirm={this.deleteNode}
                        onCancel={() => this.onDeleteNode(null)}
                    >
                        Are you sure you want to delete this node?
                    </SweetAlert>
                }
                {!!showGatewayBatchAssignModal &&
                    <GatewayBatchAssignModal
                        showModal={true}
                        onClose={this.onCloseGatewayBatchAssignModal}
                        updateList={this.handleFetch}
                        type={NODE_DEVICE}
                    />
                }
            </div>
        );
    }
}

const PortsLabels = ({auth}) => (
    <div className="nodes-ports">
        {portsLabels.map((label, index) => {
            if (typeof(label.permission) == "undefined" || auth.userCan(label.permission)) {
                return (
                    <div
                        className="nodes-ports-item"
                        key={index}
                    >
                        <div
                            className={"nodes-ports-badge badge " + label.class}
                        />
                        <div
                            className="nodes-ports-label"
                        >- {label.title}</div>
                    </div>
                );
            }
        })}
    </div>
);

PortsLabels.propTypes = {
    auth: PropTypes.object
};


Index.propTypes = {
    history: PropTypes.object,
    auth: PropTypes.object,

    // LocationSelectStore
    getLocationId: PropTypes.func
};

export default withLocationSelectStore(withGlobalStore(Index));