import {create} from "zustand";
import {immer} from "zustand/middleware/immer";

const useInterpolationSyncStore = create(
    immer((set, get) => ({
            interpolations: {},
            actions: {
                toggleInterpolation: (pointID) => {
                    set( (state) => {
                        state.interpolations[+pointID] = !(get().interpolations[+pointID] ?? true);
                    });
                }
            },
        })
    )
);

export const useInterpolationSyncStoreByPoint = (pointID) => useInterpolationSyncStore((state) => state.interpolations[+pointID]) ?? true;

export const useInterpolationSyncStoreActions = () => useInterpolationSyncStore((state) => state.actions);
