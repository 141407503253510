import React from "react";
import PropTypes from "prop-types";
import Helper from "../../helpers/helper";
import {withGlobalStore} from "../../stores/GlobalStore";
import {get as _get, find as _find} from "lodash";
import {ResetSortButton} from "../shared/resetSortButton";
import EmailsPopover from "./emails-popover";
import {useCurrentAlertActions} from "../../stores/CurrentAlertStore";

const axisList = {
    x: 1,
    y: 2,
    z: 3,
};

const headersList = {
    alertLevelName: {title: "Level", sort: true},
    alertTriggeredDescription: {title: "Description", sort: true},
    axis: {title: "Axis", sort: false},
    installationPointName: {title: "Installed On", sort: true},
    timestamp: {title: "Created", sort: true},
    sent_history: {title: "Emails Sent", sort: false},
    actions: {title: "Actions", sort: false, additionalClasses: "table-buttons-th", component: ResetSortButton},
};

const TableActive = (props) => {

    const {list, sort, auth, user, onSortChange, handleAlarmCheckbox, selectedAlarms, selectedLevel, excluded} = props;
    const listLen = Object.keys(headersList).length;

    return (
        <table className="table table-hover tl-fixed">
            <thead>
                <tr>
                    {Object.keys(headersList).map((key) => {
                        let component = "";
                        const RowComponent = _get(headersList, [key, "component"], false);
                        if (RowComponent) {
                            component = (
                                <RowComponent
                                    sort={sort}
                                    resetSort={() => onSortChange("")}
                                />
                            );
                        }
                        return listLen && headersList[key].sort ? (
                            <th
                                className={headersList[key].additionalClasses || ""}
                                key={key}
                                style={headersList[key].style || {}}
                                onClick={() => onSortChange(key)}
                            >
                                <div className="cursor-pointer">
                                    {headersList[key].title}
                                    {sort.field === key ? (
                                        <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")} />
                                    ) : (
                                        <i className="fa fa-sort" />
                                    )}
                                </div>
                            </th>
                        ) : (
                            (key !== "actions" || auth.userCan("editAlarms")) && (
                                <th
                                    className={headersList[key].additionalClasses || ""}
                                    key={key}
                                    style={headersList[key].style || {}}
                                >
                                    {headersList[key].title} {component}
                                </th>
                            )
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {list.length > 0 ? (
                    list.map((alarm) => (
                        <AlarmRow
                            excluded={excluded}
                            selectedLevel={selectedLevel}
                            selectedAlarms={selectedAlarms}
                            handleAlarmCheckbox={handleAlarmCheckbox}
                            key={alarm.id}
                            auth={auth}
                            user={user}
                            alarm={alarm}
                            props={props}
                        />
                    ))
                ) : (
                    <tr>
                        <td
                            colSpan={listLen}
                            className="text-center text-info"
                        >
                            No alerts exist.
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

TableActive.propTypes = {
    list: PropTypes.array,
    selectedAlarms: PropTypes.array,
    excluded: PropTypes.array,
    auth: PropTypes.object,
    user: PropTypes.object,
    sort: PropTypes.object,
    axisLabels: PropTypes.object,
    onSortChange: PropTypes.func,
    handleAlarmCheckbox: PropTypes.func,
    selectedLevel: PropTypes.any,
};

const AlarmRow = ({alarm, auth, user, props, handleAlarmCheckbox, selectedAlarms = [], selectedLevel, excluded = []}) => {
    const {alertCondition = {}} = alarm;
    const {alertLevel = {}, installationPoint = {}} = alertCondition;
    const axisLabel = _get(props.axisLabels, [axisList[alarm.axis.toLowerCase()], alarm.installationPointId]);
    const {setCurrentAlert} = useCurrentAlertActions();
    let blocked = () => {
        return selectedAlarms.length > 0 || selectedLevel;
    };

    const emailLogWarning = _find(_get(alarm, "emailHistory", []), (item) => item.status > 1);

    return (
        <tr>
            <td>
                {auth.userCan("editAlarms") && (
                    <label className="form-checkbox">
                        <input
                            checked={selectedAlarms.indexOf(+alarm.id) !== -1 || (selectedLevel === "full-all" && excluded.indexOf(+alarm.id) === -1)}
                            type="checkbox"
                            onChange={handleAlarmCheckbox}
                            value={alarm.id}
                        />
                        <span />
                    </label>
                )}
                <div
                    className="badge badge-pill"
                    style={{background: alertLevel.color, color: "#fff"}}
                >
                    {alertLevel.name}
                </div>
            </td>
            <td>{alarm.description}</td>
            <td>{axisLabel || alarm.axis.toUpperCase()}</td>
            <td>{installationPoint.name}</td>
            <td>{Helper.dateToUserFormat(alarm.timestamp, user)}</td>
            <td className={`alarm-line alarm-line-${alarm.id}` + (typeof emailLogWarning !== "undefined" ? " text-danger" : "")}>
                <span
                    className={"cursor-pointer emails-popover"}
                    id={`popover-${alarm.id}`}
                >
                    <span className="fa-layers fa-fw">
                        &nbsp;
                        {/* <img className={"table-image-icon"} src={"/assets/pic/mail.svg"}/>&nbsp; */}
                        <MailIcon />
                        <span className="fa-layers-counter">{_get(alarm, "emailHistory", []).length}</span>
                    </span>
                    {_get(alarm, "emailHistory", []).length > 0 && (
                        <EmailsPopover
                            popoverProps={{
                                target: `popover-${alarm.id}`,
                                container: `.alarm-line-${alarm.id}`,
                            }}
                            list={_get(alarm, "emailHistory", [])}
                        />
                    )}
                </span>
            </td>
            <td>
                <div className="btn-group btn-group-sm">
                    {auth.userCan("editAlarms") && !blocked() && (
                        <React.Fragment>
                            <button
                                className="link alarm-btn link-success"
                                onClick={() => props.onOpenAcknowledgeAlarmModal(alarm.id)}
                            >
                                <i className="fa fa-check" />
                                <span>Acknowledge</span>
                            </button>
                            <button
                                className="link alarm-btn link-warning"
                                onClick={() => props.onOpenIgnoreAlarmModal(alarm.id)}
                            >
                                <i className="fa fa-times" />
                                <span>Ignore</span>
                            </button>
                        </React.Fragment>
                    )}
                    <button
                        className="link alarm-btn link-primary"
                        onClick={() => {
                            setCurrentAlert(alarm);
                            props.onClose();
                        }}
                    >
                        <i className="fa fa-eye" />
                        <span>View</span>
                    </button>
                </div>
            </td>
        </tr>
    );
};

const MailIcon = () => {
    return (
        <span className="table-image-icon mail-icon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 384"
            >
                <path d="M394.67,149.33a117.33,117.33,0,1,0,58.68,219,10.66,10.66,0,1,0-10.69-18.46,96,96,0,1,1,48-83.17v10.67a21.33,21.33,0,1,1-42.67,0V234.67A10.63,10.63,0,0,0,430,227a53.32,53.32,0,1,0,4.51,74.8A42.6,42.6,0,0,0,512,277.33V266.67A117.47,117.47,0,0,0,394.67,149.33Zm0,149.33a32,32,0,1,1,32-32A32,32,0,0,1,394.67,298.67Z" />
                <path d="M17.7,24.69l175.72,143a10.1,10.1,0,0,0,1.89,1.23,16.07,16.07,0,0,0,7.39,1.8c2.71,0,5.42-.71,9.38-3.1L387.65,24.7a10.66,10.66,0,0,0-.64-17A42.38,42.38,0,0,0,362.67,0h-320A42.34,42.34,0,0,0,18.33,7.67,10.66,10.66,0,0,0,17.7,24.69Z" />
                <path d="M256,266.67C256,190.21,318.21,128,394.67,128a10.66,10.66,0,0,0,10.67-10.67V60.24a10.67,10.67,0,0,0-6.08-9.64A10.78,10.78,0,0,0,387.94,52L224.28,185.15l-1.87,1.24c-12.3,7.55-27.42,7.94-41.3-1.23L17.4,52A10.66,10.66,0,0,0,0,60.23V256a42.71,42.71,0,0,0,42.67,42.67H247a10.66,10.66,0,0,0,10.56-12.18A138.44,138.44,0,0,1,256,266.67Z" />
            </svg>
        </span>
    );
};

AlarmRow.propTypes = {
    alarm: PropTypes.object,
    auth: PropTypes.object,
    user: PropTypes.object,
    axisLabels: PropTypes.object,
    props: PropTypes.object,
    onOpenAcknowledgeAlarmModal: PropTypes.func,
    onOpenIgnoreAlarmModal: PropTypes.func,
    onOpenViewAlarmModal: PropTypes.func,
    onClose: PropTypes.func,
    handleAlarmCheckbox: PropTypes.func,
    selectedAlarms: PropTypes.array,
    excluded: PropTypes.array,
    selectedLevel: PropTypes.any,
};

export default withGlobalStore(TableActive);
