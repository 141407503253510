import React, {Component} from "react";
import SearchInput from "../../shared/search-input";
import PropTypes from "prop-types";
import {withLocationSelectStore} from "../../../stores/LocationSelectStore";
import {withGlobalStore} from "../../../stores/GlobalStore";
import LimitSelect from "../../shared/limit-select";
import {Loader, Pager} from "../../../shared";
import Table from "./table";
import ParametersModal from "./parameterTypes/modal";

import "../../../assets/scss/components/wua/wua.scss";
import WuaSensorApi from "../../../api/wuaSensor";
import SweetAlert from "react-bootstrap-sweetalert";
import Toast from "../../shared/toast";
import Helper from "../../../helpers/helper";
import { HeaderSimple } from "../../../shared/header";
import WuaConfigModal from "./wuaConfigModal";
import {Link} from "react-router-dom";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import {get as _get} from "lodash";

class Index extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{name: "Manage Universal Adapter Configurations"}],
            loader: true,
            list: [],
            pagination: {
                page: 1,
                pages: 1,
                perpage: Helper.getPerPage("wua"),
                total: 0
            },
            sort: {
                field: "",
                sort: "asc"
            },
            filter: {
                query: "",
                locationId: props.getLocationId()
            },
            globalSearchString: "",
            showManageParameterTypesModal: false,
            deleteId: null,
            showAddNew: false,
            onEdit: false
        };
    }

    componentDidMount() {
        this.handleFetch();
    }

    handleFetch = () => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        return WuaSensorApi.getList({
            query: {
                sort: this.state.sort,
                filter: this.state.filter,
                pagination: this.state.pagination
            },
            signal: this.signal
        }).then(response => {
            if (response) {
                const {list = [], meta = {}} = response;
                this.setState({
                    loader: false,
                    pagination: Object.assign({}, this.state.pagination, meta),
                    list
                }, () => {
                    if (!this.finished) {
                        this.finished = true;
                    }
                });
            }
        });
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    handleSortChange = (field = "") => {
        const {sort} = this.state;

        this.setState({
            sort: Object.assign({}, {
                field,
                sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"
            }),
            loader: true
        }, this.handleFetch);
    }

    handlePagerChange = (page = 1) => {
        this.setState({loader: true, pagination: Object.assign({}, this.state.pagination, {page})}, this.handleFetch);
    }

    handleSearch = (value) => {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }

        this.handleFilterChange({query: value});
    };

    handleLimitChange = e => {
        const {value} = e.target;
        Helper.setPerPage("wua", value);
        this.setState({
            loader: true,
            pagination: {
                ...this.state.pagination,
                page: 1,
                perpage: value
            }
        }, this.handleFetch);
    };

    handleFilterChange = (obj = {}) => {
        this.finished = false;
        this.setState({
            loader: true,
            filter: Object.assign({}, this.state.filter, obj),
            pagination: Object.assign({}, this.state.pagination, {page: 1})
        }, this.handleFetch);
    }

    onShowManageParameterTypesModal = () => {
        this.setState({showManageParameterTypesModal: true});
    }

    onCloseManageParameterTypesModal = () => {
        this.setState({showManageParameterTypesModal: false});
    }

    onDelete = (id) => {
        this.setState({deleteId: id});
    }

    delete = () => {
        const {deleteId} = this.state;

        WuaSensorApi
            .delete(deleteId)
            .then(() => {
                this.handleFetch()
                    .then(() => {
                        this.setState({deleteId: null}, () => Toast.success("The universal adapter configuration has been deleted."));
                    });
            });
    }

    addNew = () => {
        this.setState({showAddNew: true});
    }

    setOnEdit = (item) => {
        this.setState({onEdit: item});
    }

    render() {
        const {
            breadcrumbs,
            loader,
            list,
            pagination,
            sort,
            filter,
            globalSearchString,
            showManageParameterTypesModal,
            deleteId,
            showAddNew,
            onEdit
        } = this.state;
        const {auth} = this.props;

        return (
            <>
                <div>
                    <HeaderSimple
                        breadcrumbs={ breadcrumbs }
                        globalSearchString={ globalSearchString }
                        handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                    />
                    <div className="subheader">
                        <div className="subheader-title">Manage Universal Adapter Configurations</div>
                        <div className="subheader-controls d-flex">
                            {auth.userCan("editEquipment") &&
                                <>
                                    <div className="subheader-toolbar">
                                        <Link
                                            to="/network/ua-configurations/add"
                                            className="btn btn-primary btn-sm"
                                        >
                                            <i className="fa fa-plus"/> Add Universal Adapter Configuration
                                        </Link>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="block">
                        <div className="block-header">
                            <div className="block-label">
                                <div className="form-list form-list--row">
                                    <div className="form-group form-group--inline">
                                        <React.Fragment>
                                            <div className="form-label"><label>Asset Tree Branch:</label></div>
                                            <CollapseLocationSelect
                                                className={"form-control form-control-sm"}
                                                selectName={"locationId"}
                                                value={+_get(filter, ["locationId"], 0)}
                                                onChange={(e) => {
                                                    this.handleFilterChange({
                                                        locationId: _get(e, "target.value")
                                                    });
                                                }}
                                                emptyOptionLabel={"All"}
                                                checkStoreValue={true}
                                                withFavorite={true}
                                            />
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                            <div id="top-pagination-block" className="block-pagination">
                                {pagination.total > 10 &&
                                    <div className="limit-select-block">
                                        <LimitSelect
                                            name={"perpage"}
                                            onChange={this.handleLimitChange}
                                            defaultValue={pagination.perpage}
                                            limits={[10, 20, 50, 100]}
                                        />
                                    </div>
                                }
                                {pagination.pages > 1 &&
                                    <Pager
                                        page={pagination.page}
                                        pages={pagination.pages}
                                        perPage={pagination.perpage}
                                        onPagerChange={this.handlePagerChange}
                                    />
                                }
                            </div>
                            <div className="block-toolbar">
                                <div className="block-toolbar-wrapper">
                                    <div style={{minWidth: "210px"}}>
                                        <SearchInput
                                            placeholder="Search Universal Adapters"
                                            query={filter.query}
                                            onChange={this.handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block-body">
                            {loader
                                ?
                                <Loader/>
                                :
                                <div>
                                    <Table
                                        list={list}
                                        sort={sort}
                                        query={filter.query}
                                        onSortChange={this.handleSortChange}
                                        onDelete={this.onDelete}
                                        setOnEdit={this.setOnEdit}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <button
                        disabled={list.length > 0 ? false : true}
                        title={list.length > 0 ? "" : "There is no data to export at this time."}
                        onClick={ () => {
                            this.setState({ loader: true });
                            WuaSensorApi.export({
                                query: {
                                    sort: this.state.sort,
                                    filter: this.state.filter
                                },
                                signal: this.signal
                            }).then(response => response.blob()).then( (blob) => {
                                this.setState({ loader: false });
                                const url = window.URL.createObjectURL(new Blob([blob]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", "Universal Adapters " + new Date().toLocaleString("en-US")+".csv");
                                link.setAttribute("id", "test-file");
                                document.body.appendChild(link);
                                link.click();
                                link.parentNode.removeChild(link);
                            } );
                        } }
                        className="btn v2-btn blue ml-3"
                        style={{width:100}}
                    >
                        <img src={"/assets/pic/icon-download.svg"} alt=""/>
                        <span>Export</span>
                    </button>
                    {showManageParameterTypesModal && <ParametersModal onClose={this.onCloseManageParameterTypesModal} />}
                    {deleteId &&
                        <SweetAlert
                            warning
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnBsStyle="default"
                            btnSize="xs"
                            title="DELETE UNIVERSAL ADAPTER CONFIGURATION"
                            onConfirm={this.delete}
                            onCancel={() => this.onDelete(null)}
                        >
                            Are you sure you want to delete this universal adapter configuration?
                        </SweetAlert>
                    }
                    {showAddNew &&
                        <WuaConfigModal
                            onClose={() => this.setState({showAddNew: false})}
                            updateWuaSensorList={this.handleFetch}
                        />
                    }
                    {onEdit &&
                        <WuaConfigModal
                            updateConfig={onEdit}
                            onClose={() => this.setState({onEdit: false})}
                            updateWuaSensorList={this.handleFetch}
                        />
                    }

                </div>
            </>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item global-search">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

Index.propTypes = {
    history: PropTypes.object,
    auth: PropTypes.object,

    // LocationSelectStore
    getLocationId: PropTypes.func
};

export default withLocationSelectStore(withGlobalStore(Index));