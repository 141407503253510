import {useMutation} from "@tanstack/react-query";
import {SIMPLE_CHART_READING_API} from "./constants";

import Reading from "../../../api/reading";

export const simpleChartDeleteReadingsKey = () => [SIMPLE_CHART_READING_API.DELETE_READING_DATA];
export const simpleChartDeleteReadingsFn = (data) => Reading.deleteReading(data);

export const useSimpleChartDeleteReadingsMutation = () => {
    return useMutation({
        mutationKey: simpleChartDeleteReadingsKey,
        mutationFn: simpleChartDeleteReadingsFn,
    });
};
