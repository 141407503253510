import Api from "./api";

const UniversalAdapterApi = {
    getList: params => Api.get("universal-adapter", {...params}),
    createSingle: (data) => Api.post("universal-adapter-single" , data),
    createMultiple: (data) => Api.post("universal-adapter-multiple" , data),
    updateSingle: (id, data) => Api.patch(`universal-adapter-single/${id}`, data),
    updateMultiple: (id, data) => Api.patch(`universal-adapter-multiple/${id}`, data),
    delete: id => Api.delete(`universal-adapter/${id}`),
};

export default UniversalAdapterApi;