import {useQuery} from "@tanstack/react-query";
import ApiChartMark from "../../../api/chartMark";
import {CHART_MARK_API} from "./constants";

const useChartMarksAlertChangeQuery = (equipmentId) => {
    const {
        isSuccess,
        data: chartMarksAlertChange,
        ...rest
    } = useQuery(
        [CHART_MARK_API.GET_ALERT_CHANGE_CHART_MARK, +equipmentId],
        () => ApiChartMark.getAlertChange(+equipmentId),
        {
            enabled: !!equipmentId,
        }
    );

    return {
        chartMarksAlertChange: chartMarksAlertChange?.list,
        isSuccess,
        ...rest,
    };
};

export default useChartMarksAlertChangeQuery;
