import Api from "./api";

const Customer = {
    updateCustomerLogo: (body) => {
        return Api.post("customer/update-header-image", body, {credentials: "include", host: process.env.SSO_URL});
    },
    removeCustomerLogo: (body) => {
        return Api.delete("customer/delete-header-image", body, {credentials: "include", host: process.env.SSO_URL});
    }
};

export default Customer;