import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, ValidationError} from "../../../shared";
import FormValidator from "../../../helpers/form-validator";
import Helper from "../../../helpers/helper";
import { DATEFORMAT, FREQUENCY_TYPES, SERVER_DATETIME_FORMAT } from "../../../constants/constants";
import {get as _get, find as _find} from "lodash";
import moment from "moment";
import {PureSensorSelect} from "../../../pages/shared/sensor-select";
import Toast from "../../../pages/shared/toast";

const rules = [
    {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "frequency",
        method: "isEmpty",
        validWhen: false,
        message: "Frequency can't be empty.",
    },
    {
        field: "frequency",
        method: "isFloat",
        validWhen: true,
        message: "Frequency should be valid number.",
    },
];

class ChartMarkFftModal extends Component {
    BIND_TYPE = {
        EQUIPMENT: 1,
        INSTALLATION_POINT: 2,
    };
    TIMESTAMP_BIND_TYPE = {
        CURRENT_READING: 1,
        ALL_READINGS: 2,
    };

    constructor(props) {
        super(props);
        this.validator = new FormValidator(rules);

        this.dateFormat = _get(this.props.user, "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0]);
        this.isEdit = !!this.props.chartMarkFftId;

        if (this.isEdit) {
            this.chartMarkFft = _find(this.props.chartMarksFft, ["id", this.props.chartMarkFftId]);
        }

        this.state = {
            validation: this.validator.valid(),
            inProgress: false,
            bindType: this.isEdit && this.chartMarkFft.installation_point_id ? this.BIND_TYPE.INSTALLATION_POINT : this.BIND_TYPE.EQUIPMENT,
            timestampBindType:
                this.isEdit && this.chartMarkFft.timestamp
                    ? this.TIMESTAMP_BIND_TYPE.CURRENT_READING
                    : this.TIMESTAMP_BIND_TYPE.ALL_READINGS,
            data: {
                equipmentId: this.props.equipment.id,
                name: this.isEdit ? this.chartMarkFft.name : "",
                notes: this.isEdit ? this.chartMarkFft.notes : "",
                timestamp: +moment
                    .utc(this.isEdit && this.chartMarkFft.timestamp ? this.chartMarkFft.timestamp : this.props.currentDate)
                    .format("x"),
                frequency: this.isEdit ? this.chartMarkFft.frequency : this.props.frequency,
            },
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeBindType = this.onChangeBindType.bind(this);
    }

    onChange(event) {
        const data = {...this.state.data};

        data[event.target.name] = Helper.getInputValue(event.target);

        this.setState({data});
    }

    onSubmit() {
        this.setState({inProgress: true}, () => {
            const validation = this.validator.validate(this.state.data);

            this.setState({validation}, () => {
                if (validation.isValid) {
                    if (this.isEdit) {
                        this.editChartMark(this.getData()).catch(() => this.setState({inProgress: false}));
                    } else {
                        this.createChartMark(this.getData()).catch(() => this.setState({inProgress: false}));
                    }
                } else {
                    this.setState({inProgress: false});
                }
            });
        });
    }

    createChartMark(data) {
        return this.props.onCreate(data).then(() => {
            this.props.onClose();
            Toast.success("Chart mark has been added");
        });
    }

    editChartMark(data) {
        return this.props.onUpdate(data).then(() => {
            this.props.onClose();
            Toast.success("Chart mark has been updated");
        });
    }

    onChangeBindType(ev) {
        this.setState({bindType: +ev.target.value});
    }

    onChangeTimestampBindType(ev) {
        this.setState({timestampBindType: +ev.target.value});
    }

    getData() {
        return {
            name: this.state.data.name,
            notes: this.state.data.notes,
            timestamp:
                this.state.timestampBindType === this.TIMESTAMP_BIND_TYPE.CURRENT_READING
                    ? moment(this.state.data.timestamp).utc().format(SERVER_DATETIME_FORMAT)
                    : null,
            frequency: this.state.data.frequency,
            equipmentId: this.state.data.equipmentId,
            installationPointId: this.state.bindType === this.BIND_TYPE.INSTALLATION_POINT ? this.props.selectedPoint : null,
        };
    }

    render() {
        const {inProgress, validation} = this.state;
        const colWidth =
            12 /
            (2 +
                (this.state.bindType === this.BIND_TYPE.INSTALLATION_POINT) +
                (this.state.timestampBindType === this.TIMESTAMP_BIND_TYPE.CURRENT_READING));

        return (
            <Modal
                {...this.props}
                size="lg"
                title={"Mark selected frequency"}
                className={"custom-modal"}
                onSubmit={this.onSubmit}
                inProgress={this.state.inProgress}
            >
                <div className="row">
                    <div className="form-group col-6 mb-2">
                        <label>Asset Code:</label>
                        <p className="mb-2">{this.props.equipment.asset_code}</p>
                    </div>
                    <div className="form-group col-6 mb-2">
                        <label>Equipment:</label>
                        <p className="mb-2">{this.props.equipment.name}</p>
                    </div>
                </div>
                <hr />
                <React.Fragment>
                    <div className="row">
                        {this.state.bindType === this.BIND_TYPE.INSTALLATION_POINT && (
                            <div className={`form-group col-${colWidth}`}>
                                <label className="form-label">Sensor</label>
                                <PureSensorSelect
                                    name="installationPointId"
                                    value={this.props.selectedPoint}
                                    disabled={true}
                                    equipment={this.props.equipment}
                                />
                            </div>
                        )}
                        {this.state.timestampBindType === this.TIMESTAMP_BIND_TYPE.CURRENT_READING && (
                            <div className={`form-group mb-1 col-${colWidth}`}>
                                <label className="form-label">Timestamp</label>
                                <input
                                    type="text"
                                    className={"form-control"}
                                    value={Helper.dateToFormat(this.state.data.timestamp, this.dateFormat)}
                                    disabled={true}
                                />
                            </div>
                        )}
                        <div className={`form-group mb-1 col-${colWidth}`}>
                            <label className="form-label">Frequency, {FREQUENCY_TYPES.HZ}</label>
                            <input
                                type="number"
                                className={`form-control ${
                                    validation.frequency.isValid || !validation.frequency.message ? "" : "is-invalid"
                                }`}
                                name="frequency"
                                placeholder="Enter Frequency"
                                disabled={inProgress}
                                onChange={this.onChange}
                                value={this.state.data.frequency}
                            />
                            <ValidationError message={validation.frequency.message} />
                        </div>
                        <div className={`form-group mb-1 col-${colWidth}`}>
                            <label className="form-label">
                                Name <span className="color-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className={`form-control ${validation.name.isValid || !validation.name.message ? "" : " is-invalid"}`}
                                name="name"
                                disabled={inProgress}
                                onChange={this.onChange}
                                value={this.state.data.name}
                            />
                            <ValidationError message={validation.name.message} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-12">
                            <label className="form-label">Notes</label>
                            <textarea
                                className={"form-control"}
                                style={{height: "100px"}}
                                name="notes"
                                disabled={inProgress}
                                onChange={this.onChange}
                                value={this.state.data.notes}
                            />
                        </div>
                    </div>
                    <div className="row form-group">
                        <label className="col-2">Set For:</label>
                        <div className="axis-block-container col-10">
                            <div
                                className={`axis-block ${this.state.bindType === this.BIND_TYPE.INSTALLATION_POINT && "active"}`}
                                onClick={() =>
                                    this.onChangeBindType({
                                        target: {
                                            name: "bindType",
                                            value: this.BIND_TYPE.INSTALLATION_POINT,
                                        },
                                    })
                                }
                            >
                                <span>Installation Point Only</span>
                            </div>
                            <div
                                className={`axis-block ${this.state.bindType === this.BIND_TYPE.EQUIPMENT && "active"}`}
                                onClick={() =>
                                    this.onChangeBindType({
                                        target: {
                                            name: "bindType",
                                            value: this.BIND_TYPE.EQUIPMENT,
                                        },
                                    })
                                }
                            >
                                <span>For This Equipment</span>
                            </div>
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="axis-block-container col-10 offset-2">
                            <div
                                className={`axis-block ${
                                    this.state.timestampBindType === this.TIMESTAMP_BIND_TYPE.CURRENT_READING && "active"
                                }`}
                                onClick={() =>
                                    this.onChangeTimestampBindType({
                                        target: {
                                            name: "bindType",
                                            value: this.TIMESTAMP_BIND_TYPE.CURRENT_READING,
                                        },
                                    })
                                }
                            >
                                <span>Current Reading Only</span>
                            </div>
                            <div
                                className={`axis-block ${
                                    this.state.timestampBindType === this.TIMESTAMP_BIND_TYPE.ALL_READINGS && "active"
                                }`}
                                onClick={() =>
                                    this.onChangeTimestampBindType({
                                        target: {
                                            name: "bindType",
                                            value: this.TIMESTAMP_BIND_TYPE.ALL_READINGS,
                                        },
                                    })
                                }
                            >
                                <span>For All Readings</span>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </Modal>
        );
    }
}

ChartMarkFftModal.propTypes = {
    equipment: PropTypes.object,
    selectedPoint: PropTypes.number,
    currentDate: PropTypes.string,
    frequency: PropTypes.number,
    chartMarkFftId: PropTypes.number,
    chartMarksFft: PropTypes.array,
    user: PropTypes.object,
    history: PropTypes.object,
    addChartMarkFft: PropTypes.func,
    updateChartMarkFft: PropTypes.func,
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
};

export default ChartMarkFftModal;
