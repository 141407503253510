import React, {useState} from "react";
import PropTypes from "prop-types";
import NameField from "./parts/name";
import {get as _get, omit as _omit, setWith as _setWith} from "lodash";
import InstallationPointCustomTypeField from "./parts/type";
import SensorField from "./parts/sensor";
import InstallationPointFormData from "./InstallationPointFormData";
import {useCreateInstallationPointMutation} from "../../../hooks/api/installationPoints/useCreateInstallationPointMutation";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import Toast from "../../../pages/shared/toast";
import {useEquipmentInvalidate} from "../../../hooks/api/equipment/useEquipmentInvalidate";

export const CreateInstallationPointForm = ({onCancel, onClose, isRouteCollector}) => {
    const {equipmentItem: equipment, isRefetching} = useEquipmentByParams();
    const invalidateEquipment = useEquipmentInvalidate(equipment.id);

    const [state, setState] = useState({
        installationPoint: InstallationPointFormData.getInstallationPointObject(),
        formErrors: {},
    });

    const {mutateAsync: createInstallationPointMutate, isLoading: inProgress} = useCreateInstallationPointMutation();

    const removeError = (key) => {
        const oldFormErrors = {...state.formErrors};

        const formErrors = _omit(oldFormErrors, key);

        setState((prev) => ({...prev, formErrors}));
    };

    const onChange = (event) => {
        const data = {...state.installationPoint};

        const key = _get(event, "target.name", "");
        const val = _get(event, "target.value", "");

        _setWith(data, key, val, Object);
        removeError(key);
        setState((prev) => ({...prev, installationPoint: data}));
    };

    const onCreate = () => {
        const {installationPoint} = state;

        const isInstallationPointHasSameName = equipment.installationPoints.some((item) => item.name === installationPoint.name);

        if (isInstallationPointHasSameName) {
            Toast.error("The installation point with this name already exists.");
            return;
        }

        createInstallationPointMutate({equipmentId: equipment.id, installationPoint})
            .then((response) => {
                if (response.status !== "ok") {
                    return;
                }

                return invalidateEquipment();
            })
            .then(() => {
                Toast.success("The installation point has been added.");
                onClose();
            })
            .catch((response) => {
                setState((prev) => ({...prev, formErrors: response.errors || {}}));
            });
    };

    const colSizeClass = isRouteCollector ? " col-md-6" : " col-md-4";

    return (
        <>
            <div className={"row position-relative installation-point-form"}>
                <div className={"form-group" + colSizeClass}>
                    <NameField
                        value={_get(state.installationPoint, "name")}
                        onChange={onChange}
                        formErrors={state.formErrors}
                    />
                </div>
                <div className={"form-group" + colSizeClass}>
                    <InstallationPointCustomTypeField
                        value={_get(state.installationPoint, "installation_point_custom_type_id")}
                        onChange={onChange}
                    />
                </div>
                {!isRouteCollector && (
                    <div className={"form-group" + colSizeClass}>
                        <SensorField
                            sensor={_get(state.installationPoint, "sensor", {})}
                            formErrors={state.formErrors}
                            onChange={onChange}
                        />
                    </div>
                )}
            </div>
            <div className="row">
                <div className={"form-group col-md-8"} />
                <div className={"form-group col-md-4"}>
                    <div className="mt-3">
                        <button
                            className="btn btn-sm btn-primary pull-right ml-2"
                            onClick={onCreate}
                            disabled={inProgress || isRefetching}
                        >
                            {inProgress || isRefetching ? (
                                <span>
                                    <i className="fa fa-spinner" /> Processing
                                </span>
                            ) : (
                                "Save"
                            )}
                        </button>
                        <button
                            className="btn btn-sm btn-secondary pull-right"
                            onClick={onCancel}
                            disabled={inProgress || isRefetching}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            <hr />
        </>
    );
};

CreateInstallationPointForm.propTypes = {
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    installationPoint: PropTypes.object,
    formErrors: PropTypes.object,
    inProgress: PropTypes.bool,
    onChange: PropTypes.func,
    onCreate: PropTypes.func,
    isRouteCollector: PropTypes.bool,
};
