import React from "react";
import "./chartHeader.scss";
import WaterfallHeader from "./components/WaterfallHeader";
import TwfFftHeader from "./components/TwfFftHeader";
import { useChartStateStoreContext } from "../../../../store/hooks/useStoreContext";
import { shallow } from "zustand/shallow";

function ChartHeader() {
    const { chartModeList } = useChartStateStoreContext((state) => state.factory, shallow);

    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);

    if (chartMode === chartModeList.WATERFALL || chartMode === chartModeList.STACKED_WATERFALL) {
        return <WaterfallHeader />;
    }
    return <TwfFftHeader />;
}

export default ChartHeader;
