import {
    useCurrentAlertActions,
    useCurrentAlert,
    useCurrentAlertScrolled
} from "../../../../../stores/CurrentAlertStore";
import { useCallback, useEffect } from "react";
import { useChartStateStoreContext } from "../store/hooks/useStoreContext";
import { findKey as _findKey, get as _get, includes as _includes } from "lodash";
import { shallow } from "zustand/shallow";
import { useChartTypesStore } from "../../../../../stores/zustand/ChartSelectedChartTypeStore";

export const useAfterAlertScrollEffect = () => {
    const {setIsScrolled} = useCurrentAlertActions();
    const currentAlert = useCurrentAlert();
    const isScrolled = useCurrentAlertScrolled();
    const chartTypes = useChartTypesStore();
    const {changeSelectedSeriesList} = useChartStateStoreContext((state) => state.actions);
    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList, shallow);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);

    const getChartTypeByReading = (readingId) => {
        let chartType = false;

        (Object.keys(chartTypes) || []).map((chartIndex) => {
            if (_includes(chartTypes[chartIndex]["readingTypes"], "" + readingId)) {
                chartType = chartIndex;
            }
        });
        return chartType;
    };


    const scrollToTarget = useCallback(() => {
        const chartTypeAlert = getChartTypeByReading(+_get(currentAlert, ["alertCondition", "reading_type_id"]));
        const isFFT = +_get(currentAlert, "alertCondition.fft_alert_type", 0) === 1
            || +_get(currentAlert, "alertCondition.fft_alert_type", 0) === 2;

        if (+chartType === +chartTypeAlert && !isFFT && !isScrolled) {
            const axisByReadingType = _findKey(seriesList.list, (axisData) => +axisData.readingType === +currentAlert.readingType_id);
            if (axisByReadingType) {
                changeSelectedSeriesList([axisByReadingType]);
            }
            setIsScrolled();
        }
    }, [currentAlert]);

    useEffect(() => {
        scrollToTarget();
    }, [scrollToTarget]);
};