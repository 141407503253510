import React, {Fragment, useState} from "react";

import swal from "../../pages/shared/swal";
import ApiEquipment from "../../api/equipment";
import DropzoneWrapper from "../../shared/equipment/components/dropzoneWrapper";
import AssignPointsModal from "../../modals/equipment/assign-points";
import LocationsAside from "../../shared/aside/aside";
import {Link} from "react-router-dom";
import auth from "../../services/auth";

import {concat as _concat, filter as _filter, get as _get, unset as _unset, setWith as _setWith, omit as _omit, cloneDeep as _cloneDeep} from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import {IDLE_THRESHOLD_TYPES, EQUIPMENT_SERVICE} from "../../constants/constants";
import Helper from "../../helpers/helper";
import EquipmentTypeSelector from "../../shared/form/EquipmentTypeSelector";
import InstallationPointForm from "../../modules/installationPoint/forms/InstallationPointForm";
import InstallationPointFormData from "../../modules/installationPoint/forms/InstallationPointFormData";
import InstallationPointCustomTypeStore from "../../modules/installationPointCustomType/stores/InstallationPointCustomTypeStore";
import SelectWrapper from "../../helpers/select-wrapper";
import CollapseLocationSelect from "../../shared/collapseLocationSelect/collapseLocationSelect";
import {useHistory} from "react-router";
import useReadingsType from "../../hooks/api/Global/useReadingsType";
import useUserProfile, {profileCustomerSelector} from "../../hooks/api/Global/useUserProfile";
import {CheckboxFormGroup} from "../../shared/formParts/formParts";
import {Loader} from "../../shared";

const initialForm = {
    equipment: {
        name: "",
        locationId: "",
        assetCode: "",
        equipmentTypeId: "",
        idle_threshold: null,
        idle_threshold_type: null,
        is_route_collector: false,
        service: EQUIPMENT_SERVICE.SELF,
    },
    images: [],
    points: [InstallationPointFormData.getInstallationPointObject()],
    positions: {},
    flipTurns: {},
    imageInstallationPoint: [],
};

const CreateEquipment = () => {
    const {isLoading} = useUserProfile(profileCustomerSelector);
    const [form, setForm] = useState(() => _cloneDeep(initialForm));

    const [errors, setErrors] = useState({
        equipment: {},
    });

    const [locationAside, setLocationAside] = useState(false);

    const [assignPointsModal, setAssignPointsModal] = useState({
        open: false,
        current: false,
    });

    const [inProgress, setInProgress] = useState(false);
    const [askSavingInstPointType, setAskSavingInstPointType] = useState(false);
    const [saveInstPointType, setSaveInstPointType] = useState("");
    const [formErrors, setFormErrors] = useState([]);
    const history = useHistory();
    const {chartTypes} = useReadingsType();
    const canCreateRouteCollector = auth.hasRouteCollectorAccess() && _get(form, ["equipment", "service"]) === EQUIPMENT_SERVICE.SELF;

    const toggleLocationAside = () => {
        setLocationAside(!locationAside);
    };

    const onChange = (e, index = null) => {
        const data = {...form};

        const key = _get(e, "target.name", "");
        const val = _get(e, "target.value", "");

        if (index !== null) {
            _setWith(data, "points." + index + "." + key, val, Object);
            removeError("point." + index, key);
        } else {
            _setWith(data, key, val, Object);
        }

        if (_get(data, ["equipment", "service"]) !== EQUIPMENT_SERVICE.SELF) {
            _setWith(data, ["equipment", "is_route_collector"], false, Object);
        }

        setForm(data);
    };

    const onSubmit = () => {
        setInProgress(true);
        ApiEquipment.create(form, saveInstPointType)
            .then((response) => {
                if (response.status === "ok") {
                    history.push("/chart/" + response.equipment.id);
                } else {
                    if (response.type === "ask") {
                        let askErrorMessageMultiplePoints = "<div>";

                        if (Object.keys(response.errors.equipment.ask_sensors).length > 1) {
                            askErrorMessageMultiplePoints += "<div>The following sensors are assigned to an installation point:<div>";

                            _get(response.errors.equipment, "ask_sensors", []).map((sensor) => {
                                askErrorMessageMultiplePoints += "<span>Sensor " + sensor + "</span><br/>";
                            });
                        } else {
                            _get(response.errors.equipment, "ask_sensors", []).map((sensor) => {
                                askErrorMessageMultiplePoints += "<span>Sensor " + sensor + " is already assigned to an installation point.</span><br/>";
                            });
                        }

                        askErrorMessageMultiplePoints += "</div>";

                        setAskSavingInstPointType(askErrorMessageMultiplePoints ? askErrorMessageMultiplePoints : response.message);
                    } else if (response.errors && Object.keys(response.errors).length) {
                        setErrors(response.errors);
                    } else if (response.message) {
                        swal("", response.message, "error");
                    }
                }
                setInProgress(false);
            })
            .catch((response) => {
                setFormErrors(response.errors || {});
                setInProgress(false);
            });
    };

    const addInstPointBlock = () => {
        let points = [...form.points];
        points.push(InstallationPointFormData.getInstallationPointObject());
        setForm({
            ...form,
            points: points,
        });
    };

    const removeInstPointBlock = (index) => {
        let points = [...form.points];
        points = _filter(points, (point, key) => {
            return index !== key;
        });

        let positions = {};
        Object.keys(form.positions).map((imageIndex) => {
            let pointIndex = 0;
            Object.keys(form.positions[imageIndex] || {}).map((pIndex) => {
                if (pIndex !== index) {
                    _setWith(positions, [imageIndex, pointIndex], form.positions[imageIndex][pIndex], Object);
                    pointIndex++;
                }
            });
        });

        setForm({
            ...form,
            points: points,
            positions: positions,
        });
    };

    const toggleAssignPointsModal = (index) => {
        setAssignPointsModal({open: !assignPointsModal.open, current: assignPointsModal.open ? false : index});
    };

    const submitAssignPointsModal = (positions) => {
        toggleAssignPointsModal();
        setForm({...form, positions: positions});
    };

    const onDropFile = (files) => {
        const formNew = {...form};
        Promise.all(
            Object.keys(files).map(
                (file) =>
                    new Promise((resolve, reject) => {
                        if (files[file]) {
                            let reader = new FileReader();
                            reader.readAsDataURL(files[file]);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = (error) => reject(error);
                        } else {
                            reject("File is empty");
                        }
                    })
            )
        ).then((images = []) => {
            images.forEach((image, key) => {
                formNew.positions[(formNew.images || []).length + key] = undefined;
                formNew.flipTurns[(formNew.images || []).length + key] = undefined;

                formNew.imageInstallationPoint[(formNew.images || []).length + key] = null;
            });
            formNew.images = _concat(formNew.images, images);
            setForm(formNew);
        });
    };

    const onRemoveFile = (index) => {
        let images = [...form.images];
        let positions = {},
            flipTurns = {},
            newImageIndex = 0;

        images.forEach((image, imageIndex) => {
            if (imageIndex !== +index) {
                positions[newImageIndex.toString()] = form.positions[imageIndex];
                flipTurns[newImageIndex.toString()] = form.flipTurns[imageIndex];
                newImageIndex++;
            }
        });

        images = _filter(images, (file, key) => +index !== key);

        setForm({...form, images, positions, flipTurns});
        if (assignPointsModal.current === index) {
            setAssignPointsModal({...assignPointsModal, current: 0});
        }
    };

    const onFlipFile = (index, isRedo = false) => {
        const positions = form.positions[index];
        const flipTurn = (form.flipTurns[index] || 0) + (isRedo ? 0.25 : -0.25);
        if (Object.keys(positions || {}).length) {
            Object.keys(positions).forEach((key) => {
                const cTop = positions[key].top / positions[key].percent_top;
                const cLeft = positions[key].left / positions[key].percent_left;
                let percentLeft, percentTop;
                if (isRedo) {
                    percentTop = positions[key].percent_left;
                    percentLeft = 100 - positions[key].percent_top;
                } else {
                    percentTop = 100 - positions[key].percent_left;
                    percentLeft = positions[key].percent_top;
                }
                positions[key].percent_top = percentTop;
                positions[key].percent_left = percentLeft;
                positions[key].top = Math.round(cLeft * percentTop);
                positions[key].left = Math.round(cTop * percentLeft);
            });
        }
        setForm({
            ...form,
            positions: {...form.positions, [index]: positions},
            flipTurns: {...form.flipTurns, [index]: flipTurn},
        });
    };

    const onSetCurrent = (index) => setAssignPointsModal({...assignPointsModal, current: index});

    const onSetPosition = (data = {}) => {
        let positions = {...form.positions};
        if (data.clear === true) {
            _unset(positions, `${data.imageIndex}.${data.pointIndex}`);
        } else {
            _setWith(positions, [data.imageIndex, data.pointIndex], data, Object);
        }
        setForm({...form, positions});
    };

    const setCurrentInstPointIndex = (indexImage, indexInstPoint) => {
        const imageInstallationPoint = [...form.imageInstallationPoint];
        imageInstallationPoint[indexImage] = indexInstPoint;
        setForm({...form, imageInstallationPoint});
    };

    const removeError = (index, key) => {
        const oldFormErrors = {...formErrors};
        const indexError = _get(oldFormErrors, index);

        const newIndexError = _omit(indexError, key);
        _setWith(oldFormErrors, index, newIndexError, Object);

        setFormErrors(oldFormErrors);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Fragment>
            <div className="container create-equipment-container">
                <div className="justify-content-md-center create-equipment-content">
                    <div className="title">ADD EQUIPMENT</div>
                    <div className="sub-title">
                        <span>Equipment Information</span>
                    </div>
                    <form>
                        <div className="form-row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className={"form-label"}>
                                        Name <span className="color-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter Equipment Name"
                                        name="equipment.name"
                                        onChange={onChange}
                                        className={"form-control" + (errors.equipment.name ? " is-invalid" : "")}
                                    />
                                    {errors.equipment.name && <div className="invalid-feedback">{errors.equipment.name}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className={canCreateRouteCollector ? "col-12 col-md-6" : "col-12"}>
                                <EquipmentTypeSelector
                                    name={"equipment.equipmentTypeId"}
                                    value={form.equipment.equipmentTypeId}
                                    onChange={onChange}
                                />
                            </div>
                            {canCreateRouteCollector && (
                                <div className="col-12 col-md-6">
                                    <CheckboxFormGroup
                                        label={"Monitored by Route Collector"}
                                        name={"equipment.is_route_collector"}
                                        value={form.equipment.is_route_collector}
                                        onChange={onChange}
                                        error={errors.equipment.is_route_collector}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="form-row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Asset Code</label>
                                    <input
                                        name="equipment.assetCode"
                                        placeholder="Enter Equipment Asset Code"
                                        onChange={onChange}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className={"form-label"}>
                                        Asset Tree Branch <span className="color-danger">*</span>
                                    </label>
                                    <CollapseLocationSelect
                                        addClassName={errors.equipment.locationId ? " w100 is-invalid" : "w100"}
                                        selectName={"equipment.locationId"}
                                        value={+form.equipment.locationId}
                                        onChange={onChange}
                                        checkStoreValue={true}
                                        emptyOptionLabel={"Select Asset Tree Branch"}
                                    />
                                    {errors.equipment.locationId && <div className="invalid-feedback">This field is required.</div>}
                                </div>
                            </div>
                        </div>
                        {auth.userCan("editAssetsTree") && (
                            <div className="form-row">
                                <div className="col-12">
                                    <span
                                        onClick={toggleLocationAside}
                                        className="add-new-link"
                                    >
                                        <i className="fa fa-plus-circle" />
                                        <span>Add new asset tree branch</span>
                                    </span>
                                </div>
                            </div>
                        )}
                        {auth.userCan("superAdmin") && auth.isHybrid && (
                            <div className="form-row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className={"form-label"}>
                                            Service Type <span className="color-danger">*</span>
                                        </label>
                                        <SelectWrapper
                                            name="equipment.service"
                                            onChange={onChange}
                                            className={errors.equipment.service ? " is-invalid" : ""}
                                            value={form.equipment.service}
                                        >
                                            {Object.values(EQUIPMENT_SERVICE).map((service) => (
                                                <option
                                                    key={service}
                                                    value={service}
                                                >
                                                    {Helper.capitalize(service)}
                                                </option>
                                            ))}
                                        </SelectWrapper>
                                        {errors.equipment.service && <div className="form-control-notice">Asset Tree Branch cannot be empty.</div>}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="form-row">
                            <div className="col-12">
                                <div className="form-group">
                                    <div className="form-row">
                                        <div className={"col-md-6 custom-validation " + (errors.equipment.idle_threshold_type ? " is-invalid" : "")}>
                                            <label className="text-right form-label">Idle Threshold Type:</label>
                                            <SelectWrapper
                                                className={errors.equipment.idle_threshold_type ? " is-invalid" : ""}
                                                name="equipment.idle_threshold_type"
                                                onChange={onChange}
                                            >
                                                <option value="">---</option>
                                                {Object.values(IDLE_THRESHOLD_TYPES).map((type) => (
                                                    <option
                                                        key={type}
                                                        value={type}
                                                    >
                                                        {Helper.capitalize(type)}
                                                    </option>
                                                ))}
                                            </SelectWrapper>
                                        </div>
                                        <div className={"col-md-6 custom-validation " + (errors.equipment.idle_threshold ? " is-invalid" : "")}>
                                            <label className="text-right form-label">Idle Threshold:</label>
                                            <div className={"input-group"}>
                                                <input
                                                    className={"form-control " + (errors.equipment.idle_threshold ? " is-invalid" : "")}
                                                    type="number"
                                                    name="equipment.idle_threshold"
                                                    onChange={onChange}
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        {Helper.getIdleThresholdMeasure(chartTypes, form.equipment.idle_threshold_type)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {errors.equipment.idle_threshold && <div className="invalid-feedback">{errors.equipment.idle_threshold[0]}</div>}
                                        {errors.equipment.idle_threshold_type && (
                                            <div className="invalid-feedback">{errors.equipment.idle_threshold_type[0]}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {auth.userCan("editInstallationPoints") && (
                            <Fragment>
                                <div className="sub-title">
                                    <span>Set Up Installation Points</span>
                                </div>
                                <div className="installation-points-block">
                                    <InstallationPointCustomTypeStore>
                                        {form.points.map((point, index) => (
                                            <InstallationPointForm
                                                key={index}
                                                installationPoint={point}
                                                onChange={(e) => onChange(e, index)}
                                                removeButton={true}
                                                onRemoveButton={() => removeInstPointBlock(index)}
                                                formErrors={_get(formErrors, "point." + index)}
                                                isRouteCollector={form.equipment.is_route_collector}
                                            />
                                        ))}
                                    </InstallationPointCustomTypeStore>

                                    <span
                                        onClick={addInstPointBlock}
                                        className="add-new-link"
                                    >
                                        <i className="fa fa-plus-circle" />
                                        <span>Add new installation point</span>
                                    </span>
                                </div>
                            </Fragment>
                        )}
                    </form>
                    <div className="sub-title">
                        <span>Set Up Equipment Pictures</span>
                    </div>
                    <DropzoneWrapper
                        images={form.images}
                        flipTurns={form.flipTurns}
                        onDrop={onDropFile}
                        onAssignPoints={toggleAssignPointsModal}
                        onFlipFile={onFlipFile}
                        onRemove={onRemoveFile}
                        mainPlaceholder={"Drag and drop or upload files (up to 10)"}
                    />
                    <div className="d-flex justify-content-between">
                        <Link
                            to={"/"}
                            className="btn btn-sm btn-secondary"
                        >
                            Cancel
                        </Link>
                        <button
                            type="button"
                            disabled={inProgress}
                            className="btn btn-sm btn-primary"
                            onClick={onSubmit}
                        >
                            {inProgress ? (
                                <span>
                                    <i className="fa fa-spinner"></i> Loading
                                </span>
                            ) : (
                                "Save"
                            )}
                        </button>
                    </div>
                </div>
            </div>
            <LocationsAside
                locationAside={locationAside}
                locationAsideToggle={toggleLocationAside}
            />
            {assignPointsModal.open && (
                <AssignPointsModal
                    assignPointsModal={assignPointsModal}
                    images={form.images}
                    points={form.points}
                    flipTurns={form.flipTurns}
                    positions={form.positions}
                    imageInstallationPoint={form.imageInstallationPoint}
                    setCurrentInstPointIndex={setCurrentInstPointIndex}
                    onDrop={onDropFile}
                    onRemove={onRemoveFile}
                    onFlipFile={onFlipFile}
                    onSetCurrent={onSetCurrent}
                    onSetPosition={onSetPosition}
                    onClose={toggleAssignPointsModal}
                    onSubmit={submitAssignPointsModal}
                />
            )}
            {askSavingInstPointType !== false && (
                <div className={"sweet-alert-over-modal"}>
                    <SweetAlert
                        title=""
                        info
                        showConfirm={true}
                        onCancel={() => setAskSavingInstPointType(false)}
                        onConfirm={() => setAskSavingInstPointType(false)}
                        customButtons={
                            <React.Fragment>
                                <button
                                    className="btn btn-sm btn-default mr-2"
                                    disabled={inProgress}
                                    onClick={() => setAskSavingInstPointType(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-sm btn-primary__custom mr-2"
                                    disabled={inProgress}
                                    onClick={() => {
                                        setSaveInstPointType("create-new");
                                        onSubmit();
                                    }}
                                >
                                    Create New
                                </button>
                                <button
                                    className="btn btn-sm btn-primary"
                                    disabled={inProgress}
                                    onClick={() => {
                                        setSaveInstPointType("move-data");
                                        onSubmit();
                                    }}
                                >
                                    Move Data
                                </button>
                            </React.Fragment>
                        }
                    >
                        <div className={"sweet-alert-text"}>
                            <div
                                className="confirm-message breaking-line"
                                dangerouslySetInnerHTML={{__html: askSavingInstPointType}}
                            />
                            <br />
                            Click a button below and note the following:
                            <ul>
                                <li>
                                    <b>Cancel:</b> Closes the window.
                                </li>
                                <li>
                                    <b>Create New:</b> Creates a new installation point for the equipment and transfers the sensor from the previous
                                    installation point to the new one.{" "}
                                    <span className="text-underline">
                                        The old installation point will still exist in the system, but it will no longer have an installed sensor.
                                    </span>{" "}
                                </li>
                                <li>
                                    <b>Move Data:</b> Transfers the current installation point and sensor from the current equipment to the new location.
                                </li>
                            </ul>
                        </div>
                    </SweetAlert>
                </div>
            )}
        </Fragment>
    );
};

export default CreateEquipment;
