import React from "react";
import Breadcrumbs from "../../breadcrumbs/breadcrumbs";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import GlobalSearch from "../shared/globalSearch";

const HeaderSimple = (props) => {
    const { breadcrumbs, globalSearchString, handleChangeGlobalSearch, hideGlobalSearch = false } = props;
    const history = useHistory();
    return (
        <div className="header" id="k_header">
            <div className="header-left">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
            {!hideGlobalSearch &&  <GlobalSearch
                history={ history }
                globalSearchString={ globalSearchString }
                handleChangeGlobalSearch={ handleChangeGlobalSearch }
            /> }
        </div>
    );
};
HeaderSimple.propTypes = {
    breadcrumbs: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
    hideGlobalSearch:PropTypes.bool
};
export default HeaderSimple;
