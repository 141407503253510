import Api from "./api";
import moment from "moment";

const Reading = {
    getTypesList: () => {
        return Api.get("reading/get-types-list");
    },
    getChartData: ({installationPointID, chartType, axisID, range, from, to}) => {
        return Api.get("reading/get-chart-data", {
            query: {
                installationPointID: installationPointID,
                chartType,
                axisID,
                from,
                to,
                range,
            },
        });
    },
    getAmbientData: ({installationPointId, from, to, range}) => {
        return Api.get("reading/get-ambient-data-by-installation-point", {
            query: {
                installation_point_id: installationPointId,
                from,
                to,
                range,
            },
        });
    },
    getTachData: ({equipmentId, from, to, range}) => {
        return Api.get("reading/get-tach-data", {
            query: {
                equipmentId: equipmentId,
                from,
                to,
                range,
            },
        });
    },
    getFFTTimestampsList: (equipmentID, from, to, installationPointID) => {
        return Api.get("reading/get-fft-timestamps-with-meta", {
            query: {
                equipmentId: equipmentID,
                installationPointId: installationPointID,
                from: from || "",
                to: to || "",
            },
        });
    },
    getChartFFTDataBatch: (installationPointID, chartType, axisID, readingsList, impactVue = false, highpass = null, isInterpolation = true) => {
        return Api.get("reading/get-fft-data-batch", {
            query: {
                installationPointID: installationPointID,
                axisID: axisID,
                chartType: chartType,
                readingsList: readingsList,
                isHFDvue: impactVue === true ? impactVue : "",
                highpass: highpass,
                disableInterpolation: !isInterpolation,
            },
        });
    },
    getChartFFTData: (installationPointID, axisID, readingId, isHFDvue = false, highpass = null, isInterpolation = true) => {
        return Api.get("reading/get-fft-data", {
            query: {
                installationPointID: installationPointID,
                axisID: axisID,
                readingId: readingId,
                isHFDvue: isHFDvue || "",
                highpass: highpass,
                disableInterpolation: !isInterpolation,
            },
        });
    },
    deleteChartReading(installationPointId, readingId, isImpactVue) {
        return isImpactVue ? this.deleteChartImpactVueFFTData(installationPointId, readingId) : this.deleteChartFFTData(installationPointId, readingId);
    },
    deleteChartFFTData: (installationPointId, readingId) => {
        return Api.delete("reading/delete-fft/" + readingId + "/" + installationPointId);
    },
    deleteChartImpactVueFFTData: (installationPointId, readingId) => {
        return Api.delete("reading/delete-hfdvue-fft/" + readingId + "/" + installationPointId);
    },
    getChartFFtAverageData(installationPointID, followingType, options, signal) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(
                    Api.get("reading/get-chart-fft-average-data", {
                        query: {
                            installationPointID: installationPointID,
                            followingType: followingType,
                            ...options,
                            avgDateFrom: options.avgDateFrom ? moment(options.avgDateFrom).format("YYYY-MM-DD HH:mm:ss") : options.avgDateFrom,
                            avgDateTo: options.avgDateTo ? moment(options.avgDateTo).format("YYYY-MM-DD HH:mm:ss") : options.avgDateTo,
                        },
                        signal: signal,
                    })
                );
            }, 300);
        });
    },

    checkForUpdates(equipmentId, data) {
        return Api.post(`reading/check-for-updates/${equipmentId}`, data);
    },

    checkForFftUpdates(data) {
        return Api.post("reading/check-for-fft-updates", data);
    },

    deleteReading(data) {
        return Api.post("reading/remove-point", data);
    },

    deleteTachReading(data) {
        return Api.post("reading/remove-tach-point", data);
    },

    getAlertTypesList: () => Api.get("reading/get-alert-types-list"),

    markFftAsOutlier(installationPointId, data) {
        return Api.post(`reading/fft-outlier/${installationPointId}`, data);
    },
};

export default Reading;
