import {useMutation, useQueryClient} from "@tanstack/react-query";
import {FAULT_FREQUENCY_API} from "./constants";
import ApiFaultFrequency from "../../../api/faultFrequency";

export const useFaultFrequencyDeleteMutation = (equipmentId) => {
    const queryClient = useQueryClient();

    const deleteFaultFrequency = (deleteFaultFrequencyId) => {
        return ApiFaultFrequency.delete(equipmentId, deleteFaultFrequencyId);
    };

    const mutation = useMutation(deleteFaultFrequency, {
        onSuccess: (data, deleteFaultFrequencyId) => {
            queryClient.setQueriesData([FAULT_FREQUENCY_API.GET_FREQUENCY, equipmentId], (prev) => {
                prev.list = prev.list.filter((item) => item.id !== deleteFaultFrequencyId);
                return prev;
            });
        },
    });
    return mutation;
};
