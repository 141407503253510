import {useChartStateStoreContext, useSelectChartWaterfallSort} from "../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useEffect, useMemo} from "react";
import {useCommonChartOptionsBuilder} from "./factory/useCommonChartOptionsBuilder";
import Helper from "../../../../../helpers/helper";
import useUserProfile from "../../../../../hooks/api/Global/useUserProfile";
import {useGetFFTReadings, useGetPointReadingParamsByDateTimeBatch} from "../../../../../hooks/api/fftReadings/ReadingsQuery";
import {usePointData} from "../../hooks/usePointsData";
import {cloneDeep as _cloneDeep, get as _get} from "lodash";
import moment from "moment/moment";
import {AXES_COLORS, AXES_COLORS_DARK} from "../../../../../constants/constants";
import {useIsCircular} from "../../hooks/useChartViewTypes";
import {useIsAutoCorrelation} from "../../hooks/useChartModes";
import {useIsDarkTheme} from "../../../../../hooks/helpers/theme";
import useAxisLabelQuery from "../../../../../hooks/api/axisLabel/useAxisLabelQuery";
import ChartHelper from "../../../../../helpers/chart";
import {useEquipmentByParams} from "../../../../../hooks/api/equipment/useEquipmentQuery";
import {useIsImpactVue} from "../../hooks/useChartTypes";
import {useInterpolationSyncStoreByPoint} from "../../store/sync/InterpolationSuncStore";
import {useHighPassFilterSyncStoreByPoint} from "../../store/sync/HighPassFilterSyncStore";
import {useSelectedAxis} from "./helpers/useSelectedAxis";
import {resetZoomForGroup} from "../../helpers/resetZoomForGroup";
import {WATERFALL_ORDER_VARIANTS} from "../components/buttons/WaterfallOrderDropdown";

const sortHandlersFn = {
    [WATERFALL_ORDER_VARIANTS.DESC]: (itemA, itemB) => new Date(itemA.timestamp) - new Date(itemB.timestamp),
    [WATERFALL_ORDER_VARIANTS.ASC]: (itemA, itemB) => new Date(itemB.timestamp) - new Date(itemA.timestamp),
};

export const useDrawChartWaterfallStackEffect = () => {
    const {data: user} = useUserProfile();
    const {equipmentItem} = useEquipmentByParams();
    const {setLoader} = useChartStateStoreContext((state) => state.actions);

    const isDarkMode = useIsDarkTheme() === "true";
    const options = useCommonChartOptionsBuilder();
    const {chartType, chartViewType, chartMode, seriesList, selectedPoint, currentWaterfallDates} = useChartStateStoreContext(
        (state) => state.settings,
        shallow
    );
    const selectedAxis = useSelectedAxis();
    const pointData = usePointData(selectedPoint);
    const isInterpolation = useInterpolationSyncStoreByPoint(pointData.id);
    const highpassFilter = useHighPassFilterSyncStoreByPoint(pointData.id, pointData);
    const waterfallSort = useSelectChartWaterfallSort();

    const isImpactVue = useIsImpactVue(chartType);
    const {axisLabelsChart: axisLabels} = useAxisLabelQuery(pointData?.equipment_id);

    const isCircular = useIsCircular(chartViewType);
    const isAutoCorrelation = useIsAutoCorrelation(chartMode);
    const isDarkTheme = useIsDarkTheme();
    const axisIds = useSelectedAxis();

    const {params: readingsParams} = useGetPointReadingParamsByDateTimeBatch(
        pointData.equipment_id,
        pointData.id,
        axisIds[0],
        currentWaterfallDates,
        isImpactVue,
        highpassFilter,
        isInterpolation
    );

    const {data: readings, isSuccess, isFetched, isLoading} = useGetFFTReadings(chartType, readingsParams);

    const zoomGroupName = `waterfallList_${pointData.id}_${chartType}`;

    useEffect(() => {
        if (isLoading) {
            setLoader(true);
        }
    }, [isLoading]);

    useEffect(() => {
        isSuccess === true && isFetched && setLoader(false);
    }, [isSuccess, readingsParams, chartMode, chartViewType, selectedAxis]);

    const chartOptions = useMemo(() => {
        if (!isSuccess || !readings) {
            return [];
        }

        const sortedReading = [...readings];
        const sortFn = sortHandlersFn[waterfallSort];

        sortedReading.sort(sortFn);

        return sortedReading.map((reading) => {
            const chartOptions = _cloneDeep(options);
            chartOptions.title.text = Helper.dateToUserFormat(reading.timestamp, user);

            if (isDarkMode) {
                chartOptions.title.style = {color: "#cccccc"};
            }

            chartOptions.chart.syncZoomGroup = zoomGroupName;
            chartOptions.chart.resetZoomButton.theme = {};
            chartOptions.chart.resetZoomButton.theme = {};
            chartOptions.chart.resetZoomButton.position = {
                align: "left",
            };
            const speedSettings = ChartHelper.getCurrentSpeed(reading, pointData, equipmentItem);

            const axisLabel = _get(axisLabels, [reading.axisID, pointData.id]) || seriesList.list[reading.axisID].axisName + " - Axis";
            const seriesName = `${pointData.name || "---"} - ${axisLabel}`;

            let idKey = `${pointData.id}_${reading.axisID}_${reading.readingID}`;
            let data = reading.data;

            chartOptions.series = [
                {
                    id: idKey,
                    name: seriesName,
                    readingDate: moment.utc(reading.timestamp).format(Helper.getUserDateFormat(user)),
                    color: isDarkTheme === "true" ? AXES_COLORS_DARK[reading.axisID] : AXES_COLORS[reading.axisID],
                    pointColor: pointData.color,
                    data,
                    connectEnds: false,
                    cycleIteration: 0,
                    units: seriesList.units,
                    isAutoCorrelation: isAutoCorrelation,
                    isCircular: isCircular,
                    precision: seriesList.precision,
                    overall: reading.overall || 0,
                    pkpk: reading.pkpk || 0,
                    chartType: chartType,
                    speed: speedSettings?.value || 0 || 0,
                    ratio: speedSettings?.ratio || 1,
                    rpmFrom: speedSettings?.from || "",
                    rpmTimestamp: moment.utc(speedSettings?.rpmTimestamp || "").format(Helper.getUserDateFormat(user)),
                    dataGrouping: {
                        approximation: "high",
                        forced: true,
                    },
                    enableMouseTracking: true,
                    isStackedWaterfall: true,
                },
            ];

            return chartOptions;
        });
    }, [readings, isSuccess, selectedAxis, chartMode, chartViewType, readingsParams, isDarkMode, waterfallSort]);

    useEffect(() => {
        resetZoomForGroup(zoomGroupName, "xy");
    }, [chartOptions]);

    return chartOptions;
};
