import React, {Component, useContext} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import ApiAlarm from "../api/alarm";
import ApiDiagnostic from "../api/diagnostic";
import ApiDeviceAlert from "../api/deviceAlert";
import ApiWhatsNew from "../api/whatsNew";
import ApiGlobalNotes from "../api/globalNotes";
import InstallationPointSettingsApi from "../api/InstallationPointSettings";
import Toastr from "../pages/shared/toast";
import {remove as _remove} from "lodash";
import ActionListApi from "../api/actionList";
import "../assets/scss/global.scss";

export const GlobalContext = React.createContext();

const propTypes = {
    children: PropTypes.node,
    auth: PropTypes.object,
    user: PropTypes.object,
    chartTypes: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    updateUser: PropTypes.func,
};

class GlobalStore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            performanceStat: {},
            countActiveAlarms: 0,
            countActiveDeviceAlerts: {
                total: 0,
                gateways: 0,
                routers: 0,
                nodes: 0,
                sensor: 0,
                motes: 0,
            },
            countActiveNews: 0,
            countGlobalNotes: 0,
            countActionItems: 0,
        };

        this.pendingOnDemands = [];
        this.onDemandTimeout = false;

        this.updateCountActiveAlarms = this.updateCountActiveAlarms.bind(this);
        this.updateDiagnosticPerformanceStat = this.updateDiagnosticPerformanceStat.bind(this);
        this.updateCountActiveDeviceAlerts = this.updateCountActiveDeviceAlerts.bind(this);
        this.updateCountActiveNews = this.updateCountActiveNews.bind(this);
        this.checkOnDemand = this.checkOnDemand.bind(this);
        this.addPendingOnDemand = this.addPendingOnDemand.bind(this);
        this.updateCountGlobalNotes = this.updateCountGlobalNotes.bind(this);
    }

    componentDidMount() {
        InstallationPointSettingsApi.pendingOnDemands().then((response) => {
            if (response && response.status === "ok" && response.list.length) {
                this.pendingOnDemands = response.list;

                this.onDemandTimeout = this.getOnDemandTimout();
            }
        });
    }

    componentWillUnmount() {
        clearTimeout(this.onDemandTimeout);
    }

    addPendingOnDemand(settingId) {
        this.pendingOnDemands.push(settingId);
    }

    getOnDemandTimout() {
        return setTimeout(() => {
            this.checkOnDemand();
        }, 5 * 60 * 1000);
    }

    checkOnDemand() {
        if (!this.pendingOnDemands || !this.pendingOnDemands.length) {
            return;
        }

        InstallationPointSettingsApi.check(this.pendingOnDemands).then((response) => {
            if (response && response.list && response.list.length) {
                for (const reading of response.list) {
                    Toastr.success("Click here to navigate to charts", "New on-demand reading", {
                        timeOut: 0,
                        extendedTimeOut: 0,
                        onclick: () =>
                            window.open(
                                `/chart/${reading.installationPoint.equipment_id}#sensors=${reading.installationPoint.id}&readingFftId=${reading.readingFFT_id}`
                            ),
                    });
                    _remove(this.pendingOnDemands, (settingId) => reading.setting_id == settingId);
                }
            }
            this.onDemandTimeout = this.getOnDemandTimout();
        });
    }

    updateCountActiveAlarms() {
        ApiAlarm.getCount().then(({count = 0}) => this.setState({countActiveAlarms: +count}));
    }

    updateDiagnosticPerformanceStat() {
        ApiDiagnostic.performanceStat().then(({stat = {}}) => this.setState({performanceStat: stat}));
    }
    updateCountActiveNews() {
        ApiWhatsNew.getCount().then(({count}) => this.setState({countActiveNews: count}));
    }

    updateCountActiveDeviceAlerts() {
        ApiDeviceAlert.getCounts().then((counts) => this.setState({countActiveDeviceAlerts: counts}));
    }

    updateCountGlobalNotes() {
        ApiGlobalNotes.getCount().then(({count}) => this.setState({countGlobalNotes: +count}));
    }

    updateCountActionItems = () => {
        ActionListApi.getCount().then(({count}) => this.setState({countActionItems: +count}));
    };

    render() {
        const {performanceStat, countActiveAlarms, countActiveDeviceAlerts, countActiveNews, countGlobalNotes, countActionItems} =
            this.state;
        const {auth = {}, user = {}, chartTypes = {}, updateUser} = this.props;

        return (
            <GlobalContext.Provider
                value={{
                    auth,
                    user,
                    chartTypes,
                    performanceStat,
                    countActiveAlarms,
                    countActiveNews,
                    countActiveDeviceAlerts,
                    countGlobalNotes,
                    countActionItems,
                    updateCountActiveAlarms: this.updateCountActiveAlarms,
                    updateDiagnosticPerformanceStat: this.updateDiagnosticPerformanceStat,
                    updateCountActiveDeviceAlerts: this.updateCountActiveDeviceAlerts,
                    addPendingOnDemand: this.addPendingOnDemand,
                    updateCountActiveNews: this.updateCountActiveNews,
                    updateCountGlobalNotes: this.updateCountGlobalNotes,
                    updateCountActionItems: this.updateCountActionItems,
                    updateUser,
                }}
            >
                {this.props.children}
            </GlobalContext.Provider>
        );
    }
}

function withGlobalStore(Component) {
    return function ConnectedComponent(props) {
        const context = useContext(GlobalContext);
        return (
            <Component
                {...props}
                {...context}
            />
        );
    };
}

GlobalStore.propTypes = propTypes;

export default withRouter(GlobalStore);
export {withGlobalStore};
