import BasePopover from "../../../../pages/shared/base-popover";
import Helper from "../../../../helpers/helper";
import PropTypes from "prop-types";
import React from "react";
import "../../../../assets/scss/components/modals/equipments/equipments.scss";

const InstPointPopover = ({points, openPopoverIndex, imageIndex, colors, currentInstPointIndex, setCurrentInstPointIndex, toggle}) => {
    return (
        <BasePopover
            target={"#image-popover-" + imageIndex}
            placement={"bottom-start"}
            isOpen={openPopoverIndex === +imageIndex}
            container={".assign-photo-list-wrapper"}
            toggle={toggle}
        >
            {Object.keys(points).map((indexInstPoint) =>
                (+indexInstPoint !== 999 && points[indexInstPoint].name &&
                    <div key={indexInstPoint} className="sensors-location d-flex align-items-center justify-content-between">
                        <div className={"d-flex"}>
                            <label className="form-checkbox" title={"Assign pictures at sensor level"}>
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        setCurrentInstPointIndex(imageIndex, Helper.getInputValue(e.target) === "1" ? indexInstPoint : null);
                                    }}
                                    checked={currentInstPointIndex === indexInstPoint}
                                />
                                <span/>
                            </label>
                            <span className="index-icon-block" style={{backgroundColor: colors[indexInstPoint]}}>{+indexInstPoint + 1}</span>
                            <span className="point-name"><span>{points[indexInstPoint].name}</span></span>
                        </div>
                    </div>
                )
            )}
        </BasePopover>
    );
};

InstPointPopover.propTypes = {
    points: PropTypes.object,
    imageIndex: PropTypes.number,
    setCurrentInstPointIndex: PropTypes.func,
    currentInstPointIndex: PropTypes.any,
    openPopoverIndex: PropTypes.number,
    colors: PropTypes.object,
    toggle: PropTypes.func
};

export default InstPointPopover;