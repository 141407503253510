import React, {useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {CURSORS} from "../../../../../../constants/constants";
import ChartHelper from "../../../../../../helpers/chart";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useLockPoints} from "../../hooks/useLockPoints";
import {useChartRef} from "../../hooks/useChartRef";
import {useIsCircular} from "../../../hooks/useChartViewTypes";
import {unpauseChart} from "../../../../../../helpers/holdMode";

function CursorButton() {
    const chartRef = useChartRef();

    const {fftCursor, chartViewType} = useChartStateStoreContext((state) => state.settings, shallow);
    const {chartModeList} = useChartStateStoreContext((state) => state.factory, shallow);
    const lockPoints = useLockPoints();

    const {setChartCursor} = useChartStateStoreContext((state) => state.actions, shallow);
    const isCircular = useIsCircular(chartViewType);
    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const [isOpen, setIsOpen] = useState(false);

    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    const changeCursorHandler = (fftCursor) => {
        ChartHelper.clearAdditionalPoints(chartRef.current?.chart, chartMode === chartModeList.STACKED_WATERFALL);
        if (chartRef.current?.chart) {
            unpauseChart(chartRef.current);
        }
        lockPoints.current = [];
        setChartCursor(fftCursor);
    };

    if (isCircular || chartMode === chartModeList.WATERFALL) {
        return <></>;
    }

    return (
        <Dropdown
            size="sm"
            isOpen={isOpen}
            disabled={!!isLoading}
            toggle={() => setIsOpen((prevState) => !prevState)}
        >
            <DropdownToggle
                tag="span"
                caret
                className="frequency-types-link"
            >
                {CURSORS[fftCursor]}
            </DropdownToggle>
            <DropdownMenu>
                {Object.keys(CURSORS).map((cursor) => (
                    <DropdownItem
                        key={cursor}
                        onClick={() => changeCursorHandler(cursor)}
                        disabled={fftCursor === cursor}
                        className={`${fftCursor === cursor ? "active-cursor" : ""}`}
                    >
                        {CURSORS[cursor]}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}

export default CursorButton;
