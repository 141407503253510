import {useEffect, useMemo} from "react";
import Highcharts from "highcharts";
import {CURSORS} from "../../../../../constants/constants";
import {get as _get, sum as _sum} from "lodash";
import Helper from "../../../../../helpers/helper";
import {useChartStateStoreContext} from "../store/hooks/useStoreContext";
import {useLockPoints} from "./useLockPoints";
import {useChartRef} from "./useChartRef";
import {useSetSpeedModal} from "../../../../../modals/installationPoint/hooks/useSetSpeedModal";
import {useIsAutoCorrelation, useIsTWF} from "../../hooks/useChartModes";
import {useChartPeaks} from "./helpers/useChartPeaks";
import {useChartHoldEffect} from "../../hooks/keyboard/useChartHoldEffect";
import {useFftChartNavigationEffect} from "../../hooks/keyboard/useFftChartNavigationEffect";
import {useChartLegendEffect} from "../../hooks/keyboard/useChartLegendEffect";

export const useKeyDownEffect = (changeCursorHandler) => {
    const state = useChartStateStoreContext();
    const {setIsCurrentSpeedModal} = useChartStateStoreContext((state) => state.actions);
    const lockPoints = useLockPoints();
    const {peaks, maxPeak} = useChartPeaks();
    const isTwf = useIsTWF(state.settings.chartMode);
    const isAutoCorrelation = useIsAutoCorrelation(state.settings.chartMode);
    const chartRef = useChartRef();
    const [showSetSpeedModal] = useSetSpeedModal();
    const fftCursor = useChartStateStoreContext((state) => state.settings.fftCursor);

    const isModalOpen = useMemo(
        () => Helper.getHashParams().modal || _sum(state.modal) || state.setSpeedModal?.show,
        [state.modal, state.setSpeedModal?.show]
    );

    const handleKeyUp = (e) => {
        if (
            !_get(chartRef.current, "chart.hoverPoint", false) ||
            Highcharts.charts[Highcharts.hoverChartIndex] !== chartRef.current.chart ||
            isModalOpen
        ) {
            return;
        }
        if (e.keyCode === 67) {
            // C - key
            chartRef.current.chart.isPaused = false;
            const cursors = Object.keys(CURSORS);
            const currentIndex = cursors.indexOf(state.settings.fftCursor);
            let nextCursor = cursors[currentIndex + 1];
            if (!nextCursor) nextCursor = "normal";
            changeCursorHandler(nextCursor);
        } else if (e.keyCode === 83 && e.altKey) {
            // alt+S
            setIsCurrentSpeedModal(true);
            showSetSpeedModal();
        }
    };

    useChartHoldEffect(chartRef, fftCursor, lockPoints);
    useFftChartNavigationEffect(chartRef);
    useChartLegendEffect(chartRef);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyUp);

        return () => {
            document.removeEventListener("keydown", handleKeyUp);
        };
    }, [isTwf, isAutoCorrelation, peaks, maxPeak, state.settings.fftCursor]);
};
