import Api from "./api";

const WhatsNew = {
    getList: filter => Api.get("whats-new", filter),
    getItem: id => Api.get(`whats-new/${id}`),
    getCount: () => Api.get("whats-new/count"),
    setViewed: id => Api.get(`whats-new/set-viewed/${id}`),
    setViewedAll: () => Api.get("whats-new/set-viewed-all"),
    setMarked: id => Api.get(`whats-new/set-marked/${id}`),
    deleteMarked: id => Api.get(`whats-new/delete-marked/${id}`)
};

export default WhatsNew;