import React from "react";
import PropTypes from "prop-types";
import InfoTooltip from "../../../../shared/infoTooltip/infoTooltip";

const HeatsinkField = ({value = 0, onChange, readOnly = false, withLabel = true}) => {
    return (
        <>
            {withLabel && (
                <>
                    <label>
                        Insulator{" "}
                        <InfoTooltip iconClass="blue-tooltip">
                            Select this option to indicate any sensors that are installed with an insulator, such as a heatsink or ceramic pad, to reduce
                            heat.
                        </InfoTooltip>
                    </label>
                    <br />
                </>
            )}
            <div className="switch-block">
                <label className={"switch " + (!withLabel ? " d-block" : "")}>
                    <input
                        readOnly={true}
                        type="checkbox"
                        value={value}
                        onChange={() => {
                            if (!readOnly) {
                                onChange({
                                    target: {
                                        name: "is_heatsink",
                                        value: !value,
                                    },
                                });
                            }
                        }}
                        checked={value ? "checked" : false}
                    />
                    <span className={"slider round " + (readOnly ? " default-cursor " : "")} />
                </label>
            </div>
        </>
    );
};

HeatsinkField.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    withLabel: PropTypes.bool,
};

export default HeatsinkField;
