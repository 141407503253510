import { useCreateChartStateStore } from "../store/chartStateStore";
import React, { useRef } from "react";
import {
    ChartRefContext,
    ChartStateStore,
    LockPointsContext,
    ChartCurrentPointRefContext,
    ChartCurrentPointsRefContext
} from "../context/context";

// eslint-disable-next-line react/display-name
export const WithChartLocalStore = (Component) => (props) => {
    const chartStateStore = useCreateChartStateStore(props);
    const lockPoints = useRef([]);
    const chartRef = useRef(null);
    const currentPointRef = useRef(null);
    const currentPointsRef = useRef([]);

    return (
        <ChartCurrentPointRefContext.Provider value={currentPointRef}>
            <ChartCurrentPointsRefContext.Provider value={currentPointsRef}>
                <ChartStateStore.Provider value={chartStateStore}>
                        <LockPointsContext.Provider value={lockPoints}>
                            <ChartRefContext.Provider value={chartRef}>
                                <Component {...props} />
                            </ChartRefContext.Provider>
                        </LockPointsContext.Provider>
                </ChartStateStore.Provider>
            </ChartCurrentPointsRefContext.Provider>
        </ChartCurrentPointRefContext.Provider>
    );
};
