import {cloneDeep as _cloneDeep, get as _get, find as _find} from "lodash";

export const pauseChart = (chartInstance, cursor = null, lockPointsRef = null) => {
    if (!chartInstance.chart.isChartOver) {
        return;
    }

    chartInstance.chart.isPaused = !chartInstance.chart.isPaused;
    if ((cursor && cursor === "normal") || cursor === null) {
        chartInstance.chart.holdPoints = _cloneDeep(chartInstance?.chart?.hoverPoints);
    }

    if (!chartInstance.chart.isPaused) {
        if (lockPointsRef) {
            lockPointsRef.current = [];
        }
        chartInstance.chart.holdPoints = null;
    }
};

export const unpauseChart = (chartInstance) => {
    if (!chartInstance.chart.isChartOver) {
        return;
    }
    chartInstance.chart.isPointBPaused = false;
    chartInstance.chart.isPaused = false;
    chartInstance.chart.holdPoints = null;
};

export const isChartPaused = (chartInstance) => {
    return (
        (!_get(chartInstance, "chart.hoverPoint", false) && _get(chartInstance, "chart.isPaused", false)) ||
        _get(chartInstance, "chart.holdPoints.length", false)
    );
};
export const zoomPausedChart = (chartInstance) => {
    if (!chartInstance.chart.holdPoints) {
        return;
    }

    restorePoint(chartInstance);

    chartInstance.chart.tooltip.refresh(chartInstance.chart.holdPoints);
    chartInstance.chart.isPaused = true;
};

export const restorePoint = (chartInstance) => {
    if (!chartInstance.chart.holdPoints) {
        return;
    }

    const seriesIndex = chartInstance.chart.holdPoints[0].series.index;
    const pointIndex = chartInstance.chart.holdPoints[0].index;
    // TODO: speed up point search for charts with high amount of points
    const holdSeries = _find(chartInstance.chart.series, {index: seriesIndex});
    const holdPoint = _find(holdSeries.points, {index: pointIndex});

    if (holdPoint) {
        holdPoint.onMouseOver();
    }
};
