import {useMemo} from "react";
import {maxBy as _maxBy, minBy as _minBy} from "lodash";
export const useSoftValueBuilder = (plotLines) => {
    const softValues = useMemo(() => {
        if (!plotLines?.length) {
            return false;
        }

        const maxPlotLine = _maxBy(plotLines, (i) => i.value);
        const minPlotLine = _minBy(plotLines, (i) => i.value);

        return {softMin: minPlotLine.value, softMax: maxPlotLine.value};
    });

    return softValues;
};
