import React, {useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Helper from "../helpers/helper";
import {
    get as _get,
    each as _each
} from "lodash";
import { useEquipmentByParams } from "../hooks/api/equipment/useEquipmentQuery";
import ImpactVueText from "../shared/impactVue/ImpactVueText";

const ImpactViewNotification = () => {
    const {equipmentItem} = useEquipmentByParams();

    let sensorSupportImpactVue = false;
    let sensorEnableImpactVue = false;
    _each(_get(equipmentItem, "installationPoints") || [], point => {
        if (_get(point, "sensor.supportHfdvue")) {
            sensorSupportImpactVue = true;
        }
        if (_get(point, "sensor.is_hfdvue")) {
            sensorEnableImpactVue = true;
        }
    });

    const showNotify = sensorSupportImpactVue && sensorEnableImpactVue && !Helper.getStorageItem("impactVueNotify");
    const [showImpactVueAlert, setShowImpactVueAlert] = useState(!!showNotify);

    const onConfirm = () => {
        Helper.setStorageItem("impactVueNotify", true);
        setShowImpactVueAlert(false);
    };

    return (
        <SweetAlert
            show={showImpactVueAlert}
            customClass={"sweet-alert-subs"}
            showCancel={false}
            confirmBtnText="Ok"
            cancelBtnBsStyle="default"
            btnSize="xs"
            title=""
            onConfirm={onConfirm}
        >
            To view the <ImpactVueText /> charts, click the Change button below.
        </SweetAlert>
    );
};

export default ImpactViewNotification;