import React from "react";
import InputRange from "react-input-range";
import PropTypes from "prop-types";
import { FREQUENCY_TYPES } from "../../../constants/constants";

const HighPassFilterInput = ({name, value, maxValue, onChange, errorMsg}) => {
    if (+value > +maxValue) {
        onChange({
            target: {
                name: name || "high_pass",
                value: maxValue,
            },
        });
    }

    return (
        <>
            <input
                className={"form-control request-form-control" + (errorMsg ? " is-invalid" : "")}
                type="number"
                name={name || "high_pass"}
                value={value}
                step={1}
                min={1}
                max={maxValue}
                onChange={(e) => {
                    if (e.target.value <= maxValue && e.target.value >= 1) {
                        onChange(e);
                    }
                }}
            />
            <InputRange
                maxValue={maxValue}
                formatLabel={(value) => `${value} ${FREQUENCY_TYPES.HZ}`}
                minValue={1}
                value={value}
                onChange={(value) => {
                    if (value <= maxValue && value >= 1) {
                        onChange({
                            target: {
                                name: name || "high_pass",
                                value,
                            },
                        });
                    }
                }}
            />
        </>
    );
};

HighPassFilterInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    maxValue: PropTypes.number,
    errorMsg: PropTypes.string,
};

export default HighPassFilterInput;