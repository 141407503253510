import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import EquipmentRequestOnDemandApi from "../../../../api/equipmentRequestOnDemand";
import {Loader} from "../../../../shared";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";

const RequestOnDemandTab = () => {
    const [loader, setLoader] = useState(true);
    const [list, setList] = useState([]);

    const {equipmentItem} = useEquipmentByParams();

    useEffect(() => {
        handleFetch();
    }, []);

    const handleFetch = () => {
        EquipmentRequestOnDemandApi.list(equipmentItem.id).then((requestOnDemand) => {
            setLoader(false);
            setList(requestOnDemand.list);
        });
    };

    return (
        <div className="demand">
            <div className="demand-title justify-content-between">
                <div className={"demand-history"}>Request History</div>
            </div>
            <div className="demand-body pt-0 custom-scroll demand-scroll history">
                {loader ? (
                    <Loader />
                ) : list.length ? (
                    <RequestOnDemandList list={list} />
                ) : (
                    <div
                        className="text-center mb-5"
                        role="alert"
                    >
                        <div className="alert-text d-flex flex-column align-items-center">
                            <img
                                src="/assets/pic/icon-no-data.svg"
                                className="mb-2"
                                alt=""
                            />
                            No request history exists.
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

RequestOnDemandTab.propTypes = {
    equipment: PropTypes.object,
    history: PropTypes.object,
};

const RequestOnDemandList = (props) => {
    const {list} = props;

    return (
        <div className="timeline">
            {list.map((item) => (
                <RequestOnDemandItem
                    key={item.id}
                    item={item}
                />
            ))}
        </div>
    );
};

RequestOnDemandList.propTypes = {
    list: PropTypes.array,
};

const RequestOnDemandItem = ({item}) => {
    return (
        <div className="timeline-item timeline-item--brand">
            <div className="timeline-item__section">
                <div className="timeline-item__border">
                    <div className="timeline-item__icon">
                        <i className="fa fa-retweet" />
                    </div>
                </div>
                <span className="timeline-item__datetime">{item.created_human}</span>
            </div>
            <p className="timeline-item__text">
                <strong>{item.installationPoint.name}</strong>
                <br />
                {item.description}
            </p>
            {item.user.full_name.length > 0 && <div className="timeline-item__info">{item.user.full_name}</div>}
        </div>
    );
};

RequestOnDemandItem.propTypes = {
    item: PropTypes.object,
};

export default RequestOnDemandTab;
