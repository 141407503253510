import React, {Component} from "react";
import {Modal} from "../../../shared";
import PropTypes from "prop-types";
import Table from "./table";
import BearingApi from "../../../api/bearing";
import SelfBearingApi from "../../../api/selfBearing";
import SearchInput from "../../../pages/shared/search-input";
import {ConfirmAdd, ConfirmDelete} from "./confirmation-modals";
import SweetAlert from "react-bootstrap-sweetalert";
import SelfBearingModal from "./self-bearing-modal";
import {cloneDeep as _cloneDeep} from "lodash";
import Toast from "../../../pages/shared/toast";

class BearingModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: true,
            list: [],
            sort: {
                field: "",
                sort: "asc",
            },
            filter: {
                query: "",
            },
            selected: [],
            addBearing: null,
            deleteBearing: null,
            inProgress: false,
            selfBearings: false,
            deleteSelfBearing: null,
            addSelfBearing: false,
            selfBearingId: null,
            disabledField: false,
        };
        this.handleFetch = this.handleFetch.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handlePagerChange = this.handlePagerChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.handleAddBearing = this.handleAddBearing.bind(this);
        this.addBearing = this.addBearing.bind(this);
        this.handleDeleteBearing = this.handleDeleteBearing.bind(this);
        this.deleteBearing = this.deleteBearing.bind(this);
        this.changePlotLinesCount = this.changePlotLinesCount.bind(this);
        this.handleDeleteSelfBearing = this.handleDeleteSelfBearing.bind(this);
        this.deleteSelfBearing = this.deleteSelfBearing.bind(this);
        this.handleAddSelfBearing = this.handleAddSelfBearing.bind(this);
        this.handleCloseSelfBearingModal = this.handleCloseSelfBearingModal.bind(this);
        this.handleEditSelfBearing = this.handleEditSelfBearing.bind(this);
        this.onSubmitSelfBearingModal = this.onSubmitSelfBearingModal.bind(this);
    }

    componentDidMount() {
        this.handleFetch();
    }

    handleFetch() {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        return BearingApi.list({
            query: {
                sort: this.state.sort,
                filter: this.state.filter,
                installation_point_id: this.props.installationPointId,
                self_bearings: this.state.selfBearings,
            },
            signal: this.signal,
        }).then((response) => {
            if (response) {
                const {list, selected} = response;
                this.setState(
                    {
                        loader: false,
                        inProgress: false,
                        list: list,
                        selected,
                    },
                    () => {
                        if (!this.finished) {
                            this.finished = true;
                        }
                    }
                );
                return response;
            }
        });
    }

    handleSortChange(field = "") {
        const {sort} = this.state;

        this.setState(
            {
                sort: Object.assign(
                    {},
                    {
                        field,
                        sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc",
                    }
                ),
            },
            this.handleFetch
        );
    }

    handleSelfBearingsChange() {
        this.setState(
            {
                selfBearings: !this.state.selfBearings,
            },
            this.handleFetch
        );
    }

    handlePagerChange(page = 1) {
        this.setState({pagination: Object.assign({}, this.state.pagination, {page})}, this.handleFetch);
    }

    handleFilterChange(obj = {}) {
        this.finished = false;
        this.setState(
            {
                filter: Object.assign({}, this.state.filter, obj),
                pagination: Object.assign({}, this.state.pagination, {page: 1}),
            },
            this.handleFetch
        );
    }

    searchChange(value) {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }
        this.handleFilterChange({query: value});
    }

    handleAddBearing(bearing) {
        this.setState({addBearing: bearing});
    }

    addBearing(installationPointIds, plotLinesCount = 10) {
        let fetch = false;
        const equipment = _cloneDeep(this.props.equipment);
        const addBearing = {...this.state.addBearing, plot_lines_count: plotLinesCount};

        this.setState({loader: true, inProgress: true, addBearing: null});

        equipment.installationPoints.map((installationPoint) => {
            if (installationPointIds.indexOf(+installationPoint.id) !== -1) {
                installationPoint.bearings.push(addBearing);
                if (installationPoint.id === this.props.installationPointId) {
                    fetch = true;
                }
                BearingApi.add(installationPoint.id, addBearing).then(() => {
                    if (installationPoint.id === this.props.installationPointId) {
                        this.handleFetch();
                        this.props.invalidateEquipment();
                    }
                });
            }
        });
        if (!fetch) {
            this.setState({loader: false, inProgress: false});
        }
    }

    handleDeleteBearing(bearing) {
        this.setState({deleteBearing: bearing});
    }

    handleDeleteSelfBearing(bearing) {
        this.setState({deleteSelfBearing: bearing});
    }

    deleteSelfBearing(installationPointId) {
        let fetch = false;
        const equipment = _cloneDeep(this.props.equipment);
        const deleteSelfBearing = {...this.state.deleteSelfBearing};

        this.setState({loader: true, inProgress: true, deleteSelfBearing: false});

        equipment.installationPoints.map((installationPoint) => {
            if (installationPointId === installationPoint.id) {
                installationPoint.bearings = installationPoint.bearings.filter((bearing) => +bearing.id !== +deleteSelfBearing.id);
                if (installationPoint.id === this.props.installationPointId) {
                    fetch = true;
                }
                SelfBearingApi.delete(deleteSelfBearing.id).then(() => {
                    this.handleDeleteSelfBearing(null);
                    this.handleFetch();
                    this.props.invalidateEquipment();
                });
            }
        });

        if (!fetch) {
            this.setState({loader: false, inProgress: false});
        }
        Toast.success("The bearing has been deleted.");
    }

    handleAddSelfBearing() {
        this.setState({
            addSelfBearing: true,
        });
    }

    handleEditSelfBearing(id) {
        this.setState({
            addSelfBearing: true,
            selfBearingId: id,
        });
    }

    onSubmitSelfBearingModal() {
        const {selfBearingId} = this.state;
        const equipment = _cloneDeep(this.props.equipment);

        this.handleFetch().then((response) => {
            equipment.installationPoints.map((installationPoint) => {
                installationPoint.bearings.map((bearing) => {
                    if (bearing.id == selfBearingId) {
                        response.list.map((updatedBearing) => {
                            if (updatedBearing.id == bearing.id) {
                                installationPoint.bearings = installationPoint.bearings.filter((bearing) => +bearing.id != +selfBearingId);
                                installationPoint.bearings.push(updatedBearing);
                                this.setState({
                                    addSelfBearing: false,
                                    selfBearingId: null,
                                });
                            }
                        });
                    }
                });
            });
            this.props.invalidateEquipment();
        });
    }

    handleCloseSelfBearingModal() {
        this.setState({
            addSelfBearing: false,
            selfBearingId: null,
        });
    }

    deleteBearing(installationPointIds) {
        let fetch = false;
        const equipment = _cloneDeep(this.props.equipment);
        const deleteBearing = {...this.state.deleteBearing};

        this.setState({loader: true, inProgress: true, deleteBearing: null});

        equipment.installationPoints.map((installationPoint) => {
            if (installationPointIds.indexOf(+installationPoint.id) !== -1) {
                installationPoint.bearings = installationPoint.bearings.filter((bearing) => +bearing.id !== +deleteBearing.id);
                if (installationPoint.id === this.props.installationPointId) {
                    fetch = true;
                }
                BearingApi.delete(installationPoint.id, deleteBearing.id).then(() => {
                    if (installationPoint.id === this.props.installationPointId) {
                        this.handleFetch();
                        this.props.invalidateEquipment();
                    }
                });
            }
        });
        if (!fetch) {
            this.setState({loader: false, inProgress: false});
        }
    }

    changePlotLinesCount(changedBearing, count) {
        if (count) {
            if (count < 0) count = 0;
            if (count > 50) count = 50;

            if (this.timeout !== null) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.setState({
                    disabledField: true,
                });
                const equipment = _cloneDeep(this.props.equipment);
                equipment.installationPoints.map((installationPoint) => {
                    if (installationPoint.id === this.props.installationPointId) {
                        installationPoint.bearings.map((bearing) => {
                            if (bearing.id == changedBearing.id) {
                                bearing.plot_lines_count = count;
                            }
                        });
                    }
                });
                this.props.invalidateEquipment();

                BearingApi.updatePlotLinesCount(this.props.installationPointId, changedBearing.id, count).then(() => {
                    this.setState({
                        disabledField: false,
                    });
                });
            }, 300);
        }
    }

    render() {
        const {loader, list, sort, filter, inProgress, selected, selfBearings, deleteSelfBearing, disabledField} = this.state;
        const {auth} = this.props;
        if (!auth.userCan("editSelfBearings")) {
            return <></>;
        }
        return (
            <Modal
                {...this.props}
                title={false}
                withoutFooter={true}
                className="bearing"
                size={"lg"}
                loader={loader}
            >
                <React.Fragment>
                    <div className="row pseudo-modal-header">
                        <div className={"modal-title w-100 col-md-8"}>
                            <span className="text-uppercase">Manage Bearings</span> (selected <span className={"color-primary"}>{selected.length}</span> /
                            3)
                            {auth.userCan("editSelfBearings") && (
                                <span
                                    onClick={this.handleAddSelfBearing}
                                    className="add-installation-point-btn"
                                >
                                    <i className="fa fa-plus-circle" />
                                    <span>Add Custom Bearing</span>
                                </span>
                            )}
                        </div>

                        <div className="right-modal-title-block justify-content-end">
                            <button
                                onClick={this.props.onClose}
                                className={"close pull-right custom-close-btn"}
                                type={"button"}
                                aria-label={"Close"}
                            >
                                <span aria-hidden="true" />
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-7"></div>
                        <div className="col-md-5">
                            <div className="row mb-2">
                                <div className="col-md-6">
                                    <label className="form-checkbox">
                                        <input
                                            type="checkbox"
                                            value={0}
                                            onChange={() => this.handleSelfBearingsChange()}
                                            checked={selfBearings}
                                        />{" "}
                                        Custom Bearings
                                        <span style={selfBearings ? {} : {background: "none"}} />
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <SearchInput
                                        disabled={loader}
                                        query={filter.query}
                                        onChange={this.searchChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Table
                                auth={auth}
                                list={list}
                                sort={sort}
                                query={filter.query}
                                selected={selected}
                                onSortChange={this.handleSortChange}
                                handleAddBearing={this.handleAddBearing}
                                handleDeleteBearing={this.handleDeleteBearing}
                                changePlotLinesCount={this.changePlotLinesCount}
                                handleDeleteSelfBearing={this.handleDeleteSelfBearing}
                                handleEditSelfBearing={this.handleEditSelfBearing}
                                disabledField={disabledField}
                            />
                        </div>
                    </div>
                    {this.state.addBearing && (
                        <ConfirmAdd
                            bearing={this.state.addBearing}
                            equipment={this.props.equipment}
                            installationPointId={this.props.installationPointId}
                            onConfirm={this.addBearing}
                            inProgress={inProgress}
                            onCancel={() => this.handleAddBearing(null)}
                        />
                    )}
                    {this.state.deleteBearing && (
                        <ConfirmDelete
                            bearing={this.state.deleteBearing}
                            equipment={this.props.equipment}
                            installationPointId={this.props.installationPointId}
                            onConfirm={this.deleteBearing}
                            inProgress={inProgress}
                            onCancel={() => this.handleDeleteBearing(null)}
                        />
                    )}
                    {this.state.addSelfBearing && auth.userCan("editSelfBearings") && (
                        <SelfBearingModal
                            bearingId={this.state.selfBearingId}
                            onClose={this.handleCloseSelfBearingModal}
                            onSubmit={this.onSubmitSelfBearingModal}
                        />
                    )}
                    {deleteSelfBearing && (
                        <SweetAlert
                            warning
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnBsStyle="default"
                            btnSize="xs"
                            title="DELETE BEARING"
                            onConfirm={() => this.deleteSelfBearing(this.props.installationPointId)}
                            onCancel={() => this.handleDeleteSelfBearing(null)}
                        >
                            Are you sure you want to delete this bearing?
                        </SweetAlert>
                    )}
                </React.Fragment>
            </Modal>
        );
    }
}

BearingModal.propTypes = {
    installationPointId: PropTypes.number,
    equipment: PropTypes.object,
    onClose: PropTypes.func,
    invalidateEquipment: PropTypes.func,
    drawChart: PropTypes.func,
    auth: PropTypes.object,
};

export default BearingModal;
