import ReadingsApi from "../../../api/reading";
import {useQuery} from "@tanstack/react-query";

const useReadingsType = () => {
    const {
        isLoading,
        isSuccess,
        error,
        data: chartTypes,
    } = useQuery(["ReadingsTypeQuery"], () => ReadingsApi.getTypesList({}, (response) => response.data).then(({chartTypes}) => chartTypes));

    return {
        isLoading,
        isSuccess,
        error,
        chartTypes,
    };
};

export default useReadingsType;
