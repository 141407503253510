import React, {Component} from "react";
import SubscriptionDataHandler from "../network/action-items/subscription/data-handler";
import ActionItemsForm from "./action-items-form";
import PropTypes from "prop-types";
import {
    get as _get,
    keys as _keys
} from "lodash";
import ApiLocation from "../../api/location";
import Helper from "../../helpers/helper";

class ActionItemsWrapper extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            assetTree: [],
            assetTreeLoader: true
        };
    }

    componentDidMount() {
        ApiLocation.list().then(assetTree => {
            assetTree = Helper.transformLocations(assetTree.list || []);
            this.setState({
                assetTree,
                assetTreeLoader: false
            });
        });
    }

    render() {
        const {user, updateUser} = this.props;
        const {assetTree, assetTreeLoader} = this.state;

        if (assetTreeLoader) return "";

        return (
            <React.Fragment>
                <SubscriptionDataHandler
                    user={user}
                    assetTree={assetTree}
                    updateUser={updateUser}
                    emailEvents={_get(this.props.user, "emailEvents")}
                    facilityIds={_keys(_get(this.props.user, "locations", []))}
                    allFacility={!!_get(this.props.user, "all_facilities")}
                >
                    {(
                        loader,
                        data,
                        urgencies,
                        facilities,
                        emailEvents,
                        selectedFacilityId,
                        changeFacility,
                        changeData,
                        onSave,
                        toggleAllForFacility,
                        toggleAll
                    ) =>
                        <ActionItemsForm
                            loader={loader || assetTreeLoader}
                            data={data}
                            urgencies={urgencies}
                            user={user}
                            facilities={facilities}
                            emailEvents={emailEvents}
                            selectedFacilityId={selectedFacilityId}
                            changeFacility={changeFacility}
                            changeData={changeData}
                            onSave={onSave}
                            toggleAllForFacility={toggleAllForFacility}
                            toggleAll={toggleAll}
                        />
                    }
                </SubscriptionDataHandler>
            </React.Fragment>
        );
    }
}

ActionItemsWrapper.propTypes = {
    user: PropTypes.object,
    auth: PropTypes.object,
    updateUser: PropTypes.func
};

export default ActionItemsWrapper;