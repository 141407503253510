import React, {Component} from "react";
import PropTypes from "prop-types";
import BasePopover from "../shared/base-popover";
import Helper from "../../helpers/helper";
import {withGlobalStore} from "../../stores/GlobalStore";

class EmailsPopover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }
    toggle = () => {
        this.setState({show: !this.state.show});
    };

    render() {
        const {show} = this.state;
        const {user, list} = this.props;
        return (
            <React.Fragment>
                <BasePopover
                    {...this.props.popoverProps}
                    isOpen={show}
                    toggle={this.toggle}
                >
                    <div className="timeline">
                        {list.length > 0 &&
                            list.map( item =>
                                <div key={item.id} className="timeline-item timeline-item--custom">
                                    <div className="timeline-item__section">
                                        <div className="timeline-item__border bg-white">
                                            <div className="timeline-item__icon">
                                                {item.status === 1 ?
                                                    <i className="fa fa-info-circle"/>
                                                    :
                                                    <i className="fa fa-exclamation-triangle text-danger"/>
                                                }
                                            </div>
                                        </div>
                                        <span className="timeline-item__datetime timeline-item__datetime--line">
                                            {Helper.dateToUserFormat(item.created_at, user)}
                                        </span>
                                    </div>
                                    <span className="timeline-item__text">
                                        {item.email}
                                        {item.details && <React.Fragment><br/><br/>{item.details.replace(", email frequency once per", " (UTC), email frequency once per")}</React.Fragment>}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                </BasePopover>
            </React.Fragment>
        );
    }
}
EmailsPopover.propTypes = {
    popoverProps: PropTypes.object,
    list: PropTypes.array,
    user: PropTypes.object,
};
export default withGlobalStore(EmailsPopover);