import React, {Component} from "react";
import FormValidator from "../../../helpers/form-validator";
import {ValidationError} from "../../../shared";
import PropTypes from "prop-types";
import EquipmentApi from "../../../api/equipment";
import Loader from "../../../shared/loader/loader";
import get from "lodash/get";
import Helper from "../../../helpers/helper";
import ApiEquipment from "../../../api/equipment";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {withRouter} from "react-router";
import {get as _get, isEmpty as _isEmpty} from "lodash";
import {EQUIPMENT_SERVICE, IDLE_THRESHOLD_TYPES} from "../../../constants/constants";
import EquipmentTypeSelector from "../../../shared/form/EquipmentTypeSelector";
import {Button, ModalFooter} from "reactstrap";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import SelectWrapper from "../../../helpers/select-wrapper";
import InfoTooltip from "../../../shared/infoTooltip/infoTooltip";
import {CheckboxFormGroup} from "../../../shared/formParts/formParts";
import auth from "../../../services/auth";
import SweetAlert from "react-bootstrap-sweetalert";
import Toast from "../../shared/toast";
import {
    MANAGE_FULL_SERVICE_EQUIPMENTS_PERMISSION,
    MANAGE_SELF_SERVICE_EQUIPMENT_PERMISSION,
} from "../../../constants/permissions";

const propTypes = {
    equipment: PropTypes.object,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    showModal: PropTypes.bool,
    customStyles: PropTypes.object,
    children: PropTypes.any,
    title: PropTypes.string,
    submitTitle: PropTypes.string,
    cancelTitle: PropTypes.string,
    updateImages: PropTypes.func,
    handleUpdateImages: PropTypes.func,
    match: PropTypes.object,
    history: PropTypes.object,
    invalidateEquipment: PropTypes.func,
    handleEditEquipmentPhoto: PropTypes.func,
    auth: PropTypes.object,
    chartTypes: PropTypes.object,
    cancelEdit: PropTypes.func,
    onDisabledSubmit: PropTypes.func,
    user: PropTypes.object
};

class Edit extends Component {
    constructor(props) {
        super(props);

        this.hasImpactVue = this.props.equipment?.installationPoints?.some((item) => !!item?.sensor?.supportHfdvue && !!item?.sensor?.is_hfdvue);

        this.rules = [
            {
                field: "location_id",
                method: "isEmpty",
                validWhen: false,
                message: "Asset Tree Branch can't be empty."
            },
            {
                field: "name",
                method: "isEmpty",
                validWhen: false,
                message: "Equipment Name can't be empty."
            },
            {
                field: "idle_threshold",
                method: "isNumeric",
                validWhen: true,
                skipOnEmpty: true,
                message: "Idle threshold must be a number."
            },
            {
                field: "idle_threshold_type",
                method: "isEmpty",
                validWhen: false,
                skipOnEmpty: () => _isEmpty(this.state.data.idle_threshold) && this.state.data.idle_threshold !== 0,
                message: "Threshold type can't be empty."
            },
        ];

        if (this.hasImpactVue) {
            this.rules.push({
                field: "impact_idle_threshold",
                method: "isNumeric",
                validWhen: true,
                skipOnEmpty: true,
                message: "ImpactVue idle threshold must be a number."
            });

            this.rules.push({
                field: "impact_idle_threshold_type",
                method: "isEmpty",
                validWhen: false,
                skipOnEmpty: () => _isEmpty(this.state.data.impact_idle_threshold) && this.state.data.impact_idle_threshold !== 0,
                message: "Threshold type can't be empty."
            });
        }

        if (this.showServiceInput) {
            this.rules.push({
                field: "service",
                method: "isEmpty",
                validWhen: false,
                message: "Service Type cannot be empty."
            });
        }

        if (auth.hasRouteCollectorAccess()) {
            this.rules.push({
                field: "is_route_collector",
                method: (is_route_collector) => [0, 1].includes(is_route_collector),
                validWhen: false,
                message: "Valid values are 0 or 1."
            });
        }

        this.validator = new FormValidator(this.rules);

        this.state = {
            data: {
                location_id: "",
                name: "",
                asset_code: "",
                equipment_type_id: 1,
                amps: "",
                location: "",
                manufacturer: "",
                images: {},
                main_image: 0,
                service: null,
                idle_threshold: null,
                idle_threshold_type: null,
                impact_idle_threshold: null,
                impact_idle_threshold_type: IDLE_THRESHOLD_TYPES.ACC,
                is_route_collector: 0,
                hasActiveRegularAI: false
            },
            loader: true,
            inProgress: false,
            validation: this.validator.valid(),
            equipmentTypes: [],
            disabledSubmit: false,
            showConfirmAlert: false,
            confirmAlertMessage: "",
        };

        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    get showServiceInput() {
        return this.props.auth.isHybrid
            && this.props.auth.userCan(MANAGE_FULL_SERVICE_EQUIPMENTS_PERMISSION)
            && this.props.auth.userCan(MANAGE_SELF_SERVICE_EQUIPMENT_PERMISSION);
    }

    componentDidMount() {
        Promise.all([
            EquipmentApi.get(this.props.equipment.id),
            this.fetchEquipmentTypes(),
        ]).then(([equipment]) => {
            this.setState({
                data: {
                    ...this.state.data,
                    ...equipment,
                    equipment_type_id: _get(equipment, "type.id", ""),
                },
                loader: false
            });
        });
    }

    fetchEquipmentTypes = () => {
        return ApiEquipment
            .getTypes()
            .then(response => {
                if (response) {
                    const {list} = response;
                    const equipmentTypes = list || [];
                    this.setState({equipmentTypes});
                }
            });
    }

    onChange(e) {
        const data = Object.assign({}, this.state.data, {[get(e, "target.name", "")]: get(e, "target.value", "")});

        if (_get(data, ["service"]) !== EQUIPMENT_SERVICE.SELF) {
            data.is_route_collector = 0;
        }

        const validation = this.validator.validate(data, get(e, "target.name", ""));
        this.setState({data, validation});
    }

    drawOptions(list, storage = []) {
        (list || []).map((item) => {
            storage.push({id: item.id, name: "&nbsp;".repeat(2 * item.level) + item.name});
            if ((item.children || []).length) {
                this.drawOptions(item.children, storage);
            }
        });
        return storage;
    }

    handleSubmit() {
        const {cancelEdit} = this.props;

        this.setState(
            {inProgress: true},
            () => {
                const validation = this.validator.validate(this.state.data);

                this.setState({validation}, () => {
                    if (validation.isValid) {
                        ApiEquipment.update(this.props.match.params.equipmentId, this.state.data).then(response => {
                            if (response.status === "ok") {
                                this.props.invalidateEquipment();

                                this.setState({inProgress: false});
                                cancelEdit();
                                Toast.success("The equipment has been updated.");
                            } else if (response.errors) {
                                const validation = this.state.validation;

                                Object.keys(response.errors).map(key => {
                                    validation[key].isValid = false;
                                    validation[key].message = response.errors[key];
                                });

                                this.setState({
                                    validation,
                                    inProgress: false
                                });
                            }
                        });
                    } else {
                        this.setState({inProgress: false});
                    }
                });
            }
        );
    }

    onDisabledSubmit = (value) => {
        this.setState({disabledSubmit: value});
    }

    isDisabledServiceType = (readOnly) => {
        const {inProgress, data} = this.state;
        return (readOnly || inProgress || (data.service === "full" && data.hasActiveRegularAI) || data.is_route_collector);
    }

    blockingMessageServiceType = () => {
        const {data} = this.state;

        if (data.hasActiveRegularAI) {
            return "Active regular action item exists.";
        }

        if (data.is_route_collector) {
            return "This equipment monitored by route collector.";
        }

        return "";
    }


    handleSave () {
        const {data} = this.state;
        const useInRoute = _get(data, "useInRoute", false);

        if (+data.is_route_collector === 0 && useInRoute) {
            this.setState({
                showConfirmAlert: true,
                confirmAlertMessage: "This equipment has been added to a route. Changing the equipment type will result in its exclusion from the route. Are you sure you want to proceed with this change?"
            });
            return;
        }

        this.handleSubmit();
    };


    render() {
        const {inProgress, data, validation, disabledSubmit, loader, showConfirmAlert, confirmAlertMessage} = this.state;
        const {auth, cancelEdit, user} = this.props;
        const readOnly = auth.equipmentViewOnly(data);
        const hasAssociativeSensors = data.installationPoints?.some((item) => !!item?.sensor?.length || !!item?.sensor_id);

        return (
            <React.Fragment>
                {loader
                    ?
                    <Loader/>
                    :
                    <div className={"row"}>
                        <div className="form-group col-md-6">
                            <label>Asset Tree Branch:</label>
                            <div>
                                <CollapseLocationSelect
                                    className={"form-control"}
                                    addClassName={(validation.location_id.isValid || !validation.location_id.message ? "" : " is-invalid")}
                                    selectName={"location_id"}
                                    value={+_get(data, "location_id", null)}
                                    onChange={this.onChange}
                                    emptyOptionLabel={"Select Location"}
                                    allowedLocationIds={_get(user, "allowedLocationIds", [])}
                                    disabled={inProgress || readOnly}
                                    style={{width:"auto"}}
                                />
                                <ValidationError message={validation.location_id.message}/>
                            </div>
                        </div>

                        {this.showServiceInput &&
                            <div className="form-group col-md-6">
                                <label>Service Type:</label>
                                <SelectWrapper
                                    className={(validation.service.isValid || !validation.service.message ? "" : " is-invalid")}
                                    style={{
                                        width:"100%",
                                        display:"block",
                                    }}
                                    name="service"
                                    defaultValue={data.service}
                                    onChange={this.onChange}
                                    disabled={this.isDisabledServiceType(readOnly)}
                                >
                                    {Object.values(EQUIPMENT_SERVICE).map(service => (
                                        <option key={service} value={service}>
                                            {Helper.capitalize(service)}
                                        </option>
                                    ))}
                                </SelectWrapper>
                                <div className="form-control-notice">{this.blockingMessageServiceType()}</div>
                                <ValidationError message={validation.service.message}/>
                            </div>
                        }
                        <div className="form-group col-md-6">
                            <label>Name:</label>
                            <div>
                                <input
                                    className={"form-control" + (validation.name.isValid || !validation.name.message ? "" : " is-invalid")}
                                    type="text"
                                    name="name"
                                    defaultValue={data.name}
                                    onChange={this.onChange}
                                    disabled={inProgress || readOnly}
                                />
                                <ValidationError message={validation.name.message}/>
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Asset Сode:</label>
                            <div>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="asset_code"
                                    defaultValue={data.asset_code}
                                    onChange={this.onChange}
                                    disabled={inProgress || readOnly}
                                />
                            </div>
                        </div>
                        <div className={"col-md-6"}>
                            <EquipmentTypeSelector
                                name={"equipment_type_id"}
                                value={data.equipment_type_id}
                                onChange={this.onChange}
                                disabled={inProgress || readOnly}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <div className={"custom-validation " + (validation.idle_threshold_type.isValid || !validation.idle_threshold_type.message ? "" : " is-invalid")}>
                                <label>Idle Threshold Type:</label>
                                <SelectWrapper className={ (validation.idle_threshold_type.isValid || !validation.idle_threshold_type.message ? "" : " is-invalid")}
                                    style={{
                                        width:"100%",
                                        display:"block",
                                    }}
                                    name="idle_threshold_type"
                                    defaultValue={data.idle_threshold_type}
                                    onChange={this.onChange}
                                    disabled={inProgress || readOnly}
                                >
                                    <option value="">---</option>
                                    {Object.values(IDLE_THRESHOLD_TYPES).map(type => <option key={type} value={type}>{Helper.capitalize(type)}</option>)}
                                </SelectWrapper>
                            </div>
                            <ValidationError message={validation.idle_threshold_type.message}/>
                        </div>
                        <div className="form-group col-md-6">
                            <div className={"custom-validation " + (validation.idle_threshold.isValid || !validation.idle_threshold.message ? "" : " is-invalid")}>
                                <label>Idle Threshold:</label>
                                <InfoTooltip iconClass="blue-tooltip">
                                    RMS readings below the specified idle threshold level will reduce the number of TWF/FFT readings being taken
                                    until the RMS readings exceed the idle threshold. The minimum idle threshold value is 0.
                                </InfoTooltip>
                                <div className={"input-group"}>
                                    <input
                                        className={"form-control " + (validation.idle_threshold.isValid || !validation.idle_threshold.message ? "" : " is-invalid")}
                                        type="number"
                                        name="idle_threshold"
                                        defaultValue={data.idle_threshold}
                                        onChange={this.onChange}
                                        disabled={inProgress || readOnly}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">{Helper.getIdleThresholdMeasure(this.props.chartTypes, data.idle_threshold_type, "N/A")}</span>
                                    </div>
                                </div>
                            </div>
                            <ValidationError message={validation.idle_threshold.message}/>
                        </div>
                        {this.hasImpactVue &&
                            <>
                                <div className="form-group col-md-6">
                                    <div className={"custom-validation " + (validation.impact_idle_threshold_type.isValid || !validation.impact_idle_threshold_type.message ? "" : " is-invalid")}>
                                        <label>ImpactVue Idle Threshold Type:</label>
                                        <SelectWrapper className={ (validation.impact_idle_threshold_type.isValid || !validation.impact_idle_threshold_type.message ? "" : " is-invalid")}
                                                       style={{
                                                           width:"100%",
                                                           display:"block",
                                                       }}
                                                       name="impact_idle_threshold_type"
                                                       defaultValue={data.impact_idle_threshold_type}
                                                       onChange={this.onChange}
                                                       disabled={true}
                                        >
                                            <option value="">---</option>
                                            {Object.values(IDLE_THRESHOLD_TYPES).map(type => <option key={type} value={type}>{Helper.capitalize(type)}</option>)}
                                        </SelectWrapper>
                                    </div>
                                    <ValidationError message={validation.impact_idle_threshold_type.message}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className={"custom-validation " + (validation.impact_idle_threshold.isValid || !validation.impact_idle_threshold.message ? "" : " is-invalid")}>
                                        <label>ImpactVue Idle Threshold:</label>
                                        <InfoTooltip iconClass="blue-tooltip">
                                            RMS readings below the specified idle threshold level will reduce the number of TWF/FFT readings being taken
                                            until the RMS readings exceed the idle threshold. The minimum idle threshold value is 0.
                                        </InfoTooltip>
                                        <div className={"input-group"}>
                                            <input
                                                className={"form-control " + (validation.impact_idle_threshold.isValid || !validation.impact_idle_threshold.message ? "" : " is-invalid")}
                                                type="number"
                                                name="impact_idle_threshold"
                                                defaultValue={data.impact_idle_threshold}
                                                onChange={this.onChange}
                                                disabled={inProgress || readOnly}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{Helper.getIdleThresholdMeasure(this.props.chartTypes, data.impact_idle_threshold_type, "N/A")}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <ValidationError message={validation.impact_idle_threshold.message}/>
                                </div>
                            </>
                        }
                        {(auth.hasRouteCollectorAccess() && data.service === EQUIPMENT_SERVICE.SELF && !hasAssociativeSensors) &&
                            <div className="form-group col-md-6">
                                <div className={"custom-validation " + (validation.is_route_collector.isValid || !validation.is_route_collector.message ? "" : " is-invalid")}>
                                    <div className={"input-group"}>
                                        <CheckboxFormGroup
                                            label={"Monitored by Route Collector"}
                                            name={"is_route_collector"}
                                            value={data.is_route_collector}
                                            onChange={this.onChange}
                                            readOnly={readOnly}
                                        />
                                    </div>
                                </div>
                                <ValidationError message={validation.is_route_collector.message}/>
                            </div>
                        }
                        {!readOnly &&
                            <ModalFooter>
                                <div className="edit-btns d-flex justify-content-end">
                                    <Button onClick={cancelEdit} disabled={disabledSubmit} color={"secondary"} className="btn-sm">Cancel</Button>
                                    <Button
                                        onClick={this.handleSave}
                                        disabled={inProgress || disabledSubmit}
                                        color={"primary"}
                                        className="btn-sm ml-2">
                                        {inProgress
                                            ? <span><i className="fa fa-spinner"></i> Processing</span>
                                            : "Save"
                                        }
                                    </Button>
                                </div>
                            </ModalFooter>
                        }
                    </div>
                }

                {showConfirmAlert && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title=""
                        onConfirm={this.handleSubmit}
                        onCancel={() => this.setState({showConfirmAlert: false})}
                    >
                        {confirmAlertMessage}
                    </SweetAlert>
                )}

            </React.Fragment>
        );
    }
}

Edit.propTypes = propTypes;

export default withRouter(withGlobalStore(Edit));