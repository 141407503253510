import React, {Component} from "react";
import PropTypes from "prop-types";
import CreateNameplateForm from "./create";
import ShowNameplate from "./show";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectNameplateForm from "./select-form";
import {
    get as _get,
} from "lodash";
import NameplateDataHandler from "./nameplate-data-handler";
import Toast from "../../shared/toast";

class NameplateComponent extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            addNew: false,
            onDeleteId: null,
            showCreateNew: false,
            selectedTemplateId: 0,
            selectedNameplateId: 0,
            inProcess: false
        };
    }

    onAddNew = () => {
        this.setState({addNew: true});
    }

    onCancelAddNew = () => {
        this.setState({addNew: false, showCreateNew: false});
    }

    onCreateNew = () => {
        this.setState({showCreateNew: true});
    }

    onCancelCreateNew = () => {
        this.setState({showCreateNew: false});
    }

    onChangeTemplateId = (event) => {
        this.setState({selectedTemplateId: +_get(event, "target.value"), selectedNameplateId: 0});
    }

    onChangeNameplateId = (event) => {
        this.setState({selectedNameplateId: +_get(event, "target.value")});
    }

    onCreated = (nameplate) => {
        const {updateNameplatesList} = this.props;

        updateNameplatesList()
            .then(() => {
                this.sendSave(_get(nameplate, "id"));
            });
    }

    onSave = () => {
        const {selectedNameplateId} = this.state;

        this.sendSave(selectedNameplateId);
    }

    sendSave = (id) => {
        const {onSave, reloadEquipment, updateTemplates} = this.props;

        this.setState({inProcess: true}, () => {
            onSave(id)
                .then((response) => {
                    if (response.status === "ok") {
                        updateTemplates().then(() => {
                            reloadEquipment();
                            this.setState({addNew: false, inProcess: false}, () => {
                                Toast.success("The nameplate has been associated.");
                            });
                        });
                    }
                });
        });

    }

    setOnDelete = (onDeleteId) => {
        this.setState({onDeleteId});
    }

    onDelete = (id) => {
        const {onDelete, reloadEquipment, updateTemplates} = this.props;

        this.setState({inProcess: true}, () => {
            onDelete(id)
                .then((response) => {
                    if (response.status === "ok") {
                        updateTemplates().then(() => {
                            reloadEquipment();
                            this.setState({onDeleteId: null, inProcess: false}, () => {
                                Toast.success("The nameplate has been deleted.");
                            });
                        });
                    }
                });
        });

    }

    render() {
        const {addNew, showCreateNew, onDeleteId, selectedTemplateId, selectedNameplateId, inProcess} = this.state;
        const {templates, nameplates, name, parametersList, updateParametersList, nameplatesList} = this.props;

        return (
            <React.Fragment>
                <div className={"nameplate-component"}>
                    <div className={"nameplate-component-block top-block"}>
                        <div className={"title-nameplate-component"}>{name}</div>
                        {(!nameplates.length && !addNew) &&
                            <span className="add-new-link" onClick={this.onAddNew}>
                                <i className="fa fa-plus-circle"/>
                                <span>Add new</span>
                            </span>
                        }
                    </div>

                    {addNew &&
                        <React.Fragment>
                            {showCreateNew
                                ?
                                <React.Fragment>
                                    <div className={"nameplate-component-block p-0 pb-2"}>
                                        <NameplateDataHandler
                                            onSuccessSave={this.onCreated}
                                            templates={templates}
                                            templateId={selectedTemplateId}
                                        >
                                            {(
                                                data,
                                                formErrors,
                                                inProgress,
                                                addParameter,
                                                deleteParameter,
                                                onChange,
                                                onSave
                                            ) =>
                                                <React.Fragment>
                                                    <CreateNameplateForm
                                                        data={data}
                                                        formErrors={formErrors}
                                                        addParameter={addParameter}
                                                        deleteParameter={deleteParameter}
                                                        onChange={onChange}
                                                        onSave={onSave}

                                                        templates={templates}
                                                        parametersList={parametersList}
                                                        updateParametersList={updateParametersList}

                                                        onSelect={this.onCancelCreateNew}
                                                    />
                                                    <ActionButtons
                                                        onSave={onSave}
                                                        onCancel={this.onCancelAddNew}
                                                        disabled={!selectedTemplateId || inProgress}
                                                    />
                                                </React.Fragment>
                                            }
                                        </NameplateDataHandler>
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className={"nameplate-component-block p-0 pb-2"}>
                                        <SelectNameplateForm
                                            templates={templates}
                                            nameplates={nameplatesList}
                                            selectedNameplateId={selectedNameplateId}
                                            selectedTemplateId={selectedTemplateId}
                                            onChangeTemplateId={this.onChangeTemplateId}
                                            onChangeNameplateId={this.onChangeNameplateId}
                                            onCreateNew={this.onCreateNew}
                                        />
                                        <ActionButtons
                                            onSave={this.onSave}
                                            onCancel={this.onCancelAddNew}
                                            disabled={!selectedNameplateId || inProcess}
                                        />
                                    </div>
                                </React.Fragment>

                            }
                        </React.Fragment>
                    }

                    {!!nameplates.length &&
                        <React.Fragment>
                            {nameplates.map((nameplate, index) =>
                                <ShowNameplate
                                    key={index}
                                    nameplate={nameplate}
                                    onDelete={this.setOnDelete}
                                />
                            )}
                        </React.Fragment>
                    }
                </div>
                {onDeleteId &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title="Delete Nameplate"
                        onConfirm={() => this.onDelete(onDeleteId)}
                        onCancel={() => this.setOnDelete(false)}
                        disabled={inProcess}
                    >
                        Are you sure you want to delete this nameplate?
                    </SweetAlert>
                }
            </React.Fragment>
        );
    }
}

const ActionButtons = (({onSave, onCancel, disabled}) =>
    <React.Fragment>
        <div className={"d-flex justify-content-end buttons-row"}>
            <button
                className="btn-sm btn btn-secondary"
                onClick={onCancel}
            >
                Cancel
            </button>
            <button
                className={"btn-sm btn btn-primary ml-2" + (disabled ? " disabled" : "")}
                onClick={onSave}
                disabled={disabled}
            >
                Save
            </button>
        </div>
    </React.Fragment>
);

ActionButtons.propTypes = {
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    disabled: PropTypes.bool
};

NameplateComponent.propTypes = {
    name: PropTypes.string,
    templates: PropTypes.array,
    nameplates: PropTypes.array,
    nameplatesList: PropTypes.array,
    updateNameplatesList: PropTypes.func,
    reloadEquipment: PropTypes.func,
    updateTemplates: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    parametersList: PropTypes.array,
    updateParametersList: PropTypes.func
};

export default NameplateComponent;