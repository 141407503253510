import React from "react";
import {UncontrolledTooltip} from "reactstrap";
import {useChartStateStoreContext} from "../../../twf/store/hooks/useStoreContext";
import {CHART_TYPE} from "../../../twf/hooks/useChartAbstractFactory";
import {shallow} from "zustand/shallow";
import {useChartRef} from "../../hooks/useChartRef";
import {useLockPoints} from "../../hooks/useLockPoints";

const MaxHarmonicButton = () => {
    const fftCursor = useChartStateStoreContext((state) => state.settings.fftCursor);
    const {chartTypeName, chartModeList} = useChartStateStoreContext((state) => state.factory, shallow);
    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const chartRef = useChartRef();
    const lockPoints = useLockPoints();
    const countHarmonicPoints = useChartStateStoreContext((state) => state.settings.countHarmonicPoints);
    const { setCountHarmonicPoints } = useChartStateStoreContext((state) => state.actions, shallow);

    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    if (fftCursor !== "harmonic" || chartTypeName !== CHART_TYPE.FFT || chartMode !== chartModeList.FFT) {
        return <></>;
    }

    const onChangeHarmonicCount = (e) => {
        const value = +e.target.value;

        if (value < 0) {
            setCountHarmonicPoints(0);
            return;
        }

        if (!/^[0-9]*$/.test(value)) {
            return;
        }
        lockPoints.current = [];
        chartRef.current.chart.isPaused = false;

        setCountHarmonicPoints(value);
    };

    return (
        <div className="pull-left-label k-form__group mr-0">
            <div className="d-inline-block mr-2 k-form__label">
                <label className="frequency-types-link">Max Harmonics:</label>
            </div>
            <div className="d-inline-block mr-2 k-form__control input-group-sm">
                <input
                    id={"count_harm"}
                    className={"form-control"}
                    style={{maxWidth: "50px"}}
                    type="number"
                    name="count_harmonic"
                    value={+countHarmonicPoints}
                    disabled={!!isLoading}
                    onChange={onChangeHarmonicCount}
                />

                {+countHarmonicPoints === 0 && (
                    <UncontrolledTooltip
                        popperClassName="tooltip-md"
                        placement="top"
                        target={"count_harm"}
                    >
                        This value cannot be less than zero.
                    </UncontrolledTooltip>
                )}
            </div>
        </div>
    );
};

export default MaxHarmonicButton;
