import {useMemo} from "react";

const TWF_CHART_DATA_MODE = {
    TWF: "TWF",
    AUTOCORRELATION: "AUTOCORRELATION",
};
const FFT_CHART_DATA_MODE = {
    FFT: "FFT",
    WATERFALL: "WATERFALL",
    STACKED_WATERFALL: "STACKED_WATERFALL",
};
export const useIsTWF = (chartMode) => {
    return useMemo(() => chartMode === TWF_CHART_DATA_MODE.TWF, [chartMode]);
};
export const useIsFFT = (chartMode) => {
    return useMemo(() => chartMode === FFT_CHART_DATA_MODE.FFT, [chartMode]);
};
export const useIsStackWaterFallFFT = (chartMode) => {
    return useMemo(() => chartMode === FFT_CHART_DATA_MODE.STACKED_WATERFALL, [chartMode]);
};
export const useIsWaterFallFFT = (chartMode) => {
    return useMemo(() => chartMode === FFT_CHART_DATA_MODE.WATERFALL, [chartMode]);
};

export const useIsAutoCorrelation = (chartMode) => {
    return useMemo(() => chartMode === TWF_CHART_DATA_MODE.AUTOCORRELATION, [chartMode]);
};
