import PropTypes from "prop-types";
import Helper from "../../helpers/helper";
import {Link} from "react-router-dom";
import React from "react";
import {withGlobalStore} from "../../stores/GlobalStore";
import {
    get as _get
} from "lodash";

const Row = ({route, onDelete, query}) => {
    const isActive = !!Object.keys(route.routeActiveEvent || {}).length;
    const progress = +_get(route, "routeActiveEvent.progress.percentage");
    const color = _get(route, "routeActiveEvent.progress.color");

    return (
        <tr className={isActive ? "green-border" : "gray-border"}>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(route.name, query)}} />
            <td>{route.description}</td>
            <td>{route.created_at}</td>
            <td>{route.created_by}</td>
            <td>
                {isActive
                    ?
                    <>
                        <div className="badge badge-pill mr-2" style={{background: color, color: "#fff", width: "50px"}}>{progress}%</div>
                        <span>Started at {_get(route, "routeActiveEvent.started_at")} by {_get(route, "routeActiveEvent.started_by")}</span>
                    </>
                    :
                    <>-</>
                }
            </td>
            <td>
                <>
                    <Link
                        to={"/routes/edit/" + route.id}
                        className="link link-primary"
                    >
                        <i className="fa fa-pencil-alt"/>
                        <span>Edit</span>
                    </Link>
                    <button className="link link-danger" onClick={() => onDelete(route.id)}>
                        <i className="fa fa-times"/>
                        <span>Delete</span>
                    </button>
                </>
            </td>
        </tr>
    );
};

Row.propTypes = {
    route: PropTypes.object,
    query: PropTypes.string,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    auth: PropTypes.object
};

export default withGlobalStore(Row);