import {useModal} from "react-modal-hook";
import FaultFrequencyForm from "../components/form";
import React from "react";
import {useChartContext} from "../../../pages/chart/highcharts/twf/hooks/chartHooks";
import {useHistory} from "react-router";
import {find as _find} from "lodash";
import {useChartStateStoreContext} from "../../../pages/chart/highcharts/twf/store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useCurrentPointRef} from "../../../pages/chart/highcharts/twf/hooks/useCurrentPointRef";
import {useFaultFrequencyCreateMutation} from "../../../hooks/api/faultFrequency/useFaultFrequencyCreateMutation";
import {useFaultFrequencyQuery} from "../../../hooks/api/faultFrequency/useFaultFrequencyQuery";
import {useFaultFrequencyUpdateMutation} from "../../../hooks/api/faultFrequency/useFaultFrequencyUpdateMutation";
import { useEquipmentByParams } from "../../../hooks/api/equipment/useEquipmentQuery";
import { useSpeedSettings } from "../../../pages/chart/highcharts/twf/hooks/helpers/useSpeedSettings";
import { useUserFftChartFrequency } from "../../../stores/UserLocalSettingsStore";

export const useFaultFrequencyFormModal = () => {
    const {chartProps} = useChartContext();
    const fftFrequencyType = useUserFftChartFrequency();
    const {currentSpeedFrom, currentSpeed, currentRatio} = useSpeedSettings();
    const {selectedPoint, editFaultFrequencyId} = useChartStateStoreContext(
        (state) => state.settings,
        shallow
    );
    const {equipmentItem} = useEquipmentByParams();
    const { toggleFaultFrequency } = useChartStateStoreContext((state) => state.actions, shallow);
    const {faultFrequency} = useFaultFrequencyQuery(equipmentItem.id);
    const history = useHistory();
    const currentPoint = useCurrentPointRef();

    const {mutateAsync: createFaultFrequency} = useFaultFrequencyCreateMutation(equipmentItem.id);
    const {mutateAsync: updateFaultFrequency} = useFaultFrequencyUpdateMutation(equipmentItem.id, editFaultFrequencyId);
    const pointData = _find(equipmentItem.installationPoints, {id: selectedPoint}) || {};

    const onCreate = (data) => {
        return createFaultFrequency(data).then((res) => {
            toggleFaultFrequency(res.result);
        });
    };

    const [showModal, hideModal] = useModal(() => {
        return (
            <FaultFrequencyForm
                equipment={equipmentItem}
                faultFrequencyId={+editFaultFrequencyId}
                faultFrequencies={faultFrequency}
                showModal={true}
                history={history}
                onClose={hideModal}
                onCreate={onCreate}
                updateFaultFrequency={updateFaultFrequency}
                frequency={currentPoint.current?.x}
                frequencyType={fftFrequencyType}
                pointData={pointData}
                currentSpeed={{
                    value: currentSpeed,
                    ratio: currentRatio,
                    from: currentSpeedFrom,
                }}
            />
        );
    }, [chartProps.faultFrequencies, editFaultFrequencyId, currentPoint, currentSpeed, currentRatio, currentSpeedFrom, fftFrequencyType]);

    return [showModal, hideModal];
};
