import React from "react";
import {useChartSelectedRangeStoreActions} from "../../../../../../stores/zustand/ChartSelectedRangeStore";
import {useChartMarksBtns} from "../../hooks/useChartMarksBtns";

export const ChartMarksButtons = () => {
    const {updateRangeToChartMark} = useChartSelectedRangeStoreActions();

    const chartMarksButtons = useChartMarksBtns();

    const toCartMarkLeft = () => {
        if (chartMarksButtons.left.length) updateRangeToChartMark(chartMarksButtons.left[chartMarksButtons.left.length - 1]);
    };

    if (chartMarksButtons.left.length === 0) {
        return <></>;
    }

    return (
        <div
            onClick={toCartMarkLeft}
            className={"chart-mark-buttons"}
        >
            <i
                className="fa fa-angle-left mr-1"
                style={{color: "#46b67f"}}
            />
            <div className="chart-mark-button">
                <i className="fa fa-file" />
                <span>{chartMarksButtons.left.length}</span>
            </div>
        </div>
    );
};
