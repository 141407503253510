import {useQuery} from "@tanstack/react-query";
import ApiEquipment from "../../../api/equipment";
import { EQUIPMENT_API } from "./constants";

const useEquipmentTypes = () => {
    const {
        isLoading,
        isSuccess,
        error,
        data,
        isRefetching
    } = useQuery(
        [EQUIPMENT_API.GET_EQUIPMENT_TYPES],
        () => ApiEquipment.getTypes(),
        {
            select: (response) => response.list
        }
    );

    return {
        isLoading,
        isSuccess,
        isRefetching,
        error,
        data,
    };
};

export default useEquipmentTypes;
