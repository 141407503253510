import React from "react";
import DatePicker3d from "../../../../../components/buttons/datepicker3dButton";
import ChartModeDropdownButton from "../../../../../components/buttons/chartModeDropdownButton";
import ChartModeFullscreenedDropdown from "../../../../../components/buttons/chartModeFullscreenedDropdown";
import ChartHeaderWrapper from "./layouts/ChartHeaderWrapper";
import FullscreenButton from "../../../../../components/buttons/fullscreenButton";
import SeriesMenu from "../../../../../components/buttons/seriesMenu";
import TrendButton from "../../../../../components/buttons/trendButton";
import {useChartStateStoreContext} from "../../../../../store/hooks/useStoreContext";
import {get as _get, isEqual as _isEqual} from "lodash";
import {shallow} from "zustand/shallow";
import {useIsImpactVue} from "../../../../../../hooks/useChartTypes";
import {useCurrentDate} from "../../../../../hooks/helpers/useCurrentDate";
import InterpolationBtn from "../../../../../components/buttons/InterpolationBtn";
import HighpassFilterBtn from "../../../../../components/buttons/HighpassFilterBtn";
import {usePointData} from "../../../../../../hooks/usePointsData";
import GridStakedWaterfallSwitcher from "../../../../../components/buttons/GridStakedWaterfallSwitcher";
import ShowWaterfallZoneSwitcher from "../../../../../components/buttons/ShowWaterfallZoneSwitcher";
import {WaterfallOrderDropdown} from "../../../../../components/buttons/WaterfallOrderDropdown";

const WaterfallHeader = () => {
    const shouldSettingsRerender = (oldState, newState) => {
        return (
            _isEqual(oldState.currentDate, newState.currentDate) &&
            _isEqual(oldState.fullScreenMode, newState.fullScreenMode) &&
            _isEqual(oldState.chartMode, newState.chartMode)
        );
    };

    const {chartType, fullScreenMode, chartMode} = useChartStateStoreContext((state) => state.settings, shouldSettingsRerender);
    const currentDate = useCurrentDate();
    const isImpactVue = useIsImpactVue(chartType);

    const {chartModeList} = useChartStateStoreContext((state) => state.factory, shallow);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);

    const pointData = usePointData(selectedPoint);

    return (
        <ChartHeaderWrapper>
            <div className={"chart-actions-wrapper"}>
                {(+chartType === 9 || +chartType === 11) && (
                    <HighpassFilterBtn
                        installationPointId={+_get(pointData, "id")}
                        fMax={+_get(pointData, "settings.fft_settings.1.fmax") || 1200}
                    />
                )}
                {!isImpactVue && <SeriesMenu />}
                {currentDate && <DatePicker3d />}
                {fullScreenMode ? (
                    <>
                        <ChartModeDropdownButton />
                        <GridStakedWaterfallSwitcher />
                    </>
                ) : (
                    <>
                        <ChartModeFullscreenedDropdown />
                    </>
                )}
                {chartMode === chartModeList.STACKED_WATERFALL && <WaterfallOrderDropdown />}
                <InterpolationBtn installationPointId={+_get(pointData, "id")} />
                {chartMode === chartModeList.WATERFALL && <ShowWaterfallZoneSwitcher />}
            </div>
            <div>
                {chartMode === chartModeList.WATERFALL && <TrendButton />}
                <FullscreenButton />
            </div>
        </ChartHeaderWrapper>
    );
};
export default WaterfallHeader;
