import {useChartSelectedChartTypeStore, useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {filter as _filter, get as _get, sortBy as _sortBy, includes as _includes, pickBy as _pickBy} from "lodash";
import {useSelectedByEquipment} from "../../../stores/zustand/ChartSelectedEquipmentStore";

const TEMPERATURE_CHART_LIST = ["1"];

export const useChartTypesList = () => {
    const chartTypes = useChartTypesStore();

    const selectedChartTypes = useChartSelectedChartTypeStore();
    const {equipmentItem: equipment} = useEquipmentByParams();
    const selectedInstallationPoints = useSelectedByEquipment(equipment.id);

    const showChartTypesImpact = () => {
        if (!selectedInstallationPoints?.length) {
            return false;
        }
        const mappedInstallPoints = equipment.installationPoints.filter((point) => selectedInstallationPoints.includes(point.id));
        return mappedInstallPoints.some((item) => (!!item?.sensor?.supportHfdvue && !!item?.sensor?.is_hfdvue) || equipment?.hasImpact);
    };

    const canUserSelectOnlyTemperature = () => {
        if (!selectedInstallationPoints?.length) {
            return false;
        }
        const mappedInstallPoints = equipment.installationPoints.filter((point) => selectedInstallationPoints.includes(point.id));
        return mappedInstallPoints.every((item) => +item?.sensor?.version_type === 87);
    };

    const getFilteredSelectedCharts = () => {
        const selectedCharts = selectedChartTypes.checked || [];

        const filtered = _filter(selectedCharts, (key) => {
            return _get(chartTypes, key, false) ? checkChartType(chartTypes[key]) : false;
        });

        return filtered || [];
    };

    const getFilteredChartTypes = () => {
        if (canUserSelectOnlyTemperature()) {
            return TEMPERATURE_CHART_LIST;
        }

        const filtered = _pickBy(chartTypes, (chartType) => {
            return checkChartType(chartType);
        });

        return filtered || {};
    };

    const checkChartType = (chartType) => {
        return (
            !(chartType.type === "rpm" && equipment.tachometer === null) &&
            !(chartType.type === "tach" && equipment.tachometer === null) &&
            !(chartType.type === "range" && equipment.existDistanceSensor === false) &&
            !(!showChartTypesImpact() && _includes(["acc_impact", "fftacc_impact", "fftvel_impact", "twfacc_impact", "twfvel_impact", "pkpk_impact"], chartType.alias))
        );
    };

    const selectedCharts = getFilteredSelectedCharts();
    const filteredCharTypes = getFilteredChartTypes();

    const orderList = canUserSelectOnlyTemperature() ? TEMPERATURE_CHART_LIST : Object.keys(filteredCharTypes) || [];

    const sortedTypes = _sortBy(orderList, (value) => {
        const index = selectedChartTypes.list.indexOf(value);
        return index === -1 ? Infinity : index;
    });

    const orderedSelectedCharts = _filter(sortedTypes, (key) => {
        if (canUserSelectOnlyTemperature()) {
            return +key === 1 && _includes(selectedCharts, key);
        }
        return _includes(selectedCharts, key);
    });

    const available = sortedTypes.length - orderedSelectedCharts.length;

    return {orderedSelectedCharts, available, sortedTypes, selectedCharts};
};
