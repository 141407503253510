import s4uChartImg from "../../../../../assets/pic/overmax/S4U.png";
import sm2ChartImg from "../../../../../assets/pic/overmax/SM2-0864.png";
import sm7ChartImg from "../../../../../assets/pic/overmax/SM7-0864.png";
import sv5ChartImg from "../../../../../assets/pic/overmax/SV5.png";
import sm7UltrasonicChartImg from "../../../../../assets/pic/overmax/Ultrasonic_SM7-1150.png";

export const IMAGE_BY_SENSOR_TYPE = {
    19: s4uChartImg,
    33: sm7ChartImg,
    32: sm7UltrasonicChartImg,
    10: sv5ChartImg,
    26: sm2ChartImg,
};
