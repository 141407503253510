/* eslint-disable no-unused-vars */
import React, {createContext, useCallback, useRef, useState} from "react";
import PropTypes from "prop-types";
import {useDragLayer} from "react-dnd";

export const DragContext = createContext();

let count = 0;

function getItemStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
        return {
            display: "none",
        };
    }
    let {x, y} = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}

const layerStyles = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 999,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
};

const DraggableList = ({children, draggedElementRef, name}) => {
    const {isDragging, initialOffset, currentOffset} = useDragLayer((monitor) => ({
        item: {
            type: name,
        },
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    return (
        <>
            <>{children}</>
            <div style={layerStyles}>
                <div
                    style={getItemStyles(initialOffset, currentOffset)}
                    ref={draggedElementRef}
                />
            </div>
        </>
    );
};

DraggableList.propTypes = {
    children: PropTypes.node,
    draggedElementRef: PropTypes.object,
    name: PropTypes.string,
};

export default DraggableList;
