import React from "react";
import PropTypes from "prop-types";
import {readingIntervals} from "../../constants/constants";
import SelectWrapper from "../../helpers/select-wrapper";

const ReadingIntervalSelect = ({name, value, onChange, disabled = false}) =>  {
    return (
        <SelectWrapper
            className={(disabled ? " select-without-arrow" : "")}
            name={name}
            defaultValue={value}
            onChange={onChange}
            disabled={disabled}
        >
            {readingIntervals.map((obj) => (
                <option key={`reading-interval-temp-${obj.name}`} value={obj.id}>{obj.name}</option>
            ))}
        </SelectWrapper>
    );
};


ReadingIntervalSelect.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func
};

export default ReadingIntervalSelect;