import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {UncontrolledTooltip} from "reactstrap";
import {get as _get} from "lodash";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {shallow} from "zustand/shallow";

const OverMaxRpmControl = () => {
    const {equipmentItem: equipment} = useEquipmentByParams();

    const maxRpm = useMemo(() => _get(equipment, ["tachometer", "nodeTriggerSetting", "max_rpm"], null), [equipment]);
    const showOverMax = useChartStateStoreContext((state) => state.settings.showOverMax);
    const {toggleShowOverMax} = useChartStateStoreContext((state) => state.actions, shallow);
    return (
        <span id="showOverMaxRpmControl">
            <span
                className={"alert-link " + (maxRpm === null ? "disabled" : "")}
                onClick={() => (maxRpm !== null ? toggleShowOverMax() : {})}
            >
                <label className="switch">
                    <input
                        readOnly={true}
                        type="checkbox"
                        checked={showOverMax ? "checked" : false}
                    />
                    <span className="slider round" />
                </label>
                Show Over Max RPM
            </span>
            <UncontrolledTooltip target={"#showOverMaxRpmControl"}>{maxRpm === null ? "Max RPM Not Set" : `Max RPM: ${maxRpm}`}</UncontrolledTooltip>
        </span>
    );
};

OverMaxRpmControl.propTypes = {
    maxRpm: PropTypes.number,
    showOverMax: PropTypes.bool,
    dispatch: PropTypes.func,
};

export default OverMaxRpmControl;
