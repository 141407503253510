import React, {Component} from "react";
import {Loader, ValidationError} from "../../../shared";
import SearchInput from "../../shared/search-input";
import PropTypes from "prop-types";
import NameplateParameterSelect from "../../forms/nameplate/template/parameter-select";
import {
    get as _get,
    set as _set,
    includes as _includes
} from "lodash";
import {withGlobalStore} from "../../../stores/GlobalStore";
import NameplateParameterApi from "../../../api/nameplateParameter";
import NameplateTemplateApi from "../../../api/nameplateTemplate";
import Toast from "../../shared/toast";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import { HeaderSimple } from "../../../shared/header";

class NameplateTemplateForm extends Component
{
    constructor(props) {
        super(props);

        this.templateId = this.props.match.params.templateId;

        this.state = {
            title: this.templateId ? "Edit Template" : "Add Template",
            data: {
                name: "",
                parameterIds: []
            },
            breadcrumbs: [
                {name: "Nameplates", link: "/nameplates"},
                {name: "Manage Templates", link: "/nameplates/templates"},
                {name: this.templateId ? "Edit Template" : "Add Template"}
            ],
            loader: true,
            formErrors: {},
            globalSearchString: "",
            inProgress: false,
            parametersList: [],
            addNew: false
        };
    }

    componentDidMount() {
        Promise
            .all([
                this.handleFetchParameters(),
                this.fetchTemplate()
            ])
            .then(() => this.setState({loader: false}));
    }

    handleFetchParameters = () => {
        return NameplateParameterApi
            .list()
            .then((response) => {
                if (response) {
                    const {list = []} = response;
                    this.setState({
                        parametersList: list
                    });
                }
            });
    }

    fetchTemplate = () => {
        if (this.templateId) {
            return NameplateTemplateApi
                .view(this.templateId)
                .then(response => {
                    if (response) {
                        const {template} = response;
                        this.setState({data: template || {}});
                    }
                });
        }
    }

    onAddNew = () => {
        this.setState({addNew: true});
    }

    onCancelAddNew = () => {
        this.setState({addNew: false});
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    onChange = (event) => {
        const data = {...this.state.data};

        const key = _get(event, "target.name", "");
        const val = _get(event, "target.value", "");

        _set(data, key, val);

        this.setState({data});
    }

    handleSubmit = () => {
        return this.templateId ? this.onUpdate() : this.onCreate();
    }

    onCreate = () => {
        const {data} = this.state;

        this.setState({formErrors: {}, inProgress: true}, () => {
            NameplateTemplateApi
                .create(data)
                .then(() => {
                    Toast.success("The template has been created.");
                    this.props.history.push("/nameplates/templates");
                })
                .catch(response => {
                    this.setState({formErrors: response.errors || {}, inProgress: false});
                });
        });
    }

    onUpdate = () => {
        const {data} = this.state;

        this.setState({formErrors: {}, inProgress: true}, () => {
            NameplateTemplateApi
                .update(this.templateId, data)
                .then(() => {
                    Toast.success("The template has been updated.");
                    this.setState({inProgress: false}, () => this.props.history.push("/nameplates/templates"));
                })
                .catch(response => {
                    this.setState({formErrors: response.errors || {}, inProgress: false});
                });
        });
    }

    onChangeParameter = (e) => {
        const parameterIds = [...this.state.data.parameterIds];

        const id = ""+_get(e, "target.value");
        if (id && !_includes(parameterIds, id)) {
            parameterIds.push(id);
        }

        this.setState({addNew: false, data: {...this.state.data, ...{parameterIds}}});
    }

    deleteParameter = (id) => {
        const parameterIds = [...this.state.data.parameterIds];

        const index = parameterIds.indexOf(id);
        if (index !== -1) {
            parameterIds.splice(index, 1);
        }

        this.setState({addNew: false, data: {...this.state.data, ...{parameterIds}}});
    }

    render() {
        const {
            data,
            breadcrumbs,
            loader,
            globalSearchString,
            title,
            parametersList,
            addNew,
            formErrors,
            inProgress
        } = this.state;

        return (
            <React.Fragment>
                 <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />

                <div className="subheader">
                    <div className="subheader-title">{title}</div>
                </div>
                {loader
                    ?
                    <div className="loader-fullscreen"><Loader/></div>
                    :
                    <div className="block">
                        <div className="block-body nameplate-form-wrapper">
                            <div className={"nameplate-component-block"}>
                                <React.Fragment>
                                    <div className={"row mt-2"}>
                                        <div className={"col-md-6 mb-2"}>
                                            <strong>Template Name</strong>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <div className={"form-group mb-0"}>
                                                <input
                                                    className={"form-control" + (_get(formErrors, "name") ? " is-invalid" : "")}
                                                    name={"name"}
                                                    value={_get(data, "name")}
                                                    onChange={this.onChange}
                                                />
                                                <ValidationError message={_get(formErrors, "name")}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"mt-3" + (_get(formErrors, "parameters") ? " is-invalid" : "")}>
                                        <div className={"nameplate-template-table"}>
                                            {parametersList.map((parameter, index) =>
                                                <>
                                                    {_includes(_get(data, "parameterIds", []), ""+parameter.id) &&
                                                        <div key={index} className={"template-row position-relative"}>
                                                            <div className={"template-col"}>
                                                                <strong>{parameter.name}</strong>
                                                            </div>
                                                            <button
                                                                className={"link link-danger remove"}
                                                                style={{fontSize: "17px", position: "absolute"}}
                                                                onClick={() => this.deleteParameter(""+parameter.id)}
                                                                title={"Remove"}
                                                            >
                                                                <i className="fa fa-times-circle"/>
                                                            </button>
                                                        </div>
                                                    }
                                                </>
                                            )}
                                            {addNew &&
                                            <div className={"template-row new w-50 position-relative"}>
                                                <div className={"template-col w-100"}>
                                                    <NameplateParameterSelect
                                                        parameters={parametersList}
                                                        onChange={this.onChangeParameter}
                                                        updateParameters={this.handleFetchParameters}
                                                    />
                                                </div>
                                                <button
                                                    className={"link link-danger remove"}
                                                    style={{fontSize: "17px", position: "absolute"}}
                                                    onClick={() => this.onCancelAddNew()}
                                                    title={"Remove"}
                                                >
                                                    <i className="fa fa-times-circle"/>
                                                </button>
                                            </div>
                                            }
                                        </div>
                                        <ValidationError message={_get(formErrors, "parameters")}/>
                                    </div>
                                    {!addNew &&
                                    <div className="add-new-link mt-4 ml-0" onClick={this.onAddNew}>
                                        <i className="fa fa-plus-circle"/>
                                        <span>Add Parameter</span>
                                    </div>
                                    }
                                </React.Fragment>
                            </div>
                            <div className="text-right">
                                <Link to="/nameplates/templates" className="btn btn-sm btn-secondary">Cancel</Link>
                                <Button color="primary" size="sm" className="ml-2" disabled={inProgress || addNew} onClick={this.handleSubmit}>Save</Button>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item ml-2">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

NameplateTemplateForm.propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
};

export default withGlobalStore(NameplateTemplateForm);