import React, {Component} from "react";
import PropTypes from "prop-types";
import ApiSearch from "../../api/search";
import AllListItemsTab from "./allListItemsTab";
import InstallationPointsTab from "./installationPointsTab";
import EquipmentsTab from "./equipmentTab";
import LocationsTab from "./locationsTab";
import NodeSerialTab from "./nodeSerialTab";
import SensorHexTab from "./sensorHexTab";
import Helper from "../../helpers/helper";
import {find as _find, first, get as _get, sumBy as _sumBy} from "lodash";

import "./searchPage.css";
import {Loader} from "../../shared";
import GatewayTab from "./gatewayTab";
import RouterTab from "./routerTab";
import MoteTab from "./moteTab";
import {HeaderSimple} from "../../shared/header";

const tabs = [
    {key: "All", name: "All", icon: false, component: AllListItemsTab},
    {key: "Locations", name: "Asset Tree Branches", icon: false, component: LocationsTab},
    {key: "Equipments", name: "Equipment", icon: false, component: EquipmentsTab},
    {key: "InstallationPoints", name: "Installation points", icon: false, component: InstallationPointsTab},
    {key: "Gateway", name: "Gateways", icon: false, component: GatewayTab},
    {key: "Router", name: "Repeaters", icon: false, component: RouterTab},
    {key: "Node", name: "Nodes", icon: false, component: NodeSerialTab},
    {key: "Sensor", name: "Tethered sensors", icon: false, component: SensorHexTab},
    {key: "Mote", name: "Sensor motes", icon: false, component: MoteTab},
];

const getHashParams = () => {
    const searchParams = Helper.getHashParams();
    searchParams.query = searchParams.query || "";
    searchParams.isGlobal = !!searchParams.isGlobal;
    searchParams.searchTypeList = searchParams.searchTypeList && searchParams.searchTypeList.length ? searchParams.searchTypeList.split(",") : [];
    return searchParams;
};

class Search extends Component {
    constructor(props) {
        super(props);

        const {query} = getHashParams();

        this.state = {
            breadcrumbs: [{name: "Search"}],
            loader: true,
            query,
            list: {},
        };

        this.fetch = this.fetch.bind(this);
        this.onQueryChange = this.onQueryChange.bind(this);
        this.onOptionChange = this.onOptionChange.bind(this);
    }

    componentDidMount() {
        this.fetch();
    }

    checkData = (list) => {
        if (list) {
            let activeList = first(Object.keys(list));

            if (activeList) {
                this.props.history.push(Helper.setHashParams({tab: activeList}));
            }
        }
    };

    fetch() {
        const {query, searchTypeList} = getHashParams();

        ApiSearch.query({query, options: searchTypeList}).then(({list}) =>
            this.setState({list: {...list, All: {...list}}, loader: false}, () => this.checkData(list))
        );
    }

    componentDidUpdate() {
        const {query, isGlobal} = getHashParams();

        if (isGlobal) {
            this.setState({query}, this.fetch);
            this.props.history.push(Helper.deleteHashParams(["isGlobal"]));
        }
    }

    onQueryChange(query = "") {
        this.setState({query});
    }

    onOptionChange(e) {
        const {value, checked} = e.target;
        let {searchTypeList, tab} = getHashParams();
        const index = searchTypeList.indexOf(value);

        if (checked && index === -1) {
            searchTypeList.push(value);
        } else if (index !== -1) {
            searchTypeList = searchTypeList.slice(0, index).concat(searchTypeList.slice(index + 1));
        }

        if (!tab || searchTypeList.indexOf(tab) === -1) {
            tab = searchTypeList[0];
        }

        this.props.history.push(Helper.setHashParams({searchTypeList, tab}));
        this.fetch();
    }

    filterIsEnabled = (filterList, filterName) => {
        return _get(filterList, filterName, false) === false;
    };

    getCountListItems = (list, tabName) => {
        let items = _get(list, tabName, []);
        let countItems = items.length;
        const {searchTypeList} = getHashParams();

        if (tabName === "All") {
            countItems = 0;
            countItems = _sumBy(Object.keys(items), (itemKey) => {
                if (this.filterIsEnabled(searchTypeList, itemKey)) {
                    return countItems + items[itemKey].length;
                }
                return countItems;
            });
        }

        return countItems;
    };

    render() {
        const {breadcrumbs, loader, query, list = {}} = this.state;
        const {history} = this.props;
        const searchParams = getHashParams();

        const activePane =
            searchParams.tab && searchParams.searchTypeList.indexOf(searchParams.tab) !== -1 ? searchParams.tab : searchParams.searchTypeList[0];
        let activeTab = "";

        if (searchParams.searchTypeList.length) {
            const TabComponent = (_find(tabs, ["key", activePane]) || {}).component;
            if (TabComponent) {
                activeTab = (
                    <TabComponent
                        query={searchParams.query}
                        list={list[activePane]}
                    />
                );
            }
        }

        if (!activeTab) {
            activeTab = (
                <div className="row">
                    <div className="col">
                        <span
                            className="text-center text-info"
                            style={{display: "block"}}
                        >
                            You not selected any search type.
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <div className="container-fluid">
                <HeaderSimple
                    breadcrumbs={breadcrumbs}
                    hideGlobalSearch
                />
                {loader ? (
                    <div className="loader-fullscreen">
                        <Loader />
                    </div>
                ) : (
                    <div className="block">
                        <div className="block-header">
                            <div className="block-label">
                                <h3 className="block-title">{searchParams.query.length ? `Search for - ${searchParams.query}` : "No search request"}</h3>
                            </div>
                        </div>
                        <div className="block-body">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    history.push(Helper.setHashParams({query}));
                                    this.fetch();
                                }}
                            >
                                <div className="row justify-content-between flex-wrap align-items-center mb-3">
                                    <div className="form-group col-xl-4 mb-0">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control"
                                                value={query}
                                                onChange={(e) => this.onQueryChange((e.target || {}).value)}
                                                placeholder="Search for..."
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    type="submit"
                                                    onClick={() => {
                                                        history.push(Helper.setHashParams({query}));
                                                        this.fetch();
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-search pr-0"
                                                        aria-hidden="true"
                                                    ></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mt-3 mt-xl-0 col-xl-7">
                                        <div className="form-list form-list--row flex-wrap">
                                            {tabs.map((tab) => (
                                                <label
                                                    key={tab.key}
                                                    className="form-checkbox blue-checkbox mr-2 my-1"
                                                >
                                                    <input
                                                        onChange={this.onOptionChange}
                                                        type="checkbox"
                                                        checked={searchParams.searchTypeList.indexOf(tab.key) !== -1}
                                                        value={tab.key}
                                                    />{" "}
                                                    {tab.name}
                                                    <span />
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="row">
                                <div className="col-xl-12">
                                    <ul className="nav nav-tabs nav-tabs-line custom-search-tabs">
                                        {tabs.map((tab) => {
                                            if (searchParams.searchTypeList.indexOf(tab.key) !== -1) {
                                                return (
                                                    <li
                                                        className="nav-item"
                                                        key={tab.key}
                                                    >
                                                        <a
                                                            className={"nav-link" + (activePane === tab.key ? " active" : "")}
                                                            onClick={() => history.push(Helper.setHashParams({tab: tab.key}))}
                                                        >
                                                            {tab.icon && <i className={tab.icon} />}
                                                            {` ${tab.name} `}
                                                            <span className="badge badge-pill badge-info">{this.getCountListItems(list, tab.key)}</span>
                                                        </a>
                                                    </li>
                                                );
                                            }
                                        })}
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active">{activeTab}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

Search.propTypes = {
    history: PropTypes.object,
};

export default Search;
