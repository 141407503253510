import React, {useEffect, useState} from "react";
import FormValidator from "../../../helpers/form-validator";
import PropTypes from "prop-types";
import SelfBearingApi from "../../../api/selfBearing";
import {get as _get, set as _set} from "lodash";
import {Loader} from "../../../shared";
import ValidationError from "../../../layouts/ValidationError/ValidationError";
import {Modal} from "../../../shared";
import Toast from "../../../pages/shared/toast";
import SweetAlert from "react-bootstrap-sweetalert";

const propTypes = {
    bearingId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string]),
    title: PropTypes.string,
    bearing: PropTypes.object,
    match: PropTypes.object,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

const rules = [
    {
        field: "mfr",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "balls_rollers",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "ftf",
        method: "isFloat",
        options: {min: 0},
        validWhen: true,
        skipOnEmpty: false,
        message: "FTF Value should be a float value and not less than 0.",
    },
    {
        field: "bsf",
        method: "isFloat",
        options: {min: 0},
        validWhen: true,
        skipOnEmpty: false,
        message: "BSF Value should be a float value and not less than 0.",
    },
    {
        field: "bpfo",
        method: "isFloat",
        options: {min: 0},
        validWhen: true,
        skipOnEmpty: false,
        message: "BPFO Value should be a float value and not less than 0.",
    },
    {
        field: "bpfi",
        method: "isFloat",
        options: {min: 0},
        validWhen: true,
        skipOnEmpty: false,
        message: "BPFI Value should be a float value and not less than 0.",
    },
];

const config = {
    mfr: "",
    name: "",
    balls_rollers: "",
    ftf: "",
    bsf: "",
    bpfo: "",
    bpfi: "",
};

const SelfBearingModal = (props) => {
    const validator = new FormValidator(rules);
    const title = props.bearingId ? "Edit Custom Bearing" : "Add Custom Bearing";
    const bearingId = props.bearingId || "";

    const [data, setData] = useState(config);
    const [initialFormValues, setInitialFormValues] = useState(config);
    const [loader, setLoader] = useState(true);
    const [inProgress, setInProgress] = useState(false);
    const [validation, setValidation] = useState(validator.valid());
    const [confirmCancel, setConfirmCancel] = useState(false);

    const onChange = (e) => {
        const newData = {...data};
        _set(newData, _get(e, "target.name", ""), _get(e, "target.value", ""));
        setData(newData);
        setValidation(validator.validate(data, _get(e, "target.name", "")));
    };

    const handleSubmitAddBearing = (data) => {
        return SelfBearingApi.add({data}).then(() => {
            props.onClose();
            Toast.success("The bearing has been added.");
        });
    };

    const handleCancel = () => {
        if (JSON.stringify(data) === JSON.stringify(initialFormValues)) {
            props.onClose();
        } else {
            setConfirmCancel(true);
        }
    };

    const handleSubmitEditBearing = (data) => {
        return SelfBearingApi.update(bearingId, {data}).then(() => {
            props.onSubmit();
            props.onClose();
            if (JSON.stringify(data) === JSON.stringify(initialFormValues)) {
                Toast.success("No changes were made.");
            } else {
                Toast.success("The bearing has been updated.");
            }
        });
    };

    const handleSubmit = () => {
        const currentValidation = validator.validate(data);

        setInProgress(true);
        setValidation(currentValidation);

        if (currentValidation.isValid) {
            const submitAction = bearingId ? handleSubmitEditBearing : handleSubmitAddBearing;
            submitAction(data).catch((err) => {
                if (err.errors) {
                    const newValidation = {...validation};
                    Object.keys(err.errors).forEach((key) => {
                        newValidation[key].isValid = false;
                        newValidation[key].message = err.errors[key];
                    });
                    setValidation(newValidation);
                }
                setInProgress(false);
            });
        } else {
            setInProgress(false);
        }
    };

    useEffect(() => {
        if (bearingId) {
            SelfBearingApi.get(props.bearingId).then((response) => {
                if (response.status === "ok") {
                    const bearing = response.bearing;
                    const newData = {
                        mfr: bearing.mfr,
                        name: bearing.name,
                        balls_rollers: bearing.balls_rollers,
                        ftf: bearing.ftf,
                        bsf: bearing.bsf,
                        bpfo: bearing.bpfo,
                        bpfi: bearing.bpfi,
                    };
                    setLoader(false);
                    setData(newData);
                    setInitialFormValues(newData);
                }
            });
        } else {
            setLoader(false);
        }
    }, []);

    return (
        <Modal
            showModal={true}
            title={title}
            size={"lg"}
            loader={loader}
            onClose={handleCancel}
            onSubmit={handleSubmit}
            disableSubmit={inProgress}
            submitTitle="Save"
        >
            <div style={{marginBottom: 24}}>
                {loader ? (
                    <div className="loader-fullscreen">
                        <Loader />
                    </div>
                ) : (
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="text-right form-label">Name:</label>
                                    <div className="">
                                        <input
                                            className={"form-control" + (validation.name.isValid || !validation.name.message ? "" : " is-invalid")}
                                            type="text"
                                            name="name"
                                            defaultValue={data.name}
                                            onChange={onChange}
                                            disabled={inProgress}
                                        />
                                        <ValidationError message={validation.name.message} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="text-right form-label">Manufacturer:</label>
                                    <div className="">
                                        <input
                                            className={"form-control" + (validation.mfr.isValid || !validation.mfr.message ? "" : " is-invalid")}
                                            type="text"
                                            name="mfr"
                                            defaultValue={data.mfr}
                                            onChange={onChange}
                                            disabled={inProgress}
                                        />
                                        <ValidationError message={validation.mfr.message} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="text-right form-label">Balls/Rollers:</label>
                                    <div className="">
                                        <input
                                            className={
                                                "form-control" +
                                                (validation.balls_rollers.isValid || !validation.balls_rollers.message ? "" : " is-invalid")
                                            }
                                            type="text"
                                            name="balls_rollers"
                                            defaultValue={data.balls_rollers}
                                            onChange={onChange}
                                            disabled={inProgress}
                                        />
                                        <ValidationError message={validation.balls_rollers.message} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="text-right form-label">FTF:</label>
                                    <div className="">
                                        <input
                                            className={"form-control" + (validation.ftf.isValid || !validation.ftf.message ? "" : " is-invalid")}
                                            type="text"
                                            name="ftf"
                                            defaultValue={data.ftf}
                                            onChange={onChange}
                                            disabled={inProgress}
                                        />
                                        <ValidationError message={validation.ftf.message} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="text-right form-label">BSF:</label>
                                    <div className="">
                                        <input
                                            className={"form-control" + (validation.bsf.isValid || !validation.bsf.message ? "" : " is-invalid")}
                                            type="text"
                                            name="bsf"
                                            defaultValue={data.bsf}
                                            onChange={onChange}
                                            disabled={inProgress}
                                        />
                                        <ValidationError message={validation.bsf.message} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="text-right form-label">BPFO:</label>
                                    <div className="">
                                        <input
                                            className={"form-control" + (validation.bpfo.isValid || !validation.bpfo.message ? "" : " is-invalid")}
                                            type="text"
                                            name="bpfo"
                                            defaultValue={data.bpfo}
                                            onChange={onChange}
                                            disabled={inProgress}
                                        />
                                        <ValidationError message={validation.bpfo.message} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="text-right form-label">BPFI:</label>
                                    <div className="">
                                        <input
                                            className={"form-control" + (validation.bpfi.isValid || !validation.bpfi.message ? "" : " is-invalid")}
                                            type="text"
                                            name="bpfi"
                                            defaultValue={data.bpfi}
                                            onChange={onChange}
                                            disabled={inProgress}
                                        />
                                        <ValidationError message={validation.bpfi.message} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {confirmCancel && (
                            <SweetAlert
                                warning
                                showCancel
                                cancelBtnText="Cancel"
                                confirmBtnText="Yes"
                                cancelBtnBsStyle="default"
                                btnSize="xs"
                                title=""
                                onConfirm={props.onClose}
                                onCancel={() => setConfirmCancel(false)}
                            >
                                Are you sure you want to navigate away from this page? If you proceed, ALL of your changes will be lost. Click Yes to
                                continue, or click Cancel to stay on the current page
                            </SweetAlert>
                        )}
                    </div>
                )}
            </div>
        </Modal>
    );
};

SelfBearingModal.propTypes = propTypes;

export default SelfBearingModal;
