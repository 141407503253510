export const GENERIC_TRIGGER_TYPE = "generic";
export const WOS_TRIGGER_TYPE = "wos";

export const initialState = {
    toggles: {
        loader: true,
        isEdit: false,
        [WOS_TRIGGER_TYPE]: false,
        [GENERIC_TRIGGER_TYPE]: false,
    },
    data: {},
    form: {},
    restriction: {
        wos_sensor_types: [],
        wos_sensor_version: "",
        generic_sensor_types: [],
    }
};

export const wosInitialState = {
    client_type: "0",
    data_rate: 0,
    delay: 0,
    installation_point_id: null,
    samples: 2,
    threshold: 20
};

export const genericInitialState = {
    client_type: "0",
    installation_point_id: null,
    minimum_period: 3600,
    trigger_delay: 0,
    trigger_edge: "1"
};