import React, {useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import SpeedNotSetLabel from "../../../../components/speedNotSetLabel";
import {FREQUENCY_TYPES} from "../../../../../../constants/constants";
import FrequencyConverter from "../../../../../../helpers/frequency-converter";
import auth from "../../../../../../services/auth";
import {isEqual as _isEqual} from "lodash";
import {useFaultFrequencyListModal} from "../../../../../../modals/faultFrequencies/hooks/useFaultFrequencyListModal";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useInstallPointFaultFrequency} from "../../../../../../hooks/api/faultFrequency/useFaultFrequencyQuery";
import {shallow} from "zustand/shallow";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {usePointData} from "../../../hooks/usePointsData";
import {useSpeedSettings} from "../../hooks/helpers/useSpeedSettings";
import {useSetSpeedModal} from "../../../../../../modals/installationPoint/hooks/useSetSpeedModal";

const FaultFrequencyButton = () => {
    const {equipmentItem} = useEquipmentByParams();
    const shouldSettingsRerender = (oldState, newState) => {
        const isRerender =
            _isEqual(oldState.currentSpeed, newState.currentSpeed) &&
            _isEqual(oldState.currentSpeedFrom, newState.currentSpeedFrom) &&
            _isEqual(oldState.selectedPoint, newState.selectedPoint) &&
            _isEqual(oldState.selectedFaultFrequencies, newState.selectedFaultFrequencies);
        return isRerender;
    };

    const settings = useChartStateStoreContext((state) => state.settings, shouldSettingsRerender);
    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    const {selectedPoint, selectedFaultFrequencies} = settings;
    const {currentSpeed, currentSpeedFrom} = useSpeedSettings();
    const {filteredFaultFrequencies, isSuccess} = useInstallPointFaultFrequency(equipmentItem.id, selectedPoint);

    const [showFaultFreequencyListModal] = useFaultFrequencyListModal();
    const {toggleFaultFrequency, setSpeedModal} = useChartStateStoreContext((state) => state.actions, shallow);
    const [showSetSpeedModal] = useSetSpeedModal();

    const handleSetSpeedModal = (type) => {
        setSpeedModal({show: true, type});
        showSetSpeedModal();
    };

    const showFaultFrequencyListModal = () => {
        showFaultFreequencyListModal();
    };

    const pointData = usePointData(selectedPoint);

    const [isOpen, setIsOpen] = useState(false);
    return (
        <Dropdown
            size="sm"
            className="fault-frequency-dropdown"
            isOpen={isOpen}
            disabled={!!isLoading}
            toggle={() => setIsOpen((prevState) => !prevState)}
        >
            <DropdownToggle
                caret
                className="btn-default"
            >
                Fault frequencies
            </DropdownToggle>
            <DropdownMenu>
                {auth.userCan("editSelfBearings") && currentSpeed === 0 && !isLoading && isSuccess && (
                    <React.Fragment>
                        <DropdownItem>
                            <SpeedNotSetLabel
                                className="mx-auto my-0"
                                onClick={() => handleSetSpeedModal("manually")}
                                equipment={equipmentItem}
                                pointData={pointData}
                                currentSpeed={currentSpeed}
                                currentSpeedFrom={currentSpeedFrom}
                            />
                        </DropdownItem>
                        <DropdownItem divider />
                    </React.Fragment>
                )}
                {filteredFaultFrequencies.length ? (
                    filteredFaultFrequencies.map((faultFrequency) => (
                        <DropdownItem
                            key={faultFrequency.id}
                            className="bearing-dropdown-item"
                            onClick={() => toggleFaultFrequency(faultFrequency)}
                            toggle={false}
                            disabled={currentSpeed === 0 && faultFrequency.frequency_type === FREQUENCY_TYPES.ORDERS}
                        >
                            <i className={`fa fa-eye${selectedFaultFrequencies.indexOf(faultFrequency.id) >= 0 ? "" : "-slash"}`} />
                            <span className="label badge fault-frequency-color">{faultFrequency.name}</span>
                            <span> - </span>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: FrequencyConverter.fromType(faultFrequency.frequency_type, faultFrequency.frequency, currentSpeed).format(2, {
                                        withX: true,
                                    }),
                                }}
                            />
                        </DropdownItem>
                    ))
                ) : (
                    <DropdownItem>
                        <i className="cursor-default">No Fault Frequencies Added</i>
                    </DropdownItem>
                )}
                {auth.userCan("editSelfBearings") && (
                    <React.Fragment>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => showFaultFrequencyListModal()}>Manage Fault Frequencies...</DropdownItem>
                    </React.Fragment>
                )}
            </DropdownMenu>
        </Dropdown>
    );
};

export default FaultFrequencyButton;
