import React, {useEffect, useReducer} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {Loader, Pager} from "../../../shared";
import {Link} from "react-router-dom";
import {ResetSortButton} from "../../shared/resetSortButton";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import UserApi from "../../../api/user";
import DeviceAlertGroupsApi from "../../../api/deviceAlertGroups";
import {ALARM_EMAIL_FREQUENCY} from "../../../constants/constants";
import {find as _find} from "lodash";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import {customStyles} from "../../../helpers/select";
import { HeaderSimple } from "../../../shared/header";

const title = "Device Alert Groups";
const breadcrumbs = [{name: "Device Alert Groups"}];

const initialState = {
    list: [],
    users: [],
    filter: {},
    sort: {
        column: "",
        order: "asc"
    },
    pagination: {
        page: 1,
        pages: 1,
        perpage: 10,
        total: 0
    },
    removeId: undefined,
    loader: true
};

const reducer = (state, action) => ({...state, ...action});

const DeviceAlertGroups = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {list, sort, filter, pagination, users, removeId, loader} = state;

    useEffect(() => {
        Promise.all([
            UserApi.getSelfUsers({
                query: {
                    filter: {status: 1},
                    excludeWCDUsers: 1
                }
            }),
        ]).then(([selfUsers]) => {
            dispatch({users: selfUsers.users || []});
        });
    }, []);

    useEffect(() => {
        if (loader) {
            DeviceAlertGroupsApi.list({sort, filter, pagination}).then(({list, meta}) =>
                dispatch({list, pagination: {...pagination, ...meta}, loader: false})
            );
        }
    }, [loader]);

    const updateFilter = (key, value) => dispatch({
        loader: true,
        filter: {...filter, [key]: value},
        pagination: {...pagination, page: 1}
    });

    const updateSort = column => dispatch({
        loader: true,
        sort: {column, order: column && column === sort.column ? (sort.order === "asc" ? "desc" : "asc") : "asc"}
    });

    const updatePager = (page = 1) => dispatch({
        loader: true,
        pagination: {...pagination, page}
    });

    return (
        <div className="alert-groups">
            <HeaderSimple breadcrumbs={breadcrumbs} hideGlobalSearch />
            <div className="subheader">
                <div className="subheader-title">{title}</div>
                <div className="subheader-controls">
                    <div className="subheader-toolbar">
                        <Link
                            to="/device-alert-groups/add"
                            className="btn btn-primary btn-sm btn-elevate mr-4"
                        >
                            <i className="fa fa-plus-circle"/>
                            Add Group
                        </Link>
                    </div>
                </div>
            </div>
            <div>
                <div className="block">
                    <div className="block-header block-header-sticky">
                        <div className="block-label">
                            <div className="form-list form-list--row">
                                <div className="form-group form-group--inline">
                                    <div className="form-label">
                                        <label><b>Alert Frequency:</b></label>
                                    </div>
                                    <Select
                                        className="form-control-select form-control-select--lg"
                                        isSearchable={false}
                                        styles={customStyles}
                                        value={ALARM_EMAIL_FREQUENCY[filter.frequency] ? {value: filter.frequency, label: ALARM_EMAIL_FREQUENCY[filter.frequency]} : ""}
                                        options={Object.keys(ALARM_EMAIL_FREQUENCY).map(key => ({value: key, label: ALARM_EMAIL_FREQUENCY[key]}))}
                                        onChange={({value}) => updateFilter("frequency", value)}
                                    />
                                </div>
                                {!!Object.keys(filter).length &&
                                    <div className="form-group form-group--inline">
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => dispatch({filter: {}, loader: true})}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                }
                            </div>
                            {pagination.pages > 1 &&
                                <div id="top-pagination-block" className="block-pagination">
                                    <Pager
                                        page={pagination.page}
                                        pages={pagination.pages}
                                        onPagerChange={updatePager}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div className="block-body">
                        {loader
                            ? <div className="loader-fullscreen"><Loader/></div>
                            : <Table
                                list={list}
                                sort={sort}
                                users={users}
                                updateSort={updateSort}
                                dispatch={dispatch}
                            />
                        }
                    </div>
                </div>
            </div>
            {removeId &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title="DELETE GROUP"
                    onConfirm={() => DeviceAlertGroupsApi.delete(removeId).then(() => dispatch({removeId: undefined, loader: true}))}
                    onCancel={() => dispatch({removeId: undefined})}
                >
                    Are you sure you want to delete this group?
                </SweetAlert>
            }
        </div>
    );
};

DeviceAlertGroups.propTypes = {
    auth: PropTypes.object,
    match: PropTypes.object
};

const headersList = {
    "name": {title: "Group Name", sort: true},
    "participants": {title: "Group Participants", sort: true},
    "frequency": {title: "Alert Frequency", sort: true},
    "actions": {title: "Actions", sort: false, additionalClasses: "table-buttons-th", component: ResetSortButton}
};

const Table = ({list, sort, users, updateSort, dispatch}) => {
    return (
        <div>
            <table className="table table-hover tl-fixed">
                <thead>
                    <tr>
                        {Object.keys(headersList).map(key => {
                            let component = "";
                            const RowComponent = (headersList[key] || {}).component;
                            if (RowComponent) {
                                component = <RowComponent sort={sort} resetSort={() => updateSort("")}/>;
                            }
                            return (
                                Object.keys(headersList).length && headersList[key].sort
                                    ?
                                    <th
                                        key={key}
                                        className={headersList[key].additionalClasses || ""}
                                        onClick={() => updateSort(key)}
                                        style={headersList[key].style || {}}
                                    >
                                        <div className="cursor-pointer">
                                            {headersList[key].title}
                                            {sort.column === key
                                                ? <i className={"fa fa-sort" + (sort.order === "asc" ? "-up" : "-down")}/>
                                                : <i className="fa fa-sort"/>
                                            }
                                        </div>
                                    </th>
                                    :
                                    <th
                                        key={key}
                                        className={headersList[key].additionalClasses || ""}
                                        style={headersList[key].style || {}}
                                    >
                                        {headersList[key].title} {component}
                                    </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {list.length
                        ?
                        list.map(data =>
                            <Row
                                key={data.id}
                                data={data}
                                users={users}
                                dispatch={dispatch}
                            />
                        )
                        :
                        <tr>
                            <td colSpan={Object.keys(headersList).length} className="text-center text-info">
                                No alert groups exist.
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
};

Table.propTypes = {
    list: PropTypes.array,
    sort: PropTypes.object,
    users: PropTypes.array,
    updateSort: PropTypes.func,
    dispatch: PropTypes.func
};

const rowInitialState = {
    isOpen: false,
    isParticipantsOpen: false,
};

const Row = ({data, users, dispatch}) => {
    const [rowState, rowDispatch] = useReducer(reducer, rowInitialState);
    const {isOpen, isParticipantsOpen} = rowState;

    return (
        <React.Fragment>
            <tr style={{...(isOpen ? {background: "#f1f1f1"} : {})}}>
                <td onClick={() => rowDispatch({isOpen: isOpen})}>
                    {isOpen
                        ? <span>
                            <span style={{fontSize: 18}}><i className="fa fa-folder-minus" style={{color: "#969696"}}/> </span>
                            <b>{data.name}</b>
                        </span>
                        : <span>{data.name}</span>
                    }
                </td>
                <td>
                    <Dropdown
                        className="dropdown-equipment"
                        size="sm"
                        direction="left"
                        isOpen={isParticipantsOpen}
                        toggle={() => rowDispatch({isParticipantsOpen: !isParticipantsOpen})}
                    >
                        <DropdownToggle><span className="menu-count badge badge-info"><span>{(data.participants || []).length}</span></span></DropdownToggle>
                        <DropdownMenu className="custom-scroll custom-scroll-dropdown-menu">
                            {data.participants.map(userId => {
                                const tempUser = _find(users, user => +user.id === +userId);
                                return tempUser && <DropdownItem key={userId} header>{tempUser.full_name}</DropdownItem>;
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </td>
                <td>{ALARM_EMAIL_FREQUENCY[data.frequency] || "---"}</td>
                <td>
                    <div className='btn-group btn-group-sm'>
                        <Link
                            to={`/device-alert-groups/${data.id}`}
                            className="link alarm-btn color-primary"
                        >
                            <i className="fa fa-pen"/>
                            <span>Edit</span>
                        </Link>
                        <button
                            className="link alarm-btn color-danger"
                            onClick={() => dispatch({removeId: data.id})}
                        >
                            <i className="fa fa-trash"/>
                            <span>Delete</span>
                        </button>
                    </div>
                </td>
            </tr>
            {isOpen &&
                <tr style={{background: "#f1f1f1"}}>
                    <td colSpan={Object.keys(headersList).length} style={{borderTop: "none"}}>
                        <div style={{textAlign: "left"}}>
                            123
                        </div>
                    </td>
                </tr>
            }
        </React.Fragment>
    );
};

Row.propTypes = {
    data: PropTypes.object,
    users: PropTypes.array,
    dispatch: PropTypes.func
};

export default withGlobalStore(DeviceAlertGroups);