import {useQuery} from "@tanstack/react-query";
import ApiChartHistory from "../../../api/chartHistory";

const useChartHistories = (equipmentId) => {
    const {isSuccess: chartHistoryQuerySuccess, data: chartHistories} = useQuery(
        ["ChartHistoryQuery"],
        () => ApiChartHistory.get(+equipmentId, (response) => response.data),
        {
            enabled: !!equipmentId,
        }
    );

    return {
        chartHistoryQuerySuccess,
        chartHistories: chartHistories?.list,
    };
};

export default useChartHistories;
