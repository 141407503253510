import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {shallow} from "zustand/shallow";

export const useFFTAcquisitionsTimeStore = create(
    immer((set) => ({
        isShowFFTAcquisitionsTime: false,
        actions: {
            toggleShowFFTAcquisitionsTime() {
                set((state) => {
                    state.isShowFFTAcquisitionsTime = !state.isShowFFTAcquisitionsTime;
                });
            },
        },
    }))
);

export const useShowFFTAcquisitionsTimeActions = () => useFFTAcquisitionsTimeStore((state) => state.actions, shallow);

export const useIsShowFFTAcquisitionsTime = () => useFFTAcquisitionsTimeStore((state) => state.isShowFFTAcquisitionsTime);
