import React, {Component} from "react";
import PropTypes from "prop-types";
import ApiGateways from "../../../api/gateway";
import {Loader, Pager} from "../../../shared";
import Table from "./table";
import SearchInput from "../../shared/search-input";
import {
    sumBy as _sumBy,
    get as _get
} from "lodash";
import LimitSelect from "../../shared/limit-select";
import "../../../assets/scss/components/gateway/gateway.scss";
import {withLocationSelectStore} from "../../../stores/LocationSelectStore";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import Helper from "../../../helpers/helper";
import { HeaderSimple } from "../../../shared/header";

const totalsOptions = {
    gateways: {label: "GATEWAYS REPORTING"},
    // routers: {label: "ROUTERS REPORTING"},
    nodes: {label: "NODES REPORTING"},
    sensors: {label: "TETHERED SENSORS REPORTING"},
    motes: {label: "SENSOR MOTES REPORTING"}
};

class Gateways extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{name: "Manage Gateways"}],
            loader: true,
            list: [],
            stats: [],
            totals: {},
            pagination: {
                page: 1,
                pages: 1,
                perpage: Helper.getPerPage("gateways"),
                total: 0
            },
            sort: {
                field: "",
                sort: "asc"
            },
            filter: {
                query: "",
                locationId: props.getLocationId()
            },
            globalSearchString: ""
        };

        this.finished = false;
        this.handleFetch = this.handleFetch.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handlePagerChange = this.handlePagerChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
    }

    componentDidMount() {
        Promise.all([
            this.handleFetch()
        ]);
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    handleFetch() {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        return Promise.all([
            ApiGateways.list({
                query: {
                    sort: this.state.sort,
                    filter: this.state.filter,
                    pagination: this.state.pagination
                },
                signal: this.signal
            })
        ]).then(([gateways]) => {
            if (gateways) {
                const {list = [], meta = {}, stat = []} = gateways;
                this.setState({
                    loader: false,
                    stats: stat || [],
                    totals: {
                        gateways_reporting: _sumBy(stat, s => +s.gateway_reporting) || 0,
                        sensors_reporting: _sumBy(stat, s => +s.sensors_reporting) || 0,
                        nodes_reporting: _sumBy(stat, s => +s.nodes_reporting) || 0,
                        motes_reporting: _sumBy(stat, s => +s.motes_reporting) || 0,
                        routers_reporting: _sumBy(stat, s => +s.routers_reporting) || 0,
                        gateways_installed: stat.length,
                        sensors_installed: _sumBy(stat, s => +s.sensors_installed) || 0,
                        nodes_installed: _sumBy(stat, s => +s.nodes_installed) || 0,
                        motes_installed: _sumBy(stat, s => +s.motes_installed) || 0,
                        routers_installed: _sumBy(stat, s => +s.routers_installed) || 0,
                    },
                    pagination: Object.assign({}, this.state.pagination, meta),
                    list
                }, () => {
                    if (!this.finished) {
                        this.finished = true;
                    }
                });
            }
        });
    }

    handleSortChange(field = "") {
        const {sort} = this.state;

        this.setState({
            sort: Object.assign({}, {
                field,
                sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"
            }),
            loader: true
        }, this.handleFetch);
    }

    handlePagerChange(page = 1) {
        this.setState({loader: true, pagination: Object.assign({}, this.state.pagination, {page})}, this.handleFetch);
    }

    handleFilterChange(obj = {}) {
        this.finished = false;
        this.setState({
            filter: Object.assign({}, this.state.filter, obj),
            pagination: Object.assign({}, this.state.pagination, {page: 1}),
            loader: true,
        }, this.handleFetch);
    }

    handleSearch = (value) => {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }

        this.handleFilterChange({query: value});
    };
    handleLimitChange = e => {
        const {value} = e.target;
        Helper.setPerPage("gateways", value);
        this.setState({
            loader: true,
            pagination: {
                ...this.state.pagination,
                page: 1,
                perpage: value
            }
        }, this.handleFetch);
    };

    render() {
        const {
            breadcrumbs,
            loader,
            list,
            stats,
            totals,
            pagination,
            sort,
            filter,
            globalSearchString,
        } = this.state;

        return (
            <div>
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">Manage Gateways</div>
                </div>
                <div className="block">
                    <div className="block-header">
                        <div className="block-label custom-head-label">
                            <div className="form-list form-list--row">
                                <div className="form-group form-group--inline">
                                    <div className="form-label"><label>Asset Tree Branch:</label></div>
                                    <CollapseLocationSelect
                                        style={{textTransform: "capitalize"}}
                                        className={"form-control form-control-sm"}
                                        selectName={"locationId"}
                                        value={+_get(filter, ["locationId"], 0)}
                                        onChange={(e) => {
                                            this.handleFilterChange({
                                                locationId: _get(e, "target.value")
                                            });
                                        }}
                                        emptyOptionLabel={"All"}
                                        checkStoreValue={true}
                                        withFavorite={true}
                                    />
                                </div>
                                {Object.keys(totalsOptions).map(key =>
                                    !!totals[`${key}_installed`] &&
                                        <div key={key} className="reporting-item">
                                            <span>
                                                <b>{totals[`${key}_reporting`]}/{totals[`${key}_installed`]}</b>
                                                <br/>{totalsOptions[key].label}
                                            </span>
                                            <div className="progress" style={{height: 8}}>
                                                <div className="progress-bar" style={{
                                                    width: "100%",
                                                    background:
                                                        totals[`${key}_reporting`] === totals[`${key}_installed`]
                                                            ? "#46b67f"
                                                            : (key === "gateways" || totals[`${key}_reporting`] === 0
                                                                ? "#f44336"
                                                                : ((totals[`${key}_reporting`] / totals[`${key}_installed`]) * 100 > 90
                                                                    ? "linear-gradient(to right, #e3bb31 0%, #46b67f 100%)"
                                                                    : "linear-gradient(to right, #f44336 0%, #e3bb31 100%)"
                                                                )
                                                            )
                                                }}/>
                                            </div>
                                        </div>
                                )}
                            </div>
                        </div>
                        <div id="top-pagination-block" className="block-pagination">
                            {pagination.total > 10 &&
                            <div className="limit-select-block">
                                <LimitSelect
                                    name={"perpage"}
                                    onChange={this.handleLimitChange}
                                    defaultValue={pagination.perpage}
                                    limits={[10, 20, 50, 100]}
                                />
                            </div>
                            }
                            {pagination.pages > 1 &&
                            <Pager
                                page={pagination.page}
                                pages={pagination.pages}
                                onPagerChange={this.handlePagerChange}
                            />
                            }
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <div style={{minWidth: "180px"}}>
                                    <SearchInput
                                        placeholder="Search Gateways"
                                        query={filter.query}
                                        onChange={this.handleSearch}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-body">
                        {loader
                            ?
                            <Loader/>
                            :
                            <div>
                                <Table
                                    list={list}
                                    stats={stats}
                                    totals={totals}
                                    sort={sort}
                                    query={filter.query}
                                    onSortChange={this.handleSortChange}
                                />
                            </div>
                        }
                    </div>
                </div>
                <button
                    disabled={list.length > 0 ? false : true}
                    title={list.length > 0 ? "" : "There is no data to export at this time."}
                    onClick={ () => {
                        ApiGateways.export({
                            query: {
                                sort: this.state.sort,
                                filter: this.state.filter
                            },
                            signal: this.signal
                        }).then(response => response.blob()).then( (blob) => {
                            const url = window.URL.createObjectURL(new Blob([blob]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", "gateways " + new Date().toLocaleString("en-US")+".csv");
                            link.setAttribute("id", "test-file");
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        } );
                    } }
                    className="btn v2-btn blue ml-3"
                    style={{width:100}}
                >
                    <img src={"/assets/pic/icon-download.svg"} alt=""/>
                    <span>Export</span>
                </button>
            </div>
        );
    }
}

Gateways.propTypes = {
    history: PropTypes.object,

    // LocationSelectStore
    getLocationId: PropTypes.func
};

export default withLocationSelectStore(Gateways);