import Api from "./api";

const deviceAlertComments = {
    get: id => Api.get("device-alert-comments/" + id),
    add: data => Api.post("device-alert-comments/add", data),
    delete: id => Api.post("device-alert-comments/delete", {id : id}),
    edit: data => Api.post(`device-alert-comments/edit/${data.id}`, data)
};

export default deviceAlertComments;
