import Api from "./api";

const WifiGroupsApi = {
    getList: params => Api.get("wifi-group", {...params}),
    getView: wifiGroupId => Api.get(`wifi-group/${wifiGroupId}`),
    create: data => Api.post("wifi-group", data),
    update: (wifiGroupId, data) => Api.patch(`wifi-group/${wifiGroupId}`, data),
    delete: wifiGroupId => Api.delete(`wifi-group/${wifiGroupId}`)
};

export default WifiGroupsApi;