import { useChartStateStoreContext } from "../../store/hooks/useStoreContext";
import { shallow } from "zustand/shallow";
import { useSpeedSettingsFft } from "../../../../../../hooks/helpers/useSpeedSettingsFft";
import { useChartReadings } from "./useChartReadings";

export const useSpeedSettings = () => {
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint, shallow);
    const {data: readings} = useChartReadings();

    return useSpeedSettingsFft(readings[0], selectedPoint);
};
