import React, {Component} from "react";
import FormValidator from "../../../helpers/form-validator";
import PropTypes from "prop-types";
import Creatable from "react-select/creatable/dist/react-select.esm";
import InstallationPointCustomTypeApi from "../../../api/installationPointCustomType";
import {
    find as _find,
} from "lodash";
import {ValidationError} from "../../../shared";

const selectStyles = {
    control: (provided) => ({
        ...provided,
        height: 32,
        minHeight: 32
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: "100000"
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#9E9E9E",
    }),
    option: (provided) => ({
        ...provided,
        padding: "0 7px"
    }),
};

const darkModeSelectStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: "rgb(56, 56, 46)",
        height: 32,
        minHeight: 32
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#ccc"
    }),
    singleValueLabel: (provided) => ({
        ...provided,
        color: "#ccc"
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#36342a"
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: "100000"
    }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused
                ? "#aaa"
                : null,
            color: isFocused
                ? "#333"
                : "#ddd",
            padding: "0 7px"
        };
    },
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    input: (provided) => ({
        ...provided,
        color: "#ccc"
    })
};

const isDarkTheme = localStorage.getItem("lightMode");

const rules = [
    {
        field: "inst_point_custom_type",
        method: "isEmpty",
        validWhen: false,
        message: "Installation Point Type cannot be empty."
    },
];

class InstallationPointCustomTypeForm extends Component
{
    constructor(props) {
        super(props);

        this.validator = new FormValidator(rules);

        this.state = {
            validation: this.validator.valid(),
        };
    }

    onChangeSelect = (target, event) => {
        this.setState({
            validation: this.validator.valid(),
        });

        if (event.action === "create-option") {
            this.createInstPointCustomType(target.value, event.name);
        }
        if (event.action === "select-option") {
            this.updateInstPointCustomType(event.name, target.value);
        }
        if (event.action === "clear") {
            this.updateInstPointCustomType(event.name, "");
        }
    }

    createInstPointCustomType = (newType, name) => {
        const {updateInstallationPointCustomTypes} = this.props;

        InstallationPointCustomTypeApi
            .create({name: newType})
            .then(response => {
                if (response.status === "ok" && response.result) {
                    const id = (response.result || {}).id || "";

                    updateInstallationPointCustomTypes(() => this.updateInstPointCustomType(name, id));
                } else {
                    const validation = this.state.validation;

                    validation["inst_point_custom_type"].isValid = false;
                    validation["inst_point_custom_type"].message = response.message;

                    this.setState({validation});
                }
            });
    }

    updateInstPointCustomType = (name, value) => {
        const {onChange} = this.props;

        onChange({
            target: {
                name: name,
                value: value
            }
        });
    }

    render() {
        const {validation} = this.state;

        const {installationPointCustomTypes, name, value, addClassName} = this.props;

        return (
            <div className={"form-group " + addClassName}>
                <label>
                    Installation Point Type
                    <i className={"fa fa-info-circle ml-1"} style={{color: "#449AFF"}} title={"Select an exist type or enter a new type"}/>
                </label>
                <Creatable
                    className={"form-control-select" + (validation.inst_point_custom_type.isValid || !validation.inst_point_custom_type.message ? "" : " is-invalid")}
                    name={name}
                    value={_find(installationPointCustomTypes, {"value": value})}
                    styles={ isDarkTheme === "true" ? darkModeSelectStyles : selectStyles }
                    options={installationPointCustomTypes}
                    isClearable={true}
                    placehoder={"Select Installation Point Type"}
                    onChange={this.onChangeSelect}
                    closeMenuOnScroll={(e) => e && e.target && e.target.classList && e.target.classList.contains("custom-scroll")}
                    menuPortalTarget={document.body}
                    menuPlacement={"auto"}
                    formatCreateLabel={(label) => "Create \"" + label + "\" (press enter to create new type)"}
                />
                <ValidationError message={validation.inst_point_custom_type.message}/>
            </div>
        );
    }
}

InstallationPointCustomTypeForm.propTypes = {
    installationPointCustomTypes: PropTypes.array,
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    updateInstallationPointCustomTypes: PropTypes.func,
    addClassName: PropTypes.string
};

export default InstallationPointCustomTypeForm;