import {ResetSortButton} from "../../shared/resetSortButton";
import React, {Component, useState} from "react";
import PropTypes from "prop-types";
import Helper from "../../../helpers/helper";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {get as _get} from "lodash";
import EquipmentInfoModal from "./equipmentInfo/modal";
import {Link} from "react-router-dom";

const headersList = {
    "name": {title: "Configuration Name", sort: true},
    "reading_type_id": {title: "Reading Type", sort: true},
    "value_type_id": {title: "Value Type", sort: true},
    "brand": {title: "Brand", sort: true},
    "model": {title: "Model", sort: true},
    "equipment": {title: "Associated Equipment", sort: false},
    "actions": {title: "Actions",  additionalClasses: "table-buttons-th d-flex", sort: false, component: ResetSortButton}
};

class Table extends Component
{
    render() {
        const {auth, list, sort, onSortChange, onDelete, query} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <div className="table-scroll-wrapp tachometers">
                <table className="table table-hover tl-fixed">
                    <thead>
                        <tr>
                            {Object.keys(headersList).map(key => {
                                let component = "";
                                const RowComponent = _get(headersList, [key, "component"], false);
                                if (RowComponent) {
                                    component = <RowComponent sort={sort} resetSort={() => onSortChange("")}/>;
                                }
                                return (
                                    listLen && headersList[key].sort
                                        ? (
                                            <th className={headersList[key].additionalClasses || ""} key={key} onClick={() => onSortChange(key)}>
                                                <div className="title">
                                                    <span>{headersList[key].title}</span>
                                                    {sort.field === key ? <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")}/> : <i className={"fa fa-sort"} />}
                                                </div>
                                            </th>
                                        )
                                        : <th className={headersList[key].additionalClasses || ""} width={_get(headersList[key], "width", "")} key={key}>
                                            <span className="title">{headersList[key].title}</span> {component}
                                        </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {list.length > 0
                            ?
                            list.map((item) =>
                                <Row
                                    query={query}
                                    key={item.id}
                                    item={item}
                                    auth={auth}
                                    onDelete={onDelete}
                                />
                            )
                            :
                            <tr>
                                <td colSpan={listLen} className="text-center text-info">
                                    {query !== "" ? "No items match your search." : "No WUA sensors were found."}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

Table.propTypes = {
    auth: PropTypes.object,
    list: PropTypes.array,
    sort: PropTypes.object,
    onSortChange: PropTypes.func,
    onDelete: PropTypes.func,
    query: PropTypes.string,
};

const Row = ({item, query, onDelete, auth}) => {

    const [showEquipmentInfo, setShowEquipmentInfo] = useState(false);

    return (
        <tr>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(_get(item, "name", ""), query)}}/>
            <td>{_get(item, "readingType.name", "---") + " (" + _get(item, "readingType.unit.unit", "---") + ")"}</td>
            <td>{_get(item, "valueType.name", "---")}</td>
            <td>{_get(item, "brand", "---")}</td>
            <td>{_get(item, "model", "---")}</td>
            <td>
                {(_get(item, "universalAdapterParameters") || []).length
                    ?
                    <>
                        <a onClick={() => setShowEquipmentInfo(true)} className="link link-primary">
                            <i className="fa fa-eye"/> <span>View</span>
                        </a>
                    </>
                    :
                    <>
                        <div><i>No associated equipment</i></div>
                    </>
                }
            </td>
            <td style={{ textAlign: "left" }}>
                {auth.userCan("editEquipment") && !!_get(item, "customer_id") &&
                    <div className="btn-group btn-group-sm">
                        <Link
                            to={`/network/ua-configurations/edit/${item.id}`}
                            className="link link-primary"
                        >
                            <i className="fa fa-pen"/> <span>Edit</span>
                        </Link>
                        <button
                            className="link link-danger"
                            onClick={() => onDelete(item.id)}
                        >
                            <i className="fa fa-times"/> <span>Delete</span>
                        </button>
                    </div>
                }
            </td>
            {showEquipmentInfo &&
                <EquipmentInfoModal item={item} onCancel={() => setShowEquipmentInfo(false)}/>
            }
        </tr>
    );
};

Row.propTypes = {
    item: PropTypes.object,
    query: PropTypes.string,
    auth: PropTypes.object,
    onDelete: PropTypes.func,
};

export default withGlobalStore(Table);