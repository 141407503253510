import React, {useState} from "react";
import HighPassFilterInput from "../shared/intervals/HighPassFilterInput";
import PropTypes from "prop-types";
import {Modal} from "../../shared";
import {
    get as _get,
} from "lodash";
import installationPointSettingsApi from "../../api/InstallationPointSettings";
import Toast from "../shared/toast";

const HighPassModal = ({modalData, onClose, handleFetch}) => {
    const [highPass, setHighPass] = useState(3);
    const [loading, setLoading] = useState(false);

    const onChange = (e) => {
        setHighPass(_get(e, "target.value"));
    };

    const onSave = () => {
        setLoading(true);
        installationPointSettingsApi
            .batchHighPass({...modalData, ...{highPass}})
            .then(() => {
                handleFetch().then(() => {
                    Toast.success("The high-pass filter have been updated.");
                    onClose();
                    setLoading(false);
                });
            })
            .catch(() => {
                setLoading(false);
            });
    };

    let title = " FOR ALL EQUIPMENT";
    if (modalData.equipmentName) {
        title = " FOR EQUIPMENT - " + modalData.equipmentName;
    }
    if (modalData.locationName) {
        title = " FOR LOCATION - " + modalData.locationName;
    }

    return (
        <Modal
            showModal={true}
            title={"RMS HIGH-PASS FILTER" + title}
            onClose={onClose}
            onSubmit={onSave}
            inProgress={loading}
        >
            <div className="form-group" style={{marginBottom: 20}}>
                <div className="row mb-3 mt-3">
                    <label className="col-5 text-right pt-2">
                        RMS High-Pass Filter :
                    </label>
                    <div className="col-7">
                        <HighPassFilterInput
                            name={"high_pass"}
                            value={highPass}
                            onChange={onChange}
                            maxValue={400}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

HighPassModal.propTypes = {
    modalData: PropTypes.object,
    onClose: PropTypes.func,
    handleFetch: PropTypes.func,
};

export default HighPassModal;