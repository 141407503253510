import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    text: PropTypes.any,
    size: PropTypes.string,
    name: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    offstyle: PropTypes.string,
    onstyle: PropTypes.string,
};

function Toggle(props) {
    const {
        text,
        name,
        size = "small",
        checked,
        disabled,
        onChange,
        offstyle = "btn-secondary",
        onstyle = "btn-success",
    } = props;

    let displayStyle = checked ? onstyle : offstyle;
    let displayStyleDisabledSucces = disabled ? "btn-success-disabled" : "";

    return (
        <>
            <label>
                <span className={`${size} switch-wrapper`}>
                    <input
                        name={name}
                        type="checkbox"
                        checked={checked}
                        disabled={disabled}
                        onChange={onChange}
                    />
                    <span className={`${displayStyle} switch ${displayStyleDisabledSucces}`}>
                        <span className="switch-handle" />
                    </span>
                </span>
                <span className="switch-label">{text}</span>
            </label>
        </>
    );
}

Toggle.propTypes = propTypes;
export default Toggle;
