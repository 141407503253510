import {useModal} from "react-modal-hook";
import SimpleModal from "../../../modals/simple-modal";
import React from "react";
import PacketLossChart from "../components/PacketLossChart";
import {EQUIPMENT_TYPE_REPEATER} from "../../../constants/constants";
import {PacketLossRepeater} from "../components/PacketLossRepeater";

export const usePacketLossChartModal = (serial, type, title = "Packet Loss Chart") => {
    const [showModal, closeModal] = useModal(() => (
        <SimpleModal
            showModal={true}
            title={title}
            withoutSubmit={true}
            onClose={closeModal}
            size={"lg"}
        >
            {type === EQUIPMENT_TYPE_REPEATER
               ? <PacketLossRepeater type={type} serial={serial} />
               : <PacketLossChart serial={serial} type={type} />
            }
        </SimpleModal>
    ));

    return [showModal, closeModal];
};
