import React, {Component} from "react";
import PropTypes from "prop-types";
import SearchInput from "../../shared/search-input";
import {Loader} from "../../../shared";
import swal from "../../shared/swal";
import {
    get as _get,
} from "lodash";
import ActionListApi from "../../../api/actionList";
import ViewForm from "./forms/view";
import {withGlobalStore} from "../../../stores/GlobalStore";
import CompletionView from "./forms/completion";
import Toast from "../../shared/toast";

class ViewActionItem extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            globalSearchString: "",
            loader: true,
            actionItem: {},
            info: {},
            inProgress: false,
            showCompletion: false,
        };
    }

    componentDidMount() {
        const {info} = this.props;

        Promise.all([
            this.fetchActionItem(),
        ]).then(() => this.setState({
            info,
            loader: false
        }));
    }

    fetchActionItem = () => {
        const {actionItem} = this.state;
        const {actionItemId, updateList, onClose} = this.props;

        return ActionListApi
            .getActionItem(actionItemId)
            .then((response) => {
                const actionItemNew = response.actionItem || {};

                if (_get(actionItem, "state") !== _get(actionItemNew, "state") && Object.keys(actionItem).length) {
                    updateList(false);
                }
                this.setState({actionItem: actionItemNew});
            })
            .catch(() => {
                onClose();
            });
    }

    fetchInfo = () => {
        return ActionListApi
            .getInfo()
            .then((response) => {
                if (response) {
                    const info = {
                        action_list_states: _get(response, "action_list_states", {}),
                        action_list_urgencies: _get(response, "action_list_urgencies", {}),
                        action_list_no_actions_groups: _get(response, "action_list_no_actions_groups", {}),
                        ww_action_list_reason_groups: _get(response, "ww_action_list_reason_groups", {})
                    };
                    this.setState({info});
                }
            });
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    }

    onShowCompletion = () => {
        this.setState({showCompletion: true});
    }

    onCancelCompletion = () => {
        this.setState({showCompletion: false});
    }

    submitComment = (comment) => {
        const {actionItemId} = this.props;
        this.setState({inProgress: true});

        return ActionListApi
            .saveComment({action_item_id: actionItemId, comment})
            .then((response) => {
                if (response.status === "ok") {
                    this.fetchActionItem()
                        .then(() => this.setState(
                            {inProgress: false},
                            () => Toast.success("Comment was added successfully.")
                        ));
                }
            });
    }

    onSubmittedReport = () => {
        this.fetchActionItem()
            .then(() => {
                this.setState({showCompletion: false}, () => swal("", "The report has been saved.", "success"));
            });
    }

    render() {

        const {
            loader,
            actionItem,
            info,
            inProgress,
            showCompletion
        } = this.state;
        const {history, user, onClose} = this.props;

        return (
            <React.Fragment>
                <div className={"view-action-item" + (loader ? " loader" : "")}>
                    {loader
                        ?
                        <Loader/>
                        :
                        <React.Fragment>
                            {showCompletion
                                ?
                                <CompletionView
                                    actionItem={actionItem}
                                    info={info}
                                    history={history}
                                    onCancelCompletion={this.onCancelCompletion}
                                    onSubmittedReport={this.onSubmittedReport}
                                />
                                :
                                <ViewForm
                                    actionItem={actionItem}
                                    info={info}
                                    inProgress={inProgress}
                                    submitComment={this.submitComment}
                                    fetchActionItem={this.fetchActionItem}
                                    onShowCompletion={this.onShowCompletion}
                                    user={user}
                                    onClose={onClose}
                                />
                            }
                        </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item ml-2">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

ViewActionItem.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    type: PropTypes.string,
    user: PropTypes.object,
    actionItemId: PropTypes.number,
    info: PropTypes.object,
    onClose: PropTypes.func,
    updateList: PropTypes.func
};

export default withGlobalStore(ViewActionItem);