import Api from "./api";

const EquipmentProfileApi = {
    list: () => Api.get("equipment-profile"),
    view: id => Api.get(`equipment-profile/${id}`),
    createEquipment: (id, data) => Api.post(`equipment-profile/${id}/create-equipment`, data),
    create: (data) => Api.post("equipment-profile", data),
    update: (id, data) => Api.patch(`equipment-profile/${id}`, data),
    delete: id => Api.delete(`equipment-profile/${id}`),
    getEquipments: (id) => Api.get(`equipment-profile/${id}/get-equipments`),
    updateEquipments: (id, data) => Api.post(`equipment-profile/${id}/update-equipments`, data),
    getInfo: id => Api.get(`equipment-profile/info/${id}`),
    getEquipmentsInfo: id => Api.get(`equipment-profile/equipments-info/${id}`),
    getEquipmentInfo: id => Api.get(`equipment/${id}/profile-info`),
    updateEquipmentProfile: (InfoId, EquipmentId) => Api.post(`equipment-profile/${InfoId}/update-equipment/${EquipmentId}`),
    associateEquipmentProfile: (InfoId, EquipmentId, data) => Api.post(`equipment-profile/${InfoId}/associate-equipment/${EquipmentId}`, data),
    dissociateEquipmentProfile: (InfoId, EquipmentId) => Api.post(`equipment-profile/${InfoId}/dissociate-equipment/${EquipmentId}`)
};

export default EquipmentProfileApi;