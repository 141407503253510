import Api from "./api";

const AlertLevel = {
    getLevelsConditionsList: () => {
        return Api.get("alert-levels/conditions");
    },
    getList: () => {
        return Api.get("alert-levels");
    },
    getTable: (query) => {
        return Api.get("alert-levels/list-table", {query});
    },
    create: (data) => {
        return Api.post("alert-levels/create", data);
    },
    update: (data) => {
        return Api.post(`alert-levels/${data.id}/update`, data);
    },
    delete: (id) => {
        return Api.delete(`alert-levels/${id}/delete`);
    },
    getForProfile: () => {
        return Api.get("alert-levels/for-profile");
    },
};

export default AlertLevel;
