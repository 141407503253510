/* eslint-disable no-unused-vars */
import {useMutation, useQueryClient} from "@tanstack/react-query";
import EquipmentApi from "../../../api/equipment";
import {CHART_MARK_API} from "./constants";
import ApiChartMark from "../../../api/chartMark";
import {updateItemInList} from "../../../helpers/query";

export const useChartMarksUpdateMutation = (equipmentId, chartMarkId) => {
    const queryClient = useQueryClient();

    const updateChartMark = (data) => ApiChartMark.update(equipmentId, chartMarkId, data);

    const mutation = useMutation(updateChartMark, {
        onSuccess: (data) => {
            queryClient.setQueriesData([CHART_MARK_API.GET_CHART_MARK, +equipmentId], (prev) => updateItemInList(prev, data));
        },
    });
    return mutation;
};
