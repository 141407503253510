import {useChartStateStoreContext} from "../store/hooks/useStoreContext";
import {useEffect} from "react";
import Highcharts from "highcharts";
import {useDeleteReadingsSimple} from "../../../../../modals/deleteReadingsSimple/hooks/useDeleteReadingsSimple";
import {cloneDeep as _cloneDeep} from "lodash";

export const useChartEventEffect = (chartRef, selectedInstPoints, series) => {
    const bulkDeleteReadingsMode = useChartStateStoreContext((state) => state.settings.bulkDeleteReadingsMode);
    const {setZoomed, setBulkDeleteReadingPoints, setCurrentChart} = useChartStateStoreContext((state) => state.actions);

    const [showDeleteReadingModalSimple] = useDeleteReadingsSimple();

    const onChartSelectionEvent = (e) => {
        if (bulkDeleteReadingsMode) {
            setBulkDeleteReadingPoints(e);
            showDeleteReadingModalSimple();
            return false;
        } else {
            setZoomed(!e.resetSelection);
        }
    };

    const onSetExtremes = (e) => {
        if (e.trigger === "resetZoom") {
            return setZoomed(false);
        }

        if (e.trigger === "zoom" && chartRef.current.chart.userOptions.chart.syncZoomGroup) {
            return setZoomed(true);
        }
    };

    useEffect(() => {
        if (!chartRef.current || !chartRef.current?.chart) {
            return;
        }

        setCurrentChart(_cloneDeep(chartRef));
        Highcharts.addEvent(chartRef.current.chart, "selection", onChartSelectionEvent);
        Highcharts.addEvent(chartRef.current.chart.xAxis[0], "setExtremes", onSetExtremes);

        return () => {
            if (!chartRef.current || !chartRef.current?.chart) {
                return;
            }

            Highcharts.removeEvent(chartRef.current.chart, "selection", onChartSelectionEvent);
            Highcharts.removeEvent(chartRef.current.chart.xAxis[0], "setExtremes", onSetExtremes);
        };
    }, [chartRef.current, bulkDeleteReadingsMode, selectedInstPoints, series]);
};
