import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {ValidationError} from "../../../../shared";
import {get as _get} from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import {withGlobalStore} from "../../../../stores/GlobalStore";
import {SUPPORT_EMAIL} from "../../../../constants/constants";
import ApiGateways from "../../../../api/gateway";
import SelectWrapper from "../../../../helpers/select-wrapper";
import ApiMotes from "../../../../api/mote";
import ImpactVueText from "../../../../shared/impactVue/ImpactVueText";

const SensorField = ({sensor, formErrors, onChange}) =>
{
    return (
        <>
            <label>Sensor</label>
            <input
                name={"sensor.sensor_hex"}
                onChange={onChange}
                value={_get(sensor, "sensor_hex")}
                placeholder="Enter Sensor Hex Code"
                className={"form-control" + (_get(formErrors, "sensor.sensor_hex") ? " is-invalid" : "")}
            />
            <ValidationError additionalClass="mb-5" message={_get(formErrors, "sensor.sensor_hex")}/>

            {_get(formErrors, "sensor.duplicatedWay") &&
                <DuplicatedModal
                    message={_get(formErrors, "sensor.duplicatedWay")}
                    onChange={onChange}
                />
            }

            {_get(formErrors, "sensor.confirmC1D1") &&
                <ConfirmC1D1
                    value={_get(sensor, "confirmC1D1")}
                    onChange={onChange}
                />
            }

            {_get(formErrors, "sensor.confirmGatewayNotAssociated") &&
                <ConfirmGatewayNotAssociatedModal
                    message={_get(formErrors, "sensor.confirmGatewayNotAssociated")}
                    sensorHex={_get(sensor, "sensor_hex")}
                    onChange={onChange}
                />
            }
        </>
    );
};

SensorField.propTypes = {
    sensor: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    // from global store
    auth: PropTypes.object
};

const DuplicatedModal = ({message, onChange}) => {

    const setDuplicateWay = (way) => {
        onChange({target: {name: "sensor.duplicatedWay", value: way}});
    };

    return (
        <div className={"sweet-alert-over-modal"}>
            <SweetAlert
                info
                title={""}
                onConfirm={() => setDuplicateWay(null)}
                customButtons={
                    <React.Fragment>
                        <button
                            className="btn btn-sm btn-default mr-2"
                            onClick={() => setDuplicateWay(null)}
                        >Cancel</button>
                        <button
                            className="btn btn-sm btn-primary__custom mr-2"
                            onClick={() => setDuplicateWay("create-new")}
                        >Create New</button>
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={() => setDuplicateWay("move-data")}
                        >Move Data</button>
                    </React.Fragment>
                }
            >
                <div className={"sweet-alert-text"}>
                    <div className="confirm-message breaking-line">{message}<br/>  Click a button below and note the following: </div>
                    <ul>
                        <li><b>Cancel:</b> Closes the window.</li>
                        <li><b>Create New:</b> Creates a new installation point for the equipment and transfers the sensor
                            from the previous installation point to the new one. <span className="text-underline">The old installation point will
                                    still exist in the system, but it will no longer have an installed sensor.</span> </li>
                        <li><b>Move Data:</b> Transfers the current installation point and sensor from the current equipment to the new location.</li>
                    </ul>
                </div>
            </SweetAlert>
        </div>
    );
};

DuplicatedModal.propTypes = {
    message: PropTypes.string,
    onChange: PropTypes.func,
};

const ConfirmGatewayNotAssociatedModal = ({message, onChange, sensorHex}) => {
    const [gateways, setGateways] = useState([]);
    const [gatewayId, setGatewayId] = useState(null);
    const [inProgress, setInProgress] = useState(true);
    const [errors, setErrors] = useState("");

    useEffect(() => {
        ApiGateways.list({query: {pagination: {page: 1, perpage: 100}}}).then(({list}) => {
            setGateways(list);
            setInProgress(false);
        });
    }, []);

    const onConfirm = (confirm) => {
        if (!gatewayId) {
            setErrors("This field is required.");
            return;
        }
        setInProgress(true);
        ApiMotes.associate({serial: sensorHex, gateway_id: gatewayId}).then(() => {
            setInProgress(false);
            onChange({target: {name: "sensor.confirmGatewayNotAssociated", value: confirm}});
        });
    };

    const onCancel = () => {
        onChange({target: {name: "sensor.confirmGatewayNotAssociated", value: false}});
    };

    const onChangeGateway = (e) => {
        setErrors("");
        setGatewayId(_get(e, "target.value", ""));
    };

    return (
        <SweetAlert
            warning
            showCancel
            confirmBtnText="Save"
            cancelBtnBsStyle="default"
            btnSize="xs"
            title=""
            openAnim={false}
            closeAnim={false}
            customButtons={
                <React.Fragment>
                    <button
                        className="btn btn-sm btn-default mr-2"
                        disabled={inProgress}
                        onClick={() => onCancel()}
                    >Cancel</button>
                    <button
                        className="btn btn-sm btn-primary__custom mr-2"
                        disabled={inProgress}
                        onClick={() => onConfirm(true)}
                    >Save</button>
                </React.Fragment>
            }
            onCancel={() => null}
            onConfirm={() => null}
            disabled={inProgress}
        >
            <div>{message}</div>
            <div className="form-group mt-4">
                <label className="text-right form-label">Gateway: <span className="color-danger">*</span></label>
                <div className="">
                    <SelectWrapper
                        style={{
                            width:"100%",
                            display:"block",
                        }}
                        className={errors ? " is-invalid" : ""}
                        name="gateway_id"
                        value={+gatewayId}
                        onChange={onChangeGateway}
                        disabled={inProgress}
                        placeholder={"Select Gateway"}
                    >
                        {gateways.map(gateway => (
                            <option key={gateway.gateway_id} value={gateway.gateway_id}>{gateway.name ? gateway.name + " (" + gateway.serial + ")" : gateway.serial}</option>
                        ))}
                    </SelectWrapper>
                    <ValidationError message={errors}/>
                </div>
            </div>
        </SweetAlert>
    );
};

ConfirmGatewayNotAssociatedModal.propTypes = {
    message: PropTypes.string,
    onChange: PropTypes.func,
    sensorHex: PropTypes.string
};

const ImpactVueCheckbox = ({value, onChange}) => {
    return (
        <span
            className={"alert-link"}
            style={{position: "absolute", right: 10, top: 32}}
            onClick={() => onChange({target: {name: "sensor.is_hfdvue", value: !value}})}
            title={"ImpactVUE™"}
        >
            <label className={"switch"} style={{marginRight: "10px"}}>
                <input
                    readOnly={true}
                    type="checkbox"
                    checked={value ? "checked" : false}
                />
                <span className="slider round"/>
            </label>
            <ImpactVueText />
        </span>
    );
};

ImpactVueCheckbox.propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func,
};

const ConfirmC1D1 = ({onChange}) => {
    const [confirm, setConfirm] = useState(false);

    const onConfirm = (confirm) => {
        onChange({target: {name: "sensor.confirmC1D1", value: confirm}});
    };

    return (
        <div className={"sweet-alert-over-modal"}>
            <SweetAlert
                warning
                showConfirm={true}
                title={""}
                onCancel={() => onConfirm(false)}
                onConfirm={() => onConfirm(true)}
                customButtons={
                    <React.Fragment>
                        <button
                            className="btn btn-sm btn-default mr-2"
                            onClick={() => onConfirm(false)}
                        >Cancel</button>
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={() => onConfirm(true)}
                            disabled={!confirm}
                        >Confirm</button>
                    </React.Fragment>
                }
            >
                <div>
                    <p><b>WARNING:</b> You are attempting to replace this sensor with one that is not certified for hazardous environments.</p>
                    <p>Using the incorrect sensor type in this environment may cause fire or explosion hazards.</p>
                    <p>Please contact {SUPPORT_EMAIL} before continuing.</p>
                </div>
                <label
                    className="form-checkbox form-checkbox--black"
                >
                    <input
                        type="checkbox"
                        onChange={() => setConfirm(!confirm)}
                        value={+confirm}
                    /> <b>I understand the risk. Continue with replacement.</b> <span/>
                </label>
            </SweetAlert>
        </div>
    );
};

ConfirmC1D1.propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func,
};

export default withGlobalStore(SensorField);