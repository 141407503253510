import React, {Component} from "react";
import PropTypes from "prop-types";
import {ALARM_EMAIL_FREQUENCY} from "../../constants/constants";
import ApiUser from "../../api/user";
import ApiAlertLevel from "../../api/alertLevel";
import {
    get as _get,
    set as _set
} from "lodash";
import Toast from "../shared/toast";
import ApiUserSubscription from "../../api/userSubscriptions";
import {UserAlertGroup, UserDeviceAlertGroup} from "../subscriptions/user-subscriptions/groups";
import SelectWrapper from "../../helpers/select-wrapper";
class AlertSettingForm extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            data: {
                email_frequency: _get(props, "user.email_frequency", "") || "0",
                alert_levels: _get(props, "user.alert_levels", []),
                receive_hardware_notify: _get(props, "user.receive_hardware_notify") || "0",
                unsubscribeAlertGroup: [],
                unsubscribeDeviceAlertGroup: [],
            },
            inProgress: false,
            alertLevels: [],
            userSubscriptions: []
        };

        this.onChange = this.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onChangeAlertLevel = this.onChangeAlertLevel.bind(this);
    }

    componentDidMount() {
        Promise.all([
            ApiAlertLevel.getForProfile(),
            this.fetchSubscriptionGroups()
        ]).then(([alertLevelsList]) => {
            this.setState({
                alertLevels: _get(alertLevelsList, "list", []),
            });
        });
    }

    fetchSubscriptionGroups = () => {
        return ApiUserSubscription
            .forUser(this.props.user.id)
            .then((userSubscriptions) => {
                this.setState({
                    userSubscriptions
                });
            });
    }

    onChange(e) {
        const data = {...this.state.data};
        _set(data, _get(e, "target.name", ""), _get(e, "target.value", ""));
        this.setState({data});
    }

    onChangeAlertLevel(e) {
        const data = {...this.state.data},
            value = parseInt(_get(e, "target.value", ""));
        if (data.alert_levels.indexOf(value) === -1) {
            data.alert_levels.push(value);
        } else {
            data.alert_levels = data.alert_levels.filter((alert_level) => alert_level !== value);
        }

        this.setState({data});
    }

    onSave() {
        const data = {...this.state.data};

        this.setState({inProgress: true}, () => {
            ApiUser.updateAlertSetting({...data}).then(response => {
                if(response.status === "ok"){
                    this.setState({
                        data: {
                            ...data,
                            ...{
                                unsubscribeAlertGroup: [],
                                unsubscribeDeviceAlertGroup: []
                            }
                        },
                    }, () => {
                        this.props.user.update(data);
                        this.props.auth.setToken();
                    });
                    ApiUserSubscription.forUser(this.props.user.id).then(userSubscriptions => {
                        Toast.success("The notification settings have been updated.");
                        this.setState({userSubscriptions, inProgress: false});
                    });
                }else{
                    this.setState({inProgress: false});
                }
            });
        });
    }

    onChangeUnsubscribeAlertGroup = (unsubscribeAlertGroup) => {
        this.setState({...this.state.data, unsubscribeAlertGroup: unsubscribeAlertGroup});
    }

    onChangeUnsubscribeDeviceAlertGroup = (unsubscribeDeviceAlertGroup) => {
        this.setState({...this.state.data, unsubscribeDeviceAlertGroup: unsubscribeDeviceAlertGroup});
    }

    render() {
        const {inProgress, data, alertLevels, userSubscriptions} = this.state;
        const {auth} = this.props;

        if (!auth.userCan("superAdmin") && !auth.userCan("showAlarms")) return "";

        return (
            <div className="block">
                <div className="block-header">
                    <div className="block-label">
                        <h5 className="block-title mb-0">Alert Email Notifications <small>update your notification settings</small></h5>
                    </div>
                </div>
                <form
                    ref={el => this.el = el}
                    className="form user-profile-form"
                    onSubmit={e => {e.preventDefault(); this.onSave();}}
                >
                    <div className="block-body">
                        <div className="form-group row">
                            <label className="col-3 form-label">Alert Email Frequency</label>
                            <div className="col-9 col-lg-6">
                                <SelectWrapper
                                    name="email_frequency"
                                    value={data.email_frequency}
                                    onChange={this.onChange}
                                    disabled={inProgress}
                                >
                                    {Object.keys(ALARM_EMAIL_FREQUENCY).map((item) => (
                                        <option key={item} value={item}>{ALARM_EMAIL_FREQUENCY[item]}</option>
                                    ))}
                                </SelectWrapper>
                            </div>
                        </div>
                        {alertLevels.length > 0 &&
                            <div className="form-group row">
                                <label className="col-3 form-label">Receive Alert Notifications for</label>
                                <div className="col-9 col-lg-6">
                                    <div className="form-list form-list--column mt-2">
                                        {alertLevels.map(alertLevel =>
                                            <label key={alertLevel.id} className="form-checkbox">
                                                <input
                                                    type="checkbox"
                                                    disabled={inProgress}
                                                    value={alertLevel.id}
                                                    onChange={this.onChangeAlertLevel}
                                                    defaultChecked={data.alert_levels.indexOf(alertLevel.id) !== -1}
                                                /> {alertLevel.name} <span/>
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>
                        }
                        <UserAlertGroup
                            userSubscriptions={userSubscriptions}
                            unsubscribe={_get(data, "unsubscribeAlertGroup", [])}
                            updateUnsubscribe={this.onChangeUnsubscribeAlertGroup}
                            inProgress={inProgress}
                            labelClass={"col-3"}
                            disabled={!auth.userCan("superAdmin") && !auth.userCan("manageSubscriptions")}
                        />
                        {["self-service", "hybrid-service"].indexOf(auth.customer.system_version) !== -1 && auth.userCan("showServiceNotifications") &&
                        <React.Fragment>
                            <UserDeviceAlertGroup
                                userSubscriptions={userSubscriptions}
                                unsubscribe={_get(data, "unsubscribeDeviceAlertGroup", [])}
                                updateUnsubscribe={this.onChangeUnsubscribeDeviceAlertGroup}
                                inProgress={inProgress}
                                labelClass={"col-3"}
                                disabled={!auth.userCan("superAdmin") && !auth.userCan("manageSubscriptions")}
                            />
                            <div className="form-group row">
                                <label className="col-3 form-label">
                                    Receive Event Log Notifications
                                </label>
                                <div className="col-9 col-lg-6">
                                    <div className="form-list form-list--column mt-2">
                                        <label className="form-checkbox">
                                            <input
                                                type="checkbox"
                                                name="receive_hardware_notify"
                                                disabled={inProgress}
                                                value={+!+data.receive_hardware_notify}
                                                onChange={this.onChange}
                                                defaultChecked={+data.receive_hardware_notify === 1}
                                            /><span/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        <div className="form-group row">
                            <label className="col-3"></label>
                            <div className="col-9 col-lg-6 text-right">
                                <button
                                    type="button"
                                    className="btn btn-success btn-sm"
                                    onClick={this.onSave}
                                    disabled={inProgress}
                                >
                                    {inProgress ? <span><i className="fa fa-spinner"></i> Processing</span> : "Submit"}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

AlertSettingForm.propTypes = {
    user: PropTypes.object,
    auth: PropTypes.object
};

export default AlertSettingForm;