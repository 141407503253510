import React, {useEffect, useReducer} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {Loader, Pager} from "../../../shared";
import {Link} from "react-router-dom";
import {ResetSortButton} from "../../shared/resetSortButton";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import UserApi from "../../../api/user";
import AlertLevelApi from "../../../api/alertLevel";
import AlertGroupsApi from "../../../api/alertGroups";
import {ALARM_EMAIL_FREQUENCY} from "../../../constants/constants";
import {find as _find, filter as _filter, orderBy as _orderBy, get as _get} from "lodash";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import {customStyles} from "../../../helpers/select";
import { HeaderSimple } from "../../../shared/header";

const title = "Alert Groups";
const breadcrumbs = [{name: "Alert Groups"}];
const isDarkTheme = localStorage.getItem("lightMode");

const initialState = {
    list: [],
    users: [],
    alertLevelList: [],
    filter: {},
    sort: {
        column: "",
        order: "asc"
    },
    pagination: {
        page: 1,
        pages: 1,
        perpage: 10,
        total: 0
    },
    removeId: undefined,
    loader: true
};

const reducer = (state, action) => ({...state, ...action});

const AlertGroups = ({chartTypes}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {list, sort, filter, pagination, users, alertLevelList, removeId, loader} = state;

    useEffect(() => {
        Promise.all([
            UserApi.getSelfUsers({
                query: {
                    filter: {status: 1},
                    excludeWCDUsers: 1
                }
            }),
            AlertLevelApi.getForProfile()
        ]).then(([selfUsers, alertLevel]) => {
            dispatch({users: selfUsers.users || [], alertLevelList: alertLevel.list || []});
        });
    }, []);

    useEffect(() => {
        if (loader) {
            AlertGroupsApi.list({sort, filter, pagination}).then(({list, meta}) =>
                dispatch({list, pagination: {...pagination, ...meta}, loader: false})
            );
        }
    }, [loader]);

    const updateFilter = (key, value) => dispatch({
        loader: true,
        filter: {...filter, [key]: value},
        pagination: {...pagination, page: 1}
    });

    const updateSort = column => dispatch({
        loader: true,
        sort: {column, order: column && column === sort.column ? (sort.order === "asc" ? "desc" : "asc") : "asc"}
    });

    const updatePager = (page = 1) => dispatch({
        loader: true,
        pagination: {...pagination, page}
    });

    return (
        <div className="alert-groups">
            <HeaderSimple breadcrumbs={breadcrumbs} hideGlobalSearch />
            <div className="subheader">
                <div className="subheader-title">{title}</div>
                <div className="subheader-controls">
                    <div className="subheader-toolbar">
                        <Link
                            to="/alert-groups/add"
                            className="btn btn-primary btn-sm btn-elevate"
                        >
                            <i className="fa fa-plus-circle"/>
                            Add Group
                        </Link>
                    </div>
                </div>
            </div>
            <div className="block">
                <div className="block-header">
                    <div className="block-label">
                        <div className="form-list form-list--row">
                            <div className="form-group form-group--inline">
                                <div className="form-label">
                                    <label><b>Alert Frequency:</b></label>
                                </div>
                                <Select
                                    className="form-control-select form-control-select--lg"
                                    isSearchable={false}
                                    styles={customStyles}
                                    value={ALARM_EMAIL_FREQUENCY[filter.frequency] ? {value: filter.frequency, label: ALARM_EMAIL_FREQUENCY[filter.frequency]} : ""}
                                    options={Object.keys(ALARM_EMAIL_FREQUENCY).map(key => ({value: key, label: ALARM_EMAIL_FREQUENCY[key]}))}
                                    onChange={({value}) => updateFilter("frequency", value)}
                                />
                            </div>
                            {!!Object.keys(filter).length &&
                            <div className="form-group form-group--inline">
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => dispatch({filter: {}, loader: true})}
                                >
                                    Cancel
                                </button>
                            </div>
                            }
                        </div>
                    </div>
                    {pagination.pages > 1 &&
                    <div id="top-pagination-block" className="block-pagination">
                        <Pager
                            page={pagination.page}
                            pages={pagination.pages}
                            onPagerChange={updatePager}
                        />
                    </div>
                    }
                </div>
                <div className="block-body block-body--no-paddings">
                    {loader
                        ? <div className="loader-fullscreen"><Loader/></div>
                        : <Table
                            list={list}
                            sort={sort}
                            users={users}
                            alertLevelList={alertLevelList}
                            chartTypes={chartTypes}
                            updateSort={updateSort}
                            dispatch={dispatch}
                        />
                    }
                </div>
            </div>
            {removeId &&
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnBsStyle="default"
                btnSize="xs"
                title="DELETE GROUP"
                onConfirm={() => AlertGroupsApi.delete(removeId).then(() => dispatch({removeId: undefined, loader: true}))}
                onCancel={() => dispatch({removeId: undefined})}
            >
                Are you sure you want to delete this group?
            </SweetAlert>
            }
        </div>
    );
};

AlertGroups.propTypes = {
    auth: PropTypes.object,
    chartTypes: PropTypes.object,
    match: PropTypes.object
};

const headersList = {
    "name": {title: "Group Name", sort: true},
    "participants": {title: "Group Participants", sort: true},
    "frequency": {title: "Alert Frequency", sort: true},
    "alertLevels": {title: "Alert Levels", sort: false},
    "actions": {title: "Actions", sort: false, additionalClasses: "table-buttons-th", component: ResetSortButton}
};

const Table = ({list, sort, users, chartTypes, alertLevelList, updateSort, dispatch}) => {
    return (
        <table className="table table-hover tl-fixed">
            <thead>
                <tr>
                    {Object.keys(headersList).map(key => {
                        let component = "";
                        const RowComponent = (headersList[key] || {}).component;
                        if (RowComponent) {
                            component = <RowComponent sort={sort} resetSort={() => updateSort("")}/>;
                        }
                        return (
                            Object.keys(headersList).length && headersList[key].sort
                                ?
                                <th
                                    key={key}
                                    className={headersList[key].additionalClasses || ""}
                                    onClick={() => updateSort(key)}
                                    style={headersList[key].style || {}}
                                >
                                    <div className="cursor-pointer">
                                        {headersList[key].title}
                                        {sort.column === key
                                            ? <i className={"fa fa-sort" + (sort.order === "asc" ? "-up" : "-down")}/>
                                            : <i className="fa fa-sort"/>
                                        }
                                    </div>
                                </th>
                                :
                                <th
                                    key={key}
                                    className={headersList[key].additionalClasses || ""}
                                    style={headersList[key].style || {}}
                                >
                                    {headersList[key].title} {component}
                                </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {list.length
                    ?
                    list.map(data =>
                        <Row
                            key={data.id}
                            data={data}
                            users={users}
                            alertLevelList={alertLevelList}
                            chartTypes={chartTypes}
                            dispatch={dispatch}
                        />
                    )
                    :
                    <tr>
                        <td colSpan={Object.keys(headersList).length} className="text-center text-info">
                            No alert groups exist.
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    );
};

Table.propTypes = {
    list: PropTypes.array,
    sort: PropTypes.object,
    users: PropTypes.array,
    alertLevelList: PropTypes.array,
    chartTypes: PropTypes.object,
    updateSort: PropTypes.func,
    dispatch: PropTypes.func
};

const rowInitialState = {
    tree: {},
    isOpen: false,
    isParticipantsOpen: false,
    isShowSelected: false,
    hasUnselected: false,
    hasChildren: false,
    hiddenList: {facilities: [], locations: []}
};

const Row = ({data, users, chartTypes, alertLevelList, dispatch}) => {
    const [rowState, rowDispatch] = useReducer(reducer, rowInitialState);
    const {tree, isOpen, isParticipantsOpen, isShowSelected, hasUnselected, hasChildren, hiddenList} = rowState;

    const hiddenAll = hiddenList.facilities.length === _filter(tree || [], facility => !!(facility.locations || []).length).length;

    useEffect(() => {
        if (isOpen) AlertGroupsApi.tree(data.id).then(({tree}) => {
            let hasChildren = false;
            let hasUnselected = false;
            (tree || []).forEach(facility => {
                if ((facility.equipment || []).length) {
                    facility.equipment.forEach(equipment => {
                        if (!(equipment.installationPoints || []).length && equipment.skipped)
                            hasUnselected = true;
                    });
                    hasChildren = true;
                }
                if ((facility.locations || []).length) {
                    facility.locations.forEach(location => {
                        if ((location.equipment || []).length) {
                            location.equipment.forEach(equipment => {
                                if (!(equipment.installationPoints || []).length && equipment.skipped)
                                    hasUnselected = true;
                            });
                        } else if (location.skipped)
                            hasUnselected = true;
                    });
                    hasChildren = true;
                }
            });
            rowDispatch({tree, hasUnselected, hasChildren});
        });
    }, [isOpen]);

    return (
        <React.Fragment>
            <tr style={{...(isOpen ? {background: isDarkTheme === "true" ? "#42413d" : "#f1f1f1"} : {})}}>
                <td onClick={() => rowDispatch({isOpen: !isOpen})}>
                    {isOpen
                        ? <span>
                            <span style={{fontSize: 18}}><i className="fa fa-folder-minus" style={{color: "#969696"}}/> </span>
                            <b>{data.name}</b>
                        </span>
                        : <span>
                            <span style={{fontSize: 18}}><i className="fa fa-folder-plus" style={{color: "#e3bb31"}}/> </span>
                            {data.name}
                        </span>
                    }
                </td>
                <td>
                    <Dropdown
                        className="dropdown-equipment"
                        size="sm"
                        direction="left"
                        isOpen={isParticipantsOpen}
                        toggle={() => rowDispatch({isParticipantsOpen: !isParticipantsOpen})}
                    >
                        <DropdownToggle><span className="menu-count badge badge-info"><span>{(data.participants || []).length}</span></span></DropdownToggle>
                        <DropdownMenu className="custom-scroll custom-scroll-dropdown-menu">
                            {data.participants.map(userId => {
                                const tempUser = _find(users, user => +user.id === +userId);
                                return tempUser && <DropdownItem key={userId} header>{tempUser.full_name}</DropdownItem>;
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </td>
                <td>{ALARM_EMAIL_FREQUENCY[data.frequency] || "---"}</td>
                <td>
                    {!!(data.alertLevels || []).length && _orderBy(alertLevelList, "priority", "desc").map(level =>
                        data.alertLevels.indexOf(+level.id) !== -1 &&
                            <span key={level.id} className="badge badge-warning round-badge" style={{background: level.color}}>
                                {level.name}
                            </span>
                    )}
                </td>
                <td>
                    <div className='btn-group btn-group-sm'>
                        <Link
                            to={`/alert-groups/${data.id}`}
                            className="link alarm-btn color-primary"
                        >
                            <i className="fa fa-pen"/>
                            <span>Edit</span>
                        </Link>
                        <button
                            className="link alarm-btn color-danger"
                            onClick={() => dispatch({removeId: data.id})}
                        >
                            <i className="fa fa-trash"/>
                            <span>Delete</span>
                        </button>
                    </div>
                </td>
            </tr>
            {isOpen &&
            <tr style={{background: isDarkTheme === "true" ? "#42413d" : "#f1f1f1"}}>
                <td colSpan={Object.keys(headersList).length} style={{borderTop: "none"}}>
                    {(tree || []).length
                        ?
                        <div style={{textAlign: "left"}}>
                            {(data.chartTypes || []).length > 0 &&
                                <div style={{marginBottom: 20}}>
                                    {data.chartTypes.map(chartType => !!chartTypes[chartType] &&
                                        <span key={chartType} className="badge badge-warning round-badge">
                                            <b>{chartTypes[chartType].label}</b>
                                        </span>
                                    )}
                                </div>
                            }
                            <div className="row">
                                {hasChildren &&
                                    <div className="col-md-auto">
                                        <button
                                            className="btn btn-primary btn-sm btn-elevate form-control mb-2"
                                            onClick={() => {
                                                let tempHiddenList = {facilities: [], locations: []};
                                                if (!hiddenAll) {
                                                    tree.forEach(facility => {
                                                        if ((facility.locations || []).length) {
                                                            tempHiddenList.facilities.push(+facility.facility_id);
                                                        }
                                                    });
                                                }
                                                rowDispatch({hiddenList: tempHiddenList});
                                            }}
                                            style={{fontSize: "0.875rem", height: 25}}
                                        >
                                            {hiddenAll ? "Expand" : "Hide"} All
                                        </button>
                                        {!hiddenAll && hasUnselected &&
                                            <button
                                                className="btn btn-primary btn-sm btn-elevate form-control"
                                                onClick={() => rowDispatch({isShowSelected: !isShowSelected})}
                                                style={{fontSize: "0.875rem", height: 25}}
                                            >
                                                Show {isShowSelected ? "Selected" : "Unselected"}
                                            </button>
                                        }
                                    </div>
                                }
                                <div className="col">
                                    <div className="row">
                                        {tree.map(facility =>
                                            <Tree
                                                key={facility.facility_id}
                                                facility={facility}
                                                data={data}
                                                isShowSelected={isShowSelected}
                                                hiddenList={hiddenList}
                                                rowDispatch={rowDispatch}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="text-center"><Loader/></div>
                    }
                </td>
            </tr>
            }
        </React.Fragment>
    );
};

Row.propTypes = {
    data: PropTypes.object,
    users: PropTypes.array,
    alertLevelList: PropTypes.array,
    chartTypes: PropTypes.object,
    dispatch: PropTypes.func
};

const Tree = ({facility, isShowSelected, hiddenList, rowDispatch}) => {
    const isFacilityHidden = hiddenList.facilities.indexOf(+facility.facility_id) !== -1;

    let tree = [];
    let unselectedLocations = [];
    if (!isFacilityHidden) {
        if ((facility.equipment || []).length) {
            const location = _get(facility, "facilityLocations.0") || {};
            const isLocationHidden = hiddenList.locations.indexOf(+location.id) !== -1;
            let unselectedEquipments = [];
            tree.push(
                <div key={`l${location.id}`} style={{paddingLeft: 20}}>
                    <span
                        className="btn-visible-tree"
                        onClick={() =>
                            rowDispatch({hiddenList: {
                                ...hiddenList,
                                locations: isLocationHidden
                                    ? _filter(hiddenList.locations, locationId => locationId !== +location.id)
                                    : [...hiddenList.locations, +location.id]
                            }})
                        }
                    ><i className={`fa fa-arrow-${isLocationHidden ? "down" : "up"}`}/></span>
                    Facility Equipments
                </div>
            );
            if (!isLocationHidden) {
                facility.equipment.forEach(equipment => {
                    if ((equipment.installationPoints || []).length) {
                        const checked = _filter(equipment.installationPoints || [], installationPoint => !installationPoint.skipped).length;
                        const total = (equipment.installationPoints || []).length;
                        const label = checked !== total ? ` (${checked} of ${total} Sensors)` : "";
                        tree.push(<div key={`e${equipment.id}`} style={{paddingLeft: 50}}>{`${equipment.name} ${label}`}</div>);
                    } else if (!equipment.skipped) {
                        tree.push(<div key={`e${equipment.id}`} style={{paddingLeft: 50}}>{equipment.name}</div>);
                    } else if (isShowSelected) {
                        unselectedEquipments.push(
                            <div key={`e${equipment.id}`} style={{paddingLeft: 50}}><s>{equipment.name}</s></div>
                        );
                    }
                });
            }
            tree = [...tree, ...unselectedEquipments];
        }
        (facility.locations || []).forEach(location => {
            const isLocationHidden = hiddenList.locations.indexOf(+location.id) !== -1;
            let unselectedEquipments = [];
            if ((location.equipment || []).length) {
                tree.push(
                    <div key={`l${location.id}`} style={{paddingLeft: 20}}>
                        <span
                            className="btn-visible-tree"
                            onClick={() =>
                                rowDispatch({hiddenList: {
                                    ...hiddenList,
                                    locations: isLocationHidden
                                        ? _filter(hiddenList.locations, locationId => locationId !== +location.id)
                                        : [...hiddenList.locations, +location.id]
                                }})
                            }
                        ><i className={`fa fa-arrow-${isLocationHidden ? "down" : "up"}`}/></span>
                        {location.name}
                    </div>
                );
                if (!isLocationHidden) {
                    location.equipment.forEach(equipment => {
                        if ((equipment.installationPoints || []).length) {
                            const checked = _filter(equipment.installationPoints || [], installationPoint => !installationPoint.skipped).length;
                            const total = (equipment.installationPoints || []).length;
                            const label = checked !== total ? ` (${checked} of ${total} Sensors)` : "";
                            tree.push(<div key={`e${equipment.id}`} style={{paddingLeft: 50}}>{`${equipment.name} ${label}`}</div>);
                        } else if (!equipment.skipped) {
                            tree.push(<div key={`e${equipment.id}`} style={{paddingLeft: 50}}>{equipment.name}</div>);
                        } else if (isShowSelected) {
                            unselectedEquipments.push(
                                <div key={`e${equipment.id}`} style={{paddingLeft: 50}}><s>{equipment.name}</s></div>
                            );
                        }
                    });
                }
            } else if (!location.skipped) {
                tree.push(<div key={`l${location.id}`} style={{paddingLeft: 20}}>{location.name}</div>);
            } else if (isShowSelected) {
                unselectedLocations.push(
                    <div key={`l${location.id}`} style={{paddingLeft: 20}}><s>{location.name}</s></div>
                );
            }
            tree = [...tree, ...unselectedEquipments];
        });
        tree = [...tree, ...unselectedLocations];
    }

    return (
        <div className="col-3">
            <div>
                {!!(facility.locations || []).length &&
                    <span
                        className="btn-visible-tree"
                        onClick={() =>
                            rowDispatch({hiddenList: {
                                ...hiddenList,
                                facilities: isFacilityHidden
                                    ? _filter(hiddenList.facilities, facilityId => facilityId !== +facility.facility_id)
                                    : [...hiddenList.facilities, +facility.facility_id]
                            }})
                        }
                    ><i className={`fa fa-arrow-${isFacilityHidden ? "down" : "up"}`}/></span>
                }
                <b>{facility.name || "---"}</b>
            </div>
            {tree}
        </div>
    );
};

Tree.propTypes = {
    hiddenList: PropTypes.object,
    rowDispatch: PropTypes.func,
    isShowSelected: PropTypes.bool,
    facility: PropTypes.object,
    data: PropTypes.object
};

export default withGlobalStore(AlertGroups);