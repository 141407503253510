import React, {Component} from "react";
import PropTypes from "prop-types";
import TextEditor from "../../../../shared/textEditor/textEditor";
import FormValidator from "../../../../helpers/form-validator";
import {ValidationError} from "../../../../shared";
import {
    get as _get,
} from "lodash";

const rules = [
    {
        field: "editorContent",
        method: description => !!description.replace("<img", "*").replace(/<[^>]*>/gi, "").replace(/[\s?&nbsp;]+/, "").length,
        validWhen: true,
        message: "The Question/Comment field is required."
    }
];

class CommentForm extends Component
{
    constructor(props) {
        super(props);

        this.validator = new FormValidator(rules);

        this.state = {
            editorContent: "",
            validation: this.validator.valid(),

        };
    }

    onChange = (editorContent) => {
        const validation = this.validator.validate({editorContent});
        this.setState({editorContent, validation});
    }

    onSubmit = () => {
        const {editorContent} = this.state;
        const {submitComment} = this.props;

        this.setState(
            {inProgress: true},
            () => {
                const validation = this.validator.validate(this.state);
                this.setState({validation}, () => {
                    if (validation.isValid) {
                        submitComment(editorContent).then(() => this.setState({
                            editorContent: "",
                            inProgress: false
                        }));
                    } else {
                        this.setState({inProgress: false});
                    }
                });
            }
        );
    }

    render() {
        const {editorContent, inProgress, validation} = this.state;

        return (
            <React.Fragment>
                <div>
                    <div className={"w-100 comment-block"}>
                        <div className={"validation-control" + (_get(validation, "editorContent.message") ? " is-invalid" : "")}>
                            <TextEditor
                                content={editorContent}
                                onChange={this.onChange}
                            />
                        </div>
                        <ValidationError message={_get(validation, "editorContent.message")}/>
                    </div>
                    <div className={"ml-2 mt-3 mb-0"}>
                        {inProgress
                            ?
                            <button
                                className="btn btn-primary btn-sm pull-right"
                            >
                                <span><i className="fa fa-spinner" /> Loading</span>
                            </button>
                            :
                            <button
                                onClick={this.onSubmit}
                                className="btn btn-primary btn-sm pull-right btn-custom"
                            >
                                Submit
                            </button>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

CommentForm.propTypes = {
    inProgress: PropTypes.bool,
    submitComment: PropTypes.func,
};

export default CommentForm;