import PropTypes from "prop-types";
import React from "react";
import {
    get as _get,
    find as _find,
    map as _map
} from "lodash";
import {GENERIC_TRIGGER_TYPE} from "../stores";
import {UncontrolledTooltip} from "reactstrap";

const genericEdgeArray = [
    {key: 0, value: "Rising"},
    {key: 1, value: "Falling"},
];

export const BooleanView = ({point, triggers}) => {
    if (!_get(triggers, ["data", point.id, GENERIC_TRIGGER_TYPE])) {
        return (
            <div className={"settings-preview-item intervals-preview"}>
                <div className={"title"}>Boolean Trigger</div>
                <div className={"mt-2"}><b><i>Not Set</i></b></div>
            </div>
        );
    }

    const genericEdgeStr = +_get(_find(genericEdgeArray, ["key", +_get(triggers, ["data", point.id, GENERIC_TRIGGER_TYPE, "trigger_edge"])]), "value", 0);
    const genericDelayStr = +_get(triggers, ["data", point.id, GENERIC_TRIGGER_TYPE, "trigger_delay"], 0) + " ms";

    return (
        <div className={"settings-preview-item intervals-preview"}>
            <div className={"title"}>Boolean Trigger</div>
            <div><b>Trigger Delay: </b>{genericDelayStr}</div>
            <div><b>Threshold Edge: </b>{genericEdgeStr}</div>
        </div>
    );
};
BooleanView.propTypes = {
    point: PropTypes.object,
    triggers: PropTypes.object,
};

export const BooleanForm = ({triggers, toggleFlag, onFormChange}) => {
    return (
        <div className={"point-settings-form-container"}>
            <div className="trigger-label">
                <span className="alert-link cursor-pointer" onClick={() => toggleFlag(GENERIC_TRIGGER_TYPE, !triggers.toggles[GENERIC_TRIGGER_TYPE])}>
                    <label className="switch">
                        <input
                            readOnly={true}
                            type="checkbox"
                            checked={triggers.toggles[GENERIC_TRIGGER_TYPE] ? "checked" : false}
                        />
                        <span className="slider round"/>
                    </label>
                    <b className={"ml-2"}>Boolean Trigger Settings</b>
                    <hr/>
                </span>
            </div>
            <div className="row align-items-center mb-3">
                <label className="text-right col-5 mt-1">
                    <span className="mr-2" id="setting-td-info"><i className="fa fa-info-circle"></i></span>
                    <UncontrolledTooltip placement="top" target="setting-td-info">
                        <p className="tooltip-text">
                            This setting determines the time between the detection of
                            the trigger and when the data acquisition begins.
                        </p>
                    </UncontrolledTooltip>
                    <b>Trigger Delay</b>
                </label>
                <div className="pr-1 col-7 input-group ">
                    <input
                        disabled={!triggers.toggles[GENERIC_TRIGGER_TYPE]}
                        className={"form-control"}
                        type={"number"}
                        min={0}
                        max={60000}
                        onChange={(e) => onFormChange(e)}
                        name={GENERIC_TRIGGER_TYPE + ".trigger_delay"}
                        value={_get(triggers.form, [GENERIC_TRIGGER_TYPE, "trigger_delay"], 0)}
                    />
                    <div className="input-group-append"><span
                        className="input-group-text">ms</span>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-3">
                <label className="text-right col-5 mt-1">
                    <span className="mr-2" id="setting-te-info"><i className="fa fa-info-circle"></i></span>
                    <UncontrolledTooltip placement="top" target="setting-te-info">
                        <p className="tooltip-text">
                            The setting determines whether the trigger
                            will
                            activate on the rising edge of the signal, the falling edge, or either edge
                            transition
                            if both are selected.
                        </p>
                    </UncontrolledTooltip>
                    <b>Trigger Edge</b>
                </label>
                <div className="pr-1 col-7">
                    <select
                        disabled={!triggers.toggles[GENERIC_TRIGGER_TYPE]}
                        className="form-control select-without-arrow"
                        onChange={(e) => onFormChange(e)}
                        value={+_get(triggers.form, [GENERIC_TRIGGER_TYPE, "trigger_edge"], "0")}
                        name={GENERIC_TRIGGER_TYPE + ".trigger_edge"}
                    >
                        {
                            _map(genericEdgeArray, (edge) =>
                                <option key={edge.key} value={edge.key}>
                                    {edge.value}
                                </option>
                            )
                        }
                    </select>
                </div>
            </div>
        </div>
    );
};

BooleanForm.propTypes = {
    triggers: PropTypes.object,
    toggleFlag: PropTypes.func,
    onFormChange: PropTypes.func
};