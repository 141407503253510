import React, {memo} from "react";
import {useChartContext} from "../../../../../hooks/chartHooks";
import ScaleButton from "../../../../../components/buttons/scaleButton";
import SeriesMenuMultiple from "../../../../../components/buttons/seriesMenuMultiple";
import BearingsButton from "../../../../../components/buttons/bearingsButton";
import {CHART_TYPE} from "../../../../../hooks/useChartAbstractFactory";
import PeaksButton from "../../../../../components/buttons/peaksButton";
import FaultFrequencyButton from "../../../../../components/buttons/faultFrequencyButton";
import DatepickerButton from "../../../../../components/buttons/datepickerButton";
import ChartModeDropdownButton from "../../../../../components/buttons/chartModeDropdownButton";
import ChartViewTypeDropdownButton from "../../../../../components/buttons/chartViewTypeDropdownButton";
import ChartModeFullscreenedDropdown from "../../../../../components/buttons/chartModeFullscreenedDropdown";
import ChartViewTypeFullscreenedDropdown from "../../../../../components/buttons/chartViewTypeFullscreenedDropdown";
import ZoomButton from "../../../../../components/buttons/zoomButton";
import FullscreenButton from "../../../../../components/buttons/fullscreenButton";
import ManageAlertButtons from "../../../../../components/buttons/manageAlertButtons";
import ChartHeaderWrapper from "./layouts/ChartHeaderWrapper";
import ShowAlertsButton from "../../../../../components/buttons/showAlertsButton";
import {useChartStateStoreContext} from "../../../../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import HighpassFilterBtn from "../../../../../components/buttons/HighpassFilterBtn";
import {get as _get} from "lodash";
import {usePointData} from "../../../../../../hooks/usePointsData";
import {useIsImpactVue} from "../../../../../../hooks/useChartTypes";
import InterpolationBtn from "../../../../../components/buttons/InterpolationBtn";
import SignalWarningTooltip from "../../../../../../../../../components/tooltips/SignalWarningTooltip";

const TwfFftHeader = () => {
    const {equipment} = useChartContext();
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);
    const fullScreenMode = useChartStateStoreContext((state) => state.settings.fullScreenMode);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const pointData = usePointData(selectedPoint);
    const {chartTypeName} = useChartStateStoreContext((state) => state.factory, shallow);

    return (
        <ChartHeaderWrapper>
            <div className={"chart-actions-wrapper"}>
                {!isImpactVue && <SeriesMenuMultiple />}
                <ScaleButton />
                <ZoomButton />
                {(+chartType === 9 || +chartType === 11) && (
                    <HighpassFilterBtn
                        installationPointId={+_get(pointData, "id")}
                        fMax={+_get(pointData, "settings.fft_settings.1.fmax") || 1200}
                    />
                )}
                <BearingsButton equipment={equipment} />
                {chartTypeName === CHART_TYPE.FFT && (
                    <>
                        <PeaksButton />
                        <FaultFrequencyButton />
                    </>
                )}
                <DatepickerButton fullScreenMode={fullScreenMode} />
                {fullScreenMode ? (
                    <>
                        {/*TODO create one component*/}
                        <ChartModeDropdownButton />
                        {(chartTypeName === CHART_TYPE.TWF || chartTypeName === CHART_TYPE.TWF_IMPACT) && <ChartViewTypeDropdownButton />}
                    </>
                ) : (
                    <>
                        <ChartModeFullscreenedDropdown />
                        {(chartTypeName === CHART_TYPE.TWF || chartTypeName === CHART_TYPE.TWF_IMPACT) && <ChartViewTypeFullscreenedDropdown />}
                    </>
                )}
                {(chartTypeName === CHART_TYPE.FFT || chartTypeName === CHART_TYPE.FFT_IMPACT) && (
                    <InterpolationBtn installationPointId={+_get(pointData, "id")} />
                )}
                <SignalWarningTooltip
                    tooltipText={"FFT transmission may be impacted by poor connectivity to the device."}
                    packetLossPercent={+_get(pointData, "sensor.node.packet_loss_percent")}
                />
            </div>
            <div className={"chart-actions-right"}>
                <FullscreenButton />
                {chartTypeName === CHART_TYPE.FFT && (
                    <>
                        <ManageAlertButtons />
                        <ShowAlertsButton />
                    </>
                )}
            </div>
        </ChartHeaderWrapper>
    );
};

export default memo(TwfFftHeader);
