import Api from "./api";

const NameplateTemplateApi = {
    filteredList: (params) => Api.get("nameplate-template", {...params}),
    list: () => Api.get("nameplate-template/list"),
    view: id => Api.get(`nameplate-template/${id}`),
    create: (data) => Api.post("nameplate-template", data),
    update: (id, data) => Api.patch(`nameplate-template/${id}`, data),
    delete: id => Api.delete(`nameplate-template/${id}`),
};

export default NameplateTemplateApi;