import React, {Component} from "react";
import PropTypes from "prop-types";
import Helper from "../../../helpers/helper";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {get as _get} from "lodash";
import {Link} from "react-router-dom";

const headersList = {
    "sensor_hex": {title: "Adapter HEX Code", sort: true},
    "wua_sensor_name": {title: "Configuration", sort: true},
    "equipment_name": {title: "Equipment", sort: true},
    "installation_point_name": {title: "Installation Point", sort: true}
};

class Table extends Component
{
    render() {
        const {auth, list, sort, onSortChange, onDelete, query} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <div className="table-scroll-wrapp tachometers">
                <table className="table table-hover tl-fixed">
                    <thead>
                    <tr>
                        {Object.keys(headersList).map(key => {
                            let component = "";
                            const RowComponent = _get(headersList, [key, "component"], false);
                            if (RowComponent) {
                                component = <RowComponent sort={sort} resetSort={() => onSortChange("")}/>;
                            }
                            return (
                                listLen && headersList[key].sort
                                    ? (
                                        <th className={headersList[key].additionalClasses || ""} key={key} onClick={() => onSortChange(key)}>
                                            <div className="title">
                                                <span>{headersList[key].title}</span>
                                                {sort.field === key ? <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")}/> : <i className={"fa fa-sort"} />}
                                            </div>
                                        </th>
                                    )
                                    : <th className={headersList[key].additionalClasses || ""} width={_get(headersList[key], "width", "")} key={key}>
                                        <span className="title">{headersList[key].title}</span> {component}
                                    </th>
                            );
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {list.length > 0
                        ?
                        list.map((item) =>
                            <Row
                                query={query}
                                key={item.id}
                                item={item}
                                auth={auth}
                                onDelete={onDelete}
                            />
                        )
                        :
                        <tr>
                            <td colSpan={listLen} className="text-center text-info">
                                {query !== "" ? "No items match your search." : "No WUA sensors were found."}
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

Table.propTypes = {
    auth: PropTypes.object,
    list: PropTypes.array,
    sort: PropTypes.object,
    onSortChange: PropTypes.func,
    onDelete: PropTypes.func,
    query: PropTypes.string,
};

const Row = ({item, query}) => {

    return (
        <tr>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(_get(item, "sensor_hex", "---"), query)}} />
            <td>{_get(item, "installationPoint.wuaSensor.name", "---")}</td>
            <td>{_get(item, "installationPoint.equipment.name", "---")}</td>
            <td style={{ textAlign: "left" }}>
                {_get(item, "installationPoint.id")
                    ? <Link
                        to={`/chart/${_get(item, "installationPoint.equipment.id")}#sensors=${_get(item, "installationPoint.id")}`}
                        className="link link-primary"
                        title="View Installation Point"
                        dangerouslySetInnerHTML={{__html: _get(item, "installationPoint.name")}}
                        style={{margin: 0}}
                    />
                    : <i className="ml-2">Not set</i>}
            </td>
        </tr>
    );
};

Row.propTypes = {
    item: PropTypes.object,
    query: PropTypes.string,
    auth: PropTypes.object,
    onDelete: PropTypes.func,
};

export default withGlobalStore(Table);