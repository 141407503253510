import React, {Component} from "react";
import PropTypes from "prop-types";
import SelectWrapper from "../../helpers/select-wrapper";

class LimitSelect extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const {name, onChange, defaultValue, limits} = this.props;
        return (
            <SelectWrapper
                name={name}
                defaultValue={defaultValue}
                onChange={onChange}
                style={{width:"100px"}}
            >
                {limits.length &&
                    limits.map(limit => <option key={limit} value={limit}>{limit}</option>)
                }
            </SelectWrapper>
        );
    }
}
LimitSelect.propTypes = {
    onChange: PropTypes.func,
    defaultValue: PropTypes.any,
    name: PropTypes.string,
    limits: PropTypes.array,
};

export default LimitSelect;