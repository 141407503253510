import {useQuery} from "@tanstack/react-query";
import {TACHOMETER_API} from "./constants";
import ApiReading from "../../../api/reading";
import {useMemo} from "react";
import {get as _get} from "lodash";
import {useChartSelectedRangeStore} from "../../../stores/zustand/ChartSelectedRangeStore";

// eslint-disable-next-line no-unused-vars
export const useTachometerQuery = (equipment, enabled = true) => {
    const {from, to, range} = useChartSelectedRangeStore();

    const {data, ...rest} = useQuery(
        [TACHOMETER_API.GET_TACHOMETER, equipment.id],
        () =>
            ApiReading.getTachData({
                equipmentId: equipment.id,
                from,
                to,
                range,
            }),
        {
            enabled,
        }
    );

    const tachometers = useMemo(() => (data ? data[equipment.id] : {}), [data, equipment.id]);
    const tachReadings = useMemo(() => _get(tachometers, "readings", []), [tachometers]);
    const tachTriggers = useMemo(() => _get(tachometers, "triggers", []), [tachometers]);

    return {rawData: tachometers, tachTriggers, tachReadings, ...rest};
};
