import {useQueries} from "@tanstack/react-query";
import ApiReading from "../../../api/reading";
import {useMemo} from "react";
import {forEach as _forEach} from "lodash";
import {AMBIENT_API} from "./constants";
import {useQueriesStatus} from "../../useQueriesStatus";
import {useChartSelectedRangeStore} from "../../../stores/zustand/ChartSelectedRangeStore";
export const useAmbientQuery = (nodes) => {
    const {range, from, to} = useChartSelectedRangeStore();

    const queries = useMemo(() => {
        const queries = [];
        _forEach(nodes, (installationPoint) => {
            queries.push({
                queryKey: [
                    AMBIENT_API.GET_AMBIENT,
                    {
                        nodeId: installationPoint.sensor.node_id,
                        from,
                        to,
                        installationPointId: installationPoint.id,
                        range,
                    },
                ],
                queryFn: () => ApiReading.getAmbientData({installationPointId: installationPoint.id, from, to, range}).then(({data}) => data),
                select: (res) => ({
                    readings: res[installationPoint.sensor.node_id],
                    nodeId: installationPoint.sensor.node_id,
                    installationPointId: installationPoint.id,
                    installationPoint: installationPoint,
                }),
            });
        });
        return queries;
    }, [nodes, from, to, range]);

    const result = useQueries({queries});

    return useQueriesStatus(result);
};
