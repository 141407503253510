import React, {useEffect, useRef, useState} from "react";
import {Modal} from "../../../shared";
import PropTypes from "prop-types";
import Toast from "../../../pages/shared/toast";
import {get as _get} from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import InstallationPointApi from "../../../api/installationPoint";
import EquipmentApi from "../../../api/equipment";
import Loader from "../../../shared/loader/loader";
import InstallationPoints from "../formNew/InstallationPoints";
import WuaSensorApi from "../../../api/wuaSensor";
import {withGlobalStore} from "../../../stores/GlobalStore";
import InstallationPointForm from "../forms/InstallationPointForm";
import {CreateInstallationPointForm} from "../forms/CreateInstallationPointForm";

const EditInstallationPointsModalContent = (props) => {
    const pointsBlockRef = useRef();
    const [state, setState] = useState({
        showCreateForm: false,
        deleteInstallationPointId: false,
        inProgress: false,
        wuaSensorList: [],
    });

    useEffect(() => {
        const {afterUpdate} = props;
        setState((prev) => ({...prev, inProgress: true}));

        Promise.all([afterUpdate(), updateWuaSensorList()]).then(() => {
            setState((prev) => ({...prev, inProgress: false}));
        });
    }, []);

    const updateWuaSensorList = () => {
        if (!props.auth.userCan("manageAdapter")) {
            return true;
        }
        return WuaSensorApi.getList().then((response) => {
            setState((prev) => ({
                ...prev,
                wuaSensorList: response.list || [],
            }));
        });
    };

    const toggleCreateForm = () => {
        setState((prev) => ({...prev, showCreateForm: !state.showCreateForm}));
        pointsBlockRef.current.scrollIntoView();
    };

    const afterCreate = () => {
        const {afterUpdate} = props;

        afterUpdate().then(() => {
            toggleCreateForm();
        });
    };

    const onDelete = (id) => {
        setState((prev) => ({...prev, deleteInstallationPointId: id}));
    };

    const deleteInstallationPoint = () => {
        const {deleteInstallationPointId} = state;
        const {afterUpdate, deleteSelectedInstallationPoint, equipmentId} = props;

        setState((prev) => ({...prev, inProgress: true}));

        EquipmentApi.checkService(equipmentId).then(() =>
            InstallationPointApi.delete(deleteInstallationPointId).then(() => {
                afterUpdate().then(() => {
                    deleteSelectedInstallationPoint(deleteInstallationPointId, equipmentId);
                    Toast.success("The installation point has been deleted.");
                    onDelete(null);
                    setState((prev) => ({...prev, inProgress: false}));
                });
            })
        );
    };

    const {showCreateForm, deleteInstallationPointId, wuaSensorList} = state;
    const {installationPoints, equipmentId, equipmentName, onClose, onUpdate, afterUpdate, auth, onChange, formErrors, isRouteCollector} = props;

    const progress = state.inProgress || props.inProgress;

    return (
        <>
            <Modal
                showModal={true}
                className="edit-installation-point-modal"
                title={`Sensors for ${equipmentName}`}
                size={"lg"}
                withoutSubmit={auth.userCan("manageAdapter")}
                header={
                    auth.userCan("manageAdapter") ? (
                        ""
                    ) : (
                        <ModalHeader
                            progress={progress}
                            toggleCreateForm={!showCreateForm ? toggleCreateForm : () => {}}
                        />
                    )
                }
                onClose={onClose}
                onSubmit={onUpdate}
                disableSubmit={showCreateForm || progress}
            >
                {progress ? (
                    <Loader />
                ) : auth.userCan("manageAdapter") ? (
                    <InstallationPoints
                        equipmentId={equipmentId}
                        installationPoints={installationPoints}
                        afterUpdate={afterUpdate}
                        wuaSensorList={wuaSensorList}
                        updateWuaSensorList={updateWuaSensorList}
                    />
                ) : (
                    <div
                        ref={pointsBlockRef}
                        className="installation-points-block"
                    >
                        {showCreateForm && (
                            <CreateInstallationPointForm
                                afterCreate={afterCreate}
                                onCancel={toggleCreateForm}
                                isRouteCollector={isRouteCollector}
                            />
                        )}
                        {installationPoints.map((installationPoint, index) => (
                            <InstallationPointForm
                                key={index}
                                installationPoint={installationPoint}
                                onChange={(e) => {
                                    onChange(e, index);
                                }}
                                removeButton={true}
                                onRemoveButton={() => onDelete(_get(installationPoint, "id"))}
                                formErrors={_get(formErrors, "point." + index)}
                                isRouteCollector={isRouteCollector}
                            />
                        ))}
                    </div>
                )}
                {deleteInstallationPointId && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title="DELETE INSTALLATION POINT"
                        openAnim={false}
                        closeAnim={false}
                        onConfirm={() => deleteInstallationPoint()}
                        onCancel={() => onDelete(false)}
                        disabled={progress}
                    >
                        Are you sure you want to delete this installation point?
                    </SweetAlert>
                )}
            </Modal>
        </>
    );
};

const ModalHeader = ({toggleCreateForm, progress}) =>
    !progress && (
        <span
            onClick={toggleCreateForm}
            className="add-installation-point-btn"
        >
            <i className="fa fa-plus-circle" /> Add new installation point
        </span>
    );

ModalHeader.propTypes = {
    toggleCreateForm: PropTypes.func,
    progress: PropTypes.bool,
};

EditInstallationPointsModalContent.propTypes = {
    equipmentId: PropTypes.number,
    equipmentName: PropTypes.string,
    afterUpdate: PropTypes.func,
    deleteSelectedInstallationPoint: PropTypes.func,
    onClose: PropTypes.func,

    // From BatchInstallationPointData
    installationPoints: PropTypes.array,
    formErrors: PropTypes.object,
    inProgress: PropTypes.bool,
    onUpdate: PropTypes.func,
    onChange: PropTypes.func,

    auth: PropTypes.object,
    isRouteCollector: PropTypes.bool,
};

export default withGlobalStore(EditInstallationPointsModalContent);
