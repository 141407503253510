import { useModal } from "react-modal-hook";
import SpeedSettingsModal from "../components/speedSettings";
import React from "react";
import { useModalHistory } from "../../../hooks/helpers/useModalHistory";
import { MODALS } from "../../../constants/modals";

export const useSpeedSettings = () => {
    const [showModal, hideModal] = useModal(() => (
        <SpeedSettingsModal showModal={true} onClose={onHide} />
    ));

    const { pushHistory, deleteHistory } = useModalHistory(MODALS.SPEED_SETTINGS, showModal);

    const onShow = () => {
        pushHistory();
        showModal();
    };

    const onHide = () => {
        deleteHistory();
        hideModal();
    };

    return [onShow, onHide];
};
