import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Helper from "../../helpers/helper";

const LocationsTab = ({list, query}) => {

    if (!(list || []).length) {
        return (<div className="row">
            <div className="col">
                <span className="text-center text-info" style={{display: "block"}}>No items match your search.</span>
            </div>
        </div>);
    }

    return ( <div className="row">
        {  list.map(item =>
            <Link
                key={item.id}
                to={{pathname: `/equipments/${item.id}`, equipment: item}}
                className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-4 equipment-card-link"
            >
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="k-bg-metal pt-60 mb-3 equpment-list-photo" style={{
                                    background: "#fff"
                                }}>
                                    <img src="/assets/img/default-equipment-bg.png"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h5
                                    title={item.title}
                                    className="card-title ellipsis-text"
                                    dangerouslySetInnerHTML={{
                                        __html:"Asset Tree Branch: " + Helper.highlight(item.title, query)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p title={item.level_name} className="cad-text  ellipsis-text">
                                    <b>Level name: </b>{item.level_name}
                                </p>
                                <p title={item.updated_at} className="cad-text ellipsis-text">
                                    <b>Updated at: </b>{item.updated_at}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="card-text"><b>Equipments: </b>{item.equipments}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        )}
    </div>);
};

LocationsTab.propTypes = {
    list: PropTypes.array,
    query: PropTypes.string
};

export default LocationsTab;