import {useModal} from "react-modal-hook";
import EquipmentProfileModal from "../components/equipment-profile-modal";
import {useHistory} from "react-router";
import React from "react";
import {useModalHistory} from "../../../hooks/helpers/useModalHistory";
import {MODALS} from "../../../constants/modals";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useEquipmentInvalidate} from "../../../hooks/api/equipment/useEquipmentInvalidate";

export const useEquipmentProfileModal = () => {
    const history = useHistory();

    const {equipmentItem} = useEquipmentByParams();
    const invalidateEquipment = useEquipmentInvalidate(equipmentItem.id);

    const [showModal, hideModal] = useModal(() => (
        <EquipmentProfileModal
            showModal={true}
            history={history}
            onClose={onHide}
            equipment={equipmentItem}
            invalidateEquipment={invalidateEquipment}
        />
    ),[equipmentItem]);

    const {pushHistory, deleteHistory} = useModalHistory(MODALS.EQUIPMENT_PROFILE, showModal);

    const onShow = () => {
        pushHistory();
        showModal();
    };

    const onHide = () => {
        deleteHistory();
        hideModal();
    };

    return [onShow, onHide];
};
