import { useQuery } from "@tanstack/react-query";
import ApiAlarm from "../../../api/alarm";
import {get as _get} from "lodash";
import {ALARM_API} from "./constants";
// import {useEquipmentByParams} from "../equipment/useEquipmentQuery";

export const useAlarmList = (equipmentId) => {
    const { isSuccess, isRefetching, data: alarmList } = useQuery(
        [ALARM_API.GET_ALARM_LIST, +equipmentId], () => ApiAlarm.getList(
            {
                query: {
                    filter: {
                        equipmentId: +equipmentId,
                    },
                    count: 1000,
                }
            }
            , (response) => response.data), {
            enabled: !!equipmentId
        }
    );

    let activeAlarms= [];
    let settingsStates= {};
    let countActiveEquipmentAlarms= 0;

    if(isSuccess) {
        activeAlarms = alarmList.list || [];
        settingsStates = alarmList.settingsStates || {};
        countActiveEquipmentAlarms = +_get(alarmList, "meta.total", 0);
    }


    return {
        isSuccess,
        isRefetching,
        activeAlarms,
        settingsStates,
        countActiveEquipmentAlarms
    };
};

// export const useAlarmListByParams = () => {
//     const { equipmentItem } = useEquipmentByParams();
//
//     const { isSuccess, data: alarmList } = useQuery(
//         [ALARM_API.GET_ALARM_LIST, +equipmentItem.id], () => ApiAlarm.getList(
//             {
//                 query: {
//                     filter: {
//                         equipmentId: +equipmentItem.id,
//                     },
//                     count: 1000,
//                 }
//             }
//             , (response) => response.data), {
//             enabled: !!+equipmentItem.id
//         }
//     );
//
//     let activeAlarms= [];
//     let settingsStates= {};
//     let countActiveEquipmentAlarms= 0;
//
//     if(isSuccess) {
//         activeAlarms = alarmList.list || [];
//         settingsStates = alarmList.settingsStates || {};
//         countActiveEquipmentAlarms = +_get(alarmList, "meta.total", 0);
//     }
//
//
//     return {
//         isSuccess,
//         activeAlarms,
//         settingsStates,
//         countActiveEquipmentAlarms
//     };
// };