import React from "react";
import PropTypes from "prop-types";
import {SENSOR_TYPES} from "../../constants/constants";
import {ValidationError} from "../../shared";
import {
    get as _get,
    includes as _includes
} from "lodash";
import SelectWrapper from "../../helpers/select-wrapper";

const LinesOfResolutionSelect = ({value, name, onChange, versionType, specs, isHFDvue = false, disabled = false, errorMsg, needUpdateOnNotValidValue}) =>  {
    // const inputRef = useRef(null);

    const getDefaultValues = () => {

        let linesOfResolution = [];

        if (isHFDvue) {
            linesOfResolution = [128, 256, 512, 1024, 2048];
        } else if (versionType === SENSOR_TYPES.V1) {
            linesOfResolution = [1024, 2048, 4075, 4096];
        } else if (versionType === SENSOR_TYPES.V2) {
            linesOfResolution = [1024, 2048, 3750, 4096];
        } else if (_includes([12, 13, 15], +versionType)) {
            linesOfResolution = [1024, 2048, 4096, 8192, 16666];
        } else {
            for (let i = 1024; i <= (specs || {}).lines_of_resolution; i *= 2) {
                linesOfResolution.push(i);
            }
        }

        return linesOfResolution;
    };

    const linesOfResolutionValues = getDefaultValues();

    let defaultValue = value;
    if (!defaultValue || !_includes(linesOfResolutionValues, +defaultValue)) {
        defaultValue = _get(linesOfResolutionValues, 1);
    }

    if (needUpdateOnNotValidValue && defaultValue !== value) {
        onChange({
            target: {
                name: name || "lines_of_resolution",
                value: defaultValue
            }
        });
    }

    return (
        <>
            <SelectWrapper
                // ref={inputRef}
                className={(errorMsg ? " is-invalid" : "")}
                name={name || "lines_of_resolution"}
                onChange={onChange}
                disabled={disabled}
                value={defaultValue}
            >
                <option value="" disabled>Lines of resolution</option>
                {linesOfResolutionValues.map((value) => (
                    <option key={`lines-of-resolution-value-${value}`} value={value}>{value}</option>
                ))}
            </SelectWrapper>
            <ValidationError message={errorMsg}/>
        </>

    );
};


LinesOfResolutionSelect.propTypes = {
    isInput: PropTypes.bool,
    isHFDvue: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    versionType: PropTypes.number,
    specs: PropTypes.object,
    errorMsg: PropTypes.string,
    needUpdateOnNotValidValue: PropTypes.bool
};

export default LinesOfResolutionSelect;