import useCustomChartTypes from "../../../hooks/api/Chart/UseCustomChartTypes";
import {useRouteMatch} from "react-router";
import {useChartSelectedChartTypeActions, useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import {useEffect} from "react";

export const useAdapterTypes = () => {
    const match = useRouteMatch();
    const chartTypes = useChartTypesStore();

    const {setAdapterChartTypes} = useChartSelectedChartTypeActions();

    const {isSuccess: customsChartTypesLoader, customChartTypes} = useCustomChartTypes(match.params.equipmentId, chartTypes);

    useEffect(() => {
        if (!customsChartTypesLoader) {
            return;
        }
        setAdapterChartTypes(customChartTypes);
    }, [customChartTypes, customsChartTypesLoader, match.params.equipmentId]);

    return {customsChartTypesLoader};
};
