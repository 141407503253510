import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal} from "../shared";
import GatewayBatchAssignForm from "../pages/forms/gateway-batch-assign";
import ApiGateways from "../api/gateway";
import Loader from "../shared/loader/loader";
import {
    get as _get,
    set as _set,
    omit as _omit,
    each as _each
} from "lodash";
import GatewayBatchAssignApi from "../api/gatewayBatchAssign";
import Toast from "../pages/shared/toast";

export const MOTE_DEVICE = "sensor mote";
export const NODE_DEVICE = "node";
export const REPEATER_DEVICE = "repeater";

const DEVICE_NAMES = {
    [MOTE_DEVICE]: "Sensor Motes",
    [NODE_DEVICE]: "Nodes",
    [REPEATER_DEVICE]: "Repeaters",
};

class GatewayBatchAssignModal extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            gateways: [],
            loader: true,
            inProgress: false,
            data: {
                gateway_id: "",
                serials: [],
                serialsText: "",
                confirmFlag: {},
            },
            formErrors: {}
        };
    }

    componentDidMount() {
        this.handleGateways();
    }

    handleGateways = () => {
        return ApiGateways
            .list({query: {pagination: {page: 1, perpage: 100}}})
            .then(({list}) => {
                this.setState({gateways: list, loader: false});
            });
    }

    onChange = (event) => {
        const key = _get(event, "target.name");
        const value = _get(event, "target.value");

        this.removeError(key);

        this.updateData(key, value);
    }

    updateData = (key, value) => {
        let data = {...this.state.data};

        _set(data, key, value);

        this.setState({data});
    }

    removeError = (key) => {
        const oldFormErrors = {...this.state.formErrors};

        let formErrors = _omit(oldFormErrors, key);

        if (key === "serialsText") {
            formErrors = oldFormErrors.gateway_id ? {gateway_id: oldFormErrors.gateway_id} : {};
        }

        this.setState({formErrors});
    }

    onSubmit = () => {
        const {data} = this.state;
        const {onClose, updateList, type} = this.props;

        if (this.validate()) {
            const serialsArray = this.buildSerialsArray(data.serialsText);

            this.setState({data: {...data, ...{serials: serialsArray}}, formErrors: {}, inProgress: true}, () => {
                const postData = {
                    gateway_id: data.gateway_id,
                    confirm_flag: data.confirmFlag,
                    serials: this.buildSerialsArray(data.serialsText)
                };

                this.sendRequest(postData)
                    .then(() => {
                        Toast.success("The " + type.toLowerCase() + "s have been associated with the gateway.");
                        onClose();
                        updateList();
                    })
                    .catch(response => {
                        if(!response.errors){
                            Toast.error("Something went wrong. The " + type.toLowerCase() + "s have not been associated with the gateway.");
                        }
                        this.setState({formErrors: response.errors || {}, inProgress: false});
                    });
            });

        }
    }

    sendRequest = (postData) => {
        const {type} = this.props;

        if (type === REPEATER_DEVICE) {
            return GatewayBatchAssignApi.routers(postData);
        } else if (type === NODE_DEVICE) {
            return GatewayBatchAssignApi.nodes(postData);
        } else if (type === MOTE_DEVICE) {
            return GatewayBatchAssignApi.motes(postData);
        }
    }

    buildSerialsArray = (serialsText) => {
        const serialsArray = serialsText.split(",");
        let result = [];

        _each(serialsArray, (serial) => {
            if (serial.trim()) {
                result.push(serial.trim());
            }
        });

        return result;
    }

    validate = () => {
        const {data} = this.state;
        let formErrors = {};
        let valid = true;

        if (!data.gateway_id) {
            _set(formErrors, "gateway_id", "This field is required.");
            valid = false;
        }

        if (!data.serialsText) {
            _set(formErrors, "serialsText", "This field is required.");
            valid = false;
        }

        this.setState({formErrors});

        return valid;
    }

    setConfirmFlag = (flag, status) => {
        let oldData = {...this.state.data};
        if (status) {
            oldData.confirmFlag[flag] = flag;
        } else {
            delete oldData.confirmFlag[flag];
        }
        this.updateData("confirmFlag", oldData.confirmFlag);
    }

    render() {
        const {data, loader, gateways, inProgress, formErrors} = this.state;
        const {type} = this.props;

        return (
            <React.Fragment>

                <Modal
                    {...this.props}
                    size="lg"
                    title={"Batch Assign " + DEVICE_NAMES[type]}
                    onSubmit={this.onSubmit}
                    inProgress={inProgress}
                >
                    {loader
                        ?
                        <Loader/>
                        :
                        <GatewayBatchAssignForm
                            inProgress={inProgress}
                            gateways={gateways}
                            data={data}
                            onChange={this.onChange}
                            formErrors={formErrors}
                            setConfirmFlag = {this.setConfirmFlag}
                            deviceNames={DEVICE_NAMES[type]}
                        />
                    }
                </Modal>
            </React.Fragment>
        );
    }
}

GatewayBatchAssignModal.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
    updateList: PropTypes.func,
    type: PropTypes.string
};

export default GatewayBatchAssignModal;