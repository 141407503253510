export const getAssetTreeBranchParentByChildId = (assetTreeBranch, childId) => {
    let foundId;

    if (!assetTreeBranch) {
        return false;
    }

    assetTreeBranch.forEach((branch) => {
        if (foundId) {
            return;
        }

        if (!branch.children?.length) {
            return;
        }

        if (branch.children.some((child) => child.id === childId)) {
            foundId = branch.id;
            return;
        }

        foundId = getAssetTreeBranchParentByChildId(branch.children, childId, branch.id);
    });

    return foundId;
};
