import {useCurrentAlertCondition, useCurrentAlertGroup, useSelectedTab} from "../../../../../stores/zustand/AlertConditionStore";
import {ALERT_TABS_NAME} from "../../../constants/tabs";
import {isEmpty as _isEmpty} from "lodash";
import {useSimpleChartSeriesBuilder} from "../../../../../hooks/factory/useSimpleChartSeriesBuilder";
import {getSeriesList} from "../../../../../pages/chart/highcharts/helpers/getSeriesList";
import {useChartTypesStore} from "../../../../../stores/zustand/ChartSelectedChartTypeStore";
import {useChartComputed} from "../../../hooks/helpers/chart";
import {useEquipmentByParams} from "../../../../../hooks/api/equipment/useEquipmentQuery";

export const useSeriesBuilder = () => {
    const currentAlertGroup = useCurrentAlertGroup();
    const {equipmentItem: equipment} = useEquipmentByParams();
    const currentAlertCondition = useCurrentAlertCondition();
    const selectedTab = useSelectedTab();
    const chartTypes = useChartTypesStore();
    const seriesList = getSeriesList(currentAlertCondition.chartType, +currentAlertCondition.axisId, chartTypes);
    const {axisId} = useChartComputed(chartTypes, equipment);

    let installPoints = [];
    if (!_isEmpty(currentAlertCondition)) {
        installPoints = selectedTab === ALERT_TABS_NAME.DELTA_T ? currentAlertGroup.points.map((i) => i) : [currentAlertCondition?.installationPoint];
    }

    return useSimpleChartSeriesBuilder({
        chartType: currentAlertCondition.chartType,
        axisIds: seriesList.selected,
        pointsData: installPoints,
        deltaFlag: selectedTab === ALERT_TABS_NAME.DELTA_T,
        axisIdsComputed: [axisId],
        manageAlertModal: true,
        axisCurrent: axisId,
    });
};
