import { useCreateChartStateStore } from "../store/chartStateStore";
import React, { useRef } from "react";
import {
    ChartCurrentPointRefContext,
    ChartRefContext,
    ChartStateStore,
    LockPointsContext,
} from "../context/context";

// eslint-disable-next-line react/display-name
export const WithChartLocalStore = (Component) => (props) => {
    const chartStateStore = useCreateChartStateStore(props);
    const lockPoints = useRef([]);
    const chartRef = useRef(null);
    const currentPointRef = useRef(null);
    return (
        <ChartCurrentPointRefContext.Provider value={currentPointRef}>
            <ChartStateStore.Provider value={chartStateStore}>
                <LockPointsContext.Provider value={lockPoints}>
                    <ChartRefContext.Provider value={chartRef}>
                        <Component {...props} />
                    </ChartRefContext.Provider>
                </LockPointsContext.Provider>
            </ChartStateStore.Provider>
        </ChartCurrentPointRefContext.Provider>
    );
};
