import {useModal} from "react-modal-hook";
import ManageAxisModal from "../components/manageAxis";
import React from "react";
import {useModalHistory} from "../../../hooks/helpers/useModalHistory";
import {MODALS} from "../../../constants/modals";

export const useManageAxis = () => {
    const [showModal, hideModal] = useModal(() => {
        return <ManageAxisModal onClose={onHide} />;
    });
    const {pushHistory, deleteHistory} = useModalHistory(MODALS.MANAGE_AXIS, showModal);

    const onShow = (selected) => {
        pushHistory({selected});
        showModal();
    };

    const onHide = () => {
        deleteHistory();
        hideModal();
    };

    return [onShow, onHide];
};
