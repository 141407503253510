import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {get as _get} from "lodash";
import ReadingTypeBlock from "../reading-type-block";

import "../../../assets/scss/components/installation-group/installation-point.scss";

class InstallationPoint extends Component {

    render() {
        const {installationPoint = {}, equipment} = this.props;
        const readingTypes = _get(installationPoint, "readingTypes", []);
        const hasReadingTypes = Object.keys(readingTypes).length > 0;

        return (
            <Link style={{borderColor: _get(installationPoint, "highestLevel.color")}} className={"installation-point-block"} to={`/chart/${_get(equipment, "id")}#sensors=${_get(installationPoint, "id")}`}>
                <div>
                    <div className={"inst-point-title font-weight-bold"}><span>{_get(installationPoint, "name") || "Unnamed"}</span></div>
                    <div className="equipment-name-title">
                        <span>{_get(equipment, "name")}</span>
                    </div>
                    <div>
                        {hasReadingTypes > 0 &&
                            Object.keys(readingTypes).map((key, index) => {
                                return <ReadingTypeBlock
                                    showPopover={false}
                                    key={index}
                                    opened={false}
                                    equipmentId={equipment.id}
                                    chartTypeId={parseInt(key)}
                                    title={readingTypes[key].label || "None"}
                                    name={readingTypes[key].short || "None"}
                                    level={readingTypes[key].alertLevel}
                                />;
                            })
                        }
                    </div>
                </div>
            </Link>
        );
    }
}
InstallationPoint.propTypes = {
    installationPoint: PropTypes.object,
    equipment: PropTypes.object
};
export default InstallationPoint;