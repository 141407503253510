import ChartMarkFftModal from "../components/chartMarkFft";
import React from "react";
import {useModal} from "react-modal-hook";
import {useHistory} from "react-router";
import {useChartStateStoreContext} from "../../../pages/chart/highcharts/twf/store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useCurrentPointRef} from "../../../pages/chart/highcharts/twf/hooks/useCurrentPointRef";
import {useChartMarksCreateMutation} from "../../../hooks/api/chartMarksFft/useChartMarksCreateMutation";
import {useChartMarksUpdateMutation} from "../../../hooks/api/chartMarksFft/useChartMarksUpdateMutation";
import {useChartMarksQuery} from "../../../hooks/api/chartMarksFft/useChartMarksQuery";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useCurrentTimestampsInfo} from "../../../pages/chart/highcharts/twf/hooks/helpers/useCurrentTimestampsInfo";

export const useChartMarkFftModal = () => {
    const {selectedPoint, editChartMarkFftId} = useChartStateStoreContext((state) => state.settings, shallow);
    const {setChartMarkEditId} = useChartStateStoreContext((state) => state.actions);
    const currentPoint = useCurrentPointRef().current;
    const {equipmentItem} = useEquipmentByParams();
    const {chartMarksData} = useChartMarksQuery(equipmentItem.id);
    const currentTimestamps = useCurrentTimestampsInfo();

    const {mutateAsync: createChartMark} = useChartMarksCreateMutation(equipmentItem.id);
    const {mutateAsync: updateChartMark} = useChartMarksUpdateMutation(equipmentItem.id, +editChartMarkFftId);

    const history = useHistory();
    const [showModal, hideModal] = useModal(() => {
        return (
            <ChartMarkFftModal
                equipment={equipmentItem}
                chartMarkFftId={+editChartMarkFftId}
                selectedPoint={selectedPoint}
                chartMarksFft={chartMarksData}
                showModal={true}
                history={history}
                onClose={onHide}
                frequency={currentPoint?.x || 0}
                currentDate={`${currentTimestamps.date} ${currentTimestamps.time}`}
                onCreate={createChartMark}
                onUpdate={updateChartMark}
            />
        );
    }, [currentPoint, editChartMarkFftId, currentTimestamps]);
    const onHide = () => {
        hideModal();
        setChartMarkEditId(null);
    };
    return [showModal, onHide];
};
