import React, {useEffect, useRef, useState} from "react";
import FormValidator from "../../helpers/form-validator";
import {ValidationError} from "../../shared";
import PropTypes from "prop-types";
import {DATEFORMAT} from "../../constants/constants";
import ApiUser from "../../api/user";
import {
    get as _get,
    set as _set,
} from "lodash";
import Converter from "../../helpers/converter";
import ApiTimezone from "../../api/timezone";
import Toast from "../shared/toast";
import SelectWrapper from "../../helpers/select-wrapper";
import TwoFactorAuthentication from "./two-factor-authentication";

const propTypes = {
    user: PropTypes.object,
    chartTypes: PropTypes.object,
    auth: PropTypes.object,
};

const rules = [
    {
        field: "username",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required. "
    },
    {
        field: "name_first",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required. "
    },
    {
        field: "name_last",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required. "
    },
    {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required. "
    },
    {
        field: "email",
        method: "isEmail",
        validWhen: true,
        message: "E-mail is not a valid."
    },
    {
        field: "contact_email",
        method: "isEmail",
        validWhen: true,
        skipOnEmpty: true,
        message: "Contact E-mail is not a valid."
    },
];

const PersonalInfoForm = (props) => {

    const validator = new FormValidator(rules);
    const {user} = props;

    const ref = useRef(null);

    const [data, setData] = useState({
        id: _get(user, "id", ""),
        username: _get(user, "username", ""),
        name_first: _get(user, "name_first", ""),
        name_last: _get(user, "name_last", ""),
        email: _get(user, "email", ""),
        contact_email: _get(user, "contact_email", ""),
        phone: _get(user, "phone", ""),
        timezone_id: _get(user, "timezone_id", ""),
        use_metric: _get(user, "use_metric", ""),
        personal_settings: {
            units: Converter.convertUnitsSettingsToMetricList(props.chartTypes, {..._get(user , "personal_settings.units", {})}),
            datetime_format: _get(user , "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0])
        },
        userpic: _get(user, "userpic", ""),
        use_email_alarms: _get(user, "use_email_alarms"),
        use_phone_alarms: _get(user, "use_phone_alarms"),
        image: "",
    });

    const [avatar, setAvatar] = useState({
        base64: "",
        userpic: _get(user, "userpic", ""),
        showRemove: true,
        showCancel: false
    });

    const [inProgress, setInProgress] = useState(false);
    const [validation, setValidation] = useState(validator.valid());
    const [timezones, setTimezones] = useState([]);
    const [showContactEmail, setShowContactEmail] = useState((_get(user, "email") || "").indexOf("+") !== -1 || _get(user, "contact_email"));


    useEffect(() => {
        ApiTimezone.list().then(timezones => {
            setTimezones(timezones);
        });
    }, []);


    const onChange = (e) => {
        const {user} = props;

        if((_get(e, "target.name", "") === "email")){
            let value = _get(e, "target.value", "");
            if (value.indexOf("@") !== -1) {
                value = value.split("@").shift();
            }
            setShowContactEmail(value.indexOf("+") !== -1 || user.contact_email);
        }

        _set(data, _get(e, "target.name", ""), _get(e, "target.value", ""));
        const validation = validator.validate(data, _get(e, "target.name", ""));

        if(_get(e, "target.name", "") === "use_metric"){
            if(+_get(e, "target.value", "") === 0){
                data.personal_settings.units.map((metricItem) => {
                    metricItem.selected = "metric";
                });
            }

            if(+_get(e, "target.value", "") === 1){
                data.personal_settings.units.map((metricItem) => {
                    metricItem.selected = "us";
                });
            }
        }

        setData(data);
        setValidation(validation);
        setShowContactEmail(showContactEmail);
    };

    const onUploadUserpic = (event) => {
        const userpic = URL.createObjectURL(event.target.files[0]);

        getBase64(event.target.files[0], base64 => {
            setAvatar({
                base64,
                userpic,
                showRemove: false,
                showCancel: true
            });
        });
    };

    const onCancelUploadUserpic = () => {
        setAvatar({
            base64: "",
            userpic: data.userpic,
            showRemove: true,
            showCancel: false
        });
    };


   const onRemoveUserpic = () => {
        const userpic = process.env.SSO_URL + "/images/placeholder-user.png";

        setData({...data, userpic});
        setAvatar({
            base64: "",
            userpic: userpic,
            showRemove: true,
            showCancel: false
        });
    };

    const getBase64 = (file, cb) => {
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => cb(reader.result);
            reader.onerror = error => console.log("Error: ", error);
        }
    };

    const onSave = () => {
        const validation = validator.validate(data);
        setValidation(validation);

        if (validation.isValid) {
            setInProgress(true);
            const convertMetricListToUnitsSettings = Converter.convertMetricListToUnitsSettings(data.use_metric, data.personal_settings.units, props.chartTypes);

            ApiUser.updateProfile({
                ...data,
                personal_settings:{
                    ...data.personal_settings,
                    units: convertMetricListToUnitsSettings},
                image: avatar.base64
            }, props.chartTypes).then(response => {
                data.full_name = data.name_first + " " + data.name_last;

                setData({
                    ...data,
                    personal_settings:{
                        ...data.personal_settings,
                        units: Converter.convertUnitsSettingsToMetricList(props.chartTypes, convertMetricListToUnitsSettings)
                    },
                    userpic: response.userpic
                });

                setInProgress(false);
                onCancelUploadUserpic();

                props.user.update(data);
                props.auth.setToken();

                Toast.success("Your personal information has been updated.");
            }).catch(err => {
                if (err.errors) {
                    Object.keys(err.errors).map(key => {
                        validation[key].isValid = false;
                        validation[key].message = err.errors[key][0];
                    });

                    setValidation(validation);
                    setInProgress(false);
                }
            });
        }
    };

    return (
        <div className="block">
            <div className="block-header">
                <div className="block-label">
                    <h5 className="block-title mb-0">Personal Information <small>update your personal information</small></h5>
                </div>
            </div>
            <form
                ref={ref}
                className="form user-profile-form"
                onSubmit={e => {e.preventDefault(); onSave();}}
            >
                <div className="block-body">
                    <div className="form-group row">
                        <label className="col-3 form-label">Profile Picture</label>
                        <div className="col-9 col-lg-6">
                            <div className="user-profile-avatar" id="k_profile_avatar">
                                <div className="user-profile-holder" style={{backgroundImage: `url('${avatar.userpic}')`}}/>
                                <label className="user-profile-upload" title="Update profile picture">
                                    <i className="fa fa-pen"/>
                                    <input
                                        type="file"
                                        name="userpic"
                                        accept=".png,.jpg,.jpeg"
                                        onChange={onUploadUserpic}
                                    />
                                </label>
                                {avatar.showCancel &&
                                    <span
                                        className="user-profile-remove"
                                        title="Cancel avatar"
                                        onClick={onCancelUploadUserpic}
                                    >
                                            <i className="fa fa-times"/>
                                        </span>
                                }
                                {avatar.showRemove && data.userpic.indexOf("placeholder") === -1 &&
                                    <span
                                        className="user-profile-remove"
                                        title="Remove avatar"
                                        onClick={onRemoveUserpic}
                                    >
                                            <i className="fa fa-trash"/>
                                        </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-3 form-label">Username</label>
                        <div className="col-9 col-lg-6">
                            <input
                                className={"form-control" + (validation.username.isValid || !validation.username.message ? "" : " is-invalid")}
                                type="text"
                                name="username"
                                value={data.username}
                                onChange={onChange}
                                disabled={inProgress}
                            />
                            <ValidationError message={validation.username.message}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-3 form-label">First Name</label>
                        <div className="col-9 col-lg-6">
                            <input
                                className={"form-control" + (validation.name_first.isValid || !validation.name_first.message ? "" : " is-invalid")}
                                type="text"
                                name="name_first"
                                value={data.name_first}
                                onChange={onChange}
                                disabled={inProgress}
                            />
                            <ValidationError message={validation.name_first.message}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-3 form-label">Last Name</label>
                        <div className="col-9 col-lg-6">
                            <input
                                className={"form-control" + (validation.name_last.isValid || !validation.name_last.message ? "" : " is-invalid")}
                                type="text"
                                name="name_last"
                                value={data.name_last}
                                onChange={onChange}
                                disabled={inProgress}
                            />
                            <ValidationError message={validation.name_last.message}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-3 form-label">Contact Phone</label>
                        <div className="col-9 col-lg-6">
                            <input
                                className={"form-control" + (validation?.phone?.isValid || !validation?.phone?.message ? "" : " is-invalid")}
                                type="text"
                                name="phone"
                                value={data.phone}
                                onChange={onChange}
                                disabled={inProgress}
                            />
                            <ValidationError message={validation?.phone?.message}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-3 form-label">Email Address</label>
                        <div className="col-9 col-lg-6">
                            <input
                                className={"form-control" + (validation.email.isValid || !validation.email.message ? "" : " is-invalid")}
                                type="text"
                                name="email"
                                value={data.email}
                                onChange={onChange}
                                disabled={inProgress}
                            />
                            <ValidationError message={validation.email.message}/>
                        </div>
                    </div>
                    {showContactEmail &&
                        <div className="form-group row">
                            <label className="col-3 form-label">Contact Email Address</label>
                            <div className="col-9 col-lg-6">
                                <input
                                    title={(user.email || "").indexOf("+") !== -1 ? "Please provide a Contact Email Address since your Email Address contains a plus sign." : ""}
                                    className={"form-control" + (validation.contact_email.isValid || !validation.contact_email.message ? "" : " is-invalid")}
                                    type="text"
                                    name="contact_email"
                                    value={data.contact_email ? data.contact_email : ""}
                                    onChange={onChange}
                                    disabled={inProgress}
                                    placeholder={(user.email || "").indexOf("+") !== -1 ? "Please provide a Contact Email Address since your Email Address contains a plus sign." : ""}
                                />
                                <ValidationError message={validation.contact_email.message}/>
                            </div>
                        </div>
                    }
                    <div className="form-group row">
                        <label className="col-3 form-label">Time Zone</label>
                        <div className="col-9 col-lg-6">
                            <SelectWrapper
                                name="timezone_id"
                                value={data.timezone_id}
                                onChange={onChange}
                                disabled={inProgress}
                            >
                                {timezones.map(timezone => (
                                    <option key={timezone.id} value={timezone.id}>{timezone.title}</option>
                                ))}
                            </SelectWrapper>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-3 form-label">Date Format</label>
                        <div className="col-9 col-lg-6">
                            <SelectWrapper
                                name="personal_settings.datetime_format"
                                value={data.personal_settings.datetime_format}
                                onChange={onChange}
                                disabled={inProgress}
                            >
                                {Object.keys(DATEFORMAT).map((item) => (
                                    <option key={item} value={item}>{DATEFORMAT[item]}</option>
                                ))}
                            </SelectWrapper>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-3 form-label">Security</label>
                        <div className="col-9 col-lg-6">
                            <TwoFactorAuthentication user={user}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-3 form-label">Measurement Units</label>
                        <div className="col-9 col-lg-6">
                            <div className="form-list form-list--column mt-2">
                                <label className="form-radio form-radio--normal">
                                    <input
                                        type="radio"
                                        name="use_metric"
                                        value="0"
                                        defaultChecked={+data.use_metric === 0}
                                        onChange={onChange}
                                        disabled={inProgress}
                                    /> Metric (mm, mm/sec, °C) <span/>
                                </label>
                                <label className="form-radio form-radio--normal">
                                    <input
                                        type="radio"
                                        name="use_metric"
                                        value="1"
                                        defaultChecked={+data.use_metric === 1}
                                        onChange={onChange}
                                        disabled={inProgress}
                                    /> US (mils, in/sec, °F) <span/>
                                </label>
                                <label className="form-radio form-radio--normal">
                                    <input
                                        type="radio"
                                        name="use_metric"
                                        value="2"
                                        defaultChecked={+data.use_metric === 2}
                                        onChange={onChange}
                                        disabled={inProgress}
                                    /> Custom <span/>
                                </label>
                            </div>
                        </div>
                    </div>
                    {+data.use_metric === 2 && <hr/>}
                    <div className={+data.use_metric === 2 ? "form-group row" : "form-group row d-none"}>
                        <label className="col-3"></label>
                        <div className="col-9 col-lg-6 d-flex justify-content-between">
                            {Array.isArray(data.personal_settings.units) && data.personal_settings.units.map((metricItem,key) => {
                                return (
                                    <div className="form-list" key={key}>
                                        <div>
                                            <div>
                                                <label className="user-profile-label">{metricItem.label}</label>
                                            </div>
                                            <div>
                                                <div
                                                    style={{
                                                        alignItems: "center",
                                                        height: "100%"
                                                    }}
                                                >
                                                    {Object.keys(metricItem.units).map((metricType, keyMetricType) =>
                                                        <div className="" key={keyMetricType} >
                                                            <label className="form-radio form-radio--normal">
                                                                <input
                                                                    type="radio"
                                                                    name={"personal_settings.units." + key + ".selected"}
                                                                    onChange={onChange}
                                                                    disabled={inProgress}
                                                                    value={metricType}
                                                                    checked={_get(data, "personal_settings.units." + key + ".selected") === metricType}
                                                                />
                                                                {metricItem.units[metricType]}
                                                                <span/>
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-3"></label>
                        <div className="col-9 col-lg-6 text-right">
                            <button
                                type="button"
                                className="btn btn-success btn-sm"
                                onClick={onSave}
                                disabled={inProgress}
                            >
                                {inProgress ? <span><i className="fa fa-spinner"></i> Processing</span> : "Submit"}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

PersonalInfoForm.propTypes = propTypes;

export default PersonalInfoForm;