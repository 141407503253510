import {useCallback, useRef, useState} from "react";

let count = 0;

export const useDraggableList = ({isSorting = false, move = () => {}} = {}) => {
    const draggedElementRef = useRef(null);
    const [name] = useState(() => ++count + "");

    const [dragFrom, setDragFrom] = useState(null);

    const onDragStart = useCallback((index) => {
        setDragFrom(index);
    }, []);

    const onDrop = useCallback(
        (index) => {
            move(dragFrom, index);
        },
        [move, dragFrom]
    );

    const onDragEnd = useCallback(() => {
        setDragFrom(null);
    });

    const listItemConfig = {
        onDragStart,
        onDragEnd,
        onDrop,
        name,
        draggedElementRef,
        dragFrom,
        isSorting,
    };

    const listConfig = {
        name,
        draggedElementRef,
    };

    return {isSorting, listItemConfig, listConfig};
};
