import React, {Component} from "react";
import PropTypes from "prop-types";
import {get as _get} from "lodash";
import ParametersPopover from "./popovers/parameters";
import EquipmentsPopover from "./popovers/equipments";
import InstallationPointsPopover from "./popovers/installationPoints";
import Helper from "../../helpers/helper";
import {Link} from "react-router-dom";
import {withGlobalStore} from "../../stores/GlobalStore";

class Row extends Component
{
    render() {
        const {nameplate, onDelete, query, auth} = this.props;
        const parameters = _get(nameplate, "parameters", []);
        const equipments = _get(nameplate, "equipments", []);
        const installationPoints = _get(nameplate, "installationPoints", []);

        return (
            <React.Fragment>
                <tr>
                    <td dangerouslySetInnerHTML={{__html: Helper.highlight(nameplate.template_name, query)}} />
                    <td dangerouslySetInnerHTML={{__html: Helper.highlight(nameplate.name, query)}} />
                    <td className={`parameter-line-${nameplate.id}`}>
                        <span className="cursor-pointer" id={`popover-parameter-${nameplate.id}`} title={parameters.length ? "View Parameters" : ""}>
                            <span className="menu-count badge badge-info ml-2"><span>{parameters.length}</span></span>
                            {!!parameters.length &&
                                <ParametersPopover
                                    popoverProps={{
                                        target: `popover-parameter-${nameplate.id}`,
                                        container: `.parameter-line-${nameplate.id}`
                                    }}
                                    parameters={parameters}
                                />
                            }
                        </span>
                    </td>
                    <td className={`equipment-line-${nameplate.id}`}>
                        <span className="cursor-pointer" id={`popover-equipment-${nameplate.id}`} title={equipments.length ? "View Equipment" : ""}>
                            <span className="menu-count badge badge-info ml-2"><span>{equipments.length}</span></span>
                            {!!equipments.length &&
                                <EquipmentsPopover
                                    popoverProps={{
                                        target: `popover-equipment-${nameplate.id}`,
                                        container: `.equipment-line-${nameplate.id}`
                                    }}
                                    equipments={equipments}
                                />
                            }
                        </span>
                    </td>
                    <td className={`inst-point-line-${nameplate.id}`}>
                        <span className="cursor-pointer" id={`popover-inst-point-${nameplate.id}`} title={installationPoints.length ? "View Installation Points" : ""}>
                            <span className="menu-count badge badge-info ml-2"><span>{installationPoints.length}</span></span>

                            {!!installationPoints.length &&
                                <InstallationPointsPopover
                                    popoverProps={{
                                        target: `popover-inst-point-${nameplate.id}`,
                                        container: `.inst-point-line-${nameplate.id}`
                                    }}
                                    installationPoints={installationPoints}
                                />
                            }
                        </span>
                    </td>
                    <td>
                        {auth.userCan("editEquipment") &&
                            <>
                                <Link
                                    to={"/nameplates/edit/" + nameplate.id}
                                    className="link link-primary"
                                >
                                    <i className="fa fa-pencil-alt"/>
                                    <span>Edit</span>
                                </Link>
                                <button className="link link-danger" onClick={() => onDelete(nameplate.id)}>
                                    <i className="fa fa-times"/>
                                    <span>Delete</span>
                                </button>
                            </>
                        }
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

Row.propTypes = {
    nameplate: PropTypes.object,
    props: PropTypes.object,
    query: PropTypes.string,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    auth: PropTypes.object
};

export default withGlobalStore(Row);