import React, {Component} from "react";

import PropTypes from "prop-types";
import {Switch, Route, Redirect} from "react-router-dom";

import {Loader} from "./shared";

import AlarmsPage from "./pages/alarms/index";
import UsersPage from "./pages/users/index";
import ChartPage from "./pages/chart/index";
import ProfilePage from "./pages/profile/index";
import NotFoundPage from "./pages/not-found/index";
import Sensors from "./pages/network/sensors/index";
import AlarmsHistory from "./pages/alarms/history";
import AlarmsDefault from "./pages/alarms/default-levels/index";
import AlertConditionLog from "./pages/alarms/log";
import AlertGroups from "./pages/subscriptions/alert-groups/index";
import AlertGroupsForm from "./pages/subscriptions/alert-groups/form";
import DeviceAlertGroups from "./pages/subscriptions/device-alert-groups/index";
import DeviceAlertGroupsForm from "./pages/subscriptions/device-alert-groups/form";
import UserSubscriptions from "./pages/subscriptions/user-subscriptions/index";
import ServiceNotifications from "./pages/network/service-notifications/index";
import Gateways from "./pages/network/gateways/index";
import GatewayView from "./pages/network/gateways/view";
import Nodes from "./pages/network/nodes/index";
import NodeView from "./pages/network/node/index";
import NodeAdd from "./pages/network/node/add";
import Motes from "./pages/network/motes/index";
import MoteAdd from "./pages/network/motes/add";
import Routers from "./pages/network/routers/index";
import RouterAdd from "./pages/network/routers/add";
import RouterView from "./pages/network/routers/view";
import Tachometers from "./pages/network/tachometers/index";
import TachometerForm from "./pages/network/tachometers/form";
import WifiGroups from "./pages/network/wifi-groups/index";
import CreateEquipment from "./pages/Equipment/CreateEquipment";
import Locations from "./pages/locations/index";
import Search from "./pages/search";
import NewDashboard from "./pages/dashboard/new_dashboard";
import {withGlobalStore} from "./stores/GlobalStore";
import {withRouter} from "react-router";
import GlobalNotes from "./pages/notes";
import Nameplates from "./pages/nameplates";
import NameplateAdd from "./pages/nameplates/add";
import NameplateEdit from "./pages/nameplates/edit";
import EquipmentProfileIndex from "./pages/equipment-profile";
import CreateWrapper from "./pages/equipment-profile/create-wrapper";
import ActionItems from "./pages/network/action-items";
import AddActionItem from "./pages/network/action-items/add";
import ViewActionItem from "./pages/network/action-items/view";
import UserFormWrapper from "./pages/users/form-wrapper";
import CompareUpdateEquipment from "./pages/equipment-profile/update-equip";
import BatchIntervalsPage from "./pages/batchIntervals";
import WifiGroupForm from "./pages/network/wifi-groups/form";
import NameplateTemplates from "./pages/nameplates/templates";
import NameplateTemplateForm from "./pages/nameplates/templates/form";
import NameplateParameters from "./pages/nameplates/parameters";
import NameplateParameterForm from "./pages/nameplates/parameters/form";
import WUASensors from "./pages/network/wua-sensors/index";
import WUACustomSensor from "./pages/network/wua-custom-sensor/index";
import WUACustomSensorForm from "./pages/network/wua-custom-sensor/form";
import Routes from "./pages/routes";
import RouteForm from "./pages/routes/form";
import WuaConfigPage from "./pages/network/wua-sensors/wuaConfigPage";
import { FirmwareUpdates } from "./pages/FirmwareUpdates/FirmwareUpdates";

class RoutesContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loader: false
        };
    }

    render() {
        const {loader} = this.state;
        const {auth = {}} = this.props;

        if (loader) {
            return <div className="loader-fullscreen"><Loader/></div>;
        }

        if (window.location.pathname === "/network/service-notifications") {
            return <Redirect to="/network/event-log"/>;
        }

        return (
            <Switch>
                {auth.userCan("editEquipment") &&
                    <PropsRoute exact path="/equipments/create" component={CreateEquipment}/>
                }
                <PropsRoute exact path={["/", "/equipments/:locationId"]} component={NewDashboard}/>
                {auth.userCan("showEquipmentProfile") && <PropsRoute exact path="/equipment-profile" component={EquipmentProfileIndex}/>}
                {auth.userCan("showEquipmentProfile") && <PropsRoute exact path={["/equipment-profile/create", "/equipment-profile/update/:profileId"]} component={CreateWrapper}/>}
                {auth.userCan("showAlarms") &&
                    <PropsRoute exact path="/alarms" component={AlarmsPage}/>
                }
                {auth.userCan("showAlarms") &&
                    <PropsRoute exact path="/alarms/history" component={AlarmsHistory}/>
                }
                {auth.userCan("showAlarms") &&
                    <PropsRoute exact path="/alarms/default" component={AlarmsDefault}/>
                }
                {auth.userCan("showAlarms") &&
                    <PropsRoute exact path="/alarms/log" component={AlertConditionLog}/>
                }
                <PropsRoute exact path={"/sensor-intervals"} component={BatchIntervalsPage}/>
                <PropsRoute exact path="/users" component={UsersPage}/>
                {auth.userCan("editUsers") &&
                    <PropsRoute exact path={["/users/add", "/users/edit/:userId"]} component={UserFormWrapper}/>
                }
                <PropsRoute exact path="/chart/:equipmentId" component={ChartPage}/>
                <PropsRoute exact path="/profile" component={ProfilePage}/>
                {auth.userCan("manageSubscriptions") && <PropsRoute exact path="/alert-groups" component={AlertGroups}/>}
                {auth.userCan("manageSubscriptions") && <PropsRoute exact path="/alert-groups/add" component={AlertGroupsForm}/>}
                {auth.userCan("manageSubscriptions") && <PropsRoute exact path="/alert-groups/:id" component={AlertGroupsForm}/>}
                {auth.userCan("manageSubscriptions") && <PropsRoute exact path="/device-alert-groups" component={DeviceAlertGroups}/>}
                {auth.userCan("manageSubscriptions") && <PropsRoute exact path="/device-alert-groups/add" component={DeviceAlertGroupsForm}/>}
                {auth.userCan("manageSubscriptions") && <PropsRoute exact path="/device-alert-groups/:id" component={DeviceAlertGroupsForm}/>}
                {auth.userCan("manageSubscriptions") && <PropsRoute exact path="/user-subscriptions" component={UserSubscriptions}/>}
                <PropsRoute exact path={["/network/action-items", "/network/archive-action-items"]} component={ActionItems}/>
                <PropsRoute exact path="/network/action-items/add" component={AddActionItem}/>
                <PropsRoute exact path={["/network/action-items/:id", "/network/archive-action-items/:id"]} component={ViewActionItem}/>
                {auth.userCan("showServiceNotifications") &&
                    <PropsRoute exact path="/network/event-log" component={ServiceNotifications}/>
                }
                <PropsRoute exact path="/network/gateways" component={Gateways}/>
                <PropsRoute exact path="/network/gateways/:serial" component={GatewayView}/>
                <PropsRoute exact path="/network/nodes" component={Nodes}/>
                {auth.userCan("editNodes") &&
                    <PropsRoute exact path="/network/nodes/add" component={NodeAdd}/>
                }
                <PropsRoute exact path="/network/motes" component={Motes}/>
                {auth.userCan("editNodes") &&
                    <PropsRoute exact path="/network/motes/add" component={MoteAdd}/>
                }
                <PropsRoute exact path="/network/motes/:serial" component={NodeView}/>
                <PropsRoute exact path="/network/nodes/:serial" component={NodeView}/>
                <PropsRoute exact path="/network/routers" component={Routers}/>
                {auth.userCan("editRouters") &&
                    <PropsRoute exact path="/network/routers/add" component={RouterAdd}/>
                }
                <PropsRoute exact path="/network/routers/:serial" component={RouterView}/>
                <PropsRoute exact path="/network/sensors" component={Sensors}/>
                <PropsRoute exact path="/network/tachometers" component={Tachometers}/>
                {auth.userCan("editTachometers") &&
                    <PropsRoute exact path={["/network/tachometers/add", "/network/tachometers/edit/:tachometerId"]} component={TachometerForm}/>
                }
                {auth.userCan("manageWiFiGroup") && <PropsRoute exact path="/network/wifi-groups" component={WifiGroups}/>}
                {auth.userCan("manageWiFiGroup") &&
                    <PropsRoute exact path={["/network/wifi-groups/add", "/network/wifi-groups/edit/:wifiGroupId"]} component={WifiGroupForm}/>
                }
                <PropsRoute exact path="/locations" component={Locations}/>
                <PropsRoute exact path="/notes" component={GlobalNotes}/>
                <PropsRoute exact path="/nameplates" component={Nameplates}/>
                <PropsRoute exact path="/nameplates/add" component={NameplateAdd}/>
                <PropsRoute exact path="/nameplates/edit/:id" component={NameplateEdit}/>

                <PropsRoute exact path="/nameplates/templates" component={NameplateTemplates}/>
                <PropsRoute exact path={["/nameplates/templates/add", "/nameplates/templates/edit/:templateId"]} component={NameplateTemplateForm}/>

                <PropsRoute exact path="/nameplates/parameters" component={NameplateParameters}/>
                <PropsRoute exact path={["/nameplates/parameters/add", "/nameplates/parameters/edit/:parameterId"]} component={NameplateParameterForm}/>
                <PropsRoute exact path="/search" component={Search}/>
                <PropsRoute exact path="/compare/:InfoId/:EquipmentId" component={CompareUpdateEquipment}/>
                <PropsRoute exact path="/firmware-updates" component={FirmwareUpdates}/>

                {auth.userCan("manageAdapter") && <PropsRoute exact path="/network/ua-configurations" component={WUASensors}/>}
                {auth.userCan("manageAdapter") && <PropsRoute exact path={["/network/ua-configurations/add", "/network/ua-configurations/edit/:configId"]} component={WuaConfigPage}/>}
                {auth.userCan("manageAdapter") && <PropsRoute exact path="/network/wua-custom-sensor" component={WUACustomSensor}/>}
                {auth.userCan("manageAdapter") && <PropsRoute exact path={["/network/wua-custom-sensor/add", "/network/wua-custom-sensor/edit/:wuaCustomSensorId"]} component={WUACustomSensorForm}/>}

                {auth.userCan("manageBLEDevicesAndRoutes") && <PropsRoute exact path="/routes" component={Routes}/>}
                {auth.userCan("manageBLEDevicesAndRoutes") && <PropsRoute exact path={["/routes/add", "/routes/edit/:routeId"]} component={RouteForm}/>}

                <PropsRoute component={NotFoundPage}/>

            </Switch>
        );
    }
}

const renderMergedProps = (component, ...rest) => {
    return React.createElement(component, Object.assign({}, ...rest));
};

const PropsRoute = ({component, ...rest}) => {
    const hash = window.location.hash.substring(1);

    if (hash) {
        let redirectTo = (new URLSearchParams(hash)).get("state");

        if ((new URLSearchParams(hash)).get(process.env.COOKIE_TOKEN_NAME || "access_token")) {
            const uri = window.location.toString();

            if (uri.indexOf("#") > 0) {
                window.history.replaceState({}, document.title, uri.substring(0, uri.indexOf("#")));
            }
        }

        if (redirectTo) {
            return <Redirect to={redirectTo}/>;
        }
    }

    return <Route {...rest} render={routeProps => renderMergedProps(component, routeProps, rest)}/>;
};

PropsRoute.propTypes = {
    component: PropTypes.func
};

RoutesContent.propTypes = {
    user: PropTypes.object,
    chartTypes: PropTypes.object,
    auth: PropTypes.object,
    location: PropTypes.object,

};

export default withGlobalStore(withRouter(RoutesContent));
