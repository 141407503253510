import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import {Modal, ValidationError} from "../../../shared";
import FormValidator from "../../../helpers/form-validator";
import Helper from "../../../helpers/helper";
import {find as _find, isEqual as _isEqual} from "lodash";
import FrequencyConverter from "../../../helpers/frequency-converter";
import {FREQUENCY_TYPES} from "../../../constants/constants";
import SelectWrapper from "../../../helpers/select-wrapper";
import Toast from "../../../pages/shared/toast";

const rules = [
    {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "name",
        method: (val) => val.length <= 25,
        validWhen: true,
        message: "This field cannot have more than 25 characters.",
    },
    {
        field: "frequency",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "frequency",
        method: "isFloat",
        validWhen: true,
        message: "Frequency should be valid number.",
    },
    {
        field: "plotLinesCount",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "plotLinesCount",
        method: "isInt",
        validWhen: true,
        message: "Plot Lines should be valid number.",
    },
    {
        field: "plotLinesCount",
        method: (val) => val >= 1 && val <= 50,
        validWhen: true,
        message: "Plot Lines should be from 1 to 50.",
    },
];

const propTypes = {
    equipment: PropTypes.object,
    frequency: PropTypes.number,
    pointData: PropTypes.object,
    faultFrequencyId: PropTypes.number,
    faultFrequencies: PropTypes.array,
    history: PropTypes.object,
    addFaultFrequency: PropTypes.func,
    updateFaultFrequency: PropTypes.func,
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    frequencyType: PropTypes.string,
    getPointById: PropTypes.func,
    currentSpeed: PropTypes.object,
};

const validator = new FormValidator(rules);

const Form = (props) => {
    const isEdit = !!props.faultFrequencyId;
    const faultFrequency = isEdit && _find(props.faultFrequencies, ["id", props.faultFrequencyId]);
    const installationPointId = isEdit && faultFrequency.installation_point_id ? faultFrequency.installation_point_id : props.pointData.id;
    const frequency = isEdit
        ? faultFrequency.frequency
        : props.frequency && props.currentSpeed.value !== 0
        ? FrequencyConverter.fromHz(props.frequency, props.currentSpeed.value).toType(props.frequencyType).numberFormat()
        : "";
    const frequencyType = isEdit
        ? faultFrequency.frequency_type
        : props.currentSpeed.value === 0 && props.frequencyType === FREQUENCY_TYPES.ORDERS
        ? FREQUENCY_TYPES.HZ
        : props.frequencyType;

    const [multipleSensorSelectIsActive, setMultipleSensorSelectIsActive] = useState(false);
    const [validation, setValidation] = useState(validator.valid());
    const [installationPointIdsActive, setInstallationPointIdsActive] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [data, setData] = useState({
        equipmentId: props.equipment.id,
        installationPointIds: [installationPointId],
        name: isEdit ? faultFrequency.name : "",
        frequency: frequency,
        frequencyType: frequencyType,
        plotLinesCount: isEdit ? faultFrequency.plot_lines_count : undefined,
        type: "all",
    });
    const [dataCopy] = useState(() => data);

    const isDataEqualCopy = useMemo(() => {
        return _isEqual(data, dataCopy);
    }, [data, dataCopy]);

    const onChange = (event) => {
        const newData = {...data};
        const value = Helper.getInputValue(event.target);

        if (event.target.name === "frequencyType") {
            newData.frequency = FrequencyConverter.fromType(newData.frequencyType, newData.frequency, props.currentSpeed.value)
                .toType(value)
                .numberFormat();
        }

        newData[event.target.name] = value;

        setData(newData);
        setValidation(validator.valid());
    };

    const onSubmit = () => {
        setInProgress(true);
        const currentValidation = validator.validate(data);
        const handleFn = isEdit ? props.updateFaultFrequency(getData()) : props.onCreate(getData());
        setValidation(currentValidation);

        if (currentValidation.isValid) {
            handleFn
                .then(() => {
                    props.onClose();
                    Toast.success(`The fault frequency has been ${isEdit ? "updated" : "created"}.`);
                })
                .catch(() => setInProgress(false));
        } else {
            setInProgress(false);
        }
    };

    const getData = () => {
        const newInstallationPointIds = Object.keys(installationPointIdsActive)
            .filter((key) => installationPointIdsActive[key] === true)
            .map((key) => parseInt(key));

        const result = {
            name: data.name,
            frequency: data.frequency,
            frequencyType: data.frequencyType,
            equipmentId: data.equipmentId,
            plotLinesCount: +data.plotLinesCount,
        };

        if (isEdit) {
            result.installationPointId = installationPointId;
        } else {
            result.type = data.type;
            result.installationPointIds = newInstallationPointIds.length > 0 ? newInstallationPointIds : [installationPointId];
        }

        return result;
    };

    const toggleSelectedInstallationPoint = (pointId) => {
        setInstallationPointIdsActive((prevState) => ({
            ...prevState,
            [pointId]: !prevState[pointId],
        }));
    };

    const handleSetMultipleSensors = () => {
        setMultipleSensorSelectIsActive((prev) => !prev);

        if (multipleSensorSelectIsActive) {
            setInstallationPointIdsActive({});
        } else {
            setInstallationPointIdsActive({[installationPointId]: true});
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            title={"Set fault frequency"}
            onSubmit={onSubmit}
            inProgress={inProgress}
            disableSubmit={isDataEqualCopy}
        >
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-4 mb-2">
                        <label>Asset Code:</label>
                        <p className="mb-2">{props.equipment.asset_code}</p>
                    </div>
                    <div className="form-group col-6 mb-2">
                        <label>Equipment:</label>
                        <p className="mb-2">{props.equipment.name}</p>
                    </div>
                    <div className="form-group col-2 mb-2">
                        <label>{props.currentSpeed.from} RPM:</label>
                        <p className="mb-2">{props.currentSpeed.value > 0 ? props.currentSpeed.value : "Not set"}</p>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-3">
                        <div className="form-group">
                            <label className="form-label">Sensor</label>
                            <input
                                className="form-control"
                                type="text"
                                value={props.pointData.name}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="form-group">
                            <label className="form-label">
                                Title <span className="color-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className={"form-control" + (validation.name.isValid || !validation.name.message ? "" : " is-invalid")}
                                placeholder="Enter Frequency Name"
                                name="name"
                                disabled={inProgress}
                                onChange={onChange}
                                value={data.name}
                            />
                            <ValidationError message={validation.name.message} />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="form-group">
                            <label className="form-label">
                                Frequency <span className="color-danger">*</span>
                            </label>
                            <input
                                type="number"
                                className={"form-control" + (validation.frequency.isValid || !validation.frequency.message ? "" : " is-invalid")}
                                name="frequency"
                                disabled={inProgress}
                                onChange={onChange}
                                value={data.frequency}
                            />
                            <ValidationError message={validation.frequency.message} />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="form-group">
                            <label className="form-label">
                                Units <span className="color-danger">*</span>
                            </label>
                            <SelectWrapper
                                style={{
                                    width: "100%",
                                    display: "block",
                                }}
                                name="frequencyType"
                                disabled={inProgress}
                                onChange={onChange}
                                value={data.frequencyType}
                            >
                                {Object.values(FREQUENCY_TYPES).map((type) => (
                                    <option
                                        value={type}
                                        key={type}
                                        disabled={
                                            !(props.currentSpeed.value > 0) &&
                                            ((data.frequencyType === FREQUENCY_TYPES.ORDERS && type !== FREQUENCY_TYPES.ORDERS) ||
                                                (data.frequencyType !== FREQUENCY_TYPES.ORDERS && type === FREQUENCY_TYPES.ORDERS))
                                        }
                                    >
                                        {type}
                                    </option>
                                ))}
                            </SelectWrapper>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="form-group">
                            <label className="form-label">
                                Plot Lines <span className="color-danger">*</span>
                            </label>
                            <input
                                type="number"
                                className={"form-control" + (validation.plotLinesCount.isValid || !validation.plotLinesCount.message ? "" : " is-invalid")}
                                name="plotLinesCount"
                                disabled={inProgress}
                                onChange={onChange}
                                value={data.plotLinesCount}
                            />
                            <ValidationError message={validation.plotLinesCount.message} />
                        </div>
                    </div>
                </div>
                {!isEdit && props.equipment.installationPoints.length > 0 && (
                    <div className="row d-flex align-items-center mb-2">
                        <label className="form-label pl-2 mb-0 mr-2">Set for multiple sensors</label>
                        <div
                            className="form-checkbox d-flex align-items-center"
                            onClick={handleSetMultipleSensors}
                        >
                            <input
                                type="checkbox"
                                name="sensor"
                                checked={multipleSensorSelectIsActive}
                            />
                            <span
                                className="sensor-checkbox-wrapper"
                                style={{
                                    backgroundColor: multipleSensorSelectIsActive ? "#000000" : "inherit",
                                    borderColor: "#000000",
                                }}
                            ></span>
                        </div>
                    </div>
                )}
                {!isEdit && multipleSensorSelectIsActive && (
                    <div className="row">
                        {props.equipment.installationPoints.map((item) => (
                            <div
                                className="col-3"
                                key={item.id}
                            >
                                <div
                                    className="form-checkbox d-flex align-items-center h-100"
                                    style={{pointerEvents: props.pointData.id === item.id && "none"}}
                                    onClick={() => toggleSelectedInstallationPoint(item.id)}
                                >
                                    <input
                                        type="checkbox"
                                        name="sensor"
                                        checked={installationPointIdsActive[item.id]}
                                        disabled={props.pointData.id === item.id}
                                    />
                                    <span
                                        className="sensor-checkbox-wrapper"
                                        style={{
                                            backgroundColor: installationPointIdsActive[item.id] ? item.color || "#000000" : "inherit",
                                            borderColor: item.color,
                                        }}
                                    ></span>
                                    {item.name}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </React.Fragment>
        </Modal>
    );
};

Form.propTypes = propTypes;

export default Form;
