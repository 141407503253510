import {useQuery} from "@tanstack/react-query";
import SurveyApi from "../../../api/survey";

export const useSurveyQuery = () => {
    const { isLoading, isSuccess, error, data } = useQuery(["SurveyQuery"], () =>
        SurveyApi.checkAvailability({
            project: "system_ss"
        }).then((res) => {
            if(res.status && +res.data.system_ss.value) {
                return true;
            }
            return false;
        })
    );

    return {
        isLoading,
        isSuccess,
        error,
        data
    };
};