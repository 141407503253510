import SwitchBlock from "./switchBlock";
import GlobalSearch from "../../shared/globalSearch";
import NotesPopover from "../../../../pages/chart/components/notesPopover";
import PropTypes from "prop-types";
import React, {useRef} from "react";
import {get as _get} from "lodash";
import {useSpeedSettings} from "../../../../modals/speedSettings";
import {useNameplateModal} from "../../../../modals/nameplateModal";
import {useRequestOnDemandModal} from "../../../../modals/requestOnDemandModal";
import {useActiveAlertsModal} from "../../../../modals/alarms";
import {useNotesModal} from "../../../../modals/notes";
import {useEquipmentProfileModal, useEquipmentProfileRemoveModal} from "../../../../modals/equipment-profile";

const HeaderActionButtons = ({
    history,
    countAlerts,
    equipment,
    globalSearchString,
    handleChangeGlobalSearch,
    notesCount,
    auth,
    user,
    selectedAlarms,
    setSelectedAlarms,
    excluded,
    handleNotesCount,
}) => {
    const popover = useRef(null);
    const [showSpeedSettings] = useSpeedSettings();
    const [showNameplateModal] = useNameplateModal();
    const [showRequestOnDemandModal] = useRequestOnDemandModal();
    const [showActiveAlertsModal] = useActiveAlertsModal({
        user,
        selectedAlarms,
        setSelectedAlarms,
        excluded,
        equipment,
    });
    const [showNotesModal] = useNotesModal({handleNotesCount});
    const [showEquipmentProfileModal] = useEquipmentProfileModal();
    const [showEquipmentProfileRemoveModal] = useEquipmentProfileRemoveModal();

    return (
        <div className="header-rules">
            <React.Fragment>
                {equipment.isCloneInProcess && (
                    <button
                        title="Equipment cloning in progress."
                        className={"btn btn-sm btn-fa-icon"}
                    >
                        <i className="fas fa-circle-notch fa-spin"></i>
                    </button>
                )}

                {auth.userCan("showEquipmentProfile") && !auth.equipmentViewOnly(equipment) && (
                    <React.Fragment>
                        {_get(equipment, "profile_id") ? (
                            <button
                                id={equipment?.id}
                                title="View Equipment Profile"
                                className="btn btn-sm btn-fa-icon"
                                onClick={showEquipmentProfileRemoveModal}
                            >
                                <i className="fas fa-cogs text-success"></i>
                            </button>
                        ) : (
                            <button
                                id={equipment?.id}
                                title="Associate Equipment with a Profile"
                                className="btn btn-sm btn-fa-icon"
                                onClick={showEquipmentProfileModal}
                            >
                                <i className="fas fa-cogs"></i>
                            </button>
                        )}
                    </React.Fragment>
                )}
                <button
                    title="Notes"
                    className="btn btn-sm btn-fa-icon"
                    onClick={showNotesModal}
                >
                    <i className="fa fa-file" />
                    {!!+notesCount && <span style={{background: "#1dc9b7"}}>{notesCount}</span>}
                </button>
                {auth.userCan("showAlarms") && (
                    <button
                        title={"Active Alerts"}
                        className="btn btn-sm btn-fa-icon"
                        onClick={showActiveAlertsModal}
                    >
                        <i className="fa fa-bell" />
                        {countAlerts !== 0 && <span>{countAlerts}</span>}
                    </button>
                )}
                {!auth.equipmentViewOnly(equipment) && (
                    <button
                        title="Nameplates"
                        className={"btn btn-sm btn-fa-icon"}
                        onClick={showNameplateModal}
                    >
                        <i className="fa fa-clipboard-list" />
                    </button>
                )}
                {auth.userCan("editSelfBearings") && (
                    <button
                        title="Speed Settings"
                        className={"btn btn-sm btn-fa-icon"}
                        onClick={showSpeedSettings}
                    >
                        <i className="fa fa-tachometer-alt" />
                    </button>
                )}
                {auth.userCan("sendRequestOnDemand") && (
                    <button
                        title="Request on Demand"
                        className={"btn btn-sm btn-fa-icon"}
                        onClick={showRequestOnDemandModal}
                    >
                        <i className="fa fa-retweet" />
                    </button>
                )}
            </React.Fragment>
            <SwitchBlock />

            <GlobalSearch
                globalSearchString={globalSearchString}
                handleChangeGlobalSearch={handleChangeGlobalSearch}
            />
            {!!popover.current && (
                <NotesPopover
                    popoverProps={{
                        target: popover.current,
                        placement: "bottom-end",
                    }}
                    equipmentId={_get(equipment, "id", null)}
                    user={user}
                    history={history}
                />
            )}
        </div>
    );
};

HeaderActionButtons.propTypes = {
    history: PropTypes.object,
    handleODModal: PropTypes.func,
    handleActiveAlertsModal: PropTypes.func,
    handleSpeedSettingsModal: PropTypes.func,
    countAlerts: PropTypes.number,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
    handleNotesModal: PropTypes.func,
    handleEquipmentProfileModal: PropTypes.func,
    handleEquipmentProfileModalRemove: PropTypes.func,
    equipment: PropTypes.object,
    notesCount: PropTypes.number,
    showNameplateModal: PropTypes.func,
    auth: PropTypes.object,
    user: PropTypes.object,
    fetchEquipment: PropTypes.func,
    profileId: PropTypes.number,
    handleUniversalAdapterModal: PropTypes.func,
    selectedAlarms: PropTypes.array,
    setSelectedAlarms: PropTypes.func,
    excluded: PropTypes.array,
    handleNotesCount: PropTypes.func,
};

export default HeaderActionButtons;
