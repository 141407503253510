import React, {Component} from "react";
import {Modal} from "../shared";
import PropTypes from "prop-types";
import Helper from "../helpers/helper";
import FormValidator from "../helpers/form-validator";

const rules = [
    {
        field: "password",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "confirmPassword",
        method: (value, options, data) => value === data.password,
        validWhen: true,
        message: "Enter New Password and Confirm Password do not match.",
    },
];

class UserResetPassword extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator(rules);

        this.state = {
            data: {
                password: "",
                confirmPassword: "",
            },
            validate: this.validator.valid(),
        };
    }
    handleSubmit = () => {
        const {type, id} = Helper.getHashParams();
        const {data} = this.state;

        if (type === "byEmail") {
            this.props.handleSubmit({
                type: type,
                userId: id,
            });
        } else if (type === "setNew") {
            const validate = this.validator.validate(data);

            this.setState({validate}, () => {
                if (validate.isValid) {
                    this.props.handleSubmit({
                        ...data,
                        type: type,
                        userId: id,
                    });
                }
            });
        }
    };

    componentDidMount() {
        const {type} = Helper.getHashParams();
        const {history} = this.props;

        if (!type) {
            history.push(Helper.setHashParams({type: "byEmail"}));
        }
    }
    handleChange = (e) => {
        const {name = "", value = ""} = e.target;
        const {data} = {...this.state};
        Object.assign(data, {[name]: value});
        this.setState({data});
    };

    handleResetType = (e) => {
        const {value = ""} = e.target;
        const {history} = this.props;

        if (value) {
            history.push(Helper.setHashParams({type: value}));
        }
    };

    render() {
        const {data} = this.state;
        const {isEmailLoading} = this.props;

        return (
            <Modal
                {...this.props}
                onSubmit={this.handleSubmit}
                disableSubmit={isEmailLoading}
            >
                <hr />
                <div className="row">
                    <ByEmail
                        data={data}
                        onChange={this.handleChange}
                    />
                </div>
            </Modal>
        );
    }
}

const ByEmail = () => {
    return (
        <div className={"col-12 mt-4"}>
            <h6 className={"text-center"}>A link will be sent to the email address associated with the user.</h6>
        </div>
    );
};

ByEmail.propTypes = {};

UserResetPassword.propTypes = {
    history: PropTypes.object,
    handleSubmit: PropTypes.func,
    isEmailLoading: PropTypes.bool,
};
export default UserResetPassword;
