import React from "react";
import PropTypes from "prop-types";
import {UncontrolledTooltip} from "reactstrap";
import {get as _get} from "lodash";
import {Link} from "react-router-dom";

const AlarmInfo = ({alarm, settingsStates}) => {
    const status = _get(alarm, "setting.status", null);

    if ((+_get(alarm, "alertCondition.is_tachometer_rpm", 0) !== 1 && status === null)) {
        return "";
    }

    return <React.Fragment>
        <i className="ml-2 fa fa-info-circle" id={`info_tooltip_${alarm.id}`}/>
        <UncontrolledTooltip target={`info_tooltip_${alarm.id}`} delay={{show: 0, hide: 1000}}>
            <AlarmInfoBody alarm={alarm} settingsStates={settingsStates} useLink={true}/>
        </UncontrolledTooltip>
    </React.Fragment>;
};

AlarmInfo.propTypes = {
    alarm: PropTypes.object,
    settingsStates: PropTypes.object,
};

const AlarmInfoBody = ({alarm, settingsStates, useLink = false}) => {
    const status = _get(alarm, "setting.status", null);
    const {alertCondition = {}} = alarm;
    const {installationPoint = {}} = alertCondition;
    const {equipment = {}} = installationPoint;

    if ((+_get(alarm, "alertCondition.is_tachometer_rpm", 0) !== 1 && status === null)) {
        return "";
    }

    return <React.Fragment>
        {+_get(alarm, "alertCondition.is_tachometer_rpm", 0) === 1 ?
            <React.Fragment>- This alert is based on the tachometer RPM.<br/></React.Fragment> : ""}
        {status !== null &&
        <React.Fragment>
            {(+status === +settingsStates.active || +status === +settingsStates.activeDuplicate) && "- An on-demand request has been sent."}
            {(+status === +settingsStates.arrived || +status === +settingsStates.arrivedDuplicate) && <React.Fragment>
                {useLink ? <Link to={`/chart/${equipment.id}#sensors=${installationPoint.id}&readingFftId=${_get(alarm, "setting.reading_id")}`}>
                    <span className="text-underline cursor-pointer">- An on-demand request has been received.</span>
                </Link> :
                    <span>- An on-demand request has been received.</span>
                }
            </React.Fragment>}
            {(+status === +settingsStates.timeout || +status === +settingsStates.timeoutDuplicate) && "- An on-demand request has not been received."}
        </React.Fragment>
        }
    </React.Fragment>;
};

AlarmInfoBody.propTypes = {
    alarm: PropTypes.object,
    settingsStates: PropTypes.object,
    useLink: PropTypes.bool,
};

export {AlarmInfo, AlarmInfoBody};