import Api from "./api";

const ApiChartMark = {
    create: (params) => {
        return Api.post(`equipment/${params.equipmentId}/chart-mark`, {...params});
    },
    update: (equipmentId, chartMarkId, params) => {
        return Api.patch(`equipment/${equipmentId}/chart-mark/${chartMarkId}`, {...params});
    },
    get: (equipmentId, from = "", to = "") => {
        return Api.get(`equipment/${equipmentId}/chart-mark`, {
            query: {
                from: from,
                to: to,
            }
        });
    },
    getAlertChange: (equipmentId) => {
        return Api.get(`equipment/${equipmentId}/chart-mark-alert-change`);
    },
    delete: (equipmentId, chartMarkId) => {
        return Api.delete(`equipment/${equipmentId}/chart-mark/${chartMarkId}`);
    },
};

export default ApiChartMark;
