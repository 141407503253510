import React, {useState} from "react";
import {HeaderSimple} from "../../shared/header";
import {Table} from "./components/Table/Table";
import LimitSelect from "../shared/limit-select";
import {Pager} from "../../shared";
import SearchInput from "../shared/search-input";
import {useFirmwareQuery} from "../../hooks/api/firmware/useFirmwareQuery";
import Select from "react-select";
import {FIRMWARE_TYPE_DEFAULT_VALUE, FIRMWARE_TYPES, FIRMWARE_TYPES_LIST} from "./constants/firmwareTypes";
import {useQueryParams} from "../../hooks/useQueryParams";

const breadcrumbs = [{name: "Firmware Updates", link: "/firmware-updates"}];

export const FirmwareUpdates = () => {
    const params = useQueryParams();

    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState({value: 10});
    const [typeFilter, setTypeFilter] = useState(FIRMWARE_TYPES[params.get("type")] || FIRMWARE_TYPE_DEFAULT_VALUE);
    const [search, setSearch] = useState(params.get("search") || "");
    const [sort, setSort] = useState({field: null, sort: null});

    const {
        data: {list: firmwareList, meta: pagination},
        isRefetching,
        isSuccess,
    } = useFirmwareQuery({sort, page, perpage: perpage.value, search, typeFilter: typeFilter.value});

    const handlePerpageChange = (value) => {
        setPerpage(value);
        setPage(1);
    };

    const handleSortChange = (field = "") => {
        setSort(
            Object.assign(
                {},
                {
                    field,
                    sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc",
                }
            )
        );
    };

    return (
        <>
            <HeaderSimple breadcrumbs={breadcrumbs} />
            <div className="subheader">
                <div className="subheader-title">Firmware updates</div>
            </div>
            <div className="block">
                <div className="block-header">
                    <div className="form-list form-list--row col-5">
                        <div className="form-group form-group--inline mr-2 form-label">
                            <label>Equipment Type:</label>
                        </div>
                        <Select
                            className="react-select react-select__control col-4"
                            classNamePrefix={"react-select"}
                            options={FIRMWARE_TYPES_LIST}
                            onChange={setTypeFilter}
                            value={typeFilter}
                        />
                    </div>
                    <div className="form-list form-list--row">
                        <div
                            id="top-pagination-block"
                            className="block-pagination"
                        >
                            {pagination.total > 10 && (
                                <div className="limit-select-block">
                                    <LimitSelect
                                        name={"perpage"}
                                        onChange={handlePerpageChange}
                                        defaultValue={perpage.value}
                                        limits={[10, 20, 50, 100]}
                                    />
                                </div>
                            )}
                            {pagination.pages > 1 && (
                                <Pager
                                    page={page}
                                    pages={pagination.pages}
                                    perPage={perpage}
                                    onPagerChange={setPage}
                                />
                            )}
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <div style={{minWidth: "210px"}}>
                                    <SearchInput
                                        onChange={setSearch}
                                        query={search}
                                        placeholder="Search Firmware Updates"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="block-body">
                    <Table
                        isRefetching={isRefetching}
                        isSuccess={isSuccess}
                        firmwareList={firmwareList}
                        onSortChange={handleSortChange}
                        sort={sort}
                    />
                </div>
            </div>
        </>
    );
};
