import React, {useEffect} from "react";
import {ToastContainer} from "react-toastify";
import auth from "../../services/auth";

const ToastContent = () => {

    useEffect(() => {
        window.addEventListener("visibilitychange", visibilityChange);

        return () => {
            window.removeEventListener("visibilitychange", visibilityChange);
        };
    });

    const visibilityChange = () => {
        if (auth.isSwitchedCustomer()) {
            return window.location.reload();
        }
    };

    return (
        <ToastContainer
            position={"top-center"}
            enableMultiContainer
            containerId={"mainToast"}
            limit={0}
            autoClose={5000}
            zIndex={10003}
            newestOnTop={true}
            hideProgressBar={false}
            closeOnClick={false}
            pauseOnHover={true}
            draggable={false}
            progress={undefined}
        />
    );
};

export default ToastContent;
