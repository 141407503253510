import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get,
    find as _find,
    upperFirst as _upperFirst
} from "lodash";
import {Link} from "react-router-dom";
import ReadingTypeBlock from "./reading-type-block";
import InstallationPoint from "./parts/installation-point";
import NotesPopover from "../chart/components/notesPopover";
import {withGlobalStore} from "../../stores/GlobalStore";

class EquipmentLine extends Component{
    constructor(props) {
        super(props);
        this.state = {
            equipment: {}
        };
    }

    componentDidMount() {
        this.setState({
            equipment: this.props.equipment
        });
    }

    updateEquipmentInfo = (data) => {
        const {equipment} = {...this.state};
        this.setState({
            equipment: {
                ...equipment,
                ...data
            }
        });
    };

    render() {
        const {empty, locationLabelList, listLen, opened, handleOpen, history, auth}  = this.props;
        const {equipment} = this.state;
        if (empty) {
            return (<tr><td colSpan={listLen} className={"text-center"}> No items match your search.</td></tr>);
        }
        const readingTypes = _get(equipment, "readingTypes", {});
        const hasReadingTypes = Object.keys(readingTypes).length > 0;
        const installationPoints = _get(equipment, "installationPoints", []);
        return (
            <React.Fragment>
                <tr className={"head-row"}>
                    {auth.userCan("showAlarms") &&
                        <td>
                            <span
                                className={"badge round-badge"}
                                style={{
                                    backgroundColor: !opened ? (_get(equipment, ["level", "color"]) || "rgb(70, 182, 127)") : "#C1C1C1",
                                    color: "#ffffff"
                                }}>
                                {_get(equipment, ["level", "name"])}
                            </span>
                        </td>
                    }
                    <td onClick={() => {handleOpen(opened === _get(equipment, "id") ? false : _get(equipment, "id"));}} className={"font-weight-bold color-gray"}>{_get(equipment, ["name"])}</td>
                    {auth.userCan("showFullServiceEquipments") && auth.customer.system_version === "hybrid-service" && equipment.service &&
                        <td>
                            <div className={`badge badge-${equipment.service === "full" ? "success" : "warning"} badge-service mb-1`}>
                                <span>{_upperFirst(equipment.service)} Service</span>
                            </div>
                        </td>
                    }
                    <td>
                        <span className={"center-row"} title={_find(locationLabelList, {id: equipment.location_id})}>
                            <img src="/assets/pic/icon-marker.svg" alt="" />
                            {_get(equipment, ["locationTable", "name"]) || "----"}
                        </span>
                    </td>
                    <td>
                        <span className={"center-row"}>
                            <img src="/assets/pic/icon-item-sensors.svg" alt="" />
                            Sensors:
                            <span>
                                {equipment.alertingInstallationPointsCount ? (
                                    <span>
                                        {auth.userCan("editEquipment")
                                            ? <React.Fragment>
                                                <span
                                                    className="color-danger"
                                                    style={{color: _get(equipment, ["level", "color"]) || "rgb(70, 182, 127)"}}>
                                                    {equipment.alertingInstallationPointsCount}
                                                </span>
                                                ({equipment.installationPointsCount})
                                            </React.Fragment>
                                            : <React.Fragment>{equipment.installationPointsCount}</React.Fragment>
                                        }
                                    </span>
                                ) : (
                                    <span>{equipment.installationPointsCount}</span>
                                )}
                            </span>
                        </span>
                    </td>
                    {auth.userCan("showAlarms") &&
                        <React.Fragment>
                            <td className={ Object.keys(readingTypes).length > 0 ? "alerts-cell" : "" }>
                                {hasReadingTypes > 0 &&
                                Object.keys(readingTypes).map((key, index) => {
                                    return <ReadingTypeBlock
                                        showPopover={false}
                                        key={index}
                                        opened={opened}
                                        equipmentId={equipment.id}
                                        chartTypeId={parseInt(key)}
                                        title={readingTypes[key].label || "None"}
                                        name={readingTypes[key].short || "None"}
                                        level={readingTypes[key].alertLevel}
                                    />;
                                })
                                }
                            </td>
                            <td className={`text-nowrap equipment-line-${equipment.id}`}>
                                <span className="cursor-pointer" id={`popover-${equipment.id}`} onClick={this.togglePopover}>
                                    <i className="fa fa-envelope"/>
                                    <span> Notes <span className="badge badge-gray round-badge">{_get(equipment, "notes", []).length}</span></span>
                                    {!!_get(equipment, "notes", []).length &&
                                        <NotesPopover
                                            popoverProps={{
                                                target: `popover-${equipment.id}`,
                                                container: `.equipment-line-${equipment.id}`
                                            }}
                                            equipmentId={+equipment.id}
                                            history={history}
                                            onActionCallback={this.updateEquipmentInfo}
                                        />
                                    }
                                </span>
                            </td>
                        </React.Fragment>
                    }
                    <td>
                        <div className="btn-group btn-group-sm">
                            <Link className={"link link-primary"} to={`/chart/${_get(equipment, ["id"])}`}><i className="fa fa-eye"/> <span>View</span></Link>
                        </div>
                    </td>
                </tr>
                <tr className={`collapse bg-info ${opened && opened === _get(equipment, ["id"]) && "show"}`}>
                    <td colSpan={7}>
                        <div className="d-flex flex-wrap">
                            {installationPoints.length > 0
                                ? installationPoints.map(point => <InstallationPoint key={_get(point, "id")} equipment={equipment} installationPoint={point}/>)
                                : <div className={"w-100 text-center"}><i>Installation Points not found!</i></div>
                            }
                        </div>
                        <div onClick={() => {handleOpen(opened === _get(equipment, "id") ? false : _get(equipment, "id"));}} className="badge badge-gray round-badge toggle-equipment-row">
                            <i className="fa fa-angle-up"/>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

EquipmentLine.propTypes = {
    equipment: PropTypes.object,
    locationLabelList: PropTypes.array,
    empty: PropTypes.bool,
    listLen: PropTypes.number,
    opened: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    handleOpen: PropTypes.func,
    show: PropTypes.bool,
    history: PropTypes.object,
    auth: PropTypes.object
};
export default withGlobalStore(EquipmentLine);