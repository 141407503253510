import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {TABLE_FIELDS} from "../../constants/tableFields";
import Helper from "../../../../helpers/helper";
import useUserProfile from "../../../../hooks/api/Global/useUserProfile";

const typeLabel = {
    router: "Repeater",
    sensor: "Tethered Sensor",
    gateway: "Gateway",
    mote: "Sensor Mote",
    node: "Node",
};

export const FirmwareItem = ({firmware}) => {
    const {data: user} = useUserProfile();

    const parsedFirmware = {
        ...firmware,
        type: typeLabel[firmware.type],
        created_at: Helper.dateToUserFormat(firmware.created_at, user),
        version: firmware.version.replace("v", ""),
        description: firmware.description.trim(),
    };

    return (
        <tr>
            {TABLE_FIELDS.map((item, index) => (
                <Fragment key={index}>
                    {item.field === "short_description" ? (
                        <td>
                            {parsedFirmware[item.field]}
                            {parsedFirmware[item.field] && (
                                <>
                                    <br />
                                    <br />
                                </>
                            )}{" "}
                            {firmware.description}
                        </td>
                    ) : (
                        <td>{parsedFirmware[item.field]}</td>
                    )}
                </Fragment>
            ))}
        </tr>
    );
};

FirmwareItem.propTypes = {
    firmware: PropTypes.object,
};
