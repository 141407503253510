import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Modal} from "../../shared";
import {withGlobalStore} from "../../stores/GlobalStore";
import {PureSensorSelect} from "../../pages/shared/sensor-select";
import EquipmentSelect from "../../pages/shared/equipment-select";
import {setWith as _setWith, get as _get, find as _find, filter as _filter, isEmpty as _isEmpty, cloneDeep as _cloneDeep} from "lodash";
import CollapseLocationSelect from "../../shared/collapseLocationSelect/collapseLocationSelect";
import Toast from "../../pages/shared/toast";
import {useNameplatesListForOverlayQuery} from "../../hooks/api/nameplates/useNameplatesListForOverlayQuery";
import NameplateFormItem from "./components/NameplateFormItem";
import {useAssetListQuery} from "../../hooks/api/assets/useAssetListQuery";
import {useEquipmentByParams} from "../../hooks/api/equipment/useEquipmentQuery";
import {WuaOverlayForm} from "./components/WuaOverlayForm";
import useCustomChartTypes from "../../hooks/api/Chart/UseCustomChartTypes";
import {useChartTypesStore} from "../../stores/zustand/ChartSelectedChartTypeStore";

const OtherEquipmentModal = ({selectedPoints, overlayPoints, onSubmit, onClose, byNameplate: isInitialNameplate, chartType}) => {
    const [state, setState] = useState({
        loader: false,
        byNameplate: isInitialNameplate,
        isInstallationPointLoading: false,
        nameplatesData: [
            {
                nameplateId: null,
                equipment: null,
                installationPoint: null,
            },
        ],
        data: [
            {
                installationPoint: null,
                locationId: null,
                equipment: null,
            },
        ],
    });
    const chartTypes = useChartTypesStore();
    const {data, byNameplate, nameplatesData} = state;

    const {equipmentItem} = useEquipmentByParams();
    const {data: nameplatesList, isLoading: loader} = useNameplatesListForOverlayQuery(selectedPoints);
    const {data: assetTreeBranch, isLoading: isAssetTreeBranchLoading} = useAssetListQuery({onlyWithEquipment: true, isFlat: false});
    const {customChartTypes, isLoading: isCustomChartTypesLoading} = useCustomChartTypes(equipmentItem.id, chartTypes);

    const isWuaOverlay = customChartTypes?.[chartType];

    useEffect(() => {
        if (!overlayPoints?.length) {
            return;
        }
        if (byNameplate) {
            setState((prev) => ({...prev, nameplatesData: _cloneDeep(overlayPoints)}));
            return;
        }
        setState((prev) => ({...prev, data: _cloneDeep(overlayPoints)}));
    }, [overlayPoints]);

    const handleByNameplate = (state) => {
        setState((prev) => ({
            ...prev,
            nameplatesData: [
                {
                    nameplateId: null,
                    equipment: null,
                    installationPoint: null,
                },
            ],
            byNameplate: state,
        }));
    };
    const handleChangeNameplate = (nameplate, key) => {
        let nameplatesData = [...state.nameplatesData];

        nameplatesData[key] = nameplate;

        setState((prev) => ({...prev, nameplatesData}));
    };

    const handleChangeLocation = (e, key) => {
        const value = e.target.value;
        let {data} = state;

        _setWith(data, key + ".locationId", value, Object);
        _setWith(data, key + ".equipment", null, Object);
        _setWith(data, key + ".installationPoint", null, Object);

        setState((prev) => ({...prev, data}));
    };

    const handleChangeEquipment = (equipment, key) => {
        let {data} = state;

        _setWith(data, key + ".equipment", equipment, Object);

        setState((prev) => ({...prev, data}));
    };

    const handleChangeInstallationPoint = (e, key) => {
        const value = e.value;
        let {data} = state;

        const equipment = _get(data, key + ".equipment");
        const installationPoint = _find(_get(equipment, "installationPoints", []), (point) => +point.id === +value);

        let updatedInstallationPoint = {
            ...installationPoint,
            equipment_name: equipment.name,
        };

        if (isWuaOverlay) {
            updatedInstallationPoint = e;
        }

        _setWith(data, key + ".installationPoint", updatedInstallationPoint, Object);

        setState((prev) => ({...prev, data}));
    };

    const addAnotherSensor = () => {
        let {data, nameplatesData, byNameplate} = state;

        if (byNameplate) {
            _setWith(
                nameplatesData,
                nameplatesData.length,
                {
                    installationPoint: null,
                    nameplateId: null,
                    equipment: null,
                },
                Object
            );

            setState((prev) => ({...prev, nameplatesData}));
        } else {
            _setWith(
                data,
                data.length,
                {
                    installationPoint: null,
                    locationId: null,
                    equipment: null,
                },
                Object
            );

            setState((prev) => ({...prev, data}));
        }
    };

    const removeAnotherSensor = (key) => {
        let {data, nameplatesData, byNameplate} = state;

        if (byNameplate) {
            nameplatesData = _filter(nameplatesData, (n, index) => {
                return index !== key;
            });

            setState((prev) => ({...prev, nameplatesData}));
        } else {
            data = _filter(data, (n, index) => {
                return index !== key;
            });

            setState((prev) => ({...prev, data}));
        }
    };

    const onFormSubmit = () => {
        const {data, nameplatesData, byNameplate} = state;

        let overlayPoints = [];
        let valid = true;

        if (byNameplate) {
            nameplatesData.map((item) => {
                if (!item.nameplate) {
                    valid = false;
                    Toast.info(<div dangerouslySetInnerHTML={{__html: "Select nameplate"}}></div>);
                    return;
                }
                if (!item.equipment) {
                    valid = false;
                    Toast.info(<div dangerouslySetInnerHTML={{__html: "Select equipment"}}></div>);
                    return;
                }
                if (item.installationPoint) {
                    overlayPoints.push(item);
                } else {
                    valid = false;
                    Toast.info(<div dangerouslySetInnerHTML={{__html: "Select sensor"}}></div>);
                }
            });
        } else {
            data.map((item) => {
                if (!item.locationId && !isWuaOverlay) {
                    valid = false;
                    Toast.info(<div dangerouslySetInnerHTML={{__html: "Select location"}}></div>);
                    return;
                }
                if (!item.equipment) {
                    valid = false;
                    Toast.info(<div dangerouslySetInnerHTML={{__html: "Select equipment"}}></div>);
                    return;
                }
                if (item.installationPoint) {
                    overlayPoints.push(item);
                } else {
                    valid = false;
                    Toast.info(<div dangerouslySetInnerHTML={{__html: "Select sensor"}}></div>);
                }
            });
        }

        if (valid) {
            onSubmit(overlayPoints, byNameplate);
            onClose();
        }
    };

    return (
        <Modal
            showModal={true}
            loader={loader || isAssetTreeBranchLoading || isCustomChartTypesLoading}
            size="lg"
            title={"Select sensors to overlay"}
            className={"custom-modal overlay-modal"}
            onSubmit={onFormSubmit}
            onClose={onClose}
            submitTitle={"Overlay"}
        >
            {!_isEmpty(nameplatesList?.nameplates) ? (
                <div className={"row"}>
                    <label htmlFor="nameplates">Search By Nameplates</label>
                    <label
                        className="switch"
                        style={{marginLeft: "5px"}}
                    >
                        <input
                            id="nameplates"
                            type="checkbox"
                            checked={byNameplate}
                            onChange={() => handleByNameplate(!byNameplate)}
                        />
                        <span className="slider round" />
                    </label>
                </div>
            ) : (
                <></>
            )}
            {isWuaOverlay && (
                <WuaOverlayForm
                    chartType={chartType}
                    data={data}
                    addAnotherSensor={addAnotherSensor}
                    handleChangeEquipment={handleChangeEquipment}
                    removeAnotherSensor={removeAnotherSensor}
                    handleChangeInstallationPoint={handleChangeInstallationPoint}
                />
            )}
            {byNameplate && (
                <>
                    {nameplatesData.map((item, key) => (
                        <NameplateFormItem
                            selectedPoints={selectedPoints}
                            canRemoveItem={key > 0}
                            value={item}
                            onRemove={() => removeAnotherSensor(key)}
                            onChange={(e) => handleChangeNameplate(e, key)}
                            key={key}
                        />
                    ))}
                    {nameplatesData.length <= 10 && (
                        <span
                            className="add-installation-point-btn ml-0"
                            onClick={addAnotherSensor}
                        >
                            <i className="fa fa-plus-circle"></i>
                            <span>Add another sensor</span>
                        </span>
                    )}
                </>
            )}
            {!isWuaOverlay && !byNameplate && (
                <>
                    {data.map((item, key) => (
                        <div
                            className={"row form-group"}
                            key={key}
                        >
                            <div className={"col-4"}>
                                <CollapseLocationSelect
                                    className={"form-control modal-input"}
                                    emptyOptionLabel={"Select Location"}
                                    selectName={"locationId"}
                                    value={_get(data, key + ".locationId", "")}
                                    style={{marginLeft: "-10px", width: "100%"}}
                                    onChange={(e) => {
                                        handleChangeLocation(e, key);
                                    }}
                                    locationList={assetTreeBranch?.[0]?.children}
                                />
                            </div>
                            <div className={"col-4"}>
                                <EquipmentSelect
                                    locationId={_get(data, key + ".locationId", 0)}
                                    emptyOptionLabel={"Select Equipment"}
                                    selectName={"equipment"}
                                    value={_get(data, key + ".equipment.id", "")}
                                    onChange={(equipment) => handleChangeEquipment(equipment, key)}
                                    disabled={!_get(data, key + ".locationId")}
                                />
                            </div>
                            <div className={"col-3"}>
                                <PureSensorSelect
                                    className="react-select"
                                    name="installationPointId"
                                    value={_get(data, key + ".installationPoint.id", "")}
                                    onChange={(e) => handleChangeInstallationPoint(e, key)}
                                    equipment={_get(data, key + ".equipment", {})}
                                    disabled={!_get(data, key + ".equipment")}
                                    filterOutTachometers={true}
                                />
                            </div>
                            {key > 0 && (
                                <div className={"col-1"}>
                                    <span
                                        className="remove-block position-relative mt-2 block"
                                        onClick={() => removeAnotherSensor(key)}
                                    >
                                        <i className="fa fa-times-circle" />
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}

                    {data.length <= 10 && (
                        <span
                            className="add-installation-point-btn ml-0"
                            onClick={addAnotherSensor}
                        >
                            <i className="fa fa-plus-circle"></i>
                            <span>Add another sensor</span>
                        </span>
                    )}
                </>
            )}
        </Modal>
    );
};

OtherEquipmentModal.propTypes = {
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    selectedPoints: PropTypes.array,
    overlayPoints: PropTypes.array,
    byNameplate: PropTypes.bool,
    chartType: PropTypes.number,
};

export default withGlobalStore(OtherEquipmentModal);
