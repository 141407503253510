import React from "react";
import Helper from "../../../../../../helpers/helper";
import auth from "../../../../../../services/auth";
import {useHistory} from "react-router";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useSelectedAxis} from "../../hooks/helpers/useSelectedAxis";

const ManageAlertButtons = () => {
    const {selectedPoint, chartType} = useChartStateStoreContext((state) => state.settings, shallow);
    const selectedAxis = useSelectedAxis();
    const history = useHistory();

    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    const addAlert = () => {
        history.push(
            Helper.setHashParams({
                modal: "alert",
                type: chartType,
                axisId: selectedAxis[0],
                installationPointId: selectedPoint,
            })
        );
    };

    if (!auth.userCan("editAlarms")) {
        return <></>;
    }

    return (
        <span className={`${isLoading ? "disabled" : ""}`}>
            <span
                className={`alert-link ${isLoading ? "pe-none" : ""}`}
                onClick={addAlert}
            >
                <i className="fa fa-pen in-blue-circle" />
                Manage Alerts
            </span>
        </span>
    );
};

export default ManageAlertButtons;
