import React, {Component} from "react";
import PropTypes from "prop-types";
import ActionListApi from "../../../../api/actionList";
import {
    get as _get,
    each as _each,
    find as _find,
    filter as _filter,
    includes as _includes,
    setWith as _setWith,
    cloneDeep as _cloneDeep,
    isEqual as _isEqual,
    isNull as _isNull,
    size as _size,
    isEmpty as _isEmpty
} from "lodash";
import User from "../../../../api/user";
import Toast from "../../../shared/toast";
import Helper from "../../../../helpers/helper";

const AIEmailEvents = [13, 14, 15, 16, 17, 18];

class SubscriptionDataHandler extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            loader: true,
            facilities: [],
            urgencies: {},
            selectedFacilityId: null,
        };
    }

    componentDidMount() {
        const {user, allFacility, facilityIds, assetTree} = this.props;
        Promise.all([
            this.fetchUrgencies(),
        ]).then(() => {
            let facilities = allFacility ? assetTree.map(location => {
                if (location.level_name === "facility") {
                    return {facility_id: location.id, name: location.full_name};
                }
            }) : Object.keys(_get(user, "locations", {})).map(locationId => {
                return {facility_id: locationId, name: Helper.findLocationFullNameById(assetTree, locationId, user.locations[locationId])};
            });
            facilities = facilities.filter(facility => facility);
            this.setState({
                loader: false,
                facilities,
                selectedFacilityId: allFacility ? +_get(facilities, "0.facility_id", null) : +_get(facilityIds, "0", null)
            }, () => this.updateData());
        });
    }

    componentDidUpdate(prevProps) {
        const {allFacility, facilityIds, assetTree} = this.props;
        if (!_isEqual(prevProps.facilityIds, facilityIds) || prevProps.allFacility !== allFacility || _size(assetTree) !== _size(prevProps.assetTree)) {
            this.updateAccess();
        }
    }

    updateAccess = () => {
        const {selectedFacilityId} = this.state;
        const {allFacility, facilityIds, user, assetTree} = this.props;
        let facilities = allFacility ? assetTree.map(location => {
            if (location.level_name === "facility") {
                return {facility_id: location.id, name: location.full_name};
            }
        }) :
            Object.keys(user.locations).map(locationId => {
                return {facility_id: locationId, name: Helper.findLocationFullNameById(assetTree, locationId, user.locations[locationId])};
            });

        facilities = facilities.filter(facility => facility);
        let newSelectedFacilityId = allFacility ? +_get(facilities, "0.facility_id", null) : selectedFacilityId;
        if (!this.checkFacilityAccess(selectedFacilityId)) {
            newSelectedFacilityId = allFacility ? +_get(facilities, "0.facility_id", null) : +_get(facilityIds, "0", null);
        }

        this.setState({selectedFacilityId: newSelectedFacilityId, facilities}, () => this.updateData());
    }

    fetchUrgencies = () => {
        return ActionListApi
            .getInfo()
            .then((response) => {
                if (response) {
                    this.setState({
                        urgencies: _get(response, "action_list_urgencies", {}),
                    });
                }
            });
    }

    updateData = (value = null) => {
        let data = {};
        const {urgencies, facilities} = this.state;
        const {emailEvents} = this.props;
        _each(emailEvents, (emailEvent) => {
            const eventId = _get(emailEvent, "id");
            _each(facilities, (facility) => {
                const facilityId = _get(facility, "facility_id");
                _each(urgencies, (urgency) => {
                    const urgencyId = _get(urgency, "id");
                    if (_includes(AIEmailEvents, +eventId)) {
                        let newValue = _isNull(value) ? this.getCurrentUserValue(eventId, facilityId, urgencyId) : value;
                        if (!this.checkFacilityAccess(facilityId)) newValue = 0;

                        _setWith(data, "[" + eventId + "][" + facilityId + "][urgencies][" + urgencyId + "]", newValue, Object);
                    } else {
                        let newValue = this.getCurrentUserValue(eventId, facilityId, urgencyId);
                        _setWith(data, "[" + eventId + "][" + facilityId + "][urgencies][" + urgencyId + "]", newValue, Object);
                    }
                });
            });
        });

        this.setState({data});
    }

    getCurrentUserValue = (eventId, facilityId, urgencyId) => {
        const {data} = this.state;
        const {user} = this.props;

        if (!_isEmpty(data)) {
            return _get(data, "[" + eventId + "][" + facilityId + "][urgencies][" + urgencyId + "]", 0);
        } else {
            const userEvents = _get(user, "email_subscriptions", {});
            const userEvent = _find(userEvents, {event_id: eventId}, {});
            const userEventSettings = _get(userEvent, "settings", []);

            const eventSetting = _find(userEventSettings, {
                location_id: +facilityId,
                value: "" + urgencyId
            });
            let defaultValue = 0;
            if (!!userEvent && !eventSetting) {
                defaultValue = _get(user, "default_subscribed_to_email_subscription_settings", 0);
            }
            return _get(eventSetting, "state", defaultValue);
        }
    }

    toggleAllForFacility = (value, facilityId, urgencyId) => {
        const {emailEvents} = this.props;
        const {facilities} = this.state;
        let data = _cloneDeep(this.state.data);

        if(_isNull(facilityId)){
            _each(facilities, (facility) => {
                const facilityId = _get(facility, "facility_id");
                _each(AIEmailEvents, (eventId) => {
                    if (_find(emailEvents, {id: eventId})) {
                        if (!this.checkFacilityAccess(facilityId)) value = 0;
                        _setWith(data, "[" + eventId + "][" + facilityId + "][urgencies][" + urgencyId + "]", value, Object);
                    }
                });
            });
        } else {
            _each(AIEmailEvents, (eventId) => {
                if (_find(emailEvents, {id: eventId})) {
                    if (!this.checkFacilityAccess(facilityId)) value = 0;
                    _setWith(data, "[" + eventId + "][" + facilityId + "][urgencies][" + urgencyId + "]", value, Object);
                }
            });
        }

        this.setState({data});
    }

    toggleAll = (value) => {
        this.updateData(value);
    }

    changeFacility = (facilityId) => {
        this.setState({selectedFacilityId: facilityId});
    }

    changeData = (key, value, array = false) => {
        let data = _cloneDeep(this.state.data);

        if(array) {
            for(let i in key) {
                _setWith(data, key[i], value[i], Object);
            }
        } else {
            _setWith(data, key, value, Object);
        }

        this.setState({data});
    }

    onSave = () => {
        const {data} = this.state;
        const {user, updateUser} = this.props;

        this.setState({loader: true}, () => {
            User
                .updateEmailEvents({email_events: JSON.stringify(data), id: _get(user, "id")})
                .then(() => {
                    updateUser().then(() => this.setState({loader: false}, () => this.updateData()));
                    Toast.success("The notification settings have been updated.");
                });
        });
    }

    setValueToData = (data, value, eventId, facilityId, urgencyId) => {
        data = Object.assign({}, data);

        if (!this.checkFacilityAccess(facilityId)) {
            value = 0;
        }
        _setWith(data, "[" + eventId + "][" + facilityId + "][urgencies][" + urgencyId + "]", value, Object);
    }

    getFilteredFacility = () => {
        const {facilities} = this.state;

        return _filter(facilities, (facility) => this.checkFacilityAccess(_get(facility, "facility_id")));
    }

    checkFacilityAccess = (facilityId) => {
        const {allFacility, facilityIds} = this.props;

        return _includes(facilityIds, "" + facilityId) || allFacility;
    }

    render() {
        const {loader, data, urgencies, selectedFacilityId} = this.state;
        const {emailEvents} = this.props;

        const filteredFacility = this.getFilteredFacility();
        const filteredEmailEvents = _filter(emailEvents, event => _includes(AIEmailEvents, _get(event, "id")));

        return (
            <React.Fragment>
                {this.props.children(
                    loader,
                    data,
                    urgencies,
                    filteredFacility,
                    filteredEmailEvents,
                    selectedFacilityId,
                    this.changeFacility,
                    this.changeData,
                    this.onSave,
                    this.toggleAllForFacility,
                    this.toggleAll
                )}
            </React.Fragment>
        );
    }
}

SubscriptionDataHandler.propTypes = {
    user: PropTypes.object,
    auth: PropTypes.object,
    children: PropTypes.any,
    updateUser: PropTypes.func,
    emailEvents: PropTypes.array,
    assetTree: PropTypes.array,
    facilityIds: PropTypes.array,
    allFacility: PropTypes.bool
};

export default SubscriptionDataHandler;