import {useModal} from "react-modal-hook";
import SetIdleThresholdModal from "../components/setIdleThreshold";
import {useModalHistory} from "../../../hooks/helpers/useModalHistory";
import {MODALS} from "../../../constants/modals";
import React from "react";
export const useIdleThresholdModal = () => {
    const [showModal, hideModal] = useModal(
        () => (
            <SetIdleThresholdModal
                title="Set idle threshold"
                showModal={true}
                onClose={onHide}
            />
        ),
        []
    );
    const {pushHistory, deleteHistory} = useModalHistory(MODALS.IDLE_TRESHOLD, showModal);

    const onShow = () => {
        pushHistory();
        showModal();
    };

    const onHide = () => {
        deleteHistory();
        hideModal();
    };
    return [onShow, onHide];
};
