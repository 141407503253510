import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useSyncedAxis} from "../../../store/sync/axisSyncStore";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {shallow} from "zustand/shallow";

export const useSelectedAxis = () => {
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);
    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const {chartModeList} = useChartStateStoreContext((state) => state.factory, shallow);
    const isWaterfall = chartMode === chartModeList.WATERFALL || chartMode === chartModeList.STACKED_WATERFALL;

    return useSyncedAxis(selectedPoint, isImpactVue, isWaterfall);
};
