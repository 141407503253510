import {useGetFFTReadings, useGetPointReadingParamsByDateTime} from "../../../../../../hooks/api/fftReadings/ReadingsQuery";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {usePointData} from "../../../hooks/usePointsData";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {useCurrentDate} from "./useCurrentDate";
import {useSelectedAxis} from "./useSelectedAxis";
import {useSelectUnixDateRangeTimestampSync} from "../../../store/sync/timestampsSyncStore";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";

export const useChartReadings = () => {
    const chartType = useChartStateStoreContext((state) => state.settings.chartType, shallow);
    const isImpactVue = useIsImpactVue(chartType);
    const currentDate = useCurrentDate();
    const selectedAxis = useSelectedAxis();
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const {equipmentItem} = useEquipmentByParams();
    const pointData = usePointData(selectedPoint);
    const {unixFrom, unixTo} = useSelectUnixDateRangeTimestampSync(selectedPoint, isImpactVue);
    const {params: readingsParams} = useGetPointReadingParamsByDateTime(
        equipmentItem.id,
        selectedPoint,
        selectedAxis,
        currentDate,
        isImpactVue,
        pointData?.settings?.high_pass,
        false,
        unixFrom,
        unixTo
    );

    const {data, isSuccess, isRefetching} = useGetFFTReadings(chartType, readingsParams);

    return {data, isSuccess, isRefetching};
};
