import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button} from "reactstrap";
import FormValidator from "../../../../helpers/form-validator";
import ValidationError from "../../../../layouts/ValidationError/ValidationError";
import Loader from "../../../../shared/loader/loader";
import deviceAlertComments from "../../../../api/deviceAlertComments";

const rules = [
    {
        field: "text",
        method: text => !!text.replace(/<[^>]*>/gi, "").replace(/[\s?&nbsp;]+/, "").length,
        validWhen: true,
        message: "Text cannot be empty."
    }
];

class CommentsForm extends Component
{
    constructor(props) {
        super(props);

        this.validator = new FormValidator(rules);

        this.state = {
            data: {
                text: ""
            },
            loader: true,
            validation: this.validator.valid(),
            inProgress: false
        };

        this.onChangeText = this.onChangeText.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const {commentId} = this.props;

        if (commentId) {
            deviceAlertComments.get(commentId).then(({item}) => this.setState({
                data: Object.assign({}, this.state.data, item),
                loader: false
            }));
        } else {
            this.setState({loader: false});
        }
    }

    onChangeText(event) {
        const data = {...this.state.data, text: event.target.value};
        const validation = this.validator.validate(data);

        this.setState({data, validation});
    }

    handleSubmit() {
        const validation = this.validator.validate(this.state.data);

        this.setState({validation, inProgress: true}, () => {
            if (validation.isValid) {
                this.props.onSubmit(this.state.data);
            } else {
                this.setState({inProgress: false});
            }
        });
    }

    render() {
        const {data, validation, inProgress} = this.state;

        return (
            <div
                style={this.props.styleObject}
            >
                {this.state.loader ?
                    <Loader/> :
                    <React.Fragment>
                        <div className={"form-group" + (validation.text.isInvalid || validation.text.message ? " is-invalid" : "")}>
                            <textarea
                                style={{height: "100px", marginBottom: "10px"}}
                                className="form-control"
                                value={data.text}
                                onChange={this.onChangeText}
                            />
                            <ValidationError message={validation.text.message}/>
                        </div>
                        <div className="edit-btns d-flex justify-content-end">
                            <Button onClick={() => this.props.onClose()} color={"secondary"} className="btn-sm mr-2">Cancel</Button>
                            <Button onClick={this.handleSubmit} disabled={inProgress} color={"primary"} className="btn-sm">
                                {inProgress ? <span><i className="fa fa-spinner"/> Processing</span> : "Save"}
                            </Button>
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

CommentsForm.propTypes = {
    commentId: PropTypes.number,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    customStyles: PropTypes.object,
    children: PropTypes.any,
    styleObject: PropTypes.object
};

export default CommentsForm;