import React, {Component} from "react";
import PropTypes from "prop-types";
import ApiNodes from "../../../api/node";
import Node from "../gateways/node";
import Mote from "../gateways/mote";
import SearchInput from "../../shared/search-input";
import {Button} from "reactstrap";
import Helper from "../../../helpers/helper";
import BatteryVoltageModal from "../../../modals/battery-voltage";
import {Loader} from "../../../shared";
import ApiGateways from "../../../api/gateway";
import WifiGroupsApi from "../../../api/wifiGroups";
import {
    get as _get,
    set as _set
} from "lodash";
import Toast from "../../shared/toast";
import {withGlobalStore} from "../../../stores/GlobalStore";
import { HeaderSimple } from "../../../shared/header";
import ApiNode from "../../../api/node";
import {MOTES_LOT_DATA} from "../../../constants/constants";

class Index extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [],
            loader: true,
            node: null,
            isMote: true,
            globalSearchString: "",
            gateways: [],
            wifiGroups: [],
            inProgress: false,
            clearingData: false,
            locationFacilityId: null
        };

        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onShowBatteryVoltageModal = this.onShowBatteryVoltageModal.bind(this);
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    componentDidMount() {

        Promise.all([
            ApiNodes.view(this.props.match.params.serial),
            ApiGateways.list({query: {pagination: {page: 1, perpage: 100}}}),
            WifiGroupsApi.getList({query: {pagination: {page: 1, perpage: 100}}})
        ]).then(([{node}, {list}, groupResponse]) => {
            const isMote = MOTES_LOT_DATA.includes(node.lot_data);
            const wifiGroups = _get(groupResponse, "list", []);

            this.setState({
                loader: false,
                breadcrumbs: isMote
                    ? [{name: "Manage Sensor Motes", link: "/network/motes"}, {name: "Edit Sensor Mote " + parseInt(node.serial).toString(16).toUpperCase()}]
                    : [{name: "Manage Nodes", link: "/network/nodes"}, {name: "Edit Node " + node.serial}],
                node,
                isMote,
                gateways: list,
                wifiGroups,
                locationFacilityId: _get(node, "locationFacilityId")
            });
        });
    }

    handleSubmit() {
        this.setState({inProgress: true}, () => {
            const {node, clearingData, isMote} = this.state;
            if (clearingData && !isMote) {
                _set(node, "location", "");
            }
            const photos = _get(node, "photos");

            ApiNodes.update(node.serial, node).then((response) => {
                const node = _get(response, "node");
                if (clearingData && !Helper.hasOnlyDefaultImage(photos) && !isMote) {
                    this.detachAllImages();
                }

                Toast.success("The "+ (this.state.isMote ? "sensor mote" : "node") + " has been updated.");
                this.setState({
                    inProgress: false,
                    node: {...this.state.node, ...{location_id: node.location_id}},
                    clearingData: false,
                    locationFacilityId: _get(node, "locationFacilityId")
                });
            });
        });
    }

    onChange(event) {
        const node = {...this.state.node};
        const {locationFacilityId, clearingData, isMote} = {...this.state};

        const isClearingData = ((_get(event, "name") === "location_id" && !isMote) || clearingData)
            ? (+locationFacilityId !== +event.target.facilityId)
            : false;

        const name = _get(event, "target.name", "");
        const value = _get(event, "target.value", "");

        _set(node, name, value);

        this.setState({
            clearingData: isClearingData,
            node
        });
    }

    detachAllImages() {
        const node = {...this.state.node};

        ApiNode.detachAllImages(node.serial).then(response => {
            _set(node, "photos", response.images || []);
            this.setState({node});
        });
    };

    onShowBatteryVoltageModal() {
        this.props.history.push(Helper.setHashParams({modal: "battery-voltage"}));
    }

    render() {
        const {breadcrumbs, loader, node, isMote, gateways, globalSearchString, wifiGroups, clearingData} = this.state;
        const {auth} = this.props;
        const searchParams = Helper.getHashParams();

        return (
            <div className="container-fluid">
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                {loader ? <Loader /> :
                    isMote
                        ? <Mote
                            onEdit={true}
                            mote={node}
                            gateways={gateways}
                            onChange={this.onChange}
                            onShowBatteryVoltageModal={this.onShowBatteryVoltageModal}
                            wifiGroups={wifiGroups}
                        />
                        : <Node
                            onEdit={true}
                            node={node}
                            gateways={gateways}
                            onChange={this.onChange}
                            onShowBatteryVoltageModal={this.onShowBatteryVoltageModal}
                            clearingData={clearingData}
                            useImageToolbar={true}
                        />
                }
                <div className="text-right">
                    {auth.userCan("editNodes") &&
                        <Button color="primary" size="sm" className="ml-2" disabled={this.state.inProgress} onClick={this.handleSubmit}>Update</Button>
                    }
                </div>
                {searchParams.modal === "battery-voltage" && <BatteryVoltageModal node={node}/>}
            </div>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item global-search">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

Index.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    type: PropTypes.string,
    auth: PropTypes.object
};

export default withGlobalStore(Index);