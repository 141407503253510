import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get,
    orderBy as _orderBy,
    includes as _includes,
    first as _first
} from "lodash";
import CommentForm from "./commment";
import Helper from "../../../../helpers/helper";
import SnoozeModal from "./snooze";
import ViewHeader from "./view/header";
import ViewFooter from "./view/footer";
import SweetAlert from "react-bootstrap-sweetalert";
import ActionListApi from "../../../../api/actionList";
import Toast from "../../../shared/toast";

import "../../../../assets/scss/components/users/user-icon.scss";
import {withGlobalStore} from "../../../../stores/GlobalStore";

class ViewForm extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            showComments: true,
            showReport: false,
            showReportHistory: false,
            isSnooze: false,
            cancelSnoozeConfirm: false,
            deleteCommentId: false
        };
    }

    toggleComments = () => {
        const {showComments} = this.state;

        this.setState({showComments: !showComments});
    }

    toggleReport = () => {
        const {showReport} = this.state;

        this.setState({showReport: !showReport});
    }

    toggleReportHistory = () => {
        const {showReportHistory} = this.state;

        this.setState({showReportHistory: !showReportHistory});
    }

    onSnooze = () => {
        this.setState({isSnooze: true});
    }

    onCancelSnooze = () => {
        this.setState({isSnooze: false});
    }

    setCancelSnoozeConfirm = (value = true) => {
        this.setState({cancelSnoozeConfirm: value});
    }

    sendCancelSnooze = () => {
        const {fetchActionItem, actionItem} = this.props;

        return ActionListApi
            .cancelSnooze({action_item_id: actionItem.id})
            .then((response) => {
                if (response.status === "ok") {
                    fetchActionItem()
                        .then(() => this.setState(
                            {cancelSnoozeConfirm: false},
                            () => Toast.success("The snooze that was applied to the Action Item has been canceled.")
                        ));
                }
            });
    }

    deleteComment = (comment_id) => {
        this.setState({deleteCommentId: comment_id});
    }

    confirmDeleteComment = () => {
        const {deleteCommentId} = this.state;
        const {fetchActionItem} = this.props;

        let formData = new FormData();
        formData.append("comment_id", deleteCommentId);

        ActionListApi
            .deleteComment(formData)
            .then((response) => {
                if (response.status === "ok") {
                    fetchActionItem().then(() => this.deleteComment(false));
                }
            });
    }

    render() {
        const {showComments, showReport, showReportHistory, isSnooze, cancelSnoozeConfirm, deleteCommentId} = this.state;
        const {actionItem, info, inProgress, submitComment, user, onShowCompletion, fetchActionItem, onClose, auth} = this.props;

        const comments = _get(actionItem, "comments", []);

        const report = _get(actionItem, "wwReports.0", {});
        const isNoActions = +_get(actionItem, "is_no_actions") === 1;

        const isCompletionReport = _get(report, "id") || isNoActions;

        const inactiveWwReports = _get(actionItem, "inactiveWwReports", []);
        const isTroubleshootingHold = +_get(actionItem, "is_troubleshooting_hold") === 1;
        const isArchive = _includes([5, 9], +_get(actionItem, "state"));
        const showCommentForm = !isArchive && (!_includes([3, 4, 5, 6], +actionItem.state) || isTroubleshootingHold);

        return (
            <React.Fragment>
                <ViewHeader actionItem={actionItem} info={info} onSnooze={this.onSnooze} onClose={onClose}/>

                <div className={"action-item-body"}>
                    <AiInfo actionItem={actionItem} user={user}/>

                    <div>
                        <div className={"ai-title-second ai-title-toggle"}>
                            <span>Description</span>
                        </div>
                    </div>

                    <div className={"mt-2 mb-2 ai-description"}>
                        <div dangerouslySetInnerHTML={{ __html: _get(actionItem, "description")}} />
                    </div>

                    {isCompletionReport &&
                        <React.Fragment>
                            <div>
                                <div className={"ai-title-second ai-title-toggle action"} onClick={this.toggleReport}>
                                    <div className={"d-flex align-items-center"}>
                                        <span className={"mr-4"}>Completion Report</span>
                                        <i className={showReport ? "fa fa-angle-up" : "fa fa-angle-down"} />
                                    </div>
                                </div>
                            </div>
                            {showReport &&
                                <ReportBlock
                                    actionItem={actionItem}
                                    report={report}
                                    info={info}
                                    user={user}
                                />
                            }
                        </React.Fragment>
                    }

                    {!!inactiveWwReports.length &&
                        <React.Fragment>
                            <div>
                                <div className={"ai-title-second action"} onClick={this.toggleReportHistory}>
                                    <div>
                                        <span className={"mr-4"}>Completion Report History</span>
                                        <i className={showReportHistory ? "fa fa-angle-up" : "fa fa-angle-down"} />
                                    </div>
                                </div>
                            </div>
                            {showReportHistory &&
                                <ReportHistoryBlock
                                    inactiveReports={inactiveWwReports}
                                    user={user}
                                    info={info}
                                />
                            }
                        </React.Fragment>
                    }

                    {!!(showCommentForm && auth.userCan("editEquipment")) &&
                        <React.Fragment>
                            <div>
                                <div className={"ai-title-second ai-title-toggle"}>
                                    <div>
                                        <span className={"mr-2"}>Add Question/Comment</span>
                                    </div>
                                </div>
                            </div>

                            <CommentForm
                                inProgress={inProgress}
                                submitComment={submitComment}
                            />
                        </React.Fragment>
                    }

                    {!!comments.length &&
                        <React.Fragment>
                            <div>
                                <div className={"ai-title-second ai-title-toggle action"} onClick={this.toggleComments}>
                                    <div className={"d-flex align-items-center"}>
                                        <span className={"mr-4"}>Comments</span>
                                        <i className={showComments ? "fa fa-angle-up" : "fa fa-angle-down"} />
                                    </div>
                                </div>
                            </div>
                            {showComments &&
                                <CommentsBlock
                                    comments={comments}
                                    user={user}
                                    deleteComment={this.deleteComment}
                                    isArchive={isArchive}
                                    actionItem={actionItem}
                                    info={info}
                                    auth={auth}
                                />
                            }
                        </React.Fragment>
                    }
                </div>

                {auth.userCan("editEquipment") &&
                    <ViewFooter
                        actionItem={actionItem}
                        onShowCompletion={onShowCompletion}
                        onCancelSnooze={this.setCancelSnoozeConfirm}
                    />
                }

                {isSnooze &&
                    <SnoozeModal
                        onClose={this.onCancelSnooze}
                        fetchActionItem={fetchActionItem}
                        actionItemId={_get(actionItem, "id")}
                        user={user}
                    />
                }

                {cancelSnoozeConfirm &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title="Cancel Snooze"
                        onConfirm={this.sendCancelSnooze}
                        onCancel={() => this.setCancelSnoozeConfirm(false)}
                    >
                        Are you sure you want to cancel Action Item snooze?
                    </SweetAlert>
                }

                {deleteCommentId &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title="Delete Comment"
                        onConfirm={this.confirmDeleteComment}
                        onCancel={() => this.deleteComment(false)}
                    >
                        Are you sure you want to delete this comment?
                    </SweetAlert>
                }

            </React.Fragment>
        );
    }
}

const AiInfo = ({actionItem, user}) => {
    const aiInfo = {};

    const type = _get(actionItem, "equipment_type", "");
    if (["gateway", "node", "router"].indexOf(type) !== -1) {
        aiInfo.locationName = _get(actionItem, "location_info") || _get(actionItem, type+".location_info") || "Unassigned";
        aiInfo.equipmentName = _get(actionItem, "equipment_info");
        if (type === "gateway") {
            aiInfo.componentName = "Gateway";
            aiInfo.serial = "<strong class='ai-info-title'>Gateway Serial:</strong> " + "<span class='ai-info-description'>" + _get(actionItem, "gateway.serial", "-") + "</span>";
        } else if (type === "node") {
            aiInfo.componentName = "Node";
            aiInfo.serial = "<strong class='ai-info-title'>Node Serial:</strong> " + "<span class='ai-info-description'>" + _get(actionItem, "node.serial", "-") + "</span>";
        }
        else {
            aiInfo.componentName = "Repeater";
            aiInfo.serial = "<strong  class='ai-info-title'>Repeater Serial:</strong> " + "<span class='ai-info-description'>" + _get(actionItem, "router.serial", "-") + "</span>";
        }
    } else {
        aiInfo.locationName = _get(actionItem, "installation_point.equipment.location.name", "Unassigned");
        aiInfo.equipmentName = _get(actionItem, "installation_point.equipment.name", "-");
        aiInfo.componentName = type === "mote" ? "Sensor Mote" : "Tethered sensor";
    }

    const installationPoint = _get(actionItem, "installation_point.name");
    const assetCode = _get(actionItem, "installation_point.equipment.asset_code");
    const createdBy = _get(actionItem, "created_by");
    const createdTime = Helper.dateToUserFormat(_get(actionItem, "time_created", ""), user);

    let sensorHex = _get(actionItem, "installation_point.sensor.sensor_id");
    const sensorHardwareId = _get(actionItem, "installation_point.sensor.hardware_id");
    if (parseInt(sensorHardwareId)) {
        sensorHex = parseInt(sensorHardwareId).toString(16).toUpperCase();
    } else if (_get(actionItem, "sensor_id")) {
        sensorHex = parseInt(_get(actionItem, "sensor_id")).toString(16).toUpperCase();
    }

    const node = _get(actionItem, "node_serial");

    const isReplaceEquipment = +_get(actionItem, "is_replace_equipment");

    return (
        <React.Fragment>
            <div className={"ai-info-block"}>
                <div className={"mb-3"}><strong className={"ai-info-title"}>Location:</strong> <span className={"ai-info-description"}>{_get(aiInfo, "locationName")}</span></div>
                {_get(aiInfo, "equipmentName") &&
                <div className={"mb-3"}><strong className={"ai-info-title"}>Equipment:</strong> <span className={"ai-info-description"}>{_get(aiInfo, "equipmentName")}</span></div>
                }
                {_get(aiInfo, "componentName") &&
                <div className={"mb-3"}><strong className={"ai-info-title"}>Component:</strong> <span className={"ai-info-description"}>{_get(aiInfo, "componentName")}</span></div>
                }
                {_get(aiInfo, "serial") &&
                    <div className={"mb-3"}><div className={"ai-info-title"} dangerouslySetInnerHTML={{ __html: _get(aiInfo, "serial")}} /></div>
                }
                {installationPoint &&
                <div className={"mb-3"}><strong className={"ai-info-title"}>Installation Point:</strong> <span className={"ai-info-description"}>{installationPoint}</span></div>
                }
                {assetCode &&
                <div className={"mb-3"}><strong className={"ai-info-title"}>Asset Code:</strong> <span className={"ai-info-description"}>{assetCode}</span></div>
                }
                {_get(actionItem, "equipment_type") === "mote"
                    ?
                    <div className={"mb-3"}><strong className={"ai-info-title"}>Mote:</strong> <span className={"ai-info-description"}>{sensorHex}</span></div>
                    :
                    <>
                        {sensorHex &&
                            <div className={"mb-3"}><strong className={"ai-info-title"}>Sensor:</strong> <span className={"ai-info-description"}>{sensorHex}</span></div>
                        }
                        {node &&
                            <div className={"mb-3"}><strong className={"ai-info-title"}>Node:</strong> <span className={"ai-info-description"}>{node}</span></div>
                        }
                    </>
                }

                {!!isReplaceEquipment &&
                <div className={"mb-3"}><strong className={"ai-info-title"}>Replace WW equipment:</strong> <span className={"ai-info-description"}>Yes</span></div>
                }
                <div className={"mb-3 d-flex"}>
                    <strong className={"ai-info-title"}>Created by:</strong>
                    <span className={"ai-info-description"}>{createdBy}</span>
                    <span className={"ai-create-date"}>{createdTime}</span>
                </div>
                {!!(type === "node" && _get(actionItem, "node.activeWwrSensors", []).length) &&
                    <div>
                        <strong className={"ai-info-title"}>Node Sensors:</strong>
                        <NodeSensors actionItem={actionItem} />
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

AiInfo.propTypes = {
    actionItem: PropTypes.object,
    user: PropTypes.object
};

const NodeSensors = ({actionItem}) => {
    const sensors = _get(actionItem, "node.activeWwrSensors", []);
    const orderedSensors = _orderBy(sensors, "position");

    return (
        <React.Fragment>
            <div>
                <div className={"d-flex align-item-center mb-3"}>
                    The following sensors are attached to this node. We provide this information to
                    assist you in locating the node. Please note the EAM number (&quot;Equipment&quot;) and Bed number (&quot;Alias&quot;).
                    Please also confirm that the Node Serial Number matches the serial number listed
                    in the header of this Action Item.
                </div>
                <div className={"row item-custom"}>
                    {orderedSensors.map((sensor, index) => {
                        let sensorHex = _get(sensor, "sensor_id");
                        const sensorHardwareId = _get(sensor, "hardware_id");
                        if (sensorHardwareId) {
                            sensorHex = parseInt(sensorHardwareId).toString(16).toUpperCase();
                        }

                        return (
                            <div className={"sensor-block"} key={index}>
                                <div><strong className={"dark-title"}>Sensor:</strong> {sensorHex}</div>
                                <div><strong className={"dark-title"}>Equipment:</strong> {_get(sensor, "installationPoint.equipment.name", "---")}</div>
                                <div><strong className={"dark-title"}>Location:</strong> {_get(sensor, "installationPoint.equipment.location.name", "---")}</div>
                                <div className={"sensor-position"}><span>{_get(sensor, "position")}</span></div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </React.Fragment>
    );
};

NodeSensors.propTypes = {
    actionItem: PropTypes.object,
};

const CommentsBlock = ({comments, user, deleteComment, isArchive, actionItem, info, auth}) => {

    const getCommentWithReportByIndex = (comments, index) => {
        const comment = {};

        while (index >= 0) {
            const prevCommentIsReport = _get(comments, index - 1) && +_get(comments, (index - 1)+".type") === 3;
            const currentCommentIsReport = _get(comments, index) && +_get(comments, (index)+".type") === 3;
            if (currentCommentIsReport) {
                if (prevCommentIsReport) {
                    index--;
                } else {
                    return _get(comments, index);
                }

            } else {
                break;
            }
        }

        return comment;
    };

    return (
        <div className={"mt-3 mb-3 comments-block"}>
            {comments.map((comment, index) =>
                <React.Fragment key={index}>
                    {+_get(comment, "type") === 1 &&
                        <div className={"comment"}>
                            <div className={"comment-header"}>
                                <div className={"user-icon"}>
                                    <img src="/assets/pic/icon-user.svg" alt="" />
                                </div>
                                <div className={"comment-info"}>
                                    <div className={"user-name"}>{_get(comment, "user_name")}</div>
                                    <div className={"comment-date"}>{Helper.dateToUserFormat(_get(comment, "time_created"), user)}</div>
                                </div>
                            </div>
                            {+_get(comment, "is_deleted") === 1 &&
                                <div>
                                    This comment was deleted by <strong>{_get(comment, "deleted_by") + " "}</strong>
                                    at <strong>{Helper.dateToUserFormat(_get(comment, "time_delete"), user)}</strong>
                                </div>
                            }
                            {+_get(comment, "is_deleted") !== 1 &&
                                <React.Fragment>
                                    <div>
                                        <div dangerouslySetInnerHTML={{ __html: _get(comment, "comment")}} />
                                        <div>
                                            {(_get(comment, "attachments", []) || []).map((file, key) =>
                                                <React.Fragment key={key}>
                                                    {_get(file, "url") &&
                                                        <React.Fragment>
                                                            {_get(file, "url") &&
                                                            _includes(["png", "jpg", "jpeg", "gif", "svg"], _first(_get(file, "extension", "").split(".").slice(-1)))
                                                                ?
                                                                <div className={"mr-3 mb-3 mt-3"} style={{maxWidth: "50%"}}>
                                                                    <img src={_get(file, "url")} alt=""/>
                                                                </div>
                                                                :
                                                                <div className={"mr-3 mb-3 mt-3"}>
                                                                    <i className={"fa fa-file"}/>
                                                                    <a href={_get(file, "url")} target={"_blank"} className={"link link-primary mr-2"}>
                                                                        {_get(file, "name") + "." + _get(file, "extension")}
                                                                    </a>
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                    {(!isArchive && _get(comment, "source") === "self-service" && auth.userCan("editEquipment")) &&
                                        <div
                                            className={"delete-comment"}
                                            title={"Delete comment"}
                                            onClick={() => deleteComment(_get(comment, "id"))}
                                        >
                                            <i className={"fa fa-trash"} />
                                        </div>
                                    }
                                </React.Fragment>

                            }
                        </div>
                    }
                    {+_get(comment, "type") === 2 &&
                        <div className={"comment status"}>
                            <div className={"date"}>{Helper.dateToUserFormat(_get(comment, "time_created"), user)}</div>
                            <div className={"status"}><div dangerouslySetInnerHTML={{ __html: _get(comment, "comment")}} /></div>
                            <div className={"description"}>{_get(comment, "postscript")}</div>
                            {_get(comments, index - 1) && +_get(comments, (index - 1)+".type") === 3 &&
                                <ReportComment
                                    comment={getCommentWithReportByIndex(comments, index - 1)}
                                    actionItem={actionItem}
                                    info={info}
                                    user={user}
                                />
                            }
                        </div>
                    }
                </React.Fragment>
            )}
        </div>
    );
};

CommentsBlock.propTypes = {
    comments: PropTypes.array,
    user: PropTypes.object,
    deleteComment: PropTypes.func,
    isArchive: PropTypes.bool,
    actionItem: PropTypes.object,
    info: PropTypes.object,
    auth: PropTypes.object
};

class ReportComment extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            showReport: false
        };
    }

    toggleReport = () => {
        const {showReport} = this.state;

        this.setState({showReport: !showReport});
    }

    render() {
        const {showReport} = this.state;
        const {comment, user, info, actionItem} = this.props;

        const actionReport = _get(comment, "related_entity_name") === "wwr_ww_action_list_reports";
        const noActionReport = _get(comment, "related_entity_name") === "wwr_ww_action_list_no_action_reports";

        if (!actionReport && !noActionReport) return "";

        return (
            <React.Fragment>
                {showReport
                    ?
                    <React.Fragment>
                        <button
                            className="btn btn-primary btn-sm ml-2 toggle-report"
                            onClick={this.toggleReport}
                        >
                            Hide Report
                        </button>
                        <div className={"text-left"}>
                            {actionReport &&
                                <ActionReport
                                    actionItem={actionItem}
                                    user={user}
                                    info={info}
                                    report={_get(comment, "wwActionListReport")}
                                />
                            }
                            {noActionReport &&
                                <NoActionReport
                                    actionItem={actionItem}
                                    user={user}
                                />
                            }
                        </div>
                    </React.Fragment>
                    :
                    <button
                        className="btn btn-primary btn-sm ml-2 toggle-report"
                        onClick={this.toggleReport}
                    >
                        Show Report
                    </button>
                }

            </React.Fragment>
        );
    }
}

ReportComment.propTypes = {
    comment: PropTypes.object,
    actionItem: PropTypes.object,
    user: PropTypes.object,
    info: PropTypes.object
};

const ReportBlock = ({actionItem, user, info, report}) => {
    const isNoActions = +_get(actionItem, "is_no_actions") === 1;

    return (
        <React.Fragment>
            <div className={"report-block"}>
                {isNoActions
                    ?
                    <NoActionReport
                        actionItem={actionItem}
                        user={user}
                    />
                    :
                    <ActionReport
                        actionItem={actionItem}
                        user={user}
                        info={info}
                        report={report}
                    />
                }

            </div>

        </React.Fragment>
    );
};

ReportBlock.propTypes = {
    actionItem: PropTypes.object,
    user: PropTypes.object,
    info: PropTypes.object,
    report: PropTypes.object
};

const NoActionReport = ({actionItem, user}) => {
    const verifiedBy = _get(actionItem, "verified_by");
    const verifiedTime = _get(actionItem, "verified_time");
    const verifiedUserTime = verifiedTime ? Helper.dateToUserFormat(verifiedTime, user) : "";

    return (
        <React.Fragment>
            <div className={"report-title"}>
                <i className="fa fa-info-circle"/>
                <strong className={"ml-2"}>No Action Required</strong>
            </div>
            <hr/>
            <div className={"report-info pl-4"}>
                <div>
                    <strong>Report Submitted: </strong>
                    {verifiedUserTime ? verifiedUserTime : "-"}
                    {verifiedBy ? <React.Fragment> by {verifiedBy}</React.Fragment> : ""}
                </div>
                <div>
                    <strong>Description/ Reason Provided: </strong>
                    <span dangerouslySetInnerHTML={{ __html: _get(actionItem, "no_actions_description")}} />
                </div>
            </div>
        </React.Fragment>
    );
};

NoActionReport.propTypes = {
    actionItem: PropTypes.object,
    user: PropTypes.object
};

const ActionReport = ({user, report, info}) => {
    return (
        <React.Fragment>
            <div className={"report-title"}>
                <i className="fa fa-info-circle"/>
                <strong className={"ml-2"}>Maintenance was conducted on Waites equipment</strong>
            </div>
            <hr/>
            <ReportInfo
                user={user}
                info={info}
                report={report}
            />
        </React.Fragment>
    );
};

ActionReport.propTypes = {
    actionItem: PropTypes.object,
    user: PropTypes.object,
    report: PropTypes.object,
    info: PropTypes.object
};

const ReportInfo = ({user, report}) => {
    const verifiedBy = _get(report, "verified_by");
    const verifiedTime = _get(report, "verified_time");
    const verifiedUserTime = verifiedTime ? Helper.dateToUserFormat(verifiedTime, user) : "";

    return (
        <React.Fragment>
            <div className={"report-info pl-4"}>
                <div>
                    <strong>Actions: </strong>
                    <span dangerouslySetInnerHTML={{ __html: _get(report, "text")}} />
                </div>
                <div>
                    <strong>Report Submitted: </strong>
                    {verifiedUserTime ? verifiedUserTime : "-"}
                    {verifiedBy ? <React.Fragment> by {verifiedBy}</React.Fragment> : ""}
                </div>
                {!!_get(report, "file", []).length &&
                    <div>
                        <strong>Attached files: </strong>
                        <div>
                            {(_get(report, "file", []) || []).map((file, key) =>
                                <React.Fragment key={key}>
                                    {_get(file, "path") &&
                                    <React.Fragment>
                                        {_get(file, "name") &&
                                        _includes(["png", "jpg", "jpeg", "gif", "svg"], _first(_get(file, "name", "").split(".").slice(-1)))
                                            ?
                                            <div className={"mr-3 mb-3 mt-3"} style={{maxWidth: "50%"}}>
                                                <img src={_get(file, "path")} alt=""/>
                                            </div>
                                            :
                                            <div className={"mr-3 mb-3 mt-3"}>
                                                <i className={"fa fa-file"}/>
                                                <a
                                                    href={_get(file, "path")}
                                                    target={"_blank"}
                                                    rel={"noreferrer"}
                                                    className={"link link-primary mr-2"}
                                                >
                                                    {_get(file, "name")}
                                                </a>
                                            </div>
                                        }
                                    </React.Fragment>
                                    }
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

ReportInfo.propTypes = {
    actionItem: PropTypes.object,
    user: PropTypes.object,
    report: PropTypes.object,
    info: PropTypes.object
};

const ReportHistoryBlock = ({inactiveReports, user, info}) => {
    const reportsSort = _orderBy(inactiveReports, "verified_time", "desc");

    return (
        <React.Fragment>
            {reportsSort.map((inactiveReport, index) =>
                <div className={"report-history"} key={index}>
                    <ReportInfo
                        report={inactiveReport}
                        user={user}
                        info={info}
                    />
                    <hr/>
                </div>
            )}
        </React.Fragment>
    );
};

ReportHistoryBlock.propTypes = {
    inactiveReports: PropTypes.array,
    user: PropTypes.object,
    info: PropTypes.object
};

ViewForm.propTypes = {
    actionItem: PropTypes.object,
    info: PropTypes.object,
    inProgress: PropTypes.bool,
    submitComment: PropTypes.func,
    fetchActionItem: PropTypes.func,
    user: PropTypes.object,
    onShowCompletion: PropTypes.func,
    onClose: PropTypes.func,
    auth: PropTypes.object,
};

export default withGlobalStore(ViewForm);
