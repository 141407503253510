import React, {Component} from "react";
import {withGlobalStore} from "../../stores/GlobalStore";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../assets/scss/components/feedback/feedback.scss";
import RefactorNotificationApi from "../../api/refactorNotification";
import Toast from "../../pages/shared/toast";

class RefactorNotification extends Component
{
    constructor(props) {
        super(props);

        this.onConfirm = this.onConfirm.bind(this);
    }

    onConfirm ()  {
        RefactorNotificationApi.viewed().then(({status, message}) => {
            status === "ok" ? window.location.reload() : Toast.error(message);
        });
    }

    render() {
        return (
            <React.Fragment>
                <SweetAlert
                    info={true}
                    show={true}
                    customClass={"sweet-alert-refactor"}
                    showCancel={false}
                    cancelBtnBsStyle="default"
                    confirmBtnText="Ok"
                    btnSize="sm"
                    onConfirm={() => this.onConfirm()}
                    title={""}>
                    We are excited to announce that we have deployed a major update to improve performance and security across the dashboard.
                    We have also added a new option so you can submit feedback directly from the dashboard.
                    If you encounter any technical difficulties or issues while using the dashboard, please click <b>“Help”</b> in the navigation pane and then select <b>“Submit Feedback”</b>
                    to provide us with your feedback so we can expedite the resolution of any potential issues.
                </SweetAlert>
            </React.Fragment>
        );
    }
}

export default withGlobalStore(RefactorNotification);