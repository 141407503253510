import {useQuery} from "@tanstack/react-query";
import {FAULT_FREQUENCY_API} from "./constants";
import ApiFaultFrequency from "../../../api/faultFrequency";
import {useMemo} from "react";

export const useFaultFrequencyQuery = (equipmentId) => {
    const {data: faultFrequency, ...rest} = useQuery([FAULT_FREQUENCY_API.GET_FREQUENCY, equipmentId], () =>
        ApiFaultFrequency.get(equipmentId)
    );

    return {faultFrequency: faultFrequency?.list, ...rest};
};

export const useInstallPointFaultFrequency = (equipmentId, installPointId) => {
    const {isSuccess, faultFrequency, ...rest} = useFaultFrequencyQuery(equipmentId);

    const filteredFaultFrequencies = useMemo(() => {
        if (!isSuccess) {
            return [];
        }
        return faultFrequency.filter((faultFrequency) => {
            return +faultFrequency.installation_point_id === +installPointId;
        });
    }, [isSuccess, faultFrequency]);

    return {filteredFaultFrequencies, isSuccess, ...rest};
};
