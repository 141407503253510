import React, {Component} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../stores/GlobalStore";

class EquipmentProfileEmpty extends Component
{
    render() {
        return (
            <React.Fragment>
                <div className={"empty-profile-page justify-content-center"}>
                    <div className="text-center text-info">No equipment profiles exist</div>
                </div>
            </React.Fragment>
        );
    }
}

EquipmentProfileEmpty.propTypes = {
    onCreate: PropTypes.func
};

export default withGlobalStore(EquipmentProfileEmpty);