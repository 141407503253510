import React, {Component} from "react";
import PropTypes from "prop-types";
import FormValidator from "../../../../helpers/form-validator";
import FeedbackFiles from "./uploadFiles";
import {Modal, ValidationError} from "../../../../shared";
import {get as _get, concat as _concat} from "lodash";
import "../../../../assets/scss/components/feedback/feedback.scss";
import Feedback from "../../../../api/feedback";
import Toast from "../../../../pages/shared/toast";

const rules = [
    {
        field: "description",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
];

class HelpFeedback extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator(rules);

        this.state = {
            data: {
                attachments: [],
                description: "",
            },
            dropzoneError: "",
            validation: this.validator.valid(),
        };

        this.onDropFile = this.onDropFile.bind(this);
        this.onRemoveFile = this.onRemoveFile.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onDropFile(files, fileRejections) {
        let {attachments} = this.state.data;
        let dropzoneError = "";
        attachments = _concat(attachments, files);

        if (fileRejections.length) {
            const {file, errors} = _get(fileRejections, 0);
            const {name} = file;

            dropzoneError = "Unable to upload file " + name + ". " + _get(errors, "0.message");
        }

        this.setState({
            data: {
                ...this.state.data,
                attachments,
            },
            dropzoneError,
        });
    }

    onRemoveFile(index) {
        let {attachments} = this.state.data;

        this.setState({
            data: {
                ...this.state.data,
                attachments: attachments.filter(function (file, fileIndex) {
                    return fileIndex !== index;
                }),
            },
        });
    }

    onChangeText(event) {
        const data = Object.assign({}, this.state.data, {[_get(event, "target.name", "")]: _get(event, "target.value", "")});
        const validation = this.validator.validate(data, _get(event, "target.name", ""));

        this.setState({data, validation});
    }

    handleSubmit() {
        const {data} = {...this.state};
        const {onClose, auth = {}} = this.props;

        const validation = this.validator.validate(data, "description");
        this.setState({validation});

        if (_get(validation, "isValid")) {
            this.setState({
                ...this.state,
            });

            let formData = new FormData();
            formData.append("description", _get(data, "description"));
            formData.append("customer_id", _get(auth, "customer.customer_id"));

            _get(data, "attachments").map((file, index) => {
                formData.append(`files[${index}]`, file);
            });

            Feedback.create(formData).then(({status, message}) => {
                if (status === "ok") {
                    Toast.success("Thank you for your feedback.");
                    onClose();
                } else {
                    Toast.error(message);
                    this.setState({
                        ...this.state,
                    });
                }
            });
        }
    }

    uploadFiles(feedbackId) {
        const {attachments} = {...this.state.data};

        if (attachments.length) {
            attachments.map((file) => {
                let formData = new FormData();
                formData.append("file", file);
                formData.append("feedback_id", feedbackId);

                Feedback.uploadFile(formData).then();
            });
        }
    }

    render() {
        const {validation, dropzoneError} = this.state;
        const {attachments, description} = this.state.data;

        return (
            <Modal
                {...this.props}
                size="lg"
                title={"Submit Feedback"}
                withoutFooter={false}
                onSubmit={this.handleSubmit}
                submitTitle={"Submit"}
                className="user_guide-modal custom-modal"
            >
                <div>
                    <div className="form-group mb-2">
                        <label>
                            Description:<span className="color-danger">*</span>
                        </label>
                        <textarea
                            rows={15}
                            placeholder={"Enter feedback"}
                            name={"description"}
                            value={description}
                            className={"form-control" + (_get(validation, "description.message") ? " is-invalid" : "")}
                            onChange={this.onChangeText}
                        />
                        <ValidationError message={_get(validation, "description.message")} />
                    </div>

                    <div className={"feedback-files-block"}>
                        <FeedbackFiles
                            onDrop={this.onDropFile}
                            onRemove={this.onRemoveFile}
                            attachments={attachments}
                            error={dropzoneError}
                            mainPlaceholder={
                                <>
                                    <b>Drag and drop or upload files.</b> <br />
                                    Max upload file size: 25 MB <br />
                                    Max number of files: 20
                                </>
                            }
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

HelpFeedback.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
    auth: PropTypes.object,
    user: PropTypes.object,
};

export default HelpFeedback;
