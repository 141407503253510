import React from "react";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useZoomReset} from "../../hooks/useZoomReset";
import {useChartRef} from "../../hooks/useChartRef";

const ZoomButton = () => {
    const zoomed = useChartStateStoreContext((state) => state.settings.zoomed);

    const chartRef = useChartRef();

    const customResetZoom = useZoomReset();

    const handleResetZoom = () => {
        chartRef.current.chart.yAxis[0].zoom();
        chartRef.current.chart.xAxis[0].zoom();
        customResetZoom();
    };

    if (!zoomed) {
        return <></>;
    }
    return (
        <button
            className="highcharts-reset-zoom-custom"
            onClick={handleResetZoom}
        >
            Reset Zoom
        </button>
    );
};

export default ZoomButton;
