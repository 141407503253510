import {cloneDeep as _cloneDeep, get as _get} from "lodash";
import {removeChartNavigation} from "../../../../helpers/removeChartNavigation";
import {CHART_CONFIG} from "../../../../constants/chart";
import {useMemo} from "react";
import {useIsDarkTheme} from "../../../../hooks/helpers/theme";
import {setDarkModeForChart} from "../../../../helpers/setDarkModeForChart";

const baseOptions = removeChartNavigation(_cloneDeep(CHART_CONFIG));

export const useSignalStrengthChartDirector = ({series = [], tooltip, label = "dBm"}) => {
    const isDarkTheme = useIsDarkTheme();

    return useMemo(() => {
        let baseOptionsLocal = baseOptions;
        const hasChartData = !!_get(series, [0, "data"], []).length;

        if (isDarkTheme === "true") {
            baseOptionsLocal = setDarkModeForChart(baseOptionsLocal);
        }

        return {
            ...baseOptionsLocal,
            series: [...series],
            tooltip,
            yAxis: {
                labels: {
                    formatter({value}) {
                        return `${value} ${label}`;
                    },
                },
                max: hasChartData ? 20 : null,
                min: hasChartData ? -140 : null,
                tickAmount: 9,
                plotLines: [
                    {
                        color: "red",
                        dashStyle: "LongDash",
                        value: -65,
                    },
                ],
                plotBands: [{
                    color: "#c97b7d",
                    from: -65,
                    to: -140,
                }],
            },
        };
    }, [series, tooltip, isDarkTheme]);
};
