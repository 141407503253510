import Api from "./api";

const User = {
    profile: () => {
        return Api.get("user/profile", {host: process.env.SSO_URL});
    },
    create: (body) => {
        return Api.post("user/create", body, {host: process.env.SSO_URL});
    },
    updateProfile: (body) => {
        return Api.post("user/update-profile", body, {credentials: "include", host: process.env.SSO_URL});
    },
    updateAlertSetting: (body) => {
        return Api.post("user/update-alert-setting", body, {credentials: "include", host: process.env.SSO_URL});
    },
    update: (body) => {
        return Api.post("user/update", body, {credentials: "include", host: process.env.SSO_URL});
    },
    delete: (body) => {
        return Api.delete("user/delete", body, {host: process.env.SSO_URL});
    },
    changePassword: (body) => {
        return Api.post("user/change-password", body, {host: process.env.SSO_URL});
    },
    getSelfUser: (userId) => {
        return Api.get("user/get-self-user/" + userId, {host: process.env.SSO_URL});
    },
    getSelfUsers: (options) => {
        return Api.get("user/get-self-users", {host: process.env.SSO_URL, ...options});
    },
    resetPassword: (data) => {
        return Api.post("user/reset-password",{...data}, {host: process.env.SSO_URL});
    },
    resendEmail: (data) => {
        return Api.post("full-service/user/resend-confirm",{...data}, {host: process.env.SSO_URL});
    },
    manageAlarms: data => {
        return Api.post("user/manage-alarms", data, {host: process.env.SSO_URL});
    },
    updateEmailEvents: data => {
        return Api.post("user/update-email-events", data, {host: process.env.SSO_URL});
    }
};

export default User;
