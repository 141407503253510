import React, {memo} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import {useChartRef} from "../../../hooks/useChartRef";

import {useDrawChartEffect} from "../../../hooks/useDrawChartEffect";
import SpeedNotSetLabel from "../../../../../components/speedNotSetLabel";
import {useEquipmentByParams} from "../../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useSpeedSettings} from "../../../hooks/helpers/useSpeedSettings";
import {useChartStateStoreContext} from "../../../store/hooks/useStoreContext";
import {usePointData} from "../../../../hooks/usePointsData";
import {useSetSpeedModal} from "../../../../../../../modals/installationPoint/hooks/useSetSpeedModal";
import {useCurrentTimestampsInfo} from "../../../hooks/helpers/useCurrentTimestampsInfo";
import HPFDiffLabel from "../../../../../components/HPFDiffLabel";
import {useHighPassFilterSyncStoreByPoint} from "../../../../store/sync/HighPassFilterSyncStore";
import {useChartHoverEffect} from "../../../../hooks/keyboard/useChartHoverEffect";
import {ON_DEMAND_REASON} from "../../../../../../../constants/constants";
import {WosLabel} from "../../../../../components/WosLabel";
import {HeatsinkLabel} from "../../../../../components/HeatsinkLabel";
import {OverMaxLabel} from "../../../../../components/overMaxLabel/OverMaxLabel";

const HighchartMemoComponent = () => {
    const chartRef = useChartRef();
    const options = useDrawChartEffect();

    const {equipmentItem} = useEquipmentByParams();
    const {currentSpeed, currentSpeedFrom} = useSpeedSettings();
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const {setSpeedModal, setIsCurrentSpeedModal} = useChartStateStoreContext((state) => state.actions);
    const pointData = usePointData(selectedPoint);
    const [showSetSpeedModal] = useSetSpeedModal();
    const {isOnDemand, onDemandReason} = useCurrentTimestampsInfo();
    const highpassFilter = useHighPassFilterSyncStoreByPoint(selectedPoint, pointData);

    useChartHoverEffect(chartRef);

    const handleSetSpeedModal = (type) => {
        setIsCurrentSpeedModal(false);
        setSpeedModal({show: true, type});
        showSetSpeedModal();
    };

    const isWakeOnShakeActive = !!pointData.wosTrigger;

    return (
        <div className={"chart-block-fft"}>
            <div className="labels-block">
                <SpeedNotSetLabel
                    className="mx-auto my-0"
                    onClick={() => handleSetSpeedModal("manually")}
                    equipment={equipmentItem}
                    pointData={pointData}
                    currentSpeed={currentSpeed}
                    currentSpeedFrom={currentSpeedFrom}
                />
                {isOnDemand && onDemandReason !== ON_DEMAND_REASON.IDLE_THRESHOLD && (
                    <>
                        {onDemandReason === ON_DEMAND_REASON.LOCAL_ALARM ? (
                            <span className="badge badge-info">On Demand by Alert</span>
                        ) : (
                            <span className="badge badge-info">On Demand</span>
                        )}
                    </>
                )}
                <OverMaxLabel />
                {isWakeOnShakeActive && <WosLabel />}
                {!!pointData.is_heatsink && <HeatsinkLabel />}

                <HPFDiffLabel
                    className="mx-auto my-0"
                    chartType={+chartType}
                    pointData={pointData}
                    chartHPF={+highpassFilter}
                />
            </div>
            <HighchartsReact
                containerProps={{
                    className: "fft-chart",
                }}
                ref={chartRef}
                updateArgs={[true, true, false]}
                highcharts={Highcharts}
                constructorType={"chart"}
                options={options}
            />
        </div>
    );
};

export default memo(HighchartMemoComponent);
