import React, {Component} from "react";
import Toast from "../shared/toast";
import SearchInput from "../shared/search-input";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../stores/GlobalStore";
import {Link} from "react-router-dom";
import LimitSelect from "../shared/limit-select";
import {Loader, Pager} from "../../shared";
import SweetAlert from "react-bootstrap-sweetalert";
import Table from "./table";
import RoutesApi from "../../api/routes";
import {cloneDeep as _cloneDeep, get as _get} from "lodash";
import { HeaderSimple } from "../../shared/header";
import {withLocationSelectStore} from "../../stores/LocationSelectStore";
import CollapseLocationSelect from "../../shared/collapseLocationSelect/collapseLocationSelect";

class Routes extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{name: "Manage Routes"}],
            loader: true,
            list: [],
            pagination: {
                page: 1,
                pages: 1,
                perpage: 10,
                total: 0
            },
            sort: {
                field: "",
                sort: "asc"
            },
            filter: {
                query: "",
                locationId: props.getLocationId()
            },
            globalSearchString: "",
            deleteId: ""
        };
    }

    componentDidMount() {
        Promise.all([
            this.handleFetch(),
        ]);
    }

    handleFetch = () => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        return RoutesApi.list({
            query: {
                sort: this.state.sort,
                filter: this.state.filter,
                pagination: this.state.pagination
            },
            signal: this.signal
        }).then((response) => {
            if (response) {
                const {meta = [], list = {}} = response;
                this.setState({
                    loader: false,
                    pagination: Object.assign({}, this.state.pagination, meta),
                    list
                });
            }
        });
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    handleSortChange = (field = "") => {
        const {sort} = this.state;

        this.setState({
            sort: Object.assign({}, {
                field,
                sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"
            })
        }, this.handleFetch);
    }

    handlePagerChange = (page = 1) => {
        this.setState({
            loader: true,
            pagination: Object.assign({}, this.state.pagination, {page})
        }, this.handleFetch);
    }

    handleFilterChange = (obj = {}) => {
        this.finished = false;
        this.setState({
            filter: Object.assign({}, this.state.filter, obj),
            pagination: Object.assign({}, this.state.pagination, {page: 1}),
            loader: true
        }, this.handleFetch);
    }

    searchChange = (value) => {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }
        this.handleFilterChange({query: value});
    }

    handleLimitChange = e => {
        const {value} = e.target;
        this.setState({
            loader: true,
            pagination: {
                ...this.state.pagination,
                page: 1,
                perpage: value
            }
        }, this.handleFetch);
    };

    beforeDelete = (id) => {
        this.setState({deleteId: id});
    }

    onDelete = () => {
        const {deleteId, list} = this.state;
        let pagination = _cloneDeep(this.state.pagination);

        if (+pagination.page > 1 && list.length === 1) {
            pagination.page = +pagination.page - 1;
        }

        this.setState({loader: true, pagination}, () => {
            RoutesApi
                .delete(deleteId)
                .then(() => {
                    Toast.success("The route has been deleted.");
                    this.setState({deleteId: ""}, () => this.handleFetch());
                });
        });
    }

    cancelDelete = () => {
        this.setState({deleteId: ""});
    }

    render() {
        const {
            breadcrumbs,
            loader,
            list,
            pagination,
            sort,
            filter,
            globalSearchString,
            deleteId,
        } = this.state;

        return (
            <React.Fragment>
                <div>
                    <HeaderSimple
                        breadcrumbs={ breadcrumbs }
                        globalSearchString={ globalSearchString }
                        handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                    />
                    <div className="subheader">
                        <div className="subheader-title">Manage Routes</div>
                        <div className="subheader-controls">
                            <div className="subheader-toolbar">
                                <Link
                                    to="/routes/add"
                                    className="btn btn-primary btn-sm"
                                >
                                    <i className="fa fa-plus"/> Add Route
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <div className="block-header">
                            <div className="block-label">
                                <div className="form-list form-list--row">
                                    <div className="form-group form-group--inline">
                                        <React.Fragment>
                                            <div className="form-label"><label>Asset Tree Branch:</label></div>
                                            <CollapseLocationSelect
                                                className={"form-control form-control-sm"}
                                                selectName={"locationId"}
                                                value={+_get(filter, ["locationId"], 0)}
                                                onChange={(e) => {
                                                    this.handleFilterChange({
                                                        locationId: _get(e, "target.value")
                                                    });
                                                }}
                                                emptyOptionLabel={"All"}
                                                checkStoreValue={true}
                                                withFavorite={true}
                                            />
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                            <div id="top-pagination-block" className="block-pagination">
                                {pagination.total > 10 &&
                                    <div className="limit-select-block">
                                        <LimitSelect
                                            name={"perpage"}
                                            onChange={this.handleLimitChange}
                                            defaultValue={pagination.perpage}
                                            limits={[10, 20, 50, 100]}
                                        />
                                    </div>
                                }
                                {pagination.pages > 1 &&
                                    <Pager
                                        page={pagination.page}
                                        pages={pagination.pages}
                                        perPage={pagination.perpage}
                                        onPagerChange={this.handlePagerChange}
                                        query={filter.query}
                                    />
                                }
                            </div>
                            <div className="block-toolbar">
                                <div className="block-toolbar-wrapper">
                                    <div>
                                        <SearchInput
                                            placeholder="Search Routes"
                                            additionalClasses="routes-search-field"
                                            query={filter.query}
                                            onChange={this.searchChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block-body">
                            {loader
                                ?
                                <Loader/>
                                :
                                <div className="nameplates-templates-table">
                                    <Table
                                        list={list}
                                        sort={sort}
                                        query={filter.query}
                                        onSortChange={this.handleSortChange}
                                        onEdit={() => {}}
                                        onDelete={this.beforeDelete}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    {deleteId &&
                        <SweetAlert
                            warning
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnBsStyle="default"
                            btnSize="xs"
                            title="DELETE ROUTE"
                            openAnim={false}
                            closeAnim={false}
                            onConfirm={this.onDelete}
                            onCancel={this.cancelDelete}
                        >
                            Are you sure you want to delete this route?
                        </SweetAlert>
                    }
                </div>
            </React.Fragment>
        );

    }
}

Routes.propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object,

    // LocationSelectStore
    getLocationId: PropTypes.func
};

export default withLocationSelectStore(withGlobalStore(Routes));