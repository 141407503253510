import { useModal } from "react-modal-hook";
import HelpIndex from "../components";
import React from "react";

export const useHelpModal = (withHelpFft = false, withHelpSimple = false, withHelp3d = false) => {
    const [showModal, hideModal] = useModal(() => {
        return <HelpIndex showModal={true} onClose={hideModal} withHelpFft={withHelpFft} withHelpSimple={withHelpSimple} withHelp3d={withHelp3d}/>;
    });
    return [showModal, hideModal];
};
