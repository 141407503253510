import {ResetSortButton} from "../shared/resetSortButton";
import PropTypes from "prop-types";
import React from "react";
import {get as _get} from "lodash";
import Row from "./row";

const headersList = {
    "name": {title: "Name", sort: true},
    "description": {title: "Description", sort: false},
    "created_at": {title: "Created At", sort: false},
    "created_by": {title: "Created By", sort: false},
    "progress": {title: "Progress", sort: false},

    "actions": {title: "Actions", sort: false, additionalClasses: "table-buttons-th", component: ResetSortButton}
};

const Table = ({list, sort, onSortChange, query, onEdit, onDelete}) => {
    const listLen = Object.keys(headersList).length;

    return (
        <React.Fragment>
            <table className="table table-hover tl-fixed">
                <thead>
                    <tr>
                        {Object.keys(headersList).map((key) => {
                            let component = "";
                            const RowComponent = _get(headersList, [key, "component"], false);
                            if (RowComponent) {
                                component = <RowComponent sort={sort} resetSort={() => onSortChange("")}/>;
                            }
                            return (
                                headersList[key].sort
                                    ?
                                    <th
                                        className={headersList[key].additionalClasses || ""}
                                        key={key}
                                        style={headersList[key].style || {}}
                                        onClick={() => onSortChange(key)}
                                    >
                                        <div className="title">
                                            <span>{headersList[key].title}</span>
                                            {sort.field === key ? <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")}/> : <i className={"fa fa-sort"} />}
                                        </div>
                                    </th>
                                    :
                                    <th className={headersList[key].additionalClasses || ""} key={key} style={headersList[key].style || {}}>{headersList[key].title} {component}</th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {list.length > 0
                        ?
                        list.map((route, index) =>
                            <Row
                                key={index}
                                route={route}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                query={query}
                            />
                        )
                        :
                        <tr>
                            <td colSpan={listLen} className="text-center text-info">
                                {query !== "" ? "No items match your search." : "No routes exist."}
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </React.Fragment>
    );
};

Table.propTypes = {
    list: PropTypes.array,
    sort: PropTypes.object,
    query: PropTypes.string,
    onSortChange: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};

export default Table;