import React, {Component} from "react";
import PropTypes from "prop-types";
import HelpFft from "../../modals/help/components/fft";
import HelpSimple from "../../modals/help/components/simple";
import HelpZoom from "../chart/highcharts/zoom";
import Helper from "../../helpers/helper";
import Help3d from "../../modals/help/components/3d";

import "../../assets/scss/components/help/help.scss";

const modalSettings = {
    titleDefault: "KEYBOARD SHORTCUTS",
    titleHelpFft: "FFT/TWF Charts",
    titleHelpSimple: "Temperature/RMS/Tachometer Charts",
    titleHelp3d: "Waterfall Plot",
    zoomAdditionalTitle: "ALL CHARTS"
};

class HelpPage extends Component {
    constructor(props) {
        super(props);

        window.addEventListener( "hashchange", this.changeHashListener, false);
    }

    changeHashListener = () => {
        this.forceUpdate();
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.changeHashListener, false);
    }

    render() {
        const withHelpFft = Helper.getHashParams().fft;
        const withHelpSimple = Helper.getHashParams().simple;
        const withHelp3d = Helper.getHashParams().waterfall;

        let currentSettings = {};

        if (withHelpFft && withHelpSimple && withHelp3d) {

            currentSettings = modalSettings;
            currentSettings.modalTitle = modalSettings.titleDefault;

        } else if (withHelpFft) {

            currentSettings.modalTitle = modalSettings.titleHelpFft;
            currentSettings.titleHelpFft = modalSettings.titleDefault;
            currentSettings.zoomAdditionalTitle = "";

        } else if (withHelpSimple) {

            currentSettings.modalTitle = modalSettings.titleHelpSimple;
            currentSettings.titleHelpSimple = modalSettings.titleDefault;
            currentSettings.zoomAdditionalTitle = "";

        } else if (withHelp3d) {

            currentSettings.modalTitle = modalSettings.titleHelp3d;
            currentSettings.titleHelp3d = modalSettings.titleDefault;
            currentSettings.zoomAdditionalTitle = "";

        }

        const is3dOnly = withHelp3d && !withHelpSimple && !withHelpFft;

        return (
            <React.Fragment>
                <div className={"help-page"}>
                    <div className={"title"}>{currentSettings.modalTitle}</div>
                    {withHelpSimple &&
                    <HelpSimple title={currentSettings.titleHelpSimple} />
                    }
                    {withHelpFft &&
                        <HelpFft title={currentSettings.titleHelpFft} />
                    }
                    {withHelp3d &&
                    <Help3d title={currentSettings.titleHelp3d} />
                    }
                    {!is3dOnly && <HelpZoom additionalTitle={currentSettings.zoomAdditionalTitle} />}
                </div>
            </React.Fragment>
        );
    }
}

HelpPage.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object
};

export default HelpPage;