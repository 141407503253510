import React, {Component} from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import "../../../../assets/scss/components/feedback/feedback.scss";
import {
    get as _get,
} from "lodash";

class FeedbackFiles extends Component
{
    constructor(props) {
        super(props);

    }

    render() {
        const {onDrop, attachments, onRemove, error} = this.props;

        return (
            <React.Fragment>
                <Dropzone
                    onDrop={onDrop}
                    noDrag={true}
                    multiple={true}
                    maxFiles={20}
                    maxSize={26214400}
                    accept={".png, .jpg, .jpeg, .gif, .bmp, .pdf, .csv, .xslx, .ptt, .pttx, .docx, .doc, .txt, .mp4, .avi, .wmv, .mov"}
                >
                    {({getRootProps, getInputProps}) => (
                        <React.Fragment>
                            <section>
                                <aside className="dropzone-thumb-wrapper">
                                    {
                                        attachments.map((file, index) =>
                                            <div className="dropzone-thumb-item feedback-dropzone-item" key={index}>
                                                <div className="dropzone-thumb-img-wrapper">
                                                    <i className="fa fa-file" aria-hidden="true"/>
                                                    <span className="feedback-file-name">
                                                        {_get(file, "name")}
                                                    </span>
                                                </div>
                                                <div className="dropzone-thumb-remove-wrapper">
                                                    <span className="dropzone-thumb-remove" onClick={() => onRemove(index)}>
                                                        <i className="fa fa-times-circle"/>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </aside>

                                <div className={"dropzone dropzone-custom " + (error ? "error" : "")} {...getRootProps()}>
                                    <div>
                                        <span className="dropzone-icon"><i className="fa fa-cloud-upload-alt"/></span>
                                        <input {...getInputProps()} />
                                        <p>{this.props.mainPlaceholder || "Drag-and-drop files or upload it."}</p>
                                        {error ? <label className={"upload-error"}>{error}</label>  : "" }
                                    </div>
                                </div>
                            </section>
                        </React.Fragment>

                    )}
                </Dropzone>
            </React.Fragment>
        );
    }
}

FeedbackFiles.propTypes = {
    onRemove: PropTypes.func,
    mainPlaceholder: PropTypes.string,
    onDrop: PropTypes.func,
    attachments: PropTypes.array,
    error: PropTypes.string
};

export default FeedbackFiles;