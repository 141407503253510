import React, {Component} from "react";
import PropTypes from "prop-types";

class CompletionHeader extends Component
{
    render() {
        return (
            <React.Fragment>
                <div className={"action-item-head"}>
                    <div className={"d-flex justify-content-between"}>
                        <div className={"ai-title"}>Completion Report</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

CompletionHeader.propTypes = {
    onCancelCompletion: PropTypes.func
};

export default CompletionHeader;