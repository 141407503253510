import {useOverlaySyncStoreActions} from "../../../store/sync/overlaySyncStore";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useIsImpactVue} from "../../../hooks/useChartTypes";

export const useOverlayControls = () => {
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);

    const {enableOverlay, disableOverlay} = useOverlaySyncStoreActions();

    return {
        enableOverlay: (config) => enableOverlay(selectedPoint, config, isImpactVue),
        disableOverlay: () => disableOverlay(selectedPoint, isImpactVue),
    };
};
