import React, {Component} from "react";
import PropTypes from "prop-types";
import BasePopover from "../../shared/base-popover";
import {
    get as _get,
} from "lodash";

class TemplateParametersPopover extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }

    toggle = () => {
        this.setState({show: !this.state.show});
    };

    render() {
        const {show} = this.state;
        const {parameters} = this.props;

        return (
            <React.Fragment>
                <BasePopover
                    {...this.props.popoverProps}
                    isOpen={show}
                    toggle={this.toggle}
                >
                    <React.Fragment>
                        {parameters.length > 0 &&
                        <div className={"nameplate-template-table"}>
                            {parameters.map((parameter, index) =>
                                <div key={index} className={"template-row"}>
                                    <div className={"template-col d-flex flex-nowrap p-2"} style={{minWidth: "100px"}}>
                                        <strong>{_get(parameter, "name")}</strong>
                                    </div>
                                </div>
                            )}
                        </div>
                        }
                    </React.Fragment>
                </BasePopover>
            </React.Fragment>
        );
    }
}

TemplateParametersPopover.propTypes = {
    popoverProps: PropTypes.object,
    parameters: PropTypes.array,
};
export default TemplateParametersPopover;