import {useQuery} from "@tanstack/react-query";
import NameplateApi from "../../../api/nameplate";
import {NAMEPLATE_API} from "./constants";

export const useNameplatesListForOverlayQuery = (instPoints) => {

    return useQuery({
        queryFn: () => NameplateApi.listForOverlay({instPoints}),
        queryKey: [NAMEPLATE_API.GET_NAMEPLATE_FOR_OVERLAY, instPoints],
        enabled:!!instPoints
    });
};
