import Api from "./api";

const Note = {
    create: (data) => Api.postFormData("notes", data),
    update: (id, data) => Api.postFormData(`notes/${id}`, data),
    delete: id => Api.delete(`notes/${id}`),
    noteCollectorLocations: (params) => {
        return Api.get("notes/notes-collector-locations", {...params});
    },
};

export default Note;