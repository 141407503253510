import React, {PureComponent, useState} from "react";
import PropTypes from "prop-types";
import {get as _get, filter as _filter} from "lodash";
import {Link} from "react-router-dom";
import Highlighter from "react-highlight-words";
import Loader from "../../../../shared/loader/loader";
import InfiniteScroll from "react-infinite-scroller";
import {Button} from "reactstrap";

export class DefaultLevelsTable extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showDesktop: false,
            showDesktopParent: false,
            equipmentIdToggle: null
        };
    }

    toggleEquipment = () => {
        this.setState({
            showDesktop: !this.state.showDesktop
        });
    };

    toggleEquipmentParent = (equipmentId) => {
        if(this.state.equipmentIdToggle === equipmentId) {
            this.setState({
                equipmentIdToggle: null
            });
        } else {
            this.setState({
                equipmentIdToggle: equipmentId
            });
        }
    }

    render() {
        const {list, units, handleSetValueModal, search, loader, axisLabels, onNextPage, hasMore, auth, location} = this.props;
        const {showDesktop, equipmentIdToggle, showDesktopParent} = this.state;

        return (
            <div className="wrapper-default-table">
                <table className="table table-bordered sticky-wrapper default-levels-table">
                    {search.length === 0 &&
                        <thead className="sticky-table-header">
                            <tr className="adaptive-row-table-all-edit">
                                <th className="bg-gray-block font-weight-bold">{_get(location, "name", "ALL EQUIPMENT")} <Button onClick={this.toggleEquipment} className="btn-toogle-desktop" style={{padding: "6px 6px", position: "absolute", top: "3px", right: "17px"}} size="xs"><img src="/assets/pic/icon-dropdown.svg" alt="" /></Button></th>
                                <th className={showDesktop ? "bg-gray-block" : "bg-gray-block hide-mobile"}>
                                    Temperature, {_get(units, "temp", "N/A")}
                                    {!!list.length &&
                                    <div className="action-icon-tbl">
                                        {auth.userCan("editAlarms") &&
                                            <button
                                                className="btn btn-icon btn-sm btn-primary"
                                                onClick={() => handleSetValueModal({
                                                    show: true,
                                                    equipment: {},
                                                    installationPoint: {},
                                                    readingTypes: {
                                                        name: "Temperature",
                                                        list: ["1"],
                                                        units: _get(units, "temp", "N/A")
                                                    }
                                                })}
                                            >
                                                <i className="fa fa-pencil-alt"/>
                                            </button>
                                        }
                                    </div>
                                    }
                                </th>
                                <th className={showDesktop ? "bg-gray-block" : "bg-gray-block hide-mobile"}>
                                    Acceleration, {_get(units, "acc", "N/A")}
                                    {!!list.length &&
                                    <div className="action-icon-tbl">
                                        {auth.userCan("editAlarms") &&
                                            <button
                                                className="btn btn-icon btn-sm btn-primary"
                                                onClick={() => handleSetValueModal({
                                                    show: true,
                                                    equipment: {},
                                                    installationPoint: {},
                                                    readingTypes: {
                                                        name: "Acceleration",
                                                        list: ["3", "6", "22"],
                                                        units: _get(units, "acc", "N/A")
                                                    }
                                                })}
                                            >
                                                <i className="fa fa-pencil-alt"/>
                                            </button>
                                        }
                                    </div>
                                    }
                                </th>
                                <th className={showDesktop ? "bg-gray-block" : "bg-gray-block hide-mobile"}>
                                    Velocity, {_get(units, "vel", "N/A")}
                                    {!!list.length &&
                                    <div className="action-icon-tbl">
                                        {auth.userCan("editAlarms") &&
                                            <button
                                                className="btn btn-icon btn-sm btn-primary"
                                                onClick={() => handleSetValueModal({
                                                    show: true,
                                                    equipment: {},
                                                    installationPoint: {},
                                                    readingTypes: {
                                                        name: "Velocity",
                                                        list: ["4", "7", "23"],
                                                        units: _get(units, "vel", "N/A")
                                                    }
                                                })}
                                            >
                                                <i className="fa fa-pencil-alt"/>
                                            </button>
                                        }
                                    </div>
                                    }
                                </th>
                                <th className={showDesktop ? "bg-gray-block" : "bg-gray-block hide-mobile"}>
                                    PK-PK, {_get(units, "pkpk", "N/A")}
                                    {!!list.length &&
                                    <div className="action-icon-tbl">
                                        {auth.userCan("editAlarms") &&
                                            <button
                                                className="btn btn-icon btn-sm btn-primary"
                                                onClick={() => handleSetValueModal({
                                                    show: true,
                                                    equipment: {},
                                                    installationPoint: {},
                                                    readingTypes: {
                                                        name: "PK-PK",
                                                        list: ["16", "18", "27"],
                                                        units: _get(units, "pkpk", "N/A")
                                                    }
                                                })}
                                            >
                                                <i className="fa fa-pencil-alt"/>
                                            </button>
                                        }
                                    </div>
                                    }
                                </th>
                            </tr>
                        </thead>
                    }
                    <InfiniteScroll
                        loadMore={() => {
                            if (list.length > 0) {
                                onNextPage();
                            }
                        }}
                        hasMore={!!hasMore}
                        loader={list.length > 0 ? <tr key="loader"><td colSpan="5" className="text-center"><Loader/></td></tr> : ""}
                        element="tbody"
                    >
                        {list.length > 0 ?
                            list.map((equipment) => {
                                const showAll = equipment.showAll === false
                                    && equipment.installationPoints.length !== _filter(equipment.installationPoints, installationPoint => installationPoint.foundBySearch).length
                                    && search.trim().length > 1;
                                return (
                                    <React.Fragment key={equipment.id}>
                                        <tr className="bg-gray-block adaptive-row-equipment">
                                            <td className="font-weight-bold">
                                                <Highlighter
                                                    searchWords={[search.trim()]}
                                                    autoEscape={true}
                                                    textToHighlight={equipment.name}
                                                />
                                                <Button onClick={() => this.toggleEquipmentParent(equipment.id)} className={equipment.id === equipmentIdToggle && showDesktopParent ? "btn-toogle-desktop rotateDeg" : " btn-toogle-desktop"} style={{padding: "6px 6px", position: "absolute", top: "4px", right: "17px"}} size="xs"><img src="/assets/pic/icon-dropdown.svg" alt="" /></Button>
                                            </td>
                                            <td className={(equipment.id === equipmentIdToggle) ? "pr-30" : "pr-30 hide-mobile"}>
                                                Temperature, {_get(units, "temp", "N/A")}
                                                {!!equipment.installationPoints.length &&
                                                <div className="action-icon-tbl">
                                                    {auth.userCan("editAlarms") &&
                                                        <button
                                                            className="btn btn-icon btn-sm btn-primary"
                                                            disabled={showAll}
                                                            onClick={() => handleSetValueModal({
                                                                show: true,
                                                                equipment: {
                                                                    id: equipment.id,
                                                                    name: equipment.name,
                                                                    asset_code: equipment.asset_code
                                                                },
                                                                installationPoint: {},
                                                                readingTypes: {
                                                                    name: "Temperature",
                                                                    list: ["1"],
                                                                    units: _get(units, "temp", "N/A")
                                                                }
                                                            })}
                                                        >
                                                            <i className="fa fa-pencil-alt"/>
                                                        </button>
                                                    }
                                                </div>
                                                }
                                            </td>
                                            <td className={(equipment.id === equipmentIdToggle) ? "pr-30" : "pr-30 hide-mobile"}>
                                                Acceleration, {_get(units, "acc", "N/A")}
                                                {!!equipment.installationPoints.length &&
                                                <div className="action-icon-tbl">
                                                    {auth.userCan("editAlarms") &&
                                                        <button
                                                            className="btn btn-icon btn-sm btn-primary"
                                                            disabled={showAll}
                                                            onClick={() => handleSetValueModal({
                                                                show: true,
                                                                equipment: {
                                                                    id: equipment.id,
                                                                    name: equipment.name,
                                                                    asset_code: equipment.asset_code
                                                                },
                                                                installationPoint: {},
                                                                readingTypes: {
                                                                    name: "Acceleration",
                                                                    list: ["3", "6", "22"],
                                                                    units: _get(units, "acc", "N/A")
                                                                }
                                                            })}
                                                        >
                                                            <i className="fa fa-pencil-alt"/>
                                                        </button>
                                                    }
                                                </div>
                                                }
                                            </td>
                                            <td className={(equipment.id === equipmentIdToggle) ? "pr-30" : "pr-30 hide-mobile"}>
                                                Velocity, {_get(units, "vel", "N/A")}
                                                {!!equipment.installationPoints.length &&
                                                <div className="action-icon-tbl">
                                                    {auth.userCan("editAlarms") &&
                                                        <button
                                                            className="btn btn-icon btn-sm btn-primary"
                                                            disabled={showAll}
                                                            onClick={() => handleSetValueModal({
                                                                show: true,
                                                                equipment: {
                                                                    id: equipment.id,
                                                                    name: equipment.name,
                                                                    asset_code: equipment.asset_code
                                                                },
                                                                installationPoint: {},
                                                                readingTypes: {
                                                                    name: "Velocity",
                                                                    list: ["4", "7", "23"],
                                                                    units: _get(units, "vel", "N/A")
                                                                }
                                                            })}
                                                        >
                                                            <i className="fa fa-pencil-alt"/>
                                                        </button>
                                                    }
                                                </div>
                                                }
                                            </td>
                                            <td className={(equipment.id === equipmentIdToggle) ? "pr-30" : "pr-30 hide-mobile"}>
                                                PK-PK, {_get(units, "pkpk", "N/A")}
                                                {!!equipment.installationPoints.length &&
                                                <div className="action-icon-tbl">
                                                    {auth.userCan("editAlarms") &&
                                                        <button
                                                            className="btn btn-icon btn-sm btn-primary"
                                                            disabled={showAll}
                                                            onClick={() => handleSetValueModal({
                                                                show: true,
                                                                equipment: {
                                                                    id: equipment.id,
                                                                    name: equipment.name,
                                                                    asset_code: equipment.asset_code
                                                                },
                                                                installationPoint: {},
                                                                readingTypes: {
                                                                    name: "PK-PK",
                                                                    list: ["16", "18", "27"],
                                                                    units: _get(units, "pkpk", "N/A")
                                                                }
                                                            })}
                                                        >
                                                            <i className="fa fa-pencil-alt"/>
                                                        </button>
                                                    }
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                        {equipment.installationPoints.length > 0 ?
                                            <React.Fragment>
                                                {equipment.installationPoints.map((installationPoint) =>
                                                    <TableRow
                                                        key={installationPoint.id}
                                                        installationPoint={installationPoint}
                                                        units={units}
                                                        search={search}
                                                        equipment={equipment}
                                                        handleSetValueModal={handleSetValueModal}
                                                        axisLabels={axisLabels}
                                                        auth={auth}
                                                    />
                                                )}
                                                {showAll === true &&
                                                    <tr>
                                                        <td colSpan={5} className="text-center">
                                                            <button className="link link-primary" onClick={() => this.props.showAllSearchResults(equipment)}><b>Show All</b></button>
                                                        </td>
                                                    </tr>
                                                }
                                            </React.Fragment> :
                                            <tr>
                                                <td colSpan={5} className="text-center">Installation points not set for this Equipment
                                                </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                );
                            }
                            ) :
                            <React.Fragment>
                                {loader === false &&
                                    <tr>
                                        <td colSpan={5} className="text-center">Default Alert Levels Not Found</td>
                                    </tr>
                                }
                            </React.Fragment>
                        }
                    </InfiniteScroll>
                </table>
            </div>
        );
    }
}

DefaultLevelsTable.propTypes = {
    list: PropTypes.array,
    search: PropTypes.string,
    units: PropTypes.object,
    location: PropTypes.object,
    loader: PropTypes.bool,
    handleSetValueModal: PropTypes.func,
    showAllSearchResults: PropTypes.func,
    onNextPage: PropTypes.func,
    hasMore: PropTypes.bool,
    axisLabels: PropTypes.object,
    showDesktop: PropTypes.bool,
    auth: PropTypes.object
};

const TableRow = ({installationPoint, units, search, equipment, handleSetValueModal, axisLabels, auth}) => {
    const [showMobile, setShowMobile] = useState(false);

    const toggleInstPoint = () => {
        setShowMobile(!showMobile);
    };

    return (
        <tr className={(installationPoint.foundBySearch === false && equipment.showAll === false && search.trim().length > 1) ? "d-none" : "adaptive-row-inst-point"}>
            <td className="inst-point-title">
                <Link
                    to={`/chart/${equipment.id}#sensors=${installationPoint.id}`}
                    className="color-primary"
                >
                    <span className="font-weight-bold">
                        <Highlighter
                            searchWords={[search.trim()]}
                            autoEscape={true}
                            textToHighlight={installationPoint.name}
                        />
                    </span>
                </Link>
                <div>
                    Sensor: &nbsp;
                    <span className="font-weight-bold">
                        <Highlighter
                            searchWords={[search.trim()]}
                            autoEscape={true}
                            textToHighlight={_get(installationPoint, "sensor.hex", _get(installationPoint, "sensor.id", "not set")) || "not set"}
                        />
                    </span>
                </div>
                <Button onClick={() => toggleInstPoint()} className="btn-toogle-desktop" style={{padding: "8px 8px", position: "absolute", top: "3px", right: "10px"}} size="xs"><img src="/assets/pic/icon-dropdown.svg" alt="" /></Button>
            </td>
            <td className={showMobile ? "pr-30" : "pr-30 hide-mobile"}>
                <div className="show-mobile">
                    Temperature, {_get(units, "temp", "N/A")}
                </div>
                <AlertLevelSettingBlock
                    data={_get(installationPoint, "existingAlerts.1", {})}
                    units={_get(units, "temp", "N/A")}
                />
                <div className="action-icon-tbl">
                    {auth.userCan("editAlarms") &&
                        <button
                            className="btn btn-icon btn-sm btn-primary"
                            onClick={() => handleSetValueModal({
                                show: true,
                                equipment: {
                                    id: equipment.id,
                                    name: equipment.name,
                                    asset_code: equipment.asset_code
                                },
                                installationPoint: installationPoint,
                                readingTypes: {
                                    name: "Temperature",
                                    list: ["1"],
                                    units: _get(units, "temp", "N/A")
                                }
                            })}
                        >
                            <i className="fa fa-pencil-alt"/>
                        </button>
                    }
                </div>
            </td>
            <td className={showMobile ? "pr-30" : "pr-30 hide-mobile"}>
                <div className="show-mobile">
                    Acceleration, {_get(units, "acc", "N/A")}
                </div>
                <AlertLevelSettingBlock
                    data={_get(installationPoint, "existingAlerts.3", {})}
                    units={_get(units, "acc", "N/A")}
                    label={_get(axisLabels, [1, installationPoint.id]) || "X-axis"}
                />
                <AlertLevelSettingBlock
                    data={_get(installationPoint, "existingAlerts.6", {})}
                    units={_get(units, "acc", "N/A")}
                    label={_get(axisLabels, [2, installationPoint.id]) || "Y-axis"}
                />
                <AlertLevelSettingBlock
                    data={_get(installationPoint, "existingAlerts.22", {})}
                    units={_get(units, "acc", "N/A")}
                    label={_get(axisLabels, [3, installationPoint.id]) || "Z-axis"}
                />
                <div className="action-icon-tbl">
                    {auth.userCan("editAlarms") &&
                        <button
                            className="btn btn-icon btn-sm btn-primary"
                            onClick={() => handleSetValueModal({
                                show: true,
                                equipment: {
                                    id: equipment.id,
                                    name: equipment.name,
                                    asset_code: equipment.asset_code
                                },
                                installationPoint: installationPoint,
                                readingTypes: {
                                    name: "Acceleration",
                                    list: ["3", "6", "22"],
                                    units: _get(units, "acc", "N/A")
                                }
                            })}
                        >
                            <i className="fa fa-pencil-alt"/>
                        </button>
                    }
                </div>
            </td>
            <td className={showMobile ? "pr-30" : "pr-30 hide-mobile"}>
                <div className="show-mobile">
                    Velocity, {_get(units, "vel", "N/A")}
                </div>
                <AlertLevelSettingBlock
                    data={_get(installationPoint, "existingAlerts.4", {})}
                    units={_get(units, "vel", "N/A")}
                    label={_get(axisLabels, [1, installationPoint.id]) || "X-axis"}
                />
                <AlertLevelSettingBlock
                    data={_get(installationPoint, "existingAlerts.7", {})}
                    units={_get(units, "vel", "N/A")}
                    label={_get(axisLabels, [2, installationPoint.id]) || "Y-axis"}
                />
                <AlertLevelSettingBlock
                    data={_get(installationPoint, "existingAlerts.23", {})}
                    units={_get(units, "vel", "N/A")}
                    label={_get(axisLabels, [3, installationPoint.id]) || "Z-axis"}
                />
                <div className="action-icon-tbl">
                    {auth.userCan("editAlarms") &&
                        <button
                            className="btn btn-icon btn-sm btn-primary"
                            onClick={() => handleSetValueModal({
                                show: true,
                                equipment: {
                                    id: equipment.id,
                                    name: equipment.name,
                                    asset_code: equipment.asset_code
                                },
                                installationPoint: installationPoint,
                                readingTypes: {
                                    name: "Velocity",
                                    list: ["4", "7", "23"],
                                    units: _get(units, "vel", "N/A")
                                }
                            })}
                        >
                            <i className="fa fa-pencil-alt"/>
                        </button>
                    }
                </div>
            </td>
            <td className={showMobile ? "pr-30" : "pr-30 hide-mobile"}>
                <div className="show-mobile">
                    PK-PK, {_get(units, "pkpk", "N/A")}
                </div>
                <AlertLevelSettingBlock
                    data={_get(installationPoint, "existingAlerts.16", {})}
                    units={_get(units, "pkpk", "N/A")}
                    label={_get(axisLabels, [1, installationPoint.id]) || "X-axis"}
                />
                <AlertLevelSettingBlock
                    data={_get(installationPoint, "existingAlerts.18", {})}
                    units={_get(units, "pkpk", "N/A")}
                    label={_get(axisLabels, [2, installationPoint.id]) || "Y-axis"}
                />
                <AlertLevelSettingBlock
                    data={_get(installationPoint, "existingAlerts.27", {})}
                    units={_get(units, "pkpk", "N/A")}

                    label={_get(axisLabels, [3, installationPoint.id]) || "Z-axis"}
                />
                <div className="action-icon-tbl">
                    {auth.userCan("editAlarms") &&
                        <button
                            className="btn btn-icon btn-sm btn-primary"
                            onClick={() => handleSetValueModal({
                                show: true,
                                equipment: {
                                    id: equipment.id,
                                    name: equipment.name,
                                    asset_code: equipment.asset_code
                                },
                                installationPoint: installationPoint,
                                readingTypes: {
                                    name: "PK-PK",
                                    list: ["16", "18", "27"],
                                    units: _get(units, "pkpk", "N/A")
                                }
                            })}
                        >
                            <i className="fa fa-pencil-alt"/>
                        </button>
                    }
                </div>
            </td>
        </tr>
    );
};

TableRow.propTypes = {
    installationPoint: PropTypes.object,
    equipment: PropTypes.object,
    search: PropTypes.string,
    units: PropTypes.object,
    handleSetValueModal: PropTypes.func,
    axisLabels: PropTypes.object,
    showMobile: PropTypes.bool,
    auth: PropTypes.object,
};

const AlertLevelSettingBlock = ({data, units, label}) => {
    const caution = parseFloat(_get(data, "caution"));
    const cautionStr = isNaN(caution) ? "not set" : caution;
    const cautionColor = isNaN(caution) ? " badge-warning" : " badge-secondary";


    const warning = parseFloat(_get(data, "warning"));
    const warningStr = isNaN(warning) ? "not set" : warning;
    const warningColor = isNaN(warning) ? " badge-danger" : " badge-secondary";
    return (
        <div className="row">
            {label && <div className="col-auto pr-0 text-nowrap axis-label-text">{label}</div>}
            <div className={label ? "col-12 col-xxl-5 pr-0 text-nowrap" : "col-12 col-xxl-6 pr-0 text-nowrap col-temp"}>
                <span className={"badge round-badge mb-1 mr-1" + cautionColor}>Caution</span>
                {cautionStr !== "not set" ?
                    <span className="font-weight-bold">{cautionStr} <span className="units-sub">{units}</span></span> :
                    <span className="font-weight-bold">not set</span>
                }
            </div>
            <div className={label ? "col-12 col-xxl-5 pr-0 text-nowrap" : "col-12 col-xxl-6 pr-0 text-nowrap col-temp"}>
                <span className={"badge round-badge mb-1 mr-1" + warningColor}>Warning</span>
                {warningStr !== "not set" ?
                    <span className="font-weight-bold">{warningStr} <span className="units-sub">{units}</span></span> :
                    <span className="font-weight-bold">not set</span>
                }
            </div>
        </div>
    );
};

AlertLevelSettingBlock.propTypes = {
    data: PropTypes.object,
    units: PropTypes.string,
    label: PropTypes.string,
};