import React from "react";
import { useChartStateStoreContext } from "../../store/hooks/useStoreContext";

function BulkReadingsButton() {
    const {toggleBulkReadingsMode} = useChartStateStoreContext((state) => state.actions);
    const {bulkDeleteReadingsMode} = useChartStateStoreContext((state) => state.settings);

    if (bulkDeleteReadingsMode === false) {
        return <></>;
    }

    return (
        <button
            className="highcharts-reset-zoom-custom"
            onClick={toggleBulkReadingsMode}
        >
            Cancel Bulk Delete Readings
        </button>
    );
}

export default BulkReadingsButton;
