import React, {Component} from "react";
import PropTypes from "prop-types";
import AlertsTable from "./alerts/table";
import {
    get as _get,
} from "lodash";
import AlertsModal from "./alerts/modal";

class AlertsStep extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            modalReadingTypeIndex: null,
            modalPointIndex: null
        };
    }

    showModal = (readingTypeIndex, pointIndex) => {
        this.setState({
            modalReadingTypeIndex: readingTypeIndex,
            modalPointIndex: pointIndex
        });
    }

    closeModal = () => {
        this.setState({
            modalReadingTypeIndex: null,
            modalPointIndex: null
        });
    }

    render() {
        const {modalReadingTypeIndex, modalPointIndex} = this.state;
        const {profile, alertLevels, readingTypes, onChange, conditions} = this.props;
        const installationPoints = _get(profile, "installationPoints", []);

        return (
            <React.Fragment>
                <div className={"alerts-step"}>
                    <div className={"step-header"}>
                        <div className={"step-title"}>Configure Alert Levels</div>
                        <div>
                            <span className={"mr-2"}><strong>X, Y, Z</strong> - axis</span>
                            {alertLevels.map((level, index) =>
                                <span
                                    key={index}
                                    className="alert-level"
                                    style={{background: _get(level, "color")}}
                                >
                                    {_get(level, "name")}
                                </span>
                            )}
                        </div>
                    </div>

                    <AlertsTable
                        installationPoints={installationPoints}
                        alertLevels={alertLevels}
                        readingTypes={readingTypes}
                        showModal={this.showModal}
                        profile={profile}
                    />
                </div>
                {!!(modalReadingTypeIndex !== null && modalPointIndex !== null) &&
                    <AlertsModal
                        profile={profile}
                        installationPointIndex={modalPointIndex}
                        readingType={_get(readingTypes, modalReadingTypeIndex)}
                        alertLevels={alertLevels}
                        onClose={this.closeModal}
                        onChange={onChange}
                        conditions={conditions}
                    />
                }
            </React.Fragment>
        );
    }
}


AlertsStep.propTypes = {
    profile: PropTypes.object,
    readingTypes: PropTypes.array,
    alertLevels: PropTypes.array,
    onChange: PropTypes.func,
    conditions: PropTypes.object
};

export default AlertsStep;