import Cacher from "../../../../../../helpers/cacher";
import { getRpmPlotLines } from "../../helpers/plotLines";
import { useMemo } from "react";
import { useChartStateStoreContext } from "../../store/hooks/useStoreContext";
import { useSpeedSettings } from "../helpers/useSpeedSettings";
import { useChartRef } from "../useChartRef";
import { useIsCircular } from "../../../hooks/useChartViewTypes";

export const useRpmBuilder = () => {
    const showRpmPlotLines = useChartStateStoreContext((state) => state.settings.showRpmPlotLines);
    const chartViewType = useChartStateStoreContext((state) => state.settings.chartViewType);
    const {currentSpeed} = useSpeedSettings();
    const chartRef = useChartRef();
    const isCircular = useIsCircular(chartViewType);

    return useMemo(() => {
        if (!showRpmPlotLines || isCircular) {
            return [];
        }
        const xMaxCacher = new Cacher(() =>
            chartRef.current.chart.series.reduce((result, { xData }) => {
                if (xData && xData.length) {
                    const xDataMax = xData.at(-1);
                    return Math.max(xDataMax, result);
                }
                return result;
            }, 0)
        );

        return getRpmPlotLines(xMaxCacher, showRpmPlotLines, currentSpeed);
    }, [showRpmPlotLines, currentSpeed, isCircular]);
};
