import React, { useState } from "react";
import {Modal} from "../../shared";
import FormValidator from "../../helpers/form-validator";
import {ValidationError} from "../../shared";
import PropTypes from "prop-types";
import {get as _get} from "lodash";
import DatePickerInput from "../../shared/datePickerInput/datePickerInput";
import TimePicker from "../../shared/timePickerInput/timePickerInput";
import moment from "moment";
import Helper from "../../helpers/helper";
import {withGlobalStore} from "../../stores/GlobalStore";
import { useParams } from "react-router";
import { useAlarmListInvalidate } from "../../hooks/api/Alarm/UseAlarmListInvalidate";

const rules = [
    {
        field: "date",
        method: "isEmpty",
        validWhen: false,
        message: "Date cannot be empty."
    },
    {
        field: "time",
        method: "isEmpty",
        validWhen: false,
        message: "Time cannot be empty."
    }
];

const validator = new FormValidator(rules);

const IgnoreModal = (props) => {
    const formats = Helper.getUserDateFormat(props.user, true);
    const params = useParams();
    const invalidateAlarmList = useAlarmListInvalidate(params.equipmentId);

    const [data, setData] = useState({
        notes: "",
        date: moment().format(formats.date),
        time: moment().format(formats.time)
    });

    const [inProgress, setInProgress] = useState(false);

    const [validation, setValidation] = useState(validator.valid());

    const onChange = (e) => {
        setData(Object.assign({}, data, {[_get(e, "target.name", "")]: _get(e, "target.value", "")}));
        setValidation(validator.validate(data, _get(e, "target.name", "")));
    };

    const onChangeCustom = (obj = {}) => {
        setData(Object.assign({}, data, obj));
    };

    const datetime = moment([data.date, data.time].join(" "), `${formats.date} ${formats.time}`)
        .format("YYYY-MM-DD HH:mm:ss");

    const handleSubmitModal = () => {
        setInProgress(true);
        const validation = validator.validate(data);
        setValidation(validation);

        if (validation.isValid) {
            props.onSubmit(Object.assign({}, data, {datetime: datetime})).then(() => {
                invalidateAlarmList();
                setInProgress(false);
            });
        } else {
            setInProgress(false);
        }
    };

    const {auth} = props;

    if (!auth.userCan("editAlarms")) {
        return "";
    }

    return (
        <Modal
            {...props}
            onSubmit={handleSubmitModal}
            inProgress={inProgress}
        >
            <div className="form-group row" id="ignore-modal-portal">
                <div className="col-12">
                    <label className="text-right form-label">Notes:</label>
                    <textarea
                        className={"form-control"}
                        name="notes"
                        value={data.notes}
                        onChange={onChange}
                        disabled={inProgress}
                    />
                </div>
                <div className="col-6">
                    <label className="text-right form-label">Ignore subsequent alerts until:</label>
                    <div className="input-group date">
                        <DatePickerInput
                            className={"form-control" + (validation.date.isValid || !validation.date.message ? "" : " is-invalid")}
                            type="text"
                            name="date"
                            portalPosition="#ignore-modal-portal"
                            value={data.date}
                            disabled={inProgress}
                            onChangeHandler={date => onChangeCustom({date})}
                            displayFormat={Helper.getUserDateFormat(props.user, true).date}
                            valueFormat={Helper.getUserDateFormat(props.user, true).date}
                            ignoreModal={true}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <i className="la la-calendar"/>
                            </span>
                        </div>
                    </div>
                    <ValidationError message={validation.date.message}/>
                </div>
                <div className="col-6">
                    <label className="text-right form-label">&nbsp;</label>
                    <div className="input-group timepicker">
                        <TimePicker
                            className={"form-control" + (validation.time.isValid || !validation.time.message ? "" : " is-invalid")}
                            type="text"
                            name="time"
                            defaultValue={data.time}
                            disabled={inProgress}
                            onChange={onChange}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <i className="la la-clock-o"/>
                            </span>
                        </div>
                    </div>
                    <ValidationError message={validation.time.message}/>
                </div>
            </div>
        </Modal>
    );
};

IgnoreModal.propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    showModal: PropTypes.bool,
    customStyles: PropTypes.object,
    children: PropTypes.any,
    title: PropTypes.string,
    submitTitle: PropTypes.string,
    cancelTitle: PropTypes.string,
    auth: PropTypes.object,
    user: PropTypes.object
};

export default withGlobalStore(IgnoreModal);