import {useQuery} from "@tanstack/react-query";
import ApiLocation from "../../../api/location";
import {ASSETS_API} from "./constants";
import Helper from "../../../helpers/helper";

export const useAssetListQuery = ({onlyWithEquipment, isFlat = true} = {}) => {
    return useQuery({
        queryFn: () => ApiLocation.list({onlyWithEquipment}),
        queryKey: [ASSETS_API.GET_ASSETS_LIST, {onlyWithEquipment}],
        select: (res) => {
            if (!isFlat) {
                return res.list;
            }

            return Helper.transformLocations(res.list).map((location) => {
                return {
                    label: location.full_name,
                    value: +location.id,
                    parent_id: location.parent_id,
                };
            });
        },
    });
};
