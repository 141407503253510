import React, {Component} from "react";
import PropTypes from "prop-types";
import ParametersTable from "./table";
import {Modal} from "../../../shared";

class ParametersModal extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            createNew: false,
        };
    }

    onCreateNew = () => {
        this.setState({createNew: true});
    }

    onCancel = () => {
        this.setState({createNew: false});
    }

    getModalHeader = () => {
        const {createNew} = this.state;

        return createNew ? <React.Fragment/> : <ModalHeader onCreateNew={this.onCreateNew}/>;
    }
    render() {
        const {createNew} = this.state;
        const {showModal, parameters, updateParameterList, updateList, onClose} = this.props;

        return (
            <React.Fragment>
                <Modal
                    showModal={showModal}
                    className={"nameplate-parameters-modal"}
                    title={"Manage Parameters"}
                    header={this.getModalHeader()}
                    size={"lg"}
                    onClose={onClose}
                    withoutFooter={true}
                >
                    <ParametersTable
                        parameters={parameters}
                        updateParameterList={updateParameterList}
                        updateList={updateList}
                        createNew={createNew}
                        onCancelCreateNew={this.onCancel}
                    />
                </Modal>
            </React.Fragment>
        );
    }
}

const ModalHeader = ({onCreateNew}) => (
    <React.Fragment>
        <div className="add-new-link m-0 ml-2" onClick={onCreateNew}>
            <i className="fa fa-plus-circle"/> Add new parameter
        </div>
    </React.Fragment>
);

ModalHeader.propTypes = {
    onCreateNew: PropTypes.func,
};

ParametersModal.propTypes = {
    showModal: PropTypes.bool,
    parameters: PropTypes.array,
    updateParameterList: PropTypes.func,
    updateList: PropTypes.func,
    onClose: PropTypes.func
};

export default ParametersModal;