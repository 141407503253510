import {useQuery} from "@tanstack/react-query";
import api from "../../../api/api";

const getWuaReadingTypes = ({readingTypeId}) => api.get(`reading-types/${readingTypeId}/wua-overlay`);

const getWuaReadingTypesKeys = ({readingTypeId}) => ["WuaReadingTypes", {readingTypeId}];

export const useWuaReadingTypesQuery = ({readingTypeId, enabled = true}) => {
    return useQuery({
        queryFn: () => getWuaReadingTypes({readingTypeId}),
        queryKey: getWuaReadingTypesKeys({readingTypeId}),
        enabled,
    });
};
