import React from "react";
import AlertSensorSelect from "../../shared/alertSensorSelect";
import PropTypes from "prop-types";
import {useChartType} from "../../hooks/helpers/chart";
import AlertBody from "../../shared/alertBody";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import useAxisLabelQuery from "../../../../hooks/api/axisLabel/useAxisLabelQuery";

const LinearTab = ({chartTypes}) => {
    const { equipmentItem: equipment } = useEquipmentByParams();
    const { axisLabelsChart: axisLabels } = useAxisLabelQuery(equipment.id);
    const {chartType} = useChartType(chartTypes);

    return (
        <>
            <AlertSensorSelect
                chartType={chartType}
                equipment={equipment}
            />
            <AlertBody
                chartType={chartType}
                equipment={equipment}
                axisLabels={axisLabels}
            />
        </>
    );
};
LinearTab.propTypes = {
    chartTypes: PropTypes.array,
    equipment: PropTypes.object,
    axisLabels: PropTypes.object,
};
export default LinearTab;
