import Api from "./api";

const Node = {
    list: (params) => {
        return Api.get("nodes", {...params});
    },
    getList: (params) => {
        return Api.get("node-list", {...params});
    },
    view: (serial) => {
        return Api.get(`nodes/${serial}`);
    },
    check: (serial) => {
        return Api.get(`nodes/check/${serial}`);
    },
    getByInstallationPoint: (id) => {
        return Api.get(`nodes/installation-point/${id}`);
    },
    updateSettings: (settings) => {
        return Api.post("nodes/update-settings", settings);
    },
    update: (serial, body) => {
        return Api.post(`nodes/update/${serial}`, body);
    },
    associate: (body) => {
        return Api.post("nodes/associate", body);
    },
    delete: (serial) => {
        return Api.delete(`nodes/${serial}`);
    },
    attachImages: (serial, body) => {
        return Api.post(`nodes/attach-images/${serial}`, body);
    },
    detachImage: (serial, body) => {
        return Api.post(`nodes/detach-image/${serial}`, body);
    },
    defaultImage: () => {
        return Api.get("nodes/default-image");
    },
    detachAllImages: (serial, body) => {
        return Api.post(`nodes/detach-images/${serial}`, body);
    },
    onFlipImage: (body) => {
        return Api.post("nodes/flip-image", body);
    },
    export: (params) => {
        return Api.source("nodes/export", {...params});
    },
    packetLoss: (serial, params) => Api.get(`nodes/${serial}/packet-loss`, {query: {...params}}),
    batteryVoltage: (serial, params) => Api.get(`nodes/${serial}/battery-voltage`, {query: {...params}}),
};

export default Node;
