import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Helper from "../../../helpers/helper";
import {get as _get} from "lodash";
import {ResetSortButton} from "../../shared/resetSortButton";
import {DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {FirmwareVersion} from "../../../shared/FirmwareVersion/FirmwareVersion";

const headersList = {
    installation_point_name: {title: "Installation Point", sort: true},
    /*"sensor_hex": {title: "Sensor ID", sort: true},*/
    equipment_name: {title: "Equipment", sort: true},
    location: {title: "Location", sort: true},
    node: {title: "Node", sort: false},
    "gateway.name": {title: "Gateway", sort: true},
    version_type: {title: "Type", sort: true},
    version: {title: "Version", sort: true},
    tempLastSeen: {title: "Last Temp", sort: true},
    rmsLastSeen: {title: "Last RMS", sort: true},
    fftLastSeen: {title: "Last FFT", sort: true},
    last_seen: {title: "Last Seen", sort: true},
    actions: {title: "Actions", sort: false, additionalClasses: "table-buttons-th", component: ResetSortButton},
};

class Table extends Component {
    render() {
        const {list, sort, onSortChange, query} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <table className="table table-hover tl-fixed">
                <thead>
                    <tr>
                        {Object.keys(headersList).map((key) => {
                            let component = "";
                            const RowComponent = _get(headersList, [key, "component"], false);
                            if (RowComponent) {
                                component = (
                                    <RowComponent
                                        sort={sort}
                                        resetSort={() => onSortChange("")}
                                    />
                                );
                            }
                            return headersList[key].sort ? (
                                <th
                                    className={headersList[key].additionalClasses || ""}
                                    key={key}
                                    style={headersList[key].style || {}}
                                    onClick={() => onSortChange(key)}
                                >
                                    <div className="title">
                                        <span>{headersList[key].title}</span>
                                        {sort.field === key ? (
                                            <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")} />
                                        ) : (
                                            <i className={"fa fa-sort"} />
                                        )}
                                    </div>
                                </th>
                            ) : (
                                <th
                                    className={headersList[key].additionalClasses || ""}
                                    key={key}
                                    style={headersList[key].style || {}}
                                >
                                    {headersList[key].title} {component}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {list.length > 0 ? (
                        list.map((sensor) => (
                            <SensorRow
                                key={sensor.sensor_id}
                                sensor={sensor}
                                props={this.props}
                            />
                        ))
                    ) : (
                        <tr>
                            <td
                                colSpan={listLen}
                                className="text-center text-info"
                            >
                                {query !== "" ? "No items match your search." : "No tethered sensors were found."}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}

Table.propTypes = {
    list: PropTypes.array,
    sort: PropTypes.object,
    query: PropTypes.string,
    onSortChange: PropTypes.func,
};

class SensorRow extends Component {
    getInstPointLink(forTable = true) {
        const {sensor, props} = this.props;

        let sensorHex = sensor.sensor_id;
        if (parseInt(sensor.hardware_id)) {
            sensorHex = parseInt(sensor.hardware_id).toString(16).toUpperCase();
        }

        const instPointName = sensor.installation_point_name + " (" + sensorHex + ")";
        const text = forTable ? Helper.highlight(instPointName, props.query) : instPointName;

        return sensor.installationPoint && sensor.installationPoint.equipment ? (
            <Link
                to={`/chart/${sensor.installationPoint.equipment.id}#sensors=${sensor.installationPoint.id}`}
                className="link link-primary"
                title="View Installation Point"
                dangerouslySetInnerHTML={{__html: text}}
                style={{margin: 0}}
            />
        ) : (
            <i className="ml-2">Not set ({sensorHex})</i>
        );
    }

    getNodeLink(forTable = true) {
        const {sensor, props} = this.props;

        const text = forTable ? Helper.highlight(sensor.node.serial, props.query) : sensor.node.serial;

        return sensor.node && sensor.node.serial ? (
            <Link
                to={`/network/nodes/${sensor.node.serial}`}
                className="link link-primary"
                title="View Node"
                style={{margin: 0}}
                dangerouslySetInnerHTML={{__html: text}}
            />
        ) : (
            <i className="ml-2">Not set</i>
        );
    }

    getGatewayLink(forTable = true) {
        const {sensor, props} = this.props;
        const gateway = (sensor.node || {}).gateway || {};
        const gatewayName = gateway ? (gateway.name ? gateway.name + " (" + gateway.serial + ")" : gateway.serial) : "---";

        const text = forTable ? Helper.highlight(gatewayName, props.query) : gateway.serial;

        return gateway && gateway.serial ? (
            <Link
                to={`/network/gateways/${gateway.serial}`}
                className="link link-primary"
                title="View Gateway"
                dangerouslySetInnerHTML={{__html: text}}
                style={{margin: 0}}
            />
        ) : (
            <i className="ml-2">Not set</i>
        );
    }

    render() {
        const {sensor, props} = this.props;

        const tempLastSeen = _get(sensor, "tempLastSeen", null);
        const rmsLastSeen = _get(sensor, "rmsLastSeen", null);
        const fftLastSeen = _get(sensor, "fftLastSeen", null);
        const sensorLocation = _get(sensor, "installationPoint.equipment.location.name", "---");
        const version = sensor?.version;

        return (
            <tr>
                <td>{this.getInstPointLink()}</td>
                {/*<td dangerouslySetInnerHTML={{__html: Helper.highlight(sensorHex, props.query)}}/>*/}
                <td dangerouslySetInnerHTML={{__html: Helper.highlight(sensor.equipment_name, props.query)}} />
                <td>{sensorLocation}</td>
                <td>{this.getNodeLink()}</td>
                <td>{this.getGatewayLink()}</td>
                <td>{_get(sensor, "version_type", "N/A")}</td>
                <td>
                    <FirmwareVersion
                        version={version}
                        name={sensor?.sensor_hex}
                        isFirmwareExists={!!sensor?.firmwareExist}
                        description={sensor?.firmwareDescription}
                        shortDescription={sensor?.firmwareShortDescription}
                        type="Tethered Sensor"
                    />
                </td>
                <td>{tempLastSeen}</td>
                <td>{rmsLastSeen}</td>
                <td>{fftLastSeen}</td>
                <td>{Helper.momentFromNow(sensor.last_seen) || "Never"}</td>
                <td>
                    <div className="btn-group btn-group-sm">
                        <UncontrolledDropdown className="d-inline-block">
                            <DropdownToggle
                                tag={"button"}
                                className={"link link-primary"}
                                style={{minWidth: "1.5rem", borderRadius: 0, display: "inline-block", lineHeight: "1.6rem", cursor: "pointer"}}
                            >
                                <i className="fa fa-eye" />
                                <span>Path</span>
                            </DropdownToggle>
                            <DropdownMenu
                                right={true}
                                className={"p-3"}
                            >
                                <div>Installation Point</div>
                                {this.getInstPointLink(false)}
                                <hr className="mt-2 mb-2" />
                                <div>Node</div>
                                {this.getNodeLink(false)}
                                <hr className="mt-2 mb-2" />
                                <div>Gateway</div>
                                {this.getGatewayLink(false)}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </td>
            </tr>
        );
    }
}

SensorRow.propTypes = {
    sensor: PropTypes.object,
    props: PropTypes.object,
    query: PropTypes.string,
    onViewSensor: PropTypes.func,
};

export default Table;
