import { useMemo } from "react";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";

export const useHeightBuilder = (showAlerts, alerts) => {
    const {setSoftMinMax} = useChartStateStoreContext((state) => state.actions);

    return useMemo(() => {
        const softMin =
            showAlerts && typeof alerts !== "undefined"
                ? alerts.reduce((result, label) => (+label.value < result ? +label.value : result), 0)
                : 0;
        const softMax =
            showAlerts && typeof alerts !== "undefined"
                ? alerts.reduce((result, label) => (+label.value > result ? +label.value : result), 0)
                : 0;

        setSoftMinMax(softMin, softMax);

        return { softMin, softMax };
    }, [showAlerts, alerts]);
};
