import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Datetime from "react-datetime";

import "react-datetime/css/react-datetime.css";

const propTypes = {
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

const TimePickerInput = ({
    disabled,
    name,
    defaultValue,
    placeholder,
    className,
    onChange
}) => {
    const [pickerValue, setPickerValue] = useState(moment());

    useEffect(() => {
        if (defaultValue && defaultValue !== "Invalid date") {
            const value = moment(moment().format("MM/DD/YYYY") + " " + defaultValue);
            if (defaultValue === value.format("HH:mm:ss")) {
                setPickerValue(value);
            }
        }
    }, [defaultValue]);

    return (
        <Datetime
            initialValue={pickerValue}
            dateFormat=""
            timeFormat="HH:mm:ss"
            inputProps={{
                name,
                className,
                placeholder,
                disabled
            }}
            initialViewMode="time"
            onChange={time => typeof time === "string" ? {} : onChange(time.format("HH:mm:ss"))}
        />
    );
};

TimePickerInput.propTypes = propTypes;

export default TimePickerInput;