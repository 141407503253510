import React, {Component, useEffect, useState} from "react";
import FacilityApi from "../../../api/facility";
import {Loader} from "../../../shared";
import SelectFormGroup, {CheckboxFormGroup, InputFormGroup} from "../../../shared/formParts/formParts";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import {
    get as _get,
    set as _set,
    each as _each,
    find as _find,
    orderBy as _orderBy
} from "lodash";
import SearchInput from "../../shared/search-input";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../stores/GlobalStore";
import WifiGroupsApi from "../../../api/wifiGroups";
import Helper from "../../../helpers/helper";
import {MOTES_LOT_DATA, readingIntervals} from "../../../constants/constants";
import Toast from "../../shared/toast";
import Mote from "../gateways/mote";
import Node from "../gateways/node";
import BatteryVoltageModal from "../../../modals/battery-voltage";
import SelectWrapper from "../../../helpers/select-wrapper";
import WifiGroupAddMote from "../../../modals/wifi-group-add-mote";
import ApiNodes from "../../../api/node";
import SweetAlert from "react-bootstrap-sweetalert";
import { HeaderSimple } from "../../../shared/header";
import NotAccess from "../../not-access";

const formFields = [
    "facility_id",
    "name",
    "wap_name",
    "wap_name_confirm",
    "wap_password",
    "wap_password_confirm",
    "total_window_size",
    "rms_interval",
    "fft_interval",
    "is_rms_enabled",
    "is_fft_enabled",
];

class WifiGroupForm extends Component
{
    constructor(props) {
        super(props);

        this.wifiGroupId = this.props.match.params.wifiGroupId;

        this.state = {
            title: this.wifiGroupId ? "Edit Wifi Mote Group" : "Add Wifi Mote Group",
            data: {},
            breadcrumbs: [
                {name: "Manage Wifi Mote Groups", link: "/network/wifi-groups"},
                {name: this.wifiGroupId ? "Edit Wifi Mote Group" : "Add Wifi Mote Group"}
            ],
            loader: true,
            formErrors: {},
            globalSearchString: "",
            inProgress: false,
            facilities: [],
            oldFacilityId: null,
            nodes: [],
            addMoteModal: false,
            confirm: false,
            deleted: false
        };
    }

    getWifiGroupObject(data = {}) {
        let wifiGroup = {};

        _each(formFields, (field) => {
            wifiGroup[field] = _get(data, field, null);
            if (field === "rms_interval" && !wifiGroup[field]) {
                wifiGroup[field] = 3600;
            }
            if (field === "fft_interval" && !wifiGroup[field]) {
                wifiGroup[field] = 86400;
            }
            if (field === "wap_name_confirm") {
                wifiGroup[field] = _get(data, "wap_name");
            }
            if (field === "wap_password_confirm") {
                wifiGroup[field] = _get(data, "wap_password");
            }
            if (field === "total_window_size") {
                wifiGroup[field] = 900;
            }
        });

        return wifiGroup;
    }

    componentDidMount() {
        Promise.all([
            this.fetchFacilities(),
            this.fetchWifiGroup()
        ]).then(() => this.setState({loader: false}));
    }

    fetchFacilities = () => {
        return FacilityApi
            .list()
            .then(response => {
                this.setState({facilities: _get(response, "list", [])});
            });
    }

    fetchWifiGroup = () => {
        if (!this.wifiGroupId) {
            this.setState({data: this.getWifiGroupObject()});
            return false;
        } else {
            return WifiGroupsApi
                .getView(this.wifiGroupId)
                .then((response) => {
                    const wifiGroup = _get(response, "wifiGroup", {});
                    this.setState({
                        data: this.getWifiGroupObject(wifiGroup),
                        oldFacilityId: _get(wifiGroup, "facility_id"),
                        nodes: _get(response, "nodes", [])
                    });
                });
        }
    }

    handleChangeGlobalSearch (globalSearchString) {
        this.setState({globalSearchString: globalSearchString});
    }

    onChange = (event) => {
        const data = {...this.state.data};

        const key = _get(event, "target.name", "");
        const val = _get(event, "target.value", "");

        _set(data, key, val);

        this.setState({data});
    }

    handleSubmit = () => {
        return this.wifiGroupId ? this.onUpdate() : this.onCreate();
    }

    onCreate = () => {
        const {data} = this.state;

        this.setState({formErrors: {}, inProgress: true}, () => {
            WifiGroupsApi
                .create(data)
                .then(() => {
                    Toast.success("The wifi sensor mote group has been created.");
                    this.props.history.push("/network/wifi-groups");
                })
                .catch(response => {
                    this.setState({formErrors: response.errors || {}, inProgress: false});
                });
        });
    }

    onUpdate = () => {
        const {data} = this.state;

        this.setState({formErrors: {}, inProgress: true}, () => {
            WifiGroupsApi
                .update(this.wifiGroupId, data)
                .then(() => {
                    Toast.success("The wifi sensor mote group has been updated.");
                    this.setState({inProgress: false}, () => this.props.history.push(`/network/wifi-groups/edit/${this.wifiGroupId}`));
                })
                .catch(response => {
                    this.setState({formErrors: response.errors || {}, inProgress: false});
                });
        });
    }

    onShowBatteryVoltageModal(serial) {
        this.props.history.push(Helper.setHashParams({modal: "battery-voltage", serial}));
    }

    isMote = (item) => {
        return MOTES_LOT_DATA.includes(item.lot_data);
    };

    addMote = () => {
        this.setState({addMoteModal:true});
    }
    closeMote = () => {
        this.setState({addMoteModal:false});
    }
    successAddMote = (node) => {
        this.setState({ nodes: node, loader: false });
    }
    deleteMote = () => {
        this.setState({confirm: false, loader: true});
        const wifiGroupId = this.wifiGroupId;
        if(this.state.deleted) {
            ApiNodes.view(this.state.deleted).then((response) => {
                if (response.node) {
                    const node = _get(response, "node");
                    node.wifi_config_group_id = "";
                    ApiNodes.update(node.serial, node).then(() => {
                        WifiGroupsApi
                            .getView(wifiGroupId)
                            .then((response) => {
                                this.successAddMote(_get(response, "nodes", []));
                            });
                        Toast.success("The wifi sensor mote has been removed.");
                    });
                }
            });
        }
    }

    render() {
        const {
            data,
            breadcrumbs,
            globalSearchString,
            loader,
            inProgress,
            title,
            facilities,
            formErrors,
            nodes,
            confirm
        } = this.state;
        const {auth} = this.props;
        const searchParams = Helper.getHashParams();

        if (!auth.userCan("manageWiFiGroup")) {
            return <NotAccess/>;
        }

        return (
            <div className="">
                 <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">{title}</div>
                </div>
                {loader
                    ?
                    <div className="loader-fullscreen"><Loader/></div>
                    :
                    <div>
                        <div className="block">
                            <div className="block-body">
                                <div className={"row mb-2"}>
                                    <div className={"col-md-6"}>
                                        <InputFormGroup
                                            label={"Wifi Mote Group Name"}
                                            name={"name"}
                                            value={_get(data, "name")}
                                            errorMsg={_get(formErrors, "name")}
                                            disabled={inProgress}
                                            isRequired={true}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className={"col-md-6"}>
                                        <SelectFormGroup
                                            label={"Facility"}
                                            options={facilities}
                                            optionValue={"facility_id"}
                                            optionName={"name"}
                                            value={+_get(data, "facility_id")}
                                            valueName={"facility_id"}
                                            disabled={inProgress || (nodes || []).length}
                                            errorMsg={_get(formErrors, "facility_id")}
                                            isRequired={true}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className={"row mb-2"}>
                                    <div className={"col-md-6"}>
                                        <InputFormGroup
                                            label={"Wireless AP Name"}
                                            name={"wap_name"}
                                            value={_get(data, "wap_name")}
                                            errorMsg={_get(formErrors, "wap_name")}
                                            disabled={inProgress}
                                            isRequired={true}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className={"col-md-6"}>
                                        <InputFormGroup
                                            label={"Confirm Wireless AP Name"}
                                            name={"wap_name_confirm"}
                                            value={_get(data, "wap_name_confirm")}
                                            errorMsg={_get(formErrors, "wap_name_confirm")}
                                            disabled={inProgress}
                                            isRequired={true}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className={"row mb-2"}>
                                    <div className={"col-md-6"}>
                                        <InputFormGroup
                                            type={"password"}
                                            label={"Wireless AP Password"}
                                            name={"wap_password"}
                                            value={_get(data, "wap_password")}
                                            errorMsg={_get(formErrors, "wap_password")}
                                            disabled={inProgress}
                                            isRequired={true}
                                            onChange={this.onChange}
                                            groupId={this.wifiGroupId}
                                        />
                                    </div>
                                    <div className={"col-md-6"}>
                                        <InputFormGroup
                                            type={"password"}
                                            label={"Confirm Wireless AP Password"}
                                            name={"wap_password_confirm"}
                                            value={_get(data, "wap_password_confirm")}
                                            errorMsg={_get(formErrors, "wap_password_confirm")}
                                            disabled={inProgress}
                                            isRequired={true}
                                            onChange={this.onChange}
                                            groupId={this.wifiGroupId}
                                        />
                                    </div>
                                </div>
                                <div className={"row mb-2"}>
                                    <div className={"col-md-6"}>
                                        <IntervalInput
                                            value={_get(data, "total_window_size")}
                                            onChange={this.onChange}
                                            type={"temperature"}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className={"row mb-2"}>
                                    <div className={"col-md-6"}>
                                        <IntervalInput
                                            value={_get(data, "rms_interval")}
                                            onChange={this.onChange}
                                            type={"rms"}
                                        />
                                    </div>
                                    <div className={"col-md-6"}>
                                        <CheckboxFormGroup
                                            label={"RMS Enabled"}
                                            name={"is_rms_enabled"}
                                            value={_get(data, "is_rms_enabled")}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className={"row mb-2"}>
                                    <div className={"col-md-6"}>
                                        <IntervalInput
                                            value={_get(data, "fft_interval")}
                                            onChange={this.onChange}
                                            type={"fft"}
                                        />
                                    </div>
                                    <div className={"col-md-6"}>
                                        <CheckboxFormGroup
                                            label={"FFT Enabled"}
                                            name={"is_fft_enabled"}
                                            value={_get(data, "is_fft_enabled")}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.wifiGroupId && <div className="text-left">
                            <Button color="primary" size="sm" className="ml-2" disabled={inProgress} onClick={this.addMote}>Add Wifi Sensor Motes</Button>
                            {this.state.addMoteModal && <WifiGroupAddMote
                                showModal={this.state.addMoteModal}
                                addMote={this.successAddMote}
                                wifiGroupId={this.wifiGroupId}
                                wifiGroupName={_get(data, "name")}
                                onClose={this.closeMote}
                            ></WifiGroupAddMote>}
                        </div>}
                        <div className="text-right">
                            <Link to="/network/wifi-groups" className="btn btn-sm btn-secondary">Cancel</Link>
                            <Button color="primary" size="sm" className="ml-2" disabled={inProgress} onClick={this.handleSubmit}>Save</Button>
                        </div>
                        <div className={"row"}>
                            {nodes && nodes.length > 0
                                ? nodes.map((node) => {
                                    if (this.isMote(node)) {
                                        return (
                                            <div key={node.serial} className="col-xl-6">
                                                <Mote mote={node} onShowBatteryVoltageModal={this.onShowBatteryVoltageModal}/>
                                                <span
                                                    style={{"position": "absolute", "top": "70px", "right": "15px", "cursor":"pointer"}}
                                                    data-serial={node.serial}
                                                    onClick={() => {
                                                        this.setState({
                                                            deleted: node.serial,
                                                            confirm: true
                                                        });
                                                    }}
                                                >
                                                    <i className="la la-close"/>
                                                </span>
                                                {confirm && <SweetAlert
                                                    warning
                                                    show={confirm}
                                                    title="DELETE WIFI SENSOR MOTE"
                                                    showCancel
                                                    confirmBtnText="Yes"
                                                    cancelBtnBsStyle="default"
                                                    btnSize="xs"
                                                    onCancel={() => {
                                                        this.setState({confirm: false});
                                                    }}
                                                    onConfirm={this.deleteMote}
                                                >
                                                    <React.Fragment>Are you sure you want to delete this wifi sensor sensor mote from the wifi mote group?</React.Fragment>
                                                </SweetAlert>}
                                            </div>
                                        );
                                    }
                                    return (
                                        <div key={node.serial} className="col-xl-6">
                                            <Node node={node} onShowBatteryVoltageModal={this.onShowBatteryVoltageModal}/>
                                        </div>
                                    );
                                })
                                : (this.wifiGroupId && <div className={"col-md-12 mt-4"}>
                                    <div className="alert alert-info" role="alert">
                                        <div className="alert-icon"><i className="fas fa-exclamation-circle"/></div>
                                        <div className="alert-text">No wifi sensor motes are associated with this wifi mote group.</div>
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                }
                {searchParams.modal === "battery-voltage" && !!searchParams.serial &&
                    <BatteryVoltageModal node={_find(nodes, {serial: searchParams.serial})}/>
                }
            </div>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item ml-2">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

const IntervalInput = ({value, type, onChange, disabled}) => {

    const name = type + "_interval";

    const [convertedValue, setConvertedValue] = useState(false);
    const [valueType, setValueType] = useState(false);

    useEffect(() => {
        const orderedIntervals = _orderBy(readingIntervals, ["id"], ["desc"]);
        _each(orderedIntervals, (interval) => {
            const seconds = interval.seconds;

            if (parseInt(+value / +seconds) > 0) {
                setConvertedValue(parseInt(+value / +seconds));
                setValueType(interval.id);

                return false;
            }
        });
    }, []);

    const onChangeConvertedValue = (e) => {
        const value = _get(e, "target.value");
        const interval = readingIntervals[valueType - 1];
        onChange({
            target: {
                name,
                value: value * interval.seconds
            }
        });
        setConvertedValue(value);
    };

    const onChangeTypeSelect = (e) => {
        const value = _get(e, "target.value");
        const interval = readingIntervals[value - 1];
        onChange({
            target: {
                name,
                value: convertedValue * interval.seconds
            }
        });
        setValueType(value);
    };

    return (
        <div className="form-group">
            <label className="text-right form-label">
                {type === "temperature" ? "Temperature" : type.toUpperCase()} Interval:
            </label>
            <div className="row align-items-center mb-3">
                <div className="col-5 pr-1">
                    <input
                        type="number"
                        name={name}
                        autoComplete="off"
                        onChange={onChangeConvertedValue}
                        className={"form-control" + (value === "" || +value === 0 ? " is-invalid" : "")}
                        value={convertedValue}
                        disabled={disabled}
                    />
                    {value === "" &&
                        <span className="invalid-feedback d-block color-danger mt-1 text-right small-error-msg">This field is required.</span>
                    }
                    {+value === 0 &&
                        <span className="invalid-feedback d-block color-danger mt-1 text-right small-error-msg">Value should be more than 0.</span>
                    }
                </div>
                <div className="col-7">
                    <SelectWrapper
                        name={name}
                        value={valueType}
                        onChange={onChangeTypeSelect}
                        disabled={disabled}
                    >
                        {readingIntervals.map((obj) => (
                            <option key={`reading-interval-temp-${obj.name}`} value={obj.id}>{obj.name}</option>
                        ))}
                    </SelectWrapper>
                </div>
            </div>
        </div>
    );
};

IntervalInput.propTypes = {
    value: PropTypes.number,
    type: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

WifiGroupForm.propTypes = {
    title: PropTypes.string,
    auth: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
};

export default withGlobalStore(WifiGroupForm);