import PropTypes from "prop-types";
import React from "react";
import {
    get as _get,
} from "lodash";
import {Link} from "react-router-dom";
import Helper from "../../../../helpers/helper";

const EquipmentInfoTable = ({list}) => {

    return (
        <div className="table-scroll-wrapp tachometers">
            <table className="table table-hover tl-fixed">
                <thead>
                <tr>
                    <th><span className="title">Equipment</span></th>
                    <th><span className="title">Location</span></th>
                    <th><span className="title">Installation Point</span></th>
                    <th><span className="title">Node</span></th>
                    <th><span className="title">Last Seen</span></th>
                </tr>
                </thead>
                <tbody>
                {list.length > 0
                    ?
                    list.map((item) =>
                        <Row
                            key={item.id}
                            item={item}
                        />
                    )
                    :
                    <tr>
                        <td colSpan={6} className="text-center text-info">
                            No WUA sensors were found.
                        </td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    );
};

EquipmentInfoTable.propTypes = {
    list: PropTypes.array,
};

const Row = ({item}) => {

    const equipmentName = _get(item, "installationPoint.equipment.name");
    const equipmentId = _get(item, "installationPoint.equipment.id");
    const locationName = _get(item, "installationPoint.equipment.location.name");
    const pointName = _get(item, "installationPoint.name");
    const pointId = _get(item, "installationPoint.id");
    const sensor = _get(item, "installationPoint.sensor");
    let sensorHex = sensor ? sensor.sensor_id : null;
    if (sensor && parseInt(sensor.hardware_id)) {
        sensorHex = parseInt(sensor.hardware_id).toString(16).toUpperCase();
    }
    const nodeSerial = _get(item, "installationPoint.sensor.node.serial");
    const sensorLastSeen = _get(item, "installationPoint.sensor.last_seen");

    return (
        <tr>
            <td>{equipmentName}</td>
            <td>{locationName}</td>
            <td>
                <Link
                    to={`/chart/${equipmentId}#sensors=${pointId}`}
                    className="link link-primary"
                    title="View Installation Point"
                    dangerouslySetInnerHTML={{__html: pointName + " " + (sensorHex ? "( " + sensorHex + " )" : "( - )")}}
                    style={{margin: 0}}
                />
            </td>
            <td>
                {nodeSerial
                    ?
                    <Link
                        to={`/network/nodes/${nodeSerial}`}
                        className="link link-primary"
                        title="View Node"
                        style={{margin: 0}}
                        dangerouslySetInnerHTML={{__html: nodeSerial}}
                    />
                    :
                    <i className="ml-2">Not set</i>
                }
            </td>
            <td className={"text-left"}>{Helper.momentFromNow(sensorLastSeen) || "Never"}</td>
        </tr>
    );
};

Row.propTypes = {
    item: PropTypes.object
};

export default EquipmentInfoTable;