import {renderToString} from "react-dom/server";
import {filter as _filter, get as _get} from "lodash";
import Helper from "../../../../../../helpers/helper";
import useAlarmList from "../../../../../../hooks/api/Alarm/UseAlarmListQuery";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useSelectedByEquipment} from "../../../../../../stores/zustand/ChartSelectedEquipmentStore";
import React, {useContext, useMemo} from "react";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import auth from "../../../../../../services/auth";
import {DATEFORMAT} from "../../../../../../constants/constants";
import {AlarmInfoBody} from "../../../../../alarms/alarm-info";
import {GlobalContext} from "../../../../../../stores/GlobalStore";
import {useIsShowChartMarks} from "../../../store/sync/sharedStore";

export const useActiveAlertAnnotation = (chartSoftHeight) => {
    const {equipmentItem} = useEquipmentByParams();
    const {activeAlarms, isSuccess, settingsStates} = useAlarmList(equipmentItem.id);
    const activeInstallationPoints = useSelectedByEquipment(equipmentItem.id);
    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList);
    const {user} = useContext(GlobalContext);
    const mediumChartValue = (chartSoftHeight.softMin + chartSoftHeight.softMax) / 2;
    const isShowChartMarks = useIsShowChartMarks();

    const getGroupInfo = (alert) => {
        const groupInstallationPoints = _get(alert, "installationPoints", []);
        let result =
            "<span class=\"mb-1\"><b>Delta-T alert for " + alert.installationPointName + "</b></span>" + "<span><b>Group Installation Points:</b></span>";
        groupInstallationPoints.map((alertData) => {
            const pointData = equipmentItem.installationPoints.find((pointData) => pointData.id === alertData.installationPoint.id);
            const pointMarkers = ["circle", "square", "diamond", "triangle", "triangle-down"];
            let pointIndex = activeInstallationPoints.indexOf(+_get(pointData, "id")) + 1;
            pointIndex = pointIndex > 4 ? pointIndex % 5 : pointIndex;
            const pointMarker = _get(pointMarkers, pointIndex);
            let markerHtml = "";
            switch (pointMarker) {
                case "circle":
                    markerHtml = "<span style=\"color: " + _get(pointData, "color") + "; font-size: 23px\">●</span>";
                    break;
                case "square":
                    markerHtml = "<span style=\"color: " + _get(pointData, "color") + "; font-size: 20px\">■</span>";
                    break;
                case "diamond":
                    markerHtml = "<span style=\"color: " + _get(pointData, "color") + "; font-size: 16px\">♦</span>";
                    break;
                case "triangle":
                    markerHtml = "<span style=\"color: " + _get(pointData, "color") + "; font-size: 15px\">▲</span>";
                    break;
                case "triangle-down":
                    markerHtml = "<span style=\"color: " + _get(pointData, "color") + "; font-size: 15px\">▼</span>";
                    break;
            }
            result += "<span>" + markerHtml + _get(pointData, "name", "") + " - " + _get(alertData, "reading_count", 0) + " (readings) </span>";
        });
        result += "<span><b>Average value of group:</b> " + _get(alert, "avg_value") + " " + seriesList.units + "</span>";
        result += "<span><b>Current value:</b> " + _get(alert, "readingValue") + " " + seriesList.units + "</span>";
        result +=
            "<span><b>Deviation:</b> " +
            (Number.parseFloat(_get(alert, "readingValue", 0)) - Number.parseFloat(_get(alert, "avg_value", 0))).toFixed(1) +
            " " +
            seriesList.units +
            "</span>";

        return result;
    };

    return useMemo(() => {
        if (!isSuccess || !isShowChartMarks) {
            return [];
        }

        let alertsMarkers = _filter(activeAlarms, (alert) => {
            return activeInstallationPoints.includes(+alert.installationPointId) && seriesList.readingType.includes(alert.readingType_id + "");
            // return activeInstallationPoints.includes(+alert.installationPointId);
        });

        let annotations = [];
        alertsMarkers.forEach((alert) =>
            annotations.push({
                draggable: "",
                labels: [
                    {
                        className: "chart-alert-wrapper",
                        allowOverlap: true,
                        backgroundColor: alert.alertLevelColor,
                        borderWidth: 0,
                        padding: 5,
                        borderColor: "white",
                        borderRadius: 5,
                        point: {
                            xAxis: 0,
                            yAxis: 0,
                            x: alert.chartTimestamp,
                            y: alert.readingValue,
                        },
                        x: 0,
                        y: 4,
                        useHTML: true,
                        format:
                            "<div class=\"chart-alert-marker\" style=\"border-color: " +
                            alert.alertLevelColor +
                            ";\">" +
                            "    <div class=\"chart-label invisible open-left tm-" +
                            alert.chartTimestamp +
                            "\" style=\"border-color: " +
                            alert.alertLevelColor +
                            ";" +
                            (alert.readingValue < mediumChartValue ? "transform:translateY(-100%);" : "") +
                            "box-shadow: 1px 1px 3px " +
                            alert.alertLevelColor +
                            ";\">" +
                            "        <div class=\"chart-label-hover\" style=\"box-shadow: 0 0 0 10px " +
                            alert.alertLevelColor +
                            ";\"></div>" +
                            "        <div class=\"chart-alert-label-text\">" +
                            (_get(alert, ["alertCondition", "group_id"], false) > 1
                                ? getGroupInfo(alert)
                                : "<span>" + alert.installationPointName + "</span>") +
                            renderToString(
                                <AlarmInfoBody
                                    alarm={alert}
                                    settingsStates={settingsStates}
                                />
                            ) +
                            "           <b style=\"color: " +
                            alert.alertLevelColor +
                            "\">" +
                            alert.readingValue +
                            " " +
                            seriesList.units +
                            "</b>" +
                            "           <span>" +
                            Helper.dateToFormat(alert.chartTimestamp, _get(user, "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0])) +
                            "</span>" +
                            "        </div>" +
                            (auth.userCan("editAlarms")
                                ? "       <div class=\"chart-label-btns\">" +
                                  "           <div data-id=\"" +
                                  alert.id +
                                  "\" data-type=\"ignore\" class=\"btn btn-sm highcharts-alert-action btn-secondary btn-secondary__custom mr-2\">Ignore</div>" +
                                  "           <div data-id=\"" +
                                  alert.id +
                                  "\" data-type=\"ack\" class=\"btn btn-sm highcharts-alert-action btn-primary\">Acknowledge</div>" +
                                  "       </div>"
                                : "") +
                            "    </div>" +
                            "</div>",
                    },
                ],
            })
        );

        return annotations;
    }, [isSuccess, activeInstallationPoints, activeAlarms, seriesList.selected, isShowChartMarks]);
};
