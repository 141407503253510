import React, {useRef} from "react";
import PropTypes from "prop-types";
import {filter as _filter, find as _find, includes as _includes, isEmpty, isNull, isUndefined} from "lodash";
import Select from "react-select";
import {useEquipmentsForLocationQuery} from "../../hooks/api/equipment/useEquipmentsForLocationQuery";

const EquipmentSelect = ({selectName, equipmentId, emptyOptionLabel, value, filterOutEquipmentIds, locationId, onChange, disabled}) => {
    const ref = useRef(null);

    const {data: list, isLoading} = useEquipmentsForLocationQuery(locationId);

    const getEquipmentById = (equipmentId) => {
        let resultEquipment = null;

        Object.values(list || []).forEach(({equipments}) => {
            if (equipments && equipments.length) {
                equipments.forEach((equipment) => {
                    if (equipment.id === equipmentId) {
                        resultEquipment = equipment;
                        return false;
                    }
                });
            }
            if (resultEquipment) {
                return false;
            }
        });

        return resultEquipment;
    };

    const onSelectChange = (e) => {
        if (!isNull(e)) {
            onChange(e.value ? getEquipmentById(+e.value) : null);
        }
    };
    // TODO: temp solution
    const options = isLoading
        ? []
        : _filter(
              (Object.keys(list) || []).map((index) => {
                  let equipmentList = list[index].equipments;
                  let locationNames = index.split("/");
                  if (equipmentList && equipmentList.length) {
                      return {
                          label: locationNames[locationNames.length - 1],
                          options: (equipmentList || []).map((item) => !_includes(filterOutEquipmentIds, item.id) && {label: item.name, value: item.id}),
                      };
                  }
              }),
              (i) => !(isEmpty(i) || isUndefined(i))
          );

    const allOptions = options.reduce((arr, opt) => [...arr, ...opt.options], []);
    const opt = _find(allOptions, {value: value}) || null;

    return (
        <Select
            defaultValue={_find(options, {value: equipmentId})}
            isDisabled={isLoading || disabled}
            name={selectName}
            ref={ref}
            isSearchable={false}
            className={"react-select"}
            classNamePrefix={"react-select"}
            onChange={onSelectChange}
            value={opt}
            placeholder={emptyOptionLabel || "---"}
            isLoading={isLoading && locationId}
            options={options}
            menuPortalTarget={document.body}
        />
    );
};

EquipmentSelect.propTypes = {
    locationId: PropTypes.number,
    onChange: PropTypes.func,
    equipmentId: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectName: PropTypes.string,
    className: PropTypes.string,
    emptyOptionLabel: PropTypes.string,
    disabled: PropTypes.bool,
    filterOutEquipmentIds: PropTypes.array,
};

export default EquipmentSelect;
