import Api from "./api";

const WuaCustomSensorApi = {
    getList: params => Api.get("wua-custom-sensor", {...params}),
    getView: id => Api.get(`wua-custom-sensor/${id}`),
    create: data => Api.post("wua-custom-sensor", data),
    update: (id, data) => Api.patch(`wua-custom-sensor/${id}`, data),
    delete: id => Api.delete(`wua-custom-sensor/${id}`),
};

export default WuaCustomSensorApi;