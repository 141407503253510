/* eslint-disable no-unused-vars */
// noinspection JSUnusedLocalSymbols

import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {each as _each, find as _find, get as _get, includes as _includes, isUndefined} from "lodash";
import SortableTree, {getFlatDataFromTree, toggleExpandedForAll} from "react-sortable-tree";
import ApiLocation from "../../api/location";
import {Loader} from "../../shared";
import Helper from "../../helpers/helper";
import LocationModal from "../../modals/location";
import SweetAlert from "react-bootstrap-sweetalert";
import SearchInput from "../shared/search-input";
import {withGlobalStore} from "../../stores/GlobalStore";
import cookies from "react-cookies";
import Toast from "../shared/toast";

import "../../assets/scss/components/facility/facility-icon.scss";
import {withLocationSelectStore} from "../../stores/LocationSelectStore";
import {HeaderSimple} from "../../shared/header";
import {getLocationId, setLocationId} from "../../helpers/locations-list";
import CollapseLocationSelect from "../../shared/collapseLocationSelect/collapseLocationSelect";
import {deleteItemFromAssetBranch} from "../../helpers/deleteItemFromAssetBranch";
import {getAssetTreeBranchParentByChildId} from "../../helpers/getAssetTreeBranchParentByChildId";
import styles from "./location.module.scss";

const ROW_HEIGHT = 62;

class Locations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{name: "Asset Tree"}],
            loader: true,
            treeData: [],
            backUpTreeData: [],
            draggedNode: null,
            btnText: "Collapse all",
            btnAction: false,
            icon: <i className="fa fa-angle-double-up" />,
            deleteLocationId: null,
            movedToAssetTreeBranchId: null,
            globalSearchString: "",
            inProgress: false,
            locationCustomerId: null,
            confirmDeleteModal: {
                assetBranchForDelete: [],
                show: false,
                countUsers: 0,
                countAi: 0,
                countDevices: 0,
            },
            autoSort: false,
        };

        this.handleFetch = this.handleFetch.bind(this);
        this.deleteLocation = this.deleteLocation.bind(this);
        this.onDeleteLocation = this.onDeleteLocation.bind(this);
        this.onSubmitLocationEditModal = this.onSubmitLocationEditModal.bind(this);
        this.onSubmitLocationCreateModal = this.onSubmitLocationCreateModal.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
        this.handleChangeAssetTreeBranchForDelete = this.handleChangeAssetTreeBranchForDelete.bind(this);
        this.refreshLocation = props.refreshLocation;
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    componentDidMount() {
        this.handleFetch();
        this.handleAutoSort();
    }

    handleFetch() {
        ApiLocation.list().then((data) => {
            let treeData = _get(data.list, "0.children") || [];
            if (!isUndefined(cookies.load("auto-sort-asset-tree"))) {
                treeData = Helper.sortLocationList(treeData);
            }

            this.setState(
                {
                    loader: false,
                    treeData: treeData,
                    backUpTreeData: treeData,
                    inProgress: false,
                    locationCustomerId: _get(data.list, "0.id"),
                },
                this.props.history.push(Helper.deleteHashParams(["modal", "id"]))
            );
        });
    }

    expand(expanded) {
        const newTreeData = toggleExpandedForAll({
            treeData: this.state.treeData,
            expanded,
        });

        if (expanded) {
            this.setState({
                btnText: "Collapse all",
                btnAction: false,
                icon: <i className="fa fa-angle-double-up" />,
                treeData: newTreeData,
            });
        } else {
            this.setState({
                btnText: "Expand all",
                btnAction: true,
                icon: <i className="fa fa-angle-double-down" />,
                treeData: newTreeData,
            });
        }
    }

    onUpdateSorting = () => {
        const {treeData} = this.state;

        this.setState({inProgress: true}, () => {
            ApiLocation.updateTree({newTree: treeData}).then((response) => {
                if (response.status === "ok") {
                    this.refreshLocation();
                    Toast.success("Your changes have been saved.");
                    this.setState({draggedNode: null, inProgress: false, backUpTreeData: treeData});
                }
                Toast.error(response.message);
                this.setState({inProgress: false});
            });
        });
    };

    onCancelSorting = () => {
        this.setState({treeData: this.state.backUpTreeData});
    };

    onSubmitLocationEditModal(data, id) {
        const {updateUser} = this.props;

        ApiLocation.update(data, id).then(() => {
            this.refreshLocation();
            updateUser().then(() => this.handleFetch());
        });
    }

    onSubmitLocationCreateModal(data) {
        const {updateUser} = this.props;

        ApiLocation.create(data).then(() => {
            this.refreshLocation();
            updateUser().then(() => this.handleFetch());
        });
    }

    onDeleteLocation = (deleteLocationId) => {
        if (deleteLocationId) {
            ApiLocation.getDeleteInfo(deleteLocationId).then((response) => {
                this.setState({
                    confirmDeleteModal: {
                        show: true,
                        assetBranchForDelete: deleteItemFromAssetBranch(this.state.treeData, deleteLocationId),
                        countUsers: _get(response, "countUsers"),
                        countEquipment: _get(response, "countEquipment"),
                        countAi: _get(response, "countActionItems"),
                        location: _get(response, "location"),
                        facilityLocation: _get(response, "facilityLocation"),
                        parent: this.findLocationParentById(deleteLocationId, this.state.treeData),
                        countDevices: _get(response, "countDevices"),
                    },
                    movedToAssetTreeBranchId: getAssetTreeBranchParentByChildId(this.state.treeData, deleteLocationId),
                    deleteLocationId: deleteLocationId,
                });
            });
        } else {
            this.setState({
                confirmDeleteModal: {
                    show: false,
                    countUsers: 0,
                    countAi: 0,
                    countDevices: 0,
                    parent: null,
                    location: null,
                },
                deleteLocationId: null,
            });
        }
    };

    findLocationParentById = (locationId, assetTree) => {
        let parentLocation = false;

        _each(assetTree, (location) => {
            parentLocation = this.findChildren(locationId, location);
            if (parentLocation) return false;
        });

        return parentLocation;
    };

    findChildren = (locationId, location) => {
        let parentLocation = false;
        const children = _get(location, "children", []);

        _each(children, (child) => {
            if (+child.id === +locationId) {
                parentLocation = location;
                return false;
            }

            parentLocation = this.findChildren(locationId, child);

            if (parentLocation) return false;
        });

        return parentLocation;
    };

    deleteLocation() {
        const deleteLocationId = this.state.deleteLocationId;
        const moveToAsset = this.state.movedToAssetTreeBranchId;

        ApiLocation.delete(deleteLocationId, moveToAsset).then(() => {
            this.onDeleteLocation(null);
            this.handleFetch();

            if (+deleteLocationId === +getLocationId()) {
                setLocationId("");
            }
        });
    }
    onChangeTree = (treeData) => {
        this.setState({treeData});
    };

    handleAutoSort = () => {
        let sort = cookies.load("auto-sort-asset-tree");
        if (isUndefined(sort)) {
            this.setState({autoSort: false});
        } else {
            this.setState({autoSort: true});
        }
    };

    handleChangeAssetTreeBranchForDelete(assetOption) {
        this.setState({movedToAssetTreeBranchId: assetOption.value});
    }

    hasAssetTreeBranchName = (node, nextParent) => {
        const {name, id} = node;
        const nextParentChildren = _get(nextParent, "children");

        if (!nextParentChildren) {
            return false;
        }

        return _find(nextParentChildren, function (child) {
            if (name === _get(child, "name") && _get(child, "id") !== id) {
                return true;
            }
        });
    };

    toggleAutoSort = () => {
        let sort = cookies.load("auto-sort-asset-tree");
        if (isUndefined(sort)) {
            const expires = new Date();
            expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 356);
            cookies.save("auto-sort-asset-tree", "1", {expires: expires});
        } else {
            cookies.remove("auto-sort-asset-tree");
        }

        this.setState({loader: true});
        this.refreshLocation();
        this.handleAutoSort();
        this.handleFetch();
    };

    render() {
        const {breadcrumbs, treeData, loader, btnText, btnAction, icon, globalSearchString, inProgress, confirmDeleteModal, movedToAssetTreeBranchId} =
            this.state;

        const {auth, history, user} = this.props;

        const searchParams = Helper.getHashParams();

        let hasAccessToFacility = true;
        _each(treeData, (facility) => {
            hasAccessToFacility = hasAccessToFacility && _includes(user.allowedLocationIds, +_get(facility, "id"));
        });

        return (
            <div>
                <HeaderSimple
                    breadcrumbs={breadcrumbs}
                    globalSearchString={globalSearchString}
                    handleChangeGlobalSearch={this.handleChangeGlobalSearch}
                />
                {loader ? (
                    <div className="loader-fullscreen">
                        <Loader />
                    </div>
                ) : (
                    <div>
                        <div className="subheader">
                            <div className="subheader-title">Asset Tree</div>
                            <div className="subheader-controls">
                                <div className="subheader-toolbar">
                                    <button
                                        type="button"
                                        className={"btn btn-sm ml-2 " + (this.state.autoSort ? "btn-success" : "btn-danger")}
                                        onClick={this.toggleAutoSort}
                                    >
                                        <span>Auto sorting: {this.state.autoSort ? "On" : "Off"}</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-primary ml-2"
                                        onClick={() => this.expand(btnAction)}
                                    >
                                        {icon} <span>{btnText}</span>
                                    </button>
                                    {auth.userCan("editAssetsTree") && (
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-success ml-2"
                                            onClick={() => history.push(Helper.setHashParams({modal: "add"}))}
                                        >
                                            <i className="fa fa-plus" /> <span>Add Branch</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="block">
                            <div className="block-body">
                                <div style={{height: this.calculateHeight(treeData)}}>
                                    <SortableTree
                                        treeData={treeData}
                                        onChange={(treeData) => this.onChangeTree(treeData)}
                                        canDrag={({node}) => {
                                            const facilityLocation = _find(treeData, {facility_id: node.facility_id});
                                            const facilityLocationId = _get(facilityLocation, "id");

                                            if (auth.userCan("editAssetsTree") && !this.state.autoSort) {
                                                return (
                                                    user.all_facilities ||
                                                    (+node.is_lock === 0 &&
                                                        (user.all_facilities || _includes(user.allowedLocationIds, facilityLocationId)))
                                                );
                                            }

                                            return false;
                                        }}
                                        canDrop={({prevPath, nextPath, node, nextParent}) => {
                                            if (auth.userCan("editAssetsTree")) {
                                                const hasAssetTreeBranchName = this.hasAssetTreeBranchName(node, nextParent);

                                                if (hasAssetTreeBranchName) {
                                                    return !hasAssetTreeBranchName;
                                                }

                                                return (!this.state.autoSort || nextPath.length > 1) && prevPath[0] === nextPath[0];
                                            }

                                            return false;
                                        }}
                                        maxDepth={10000}
                                        scaffoldBlockPxWidth={30}
                                        getNodeKey={({node}) => node.id}
                                        generateNodeProps={({node}) => {
                                            let locationName = (
                                                <span>
                                                    <span className={"tree-facility-icon"}>
                                                        <i className="fa fa-home fa-2x" />
                                                    </span>{" "}
                                                    <span className="tree-facility-name">{node.name}</span>
                                                </span>
                                            );

                                            return {
                                                title: +node.is_lock ? locationName : node.name,
                                                className: "k-sortable-row",
                                                buttons: [
                                                    <Link
                                                        key={`show-${node.id}`}
                                                        to={`/equipments/${node.id}`}
                                                        className="btn btn-sm btn-icon btn-primary"
                                                        title="View"
                                                    >
                                                        <i className="fa fa-eye" />
                                                    </Link>,
                                                    ...(auth.userCan("editAssetsTree") &&
                                                    +node.is_lock === 0 &&
                                                    (user.all_facilities || _includes(user.allowedLocationIds, node.id))
                                                        ? [
                                                              <span
                                                                  key={`edit-${node.id}`}
                                                                  className="btn btn-sm btn-icon btn-warning"
                                                                  onClick={() =>
                                                                      history.push(
                                                                          Helper.setHashParams({
                                                                              modal: "edit",
                                                                              id: node.id,
                                                                          })
                                                                      )
                                                                  }
                                                                  title="Edit"
                                                              >
                                                                  <i className="fas fa-pen" />
                                                              </span>,
                                                              <span
                                                                  key={`del-${node.id}`}
                                                                  className="btn btn-sm btn-icon btn-danger"
                                                                  style={{marginLeft: 5}}
                                                                  onClick={() => this.onDeleteLocation(node.id)}
                                                                  title="Delete"
                                                              >
                                                                  <i className="fas fa-trash-alt" />
                                                              </span>,
                                                          ]
                                                        : []),
                                                ],
                                            };
                                        }}
                                        rowHeight={ROW_HEIGHT}
                                    />
                                </div>
                            </div>
                        </div>
                        {hasAccessToFacility && (
                            <div className="d-flex justify-content-end align-items-center controls-btns-equipment sm-buttons">
                                <div
                                    className={"btn btn-sm btn-secondary mr-2" + (inProgress ? " disabled" : "")}
                                    onClick={this.onCancelSorting}
                                >
                                    {inProgress && <i className="fa fa-spinner ml-2" />}
                                    Cancel
                                </div>
                                <div
                                    className={"btn btn-sm btn-primary" + (inProgress ? " disabled" : "")}
                                    onClick={this.onUpdateSorting}
                                >
                                    {inProgress && <i className="fa fa-spinner ml-2" />}
                                    Save
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {searchParams.modal === "add" && (
                    <LocationModal
                        showModal={true}
                        title={"ADD BRANCH"}
                        submitTitle={"Save"}
                        list={treeData}
                        onClose={() => history.push(Helper.deleteHashParams(["modal"]))}
                        onSubmit={this.onSubmitLocationCreateModal}
                        inProgress={inProgress}
                        user={user}
                    />
                )}
                {searchParams.modal === "edit" && !!searchParams.id && (
                    <LocationModal
                        locationId={+searchParams.id}
                        showModal={true}
                        title={"EDIT BRANCH"}
                        submitTitle={"Save"}
                        onClose={() => history.push(Helper.deleteHashParams(["modal", "id"]))}
                        onSubmit={(data) => this.onSubmitLocationEditModal(data, +searchParams.id)}
                        inProgress={inProgress}
                        user={user}
                    />
                )}
                {confirmDeleteModal.show && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title="DELETE BRANCH"
                        onConfirm={this.deleteLocation}
                        onCancel={() => this.onDeleteLocation(null)}
                    >
                        Are you sure you want to delete this branch?
                        <br />
                        <div className={"text-left"}>
                            {!!(confirmDeleteModal.countAi || confirmDeleteModal.countUsers || confirmDeleteModal.countEquipment) && (
                                <React.Fragment>
                                    <div className={"mt-2 mb-2"}>
                                        <div className="mb-1">Note that the following exists:</div>
                                        <ul className="pl-4">
                                            {!!confirmDeleteModal.countEquipment && (
                                                <li>
                                                    {+confirmDeleteModal.countEquipment} {confirmDeleteModal.countEquipment > 1 && "pieces of"} equipment
                                                </li>
                                            )}
                                            {!!confirmDeleteModal.countAi && (
                                                <li>
                                                    {+confirmDeleteModal.countAi > 1
                                                        ? +confirmDeleteModal.countAi + " active action items"
                                                        : "1 active action item"}
                                                </li>
                                            )}
                                            {!!confirmDeleteModal.countUsers && (
                                                <li>
                                                    {confirmDeleteModal.countUsers > 1
                                                        ? +confirmDeleteModal.countUsers + " users with access to this branch"
                                                        : "1 user with access to this branch"}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className={"mt-2 mb-2"}>
                                        Deleting this branch will reassign all associated equipment and active action items to another branch. Users will
                                        lose access to {confirmDeleteModal.location.name} and will stop receiving emails for this branch.
                                    </div>
                                    <div className="mb-2">Select the location to which you want to move the equipment from the remote branch.</div>
                                    <div className="w-100 flex">
                                        <CollapseLocationSelect
                                            locationList={[confirmDeleteModal.facilityLocation]}
                                            onChange={this.handleChangeAssetTreeBranchForDelete}
                                            value={movedToAssetTreeBranchId}
                                            emptyOptionLabel={false}
                                            addClassName={styles.locationDelete}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </SweetAlert>
                )}
            </div>
        );
    }

    calculateHeight(treeData) {
        const flat = getFlatDataFromTree({treeData, getNodeKey: (node) => node.id});
        return flat.length * ROW_HEIGHT + 75;
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item global-search">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

Locations.propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object,
    updateUser: PropTypes.func,
    refreshLocation: PropTypes.func,
};

export default withLocationSelectStore(withGlobalStore(Locations));
