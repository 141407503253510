import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {removeChartNavigation} from "../../helpers/removeChartNavigation";
import {CHART_CONFIG} from "../../constants/chart";
import {setDarkModeForChart} from "../../helpers/setDarkModeForChart";
import Select from "react-select";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import ApiNode from "../../api/node";
import {get as _get, cloneDeep as _cloneDeep, last, first, map as _map} from "lodash";
import ToggleModal from "../toggle-modal";
import {Button} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

const availableTimeValues = [
    {
        value: "-2 hours",
        label: "Last 2 Hours",
    },
    {
        value: "-6 hours",
        label: "Last 6 Hours",
    },
    {
        value: "-12 hours",
        label: "Last 12 Hours",
    },
    {
        value: "-1 day",
        label: "Last Day",
    },
    {
        value: "-2 days",
        label: "Last 2 Days",
    },
    {
        value: "-4 days",
        label: "Last 4 Days",
    },
    {
        value: "-1 week",
        label: "Last Week",
    },
    {
        value: "-2 weeks",
        label: "Last 2 Weeks",
    },
    {
        value: "-1 month",
        label: "Last Month",
    },
    {
        value: "-3 months",
        label: "Last 3 Months",
    },
    {
        value: "-6 months",
        label: "Last 6 Months",
    },
    {
        value: "-1 year",
        label: "Last Year",
    },
    {
        value: "-2 years",
        label: "Last 2 Years",
    },
];

const countBatteryTrendValue = (a, x, b) => {
    return Math.round(parseFloat(a) * parseFloat(x) + parseFloat(b)) / 1000;
};
const NodeBatteryVoltageChart = (props) => {
    const {
        node
    } = props;

    let chartConfig = removeChartNavigation(_cloneDeep(CHART_CONFIG));

    if(localStorage.getItem("lightMode") === "true") {
        chartConfig = setDarkModeForChart(chartConfig);
    }

    const [fullScreen, setFullScreen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [options, setOptions] = useState({
        ...chartConfig,
        tooltip: {
            ...chartConfig.tooltip,
            enabled: true,
        },
        xAxis: {
            ordinal: false,
            crosshair: true,
            type: "datetime"
        },
        yAxis: {
            softMax: 3.65,
            endOfTick: false,
            tickInterval: 0.05,
            softMin: 2.9,
            startOnTick: false,
            title: {text: "Voltage(V)"},
            labels: {
                formatter: function () {
                    return this.axis.defaultLabelFormatter.call(this) + "(v)";
                }
            }
        },
        series: []
    });
    const [stats, setStats] = useState([]);
    const [selected, setSelected] = useState({
        dates: {},
        days: "-2 weeks",
        daysOption: {
            value: "-2 weeks",
            label: "Last 2 Weeks"
        }
    });

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const fetchData = (selected) => {
        setLoader(true);
        const {days, dates} = selected;
        ApiNode.batteryVoltage(node.serial, {selectedDays:days, selectedDates:dates}).then(response => {
            const isMote = !response.list.batt2;
            let series = [];

            const batt1MinTimestamp = _get(first(response.list.batt1), 0);
            const batt1MaxTimestamp = _get(last(response.list.batt1), 0);

            let batt1TrendData = [];

            if (batt1MinTimestamp && batt1MaxTimestamp) {
                batt1TrendData = [
                    [
                        batt1MinTimestamp,
                        countBatteryTrendValue(node.batt1_trend_slope, batt1MinTimestamp / 1000, node.batt1_trend_y_intersect)
                    ],
                    [
                        batt1MaxTimestamp,
                        countBatteryTrendValue(node.batt1_trend_slope, batt1MaxTimestamp / 1000, node.batt1_trend_y_intersect)
                    ]
                ];
            }

            if(!isMote) {
                const batt2MinTimestamp = _get(first(response.list.batt2), 0);
                const batt2MaxTimestamp = _get(last(response.list.batt2), 0);
                let batt2TrendData = [];
                if (batt2MinTimestamp && batt2MaxTimestamp) {
                    batt2TrendData = [
                        [
                            batt2MinTimestamp,
                            countBatteryTrendValue(node.batt2_trend_slope, batt2MinTimestamp / 1000, node.batt2_trend_y_intersect)
                        ],
                        [
                            batt2MaxTimestamp,
                            countBatteryTrendValue(node.batt2_trend_slope, batt2MaxTimestamp / 1000, node.batt2_trend_y_intersect)
                        ]
                    ];
                }

                series = [
                    {
                        name: "Battery 1",
                        data: response.list.batt1 || []
                    },
                    {
                        name: "Battery 2",
                        data: response.list.batt2 || []
                    },
                    {
                        name: "Battery 1 Lifetime trend",
                        data: [...batt1TrendData],
                        visible: false,
                        enableMouseTracking: false,
                        marker: {
                            enabled: false
                        }
                    },
                    {
                        name: "Battery 2 Lifetime trend",
                        data: [...batt2TrendData],
                        visible: false,
                        enableMouseTracking: false,
                        marker: {
                            enabled: false
                        }
                    },
                ];
            } else {
                series = [
                    {
                        name: "Battery 1",
                        data: response.list.batt1 || []
                    },
                    {
                        name: "Battery 1 Lifetime trend",
                        data: [...batt1TrendData],
                        visible: false,
                        enableMouseTracking: false,
                        marker: {
                            enabled: false
                        }
                    },
                ];
            }

            setStats(response.stats);

            setOptions({
                ...options,
                series: series
            });
            setLoader(false);
        });
    };

    useEffect(() => {
        fetchData(selected);
    }, [selected]);

    return <ToggleModal
        enabled={fullScreen}
        loader={loader}
        centered={true}
        size="full-screen"
        showModal={true}
        title={""}
        withoutFooter={true}
    >
        <div className="row">
            <div className={"col-5 mr-auto"}>
                <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                    }}
                    maxDate={new Date()}
                    placeholderText={"Select Period"}
                    portalPosition={fullScreen === true ? "date-picker-portal-modal" : null}
                    customInput={
                        <input className={"form-control"} style={{width:"180px"}} />
                    }
                />
                {startDate && endDate && <Button style={{margin:"0 5px"}} className="btn-fa-icon btn-sm btn-default" onClick={() => {
                    setSelected({
                        ...selected,
                        dates: {
                            startDate: moment(startDate).format("YYYY-MM-DD"),
                            endDate: moment(endDate).format("YYYY-MM-DD")
                        }
                    });
                }}>
                    <i className={"la la-search"} />
                </Button>}
                {startDate && endDate && <Button style={{margin:"0 5px"}} className="btn-fa-icon btn-sm btn-default" onClick={() => { setDateRange([null, null]); }}>
                    <i className={"fa fa-times"} />
                </Button>}
            </div>
            <div className={"col-4 mr-2"}>
                <Select
                    name="charts-time-selector"
                    value={selected.daysOption}
                    onChange={(val) => {
                        setDateRange([null, null]);
                        setSelected({
                            days: val.value,
                            dates: {},
                            daysOption: val
                        });
                        setLoader(true);
                    }}
                    options={availableTimeValues}
                    clearable={false}
                    closeOnSelect={false}
                    className="react-select"
                    classNamePrefix="react-select"
                />
            </div>
            <div>
                <Button className="btn-fa-icon btn-sm pull-right btn-default" onClick={() => {setFullScreen(!fullScreen);}}>
                    <i className={fullScreen ? "fa fa-times" : "fa fa-expand-arrows-alt"} />
                </Button>
            </div>
        </div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
        <table className={"table border-0"}>
            <tbody>
                {
                    _map(stats, (item, key) => {
                        let label = "V";
                        return (
                            <tr key={key}>
                                <td><span><b><span className='tooltip-icon'>●</span> {key}</b></span></td>
                                <td><span className="text-info"><b>MIN:</b></span></td>
                                <td>{(item.min || item.min === 0) ? (item.min + " " + label) : <i>No Data</i>}</td>
                                <td><span className="text-danger"><b>MAX:</b></span></td>
                                <td>{(item.max || item.max === 0) ? (item.max + " " + label) : <i>No Data</i>}</td>
                                <td><span className="text-warning"><b>AVG:</b></span></td>
                                <td>{(item.avg || item.avg === 0) ? (item.avg + " " + label) : <i>No Data</i>}</td>
                                <td><span className="text-success"><b>Current:</b></span></td>
                                <td>{(item.latest || item.latest === 0) ? (item.latest + " " + label) : <i>No Data</i>}</td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
    </ToggleModal>;
};

NodeBatteryVoltageChart.propTypes = {
    node: PropTypes.object,
};

export default NodeBatteryVoltageChart;
