import React from "react";
import PropTypes from "prop-types";
import {ALLOWABLE_PACKET_LOSS_PERCENTAGE, ALLOWABLE_SIGNAL_STRENGTH} from "../../constants/constants";
import InfoTooltip from "../../shared/infoTooltip/infoTooltip";

const SignalWarningTooltip = ({packetLossPercent, tooltipText, signalStrength = null, className= ""}) => {
    if (packetLossPercent > ALLOWABLE_PACKET_LOSS_PERCENTAGE || signalStrength < ALLOWABLE_SIGNAL_STRENGTH) {
        return (
            <span className={"ml-2 " + className}>
                <InfoTooltip
                    variant={"info"}
                    iconClass={"warning-tooltip icon-parent cursor-pointer fz-16"}>
                    {tooltipText}
                </InfoTooltip>
            </span>);
    }

    return <></>;
};

SignalWarningTooltip.propTypes = {
    packetLossPercent: PropTypes.number,
    signalStrength: PropTypes.number,
    tooltipText: PropTypes.string,
    className: PropTypes.string
};

export default SignalWarningTooltip;
