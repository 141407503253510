import {useEffect} from "react";

export const useChartLegendEffect = (chartRef) => {
    const handleKeyUp = (e) => {
        if (!chartRef.current) {
            return;
        }

        if (!chartRef.current.chart.isChartOver) {
            return;
        }

        if (e.keyCode !== 76) {
            return;
        }
        // L - key

        if (!chartRef.current.chart.legend.display) {
            chartRef.current.chart.update({
                legend: {
                    enabled: true,
                },
            });
        } else {
            chartRef.current.chart.update({
                legend: {
                    enabled: false,
                },
            });
        }

        chartRef.current.chart.isPaused = undefined;
    };

    useEffect(() => {
        document.addEventListener("keyup", handleKeyUp);

        return () => {
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, []);
};
