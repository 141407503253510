import Api from "./api";

const NameplateParameterApi = {
    list: (params) => Api.get("nameplate-parameter", {...params}),
    view: id => Api.get(`nameplate-parameter/${id}`),
    create: (data) => Api.post("nameplate-parameter", data),
    update: (id, data) => Api.patch(`nameplate-parameter/${id}`, data),
    delete: id => Api.delete(`nameplate-parameter/${id}`),
};

export default NameplateParameterApi;