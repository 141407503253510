import Api from "./api";

const GlobalNotes = {
    getList: params => Api.get("global-notes", {...params}),
    getFilters: () => Api.get("global-notes/get-filters"),
    getSelectsList: () => Api.get("global-notes/get-selects-list"),
    setViewed: id => Api.get(`global-notes/set-viewed/${id}`),
    getCount: params => Api.get("global-notes/count", {...params}),
    markAsViewed: () => Api.get("global-notes/mark-as-viewed"),
};

export default GlobalNotes;