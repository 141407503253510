import React, {Component} from "react";
import PropTypes from "prop-types";
import UniversalAdapterParameterForm from "./form";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiReadingTypes from "../../../../api/readingTypes";
import {
    get as _get
} from "lodash";
import Toast from "../../../shared/toast";

const columnList = {
    "name": {title: "Parameter Name", className: "col-6"},
    "unit": {title: "Parameter Unit", className: "col-4"},
    "actions": {title: "Actions", className: "col-2"}
};

class ParametersTable extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            editIndex: null,
            isDelete: false
        };
    }

    onEdit = (index) => {
        const {onCancelCreateNew} = this.props;
        this.setState({editIndex: index}, onCancelCreateNew);
    }

    onCancel = () => {
        const {onCancelCreateNew} = this.props;
        this.setState({editIndex: null}, onCancelCreateNew);
    }

    onSave = () => {
        const {updateParameterList} = this.props;

        return updateParameterList().then(() => {
            this.onCancel();
        });
    }

    onDelete = (index) => {
        this.setState({isDelete: index});
    }

    deleteParameter = (index) => {
        const {parameters, updateParameterList} = this.props;

        ApiReadingTypes.delete(_get(parameters, "["+index+"].id"))
            .then(() => {
                updateParameterList().then(() => {
                    Toast.success("The parameter type has been deleted.");
                    this.setState({isDelete: false});
                });
            });
    }

    render() {
        const {editIndex, isDelete} = this.state;
        const {parameters, createNew} = this.props;

        return (
            <React.Fragment>
                {createNew
                    ?
                    <React.Fragment>
                        <ParameterHeader/>
                        <UniversalAdapterParameterForm
                            columnList={columnList}
                            onSave={this.onSave}
                            onCancel={this.onCancel}
                        />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <ParameterHeader/>
                        <div className={"parameters-body custom-scroll"}>
                            {(parameters || []).length > 0
                                ?
                                parameters.map((parameter, index) =>
                                    <React.Fragment key={index}>
                                        {editIndex === index
                                            ?
                                            <UniversalAdapterParameterForm
                                                columnList={columnList}
                                                parameter={parameter}
                                                onSave={this.onSave}
                                                onCancel={this.onCancel}
                                            />
                                            :
                                            <ParameterRow
                                                parameter={parameter}
                                                onEdit={() => this.onEdit(index)}
                                                onDelete={() => this.onDelete(index)}
                                            />
                                        }
                                    </React.Fragment>
                                )
                                :
                                <div className={"row adapter-parameter-row"}>
                                    <div className="col-12 text-center text-info">
                                        No parameter types exist
                                    </div>
                                </div>
                            }
                        </div>
                    </React.Fragment>
                }
                {isDelete !== false &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title="DELETE PARAMETER TYPE"
                        openAnim={false}
                        closeAnim={false}
                        onConfirm={() => this.deleteParameter(isDelete)}
                        onCancel={() => this.onDelete(false)}
                    >
                        Are you sure you want to delete this parameter type?
                    </SweetAlert>
                }
            </React.Fragment>
        );
    }
}

const ParameterHeader = () => (
    <div className={"row adapter-parameter-header"}>
        {Object.keys(columnList).map((key, index) =>
            <div key={index} className={"title " + columnList[key].className}>
                <span>{columnList[key].title}</span>
            </div>
        )}
    </div>
);

class ParameterRow extends Component
{
    render() {
        const {parameter, onEdit, onDelete} = this.props;

        return (
            <React.Fragment>
                <div className={"row adapter-parameter-row"}>
                    <div className={columnList.name.className}>
                        <span>{parameter.name}</span>
                    </div>
                    <div className={columnList.unit.className}>
                        <span>{parameter.unit}</span>
                    </div>
                    <div className={columnList.actions.className}>
                        <div className="btn-group btn-group-sm">
                            <button
                                className="link color-primary"
                                onClick={onEdit}
                            >
                                <i className="fa fa-pen"/> <span>Edit</span>
                            </button>
                            <button
                                className="link color-danger"
                                onClick={onDelete}
                            >
                                <i className="fa fa-times"/> <span>Delete</span>
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ParameterRow.propTypes = {
    parameter: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};

ParametersTable.propTypes = {
    parameters: PropTypes.array,
    updateParameterList: PropTypes.func,
    createNew: PropTypes.bool,
    onCancelCreateNew: PropTypes.func
};

export default ParametersTable;