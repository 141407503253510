import React from "react";
import PropTypes from "prop-types";
import { usePeaksModal } from "../../../../../../modals/peaks/hooks/usePeaksModal";
import { useChartStateStoreContext } from "../../store/hooks/useStoreContext";

const PeaksButton = () => {
    const [onShow] = usePeaksModal();

    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    return (
        <div className="btn-group-sm">
            <button type="button" disabled={!!isLoading} className="btn btn-default" onClick={onShow}>
                Peaks
            </button>
        </div>
    );
};

PeaksButton.propTypes = {
    togglePeakModal: PropTypes.func,
};

export default PeaksButton;
