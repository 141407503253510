import React from "react";
import ChartHeader from "./components/chartHeader/chartHeader";
import PropTypes from "prop-types";
import ChartFooter from "./components/chartFooter/chartFooter";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useChartHeight} from "../../hooks/chartHooks";
import Loader from "../../../../../../shared/loader/loader";

const ChartWrapper = ({children, className}) => {
    const chartModeList = useChartStateStoreContext((state) => state.factory.chartModeList, shallow);
    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint, shallow);
    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    const chartHeight = useChartHeight();

    return (
        <div
            className={`chart-block ${
                chartMode === chartModeList.STACKED_WATERFALL ? "chart-block-fft-waterfall-wrapper" : "chart-block-fft"
            } ${className}`}
            id={`placeholder-${chartType}-${selectedPoint}`}
            data-chart-type={chartType}
            data-point-id={selectedPoint}
        >
            <ChartHeader />
            <div
                className="position-relative"
                style={{
                    height: chartMode !== chartModeList.STACKED_WATERFALL ? chartHeight + 38 + "px" : "auto",
                    minHeight: chartMode === chartModeList.STACKED_WATERFALL ? chartHeight + 38 + "px" : "auto",
                }}
            >
                {isLoading && (
                    <div className="loader-relative loader-relative_solid">
                        <Loader additionalClass="loader-sm" />
                    </div>
                )}
                {children}
            </div>
            <ChartFooter />
        </div>
    );
};
ChartWrapper.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
export default ChartWrapper;
