import {minBy as _minBy, orderBy as _orderBy, get as _get, isNull} from "lodash";

const WATERFALL_COLORS = {
    NONE: "#5687c8",
    WARNING: "#f37b6d",
    CAUTION: "#ddb641",
};

export const getColorScale = (alertConditions, maxZValue, axisId = null) => {
    if (!alertConditions.length) {
        return [];
    }
    const localAlertConditions = alertConditions.filter((item) => {
        const currentAxis = Array.isArray(axisId) ? axisId[0] : axisId;

        return isNull(currentAxis) ? true : +item.axisId === +currentAxis;
    });

    const minCaution = _minBy(
        localAlertConditions.filter((item) => item.alertLevel.name === "Caution" && !!item.value),
        "value"
    );

    const minWarning = _minBy(
        localAlertConditions.filter((item) => item.alertLevel.name === "Warning" && !!item.value),
        "value"
    );

    const cautionValue = _get(minCaution, "value", 0);
    const warningValue = _get(minWarning, "value", 0);

    const cautionGradientValue = cautionValue / maxZValue;
    const warningGradientValue = warningValue / maxZValue;

    const colorScale = [[0.0, WATERFALL_COLORS.NONE]];

    if (cautionGradientValue && cautionValue < maxZValue) {
        colorScale.push([cautionGradientValue, WATERFALL_COLORS.CAUTION]);
    }

    if (warningGradientValue && warningValue < maxZValue) {
        colorScale.push([warningGradientValue, WATERFALL_COLORS.WARNING]);
    }

    if (colorScale.length === 1) {
        colorScale.push([1, WATERFALL_COLORS.NONE]);
    }

    const isWarningLast = (colorScale.length === 2 && cautionValue <= warningValue) || (colorScale.length === 3 && warningValue >= cautionValue);

    if (isWarningLast) {
        colorScale.push([1, WATERFALL_COLORS.WARNING]);
    }

    const isCautionLast = (colorScale.length === 2 && cautionValue > warningValue) || (colorScale.length === 3 && warningValue < cautionValue);

    if (isCautionLast) {
        colorScale.push([1, WATERFALL_COLORS.CAUTION]);
    }

    return _orderBy(colorScale, 0);
};
