import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Helper from "../../helpers/helper";
import {get as _get} from "lodash";

const EquipmentTab = ({list, query}) => {

    if (!(list || []).length) {
        return (<div className="row">
            <div className="col">
                <span className="text-center text-info" style={{display: "block"}}>No items match your search.</span>
            </div>
        </div>);
    }

    return (<div className="row">
        {list.map(item => {
            return (
                <Link
                    key={item.id}
                    to={`/chart/${item.id}`}
                    className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-4 equipment-card-link"
                >
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div className="k-bg-metal pt-60 mb-3 equpment-list-photo" style={{
                                        background: "#fff"
                                    }}>
                                        <img
                                            src={_get(item, ["image", "url"]) ?? "/assets/img/default-equipment-bg.png"}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                                <span
                                                    title={item.title}
                                                    className="ellipsis-text"
                                                    dangerouslySetInnerHTML={{
                                                        __html: "Equipment Name: " + Helper.highlight(item.title, query)
                                                    }}
                                                />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <span><b>ID:</b> {item.id}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            );
        })}
    </div>);
};

EquipmentTab.propTypes = {
    list: PropTypes.array,
    query: PropTypes.string
};

export default EquipmentTab;