import React, { useEffect, useReducer } from "react";
import GlobalStore from "../../stores/GlobalStore";
import LocationSelectStore from "../../stores/LocationSelectStore";
import Menu from "./Components/Menu/Menu";
import ErrorBoundary from "./Components/ErrorBoundary";
import { useHideSide } from "./Hooks/UseHideSide";
import auth from "../../services/auth";
import ApiReading from "../../api/reading";
import CustomerApi from "../../api/customer";
import { get as _get, has as _has } from "lodash";
import { ACTION, reducer } from "./Reducer/MainReducer";
import cookies from "react-cookies";
import PrivacyPolicyModal from "../../modals/privacy-policy";
import CustomerSelectModal from "../../modals/customer-select";
import HelpPage from "../../pages/help";
import { Loader } from "../../shared";
import NotAccess from "../../pages/not-access";
import PropTypes from "prop-types";
import { useLoginValidator } from "./Hooks/UseLoginValidator";
import { injectHtmlDependencies } from "./Helper/InjectHtmlDependencies";
import { useThemeSync } from "./Hooks/UseThemeSync";
import { updateChartTypesMeasures } from "./Helper/UpdateChartTypesMeasures";
import { validateUserLogin } from "./Helper/ValidateUserLogin";
import { useChartSelectedChartTypeActions } from "../../stores/zustand/ChartSelectedChartTypeStore";
import RefactorNotification from "../../modals/userAlert/refactor-notification";
import NotAzureAccess from "../../pages/not-access/azure";
import {INACTIVE_CUSTOMER_STATUS_TYPE} from "../../constants/constants";
import {useMatomo} from "@datapunt/matomo-tracker-react";


const initialState = {
    user: null,
    auth,
    notAccess: false,
    loader: true,
};

const MainLayout = ({ children }) => {
    const toggleLeftSide = useHideSide();
    const {setSelectedChartType} = useChartSelectedChartTypeActions();
    const { pushInstruction } = useMatomo();
    useLoginValidator();
    useThemeSync();

    const [state, dispatch] = useReducer(reducer, initialState);

    const defaultUpdate = (data) => {
        let { chartTypes } = state;

        if (_has(data, ["personal_settings", "units"])) {
            chartTypes = updateChartTypesMeasures(
                _get(data, ["personal_settings", "units"]),
                state.chartTypes
            );
        }
        dispatch({
            type: ACTION.UPDATE_USER,
            state: {
                ...data,
                chartTypes: chartTypes,
            },
        });
    };

    const updateUser = () => {
        return auth.getProfile().then((profile = {}) => {
            dispatch({
                type: ACTION.SET_STATE,
                state: {
                    user: {
                        ...(profile.user || {}),
                        customer: profile.customer || {},
                        //TODO! use dispatch action
                        update: defaultUpdate,
                        emailEvents: _get(profile, "emailEventsDictionary", []),
                    },
                },
            });
        });
    };

    useEffect(() => {
        validateUserLogin();

        Promise.all([
            ApiReading.getTypesList(),
            auth.getProfile(),
            auth.setAllowedCustomers(),
        ]).then(([{chartTypes} = {}, profile = {}]) => {
            setSelectedChartType(chartTypes);

            if (
                auth.allowedCustomers.length === 1 &&
                +auth.customer.customer_id !== +auth.allowedCustomers[0].customer_id
            ) {
                CustomerApi.set({ customer_id: auth.allowedCustomers[0].customer_id }).then(() => {
                    window.location.reload(false);
                });
                return;
            }

            let notAccess = false;
            if (!auth.userCan("myDashboardAccess")) {
                notAccess = true;
                if (
                    auth.userCan("dashboardAccess", "ww2-dashboard") ||
                    auth.userCan("readonlyAccess", "ww2-dashboard") ||
                    auth.userCan("viewOnlyAccess", "ww2-dashboard")
                ) {
                    window.location.href = process.env.DASHBOARD_URL;
                }
            }

            if (+auth.customer.status_type === INACTIVE_CUSTOMER_STATUS_TYPE) {
                notAccess = true;
            }

            injectHtmlDependencies();

            //TODO! use dispatch action
            dispatch({
                type: ACTION.SET_STATE,
                state: {
                    user: {
                        ...(profile.user || {}),
                        customer: profile.customer || {},
                        update: defaultUpdate,
                        emailEvents: _get(profile, "emailEventsDictionary", []),
                    },
                    chartTypes,
                    notAccess,
                    loader: false,
                },
            });
        }).catch((error) => {
            if (error.message) {
                try {
                    const parsed = JSON.parse(error.message);
                    if (_get(parsed, "errors.message") === "Incorrect Domain") {
                        dispatch({
                            type: ACTION.SET_STATE,
                            state: {
                                notAccess: true,
                                loader: false,
                            },
                        });
                    }
                } catch (e) {
                    return false;
                }
            }
        });
    }, []);

    const { auth, user, chartTypes, loader, notAccess } = state;
    const cookieValue = cookies.load(process.env.POLICY_COOKIE);

    if (user) {
        pushInstruction("setUserId", _get(user, "full_name"));
    }

    if (auth.hasAzureStorage()) {
        return <NotAzureAccess auth={auth} />;
    }

    if (!cookieValue) {
        return <PrivacyPolicyModal />;
    }

    if (auth.needSelectCustomer) {
        return <CustomerSelectModal auth={auth} />;
    }

    if (auth.showRefactorNotification){
        return <RefactorNotification/>;
    }

    if (window.location.pathname === "/help") {
        return <HelpPage />;
    }

    if (loader) {
        return (
            <div className="loader-fullscreen">
                <Loader />
            </div>
        );
    }

    if (notAccess) {
        return <NotAccess />;
    }

    return (
        <ErrorBoundary>
                <GlobalStore auth={auth} user={user} chartTypes={chartTypes} updateUser={updateUser}>
                    <LocationSelectStore>
                        <div className={"app-item" + (toggleLeftSide.hideSide ? " hide-side" : "")}>
                            <Menu toggleLeftSide={toggleLeftSide} />
                                <div className="app-content">
                                    <div className="app-main">{children}</div>
                                </div>
                        </div>
                </LocationSelectStore>
                </GlobalStore>
        </ErrorBoundary>
    );
};
MainLayout.propTypes = {
    children: PropTypes.node,
};

export default MainLayout;
