import {useQuery} from "@tanstack/react-query";
import ApiNode from "../../../api/node";
import {PACKET_LOSS_KEYS} from "./constants";
import ApiRouter from "../../../api/router";
import ApiGateway from "../../../api/gateway";
import {EQUIPMENT_TYPE_GATEWAY, EQUIPMENT_TYPE_NODE, EQUIPMENT_TYPE_REPEATER} from "../../../constants/constants";

export const usePacketLossQuery = (serial, type, timeRange, dateRange) => {
    if (type === EQUIPMENT_TYPE_NODE) {
        return useQuery([PACKET_LOSS_KEYS.PACKET_LOSS_NODE, {serial, timeRange, dateRange}], () =>
            ApiNode.packetLoss(serial, {selectedDays: timeRange, selectedDates: dateRange})
        );
    }

    if (type === EQUIPMENT_TYPE_REPEATER) {
        return useQuery([PACKET_LOSS_KEYS.PACKET_LOSS_REPEATER, {serial, timeRange, dateRange}], () =>
            ApiRouter.packetLoss(serial, {selectedDays: timeRange, selectedDates: dateRange})
        );
    }

    if (type === EQUIPMENT_TYPE_GATEWAY) {
        return useQuery([PACKET_LOSS_KEYS.PACKET_LOSS_GATEWAY, {serial, timeRange, dateRange}], () =>
            ApiGateway.packetLoss(serial, {selectedDays: timeRange, selectedDates: dateRange})
        );
    }
};
