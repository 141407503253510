import {useQuery} from "@tanstack/react-query";
import api from "../../../api/api";

const placeholderData = {list: [], meta: {page: null, pages: null, perpage: null, total: null}};

const getFirmwareQueryKeys = ({page, sort, perpage, search, typeFilter}) => ["firmware", {page, sort, perpage, search, typeFilter}];

const getFirmware = ({sort = {field: null, sort: null}, page, perpage, search, typeFilter}) =>
    api.get("firmware", {query: {sort, pagination: {page, perpage}, filter: {query: search, type: typeFilter}}});

export const useFirmwareQuery = ({page, perpage, sort, search, typeFilter} = {}) =>
    useQuery({
        queryFn: () => getFirmware({page, sort, perpage, search, typeFilter}),
        queryKey: getFirmwareQueryKeys({page, sort, perpage, search, typeFilter}),
        placeholderData,
    });
