import React from "react";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";

const GridStakedWaterfallSwitcher = () => {
    const isGridView = useChartStateStoreContext((state) => state.settings.isGridView);

    const {toggleGridView} = useChartStateStoreContext((state) => state.actions);

    const onToggle = () => {
        toggleGridView();
    };

    return (
        <span
            className="alert-link black-color"
            onClick={onToggle}
        >
            <label className="switch">
                <input
                    readOnly={true}
                    type="checkbox"
                    checked={isGridView ? "checked" : false}
                />
                <span className="slider round" />
            </label>
            Grid View
        </span>
    );
};

export default GridStakedWaterfallSwitcher;
