import React, {Component} from "react";
import PropTypes from "prop-types";
import {withLocationSelectStore} from "../../../stores/LocationSelectStore";
import {withGlobalStore} from "../../../stores/GlobalStore";
import SearchInput from "../../shared/search-input";
import {Link} from "react-router-dom";
import LimitSelect from "../../shared/limit-select";
import {Loader, Pager} from "../../../shared";
import WifiGroupsApi from "../../../api/wifiGroups";
import Table from "./table";
import SweetAlert from "react-bootstrap-sweetalert";
import Toast from "../../shared/toast";
import Helper from "../../../helpers/helper";
import { HeaderSimple } from "../../../shared/header";
import NotAccess from "../../not-access";

class Index extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{name: "Manage Wifi Mote Groups"}],
            loader: true,
            list: [],
            pagination: {
                page: 1,
                pages: 1,
                perpage: Helper.getPerPage("wifi"),
                total: 0
            },
            sort: {
                field: "",
                sort: "asc"
            },
            filter: {
                query: "",
            },
            globalSearchString: "",
            deleteGroupId: null
        };
    }

    onDeleteGroup = (id) => {
        this.setState({deleteGroupId: id});
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    componentDidMount() {
        this.handleFetch();
    }

    handleFetch() {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        return WifiGroupsApi.getList({
            query: {
                sort: this.state.sort,
                filter: this.state.filter,
                pagination: this.state.pagination
            },
            signal: this.signal
        }).then(response => {
            if (response) {
                const {list = [], meta = {}} = response;
                this.setState({
                    loader: false,
                    pagination: Object.assign({}, this.state.pagination, meta),
                    list
                }, () => {
                    if (!this.finished) {
                        this.finished = true;
                    }
                });
            }
        });
    }

    handleSortChange = (field = "") => {
        const {sort} = this.state;

        this.setState({
            sort: Object.assign({}, {
                field,
                sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"
            }),
            loader: true
        }, this.handleFetch);
    }

    handlePagerChange = (page = 1) => {
        this.setState({loader: true, pagination: Object.assign({}, this.state.pagination, {page})}, this.handleFetch);
    }

    handleSearch = (value) => {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }

        this.handleFilterChange({query: value});
    };

    handleLimitChange = e => {
        const {value} = e.target;
        Helper.setPerPage("wifi", value);
        this.setState({
            loader: true,
            pagination: {
                ...this.state.pagination,
                page: 1,
                perpage: value
            }
        }, this.handleFetch);
    };

    handleFilterChange = (obj = {}) => {
        this.finished = false;
        this.setState({
            loader: true,
            filter: Object.assign({}, this.state.filter, obj),
            pagination: Object.assign({}, this.state.pagination, {page: 1})
        }, this.handleFetch);
    }

    deleteGroup = () => {
        const {deleteGroupId} = this.state;

        WifiGroupsApi
            .delete(deleteGroupId)
            .then(() => {
                this.handleFetch()
                    .then(() => {
                        this.setState({deleteGroupId: null}, () => Toast.success("The wifi sensor mote group has been deleted."));
                    });
            });
    }

    render() {
        const {
            breadcrumbs,
            loader,
            list,
            pagination,
            sort,
            filter,
            globalSearchString,
            deleteGroupId
        } = this.state;
        const {auth} = this.props;

        if (!auth.userCan("manageWiFiGroup")) {
            return <NotAccess/>;
        }

        return (
            <div>
                 <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">Manage Wifi Mote Groups</div>
                    <div className="subheader-controls">
                        <div className="subheader-toolbar">
                            <Link
                                to="/network/wifi-groups/add"
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-plus"/> Add Wifi Mote Group
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="block">
                    <div className="block-header">
                        <div className="block-label" />
                        <div id="top-pagination-block" className="block-pagination">
                            {pagination.total > 10 &&
                                <div className="limit-select-block">
                                    <LimitSelect
                                        name={"perpage"}
                                        onChange={this.handleLimitChange}
                                        defaultValue={pagination.perpage}
                                        limits={[10, 20, 50, 100]}
                                    />
                                </div>
                            }
                            {pagination.pages > 1 &&
                                <Pager
                                    page={pagination.page}
                                    pages={pagination.pages}
                                    perPage={pagination.perpage}
                                    onPagerChange={this.handlePagerChange}
                                />
                            }
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <div style={{minWidth: "210px"}}>
                                    <SearchInput
                                        placeholder="Search Wifi Mote Groups"
                                        query={filter.query}
                                        onChange={this.handleSearch}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-body">
                        {loader
                            ?
                            <Loader/>
                            :
                            <div>
                                <Table
                                    list={list}
                                    sort={sort}
                                    query={filter.query}
                                    onSortChange={this.handleSortChange}
                                    onDelete={this.onDeleteGroup}
                                />
                            </div>
                        }
                    </div>
                </div>
                {deleteGroupId &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title="DELETE WIFI MOTE GROUP"
                        onConfirm={this.deleteGroup}
                        onCancel={() => this.onDeleteGroup(null)}
                    >
                        Are you sure you want to delete this wifi sensor mote group?
                    </SweetAlert>
                }
            </div>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item global-search">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

Index.propTypes = {
    history: PropTypes.object,
    auth: PropTypes.object
};

export default withLocationSelectStore(withGlobalStore(Index));