import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import Helper from "../helpers/helper";
import { FREQUENCY_TYPES, USER_CHART_LAYOUT_VIEW_TYPES} from "../constants/constants";
import { forceCheck } from "react-lazyload";

const useUserLocalSettingsStore = create(
    immer((set) => ({
        fftChartFrequency: Helper.getStorageItem("fftChartFrequency", FREQUENCY_TYPES.HZ),
        theme: Helper.getStorageItem("lightMode", false),
        viewChartType: Helper.getStorageItem("viewChartType") || USER_CHART_LAYOUT_VIEW_TYPES.LIST,
        viewDashboardType: Helper.getStorageItem("viewDashboardType") || USER_CHART_LAYOUT_VIEW_TYPES.LIST,
        actions: {
            setFftChartFrequency: (value) => {
                set((state) => {
                    state.fftChartFrequency = value;
                    Helper.setStorageItem("fftChartFrequency", value);
                });
            },
            setTheme: (value) => {
                set((state) => {
                    state.theme = value;
                    Helper.setStorageItem("lightMode", value);
                });
            },
            setViewChartType: (value) => {
                set((state) => {
                    state.viewChartType = value;
                    Helper.setStorageItem("viewChartType", value);
                    switch (value) {
                        case "list":
                        case "blocks":
                            window.dispatchEvent(new Event("resize"));
                            window.dispatchEvent(new Event("changeViewType"));
                            return forceCheck();
                        default:
                            return;
                    }
                });
            },
            setViewDashboardType: (value) => {
                set((state) => {
                    state.viewChartType = value;
                    Helper.setStorageItem("viewChartType", value);
                    switch (value) {
                        case "list":
                        case "blocks":
                            window.dispatchEvent(new Event("resize"));
                            window.dispatchEvent(new Event("changeViewType"));
                            return forceCheck();
                        default:
                            return;
                    }
                });
            }
        },
    }))
);

export const useUserFftChartFrequency = () => useUserLocalSettingsStore((state) => state.fftChartFrequency);
export const useUserTheme = () => useUserLocalSettingsStore((state) => state.theme);
export const useUserViewChartType = () => useUserLocalSettingsStore((state) => state.viewChartType);
export const useUserLocalSettingsStoreActions = () => useUserLocalSettingsStore((state) => state.actions);