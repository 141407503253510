import {useChartStateStoreContext} from "../store/hooks/useStoreContext";
import {useEffect} from "react";
import {shallow} from "zustand/shallow";

export const useChartScaleInit = (chartRef, dependencies) => {
    const {setInitMinMax} = useChartStateStoreContext((state) => state.actions);
    const softMinCurrent = useChartStateStoreContext((state) => state.settings.softMinCurrent, shallow);
    const softMaxCurrent = useChartStateStoreContext((state) => state.settings.softMaxCurrent, shallow);

    const onChartScaleInit = (chartRef, softMaxCurrent, softMinCurrent) => {
        if (chartRef.current.chart.yAxis[0].max || chartRef.current.chart.yAxis[0].min) {
            setInitMinMax({
                valueInitMax: (+softMaxCurrent !== 0 && softMaxCurrent > +chartRef.current.chart.yAxis[0].max) ? softMaxCurrent.toFixed(2) : chartRef.current.chart.yAxis[0].max.toFixed(2),
                valueInitMin: (+softMinCurrent !== 0 && +softMinCurrent < +chartRef.current.chart.yAxis[0].min) ? softMinCurrent : chartRef.current.chart.yAxis[0].min,
            });
        }
    };

    useEffect(() => {
        if (!chartRef.current || !chartRef.current?.chart) {
            return;
        }

        onChartScaleInit(chartRef, softMaxCurrent, softMinCurrent);

        return () => {
            if (!chartRef.current || !chartRef.current?.chart) {
                return;
            }

            onChartScaleInit(chartRef, softMaxCurrent, softMinCurrent);
        };
    }, [chartRef.current, dependencies, softMaxCurrent, softMinCurrent]);
};
