import React, {Component, useRef, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {Popover, PopoverHeader, PopoverBody} from "reactstrap";
import Helper from "../../helpers/helper";
import ApiWhatsNew from "../../api/whatsNew";
import {Loader} from "../../shared";
import {get as _get} from "lodash";

import "../../assets/scss/components/whats-new/whats-new.scss";
import SelectWrapper from "../../helpers/select-wrapper";


class WhatNewPopup  extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            items: [],
            filter: {
                filter: ""
            }
        };
    }
    fetch = () => {
        const {filter} = this.state;

        ApiWhatsNew.getList({query: this.state.filter}).then(({list, status}) => {
            if (status === "ok") {
                this.setState({
                    items: list,
                    loader: false
                }, () => {
                    if (filter.filter == "") {
                        this.props.updateCountWhatsNew(list.length);
                    }
                });
            }
        });
    };
    componentDidMount() {
        this.fetch();
    }
    componentDidUpdate(prevProps) {
        if (this.props.forceUpdate !== prevProps.forceUpdate) {
            this.fetch();
        }
    }
    setViewedAll = (e) => {
        e.preventDefault();
        const {countActiveNews} = this.props;
        if (countActiveNews === 0) {
            return false;
        }
        ApiWhatsNew.setViewedAll().then(({status = ""}) => {
            if (status === "ok") {
                this.setState({
                    loader: true
                }, () => {
                    this.fetch();
                    this.props.updateCountActiveNews();
                });
            }
        });
    };
    handleFilter = e => {
        const {name, value} = e.target;
        this.setState({
            filter: {
                ...this.state.filter,
                [name] : value
            },
            loader: true
        }, this.fetch);
    };

    handleMarked = item => {
        const {userMarked={}, id} = item;

        if (!userMarked) {
            ApiWhatsNew.setMarked(id).then(({status}) => {
                if (status === "ok") {
                    this.fetch();
                }
            });
            return;
        }
        ApiWhatsNew.deleteMarked(id).then(({status}) => {
            if (status === "ok") {
                this.fetch();
            }
        });
    };

    render() {
        const {popUpProps, history, user, setViewNewItem} = this.props;
        const {items, loader, filter} = this.state;

        return (
            <Popover
                {...popUpProps}
            >
                <PopoverHeader>
                    <div className="d-flex justify-content-between">
                        <span className={"whats-new-popover-title"}>What&apos;s New</span>
                        <div>
                            <a
                                href=""
                                onClick={this.setViewedAll}
                                className={`link link-primary mr-4 ${this.props.countActiveNews === 0 ? "disabled" : ""}`}
                            >
                                Mark all as read
                            </a>
                            <span className="button-close-whats" onClick={this.props.toggleWhatsPopup}>
                                <i className="fas fa-times"/>
                            </span>
                        </div>

                    </div>
                    <hr/>
                    <div className="form-group">
                        <label htmlFor="filter">View</label>
                        <SelectWrapper
                            onChange={this.handleFilter}
                            menuPortalTarget={null}
                            defaultValue={filter.filter}
                            className={"font-weight-normal"}
                            name="filter">
                                <option value="byDate">By Date</option>
                                <option value="alphabetically">Alphabetically</option>
                                <option value="notViewed">Unread</option>
                                <option value="marked">Marked</option>
                        </SelectWrapper>
                    </div>
                </PopoverHeader>
                <PopoverBody>
                    <div className={"custom-scroll"} style={{height: "auto", maxHeight: "450px"}}>
                        {loader
                            ? <Loader/>
                            : items.length > 0
                                ?
                                items.map(item =>
                                    <WhatsNewItem
                                        handleMarked={this.handleMarked}
                                        user={user}
                                        history={history}
                                        key={item.id}
                                        item={item}
                                        setViewNewItem={setViewNewItem}
                                    />
                                )
                                :
                                <h6 style={{margin: "10px 0"}} className="text-center">No items were found</h6>
                        }
                    </div>
                </PopoverBody>
            </Popover>
        );
    }
}

WhatNewPopup.propTypes = {
    popUpProps: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object,
    forceUpdate: PropTypes.bool,
    updateCountActiveNews: PropTypes.func,
    updateCountWhatsNew: PropTypes.func,
    countActiveNews: PropTypes.number,
    setViewNewItem: PropTypes.func,
    toggleWhatsPopup: PropTypes.func
};
export default withRouter(WhatNewPopup);

const WhatsNewItem = ({item, history, user, handleMarked, setViewNewItem}) => {

    const ref = useRef(null);

    let {userMarked = {}} = item;
    let description = _get(item, "short_description") ? _get(item, "short_description") : _get(item, "description");
    const [showMoreLink, setShowMoreLink] = useState(false);
    let overFlowed = false;

    if (description.indexOf("img") !== -1 || description.indexOf("href=") !== -1) {
        overFlowed = true;
    }
    let modifyDescr = description.replace(/<img.*?>/g, "");

    if (modifyDescr.trim().length > 500 && !showMoreLink) {
        overFlowed = true;
    }

    let viewItem = itemId => {
        setViewNewItem();
        return history.push(Helper.setHashParams({modal: "whats-new", id: itemId}));
    };
    useEffect(() => {
        if (ref.current.offsetHeight + 10 < ref.current.scrollHeight && !showMoreLink || overFlowed) {
            setShowMoreLink(true);
        }
    });

    return (
        <div className={"whats-new-popover-item"}>
            <i onClick={() => handleMarked(item)} title={userMarked ? "Unmark item" : "Set marked"} className={`fa${userMarked ? "s" : "r"} fa-star color-yellow-brand cursor-pointer whats-new-marked`}/>
            <div className={"whats-new-item-title  cursor-pointer"}>
                <span onClick={() => viewItem(item.id)} dangerouslySetInnerHTML={{__html: item.title || "---"}} />
                {_get(item, "viewed", []).length === 0 && <i className="fa fa-circle whats-new-point-icon color-yellow-brand pull-right"/>}
            </div>
            <div className={""}>
                <span ref={ref} className={"whats-new-item-description"} dangerouslySetInnerHTML={{__html: modifyDescr}}/>
                {showMoreLink &&
                <a
                    href=""
                    onClick={e => {
                        e.preventDefault();
                        viewItem(item.id);
                    }}
                    className={"link link-primary view-more-link"}
                >More</a>
                }
            </div>
            <div className={"whats-new-item-date"}>{_get(Helper.explodeDate(Helper.dateToUserFormat(item.release_date, user)), "date")}</div>
        </div>
    );
};

WhatsNewItem.propTypes = {
    item: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object,
    handleMarked: PropTypes.func,
    setViewNewItem: PropTypes.func
};