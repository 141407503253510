import React, {useEffect, useReducer} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {Loader, ValidationError} from "../../../shared";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import {get as _get, cloneDeep as _cloneDeep, mapValues as _mapValues, isEqual as _isEqual, find as _find, each as _each} from "lodash";
import Select from "react-select";
import MultiSelect from "./form/multi-select";
import AlertSettings from "./form/alert-settings";
import UserApi from "../../../api/user";
import DeviceAlertGroupsApi from "../../../api/deviceAlertGroups";
import {customStyles} from "../../../helpers/select";
import GeneralInfo from "./form/general-info";
import ApiLocations from "../../../api/location";
import Helper from "../../../helpers/helper";
import {HeaderSimple} from "../../../shared/header";

const isDarkTheme = localStorage.getItem("lightMode");

const entityList = [
    {
        name: "gateways",
        labels: "gateways",
        key: "gateway_id",
        isCheckable: true,
        updateCurrent: (ev, item, updateCurrent) => updateCurrent(ev, {gateways: item.gateway_id}),
    },
    {
        name: "routers",
        labels: "repeaters",
        key: "router_id",
        filter: (item, current) => {
            let flag = false;
            if (current.gateways && +current.gateways !== +item.gateway_id) flag = true;
            return flag;
        },
    },
    {
        name: "nodes",
        labels: "nodes",
        key: "node_id",
        isCheckable: true,
        updateCurrent: (ev, item, updateCurrent) => updateCurrent(ev, {gateways: item.gateway_id, nodes: item.node_id}),
        filter: (item, current) => {
            let flag = false;
            if (current.gateways && +current.gateways !== +item.gateway_id) flag = true;
            return flag;
        },
    },
    {
        name: "sensors",
        labels: "sensors",
        key: "sensor_id",
        label: "sensor_hex_id",
        filter: (item, current) => {
            let flag = false;
            if (current.gateways && +current.gateways !== +item.gateway_id) flag = true;
            if (current.nodes && +current.nodes !== +item.node_id) flag = true;
            return flag;
        },
    },
    {
        name: "motes",
        labels: "sensor motes",
        key: "node_id",
        filter: (item, current) => {
            let flag = false;
            if (current.gateways && +current.gateways !== +item.gateway_id) flag = true;
            return flag;
        },
    },
];

const normalizedData = (group, selfUsers, facilities) => {
    let data = {};
    if ((group || {}).id) {
        let groupParticipants = [];
        let groupFacilities = [];
        if ((group.participants || []).length === (selfUsers.users || []).length) {
            groupParticipants.push({value: "*", label: "All Users"});
        } else {
            (selfUsers.users || []).forEach((user) => {
                if ((group.participants || []).indexOf(+user.id) !== -1) {
                    groupParticipants.push({value: user.id, label: user.full_name});
                }
            });
        }
        (facilities || []).forEach((facility) => {
            if ((group.facilities || []).indexOf(+facility.facility_id) !== -1) {
                groupFacilities.push({value: facility.facility_id, label: facility.name});
            }
        });
        data = {
            ...group,
            type: +(group.type === "custom"),
            offlinePeriod: group.offline_period || 15,
            facilities: groupFacilities,
            participants: groupParticipants,
        };
    } else {
        data.offlinePeriod = 15;
        data.issue = "all";
    }
    return data;
};

const normalizedUsers = (users, locationStructure) => {
    _each(users, (user) => {
        const locations = _get(user, "locations", {});
        let accessNames = "";

        if (_get(user, "is_super_admin") || !!_get(user, "all_facilities")) {
            accessNames = "All";
        } else if (!Object.keys(locations).length) {
            accessNames = "Not set";
        } else {
            _each(locations, (name, id) => {
                const currentLocation = _find(locationStructure, {id: +id}) || {};
                accessNames = accessNames ? accessNames + ", " + _get(currentLocation, "full_name", "-") : _get(currentLocation, "full_name", "-");
            });
        }

        user.accessNames = accessNames;
    });

    return users;
};

const initialState = {
    data: {},
    users: [],
    issues: {},
    structure: {},
    search: {},
    current: {},
    validation: {},
    inProgress: false,
    loader: true,
};

const reducer = (state, action) => ({...state, ...action});

const DeviceAlertGroupsForm = ({match, history}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {users, data, structure, search, current, issues, validation, inProgress, loader} = state;

    useEffect(() => {
        Promise.all([
            match.params.id ? DeviceAlertGroupsApi.get(match.params.id) : false,
            UserApi.getSelfUsers({
                query: {
                    filter: {status: 1},
                    excludeWCDUsers: 1,
                },
            }),
            DeviceAlertGroupsApi.facilities(),
            DeviceAlertGroupsApi.gateways(),
            DeviceAlertGroupsApi.routers(),
            DeviceAlertGroupsApi.sensors(),
            DeviceAlertGroupsApi.nodes(),
            DeviceAlertGroupsApi.motes(),
            DeviceAlertGroupsApi.issues(),
            ApiLocations.list(),
        ]).then(([group, selfUsers, facilities, gateways, routers, sensors, nodes, motes, issues, locationResponse]) => {
            const locationStructure = Helper.transformLocations(_get(locationResponse, "list", []));

            dispatch({
                data: normalizedData(group, selfUsers, facilities),
                users: normalizedUsers(selfUsers.users || [], locationStructure),
                issues: issues || {},
                structure: {facilities, gateways, routers, sensors, nodes, motes},
                loader: false,
            });
        });
    }, []);

    const updateAll = (key, ids, isAll) => {
        let tempData = _cloneDeep(data);
        if (!tempData[key]) tempData[key] = [];
        ids.forEach((id) => {
            const tempKey = tempData[key].indexOf(id);
            if (isAll) tempKey !== -1 && tempData[key].splice(tempKey, 1);
            else tempKey === -1 && tempData[key].push(id);
        });
        dispatch({
            data: tempData,
            validation: {...validation, [key]: ""},
        });
    };

    const updateData = (key, value) => {
        let other = {};
        let tempData = _cloneDeep(data);
        if (Object.values(_mapValues(entityList, "name")).indexOf(key) !== -1) {
            if (!tempData[key]) tempData[key] = [];
            const tempKey = tempData[key].indexOf(value);
            tempKey !== -1 ? tempData[key].splice(tempKey, 1) : tempData[key].push(value);
        } else {
            tempData[key] = value;
            if (["type", "issue"].indexOf(key) !== -1) {
                other.search = {};
                other.current = {};
                entityList.forEach((entity) => (tempData[entity.name] = []));
            }
            if (key === "type") tempData.facilities = [];
        }
        dispatch({
            ...other,
            data: tempData,
            validation: {...validation, [key]: ""},
        });
    };

    const updateCurrent = (ev, values) => {
        if (["SPAN", "INPUT"].indexOf(ev.target.tagName) === -1) {
            dispatch({current: _isEqual(current, values) ? {} : values});
        }
    };

    const updateSearch = (ev, key) => {
        const {value} = ev.target;
        dispatch({search: {...search, [key]: value}});
    };

    const submit = () => {
        let submitData = _cloneDeep(data);
        submitData.participants =
            _get(submitData, "participants.0.value") === "*"
                ? (users || []).map((user) => +user.id)
                : (submitData.participants || []).map((user) => +user.value);
        submitData.facilities =
            _get(submitData, "facilities.0.value") === "*"
                ? (structure.facilities || []).map((facility) => +facility.facility_id)
                : (submitData.facilities || []).map((facility) => +facility.value);
        submitData.type = (typeList[submitData.type] || {}).key;
        dispatch({inProgress: true});
        (match.params.id ? DeviceAlertGroupsApi.update(match.params.id, submitData) : DeviceAlertGroupsApi.store(submitData))
            .then(() => history.push("/device-alert-groups"))
            .catch((resp) => {
                if (resp.status === 422) {
                    let respValidation = {};
                    Object.keys(resp.errors || {}).forEach((key) => (respValidation[key] = (resp.errors[key] || [])[0] || ""));
                    dispatch({validation: respValidation, inProgress: false});
                }
            });
    };

    let facilityIdsList = (structure.facilities || []).map((facility) => facility.facility_id);
    if (+data.type === 0 && !(data.facilities || []).length) {
        facilityIdsList = [];
    }
    if (+data.type === 0 && (data.facilities || []).length && data.facilities[0].value !== "*") {
        facilityIdsList = [];
        data.facilities.forEach((facility) => facilityIdsList.push(facility.value));
    }
    const breadcrumbs = [{name: "Device Alert Groups", link: "/device-alert-groups"}, {name: (match.params.id ? "Edit" : "Add") + " Group"}];
    return (
        <div className="alert-groups-add">
            <HeaderSimple
                breadcrumbs={breadcrumbs}
                hideGlobalSearch
            />
            <div className="subheader">
                <div className="subheader-title">{match.params.id ? "Edit" : "Add"} Device Alert Group</div>
            </div>
            {loader ? (
                <div className="loader-fullscreen">
                    <Loader />
                </div>
            ) : (
                <React.Fragment>
                    <div className="block">
                        <div className="block-body">
                            <GeneralInfo
                                data={data}
                                users={users}
                                validation={validation}
                                updateData={updateData}
                            />
                            <AlertSettings
                                data={data}
                                issues={issues}
                                validation={validation}
                                updateData={updateData}
                            />
                        </div>
                        {+data.type !== 0 && (
                            <div className="form-group invalid-facilities-feedback">
                                <ValidationError message={validation.gateways} />
                            </div>
                        )}
                        {data.type !== undefined && (
                            <div
                                style={{
                                    background: isDarkTheme === "true" ? "#36342a" : "#f0f0f0",
                                }}
                            >
                                <div className="block-body">
                                    <span className="form-title-group">Group Selection</span>

                                    {+data.type === 0 && (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        Facilities <span className="color-danger">*</span>
                                                    </label>
                                                    <Select
                                                        className={"form-control-select" + (validation.facilities ? " is-invalid" : "")}
                                                        isMulti
                                                        isSearchable={false}
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        menuPlacement="top"
                                                        styles={customStyles}
                                                        value={data.facilities || []}
                                                        options={[
                                                            {value: "*", label: "All Facilities"},
                                                            ...(structure.facilities || []).map((facility) => ({
                                                                value: facility.facility_id,
                                                                label: facility.name,
                                                            })),
                                                        ]}
                                                        onChange={(value, ev) => {
                                                            if (ev.action === "select-option") {
                                                                if (
                                                                    (ev.option || {}).value === "*" ||
                                                                    value.length === (structure.facilities || []).length
                                                                )
                                                                    value = [{value: "*", label: "All Facilities"}];
                                                                else value = value.filter((item) => item.value !== "*");
                                                            }
                                                            updateData("facilities", value);
                                                        }}
                                                    />
                                                    <ValidationError message={validation.facilities} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {+data.type === 1 && !!_find(entityList, (entity) => (data[entity.name] || []).length) && (
                                        <div
                                            style={{position: "absolute", top: 60, right: 25, cursor: "pointer"}}
                                            onClick={() => updateData("type", data.type)}
                                        >
                                            <span className="badge badge-danger">Clear</span>
                                        </div>
                                    )}
                                    {!!facilityIdsList.length && (
                                        <div className="alert-groups-list alert-groups-list--device-group">
                                            {entityList.map((entity) => (
                                                <MultiSelect
                                                    key={entity.name}
                                                    entity={entity}
                                                    data={data}
                                                    issues={issues}
                                                    search={search}
                                                    current={current}
                                                    structure={structure}
                                                    validation={validation}
                                                    facilityIdsList={facilityIdsList}
                                                    updateCurrent={updateCurrent}
                                                    updateSearch={updateSearch}
                                                    updateData={updateData}
                                                    updateAll={updateAll}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="text-right">
                        <Link
                            to="/device-alert-groups"
                            className="btn btn-sm btn-secondary"
                        >
                            Cancel
                        </Link>
                        <Button
                            color="primary"
                            size="sm"
                            className="ml-2"
                            disabled={inProgress}
                            onClick={submit}
                        >
                            Save
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

DeviceAlertGroupsForm.propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
};

const typeList = [
    {value: 0, label: "All devices per facility", key: "facility"},
    {value: 1, label: "Custom", key: "custom"},
];

export default withGlobalStore(DeviceAlertGroupsForm);
