import {create} from "zustand";
import {immer} from "zustand/middleware/immer";

const useHighPassFilterSyncStore = create(
    immer((set) => ({
            highpassFilters: {},
            actions: {
                setHighPassFilter: (pointID, filterValue) => {
                    set( (state) => {
                        state.highpassFilters[+pointID] = +filterValue;
                    });
                },
                setHighPassFilterByAlert: (alert) => {
                    set((state) => {
                        state.highpassFilters[+alert.installationPointId] = +alert.reading_hpf;
                    });
                }
            },
        })
    )
);

export const useHighPassFilterSyncStoreByPoint = (pointID, pointData) => useHighPassFilterSyncStore((state) => state.highpassFilters[+pointID]) ?? pointData?.settings?.high_pass;
export const useHighPassFilterSyncStoreActions = () => useHighPassFilterSyncStore((state) => state.actions);
