import React, {Component} from "react";
import PropTypes from "prop-types";
import ApiRouters from "../../../api/router";
import ApiGateways from "../../../api/gateway";
import SearchInput from "../../shared/search-input";
import {Button} from "reactstrap";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {
    get as _get,
    set as _set
} from "lodash";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import SelectWrapper from "../../../helpers/select-wrapper";
import { HeaderSimple } from "../../../shared/header";
import Toast from "../../shared/toast";
import Helper from "../../../helpers/helper";
import ImageToolbar from "../../../widgets/ImageToolbar";
import {usePacketLossChartModal} from "../hooks/usePacketLossChartModal";
import {EQUIPMENT_TYPE_REPEATER} from "../../../constants/constants";
import SignalWarningTooltip from "../../../components/tooltips/SignalWarningTooltip";

class RouterView extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [],
            loader: true,
            router: null,
            globalSearchString: "",
            inProgress: false,
            gateways: [],
            clearingData: false,
            locationFacilityId: null
        };

        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    componentDidMount() {
        Promise.all([
            ApiRouters.view(this.props.match.params.serial),
            ApiGateways.list({query: {pagination: {page: 1, perpage: 100}}})
        ]).then(([{router}, {list}]) => {
            this.setState({
                loader: false,
                breadcrumbs: [{name: "Manage Repeaters", link: "/network/routers"}, {name: "Edit Repeater " + router.serial}],
                router,
                locationFacilityId: _get(router, "locationFacilityId"),
                gateways: list
            });
        });
    }

    handleSubmit() {
        this.setState({inProgress: true}, () => {
            const {router, clearingData} = this.state;
            if (clearingData) {
                _set(router, "location", "");
            }

            ApiRouters.update(router.serial, router).then(() => {
                if (clearingData && !Helper.hasOnlyDefaultImage(_get(router, "photos", []))) {
                    this.detachAllImages();
                }

                Toast.success("The repeater has been updated.");
                this.setState({
                    inProgress: false,
                    clearingData: false,
                    locationFacilityId: _get(router, "locationFacilityId"),
                });
            });
        });
    }

    onChange(event) {
        const router = {...this.state.router};
        const {locationFacilityId, clearingData} = {...this.state};

        const isClearingData = (_get(event, "name") === "location_id" || clearingData)
            ? (+locationFacilityId !== +event.target.facilityId)
            : false;
        router[event.target.name] = event.target.value;

        this.setState({
            clearingData: isClearingData,
            router
        });
    }

    attachImages = images => {
        const router = {...this.state.router};

        ApiRouters.attachImages(router.serial, {images}).then(response => {
            if ((response.loaded || {}).images) {
                router.photos = response.loaded.images;
                this.setState({
                    router
                });
            }
        });
    };

    detachImage = image => {
        const router = {...this.state.router};

        ApiRouters.detachImage(router.serial, {id: (image || {}).id}).then(response => {
            router.photos = response.images || [];
            this.setState({
                router
            });
        });
    };

    detachAllImages() {
        const router = {...this.state.router};

        ApiRouters.detachAllImages(router.serial).then(response => {
            _set(router, "photos", response.images || []);
            this.setState({router});
        });
    };

    onFlipImage = (imageIndex, flipTurn) => {
        const imageId = _get(this.state.router, ["photos", imageIndex, "id"], 0);
        ApiRouters.onFlipImage({id: imageId, flipTurn: flipTurn});
    };

    render() {
        const {breadcrumbs, loader, router, gateways, inProgress, globalSearchString, clearingData} = this.state;
        const {auth, user} = this.props;
        const hasOnlyDefaultImage = Helper.hasOnlyDefaultImage(_get(router, "photos", []));

        return (
            <div className="container-fluid">
                 <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                {loader ? <div/> :
                    <React.Fragment>
                        <div className="subheader">
                            <div className="subheader-title">Edit Repeater {router.serial}</div>
                        </div>
                        <div className="block">
                            <div className="block-body gateway-edit">
                                <div className="row">
                                    <div className="col-md-3">
                                        {clearingData &&
                                            <div className="alert alert-warning" role="alert">
                                                <span>Note that the location and all photos will be removed when you change the facility.</span>
                                            </div>
                                        }

                                        <ImageToolbar
                                            title={"Add Repeater Photo"}
                                            images={router.photos}
                                            onUploadImages={this.attachImages}
                                            onDelete={this.detachImage}
                                            withDropzone={auth.userCan("editRouters")}
                                            canEdit={!hasOnlyDefaultImage && auth.userCan("editRouters")}
                                            onFlipTurnImage={this.onFlipImage}
                                        />
                                    </div>
                                    <div className="col-md-1"/>
                                    <div className="col-md-8">
                                        <table className={"table border-0"}>
                                            <tbody>
                                                <tr>
                                                    <td><strong>Serial Number: </strong></td>
                                                    <td>{router.serial}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Radio Address: </strong></td>
                                                    <td>{router.radio_address}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Gateway: </strong></td>
                                                    <td>
                                                        {auth.userCan("editRouters") ?
                                                            <SelectWrapper
                                                                name="gateway_id"
                                                                value={+router.gateway_id}
                                                                onChange={this.onChange}
                                                                disabled={inProgress}
                                                            >
                                                                {gateways.map(gateway => (
                                                                    <option key={gateway.gateway_id} value={gateway.gateway_id}>{gateway.name ? gateway.name + " (" + gateway.serial + ")" : gateway.serial}</option>
                                                                ))}
                                                            </SelectWrapper>
                                                            : <React.Fragment>
                                                                {router.gateway ? (router.gateway.name ? router.gateway.name + " (" + router.gateway.serial + ")" : router.gateway.serial) : "---"}
                                                            </React.Fragment>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Location: </strong></td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={router.location || ""}
                                                            name="location"
                                                            onChange={this.onChange}
                                                            className="form-control"
                                                            readOnly={!auth.userCan("editRouters")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Asset Tree Branch: </strong></td>
                                                    <td>
                                                        <CollapseLocationSelect
                                                            className={"form-control"}
                                                            selectName={"location_id"}
                                                            value={+_get(router, "location_id", null)}
                                                            onChange={this.onChange}
                                                            needMarkFacility={true}
                                                            emptyOptionLabel={"Select Location"}
                                                            allowedLocationIds={_get(user, "allowedLocationIds", [])}
                                                            disabled={!auth.userCan("editRouters")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Signal Charts: </strong></td>
                                                    <td>
                                                        <PacketLoss router={router}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Last Seen: </strong></td>
                                                    <td>{_get(router, "last_seen")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
                <div className="text-right">
                    {auth.userCan("editRouters") &&
                        <Button color="primary" size="sm" className="ml-2" disabled={this.state.inProgress} onClick={this.handleSubmit}>Update</Button>
                    }
                </div>
            </div>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item global-search">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

const PacketLoss = ({router}) => {
    const [showModal] = usePacketLossChartModal(router.serial, EQUIPMENT_TYPE_REPEATER, "Signal Charts");

    return (
        <>
            <button
                className="btn btn-success btn-elevate btn-circle btn-icon"
                title={"Show Signal Charts"}
                onClick={showModal}
            >
                <i className="flaticon2-chart"/>
            </button>
            <SignalWarningTooltip
                packetLossPercent={_get(router, "packetLossPercent")}
                signalStrength={_get(router, "signalStrength")}
                tooltipText={"This repeater has marginal connectivity and might degrade the overall network performance."}
            />
        </>
    );
};

PacketLoss.propTypes = {
    router: PropTypes.object,
};

RouterView.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    auth: PropTypes.object,
    user: PropTypes.object
};

export default withGlobalStore(RouterView);