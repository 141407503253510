import UserApi from "../../../api/user";
import { useQuery } from "@tanstack/react-query";

export const profileUserSelector = (data) => data.user;
export const profileCustomerSelector = (data) => data.customer;

const useUserProfile = (selector = profileUserSelector) => {
    const { isLoading, isSuccess, error, data } = useQuery(["UserProfileQuery"], () =>
        UserApi.profile((response) => response.data),
        {
            select: selector
        }
    );

    return {
        isLoading,
        isSuccess,
        error,
        data
    };
};

export default useUserProfile;
