import React, {Component, useState, useEffect} from "react";
import PropTypes from "prop-types";
import {get as _get, set as _set, sumBy as _sumBy, filter as _filter, orderBy as _orderBy, forEach as _forEach} from "lodash";
import EquipmentTable from "./equipment-list-table";

import "../../assets/scss/components/collapse-icon-tree/collapse-icon-tree.scss";

class EquipmentLocationTree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            treeData: [],
            collapsed: true,
            filter: {
                field: ""
            }
        };
    }
    indexEquipments = () => {
        const {list} = this.props;
        let indexedEquipmentsList = {};

        if (list) {
            Object.keys(list).map(level => {
                _get(list, [level, "equipments"], []).map(equipment => {
                    if (!_get(indexedEquipmentsList, equipment.location_id, false)) {
                        indexedEquipmentsList[equipment.location_id] = {};
                    }
                    if (!_get(indexedEquipmentsList, [equipment.location_id, level], false)) {
                        indexedEquipmentsList[equipment.location_id][level] = {
                            level: list[level].level,
                            equipments: []
                        };
                    }
                    indexedEquipmentsList[equipment.location_id][level]["equipments"].push(equipment);
                });
            });
        }

        return this.listForLocation(this.props.locations, indexedEquipmentsList);
    };

    getCountEquipmentsInLocation = location => {
        return _sumBy((Object.keys(location.equipments) || []).map(key => {
            return _get(location.equipments, [key, "equipments"], []).length;
        }));
    };

    listForLocation = (locations, equipments) => {
        return locations.map(location => {
            if (_get(location, "children", []).length) {
                location["children"] = this.listForLocation(_get(location, "children"), equipments);
            }
            location["equipments"] = _get(equipments, [location.id], {});

            return location;
        });
    };

    componentDidMount() {
        this.setState({treeData: this.indexEquipments()});
    }
    toggleCollapse = () => {
        this.setState({collapsed: !this.state.collapsed});
    };

    handleSort = (field, sort, location) => {
        let {filter} = {...this.state};
        _set(filter, [location, "sort"], {
            sort,
            field
        });

        this.setState({
            filter: {
                field: field,
                ...this.state.filter,
                ...filter
            }
        });
    };

    render() {
        let {filter, locationLabelList, resultCount, history, opened, handleOpen, auth} = this.props;
        const {treeData, collapsed} = this.state;
        filter = {
            ...filter,
            ...this.state.filter
        };

        return (
            <div className={"col-md-12"}>
                <button onClick={this.toggleCollapse} style={{marginBottom: "15px"}} className="btn btn-sm btn-primary">
                    <i className={`fa fa-angle-double-${collapsed ? "up" : "down"}`}/> {collapsed ? "Collapse" : "Expand"} all
                </button>
                <div className="block">
                    <div className="block-body">
                        <EquipmentsTreeContainer
                            countEquipments={this.getCountEquipmentsInLocation}
                            handleOpen={handleOpen}
                            handleSort={this.handleSort}
                            opened={opened}
                            history={history}
                            collapsedTree={collapsed}
                            list={treeData}
                            filter={filter}
                            locationLabelList={locationLabelList}
                            resultCount={resultCount}
                            auth={auth}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

EquipmentLocationTree.propTypes = {
    list: PropTypes.object,
    history: PropTypes.object,
    filter: PropTypes.object,
    resultCount: PropTypes.number,
    locations: PropTypes.array,
    opened: PropTypes.any,
    handleOpen: PropTypes.func,
    handleSort: PropTypes.func,
    locationLabelList: PropTypes.array,
    auth: PropTypes.object
};

const EquipmentsTreeContainer = ({list, locationLabelList, filter, history, handleOpen, handleSort, opened, countEquipments, parent = null, collapsedTree, auth}) => {
    return (
        (list || []).map(location => {

            let count = countEquipments(location);
            let unblocked = true;
            let activeLocationClass = "";
            let activeLevelClass = "";
            let icon = "bars";
            let [collapsed, setCollapsed] = useState(collapsedTree);
            const [expanded, setExpanded] = useState(collapsedTree);
            let tmpList = location.equipments || {};


            if (filter.location) {
                unblocked = false;
                if (+filter.location === +location.id) {
                    parent = location;
                    unblocked = true;
                }
                if (parent) {
                    if (location.left >= parent.left && location.right <= parent.right) {
                        unblocked = true;
                        activeLocationClass = "color-primary";
                    }
                }
            }

            if (+location.level === 1) {
                icon = "home";
            }

            if (filter.level) {
                tmpList = _filter(location.equipments, (item = {}) => (item.level || {}).name === filter.level);
                count = (_get(tmpList, [0, "equipments"], [])).length;
                if (count > 0) {
                    activeLevelClass = `color-level-${filter.level.toLowerCase()}`;
                }
            }

            if (_get(filter, [location.id, "sort", "field"], false) && _get(filter, [location.id, "sort", "field"], false) !== "level") {
                const sort = _get(filter, [location.id, "sort"], {});
                _forEach(tmpList, (value) => {
                    value["equipments"] = [..._orderBy(value.equipments, sort.field, sort.sort)];
                    return value;
                });
            }

            useEffect(() => {
                setCollapsed(collapsedTree);
                setExpanded(collapsedTree || +location.level === 1);
            },[collapsedTree]);

            return (
                <div key={location.id} className="col-md-12 equipment-tree-container">
                    {location.children.length > 0 &&
                    <span onClick={() => setExpanded(!expanded)} className={"collapse-tree-button"} data-toggle={(!unblocked) ? "disabled" : ""} aria-expanded={expanded}>
                        <i className="fa equipment-locations-tree-icon"/>
                    </span>
                    }
                    <div className={"location-head-name"}>
                        <i className={`mr-2 fa fa-${icon}`}/>
                        {location.name}
                        {count > 0 &&
                        <i
                            onClick={() => setCollapsed(!collapsed)}
                            className={`fa fa-arrow-circle-${collapsed ? "up" : "down"} cursor-pointer ml-2 ${activeLocationClass} ${activeLevelClass}`}
                        />
                        }
                    </div>
                    <hr/>
                    <div className={`location-equipment-list collapse ${collapsed && count > 0 ? "show" : ""}`} id={`equipment-collapse-${location.id}`}>
                        {Object.keys(_get(location, "equipments", [])).length > 0 &&
                            <EquipmentTable
                                pageLength={count}
                                handleOpen={handleOpen}
                                handleSort={(field, sort) => handleSort(field, sort, location.id)}
                                opened={opened}
                                sortable={true}
                                history={history}
                                list={tmpList}
                                serversort={false}
                                filter={_get(filter, location.id, {sort: {}})}
                                locationLabelList={locationLabelList}
                                resultCount={count}
                                auth={auth}
                            />
                        }
                    </div>
                    <div className={`tree-data collapse ${(expanded) ? "show" : ""}`} id={`collapse-location-${location.id}`}>
                        {location.children.length > 0 &&
                            <EquipmentsTreeContainer
                                collapsedTree={collapsedTree}
                                parent={parent}
                                countEquipments={countEquipments}
                                handleOpen={handleOpen}
                                handleSort={handleSort}
                                opened={opened}
                                history={history}
                                list={location.children}
                                filter={filter}
                                locationLabelList={locationLabelList}
                                resultCount={count}
                                auth={auth}
                            />
                        }
                    </div>
                </div>
            );
        })
    );
};
EquipmentsTreeContainer.propTypes = {
    filter: PropTypes.object,
    locationLabelList: PropTypes.array,
    history: PropTypes.object,
    list: PropTypes.array,
    resultCount: PropTypes.number,
    opened: PropTypes.any,
    handleOpen: PropTypes.func,
    handleSort: PropTypes.func,
    countEquipments: PropTypes.func,
    parent: PropTypes.any,
    collapsedTree: PropTypes.bool
};


export default EquipmentLocationTree;