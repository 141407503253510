import React from "react";
import PropTypes from "prop-types";
const AlertTabs = ({tabConfig, onTabChange, value}) => {

    return (
        <div className="axis-block-container mb-3 w-100">
            {tabConfig.map((item) => (
                <div
                    key={item.key}
                    className={"axis-block axis-block-manage " + (item.key === value ? "active" : "")}
                    onClick={() => onTabChange(item.key)}
                >
                    <span>{item.title}</span>
                </div>
            ))}
        </div>
    );
};
AlertTabs.propTypes = {
    tabConfig: PropTypes.object,
    onTabChange: PropTypes.func,
    value: PropTypes.string,
};
export default AlertTabs;
