import React from "react";
import PropTypes from "prop-types";

import "./pager.scss";
import { usePagerPagination } from "./hooks/usePagerPagination";
import { usePagerRange } from "./hooks/usePagerRange";

const Pager = (props) => {
    const { page, pages, total, perPage, onPagerChange } = props;

    const pagerList = usePagerPagination(page, pages, onPagerChange);
    const { from, to } = usePagerRange(page, perPage, total);

    return (
        <div className={"pager"}>
            <div className={"pager-wrapper"}>
                <ul className={"pager-nav"}>{pagerList}</ul>
                {total && total > 0 && total > perPage && (
                    <div className={"pager-info"}>
                        <span
                            className={"pager-detail"}
                        >{`Displaying ${from} - ${to} of ${total} records`}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

Pager.propTypes = {
    page: PropTypes.number,
    pages: PropTypes.number,
    total: PropTypes.number,
    perPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onPagerChange: PropTypes.func,
};

export default Pager;
