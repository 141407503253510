import {useMutation, useQueryClient} from "@tanstack/react-query";
import Reading from "../../../api/reading";
import {FFT_TIMESTAMPS_API} from "./constants";

export const useDeleteFftReadingMutation = (readingId, equipmentID, isImpactVue = false) => {
    const queryClient = useQueryClient();

    return useMutation(({installationPointId, readingId}) => Reading.deleteChartReading(installationPointId, readingId, isImpactVue), {
        onSuccess: () => {
            queryClient.refetchQueries([FFT_TIMESTAMPS_API.FFT_TIMESTAMPS_EQUIPMENT, +equipmentID]);
            queryClient.refetchQueries([FFT_TIMESTAMPS_API.FFT_READING, readingId, isImpactVue]);
        },
    });
};
