import {ValidationError} from "../../../../shared";
import React from "react";
import PropTypes from "prop-types";
import {get as _get} from "lodash";

const NameField = ({value, formErrors, onChange}) => {
    return (
        <>
            <label>Installation Point Name</label>
            <input
                name={"name"}
                onChange={onChange}
                value={value}
                placeholder="Enter Point Name"
                className={"form-control" + (_get(formErrors, "name") ? " is-invalid" : "")}
            />
            <ValidationError additionalClass="mb-5" message={_get(formErrors, "name")}/>
        </>
    );
};

NameField.propTypes = {
    value: PropTypes.string,
    formErrors: PropTypes.object,
    onChange: PropTypes.func
};

export default NameField;