import { useContext } from "react";
import { ChartRefContext } from "../context/context";

export const useChartRef = () => {
    const chartRefContext = useContext(ChartRefContext);
    if (!chartRefContext) {
        throw new Error("Lose chart ref context");
    }
    return chartRefContext;
};
