import React from "react";
import PropTypes from "prop-types";
import {useAlertConditionListQuery} from "../../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {useInstallationPointIds} from "../../../../hooks/helpers/useInstallationPointIds";
import {get as _get} from "lodash";
import {useAlertConditionStoreActions, useCurrentAlertGroup} from "../../../../stores/zustand/AlertConditionStore";
import AlertSensorMultipleSelect from "../../shared/alertSensorMultipleSelect";
import {useChartComputed} from "../../hooks/helpers/chart";
import AlertGroupManagement from "../../shared/alertGroupManagement";
import AlertBody from "../../shared/alertBody";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import useAxisLabelQuery from "../../../../hooks/api/axisLabel/useAxisLabelQuery";

const DeltaTab = ({chartTypes}) => {
    const { equipmentItem: equipment } = useEquipmentByParams();
    const { axisLabelsChart: axisLabels } = useAxisLabelQuery(equipment.id);
    const installationPointIds = useInstallationPointIds(equipment);
    const {axisId, chartType} = useChartComputed(chartTypes, equipment);
    const {alertConditionsGroup: alertGroups} = useAlertConditionListQuery(chartType, installationPointIds);
    const {setCurrentAlertGroup} = useAlertConditionStoreActions();
    const currentGroup = useCurrentAlertGroup();
    // eslint-disable-next-line no-unused-vars
    const changeAlertGroup = (groupID) => {
        if (groupID === currentGroup.id) {
            return;
        }
        const currentGroupLocal = _get(alertGroups, [axisId, groupID], {points: [], id: false, names: []});
        setCurrentAlertGroup(currentGroupLocal);
    };
    // eslint-disable-next-line no-unused-vars
    const removeBadge = (id) => {
        // dispatch({deleteId: id});
        // tryRemoveCurrent();
    };

    return (
        <>
            <AlertGroupManagement
                equipment={equipment}
                chartTypes={chartTypes}
            />
            <AlertSensorMultipleSelect
                equipment={equipment}
                chartTypes={chartTypes}
            />
            <AlertBody
                chartType={chartType}
                equipment={equipment}
                axisLabels={axisLabels}
            />
        </>
    );
};

DeltaTab.propTypes = {
    chartTypes: PropTypes.array,
    equipment: PropTypes.object,
    axisLabels: PropTypes.object,
};

export default DeltaTab;
