import Api from "./api";

const Alarm = {
    getList: (params) => {
        return Api.get("alarms", {...params});
    },

    export: (params) => {
        return Api.get("alarms/export", {...params});
    },

    getHistoryList: (params) => {
        return Api.get("alarms/history", {...params});
    },

    getCount: query => Api.get("alarms/count", {query}),

    ignore: (data) => {
        return Api.post("alarms/ignore", data);
    },

    acknowledge: (data) => {
        return Api.post("alarms/acknowledge", data);
    },

    getAlert: id => Api.get(`alarms/${id}`),

    editNote: (id, data) => {
        return Api.post(`alarms/${id}/note`, data);
    },
};

export default Alarm;
