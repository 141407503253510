import React, {useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import SpeedNotSetLabel from "../../../../components/speedNotSetLabel";
import {BEARING_COLORS, BEARING_TYPES} from "../../../../../../constants/constants";
import auth from "../../../../../../services/auth";
import {get as _get, findIndex as _findIndex} from "lodash";
import {useBearingModal} from "../../../../../../modals/bearing/Hooks/UseBearingModal";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {usePointData} from "../../../hooks/usePointsData";
import { useSpeedSettings } from "../../hooks/helpers/useSpeedSettings";
import {useSetSpeedModal} from "../../../../../../modals/installationPoint/hooks/useSetSpeedModal";

const BearingsButton = () => {
    const {equipmentItem} = useEquipmentByParams();
    const {currentSpeed} = useSpeedSettings();
    const {selectedPoint, currentSpeedFrom} = useChartStateStoreContext((state) => state.settings, shallow);

    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    const {toggleBearing, setSpeedModal} = useChartStateStoreContext((state) => state.actions, shallow);
    const activeBearings = useChartStateStoreContext((state) => state.settings.activeBearings, shallow);
    const [showModal] = useBearingModal();
    const [showSetSpeedModal] = useSetSpeedModal();

    const handleSetSpeedModal = (type) => {
        setSpeedModal({show: true, type});
        showSetSpeedModal();
    };

    const pointData = usePointData(selectedPoint);

    const onToggleBearing = (bearing, type) => {
        toggleBearing({name: bearing.name, type});
    };

    const bearings = _get(
        equipmentItem.installationPoints.filter((installationPoint) => installationPoint.id === +selectedPoint),
        "0.bearings",
        []
    );

    const [isOpen, setIsOpen] = useState(false);
    return (
        <Dropdown
            size="sm"
            className="bearing-dropdown"
            isOpen={isOpen}
            disabled={!!isLoading}
            toggle={() => setIsOpen((prevState) => !prevState)}
        >
            <DropdownToggle
                caret
                className="btn-default"
            >
                Bearing
            </DropdownToggle>
            <DropdownMenu className="bearing-list">
                {auth.userCan("editSelfBearings") && currentSpeed === 0 && !isLoading && (
                    <React.Fragment>
                        <DropdownItem>
                            <SpeedNotSetLabel
                                className="mx-auto my-0"
                                onClick={() => handleSetSpeedModal("manually")}
                                equipment={equipmentItem}
                                pointData={pointData}
                                currentSpeed={currentSpeed}
                                currentSpeedFrom={currentSpeedFrom}
                            />
                        </DropdownItem>
                        <DropdownItem divider />
                    </React.Fragment>
                )}
                {bearings?.length ? (
                    <React.Fragment>
                        {bearings.map((bearing, bearingIndex) => (
                            <div
                                className="bearing-item"
                                key={`bearing-${bearingIndex}`}
                            >
                                <DropdownItem header>
                                    {bearing.mfr} - {bearing.name}
                                </DropdownItem>
                                {BEARING_TYPES.map((bearingParam, bearingParamIndex) => {
                                    const isActive = _findIndex(activeBearings, {name: bearing.name, type: bearingParam}) !== -1 && currentSpeed > 0;
                                    return (
                                        <DropdownItem
                                            key={`${bearing.id}-${bearingParam}`}
                                            className={`bearing-dropdown-item${isActive ? " active-bearing" : ""}`}
                                            onClick={() => onToggleBearing(bearing, bearingParam)}
                                            toggle={false}
                                            disabled={currentSpeed === 0}
                                        >
                                            <i className={`fa fa-eye${isActive ? "" : "-slash"}`} />
                                            <span
                                                className="label"
                                                style={{
                                                    backgroundColor: _get(BEARING_COLORS, bearingIndex * 4 + bearingParamIndex, "grey"),
                                                }}
                                            >
                                                {bearingParam}
                                            </span>
                                            <span> - </span>
                                            <span>{bearing[bearingParam]}</span>
                                        </DropdownItem>
                                    );
                                })}
                            </div>
                        ))}
                    </React.Fragment>
                ) : (
                    <DropdownItem>
                        <i className="cursor-default">No Bearings Added</i>
                    </DropdownItem>
                )}
                {auth.userCan("editSelfBearings") && (
                    <React.Fragment>
                        <DropdownItem divider />
                        <DropdownItem onClick={showModal}>Manage Bearings...</DropdownItem>
                    </React.Fragment>
                )}
            </DropdownMenu>
        </Dropdown>
    );
};

export default BearingsButton;
