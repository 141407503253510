export const setDarkModeForChart = (chartConfig) => {
    chartConfig.chart.backgroundColor = {
        linearGradient: {x1: 0, y1: 0, x2: 1, y2: 1},
        stops: [
            [0, "#3a3934"],
            [1, "#36342a"],
        ],
    };
    chartConfig.tooltip.backgroundColor = "rgba(54, 54, 50, 0.9)";
    chartConfig.tooltip.style.color = "#ddd";
    chartConfig.yAxis[0].gridLineColor = "rgba(200,200,200,0.4)";
    chartConfig.yAxis[0].gridLineWidth = 0.5;
    return chartConfig;
};
