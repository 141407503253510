import React, {useState} from "react";
import "../../../assets/scss/components/installation-points/installation-points.scss";
import PropTypes from "prop-types";
import SensorsForm from "./sensorsForm";
import AdaptersForm from "./adaptersForm";
import EquipmentApi from "../../../api/equipment";
import EquipmentInstallationPointApi from "../../../api/equipmentInstallationPoint";
import Toast from "../../../pages/shared/toast";
import NameField from "../forms/parts/name";
import InstallationPointCustomTypeField from "../forms/parts/type";
import SensorField from "../forms/parts/sensor";
import {get as _get, setWith as _setWith, set as _set, omit as _omit, find as _find} from "lodash";
import {ValidationError} from "../../../shared";
import SelectWrapper from "../../../helpers/select-wrapper";
import UniversalAdapterApi from "../../../api/universalAdapter";
import WuaConfigModal from "../../../pages/network/wua-sensors/wuaConfigModal";
import {InputFormGroup} from "../../../shared/formParts/formParts";
import {Link} from "react-router-dom";
import HeatsinkField from "../forms/parts/heatsink";
import InfoTooltip from "../../../shared/infoTooltip/infoTooltip";

const InstallationPoints = ({
    installationPoints,
    equipmentId,
    afterUpdate,
    wuaSensorList,
    updateWuaSensorList,
    defaultActiveTab,
    defaultWithAddNew,
    defaultHideTab,
}) => {
    const [activeTab, setActiveTab] = useState(defaultActiveTab ?? "sensors");
    const [withAddNew, setWithAddNew] = useState(defaultWithAddNew ?? false);

    return (
        <div className={"inst-points-form"}>
            <div className={"d-flex justify-content-between align-items-center"}>
                {!defaultHideTab && (
                    <div className="axis-block-container my-4">
                        <div
                            className={"axis-block" + (activeTab === "sensors" ? " active" : "")}
                            onClick={() => setActiveTab("sensors")}
                        >
                            <span>Sensors</span>
                        </div>
                        <div
                            className={"axis-block" + (activeTab === "adaptors" ? " active" : "")}
                            onClick={() => setActiveTab("adaptors")}
                        >
                            <span>Adapters</span>
                        </div>
                    </div>
                )}
                <div>
                    {!withAddNew && (
                        <span
                            className="add-installation-point-btn"
                            onClick={() => setWithAddNew(true)}
                        >
                            <i className="fa fa-plus-circle"></i> Add new installation point
                        </span>
                    )}
                </div>
            </div>
            <div className="w-100 border inst-point-table">
                {activeTab === "sensors" ? <SensorHead /> : <AdapterHead updateWuaSensorList={updateWuaSensorList} />}
                {withAddNew && (
                    <div className={"px-4 py-3"}>
                        {activeTab === "sensors" ? (
                            <CreateSensorForm
                                equipmentId={equipmentId}
                                afterUpdate={() => afterUpdate().then(() => setWithAddNew(false))}
                                onCancel={() => setWithAddNew(false)}
                                installationPoints={installationPoints}
                            />
                        ) : (
                            <CreateAdapterForm
                                equipmentId={equipmentId}
                                afterUpdate={() => afterUpdate().then(() => setWithAddNew(false))}
                                onCancel={() => setWithAddNew(false)}
                                wuaSensorList={wuaSensorList}
                                installationPoints={installationPoints}
                            />
                        )}
                    </div>
                )}
                {(installationPoints || []).map((installationPoint, index) => (
                    <>
                        {activeTab === "sensors" ? (
                            <>
                                {installationPoint.point_type === "sensor" && (
                                    <div
                                        className={"px-4 py-3"}
                                        key={index}
                                    >
                                        <SensorsForm
                                            equipmentId={equipmentId}
                                            installationPoint={installationPoint}
                                            afterUpdate={afterUpdate}
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {installationPoint.point_type === "adapter" && (
                                    <div
                                        className={"px-4 py-3"}
                                        key={index}
                                    >
                                        <AdaptersForm
                                            equipmentId={equipmentId}
                                            installationPoint={installationPoint}
                                            afterUpdate={afterUpdate}
                                            wuaSensorList={wuaSensorList}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </>
                ))}
            </div>
        </div>
    );
};

const SensorHead = () => {
    return (
        <div className={"border p-0"}>
            <div className={"row point-header p-4"}>
                <div className={"col-3 font-weight-bold px-4"}>Installation Point Name</div>
                <div className={"col-3 font-weight-bold px-2"}>Installation Point Type</div>
                <div className={"col-3 font-weight-bold"}>Sensor HEX Code</div>
                <div className={"col-2 font-weight-bold"}>
                    Insulator{" "}
                    <InfoTooltip iconClass="blue-tooltip">
                        Select this option to indicate any sensors that are installed with an insulator, such as a heatsink or ceramic pad, to reduce heat.
                    </InfoTooltip>
                </div>
            </div>
        </div>
    );
};

const AdapterHead = ({updateWuaSensorList}) => {
    const [addNewConfiguration, setAddNewConfiguration] = useState(false);

    return (
        <div className={"border p-0"}>
            <div className={"row point-header p-4"}>
                <div className={"col-3 font-weight-bold px-4"}>Installation Point Name</div>
                <div className={"col-3 font-weight-bold px-4"}>
                    Configuration
                    <Link
                        to="/network/ua-configurations/add"
                        className="add-installation-point-btn ml-1"
                        title={"Add new universal adapter configuration"}
                        target="_blank"
                    >
                        <i className="fa fa-plus-circle" />
                    </Link>
                </div>
                <div className={"col-3 font-weight-bold px-2"}>Installation Point Type</div>
                <div className={"col-3 font-weight-bold"}>Adapter HEX Code</div>
            </div>
            {addNewConfiguration && (
                <WuaConfigModal
                    updateWuaSensorList={updateWuaSensorList}
                    onClose={() => setAddNewConfiguration(false)}
                />
            )}
        </div>
    );
};

AdapterHead.propTypes = {
    updateWuaSensorList: PropTypes.func,
};

const CreateSensorForm = ({equipmentId, onCancel, afterUpdate, installationPoints}) => {
    const [point, setPoint] = useState({
        name: "",
        installation_point_custom_type_id: "",
        sensor: {
            sensor_hex: "",
            confirmC1D1: false,
            confirmGatewayNotAssociated: false,
            duplicatedWay: null,
        },
    });
    const [inProgress, setInProgress] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const onChange = (event) => {
        let newPoint = {...point};

        const key = _get(event, "target.name", "");
        const val = _get(event, "target.value", "");

        _setWith(newPoint, key, val, Object);

        removeError(key);
        setPoint(newPoint);
    };

    const removeError = (key) => {
        const oldFormErrors = {...formErrors};

        const newFormErrors = _omit(oldFormErrors, key);

        setFormErrors(newFormErrors);
    };

    const onUpdate = () => {
        if ((installationPoints || []).some((item) => item.name === point.name)) {
            Toast.error("The installation point with this name already exists.");
            return;
        }

        setInProgress(true);
        EquipmentApi.checkService(equipmentId).then(() =>
            EquipmentInstallationPointApi.create(equipmentId, point)
                .then((response) => {
                    if (response.status === "ok") {
                        afterUpdate().then(() => {
                            Toast.success("The installation point has been created.");
                            setInProgress(false);
                            onCancel();
                        });
                    }
                })
                .catch((response) => {
                    setFormErrors(response.errors || {});
                    setInProgress(false);
                })
        );
    };

    return (
        <div className={"row align-items-center"}>
            <div className={"col-3"}>
                <NameField
                    value={_get(point, "name")}
                    onChange={onChange}
                    formErrors={formErrors}
                />
            </div>
            <div className={"col-3"}>
                <InstallationPointCustomTypeField
                    value={_get(point, "installation_point_custom_type_id")}
                    onChange={onChange}
                />
            </div>
            <div className={"col-3 px-2"}>
                <SensorField
                    sensor={_get(point, "sensor", {})}
                    formErrors={formErrors}
                    onChange={onChange}
                />
            </div>
            <div className={"col-2 px-2"}>
                <HeatsinkField
                    value={_get(point, "is_heatsink", 0) || 0}
                    withLabel={false}
                    onChange={onChange}
                />
            </div>
            <div className={"col-1"}>
                {!inProgress && (
                    <>
                        <img
                            className={"action-btn mr-4"}
                            src={"/assets/img/check.svg"}
                            onClick={onUpdate}
                        />
                        <img
                            className={"action-btn"}
                            src={"/assets/img/close.svg"}
                            onClick={onCancel}
                            title="Cancel"
                        />
                    </>
                )}
            </div>
        </div>
    );
};

CreateSensorForm.propTypes = {
    installationPoint: PropTypes.object,
    equipmentId: PropTypes.number,
    onCancel: PropTypes.func,
    afterUpdate: PropTypes.func,
    installationPoints: PropTypes.array,
};

const CreateAdapterForm = ({equipmentId, onCancel, afterUpdate, wuaSensorList, installationPoints}) => {
    const [adapter, setAdapter] = useState({
        name: "",
        sensor_id: "",
        installation_point_name: "",
        installation_point_custom_type_id: "",
        equipment_id: equipmentId,
        parameters: [
            {
                wua_sensor_id: "",
                sensor_position: 0,
                wua_interval: 1,
                wua_interval_type: 3,
            },
        ],
    });
    const [inProgress, setInProgress] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const changeAdapterData = (event) => {
        const key = _get(event, "target.name");
        const value = _get(event, "target.value");

        updateAdapterData(key, value);
    };

    const updateAdapterData = (key, value) => {
        let newAdapter = {...adapter};

        _set(newAdapter, key, value);

        setAdapter(newAdapter);
        removeError(key);
    };

    const removeError = (key) => {
        const oldFormErrors = {...formErrors};

        const newFormErrors = _omit(oldFormErrors, key);

        setFormErrors(newFormErrors);
    };

    const onCreate = () => {
        if ((installationPoints || []).some((item) => item.name === adapter.installation_point_name)) {
            Toast.error("The installation point with this name already exists.");
            return;
        }

        setInProgress(true);

        UniversalAdapterApi.createSingle(adapter)
            .then((response) => {
                if (response.status === "ok") {
                    afterUpdate().then(() => {
                        setInProgress(false);
                        Toast.success("The installation point has been created.");
                    });
                }
            })
            .catch((response) => {
                if (response.status === 422) {
                    setInProgress(false);
                    setFormErrors(response.errors || {});
                }
            });
    };

    const currentWuaSensor = _find(wuaSensorList, (wuaSensor) => +wuaSensor.id === +_get(adapter, "parameters.0.wua_sensor_id"));

    return (
        <>
            <div className={"row align-items-center"}>
                <div className={"col-3"}>
                    <input
                        className={"form-control" + (_get(formErrors, "installation_point_name") ? " is-invalid" : "")}
                        name={"installation_point_name"}
                        value={adapter.installation_point_name}
                        onChange={changeAdapterData}
                    />
                    <ValidationError message={_get(formErrors, "installation_point_name")} />
                </div>
                <div className={"col-3"}>
                    <SelectWrapper
                        className={_get(formErrors, "parameters[0].wua_sensor_id") ? " is-invalid" : ""}
                        name={"parameters[0].wua_sensor_id"}
                        value={_get(adapter, "parameters.0.wua_sensor_id")}
                        onChange={changeAdapterData}
                    >
                        {(wuaSensorList || []).length && <option value={""}>Select Configuration</option>}
                        {(wuaSensorList || []).length ? (
                            wuaSensorList.map((wuaSensor, index) => (
                                <option
                                    key={index}
                                    value={wuaSensor.id}
                                >
                                    {wuaSensor.name}
                                </option>
                            ))
                        ) : (
                            <option value={""}>No Configurations</option>
                        )}
                    </SelectWrapper>
                    <ValidationError message={_get(formErrors, "parameters[0].wua_sensor_id")} />
                </div>
                <div className={"col-3"}>
                    <InstallationPointCustomTypeField
                        value={_get(adapter, "installation_point_custom_type_id")}
                        onChange={changeAdapterData}
                    />
                </div>
                <div className={"col-2 px-2"}>
                    <input
                        className={"form-control" + (formErrors.sensor_id ? " is-invalid" : "")}
                        name={"sensor_id"}
                        value={adapter.sensor_id}
                        onChange={changeAdapterData}
                    />
                    <ValidationError message={formErrors.sensor_id} />
                </div>
                <div className={"col-1"}>
                    {!inProgress && (
                        <>
                            <img
                                className={"action-btn mr-4"}
                                src={"/assets/img/check.svg"}
                                onClick={onCreate}
                            />
                            <img
                                className={"action-btn"}
                                src={"/assets/img/close.svg"}
                                onClick={onCancel}
                                title="Cancel"
                            />
                        </>
                    )}
                </div>
            </div>
            {currentWuaSensor && (
                <div className={"row point-info wua-info p-2 py-4"}>
                    <div className={"col-md-12"}>
                        <InputFormGroup
                            label={"Configuration Name"}
                            value={_get(currentWuaSensor, "name")}
                            disabled={true}
                        />
                    </div>

                    <div className={"col-md-12"}>
                        <InputFormGroup
                            label={"Input Value Type"}
                            value={_get(currentWuaSensor, "valueType.name")}
                            disabled={true}
                        />
                    </div>

                    {_get(currentWuaSensor, "valueType.value_key") !== "temperature" && (
                        <>
                            <div className={"col-md-6"}>
                                <InputFormGroup
                                    label={"Minimum Input Value"}
                                    value={_get(currentWuaSensor, "min_voltage")}
                                    disabled={true}
                                />
                            </div>
                            <div className={"col-md-6"}>
                                <InputFormGroup
                                    label={"Maximum Input Value"}
                                    value={_get(currentWuaSensor, "max_voltage")}
                                    disabled={true}
                                />
                            </div>
                        </>
                    )}

                    <div className={"col-md-12"}>
                        <InputFormGroup
                            label={"Process Variable"}
                            value={_get(currentWuaSensor, "readingType.name") + " (" + _get(currentWuaSensor, "readingType.unit.unit") + ")"}
                            disabled={true}
                        />
                    </div>

                    {_get(currentWuaSensor, "valueType.value_key") !== "temperature" && (
                        <>
                            <div className={"col-md-6"}>
                                <InputFormGroup
                                    label={"Process Variable Minimum Value"}
                                    value={_get(currentWuaSensor, "min_sensor_value")}
                                    disabled={true}
                                />
                            </div>
                            <div className={"col-md-6"}>
                                <InputFormGroup
                                    label={"Process Variable Maximum Value"}
                                    value={_get(currentWuaSensor, "max_sensor_value")}
                                    disabled={true}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

CreateAdapterForm.propTypes = {
    installationPoint: PropTypes.object,
    equipmentId: PropTypes.number,
    onCancel: PropTypes.func,
    afterUpdate: PropTypes.func,
    wuaSensorList: PropTypes.array,
    installationPoints: PropTypes.array,
};

InstallationPoints.propTypes = {
    equipmentId: PropTypes.number,
    installationPoints: PropTypes.array,
    afterUpdate: PropTypes.func,
    wuaSensorList: PropTypes.array,
    updateWuaSensorList: PropTypes.func,
    defaultActiveTab: PropTypes.string,
    defaultWithAddNew: PropTypes.bool,
    defaultHideTab: PropTypes.bool,
};

export default InstallationPoints;
