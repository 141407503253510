import React, {Component, createContext} from "react";
import InstallationPointCustomTypeApi from "../../../api/installationPointCustomType";
import {
    get as _get,
    orderBy as _orderBy
} from "lodash";
import PropTypes from "prop-types";

const InstallationPointCustomTypeStoreContext = createContext();

class InstallationPointCustomTypeStore extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            types: [],
            inProgress: false
        };
    }

    componentDidMount() {
        this.fetch();
    }

    fetch = () => {
        return InstallationPointCustomTypeApi
            .list()
            .then(response => {
                const list = _get(response, "list");

                this.setState({
                    inProgress: false,
                    types: this.transformList(list)
                });
            });
    }

    transformList = (list) => {
        const transformedList = (list || []).map(type => {
            return this.transformType(type);
        });

        return transformedList || [];
    }

    transformType = (type) => {
        return {
            label: _get(type, "name"),
            value: +_get(type, "id")
        };
    }

    create = (typeName) => {
        return InstallationPointCustomTypeApi
            .create({name: typeName})
            .then(response => {
                const type = _get(response, "result");

                if (type) {
                    this.add(this.transformType(type));
                }

                return _get(type, "id");
            });
    }

    add = (type) => {
        let updateTypes = [...this.state.types];

        updateTypes.push(type);

        this.setState({types: _orderBy(updateTypes, "label")});
    }

    render() {
        const {types} = this.state;
        const {children} = this.props;

        return (
            <InstallationPointCustomTypeStoreContext.Provider value={{
                installationPointCustomTypes: types,
                fetchInstallationPointCustomTypes: this.fetch,
                createInstallationPointCustomType: this.create
            }}>
                {children}
            </InstallationPointCustomTypeStoreContext.Provider>
        );
    }
}

function withInstallationPointCustomTypeStore(Component) {
    return function ConnectedComponent(props) {
        return (
            <InstallationPointCustomTypeStoreContext.Consumer>
                {context => <Component
                    {...props}
                    {...context}
                />}
            </InstallationPointCustomTypeStoreContext.Consumer>
        );
    };
}

InstallationPointCustomTypeStore.propTypes = {
    children: PropTypes.node,
};

export default InstallationPointCustomTypeStore;
export {withInstallationPointCustomTypeStore};