import React, {useEffect, useReducer} from "react";
import PropTypes from "prop-types";
import Helper from "../../../helpers/helper";
import {withRouter} from "react-router";
import Images from "./components/images/images";
import EquipmentRequestOnDemandApi from "../../../api/equipmentRequestOnDemand";
import ReadingTypesList from "./components/readingTypes/list";
import IntervalsModal from "../../../modals/intervals";
import BottomSettings from "./components/bottomSetting/bottomSetting";
import EditInstallationPointsModal from "../../../modules/installationPoint/modals/EditInstallationPointsModal";
import {ACTION, reducer} from "./reducer/mainReducer";
import "./aside.scss";
import {useChartSelectedEquipmentStore} from "../../../stores/zustand/ChartSelectedEquipmentStore";
import {useEquipmentInvalidate} from "../../../hooks/api/equipment/useEquipmentInvalidate";
import {useInstallPointListInvalidate} from "../../../hooks/api/installationPoints/useInstallPointListInvalidate";
import useUserProfile, {profileCustomerSelector} from "../../../hooks/api/Global/useUserProfile";
import {get as _get} from "lodash";

const Aside = (props) => {
    const initialState = {
        showSensorsEditModal: false,
        count: {
            alarms: (props.activeAlarms || []).length,
            actionItems: 0,
            requestOnDemand: 0,
        },
        isShow: false,
        images: [],
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    const {equipment, activeAlarms, user} = props;
    const {showSensorsEditModal, isShow} = state;
    const equipmentSelected = useChartSelectedEquipmentStore();

    const invalidateSimpleChartMarks = useInstallPointListInvalidate(equipment.id);
    const invalidateEquipment = useEquipmentInvalidate(equipment.id);
    const {data: customer} = useUserProfile(profileCustomerSelector);
    const isRouteCollector = Boolean(_get(equipment, "is_route_collector") && _get(customer, "is_ble"));

    const afterUpdate = async () => {
        await invalidateSimpleChartMarks();
        await invalidateEquipment();
    };

    useEffect(() => {
        EquipmentRequestOnDemandApi.count(props.equipment.id).then(({count: requestOnDemand}) => {
            dispatch({
                type: ACTION.SET_STATE,
                state: {
                    activeTab: state.activeTab,
                    count: Object.assign({}, state.count, {requestOnDemand}),
                },
            });
        });
    }, []);

    const toggleSensorsEditModal = (type) => {
        let {showSensorsEditModal} = state;
        showSensorsEditModal = showSensorsEditModal === "adapter" || showSensorsEditModal === "sensor" ? false : type;

        dispatch({type: ACTION.SET_STATE, state: {showSensorsEditModal}});
    };

    const handleIntervalsModal = () => {
        props.history.push(Helper.setHashParams({modal: "intervals"}));
    };

    const handleCloseModal = () => {
        props.history.push(Helper.deleteHashParams(["modal"]));
    };

    const toggleRightMenu = () => {
        dispatch({type: ACTION.SET_STATE, state: {isShow: !state.isShow}});
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 100);
    };

    const handleUpdateImages = (images) => {
        invalidateEquipment();
        dispatch({type: ACTION.SET_STATE, state: {images}});
    };

    const searchParams = Helper.getHashParams();

    return (
        <div style={{position: "relative"}}>
            <div
                className={"btn-hide-right" + (isShow ? " hide-right-absolute" : "")}
                onClick={toggleRightMenu}
            >
                <img
                    className="btn-menu-trigger"
                    src="/assets/pic/icon-menu-trigger.svg"
                    alt=""
                />
            </div>
            <div
                className={"k-aside-secondary k-aside-trigger" + (isShow ? " hide-right" : "")}
                id="k_aside_secondary"
                style={{paddingTop: "1rem"}}
            >
                <Images
                    equipment={equipment}
                    handleUpdateImages={handleUpdateImages}
                    equipmentSelected={equipmentSelected}
                />

                <div className="aside-content custom-scroll custom-scroll-aside">
                    <div className={"aside-content-item current"}>
                        <ReadingTypesList
                            toggleSensorsEditModal={toggleSensorsEditModal}
                            handleIntervalsModal={handleIntervalsModal}
                            instPointType={"sensor"}
                            activeAlarms={activeAlarms}
                            isRouteCollector={isRouteCollector}
                        />
                    </div>
                </div>
                <BottomSettings equipment={equipment} user={user} />
                {showSensorsEditModal && (
                    <EditInstallationPointsModal
                        equipment={equipment}
                        afterUpdate={afterUpdate}
                        onClose={toggleSensorsEditModal}
                        isRouteCollector={isRouteCollector}
                    />
                )}
                {(searchParams.modal === "intervals" && !isRouteCollector) && (
                    <IntervalsModal
                        showModal={true}
                        onClose={handleCloseModal}
                        equipment={equipment}
                        invalidateEquipment={invalidateEquipment}
                    />
                )}
            </div>
        </div>
    );
};

Aside.propTypes = {
    history: PropTypes.object,
    equipment: PropTypes.object,
    activeAlarms: PropTypes.array,
    user: PropTypes.object,
    isShow: PropTypes.bool,
};

export default withRouter(Aside);
