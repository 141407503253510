import Api from "./api";
import {get as _get} from "lodash";

const UserSubscriptions = {
    list: query => Api.get("user-subscriptions", {query}),
    forUser: userId => {
        return UserSubscriptions
            .list({filter: {user: userId}})
            .then((response) => {
                return _get(response, "list.0");
            });
    }
};

export default UserSubscriptions;