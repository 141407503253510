import React, {useEffect, useReducer, useRef} from "react";
import ApiEquipment from "../../../api/equipment";
import {Modal} from "../../../shared";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import Dropzone from "react-dropzone";
import Helper from "../../../helpers/helper";
import SweetAlert from "react-bootstrap-sweetalert";
import {Button, FormGroup} from "reactstrap";
import PropTypes from "prop-types";
import {setWith as _setWith, findKey as _findKey, find as _find, get as _get, has as _has, unset as _unset} from "lodash";
import {toNextImage, toPrevImage} from "./helpers/image-navigation";
import {getInstallPointPopupVisibility, setInstallPointPopupVisibility} from "./services/install-point-popup";
import ReactCrop from "react-image-crop";
import ImageDropLayout from "./components/image-drop-layout";
import InstPointPopover from "./components/inst-point-popover";
import SensorLocationBlock from "./components/sensor-location-block";
import {
    getImageInstallationPoint,
    getMainIdAndFlipTurnsById,
    getUpdatedImageData,
    readFileAsDataUrl,
    updateImageAfterUpload,
    updateImagesPositions,
    updatePositionOnImageFlip,
} from "./helpers/image-install-point";
import {ACTION, reducer} from "./reducer/main-reducer";
import FormCheckbox from "../../../shared/formCheckbox/formCheckbox";
import {useParams} from "react-router";
import "../../../assets/scss/components/modals/equipments/equipments.scss";
import {useEquipmentInvalidate} from "../../../hooks/api/equipment/useEquipmentInvalidate";

const EditModal = (props) => {
    const imageRef = useRef(null);

    const equipmentImages = _get(props.equipment, "images");
    const invalidateEquipment = useEquipmentInvalidate(props.equipment.id);

    const initialState = {
        current: 0,
        images: props.images,
        points: {
            ...props.points,
            999: {
                id: 999,
                name: props.equipment.name,
            },
        },
        positions: props.positions,
        main: +(_findKey(props.images, (url) => url === (_find(props.equipment.images, (image = {}) => +image.is_primary_image === 1) || {}).url) || 0),
        flipTurns: {},
        crop: {
            unit: "%",
            aspect: 16 / 9,
            width: 100,
            height: (100 * 9) / 16,
        },
        hasChanges: false,
        onGoFromImage: false,
        onCloseImage: null,
        deleteImage: false,
        inProgress: false,
        imageInstallationPoint: getImageInstallationPoint(equipmentImages),
        openPopoverIndex: null,
        isVisibleInstallPointPopup: true,
        isLocalCheckedInstallPointPopup: false,
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    const {auth, equipment, assignPointsModal, onClose} = props;

    const {
        onGoFromImage,
        onCloseImage,
        isLocalCheckedInstallPointPopup,
        flipTurns,
        positions,
        isVisibleInstallPointPopup,
        main,
        images,
        points,
        crop,
        inProgress,
        hasChanges,
        deleteImage,
        openPopoverIndex,
        isZoomed,
        current,
        imageInstallationPoint,
    } = state;

    const {equipmentId} = useParams();

    const isUserCanManageEquipment = !auth.equipmentViewOnly(equipment);

    useEffect(() => {
        const isVisible = getInstallPointPopupVisibility(equipmentId);
        dispatch({type: ACTION.SET_STATE, state: {isVisibleInstallPointPopup: isVisible}});
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDownEvents);
        return () => window.removeEventListener("keydown", handleKeyDownEvents);
    }, []);

    useEffect(() => {
        dispatch({type: ACTION.SET_STATE, state: {points: {...points, ...props.points}}});
    }, [props.points]);

    useEffect(() => {
        dispatch({type: ACTION.SET_STATE, state: {current: props.assignPointsModal.current || 0}});
    }, [props.assignPointsModal.current]);

    useEffect(() => {
        dispatch({type: ACTION.SET_STATE, state: {images: props.images}});
    }, [props.images]);

    const onSetCurrent = (index) => {
        if (isUserCanManageEquipment && !positions[current]) {
            const func = () => {
                dispatch({type: ACTION.SET_STATE, state: {current: index, onGoFromImage: false}});
            };

            dispatch({type: ACTION.SET_STATE, state: {onGoFromImage: {func}}});
            if (!isVisibleInstallPointPopup) {
                func();
            }
        } else {
            dispatch({type: ACTION.SET_STATE, state: {current: index}});
        }
    };

    const handleKeyDownEvents = ({code}) => {
        let localCurrent = current;
        if (code === "ArrowRight") {
            onSetCurrent(++localCurrent > images.length - 1 ? 0 : current);
        } else if (code === "ArrowLeft") {
            onSetCurrent(--localCurrent < 0 ? images.length - 1 : current);
        }
    };
    const onSetMainImage = () => dispatch({type: ACTION.SET_STATE, state: {main: current, hasChanges: true}});

    const hideInstallPointPopup = () => {
        dispatch({type: ACTION.SET_STATE, state: {isVisibleInstallPointPopup: false}});
        setInstallPointPopupVisibility(equipmentId);
    };

    const toggleLocalCheckedInstallPointPopup = () => {
        const state = {
            isLocalCheckedInstallPointPopup: !isLocalCheckedInstallPointPopup,
        };
        dispatch({type: ACTION.SET_STATE, state});
    };

    const onPopupConfirm = () => {
        if (isLocalCheckedInstallPointPopup) {
            hideInstallPointPopup(false);
        }
        onGoFromImage.func();
    };
    const onCropChange = (crop) => dispatch({type: ACTION.SET_STATE, state: crop});

    const onOpenPopover = (index) => {
        dispatch({
            type: ACTION.SET_STATE,
            state: {
                openPopoverIndex: index,
            },
        });
    };

    const handleImageClick = (e, index) => {
        e.preventDefault();
        if (e.type === "click") {
            onSetCurrent(index);
        } else if (e.type === "contextmenu") {
            onOpenPopover(index);
        }
    };

    const onSetPosition = (data) => {
        if (data.clear === true) {
            _unset(positions, `${data.imageIndex}.${data.pointIndex}`);
        } else {
            _setWith(positions, [data.imageIndex, data.pointIndex], data, Object);
        }
        dispatch({
            type: ACTION.SET_STATE,
            state: {
                positions: positions,
                hasChanges: true,
            },
        });
    };
    const onSubmit = () => {
        if (!images.length) {
            onClose();
            return true;
        }

        const callback = () => {
            const {mainId, flipTurnsById} = getMainIdAndFlipTurnsById(images, equipment, main, flipTurns);

            const updatedPositions = updateImagesPositions(positions, equipment);

            ApiEquipment.assignImageInstallationPoint(equipment.id, imageInstallationPoint)
                .then(() => ApiEquipment.setMarkerPosition(equipment.id, updatedPositions))
                .then(() => ApiEquipment.setMainImage(equipment.id, mainId))
                .then(() => ApiEquipment.flipImages(equipment.id, flipTurnsById))
                .then(() => {
                    invalidateEquipment().then(onClose);
                });
        };

        dispatch({type: ACTION.SET_STATE, state: {inProgress: true}, callback});
    };

    const onFlipImage = (flipTurn) => {
        const updatedPositions = updatePositionOnImageFlip(positions, current, flipTurn);

        dispatch({
            type: ACTION.SET_STATE,
            state: {
                positions: updatedPositions,
                flipTurns: {...flipTurns, [current]: (flipTurns[current] || 0) + flipTurn},
                hasChanges: true,
            },
        });
    };

    const onDeleteImage = () => {
        ApiEquipment.removeImage(equipment.id, (_find(equipment.images, {url: images[current]}) || {}).id).then(() => {
            const {updatedImages, updatedPositions, updatedFlipTurns} = getUpdatedImageData(images, current, positions, flipTurns);

            dispatch({
                type: ACTION.SET_STATE,
                state: {
                    images: updatedImages,
                    positions: updatedPositions,
                    flipTurns: updatedFlipTurns,
                    current: updatedImages[current] ? current : 0,
                    main: main !== current ? main : 0,
                    deleteImage: false,
                },
            });
            invalidateEquipment();
        });
    };
    const onUploadImages = (files) => {
        const prevImages = images;

        Promise.all(Object.keys(files).map((key) => readFileAsDataUrl(files[key])))
            .then((images) => ApiEquipment.attachImages(equipment.id, {images}))
            .then((response) => {
                const newImages = updateImageAfterUpload(prevImages, response);
                const imageInstallationPoint = getImageInstallationPoint(response.images || []);

                dispatch({
                    type: ACTION.SET_STATE,
                    state: {
                        images: [...prevImages, ...newImages],
                        current: prevImages.length,
                        imageInstallationPoint,
                    },
                });
                invalidateEquipment();
            });
    };
    const setCurrentInstPointId = (id) => {
        const imageInstallationPointLocal = [...imageInstallationPoint];
        imageInstallationPointLocal[current].installationPointId = id;

        dispatch({
            type: ACTION.SET_STATE,
            state: {
                imageInstallationPoint: imageInstallationPointLocal,
            },
        });
    };

    const setCurrentInstPointIndex = (imageIndex, pointIndex) => {
        const imageInstallationPointLocal = [...imageInstallationPoint];
        imageInstallationPoint[imageIndex].installationPointId = pointIndex ? points[pointIndex].id : pointIndex;
        dispatch({
            type: ACTION.SET_STATE,
            state: {
                imageInstallationPoint: imageInstallationPointLocal,
            },
        });
    };

    const setZoom = () => {
        dispatch({type: ACTION.SET_STATE, state: {isZoomed: !isZoomed}});
    };
    const onCropComplete = (crop) => console.log(crop);

    const colors = {...props.colors, 999: "#ffcd05"};

    const isCrop = false;
    if (!isUserCanManageEquipment) {
        return (
            <Modal
                showModal={assignPointsModal.open}
                className="custom-modal assign-points-modal"
                title="Equipment Photos"
                size="xl"
                onClose={onClose}
                withoutSubmit={true}
                inProgress={inProgress}
            >
                <div className="row">
                    <div className="col-8 text-left">
                        {images.length ? (
                            <div className="assign-photo-main-wrapper">
                                {images.length > 1 && (
                                    <div
                                        onClick={() => onSetCurrent(toPrevImage(+current, images.length - 1))}
                                        className="nav-arrow to-prev"
                                    >
                                        <span className="controll-arrow arrow-prev" />
                                    </div>
                                )}
                                <div className="assign-photo-main-block">
                                    <div className="assign-photo-item">
                                        <div className="assign-photo">
                                            <div className="assign-photo-drop">
                                                {Object.keys(_get(positions, +current) || {}).map((index) => (
                                                    <span
                                                        key={`${current}-${index}`}
                                                        title={points[index].name}
                                                        style={{
                                                            position: "absolute",
                                                            left: _get(positions, `${+current}.${+index}.percent_left`, 0) + "%",
                                                            top: _get(positions, `${+current}.${+index}.percent_top`, 0) + "%",
                                                        }}
                                                        className="img-tooltip-wrapper"
                                                    >
                                                        <span
                                                            className="img-tooltip"
                                                            style={{backgroundColor: colors[index]}}
                                                        >
                                                            <span className="img-text-number">
                                                                {+index === 999 ? (
                                                                    <i
                                                                        className="fa fa-home"
                                                                        style={{margin: 0, fontSize: 12}}
                                                                    />
                                                                ) : (
                                                                    +index + 1
                                                                )}
                                                            </span>
                                                        </span>
                                                        <span
                                                            className="img-tooltip-arrow"
                                                            style={{borderColor: colors[index] + " transparent transparent transparent"}}
                                                        />
                                                    </span>
                                                ))}
                                            </div>
                                            <img
                                                ref={imageRef}
                                                src={images[+current]}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                {images.length > 1 && (
                                    <div
                                        onClick={() => onSetCurrent(toNextImage(+current, images.length - 1))}
                                        className="nav-arrow to-next"
                                    >
                                        <span className="controll-arrow arrow-next" />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="assign-photo-main-wrapper">
                                <div className="assign-photo-main-block">
                                    <div className="assign-photo-item">
                                        <div className="assign-photo">
                                            <img
                                                className="img-fluid"
                                                src="/assets/img/default-equipment-bg.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="assign-photo-list-wrapper">
                            {images.map((image, index) => (
                                <div
                                    onClick={() => onSetCurrent(index)}
                                    key={index}
                                    className={`assign-photo-list-item ${index === current && "active-image"}`}
                                    style={{background: "#fff", transform: `rotate(${flipTurns[index] || 0}turn)`}}
                                >
                                    <img src={image} />
                                    {index === main && (
                                        <span style={{background: "none", width: "100%", height: "100%"}}>
                                            <span style={{background: "#46b67f"}}>
                                                <i className="fa fa-bookmark" />
                                            </span>
                                        </span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <DndProvider backend={HTML5Backend}>
                        <div className="sensors-marker-list col-md-4 view-only">
                            <div className="mt-2">
                                {_has(positions, [current, 999]) ? (
                                    <div
                                        className="sensors-location"
                                        style={{opacity: 0.5}}
                                    >
                                        <span
                                            className="index-icon-block"
                                            style={{backgroundColor: "#ffcd05"}}
                                        >
                                            <i
                                                className="fa fa-home"
                                                style={{margin: 0, fontSize: 12}}
                                            />
                                        </span>
                                        <span
                                            className="pull-right cursor-pointer"
                                            onClick={() =>
                                                onSetPosition({
                                                    imageIndex: current,
                                                    pointIndex: 999,
                                                    clear: true,
                                                })
                                            }
                                        >
                                            <i className="fa fa-times-circle" />
                                        </span>
                                        <span className="point-name">
                                            <span>{equipment.name}</span>
                                        </span>
                                    </div>
                                ) : (
                                    <SensorLocationBlock
                                        setPosition={onSetPosition}
                                        positions={positions}
                                        pointIndex={999}
                                        imageIndex={+current}
                                        name={equipment.name}
                                        color="#ffcd05"
                                        imageRef={imageRef}
                                    />
                                )}
                                {Object.keys(points).map(
                                    (index) =>
                                        +index !== 999 &&
                                        (images.length ? (
                                            _has(positions, [current, index]) ? (
                                                <div
                                                    key={index}
                                                    className="sensors-location d-flex align-items-center justify-content-between"
                                                >
                                                    <div className={"d-flex"}>
                                                        <label
                                                            className="form-checkbox"
                                                            title={"Assign pictures at sensor level"}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    +_get(imageInstallationPoint, "[" + current + "].installationPointId") ===
                                                                    +points[index].id
                                                                }
                                                                disabled={true}
                                                            />
                                                            <span />
                                                        </label>
                                                        <span
                                                            className="index-icon-block"
                                                            style={{backgroundColor: colors[index]}}
                                                        >
                                                            {+index + 1}
                                                        </span>
                                                        <span
                                                            className="point-name"
                                                            style={{opacity: 0.5}}
                                                        >
                                                            <span>{points[index].name}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <SensorLocationBlock
                                                    key={index}
                                                    setPosition={onSetPosition}
                                                    positions={positions}
                                                    pointIndex={+index}
                                                    imageIndex={+current}
                                                    name={points[index].name}
                                                    color={colors[index]}
                                                    imageRef={imageRef}
                                                    onChangeCheckbox={(e) => {
                                                        setCurrentInstPointId(Helper.getInputValue(e.target) === "1" ? points[index].id : null);
                                                    }}
                                                    checkedCheckbox={
                                                        +_get(imageInstallationPoint, "[" + current + "].installationPointId") === +points[index].id
                                                    }
                                                />
                                            )
                                        ) : (
                                            <div
                                                key={index}
                                                className="sensors-location"
                                            >
                                                <span
                                                    className="index-icon-block"
                                                    style={{backgroundColor: colors[index]}}
                                                >
                                                    {+index + 1}
                                                </span>
                                                <span className="point-name">
                                                    <span>{points[index].name}</span>
                                                </span>
                                            </div>
                                        ))
                                )}
                            </div>
                        </div>
                    </DndProvider>
                </div>
            </Modal>
        );
    }

    return (
        <Modal
            showModal={assignPointsModal.open}
            className="custom-modal assign-points-modal"
            title="Equipment Photos"
            size="xl"
            onClose={() => {
                if (!positions[current]) {
                    const func = () => dispatch({type: ACTION.SET_STATE, state: {onCloseImage: null}, callback: onClose});
                    if (!onGoFromImage) {
                        dispatch({type: ACTION.SET_STATE, state: {onCloseImage: true}});
                    }

                    if (!isVisibleInstallPointPopup) {
                        if (hasChanges) {
                            dispatch({
                                type: ACTION.SET_STATE,
                                state: {
                                    onCloseImage: true,
                                },
                            });
                            return;
                        }
                        func();
                    }
                } else if (hasChanges) {
                    const func = () => dispatch({type: ACTION.SET_STATE, state: {onCloseImage: null}, callback: onClose});
                    if (!onGoFromImage) {
                        dispatch({
                            type: ACTION.SET_STATE,
                            state: {
                                onCloseImage: true,
                            },
                        });
                    }
                    if (!isVisibleInstallPointPopup) {
                        func();
                    }
                } else {
                    onClose();
                }
            }}
            onSubmit={() => {
                if (!positions[current]) {
                    const func = () => dispatch({type: ACTION.SET_STATE, state: {onGoFromImage: false}, callback: onSubmit});
                    dispatch({type: ACTION.SET_STATE, state: {onGoFromImage: {func}}});
                    if (!isVisibleInstallPointPopup) {
                        func();
                    }
                } else {
                    onSubmit();
                }
            }}
            inProgress={inProgress}
        >
            <div className="row">
                <DndProvider backend={HTML5Backend}>
                    <div className="col-md-8 text-left">
                        {isCrop ? (
                            <ReactCrop
                                src={images[current]}
                                crop={crop}
                                onImageError={(ev, aa, bb) => console.log("error", ev, aa, bb)}
                                onDragStart={(ev, aa, bb) => console.log("start", ev, aa, bb)}
                                onDragEnd={(ev, aa, bb) => console.log("end", ev, aa, bb)}
                                onComplete={onCropComplete}
                                onChange={onCropChange}
                            />
                        ) : images.length ? (
                            <ImageDropLayout
                                colors={colors}
                                points={points}
                                flipTurns={flipTurns}
                                positions={positions}
                                imageIndex={+current}
                                images={images}
                                setPosition={onSetPosition}
                                setCurrent={onSetCurrent}
                                isZoomed={isZoomed}
                                handleZoomChange={setZoom}
                                imageRef={imageRef}
                            />
                        ) : (
                            <div className="assign-photo-main-wrapper">
                                <div className="assign-photo-main-block">
                                    <div className="assign-photo-item">
                                        <div className="assign-photo">
                                            <img
                                                className="img-fluid"
                                                src="/assets/img/default-equipment-bg.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!!images.length && (
                            <div style={{textAlign: "center"}}>
                                <button
                                    onClick={onSetMainImage}
                                    className="btn btn-sm btn-success"
                                    style={{marginRight: 5}}
                                    disabled={current === main}
                                >
                                    <i className="fa fa-bookmark" /> Set as Main Image
                                </button>
                                <button
                                    onClick={() => onFlipImage(-0.25)}
                                    className="btn btn-sm btn-primary"
                                    style={{marginRight: 5}}
                                >
                                    <i className="fa fa-undo" /> Rotate
                                </button>
                                <button
                                    onClick={() => onFlipImage(0.25)}
                                    className="btn btn-sm btn-primary"
                                    style={{marginRight: 5}}
                                >
                                    <i className="fa fa-redo" /> Rotate
                                </button>
                                <button
                                    onClick={() => setZoom()}
                                    className="btn btn-sm btn-primary"
                                    style={{marginRight: 5}}
                                >
                                    <i className="fa fa-search-plus" /> Zoom
                                </button>
                                <button
                                    onClick={() => dispatch({type: ACTION.SET_STATE, state: {deleteImage: true}})}
                                    className="btn btn-sm btn-danger"
                                >
                                    <i className="fa fa-trash" /> Delete Image
                                </button>
                            </div>
                        )}
                        <div className="assign-photo-list-wrapper">
                            <Dropzone
                                onDrop={onUploadImages}
                                accept="image/*"
                            >
                                {({getRootProps, getInputProps}) => (
                                    <div
                                        {...getRootProps()}
                                        className="assign-photo-list-item add-image-block"
                                        title={"Add Equipment Photo"}
                                    >
                                        <input {...getInputProps()} />
                                        <button
                                            onClick={() => {}}
                                            className="btn btn-secondary btn-circle btn-icon btn-add-image"
                                            style={{width: "2.5rem"}}
                                        >
                                            <i className="fa fa-plus" />
                                        </button>
                                    </div>
                                )}
                            </Dropzone>
                            {images.map((image, index) => (
                                <React.Fragment key={index}>
                                    <div
                                        onClick={(e) => handleImageClick(e, index)}
                                        onContextMenu={(e) => handleImageClick(e, index)}
                                        key={index}
                                        id={"image-popover-" + index}
                                        className={`assign-photo-list-item ${index === current && "active-image"}`}
                                        style={{
                                            background: "#fff",
                                            transform: `rotate(${flipTurns[index] || 0}turn)`,
                                        }}
                                    >
                                        <img src={image} />
                                        {index === main && (
                                            <span
                                                style={{
                                                    transform: `rotate(${1 - (flipTurns[index] || 0)}turn)`,
                                                    background: "none",
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            >
                                                <span style={{background: "#46b67f"}}>
                                                    <i className="fa fa-bookmark" />
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                    <InstPointPopover
                                        points={points}
                                        colors={colors}
                                        setCurrentInstPointIndex={setCurrentInstPointIndex}
                                        currentInstPointIndex={_findKey(points, (i) => {
                                            return +i.id === +_get(imageInstallationPoint, "[" + index + "].installationPointId");
                                        })}
                                        imageIndex={index}
                                        openPopoverIndex={openPopoverIndex}
                                        toggle={() => onOpenPopover(null)}
                                    />
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    <div className="sensors-marker-list col-md-4">
                        <span className="secondary-text mb-3">
                            <i className="fa fa-info-circle mr-1" /> Drag the marker to the location of the installation point
                        </span>
                        <div className="mt-2">
                            {_has(positions, [current, 999]) ? (
                                <div
                                    className="sensors-location"
                                    style={{opacity: 0.5}}
                                >
                                    <span
                                        className="index-icon-block"
                                        style={{backgroundColor: "#ffcd05"}}
                                    >
                                        <i
                                            className="fa fa-home"
                                            style={{margin: 0, fontSize: 12}}
                                        />
                                    </span>
                                    <span
                                        className="pull-right cursor-pointer"
                                        onClick={() =>
                                            onSetPosition({
                                                imageIndex: current,
                                                pointIndex: 999,
                                                clear: true,
                                            })
                                        }
                                    >
                                        <i className="fa fa-times-circle" />
                                    </span>
                                    <span className="point-name">
                                        <span>{equipment.name}</span>
                                    </span>
                                </div>
                            ) : (
                                <SensorLocationBlock
                                    setPosition={onSetPosition}
                                    positions={positions}
                                    pointIndex={999}
                                    imageIndex={+current}
                                    name={equipment.name}
                                    color="#ffcd05"
                                    imageRef={imageRef}
                                />
                            )}
                            {Object.keys(points).map(
                                (index) =>
                                    +index !== 999 &&
                                    (images.length ? (
                                        _has(positions, [current, index]) ? (
                                            <div
                                                key={index}
                                                className="sensors-location d-flex align-items-center justify-content-between"
                                            >
                                                <div className={"d-flex"}>
                                                    <label
                                                        className="form-checkbox"
                                                        title={"Assign pictures at sensor level"}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                setCurrentInstPointId(Helper.getInputValue(e.target) === "1" ? points[index].id : null);
                                                            }}
                                                            checked={
                                                                +_get(imageInstallationPoint, "[" + current + "].installationPointId") ===
                                                                +points[index].id
                                                            }
                                                        />
                                                        <span />
                                                    </label>
                                                    <span
                                                        className="index-icon-block"
                                                        style={{backgroundColor: colors[index]}}
                                                    >
                                                        {+index + 1}
                                                    </span>
                                                    <span
                                                        className="point-name"
                                                        style={{opacity: 0.5}}
                                                    >
                                                        <span>{points[index].name}</span>
                                                    </span>
                                                </div>
                                                <span
                                                    className="pull-right cursor-pointer"
                                                    style={{opacity: 0.5}}
                                                    onClick={() =>
                                                        onSetPosition({
                                                            imageIndex: current,
                                                            pointIndex: index,
                                                            clear: true,
                                                        })
                                                    }
                                                >
                                                    <i className="fa fa-times-circle" />
                                                </span>
                                            </div>
                                        ) : (
                                            <SensorLocationBlock
                                                key={index}
                                                setPosition={onSetPosition}
                                                positions={positions}
                                                pointIndex={+index}
                                                imageIndex={+current}
                                                name={points[index].name}
                                                color={colors[index]}
                                                imageRef={imageRef}
                                                onChangeCheckbox={(e) => {
                                                    setCurrentInstPointId(Helper.getInputValue(e.target) === "1" ? points[index].id : null);
                                                }}
                                                checkedCheckbox={
                                                    +_get(imageInstallationPoint, "[" + current + "].installationPointId") === +points[index].id
                                                }
                                            />
                                        )
                                    ) : (
                                        <div
                                            key={index}
                                            className="sensors-location"
                                        >
                                            <span
                                                className="index-icon-block"
                                                style={{backgroundColor: colors[index]}}
                                            >
                                                {+index + 1}
                                            </span>
                                            <span className="point-name">
                                                <span>{points[index].name}</span>
                                            </span>
                                        </div>
                                    ))
                            )}
                        </div>
                    </div>
                </DndProvider>
            </div>
            {onCloseImage && (
                <SweetAlert
                    warning
                    showCancel
                    cancelBtnText="Cancel"
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title=""
                    onConfirm={onClose}
                    onCancel={() => dispatch({type: ACTION.SET_STATE, state: {onCloseImage: null}})}
                >
                    Are you sure you want to navigate away from this page? If you proceed, ALL of your changes will be lost. Click Yes to continue, or
                    click Cancel to stay on the current page
                </SweetAlert>
            )}
            {deleteImage && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title="DELETE PHOTO"
                    openAnim={false}
                    closeAnim={false}
                    onConfirm={onDeleteImage}
                    onCancel={() => dispatch({type: ACTION.SET_STATE, state: {deleteImage: false}})}
                >
                    Are you sure you want to delete this photo?
                </SweetAlert>
            )}
            {onGoFromImage && isVisibleInstallPointPopup && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Return to installation points"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title=""
                    openAnim={false}
                    closeAnim={false}
                    customButtons={
                        <>
                            <div className="mb-2">
                                <Button
                                    onClick={onPopupConfirm}
                                    className="mr-3"
                                >
                                    No
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        dispatch({
                                            type: ACTION.SET_STATE,
                                            state: {onGoFromImage: false},
                                        })
                                    }
                                >
                                    Yes
                                </Button>
                            </div>
                            <FormGroup
                                check
                                className="ask-again-btn-wrapper mb-4"
                            >
                                <FormCheckbox
                                    checked={isLocalCheckedInstallPointPopup}
                                    onChange={toggleLocalCheckedInstallPointPopup}
                                >
                                    Do not ask again
                                </FormCheckbox>
                            </FormGroup>
                            <p>Note: If you want to assign installation points to the photo, drag the marker to the installation point location.</p>
                        </>
                    }
                >
                    {onGoFromImage.text || (
                        <React.Fragment>
                            <p>Do you want to assign installation points to the photo?</p>
                        </React.Fragment>
                    )}
                </SweetAlert>
            )}
        </Modal>
    );
};

EditModal.propTypes = {
    auth: PropTypes.object,
    assignPointsModal: PropTypes.object,
    images: PropTypes.array,
    points: PropTypes.array,
    positions: PropTypes.object,
    equipment: PropTypes.object,
    colors: PropTypes.array,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    isZoomed: PropTypes.bool,
};

export default EditModal;
