import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiAlertCondition from "../../../api/alertCondition";
import {ALERT_CONDITIONS_API} from "./constants";

export const useAlertConditionUpdateMultipleMutation = (chartType) => {
    const queryClient = useQueryClient();

    const mutation = useMutation((data) => ApiAlertCondition.updateMultiple(data), {
        onSuccess: () => queryClient.refetchQueries({queryKey: [ALERT_CONDITIONS_API.LIST, chartType]}),
    });
    return mutation;
};
