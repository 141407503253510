import {useMemo} from "react";

export const CHART_VIEW_TYPE = {
    LINEAR: "LINEAR",
    CIRCULAR: "CIRCULAR",
};

export const useIsCircular = (chartViewType) => {
    return useMemo(() => chartViewType === CHART_VIEW_TYPE.CIRCULAR, [chartViewType]);
};
export const useIsLinear = (chartViewType) => {
    return useMemo(() => chartViewType === CHART_VIEW_TYPE.LINEAR, [chartViewType]);
};
