import {useDrop} from "react-dnd";
import React, {useEffect, useState} from "react";
import $ from "jquery";
import {Controlled as ControlledZoom} from "react-medium-image-zoom";
import PropTypes from "prop-types";
import {get as _get} from "lodash";
import SensorLocationBlock from "./sensor-location-block";
import {toNextImage, toPrevImage} from "../helpers/image-navigation";
import {Loader} from "../../../../shared";

const ImageDropLayout = ({points, positions, flipTurns, colors, imageIndex, images, setPosition, setCurrent, isZoomed, handleZoomChange, imageRef}) => {
    const [load, setLoad] = useState(false);
    const [stylesPhoto, setStylesPhoto] = useState(null);
    const [{canDrop, isOver}, drop] = useDrop(
        {
            accept: "SensorLocationBlock",
            drop: (item, monitor) => ({name: item.name, imageIndex: imageIndex, monitor: monitor.getClientOffset()}),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        },
        [load]
    );
    const isActive = canDrop && isOver;
    let opacity = "1";
    if (isActive) {
        opacity = "0.9";
    } else if (canDrop) {
        opacity = "1";
    }
    const posTooltips = _get(positions, imageIndex) || {};

    useEffect(() => {
        if (load) {
            const $drop = $(".assign-photo-drop");
            const $dropTooltip = $(".img-tooltip-wrapper");
            const $dropTooltipText = $(".img-text-number");

            $drop.height(stylesPhoto.height);
            $drop.width(stylesPhoto.width);

            if (Math.abs((flipTurns[imageIndex] || 0) % 0.5) === 0.25) {
                $drop.css({
                    transform: `rotate(${1 - (flipTurns[imageIndex] || 0)}turn) scale(${$drop.height() / stylesPhoto.width}, ${
                        $drop.width() / stylesPhoto.height
                    })`,
                });
                $dropTooltip.css({transform: "inherit"});
                let flipText = "" + flipTurns[imageIndex];

                if (flipText.split(".", 2)[1] === "25") {
                    $dropTooltipText.css({transform: "rotate(90deg)"});
                } else {
                    $dropTooltipText.css({transform: "rotate(-90deg)"});
                }

                $drop.addClass("horizontal-img");
            } else {
                $drop.css({transform: `rotate(${1 - (flipTurns[imageIndex] || 0)}turn)`});
                $dropTooltip.css({transform: "initial"});
                $drop.removeClass("horizontal-img");
            }
        }
    }, [imageRef.current, imageIndex, flipTurns, positions, load, stylesPhoto]);

    const handleImageLoad = () => {
        const {width, height} = imageRef.current;
        setStylesPhoto({
            width: width,
            height: height,
        });
        setLoad(true);
    };

    return (
        <div
            style={{opacity}}
            className="assign-photo-main-wrapper"
        >
            {images.length > 1 && (
                <div
                    onClick={() => setCurrent(toPrevImage(imageIndex, images.length - 1))}
                    className="nav-arrow to-prev"
                >
                    <span className={"controll-arrow arrow-prev"} />
                </div>
            )}
            <div className="assign-photo-main-block">
                <div className="assign-photo-item">
                    <div className="assign-photo">
                        <div style={{transform: `rotate(${flipTurns[imageIndex] || 0}turn)`}}>
                            {!load ? (
                                <Loader />
                            ) : (
                                <div
                                    className="assign-photo-drop"
                                    ref={drop}
                                    style={{
                                        transform: `rotate(${1 - (flipTurns[imageIndex] || 0)}turn)`,
                                        position: "absolute",
                                    }}
                                >
                                    {Object.keys(posTooltips).map((index) => (
                                        <SensorLocationBlock
                                            key={index}
                                            positions={positions}
                                            setPosition={setPosition}
                                            pointIndex={+index}
                                            imageIndex={imageIndex}
                                            name={points[index].name}
                                            color={colors[index]}
                                            column="left"
                                            imageRef={imageRef}
                                        />
                                    ))}
                                </div>
                            )}
                            <img
                                style={{opacity: load ? 1 : 0, position: load ? "static" : "absolute"}}
                                onLoadStart={() => setLoad(false)}
                                onLoad={handleImageLoad}
                                ref={imageRef}
                                className={"thumbnail-img"}
                                src={_get(images[imageIndex], "url") ?? images[imageIndex]}
                                alt=""
                            />
                        </div>
                    </div>
                    {isZoomed && (
                        <ControlledZoom
                            zoomMargin={30}
                            isZoomed={isZoomed}
                            transitionDuration={0}
                            onZoomChange={handleZoomChange}
                        >
                            <img
                                src={_get(images[imageIndex], "url") ?? images[imageIndex]}
                                style={{transform: `rotate(${flipTurns[imageIndex] || 0}turn)`, position: "relative"}}
                                alt=""
                            />
                        </ControlledZoom>
                    )}
                </div>
            </div>
            {images.length > 1 && (
                <div
                    onClick={() => setCurrent(toNextImage(imageIndex, images.length - 1))}
                    className="nav-arrow to-next"
                >
                    <span className={"controll-arrow arrow-next"} />
                </div>
            )}
        </div>
    );
};
ImageDropLayout.propTypes = {
    images: PropTypes.array,
    imageIndex: PropTypes.number,
    points: PropTypes.object,
    flipTurns: PropTypes.object,
    positions: PropTypes.object,
    colors: PropTypes.object,
    setPosition: PropTypes.func,
    setCurrent: PropTypes.func,
    isZoomed: PropTypes.bool,
    handleZoomChange: PropTypes.func,
    imageRef: PropTypes.object,
};

export default ImageDropLayout;
