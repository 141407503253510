import React, {useEffect, useState} from "react";
import { Modal } from "../../../shared";
import PropTypes from "prop-types";
import {get as _get} from "lodash";
import "../../../assets/scss/components/guide/guide.scss";
import Loader from "../../../shared/loader/loader";
import UserGuideApi from "../../../api/userGuide";

const HelpRouteCollectorGuide = (props) => {

    const [loader, setLoader] = useState(true);
    const [url, setUrl] = useState("");

    useEffect(() => {
        UserGuideApi.getRouteCollectorGuide().then(response => {
                setLoader(false);
                setUrl(_get(response, "url", ""));
            });
    }, []);

    return (
        <Modal
            {...props}
            size="m"
            title={"Route Collector Guide"}
            withoutFooter={true}
            className="user_guide-modal"
        >
            {loader ?
                <Loader/> :
                <div>
                    <ul className="user_guide-list">
                        <li>
                            <p>
                                The <i>Waites Self-Service Route Collector Guide</i> is a detailed guide
                                to the device that allows you to take point-by-point readings on demand.
                            </p>

                            {url &&
                                <div className="mt-2 mb-2">
                                    <a
                                        className={"btn btn-primary btn-sm"}
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Click here to open
                                    </a>
                                </div>
                            }
                        </li>
                    </ul>
                </div>
            }
        </Modal>
    );
};

HelpRouteCollectorGuide.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func
};

export default HelpRouteCollectorGuide;