import ChartHelper from "./chart";

export const getSpeedSettingsFft = ({installationPointId, reading, pointData, equipment}) => {
    if (!installationPointId) {
        return {
            currentSpeed: 0,
            currentRatio: 1,
            currentSpeedFrom: "",
            currentRpmTimestamp: "",
        };
    }

    const speedSettings = ChartHelper.getCurrentSpeed(reading, pointData, equipment);
    return {
        currentSpeed: speedSettings?.value || 0,
        currentRatio: speedSettings?.ratio || 1,
        currentSpeedFrom: speedSettings?.from || "",
        currentRpmTimestamp: speedSettings?.rpmTimestamp || "",
    };
};
