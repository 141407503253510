import React, {Component, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import Helper from "../../../helpers/helper";
import {get as _get} from "lodash";

const headersList = {
    "mfr": {title: "Mfr", sort: false},
    "name": {title: "Bearing ID", sort: false},
    "balls_rollers": {title: "#Balls/Rollers", sort: false},
    "plotLines" : {title: "Plot Lines", sort: false, style: {width: 130}},
    "actions": {title: "Action", sort: false, style: {width: 50}}
};

class Table extends Component
{
    constructor(props) {
        super(props);
    }

    render() {
        const {list, sort, onSortChange, handleDeleteBearing, selected, changePlotLinesCount, auth, handleDeleteSelfBearing, handleEditSelfBearing, disabledField} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <table className="table table-hover bearing-table">
                <thead>
                    <tr>
                        {Object.keys(headersList).map((key) =>
                            listLen && headersList[key].sort
                                ?
                                <th key={key} style={headersList[key].style || {}} onClick={() => onSortChange(key)}>
                                    {headersList[key].title}
                                    {sort.field === key && <i className={"la la-arrow" + (sort.sort === "asc" ? "-up" : "-down")}/>}
                                </th>
                                :
                                <th key={key} style={headersList[key].style || {}}>{headersList[key].title}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {list.length > 0
                        ?
                        list.map((bearing) => <BearingRow
                            key={bearing.id}
                            bearing={bearing}
                            props={this.props}
                            handleAddBearing={this.props.handleAddBearing}
                            handleDeleteBearing={handleDeleteBearing}
                            changePlotLinesCount={changePlotLinesCount}
                            selected={selected}
                            auth={auth}
                            handleDeleteSelfBearing={handleDeleteSelfBearing}
                            handleEditSelfBearing={handleEditSelfBearing}
                            disabledField={disabledField}
                        />)
                        :
                        <tr>
                            <td colSpan={listLen} className="text-center text-info">
                                Bearings not found
                            </td>
                        </tr>
                    }
                    <tr className={"bg-info"}>
                        <td align="center" colSpan={listLen}><i className={"text-center"}>To view more bearings, perform a search.</i></td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

Table.propTypes = {
    list: PropTypes.array,
    selected: PropTypes.array,
    sort: PropTypes.object,
    query: PropTypes.string,
    onSortChange: PropTypes.func,
    handleAddBearing: PropTypes.func,
    handleDeleteBearing: PropTypes.func,
    changePlotLinesCount: PropTypes.func,
    auth: PropTypes.object,
    handleDeleteSelfBearing: PropTypes.func,
    handleEditSelfBearing: PropTypes.func,
    disabledField: PropTypes.bool,
};

const BearingRow = ({bearing, props, handleAddBearing, handleDeleteBearing, selected, changePlotLinesCount, auth, handleDeleteSelfBearing, handleEditSelfBearing, disabledField}) => {
    const selectedBearing = _get(bearing, "selected", {});
    const notEmptyBearing = Object.keys(selectedBearing).length > 0;
    const plotLinesCountRef = useRef(undefined);

    useEffect(() => {
        if ((plotLinesCountRef.current || {}).value !== undefined) {
            if ((plotLinesCountRef.current || {}).value < 0) plotLinesCountRef.current.value = 0;
            else if ((plotLinesCountRef.current || {}).value > 50) plotLinesCountRef.current.value = 50;
            else plotLinesCountRef.current.value = +plotLinesCountRef.current.value;
        }
    }, [(plotLinesCountRef.current || {}).value]);

    return (
        <tr className={notEmptyBearing ? "border-left-md-warning" : ""}>
            <td className={notEmptyBearing ? "font-weight-bold" : ""} dangerouslySetInnerHTML={{__html: Helper.highlight(bearing.mfr, props.query)}}/>
            <td className={notEmptyBearing ? "font-weight-bold" : ""} dangerouslySetInnerHTML={{__html: Helper.highlight(bearing.name, props.query)}}/>
            <td className={notEmptyBearing ? "font-weight-bold" : ""} dangerouslySetInnerHTML={{__html: Helper.highlight(bearing.balls_rollers, props.query)}}/>
            <td>
                {notEmptyBearing &&
                <input
                    className="form-control form-control-sm active-bearing-input"
                    name="plot_lines_count"
                    ref={plotLinesCountRef}
                    defaultValue={_get(selectedBearing, "plot_lines_count", "10")}
                    onChange={e => changePlotLinesCount(bearing, e.currentTarget.value)}
                    disabled={disabledField}
                />
                }
            </td>
            <td>
                <div className="btn-group btn-group-sm">
                    {notEmptyBearing
                        ?
                        <button
                            className="link color-yellow-brand"
                            onClick={() => handleDeleteBearing(bearing)}
                        >
                            <span className={"badge badge-warning round-badge badge-icon"} style={{ width: 20, height:20 }}>
                                <i className={"fa fa-times"}/>
                            </span>
                            <span>Clear</span>
                        </button>
                        :
                        <button
                            className="link link-primary"
                            onClick={() => handleAddBearing(bearing)}
                            disabled={(selected.length > 2)}
                        >
                            <span className="badge badge-primary round-badge badge-icon" style={{ width: 20, height:20 }}>
                                <i className="fa fa-plus"/>
                            </span>
                            <span>Add</span>
                        </button>}
                    {(bearing.customer_id && auth.userCan("editSelfBearings")) &&
                        <React.Fragment>
                            <button
                                className="link link-danger"
                                onClick={() => handleDeleteSelfBearing(bearing)}
                            >
                                <i className="fa fa-times"/> <span>Delete</span>
                            </button>
                            <button
                                className="link link-primary"
                                onClick={() => handleEditSelfBearing(bearing.id)}
                            >
                                <i className="fa fa-pen"/> <span>Edit</span>
                            </button>
                        </React.Fragment>
                    }
                </div>
            </td>
        </tr>
    );
};

BearingRow.propTypes = {
    bearing: PropTypes.object,
    selected: PropTypes.array,
    props: PropTypes.object,
    query: PropTypes.string,
    handleAddBearing: PropTypes.func,
    handleDeleteBearing: PropTypes.func,
    changePlotLinesCount: PropTypes.func,
    auth: PropTypes.object,
    handleDeleteSelfBearing: PropTypes.func,
    handleEditSelfBearing: PropTypes.func,
    disabledField: PropTypes.bool
};

export default Table;