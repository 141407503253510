import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import ToastContent from "./pages/shared/toastContent";
import RouterWrapper from "./router";

import "core-js";
import "regenerator-runtime/runtime";

import "isomorphic-fetch";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import "abortcontroller-polyfill";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/global.scss";
import "./assets/scss/dark.scss";
import "./assets/css/temp.css";
import "react-sortable-tree/style.css";

if (navigator.userAgent.indexOf("MSIE") !== -1 || navigator.appVersion.indexOf("Trident/") > -1) {
    let element = document.querySelector("html");
    if (element.classList) {
        element.classList.add("ie");
    } else {
        element.className += " ie";
    }
}

ReactDOM.render(
    <StrictMode>
        <RouterWrapper />
    </StrictMode>,
    document.getElementById("root")
);

ReactDOM.render(<ToastContent />, document.getElementById("toast"));
