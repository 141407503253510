import PropTypes from "prop-types";
import React from "react";

const FilterBar = ({ updateDND, dnd }) => {
    if (dnd.isSorting) {
        return (
            <>
                <div className="d-flex">
                    <button
                        onClick={() => updateDND({ isRefresh: true })}
                        className="btn btn-secondary btn-sm btn-elevate mr-2"
                    >
                        <i className="fa fa-align-justify" />
                        Cancel
                    </button>
                    <button
                        onClick={() => updateDND({ isApply: true })}
                        className="btn btn-success btn-sm btn-elevate"
                    >
                        <i className="fa fa-align-left" />
                        Apply
                    </button>
                </div>
            </>
        );
    }

    return (
        <button
            onClick={() => updateDND({ isSorting: true })}
            className="btn btn-warning btn-sm btn-elevate"
        >
            <i className="fa fa-align-left" />
            Sorting
        </button>
    );
};

FilterBar.propTypes = {
    updateDND: PropTypes.func,
    dnd: PropTypes.object,
};

export default FilterBar;
