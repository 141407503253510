import React from "react";
import {useFFTChartSeriesBuilder} from "../../../../../hooks/factory/useFFTChartSeriesBuilder";
import PropTypes from "prop-types";
import {useGetPointById} from "../../../../../hooks/helpers/usePointId";
import {useEquipmentByParams} from "../../../../../hooks/api/equipment/useEquipmentQuery";
import {Loader} from "../../../../../shared";
import {useIsImpactVue} from "../../../../../pages/chart/highcharts/hooks/useChartTypes";

export const withFftReading = (Component) => {
    const Hoc = ({alert = {}, ...rest}) => {
        const {readingsLoaded, series} = useFFTChartSeriesBuilder({
            axis: [alert?.axisId],
            chartType: alert?.chartType,
            installationPointId: alert?.installationPointId,
            isEnabled: !!Object.keys(alert).length,
            isImpactVue: useIsImpactVue(alert?.chartType)
        });
        const {equipmentItem} = useEquipmentByParams();
        const getPointById = useGetPointById(alert?.installationPointId, equipmentItem);
        if (!readingsLoaded) {
            return <Loader />;
        }
        return (
            <Component
                alert={alert}
                chartSeries={series}
                getPointById={() => getPointById}
                equipment={equipmentItem}
                {...rest}
            />
        );
    };
    Hoc.propTypes = {
        alert: PropTypes.object,
    };
    return Hoc;
};
