import React, {Component} from "react";
import PropTypes from "prop-types";

import "../../assets/scss/components/search-field/search-field.scss";

class SearchInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.query,
        };
        this.onChange = this.onChange.bind(this);
        this.onClear = this.onClear.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    onChange(e) {
        const {value = ""} = e.target;
        this.setState({inputValue: value});
        if (this.props.handleSearch !== "global") {
            this.props.onChange(value);
        }
    }

    onClear() {
        this.setState({inputValue: ""});
        this.props.onChange("");
    }

    handleSearch() {
        this.props.onChange(this.state.inputValue);
        if (this.props.handleSearch == "global") {
            this.props.history.push(
                "/search#searchTypeList=Locations%2CEquipments%2CInstallationPoints%2CSensorHex%2CNodeSerial%2CAll%2CGateway%2CRouter%2CNode%2CSensor%2CMote&tab=Mote&query=" +
                    this.state.inputValue
            );
        }
    }

    handleKeyDown(e) {
        if (this.props.handleSearch == "global" && e.key === "Enter") {
            this.props.onChange(this.state.inputValue);

            this.props.history.push(
                "/search#searchTypeList=Locations%2CEquipments%2CInstallationPoints%2CSensorHex%2CNodeSerial%2CAll%2CGateway%2CRouter%2CNode%2CSensor%2CMote&tab=Mote&query=" +
                    this.state.inputValue
            );
        }
    }

    render() {
        return (
            <div className="search-field">
                <input
                    type="text"
                    className={`search-field-input form-control ${this.props.additionalClasses || ""}`}
                    data-qa="searchInput"
                    placeholder={this.props.placeholder || "Search..."}
                    value={this.state.inputValue}
                    onChange={this.onChange}
                    disabled={this.props.disabled}
                    onKeyDown={this.handleKeyDown}
                />
                <span
                    className="search-field-submit"
                    onClick={this.handleSearch}
                >
                    <span>
                        <i className="la la-search" />
                    </span>
                </span>
                {this.props.query.length > 0 && (
                    <span
                        className="search-field-clear"
                        onClick={this.onClear}
                    >
                        <span>
                            <i className="la la-times" />
                        </span>
                    </span>
                )}
            </div>
        );
    }
}

SearchInput.propTypes = {
    history: PropTypes.any,
    disabled: PropTypes.bool,
    query: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    additionalClasses: PropTypes.string,
    handleSearch: PropTypes.any,
};

SearchInput.defaultProps = {
    disabled: false,
};

export default SearchInput;
