import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import PhotoModal from "../../modals/photo/photo";
import {get as _get} from "lodash";
import "./photosWrapper.scss";
import Helper from "../../helpers/helper";

const PhotosWrapper = (props) => {
    const {
        photos,
        withPhotoModal,
        withDropzone,
        buttonName,
        withDeleteButton,
        defaultPhoto,
        withoutDeleteModalBtn,
        withList,
        onDeletePhoto,
        onAddPhotos,
        displayPhotosAsDefault
    } = props;

    const [currentPhoto, setCurrentPhoto] = useState(0);
    const [showPhotosModal, setShowPhotosModal] = useState(false);

    const setCurrent = (index) => {
        setCurrentPhoto(index);
    };

    useEffect(() => {
        if (photos.length) {
            setCurrentPhoto(photos.length - 1);
        }

        if ((!photos.length && showPhotosModal) || Helper.hasOnlyDefaultImage(photos)) {
            setShowPhotosModal(false);
        }
    }, [photos]);

    const attachPhotos = (files) => {
        Promise.all(
            Object.keys(files).map(
                (key) =>
                    new Promise((resolve, reject) => {
                        if (files[key]) {
                            let reader = new FileReader();
                            reader.readAsDataURL(files[key]);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = (error) => reject(error);
                        } else {
                            reject("File is empty");
                        }
                    })
            )
        ).then((images) => onAddPhotos(images));
    };

    const detachPhoto = (photo) => onDeletePhoto(photo);

    const photoPrev = () =>
        setCurrentPhoto((currentPhoto === 0 ? photos.length : currentPhoto) - 1);

    const photoNext = () =>
        setCurrentPhoto(currentPhoto === photos.length - 1 ? 0 : currentPhoto + 1);

    return (
        <div>
            {(photos || []).length && photos[currentPhoto]
                ? (displayPhotosAsDefault
                    ? <DisplayAsDefaultPhoto imageURL={_get(photos, [currentPhoto, "url"])}/>
                    : <DisplayAsCustomPhotos
                        photos={photos}
                        currentPhoto={currentPhoto}
                        photoNext={photoNext}
                        photoPrev={photoPrev}
                        withDropzone={withDropzone}
                        setShowPhotosModal={setShowPhotosModal}
                    />)
                : <DisplayAsDefaultPhoto defaultPhoto={defaultPhoto}/>
            }
            <div className={"d-flex justify-content-between"}>
                {withDropzone && (!withList || !(photos || []).length) && (
                    <Dropzone onDrop={attachPhotos} accept="image/*">
                        {({ getRootProps, getInputProps }) => (
                            <span {...getRootProps()}>
                                <input {...getInputProps()} />
                                <button
                                    className="btn btn-sm btn-primary"
                                    style={{
                                        display: "block",
                                        margin: "8px auto 0",
                                    }}
                                >
                                    {buttonName}
                                </button>
                            </span>
                        )}
                    </Dropzone>
                )}

                {!!((photos || []).length && photos[currentPhoto] && withDeleteButton && !Helper.hasOnlyDefaultImage(photos)) && (
                    <button
                        className="btn btn-sm btn-danger"
                        onClick={() => detachPhoto(photos[currentPhoto])}
                        style={{
                            display: "block",
                            margin: "8px 0 0",
                        }}
                    >
                        Delete Photo
                    </button>
                )}
            </div>
            {withList && !!(photos || []).length && (
                <div className="assign-photo-list-wrapper">
                    <Dropzone onDrop={attachPhotos} accept="image/*">
                        {({ getRootProps, getInputProps }) => (
                            <span
                                {...getRootProps()}
                                className="assign-photo-list-item add-image-block"
                                title={buttonName}
                            >
                                <input {...getInputProps()} />
                                <button
                                    onClick={() => {}}
                                    className="btn btn-secondary btn-circle btn-icon btn-add-image"
                                    style={{ width: "2.5rem" }}
                                >
                                    <i className="fa fa-plus" />
                                </button>
                            </span>
                        )}
                    </Dropzone>
                    {photos.map((image, index) => (
                        <div
                            onClick={() => setCurrent(index)}
                            key={index}
                            className={`assign-photo-list-item ${
                                index === currentPhoto && "active-image"
                            }`}
                            style={{ background: "#fff" }}
                        >
                            <img src={image.url} />
                        </div>
                    ))}
                </div>
            )}
            {withPhotoModal && (
                <PhotoModal
                    showModal={showPhotosModal}
                    onClose={() => setShowPhotosModal(false)}
                    onDelete={detachPhoto}
                    onNext={photoNext}
                    onPrev={photoPrev}
                    photos={photos}
                    currentPhoto={currentPhoto}
                    withoutDeleteModalBtn={withoutDeleteModalBtn}
                    withList={withList}
                    attachPhotos={attachPhotos}
                    setCurrent={setCurrent}
                />
            )}
        </div>
    );
};

PhotosWrapper.defaultProps = {
    withPhotoModal: false,
    withDropzone: true,
    withDeleteButton: false,
};

PhotosWrapper.propTypes = {
    photos: PropTypes.array,
    onAddPhotos: PropTypes.func,
    onDeletePhoto: PropTypes.func,
    withPhotoModal: PropTypes.bool,
    withDropzone: PropTypes.bool,
    buttonName: PropTypes.string,
    withDeleteButton: PropTypes.bool,
    defaultPhoto: PropTypes.string,
    withoutDeleteModalBtn: PropTypes.bool,
    withList: PropTypes.bool,
    displayPhotosAsDefault: PropTypes.bool
};


const DisplayAsDefaultPhoto = ({imageURL, defaultPhoto}) => {
    const imageSrc = imageURL ?? (defaultPhoto ? ("/assets/img/" + defaultPhoto) : "/assets/img/node.jpg");

    return <React.Fragment>
        <div className="k-iconbox def" style={{padding: 10}}>
            <div className="k-iconbox__icon" style={{marginBottom: 0}}>
                <img src={imageSrc} style={{maxHeight: 200}}/>
            </div>
        </div>
    </React.Fragment>;
};

DisplayAsDefaultPhoto.propTypes = {
    imageURL: PropTypes.string,
    defaultPhoto: PropTypes.string,
};

const DisplayAsCustomPhotos = (props) => {
    const {
        photos,
        withDropzone,
        photoPrev,
        photoNext,
        setShowPhotosModal,
        currentPhoto
    } = props;

    const imageSrc = _get(photos, [currentPhoto, "url"]) ?? _get(photos, [currentPhoto]);

    return <Fragment>
        <div className={"sliderWrapper cursor-pointer " + (withDropzone ? "network-item" : "")}>
            <div className="slide-item">
                {photos.length > 1 && (
                    <Fragment>
                        <span className="sliderPrevBtn" onClick={photoPrev}>
                            <i className="fa fa-angle-left"/>
                        </span>
                        <span className="sliderNextBtn" onClick={photoNext}>
                            <i className="fa fa-angle-right"/>
                        </span>
                    </Fragment>
                )}
                <img className="previews-photos d-block m-auto"
                    src={imageSrc}
                    onClick={() => setShowPhotosModal(true)}
                />
            </div>
        </div>
    </Fragment>;
};

DisplayAsCustomPhotos.propTypes = {
    photos: PropTypes.array,
    withDropzone: PropTypes.bool,
    photoPrev: PropTypes.func,
    photoNext: PropTypes.func,
    setShowPhotosModal: PropTypes.func,
    currentPhoto: PropTypes.number
};

export default PhotosWrapper;
