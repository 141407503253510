import React, {useState} from "react";
import {ValidationError} from "../../shared";
import PropTypes from "prop-types";
import {
    get as _get,
    includes as _includes,
    each as _each
} from "lodash";
import SelectWrapper from "../../helpers/select-wrapper";

const GatewayBatchAssignForm = (props) =>
{
    const {data, gateways, inProgress, onChange, formErrors, setConfirmFlag, deviceNames} = props;


    const [fileError, setFileError] = useState("");

    const showFile = async (e) => {
        e.preventDefault();
        setFileError("");
        if (e.target.files && e.target.files.length) {
            if (!e.target.files[0].type || _includes(e.target.files[0].type, ["text/plain"])) {
                setFileError("");
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const text = (e.target.result);
                    const serialArray = text.split("\n");

                    let trimSerialArray = [];
                    _each(serialArray, (serial) => {
                        if (serial.trim()) {
                            trimSerialArray.push(serial.trim());
                        }
                    });

                    let serialText = "";
                    _each(trimSerialArray, (serial, index) => {
                        serialText += serial.trim() + (trimSerialArray.length !== index + 1 ? ", " : "");
                    });

                    onChange({
                        target: {
                            name: "serialsText",
                            value: serialText
                        }
                    });
                };
                reader.readAsText(e.target.files[0]);
            } else {
                setFileError("Invalid file format.");
            }
        }
    };

    const serialErrors = [];
    const instPointErrors = [];
    const selfTestErrors = [];
    let hasSerialErrors = false;
    {
        _get(data, "serials", []).map((serial) => {
            if (_get(formErrors, "serials[" + serial + "]")) {
                let errors = _get(formErrors, ["serials[" + serial + "]", "errors"]);
                let confirms = _get(formErrors, ["serials[" + serial + "]", "confirms"]);
                for (let type in confirms) {
                    if (type == 1) {
                        instPointErrors.push(confirms[type]);
                        continue;
                    }
                    if (type == 2) {
                        selfTestErrors.push(confirms[type]);
                        continue;
                    }
                }
                for (let i in errors) {
                    serialErrors.push(errors[i]);
                }
            }
        });
    }
    {
        if(_get(formErrors, "serialsText") || serialErrors.length || instPointErrors.length || selfTestErrors.length){
            hasSerialErrors = true;
        }
    }

    return (
        <div>
            <div className="block">
                <div className="block-body">
                    <div className="form-group">
                        <label className="text-right form-label">Gateway: <span className="color-danger">*</span></label>
                        <div className="">
                            <SelectWrapper
                                className={(_get(formErrors, "gateway_id") ? " is-invalid" : "")}
                                style={{
                                    width:"100%",
                                    display:"block",
                                }}
                                name="gateway_id"
                                value={data.gateway_id}
                                onChange={onChange}
                                disabled={inProgress}
                                placeholder={"Select Gateway"}
                            >
                                {/*<option value="">Select Gateway</option>*/}
                                {gateways.map(gateway => (
                                    <option key={gateway.gateway_id} value={gateway.gateway_id}>{gateway.name ? gateway.name + " (" + gateway.serial + ")" : gateway.serial}</option>
                                ))}formErrors
                            </SelectWrapper>
                            <ValidationError message={_get(formErrors, "gateway_id")}/>
                        </div>
                    </div>
                    <div>
                        <div className={"mb-2"}>Do one of the following:</div>
                        <ul className={"unnumerated-list"}>
                            <li>
                                Upload a file that contains a list of the serial numbers for the {deviceNames.toLowerCase() + " "}
                                that you want to add. The serial numbers are added to the <b>Serial Numbers ({deviceNames})</b> field when you select a file.
                                <div className="mb-2"><b>Note:</b> Serial numbers must be displayed in a list on separate lines.</div>
                            </li>
                            <li>
                                Enter the serial numbers manually in the <b>Serial Numbers ({deviceNames})</b> field.
                                <div className="mb-2"><b>Note:</b> Serial numbers must be displayed in a list separated by commas.</div>
                            </li>
                        </ul>
                    </div>
                    <div className="form-group">
                        <label className="text-right form-label">File: </label>
                        <div className="">
                            <input
                                type="file"
                                className={"form-control" + (fileError ? " is-invalid" : "")}
                                onChange={(e) => showFile(e)}
                            />
                            <ValidationError message={fileError}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="text-right form-label">Serial Numbers ({deviceNames}): <span className="color-danger">*</span></label>
                        <div className="">
                            <textarea
                                className={"form-control" + (hasSerialErrors ? " is-invalid" : "")}
                                name={"serialsText"}
                                style={{height: "150px"}}
                                onChange={onChange}
                                value={data.serialsText}
                            />
                            <ValidationError message={_get(formErrors, "serialsText")}/>
                            {serialErrors.map((error, index) =>
                                <div className={"invalid-feedback position-relative"} key={index}>{error}</div>
                            )}
                            {(!serialErrors.length > 0 && instPointErrors.length > 0) &&
                            <div className="form-group border-confirm-block">
                                <div className="col-md-12 text-center">
                                    {instPointErrors.map((error, index) =>
                                        <div className="text-error" key={index}>{error}</div>
                                    )}
                                    <div>
                                        <label className="form-checkbox">
                                            <input
                                                className="confirm-checkbox"
                                                type="checkbox"
                                                defaultChecked={false}
                                                value={"clearInstPoint"}
                                                onChange={(ev) => setConfirmFlag(ev.target.value, ev.target.checked)}
                                            />Clear Installation Point association<span/>
                                        </label>
                                    </div>
                                </div>
                            </div>}
                            {(!serialErrors.length > 0 && selfTestErrors.length > 0) &&
                            <div className="form-group border-confirm-block">
                                <div className="col-md-12 text-center">
                                    {selfTestErrors.map((error, index) =>
                                        <div className="text-error" key={index}>{error}</div>
                                    )}
                                    <div>
                                        <label className="form-checkbox">
                                            <input
                                                type="checkbox"
                                                defaultChecked={false}
                                                value={"ignoreSelfTest"}
                                                onChange={(ev) => setConfirmFlag(ev.target.value, ev.target.checked)}
                                            />Ignore Self Test<span/>
                                        </label>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

GatewayBatchAssignForm.propTypes = {
    data: PropTypes.object,
    gateways: PropTypes.array,
    inProgress: PropTypes.bool,
    onChange: PropTypes.func,
    formErrors: PropTypes.object,
    setConfirmFlag: PropTypes.func,
    deviceNames: PropTypes.string
};

export default GatewayBatchAssignForm;