import React, {useRef} from "react";
import PropTypes from "prop-types";
import {get as _get, filter as _filter} from "lodash";
import SelectWrapper from "../../helpers/select-wrapper";

const RTD_VALUE_TYPE_ID = 4;

const SensorSelect = ({value, onChange, disabled, name, equipment, filterOutTachometers, adapterChartType, withRtdSensors}) => {
    let installationPoints = _get(equipment, "installationPoints", []);

    if (filterOutTachometers && !adapterChartType) {
        installationPoints = _filter(
            installationPoints,
            (installationPoint) =>
                installationPoint.point_type === "sensor" ||
                (withRtdSensors && installationPoint?.universalAdapterParameter?.wuaSensor?.value_type_id === RTD_VALUE_TYPE_ID)
        );
    }

    if (adapterChartType) {
        installationPoints = installationPoints.filter(
            (item) => item.point_type === "adapter" && item?.universalAdapterParameter?.wuaSensor?.reading_type_id === adapterChartType
        );
    }

    const ref = useRef(null);

    return (
        <SelectWrapper
            ref={ref}
            isSearchable={false}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            menuPortalTarget={document.body}
            placeholder={"Select Sensor"}
        >
            {/*<option value="" disabled>Select sensor</option>*/}
            {installationPoints.map((installationPoint) => (
                <option
                    key={installationPoint.id}
                    value={installationPoint.id}
                >
                    {installationPoint.name}
                </option>
            ))}
        </SelectWrapper>
    );
};

SensorSelect.propTypes = {
    name: PropTypes.string,
    equipment: PropTypes.object,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    className: PropTypes.string,
    filterOutTachometers: PropTypes.bool,
    maxMenuHeight: PropTypes.number,
    adapterChartType: PropTypes.number,
    withRtdSensors: PropTypes.bool,
};

export default SensorSelect;

export const PureSensorSelect = SensorSelect;
