import Helper from "../../../../helpers/helper";
import SelectWrapper from "../../../../helpers/select-wrapper";
import CollapseLocationSelect from "../../../collapseLocationSelect/collapseLocationSelect";
import SearchInput from "../../../../pages/shared/search-input";
import PropTypes from "prop-types";
import React from "react";
import { get as _get } from "lodash";

const SearchBar = ({
    levelList = {},
    filter,
    dnd = {},
    handleFilter,
    handleSearch,
    resetFilter,
    setLocationName,
    auth,
}) => {
    let filterNotEmpty = (filter) => {
        return _get(filter, "location", "") || _get(filter, "level", "");
    };
    const { viewDashboardType } = Helper.getHashParams();

    return (
        <div className="header-rules mr-4 form-list form-list--row">
            {auth.userCan("showAlarms") && (
                <React.Fragment>
                    <div className="filter-item">
                        <label>Alert Level</label>
                    </div>
                    <div className="form-group form-group--inline">
                        <SelectWrapper
                            onChange={handleFilter}
                            value={_get(filter, ["level"]) || ""}
                            name="level"
                            style={{ marginLeft: 5, width: 150 }}
                            disabled={dnd.isSorting}
                        >
                            <option value="">All</option>
                            {Object.keys(levelList).map((key) => (
                                <option key={key} value={levelList[key]}>
                                    {levelList[key]}
                                </option>
                            ))}
                        </SelectWrapper>
                    </div>
                </React.Fragment>
            )}
            {auth.userCan("showFullServiceEquipments") &&
                auth.customer.system_version === "hybrid-service" && (
                <React.Fragment>
                    <div className="filter-item">
                        <label>Service</label>
                    </div>
                    <div className="form-group form-group--inline">
                        <SelectWrapper
                            onChange={handleFilter}
                            value={_get(filter, ["service"]) || ""}
                            name="service"
                            style={{ marginLeft: 5, width: 150 }}
                            disabled={dnd.isSorting}
                        >
                            <option value="">All</option>
                            <option value="full">Full Service</option>
                            <option value="self">Self Service</option>
                        </SelectWrapper>
                    </div>
                </React.Fragment>
            )}
            <div className="filter-item">
                <label>Asset Tree Branch</label>
            </div>
            <div className="form-group form-group--inline">
                <CollapseLocationSelect
                    style={{
                        marginLeft: 5,
                        width: 150,
                    }}
                    selectName={"location"}
                    value={+_get(filter, ["location"], 0)}
                    onChange={handleFilter}
                    disabled={dnd.isSorting}
                    emptyOptionLabel={"All"}
                    setLocationName={setLocationName}
                    checkStoreValue={true}
                    withFavorite={true}
                />
            </div>
            {filterNotEmpty(filter) && (
                <div className="filter-item">
                    <button
                        onClick={resetFilter}
                        className="btn btn-primary btn-sm"
                    >
                        Clear
                    </button>
                </div>
            )}
            {viewDashboardType !== "tree" && (
                <div className="filter-item global-search">
                    <SearchInput
                        placeholder="Search Equipment"
                        query={_get(filter, ["search"], "")}
                        additionalClasses={"form-control-sm"}
                        onChange={handleSearch}
                        disabled={dnd.isSorting}
                    />
                </div>
            )}
        </div>
    );
};
SearchBar.propTypes = {
    levelList: PropTypes.object,
    filter: PropTypes.any,
    dnd: PropTypes.object,
    handleFilter: PropTypes.func,
    handleSearch: PropTypes.func,
    resetFilter: PropTypes.func,
    setLocationName: PropTypes.func,
    auth: PropTypes.object,
};
export default SearchBar;
