import React, {Component} from "react";
import PropTypes from "prop-types";
import AlertsRow from "./row";
import {
    get as _get,
} from "lodash";

class AlertsTable extends Component
{
    render() {
        const {installationPoints, readingTypes, alertLevels, showModal, profile} = this.props;

        return (
            <React.Fragment>
                <table className="table table-bordered sticky-wrapper alerts-table">
                    <thead className="sticky-table-header">
                        <tr>
                            <th><strong>Installation Point</strong></th>
                            {readingTypes.map((type, index) =>
                                <th key={index}>{_get(type, "label") + ", " + _get(type, "unit")}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {installationPoints.map((point, index) =>
                            <AlertsRow
                                key={index}
                                pointIndex={index}
                                installationPoint={point}
                                alertLevels={alertLevels}
                                readingTypes={readingTypes}
                                showModal={showModal}
                                profile={profile}
                            />
                        )}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}


AlertsTable.propTypes = {
    installationPoints: PropTypes.array,
    readingTypes: PropTypes.array,
    alertLevels: PropTypes.array,
    showModal: PropTypes.func,
    profile: PropTypes.object
};

export default AlertsTable;