import React, {Component} from "react";
import {Modal} from "../shared";
import {get as _get, isEmpty, forEach as _forEach} from "lodash";
import FormValidator from "../helpers/form-validator";
import ApiNodes from "../api/node";
import Toast from "../pages/shared/toast";
import Mote from "../pages/network/gateways/mote";
import SweetAlert from "react-bootstrap-sweetalert";
import WifiGroupsApi from "../api/wifiGroups";
import PropTypes from "prop-types";

const validation = [
    {
        field: "serial",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    }
];

class WifiGroupAddMote extends Component{

    constructor()
    {
        super();
        this.validator = new FormValidator(validation);

        this.checkbox = [];

        this.setCheckboxRef = element => {
            const value = _get(element, "value");
            this.checkbox[value] = element;
        };

        this.state = {
            data: {
                serial: "",
                wifiGroupId: null,
                wifiGroupName: null
            },
            validation: this.validator.valid(),
            inProgress: false,
            find: false,
            node:{},
            confirm: false,
            modal:true,
            nodesForUpdate: [],
            confirmChangeGroup: false
        };
    }

    componentDidMount()
    {
        this.validator = new FormValidator(validation);
        this.setState({
            data:{
                serial:"",
                wifiGroupId:this.props.wifiGroupId,
                wifiGroupName: this.props.wifiGroupName
            },
            find: false,
            node:{},
            nodesForUpdate: []
        });
        this.handeNodeTable();
    }

    handeNodeTable(serial) {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        this.setState({inProgress: true});
        let apiNodesParams = {
            query: {
                filter: {
                    lot_data:"MOTE",
                    wifi_config_group_id: this.props.wifiGroupId
                }
            },
            signal: this.signal
        };

        if(serial) apiNodesParams.query.filter.serial = serial;

        ApiNodes.getList(apiNodesParams).then((response) => {
            if (response && response.list) {
                const node = _get(response, "list");
                this.setState({node: node, inProgress: false});
            }
        });
    }

    handleChange = e =>
    {
        this.controller.abort();
        const data = {...this.state.data};
        const value = _get(e, "target.value", "");
        const name = _get(e, "target.name", "");
        data.serial = parseInt(value, 16);
        const validation = this.validator.validate(data, name);
        this.setState({data, validation, find: false, node:{}});
        this.handeNodeTable(value);
    };

    handleChangeCheckbox = e =>
    {
        e.checked = !e.checked;
        const value = _get(e, "value", "");
        const checked = _get(e, "checked", "");
        if(checked) {
            this.setState({nodesForUpdate: [...this.state.nodesForUpdate, value]});
        } else {
            this.setState({nodesForUpdate: this.state.nodesForUpdate.filter(serial => serial !== value)});
        }
    }

    handleConfirm = () =>
    {
        this.setState({confirmChangeGroup: false});
    }

    handleSubmitSave = () =>
    {
        this.setState({ inProgress: true });
        const state = {...this.state};
        if(!isEmpty(state.nodesForUpdate)){
            _forEach(state.nodesForUpdate, function (node) {
                _forEach(state.node, function (item) {
                    if(item.serial == node) {
                        let updateData = {
                            wifi_config_group_id: state.data.wifiGroupId,
                            location: item.location_info,
                            location_id: item.location_id,
                            gateway_id: item.gateway_id
                        };
                        ApiNodes.update(node, updateData);
                    }
                });
            });

            this.props.onClose();
            WifiGroupsApi
                .getView(state.data.wifiGroupId)
                .then((response) => {
                    this.props.addMote(_get(response, "nodes", []));
                    this.setState({
                        confirm: false,
                        node: {},
                        inProgress: false,
                        find: false
                    });
                    Toast.success("The wifi sensor motes have been added.");
                });
        } else {
            Toast.error("Nothing selected");
        }
    }

    render()
    {
        const {
            data,
            inProgress,
            validation,
            find,
            node,
            confirm
        } = this.state;
        return (
            <Modal
                {...this.props}
                onSubmit={this.handleSubmitSave}
                submitTitle="Save"
                inProgress={inProgress}
                disabled={find}
                size="lg"
            >
                {this.state.confirmChangeGroup && <SweetAlert
                    warning
                    show={!!this.state.confirmChangeGroup}
                    title="CHANGE GROUP"
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    onCancel={this.handleConfirm}
                    onConfirm={
                        ()=>{
                            this.handleChangeCheckbox(this.checkbox[this.state.confirmChangeGroup]);
                            this.handleConfirm();
                        }
                    }
                >
                    <React.Fragment>Are you sure you want to change the Wifi mote group to {data.wifiGroupName} ?
                    </React.Fragment>
                </SweetAlert>}
                <div className="form-group">
                    <label htmlFor="name">Add Wifi Sensor Mote to this Wifi Mote Group</label>
                    <input
                        className={"form-control" + (validation.serial.isValid || !validation.serial.message ? "" : " is-invalid")}
                        type="text"
                        name="serial"
                        onChange={this.handleChange}
                    />
                    {!find && !isEmpty(node) ? <table className="table table-hover tl-fixed">
                        <thead>
                            <tr>
                                <th>Serial Number</th>
                                <th>Version</th>
                                <th>Wifi Mote Group</th>
                                <th>Location</th>
                                <th>Last Seen</th>
                                <th>Add to Group</th>
                            </tr>
                        </thead>
                        <tbody>
                            {node.map((item) => {
                                return <tr key={item.serial}>
                                    <td>{parseInt(item.serial).toString(16).toUpperCase()}</td>
                                    <td>{_get(item, "code_version", "---")}</td>
                                    <td>{_get(item, "wifi_config_group_name", "---")}</td>
                                    <td>{_get(item, "location.name", "---")}</td>
                                    <td>{_get(item, "last_seen", "---")}</td>
                                    <td>
                                        <label
                                            className="switch"
                                            onClick={
                                                (e)=>{
                                                    e.preventDefault();
                                                    if(_get(item, "wifi_config_group_name") == null) {
                                                        this.handleChangeCheckbox(this.checkbox[item.serial]);
                                                    } else {
                                                        if(!this.checkbox[item.serial].checked) {
                                                            this.setState({confirmChangeGroup: item.serial});
                                                        } else {
                                                            this.handleChangeCheckbox(this.checkbox[item.serial]);
                                                            this.handleConfirm();
                                                        }
                                                    }
                                                }
                                            }
                                        >
                                            <input
                                                type="checkbox"
                                                value={item.serial}
                                                ref={this.setCheckboxRef}
                                            />
                                            <span className="slider round"/>
                                        </label>
                                    </td>
                                </tr>;
                            }
                            )}
                        </tbody>
                    </table> : <div style={{textAlign:"center", marginTop:"10px"}}>{inProgress ? "Searching" : find  ? "" : "No results were found."}</div>}
                    { find && !isEmpty(node) && <Mote mote={node}></Mote>}
                </div>
                {confirm && <SweetAlert
                    warning
                    show={confirm}
                    title="ADD MOTE"
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    onCancel={() => {this.setState({confirm: false});}}
                    onConfirm={this.handleSubmitSave}
                >
                    <React.Fragment>Are you sure you want to add this mote to Wifi Group [{data.wifiGroupName}] ?</React.Fragment>
                </SweetAlert>}
            </Modal>
        );
    }

}

WifiGroupAddMote.propTypes = {
    wifiGroupId: PropTypes.string,
    wifiGroupName: PropTypes.string,
    onClose: PropTypes.func,
    addMote: PropTypes.func
};

export default WifiGroupAddMote;