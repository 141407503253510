import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, ValidationError} from "../../../shared";
import FormValidator from "../../../helpers/form-validator";
import Helper from "../../../helpers/helper";
import {DATEFORMAT, SERVER_DATETIME_FORMAT} from "../../../constants/constants";
import {get as _get, find as _find} from "lodash";
import moment from "moment";
import SensorSelect from "../../../pages/shared/sensor-select";

const rules = [
    {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "Name cannot be empty.",
    },
];

class AddChartMarkModal extends Component {
    BIND_TYPE = {
        EQUIPMENT: 1,
        INSTALLATION_POINT: 2,
    };

    constructor(props) {
        super(props);
        this.validator = new FormValidator(rules);

        this.dateFormat = _get(this.props.user, "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0]);
        this.isEdit = !!this.props.chartMarkId;

        if (this.isEdit) {
            this.chartMark = _find(this.props.chartMarks, ["id", this.props.chartMarkId]);
        }
        let installationPointId = _get(this.props.equipment, ["installationPoints", 0, "id"], null);

        if (this.props.equipmentSelected[this.props.equipment.id].length) {
            installationPointId = _get(this.props.equipmentSelected[this.props.equipment.id], 0, null);
        }

        this.state = {
            validation: this.validator.valid(),
            inProgress: false,
            bindType: this.isEdit && this.chartMark.installation_point_id ? this.BIND_TYPE.INSTALLATION_POINT : this.BIND_TYPE.EQUIPMENT,
            data: {
                installationPointId:
                    this.isEdit && this.chartMark.installation_point_id ? this.chartMark.installation_point_id : installationPointId,
                equipmentId: this.props.equipment.id,
                name: this.isEdit ? this.chartMark.name : "",
                notes: this.isEdit ? this.chartMark.notes : "",
                timestamp: this.isEdit ? +moment.utc(this.chartMark.timestamp).format("x") : this.props.timestamp,
            },
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeBindType = this.onChangeBindType.bind(this);
    }

    onChange(event) {
        const data = {...this.state.data};

        data[event.target.name] = Helper.getInputValue(event.target);

        this.setState({data});
    }

    onSubmit() {
        this.setState({inProgress: true}, () => {
            const validation = this.validator.validate(this.state.data);

            this.setState({validation}, () => {
                if (validation.isValid) {
                    if (this.isEdit) {
                        this.editChartMark(this.getData()).catch(() => this.setState({inProgress: false}));
                    } else {
                        this.createChartMark(this.getData()).catch(() => this.setState({inProgress: false}));
                    }
                } else {
                    this.setState({inProgress: false});
                }
            });
        });
    }

    createChartMark(data) {
        return this.props.onCreate(data).then(() => {
            this.props.updateCountGlobalNotes();
            this.props.updateEquipmentNotesCount();
            this.props.onClose();
        });
    }

    editChartMark(data) {
        return this.props.onUpdate(data).then(() => {
            this.props.onClose();
        });
    }

    onChangeBindType(ev) {
        this.setState({bindType: +ev.target.value});
    }

    getData() {
        return {
            name: this.state.data.name,
            notes: this.state.data.notes,
            timestamp: moment(this.state.data.timestamp).utc().format(SERVER_DATETIME_FORMAT),
            equipmentId: this.state.data.equipmentId,
            installationPointId: this.state.bindType === this.BIND_TYPE.INSTALLATION_POINT ? this.state.data.installationPointId : null,
        };
    }

    render() {
        const {inProgress, validation} = this.state;
        const colWidth = this.state.bindType === this.BIND_TYPE.EQUIPMENT ? 6 : 4;

        return (
            <Modal
                {...this.props}
                size="lg"
                title={"Mark selected time"}
                className={"custom-modal"}
                onSubmit={this.onSubmit}
                inProgress={this.state.inProgress}
                showModal={true}
            >
                <div className="row">
                    <div className="form-group col-6 mb-2">
                        <label>Asset Code:</label>
                        <p className="mb-2">{this.props.equipment.asset_code}</p>
                    </div>
                    <div className="form-group col-6 mb-2">
                        <label>Equipment:</label>
                        <p className="mb-2">{this.props.equipment.name}</p>
                    </div>
                </div>
                <hr />
                <React.Fragment>
                    <div className="row">
                        {this.state.bindType === this.BIND_TYPE.INSTALLATION_POINT && (
                            <div className={`form-group col-${colWidth}`}>
                                <label className="form-label">Sensor</label>
                                <SensorSelect
                                    name="installationPointId"
                                    value={this.state.data.installationPointId}
                                    onChange={this.onChange}
                                    equipment={this.props.equipment}
                                />
                            </div>
                        )}
                        <div className={`form-group mb-1 col-${colWidth}`}>
                            <label className="form-label">Timestamp</label>
                            <input
                                type="text"
                                className={"form-control"}
                                value={Helper.dateToFormat(this.state.data.timestamp, this.dateFormat)}
                                disabled={true}
                            />
                        </div>
                        <div className={`form-group mb-1 col-${colWidth}`}>
                            <label className="form-label">
                                Name <span className="color-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className={"form-control" + (validation.name.isValid || !validation.name.message ? "" : " is-invalid")}
                                name="name"
                                placeholder="Enter Mark Name"
                                disabled={inProgress}
                                onChange={this.onChange}
                                value={this.state.data.name}
                            />
                            <ValidationError message={validation.name.message} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-12">
                            <label className="form-label">Notes</label>
                            <textarea
                                className={"form-control"}
                                style={{height: "100px"}}
                                name="notes"
                                disabled={inProgress}
                                onChange={this.onChange}
                                value={this.state.data.notes}
                            />
                        </div>
                    </div>
                    <div className="row form-group">
                        <label className="col-2">Set For:</label>
                        <div className="axis-block-container col-10">
                            <div
                                className={`axis-block ${this.state.bindType === this.BIND_TYPE.INSTALLATION_POINT && "active"}`}
                                onClick={() =>
                                    this.onChangeBindType({
                                        target: {
                                            name: "bindType",
                                            value: this.BIND_TYPE.INSTALLATION_POINT,
                                        },
                                    })
                                }
                            >
                                <span>Installation Point Only</span>
                            </div>
                            <div
                                className={`axis-block ${this.state.bindType === this.BIND_TYPE.EQUIPMENT && "active"}`}
                                onClick={() =>
                                    this.onChangeBindType({
                                        target: {
                                            name: "bindType",
                                            value: this.BIND_TYPE.EQUIPMENT,
                                        },
                                    })
                                }
                            >
                                <span>For This Equipment</span>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </Modal>
        );
    }
}

AddChartMarkModal.propTypes = {
    equipment: PropTypes.object,
    timestamp: PropTypes.number,
    chartMarkId: PropTypes.number,
    chartMarks: PropTypes.array,
    user: PropTypes.object,
    history: PropTypes.object,
    onCreate: PropTypes.func,
    onClose: PropTypes.func,
    onUpdate: PropTypes.func,
    equipmentSelected: PropTypes.object,
    updateCountGlobalNotes: PropTypes.func,
    updateEquipmentNotesCount: PropTypes.func,
};

export default AddChartMarkModal;
