import React, {Component} from "react";
import PropTypes from "prop-types";
import SubscriptionActonItems from "../network/action-items/subscription/subscription";

class ActionItemsForm extends Component
{
    render() {
        const {
            loader,
            data,
            urgencies,
            facilities,
            emailEvents,
            selectedFacilityId,
            changeFacility,
            changeData,
            onSave,
            toggleAllForFacility,
            toggleAll,
            user,
        } = this.props;

        return (
            <React.Fragment>
                <div className="block">
                    <div className="block-header">
                        <div className="block-label">
                            <h5 className="block-title">Action Item Email Notifications</h5>
                        </div>
                    </div>
                    <form
                        ref={el => this.el = el}
                        className="k-form k-form--label-right"
                        onSubmit={e => {e.preventDefault(); onSave();}}
                    >
                        <div className="block-body">
                            <div className="k-section k-section--first">
                                <div className="k-section__body profile-form">

                                    <SubscriptionActonItems
                                        loader={loader}
                                        data={data}
                                        urgencies={urgencies}
                                        facilities={facilities}
                                        user={user}
                                        emailEvents={emailEvents}
                                        selectedFacilityId={selectedFacilityId}
                                        changeFacility={changeFacility}
                                        changeData={changeData}
                                        toggleAllForFacility={toggleAllForFacility}
                                        fromProfile={true}
                                        toggleAll={toggleAll}
                                    />

                                    <div className="form-group row">
                                        <label className="col-3" />
                                        <div className="col-9 col-lg-6 text-right">
                                            <button
                                                type="button"
                                                className="btn btn-success btn-sm"
                                                onClick={onSave}
                                                disabled={loader}
                                            >
                                                {loader ? <span><i className="fa fa-spinner" /> Processing</span> : "Submit"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

ActionItemsForm.propTypes = {
    loader: PropTypes.bool,
    data: PropTypes.object,
    urgencies: PropTypes.object,
    user: PropTypes.object,
    facilities: PropTypes.array,
    emailEvents: PropTypes.array,
    selectedFacilityId: PropTypes.number,
    changeFacility: PropTypes.func,
    changeData: PropTypes.func,
    onSave: PropTypes.func,
    toggleAllForFacility: PropTypes.func,
    toggleAll: PropTypes.func
};

export default ActionItemsForm;