import {useEffect} from "react";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {useLocation} from "react-router";

const MatomoLayout = ({children}) => {
    const { trackPageView } = useMatomo();
    const location = useLocation();


    useEffect(() => {
        trackPageView({
            documentTitle: document.title,
            href: window.location.href,
        });
    }, [location.pathname]);

    return children;
};

export default MatomoLayout;