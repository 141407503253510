import {get as _get} from "lodash";
import Helper from "../../../../helpers/helper";

export const getSeriesList = (chartType, axisId, chartTypes) => {
    const list = _get(chartTypes, `${chartType}.series`, {});
    axisId = axisId || Object.keys(list).shift();
    const selected = [axisId];

    let units = _get(chartTypes, `${chartType}.series.${axisId}.units`, "");

    if (_get(chartTypes, `${chartType}.type`, "") === "twf") {
        units = Helper.removeSubstrings(units, ["(PK-PK)", "(PK)"]);
    }
    return {
        list,
        selected,
        units,
        readingType: [_get(list, [axisId, "readingType"], "")],
        precision: _get(chartTypes, `${chartType}.series.${axisId}.precision`),
        title: _get(chartTypes, `${chartType}.label`, "") + (units && `, ${units}`),
        label: _get(chartTypes, `${chartType}.label`, ""),
    };
};
