import {useQuery} from "@tanstack/react-query";
import {SIGNAL_KEYS} from "./constants";
import ApiRouter from "../../../api/router";
import {EQUIPMENT_TYPE_REPEATER} from "../../../constants/constants";

export const useSignalStrengthQuery = (serial, type, timeRange, dateRange) => {
    if (type === EQUIPMENT_TYPE_REPEATER) {
        return useQuery([SIGNAL_KEYS.SIGNAL_STRENGTH, {serial, timeRange, dateRange}], () =>
            ApiRouter.signalStrength(serial, {selectedDays: timeRange, selectedDates: dateRange})
        );
    }
};
