import React from "react";
import PropTypes from "prop-types";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {Loader} from "../shared";

const ToggleModal = (props) => {
    const {
        enabled,
        showModal,
        title,
        children,
        cancelTitle,
        submitTitle,
        size = "",
        centered = false,
        withoutFooter = false,
        fftWaterfallFlag = false,
        loader = false,
    } = props;
    const {onClose, onSubmit} = props;

    if (loader) {
        return <Loader />;
    }
    if (enabled) {
        return (
            <Modal
                isOpen={showModal}
                toggle={onClose}
                size={size}
                centered={centered}
                className="position-relative"
            >
                {title !== "" && <ModalHeader toggle={onClose}>{title}</ModalHeader>}
                {children && <ModalBody className={fftWaterfallFlag === true ? "waterfall-body" : ""}>{children}</ModalBody>}
                {!withoutFooter && (
                    <ModalFooter>
                        <Button
                            onClick={onClose}
                            color={"secondary"}
                            className="btn-sm"
                        >
                            {cancelTitle ? cancelTitle : "Cancel"}
                        </Button>
                        <Button
                            onClick={onSubmit}
                            color={"info"}
                            className="btn-sm"
                        >
                            {submitTitle ? submitTitle : "Save"}
                        </Button>
                    </ModalFooter>
                )}
            </Modal>
        );
    } else {
        return (
            <React.Fragment>{children && <ModalBody className={fftWaterfallFlag === true ? "waterfall-body" : ""}>{children}</ModalBody>}</React.Fragment>
        );
    }
};

ToggleModal.propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    showModal: PropTypes.bool,
    customStyles: PropTypes.object,
    children: PropTypes.any,
    title: PropTypes.string,
    submitTitle: PropTypes.string,
    cancelTitle: PropTypes.string,
    progress: PropTypes.object,
    centered: PropTypes.bool,
    enabled: PropTypes.bool,
    size: PropTypes.string,
    className: PropTypes.string,
    withoutFooter: PropTypes.bool,
    fftWaterfallFlag: PropTypes.bool,
    loader: PropTypes.bool,
};

ToggleModal.defaultProps = {
    progress: {active: false},
    enabled: true,
};

export default ToggleModal;
