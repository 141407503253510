import {toast} from "react-toastify";

const getOptions = (options, containerId = "mainToast") => ({
    position: toast.POSITION.TOP_CENTER,
    autoClose: 5000,
    zIndex: 10003,
    newestOnTop: true,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    containerId: containerId,
    ...(options || {})
});

export const success = (content, options, containerToast) => toast.success(content, getOptions(options, containerToast));
export const warning = (content, options, containerToast) => toast.warn(content, getOptions(options, containerToast));
export const error = (content, options, containerToast) => toast.error(content, getOptions(options, containerToast));
export const info = (content, options, containerToast) => toast.info(content, getOptions(options, containerToast));

export default {info, success, warning, error};