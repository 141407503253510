import React, {useRef} from "react";
import {UncontrolledTooltip} from "reactstrap";
import {ReactComponent as Heatsink} from "../../../../src/assets/pic/radiator.svg";

export const HeatsinkLabel = () => {
    const targetRef = useRef(null);

    return (
        <>
            <div
                className="badge badge-warning cursor-pointer mr-2"
                ref={targetRef}
            >
                <Heatsink style={{width: "16px", height: "12px"}} />
            </div>
            <UncontrolledTooltip
                target={targetRef}
                placement="top"
            >
                This sensor has an insulator installed on it.
            </UncontrolledTooltip>
        </>
    );
};
