import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal} from "reactstrap";
import {
    get as _get,
    set as _set,
    cloneDeep as _cloneDeep,
    findIndex as _findIndex,
    isNull as _isNull,
    forEach as _forEach,
    find as _find,
    setWith as _setWith,
    each as _each,
    filter as _filter
} from "lodash";
import SimpleAlertTemplate from "../../../../modals/alert/parts/simple-alert-template";

class AlertsModal extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            alertsConditions: this.getDefaultAlertConditions(),
            currentAxisId: null,
            currentAlertLevelId: this.getDefaultAlertLevel(),
        };
    }

    getDefaultAlertConditions = () => {
        const {profile, installationPointIndex} = this.props;
        let alertsConditions = _get(profile, "alertsConditions."+installationPointIndex, []);

        return _cloneDeep(alertsConditions);
    }

    getDefaultAlertLevel = () => {
        const {alertLevels} = this.props;
        const firstLevelKey = _get(Object.keys(alertLevels), "0");

        return +_get(alertLevels, firstLevelKey+".id");
    }

    setCurrentAxisId = (currentAxisId) => {
        this.setState({currentAxisId: currentAxisId});
    }

    setCurrentAlertLevelId = (currentAlertLevelId) => {
        this.setState({currentAlertLevelId: currentAlertLevelId});
    }

    onChange = (data) => {
        const {currentAxisId} = this.state;

        return _isNull(currentAxisId) ? this.batchChange(data) : this.oneChange(data, currentAxisId);
    }

    batchChange = (data) => {
        const {readingType} = this.props;
        const axis = _get(readingType, "axis", []);
        let alertsConditions = [...this.state.alertsConditions];

        _forEach(axis, (axis, axisId) => {
            alertsConditions = this.setData(data, alertsConditions, +axisId);
        });

        this.setState({alertsConditions});
    }

    oneChange = (data, axisId) => {
        let alertsConditions = [...this.state.alertsConditions];

        this.setState({
            alertsConditions: this.setData(data, alertsConditions, axisId)
        });
    }

    setData = (data, alertsConditions, axisId) => {
        const {currentAlertLevelId} = this.state;

        const index = _findIndex(alertsConditions, {axisId, alertLevelId: currentAlertLevelId, alias: this.getReadingTypeAlias()});
        const condition = _get(alertsConditions, index, this.getDefaultCondition(axisId, currentAlertLevelId));

        if (index >= 0) {
            _set(alertsConditions, index, {...condition, ...data});
        } else {
            alertsConditions.push({...condition, ...data});
        }

        return alertsConditions;
    }

    onRemove = () => {
        const {currentAxisId} = this.state;

        return _isNull(currentAxisId) ? this.batchRemove() : this.oneRemove(currentAxisId);
    }

    batchRemove = () => {
        const {readingType} = this.props;
        const axis = _get(readingType, "axis", []);
        let alertsConditions = [...this.state.alertsConditions];

        _forEach(axis, (axis, axisId) => {
            alertsConditions = this.unsetData(alertsConditions, +axisId);
        });

        this.setState({alertsConditions});
    }

    oneRemove = (axisId) => {
        let alertsConditions = [...this.state.alertsConditions];

        this.setState({
            alertsConditions: this.unsetData(alertsConditions, axisId)
        });
    }

    unsetData = (alertsConditions, axisId) => {
        const {currentAlertLevelId} = this.state;

        const index = _findIndex(alertsConditions, {axisId, alertLevelId: currentAlertLevelId, alias: this.getReadingTypeAlias()});

        if (index >= 0) {
            alertsConditions.splice(index, 1);
        }

        return alertsConditions;
    }

    findCondition = (alertLevelId) => {
        const {currentAxisId} = this.state;

        return _isNull(currentAxisId) ? this.getAllCondition(alertLevelId) : this.find(alertLevelId);
    }

    find = (alertLevelId) => {
        const {alertsConditions, currentAxisId} = this.state;

        const index = _findIndex(alertsConditions, {axisId: currentAxisId, alertLevelId, alias: this.getReadingTypeAlias()});

        return this.getByIndex(index);
    }

    first = (alertLevelId) => {
        const {alertsConditions} = this.state;

        const index = _findIndex(alertsConditions, {alertLevelId, alias: this.getReadingTypeAlias()});

        return this.getByIndex(index);
    }

    getAllCondition = (alertLevelId) => {
        const {alertsConditions} = this.state;
        const {readingType} = this.props;
        const axis = _get(readingType, "axis", {});
        let defaultCondition = this.getDefaultCondition(null, alertLevelId);

        const filteredByAlertLevel = _filter(alertsConditions, {alertLevelId, alias: this.getReadingTypeAlias()});
        if (Object.keys(axis).length !== filteredByAlertLevel.length) {
            return  defaultCondition;
        }

        _each(["notificationType", "requireReadings", "condition", "value"], (key) => {
            let currentConditionValue = null;

            let result = true;
            _each(filteredByAlertLevel, (condition) => {
                if (currentConditionValue === null) {
                    currentConditionValue = _get(condition, key);
                } else if (_get(condition, key) != currentConditionValue) {
                    result = false;
                }
            });

            if (result) {
                _set(defaultCondition, key, currentConditionValue);
            }
        });

        return defaultCondition;
    }

    getByIndex = (index) => {
        const {alertsConditions, currentAlertLevelId, currentAxisId} = this.state;

        return _get(alertsConditions, index, this.getDefaultCondition(currentAxisId, currentAlertLevelId));
    }

    getDefaultCondition = (axisId, alertLevelId) => {
        return {
            axisId,
            alertLevelId,
            readingTypeId: this.getReadingTypeId(axisId),
            notificationType: 1,
            requireReadings: 2,
            condition: ">=",
            value: "",
            alias: this.getReadingTypeAlias()
        };
    }

    getReadingTypeId = (axisId) => {
        const {readingType} = this.props;

        return +_get(readingType, "axis."+axisId+".readingType", 0);
    }

    getReadingTypeAlias = () => {
        const {readingType} = this.props;

        return _get(readingType, "alias");
    }

    onConfirm = () => {
        const {alertsConditions} = this.state;
        const {onChange, installationPointIndex, onClose, profile} = this.props;
        let currentAlertsConditions = _get(profile, "alertsConditions", {});
        let newAlertsConditions = {};

        _each(currentAlertsConditions, (conditions, pointIndex) => {
            _setWith(newAlertsConditions, pointIndex, conditions, Object);
        });
        _setWith(newAlertsConditions, installationPointIndex, alertsConditions, Object);

        onChange({
            target: {
                name: "alertsConditions",
                value: newAlertsConditions
            }
        });

        onClose();
    }

    render() {
        const {currentAxisId, currentAlertLevelId} = this.state;
        const {onClose, profile, installationPointIndex, readingType, alertLevels, conditions} = this.props;
        const installationPoint = _get(profile, "installationPoints."+installationPointIndex);
        const axis = _get(readingType, "axis", {});
        const cautionLevel = _find(alertLevels, {name: "Caution"});
        const cautionLevelId = _get(cautionLevel, "id");

        return (
            <React.Fragment>
                <Modal
                    isOpen={true}
                    toggle={onClose}
                    size={"lg"}
                    centered={false}
                >
                    <div className={"profile-alerts-modal"}>
                        <div className={"profile-modal-header modal-header"}>
                            <div className={"title"}>Set Default Alert Levels</div>
                            <div className={"close"} onClick={onClose}></div>
                        </div>

                        <hr/>

                        <div className={"profile-modal-body"}>
                            <div className={"row pt-4 pb-4"}>
                                <div className={"col-md-6"}>
                                    <strong className={"mr-3"}>Reading Type:</strong>
                                    {_get(readingType, "label") + ", " + _get(readingType, "unit")}
                                </div>
                                <div className={"col-md-6"}>
                                    <strong className={"mr-3"}>Installation Point:</strong>
                                    {_get(installationPoint, "name")}
                                </div>
                            </div>

                            <hr/>

                            {Object.keys(axis).length > 1 &&
                                <AxisTypeControl
                                    axis={axis}
                                    currentAxisId={currentAxisId}
                                    setCurrentAxisId={this.setCurrentAxisId}
                                />
                            }

                            {alertLevels.map((level, index) => {
                                const isCurrent = +currentAlertLevelId === +level.id;

                                return (
                                    <SimpleAlertTemplate
                                        key={index}
                                        units={_get(readingType, "unit")}
                                        level={level}
                                        alert={this.findCondition(+level.id)}
                                        isCurrent={isCurrent}
                                        isProfile={true}
                                        cautionValue={+_get(this.findCondition(cautionLevelId), "value", 0)}
                                        conditions={conditions}
                                        setCurrent={() => this.setCurrentAlertLevelId(+level.id)}
                                        update={(data) => this.onChange(data)}
                                        remove={this.onRemove}
                                    />
                                );
                            })}
                        </div>

                        <hr/>

                        <div className={"row profile-modal-bottom justify-content-end"}>
                            <div className={"col-3"}>
                                <div
                                    onClick={onClose}
                                    className="btn btn-secondary"
                                >
                                    Cancel
                                </div>
                            </div>
                            <div className={"col-3"}>
                                <div
                                    className={"btn btn-primary"}
                                    onClick={this.onConfirm}
                                >
                                    Save
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </React.Fragment>
        );
    }
}


const AxisTypeControl = ({axis, currentAxisId, setCurrentAxisId}) => {
    return (
        <React.Fragment>
            <div className="form-group row mb-2">
                <label className="col-4">Axis Type:</label>
                <div className="col-8">
                    <div className="axis-block-container">
                        <div
                            className={`axis-block ${!currentAxisId ? "active" : ""}`}
                            onClick={() => setCurrentAxisId(null)}
                        >
                            <span>All</span>
                        </div>
                        {Object.keys(axis).map((axisId, index) =>
                            <div
                                key={index}
                                className={`axis-block ${+currentAxisId === +axisId ? "active" : ""}`}
                                onClick={() => setCurrentAxisId(+axisId)}
                            >
                                <span>{_get(axis, axisId+".axisName")}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

AxisTypeControl.propTypes = {
    axis: PropTypes.object,
    currentAxisId: PropTypes.number,
    setCurrentAxisId: PropTypes.func
};


AlertsModal.propTypes = {
    profile: PropTypes.object,
    readingType: PropTypes.object,
    alertLevels: PropTypes.array,
    installationPointIndex: PropTypes.number,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    conditions: PropTypes.object
};

export default AlertsModal;