import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Modal, Pager, Loader} from "../../../shared";
import TableActive from "../../../pages/alarms/table-active";
import ApiAlarms from "../../../api/alarm";
import AlarmFilters from "../../../pages/alarms/alarm-filters";
import SearchInput from "../../../pages/shared/search-input";
import {get as _get} from "lodash";
import LimitSelect from "../../../pages/shared/limit-select";
import {withGlobalStore} from "../../../stores/GlobalStore";
import Helper from "../../../helpers/helper";
import {useAlarmList} from "../../../hooks/api/Alarm/UseAlarmList";
import {useParams} from "react-router";

const customStyles = {
    content: {
        top: "5%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        padding: "0",
        width: "80%",
        maxWidth: "500px",
        marginRight: "-50%",
        transform: "translate(-50%, 0)",
    },
};

const ActiveModal = (props) => {
    const xhrController = {};

    const [state, setState] = useState({
        loader: true,
        list: [],
        pagination: {
            page: 1,
            pages: 1,
            perpage: 10,
            total: 0,
        },
        sort: {
            field: "",
            sort: "asc",
        },
        filters: {
            query: "",
        },
        selectedLevel: null,
    });

    useEffect(() => {
        handleFetch();
    }, []);

    //TODO REWORK - temp fix before deploy
    const params = useParams();
    const {isRefetching} = useAlarmList(params.equipmentId);

    useEffect(() => {
        if (state.loader === true || isRefetching) {
            handleFetch();
        }

        props.setSelectedAlarms([]);
    }, [state.loader, isRefetching]);

    const handleAcknowledgeModal = () => {
        props.history.push(Helper.setHashParams({modalAlarm: "ack", selected: "all"}));
    };

    const handleIgnoreModal = () => {
        props.history.push(Helper.setHashParams({modalAlarm: "ignore", selected: "all"}));
    };

    const handleFetch = () => {
        const {sort, pagination, filters} = state;
        const {equipmentId} = props;

        xhrController.controller = new window.AbortController();
        xhrController.signal = xhrController.controller.signal;
        xhrController.finished = false;
        setState((prev) => ({...prev, loader: true}));

        if (equipmentId) {
            ApiAlarms.getList({
                query: {
                    sort: sort,
                    filter: {
                        equipmentId,
                        status: 0,
                        ...filters,
                    },
                    pagination: pagination,
                },
                signal: xhrController.signal,
            }).then((response) => {
                const meta = _get(response, "meta", {});
                const list = _get(response, "list", []);
                setState((prev) => ({
                    ...prev,
                    loader: false,
                    pagination: {
                        ...prev.pagination,
                        ...meta,
                    },
                    list,
                    selectedLevel: null,
                }));
                xhrController.finished = true;
            });
        }
    };

    const handleSortChange = (field = "") => {
        const {sort} = state;

        setState((prev) => ({
            ...prev,
            sort: {...prev.sort, field, sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"},
            loader: true,
        }));
    };

    const handlePagerChange = (page = 1) => {
        setState((prev) => ({...prev, pagination: {...prev.pagination, page}, loader: true}));
    };

    const handleLimitChange = (e) => {
        const {name, value} = _get(e, "target", {});
        setState((prev) => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                page: 1,
                [name]: value,
            },
            loader: true,
        }));
    };

    const handleFilter = (data = {}) => {
        setState((prev) => ({
            ...prev,
            filters: {
                ...prev.filters,
                ...data,
            },
            pagination: {
                ...prev.pagination,
                page: 1,
            },
            loader: true,
        }));
    };
    const bindSearch = (value) => {
        if (!xhrController.finished) {
            xhrController.finished = true;
            xhrController.controller.abort();
        }
        handleFilter({query: value});
    };

    const handleCheckbox = (e) => {
        const {selectedLevel} = state;
        const {value} = e.target;

        let {selectedAlarms = [], excluded = [], filter = []} = props;

        if (selectedLevel === "full-all") {
            const index = excluded.indexOf(parseInt(value));
            selectedAlarms = [];

            if (index !== -1) {
                excluded.splice(index, 1);
            } else {
                excluded.push(parseInt(value));
            }
        } else {
            const index = selectedAlarms.indexOf(parseInt(value));
            if (index !== -1) {
                selectedAlarms.splice(index, 1);
            } else {
                selectedAlarms.push(parseInt(value));
            }
        }

        props.setSelectedAlarms(selectedAlarms, filter, selectedLevel, excluded);
    };

    const handleCheckboxesLevel = (levelType) => {
        let selectedAlarms = [];
        const {list = []} = state;
        setState((prev) => ({...prev, selectedLevel: levelType}));

        if (levelType === "all" || levelType === "full-all") {
            selectedAlarms = list.map((item) => item.id);
            return props.setSelectedAlarms(selectedAlarms, state.filters, levelType);
        }

        if (list.length > 0) {
            list.map((item) => {
                if (+item.alertLevel_id === +levelType) {
                    selectedAlarms.push(parseInt(item.id));
                }
            });

            return props.setSelectedAlarms(selectedAlarms);
        }

        return props.setSelectedAlarms([]);
    };

    const {loader, list, pagination, sort, filters, selectedLevel} = state;
    const {auth, history, selectedAlarms, excluded, axisLabels} = props;

    const activeModalName = _get(props.equipment, "name", "Active Alerts");
    const showAlertHeader = (selectedLevel || selectedAlarms.length > 0) && list.length > 0;

    return (
        <Modal
            customStyles={customStyles}
            withoutFooter={true}
            size="xl"
            title={props.title || activeModalName}
            {...props}
        >
            <React.Fragment>
                <div className="row mb-2">
                    <div className="col-12">
                        <span className={"secondary-text"}>
                            <i className="fa fa-info-circle mr-1" />
                            Active Alerts
                        </span>
                    </div>
                </div>
                <div className="block mb-0 sticky-custom-modal-header">
                    <div className="block-header pl-0 pr-3 py-0">
                        <div className="block-label">
                            <AlarmFilters
                                showCheckboxes={auth.userCan("editAlarms")}
                                history={history}
                                selected={selectedAlarms}
                                checkedAlarms={selectedLevel}
                                updated={handleFilter}
                                handleCheckboxes={handleCheckboxesLevel}
                            />
                            <div
                                id="top-pagination-block"
                                className="block-pagination"
                            >
                                {pagination.total > 10 && (
                                    <div className="limit-select-block">
                                        <LimitSelect
                                            name={"perpage"}
                                            onChange={handleLimitChange}
                                            defaultValue={pagination.perpage}
                                            limits={[10, 20, 50, 100]}
                                        />
                                    </div>
                                )}
                                {pagination.pages > 1 && (
                                    <Pager
                                        page={pagination.page}
                                        pages={pagination.pages}
                                        onPagerChange={handlePagerChange}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <div style={{minWidth: "170px"}}>
                                    <SearchInput
                                        onChange={bindSearch}
                                        disabled={false}
                                        query={filters.query}
                                        placeholder="Search Alerts"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="bottom-header-block alert-header-block alert-header-block-custom px-3 py-3">
                            {showAlertHeader && (
                                <div className="custom-alert-block my-0">
                                    <div
                                        className="alert alert-outline-warning fade show"
                                        role="alert"
                                    >
                                        <div className="alert-icon">
                                            <i className="flaticon-warning" />
                                        </div>
                                        <div className="alert-text">
                                            <strong>{selectedLevel === "full-all" ? pagination.total - excluded.length : selectedAlarms.length}</strong>{" "}
                                            {selectedAlarms.length < 2 ? "alert has" : "alerts have"} been selected.
                                            {selectedLevel === "full-all" || selectedAlarms.length === pagination.total ? (
                                                <span
                                                    onClick={() => handleCheckboxesLevel(false)}
                                                    className={"font-weight-bold cursor-pointer"}
                                                >
                                                    {" "}
                                                    Clear selection
                                                </span>
                                            ) : (
                                                <span
                                                    onClick={() => handleCheckboxesLevel("full-all")}
                                                    className={"font-weight-bold cursor-pointer"}
                                                >
                                                    {" "}
                                                    Click here to select all {pagination.total} alerts.
                                                </span>
                                            )}
                                        </div>
                                        {(selectedLevel || selectedAlarms.length > 0) && (
                                            <div className="alert-buttons">
                                                <button
                                                    className="link color-success"
                                                    title="Acknowledge selected"
                                                    data-qa="AcknowledgeButton"
                                                    onClick={handleAcknowledgeModal}
                                                >
                                                    <i className="fa fa-check" />
                                                    <span>Acknowledge Selected</span>
                                                </button>
                                                <button
                                                    className="link color-warning"
                                                    title="Ignore selected"
                                                    data-qa="IgnoreledgeButton"
                                                    onClick={handleIgnoreModal}
                                                >
                                                    <i className="fa fa-times" />
                                                    <span>Ignore Selected</span>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {loader ? (
                    <Loader />
                ) : (
                    <div className="row">
                        <div className="col-12">
                            <TableActive
                                handleAlarmCheckbox={handleCheckbox}
                                list={list}
                                selectedAlarms={selectedAlarms}
                                sort={sort}
                                excluded={excluded}
                                selectedLevel={selectedLevel}
                                onSortChange={handleSortChange}
                                onOpenAcknowledgeAlarmModal={props.onOpenAcknowledgeAlarmModal}
                                onOpenIgnoreAlarmModal={props.onOpenIgnoreAlarmModal}
                                equipment={props.equipment}
                                axisLabels={axisLabels}
                                onClose={props.onClose}
                            />
                        </div>
                    </div>
                )}
            </React.Fragment>
        </Modal>
    );
};

ActiveModal.propTypes = {
    equipmentId: PropTypes.number,
    auth: PropTypes.object,
    onOpenAcknowledgeAlarmModal: PropTypes.func,
    onOpenIgnoreAlarmModal: PropTypes.func,
    updateAlertsList: PropTypes.func,
    setSelectedAlarms: PropTypes.func,
    onClose: PropTypes.func,
    history: PropTypes.object,
    filter: PropTypes.object,
    selectedAlarms: PropTypes.array,
    excluded: PropTypes.array,
    forceUpdate: PropTypes.bool,
    equipment: PropTypes.object,
    title: PropTypes.node,
    axisLabels: PropTypes.object,
};

export default withGlobalStore(ActiveModal);
