import {useMutation, useQueryClient} from "@tanstack/react-query";
import {FAULT_FREQUENCY_API} from "./constants";
import ApiFaultFrequency from "../../../api/faultFrequency";
import {addNewItemInList} from "../../../helpers/query";

export const useFaultFrequencyCreateMutation = (equipmentId, onSuccess = () => {}) => {
    const queryClient = useQueryClient();

    const create = (data) => ApiFaultFrequency.create(data);

    const mutation = useMutation(create, {
        onSuccess: (data) => {
            queryClient.setQueriesData([FAULT_FREQUENCY_API.GET_FREQUENCY, equipmentId], (prev) => {
                onSuccess();
                return addNewItemInList(prev, data);
            });
        },
    });

    return mutation;
};
