import React, {Component, Fragment, useEffect, useState} from "react";
import {Modal} from "../../shared";
import PropTypes from "prop-types";
import {get as _get, find as _find, each as _each, zipObject as _zipObject} from "lodash";
import Toast from "../../pages/shared/toast";
import ApiDefaultCondition from "../../api/alertDefaultCondition";
import SweetAlert from "react-bootstrap-sweetalert";
import {withGlobalStore} from "../../stores/GlobalStore";
import SensorApi from "../../api/sensor";
import AlertLevelApi from "../../api/alertLevel";
import Helper from "../../helpers/helper";
import FMaxSelect from "../../pages/shared/fmax-select";
import LinesOfResolutionSelect from "../../pages/shared/lines-of-resolution-select";
import {UncontrolledTooltip} from "reactstrap";
import {SENSOR_TYPES} from "../../constants/constants";
import SelectWrapper from "../../helpers/select-wrapper";

const alertTriggerTypeLevels = {
    1: "Alert only when reading exceeds the last active alert",
    2: "Alert for every reading",
};

class SetDefaultsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inProgress: false,
            data: {},
            originData: {},
            confirmationModal: {
                title: "",
                show: false,
            },
            alertLevels: [],
            defaultFftSettings: [],
            currentReadingTypeId: props.readingTypes.list[0],
            currentAlertLevelId: null,
            deleteConfirmation: null,
            loader: true,
            validationError: false,
            onlyCurrent: false,
        };

        this.handleSetConfirmationModal = this.handleSetConfirmationModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onHideDeleteConfirmation = this.onHideDeleteConfirmation.bind(this);
        this.onShowDeleteConfirmation = this.onShowDeleteConfirmation.bind(this);
        this.onConfirmDeleteAlerts = this.onConfirmDeleteAlerts.bind(this);

        this.readingTypeToAxis = {};
        if (props.readingTypes.list.length > 1) {
            this.readingTypeToAxis = _zipObject(props.readingTypes.list, ["X", "Y", "Z"]);
        }

        this.recordsDeleted = false;
    }

    componentDidMount() {
        this.fetch();
    }

    calcOriginData = () => {
        let data = {};

        (this.props.readingTypes.list || []).forEach((readingType) => {
            const existingAlerts = (this.props.installationPoint.existingAlerts || {})[readingType];
            if (Object.keys(existingAlerts || {}).length) {
                if (!data[readingType]) data[readingType] = {};
                _each(existingAlerts, (value, key) => {
                    ["caution", "warning"].forEach((level) => {
                        if (key.indexOf(level) !== -1) {
                            if (!data[readingType][level]) data[readingType][level] = {};
                            data[readingType][level][key] = this.normalizeValue(value);
                        }
                    });
                });
            }
        });

        return data;
    };

    fetch = () => {
        Promise.all([
            AlertLevelApi.getList(),
            AlertLevelApi.getLevelsConditionsList(),
            _get(this.props.installationPoint, "sensor.id")
                ? SensorApi.defaultFftSettings(this.props.installationPoint.sensor.id)
                : new Promise((resolve) => resolve(null)),
        ]).then(([alertLevels, conditionsList, defaultFftSettings]) => {
            const levels = (alertLevels || {})["list"] || [];
            const originData = this.calcOriginData();
            this.setState({
                data: originData,
                originData: this.calcOriginData(),
                defaultFftSettings: (defaultFftSettings || {}).specs || [],
                alertLevels: levels,
                currentAlertLevelId: (levels[0] || {}).id,
                conditions: (conditionsList || {})["list"] || [],
                loader: false,
            });
        });
    };

    getReadingTypeAxis(readingType) {
        return _get(this.readingTypeToAxis, readingType, "N/A");
    }

    resultData = () => {
        const {data, originData, alertLevels, defaultFftSettings, currentReadingTypeId} = this.state;
        const {installationPoint} = this.props;

        let tempData = {};

        const specs =
            _find(defaultFftSettings, (spec) => spec.axis_name === (this.readingTypeToAxis[currentReadingTypeId] || "").toLowerCase()) ||
            {};

        const versionType = +((installationPoint.sensor || {}).version_type || 0);
        const fMax = specs.fmax || (versionType === SENSOR_TYPES.V1 ? 2500 : 3200);
        const lor = specs.lines_of_resolution || 1024;

        Object.keys(data).forEach((readingId) => {
            alertLevels.forEach((level) => {
                const levelName = level.name.toLowerCase();
                if (this.checkExistData(readingId, levelName)) {
                    tempData[readingId] = {
                        ...(tempData[readingId] || {}),
                        ...{
                            [`${levelName}_condition`]: ">=",
                            [`${levelName}_notification_type`]: 1,
                            [`${levelName}_require_readings`]: 2,
                        },
                        ...((installationPoint || {}).id
                            ? {
                                  [`${levelName}_rms_od`]: 0,
                                  ...(!_get(originData, [readingId, levelName, `${levelName}_rms_od`]) &&
                                  _get(data, [readingId, levelName, `${levelName}_rms_od`])
                                      ? {
                                            [`${levelName}_f_max`]: fMax,
                                            [`${levelName}_lines_of_resolution`]: lor,
                                        }
                                      : {}),
                              }
                            : {}),
                        ...(_get(originData, [readingId, levelName]) || {}),
                        ...(_get(data, [readingId, levelName]) || {}),
                    };
                    if (["", 0].includes(+tempData[readingId][`${levelName}_require_readings`])) {
                        tempData[readingId][`${levelName}_require_readings`] = 2;
                    }
                }
            });
        });

        return tempData;
    };

    handleSubmit(overwrite) {
        this.setState({inProgress: true}, () => {
            const {equipment, installationPoint, location} = this.props;

            const request = {
                installationPointId: _get(installationPoint, "id", false),
                equipmentId: _get(equipment, "id", false),
                locationId: _get(location, "id", false),
                data: this.resultData(),
                overwrite: overwrite,
            };

            let requestsArray = [];

            Object.keys(request.data).map((key) => {
                let chunkedData = {
                    ...request,
                    data: {[key]: _get(request.data, key)},
                };
                requestsArray.push(ApiDefaultCondition.update(chunkedData));
            });

            Promise.all(requestsArray)
                .then((responses) => {
                    let isSuccess = 0;
                    let messages = [];
                    responses.forEach((response) => {
                        if (response.status === "ok") {
                            ++isSuccess;
                        } else {
                            if (!messages.includes(response.message)){
                                messages.push(response.message);
                            }
                        }
                    });
                    if (responses.length === isSuccess) {
                        Toast.success("The alert conditions have been updated.");
                        this.setState({inProgress: false}, () => {
                            this.props.onClose(true);
                        });
                    } else {
                        Toast.error(messages.join(";") || "Server Error. Please contact to administrator.");
                        this.setState({inProgress: false});
                    }
                })
                .catch((err) => {
                    if (err.errors && Object.values(err.errors).length) {
                        Toast.error(Object.values(err.errors)[0]);
                    }
                    this.setState({inProgress: false});
                });
        });
    }

    getCurrentLevel = () => _find(this.state.alertLevels, (level) => +level.id === +this.state.currentAlertLevelId) || {};

    getCurrentLevelName = () => (this.getCurrentLevel().name || "").toLowerCase();

    normalizeValue = (value) => (isNaN(parseFloat(value)) ? value : parseFloat(value));

    onChange = (key, value) => {
        const data = {...this.state.data};
        const {currentReadingTypeId} = this.state;
        const levelName = this.getCurrentLevelName();

        if (!data[currentReadingTypeId]) {
            data[currentReadingTypeId] = {};
        }
        if (!data[currentReadingTypeId][levelName]) {
            data[currentReadingTypeId][levelName] = {};
        }

        data[currentReadingTypeId][levelName][key] = this.normalizeValue(value);

        this.setState({data});
    };

    handleSetConfirmationModal(data) {
        this.setState({confirmationModal: {...data}});
    }

    onConfirmDeleteAlerts() {
        const data = {...this.state.data};
        const originData = {...this.state.originData};
        const {readingType, level} = this.state.deleteConfirmation;

        this.setState({inProgress: true, deleteConfirmation: null}, () => {
            const request = {
                installationPointId: _get(this.props.installationPoint, "id") || false,
                equipmentId: _get(this.props.equipment, "id") || false,
                locationId: _get(this.props.location, "id", false),
                data: {
                    [readingType]: [level],
                },
            };

            ApiDefaultCondition.delete(request)
                .then((response) => {
                    if (response.status === "ok") {
                        Toast.success("The alert conditions have been removed.");
                        this.recordsDeleted = true;
                        this.props.removeExistingAlert(readingType, level);
                        if (_get(data, [readingType, level])) {
                            delete data[readingType][level];
                            if (!Object.keys(data[readingType]).length) {
                                delete data[readingType];
                            }
                        }
                        if (_get(originData, [readingType, level])) {
                            delete originData[readingType][level];
                            if (!Object.keys(originData[readingType]).length) {
                                delete originData[readingType];
                            }
                        }
                        this.setState({data, inProgress: false});
                    } else {
                        Toast.error(response.message || "Server Error. Please contact to administrator.");
                        this.setState({inProgress: false});
                    }
                })
                .catch((err) => {
                    if (err.errors && Object.values(err.errors).length) {
                        Toast.error(Object.values(err.errors)[0]);
                    }
                    this.setState({inProgress: false});
                });
        });
    }

    onShowDeleteConfirmation(level) {
        this.setState({
            deleteConfirmation: {readingType: this.state.currentReadingTypeId, level},
        });
    }

    onHideDeleteConfirmation() {
        this.setState({deleteConfirmation: null});
    }

    getDeleteConfirmationText() {
        const {level, readingType} = this.state.deleteConfirmation;
        const {readingTypes, equipment, installationPoint, location} = this.props;
        const getEquipmentName = () => {
            let res = "";
            res += _get(installationPoint, "name", "ALL INSTALLATION POINTS");
            res += " on ";
            res += _get(equipment, "name", _get(location, "name", "ALL EQUIPMENT"));
            return res;
        };

        return (
            <React.Fragment>
                <p>
                    Are you sure you want to delete {level} {_get(readingTypes, "name", "")} alerts
                    {readingTypes.list.length > 1 ? ` for ${this.getReadingTypeAxis(readingType)}-axis` : ""} on {getEquipmentName()}?
                </p>
                <p>This alert will be deleted immediately. You can&apos;t undo this action.</p>
            </React.Fragment>
        );
    }

    setCurrentReadingTypeId = (currentReadingTypeId) => this.setState({currentReadingTypeId});

    setCurrentAlertLevelId = (currentAlertLevelId) => this.setState({currentAlertLevelId});

    checkExistAnyData = () => {
        let flag = false;
        const {data} = this.state;

        Object.keys(data).forEach((readingId) => {
            flag = flag || this.checkExistData(readingId);
        });

        return flag;
    };

    checkExistData = (readingId, levelName) => {
        const {data, originData} = this.state;

        let flag = false;

        Object.keys(data[readingId] || {}).forEach((tempLevelName) => {
            if (flag) return false;
            if (levelName && levelName !== tempLevelName) return true;

            Object.keys(data[readingId][tempLevelName] || {}).forEach((key) => {
                const value = data[readingId][tempLevelName][key];
                const originValue = _get(originData, [readingId, tempLevelName, key]);

                flag = flag || this.normalizeValue(value) !== this.normalizeValue(originValue);
            });
        });

        return flag;
    };

    validateValue = (readingId, levelName) => {
        let flag = true;
        const {data} = this.state;

        Object.keys(data[readingId] || {}).forEach((tempLevelName) => {
            if (!flag) return false;
            if (levelName && levelName !== tempLevelName) return true;
            const alert = data[readingId][tempLevelName] || {};

            if (
                (!alert[tempLevelName] && alert[tempLevelName] !== 0) ||
                (alert[`${tempLevelName}_require_readings`] !== undefined && !alert[`${tempLevelName}_require_readings`])
            ) {
                flag = false;
            }
        });

        return flag;
    };

    validateValues = () => {
        let flag = true;
        const {data} = this.state;

        Object.keys(data).forEach((readingId) => {
            if (!flag) return false;
            flag = this.validateValue(readingId);
        });

        return flag;
    };

    validateOnlyCurrent = () => {
        let flag = false;
        const {originData, data, currentReadingTypeId} = this.state;

        if (
            !Object.keys(originData).length &&
            (!Object.keys(data).length ||
                (Object.keys(data).length === 1 &&
                    Object.keys(data[currentReadingTypeId] || {}).length === 1 &&
                    data[currentReadingTypeId][this.getCurrentLevelName()]))
        ) {
            flag = true;
        }

        return flag;
    };

    render() {
        const {
            currentReadingTypeId,
            currentAlertLevelId,
            defaultFftSettings,
            conditions,
            alertLevels,
            inProgress,
            confirmationModal,
            deleteConfirmation,
            data,
            originData,
            loader,
            validationError,
            onlyCurrent,
        } = this.state;
        const {onClose, equipment, installationPoint, location, readingTypes} = this.props;

        const specs =
            _find(defaultFftSettings, (spec) => spec.axis_name === (this.readingTypeToAxis[currentReadingTypeId] || "").toLowerCase()) ||
            _get(defaultFftSettings, 0) ||
            {};

        const alerts = {
            caution: _get(data, [currentReadingTypeId, "caution"]) || {},
            warning: _get(data, [currentReadingTypeId, "warning"]) || {},
        };

        const resultData = this.resultData();

        return (
            <React.Fragment>
                <Modal
                    {...this.props}
                    size="lg"
                    onClose={() => onClose(this.recordsDeleted)}
                    loader={loader}
                    inProgress={inProgress || loader}
                    className="custom-modal"
                    bodyClass="mh-250"
                    withoutFooter={true}
                    submitTitle="Create Conditions"
                    customBtns={[
                        {
                            title: "Cancel",
                            color: "secondary",
                            callback: () => onClose(this.recordsDeleted),
                        },
                        {
                            title: "Save and Overwrite Existing Alerts",
                            color: "info",
                            callback: () => {
                                if (this.checkExistAnyData()) {
                                    if (!this.validateValues()) {
                                        this.setState({
                                            onlyCurrent: this.validateOnlyCurrent(),
                                            validationError: true,
                                        });
                                    } else {
                                        this.setState({onlyCurrent: false, validationError: false});
                                        this.handleSetConfirmationModal({
                                            title: "Save and Overwrite Existing Alerts",
                                            show: true,
                                        });
                                    }
                                } else if (this.validateOnlyCurrent()) {
                                    this.setState({onlyCurrent: true, validationError: true});
                                } else {
                                    Toast.error("Note: The values were not changed.");
                                }
                            },
                        },
                        {
                            title: "Save Non-Existing Alerts Only",
                            color: "success",
                            callback: () => {
                                if (this.checkExistAnyData()) {
                                    if (!this.validateValues()) {
                                        this.setState({
                                            onlyCurrent: this.validateOnlyCurrent(),
                                            validationError: true,
                                        });
                                    } else {
                                        this.setState({onlyCurrent: false, validationError: false});
                                        this.handleSubmit(false);
                                    }
                                } else if (this.validateOnlyCurrent()) {
                                    this.setState({onlyCurrent: true, validationError: true});
                                } else {
                                    Toast.error("Note: The values were not changed.");
                                }
                            },
                        },
                    ]}
                >
                    <div className="row mb-2">
                        <div className="col-12">
                            <span className="secondary-text">
                                <i className="fa fa-info-circle mr-1" />
                                Check the following details before creating alert levels:
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        {_get(readingTypes, "name", false) && (
                            <div className="form-group col-12 mb-2">
                                <label>Reading Type: </label>
                                <span className="font-weight-bold ml-1">
                                    {_get(readingTypes, "name", "Not set")}, {_get(readingTypes, "units", "N/A")}
                                </span>
                            </div>
                        )}
                        {_get(equipment, "name", false) ? (
                            <div className="form-group col-6 mb-1">
                                <label>Equipment:</label>
                                <div>{_get(equipment, "name", "Not set")}</div>
                                <div>{_get(equipment, "asset_code", "Not set")}</div>
                            </div>
                        ) : (
                            <div className="form-group col-6 mb-1">
                                <label>{_get(location, "name", false) === false ? "Equipment: " : "Asset Tree Branch: "}</label>
                                <div className="font-weight-bold text-danger">{_get(location, "name", "ALL EQUIPMENT")}</div>
                            </div>
                        )}
                        {_get(installationPoint, "name", false) ? (
                            <div className="form-group col-6 mb-1">
                                <label>Installation Point: </label>
                                <div>{_get(installationPoint, "name", "Not set")}</div>
                            </div>
                        ) : (
                            <div className="form-group col-6 mb-1">
                                <label>Installation Point:</label>
                                <div className="font-weight-bold text-danger">ALL INSTALLATION POINTS</div>
                            </div>
                        )}
                    </div>
                    <hr style={{margin: "20px 0"}} />
                    {readingTypes.list.length > 1 && (
                        <AxisTypeControl
                            axis={this.readingTypeToAxis}
                            currentReadingTypeId={+currentReadingTypeId}
                            setCurrentReadingTypeId={this.setCurrentReadingTypeId}
                            onlyCurrent={onlyCurrent}
                            validationError={validationError}
                            validateValue={this.validateValue}
                            checkExistData={this.checkExistData}
                        />
                    )}
                    {alertLevels.map((level) => (
                        <AlertTemplate
                            key={level.id}
                            units={readingTypes.units}
                            level={level}
                            alert={alerts[level.name.toLowerCase()]}
                            cautionValue={alerts["caution"]["caution"] || 0}
                            specs={specs}
                            versionType={+((installationPoint.sensor || {}).version_type || 0)}
                            inProgress={inProgress}
                            isCurrent={+currentAlertLevelId === +level.id}
                            isSaved={!!Object.keys(_get(originData, [currentReadingTypeId, level.name.toLowerCase()]) || {}).length}
                            conditions={conditions}
                            setCurrent={() => this.setCurrentAlertLevelId(+level.id)}
                            update={this.onChange}
                            remove={() => this.onShowDeleteConfirmation(level.name.toLowerCase())}
                            validationError={validationError}
                            onlyCurrent={onlyCurrent}
                            isEdited={this.checkExistData(currentReadingTypeId, level.name.toLowerCase())}
                            hasAnyData={!!Object.keys(data).length}
                        />
                    ))}
                    {deleteConfirmation && (
                        <SweetAlert
                            warning
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnBsStyle="default"
                            btnSize="xs"
                            title=" "
                            className="wide"
                            onConfirm={this.onConfirmDeleteAlerts}
                            onCancel={this.onHideDeleteConfirmation}
                            style={{width: "40em"}}
                        >
                            {this.getDeleteConfirmationText()}
                        </SweetAlert>
                    )}
                </Modal>
                {confirmationModal.show !== false && (
                    <Modal
                        title={confirmationModal.title}
                        showModal={confirmationModal.show}
                        size={"lg"}
                        onClose={() =>
                            this.handleSetConfirmationModal({
                                title: "",
                                show: false,
                            })
                        }
                        onCancel={() =>
                            this.handleSetConfirmationModal({
                                title: "",
                                show: false,
                            })
                        }
                        inProgress={inProgress}
                        className={"custom-modal"}
                        onSubmit={() => this.handleSubmit(true)}
                        submitTitle={"Yes"}
                    >
                        <p>Are you sure you want to {confirmationModal.title.toLowerCase()}?</p>
                        {readingTypes.list.map(
                            (readingTypeId) =>
                                this.checkExistData(readingTypeId) && (
                                    <React.Fragment key={readingTypeId}>
                                        {readingTypes.list.length > 1 && (
                                            <div className="subheader mb-0">
                                                <div className="subheader-title subheader-title--axis">
                                                    {this.getReadingTypeAxis(readingTypeId)}-axis
                                                </div>
                                                <div className="clear" />
                                            </div>
                                        )}
                                        <div className="row mt-3">
                                            {alertLevels.map(
                                                (level) =>
                                                    this.checkExistData(readingTypeId, level.name.toLowerCase()) && (
                                                        <ConfirmAlertTemplate
                                                            key={level.id}
                                                            units={readingTypes.units}
                                                            level={level}
                                                            alert={resultData[readingTypeId] || {}}
                                                            savedAlert={(originData[readingTypeId] || {})[level.name.toLowerCase()] || {}}
                                                        />
                                                    )
                                            )}
                                        </div>
                                    </React.Fragment>
                                )
                        )}
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}

const AxisTypeControl = ({
    axis,
    currentReadingTypeId,
    setCurrentReadingTypeId,
    onlyCurrent,
    validationError,
    validateValue,
    checkExistData,
}) => (
    <div className="form-group row mb-3">
        <label className="col-4">Axis Type:</label>
        <div className="col-8">
            <div className="axis-block-container">
                {Object.keys(axis).map((axisId) => {
                    const validationTab =
                        validationError &&
                        !onlyCurrent &&
                        +currentReadingTypeId !== +axisId &&
                        checkExistData(axisId) &&
                        !validateValue(axisId);
                    return (
                        <div
                            key={axisId}
                            className={`axis-block ${+currentReadingTypeId === +axisId ? "active" : ""}`}
                            onClick={() => setCurrentReadingTypeId(+axisId)}
                        >
                            <span>{axis[axisId]}</span>

                            {validationTab && (
                                <React.Fragment>
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="axis-info-tab"
                                    >
                                        Fields couldn&apos;t be empty
                                    </UncontrolledTooltip>
                                    <span
                                        className="color-danger sign-error"
                                        id="axis-info-tab"
                                    >
                                        <i
                                            className="fa fa-exclamation-triangle"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </React.Fragment>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    </div>
);

AxisTypeControl.propTypes = {
    axis: PropTypes.object,
    onlyCurrent: PropTypes.bool,
    validationError: PropTypes.bool,
    validateValue: PropTypes.func,
    checkExistData: PropTypes.func,
    currentReadingTypeId: PropTypes.number,
    setCurrentReadingTypeId: PropTypes.func,
};

const AlertTemplate = ({
    inProgress,
    isCurrent,
    alert,
    cautionValue,
    level,
    specs,
    versionType,
    units,
    conditions,
    update,
    remove,
    setCurrent,
    validationError,
    isEdited,
    hasAnyData,
    onlyCurrent,
}) => {
    const [percentage, setPercentage] = useState("");
    const current = level.name.toLowerCase();

    useEffect(() => {
        calcPercentage();
    }, [alert[current], cautionValue]);

    const calcPercentage = (value, commit) => {
        const newPercentage =
            value || (cautionValue ? Math.round((-(cautionValue - alert[current]) / (cautionValue / 100)) * 100) / 100 : 0).toString();
        if (commit) {
            update(current, (Math.round((cautionValue / 100) * (100 + +newPercentage) * 10000) / 10000).toString());
        }
        setPercentage(newPercentage);
    };

    return (
        <div
            className={`form-group mb-3 alert-level-block alert-level-block-big${isCurrent ? " current" : ""}`}
            onClick={() => (isCurrent ? {} : setCurrent())}
        >
            <div className="row flex-xl-nowrap justify-content-end">
                <div
                    className={
                        level.name === "Caution" ? "col col-12 col-xl-5 mb-3 mb-xl-0" : "col col-12 col-xl-auto flex-grow-1 mb-3 mb-xl-0"
                    }
                >
                    <label className={level.name === "Caution" ? "level-label caution-level" : "level-label warning-level"}>
                        {level.name || ""}
                    </label>
                </div>
                <div
                    className={level.name === "Caution" ? "col-12 col-md-6 col-xl-3 mb-3 mb-md-0" : "col-12 col-md-4 col-xl-2 mb-3 mb-md-0"}
                >
                    <SelectWrapper
                        value={alert[`${current}_condition`] || ""}
                        onChange={(ev) => update(`${current}_condition`, ev.target.value)}
                        disabled={!isCurrent}
                        name={current}
                    >
                        {Object.keys(conditions || {}).map((key) => (
                            <option
                                key={key}
                                value={key}
                            >
                                {key}
                            </option>
                        ))}
                    </SelectWrapper>
                </div>
                <div className={level.name === "Caution" ? "col-xl-auto d-none" : "col-12 col-md-4 col-xl-2 mb-3 mb-md-0"}>
                    {current !== "caution" && (
                        <div className="input-group">
                            <input
                                className="form-control request-form-control"
                                type="number"
                                step={1}
                                value={percentage || ""}
                                title="A percentage of caution level"
                                onChange={(ev) => calcPercentage(ev.target.value, true)}
                                disabled={!isCurrent}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text request-form-control">%</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className={level.name === "Caution" ? "col-12 col-md-6 col-xl-4" : "col-12 col-md-4 col-xl-3"}>
                    <div className={"input-group input-group-sm"}>
                        <input
                            className={`form-control request-form-control${isCurrent ? " current-alert-value" : ""}`}
                            type="number"
                            value={isNaN(parseFloat(alert[current])) ? "" : alert[current]}
                            step={0.1}
                            min={0}
                            onChange={(ev) => update(current, ev.target.value)}
                            disabled={!isCurrent}
                        />
                        {!!units && (
                            <div className="input-group-append">
                                <span className="input-group-text request-form-control">{units}</span>
                            </div>
                        )}
                    </div>
                    {!alert[current] &&
                        alert[current] !== 0 &&
                        validationError &&
                        ((!onlyCurrent && isEdited) || (onlyCurrent && isCurrent && (isEdited || !hasAnyData))) && (
                            <span className="error-msg d-block color-danger mt-1 text-right">This field is required</span>
                        )}
                </div>
            </div>
            <div className="row align-items-center mt-2">
                <div className="col-md-5">
                    <span>Trigger Type</span>
                </div>
                <div className="col-md-7">
                    <SelectWrapper
                        value={alert[`${current}_notification_type`] || ""}
                        onChange={(ev) => update(`${current}_notification_type`, ev.target.value)}
                        disabled={!isCurrent}
                        name={current}
                    >
                        {Object.keys(alertTriggerTypeLevels).map((key) => (
                            <option
                                key={key}
                                value={key}
                            >
                                {alertTriggerTypeLevels[key]}
                            </option>
                        ))}
                    </SelectWrapper>
                </div>
            </div>
            <div className="row align-items-center mt-2">
                <div className="col-md-5">
                    <span>Consecutive Readings Required Before Trigger</span>
                </div>
                <div className="col-md-7">
                    <input
                        className="form-control request-form-control"
                        type={"number"}
                        value={
                            isCurrent && alert[`${current}_require_readings`] === undefined ? 2 : alert[`${current}_require_readings`] || ""
                        }
                        onChange={(ev) => update(`${current}_require_readings`, ev.target.value)}
                        disabled={!isCurrent}
                        min={1}
                    />

                    {alert[`${current}_require_readings`] !== undefined &&
                        !alert[`${current}_require_readings`] &&
                        validationError &&
                        ((!onlyCurrent && isEdited) || (onlyCurrent && isCurrent && (isEdited || !hasAnyData))) && (
                            <span className="error-msg d-block color-danger mt-1 text-right">This field is required</span>
                        )}
                </div>
            </div>
            {!!versionType && (
                <RmsOdTemplate
                    alert={alert}
                    level={level}
                    specs={specs}
                    versionType={versionType}
                    isCurrent={isCurrent}
                    update={update}
                />
            )}
            <div className="row justify-content-end mt-4 mx-0">
                <button
                    className="btn btn-sm btn-danger btn-remove"
                    onClick={remove}
                    disabled={inProgress}
                >
                    Delete
                </button>
            </div>
        </div>
    );
};

AlertTemplate.propTypes = {
    inProgress: PropTypes.bool,
    isCurrent: PropTypes.bool,
    isSaved: PropTypes.bool,
    units: PropTypes.string,
    alert: PropTypes.object,
    level: PropTypes.object,
    specs: PropTypes.object,
    conditions: PropTypes.object,
    cautionValue: PropTypes.number,
    versionType: PropTypes.number,
    setCurrent: PropTypes.func,
    update: PropTypes.func,
    remove: PropTypes.func,
    validationError: PropTypes.bool,
    isEdited: PropTypes.bool,
    hasAnyData: PropTypes.bool,
    onlyCurrent: PropTypes.bool,
};

const RmsOdTemplate = ({alert, level, versionType, isCurrent, update, specs}) => {
    const current = level.name.toLowerCase();
    return (
        <div className="row align-items-center mt-2">
            <div className="col-md-5">
                <label className="form-checkbox form-checkbox--alerts rms-od-checkbox">
                    <input
                        type="checkbox"
                        checked={+alert[`${current}_rms_od`] === 1}
                        onChange={(ev) => update(`${current}_rms_od`, Helper.getInputValue(ev.target))}
                        disabled={!isCurrent}
                    />
                    Request FFT
                    <span />
                </label>
            </div>
            <div className="col-md-7">
                <div className="row">
                    {+alert[`${current}_rms_od`] === 1 && (
                        <Fragment>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <FMaxSelect
                                        specs={specs}
                                        value={alert[`${current}_f_max`]}
                                        versionType={+versionType}
                                        onChange={(ev) => update(`${current}_f_max`, ev.target.value)}
                                        disabled={!isCurrent}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <LinesOfResolutionSelect
                                        specs={specs}
                                        versionType={+versionType}
                                        value={alert[`${current}_lines_of_resolution`]}
                                        onChange={(ev) => update(`${current}_lines_of_resolution`, ev.target.value)}
                                        disabled={!isCurrent}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

RmsOdTemplate.propTypes = {
    alert: PropTypes.object,
    level: PropTypes.object,
    versionType: PropTypes.number,
    isCurrent: PropTypes.bool,
    update: PropTypes.func,
    specs: PropTypes.object,
};

const ConfirmAlertTemplate = ({alert, savedAlert, level, units}) => {
    const current = level.name.toLowerCase();

    return (
        <div className="form-group row col-md-12 mb-0">
            <div className="col-12 mb-1">
                <span className={`badge badge-${current === "caution" ? "warning" : "danger"}`}>{level.name}</span>
            </div>
            <ConfirmAlertTemplateBlock
                title="Condition:"
                from={savedAlert[`${current}_condition`]}
                to={alert[`${current}_condition`]}
            />
            <ConfirmAlertTemplateBlock
                title={`Value (${units}):`}
                from={savedAlert[current]}
                to={alert[current]}
            />
            <ConfirmAlertTemplateBlock
                title="Trigger Type:"
                from={alertTriggerTypeLevels[savedAlert[`${current}_notification_type`]]}
                to={alertTriggerTypeLevels[alert[`${current}_notification_type`]]}
            />
            <ConfirmAlertTemplateBlock
                title="Consecutive Readings Required Before Trigger:"
                from={+savedAlert[`${current}_require_readings`]}
                to={+alert[`${current}_require_readings`]}
            />
            {(+savedAlert[`${current}_rms_od`] === 1 || +alert[`${current}_rms_od`] === 1) && (
                <>
                    <ConfirmAlertTemplateBlock
                        title="Request FFT:"
                        from={+savedAlert[`${current}_rms_od`] ? "Yes" : "No"}
                        to={alert[`${current}_rms_od`] ? "Yes" : "No"}
                    />
                    {+alert[`${current}_rms_od`] === 1 && (
                        <>
                            <ConfirmAlertTemplateBlock
                                title="FMax:"
                                from={savedAlert[`${current}_f_max`]}
                                to={alert[`${current}_f_max`]}
                            />
                            <ConfirmAlertTemplateBlock
                                title="Lines of resolution:"
                                from={savedAlert[`${current}_lines_of_resolution`]}
                                to={alert[`${current}_lines_of_resolution`]}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
};

ConfirmAlertTemplate.propTypes = {
    units: PropTypes.string,
    alert: PropTypes.object,
    savedAlert: PropTypes.object,
    level: PropTypes.object,
};

const ConfirmAlertTemplateBlock = ({title, from, to}) => (
    <div className={"row col-12 mb-3"}>
        <label className="col-12">{title}</label>
        <div className="col-6">
            <label className="mr-1 mb-0 ml-3">From:</label>
            <span className="text-bold">{isNaN(parseFloat(from)) ? from || "not set" : from}</span>
        </div>
        <div className="col-6">
            <label className="mr-1 mb-0 ml-3">To:</label>
            <span className="text-bold">{isNaN(parseFloat(to)) ? to || "not set" : to}</span>
        </div>
    </div>
);

ConfirmAlertTemplateBlock.propTypes = {
    title: PropTypes.string,
    from: PropTypes.any,
    to: PropTypes.any,
};

SetDefaultsModal.propTypes = {
    installationPoint: PropTypes.object,
    readingTypes: PropTypes.object,
    equipment: PropTypes.object,
    chartTypes: PropTypes.array,
    location: PropTypes.object,
    onClose: PropTypes.func,
    removeExistingAlert: PropTypes.func,
};

export default withGlobalStore(SetDefaultsModal);
