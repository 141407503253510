import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiChartMarkFft from "../../../api/chartMarkFft";
import {CHART_MARK_API} from "./constants";
import {addNewItemInList} from "../../../helpers/query";

export const useChartMarksCreateMutation = (equipmentId) => {
    const queryClient = useQueryClient();

    const createChartMark = (data) => ApiChartMarkFft.create(data);

    const mutation = useMutation(createChartMark, {
        onSuccess: (data) => queryClient.setQueriesData([CHART_MARK_API.GET_CHART_MARK_FFT, equipmentId], (prev) => addNewItemInList(prev, data)),
    });
    return mutation;
};
