import $ from "jquery";
import React, { Component } from "react";
import { Modal } from "../../../shared";
import PropTypes from "prop-types";
import {
    get as _get,
} from "lodash";

import "../../../assets/scss/components/guide/guide.scss";
import Loader from "../../../shared/loader/loader";
import UserGuideApi from "../../../api/userGuide";

class HelpUserGuide extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: true,
            url: false
        };

        this.dropdownMenu = this.dropdownMenu.bind(this);
    }

    componentDidMount() {
        UserGuideApi
            .getUserGuide()
            .then(response => {
                this.setState({
                    loader: false,
                    url: _get(response, "url", false)
                });
            });
    }

    dropdownMenu(ulId) {
        $("#" + ulId).stop().slideToggle(200);
    }

    render() {
        const {loader, url} = this.state;

        return (
            <Modal
                {...this.props}
                size="m"
                title={"User Guide"}
                withoutFooter={true}
                className="user_guide-modal"
            >
                {loader ?
                    <Loader/> :
                    <div>
                        <ul className="user_guide-list">
                            <li>
                                <p>
                                    The <i>Waites Self-Service Dashboard User Guide</i> is a comprehensive user guide that provides
                                    procedural information and field descriptions for all windows
                                    in the Waites Self-Service Dashboard.
                                </p>

                                {!!url &&
                                    <div className="mt-2 mb-2">
                                        <a
                                            className={"btn btn-primary btn-sm"}
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Click here to open
                                        </a>
                                    </div>
                                }
                            </li>
                        </ul>
                    </div>
                }

            </Modal>
        );
    }
}

HelpUserGuide.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func
};

export default HelpUserGuide;