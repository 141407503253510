import React, {useEffect, useMemo, useState} from "react";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {EditorState, convertToRaw, ContentState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import PropTypes from "prop-types";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FileManagerApi from "../../api/fileManager";
import {get as _get} from "lodash";

const uploadImage = (file) => {
    return new Promise((resolve, reject) => {
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                FileManagerApi.uploadBase64Image({file: reader.result}).then((response) => {
                    resolve({data: {link: _get(response, "file", "")}});
                });
            };
            reader.onerror = (error) => reject(error);
        } else {
            reject("File is empty");
        }
    });
};

const ToolbarOptions = {
    options: ["inline", "blockType", "list", "textAlign", "link", "image", "history"],
    inline: {
        options: ["bold", "italic", "underline"],
    },
    image: {
        uploadCallback: uploadImage,
        previewImage: true,
        deletionEnabled: true,
    },
};

const TextEditor = (props) => {
    const initialContentState = useMemo(() => {
        let editorState = EditorState.createEmpty();
        if (props.content && props.content !== "<p></p>") {
            editorState = EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(props.content)));
        }
        return editorState;
    }, [props.content]);

    useEffect(() => {
        if (props.content === "") {
            setEditorState(EditorState.createEmpty());
        }
    }, [props.content]);

    const [editorState, setEditorState] = useState(initialContentState);

    const convertToRawFix = (convertToRaw) => {
        let blocks = _get(convertToRaw, "blocks", []);

        blocks.map((block) => {
            let inlineStyleRanges = _get(block, "inlineStyleRanges", []);
            inlineStyleRanges.map((range) => {
                const style = _get(range, "style", "");
                if (style.indexOf("fontfamily-") === 0) {
                    range.style = style.replace(/"/g, "");
                }
            });
        });

        return convertToRaw;
    };

    const onEditorStateChange = (editorState) => {
        const {onChange} = props;

        setEditorState(editorState);

        if (onChange) {
            onChange(draftToHtml(convertToRawFix(convertToRaw(editorState.getCurrentContent()))));
        }
    };

    return (
        <Editor
            editorState={editorState}
            toolbarClassName="ai-editor-toolbar"
            wrapperClassName="ai-editor-wrapper"
            editorClassName="ai-editor"
            onEditorStateChange={onEditorStateChange}
            toolbar={ToolbarOptions}
        />
    );
};

TextEditor.propTypes = {
    content: PropTypes.string,
    onChange: PropTypes.func,
};

export default TextEditor;
