import {get as _get, set as _set, has as _has} from "lodash";
import {useMemo} from "react";

export const useNodes = (pointData) => {
    const nodes = useMemo(() => {
        const nodes = {};
        pointData.forEach((point) => {
            let nodeID = _get(point, "sensor.node_id", false);

            if (nodeID && !_has(nodes, nodeID)) {
                _set(nodes, nodeID, point);
            }
        });

        return nodes;
    }, [pointData]);

    return nodes;
};
