import React from "react";
import {LossPacketsAlert} from "../../../shared/lossPacketsAlert/lossPacketsAlert";
import {find as _find, get as _get} from "lodash";
import {useCurrentAlertCondition, useSelectedTab} from "../../../stores/zustand/AlertConditionStore";
import {useSetAlert} from "../hooks/helpers/alerts";
import PropTypes from "prop-types";
import {useTachometer} from "../hooks/helpers/tachometer";
import {useChartComputed} from "../hooks/helpers/chart";
import {useInstallationPoint} from "../hooks/useInstallationPoint";
import useAlertLevelsList from "../../../hooks/api/Alert/useAlertLevelList";
import {useAlertConditionListQuery} from "../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {useInstallationPointIds} from "../../../hooks/helpers/useInstallationPointIds";

const LossPacketAlert = ({chartTypes, equipment}) => {
    const {chartType} = useChartComputed(chartTypes, equipment);
    const currentAlertCondition = useCurrentAlertCondition();
    const alertType = useSelectedTab();
    const installationPoint = useInstallationPoint(equipment, chartType);
    const setAlert = useSetAlert(chartTypes, equipment, alertType);
    const installationPointIds = useInstallationPointIds(equipment);

    const {tachometerList: tachometerRpmAlertList} = useTachometer(equipment, chartType);
    const {alertLevelsList: levels} = useAlertLevelsList();
    const {alertConditions: alertList} = useAlertConditionListQuery(chartType, installationPointIds);

    const baseHasCheckedFFT = (list) => {
        const currentId = _get(currentAlertCondition, "id");
        const currentRmsOd = _get(currentAlertCondition, "rmsOd");

        if (+currentRmsOd === 1) {
            return true;
        }

        return !!_find(list, (alert) => +_get(alert, "id") !== +currentId && +_get(alert, "rmsOd") === 1);
    };
    const hasCheckedFFTLinear = () => {
        return baseHasCheckedFFT(alertList);
    };

    const isShowLossPacketsAlert = () => {
        switch (alertType) {
            case "linear":
                return hasCheckedFFTLinear();
            case "tachometer-rpm":
                return hasCheckedFFTTachometerRpm();
            default:
                return false;
        }
    };

    const hasCheckedFFTTachometerRpm = () => {
        let isShow = false;

        if (tachometerRpmAlertList.length) {
            isShow = baseHasCheckedFFT(tachometerRpmAlertList);
        }

        if (!isShow) {
            levels.map((level) => {
                const isCurrent = +currentAlertCondition.alert_level_id === +level.id && +currentAlertCondition.index === 0;
                const alert = isCurrent ? currentAlertCondition : setAlert(level);
                if (+_get(alert, "rmsOd") === 1) {
                    isShow = true;
                }
            });
        }

        return isShow;
    };

    return (
        <div className="col-7">
            {isShowLossPacketsAlert() && (
                <LossPacketsAlert
                    id={_get(installationPoint, "id")}
                    isCollapsed={false}
                    className={"manage-alerts"}
                    lossPacketsPercent={_get(installationPoint, "sensor.node.packet_loss_percent")}
                />
            )}
        </div>
    );
};
LossPacketAlert.propTypes = {chartTypes: PropTypes.object, equipment: PropTypes.object};
export default LossPacketAlert;
