import { useContext } from "react";
import { LockPointsContext } from "../context/context";

export const useLockPoints = () => {
    const lockPoints = useContext(LockPointsContext);
    if (!lockPoints) {
        throw new Error("Lose lock points context");
    }
    return lockPoints;
};
