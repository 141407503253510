import React from "react";
import PropTypes from "prop-types";
import {
    indexOf as _indexOf,
    filter as _filter,
} from "lodash";

const SensorCheckList = ({onChange, groupId, value, installationPoints, filterOutTachometers}) => {

    if (filterOutTachometers) {
        installationPoints = _filter(installationPoints, installationPoint => installationPoint.point_type === "sensor");
    }

    return (
        <div>
            {installationPoints.map(installationPoint => (
                <label key={installationPoint.id} className={"form-checkbox " + (groupId !== false && " disabled")}>
                    <input
                        type="checkbox"
                        disabled={groupId !== false}
                        value={installationPoint.id}
                        onChange={onChange}
                        checked={_indexOf(value, installationPoint.id) !== -1}
                    /> {installationPoint.name}
                    <span/>
                </label>
            ))}
        </div>
    );
};


SensorCheckList.propTypes = {
    installationPoints: PropTypes.object,
    value: PropTypes.array,
    groupId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    onChange: PropTypes.func,
    filterOutTachometers: PropTypes.bool
};

export default SensorCheckList;

export const PureSensorSelect = SensorCheckList;