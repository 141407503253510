import {useQuery} from "@tanstack/react-query";
import ApiLocation from "../../../api/location";
import {EQUIPMENT_API} from "./constants";

export const useEquipmentsForLocationQuery = (locationId) => {
    const params = {
        query: {
            id: locationId,
        },
    };

    return useQuery({
        queryFn: () => ApiLocation.equipmentsForLocation(params),
        queryKey: [EQUIPMENT_API.GET_EQUIPMENTS_FOR_LOCATION, params],
        select: (res) => res.list,
        enabled: !!locationId,
    });
};
