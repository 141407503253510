import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {ToggleModal} from "../../../../shared";
import {withGlobalStore} from "../../../../stores/GlobalStore";
import {ChartContext, ChartFactoryContext} from "./context/context";
import ChartWrapper from "./layouts/chartWrapper/chartWrapper";
import {WithChartLocalStore} from "./hoc/withChartLocalStore";
import {useChartStateStoreContext} from "./store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useChartRef} from "./hooks/useChartRef";
import ChartBody from "./components/chartBody/chartBody";

const propTypes = {
    chartType: PropTypes.string,
    showOutlierDialog: PropTypes.func,
    handleSetIdleThresholdModal: PropTypes.func,
    user: PropTypes.object,
};

const SimpleChart = (props) => {
    const {chartType} = props;
    const chartRef = useChartRef();
    const factoryResult = useChartStateStoreContext((state) => state.factory, shallow);

    const fullScreenMode = useChartStateStoreContext((state) => state.settings.fullScreenMode);

    return (
        <ChartFactoryContext.Provider value={factoryResult}>
            <ChartContext.Provider
                value={{
                    chartRef,
                    chartProps: props,
                }}
            >
                <React.Fragment>
                    <ToggleModal
                        centered={true}
                        size="full-screen"
                        showModal={true}
                        enabled={fullScreenMode}
                        title={""}
                        withoutFooter={true}
                    >
                        <ChartWrapper>
                            <ChartBody
                                user={props.user}
                                handleSetIdleThresholdModal={props.handleSetIdleThresholdModal}
                                chartType={chartType}
                                {...props}
                            />
                        </ChartWrapper>
                    </ToggleModal>
                </React.Fragment>
            </ChartContext.Provider>
        </ChartFactoryContext.Provider>
    );
};

SimpleChart.propTypes = propTypes;

export default withGlobalStore(withRouter(WithChartLocalStore(SimpleChart)));
