import React, {Component} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../stores/GlobalStore";
import EquipmentProfileList from "./list";
import EquipmentProfileEmpty from "./empty";
import Loader from "../../shared/loader/loader";
import EquipmentProfileApi from "../../api/equipmentProfile";
import CreateEquipmentModal from "./create-equipment/modal";
import Helper from "../../helpers/helper";
import { HeaderSimple } from "../../shared/header";

class EquipmentProfileIndex extends Component
{
    constructor(props) {
        super(props);

        let updateModal = Helper.getHashParams().update || null;
        if (updateModal) {
            this.props.history.push(Helper.deleteHashParams(["update"]));
        }

        this.state = {
            breadcrumbs: [{name: "Equipment Profiles"}],
            profiles: [],
            loader: true,
            profileIdToCreate: null,
            profileIdForUpdateEquipments: updateModal
        };
    }

    componentDidMount() {
        Promise.all([
            this.fetchProfiles()
        ]).then(() => this.setState({loader: false}));
    }

    fetchProfiles = () => {
        return EquipmentProfileApi
            .list()
            .then((response) => {
                if (response) {
                    const {list = []} = response;
                    this.setState({
                        profiles: list
                    });
                }
            });
    }

    toCreate = () => {
        const {history} = this.props;

        history.push("/equipment-profile/create");
    }

    onCreateEquipment = (profileId) => {
        this.setState({profileIdToCreate: profileId});
    }

    onCancelCreateEquipment = () => {
        this.setState({profileIdToCreate: null});
    }

    render() {
        const {breadcrumbs, profiles, loader, profileIdToCreate} = this.state;
        const {history, auth, user} = this.props;
        return (
            <React.Fragment>
                <div>
                    <HeaderSimple breadcrumbs={breadcrumbs} hideGlobalSearch />
                    <div className="subheader">
                        <div className="subheader-title">Equipment Profiles</div>
                        <div className="subheader-controls">
                            <div className="subheader-toolbar">
                                <div
                                    onClick={this.toCreate}
                                    className="btn btn-primary btn-sm"
                                >
                                    <i className="fa fa-plus"/> Add Equipment Profile
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"profiles-page"  + (loader ? " loading" : "")}>
                        {loader
                            ?
                            <Loader/>
                            :
                            <React.Fragment>
                                {profiles.length
                                    ?
                                    <EquipmentProfileList
                                        profiles={profiles}
                                        onCreate={this.toCreate}
                                        onCreateEquipment={this.onCreateEquipment}
                                        fetchProfiles={this.fetchProfiles}
                                        auth={auth}
                                    />
                                    :
                                    <EquipmentProfileEmpty
                                        onCreate={this.toCreate}
                                    />
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>
                {profileIdToCreate &&
                    <CreateEquipmentModal
                        profileId={profileIdToCreate}
                        onClose={this.onCancelCreateEquipment}
                        history={history}
                        auth={auth}
                        user={user}
                    />
                }
            </React.Fragment>
        );
    }
}

EquipmentProfileIndex.propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object
};

export default withGlobalStore(EquipmentProfileIndex);