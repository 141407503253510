import {useMemo} from "react";
import moment from "moment";
import useChartMarksQuery from "../../../../../hooks/api/chartMarksSimple/useChartMarksQuery";
import {useSelected} from "./helpers/useSelected";
import {useEquipmentByParams} from "../../../../../hooks/api/equipment/useEquipmentQuery";
import {useChartSelectedRangeStore} from "../../../../../stores/zustand/ChartSelectedRangeStore";
import {useChartStateStoreContext} from "../store/hooks/useStoreContext";
import Helper from "../../../../../helpers/helper";

export const useChartMarksBtns = () => {
    const {equipmentItem} = useEquipmentByParams();
    const {chartMarksData, isSuccess} = useChartMarksQuery(equipmentItem.id);
    const selectedPoints = useSelected();
    const rangeSelector = useChartSelectedRangeStore();
    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    return useMemo(() => {
        let chartMarksButtons = {
            left: [],
            right: [],
        };

        if (isSuccess && isLoading === false) {
            chartMarksData
                .filter((chartMark) => !chartMark.installation_point_id || selectedPoints.indexOf(chartMark.installation_point_id) >= 0)
                .forEach((chartMark) => {
                    const chartMarkTimestamp = +moment.utc(chartMark.timestamp).format("x");
                    const isDisplayChartMark = rangeSelector.isCustom
                        ? chartMarkTimestamp > Helper.unixToMilliseconds(rangeSelector.to) ||
                          chartMarkTimestamp < Helper.unixToMilliseconds(rangeSelector.from)
                        : chartMarkTimestamp < +moment.utc() - rangeSelector.range;

                    if (isDisplayChartMark) {
                        chartMarksButtons.left.push(chartMark);
                    }
                });
        }

        return chartMarksButtons;
    }, [isLoading, isSuccess, rangeSelector]);
};
