import React from "react";
import auth from "../../../../../../services/auth";
import {useChartContext} from "../../hooks/chartHooks";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useAlertConditionListQuery} from "../../../../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {useSelectedByEquipment} from "../../../../../../stores/zustand/ChartSelectedEquipmentStore";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useIsTachometer} from "../../../hooks/useChartTypes";

const ShowAlertsButton = () => {
    const {chartProps, chartRef} = useChartContext();
    const {chartType} = chartProps;
    const {equipmentItem} = useEquipmentByParams();
    const selected = useSelectedByEquipment(equipmentItem.id);
    const allInstallationPointIds = equipmentItem.installationPoints.map((i) => i.id);
    const isTachometer = useIsTachometer(chartType);
    const {alertConditionsCount} = useAlertConditionListQuery(chartType, isTachometer ? allInstallationPointIds : selected);

    const {showAlerts} = useChartStateStoreContext((state) => state.settings, shallow);
    const {toggleAlerts} = useChartStateStoreContext((state) => state.actions);
    const showAlertsToggle = () => {
        chartRef.current.chart.isPaused = false;
        toggleAlerts();
    };

    if (!auth.userCan("showAlarms") || alertConditionsCount <= 0) {
        return <></>;
    }

    return (
        <span>
            <span
                className="alert-link"
                onClick={showAlertsToggle}
            >
                <label className="switch">
                    <input
                        readOnly={true}
                        type="checkbox"
                        checked={showAlerts ? "checked" : false}
                    />
                    <span className="slider round" />
                </label>
                Show Alerts
            </span>
        </span>
    );
};

export default ShowAlertsButton;
