import React, {Component} from "react";
import EquipmentSelect from "./equipment-select";
import {
    get as _get
} from "lodash";
import PropTypes from "prop-types";
import CollapseLocationSelect from "../../shared/collapseLocationSelect/collapseLocationSelect";

const propTypes = {
    setEquipment: PropTypes.func,
    filterOutEquipmentIds: PropTypes.array
};

class InstallationPointFinder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            locationId: null,
            equipment: null
        };
    }

    handleChangeLocation = (e) => {
        const {setEquipment} = this.props;

        const value = _get(e, "target.value");

        this.setState({
            locationId: value,
            equipment: null
        }, () => setEquipment(null));
    }

    handleEquipmentChange = (equipment) => {
        const {setEquipment} = this.props;

        this.setState({
            equipment: equipment,
        }, () => setEquipment(this.state.equipment));
    }

    render() {

        return (
            <div className={"row form-group mb-2 mt-2"}>
                <div className={"col-6"}>
                    <CollapseLocationSelect
                        className={"form-control modal-input"}
                        addClassName={"w100"}
                        emptyOptionLabel={"Select location"}
                        selectName={"locationId"}
                        value={this.state.locationId || ""}
                        onChange={this.handleChangeLocation}
                        needMarkFacility={true}
                    />
                </div>
                <div className={"col-6"}>
                    <EquipmentSelect
                        locationId={this.state.locationId || 0}
                        emptyOptionLabel={"Select equipment"}
                        selectName={"equipment"}
                        value={(this.state.equipment && this.state.equipment.id) || ""}
                        onChange={this.handleEquipmentChange}
                        disabled={!this.state.locationId}
                        filterOutEquipmentIds={this.props.filterOutEquipmentIds || []}
                    />
                </div>
            </div>
        );
    }
}

InstallationPointFinder.propTypes = propTypes;

export default InstallationPointFinder;