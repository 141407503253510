import {useQuery} from "@tanstack/react-query";
import {TACHOMETER_API} from "./constants";
import ApiEquipment from "../../../api/equipment";
import {get as _get} from "lodash";

export const useSearchTachometerQuery = (equipment) => {
    const {data, ...rest} = useQuery([TACHOMETER_API.SEARCH_TACHOMETER, equipment], () =>
        ApiEquipment.searchTachometers({facility_id: _get(equipment, ["locationTable", "facility_id"])})
    );
    return {tachometerNodeList: _get(data, "list"), ...rest};
};
