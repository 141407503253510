import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TACHOMETER_API } from "./constants";
import ApiEquipment from "../../../api/equipment";
import { EQUIPMENT_API } from "../equipment/constants";

export const useTachometerUpdateMutation = (equipmentId) => {
    const queryClient = useQueryClient();

    return useMutation((data) => ApiEquipment.changeSpeed(equipmentId, data), {
        onSuccess: (data) => {
            queryClient.refetchQueries({ queryKey: [TACHOMETER_API.GET_TACHOMETER, +equipmentId] });
            queryClient.setQueriesData([EQUIPMENT_API.GET_EQUIPMENT, +equipmentId], data);
        },
    });
};
