import React, {useState} from "react";
import {withGlobalStore} from "../../stores/GlobalStore";
import {Button, Collapse, UncontrolledTooltip} from "reactstrap";
import {Modal} from "../../shared";
import PropTypes from "prop-types";
import Toggle from "../../shared/toggle/toggle";

import "../../assets/scss/components/privacy-policy/privacy-policy.scss";

const PrivacyPolicySettingsModal = ({isOpen, onClose, onChangeData, data = {}, saveData, loading}) => {

    const [isOpenStrictly, setIsOpenStrictly] = useState(false);
    const [isOpenFunctionality, setIsOpenFunctionality] = useState(false);
    const [isOpenTrackingPerformance, setIsOpenTrackingPerformance] = useState(false);
    const [isOpenTargeting, setIsOpenTargeting] = useState(false);

    return (
        <Modal showModal={isOpen} withoutFooter={true} onClose={() => onClose(false)}>
            <>
                <h4>Cookie Settings</h4>
                <p>
                    When you visit any web site, it may store or retrieve information on your browser, mostly
                    in the form of cookies. This information might be about you, your preferences or your
                    device and is mostly used to make the site work as you expect it to. The information
                    does not usually directly identify you, but it can give you a more personalized web
                    experience.
                </p>

                <p>
                    Because we respect your right to privacy, you can choose not to allow some types of
                    cookies. Click on the different category headings to find out more and change our default
                    settings. However, blocking some types of cookies may impact your experience of the
                    site and the services we are able to offer.
                </p>

                <p>
                    In some cases, data obtained from cookies is shared with third parties and may be
                    considered a “sale of personal information” under the California Consumer Privacy Act
                    (CCPA). You can exercise your right to opt-out of that sharing at any time by disabling
                    cookies.
                </p>

                <div className="settings-modal-inputs-block">
                    <div className="settings-modal-inputs__header d-flex justify-content-between">
                        <span onClick={() => setIsOpenStrictly(!isOpenStrictly)}>Strictly Necessary
                            <i className={`ml-2 fa fa-${!isOpenStrictly ? "plus" : "minus"}`}/>
                        </span>
                        <span id={"strictly-nessesary"}>
                            <Toggle onChange={onChangeData} name="strictlyNecessary" disabled={true} checked={true} />
                            <UncontrolledTooltip popperClassName="tooltip-md" placement="top" target={"strictly-nessesary"}>
                                Always Active
                            </UncontrolledTooltip>
                        </span>

                    </div>
                    <Collapse isOpen={isOpenStrictly}>
                        <p>
                            These Cookies are essential to provide You with services available through the
                            Website and to enable You to use some of its features. They help to authenticate
                            users and prevent fraudulent use of user accounts. Without these Cookies, the
                            services that You have asked for cannot be provided, and We only use these Cookies
                            to provide You with those services.
                        </p>
                    </Collapse>
                </div>

                <div className="settings-modal-inputs-block">
                    <div className="settings-modal-inputs__header d-flex justify-content-between">
                        <span onClick={() => setIsOpenFunctionality(!isOpenFunctionality)}>Functionality Cookies
                            <i className={`ml-2 fa fa-${!isOpenFunctionality ? "plus" : "minus"}`}/>
                        </span>
                        <Toggle onChange={onChangeData} name="functionality" checked={+data.functionality === 1}/>
                    </div>
                    <Collapse isOpen={isOpenFunctionality}>
                        <p>
                            These Cookies allow us to remember choices You make when You use the Website,
                            such as remembering your login details or language preference. The purpose of these
                            Cookies is to provide You with a more personal experience and to avoid You having to
                            re-enter your preferences every time You use the Website.

                        </p>
                    </Collapse>
                </div>

                <div className="settings-modal-inputs-block">
                    <div className="settings-modal-inputs__header d-flex justify-content-between">
                        <span onClick={() => setIsOpenTrackingPerformance(!isOpenTrackingPerformance)}>
                            Tracking and Performance Cookies
                            <i className={`ml-2 fa fa-${!isOpenTrackingPerformance ? "plus" : "minus"}`}/>
                        </span>
                        <Toggle onChange={onChangeData} name="trackingPerformance" checked={+data.trackingPerformance === 1}/>
                    </div>
                    <Collapse isOpen={isOpenTrackingPerformance}>
                        <p>
                            These Cookies are used to track information about traffic to the Website and how
                            users use the Website. The information gathered via these Cookies may directly or
                            indirectly identify you as an individual visitor. This is because the information
                            collected is typically linked to a pseudonymous identifier associated with the device
                            you use to access the Website. We may also use these Cookies to test new pages,
                            features or new functionality of the Website to see how our users react to them.
                        </p>
                    </Collapse>
                </div>

                <div className="settings-modal-inputs-block">
                    <div className="settings-modal-inputs__header d-flex justify-content-between">
                        <span onClick={() => setIsOpenTargeting(!isOpenTargeting)}>
                            Targeting and Advertising Cookies
                            <i className={`ml-2 fa fa-${!isOpenTargeting ? "plus" : "minus"}`}/>
                        </span>
                        <Toggle onChange={onChangeData} name="targeting" checked={+data.targeting === 1}/>
                    </div>
                    <Collapse isOpen={isOpenTargeting}>
                        <p>
                            These Cookies track your browsing habits to enable Us to show advertising which is
                            more likely to be of interest to You. These Cookies use information about your
                            browsing history to group You with other users who have similar interests. Based on
                            that information, and with Our permission, third party advertisers can place Cookies
                            to enable them to show adverts which We think will be relevant to your interests while
                            You are on third party websites.
                        </p>
                    </Collapse>
                </div>

                <div className="modal-control-btns d-flex justify-content-between flex-nowrap">
                    <Button
                        className="mt-0 mx-1 ml-0"
                        color="white"
                        onClick={() => onClose(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="mt-0 mx-1"
                        color="white"
                        onClick={() => saveData(data)}
                    >
                        {loading
                            ? <span><i className="fa fa-spinner"/> Processing</span>
                            : "Confirm my choice"
                        }
                    </Button>
                    <Button
                        className="mt-0 mx-1 mr-0"
                        color={"black"}
                        onClick={() => saveData()}
                    >
                        {loading
                            ? <span><i className="fa fa-spinner"/> Processing</span>
                            : "Accept All"
                        }
                    </Button>
                </div>
            </>
        </Modal>
    );
};

PrivacyPolicySettingsModal.propTypes = {
    isOpen: PropTypes.bool,
    loading: PropTypes.bool,
    onClose: PropTypes.func,
    onChangeData: PropTypes.func,
    saveData: PropTypes.func,
    data: PropTypes.object
};

export default withGlobalStore(PrivacyPolicySettingsModal);