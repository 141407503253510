import React, {Component} from "react";
import PropTypes from "prop-types";
import ApiTachometers from "../../../api/tachometer";
import Table from "./table";
import {Loader, Pager} from "../../../shared";
import SearchInput from "../../shared/search-input";
import {Link} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {withGlobalStore} from "../../../stores/GlobalStore";
import LimitSelect from "../../shared/limit-select";
import {
    get as _get
} from "lodash";
import {withLocationSelectStore} from "../../../stores/LocationSelectStore";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import Helper from "../../../helpers/helper";
import { HeaderSimple } from "../../../shared/header";

class Index extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{name: "Manage Tachometer"}],
            loader: true,
            list: [],
            pagination: {
                page: 1,
                pages: 1,
                perpage: Helper.getPerPage("tachometers"),
                total: 0
            },
            sort: {
                field: "",
                sort: "asc"
            },
            filter: {
                query: "",
                locationId: props.getLocationId()
            },
            globalSearchString: "",
            deleteTachometerId: null,
        };


        this.finished = false;

        this.handleFetch = this.handleFetch.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handlePagerChange = this.handlePagerChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
        this.onDeleteTachometer = this.onDeleteTachometer.bind(this);
        this.deleteTachometer = this.deleteTachometer.bind(this);
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    componentDidMount() {
        Promise.all([
            this.handleFetch(),
        ]);
    }

    handleFetch() {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        return ApiTachometers.list({
            query: {
                sort: this.state.sort,
                filter: this.state.filter,
                pagination: this.state.pagination
            },
            signal: this.signal
        }).then(response => {
            if (response) {
                const {list = [], meta = {}} = response;
                this.setState({
                    loader: false,
                    pagination: Object.assign({}, this.state.pagination, meta),
                    list
                }, () => {
                    if (!this.finished) {
                        this.finished = true;
                    }
                });
            }
        });
    }

    handleSortChange(field = "") {
        const {sort} = this.state;

        this.setState({
            sort: Object.assign({}, {
                field,
                sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"
            }),
            loader: true
        }, this.handleFetch);
    }

    handlePagerChange(page = 1) {
        this.setState({loader: true, pagination: Object.assign({}, this.state.pagination, {page})}, this.handleFetch);
    }

    handleFilterChange(obj = {}) {
        this.finished = false;
        this.setState({
            filter: Object.assign({}, this.state.filter, obj),
            pagination: Object.assign({}, this.state.pagination, {page: 1}),
            loader: true
        }, this.handleFetch);
    }

    handleSearch = (value) => {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }

        this.handleFilterChange({query: value});
    };

    onDeleteTachometer(deleteTachometerId) {
        this.setState({deleteTachometerId});
    }

    deleteTachometer() {
        ApiTachometers.delete(this.state.deleteTachometerId).then(() => {
            this.onDeleteTachometer(null);
            this.handleFetch();
        });
    }

    handleLimitChange = e => {
        const {value} = e.target;
        Helper.setPerPage("tachometers", value);
        this.setState({
            loader: true,
            pagination: {
                ...this.state.pagination,
                page: 1,
                perpage: value
            }
        }, this.handleFetch);
    };

    render() {
        const {
            breadcrumbs,
            loader,
            list,
            pagination,
            sort,
            filter,
            globalSearchString,
            deleteTachometerId,
        } = this.state;
        const {auth} = this.props;

        return (
            <div>
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">Manage Tachometers</div>
                    {auth.userCan("editTachometers") &&
                        <div className="subheader-controls">
                            <div className="subheader-toolbar">
                                <Link
                                    to="/network/tachometers/add"
                                    className="btn btn-primary btn-sm"
                                >
                                    <i className="fa fa-plus"/> Add Tachometer
                                </Link>
                            </div>
                        </div>
                    }
                </div>
                <div className="block">
                    <div className="block-header">
                        <div className="block-label">
                            <div className="form-list form-list--row">
                                <div className="form-group form-group--inline">
                                    <div className="form-label"><label>Asset Tree Branch:</label></div>
                                    <CollapseLocationSelect
                                        className="form-control form-control-sm"
                                        selectName={"locationId"}
                                        style={{textTransform: "capitalize"}}
                                        value={+_get(filter, ["locationId"], 0)}
                                        onChange={(e) => {
                                            this.handleFilterChange({
                                                locationId: _get(e, "target.value")
                                            });
                                        }}
                                        emptyOptionLabel={"All"}
                                        checkStoreValue={true}
                                        withFavorite={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="top-pagination-block" className="block-pagination">
                            {pagination.total > 10 &&
                            <div className="limit-select-block">
                                <LimitSelect
                                    name={"perpage"}
                                    onChange={this.handleLimitChange}
                                    defaultValue={pagination.perpage}
                                    limits={[10, 20, 50, 100]}
                                />
                            </div>
                            }
                            {pagination.pages > 1 &&
                            <Pager
                                page={pagination.page}
                                pages={pagination.pages}
                                perPage={pagination.perpage}
                                onPagerChange={this.handlePagerChange}
                            />
                            }
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <div style={{minWidth: "178px"}}>
                                    <SearchInput
                                        placeholder="Search Tachometers"
                                        query={filter.query}
                                        onChange={this.handleSearch}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-body">
                        {loader
                            ?
                            <Loader/>
                            :
                            <div>
                                <Table
                                    list={list}
                                    sort={sort}
                                    query={filter.query}
                                    onSortChange={this.handleSortChange}
                                    onDeleteTachometer={this.onDeleteTachometer}
                                />
                            </div>
                        }
                    </div>
                </div>
                <button
                    disabled={list.length > 0 ? false : true}
                    title={list.length > 0 ? "" : "There is no data to export at this time."}
                    onClick={ () => {
                        this.setState({ loader: true });
                        ApiTachometers.export({
                            query: {
                                sort: this.state.sort,
                                filter: this.state.filter
                            },
                            signal: this.signal
                        }).then(response => response.blob()).then( (blob) => {
                            this.setState({ loader: false });
                            const url = window.URL.createObjectURL(new Blob([blob]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", "tachometers " + new Date().toLocaleString("en-US")+".csv");
                            link.setAttribute("id", "test-file");
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        } );
                    } }
                    className="btn v2-btn blue ml-3"
                    style={{width:100}}
                >
                    <img src={"/assets/pic/icon-download.svg"} alt=""/>
                    <span>Export</span>
                </button>
                {deleteTachometerId &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title="DELETE TACHOMETER"
                        onConfirm={this.deleteTachometer}
                        onCancel={() => this.onDeleteTachometer(null)}
                    >
                        Are you sure you want to delete this tachometer?
                    </SweetAlert>
                }
            </div>
        );
    }
}

Index.propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object,

    // LocationSelectStore
    getLocationId: PropTypes.func
};

export default withLocationSelectStore(withGlobalStore(Index));