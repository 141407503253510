/**
 * Calculates value from closure just once and only when you need it
 */
export default class Cacher {

    constructor(closure) {
        this._value = null;
        this.closure = closure;
    }

    get value() {
        if (this._value === null) {
            this._value = this.closure();
        }

        return this._value;
    }
}