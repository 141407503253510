import cookies from "react-cookies";
import { useState } from "react";
import { isMobile, isTablet } from "react-device-detect";

const COOKIE_SIDE = "hideSide";

export const useHideSide = () => {
    let hideSideCookie = +cookies.load(COOKIE_SIDE) === 1;
    const [hideSide, setHideSide] = useState(isMobile || isTablet ? 1 : +hideSideCookie);

    const update = (hideSide) => {
        setHideSide(hideSide);
        cookies.save(COOKIE_SIDE, hideSide ? 1 : 0);
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 100);
    };

    return { update, hideSide };
};
