import React from "react";
import {get} from "lodash";
import PropTypes from "prop-types";
import auth from "../../../services/auth";

const InstPointsRow = ({equipment}) => {
    return (
        <span>
            {equipment.alertingInstallationPointsCount ? (
                <span>
                    {auth.userCan("editEquipment") ? (
                        <React.Fragment>
                            <span className="color-danger">{equipment.alertingInstallationPointsCount}</span>({equipment.countInstallationPoints})
                        </React.Fragment>
                    ) : (
                        <React.Fragment>{equipment.countInstallationPoints}</React.Fragment>
                    )}
                </span>
            ) : (
                <span>{equipment.countInstallationPoints}</span>
            )}
        </span>
    );
};

InstPointsRow.propTypes = {
    equipment: PropTypes.object,
};

export const EquipmentInfo = ({equipment}) => {
    return (
        <>
            <div className="eq-line">
                <img
                    src="/assets/pic/icon-marker.svg"
                    alt=""
                />
                <span>{get(equipment, ["locationTable", "name"])}</span>
            </div>
            <div className="eq-line">
                <img
                    src="/assets/pic/icon-item-sensors.svg"
                    alt=""
                />
                <span>Sensors: {equipment.countSensors}</span>
            </div>
            {auth.userCan("manageAdapter") && (
                <div className="eq-line">
                    <img
                        src="/assets/pic/icon-item-sensors.svg"
                        alt=""
                    />
                    <span>Adapters: {equipment.countAdapters}</span>
                </div>
            )}
            <div className="eq-line">
                <img
                    src="/assets/pic/icon-item-sensors.svg"
                    alt=""
                />
                <span>
                    Installation Points: <InstPointsRow equipment={equipment} />
                </span>
            </div>
        </>
    );
};

EquipmentInfo.propTypes = {
    equipment: PropTypes.object,
};
