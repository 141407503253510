import {useQuery} from "@tanstack/react-query";
import {CHART_MARK_API} from "./constants";
import ApiChartMarkFft from "../../../api/chartMarkFft";

export const useChartMarksQuery = (equipmentId) => {
    const {data: chartMarksData, ...rest} = useQuery([CHART_MARK_API.GET_CHART_MARK_FFT, equipmentId], () =>
        ApiChartMarkFft.get(equipmentId)
    );

    return {chartMarksData: chartMarksData?.list, ...rest};
};
