import {useEffect} from "react";
import {get as _get} from "lodash";

export const useChartHoverEffect = (chartRef) => {
    const onChartOver = () => {
        chartRef.current.chart.isChartOver = true;
    };

    const onChartOut = () => {
        chartRef.current.chart.isChartOver = false;
    };

    useEffect(() => {
        const chartContainer = _get(chartRef, "current.container.current");

        if (chartContainer) {
            chartContainer.addEventListener("mouseover", onChartOver);
            chartContainer.addEventListener("mouseout", onChartOut);
        }

        return () => {
            if (chartContainer) {
                chartContainer.removeEventListener("mouseover", onChartOver);
                chartContainer.removeEventListener("mouseout", onChartOut);
            }
        };
    }, []);
};
