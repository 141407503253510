import React, {useMemo} from "react";
import Helper from "../../../helpers/helper";
import PropTypes from "prop-types";
import {mapValues as _mapValues} from "lodash";
import SearchBar from "./components/searchBar";
import FilterBar from "./components/filterBar";
import "./headerDashboard.scss";
import {useParams} from "react-router";

const HeaderDashboard = (props) => {
    const {dnd, handleFilter, handleSearch, resetFilter, setLocationName, list, filter, auth, updateDND} = props;

    const params = useParams();

    const viewDashboardType = useMemo(() => Helper.getHashParams().viewDashboardType || "blocks", [params]);

    return (
        <div
            className="header"
            id="k_header"
        >
            <div className="search-bar-wrapper">
                <SearchBar
                    levelList={_mapValues(list, (item = {}) => (item.level || {}).name)}
                    dnd={dnd}
                    filter={filter}
                    handleFilter={handleFilter}
                    handleSearch={handleSearch}
                    resetFilter={resetFilter}
                    setLocationName={setLocationName}
                    auth={auth}
                />
                {viewDashboardType === "blocks" && (
                    <FilterBar
                        updateDND={updateDND}
                        dnd={dnd}
                    />
                )}
            </div>
        </div>
    );
};

HeaderDashboard.propTypes = {
    dnd: PropTypes.object,
    handleFilter: PropTypes.func,
    handleSearch: PropTypes.func,
    resetFilter: PropTypes.func,
    setLocationName: PropTypes.func,
    updateDND: PropTypes.func,
    list: PropTypes.object,
    filter: PropTypes.object,
    auth: PropTypes.object,
};

export default HeaderDashboard;
