import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {get as _get, each as _each, setWith as _setWith, cloneDeep as _cloneDeep, filter as _filter, omit as _omit} from "lodash";
import InstallationPointFormData from "../forms/InstallationPointFormData";
import EquipmentInstallationPointApi from "../../../api/equipmentInstallationPoint";
import EquipmentApi from "../../../api/equipment";

const BatchInstallationPointData = (props) => {
    const [state, setState] = useState({
        installationPoints: [],
        formErrors: {},
        inProgress: false,
    });


    const initBatchPoints = () => {
        let installationPoints = [];

        _each(getPoints(), (point) => {
            installationPoints.push(InstallationPointFormData.getInstallationPointObject(point));
        });

        setState((prev) => ({...prev, installationPoints}));
    };

    useEffect(initBatchPoints, [props.points]);

    const getPoints = () => {
        const {points} = props;

        return _cloneDeep(
            _filter(points, (item) => {
                return item.point_type === "sensor" || item.point_type === "adapter";
            })
        );
    };

    const onUpdate = () => {
        const {installationPoints} = state;
        const {equipmentId, afterUpdate} = props;

        setState((prev) => ({...prev, inProgress: true}));

        EquipmentApi.checkService(props.equipmentId).then(() =>
            EquipmentInstallationPointApi.batchUpdate(equipmentId, {installationPoints})
                .then((response) => {
                    if (response.status === "ok") {
                        afterUpdate();
                        setState((prev) => ({...prev, inProgress: false}));
                    }
                })
                .catch((response) => {
                    setState((prev) => ({...prev, formErrors: response.errors || {}, inProgress: false}));
                })
        );
    };

    const onChange = (event, index) => {
        const data = [...state.installationPoints];

        const key = _get(event, "target.name", "");
        const val = _get(event, "target.value", "");

        _setWith(data, index + "." + key, val, Object);
        removeError("point." + index, key);

        setState((prev) => ({...prev, installationPoints: data}));
    };

    const removeError = (index, key) => {
        const oldFormErrors = {...state.formErrors};
        const indexError = _get(oldFormErrors, index);

        const newIndexError = _omit(indexError, key);
        _setWith(oldFormErrors, index, newIndexError, Object);

        setState((prev) => ({...prev, formErrors: oldFormErrors}));
    };

    const {installationPoints, formErrors, inProgress} = state;
    const {children} = props;

    return children({
        installationPoints,
        formErrors,
        inProgress,
        onChange: onChange,
        onUpdate: onUpdate,
    });
};

BatchInstallationPointData.propTypes = {
    children: PropTypes.node,
    equipmentId: PropTypes.number,
    points: PropTypes.array,
    afterUpdate: PropTypes.func,
};

export default BatchInstallationPointData;
