import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get,
    includes as _includes,
} from "lodash";

class ViewFooter extends Component
{
    render() {
        const {onShowCompletion, actionItem, onCancelSnooze} = this.props;

        const state = +_get(actionItem, "state");

        const isArchive = _includes([5, 9], state);
        const hasReport = !!Object.keys(_get(actionItem, "wwReports.0", {})).length;
        const isNoActions = +_get(actionItem, "is_no_actions") === 1;
        const isTroubleshootingHold = +_get(actionItem, "is_troubleshooting_hold") === 1;

        let showWwEditReportBtn = false;
        let showCloseBtn = false;
        let showWwResubmitReportBtn = false;

        if (hasReport) {
            showWwEditReportBtn = !isArchive && state === 6;
            showCloseBtn = !isArchive && !_includes([0, 2, 3, 4, 6, 7, 10], state);
            showWwResubmitReportBtn = !isArchive && _includes([0, 2, 7, 10], state);
        } else {
            showWwEditReportBtn = isNoActions && !isArchive && !_includes([0, 2, 3, 7, 10], state);
            showCloseBtn = !isNoActions && !isArchive && state !== 3;
            showWwResubmitReportBtn = isNoActions && !isArchive && _includes([0, 2, 7, 10], state) && state !== 3;
        }

        const showCancelSnoozeBtn = state === 3 && !isArchive && !isTroubleshootingHold;

        return (
            <React.Fragment>
                <div className={"action-item-footer"}>
                    {showCloseBtn &&
                        <button
                            onClick={onShowCompletion}
                            className="btn v2-btn blue-border v2-btn-size"
                        >
                            Complete Action Item
                        </button>
                    }
                    {showWwEditReportBtn &&
                        <button
                            onClick={onShowCompletion}
                            className="btn v2-btn blue-border"
                        >
                            Edit Report
                        </button>
                    }
                    {showWwResubmitReportBtn &&
                        <button
                            onClick={onShowCompletion}
                            className="btn v2-btn blue-border"
                        >
                            Resubmit Report
                        </button>
                    }
                    {showCancelSnoozeBtn &&
                        <button
                            onClick={onCancelSnooze}
                            className="btn v2-btn blue-border"
                        >
                            Cancel Snooze
                        </button>
                    }
                </div>
            </React.Fragment>
        );
    }
}

ViewFooter.propTypes = {
    actionItem: PropTypes.object,
    onShowCompletion: PropTypes.func,
    onCancelSnooze: PropTypes.func
};

export default ViewFooter;