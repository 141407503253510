import $ from "jquery";
import React, {useEffect, useReducer, useRef} from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ChartHelper from "../../../../../helpers/chart";
import {find as _find} from "lodash";
import FrequencyConverter from "../../../../../helpers/frequency-converter";
import {withFftReading} from "../hoc/withFftReading";
import {useAlertConditionStoreActions} from "../../../../../stores/zustand/AlertConditionStore";
import {FREQUENCY_TYPES} from "../../../../../constants/constants";

const isDarkTheme = localStorage.getItem("lightMode");

const initialOptions = {
    chart: {
        zoomType: false,
        backgroundColor:
            isDarkTheme === "true"
                ? {
                      linearGradient: {x1: 0, y1: 0, x2: 1, y2: 1},
                      stops: [
                          [0, "#3a3934"],
                          [1, "#36342a"],
                      ],
                  }
                : "#FFFFFF",
        height: 100,
        animation: false,
    },
    credits: {
        enabled: false,
    },
    plotOptions: {
        line: {
            lineWidth: 1,
            states: {
                hover: {
                    enabled: false,
                },
            },
        },
        series: {
            connectNulls: false,
            animation: {
                duration: 0,
            },
        },
    },
    title: {
        text: "",
    },
    legend: {
        enabled: false,
    },
    xAxis: {
        type: "datetime",
    },
    yAxis: {
        opposite: false,
        title: {
            align: "middle",
            text: "",
        },
        ...(isDarkTheme === "true" ? {gridLineColor: "rgba(200,200,200,0.4)", gridLineWidth: 0.5} : {}),
    },
    rangeSelector: {
        enabled: false,
    },
    tooltip: {
        enabled: false,
        ...(isDarkTheme === "true" ? {backgroundColor: "rgba(54, 54, 50, 0.9)", style: {color: "#ddd"}} : {}),
    },
    series: [],
    navigation: {
        buttonOptions: {
            enabled: false,
        },
    },
    lang: {noData: ""},
};

const initialState = {
    options: initialOptions,
    readingsLoader: true,
    loader: true,
};

const reducer = (state, action) => ({...state, ...action});

const BandNavigationChart = ({alert, getPointById, chartSeries, equipment}) => {
    const ref = useRef(null);
    const [state, dispatch] = useReducer(reducer, initialState);
    const {options, loader, readingsLoader} = state;
    const setReadings = () =>
        currentReadings()
            .then((readings) => drawSeries(readings))
            .catch((err) => console.log(err));

    const currentReadings = () => {
        return Promise.resolve();
    };

    const drawSeries = () => {
        const rpmSpeed = ChartHelper.getPointSpeed(getPointById(alert.installationPointId), equipment) || {};
        const currentSpeed = options.series[0]?.speed || rpmSpeed.value || 0;
        const currentFrequency =
            currentSpeed === 0 && alert.fftBandFrequencyType === FREQUENCY_TYPES.ORDERS ? FREQUENCY_TYPES.HZ : alert.fftBandFrequencyType;

        options.xAxis.currentSpeed = currentSpeed;
        options.xAxis.currentFrequency = currentFrequency;
        options.xAxis.labels = {formatter: ChartHelper.formatXAxisFFT};
        options.series = [...chartSeries];
        dispatch({options, readingsLoader: false});
    };

    const drawPlotLines = () => {
        if (!options.xAxis.currentFrequency) return;

        options.xAxis.type = "line";
        options.yAxis.softMin = parseFloat(alert.value);
        options.yAxis.softMax = parseFloat(alert.value);
        options.chart.spacingTop = 15;
        options.yAxis.visible = false;
        options.xAxis.min = null;
        options.xAxis.max = null;

        const fftBandFrom = +FrequencyConverter.fromType(options.xAxis.currentFrequency, alert.fftBandFrom, options.xAxis.currentSpeed)
            .toHz()
            .numberFormat();

        const fftBandTo = +FrequencyConverter.fromType(options.xAxis.currentFrequency, alert.fftBandTo, options.xAxis.currentSpeed)
            .toHz()
            .numberFormat();

        options.xAxis.plotBands = [
            {
                id: "band",
                className: "cursor-move",
                color: "rgba(196, 196, 196, 0.34)",
                from: fftBandFrom,
                to: fftBandTo,
                events: {
                    mousedown: (ev) => {
                        const band = _find(ref.current.chart.xAxis[0].plotLinesAndBands, {id: "band"});
                        const lineTo = _find(ref.current.chart.xAxis[0].plotLinesAndBands, {id: "lineTo"});
                        const lineFrom = _find(ref.current.chart.xAxis[0].plotLinesAndBands, {id: "lineFrom"});
                        let xOffset, clickX, from, to;

                        const start = (ev) => {
                            $(document).bind({
                                "mousemove.line": step,
                                "mouseup.line": stop,
                                "click.line": stop,
                            });
                            clickX = ev.pageX - band.svgElem.translateX;
                        };

                        const step = (ev) => {
                            xOffset = ev.pageX - clickX;

                            const pixelFrom = toPixels(fftBandFrom);
                            const pixelTo = toPixels(fftBandTo);

                            if (pixelFrom + xOffset < toPixels(0)) {
                                xOffset = toPixels(0) - pixelFrom;
                            }
                            if (pixelTo + xOffset > toPixels(band.axis.max)) {
                                xOffset = toPixels(band.axis.max) - pixelTo;
                            }

                            from = toValue(xOffset + toPixels(fftBandFrom));
                            to = toValue(xOffset + toPixels(fftBandTo));

                            band.svgElem.translate(xOffset, null);
                            lineFrom.svgElem.translate(xOffset, null);
                            lineTo.svgElem.translate(xOffset, null);
                            updateFftBand(from, to);
                        };

                        const stop = () => {
                            updateFftBand(from, to, true);
                            $(document).unbind(".line");
                        };

                        if (typeof band.svgElem.translateX === "undefined") {
                            band.svgElem.translate(0, 0);
                        }

                        start(ev);
                    },
                },
                zIndex: 9,
            },
        ];

        options.xAxis.plotLines = [
            {
                id: "lineFrom",
                className: "cursor-ew-resize",
                color: "black",
                width: 3,
                value: fftBandFrom,
                events: {
                    mousedown: (ev) => {
                        const band = _find(ref.current.chart.xAxis[0].plotLinesAndBands, {id: "band"});
                        const lineFrom = _find(ref.current.chart.xAxis[0].plotLinesAndBands, {id: "lineFrom"});
                        let xOffset, clickX, from;
                        const start = (ev) => {
                            $(document).bind({
                                "mousemove.line": step,
                                "mouseup.line": stop,
                                "click.line": stop,
                            });
                            clickX = ev.pageX - lineFrom.svgElem.translateX;
                        };

                        const step = (ev) => {
                            xOffset = ev.pageX - clickX;
                            const pixelFrom = toPixels(fftBandFrom);
                            const pixelTo = toPixels(fftBandTo);

                            if (pixelFrom + xOffset < toPixels(0)) {
                                xOffset = toPixels(0) - pixelFrom;
                            }
                            if (pixelFrom + xOffset > toPixels(band.axis.max)) {
                                xOffset = toPixels(band.axis.max) - pixelFrom;
                            }

                            let pixelWidth = pixelTo - pixelFrom;
                            const scale = (pixelWidth - xOffset) / pixelWidth;

                            from = toValue(xOffset + pixelFrom);

                            lineFrom.svgElem.translate(xOffset, null);
                            band.svgElem.translate(-(scale * pixelTo - pixelTo));
                            band.svgElem.attr({scaleX: scale});

                            updateFftBand(from, null);
                        };

                        const stop = () => {
                            updateFftBand(from, null, true);
                            $(document).unbind(".line");
                        };

                        if (typeof lineFrom.svgElem.translateX === "undefined") {
                            lineFrom.svgElem.translate(0, 0);
                        }

                        start(ev);
                    },
                },
                zIndex: 10,
            },
            {
                id: "lineTo",
                className: "cursor-ew-resize",
                color: "black",
                width: 3,
                value: fftBandTo,
                events: {
                    mousedown: (ev) => {
                        const band = _find(ref.current.chart.xAxis[0].plotLinesAndBands, {id: "band"});
                        const lineTo = _find(ref.current.chart.xAxis[0].plotLinesAndBands, {id: "lineTo"});
                        let xOffset, clickX, to;
                        const start = (ev) => {
                            $(document).bind({
                                "mousemove.line": step,
                                "mouseup.line": stop,
                                "click.line": stop,
                            });
                            clickX = ev.pageX - lineTo.svgElem.translateX;
                        };

                        const step = (ev) => {
                            xOffset = ev.pageX - clickX;
                            const pixelFrom = toPixels(fftBandFrom);
                            const pixelTo = toPixels(fftBandTo);

                            if (pixelTo + xOffset < toPixels(0)) {
                                xOffset = toPixels(0) - pixelTo;
                            }
                            if (pixelTo + xOffset > toPixels(band.axis.max)) {
                                xOffset = toPixels(band.axis.max) - pixelTo;
                            }

                            let pixelWidth = pixelTo - pixelFrom;
                            const scale = (pixelWidth + xOffset) / pixelWidth;

                            to = toValue(xOffset + pixelTo);

                            lineTo.svgElem.translate(xOffset, null);
                            band.svgElem.translate(-(scale * pixelFrom - pixelFrom));
                            band.svgElem.attr({scaleX: scale});

                            updateFftBand(null, to);
                        };

                        const stop = () => {
                            updateFftBand(null, to, true);
                            $(document).unbind(".line");
                        };

                        if (typeof lineTo.svgElem.translateX === "undefined") {
                            lineTo.svgElem.translate(0, 0);
                        }

                        start(ev);
                    },
                },
                zIndex: 10,
            },
        ];

        dispatch({options, loader: false});
    };

    const toPixels = (value) => ref.current.chart.xAxis[0].toPixels(value);
    const toValue = (pixels) => ref.current.chart.xAxis[0].toValue(pixels);

    useEffect(() => {
        if (!readingsLoader) {
            drawPlotLines();
        }
    }, [readingsLoader]);

    useEffect(() => {
        dispatch({readingsLoader: true, loader: true});
        setReadings();
    }, [alert.id, alert.axisId, alert.fftAlertType, alert.installationPointId, alert.fftBandFrequencyType]);

    useEffect(() => {
        dispatch({loader: true});
        drawPlotLines();
    }, [alert.alert_level_id, alert.fftBandFrequencyType, alert.fftBandFrom, alert.fftBandTo, alert.value, alert.index, alert]);

    const {setRange} = useAlertConditionStoreActions();

    const updateFftBand = (from, to, commit = false) => {
        let state = {};

        const convertedFrom = +FrequencyConverter.fromHz(from, options.xAxis.currentSpeed || 1)
            .toType(alert.fftBandFrequencyType)
            .numberFormat();

        const convertedTo = +FrequencyConverter.fromHz(to, options.xAxis.currentSpeed || 1)
            .toType(alert.fftBandFrequencyType)
            .numberFormat();
        const changeFrom = from !== null && typeof from != "undefined" && Math.round(convertedFrom) !== Math.round(alert.fftBandFrom);
        const changeTo = to !== null && typeof from != "undefined" && Math.round(convertedTo) !== Math.round(alert.fftBandTo);

        state.fftBandFrom = changeFrom ? Math.round(convertedFrom).toString() : alert.fftBandFrom;
        state.fftBandTo = changeTo ? Math.round(convertedTo).toString() : alert.fftBandTo;

        if (+state.fftBandFrom > +state.fftBandTo) {
            state = {
                fftBandTo: state.fftBandFrom,
                fftBandFrom: state.fftBandTo,
            };
        }
        if (commit) {
            // setCurrent(state);
            setRange({
                from: state.fftBandFrom,
                to: state.fftBandTo,
            });
        } else {
            const $inputFftBandFrom = $(".current-alert-fftBandFrom");
            const $inputFftBandTo = $(".current-alert-fftBandTo");
            $inputFftBandFrom && $inputFftBandFrom.length && $inputFftBandFrom.val(state.fftBandFrom);
            $inputFftBandTo && $inputFftBandTo.length && $inputFftBandTo.val(state.fftBandTo);
        }
    };

    if (loader) return "";

    return (
        <HighchartsReact
            ref={ref}
            highcharts={Highcharts}
            constructorType="chart"
            updateArgs={[true, true, false]}
            options={options}
        />
    );
};

BandNavigationChart.propTypes = {
    alert: PropTypes.object,
    syncFFTChart: PropTypes.object,
    updateFftBand: PropTypes.func,
    getPointById: PropTypes.func,
    chartSeries: PropTypes.array,
    equipment: PropTypes.object,
    controller: PropTypes.object,
};

export default withFftReading(BandNavigationChart);
