import React, {Component} from "react";
import PropTypes from "prop-types";


class HelpSimple extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {title} = this.props;

        return (
            <React.Fragment>
                <h5 className="chart-title mt-2 mb-2">{title}</h5>
                <table className="table table-head-noborder">
                    <tbody>
                        <tr>
                            <td>
                                <span className="badge badge-secondary"><b>L</b></span>
                            </td>
                            <td>Show\Hide legend chart</td>
                        </tr>
                        <tr>
                            <td>
                                <span className="badge badge-secondary"><b>H</b></span>
                            </td>
                            <td>Hold tooltip</td>
                        </tr>
                        <tr>
                            <td>
                                <span className="badge badge-secondary"><i className="fa fa-arrow-right"/></span>
                            </td>
                            <td>Hover next point</td>
                        </tr>
                        <tr>
                            <td>
                                <span className="badge badge-secondary"><i className="fa fa-arrow-left"/></span>
                            </td>
                            <td>Hover previous point</td>
                        </tr>
                        <tr>
                            <td>
                                <span className="badge badge-secondary"><b>Delete</b></span> / <span className="badge badge-secondary"><b>Backspace</b></span>
                            </td>
                            <td>Delete hovered point</td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

HelpSimple.propTypes = {
    title: PropTypes.string
};

export default HelpSimple;