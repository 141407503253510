/* eslint-disable no-unused-vars */
import React, {useRef, useState} from "react";
import {UncontrolledTooltip} from "reactstrap";
import {useChartStateStoreContext} from "../../highcharts/twf/store/hooks/useStoreContext";
import {useSelectedAxis} from "../../highcharts/twf/hooks/helpers/useSelectedAxis";
import {usePointData} from "../../highcharts/hooks/usePointsData";
import {useInterpolationSyncStoreByPoint} from "../../highcharts/store/sync/InterpolationSuncStore";
import {useHighPassFilterSyncStoreByPoint} from "../../highcharts/store/sync/HighPassFilterSyncStore";
import {useGetFFTReadings, useGetPointReadingParamsByDateTime} from "../../../../hooks/api/fftReadings/ReadingsQuery";
import {useIsImpactVue} from "../../highcharts/hooks/useChartTypes";
import {useCurrentDate} from "../../highcharts/twf/hooks/helpers/useCurrentDate";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "./overMaxLabel.module.scss";
import {createPortal} from "react-dom";
import {IMAGE_BY_SENSOR_TYPE} from "./constants/imageBySensorType";

export const OverMaxLabel = () => {
    const ref = useRef(null);
    const [isShowAlert, setIsShowAlert] = useState(false);

    const installationPointId = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);
    const currentDate = useCurrentDate();
    const axis = useSelectedAxis();

    const pointData = usePointData(installationPointId);
    const isInterpolation = useInterpolationSyncStoreByPoint(installationPointId);
    const highpassFilter = useHighPassFilterSyncStoreByPoint(installationPointId, pointData);

    const versionType = pointData?.sensor?.version_type;

    const imageByVersion = IMAGE_BY_SENSOR_TYPE[versionType];

    const {params: readingsParams} = useGetPointReadingParamsByDateTime(
        pointData?.equipment_id,
        pointData?.id,
        axis,
        currentDate,
        isImpactVue,
        highpassFilter,
        isInterpolation
    );

    const {data: readings} = useGetFFTReadings(chartType, readingsParams, true, true);

    const isShowLabel = readings?.some((item) => item?.isCutoffByBandwidth);
    const firstReadingWithBandwidth = readings?.find((item) => item?.bandwidth);

    if (!isShowLabel) {
        return null;
    }

    return (
        <div className={styles.label}>
            <div
                ref={ref}
                className="badge badge-warning cursor-pointer"
            >
                <i className="fas fa-chart-area" />
            </div>
            <UncontrolledTooltip
                target={ref}
                autohide={false}
            >
                There is attenuation for the frequency range over {firstReadingWithBandwidth?.bandwidth}.
                {imageByVersion && (
                    <span
                        onClick={() => setIsShowAlert(true)}
                        className={styles.clickableLink}
                    >
                        {" "}
                        Viewing the frequency curve image is recommended.
                    </span>
                )}
                <br />
            </UncontrolledTooltip>
            {isShowAlert &&
                createPortal(
                    <SweetAlert
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        customClass={styles.alert}
                        onCancel={() => setIsShowAlert(false)}
                        onConfirm={() => setIsShowAlert(false)}
                    >
                        <img
                            src={imageByVersion}
                            className={styles.img}
                            alt=""
                        />
                    </SweetAlert>,
                    document.body
                )}
        </div>
    );
};
