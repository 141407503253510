import React, {useEffect, useState} from "react";
import {Loader, ValidationError} from "../../shared";
import {InputFormGroup} from "../../shared/formParts/formParts";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import SearchInput from "../shared/search-input";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../stores/GlobalStore";
import {get as _get, setWith as _setWith, findIndex as _findIndex, omit as _omit} from "lodash";
import {HeaderSimple} from "../../shared/header";
import {useHistory, useParams} from "react-router";
import {useRouteByIdQuery} from "../../hooks/api/routes/useRouteByIdQuery";
import Select from "react-select";
import AssetCollapseItem from "./components/assetCollapseItem/AssetCollapseItem";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import DraggableItem from "../../shared/drag/DraggableItem";
import DraggableList from "../../shared/drag/DraggableList";
import {useDraggableList} from "./hooks/useDraggableList";
import {useRouteUpdateMutation} from "../../hooks/api/routes/useRouteUpdateMutation";
import Toast from "../shared/toast";
import {useRouteCreateMutation} from "../../hooks/api/routes/useRouteCreateMutation";
import {useRouteCollectorAssetQuery} from "../../hooks/api/routes/useRouteCollectorAssetQuery";

const Form = () => {
    const {routeId} = useParams();
    const title = routeId ? "Edit Route" : "Add Route";
    const breadcrumbs = [{name: routeId ? "Edit Route" : "Add Route"}];

    // eslint-disable-next-line no-unused-vars
    const [isSorting, setIsSorting] = useState(false);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [availableAssetsTree, setAvailableAssetsTree] = useState([]);

    const {data: routeData, isLoading: isRouteLoading, isSuccess: isRouteSuccess, fetchStatus: routeFetchStatus} = useRouteByIdQuery(routeId);
    const {data: assets, isLoading: isAssetsLoading, isSuccess: isAssetsSuccess} = useRouteCollectorAssetQuery();

    const {mutateAsync: mutateRouteUpdate, isLoading: isUpdating} = useRouteUpdateMutation(routeId);
    const {mutateAsync: mutateRouteCreate, isLoading: isCreating} = useRouteCreateMutation();

    const history = useHistory();

    useEffect(() => {
        if (!isRouteSuccess || !isAssetsSuccess) {
            return;
        }

        const selectedAssets = Object.entries(routeData.assets)
            .reduce((arr, [key, item]) => {
                const preparedEquipment = item.equipment.map((item) => ({...item, label: item.name, value: item.id}));
                const formatedAsset = assets.find((item) => +item.value === +key);

                return [...arr, {...item, label: formatedAsset.label, equipment: preparedEquipment, isAll: !preparedEquipment.length}];
            }, [])
            .sort((itemA, itemB) => itemA.sort_asset - itemB.sort_asset);

        setSelectedAssets(selectedAssets);
    }, [isRouteLoading, isAssetsLoading]);

    useEffect(() => {
        if (!isAssetsSuccess) {
            return;
        }

        setAvailableAssetsTree(assets);
    }, [isAssetsSuccess]);

    useEffect(() => {
        if (!isAssetsSuccess) {
            return;
        }

        const availableAssetsLocal = [...assets];

        selectedAssets.forEach((item) => {
            toggleAssetsTreeChildren(availableAssetsLocal, item.value);
        });

        setAvailableAssetsTree(availableAssetsLocal);
    }, [selectedAssets]);

    const toggleAssetsTreeChildren = (availableAssets, disableId) => {
        availableAssets.forEach((item) => {
            if (item.parent_id === disableId) {
                item.disabled = true;
                toggleAssetsTreeChildren(availableAssets, item.value);
            }
        });
    };

    useEffect(() => {
        if (!isRouteSuccess) {
            return;
        }

        setName(routeData.name);
        setDescription(routeData.description);
    }, [isRouteSuccess]);

    const onAssetMove = (from, to) => {
        const assetLocal = [...selectedAssets];

        const moved = assetLocal.splice(from, 1);
        assetLocal.splice(to, 0, ...moved);

        setSelectedAssets(assetLocal);
    };

    const {listItemConfig, listConfig} = useDraggableList({isSorting, move: onAssetMove});

    const loader = (isRouteLoading && routeFetchStatus !== "idle") || isAssetsLoading;

    const removeError = (key) => {
        if (!formErrors[key]) {
            return;
        }

        const formErrorsLocal = {...formErrors};

        const formErrorsUpdated = _omit(formErrorsLocal, key);

        setFormErrors(formErrorsUpdated);
    };

    const handleSubmit = () => {
        return routeId ? onUpdate() : onCreate();
    };
    const prepareSelectedAssetsForSubmit = (selectedAssets) => selectedAssets.map((item) => (item.isAll ? {...item, equipment: []} : item));

    const onCreate = () => {
        if (!validate()) {
            return false;
        }

        mutateRouteCreate({
            description,
            name,
            assets: prepareSelectedAssetsForSubmit(selectedAssets),
        }).then(() => {
            Toast.success("The route has been added.");
            history.push("/routes");
        });
    };

    const onUpdate = () => {
        if (!validate()) {
            return false;
        }

        mutateRouteUpdate({
            description,
            name,
            assets: prepareSelectedAssetsForSubmit(selectedAssets),
        }).then(() => {
            Toast.success("The route has been updated.");
        });
    };

    const validate = () => {
        const formErrors = {};

        if (!name) {
            _setWith(formErrors, "name", "This field is required.", Object);
        }

        if (selectedAssets.length < 1) {
            _setWith(formErrors, "assets", "This field is required.", Object);
        }

        selectedAssets.forEach((item) => {
            if (!item?.equipment?.length && !item?.isAll) {
                formErrors[item.value] = "This field is required.";
            }
        });

        const isErrorValidation = !Object.keys(formErrors).length;
        setFormErrors(formErrors);

        return isErrorValidation;
    };

    const removeNestedItem = (availableAssets, options, disableId) => {
        let removedItem = null;

        availableAssets.forEach((item) => {
            const selectedValueKey = _findIndex(options, {value: item.value});

            if (item.parent_id === disableId) {
                if (selectedValueKey > -1) {
                    const removedItemLocal = options.splice(selectedValueKey, 1);
                    removedItem = {equipment: removedItemLocal[0].equipment, disableId};
                    return;
                }
                const removedItemLocal = removeNestedItem(availableAssets, options, item.value);

                if (removedItemLocal && removedItemLocal.disableId === item.value && disableId) {
                    removedItem = {equipment: removedItemLocal.equipment, disableId};
                }
            }
        });

        return removedItem;
    };

    const onSelectChange = (value) => {
        value.forEach((item) => {
            const removedItem = removeNestedItem(availableAssetsTree, value, item.value);

            if (item.value !== removedItem?.disableId) {
                return;
            }

            item.equipment = removedItem.equipment;
        });

        removeError("assets");
        setSelectedAssets(value);
    };

    const onEquipmentChange = (index, {equipments, isAll}) => {
        const localAssets = [...selectedAssets];

        localAssets[index].equipment = equipments;
        localAssets[index].isAll = isAll;
        removeError(index);
        setSelectedAssets(localAssets);
    };

    const onEquipmentDelete = (assetIndex, equipmentIndex) => {
        const localAssets = [...selectedAssets];

        localAssets[assetIndex].equipment.splice(equipmentIndex, 1);

        setSelectedAssets(localAssets);
    };

    const onEquipmentMove = (assetIndex, from, to) => {
        const assetLocal = [...selectedAssets];

        const moved = assetLocal[assetIndex].equipment.splice(from, 1);
        assetLocal[assetIndex].equipment.splice(to, 0, ...moved);

        setSelectedAssets(assetLocal);
    };

    return (
        <div className="">
            <HeaderSimple breadcrumbs={breadcrumbs} />
            <div className="subheader">
                <div className="subheader-title">{title}</div>
                <div className="subheader-controls d-flex" />
            </div>
            {loader ? (
                <div className="loader-fullscreen">
                    <Loader />
                </div>
            ) : (
                <div>
                    <div className="block">
                        <div className="block-body">
                            <div className={"row mb-2"}>
                                <div className={"col-md-12"}>
                                    <InputFormGroup
                                        label={"Name"}
                                        name={"name"}
                                        value={name}
                                        errorMsg={_get(formErrors, "name")}
                                        disabled={isUpdating || isCreating}
                                        isRequired={true}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className={"row mb-2"}>
                                <div className={"col-md-12"}>
                                    <InputFormGroup
                                        label={"Description"}
                                        name={"description"}
                                        value={description}
                                        errorMsg={_get(formErrors, "description")}
                                        disabled={isUpdating || isCreating}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className={"row mb-2"}>
                                <div className={"col-md-12"}>
                                    <div className="form-group">
                                        <label className="text-right form-label">
                                            Asset Tree Branch:<span className="color-danger">*</span>
                                        </label>
                                        <div className={"form-group"}>
                                            <Select
                                                className={`form-control-select ${formErrors.assets ? "is-invalid" : ""}`}
                                                isMulti
                                                placehoder={false}
                                                isSearchable={false}
                                                isClearable={false}
                                                hideSelectedOptions={false}
                                                options={availableAssetsTree}
                                                value={selectedAssets}
                                                onChange={onSelectChange}
                                                isOptionDisabled={(option) => option.disabled === true}
                                            />
                                            <ValidationError message={formErrors.assets} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!!selectedAssets.length && (
                                <div className="mb-3 d-flex justify-content-end">
                                    {isSorting ? (
                                        <button
                                            onClick={() => setIsSorting((prev) => !prev)}
                                            className="btn btn-success btn-sm btn-elevate"
                                        >
                                            <i className="fa fa-align-left" />
                                            Apply
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() => setIsSorting((prev) => !prev)}
                                            className="btn btn-warning btn-sm btn-elevate"
                                        >
                                            <i className="fa fa-align-left" />
                                            Sorting
                                        </button>
                                    )}
                                </div>
                            )}
                            <DndProvider backend={HTML5Backend}>
                                <DraggableList {...listConfig}>
                                    {selectedAssets.map((item, index) => (
                                        <DraggableItem
                                            key={item.value}
                                            elementIndex={index}
                                            {...listItemConfig}
                                        >
                                            <AssetCollapseItem
                                                items={_get(item, "equipment", [])}
                                                title={item.label}
                                                assetId={item.value}
                                                isAllSelected={item.isAll}
                                                isSorting={isSorting}
                                                onEquipmentChange={(v) => onEquipmentChange(index, v)}
                                                onEquipmentDelete={(equipmentIndex) => onEquipmentDelete(index, equipmentIndex)}
                                                onEquipmentDrop={(from, to) => onEquipmentMove(index, from, to)}
                                                errorMessage={formErrors[item.value]}
                                            />
                                        </DraggableItem>
                                    ))}
                                </DraggableList>
                            </DndProvider>
                        </div>
                    </div>
                    <div className="text-right">
                        <Link
                            to="/routes"
                            className="btn btn-sm btn-secondary"
                        >
                            Cancel
                        </Link>
                        <Button
                            color="primary"
                            size="sm"
                            className="ml-2"
                            disabled={isUpdating || isCreating}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

const HeaderRight = () => {
    const history = useHistory();

    return (
        <div className="header-rules">
            <div className="filter-item ml-2">
                <SearchInput
                    history={history}
                    disabled={false}
                    placeholder="Global Search"
                    additionalClasses="form-control-sm"
                    handleSearch="global"
                />
            </div>
        </div>
    );
};

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

Form.propTypes = {
    title: PropTypes.string,
    auth: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
};

export default withGlobalStore(Form);
