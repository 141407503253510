import { useMutation, useQueryClient } from "@tanstack/react-query";
import EquipmentTypeApi from "../../../api/equipmentType";
import { EQUIPMENT_API } from "./constants";

const useAddEquipmentType = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newType) => EquipmentTypeApi.create({name: newType}),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: [EQUIPMENT_API.GET_EQUIPMENT_TYPES]});
        }
    });
};

export default useAddEquipmentType;
