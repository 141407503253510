import React, {useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {useChartActions, useSelectChartWaterfallSort} from "../../store/hooks/useStoreContext";

export const WATERFALL_ORDER_VARIANTS = {
    DESC: "desc",
    ASC: "asc",
};

const labels = {
    [WATERFALL_ORDER_VARIANTS.DESC]: "Oldest to newest",
    [WATERFALL_ORDER_VARIANTS.ASC]: "Newest to oldest",
};

const items = [
    {value: WATERFALL_ORDER_VARIANTS.DESC, label: "Oldest to newest"},
    {value: WATERFALL_ORDER_VARIANTS.ASC, label: "Newest to oldest"},
];

export const WaterfallOrderDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const waterfallSort = useSelectChartWaterfallSort();
    const {setWaterfallSort} = useChartActions();

    return (
        <div className="btn-group-sm dropdown">
            <Dropdown
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
            >
                <DropdownToggle
                    tag="span"
                    caret
                    className="frequency-types-link"
                >
                    Order: {labels[waterfallSort]}
                </DropdownToggle>
                <DropdownMenu>
                    {items.map((item) => (
                        <DropdownItem
                            key={item.value}
                            onClick={() => setWaterfallSort(item.value)}
                        >
                            {item.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};
