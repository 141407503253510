import PropTypes from "prop-types";
import React from "react";
import {get as _get} from "lodash";
import Helper from "../../../helpers/helper";
import {withGlobalStore} from "../../../stores/GlobalStore";

const Row = ({item, query, user}) => {

    const alertCondition = _get(item, "selfAlertCondition") || {};
    const installationPoint = _get(item, "installationPoint") || {};
    const equipment = _get(installationPoint, "equipment") || {};
    const alertLevel = _get(alertCondition, "alertLevel") || {};
    let sensorHex = parseInt(_get(installationPoint, "sensor_id")).toString(16).toUpperCase();

    return (
        <tr>
            <td><div className="badge badge-pill" style={{ background: alertLevel.color, color: "#fff" }}>{alertLevel.name}</div></td>
            <td>{_get(alertCondition, "title", "---")}</td>
            <td>{Helper.getAxisName(+alertCondition.axis_id).toUpperCase()}</td>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(_get(equipment, "name", "---"), query)}}/>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(_get(installationPoint, "name", "---"), query)}}/>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(sensorHex, query)}}/>
            <td>{_get(item, "action", "---")}</td>
            <td>{Helper.dateToUserFormat(_get(item, "created_at", "---"), user)}</td>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(_get(item, "user_name", "---"), query)}}/>
            <td><Data data={_get(item, "data")} action={_get(item, "action")} /></td>
            <td/>
        </tr>
    );
};

Row.propTypes = {
    item: PropTypes.object,
    query: PropTypes.string,
    auth: PropTypes.object,
    user: PropTypes.object
};

const Data = ({data, action}) => {
    const fields = {
        "value": "Value",
        "condition": "Condition",
        "ignore_until": "Ignore Until",
        "fft_band_frequency_type": "Frequency Type",
        "fft_band_from": "From",
        "fft_band_to": "To",
        "require_readings": "Required Readings",
        "email": "Emails Sent"
    };

    return (
        <>
            {action === "removed" &&
                <>
                    {Object.keys(fields).map((field, index) =>
                        <>
                            {_get(data, field) !== undefined &&
                                <div key={index}><b>{fields[field] + ": "}</b>{_get(data, field+".old.value")}</div>
                            }
                        </>
                    )}
                    <div><b>{"Title: "}</b>{_get(data, "title.old.value")}</div>
                </>
            }
            {action === "created" &&
                <>
                    {Object.keys(fields).map((field, index) =>
                        <>
                            {_get(data, field) !== undefined &&
                                <div key={index}><b>{fields[field] + ": "}</b>{_get(data, field+".new.value")}</div>
                            }
                        </>
                    )}
                </>
            }
            {action === "updated" &&
                <>
                    {Object.keys(fields).map((field, index) =>
                        <>
                            {_get(data, field) !== undefined &&
                                <div key={index}>
                                    <b>{fields[field] + ": "}</b>
                                    {_get(data, field + ".old.value") !== undefined &&
                                        <span>{"Old: " + _get(data, field+".old.value") + " "}</span>
                                    }
                                    {_get(data, field + ".new.value") !== undefined &&
                                        <span>{"New: " + _get(data, field+".new.value") + " "}</span>
                                    }
                                </div>
                            }
                        </>
                    )}
                </>
            }
        </>
    );
};

Data.propTypes = {
    data: PropTypes.object,
    action: PropTypes.string,
};

export default withGlobalStore(Row);