import Api from "../../../api/api";
import {useMutation} from "@tanstack/react-query";

const getNearestFft = ({installationPointId, timestamp, isImpactVue}) =>
    Api.get("reading/nearest-fft-timestamp", {
        query: {
            installationPointId,
            timestamp,
            isImpactVue: isImpactVue ? "1" : "0",
        },
    });

export const useNearestFftMutation = ({onSuccess} = {}) => {
    return useMutation({
        mutationFn: getNearestFft,
        onSuccess: (res, variables) => onSuccess(res, variables),
    });
};
