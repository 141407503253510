import React from "react";
import {withGlobalStore} from "../../../stores/GlobalStore";
import FftChartWaterfall from "./twf/components/chartBody/components/FftChartWaterfall";
import {useDrawChartWaterfallStackEffect} from "./twf/hooks/useDrawWaterfallStackChartEffect";
import {useChartStateStoreContext} from "./twf/store/hooks/useStoreContext";

const ChartsFFTWaterfallList = () => {
    const options = useDrawChartWaterfallStackEffect();

    const isGridView = useChartStateStoreContext((state) => state.settings.isGridView);

    return (
        <div className={isGridView ? "stacked-waterfall-grid" : "row"}>
            <>
                {options.map((optionObject, index) => {
                    return (
                        <FftChartWaterfall
                            key={index}
                            index={index}
                            options={optionObject}
                        />
                    );
                })}
            </>
        </div>
    );
};

export default withGlobalStore(ChartsFFTWaterfallList);
