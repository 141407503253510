import {useQuery} from "@tanstack/react-query";
import {USER_API} from "./constants";
import ApiUser from "../../../api/user";

export const useSelfUserQuery = (locationId) => {
    const {data, ...rest} = useQuery([USER_API.SELF_USER, locationId], () =>
        ApiUser.getSelfUsers({
            query: {
                excludeWCDUsers: 1,
                filter: { status: 1, location_id: locationId },
            }
        })
    );

    return {users: data?.users, ...rest};
};
