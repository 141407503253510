import React from "react";
import PropTypes from "prop-types";
import InstallationPointCustomTypeFormContent
    from "../../../installationPointCustomType/forms/InstallationPointCustomTypeFormContent";
import {withInstallationPointCustomTypeStore} from "../../../installationPointCustomType/stores/InstallationPointCustomTypeStore";

const InstallationPointCustomTypeField = ({
    value,
    onChange,
    installationPointCustomTypes,
    createInstallationPointCustomType
}) => {

    return (
        <InstallationPointCustomTypeFormContent
            name={"installation_point_custom_type_id"}
            value={value}
            onChange={onChange}
            types={installationPointCustomTypes}
            onCreate={createInstallationPointCustomType}
        />
    );
};

InstallationPointCustomTypeField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    errorMsg: PropTypes.string,

    // From InstallationPointCustomTypeStore
    installationPointCustomTypes: PropTypes.array,
    createInstallationPointCustomType: PropTypes.func
};

export default withInstallationPointCustomTypeStore(InstallationPointCustomTypeField);