import React from "react";
import PropTypes from "prop-types";
import {
    includes as _includes
} from "lodash";
import SelectWrapper from "../../../helpers/select-wrapper";

const LinesOfResolutionSelect = ({value, values, name, onChange, disabled = false, errorMsg, maxAcquisitionTime, curFmax, isHFDvue = false, menuPortalTarget = document.body}) =>  {
    let lorValues = values;

    if (lorValues.length && !_includes(lorValues, +value) && !disabled) {
        onChange({
            target: {
                name: name || "lines_of_resolution",
                value: lorValues[0]
            }
        });
    }

    return (
        <SelectWrapper
            className={ (errorMsg ? "is-invalid" : "") }
            style={{
                width:"100%",
                display:"block",
            }}
            name={name || "lines_of_resolution"}
            onChange={onChange}
            disabled={disabled}
            value={value}
            menuPosition={"fixed"}
            menuPortalTarget={menuPortalTarget}
            placeholder={"Lines of resolution"}
        >
            <option value="" disabled>Lines of resolution</option>
            {lorValues.map((LoRValue) => (
                <option disabled={isHFDvue && ((maxAcquisitionTime * curFmax) < LoRValue)} key={`fmax-value-${LoRValue}`} value={LoRValue}>{LoRValue}</option>
            ))}
        </SelectWrapper>
    );
};


LinesOfResolutionSelect.propTypes = {
    isInput: PropTypes.bool,
    isHFDvue: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    versionType: PropTypes.number,
    curFmax: PropTypes.number,
    maxAcquisitionTime: PropTypes.number,
    sensorParameters: PropTypes.object,
    errorMsg: PropTypes.string,
    needUpdateOnNotValidValue: PropTypes.bool,
    maxValue: PropTypes.number,
    isRequestOnDemand: PropTypes.bool,
    values: PropTypes.array,
    menuPortalTarget: PropTypes.any
};

export default LinesOfResolutionSelect;