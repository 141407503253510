import React from "react";
import { Button } from "reactstrap";
import { useChartStateStoreContext } from "../../store/hooks/useStoreContext";

function TrendButton() {
    const show3dTrend = useChartStateStoreContext((state) => state.settings.show3dTrend);
    const {toggle3dTrend} = useChartStateStoreContext((state) => state.actions);

    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    return (
        <Button
            className="btn-fa-icon btn-sm btn-default mr-2"
            onClick={toggle3dTrend}
            disabled={!!isLoading}
            title={show3dTrend ? "Hide Trend" : "Show Trend"}
        >
            <i className={show3dTrend ? "far fa-window-maximize" : "fas fa-columns"} />
        </Button>
    );
}

export default TrendButton;
