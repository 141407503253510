import React from "react";
import PropTypes from "prop-types";
import InstallationPointCustomTypeStore from "../../installationPointCustomType/stores/InstallationPointCustomTypeStore";
import BatchInstallationPointData from "../dataHendlers/BatchInstallationPointData";
import {get as _get} from "lodash";
import EditInstallationPointsModalContent from "./EditInstallationPointsModalContent";
import Toast from "../../../pages/shared/toast";
import {useChartSelectedEquipmentStoreActions} from "../../../stores/zustand/ChartSelectedEquipmentStore";

const EditInstallationPointsModal = ({equipment, afterUpdate, onClose, isRouteCollector}) => {
    const {deleteSelectedInstallationPoint} = useChartSelectedEquipmentStoreActions();
    const afterUpdateDone = () => {
        afterUpdate().then(() => {
            onClose();
            Toast.success("The installation points have been updated.");
        });
    };

    return (
        <InstallationPointCustomTypeStore>
            <BatchInstallationPointData
                equipmentId={equipment.id}
                points={_get(equipment, "installationPoints", [])}
                afterUpdate={afterUpdateDone}
            >
                {(props) => (
                    <EditInstallationPointsModalContent
                        {...props}
                        equipmentId={equipment.id}
                        equipmentName={equipment.name}
                        onClose={onClose}
                        afterUpdate={afterUpdate}
                        deleteSelectedInstallationPoint={deleteSelectedInstallationPoint}
                        isRouteCollector={isRouteCollector}
                    />
                )}
            </BatchInstallationPointData>
        </InstallationPointCustomTypeStore>
    );
};

EditInstallationPointsModal.propTypes = {
    equipment: PropTypes.object,
    afterUpdate: PropTypes.func,
    onClose: PropTypes.func,
    isRouteCollector: PropTypes.bool
};

export default EditInstallationPointsModal;
