import React, {Component} from "react";
import {Modal} from "../../../shared";
import PropTypes from "prop-types";
import {UncontrolledTooltip} from "reactstrap";
import {
    get as _get,
    includes as _includes,
    remove as _remove,
} from "lodash";

class ConfirmAdd extends Component
{
    constructor(props) {
        super(props);

        const installationPoints = props.equipment.installationPoints.filter(installationPoint =>
            _get(installationPoint, "bearings", []).filter(b => +b.id === +props.bearing.id).length === 0
        );

        this.state = {
            selected: [props.installationPointId],
            installationPoints: installationPoints,
            isSelectedAll: false,
            plotLinesCount: 10,

        };
        this.onChange = this.onChange.bind(this);
        this.onSelectedAll = this.onSelectedAll.bind(this);
    }

    onChange(e) {
        const {selected} = this.state;
        const {value, name} = e.target;

        if (name === "plotLinesCount") {
            this.setState({plotLinesCount: value});
        } else {
            if (e.target.checked) {
                selected.push(+value);
            } else {
                _remove(selected, el => {
                    return el === +value;
                });
            }

            this.setState({selected});
        }
    }

    onSelectedAll() {
        const {isSelectedAll, installationPoints} = this.state;

        if (isSelectedAll) {
            this.setState({selected: [], isSelectedAll: false});
        } else {
            const selected = [];
            installationPoints.map(installationPoint => {selected.push(installationPoint.id);});
            this.setState({selected, isSelectedAll: true});
        }
    }

    render() {
        const {plotLinesCount, selected, isSelectedAll, installationPoints} = this.state;
        const {bearing, equipment, inProgress} = this.props;

        return (
            <Modal
                size={"lg"}
                title={"Add Bearing - " + bearing.name}
                className={"custom-modal"}
                inProgress={inProgress}
                showModal={true}
                onClose={this.props.onCancel}
                onSubmit={() => this.props.onConfirm(selected, plotLinesCount)}
                disableSubmit={selected.length === 0}
                submitTitle="Add"
            >
                <div className="row">
                    <div className="form-group col-12">
                        <label>Plot Lines:</label>
                        <input
                            className={"form-control"}
                            type="text"
                            name="plotLinesCount"
                            value={plotLinesCount}
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <br/>
                <SetFor
                    type="add"
                    installationPoints={installationPoints}
                    equipment={equipment}
                    selected={selected}
                    isSelectedAll={isSelectedAll}
                    onChange={this.onChange}
                    onSelectedAll={this.onSelectedAll}
                />
            </Modal>
        );
    }
}

ConfirmAdd.propTypes = {
    bearing: PropTypes.object,
    equipment: PropTypes.object,
    installationPointId: PropTypes.number,
    inProgress: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
};

class ConfirmDelete extends Component
{
    constructor(props) {
        super(props);

        const installationPoints = props.equipment.installationPoints.filter(installationPoint =>
            _get(installationPoint, "bearings", []).filter(b => +b.id === +props.bearing.id).length !== 0
        );

        this.state = {
            selected: [props.installationPointId],
            installationPoints: installationPoints,
            isSelectedAll: false,

        };
        this.onChange = this.onChange.bind(this);
        this.onSelectedAll = this.onSelectedAll.bind(this);
    }

    onChange(e) {
        const {selected} = this.state;
        const {value} = e.target;

        if (e.target.checked) {
            selected.push(+value);
        } else {
            _remove(selected, el => {
                return el === +value;
            });
        }

        this.setState({selected});
    }

    onSelectedAll() {
        const {isSelectedAll, installationPoints} = this.state;

        if (isSelectedAll) {
            this.setState({selected: [], isSelectedAll: false});
        } else {
            const selected = [];
            installationPoints.map(installationPoint => {selected.push(installationPoint.id);});
            this.setState({selected, isSelectedAll: true});
        }
    }

    render() {
        const {selected, isSelectedAll, installationPoints} = this.state;
        const {bearing, equipment, inProgress} = this.props;

        return (
            <Modal
                size={"lg"}
                title={"Clear Bearing - " + bearing.name}
                className={"custom-modal"}
                inProgress={inProgress}
                showModal={true}
                onClose={this.props.onCancel}
                onSubmit={() => this.props.onConfirm(selected)}
                disableSubmit={selected.length === 0}
                submitTitle="Clear"
            >
                <SetFor
                    type="delete"
                    installationPoints={installationPoints}
                    equipment={equipment}
                    selected={selected}
                    isSelectedAll={isSelectedAll}
                    onChange={this.onChange}
                    onSelectedAll={this.onSelectedAll}
                />
            </Modal>
        );
    }
}

ConfirmDelete.propTypes = {
    bearing: PropTypes.object,
    equipment: PropTypes.object,
    installationPointId: PropTypes.number,
    inProgress: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
};

const SetFor = ({type, installationPoints, equipment, selected, isSelectedAll, onChange, onSelectedAll}) => {

    const availableInstallationPointIds = [];
    installationPoints.map(installationPoint => {availableInstallationPointIds.push(installationPoint.id);});

    return (
        <div className="form-group row">
            <div className="col-12">
                <div className="form-checkbox-list">
                    <label
                        className="form-checkbox white-checkbox"
                        title={isSelectedAll ? "Deselect All" : "Select All"}
                    >
                        <input
                            type="checkbox"
                            checked={isSelectedAll}
                            onChange={onSelectedAll}
                        />
                        <p><b>Sensors</b></p>
                        <span style={{borderColor: "#4b4b4b", ...(isSelectedAll ? {backgroundColor: "#4b4b4b"} : {})}}/>
                    </label>
                    {equipment.installationPoints.map(installationPoint => {

                        if(installationPoint.point_type !== "sensor"){
                            return false;
                        }

                        if (
                            availableInstallationPointIds.indexOf(installationPoint.id) === -1 ||
                            (type === "add" && installationPoint.bearings && installationPoint.bearings.length > 2)
                        ) {
                            return (
                                <React.Fragment>
                                    <label
                                        key={installationPoint.id}
                                        id={"bearingInstPoint_" + installationPoint.id}
                                        className="form-checkbox white-checkbox disabled font-weight-normal"
                                    >
                                        <input
                                            type="checkbox"
                                            checked={false}
                                            disabled={true}
                                        />
                                        <p>{installationPoint.name}</p>
                                        <span style={{backgroundColor: "#d9d9d9", borderColor: "#d9d9d9"}}/>
                                    </label>
                                    {(type === "add" && installationPoint.bearings) &&
                                        <UncontrolledTooltip
                                            placement="top-start"
                                            hideArrow={true}
                                            target={"#bearingInstPoint_" + installationPoint.id}
                                        >{availableInstallationPointIds.indexOf(installationPoint.id) === -1 ? "Sensor already has this bearing" : "Sensor already has 3 bearings"}</UncontrolledTooltip>
                                    }
                                    {(type !== "add" && availableInstallationPointIds.indexOf(installationPoint.id) === -1) &&
                                        <UncontrolledTooltip
                                            placement="top-start"
                                            hideArrow={true}
                                            target={"#bearingInstPoint_" + installationPoint.id}
                                        >This sensor does not have this bearing</UncontrolledTooltip>
                                    }
                                </React.Fragment>
                            );
                        }

                        const checked = _includes(selected, installationPoint.id);
                        return (
                            <label key={installationPoint.id} className="form-checkbox white-checkbox font-weight-normal">
                                <input
                                    type="checkbox"
                                    onChange={onChange}
                                    name={"selected"}
                                    checked={checked}
                                    value={installationPoint.id}
                                />
                                <p>{installationPoint.name}</p>
                                {checked ?
                                    <span style={{backgroundColor: _get(installationPoint, "color", "#000"), borderColor: _get(installationPoint, "color", "#000")}}/>
                                    :
                                    <span style={{borderColor: _get(installationPoint, "color", "#000")}}/>
                                }
                            </label>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

SetFor.propTypes = {
    type: PropTypes.string,
    installationPoints: PropTypes.array,
    equipment: PropTypes.object,
    selected: PropTypes.array,
    isSelectedAll: PropTypes.bool,
    onChange: PropTypes.func,
    onSelectedAll: PropTypes.func
};

export {ConfirmAdd, ConfirmDelete};