import {useMemo} from "react";
import {AXES_COLORS, AXES_COLORS_DARK, CHART_VIEW_TYPE} from "../../constants/constants";
import {get as _get} from "lodash";
import {useGetFFTReadings, useGetPointReadingParamsByDateTime} from "../api/fftReadings/ReadingsQuery";
import useUserProfile from "../api/Global/useUserProfile";
import {usePointData} from "../../pages/chart/highcharts/hooks/usePointsData";
import useAxisLabelQuery from "../api/axisLabel/useAxisLabelQuery";
import {useIsDarkTheme} from "../helpers/theme";
import Helper from "../../helpers/helper";
import {useIsAutoCorrelation} from "../../pages/chart/highcharts/hooks/useChartModes";
import {useIsCircular} from "../../pages/chart/highcharts/hooks/useChartViewTypes";
import {useSeriesList} from "../helpers/useSeriesList";
import {getSpeedSettingsFft} from "../../helpers/reading";
import {useEquipmentByParams} from "../api/equipment/useEquipmentQuery";
import {useInterpolationSyncStoreByPoint} from "../../pages/chart/highcharts/store/sync/InterpolationSuncStore";
import {useHighPassFilterSyncStoreByPoint} from "../../pages/chart/highcharts/store/sync/HighPassFilterSyncStore";
import {useSelectUnixDateRangeTimestampSync} from "../../pages/chart/highcharts/store/sync/timestampsSyncStore";

export const useFFTChartSeriesBuilder = ({
    chartType,
    installationPointId,
    currentDate = {
        date: "last",
        time: "last",
    },
    axis = [1],
    chartViewType = CHART_VIEW_TYPE.LINEAR,
    overlayPoints = [],
    chartMode = null,
    chartModeList = null,
    isImpactVue = false,
    isEnabled = true,
    pointColor = null,
    color = null,
    overlayReadingId = null,
}) => {
    if (+axis[0] === 0) {
        axis = [1, 2, 3];
    }
    const pointData = usePointData(installationPointId, overlayPoints);
    const seriesList = useSeriesList(chartType);
    const isInterpolation = useInterpolationSyncStoreByPoint(installationPointId);
    const highpassFilter = useHighPassFilterSyncStoreByPoint(installationPointId, pointData);
    const {data: user} = useUserProfile();
    const {axisLabelsChart: axisLabels} = useAxisLabelQuery(pointData?.equipment_id);

    const {unixFrom, unixTo} = useSelectUnixDateRangeTimestampSync(installationPointId, isImpactVue);

    const {params: readingsParams, isRefetching} = useGetPointReadingParamsByDateTime(
        pointData?.equipment_id,
        pointData?.id,
        axis,
        currentDate,
        isImpactVue,
        highpassFilter,
        isInterpolation,
        unixFrom,
        unixTo,
        overlayReadingId
    );

    const {data: readings, isSuccess: readingsLoaded, ...rest} = useGetFFTReadings(chartType, readingsParams, isEnabled);
    const isCircular = useIsCircular(chartViewType);
    const isAutoCorrelation = useIsAutoCorrelation(chartMode);
    const isDarkTheme = useIsDarkTheme();
    const {equipmentItem} = useEquipmentByParams();
    const maxRpm = useMemo(() => _get(equipmentItem, ["tachometer", "nodeTriggerSetting", "max_rpm"], null), [equipmentItem]);
    const generateSeries = () => {
        if (!readingsLoaded) {
            return [];
        }
        let series = [];
        readings.forEach((reading) => {
            const axisLabel = _get(axisLabels, [reading.axisID, installationPointId]) || seriesList.list[reading.axisID].axisName + " - Axis";
            const seriesName = `${pointData.name || "---"} - ${axisLabel}`;

            const {currentSpeed, currentRatio, currentSpeedFrom, currentRpmTimestamp} = getSpeedSettingsFft({
                pointData,
                reading,
                equipment: equipmentItem,
                installationPointId,
            });
            let idKey = `${pointData.id}_${reading.axisID}`;

            const cycleTime = 60 / (currentSpeed || 1);
            //TODO use factory
            let data = reading.data;
            if (chartMode && chartModeList) {
                if (chartMode === chartModeList.TWF) {
                    idKey += "_" + chartModeList.TWF;
                    data = reading.data;
                } else if (chartMode === chartModeList.AUTOCORRELATION) {
                    idKey += "_" + chartModeList.AUTOCORRELATION;
                    data = reading.autoCorrelation;
                } else if (chartMode === chartModeList.FFT) {
                    idKey += "_" + chartModeList.FFT;
                    data = reading.data;
                } else {
                    data = [];
                }
            }

            let cycleIteration = 0;
            if (isCircular) {
                idKey += CHART_VIEW_TYPE.CIRCULAR;
                data = data.map((point) => {
                    if (point[0] >= cycleTime + cycleIteration * cycleTime) {
                        cycleIteration++;
                    }
                    const x = (point[0] - cycleIteration * cycleTime) / cycleTime;
                    return [x * 360, point[1]];
                });
            } else {
                idKey += CHART_VIEW_TYPE.LINEAR;
            }

            series.push({
                id: idKey,
                name: seriesName,
                readingDate: Helper.dateToUserFormat(reading.timestamp, user),
                color: color || (isDarkTheme === "true" ? AXES_COLORS_DARK[reading.axisID] : AXES_COLORS[reading.axisID]),
                pointColor: pointColor || pointData.color,
                data,
                connectEnds: false,
                isOverlay: !!overlayPoints.length,
                cycleIteration: cycleIteration || 0,
                units: seriesList.units,
                isAutoCorrelation: isAutoCorrelation,
                isCircular: isCircular,
                precision: seriesList.precision,
                overall: reading.overall || 0,
                pkpk: reading.pkpk || 0,
                chartType: chartType,
                cycleTime: cycleTime,
                speed: currentSpeed || 0,
                ratio: currentRatio || 1,
                rpmFrom: currentSpeedFrom,
                rpmTimestamp: Helper.dateToUserFormat(currentRpmTimestamp, user),
                maxRpm: maxRpm,
                dataGrouping: {
                    approximation: "high",
                    forced: true,
                },
                enableMouseTracking: true,
            });
        });

        return series;
    };

    const series = useMemo(
        () => generateSeries(),
        [axis, readingsLoaded, chartMode, chartViewType, seriesList, currentDate, isInterpolation, highpassFilter, maxRpm]
    );
    return {series, readingsLoaded, ...rest, isTimestampsRefetching: isRefetching};
};
