import Highcharts from "highcharts";

export const resetZoomForGroup = (zoomGroup, axis) => {
    Highcharts.charts.forEach((item) => {
        if (!item) {
            return;
        }

        if (item?.userOptions?.chart?.syncZoomGroup !== zoomGroup) {
            return;
        }
        if (axis === "x" || axis === "xy") {
            item.xAxis[0].setExtremes(item.xAxis[0].dataMin, item.xAxis[0].dataMax, undefined, false, {trigger: "resetZoom"});
        }

        if (axis === "y" || axis === "xy") {
            item.yAxis[0].setExtremes(item.yAxis[0].dataMin, item.yAxis[0].dataMax, undefined, false, {trigger: "resetZoom"});
        }

        Highcharts.syncZoomOptions[zoomGroup] = {};
    });
};
