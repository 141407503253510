import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {persist} from "zustand/middleware";
import {shallow} from "zustand/shallow";
import {cloneDeep as _cloneDeep,
    filter as _filter,
    includes as _includes
} from "lodash";

const store = (set, get) => ({
    equipmentSelected: {},
    actions: {
        deleteSelectedInstallationPoint: (pointID, equipmentId) => {
            const points = get().equipmentSelected[equipmentId] || [];
            set((state) => {
                state.equipmentSelected[equipmentId] = points.filter((item) => item !== pointID);
            });
        },
        setSelectedInstallationPoints: (pointsIDs, equipmentId) => {
            set((state) => {
                state.equipmentSelected[equipmentId] = pointsIDs;
            });
        },
        toggleSelectedInstallationPoint: (pointID, equipmentId) => {
            const points = get().equipmentSelected[equipmentId] || [];
            if(points.includes(pointID)){
                set((state) => {
                    state.equipmentSelected[equipmentId] = points.filter((id) => id !== pointID);
                });
            }else{
                set((state) => {
                    state.equipmentSelected[equipmentId] = [...points, pointID];
                });
            }
        },
        checkSelectedInstallationPoint: (equipment) => {
            let currentSelectedId = _cloneDeep(get().equipmentSelected[equipment.id]);
            let existingInstallationPoints = equipment.installationPoints;

            const filteredItems = _filter(existingInstallationPoints, (item) => {
                return _includes(currentSelectedId, item.id);
            });

            set((state) => {
                state.equipmentSelected[equipment.id] = filteredItems.map(item => item.id);
            });
        }
    },
});

export const chartSelectedEquipmentStore = create(
    persist(immer(store), {
        name: "chartSelectedEquipmentStore",
        getStorage: () => localStorage,
        partialize: (state) => ({equipmentSelected: state.equipmentSelected}),
    })
);

export const useChartSelectedEquipmentStore = () => chartSelectedEquipmentStore((state) => state.equipmentSelected, shallow);
export const useSelectedByEquipment = (equipmentId) => chartSelectedEquipmentStore((state) => state.equipmentSelected[equipmentId], shallow);
export const useChartSelectedEquipmentStoreActions = () => chartSelectedEquipmentStore((state) => state.actions, shallow);
