import {useMutation, useQueryClient} from "@tanstack/react-query";
import {CHART_MARK_API} from "./constants";
import ApiChartMark from "../../../api/chartMark";
import {deleteItemFromListById} from "../../../helpers/query";

export const useChartMarksDeleteMutation = (equipmentId) => {
    const queryClient = useQueryClient();

    const deleteChartMark = (deleteChartMarkFftId) => ApiChartMark.delete(equipmentId, deleteChartMarkFftId);

    const mutation = useMutation(deleteChartMark, {
        onSuccess: (_, deleteChartMarkFftId) => {
            queryClient.setQueriesData([CHART_MARK_API.GET_CHART_MARK, +equipmentId], (prev) => deleteItemFromListById(prev, deleteChartMarkFftId));
        },
    });
    return mutation;
};
