import React, {Component} from "react";
import PropTypes from "prop-types";
import CreateDataHandler from "./create-data-handler";
import EquipmentProfileCreate from "./create";
import {
    get as _get,
} from "lodash";
import {withGlobalStore} from "../../stores/GlobalStore";



class CreateWrapper extends Component
{
    render() {
        const {match, chartTypes, history, auth} = this.props;
        const profileId = +_get(match, "params.profileId");

        return (
            <React.Fragment>
                <CreateDataHandler
                    history={history}
                    profileId={profileId}
                >
                    {(
                        loader,
                        steps,
                        currentStepIndex,
                        setCurrentStepIndex,
                        profile,
                        onChange,
                        formErrors,
                        addInstallationPoint,
                        removeInstallationPoint,
                        instPointCustomTypes,
                        fetchInstPointCustomType,
                        readingTypes,
                        alertLevels,
                        validateStep,
                        addBearing,
                        removeBearing,
                        defaultBearings,
                        fetchBearings,
                        onCreate,
                        conditions,
                        onSaveAsDraft,
                        inProgress,
                        isCreate,
                        equipmentTypes,
                        fetchEquipmentTypes,
                        validationInProgress
                    ) =>
                        <EquipmentProfileCreate
                            loader={loader}
                            steps={steps}
                            currentStepIndex={currentStepIndex}
                            setCurrentStepIndex={setCurrentStepIndex}
                            profile={profile}
                            chartTypes={chartTypes}
                            onChange={onChange}
                            formErrors={formErrors}
                            addInstallationPoint={addInstallationPoint}
                            removeInstallationPoint={removeInstallationPoint}
                            instPointCustomTypes={instPointCustomTypes}
                            fetchInstPointCustomType={fetchInstPointCustomType}
                            readingTypes={readingTypes}
                            alertLevels={alertLevels}
                            validateStep={validateStep}
                            addBearing={addBearing}
                            removeBearing={removeBearing}
                            defaultBearings={defaultBearings}
                            fetchBearings={fetchBearings}
                            onCreate={onCreate}
                            conditions={conditions}
                            onSaveAsDraft={onSaveAsDraft}
                            inProgress={inProgress}
                            isCreate={isCreate}
                            equipmentTypes={equipmentTypes}
                            fetchEquipmentTypes={fetchEquipmentTypes}
                            validationInProgress={validationInProgress}
                            auth={auth}
                            history={history}
                        />
                    }
                </CreateDataHandler>
            </React.Fragment>
        );
    }
}

CreateWrapper.propTypes = {
    match: PropTypes.object,
    chartTypes: PropTypes.object,
    history: PropTypes.object,
    auth: PropTypes.object
};

export default withGlobalStore(CreateWrapper);