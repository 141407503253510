import {useModal} from "react-modal-hook";
import DeleteReading from "../components/deleteReading";
import React from "react";
import {find as _find} from "lodash";
import {useChartStateStoreContext} from "../../../pages/chart/highcharts/twf/store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useCurrentTimestampsInfo} from "../../../pages/chart/highcharts/twf/hooks/helpers/useCurrentTimestampsInfo";
import {useDeleteFftReadingMutation} from "../../../hooks/api/fftReadings/useDeleteFftReadingMutation";
import {useIsImpactVue} from "../../../pages/chart/highcharts/hooks/useChartTypes";
import {useUpdateCurrentDate} from "../../../pages/chart/highcharts/twf/hooks/helpers/useUpdateCurrentDate";
import Toast from "../../../pages/shared/toast";

export const useDeleteReadingModal = () => {
    const {equipmentItem} = useEquipmentByParams();

    const {selectedPoint} = useChartStateStoreContext((state) => state.settings, shallow);
    const updateCurrentDate = useUpdateCurrentDate();
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const {fulfilledTimestamp, readingId} = useCurrentTimestampsInfo();

    const isImpactVue = useIsImpactVue(chartType);
    const {mutateAsync} = useDeleteFftReadingMutation(readingId, equipmentItem.id, isImpactVue);

    const handleDeleteReading = () => {
        const pointData = _find(equipmentItem.installationPoints, {id: selectedPoint}) || {};

        mutateAsync({installationPointId: pointData.id, readingId}).then(() => {
            updateCurrentDate({date: "last", time: "last"});
            hideModal();
            Toast.success("The reading has been deleted.");
        });
    };

    const [showModal, hideModal] = useModal(() => {
        return (
            <DeleteReading
                currentDate={fulfilledTimestamp}
                onReadingDelete={handleDeleteReading}
                onClose={hideModal}
                equipment={equipmentItem}
            />
        );
    }, [fulfilledTimestamp, handleDeleteReading]);

    return [showModal, hideModal];
};
