import {useCallback, useEffect} from "react";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useCurrentAlert, useCurrentAlertActions, useCurrentAlertScrolled} from "../../../stores/CurrentAlertStore";
import {get as _get, includes as _includes, find as _find} from "lodash";
import Helper from "../../../helpers/helper";
import {useChartSelectedChartTypeActions, useChartSelectedChartTypeStore, useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import {useChartSelectedEquipmentStoreActions} from "../../../stores/zustand/ChartSelectedEquipmentStore";
import {useAlarmList} from "../../../hooks/api/Alarm/UseAlarmList";
import {useHighPassFilterSyncStoreActions} from "../highcharts/store/sync/HighPassFilterSyncStore";

export const useBeforeAlertScrollEffect = () => {
    const currentAlert = useCurrentAlert();
    const isScrolled = useCurrentAlertScrolled();
    const {setCurrentAlert, setTarget} = useCurrentAlertActions();
    const chartTypes = useChartTypesStore();
    const {equipmentItem} = useEquipmentByParams();
    const selectedChartTypes = useChartSelectedChartTypeStore();
    const {setHighPassFilterByAlert} = useHighPassFilterSyncStoreActions();
    const {setSelectedInstallationPoints} = useChartSelectedEquipmentStoreActions();
    const {isSuccess: equipmentLoaded} = useEquipmentByParams();
    const {isSuccess: alarmsLoaded, activeAlarms} = useAlarmList(equipmentItem?.id);
    const {setCheckedChartType} = useChartSelectedChartTypeActions();

    const alertIdParam = _get(Helper.getHashParams(), "alertId", false);
    const getChartTypeByReading = (readingId) => {
        let chartType = false;

        (Object.keys(chartTypes) || []).map((chartIndex) => {
            if (_includes(chartTypes[chartIndex]["readingTypes"], "" + readingId)) {
                chartType = chartIndex;
            }
        });
        return chartType;
    };

    const setCurrentAlarmSettings = useCallback(() => {
        if (equipmentLoaded && alarmsLoaded) {
            const currentAlertFromList = _find(activeAlarms, (alert) => +alert.id === +alertIdParam);
            if (currentAlertFromList === undefined || isScrolled) {
                return;
            }

            const isFFT =
                _get(currentAlertFromList, "alertCondition.fft_alert_type", 0) === 1 ||
                _get(currentAlertFromList, "alertCondition.fft_alert_type", 0) === 2;

            let chartType = isFFT
                ? _get(currentAlertFromList, ["alertCondition", "fft_type"]) + ""
                : getChartTypeByReading(+_get(currentAlertFromList, ["alertCondition", "reading_type_id"]));

            let installationPoint = +_get(currentAlertFromList, "installationPointId");
            let installationPointsGroup = _get(currentAlertFromList, "installationPoints", []);

            let targetSelector = isFFT ? `placeholder-${chartType}-${installationPoint}` : `placeholder-${chartType}`;

            if (installationPointsGroup.length > 1) {
                setSelectedInstallationPoints(
                    installationPointsGroup.map((alertData) => +alertData.installation_point_id),
                    equipmentItem.id
                );
            } else if (installationPoint) {
                setSelectedInstallationPoints([installationPoint], equipmentItem.id);
            }

            if (chartType && !_includes(selectedChartTypes.checked, chartType)) {
                setCheckedChartType(chartType);
            }
            if (isFFT) {
                setHighPassFilterByAlert(currentAlertFromList);
            }
            setCurrentAlert(currentAlertFromList);
            setTarget(targetSelector);
        }
    }, [currentAlert, equipmentLoaded, alarmsLoaded, alertIdParam, isScrolled]);

    useEffect(() => {
        setCurrentAlarmSettings();
    }, [setCurrentAlarmSettings]);
};
