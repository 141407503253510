import React, {useState} from "react";
import InfiniteScroll from "react-infinite-scroller";
import PropTypes from "prop-types";
import {upperFirst as _upperFirst} from "lodash";

const isDarkTheme = localStorage.getItem("lightMode");

const MultiSelect = ({entity, data, structure, issues, current, search, facilityIdsList, updateCurrent, updateSearch, updateAll, updateData}) => {
    const [dataLength, setDataLength] = useState(20);

    if (!(structure[entity.name] || []).length) return "";

    const filteredList = structure[entity.name].filter((item) => {
        const isFacilityInList = facilityIdsList.indexOf(+item.facility_id) > -1;
        const isFacilityInSearch = !search[entity.name] || item[entity.label || "serial"].toLowerCase().includes(search[entity.name].toLowerCase());
        const isFilterFnApplied = !(entity.filter || (() => {}))(item, current);

        return isFacilityInList && isFacilityInSearch && isFilterFnApplied;
    });

    const ids = filteredList.map((item) => +item[entity.key]);

    let tempLength = 0;
    const filteredEntities = filteredList.filter(() => tempLength++ <= dataLength);

    const isAll = filteredList.every((item) => (data[entity.name] || []).indexOf(+item[entity.key]) > -1);

    const isDisabled = (issues[data.issue] || []).indexOf(entity.name.substr(0, entity.name.length - 1)) === -1;

    const list = filteredEntities.map((item) => {
        const pointVal = (data[entity.name] || []).indexOf(+item[entity.key]) !== -1;

        return (
            <div
                key={item[entity.key]}
                style={{
                    padding: 8,
                    ...(entity.isCheckable ? {cursor: "pointer"} : {}),
                    ...(+current[entity.name] === +item[entity.key] ? {background: isDarkTheme === "true" ? "#2765ac" : "#c7e1ff"} : {}),
                }}
                onClick={(ev) => (entity.updateCurrent || (() => {}))(ev, item, updateCurrent)}
            >
                {!!+data.type && (
                    <label
                        className="form-checkbox modal-checkbox"
                        title={isDisabled ? `No ${data.issue} issue` : ""}
                    >
                        <input
                            type="checkbox"
                            checked={pointVal}
                            onChange={() => (isDisabled ? {} : updateData(entity.name, +item[entity.key]))}
                        />
                        <span style={{background: pointVal ? "#31AEE3" : "#fff", ...(isDisabled ? {cursor: "not-allowed"} : {})}} />
                    </label>
                )}
                <span>
                    <b>{item[entity.label || "serial"]}</b>
                </span>
            </div>
        );
    });

    const handleUpdateSize = () => setTimeout(() => setDataLength(structure[entity.name].length > 2000 ? dataLength + 200 : dataLength + 20), 300);

    return (
        <div
            className="alert-groups-select"
            style={{...(isDisabled ? {opacity: 0.4} : {})}}
        >
            <div>
                {+data.type && filteredEntities.length ? (
                    <label
                        className="form-checkbox modal-checkbox"
                        style={{textTransform: "uppercase"}}
                    >
                        <input
                            type="checkbox"
                            checked={isAll}
                            onChange={() => (isDisabled ? {} : updateAll(entity.name, ids, isAll))}
                        />{" "}
                        <b>{entity.labels}</b>
                        <span style={{background: isAll ? "#31AEE3" : "#f0f0f0", ...(isDisabled ? {cursor: "not-allowed"} : {})}} />
                    </label>
                ) : (
                    <label
                        className="form-checkbox modal-checkbox"
                        style={{cursor: "default", paddingLeft: 0, textTransform: "uppercase"}}
                    >
                        <b>{entity.labels}</b>
                    </label>
                )}
            </div>
            <div>
                <div className="custom-search-block">
                    <i className="fa fa-search fa-2x" />
                    <input
                        type="text"
                        value={search[entity.name] || ""}
                        onChange={(ev) => updateSearch(ev, entity.name)}
                        className="form-control custom-search-input"
                        placeholder={`Search by ${_upperFirst(entity.labels.substr(0, entity.labels.length - 1))}`}
                    />
                </div>
            </div>
            <div className="custom-scroll">
                {filteredEntities.length ? (
                    filteredEntities.length >= 20 ? (
                        <InfiniteScroll
                            loadMore={handleUpdateSize}
                            hasMore={dataLength < structure[entity.name].length}
                            useWindow={false}
                            loader=""
                        >
                            {list}
                        </InfiniteScroll>
                    ) : (
                        list
                    )
                ) : (
                    <div className="text-center device-alert-no-results">
                        <span>No Results</span>
                    </div>
                )}
            </div>
        </div>
    );
};

MultiSelect.propTypes = {
    updateAll: PropTypes.func,
    updateData: PropTypes.func,
    updateSearch: PropTypes.func,
    updateCurrent: PropTypes.func,
    facilityIdsList: PropTypes.array,
    structure: PropTypes.object,
    current: PropTypes.object,
    search: PropTypes.object,
    entity: PropTypes.object,
    issues: PropTypes.object,
    data: PropTypes.object,
};

export default MultiSelect;
