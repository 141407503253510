import Api from "./api";

const CustomerApi = {
    list: () => {
        return Api.get("customer/list");
    },
    set: (data) => {
        return Api.post("customer/set", data);
    },
};

export default CustomerApi;