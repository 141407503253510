import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import {
    useChartStateStoreContext,
} from "../../store/hooks/useStoreContext";
import { shallow } from "zustand/shallow";

const ChartModeFullscreenedDropdown = () => {
    const { chartModeList } = useChartStateStoreContext((state) => state.factory, shallow);
    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const {changeChartMode} = useChartStateStoreContext((state) => state.actions);
    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    const setChartMode = (chartMode) => {
        changeChartMode(chartMode);
    };

    const [isOpen, setIsOpen] = useState(false);
    const chartModes = Object.entries(chartModeList);

    return (
        <Dropdown size="sm" disabled={!!isLoading} isOpen={isOpen} toggle={() => setIsOpen((prevState) => !prevState)}>
            <DropdownToggle tag="span" caret className="frequency-types-link">
                {chartMode}
            </DropdownToggle>
            <DropdownMenu>
                {chartModes.map(([key, val]) => (
                    <DropdownItem
                        key={key}
                        onClick={() => setChartMode(val)}
                        disabled={chartMode === val}
                    >
                        {val}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default ChartModeFullscreenedDropdown;
