import React from "react";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useAlertConditionListQuery} from "../../../../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {shallow} from "zustand/shallow";

const ShowWaterfallZoneSwitcher = () => {
    const isShowZoneOnWaterfall = useChartStateStoreContext((state) => state.settings.isShowZoneOnWaterfall);
    const toggleShowZone = useChartStateStoreContext((state) => state.actions.toggleShowZone);

    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint, shallow);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType, shallow);

    const {alertConditionsCount} = useAlertConditionListQuery(chartType, selectedPoint);

    if (!alertConditionsCount) {
        return <></>;
    }

    return (
        <React.Fragment>
            <span
                className={"alert-link black-color"}
                style={{whiteSpace: "nowrap"}}
                onClick={toggleShowZone}
                title={"Waterfall zone"}
            >
                <label
                    className={"switch"}
                    style={{marginRight: "10px"}}
                >
                    <input
                        readOnly={true}
                        type="checkbox"
                        checked={isShowZoneOnWaterfall}
                    />
                    <span className="slider round" />
                </label>
                <span style={{position: "relative", top: 1}}>Show Alert Zone</span>
            </span>
        </React.Fragment>
    );
};

export default ShowWaterfallZoneSwitcher;
