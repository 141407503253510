import {restorePoint, zoomPausedChart} from "../../../helpers/holdMode";

export const wrapHoldMode = (Highcharts) => {
    Highcharts.wrap(Highcharts.Pointer.prototype, "onContainerMouseMove", function (proceed) {
        Highcharts.hoverChartIndex = this.chart.index;

        if (this.chart.isPaused || this.chart.isPointBPaused) {
            if (!this.chart.hoverPoints) {
                restorePoint(this);
            }
            return;
        }

        return proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    });

    Highcharts.wrap(Highcharts.Pointer.prototype, "onContainerMouseDown", function (proceed, e) {
        const isTooltipClicked = e.target.closest(".highcharts-tooltip");
        if (isTooltipClicked || e?.button === 2) {
            return;
        }

        if (this.chart.holdPoints) {
            this.chart.isPaused = false;
            this.chart.isZoomedOnPause = true;
        }

        return proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    });
    Highcharts.wrap(Highcharts.Pointer.prototype, "onDocumentMouseUp", function (proceed, e) {
        const chart = Highcharts.charts.find((el) => el?.isChartOver);
        if (!chart) {
            return;
        }

        const isTooltipClicked = e.target.closest(".highcharts-tooltip");
        if ((isTooltipClicked || e?.button === 2) && chart.isPaused) {
            return;
        }

        proceed.apply(this, Array.prototype.slice.call(arguments, 1));
        zoomPausedChart({chart});
    });

    Highcharts.wrap(Highcharts.Pointer.prototype, "onDocumentMouseMove", function (proceed) {
        proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    });

    Highcharts.wrap(Highcharts.Pointer.prototype, "reset", function (proceed) {
        if (this.chart.isPaused || this.chart.isPointBPaused) return;
        proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    });

    Highcharts.wrap(Highcharts.Tooltip.prototype, "refresh", function (proceed) {
        if ((this.chart.isPaused || this.chart.isPointBPaused) && !this.chart.holdPoints.length) return;

        proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    });

    Highcharts.wrap(Highcharts.Tooltip.prototype, "hide", function (proceed) {
        if (this.chart.isPaused || this.chart.isPointBPaused) return;
        proceed.apply(this);
    });
};
