import React from "react";
import Select from "react-select";
import {get as _get} from "lodash";
import {useNameplatesListForOverlayQuery} from "../../../hooks/api/nameplates/useNameplatesListForOverlayQuery";
import PropTypes from "prop-types";

const NameplateFormItem = ({selectedPoints, canRemoveItem, value, onRemove, onChange}) => {
    const {data: nameplatesList, isLoading} = useNameplatesListForOverlayQuery(selectedPoints);

    return (
        <div className={"row form-group"}>
            <div className={"col-4"}>
                <Select
                    className={"react-select"}
                    classNamePrefix={"react-select"}
                    placeholder={"Select nameplate"}
                    selectName={"nameplateId"}
                    isSearchable={false}
                    isLoading={isLoading}
                    options={nameplatesList.nameplates}
                    value={value.nameplate}
                    onChange={(e) => {
                        onChange({
                            ...value,
                            nameplate: e,
                            equipment: null,
                            installationPoint: null,
                        });
                    }}
                />
            </div>
            <div className={"col-4"}>
                <Select
                    className={"react-select"}
                    classNamePrefix={"react-select"}
                    placeholder={"Select equipment"}
                    selectName={"equipmentId"}
                    isSearchable={false}
                    isLoading={isLoading}
                    isDisabled={!value.nameplate}
                    options={_get(nameplatesList, ["equipments", value?.nameplate?.value], null)}
                    value={value.equipment}
                    onChange={(e) => {
                        onChange({...value, equipment: {...e, equipment_name: e?.label}, installationPoint: null});
                    }}
                />
            </div>
            <div className={"col-3"}>
                <Select
                    className={"react-select"}
                    classNamePrefix={"react-select"}
                    placeholder={"Select sensor"}
                    selectName={"installationPointId"}
                    isLoading={isLoading}
                    isSearchable={false}
                    isDisabled={!value.equipment}
                    options={_get(nameplatesList, ["points", value?.nameplate?.value, value.equipment?.value], null)}
                    value={value.installationPoint}
                    onChange={(e) => {
                        onChange({
                            ...value,
                            installationPoint: {
                                ...e,
                                id: e?.value,
                                name: e?.label,
                                equipment_name: value?.equipment?.label,
                            },
                        });
                    }}
                />
            </div>
            {canRemoveItem && (
                <div className={"col-1"}>
                    <span
                        className="remove-block position-relative mt-2 block"
                        onClick={onRemove}
                    >
                        <i className="fa fa-times-circle" />
                    </span>
                </div>
            )}
        </div>
    );
};

NameplateFormItem.propTypes = {
    selectedPoints: PropTypes.number,
    canRemoveItem: PropTypes.bool,
    onRemove: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.object,
};

export default NameplateFormItem;
