import {useSyncedOverlay} from "../../../store/sync/overlaySyncStore";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useIsImpactVue} from "../../../hooks/useChartTypes";

export const useOverlay = () => {
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);

    return useSyncedOverlay(selectedPoint, isImpactVue);
};
