import Api from "./api";

const Tachometer = {
    list: (params) => {
        return Api.get("tachometers", {...params});
    },
    searchNodes: (query) => {
        return Api.get("tachometers/search-nodes", {query});
    },
    get: (tachometerId) => {
        return Api.get("tachometers/get/" + tachometerId);
    },
    create: (body) => {
        return Api.post("tachometers/create", body);
    },
    update: (id, body) => {
        return Api.post("tachometers/update/" + id, body);
    },
    delete: (id, body) => {
        return Api.delete("tachometers/delete/" + id, body);
    },
    export: (params) => {
        return Api.source("tachometers/export", {...params});
    },
};

export default Tachometer;
