import { useQuery } from "@tanstack/react-query";
import EquipmentApi from "../../../api/equipment";
import { EQUIPMENT_NOTES_API } from "./constants";
const useEquipmentNotesQuery = (equipmentId) => {
    const { isSuccess, data: equipmentNotes } = useQuery(
        [EQUIPMENT_NOTES_API.GET_EQUIPMENT_NOTES, equipmentId], () => EquipmentApi.notesCount(
            +equipmentId
        ),
        {
            enabled: !!equipmentId
        }
    );

    let notesCount = null;

    if (isSuccess) {
        notesCount = equipmentNotes.count;
    }

    return {
        isSuccess,
        notesCount
    };
};

export default useEquipmentNotesQuery;