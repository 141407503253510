import Api from "./api";

const ActionListApi = {
    getCount: () => Api.get("action-list/count-notifications"),
    mainList: (data= {}) => Api.get("action-list/main-list", {query: {...data}}),
    getInfo: () => Api.get("action-list/get-info"),
    getActionItem: (id) => Api.get(`action-list/get-action-item?action_item_id=${id}`),
    getArchiveActionItems: (data= {}) => Api.get("action-list/get-archive-action-items", {query: {...data}}),
    saveComment: (data) => Api.post("action-list/save-comment", data),
    deleteComment: (data) => Api.postFormData("action-list/delete-comment", data),
    snooze: (data) => Api.post("action-list/remind-later", data),
    saveWithoutReport: (data) => Api.post("action-list/save-without-ww-report", data),
    saveReport: (data) => Api.post("action-list/save-new-ww-report", data),
    cancelSnooze: (data) => Api.post("action-list/cancel-snooze", data),
    exportActionItems: () => Api.source("action-list/export-active-action-items"),
    exportArchiveActionItems: () => Api.source("action-list/export-archive-action-items")
};

export default ActionListApi;