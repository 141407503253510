import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal} from "../../shared";
import EquipmentEdit from "../../pages/forms/equimpent/edit";

class EquipmentModal extends Component
{
    getModalHeader = () => {
        const {onClone, onDelete, auth, equipment} = this.props;

        return !auth.equipmentViewOnly(equipment)
            ?
            <ModalHeader onEdit={this.onEdit} onClone={onClone} onDelete={onDelete}/>
            :
            <React.Fragment />;
    }

    render() {
        const {equipment, onClose} = this.props;

        return (
            <React.Fragment>
                <Modal
                    {...this.props}
                    size="lg"
                    className={"custom-modal equipment-modal"}
                    title={"Edit Equipment Details (" + equipment.name + ")"}
                    header={this.getModalHeader()}
                    withoutSubmit={true}
                    withoutFooter={true}
                >
                    <React.Fragment>
                        <EquipmentEdit
                            equipment={this.props.equipment}
                            cancelEdit={onClose}
                            invalidateEquipment={this.props.invalidateEquipment}
                            chartTypes={this.props.chartTypes}
                        />
                    </React.Fragment>
                </Modal>
            </React.Fragment>
        );
    }
}

const ModalHeader = ({/*onEdit,*/ onClone, onDelete}) => (
    <React.Fragment>
        {/*<button className={"link"} title={"Edit"} onClick={onEdit}>
            <i className="fa fa-pen"/>
        </button>*/}
        <button className={"link"} title={"Clone"} onClick={onClone}>
            <i className="fa fa-clone"/>
        </button>
        <button className={"link"} title={"Delete"} onClick={onDelete}>
            <i className="fa fa-trash"/>
        </button>
    </React.Fragment>
);

ModalHeader.propTypes = {
    onEdit: PropTypes.func,
    onClone: PropTypes.func,
    onDelete: PropTypes.func
};

EquipmentModal.propTypes = {
    showModal: PropTypes.bool,
    equipment: PropTypes.object,
    auth: PropTypes.object,
    onClose: PropTypes.func,
    onClone: PropTypes.func,
    invalidateEquipment: PropTypes.func,
    onDelete: PropTypes.func,
    chartTypes: PropTypes.array
};

export default EquipmentModal;