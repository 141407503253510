import {FREQUENCY_TYPES, SPEED_TYPES} from "../../constants/constants";
import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    nameRpm: PropTypes.string,
    nameRatio: PropTypes.string,
    valueRpm: PropTypes.number,
    valueRatio: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    equipmentSpeedTypeId: PropTypes.number,
    hasTachometer: PropTypes.bool,
    additionalClasses: PropTypes.string
};

const InstallationPointSpeedSettingInput = ({nameRpm, nameRatio, valueRpm, valueRatio, onChange, disabled, equipmentSpeedTypeId, hasTachometer, additionalClasses}) => {

    const showRatio = +equipmentSpeedTypeId === SPEED_TYPES.RATIO || equipmentSpeedTypeId === SPEED_TYPES.VARIABLE || hasTachometer;
    const showRpm = +equipmentSpeedTypeId === SPEED_TYPES.FIXED;

    return (
        <>
            {showRpm &&
                <div className={"form-group " + (additionalClasses ? additionalClasses : "")}>
                    <label>{FREQUENCY_TYPES.CPM} Value:</label>
                    <input
                        name={nameRpm}
                        onChange={onChange}
                        value={valueRpm}
                        placeholder="Enter Sensor Speed"
                        className="form-control"
                        disabled={disabled}
                    />
                </div>
            }
            {showRatio &&
                <div className={"form-group "  + (additionalClasses ? additionalClasses : "")}>
                    <label>Ratio Value:</label>
                    <input
                        name={nameRatio}
                        onChange={onChange}
                        value={valueRatio}
                        placeholder="Enter Sensor Ratio"
                        className="form-control"
                        disabled={disabled}
                    />
                </div>
            }
        </>
    );
};

InstallationPointSpeedSettingInput.propTypes = propTypes;
export default InstallationPointSpeedSettingInput;