import React, {Component} from "react";
import PropTypes from "prop-types";
import {Popover, PopoverBody, PopoverHeader} from "reactstrap";

class BasePopover extends Component {
    render() {
        const {children, title = "", placement = "bottom-end", trigger = "legacy"} = this.props;
        return (
            <Popover
                {...this.props}
                placement={placement}
                trigger={trigger}
                popperClassName={"custom-popover"}
            >
                {title && title.length &&
                    <PopoverHeader>{title}</PopoverHeader>
                }
                <PopoverBody className="custom-scroll px-4 py-4" style={{height: "auto", maxHeight: 450}}>
                    {children}
                </PopoverBody>
            </Popover>
        );
    }
}

BasePopover.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    target: PropTypes.any,
    placement: PropTypes.string,
    trigger: PropTypes.string,
    toggle: PropTypes.func
};

export  default BasePopover;