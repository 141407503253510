import ApiFaultFrequency from "../../../api/faultFrequency";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {FAULT_FREQUENCY_API} from "./constants";
import {updateItemInList} from "../../../helpers/query";

export const useFaultFrequencyUpdateMutation = (equipmentId, faultFrequencyId) => {
    const queryClient = useQueryClient();

    const updateFaultFrequency = (data) =>
        ApiFaultFrequency.update(equipmentId, faultFrequencyId, data);

    const mutation = useMutation(updateFaultFrequency, {
        onSuccess: (data) => {
            queryClient.setQueriesData([FAULT_FREQUENCY_API.GET_FREQUENCY, equipmentId], (prev) => updateItemInList(prev, data));
        },
    });

    return mutation;
};
