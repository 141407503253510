import {useModal} from "react-modal-hook";
import NameplateModal from "../components/nameplateModal";
import React from "react";
import {useModalHistory} from "../../../hooks/helpers/useModalHistory";
import {MODALS} from "../../../constants/modals";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useEquipmentInvalidate} from "../../../hooks/api/equipment/useEquipmentInvalidate";

export const useNameplateModal = () => {
    const {equipmentItem} = useEquipmentByParams();
    const invalidateEquipment = useEquipmentInvalidate(equipmentItem.id);
    const [showModal, hideModal] = useModal(() => (
        <NameplateModal
            showModal={true}
            onClose={onHide}
            equipment={equipmentItem}
            reloadEquipment={invalidateEquipment}
        />
    ),[equipmentItem]);

    const {pushHistory, deleteHistory} = useModalHistory(MODALS.NAMEPLATE, showModal);

    const onShow = () => {
        pushHistory();
        showModal();
    };

    const onHide = () => {
        deleteHistory();
        hideModal();
    };

    return [onShow, onHide];
};
