import React from "react";
import {
    useInterpolationSyncStoreActions,
    useInterpolationSyncStoreByPoint
} from "../../../store/sync/InterpolationSuncStore";
import PropTypes from "prop-types";

const InterpolationBtn = ({installationPointId}) => {
    const {toggleInterpolation} = useInterpolationSyncStoreActions();
    const isInterpolation = useInterpolationSyncStoreByPoint(installationPointId);

    return (
        <React.Fragment>
            <span
                className={"alert-link black-color"}
                style={{whiteSpace: "nowrap"}}
                onClick={() => toggleInterpolation(installationPointId)}
                title={"Interpolation"}
            >
                <label
                    className={"switch"}
                    style={{marginRight: "10px"}}
                >
                    <input
                        readOnly={true}
                        type="checkbox"
                        checked={isInterpolation || isInterpolation === undefined  ? "checked" : false}
                    />
                    <span className="slider round" />
                </label>
                <span style={{position: "relative", top: 1}}>Interpolation</span>
            </span>
        </React.Fragment>
    );
};

InterpolationBtn.propTypes = {
    installationPointId: PropTypes.number,
};

export default InterpolationBtn;
