import PropTypes from "prop-types";
import NameField from "../forms/parts/name";
import InstallationPointCustomTypeField from "../forms/parts/type";
import SensorField from "../forms/parts/sensor";
import React, {useState} from "react";
import {
    get as _get,
    setWith as _setWith,
    omit as _omit,
    cloneDeep as _cloneDeep
} from "lodash";
import EquipmentApi from "../../../api/equipment";
import EquipmentInstallationPointApi from "../../../api/equipmentInstallationPoint";
import Toast from "../../../pages/shared/toast";
import InstallationPointApi from "../../../api/installationPoint";
import SweetAlert from "react-bootstrap-sweetalert";
import HeatsinkField from "../forms/parts/heatsink";

const SensorsForm = ({installationPoint, equipmentId, afterUpdate}) => {
    const [isEdit, setIsEdit] = useState(false);

    return (
        <>
            {isEdit
                ?
                <EditSensorForm
                    installationPoint={installationPoint}
                    equipmentId={equipmentId}
                    onCancel={() => setIsEdit(false)}
                    afterUpdate={() => afterUpdate().then(() => setIsEdit(false))}
                />
                :
                <ViewSensorForm
                    installationPoint={installationPoint}
                    onEdit={() => setIsEdit(true)}
                    equipmentId={equipmentId}
                    afterUpdate={afterUpdate}
                />
            }

        </>
    );
};

const EditSensorForm = ({installationPoint, equipmentId, onCancel, afterUpdate}) => {
    const [point, setPoint] = useState(_cloneDeep(installationPoint));
    const [inProgress, setInProgress] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const onChange = (event) => {
        let newPoint = {...point};

        const key = _get(event, "target.name", "");
        const val = _get(event, "target.value", "");

        _setWith(newPoint, key, val, Object);

        removeError(key);
        setPoint(newPoint);
    };

    const removeError = (key) => {
        const oldFormErrors = {...formErrors};

        const newFormErrors = _omit(oldFormErrors, key);

        setFormErrors(newFormErrors);
    };

    const onUpdate = () => {
        setInProgress(true);
        EquipmentApi.checkService(equipmentId).then(() =>
            EquipmentInstallationPointApi
                .batchUpdate(equipmentId, {installationPoints: [point]})
                .then(response => {
                    if (response.status === "ok") {
                        afterUpdate().then(() => {
                            Toast.success("The installation point has been updated.");
                            setInProgress(false);
                            onCancel();
                        });
                    }
                })
                .catch(response => {
                    setFormErrors(_get(response.errors || {}, "point.0"));
                    setInProgress(false);
                })
        );
    };

    return (
        <div className={"row align-items-center py-2"}>
            <div className={"col-3"}>
                <NameField
                    value={_get(point, "name")}
                    onChange={onChange}
                    formErrors={formErrors}
                />
            </div>
            <div className={"col-3"}>
                <InstallationPointCustomTypeField
                    value={_get(point, "installation_point_custom_type_id")}
                    onChange={onChange}
                />
            </div>
            <div className={"col-3 px-2"}>
                <SensorField
                    sensor={_get(point, "sensor", {})}
                    formErrors={formErrors}
                    onChange={onChange}
                />
            </div>
            <div className={"col-2 px-2"}>
                <HeatsinkField
                    value={_get(point, "is_heatsink", 0) || 0}
                    withLabel={false}
                    onChange={onChange}
                />
            </div>
            <div className={"col-1"}>
                {!inProgress &&
                    <>
                        <img className={"action-btn mr-4"} src={"/assets/img/check.svg"} onClick={onUpdate} />
                        <img className={"action-btn"} src={"/assets/img/close.svg"} onClick={onCancel} title="Cancel" />
                    </>
                }
            </div>
        </div>
    );
};

EditSensorForm.propTypes = {
    installationPoint: PropTypes.object,
    equipmentId: PropTypes.number,
    onCancel: PropTypes.func,
    afterUpdate: PropTypes.func,
};


const ViewSensorForm = ({installationPoint, onEdit, afterUpdate, equipmentId}) => {

    const [onDelete, setOnDelete] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    const deleteInstallationPoint = () => {
        setInProgress(true);
        EquipmentApi.checkService(equipmentId).then(() =>
            InstallationPointApi
                .delete(installationPoint.id)
                .then(() => {
                    afterUpdate().then(() => {
                        Toast.success("The installation point has been deleted.");
                        setOnDelete(false);
                        setInProgress(false);
                    });
                })
        );
    };

    return (
        <div className={"row point-info p-2 py-4 align-items-center"}>
            <div className={"col-3"}>{_get(installationPoint, "name")}</div>
            <div className={"col-3"}>{_get(installationPoint, "installationPointCustomType.name")}</div>
            <div className={"col-3 px-2"}>{_get(installationPoint, "sensor.sensor_hex")}</div>
            <div className={"col-2 px-2"}>
                <HeatsinkField
                    value={_get(installationPoint, "is_heatsink")}
                    readOnly={true}
                    withLabel={false}
                />
            </div>
            <div className={"col-1"}>
                {!inProgress &&
                    <>
                        <img className={"action-btn mr-4"} src={"/assets/img/pencil.svg"} onClick={onEdit} />
                        <img className={"action-btn"} src={"/assets/img/bucket.svg"} onClick={() => setOnDelete(true)} />
                    </>
                }
            </div>
            {onDelete &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title="DELETE INSTALLATION POINT"
                    openAnim={false}
                    closeAnim={false}
                    onConfirm={() => deleteInstallationPoint()}
                    onCancel={() => setOnDelete(false)}
                    disabled={inProgress}
                >
                    Are you sure you want to delete this installation point?
                </SweetAlert>
            }
        </div>
    );
};

ViewSensorForm.propTypes = {
    installationPoint: PropTypes.object,
    onEdit: PropTypes.func,
    afterUpdate: PropTypes.func,
    equipmentId: PropTypes.number
};

SensorsForm.propTypes = {
    installationPoint: PropTypes.object,
    equipmentId: PropTypes.number,
    afterUpdate: PropTypes.func,
};

export default SensorsForm;