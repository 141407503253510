import { ValidationError } from "../index";
import React, { useState } from "react";
import {concat as _concat, findIndex as _findIndex, get as _get} from "lodash";
import PropTypes from "prop-types";
import SelectWrapper from "../../helpers/select-wrapper";
import PhotosWrapper from "../photosWrapper/photosWrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import Toast from "../../pages/shared/toast";

const SelectFormGroup = ({
    label,
    className,
    options,
    optionValue,
    optionName,
    value,
    valueName,
    errorMsg,
    disabled,
    isRequired,
    onChange,
    addToLabel
}) => {
    return (
        <div className={"form-group" + " " + className}>
            <label className="text-right form-label">
                {label}: {!!isRequired && <span className="color-danger">*</span>}
                {addToLabel || ""}
            </label>
            <SelectWrapper
                className={!errorMsg ? "" : " is-invalid"}
                style={{
                    width: "100%",
                    display: "block",
                }}
                name={valueName}
                value={value}
                onChange={onChange}
                disabled={disabled}
                placeholder={"Select " + label}
            >
                {/*<option value={""}>Select {label}</option>*/}
                {options.map((item) => (
                    <option key={+_get(item, optionValue)} value={+_get(item, optionValue)}>
                        {_get(item, optionName)}
                    </option>
                ))}
            </SelectWrapper>
            <ValidationError message={errorMsg} />
        </div>
    );
};

SelectFormGroup.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    optionValue: PropTypes.string,
    optionName: PropTypes.string,
    value: PropTypes.any,
    valueName: PropTypes.string,
    errorMsg: PropTypes.string,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    addToLabel: PropTypes.node
};

const InputFormGroup = ({
    label,
    name,
    value,
    errorMsg,
    disabled,
    isRequired,
    onChange,
    className,
    type,
    groupId = null,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            {label &&<label className="text-right form-label">
                {label}: {!!isRequired && <span className="color-danger">*</span>}
            </label>}
            <div className={type === "password" ? "input-group" : ""}>
                <input
                    className={"form-control " + (!errorMsg ? "" : " is-invalid")}
                    type={type && (type !== "password" || !showPassword) ? type : "text"}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
                {type === "password" && groupId === null && (
                    <div
                        className={"input-group-append cursor-pointer"}
                        onClick={() => setShowPassword(!showPassword)}
                        title={showPassword ? "Hide " + label : "Show " + label}
                    >
                        <div className={"input-group-text"}>
                            <i className={"fa fa-eye" + (showPassword ? "-slash" : "")} />
                        </div>
                    </div>
                )}
                <ValidationError message={errorMsg} />
            </div>
        </div>
    );
};

InputFormGroup.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    errorMsg: PropTypes.string,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    type: PropTypes.string,
    groupId: PropTypes.number,
};

const CheckboxFormGroup = ({label, name, value, isRequired, onChange, error, readOnly}) => {
    return (
        <div className={"form-check" + (error ? " validate" : "")}>
            <div className="form-label">&nbsp;</div>
            <div className="mt-3"></div>
            <label className="form-checkbox">
                {label} {!!isRequired && <span className="color-danger">*</span>}
                <input className={"form-checkbox"}
                       readOnly={true}
                       type="checkbox"
                       checked={value ? "checked" : false}
                       onChange={() => {
                           if (!readOnly) {
                               onChange({
                                   target: {
                                       name: name,
                                       value: !value,
                                   },
                               });
                           }
                       }}
                />
                <span
                    className={"sensor-checkbox-wrapper"}
                    style={{ backgroundColor: value ? "rgb(30, 144, 255)" : "inherit", borderColor: "rgb(30, 144, 255)"  }}
                />
            </label>
            <div className={"invalid-feedback"}>{error}</div>
        </div>
    );
};

CheckboxFormGroup.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
    error: PropTypes.string,
    readOnly: PropTypes.bool
};

const FileFormGroup = ({
    label,
    name,
    url,
    fileName,
    errorMsg,
    onBatchChange,
}) => {

    const onSetFile = (e) => {
        const name = _get(e, "target.name");
        const files = _get(e, "target.files");

        if (files.length) {
            Promise.all(
                Object.keys(files).map(
                    (key) =>
                        new Promise((resolve, reject) => {
                            if (files[key]) {
                                let reader = new FileReader();
                                reader.readAsDataURL(files[key]);
                                reader.onload = () => resolve(reader.result);
                                reader.onerror = (error) => reject(error);
                            } else {
                                reject("File is empty");
                            }
                        })
                )
            ).then((filesData) => {
                onBatchChange({
                    [name+"_file_name"]: files[0].name,
                    [name+"_file"]: filesData[0]
                });
            });
        }
    };

    const onRemoveFile = () => {
        onBatchChange({
            [name+"_file_name"]: "",
            [name+"_file"]: "",
            [name+"_url"]: "",
        });
    };

    return (
        <div className="form-group">
            <label className="text-right form-label">{label}: </label>
            <div className="">
                {fileName
                    ?
                    <div className={"d-flex"}>
                        <a href={url ? url : "#"}>{fileName}</a>
                        <div className={"note-file-remove ml-2"} onClick={onRemoveFile}>
                            <i className={"fa fa-times"} title={"Remove File"}/>
                        </div>
                    </div>
                    :
                    <>
                        <input
                            type="file"
                            name={name}
                            className={"form-control" + (errorMsg ? " is-invalid" : "")}
                            onChange={onSetFile}
                        />
                        <ValidationError message={errorMsg}/>
                    </>
                }

            </div>
        </div>
    );
};

FileFormGroup.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    errorMsg: PropTypes.string,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    onBatchChange: PropTypes.func,
    className: PropTypes.string,
    url: PropTypes.string,
    fileName: PropTypes.string,
};

const TextareaFormGroup = ({
    label,
    name,
    value,
    errorMsg,
    disabled,
    isRequired,
    onChange,
    className,
}) => {

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            {label &&<label className="text-right form-label">
                {label}: {!!isRequired && <span className="color-danger">*</span>}
            </label>}
            <div>
                <textarea
                    className={"form-control" + (errorMsg ? " is-invalid" : "")}
                    name={name}
                    style={{height: "150px"}}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                />
                <ValidationError message={errorMsg} />
            </div>
        </div>
    );
};

TextareaFormGroup.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    errorMsg: PropTypes.string,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    type: PropTypes.string,
    groupId: PropTypes.number,
};

const ImageFormGroup = ({
    label,
    name,
    value,
    errorMsg,
    isRequired,
    onChange,
    className,
    needConfirmDelete
}) => {

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [photoForDetach, setPhotoForDetach] = useState({});

    const attachImages = images => {
        const photos = [...value];

        if (photos.length + images.length > 10) {
            Toast.error("You cannot upload more than 10 photos.");
            return;
        }

        let imageObjects = [];
        images.map(image => {
            imageObjects.push({url: image});
        });

        onChange({
            target: {
                name: name,
                value: _concat(photos, imageObjects)
            }
        });
    };

    const detachImage = (image) => {
        if (needConfirmDelete && !confirmDelete) {
            setConfirmDelete(true);
            setPhotoForDetach(image);
        } else {
            let photos = [...value];
            const index = _findIndex(photos, {url: photoForDetach.url});

            if (index >= 0) {
                photos.splice(index, 1);
            }

            onChange({
                target: {
                    name: name,
                    value: photos
                }
            });
            setConfirmDelete(false);
            setPhotoForDetach({});
        }

    };

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            {label &&<label className="text-right form-label">
                {label}: {!!isRequired && <span className="color-danger">*</span>}
            </label>}
            <div>
                <PhotosWrapper
                    photos={value ? value : []}
                    onAddPhotos={attachImages}
                    onDeletePhoto={detachImage}
                    withPhotoModal={true}
                    withDropzone={true}
                    buttonName={"Add " + label}
                    defaultPhoto={"picture.svg"}
                    withDeleteButton={true}
                    withList={true}
                />
                <ValidationError message={errorMsg} />
            </div>
            {confirmDelete &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title="Are you sure you want to delete this photo?"
                    onConfirm={detachImage}
                    onCancel={() => setConfirmDelete(false)}
                />
            }
        </div>
    );
};

ImageFormGroup.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    errorMsg: PropTypes.string,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    type: PropTypes.string,
    groupId: PropTypes.number,
    needConfirmDelete: PropTypes.bool
};

export default SelectFormGroup;
export { SelectFormGroup, InputFormGroup, CheckboxFormGroup, FileFormGroup, TextareaFormGroup, ImageFormGroup };
