import React, {useEffect, useState} from "react";
import ApiReadingTypes from "../../../api/readingTypes";
import WuaSensorValueTypeApi from "../../../api/wuaSensorValueType";
import {get as _get, each as _each, find as _find} from "lodash";
import WuaSensorApi from "../../../api/wuaSensor";
import Toast from "../../shared/toast";
import WuaConfigForm from "./wuaConfigForm";
import {Loader} from "../../../shared";
import {useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import Helper from "../../../helpers/helper";
import {ADAPTER_VALUE_TYPE} from "../../../constants/constants";
import {HeaderSimple} from "../../../shared/header";
import {Button} from "reactstrap";
import NotFound from "../../not-found";
import {useParams} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {useHistory} from "react-router";

const defaultConfig = {
    name: "",
    reading_type_id: null,
    brand: "",
    model: "",
    value_type_id: "",
    offset: "",
    ratio: "",
    datasheet_file: "",
    datasheet_url: "",
    datasheet_file_name: "",
    wiring_diagram_file: "",
    wiring_diagram_url: "",
    wiring_diagram_file_name: "",
    wiring_instructions: "",
    photos: [],
    voltage: true,
    min_voltage: "",
    max_voltage: "",
    min_sensor_value: "",
    max_sensor_value: "",
};

const WuaConfigPage = () => {
    const params = useParams();
    const history = useHistory();
    const configId = params.configId;

    const [config, setConfig] = useState({});
    const [initialFormValues, setInitialFormValues] = useState({});
    const [confirmCancel, setConfirmCancel] = useState(false);
    const [readingTypes, setReadingTypes] = useState([]);
    const [valueTypes, setValueTypes] = useState([]);
    const [loader, setLoader] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [isRtd, setIsRtd] = useState(false);
    const [globalSearchString, setGlobalSearchString] = useState("");

    const breadcrumbs = [
        {name: "Manage Universal Adapter Configurations", link: "/network/ua-configurations"},
        {name: configId ? "Edit Universal Adapter Configuration" : "Add Universal Adapter Configuration"},
    ];
    const title = configId ? "Edit Universal Adapter Configuration" : "Add Universal Adapter Configuration";

    const chartTypes = useChartTypesStore();

    useEffect(() => {
        Promise.all([fetchWuaSensor(), fetchReadingTypes(), fetchValueTypes()]).then(() => {
            setLoader(false);
        });
    }, []);

    useEffect(() => {
        const isRTD = isCurrentValueTypeRTD();

        if (config.value_type_id === ADAPTER_VALUE_TYPE.RTD) {
            const tempType = _find(chartTypes, (item) => item.alias === "temp");

            setConfig({
                ...config,
                ...{
                    reading_type_id: +_get(tempType, "readingTypes.0"),
                    min_voltage: "",
                    max_voltage: "",
                    min_sensor_value: "",
                    max_sensor_value: "",
                },
            });
        }

        if (config.value_type_id === ADAPTER_VALUE_TYPE.V05) {
            setConfig({
                ...config,
                ...{
                    min_voltage: 0,
                    max_voltage: 5,
                },
            });
        }

        if (config.value_type_id === ADAPTER_VALUE_TYPE.V010) {
            setConfig({
                ...config,
                ...{
                    min_voltage: 0,
                    max_voltage: 5,
                },
            });
        }

        if (config.value_type_id === ADAPTER_VALUE_TYPE.MA420) {
            setConfig({
                ...config,
                ...{
                    min_voltage: 4,
                    max_voltage: 20,
                },
            });
        }

        setIsRtd(isRTD);
    }, [config.value_type_id, valueTypes]);

    useEffect(() => {
        if (config.value_type_id === ADAPTER_VALUE_TYPE.V010) {
            setConfig({
                ...config,
                ...{max_voltage: config.min_voltage === 0 ? 5 : 10},
            });
        }
    }, [config.min_voltage, valueTypes]);

    const isCurrentValueTypeRTD = () => {
        const currValueType = _find(valueTypes, {id: config.value_type_id});
        return currValueType && currValueType.value_key === "temperature";
    };

    const fetchWuaSensor = () => {
        if (!configId) {
            setConfig(defaultConfig);
            setInitialFormValues(defaultConfig);
            return false;
        } else {
            return WuaSensorApi.getView(configId).then((response) => {
                const wuaSensor = _get(response, "wuaSensor", {});
                setConfig(wuaSensor);
                setInitialFormValues(wuaSensor);
            });
        }
    };

    const fetchReadingTypes = () => {
        return ApiReadingTypes.customList().then((response) => {
            let data = [];
            _each(_get(response, "list", []), (readingType) => {
                readingType.name = _get(readingType, "name") + " (" + _get(readingType, "unit") + ")";
                data.push(readingType);
            });
            const tempType = _find(chartTypes, (item) => item.alias === "temp");
            if (tempType) {
                data.push({
                    id: +_get(tempType, "readingTypes.0"),
                    name: _get(tempType, "label") + " (" + Helper.getUserMeasure(chartTypes, 1) + ")",
                });
            }
            setReadingTypes(data);
        });
    };

    const fetchValueTypes = () => {
        return WuaSensorValueTypeApi.getList().then((response) => {
            setValueTypes(_get(response, "list", []));
        });
    };

    const onChange = (event) => {
        const key = _get(event, "target.name", "");
        const val = _get(event, "target.value", "");

        setConfig({...config, ...{[key]: val}});
    };

    const onBatchChange = (data) => {
        setConfig({...config, ...data});
    };

    const handleSubmit = () => {
        return configId ? update() : create();
    };

    const create = () => {
        setInProgress(true);
        setFormErrors({});

        return WuaSensorApi.create(config)
            .then(() => {
                Toast.success("The universal adapter configuration has been created.");
                history.push("/network/ua-configurations");
            })
            .catch((response) => {
                setFormErrors(response.errors || {});
                setInProgress(false);
            });
    };

    const update = () => {
        setInProgress(true);
        setFormErrors({});

        return WuaSensorApi.update(config.id, config)
            .then(() => {
                if (JSON.stringify(config) === JSON.stringify(initialFormValues)) {
                    Toast.success("No changes were made.");
                } else {
                    Toast.success("The universal adapter configuration has been updated.");
                }
                history.push("/network/ua-configurations");
            })
            .catch((response) => {
                setFormErrors(response.errors || {});
                setInProgress(false);
            });
    };

    if (configId && config.id && !_get(config, "customer_id")) {
        return <NotFound />;
    }

    const handleCancel = () => {
        JSON.stringify(config) === JSON.stringify(initialFormValues) ? history.push("/network/ua-configurations") : setConfirmCancel(true);
    };

    return (
        <div className="">
            <HeaderSimple
                breadcrumbs={breadcrumbs}
                globalSearchString={globalSearchString}
                handleChangeGlobalSearch={setGlobalSearchString}
            />
            <div className="subheader">
                <div className="subheader-title">{title}</div>
            </div>
            {loader ? (
                <div className="loader-fullscreen">
                    <Loader />
                </div>
            ) : (
                <div>
                    <div className="block">
                        <div className="block-body">
                            <WuaConfigForm
                                data={config}
                                readingTypes={readingTypes}
                                valueTypes={valueTypes}
                                inProgress={inProgress}
                                formErrors={formErrors}
                                onChange={onChange}
                                fetchReadingTypes={fetchReadingTypes}
                                onBatchChange={onBatchChange}
                                isRtd={isRtd}
                            />
                        </div>
                    </div>
                    <div className="text-right">
                        <div
                            className="btn btn-sm btn-secondary"
                            onClick={handleCancel}
                        >
                            Cancel
                        </div>
                        <Button
                            color="primary"
                            size="sm"
                            className="ml-2"
                            disabled={inProgress}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </div>
                    {confirmCancel && (
                        <SweetAlert
                            warning
                            showCancel
                            cancelBtnText="Cancel"
                            confirmBtnText="Yes"
                            cancelBtnBsStyle="default"
                            btnSize="xs"
                            title=""
                            onConfirm={() => history.push("/network/ua-configurations")}
                            onCancel={() => setConfirmCancel(false)}
                        >
                            Are you sure you want to navigate away from this page? If you proceed, ALL of your changes will be lost. Click Yes to continue,
                            or click Cancel to stay on the current page
                        </SweetAlert>
                    )}
                </div>
            )}
        </div>
    );
};

export default WuaConfigPage;
