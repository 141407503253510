import React from "react";
import PropTypes from "prop-types";
import {
    get as _get
} from "lodash";
import NameField from "./parts/name";
import InstallationPointCustomTypeField from "./parts/type";
import SensorField from "./parts/sensor";
import HeatsinkField from "./parts/heatsink";

const InstallationPointForm = ({installationPoint, formErrors, onChange, removeButton, onRemoveButton, isRouteCollector = false}) =>
{
    const colSizeClass = isRouteCollector ? " col-md-5" : " col-md-3";
    return (
        <div className={"row position-relative installation-point-form"}>
            <div className={"form-group" + colSizeClass}>
                <NameField
                    value={_get(installationPoint, "name")}
                    onChange={onChange}
                    formErrors={formErrors}
                />
            </div>
            <div className={"form-group" + colSizeClass}>
                <InstallationPointCustomTypeField
                    value={_get(installationPoint, "installation_point_custom_type_id")}
                    onChange={onChange}
                />
            </div>
            {!isRouteCollector &&
                <div className={"form-group" + colSizeClass}>
                    <SensorField
                        sensor={_get(installationPoint, "sensor", {})}
                        formErrors={formErrors}
                        onChange={onChange}
                    />
                </div>
            }

            <div className={"form-group" + (isRouteCollector ? "col-md-2" : colSizeClass)}>
                <HeatsinkField
                    value={_get(installationPoint, "is_heatsink", 0) || 0}
                    onChange={onChange}
                />
            </div>

            {removeButton &&
                <span className="remove-block" onClick={onRemoveButton}>
                    <i className="fa fa-times-circle" />
                </span>
            }
        </div>
    );
};

InstallationPointForm.propTypes = {
    installationPoint: PropTypes.object,
    onChange: PropTypes.func,
    formErrors: PropTypes.object,
    removeButton: PropTypes.bool,
    isRouteCollector: PropTypes.bool,
    onRemoveButton: PropTypes.func
};

export default InstallationPointForm;