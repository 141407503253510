import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get,
} from "lodash";

class StepsButtons extends Component
{
    render() {
        const {steps, currentStepIndex, setCurrentStepIndex, inProgress, profile, formErrors} = this.props;

        return (
            <React.Fragment>
                <div className={"steps"}>
                    {steps.map((step, index) =>
                        <div
                            key={index}
                            onClick={() => {
                                if (!_get(step, "disabled")) {
                                    setCurrentStepIndex(index);
                                }
                            }}
                            className={
                                "step"
                                + (index === 0 ? " first" : "")
                                + (index === steps.length - 1 ? " last" : "")
                                + ((index !== steps.length - 1 && index !== 0) ? " middle" : "")
                                + ((index === currentStepIndex) ? " active" : "")
                                + (step.isDone(profile, currentStepIndex) ? " done" : "")
                                + (!step.isEnabled(profile, formErrors) ? " disabled" : "")
                            }
                        >
                            {index > 0 &&
                                <Triangle type={"left"} />
                            }

                            {step.name}

                            {inProgress && index === currentStepIndex
                                ?
                                <i className="fa fa-spinner ml-2" />
                                :
                                <React.Fragment>
                                    {!step.isValid(profile, formErrors)
                                        ?
                                        <i className="fas fa-exclamation-triangle ml-2 color-warning" />
                                        :
                                        <React.Fragment>
                                            {!!step.isDone(profile, currentStepIndex) &&
                                                <i className="fas fa-check-circle ml-2" />
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }

                            {index < steps.length - 1 &&
                                <Triangle type={"right"} />
                            }
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const TriangleTypes = ["left", "right"];

const Triangle = ({type}) => (
    <div className={"triangle " + type}>
        <div className="outer">
            <div className="inner" />
        </div>
    </div>
);

Triangle.propTypes = {
    type: PropTypes.oneOf(TriangleTypes).isRequired,
};

StepsButtons.propTypes = {
    steps: PropTypes.array,
    currentStepIndex: PropTypes.number,
    setCurrentStepIndex: PropTypes.func,
    inProgress: PropTypes.bool,
    profile: PropTypes.object,
    formErrors: PropTypes.object
};

export default StepsButtons;