const STORAGE_VISIBILITY_KEY = "install-point-popup-visibility";

export const getInstallPointPopupVisibility = (equipmentId) => {
    const installPointVisibility = JSON.parse(window.localStorage.getItem(STORAGE_VISIBILITY_KEY));
    if(!installPointVisibility) {
        return true;
    }
    return !installPointVisibility.includes(+equipmentId);
};

export const setInstallPointPopupVisibility = (equipmentId) => {
    const installPointVisibility = JSON.parse(window.localStorage.getItem(STORAGE_VISIBILITY_KEY)) || [];
    if(!installPointVisibility.includes(+equipmentId)) {
        installPointVisibility.push(+equipmentId);
    }
    window.localStorage.setItem(STORAGE_VISIBILITY_KEY,JSON.stringify(installPointVisibility));
};