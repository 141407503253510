import {useEffect} from "react";
import Helper from "../../../helpers/helper";
import {useChartSelectedEquipmentStoreActions} from "../../../stores/zustand/ChartSelectedEquipmentStore";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";

export const useSyncSensorWithRoute = () => {
    const {setSelectedInstallationPoints} = useChartSelectedEquipmentStoreActions();
    const {equipmentItem, isSuccess} = useEquipmentByParams();

    useEffect(() => {
        const {sensors = ""} = Helper.getHashParams();
        if (!sensors || !equipmentItem?.id) {
            return;
        }

        setSelectedInstallationPoints([+sensors], equipmentItem.id);
    }, [isSuccess, equipmentItem?.id]);
};
