import React, {useEffect, useReducer} from "react";
import {Modal} from "../../../shared";
import PropTypes from "prop-types";
import {get as _get, has as _has} from "lodash";
import {ExportToCsv} from "export-to-csv";
import FrequencyConverter from "../../../helpers/frequency-converter";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";

import "../../../assets/scss/components/fft/fft-modal.scss";
import { FREQUENCY_TYPES } from "../../../constants/constants";

const ACTIONS = {
    SEARCH_PICK: "ACTION SEARCH PICK",
    SET_DROPDOWN_SERIES: "ACTION SET DROPDOWN SERIES",
    CHANGE_SELECTED_SERIES: "ACTION CHANGE SELECTED SERIES",
    TOGGLE_HARMONIC: "ACTION TOGGLE HARMONIC",
    SET_HARMONIC_POINTS: "ACTION SET HARMONIC POINTS",
};

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SEARCH_PICK:
            return {
                ...state,
                peaks: action.payload.peaks,
                points: action.payload.points,
                loader: action.payload.loader,
            };
        case ACTIONS.TOGGLE_HARMONIC:
            return {
                ...state,
                harmonicPeak: action.payload.harmonicPeak,
                harmonicLoader: action.payload.harmonicPeak !== null,
            };
        case ACTIONS.SET_HARMONIC_POINTS:
            return {
                ...state,
                harmonicLoader: false,
                harmonicPoints: action.payload.harmonicPoints,
            };
        case ACTIONS.SET_DROPDOWN_SERIES:
            return {
                ...state,
                openDropdownSeries: action.payload,
            };
        case ACTIONS.CHANGE_SELECTED_SERIES:
            return {
                ...state,
                selectedSeries: action.payload.selectedSeries,
            };
        default:
            throw Error("Unknown action.");
    }
}

const initialState = {
    loader: false,
    points: [],
    peaks: [],
    harmonicLoader: false,
    harmonicPeak: null,
    harmonicPoints: [],
    selectedSeries: 0,
    openDropdownSeries: false,
};

function createInitialState(points) {
    const state = {...initialState};
    state.peaks = searchPeaks(points);
    state.points = points;
    return state;
}

function searchPeaks(points) {
    let peaksArray = [];
    if (points.length > 2) {
        for (let i = 1; i < points.length; i++) {
            if (
                points[i] &&
                points[i - 1] &&
                points[i + 1] &&
                points[i][1] > points[i - 1][1] &&
                points[i][1] > points[i + 1][1] &&
                points[i][0] >= 5
            ) {
                peaksArray.push(points[i]);
            }
        }
    }
    return peaksArray;
}

const PeaksModal = ({options, selectedPoint, axisLabels, seriesList, currentSpeed, showHarmonic, showModal, onClose}) => {
    const [state, dispatch] = useReducer(reducer, _get(options, "series.0.data", []), createInitialState);
    useEffect(() => {
        if (state.selectedSeries !== null && state.selectedSeries !== undefined) {
            redrawPeaks(_get(options, "series." + state.selectedSeries + ".data", []));
        }
    }, [state.selectedSeries]);

    useEffect(() => {
        if (state.harmonicLoader === true && state.harmonicPeak !== null) {
            dispatch({
                type: ACTIONS.SET_HARMONIC_POINTS,
                payload: {
                    harmonicPoints: searchHarmoniPoints(state.harmonicPeak),
                },
            });
        }
    }, [state.harmonicLoader]);

    const toggleSeriesOpen = () => {
        dispatch({type: ACTIONS.SET_DROPDOWN_SERIES, payload: !state.openDropdownSeries});
    };

    const changeSelectedSeries = (selectedSeries) => {
        dispatch({
            type: ACTIONS.CHANGE_SELECTED_SERIES,
            payload: {
                selectedSeries: selectedSeries,
            },
        });
    };

    const redrawPeaks = (points) => {
        dispatch({
            type: ACTIONS.SEARCH_PICK,
            payload: {
                peaks: searchPeaks(points),
                points: points,
                loader: false,
            },
        });
    };

    const searchHarmoniPoints = (peak) => {
        let harmonicPoints = [];

        let count = state.points.length,
            x = peak[0],
            data_max = state.points[0][0],
            highest_key_y = null;

        state.points.forEach((point) => {
            if (point[0] > data_max) {
                data_max = point[0];
            }
        });

        state.points.forEach((point, key) => {
            if (point[0] === x) {
                highest_key_y = key;
                return false;
            }
        });

        for (let i = 1; i < count; i++) {
            if (i !== 1) {
                let value = peak[0] * i,
                    closest = null,
                    closest_key = null;

                state.points.forEach((point, key) => {
                    if (closest === null || Math.abs(point[0] - value) < Math.abs(closest - value)) {
                        closest = point[0];
                        closest_key = key;
                    }
                });

                let sliced_arr = [];
                for (let x = closest_key - 2; x < closest_key + 3; x++) {
                    if (state.points[x]) {
                        sliced_arr[x] = state.points[x];
                    }
                }
                let highest_y = null;

                for (let k in sliced_arr) {
                    if (highest_y === null || sliced_arr[k].y > highest_y) {
                        highest_y = sliced_arr[k].y;
                        highest_key_y = k;
                    }
                }

                if (harmonicPoints.indexOf(state.points[highest_key_y]) === -1) {
                    harmonicPoints.push(state.points[highest_key_y]);
                }
            }
        }

        return harmonicPoints;
    };

    const toggleHarmonic = (peak) => {
        dispatch({
            type: ACTIONS.TOGGLE_HARMONIC,
            payload: {
                harmonicPeak: state.harmonicPeak === peak ? null : peak,
            },
        });
    };

    const csvExport = () => {
        let data = [];

        let headers = [`Frequency (${FREQUENCY_TYPES.HZ})`, `Frequency (${FREQUENCY_TYPES.CPM})`, ...(currentSpeed ? [`Frequency (${FREQUENCY_TYPES.ORDERS})`] : []), "Value"];
        state.peaks.forEach((peak) => {
            data.push([
                FrequencyConverter.fromHz(peak[0]).numberFormat(),
                FrequencyConverter.fromHz(peak[0]).toCpm().numberFormat(),
                ...(currentSpeed ? [FrequencyConverter.fromHz(peak[0], currentSpeed).toOrders().numberFormat()] : []),
                peak[1],
            ]);

            if (peak === state.harmonicPeak) {
                data.push(["", "", "", "", ""]);
                data.push([].concat.apply([], ["", ...headers, ""]));
                state.harmonicPoints.forEach((hPoint) => {
                    data.push([
                        "",
                        FrequencyConverter.fromHz(hPoint[0]).numberFormat(),
                        FrequencyConverter.fromHz(hPoint[0]).toCpm().numberFormat(),
                        ...(currentSpeed ? [FrequencyConverter.fromHz(hPoint[0], currentSpeed).toOrders().numberFormat()] : []),
                        hPoint[1],
                    ]);
                });
                data.push(["", "", "", "", ""]);
            }
        });

        const csvExporter = new ExportToCsv({
            filename: "FFT Peaks",
            showLabels: true,
            headers: headers,
        });
        csvExporter.generateCsv(data);
    };

    return (
        <Modal
            loader={state.loader}
            showModal={showModal}
            onClose={onClose}
            withoutFooter={true}
            className="fft-peaks-modal"
            size={"xl"}
            header={
                <ModalHeader
                    csvExport={csvExport}
                    selectedPoint={selectedPoint}
                    seriesList={seriesList}
                    axisLabels={axisLabels}
                    selectedSeries={state.selectedSeries}
                    openDropdownSeries={state.openDropdownSeries}
                    toggleSeriesOpen={toggleSeriesOpen}
                    changeSelectedSeries={changeSelectedSeries}
                />
            }
        >
            <div className="row">
                <div className="col-md-12">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Frequency ({FREQUENCY_TYPES.HZ})</th>
                                <th>Frequency ({FREQUENCY_TYPES.CPM})</th>
                                {!!currentSpeed && <th>Frequency ({FREQUENCY_TYPES.ORDERS})</th>}
                                <th>Value</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.peaks?.length > 0 ? (
                                state.peaks.map((peak, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td width="1%">
                                                <button
                                                    className={
                                                        "btn btn-sm btn-icon btn-circle btn-" +
                                                        (state.harmonicPeak === peak ? "warning" : "primary")
                                                    }
                                                    onClick={() => toggleHarmonic(peak)}
                                                    title={`${state.harmonicPeak === peak ? "Hide" : "Show"} Harmonic Points`}
                                                >
                                                    <i className={"fa fa-" + (state.harmonicPeak === peak ? "minus" : "plus")} />
                                                </button>
                                            </td>
                                            <td>{FrequencyConverter.fromHz(peak[0]).numberFormat()}</td>
                                            <td>{FrequencyConverter.fromHz(peak[0]).toCpm().numberFormat()}</td>
                                            {!!currentSpeed && (
                                                <td>{FrequencyConverter.fromHz(peak[0], currentSpeed).toOrders().numberFormat()}</td>
                                            )}
                                            <td>{peak[1]}</td>
                                            <th width="1%">
                                                <button
                                                    className="link link-primary"
                                                    onClick={() => showHarmonic(state.selectedSeries, peak)}
                                                >
                                                    <i className="fa fa-eye" />
                                                </button>
                                            </th>
                                        </tr>
                                        {state.harmonicPeak === peak &&
                                            !state.harmonicLoader &&
                                            state.harmonicPoints.length &&
                                            state.harmonicPoints.map((point) => (
                                                <tr
                                                    className="tr-gray"
                                                    key={point[0]}
                                                >
                                                    <td></td>
                                                    <td>{FrequencyConverter.fromHz(point[0]).numberFormat()}</td>
                                                    <td>{FrequencyConverter.fromHz(point[0]).toCpm().numberFormat()}</td>
                                                    {!!currentSpeed && (
                                                        <td>
                                                            {FrequencyConverter.fromHz(peak[0], currentSpeed).toOrders().numberFormat()}
                                                        </td>
                                                    )}
                                                    <td>{point[1]}</td>
                                                </tr>
                                            ))}
                                        {state.harmonicPeak === peak && state.harmonicLoader && (
                                            <tr>
                                                <td
                                                    align="center"
                                                    colSpan="4"
                                                >
                                                    <i>Loading...</i>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        align="center"
                                        colSpan="4"
                                    >
                                        <i>Peaks Not Found</i>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal>
    );
};

const ModalHeader = ({
    csvExport,
    selectedPoint,
    seriesList,
    axisLabels,
    openDropdownSeries,
    toggleSeriesOpen,
    selectedSeries,
    changeSelectedSeries,
}) => {
    const selectedLabels = Object.keys(seriesList.list)
        .filter((key) => seriesList.selected.indexOf(key) >= 0)
        .map((key) =>
            selectedPoint && _has(axisLabels, [key, selectedPoint]) && axisLabels[key][selectedPoint]
                ? axisLabels[key][selectedPoint]
                : seriesList.list[key].axisName + " - Axis"
        );

    return (
        <>
            {selectedLabels.length > 1 && (
                <Dropdown
                    size="sm"
                    isOpen={openDropdownSeries}
                    toggle={toggleSeriesOpen}
                >
                    <DropdownToggle
                        tag="span"
                        caret
                        className="frequency-types-link"
                    >
                        {selectedLabels[selectedSeries]}
                    </DropdownToggle>
                    <DropdownMenu>
                        {selectedLabels.map((selectedLabel, index) => (
                            <DropdownItem
                                key={index}
                                onClick={() => changeSelectedSeries(index)}
                            >
                                {selectedLabel}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            )}
            <span>FFT Peaks</span>
            <span
                onClick={csvExport}
                className="add-installation-point-btn"
            >
                <i className="fa fa-cloud" /> Download List
            </span>
        </>
    );
};

ModalHeader.propTypes = {
    selectedPoint: PropTypes.number,
    selectedSeries: PropTypes.number,
    seriesList: PropTypes.object,
    axisLabels: PropTypes.object,
    openDropdownSeries: PropTypes.bool,
    toggleSeriesOpen: PropTypes.func,
    csvExport: PropTypes.func,
    changeSelectedSeries: PropTypes.func,
};

PeaksModal.propTypes = {
    onClose: PropTypes.func,
    showModal: PropTypes.bool,
    options: PropTypes.object,
    selectedPoint: PropTypes.number,
    axisLabels: PropTypes.object,
    seriesList: PropTypes.object,
    currentSpeed: PropTypes.number,
    showHarmonic: PropTypes.func,
};

export default PeaksModal;
