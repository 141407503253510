import React, {Component} from "react";
import PropTypes from "prop-types";
import NameplateComponent from "./component";
import EquipmentApi from "../../../api/equipment";

class EquipmentForm extends Component
{
    onCreateNameplate = (data) => {
        const {equipment} = this.props;

        return EquipmentApi.createNameplate(equipment.id, data);
    }

    onDelete = (id) => {
        const {equipment} = this.props;

        return EquipmentApi.deleteNameplate(equipment.id, id);
    }

    render() {
        const {
            equipment,
            templates,
            equipmentNameplates,
            nameplatesList,
            updateNameplatesList,
            reloadEquipment,
            updateTemplates,
            parametersList,
            updateParametersList
        } = this.props;

        return (
            <React.Fragment>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <NameplateComponent
                            name={equipment.name}
                            templates={templates}
                            nameplates={equipmentNameplates}
                            nameplatesList={nameplatesList}
                            updateNameplatesList={updateNameplatesList}
                            reloadEquipment={reloadEquipment}
                            updateTemplates={updateTemplates}
                            onSave={this.onCreateNameplate}
                            onDelete={this.onDelete}
                            parametersList={parametersList}
                            updateParametersList={updateParametersList}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

EquipmentForm.propTypes = {
    equipment: PropTypes.object,
    equipmentTypes: PropTypes.array,
    updateEquipmentTypes: PropTypes.func,
    equipmentNameplates: PropTypes.array,
    nameplatesList: PropTypes.array,
    updateNameplatesList: PropTypes.func,
    reloadEquipment: PropTypes.func,
    templates: PropTypes.array,
    updateTemplates: PropTypes.func,
    parametersList: PropTypes.array,
    updateParametersList: PropTypes.func
};

export default EquipmentForm;