import Api from "./api";

const Bearing = {
    list: (params) => {
        return Api.get("bearing", {...params});
    },
    add: (installationPointId, bearing) => {
        return Api.post(`bearing/${installationPointId}/add/${bearing.id}`, bearing);
    },
    delete: (installationPointId, bearingId) => {
        return Api.post(`bearing/${installationPointId}/delete/${bearingId}`);
    },
    updatePlotLinesCount: (installationPointId, bearingId, count) => {
        return Api.post(`bearing/${installationPointId}/update-plot-lines-count/${bearingId}`, {plot_lines_count: count});
    },
};

export default Bearing;
