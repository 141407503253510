import React from "react";
import {Button} from "reactstrap";
import PropTypes from "prop-types";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useZoomReset} from "../../hooks/useZoomReset";

const FullscreenButton = () => {
    const fullScreenMode = useChartStateStoreContext((state) => state.settings.fullScreenMode);
    const {toggleFullscreen} = useChartStateStoreContext((state) => state.actions);
    const resetZoom = useZoomReset();

    const toggleFullScreen = () => {
        toggleFullscreen();
        resetZoom();
    };

    return (
        <Button
            className="btn-fa-icon btn-sm pull-right btn-default"
            onClick={toggleFullScreen}
        >
            <i className={fullScreenMode ? "fa fa-times" : "fa fa-expand-arrows-alt"} />
        </Button>
    );
};
FullscreenButton.propTypes = {
    fullScreenMode: PropTypes.bool,
    toggleFullScreen: PropTypes.func,
};

export default FullscreenButton;
