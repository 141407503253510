import React, {useMemo, useRef, useState} from "react";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import {CHART_CONFIG} from "../../../../../constants/chart";
import {cloneDeep as _cloneDeep} from "lodash";
import {setDarkModeForChart} from "../../../../../helpers/setDarkModeForChart";
import {useChartSelectedRangeStore} from "../../../../../stores/zustand/ChartSelectedRangeStore";
import {useSeriesBuilder} from "../hooks/useSeriesBuilder";
import {removeChartNavigation} from "../../../../../helpers/removeChartNavigation";
import {useAlertPlotLineBuilder} from "../hooks/useAlertPlotLineBuilder";
import {useIsDarkTheme} from "../../../../../hooks/helpers/theme";
import {useSoftValueBuilder} from "../hooks/useSoftValueBuilder";
import {useCurrentAlertGroup, useSelectedTab} from "../../../../../stores/zustand/AlertConditionStore";
import {useChartComputed} from "../../../hooks/helpers/chart";
import {useEquipmentByParams} from "../../../../../hooks/api/equipment/useEquipmentQuery";
import {useChartTypesStore} from "../../../../../stores/zustand/ChartSelectedChartTypeStore";
import {ALERT_TABS_NAME} from "../../../constants/tabs";
import {useTachometerSeriesBuilder} from "../hooks/useTachometerSeriesBuilder";
import ShowTachTriggers from "./showTachTriggers";
import {useIsTachometer} from "../../../../../pages/chart/highcharts/hooks/useChartTypes";
import {Loader} from "../../../../../shared";

const SimpleChartTemplate = ({alert, updateAlertValue, otherAlerts}) => {
    const chartRef = useRef(null);
    const [showOverMax, setShowOverMax] = useState(false);

    const isDarkTheme = useIsDarkTheme();
    const rangeSelectorDate = useChartSelectedRangeStore();
    const {equipmentItem: equipment} = useEquipmentByParams();
    const chartTypes = useChartTypesStore();
    const currentAlertGroup = useCurrentAlertGroup();
    const selectedTab = useSelectedTab();
    const {axisId, chartType} = useChartComputed(chartTypes, equipment);
    const isTachometer = useIsTachometer(chartType);

    const {series, isLoading} = useSeriesBuilder();

    const alertPlotLines = useAlertPlotLineBuilder({alert, otherAlerts, chartRef, updateAlertValue});

    const softValues = useSoftValueBuilder(alertPlotLines, chartRef, series);

    const {series: tachSeries} = useTachometerSeriesBuilder({isTachometer, showOverMax});

    const options = useMemo(() => {
        const baseChartConfig = _cloneDeep(CHART_CONFIG);
        baseChartConfig.chart.wrapZoomType = false;

        if (isDarkTheme === "true") {
            setDarkModeForChart(baseChartConfig);
        }

        baseChartConfig.series = [...series, ...tachSeries];
        baseChartConfig.yAxis[0].plotLines = [...alertPlotLines];
        baseChartConfig.xAxis.range = rangeSelectorDate.range;
        baseChartConfig.legend.enabled =
            (selectedTab == ALERT_TABS_NAME.LINEAR || selectedTab == ALERT_TABS_NAME.TACHOMETER_RPM) && axisId !== 0 ? false : true;

        if (softValues) {
            baseChartConfig.yAxis[0].softMax = softValues.softMax;
            baseChartConfig.yAxis[0].softMin = softValues.softMin;
        }
        removeChartNavigation(baseChartConfig);

        return baseChartConfig;
    }, [alert, series, rangeSelectorDate, alertPlotLines, softValues, currentAlertGroup, otherAlerts, showOverMax]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            {isTachometer && (
                <ShowTachTriggers
                    showTriggers={showOverMax}
                    toggleTachometerTriggers={() => setShowOverMax((prev) => !prev)}
                />
            )}
            <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                constructorType={"chart"}
                options={options}
                updateArgs={[true, true, false]}
            />
        </>
    );
};

SimpleChartTemplate.propTypes = {
    alert: PropTypes.object,
    updateAlertValue: PropTypes.func,
    otherAlerts: PropTypes.array,
};
export default SimpleChartTemplate;
