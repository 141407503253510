import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {useChartHoverEffect} from "../../../../hooks/keyboard/useChartHoverEffect";
import {useChartHoldEffect} from "../../../../hooks/keyboard/useChartHoldEffect";
import {useFftChartNavigationEffect} from "../../../../hooks/keyboard/useFftChartNavigationEffect";
import {useChartLegendEffect} from "../../../../hooks/keyboard/useChartLegendEffect";
import {resetZoomForGroup} from "../../../../helpers/resetZoomForGroup";

const FftChartWaterfall = ({options}) => {
    const ref = useRef(null);

    const [isZoomed, setIsZoomed] = useState(false);

    useChartHoverEffect(ref);
    useChartHoldEffect(ref);
    useFftChartNavigationEffect(ref);
    useChartLegendEffect(ref);

    useEffect(() => {
        Highcharts.addEvent(ref.current.chart, "selection", onChartSelectionEvent);
        Highcharts.addEvent(ref.current.chart.yAxis[0], "setExtremes", onSetExtremes);

        return () => {
            Highcharts.removeEvent(ref.current.chart, "selection", onChartSelectionEvent);
            Highcharts.removeEvent(ref.current.chart.yAxis[0], "setExtremes", onSetExtremes);
        };
    }, []);

    const onSetExtremes = (e) => {
        if (e.trigger === "resetZoom") {
            return setIsZoomed(false);
        }
    };

    const onChartSelectionEvent = (e) => setIsZoomed(!e.resetSelection);

    const handleResetZoom = () => {
        setIsZoomed(false);

        if (ref.current.chart.userOptions?.chart?.syncZoomGroup) {
            resetZoomForGroup(ref.current.chart.userOptions?.chart?.syncZoomGroup, "xy");
        }

        ref.current.chart.xAxis[0].zoom();
        ref.current.chart.yAxis[0].zoom();
    };

    return (
        <div className={"chart-block-fft-waterfall"}>
            {isZoomed && (
                <button
                    className="highcharts-reset-zoom-custom highcharts-reset-zoom-custom_absolute"
                    onClick={handleResetZoom}
                >
                    Reset Zoom
                </button>
            )}
            <HighchartsReact
                ref={ref}
                highcharts={Highcharts}
                containerProps={{className: "fft-chart"}}
                constructorType={"chart"}
                updateArgs={[true, true, false]}
                options={options}
            />
        </div>
    );
};

FftChartWaterfall.propTypes = {
    options: PropTypes.object,
};

export default FftChartWaterfall;
