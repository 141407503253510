import React, {Component} from "react";
import {Loader, ValidationError} from "../../../shared";
import SearchInput from "../../shared/search-input";
import PropTypes from "prop-types";
import {
    get as _get,
    set as _set,
    includes as _includes
} from "lodash";
import {withGlobalStore} from "../../../stores/GlobalStore";
import NameplateParameterApi from "../../../api/nameplateParameter";
import Toast from "../../shared/toast";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import { HeaderSimple } from "../../../shared/header";

class NameplateParameterForm extends Component
{
    constructor(props) {
        super(props);

        this.parameterId = this.props.match.params.parameterId;

        this.state = {
            title: this.parameterId ? "Edit Parameter" : "Add Parameter",
            data: {
                name: "",
            },
            breadcrumbs: [
                {name: "Nameplates", link: "/nameplates"},
                {name: "Manage Parameters", link: "/nameplates/parameters"},
                {name: this.parameterId ? "Edit Parameter" : "Add Parameter"}
            ],
            loader: true,
            formErrors: {},
            globalSearchString: "",
            inProgress: false,
            addNew: false
        };
    }

    componentDidMount() {
        Promise
            .all([
                this.fetchParameter()
            ])
            .then(() => this.setState({loader: false}));
    }

    fetchParameter = () => {
        if (this.parameterId) {
            return NameplateParameterApi
                .view(this.parameterId)
                .then(response => {
                    if (response) {
                        const {parameter} = response;
                        this.setState({data: parameter || {}});
                    }
                });
        }
    }

    onAddNew = () => {
        this.setState({addNew: true});
    }

    onCancelAddNew = () => {
        this.setState({addNew: false});
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    onChange = (event) => {
        const data = {...this.state.data};

        const key = _get(event, "target.name", "");
        const val = _get(event, "target.value", "");

        _set(data, key, val);

        this.setState({data});
    }

    handleSubmit = () => {
        return this.parameterId ? this.onUpdate() : this.onCreate();
    }

    onCreate = () => {
        const {data} = this.state;

        this.setState({formErrors: {}, inProgress: true}, () => {
            NameplateParameterApi
                .create(data)
                .then(() => {
                    Toast.success("The parameter has been created.");
                    this.props.history.push("/nameplates/parameters");
                })
                .catch(response => {
                    this.setState({formErrors: response.errors || {}, inProgress: false});
                });
        });
    }

    onUpdate = () => {
        const {data} = this.state;

        this.setState({formErrors: {}, inProgress: true}, () => {
            NameplateParameterApi
                .update(this.parameterId, data)
                .then(() => {
                    Toast.success("The parameter has been updated.");
                    this.setState({inProgress: false}, () => this.props.history.push("/nameplates/parameters"));
                })
                .catch(response => {
                    this.setState({formErrors: response.errors || {}, inProgress: false});
                });
        });
    }

    onChangeParameter = (e) => {
        const parameterIds = [...this.state.data.parameterIds];

        const id = ""+_get(e, "target.value");
        if (id && !_includes(parameterIds, id)) {
            parameterIds.push(id);
        }

        this.setState({addNew: false, data: {...this.state.data, ...{parameterIds}}});
    }

    deleteParameter = (id) => {
        const parameterIds = [...this.state.data.parameterIds];

        const index = parameterIds.indexOf(id);
        if (index !== -1) {
            parameterIds.splice(index, 1);
        }

        this.setState({addNew: false, data: {...this.state.data, ...{parameterIds}}});
    }

    render() {
        const {
            data,
            breadcrumbs,
            loader,
            globalSearchString,
            title,
            addNew,
            formErrors,
            inProgress
        } = this.state;

        return (
            <React.Fragment>
                 <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />

                <div className="subheader">
                    <div className="subheader-title">{title}</div>
                </div>
                {loader
                    ?
                    <div className="loader-fullscreen"><Loader/></div>
                    :
                    <div className="block">
                        <div className="block-body nameplate-form-wrapper">
                            <div className={"nameplate-component-block"}>
                                <React.Fragment>
                                    <div className={"row mt-2"}>
                                        <div className={"col-md-6 mb-2"}>
                                            <strong>Name</strong>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <div className={"form-group mb-0"}>
                                                <input
                                                    className={"form-control" + (_get(formErrors, "name") ? " is-invalid" : "")}
                                                    name={"name"}
                                                    value={_get(data, "name")}
                                                    onChange={this.onChange}
                                                />
                                                <ValidationError message={_get(formErrors, "name")}/>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            </div>
                            <div className="text-right">
                                <Link to="/nameplates/parameters" className="btn btn-sm btn-secondary">Cancel</Link>
                                <Button color="primary" size="sm" className="ml-2" disabled={inProgress || addNew} onClick={this.handleSubmit}>Save</Button>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item ml-2">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

NameplateParameterForm.propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
};

export default withGlobalStore(NameplateParameterForm);