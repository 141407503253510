import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiAlertCondition from "../../../api/alertCondition";
import { ALERT_CONDITIONS_API } from "./constants";

export const useAlertConditionUpdateMutation = (chartType) => {
    const queryClient = useQueryClient();

    return useMutation(
        (data) => {
            return ApiAlertCondition.update(data);
        },
        {
            onSuccess: () => {
                    queryClient.refetchQueries({ queryKey: [ALERT_CONDITIONS_API.LIST, chartType] });
            },
        }
    );
};