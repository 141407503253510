import {useMemo} from "react";
import {useSignalQualityQuery} from "../../../../hooks/api/signal/useSignalQualityQuery";

export const useSignalQualitySeries = (serial, type, timeRange, dateRange) => {
    const {data: response, isSuccess} = useSignalQualityQuery(serial, type, timeRange, dateRange);

    return useMemo(() => {
        if (!isSuccess) {
            return [];
        }

        return [
            {
                name: "Signal Quality",
                data: response.list,
            },
        ];
    }, [isSuccess, response]);
};
