import {CHART_TYPE_ACCELERATION_IMPACT_VUE_ID, IDLE_THRESHOLD_CHART_TYPES} from "../../../../../../constants/constants";
import { useChartStateStoreContext } from "../../store/hooks/useStoreContext";
import { shallow } from "zustand/shallow";
import { find as _find , get as _get } from "lodash";
import { useSelected } from "../helpers/useSelected";
import { useMemo } from "react";
import { useEquipmentByParams } from "../../../../../../hooks/api/equipment/useEquipmentQuery";

export const useIdleThresholdBuilder = () => {
    const chartType = useChartStateStoreContext((state) => state.settings.chartType, shallow);
    const selectedInstallationPoints = useSelected();
    const {equipmentItem, isRefetching} = useEquipmentByParams();
    const getIdleThresholdPlotLines = () => {
        const existsThresholdChartType = Boolean(Object.values(IDLE_THRESHOLD_CHART_TYPES).find((chartTypesArray) => chartTypesArray.includes(+chartType)));
        const plotLines = [];
        if (!existsThresholdChartType) {
            return plotLines;
        }

        selectedInstallationPoints.map((installationPointId) => {
            const installationPoint = _find(equipmentItem.installationPoints, {id: installationPointId});
            if (!installationPoint) {
                return;
            }

            const idleThreshold = (+chartType === CHART_TYPE_ACCELERATION_IMPACT_VUE_ID) ? installationPoint.impact_idle_threshold : installationPoint.idle_threshold;
            const idleThresholdType = (+chartType === CHART_TYPE_ACCELERATION_IMPACT_VUE_ID) ? installationPoint.impact_idle_threshold_type : installationPoint.idle_threshold_type;

            if (!_get(IDLE_THRESHOLD_CHART_TYPES, idleThresholdType, []).includes(+chartType)) {
                return;
            }
            const style = `background: ${installationPoint.color}; height: 10px; width: 10px; margin-top: 10px;`;
            const text =
                `<span class="badge badge-square alert-badge-${installationPoint.id}" data-alert-id="${installationPoint.id}" ` +
                `data-installation-point-id="${installationPoint.id}" ` +
                `style="${style}" title="${
                    installationPoint.name.replace(/"/g, "") + " - " + "Idle Threshold: " + idleThreshold
                }"></span> `;

            plotLines.push({
                color: installationPoint.color,
                width: 2,
                label: {
                    align: "right",
                    useHTML: true,
                    text,
                    x: 10,
                },

                dashStyle: "longdash",
                value: idleThreshold,
            });
        });

        return plotLines;
    };

    return useMemo(() => getIdleThresholdPlotLines(), [selectedInstallationPoints, isRefetching]);
};
