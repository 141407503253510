import React from "react";

import "../../assets/scss/components/error-page/error.scss";
import PropTypes from "prop-types";

NotAzureAccess.propTypes = {
    auth: PropTypes.object,
};

export default function NotAzureAccess({auth}) {
    return (
        <div className="app-item">
            <div className="app-content">
                <div className="main">
                    <div className="error-page">
                        <div className="error-page-content mr-3 d-flex flex-column justify-content-center">
                            <div className="error-page-title d-flex justify-content-center">401</div>
                            <div className="error-page-desc d-flex justify-content-center text-center">
                                You have selected a customer that utilizes Azure storage. <br/>
                                Actions on this customer are not possible from the Self-Service Dashboard.
                            </div>
                            <div className="error-page-buttons">
                                <div className="d-flex buttons__block justify-content-center">
                                    <span><a href="#" onClick={() => auth.customerLogout()} className="btn v2-btn blue blue-border mt-5">Switch Customer</a></span>
                                </div>

                                <div className="d-flex buttons__block justify-content-center">
                                    <span><a href="#" onClick={() => auth.logout()} className="btn v2-btn blue blue-border mt-5">Sign Out</a></span>
                                </div>
                            </div>
                        </div>
                        <div className="error-page-image">
                            <img src="/assets/media/misc/404-bg1.jpg" className="error-page-image-content" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
