import {useMemo} from "react";
import {useChartSelectedEquipmentStore} from "../../../../../../stores/zustand/ChartSelectedEquipmentStore";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import useAxisLabelQuery from "../../../../../../hooks/api/axisLabel/useAxisLabelQuery";
import {sortedUniq as _sortedUniq, pick as _pick} from "lodash";

export const useAxisLabels = () => {
    const equipmentSelected = useChartSelectedEquipmentStore();
    const {equipmentItem} = useEquipmentByParams();
    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList, shallow);
    const {axisLabelsChart} = useAxisLabelQuery(equipmentItem.id);
    const selectedInstallationPoints = equipmentSelected[equipmentItem?.id];

    return useMemo(() => {
        if (!axisLabelsChart) {
            return {};
        }

        return Object.entries(seriesList.list).reduce((axisLabels, [key, value]) => {
            if (!axisLabelsChart[key]) {
                axisLabels[key] = value.axisName + " - Axis";
                return axisLabels;
            }

            const labelsByInstallationPoints = _pick(axisLabelsChart[key], selectedInstallationPoints);

            let labelsByAxis = Object.values(labelsByInstallationPoints);

            if (labelsByAxis.length !== selectedInstallationPoints.length && selectedInstallationPoints.length) {
                labelsByAxis = [...labelsByAxis, value.axisName + " - Axis"];
            }

            axisLabels[key] = _sortedUniq(labelsByAxis).join(", ");
            return axisLabels;
        }, {});
    }, [axisLabelsChart, selectedInstallationPoints, seriesList.list]);
};
