import {useMemo} from "react";
import ChartHelper from "../../../../../helpers/chart";
import {getFftBearings, getTwfBearings} from "../helpers/plotLines";
import {ContextMenuFFTContent} from "../components/contextMenu/fftMenu";
import {ContextMenuTWFContent} from "../components/contextMenu/twfMenu";

export const CHART_TYPE = {
    FFT: "FFT",
    FFT_IMPACT: "FFTImpactVue",
    TWF: "TWF",
    TWF_IMPACT: "TWFImpactVue",
};

export const CHART_MODE_LIST_FFT_COMMON = {
    WATERFALL: "Waterfall Plot",
    STACKED_WATERFALL: "Stacked Waterfall Plot",
};

export const CHART_MODE_LIST_TWF_COMMON = {
    AUTOCORRELATION: "Autocorrelation",
};

export const useChartAbstractFactory = (props) => {
    return useMemo(() => {
        const {chartTypes, chartType} = props;
        const isImpactVueFFT = +props.chartType === 21 || +props.chartType === 22;
        const isImpactVueTWF = +props.chartType === 19 || +props.chartType === 20;

        // FFT
        if (chartTypes[+chartType].type === "fft") {
            return {
                getBearingFn: getFftBearings,
                chartTooltipFormatter: ChartHelper.formatTooltipFFT,
                chartTypeName: isImpactVueFFT ? CHART_TYPE.FFT_IMPACT : CHART_TYPE.FFT,
                xAxisLabelFormatter: ChartHelper.formatXAxisFFT,
                tickPositioner: ChartHelper.fftTicksPositioner,
                chartModeList: {
                    FFT: chartTypes[chartType].short,
                    ...CHART_MODE_LIST_FFT_COMMON,
                },
                ChartContextMenu: ContextMenuFFTContent,
            };
        }

        // TWF
        return {
            getBearingFn: getTwfBearings,
            chartTooltipFormatter: ChartHelper.formatTooltipTWF,
            chartTypeName: isImpactVueTWF ? CHART_TYPE.TWF_IMPACT : CHART_TYPE.TWF,
            chartModeList: {
                TWF: chartTypes[chartType].short,
                ...CHART_MODE_LIST_TWF_COMMON,
            },
            ChartContextMenu: ContextMenuTWFContent,
        };
    }, [props.chartType]);
};
