import {usePacketLossQuery} from "../../../hooks/api/packetLoss/usePacketLossQuery";
import {useMemo} from "react";

export const usePacketLossSeries = (serial, type, timeRange, dateRange) => {
    const {data: response, isSuccess} = usePacketLossQuery(serial, type, timeRange, dateRange);

    return useMemo(() => {
        if (!isSuccess) {
            return [];
        }

        return [
            {
                name: "Packet Loss",
                data: response.list,
            },
        ];
    }, [isSuccess, response]);
};
