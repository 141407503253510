import {useQuery} from "@tanstack/react-query";
import EquipmentApi from "../../../api/equipment";

const getCustomChartTypesKeys = ({equipmentId, chartTypes}) => ["CustomChartTypesQuery", {equipmentId, chartTypes}];

const useCustomChartTypes = (equipmentId, chartTypes) => {
    const {
        isSuccess,
        data: customChartTypes,
        isLoading,
    } = useQuery({
        queryKey: getCustomChartTypesKeys({equipmentId, chartTypes}),
        queryFn: () => EquipmentApi.customChartTypes(+equipmentId, (response) => response.data),
        enabled: !!equipmentId && !!chartTypes,
    });

    let allChartTypes = {};

    if (isSuccess) {
        allChartTypes = {...chartTypes, ...customChartTypes};
    }

    return {
        isSuccess,
        customChartTypes,
        allChartTypes,
        isLoading,
    };
};

export default useCustomChartTypes;
