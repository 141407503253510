export const ACTION = {
    SET_STATE: "setState",
    DELETE_LOCATION_ID: "deleteLocationID",
    SET_TREE_DATA: "setTreeData",
    SET_DRAGGED_NODE: "setDraggedNode",
    SET_SEARCH_PARAMS: "setSearchParams",
};

export const reducer = (reducerState, action) => {
    const { state, type, payload, callback = () => {} } = action;

    callback();
    switch (type) {
        case ACTION.SET_STATE:
            return { ...reducerState, ...state };
        case ACTION.DELETE_LOCATION_ID:
            return { ...reducerState, deleteLocationId: payload };
        case ACTION.SET_TREE_DATA:
            return { ...reducerState, treeData: payload };
        case ACTION.SET_DRAGGED_NODE:
            return { ...reducerState, draggedNode: payload };
        case ACTION.SET_SEARCH_PARAMS:
            return { ...reducerState, searchParams: payload };
        default:
            throw new Error("Untyped dispatch call");
    }
};
