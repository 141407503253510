import moment from "moment";
import {forEach as _forEach, get as _get, has as _has} from "lodash";
import {useFFTTimestampsQuery} from "../../../../../../hooks/api/fftReadings/TimestampsQuery";
import {useMemo} from "react";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {MTIME_TWO_MONTH, useChartSelectedRangeStore} from "../../../../../../stores/zustand/ChartSelectedRangeStore";
import {useLocateNearestFFT} from "../helpers/useLocateNearestFFT";
import {useIsShowFFTAcquisitionsTime} from "../../../store/sync/fftAcquisitionsTimeStore";
import Helper from "../../../../../../helpers/helper";
import {useIsImpactVue} from "../../../hooks/useChartTypes";

export const useFftAcquisitionsBuilder = (series, equipment) => {
    const {data: fftTimestampsData, isSuccess} = useFFTTimestampsQuery({
        equipmentId: equipment.id,
        unixFrom: moment().subtract(2, "month").unix(),
        unixTo: moment().unix(),
    });
    const {chartType} = useChartStateStoreContext((state) => state.settings);
    const rangeSelector = useChartSelectedRangeStore();
    const locateNearestFFT = useLocateNearestFFT();
    const isImpactVue = useIsImpactVue(chartType);

    const isShowFFTAcquisitionsTime = useIsShowFFTAcquisitionsTime();

    return useMemo(() => {
        if (!isShowFFTAcquisitionsTime || !isSuccess) {
            return [];
        }

        if (rangeSelector.range > MTIME_TWO_MONTH) {
            return [];
        }

        const {fftTimestamps, fftTimestampsImpactVue} = fftTimestampsData;
        const timestamps = isImpactVue ? fftTimestampsImpactVue : fftTimestamps;
        let anon = [];

        _forEach(series, (item) => {
            const fftTimestampsPath = `${item.installationPointId}.1`;
            const fftTimestampsInRange = Object.keys(_get(timestamps, fftTimestampsPath, [])).filter((val) => {
                return moment.utc(val).format("x") >= Helper.unixToMilliseconds(rangeSelector.from);
            });

            if (item.data === undefined || !item.data.length || !fftTimestampsInRange.length || !_has(timestamps, item.installationPointId)) {
                return;
            }

            _forEach(fftTimestampsInRange, (dateKey) => {
                Object.keys(_get(timestamps, fftTimestampsPath + "." + dateKey, [])).forEach((time) => {
                    const itemsUnit = dateKey + " " + time;
                    anon.push({
                        draggable: "",
                        events: {
                            click: () => {
                                locateNearestFFT({installationPointId: item.installationPointId, timestamp: moment.utc(itemsUnit).unix(), isImpactVue});
                            },
                        },
                        labels: {
                            allowOverlap: true,
                            shape: "rect",
                            text: " ",
                            className: "cursor-pointer cube",
                            backgroundColor: item.color || "#000000",
                            borderColor: item.color,
                            points: [
                                {
                                    x: +moment.utc(itemsUnit).format("x"),
                                    y: 0,
                                    xAxis: 0,
                                    yAxis: 0,
                                },
                            ],
                        },
                    });
                });
            });
        });
        return anon;
    }, [series, fftTimestampsData, isShowFFTAcquisitionsTime, rangeSelector]);
};
