import { useDrag } from "react-dnd";
import PropTypes from "prop-types";
import React from "react";
import { get as _get } from "lodash";
import "../../../../assets/scss/components/modals/equipments/equipments.scss";

const SensorLocationBlock = ({setPosition, positions, pointIndex, imageIndex, name, color, column, onChangeCheckbox, checkedCheckbox, imageRef}) => {
    column = column || "right";

    const [{isDragging}, drag] = useDrag({
        item: {name, type: "SensorLocationBlock"},
        end: (dropResult , monitor)=> {
            if (!dropResult) {
                return;
            }
            const boundary = imageRef.current.getBoundingClientRect();
            const dropMonitor = monitor.getDropResult();

            if (dropMonitor) {
                let percent_left = (dropMonitor.monitor.x - boundary.left) * 100 / boundary.width;
                let percent_top = (dropMonitor.monitor.y - boundary.top) * 100 / boundary.height;
                if (percent_left < 0) percent_left = 0;
                if (percent_left > 100) percent_left = 100;
                if (percent_top < 0) percent_top = 0;
                if (percent_top > 100) percent_top = 100;
                let top = Math.round(boundary.height * percent_top / 100);
                let left = Math.round(boundary.width * percent_left / 100);
                setPosition({
                    imageIndex,
                    pointIndex,
                    color,
                    percent_top,
                    percent_left,
                    top,
                    left
                });
            } else {
                setPosition({
                    imageIndex,
                    pointIndex,
                    clear: true
                });
            }

        },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
    });

    const opacity = isDragging ? 0.4 : 1;

    if (column == "right") {
        return (
            <div className="sensors-location d-flex align-items-center" ref={drag} style={{opacity}}>
                {pointIndex !== 999 &&
                    <label className="form-checkbox" title={"Assign pictures at sensor level"}>
                        <input
                            type="checkbox"
                            onChange={onChangeCheckbox}
                            checked={checkedCheckbox}
                        />
                        <span/>
                    </label>
                }
                <span className="index-icon-block" style={{backgroundColor: color}}>
                    {pointIndex === 999 ? <i className="fa fa-home" style={{margin: 0, fontSize: 12}}/> : +pointIndex + 1}
                </span>
                <span className="point-name"><span>{name}</span></span>
            </div>
        );
    }

    return (
        <span ref={drag} title={name} style={{position: "absolute", left: _get(positions, `${imageIndex}.${pointIndex}.percent_left`, 0) + "%",top: _get(positions, `${imageIndex}.${pointIndex}.percent_top`, 0) + "%"}} className="img-tooltip-wrapper">
            <span className="img-tooltip" style={{backgroundColor: color}}>
                <span className="img-text-number">
                    {pointIndex === 999 ? <i className="fa fa-home" style={{margin: 0, fontSize: 12}}/> : +pointIndex + 1}
                </span>
            </span>
            <span className="img-tooltip-arrow" style={{borderColor: color + " transparent transparent transparent"}}/>
        </span>
    );
};
SensorLocationBlock.propTypes = {
    name: PropTypes.string,
    imageIndex: PropTypes.number,
    pointIndex: PropTypes.number,
    color: PropTypes.string,
    positions: PropTypes.object,
    flipTurns: PropTypes.object,
    setPosition: PropTypes.func,
    column: PropTypes.any,
    onChangeCheckbox: PropTypes.func,
    checkedCheckbox: PropTypes.bool,
    imageRef:PropTypes.object
};

export default SensorLocationBlock;