import React, {useRef} from "react";
import {UncontrolledTooltip} from "reactstrap";

export const WosLabel = () => {
    const targetRef = useRef(null);

    return (
        <>
            <div
                className="badge badge-warning cursor-pointer mr-1"
                ref={targetRef}
            >
                WoS <i className={"fas fa-hourglass-half"} />
            </div>
            <UncontrolledTooltip
                target={targetRef}
                placement="top"
            >
                Wake-on-Shake is enabled for this sensor, which will determine when vibration readings are collected.
            </UncontrolledTooltip>
        </>
    );
};
