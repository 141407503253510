import Moment from "moment";
import {
    DATEFORMAT,
    IDLE_THRESHOLD_TYPES,
    MONTH_LEADING_DATEFORMAT,
    readingIntervals,
    SHORT_DATEFORMAT,
    SIGNAL_LABEL_DANGER,
    SIGNAL_LABEL_SUCCESS,
    SIGNAL_LABEL_WARNING,
} from "../constants/constants";
import {
    orderBy as _orderBy,
    get as _get,
    find as _find,
    size as _size,
    isObject as _isObject,
    isEmpty as _isEmpty,
    reduce as _reduce,
    camelCase as _camelCase,
    replace as _replace,
    isUndefined,
} from "lodash";
import Highcharts from "highcharts";
import moment from "moment";
import cookies from "react-cookies";

class Helper {
    static isCheckbox(element) {
        return element.tagName.toLowerCase() === "input" && element.type.toLowerCase() === "checkbox";
    }

    static isSelectMultiple(element) {
        return element.tagName.toLowerCase() === "select" && element.multiple;
    }

    static getInputValue(element) {
        if (Helper.isCheckbox(element)) {
            return element.checked ? "1" : "0";
        }
        if (Helper.isSelectMultiple(element)) {
            let value = [];
            for (let i = 0; i < element.options.length; i++) {
                if (element.options[i].selected) {
                    value.push(element.options[i].value);
                }
            }
            return value;
        }

        return element.value;
    }

    static getHashParams() {
        let params = {};

        for (let [key, value] of new URLSearchParams(window.location.hash.slice(1)).entries()) {
            if (key.match(/\[]/)) {
                let tmpKey = key.replace(/\[]/, "");
                if (!Array.isArray(params[tmpKey])) {
                    params[tmpKey] = [];
                }
                params[tmpKey].push(value);
            } else {
                params[key] = value;
            }
        }

        return params;
    }

    static deleteHashParams(params = []) {
        const hashParams = new URLSearchParams(window.location.hash.slice(1));

        params.forEach((param) => {
            hashParams.delete(param);
        });

        return window.location.pathname + (hashParams.toString() ? `#${hashParams.toString()}` : "");
    }

    static setHashParams(params = {}) {
        const hashParams = new URLSearchParams(window.location.hash.slice(1));

        Object.keys(params).forEach((name) => {
            params[name] ? hashParams.set(name, params[name]) : hashParams.delete(name);
        });

        return window.location.pathname + (hashParams.toString() ? `#${hashParams.toString()}` : "");
    }

    static highlight(html, search) {
        if (search === null || typeof search === "undefined" || search === false || search === "") {
            return html;
        }

        if (typeof html == "number") {
            html = html.toString();
        }

        if (_isEmpty(html)) {
            return "";
        }

        search.split(" ").map((word) => {
            if (word !== "") {
                html = html.replace(new RegExp(Helper.escapeRegExp(word), "gi"), (math) => `{{{mark}}}${math}{{{/mark}}}`);
            }
        });

        return html.replace(new RegExp("{{{mark}}}", "gi"), "<mark>").replace(new RegExp("{{{/mark}}}", "gi"), "</mark>");
    }

    static escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    static momentFromNow(date, utc = true) {
        return !!date && (utc ? Moment.utc(date).fromNow() : Moment(date).fromNow());
    }

    static dateToUserFormat(date, user) {
        let format = DATEFORMAT[_get(user, "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0])];

        if (date === "") {
            return "";
        }

        if (typeof date === "number") {
            return Moment.utc(date).format(format);
        } else {
            return Moment(date).format(format);
        }
    }

    static getUserDateFormat(user, explodeData = false) {
        let format = DATEFORMAT[_get(user, "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0])];
        if (explodeData) {
            const splitData = format.split(" ");

            return {
                date: _get(splitData, "0"),
                time: _get(splitData, "1"),
            };
        }
        return format;
    }

    static getUserShortDateFormat(user) {
        const userDateFormat = _get(user, "personal_settings.datetime_format", MONTH_LEADING_DATEFORMAT);
        return SHORT_DATEFORMAT[userDateFormat];
    }

    static getDurationTimestamp(timestamp, userTimezone = 0) {
        const timestampWithTimezone =
            userTimezone > 0 ? moment.utc(timestamp).subtract(userTimezone, "hours") : moment.utc(timestamp).add(-userTimezone, "hours");

        const timestampsMoment = moment.duration(moment().diff(timestampWithTimezone));
        const daysTimestamp = timestampsMoment.asDays();
        const days = daysTimestamp > 0 ? Math.floor(daysTimestamp) : Math.ceil(daysTimestamp);
        const hours = Math.ceil(timestampsMoment.asHours()) - days * 24;

        return `-${days}d ${hours}h`;
    }

    static explodeDate(date = "") {
        const splitData = date.split(" ");
        return {
            date: _get(splitData, "0"),
            time: _get(splitData, "1"),
        };
    }

    static dateToFormat(date, serverFormat) {
        let format = DATEFORMAT[serverFormat];

        if (typeof date === "number") {
            return Moment.utc(date).format(format);
        } else {
            return Moment(date).format(format);
        }
    }

    static daysBetween(date1, date2) {
        const ONEDAY = 1000 * 60 * 60 * 24;
        // Convert both dates to milliseconds
        let date1_ms = date1.getTime();
        let date2_ms = date2.getTime();
        // Calculate the difference in milliseconds
        let difference_ms = Math.abs(date1_ms - date2_ms);

        // Convert back to days and return
        return Math.ceil(difference_ms / ONEDAY);
    }

    static readingIntervalName(id, intervalTime = null) {
        let name = "";

        readingIntervals.forEach((item) => {
            if (item.id === id) {
                switch (item.name) {
                    case "Seconds":
                        return intervalTime === 1 ? (name = "Second") : (name = item.name);
                    case "Minutes":
                        return intervalTime === 1 ? (name = "Minute") : (name = item.name);
                    case "Hours":
                        return intervalTime === 1 ? (name = "Hour") : (name = item.name);
                    case "Days":
                        return intervalTime === 1 ? (name = "Day") : (name = item.name);
                    case "Weeks":
                        return intervalTime === 1 ? (name = "Week") : (name = item.name);
                    default:
                        return (name = item.name);
                }
            }
        });

        return name;
    }

    static objKeysToCamelCase(obj, withOutEmptyValue = false, recursive = false) {
        if (_isObject(obj)) {
            return _reduce(
                obj,
                (result, val, key) => {
                    if (withOutEmptyValue && (!val || (_isObject(val) && _isEmpty(val)))) {
                        return result;
                    }
                    result[_camelCase(key)] = recursive && _isObject(val) && Object.keys(val).length ? Helper.objKeysToCamelCase(val) : val;
                    return result;
                },
                {}
            );
        }
        return {};
    }

    static setPerPage(key, value = 10) {
        let limitStore = this.getStorageItem("limitSelectValue", {});
        this.setStorageItem("limitSelectValue", {...limitStore, [key]: value});
    }

    static getPerPage(key, defaultValue = 10) {
        return +_get(this.getStorageItem("limitSelectValue", {}), key) ? +_get(this.getStorageItem("limitSelectValue", {}), key) : defaultValue;
    }

    static setStorageItem(key, value = "") {
        if (typeof value === "object") {
            value = JSON.stringify(value);
        }
        localStorage.setItem(key, value);
    }

    static getStorageItem(key, defaultValue = null) {
        let value = localStorage.getItem(key);
        if (key === "limitSelectValue") {
            value = JSON.parse(value);
        }
        return value || defaultValue;
    }

    static numberFormat(num, precision = 2) {
        num = Math.round(num * 10 ** precision) / 10 ** precision; // fix floats of type  1.23123e-12
        return Highcharts.numberFormat(num, precision, ".", "");
    }

    static getBatteryColor(batteryStats = {}, type = "node") {
        const {battery1 = {}, battery2 = {}} = batteryStats;
        const normalChargeLevel = type === "mote" ? 2.8 : 3.3;

        if (!(battery1.latest && battery2.latest)) return "";
        if (battery1.latest > normalChargeLevel && battery2.latest > normalChargeLevel) return "success";
        if (battery1.latest > normalChargeLevel || battery2.latest > normalChargeLevel) return "warning";

        return "danger";
    }

    static getNodeBatteryColor(batteryValue, type = "node") {
        const normalChargeLevel = type === "mote" ? 2.8 : 3.3;
        return batteryValue ? (batteryValue > normalChargeLevel ? "success" : "warning") : "";
    }

    static getBatterySetting(powerType, batteryValue = 0) {
        if (powerType === "power_120_vac") {
            return {
                title: "120 VAC Line Powered",
                iconClass: "fa fa-plug",
                color: "success",
            };
        }
        if (powerType === "power_12_32_vdc") {
            return {
                title: "12-32 VDC Line Powered",
                iconClass: "fa fa-plug",
                color: "success",
            };
        }

        const color = this.getNodeBatteryColor(batteryValue);
        return {
            title: "Battery Powered",
            iconClass: color === "success" ? "fa fa-battery-full" : "fa fa-battery-half",
            color: color,
        };
    }

    static getSignalLabel(color) {
        if (color === SIGNAL_LABEL_SUCCESS) return "Excellent Connectivity";
        if (color === SIGNAL_LABEL_WARNING) return "Good Connectivity";
        if (color === SIGNAL_LABEL_DANGER) return "Poor Connectivity";
        return "No data exists";
    }

    static capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static findCommence(range) {
        if (range === null) {
            return null;
        }

        return moment()
            .utc()
            .subtract(range / 1000, "seconds")
            .unix();
    }

    static getIdleThresholdMeasure(chartTypes, idleThresholdType, defaultValue = "") {
        switch (idleThresholdType) {
            case IDLE_THRESHOLD_TYPES.ACC:
                return Helper.getUserMeasure(chartTypes, 2);
            case IDLE_THRESHOLD_TYPES.VEL:
                return Helper.getUserMeasure(chartTypes, 3);
            default:
                return defaultValue;
        }
    }

    static getUserMeasure(chartTypes, chartType) {
        return Object.values(chartTypes[chartType].series)[0].units;
    }

    static getDisplayName(Component) {
        return Component.displayName || Component.name || "Component";
    }

    static openHelpWindow(type = "all") {
        let params = "";

        if (type === "all") params = "#simple=1&fft=1&waterfall=1";
        if (type === "simple") params = "#simple=1";
        if (type === "fft") params = "#fft=1";
        if (type === "waterfall") params = "#waterfall=1";

        const width = 580;
        const height = 500;
        const top = screen.height - height;
        const left = 0;

        window.open("/help" + params, "helpWindow", `width=${width},height=${height},left=${left},top=${top}`);
    }

    static chunkArray(array, chunkSize) {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    }

    static transformLocations = (list) => {
        let locations = [];

        const buildLocationsTree = (structure, offset = "", parent_id = null, parent_name = "") => {
            structure.map((location) => {
                let locationName = "";
                if (location.level_name !== "customer") {
                    locationName = parent_name ? parent_name + " / " + location.name : location.name;
                }
                if (location.level_name !== "customer") {
                    locations.push({
                        id: location.id,
                        level_name: location.level_name,
                        name: (location.level_name === "facility" ? "" : String(offset)) + String(location.name),
                        parent_id: parent_id,
                        full_name: locationName,
                        facility_id: location.facility_id,
                    });
                }
                if (_size(location.children)) {
                    const locationList = !isUndefined(cookies.load("auto-sort-asset-tree"))
                        ? _orderBy(location.children, [(item) => _get(item, "name").toLowerCase()], ["asc"])
                        : location.children;
                    buildLocationsTree(locationList, offset + "--", location.id, locationName);
                }
            });
        };

        buildLocationsTree(list);
        return locations;
    };

    static sortLocationList = (locationList, fieldSort = "name") => {
        locationList = _orderBy(locationList, [(location) => _get(location, fieldSort).toLowerCase()], ["asc"]);

        const buildingSortedList = (list) => {
            list = _orderBy(list, [(item) => _get(item, fieldSort).toLowerCase()], ["asc"]);
            list.map((location, key) => {
                if (_size(location.children)) {
                    list[key].children = _orderBy(location.children, [(childLocations) => _get(childLocations, fieldSort).toLowerCase()], ["asc"]);
                    buildingSortedList(location.children);
                }
            });

            return list;
        };

        locationList.map((location, key) => {
            const locationChild = _get(location, "children");
            if (_size(locationChild)) {
                locationList[key].children = buildingSortedList(locationChild);
            }
        });

        return locationList;
    };

    static findLocationFullNameById = (assetTree, id, defaultName) => {
        const location = _find(assetTree, {id: +id});
        return _get(location, "full_name", defaultName);
    };

    static getAxisName = (axisId) => {
        let axisName = "x";

        switch (+axisId) {
            case 2:
                axisName = "y";
                break;
            case 3:
                axisName = "z";
                break;
        }

        return axisName;
    };

    static hasOnlyDefaultImage = (images) => {
        if (images.length === 1) {
            const image = _get(images, 0);

            if (!image || typeof image === "string") {
                return false;
            }

            return "is_default" in image;
        }

        return false;
    };

    static removeSubstrings(str, substrings) {
        for (let substring of substrings) {
            str = _replace(str, substring, "");
        }
        return str;
    }

    static unixToMilliseconds = (unixTime) => {
        return unixTime * 1000;
    }
}

export default Helper;
