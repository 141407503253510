import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get,
    find as _find,
    concat as _concat,
    findIndex as _findIndex
} from "lodash";
import ValueSelect from "./value-select";
import {ValidationError} from "../../../shared";
import NameplateParameterSelect from "./template/parameter-select";
import PhotosWrapper from "../../../shared/photosWrapper/photosWrapper";
import SelectWrapper from "../../../helpers/select-wrapper";

class CreateNameplateForm extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            newParametersIds: [],
            addNew: false
        };
    }

    onAddNew = () => {
        this.setState({addNew: true});
    }

    onCancelAddNew = () => {
        this.setState({addNew: false});
    }

    onChangeParameter = (e) => {
        const {parametersList, addParameter} = this.props;

        const id = +_get(e, "target.value");
        const parameter = _find(parametersList, {id});

        addParameter(parameter);

        this.setState({addNew: false});
    }

    attachImages = images => {
        const {onChange} = this.props;
        const photos = [...this.props.data.photos];

        let imageObjects = [];
        images.map(image => {
            imageObjects.push({url: image});
        });

        onChange({
            target: {
                name: "photos",
                value: _concat(photos, imageObjects)
            }
        });
    };

    detachImage = (image) => {
        const {onChange} = this.props;
        let photos = [...this.props.data.photos];
        const index = _findIndex(photos, {url: image.url});

        if (index >= 0) {
            photos.splice(index, 1);
        }

        onChange({
            target: {
                name: "photos",
                value: photos
            }
        });
    };

    render() {
        const {addNew} = this.state;
        const {
            data,
            templates,
            onChange,
            formErrors,
            parametersList,
            updateParametersList,
            deleteParameter,
            onSelect
        } = this.props;

        const nameplateParameters = _get(data, "parameters", []);

        return (
            <React.Fragment>
                <div className={"nameplate-component-block"}>
                    <div className={"row"}>
                        <div className={"col-md-6 mb-2"}>
                            <strong>Template</strong>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <div className={"form-group mb-0"}>
                                <SelectWrapper
                                    className={(_get(formErrors, "template_id") ? " is-invalid" : "")}
                                    style={{
                                        width:"100%",
                                        display:"block",
                                    }}
                                    name={"template_id"}
                                    value={_get(data, "template_id")}
                                    onChange={onChange}
                                >
                                    {!_get(data, "template_id") && <option value={""}>Select...</option>}
                                    {templates.map((template, index) =>
                                        <option key={index} value={template.id}>{template.name}</option>
                                    )}
                                </SelectWrapper>
                                <ValidationError message={_get(formErrors, "template_id")}/>
                            </div>
                        </div>
                    </div>
                    {!!_get(data, "template_id") &&
                        <React.Fragment>
                            <div className={"row mt-2"}>
                                <div className={"col-md-6 mb-2"}>
                                    <strong>Nameplate Name</strong>
                                </div>
                            </div>
                            <div className={`row ${_get(formErrors, "name") && "mb-4"}`}>
                                <div className={"col-md-6"}>
                                    <div className={"form-group mb-0"}>
                                        <input
                                            className={"form-control" + (_get(formErrors, "name") ? " is-invalid" : "")}
                                            name={"name"}
                                            value={_get(data, "name")}
                                            onChange={onChange}
                                        />
                                        <ValidationError message={_get(formErrors, "name")}/>
                                    </div>
                                </div>
                                {onSelect &&
                                    <div className={"col-md-6 mb-2"}>
                                        <div className="add-new-link mt-2 ml-0" onClick={onSelect}>
                                            <i className="fa fa-plus-circle"/>
                                            <span>Select Existing</span>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-md-6 mb-2"}>
                                    <strong>Nameplate Photo</strong>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-md-3 photo-block"}>
                                    <PhotosWrapper
                                        photos={data.photos ? data.photos : []}
                                        onAddPhotos={this.attachImages}
                                        onDeletePhoto={this.detachImage}
                                        withPhotoModal={true}
                                        withDropzone={true}
                                        buttonName={"Add Nameplate Photo"}
                                        defaultPhoto={"picture.svg"}
                                        withDeleteButton={true}
                                        withList={true}
                                    />
                                </div>
                            </div>

                            <React.Fragment>
                                <div className={"mt-3" + (_get(formErrors, "parameters") ? " is-invalid" : "")}>
                                    <div className={"nameplate-template-table"}>
                                        {nameplateParameters.map((parameter, index) =>
                                            <div key={index} className={"template-row"}>
                                                <div className={"template-col"}>
                                                    <strong>{parameter.name}</strong>
                                                </div>
                                                <div className={"form-group template-col"}>
                                                    <ValueSelect
                                                        name={"parameters["+index+"].value"}
                                                        value={_get(data, "parameters["+index+"].value")}
                                                        parameter={parameter}
                                                        errorMsg={_get(formErrors, "parameters["+index+"].value")}
                                                        onChange={onChange}
                                                    />
                                                    {!parameter.is_required &&
                                                        <button
                                                            className={"link link-danger remove"}
                                                            style={{fontSize: "17px", position: "absolute"}}
                                                            onClick={() => deleteParameter(index)}
                                                            title={"Remove"}
                                                        >
                                                            <i className="fa fa-times-circle"/>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {addNew &&
                                            <div className={"template-row new"}>
                                                <div className={"template-col"}>
                                                    <NameplateParameterSelect
                                                        parameters={parametersList}
                                                        onChange={this.onChangeParameter}
                                                        updateParameters={updateParametersList}
                                                    />
                                                </div>
                                                <div className={"form-group template-col"}>
                                                    <button
                                                        className={"link link-danger remove"}
                                                        style={{fontSize: "17px", position: "absolute"}}
                                                        onClick={() => this.onCancelAddNew()}
                                                        title={"Remove"}
                                                    >
                                                        <i className="fa fa-times-circle"/>
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <ValidationError message={_get(formErrors, "parameters")}/>
                                </div>
                                {!addNew &&
                                    <div className="add-new-link mt-4 ml-0" onClick={this.onAddNew}>
                                        <i className="fa fa-plus-circle"/>
                                        <span>Add Parameter</span>
                                    </div>
                                }
                            </React.Fragment>
                        </React.Fragment>
                    }

                </div>
            </React.Fragment>
        );
    }
}

CreateNameplateForm.propTypes = {
    data: PropTypes.object,
    formErrors: PropTypes.object,
    inProgress: PropTypes.bool,
    addParameter: PropTypes.func,
    deleteParameter: PropTypes.func,
    onChange: PropTypes.func,
    onSave: PropTypes.func,

    templates: PropTypes.array,
    parametersList: PropTypes.array,
    updateParametersList: PropTypes.func,

    onSelect: PropTypes.func
};

export default CreateNameplateForm;