import React from "react";
import PropTypes from "prop-types";
import Helper from "../../../helpers/helper";
import ImpactVueText from "../../../shared/impactVue/ImpactVueText";

const aliasList = ["acc", "vel", "pkpk", "temp", "acc_impact", "pkpk_impact"];

const aliasWithImpact = {
    acc_impact: true,
    pkpk_impact: true,
};

const RmsOdTemplate = ({alert, alias, isCurrent, update}) => {
    if (aliasList.indexOf(alias) === -1) return "";

    return (
        <div
            className="row mt-2 align-items-baseline"
            style={{minHeight: "30px"}}
        >
            <div className={alert.alertLevel.name === "Caution" ? "col-md-4" : "col-md-4"}>
                <label className="form-checkbox form-checkbox--alerts d-flex align-items-center">
                    <input
                        type="checkbox"
                        checked={alert.rmsOd === "1"}
                        onChange={(ev) => update({rmsOd: Helper.getInputValue(ev.target)})}
                        disabled={!isCurrent}
                    />
                    <div style={{marginRight: 4}}>Request</div>
                    {aliasWithImpact[alias] && (
                        <div style={{paddingBottom: 2, marginRight: 4}}>
                            <ImpactVueText />
                        </div>
                    )}
                    <div>FFT</div>
                    <span />
                </label>
            </div>
        </div>
    );
};

RmsOdTemplate.propTypes = {
    alert: PropTypes.object,
    alias: PropTypes.string,
    versionType: PropTypes.number,
    isCurrent: PropTypes.bool,
    update: PropTypes.func,
    specs: PropTypes.object,
};

export default RmsOdTemplate;
