import React, {useState} from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "../../shared/loader/loader";
import EquipmentLine from "./equipment-line";
import {get as _get, filter as _filter, orderBy as _orderBy} from "lodash";
import {ResetSortButton} from "../shared/resetSortButton";

const headList = {
    "level.name" : {title: "Level", width: 120, sort: true},
    "name" : {title: "Name", width: 500, sort: true},
    "service": {title: "Service", sort: true},
    "locationTable.name": {title: "Asset Tree Branch", width: 150, sort: true},
    "installationPointsCount" : {title: "Sensor", sort: true},
    "alarmsColumn": {title: "Alerts", sort: false},
    "notesColumn": {title: "Note", sort: false},
    "action": {title: "Action", additionalClasses: "table-buttons-th", width: 50, sort: false, component: ResetSortButton},
};

const EquipmentTable = ({list, locationLabelList, resultCount, filter, handleSort, opened, handleOpen, history, pageLength = 20, sortable = true, auth}) => {
    const [dataLength, setDataLength] = useState(pageLength);
    let index = 1;

    let equipmentsList = [];

    Object.keys(list).map(key => {
        if (list[key].equipments !== undefined) {
            list[key].equipments.map(equipment => {
                equipmentsList.push({...equipment, ...{level: list[key].level}});
            });
        }
    });

    const listLen = equipmentsList.length;
    const {sort} = filter;

    if (filter.sort.field) {
        equipmentsList = _orderBy(equipmentsList, [item => _get(item, filter.sort.field).toString().toLowerCase()], [filter.sort.sort]);
    }

    if (filter.level) {
        equipmentsList = _filter(equipmentsList, (item = {}) => (item.level || {}).name === filter.level);
    }

    if (!auth.userCan("showAlarms")) {
        delete headList["level"];
        delete headList["alarmsColumn"];
        delete headList["notesColumn"];
    }

    if (auth.customer.system_version !== "hybrid-service") {
        delete headList["service"];
    }

    return (
        <div className="col-12 equipment-list-table">
            <table className={"table table-hover equipment-table tl-fixed"}>
                <thead>
                    <tr>
                        {Object.keys(headList).map((key) => {
                            let component = "";
                            const RowComponent = _get(headList, [key, "component"], false);
                            if (RowComponent) {
                                component = <RowComponent sort={sort} resetSort={() => handleSort("")}/>;
                            }
                            return (
                                (listLen && headList[key].sort && sortable)
                                    ?
                                    <th
                                        className={headList[key].additionalClasses || ""}
                                        // width={_get(headList, [key, "width"])}
                                        key={key}
                                        onClick={() => handleSort(key, sort.sort === "asc" ? "desc" : "asc")}
                                    >
                                        {headList[key].title}
                                        {sort.field === key
                                            ? <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")}/>
                                            : <i className="fa fa-sort"/>
                                        }
                                    </th>
                                    : <th width={_get(headList, [key, "width"])} key={key}>{headList[key].title} {component}</th>
                            );
                        }
                        )}
                    </tr>
                </thead>
                {resultCount > 0
                    ?
                    <InfiniteScroll
                        loadMore={() => setDataLength(dataLength + 10)}
                        hasMore={dataLength < equipmentsList.length}
                        loader={<tr key="loader"><td colSpan={listLen} className="text-center"><Loader/></td></tr>}
                        element="tbody"
                    >
                        {(equipmentsList.map((equipment) => {
                            if (index++ > dataLength) return;
                            return <EquipmentLine
                                key={equipment.id}
                                opened={opened}
                                history={history}
                                handleOpen={handleOpen}
                                equipment={equipment}
                                locationLabelList={locationLabelList}
                                listLen={listLen}
                            />;
                        }))}
                    </InfiniteScroll>
                    :
                    <tbody id="accordionList"><EquipmentLine empty={true} listLen={listLen}/></tbody>
                }
            </table>
        </div>
    );
};

EquipmentTable.propTypes = {
    list: PropTypes.object,
    locationLabelList: PropTypes.array,
    resultCount: PropTypes.number,
    opened: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    handleOpen: PropTypes.func,
    filter: PropTypes.object,
    handleSort: PropTypes.func,
    history: PropTypes.object,
    pageLength: PropTypes.number,
    sortable: PropTypes.bool,
    serversort: PropTypes.bool,
    auth: PropTypes.object
};

export default EquipmentTable;