import Api from "./api";

const InstallationPointApi = {
    create: (data, type) => {
        if (type) {
            return Api.post("installation-point", data, {query: {type}});
        }
        return Api.post("installation-point", data);
    },
    get: id => Api.get(`installation-point/${id}`),
    getSimilar: id => Api.get(`installation-point/${id}/get-similar`),
    update: (id, data) => Api.patch(`installation-point/${id}`, data),
    delete: id => Api.delete(`installation-point/${id}`),
    changeSpeed: (id, data) => Api.post(`installation-point/${id}/change-speed`, data),
    changeIdleThreshold: (id, data) => Api.post(`installation-point/${id}/change-idle-threshold`, data),
    updatePointsSort: points => Api.post("installation-point/update-sorting", points),
    createNameplate: (installationPointId, nameplateId) => Api.post(`installation-point/${installationPointId}/nameplate/${nameplateId}`),
    deleteNameplate: (installationPointId, nameplateId) => Api.delete(`installation-point/${installationPointId}/nameplate/${nameplateId}`),
};

export default InstallationPointApi;