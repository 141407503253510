import React, {Component} from "react";
import PropTypes from "prop-types";
import {Loader} from "../../../shared";
import SearchInput from "../../shared/search-input";
import {Link} from "react-router-dom";
import TextEditor from "../../../shared/textEditor/textEditor";
import {Button} from "reactstrap";
import SelectWrapper from "../../../helpers/select-wrapper";
import {HeaderSimple} from "../../../shared/header";

class AddActionItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "Add Action Item",
            breadcrumbs: [{name: "Device Action Items", link: "/network/action-items"}, {name: "Add Action Item"}],
            globalSearchString: "",
            editorContent: "",
        };
    }

    onChange = (editorContent) => {
        this.setState({editorContent});
    };

    render() {
        const {breadcrumbs, loader, globalSearchString, editorContent} = this.state;

        return (
            <React.Fragment>
                <div className={"add-action-item"}>
                    <HeaderSimple
                        breadcrumbs={breadcrumbs}
                        globalSearchString={globalSearchString}
                        handleChangeGlobalSearch={this.handleChangeGlobalSearch}
                    />
                    {loader ? (
                        <div className="loader-fullscreen">
                            <Loader />
                        </div>
                    ) : (
                        <div>
                            <div className="block">
                                <div className="block-body">
                                    <div className={"d-flex justify-content-between align-item-center pt-2"}>
                                        <div className={"ai-title"}>Add Action Item</div>
                                        <Link
                                            to="/network/action-items"
                                            className="back-button"
                                            title={"Back"}
                                        >
                                            <i className={"fa fa-arrow-right"} />
                                        </Link>
                                    </div>
                                    <div className={"d-flex align-item-center mt-3 mb-3"}>
                                        <div className="icon-info mr-2">
                                            <img
                                                src="/assets/img/icon-info-blue.png"
                                                alt=""
                                                className="mCS_img_loaded"
                                            />
                                        </div>
                                        <div className="ai-description">
                                            A Waites Analyst will review the sensor data based on the information that you provide below. Please be sure to
                                            provide a detailed description.
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <div className="form-group">
                                                <label className="text-right form-label">
                                                    Title: <span className="color-danger">*</span>
                                                </label>
                                                <div className="">
                                                    <input
                                                        className={"form-control"}
                                                        name="title"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <div className="form-group">
                                                <label className="text-right form-label">
                                                    Urgency: <span className="color-danger">*</span>
                                                </label>
                                                <div className="">
                                                    <SelectWrapper name="urgency">
                                                        <option value={1}>Standard</option>
                                                        <option value={2}>High</option>
                                                        <option value={3}>Critical</option>
                                                    </SelectWrapper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <div className="form-group">
                                                <label className="text-right form-label">
                                                    Device Serial Number: <span className="color-danger">*</span>
                                                </label>
                                                <div className="">
                                                    <input
                                                        className={"form-control"}
                                                        name="title"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row mt-3"}>
                                        <div className={"col-md-12"}>
                                            <TextEditor
                                                content={editorContent}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={"row mt-3"}>
                                        <div className={"col-md-12 action-buttons text-right"}>
                                            <Link
                                                to="/network/action-items"
                                                className="btn btn-sm btn-secondary"
                                            >
                                                Cancel
                                            </Link>
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className="ml-2"
                                                onClick={() => {}}
                                            >
                                                <i className="fas fa-plus" />
                                                Add Action Item
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item ml-2">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

AddActionItem.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    type: PropTypes.string,
};

export default AddActionItem;
