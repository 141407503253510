import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {shallow} from "zustand/shallow";

export const useSharedStore = create(
    immer((set) => ({
        isShowChartMarks: true,
        actions: {
            toggleChartMarks() {
                set((state) => {
                    state.isShowChartMarks = !state.isShowChartMarks;
                });
            },
        },
    }))
);

export const useSharedStoreActions = () => useSharedStore((state) => state.actions, shallow);
export const useIsShowChartMarks = () => useSharedStore((state) => state.isShowChartMarks);
