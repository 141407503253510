import React, {useEffect, useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledTooltip} from "reactstrap";
import PropTypes from "prop-types";
import {useHighPassFilterSyncStoreActions, useHighPassFilterSyncStoreByPoint} from "../../../store/sync/HighPassFilterSyncStore";
import {usePointData} from "../../../hooks/usePointsData";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {FREQUENCY_TYPES} from "../../../../../../constants/constants";

const HighpassFilterBtn = ({installationPointId, fMax}) => {
    const {setHighPassFilter} = useHighPassFilterSyncStoreActions();
    const pointData = usePointData(installationPointId);
    const highpassFilter = useHighPassFilterSyncStoreByPoint(installationPointId, pointData);
    const {setLoader} = useChartStateStoreContext((state) => state.actions, shallow);
    const [isOpen, setIsOpen] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [tempHighPass, setTempHighpass] = useState(highpassFilter);

    useEffect(() => {
        if (!isOpen) {
            setTempHighpass(highpassFilter);
        }
    }, [isOpen, highpassFilter]);

    useEffect(() => {
        if (tempHighPass >= 1 && tempHighPass <= fMax) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [tempHighPass]);

    const onChange = (e) => {
        const value = e.target.value;
        setTempHighpass(value);
    };

    const onSave = () => {
        setHighPassFilter(installationPointId, tempHighPass);
        setIsOpen(false);
        setLoader(true);
    };

    const onClear = () => {
        setHighPassFilter(installationPointId, null);
        setIsOpen(false);
    };

    return (
        <Dropdown
            size="sm"
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
        >
            <DropdownToggle
                caret
                className="btn-default btn-zoom-scale"
            >
                HPF {highpassFilter ? " - " + highpassFilter + " " + FREQUENCY_TYPES.HZ : ""}
            </DropdownToggle>
            <DropdownMenu>
                <div className={"position-relative p-3"}>
                    <div className={"text-primary text-nowrap pr-2 pl-2 pb-3 text-center"}>
                        Input High-Pass filter from 1 {FREQUENCY_TYPES.HZ} to {fMax} {FREQUENCY_TYPES.HZ}
                    </div>
                    <div className="scale-content">
                        <div>
                            <label className="d-flex align-items-center">
                                <span className={"text-nowrap pr-2"}>HPF:</span>
                                <input
                                    value={tempHighPass}
                                    className="form-control form-control-sm form-control-custom flex-grow-1"
                                    onChange={onChange}
                                    type={"number"}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <DropdownItem divider />
                <div className={"position-relative pl-3 pr-3 order-2"}>
                    <button
                        className="btn btn-sm btn-secondary pull-left text-secondary"
                        onClick={() => onClear()}
                        disabled={!isValid}
                    >
                        Clear
                    </button>
                    <button
                        id={"highpass" + installationPointId}
                        className="btn btn-sm btn-info pull-right"
                        onClick={() => onSave()}
                        disabled={!isValid}
                    >
                        Save
                    </button>
                    {!isValid && (
                        <UncontrolledTooltip
                            placement="top"
                            target={"highpass" + installationPointId}
                        >
                            Input High-Pass filter from 1 {FREQUENCY_TYPES.HZ} to {fMax} {FREQUENCY_TYPES.HZ}
                        </UncontrolledTooltip>
                    )}
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

HighpassFilterBtn.propTypes = {
    installationPointId: PropTypes.number,
    fMax: PropTypes.number,
};

export default HighpassFilterBtn;
