/* eslint-disable */
import SetSpeedModal from "../components/setSpeed";
import React, {useMemo, useState} from "react";
import {get as _get, find as _find, forIn as _forIn} from "lodash";
import {useModal} from "react-modal-hook";
import {useChartStateStoreContext, useSelectUnixFrom, useSelectUnixTo} from "../../../pages/chart/highcharts/twf/store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useCurrentPointRef} from "../../../pages/chart/highcharts/twf/hooks/useCurrentPointRef";
import {cloneDeep as _cloneDeep} from "lodash";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useCurrentTimestampsInfo} from "../../../pages/chart/highcharts/twf/hooks/helpers/useCurrentTimestampsInfo";
import {useFFTTimestampsQuery} from "../../../hooks/api/fftReadings/TimestampsQuery";
import {useSpeedSettings} from "../../../pages/chart/highcharts/twf/hooks/helpers/useSpeedSettings";
import {useSelectedAxis} from "../../../pages/chart/highcharts/twf/hooks/helpers/useSelectedAxis";
import {useIsImpactVue} from "../../../pages/chart/highcharts/hooks/useChartTypes";
import {IMPACT_VUE_TIMESTAMPS, REGULAR_TIMESTAMPS} from "../../../constants/constants";

export const useSetSpeedModal = () => {
    const [point, setPoint] = useState({});
    const currentPointRef = useCurrentPointRef();
    const {seriesList, currentDate, selectedPoint} = useChartStateStoreContext((state) => state.settings, shallow);
    const {equipmentItem} = useEquipmentByParams();
    const setSpeedModal = useChartStateStoreContext((state) => state.speedModal, shallow);
    const {setIsCurrentSpeedModal} = useChartStateStoreContext((state) => state.actions);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const unixFrom = useSelectUnixFrom();
    const unixTo = useSelectUnixTo();

    const isImpactVue = useIsImpactVue(chartType);
    const {data: timestamps} = useFFTTimestampsQuery({
        equipmentId: equipmentItem.id,
        selectorFun: (res) => _get(res, [isImpactVue ? IMPACT_VUE_TIMESTAMPS : REGULAR_TIMESTAMPS, selectedPoint]),
        unixFrom,
        unixTo,
    });
    const {currentSpeedFrom, currentSpeed, currentRatio} = useSpeedSettings();
    const {readingId, date, time} = useCurrentTimestampsInfo();
    const selectedAxis = useSelectedAxis();

    const currentAxisTimestamps = useMemo(() => {
        const axisTimestamps = [];

        _forIn(timestamps, (value, key) => {
            const currentTimestampSet = _get(value, `${date}.${time}`);

            if (currentTimestampSet) {
                axisTimestamps.push({
                    ...currentTimestampSet,
                    axisId: key,
                    readingId: currentTimestampSet.id,
                    readingDate: `${date} ${time}`,
                });
            }
        });

        return axisTimestamps;
    }, [date, time, timestamps]);

    const pointData = _find(equipmentItem.installationPoints, {id: selectedPoint}) || {};
    const [showModal, hideModal] = useModal(() => {
        return (
            <SetSpeedModal
                showModal={true}
                onClose={hideCurrentModal}
                pointData={pointData}
                currentSpeed={currentSpeed}
                currentSpeedFrom={currentSpeedFrom}
                currentRatio={currentRatio}
                currentAxisTimestamps={currentAxisTimestamps}
                isCurrent={setSpeedModal.isCurrent}
                speed={_get(point, "x", 0)}
                seriesList={seriesList}
                readingId={readingId}
                isImpactVue={isImpactVue}
            />
        );
    }, [point, currentDate, pointData, selectedAxis, equipmentItem, currentSpeed, currentSpeedFrom, currentRatio, setSpeedModal.isCurrent, readingId]);
    const hideCurrentModal = () => {
        if (setSpeedModal.isCurrent) {
            setIsCurrentSpeedModal(false);
        }
        hideModal();
    };

    const showCurrentModal = (currentPoint = currentPointRef.current) => {
        setPoint(_cloneDeep(currentPoint));
        showModal();
    };

    return [showCurrentModal, hideCurrentModal];
};
