import {useMemo} from "react";
import ApiReading from "../../../api/reading";
import {useQueries} from "@tanstack/react-query";
import {SIMPLE_CHART_READING_API} from "./constants";
import {useQueriesStatus} from "../../useQueriesStatus";
import {get as _get} from "lodash";
import {useChartRangeWithAlert} from "../../../pages/chart/highcharts/hooks/useChartRangeWithAlert";

export const useSimpleChartReadings = ({installPointsIds, axisIds, chartType, enabled = true}) => {
    const rangeSelectorWithAlert = useChartRangeWithAlert();

    const {from, to, range} = rangeSelectorWithAlert;

    const queries = useMemo(() => {
        const queries = [];
        // if all axis was selected
        if (axisIds[0] === 0) {
            axisIds = [1, 2, 3];
        }
        installPointsIds.forEach((installPointId) => {
            axisIds.forEach((axisId) => {
                if (installPointId > 0) {
                    queries.push({
                        queryKey: [
                            SIMPLE_CHART_READING_API.GET_LIST,
                            {installationPointId: +installPointId, chartType: +chartType, axisId: +axisId, rangeSelectorWithAlert, range, to, from},
                        ],
                        queryFn: () =>
                            ApiReading.getChartData({
                                installationPointID: +installPointId,
                                chartType: +chartType,
                                axisID: +axisId,
                                from,
                                to,
                                range,
                            }).then(({data}) => data),
                        select: (res) => ({
                            readings: _get(res, [chartType, axisId, installPointId], []),
                            chartType,
                            axisId,
                            installPointId,
                        }),
                        refetchInterval: 1000 * 60 * 5,
                        refetchIntervalInBackground: true,
                        refetchOnWindowFocus: true,
                        enabled,
                        cacheTime: 0,
                    });
                }
            });
        });
        return queries;
    }, [chartType, installPointsIds, axisIds, from, to, range]);

    const results = useQueries({queries});

    const {data, isSuccess, isLoading, isRefetching} = useQueriesStatus(results);
    return {data, isSuccess, isLoading, isRefetching};
};
