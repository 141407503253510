import {cloneDeep as _cloneDeep} from "lodash";
import {removeChartNavigation} from "../../../../helpers/removeChartNavigation";
import {CHART_CONFIG} from "../../../../constants/chart";
import {useMemo} from "react";
import {useIsDarkTheme} from "../../../../hooks/helpers/theme";
import {setDarkModeForChart} from "../../../../helpers/setDarkModeForChart";
import useUserProfile, { profileUserSelector } from "../../../../hooks/api/Global/useUserProfile";
import Helper from "../../../../helpers/helper";
import {useSignalStrengthQuery} from "../../../../hooks/api/signal/useSignalStrengthQuery";
const baseOptions = removeChartNavigation(_cloneDeep(CHART_CONFIG));

export const useSignalStrengthTooltip = (serial, type, timeRange, dateRange, label= "dBm") => {
    const {isSuccess} = useSignalStrengthQuery(serial, type, timeRange, dateRange);

    const isDarkMode = useIsDarkTheme();

    const {data: user} = useUserProfile(profileUserSelector);

    return useMemo(() => {
        if (!isSuccess) {
            return {};
        }
        let baseOptionsLocal = baseOptions;
        if (isDarkMode === "true") {
            baseOptionsLocal = setDarkModeForChart(baseOptions);
        }

        return {
            ...baseOptionsLocal.tooltip,
            enabled: true,
            formatter() {
                return Helper.dateToUserFormat(this.x, user) + `<br/> <span class='tooltip-icon'>●</span> Signal Strength: ${this.y} ${label}`;
            },
        };
    }, [isSuccess, user]);
};
