import {useQuery} from "@tanstack/react-query";
import {DASHBOARD_API} from "./constants";
import ApiEquipments from "../../../api/equipment";

const dashboardListKey = () => [DASHBOARD_API.GET_DASHBOARD_LIST];
const dashboardListFn = () => ApiEquipments.dashboardList();

export const useDashboardListQuery = () => {
    return useQuery({
        queryFn: dashboardListFn,
        queryKey: dashboardListKey,
    });
};
