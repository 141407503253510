import {useQuery} from "@tanstack/react-query";
import ApiChartMark from "../../../api/chartMark";
import {CHART_MARK_API} from "./constants";

const useChartMarksQuery = (equipmentId) => {
    const {
        isSuccess,
        data: chartMarksData,
        ...rest
    } = useQuery(
        [CHART_MARK_API.GET_CHART_MARK, +equipmentId],
        () => ApiChartMark.get(+equipmentId, "", ""),
        {
            enabled: !!equipmentId,
        }
    );

    return {
        chartMarksData: chartMarksData?.list,
        isSuccess,
        ...rest,
    };
};

export default useChartMarksQuery;
