import React from "react";
import { useChartStateStoreContext } from "../../store/hooks/useStoreContext";
import { CHART_VIEW_TYPE, useIsCircular, useIsLinear } from "../../../hooks/useChartViewTypes";
import { useSpeedSettings } from "../../hooks/helpers/useSpeedSettings";

const ChartViewTypeDropdownButton = () => {
    const {currentSpeed} = useSpeedSettings();
    const chartViewType = useChartStateStoreContext((state) => state.settings.chartViewType);
    const { setChartViewType } = useChartStateStoreContext( (state) => state.actions);
    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    const isCircular = useIsCircular(chartViewType);
    const isLinear = useIsLinear(chartViewType);

    return (
        <div className={"btn-group-sm dropdown btn-default " + (isLoading ? "disabled" : "")}>
            <button
                type="button"
                className="btn btn-default"
                onClick={() => setChartViewType(CHART_VIEW_TYPE.LINEAR)}
                disabled={isLinear}
            >
                Linear
            </button>
            <button
                type="button"
                className="btn btn-default"
                onClick={() => setChartViewType(CHART_VIEW_TYPE.CIRCULAR)}
                disabled={isCircular || currentSpeed <= 0}
            >
                Circular
            </button>
        </div>
    );
};

export default ChartViewTypeDropdownButton;
