import React from "react";
import PropTypes from "prop-types";
import {useChartStateStoreContext} from "../../../../../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useEquipmentByParams} from "../../../../../../../../../../hooks/api/equipment/useEquipmentQuery";

const ChartHeaderWrapper = ({children}) => {
    const {fullScreenMode, seriesList} = useChartStateStoreContext((state) => state.settings, shallow);
    const {equipmentItem} = useEquipmentByParams();
    return (
        <div className="chart-block-header">
            <div className="chart-block-title">
                {fullScreenMode && <div className="chart-title-equipment">{equipmentItem.name}</div>}
                <div className="chart-title-text">{seriesList.title}</div>
            </div>
            <div className="chart-block-actions">{children}</div>
        </div>
    );
};

ChartHeaderWrapper.propTypes = {
    children: PropTypes.node,
};

export default ChartHeaderWrapper;
