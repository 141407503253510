import React, {Fragment, Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Helper from "../../helpers/helper";
import {withGlobalStore} from "../../stores/GlobalStore";
import {
    get as _get,
    find as _find
} from "lodash";
import {ResetSortButton} from "../shared/resetSortButton";
import EmailsPopover from "./emails-popover";
import {UncontrolledTooltip} from "reactstrap";
import LogPopover from "./log-popover";
import {AlarmInfo} from "./alarm-info";

const axisList = {
    x: 1,
    y: 2,
    z: 3,
};

const headersList = {
    "alertLevelName": {title: "Level", sort: true},
    "alertTriggeredDescription": {title: "Description", sort: true},
    "axis": {title: "Axis", sort: false},
    "equipmentName": {title: "Equipment", sort: true},
    "installationPointName": {title: "Installed On", sort: true},
    //"status": {title: "Status", sort: true},
    "timestamp": {title: "Created", sort: true},
    "log": {title: "Previous Triggers", sort: false},
    "sent_history": {title: "Emails Sent", sort: false},
    "actions": {title: "Actions", sort: false, additionalClasses: "table-buttons-th", component: ResetSortButton}
};

class Table extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            selected: []
        };
    }


    render() {
        const {list, sort, onSortChange, user, selectedLevel, excluded, query = "", settingsStates} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <table className="table table-hover tl-fixed">
                <thead>
                    <tr>
                        {Object.keys(headersList).map((key) => {
                            let component = "";
                            const RowComponent = _get(headersList, [key, "component"]);
                            if (RowComponent) {
                                component = <RowComponent sort={sort} resetSort={() => onSortChange("")}/>;
                            }
                            return (
                                listLen && headersList[key].sort
                                    ?
                                    <th className={headersList[key].additionalClasses || ""} key={key} style={headersList[key].style || {}} onClick={() => onSortChange(key)}>
                                        <div className="cursor-pointer">
                                            {headersList[key].title}
                                            {(sort.field === key) ? <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")}/> : <i className="fa fa-sort"/>}
                                        </div>
                                    </th>
                                    :
                                    <th className={headersList[key].additionalClasses || ""} key={key} style={headersList[key].style || {}}>{headersList[key].title} {component}</th>
                            );
                        })
                        }
                    </tr>
                </thead>
                <tbody>
                    {list.length > 0
                        ?
                        list.map((alarm) => <AlarmRow settingsStates={settingsStates} excluded={excluded} selectedLevel={selectedLevel} selected={this.props.selected} handleCheckbox={this.props.handleCheckbox} key={alarm.id} user={user} alarm={alarm} props={this.props}/>)
                        :
                        <tr>
                            <td colSpan={listLen} className="text-center text-info">
                                {query !== "" ? "No items match your search." : "No active alerts exist."}
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        );
    }
}

Table.propTypes = {
    list: PropTypes.array,
    settingsStates: PropTypes.object,
    user: PropTypes.object,
    sort: PropTypes.object,
    axisLabels: PropTypes.object,
    onSortChange: PropTypes.func,
    selected: PropTypes.array,
    excluded: PropTypes.array,
    handleCheckbox: PropTypes.func,
    selectedLevel: PropTypes.any,
    query: PropTypes.string
};

const AlarmRow = ({alarm, user, props, handleCheckbox, selected, selectedLevel, excluded, settingsStates}) => {
    const {alertCondition = {}} = alarm;
    const {alertLevel = {}, installationPoint = {}} = alertCondition;
    const {equipment = {}} = installationPoint;
    const axisLabel = _get(props.axisLabels, [axisList[alarm.axis.toLowerCase()], alarm.installationPointId]);

    let blocked = () => {
        return selected.length > 0 || selectedLevel;
    };
    const emailLogWarning = _find(_get(alarm, "emailHistory", []), (item) => item.status > 1);

    const getLink = () => {
        let link = installationPoint.point_type === "sensor" ? `/chart/${equipment.id}#sensors=${installationPoint.id}&alertId=${alarm.id}` : `/chart/${equipment.id}#alertId=${alarm.id}`,
            status = _get(alarm, "setting.status", null);

        if (status !== null && (+status === +settingsStates.arrived || +status === +settingsStates.arrivedDuplicate)) {
            link += `&readingFftId=${_get(alarm, "setting.reading_id")}`;
        }

        return link;
    };

    return (
        <tr>
            <td style={{whiteSpace: "nowrap"}}>
                {props.auth.userCan("editAlarms") && (
                    <label className="form-checkbox">
                        <input
                            checked={selected.indexOf(alarm.id) !== -1 || (selectedLevel === "full-all" && excluded.indexOf(alarm.id) === -1)}
                            type="checkbox"
                            onChange={handleCheckbox}
                            value={alarm.id}
                        />
                        <span />
                    </label>
                )}
                <div
                    className="badge badge-pill"
                    style={{background: alertLevel.color, color: "#fff"}}
                >
                    {alertLevel.name}
                </div>
            </td>
            <td>
                {alarm.description}
                <AlarmInfo
                    alarm={alarm}
                    settingsStates={settingsStates}
                />
            </td>
            <td>{axisLabel || alarm.axis.toUpperCase()}</td>
            <td>{equipment.name}</td>
            <td>{installationPoint.name}</td>
            <td data-order={alarm.timestamp}>{Helper.dateToUserFormat(alarm.timestamp, user)}</td>
            <td className={`alarm-log-${alarm.id}`}>
                <span
                    className={"cursor-pointer log-popover"}
                    id={`popover-log-${alarm.id}`}
                >
                    <span className="fa-layers fa-fw">
                        &nbsp;
                        <i className="fa fa-exclamation-triangle" />
                        <span className="fa-layers-counter ml-2">{_get(alarm, "log", []).length}</span>
                    </span>
                    {_get(alarm, "log", []).length > 0 && (
                        <LogPopover
                            popoverProps={{
                                target: `popover-log-${alarm.id}`,
                                container: `.alarm-log-${alarm.id}`,
                            }}
                            list={_get(alarm, "log", [])}
                        />
                    )}
                </span>
            </td>
            <td className={`alarm-line-${alarm.id}` + (typeof emailLogWarning !== "undefined" ? " text-danger" : "")}>
                <span
                    className={"cursor-pointer emails-popover"}
                    id={`popover-${alarm.id}`}
                >
                    <span className="fa-layers fa-fw">
                        &nbsp;
                        {/* <img className={"table-image-icon"} src={"/assets/pic/mail.svg"}/>&nbsp; */}
                        <MailIcon />
                        <span className="fa-layers-counter">{_get(alarm, "emailHistory", []).length}</span>
                    </span>
                    {_get(alarm, "emailHistory", []).length > 0 && (
                        <EmailsPopover
                            popoverProps={{
                                target: `popover-${alarm.id}`,
                                container: `.alarm-line-${alarm.id}`,
                            }}
                            list={_get(alarm, "emailHistory", [])}
                        />
                    )}
                </span>
            </td>
            <td>
                <div className="btn-group btn-group-sm">
                    {props.auth.userCan("editAlarms") && !blocked() && (
                        <Fragment>
                            <button
                                className="link alarm-btn link-success"
                                data-qa="AcknowledgeButton"
                                onClick={() => props.onOpenAcknowledgeAlarmModal(alarm.id)}
                            >
                                <i
                                    className="fa fa-check"
                                    id={`acknowledge-${alarm.id}`}
                                />
                                <UncontrolledTooltip
                                    popperClassName="tooltip-md"
                                    placement="top"
                                    target={`acknowledge-${alarm.id}`}
                                >
                                    Acknowledge
                                </UncontrolledTooltip>
                                <span>Acknowledge</span>
                            </button>
                            <button
                                className="link alarm-btn link-secondary"
                                data-qa="IgnoreButton"
                                onClick={() => props.onOpenIgnoreAlarmModal(alarm.id)}
                            >
                                <i
                                    className="fa fa-times"
                                    id={`ignore-${alarm.id}`}
                                />
                                <UncontrolledTooltip
                                    popperClassName="tooltip-md"
                                    placement="top"
                                    target={`ignore-${alarm.id}`}
                                >
                                    <div className="test">Ignore</div>
                                </UncontrolledTooltip>
                                <span>Ignore</span>
                            </button>
                        </Fragment>
                    )}
                    <Link
                        data-qa="ViewButton"
                        to={getLink()}
                        className="link alarm-btn link-primary"
                    >
                        <i
                            className="fa fa-eye"
                            id={`view-${alarm.id}`}
                        />
                        <UncontrolledTooltip
                            popperClassName="tooltip-md"
                            placement="top"
                            target={`view-${alarm.id}`}
                        >
                            View
                        </UncontrolledTooltip>
                        <span>View</span>
                    </Link>
                </div>
            </td>
        </tr>
    );
};

AlarmRow.propTypes = {
    auth: PropTypes.object,
    alarm: PropTypes.object,
    settingsStates: PropTypes.object,
    user: PropTypes.object,
    props: PropTypes.object,
    axisLabels: PropTypes.object,
    onOpenAcknowledgeAlarmModal: PropTypes.func,
    onOpenIgnoreAlarmModal: PropTypes.func,
    onOpenViewAlarmModal: PropTypes.func,
    handleCheckbox: PropTypes.func,
    selected: PropTypes.array,
    excluded: PropTypes.array,
    selectedLevel: PropTypes.any
};

const MailIcon = () => {
    return (
        <span className="table-image-icon mail-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 384">
                <path d="M394.67,149.33a117.33,117.33,0,1,0,58.68,219,10.66,10.66,0,1,0-10.69-18.46,96,96,0,1,1,48-83.17v10.67a21.33,21.33,0,1,1-42.67,0V234.67A10.63,10.63,0,0,0,430,227a53.32,53.32,0,1,0,4.51,74.8A42.6,42.6,0,0,0,512,277.33V266.67A117.47,117.47,0,0,0,394.67,149.33Zm0,149.33a32,32,0,1,1,32-32A32,32,0,0,1,394.67,298.67Z"/>
                <path d="M17.7,24.69l175.72,143a10.1,10.1,0,0,0,1.89,1.23,16.07,16.07,0,0,0,7.39,1.8c2.71,0,5.42-.71,9.38-3.1L387.65,24.7a10.66,10.66,0,0,0-.64-17A42.38,42.38,0,0,0,362.67,0h-320A42.34,42.34,0,0,0,18.33,7.67,10.66,10.66,0,0,0,17.7,24.69Z"/>
                <path d="M256,266.67C256,190.21,318.21,128,394.67,128a10.66,10.66,0,0,0,10.67-10.67V60.24a10.67,10.67,0,0,0-6.08-9.64A10.78,10.78,0,0,0,387.94,52L224.28,185.15l-1.87,1.24c-12.3,7.55-27.42,7.94-41.3-1.23L17.4,52A10.66,10.66,0,0,0,0,60.23V256a42.71,42.71,0,0,0,42.67,42.67H247a10.66,10.66,0,0,0,10.56-12.18A138.44,138.44,0,0,1,256,266.67Z"/>
            </svg>
        </span>
    );
};

export default withGlobalStore(Table);