import React from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../stores/GlobalStore";

import "react-image-crop/dist/ReactCrop.css";
import "react-medium-image-zoom/dist/styles.css";

import "../../../assets/scss/components/assign-points-modal/assign-points-modal.scss";
import "../../../pages/chart/aside/components/images/images.scss";
import EditModal from "./edit-modal";
import CreateModal from "./create-modal";

const Index = props => (props.equipment || {}).id ? <EditModal {...props}/> : <CreateModal {...props}/>;

Index.propTypes = {
    equipment: PropTypes.object
};

export default withGlobalStore(Index);