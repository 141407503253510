import React from "react";
import ChartHeader from "./components/chartHeader/chartHeader";
import PropTypes from "prop-types";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useChartHeight} from "../../hooks/chartHooks";
import Loader from "../../../../../../shared/loader/loader";

const ChartWrapper = ({children}) => {
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    const chartHeight = useChartHeight();
    return (
        <div
            className={"chart-block"}
            data-chart-type={chartType}
            id={"placeholder-" + chartType}
        >
            <ChartHeader />
            <div
                className="position-relative"
                style={{
                    height: chartHeight + 38 + "px",
                    overflow: isLoading && "hidden",
                }}
            >
                {isLoading && (
                    <div className="loader-relative loader-relative_solid">
                        <Loader additionalClass="loader-sm" />
                    </div>
                )}
                {children}
            </div>
        </div>
    );
};
ChartWrapper.propTypes = {
    children: PropTypes.node,
};
export default ChartWrapper;
