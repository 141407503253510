import React from "react";
import PropTypes from "prop-types";
import {get as _get} from "lodash";
import Helper from "../../../helpers/helper";

const AxisTypeControl = ({axisId, history, axisLabels, chartType, chartTypes, isEnvelope, currentIsChanged, installationPointId, setConfirm}) => (
    <div className="form-group row align-items-center mb-3">
        <label className="col-4 mb-0">Axis Type:</label>
        <div className="col-8">
            <div className="axis-block-container">
                {!isEnvelope &&
                    <div
                        className={`axis-block ${!axisId ? "active" : ""}`}
                        onClick={() => {
                            if (currentIsChanged) setConfirm(() => history.push(Helper.deleteHashParams(["axisId"])));
                            else history.push(Helper.deleteHashParams(["axisId"]));
                        }}
                    >
                        <span>All</span>
                    </div>
                }
                {Object.keys((chartTypes[chartType] || {}).series || {}).map(key => {
                    const axisLabel = _get(axisLabels, [key, installationPointId]);
                    return (
                        <div
                            key={key}
                            className={`axis-block ${+axisId === +key ? "active" : ""}`}
                            onClick={() => {
                                if (currentIsChanged) setConfirm(() => history.push(Helper.setHashParams({axisId: key})));
                                else history.push(Helper.setHashParams({axisId: key}));
                            }}
                        >
                            <span>{axisLabel || chartTypes[chartType].series[key].axisName}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    </div>
);

AxisTypeControl.propTypes = {
    history: PropTypes.object,
    axisLabels: PropTypes.object,
    chartTypes: PropTypes.object,
    isEnvelope: PropTypes.bool,
    currentIsChanged: PropTypes.bool,
    installationPointId: PropTypes.number,
    chartType: PropTypes.any,
    axisId: PropTypes.number,
    setConfirm: PropTypes.func
};

export default AxisTypeControl;