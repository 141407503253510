import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import ApiReadingTypes from "../../../api/readingTypes";
import WuaSensorValueTypeApi from "../../../api/wuaSensorValueType";
import {
    get as _get,
    each as _each,
    find as _find
} from "lodash";
import WuaSensorApi from "../../../api/wuaSensor";
import Toast from "../../shared/toast";
import WuaConfigForm from "./wuaConfigForm";
import {Loader, Modal} from "../../../shared";
import {useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import Helper from "../../../helpers/helper";
import {ADAPTER_VALUE_TYPE} from "../../../constants/constants";

const defaultConfig = {
    name: "",
    reading_type_id: null,
    brand: "",
    model: "",
    value_type_id: "",
    offset: "",
    ratio: "",
    datasheet_file: "",
    datasheet_url: "",
    datasheet_file_name: "",
    wiring_diagram_file: "",
    wiring_diagram_url: "",
    wiring_diagram_file_name: "",
    wiring_instructions: "",
    photo: "",
    voltage: true,
    min_voltage: "",
    max_voltage: "",
    min_sensor_value: "",
    max_sensor_value: "",
};

const WuaConfigModal = ({updateConfig, onClose, updateWuaSensorList}) => {
    const [config, setConfig] = useState(updateConfig ? updateConfig : defaultConfig);
    const [readingTypes, setReadingTypes] = useState([]);
    const [valueTypes, setValueTypes] = useState([]);
    const [loader, setLoader] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [isRtd, setIsRtd] = useState(false);

    const chartTypes = useChartTypesStore();

    useEffect(() => {
        Promise.all([
            fetchReadingTypes(),
            fetchValueTypes()
        ]).then(() => {
            setLoader(false);
        });
    }, []);

    useEffect(() => {
        const isRTD = isCurrentValueTypeRTD();

        if (config.value_type_id === ADAPTER_VALUE_TYPE.RTD) {
            const tempType = _find(chartTypes, (item) => item.alias === "temp");
            setConfig({...config, ...{
                reading_type_id: +_get(tempType, "readingTypes.0"),
                min_voltage: "",
                max_voltage: "",
                min_sensor_value: "",
                max_sensor_value: "",
            }});
        }

        if (config.value_type_id === ADAPTER_VALUE_TYPE.V05) {
            setConfig({...config, ...{
                min_voltage: 0,
                max_voltage: 5,
           }});
        }

        if (config.value_type_id === ADAPTER_VALUE_TYPE.V010) {
            setConfig({...config, ...{
                min_voltage: 0,
                max_voltage: 5,
            }});
        }

        if (config.value_type_id === ADAPTER_VALUE_TYPE.MA420) {
            setConfig({...config, ...{
                min_voltage: 4,
                max_voltage: 20,
            }});
        }

        setIsRtd(isRTD);
    }, [config.value_type_id, valueTypes]);

    useEffect(() => {
        if (config.value_type_id === ADAPTER_VALUE_TYPE.V010) {
            setConfig({...config, ...{max_voltage: config.min_voltage === 0 ? 5 : 10}});
        }
    }, [config.min_voltage, valueTypes]);

    const isCurrentValueTypeRTD = () => {
        const currValueType = _find(valueTypes, {"id": config.value_type_id});
        return currValueType && currValueType.value_key === "temperature";
    };

    const fetchReadingTypes = () => {
        return ApiReadingTypes
            .customList()
            .then(response => {
                let data = [];
                _each(_get(response, "list", []), (readingType) => {
                    readingType.name = _get(readingType, "name") + " (" + _get(readingType, "unit") + ")";
                    data.push(readingType);
                });
                const tempType = _find(chartTypes, (item) => item.alias === "temp");
                if (tempType) {
                    data.push({
                        id: +_get(tempType, "readingTypes.0"),
                        name: _get(tempType, "label") + " (" + Helper.getUserMeasure(chartTypes, 1) + ")"
                    });
                }
                setReadingTypes(data);
            });
    };

    const fetchValueTypes = () => {
        return WuaSensorValueTypeApi
            .getList()
            .then(response => {
                setValueTypes(_get(response, "list", []));
            });
    };

    const onChange = (event) => {
        const key = _get(event, "target.name", "");
        const val = _get(event, "target.value", "");

        setConfig({...config, ...{[key]: val}});
    };

    const onBatchChange = (data) => {
        setConfig({...config, ...data});
    };

    const submit = () => {
        return updateConfig ? update() : create();
    };

    const create = () => {
        setInProgress(true);
        setFormErrors({});

        return WuaSensorApi
            .create(config)
            .then(() => {
                updateWuaSensorList().then(() => {
                    Toast.success("The universal adapter configuration has been created.");
                    onClose();
                });

                /*this.props.history.push("/network/wua-sensors");*/
            })
            .catch(response => {
                setFormErrors(response.errors || {});
                setInProgress(false);
            });
    };

    const update = () => {
        setInProgress(true);
        setFormErrors({});

        return WuaSensorApi
            .update(config.id, config)
            .then(() => {
                updateWuaSensorList().then(() => {
                    Toast.success("The universal adapter configuration has been updated.");
                    onClose();
                });

                /*this.props.history.push("/network/wua-sensors");*/
            })
            .catch(response => {
                setFormErrors(response.errors || {});
                setInProgress(false);
            });
    };

    return (
        <>
            <Modal
                showModal={true}
                size={"lg"}
                onSubmit={submit}
                onClose={onClose}
                title={updateConfig ? "Update Adapter Configuration" : "Add Adapter Configuration"}
            >
                {loader
                    ?
                    <div className="loader-fullscreen"><Loader/></div>
                    :
                    <WuaConfigForm
                        data={config}
                        readingTypes={readingTypes}
                        valueTypes={valueTypes}
                        inProgress={inProgress}
                        formErrors={formErrors}
                        onChange={onChange}
                        fetchReadingTypes={fetchReadingTypes}
                        onBatchChange={onBatchChange}
                        isRtd={isRtd}
                    />
                }
            </Modal>
        </>
    );
};

WuaConfigModal.propTypes = {
    updateConfig: PropTypes.object,
    onClose: PropTypes.func,
    updateWuaSensorList: PropTypes.func
};

export default WuaConfigModal;