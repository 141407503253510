import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledTooltip} from "reactstrap";
import {useChartContext} from "../../hooks/chartHooks";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";

const ScaleButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {chartRef} = useChartContext();

    const valueMax = useChartStateStoreContext((state) => state.settings.valueMax);
    const valueMin = useChartStateStoreContext((state) => state.settings.valueMin);
    const valueInitMin = useChartStateStoreContext((state) => state.settings.valueInitMin);
    const valueInitMax = useChartStateStoreContext((state) => state.settings.valueInitMax);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const zoomed = useChartStateStoreContext((state) => state.settings.zoomed);
    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    const {setZoomed} = useChartStateStoreContext((state) => state.actions);

    const handleZoom = (min, max) => {
        chartRef.current.chart.yAxis[0].setExtremes(min, max, 1);

        if (Number.isInteger(min) || Number.isInteger(max)) {
            setZoomed(true);
        }
        chartRef.current.chart.reflow();
        setIsOpen(false);
    };

    const [min, setMin] = useState(+valueInitMin ?? 0);
    const [max, setMax] = useState(+valueInitMax ?? 0);
    const isInvalidZoomValue = useMemo(() => +min > +max, [min, max]);

    const handleChangeMin = (e) => {
        setMin(e.target.value);
    };

    const handleChangeMax = (e) => {
        setMax(e.target.value);
    };

    const changeZoom = () => {
        if (isInvalidZoomValue) {
            return;
        }

        handleZoom(parseFloat(min), parseFloat(max));
    };

    useEffect(() => {
        if (min !== valueMin) {
            setMin(+valueMin);
        }
        if (max !== valueMax) {
            setMax(+valueMax);
        }
    }, [valueMax, valueMin]);

    useEffect(() => {
        if (zoomed === false) {
            if (min !== "") {
                setMin(+valueInitMin);
            }
            if (max !== "") {
                setMax(+valueInitMax);
            }
        }
    }, [valueInitMin, valueInitMax, zoomed]);

    if (isLoading) {
        return <></>;
    }

    return (
        <Dropdown
            size="sm"
            disabled={isLoading}
            isOpen={isOpen}
            toggle={() => setIsOpen((prevState) => !prevState)}
        >
            <DropdownToggle
                caret
                className="btn-default btn-zoom-scale"
            >
                Change Scale
            </DropdownToggle>
            <DropdownMenu>
                <div
                    style={{
                        postiton: "relative",
                        padding: "0 10px",
                    }}
                >
                    <div className="scale-wrapper"></div>
                    <div className="scale-content">
                        <div>
                            <label className="d-flex align-items-center">
                                <span>Y max:</span>
                                <input
                                    value={(max + "").trim() || 0 + ""}
                                    className="form-control form-control-sm form-control-custom flex-grow-1"
                                    onChange={handleChangeMax}
                                    type="number"
                                    style={{
                                        width: "80px",
                                        marginLeft: "5px",
                                    }}
                                />
                            </label>
                        </div>
                        <div>
                            <label className="d-flex align-items-center">
                                <span>Y min:</span>
                                <input
                                    value={(min + "").trim() || 0 + ""}
                                    className="form-control form-control-sm form-control-custom flex-grow-1"
                                    onChange={handleChangeMin}
                                    type="number"
                                    style={{
                                        width: "80px",
                                        marginLeft: "7px",
                                    }}
                                />
                            </label>
                        </div>
                        <DropdownItem divider />
                        <div>
                            <button
                                id={`zoom-twf-${chartType}`}
                                className="btn btn-sm btn-info pull-right"
                                onClick={changeZoom}
                            >
                                Scale
                            </button>
                            {isInvalidZoomValue && (
                                <UncontrolledTooltip
                                    placement="top"
                                    target={`zoom-twf-${chartType}`}
                                >
                                    The minimum value cannot be greater than the maximum
                                </UncontrolledTooltip>
                            )}
                        </div>
                    </div>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

ScaleButton.propTypes = {
    isOpen: PropTypes.bool,
    toggleSeriesOpen: PropTypes.func,
    valueMax: PropTypes.number,
    handleChangeMax: PropTypes.func,
    valueMin: PropTypes.number,
    handleChangeMin: PropTypes.func,
    chartType: PropTypes.number,
    handleZoom: PropTypes.func,
};

export default ScaleButton;
