import React from "react";
import Select from "react-select";
import {customStyles} from "../../../../helpers/select";
import {ValidationError} from "../../../../shared";
import {upperFirst as _upperFirst} from "lodash";
import {ALARM_EMAIL_FREQUENCY} from "../../../../constants/constants";
import PropTypes from "prop-types";

const typeList = [
    {value: 0, label: "All devices per facility", key: "facility"},
    {value: 1, label: "Custom", key: "custom"}
];

const AlertSettings = ({data, issues, validation, updateData}) => {
    return (
        <React.Fragment>
            <span className="form-title-group">Alert Settings</span>
            <div className="row">
                <div className="col-2">
                    <div className="form-group">
                        <label className="form-label">Type <span className="color-danger">*</span></label>
                        <Select
                            className={"form-control-select" + (validation.type ? " is-invalid" : "")}
                            isSearchable={false}
                            styles={customStyles}
                            value={typeList[data.type]}
                            options={typeList}
                            onChange={({value}) => updateData("type", value)}
                        />
                        <ValidationError message={validation.type}/>
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group">
                        <label className="form-label">Alert Frequency <span className="color-danger">*</span></label>
                        <Select
                            className={"form-control-select" + (validation.frequency ? " is-invalid" : "")}
                            isSearchable={false}
                            styles={customStyles}
                            value={ALARM_EMAIL_FREQUENCY[data.frequency] ? {value: data.frequency, label: ALARM_EMAIL_FREQUENCY[data.frequency]} : undefined}
                            options={Object.keys(ALARM_EMAIL_FREQUENCY).map(key => ({value: key, label: ALARM_EMAIL_FREQUENCY[key]}))}
                            onChange={({value}) => updateData("frequency", value)}
                        />
                        <ValidationError message={validation.frequency}/>
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group">
                        <label className="form-label">Issues <span className="color-danger">*</span></label>
                        <Select
                            className={"form-control-select" + (validation.issue ? " is-invalid" : "")}
                            isSearchable={false}
                            styles={customStyles}
                            value={data.issue ? {value: data.issue, label: _upperFirst(data.issue)} : undefined}
                            options={Object.keys(issues).map(key => ({value: key, label: _upperFirst(key)}))}
                            onChange={({value}) => updateData("issue", value)}
                        />
                        <ValidationError message={validation.issue}/>
                    </div>
                </div>
                {["all", "offline"].indexOf(data.issue) !== -1 &&
                <div className="col-2">
                    <div className="form-group">
                        <label className="form-label">Offline Period <span className="color-danger">*</span></label>
                        <input
                            className={"form-control" + (validation.offlinePeriod ? " is-invalid" : "")}
                            min={15}
                            max={60}
                            type="number"
                            value={data.offlinePeriod || ""}
                            onChange={ev => updateData("offlinePeriod", ev.target.value)}
                        />
                        <ValidationError message={validation.offlinePeriod}/>
                    </div>
                </div>
                }
            </div>
        </React.Fragment>
    );
};

AlertSettings.propTypes = {
    updateData: PropTypes.func,
    validation: PropTypes.object,
    issues: PropTypes.object,
    data: PropTypes.object
};

export default AlertSettings;