export const scrollToFft = (chartType, installationPoint) => {
    const chart = document.querySelector(`[data-chart-type="${chartType}"][data-point-id="${installationPoint}"]`);

    if (chart) {
        chart.scrollIntoView({
            behavior: "smooth",
        });
    }

    const chartContainer = document.querySelector(`#placeholder-${chartType}-${installationPoint}`);

    if (chartContainer) {
        chartContainer.scrollIntoView({
            behavior: "smooth",
        });
    }

};
