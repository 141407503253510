import React, {Component} from "react";
import { Link } from "react-router-dom";

import "../../assets/scss/components/error-page/error.scss";
class Page extends Component
{
    render() {
        return (
            <div className="error-page">
                <div className="error-page-content">
                    <div className="error-page-image error-page-new-image pl-5">
                        <img src="/assets/media/misc/404-new.png" className="error-page-image-content" alt="" title=""/>
                    </div>
                    <div className="error-page-desc error-page-new-desc">Sorry but we couldn&apos;t find that page.</div>
                    <div className="error-page-sub-desc">Please check your link or click the button below.</div>
                    <div className="error-page-buttons">
                        <div className="d-flex buttons__block justify-content-center">
                            <span><Link className="btn v2-btn blue blue-border mt-5" to={"/"}>Go to Dashboard</Link></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Page;
