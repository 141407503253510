import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {get as _get} from "lodash";

const ALERT_TYPE_BAND = 1;
const ALERT_TYPE_ENVELOPE = 2;

const AlertCondition = ({condition, equipmentId}) => {
    switch (true) {
    case isBand(condition):
        return (<BandAlertCondition equipmentId={equipmentId} condition={condition}/>);
    case isEnvelope(condition):
        return (<EnvelopeAlertCondition equipmentId={equipmentId} condition={condition}/>);
    default:
        return (<StandardAlertCondition condition={condition} equipmentId={equipmentId}/>);
    }
};

AlertCondition.propTypes = {
    condition: PropTypes.object,
    equipmentId: PropTypes.any
};

const StandardAlertCondition = ({condition, equipmentId}) => {
    return (
        <div className="k-list__item">
            <span className="k-list__text">
                <div className="d-flex justify-content-between">
                    {condition.installation_point_name}
                    {(_get(condition, "lastAlertTriggered.id", false) && equipmentId && _get(condition, "installation_point_id", false)) &&
                    <Link
                        to={`/chart/${equipmentId}#sensors=${condition.installation_point_id}&alertId=${condition.lastAlertTriggered.id}`}
                        className="link link-primary"
                    >
                        <i className="fa fa-eye"/>
                        <span>View</span>
                    </Link>
                    }
                </div>
                <b>{condition.lastAlertTriggered.title || condition.alertLevel.name}: </b>
                {condition.lastAlertTriggered.description || "Empty description"}
                &nbsp;{+_get(condition, "is_tachometer_rpm", 0) === 1 ? "(This alert is based on the tachometer RPM.)" : ""}
            </span>
        </div>
    );
};

StandardAlertCondition.propTypes = {
    condition: PropTypes.object,
    equipmentId: PropTypes.any
};

const BandAlertCondition = ({condition, equipmentId}) => {
    return (
        <div className="k-list__item">
            <span className="k-list__text">
                <div className="d-flex justify-content-between">
                    {condition.installation_point_name}
                    {(_get(condition, "lastAlertTriggered.id", false) && equipmentId && _get(condition, "installation_point_id", false)) &&
                    <Link
                        to={`/chart/${equipmentId}#sensors=${condition.installation_point_id}&alertId=${condition.lastAlertTriggered.id}`}
                        className="link link-primary"
                    >
                        <i className="fa fa-eye"/>
                        <span>View</span>
                    </Link>
                    }
                </div>
                <div>Axis: {condition.axis_name} - Band ({condition.fft_band_frequency_type}): {condition.fft_band_from} - {condition.fft_band_to}</div>
                <b>{condition.lastAlertTriggered.title || condition.alertLevel.name}: </b>
                {condition.lastAlertTriggered.description || "Empty description"}
            </span>
        </div>
    );
};

BandAlertCondition.propTypes = {
    condition: PropTypes.object,
    equipmentId: PropTypes.any
};

const EnvelopeAlertCondition = ({condition, equipmentId}) => {
    return (
        <div className="k-list__item">
            <span className="k-list__text">
                <div className="d-flex justify-content-between">
                    {condition.installation_point_name}
                    {(_get(condition, "lastAlertTriggered.id", false) && equipmentId && _get(condition, "installation_point_id", false)) &&
                    <Link
                        to={`/chart/${equipmentId}#sensors=${condition.installation_point_id}&alertId=${condition.lastAlertTriggered.id}`}
                        className="link link-primary"
                    >
                        <i className="fa fa-eye"/>
                        <span>View</span>
                    </Link>
                    }
                </div>
                <b>{condition.alertLevel.name}: </b>
                {condition.lastAlertTriggered.description || "Empty description"}
            </span>
        </div>
    );
};

EnvelopeAlertCondition.propTypes = {
    condition: PropTypes.object,
    equipmentId: PropTypes.any
};

function isFft(condition) {
    return !isStandard(condition);
}

function isStandard(condition) {
    return condition.reading_type_id !== null;
}

function isBand(condition) {
    return isFft(condition)
        && parseInt(condition.fft_alert_type) === ALERT_TYPE_BAND;
}

function isEnvelope(condition) {
    return isFft(condition)
        && parseInt(condition.fft_alert_type) === ALERT_TYPE_ENVELOPE;
}

AlertCondition.propTypes = {
    condition: PropTypes.object
};

export default AlertCondition;