import {useMemo} from "react";
import {getChartHistoriesPlotLines} from "../../helpers/plotLines";
import {useChartSelectedEquipmentStore} from "../../../../../../stores/zustand/ChartSelectedEquipmentStore";
import useChartHistories from "../../../../../../hooks/api/Chart/useChartHistories";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useIsShowChartMarks} from "../../../store/sync/sharedStore";

export const useHistoriesBuilder = (props, equipment) => {
    const selectedEquipment = useChartSelectedEquipmentStore();
    const {chartHistories} = useChartHistories(equipment.id, props.selected, equipment, props.user);
    const {equipmentItem} = useEquipmentByParams();
    const isShowChartMarks = useIsShowChartMarks();

    const historiesPlotLines = useMemo(
        () => getChartHistoriesPlotLines({chartHistories: chartHistories, equipmentItem, selectedEquipment, user: props.user, isShowChartMarks}),
        [chartHistories, selectedEquipment, isShowChartMarks]
    );
    return historiesPlotLines;
};
