import cookies from "react-cookies";
import ApiUser from "../api/user";
import {CUSTOMER_STORAGE_AZURE, CUSTOMER_SYSTEM_VERSION} from "../constants/constants";
import {
    get as _get,
    find as _find
} from "lodash";
import CustomerApi from "../api/customer";
import Helper from "../helpers/helper";
import {
    MANAGE_FULL_SERVICE_EQUIPMENTS_PERMISSION,
    MANAGE_SELF_SERVICE_EQUIPMENT_PERMISSION,
    MANAGE_WIFI_GROUP_PERMISSION,
} from "../constants/permissions";

class AuthService {
    constructor() {
        this.setToken();
        this.permissions = [];
        this.customer = null;
        this.allowedCustomers = [];
        this.needSelectCustomer = false;
        this.showRefactorNotification = false;
        this.isWaitesAccount = false;
        this.use_2fa = false;
        this.cookiesCustomerId = null;
        this.getProfile = this.getProfile.bind(this);
    }

    loggedIn() {
        return !!this.getToken();
    }

    getToken() {
        const cookieToken = cookies.load(process.env.COOKIE_TOKEN_NAME || "access_token");
        //TODO: parse token and check user id. refresh page only on user id changed
        if (this.token && this.token !== cookieToken) {
            return window.location.reload();
        }
        return cookieToken;
    }

    setToken() {
        this.token = cookies.load(process.env.COOKIE_TOKEN_NAME || "access_token");
    }

    logout() {
        cookies.remove(process.env.CUSTOMER_COOKIE_PREFIX + "customer_id_previous", { path: "/", domain: process.env.SSO_COOKIE_DOMAIN});
        cookies.remove(process.env.CUSTOMER_COOKIE_PREFIX + "customer_id", { path: "/", domain: process.env.SSO_COOKIE_DOMAIN});
        window.location.href = process.env.SSO_URL + "/logout-simple/1";
    }

    getProfile() {
        return ApiUser.profile().then(profile => {
            this.permissions = profile.permissions;
            this.customer = profile.customer;
            this.isWaitesAccount = _get(profile, "user.isWaitesAccount", false);
            this.showRefactorNotification = _get(profile, "user.showRefactorNotification", false);
            this.use_2fa = _get(profile, "user.use_2fa", false);

            return profile;
        });
    }

    get isHybrid() {
        return this.customer.system_version === CUSTOMER_SYSTEM_VERSION.HYBRID;
    }

    userCan(hasPermission, group) {
        if (hasPermission === MANAGE_WIFI_GROUP_PERMISSION) {
            return false;
        }
        const isSuperAdmin = this.permissions.filter(permission => permission.permission === "superAdmin").length;

        if (hasPermission === "manageAdapter" &&
            _get(this.customer, "customer_id") !== +401 &&
            _get(this.customer, "customer_id") !== +2 &&
            _get(this.customer, "customer_id") !== +445 &&
            _get(this.customer, "customer_id") !== +419 &&
            _get(this.customer, "customer_id") !== +240 &&
            _get(this.customer, "customer_id") !== +455 &&
            _get(this.customer, "customer_id") !== +424 &&
            _get(this.customer, "customer_id") !== +364 &&
            _get(this.customer, "customer_id") !== +312 &&
            _get(this.customer, "customer_id") !== +502 &&
            _get(this.customer, "customer_id") !== +218 &&
            _get(this.customer, "customer_id") !== +252
        ) {
            return false;
        } else if (hasPermission === "manageAdapter") {
            return true;
        }

        if (
            hasPermission === "showImpactVue"
            && _get(this.customer, "customer_id") !== +303
            && _get(this.customer, "customer_id") !== +312
            && _get(this.customer, "customer_id") !== +257
            && _get(this.customer, "customer_id") !== +307
            && _get(this.customer, "customer_id") !== +281
            && _get(this.customer, "customer_id") !== +247
            && _get(this.customer, "customer_id") !== +275
            && _get(this.customer, "customer_id") !== +302
            && _get(this.customer, "customer_id") !== +242

            && _get(this.customer, "customer_id") !== +281
            && _get(this.customer, "customer_id") !== +311
            && _get(this.customer, "customer_id") !== +266
            && _get(this.customer, "customer_id") !== +340
            && _get(this.customer, "customer_id") !== +333
            && _get(this.customer, "customer_id") !== +325
            && _get(this.customer, "customer_id") !== +283
            && _get(this.customer, "customer_id") !== +299
            && _get(this.customer, "customer_id") !== +313
            && _get(this.customer, "customer_id") !== +269
            && _get(this.customer, "customer_id") !== +108
        ) {
            return false;
        } else if (hasPermission === "showImpactVue") {
            return true;
        }

        if (hasPermission === "showEquipmentProfile" && this.permissions.filter(permission => permission.permission === "editEquipment").length) {
            return true;
        }

        if (isSuperAdmin) {
            if(hasPermission === "manageBLEDevicesAndRoutes"){
                return !!this.customer.is_ble;
            }
            return true;
        }

        if (hasPermission === "editAlarms" && !this.permissions.filter(permission => permission.permission === "showAlarms").length) {
            return false;
        }

        if (hasPermission === "manageFullServiceEquipments" && !this.permissions.filter(permission => permission.permission === "showFullServiceEquipments").length) {
            return false;
        }

        return !!this.permissions.filter(permission =>
            permission.permission === hasPermission &&
            (group ? permission.group === group : true)
        ).length;
    }

    equipmentViewOnly = (equipment = {}) =>
        (equipment.service === "full" && !this.userCan(MANAGE_FULL_SERVICE_EQUIPMENTS_PERMISSION)) ||
        (equipment.service !== "full" && !this.userCan(MANAGE_SELF_SERVICE_EQUIPMENT_PERMISSION));

    setAllowedCustomers() {
        return CustomerApi.list().then(response => {
            this.allowedCustomers = _get(response, "list", []);

            this.cookiesCustomerId = cookies.load(process.env.CUSTOMER_COOKIE_PREFIX + "customer_id");
            if (!this.cookiesCustomerId && this.allowedCustomers.length > 1) {
                this.needSelectCustomer = true;
            }
        });
    }

    customerLogout = () => {
        cookies.save(process.env.CUSTOMER_COOKIE_PREFIX + "customer_id_previous", _get(this.customer, "customer_id"), { path: "/", domain: process.env.SSO_COOKIE_DOMAIN});
        cookies.remove(process.env.CUSTOMER_COOKIE_PREFIX + "customer_id", { path: "/", domain: process.env.SSO_COOKIE_DOMAIN});
        Helper.setStorageItem("location", null);
        window.location.replace("/");
    }

    hasAzureStorage = () => {
        return _get(this.customer, "storage") === CUSTOMER_STORAGE_AZURE;
    }

    isSwitchedCustomer = () => {
        const cookieCustomer = cookies.load(process.env.CUSTOMER_COOKIE_PREFIX + "customer_id");

        return !!(this.cookiesCustomerId && cookieCustomer && this.cookiesCustomerId !== cookieCustomer);
    }

    isAllLocations = (user) => {
        const customers = _get(user, "user_customers");
        const currentCustomer = _find(customers, {customer_id: +_get(this.customer, "customer_id")});
        return !!_get(currentCustomer, "all_locations");
    }

    hasRouteCollectorAccess = () => {
        return +_get(this.customer, "is_ble") === 1 && this.userCan("manageBLEDevicesAndRoutes");
    }
}

export default new AuthService();
