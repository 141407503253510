import React from "react";
import {withGlobalStore} from "../../../../../stores/GlobalStore";
import PropTypes from "prop-types";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {get as _get, maxBy as _maxBy, includes as _includes, filter as _filter} from "lodash";
import {useChartSelectedEquipmentStoreActions, useSelectedByEquipment} from "../../../../../stores/zustand/ChartSelectedEquipmentStore";
import {MOTES_LOT_DATA} from "../../../../../constants/constants";

const SensorRow = SortableElement(({className, installationPoint, checked, alertLevelColor, copy, pointMarker, auth, equipment, isRouteCollector}) => {
    const {toggleSelectedInstallationPoint} = useChartSelectedEquipmentStoreActions();

    let color = installationPoint.color || "#000000";
    const sensorHex = _get(installationPoint, "sensor_hex");

    return (
        <li
            id={"point_" + installationPoint.id}
            className={"sortable-list-horizontal-item " + (className || "")}
        >
            <label
                className={"form-checkbox "}
                key={installationPoint.id}
            >
                <input
                    type="checkbox"
                    name="sensor"
                    checked={checked}
                    onChange={() => toggleSelectedInstallationPoint(installationPoint.id, equipment.id)}
                />
                {installationPoint.name}

                {!isRouteCollector && (
                    <React.Fragment>
                        <br />
                        Hex Code:{" "}
                        <b
                            title="Click to copy Hex Code"
                            onClick={(e) => copy(e, sensorHex)}
                        >
                            {+sensorHex !== 0 ? sensorHex : "N/A"}
                        </b>
                    </React.Fragment>
                )}

                <span
                    className={"sensor-checkbox-wrapper"}
                    style={{backgroundColor: checked ? color : "inherit", borderColor: color}}
                />

                {!!alertLevelColor && auth.userCan("showAlarms") && (
                    <sup>
                        <span
                            className="badge badge-dot badge-lg"
                            style={{background: alertLevelColor}}
                        />
                    </sup>
                )}
                <SensorType
                    isRouteCollector={isRouteCollector}
                    installationPoint={installationPoint}
                />
                {_get(installationPoint, "sensor") && !_get(installationPoint, "sensor.node.gateway_id") && (
                    <React.Fragment>
                        <br />
                        {_includes(MOTES_LOT_DATA, _get(installationPoint, "sensor.node.lot_data")) ? (
                            <p
                                className={"m-0"}
                                style={{color: "#EA3C3C"}}
                            >
                                This sensor mote is not associated with a gateway.
                            </p>
                        ) : (
                            <p
                                className={"m-0"}
                                style={{color: "#EA3C3C"}}
                            >
                                This sensor is not currently connected to a node associated with a gateway.
                            </p>
                        )}
                    </React.Fragment>
                )}
                {pointMarker && (
                    <div className={"sensor-point-type " + pointMarker}>
                        {pointMarker === "circle" && <span style={{color: color, fontSize: "23px"}}>●</span>}
                        {pointMarker === "square" && <span style={{color: color, fontSize: "25px"}}>■</span>}
                        {pointMarker === "diamond" && <span style={{color: color, fontSize: "16px"}}>♦</span>}
                        {pointMarker === "triangle" && <span style={{color: color, fontSize: "15px"}}>▲</span>}
                        {pointMarker === "triangle-down" && <span style={{color: color, fontSize: "15px"}}>▼</span>}
                    </div>
                )}
            </label>
        </li>
    );
});
SensorRow.propTypes = {
    installationPoint: PropTypes.object,
    auth: PropTypes.object,
    alertLevelColor: PropTypes.string,
    checked: PropTypes.bool,
    copy: PropTypes.func,
    className: PropTypes.string,
    pointMarker: PropTypes.string,
};

const SensorSortableList = SortableContainer(({activeAlarms, copy, auth, installationPoints, sortableListId, equipment, isRouteCollector}) => {
    const pointMarkers = ["circle", "square", "diamond", "triangle", "triangle-down"];
    const equipmentSelected = useSelectedByEquipment(equipment.id);

    return (
        <ul
            id={sortableListId}
            className="sortable-list-horizontal"
        >
            {installationPoints.map((installationPoint, index) => {
                const alertLevelColor = (_maxBy(_filter(activeAlarms, ["installationPointId", installationPoint.id.toString()]), "priority") || {})
                    .alertLevelColor;
                let newMarkerIndex = equipmentSelected?.indexOf(+installationPoint.id, 0) ?? -1;
                newMarkerIndex = newMarkerIndex > 4 && newMarkerIndex > -1 ? newMarkerIndex % 5 : newMarkerIndex;
                const checked = _includes(equipmentSelected, installationPoint.id);

                return (
                    <SensorRow
                        auth={auth}
                        index={index}
                        key={installationPoint.id}
                        copy={copy}
                        checked={checked}
                        installationPoint={installationPoint}
                        alertLevelColor={alertLevelColor}
                        pointMarker={_get(pointMarkers, newMarkerIndex)}
                        equipment={equipment}
                        isRouteCollector={isRouteCollector}
                    />
                );
            })}
        </ul>
    );
});

SensorSortableList.propTypes = {
    equipment: PropTypes.object,
    auth: PropTypes.object,
    activeAlarms: PropTypes.array,
    copy: PropTypes.func,
    instPointType: PropTypes.string,
    installationPoints: PropTypes.array,
    sortableListId: PropTypes.string,
    isRouteCollector: PropTypes.bool,
};

const SensorType = ({isRouteCollector, installationPoint}) => {
    if (isRouteCollector) {
        return (
            <React.Fragment>
                <br />
                Sensor Type: <b>Route Collector</b>
            </React.Fragment>
        );
    }

    const versionName = _get(installationPoint, "sensor.version_name");

    if (versionName) {
        return (
            <React.Fragment>
                <br />
                Sensor Type: <b>{versionName}</b>
            </React.Fragment>
        );
    }

    return null;
};

SensorType.propTypes = {
    isRouteCollector: PropTypes.bool,
    installationPoint: PropTypes.object,
};

export default withGlobalStore(SensorSortableList);
