import React, {Component, Fragment, useState} from "react";
import PropTypes from "prop-types";
import {UncontrolledPopover , PopoverBody} from "reactstrap";
import get from "lodash/get";
import Helper from "../../helpers/helper";
import {Link} from "react-router-dom";
import {
    get as _get,
    find as _find
} from "lodash";
import {ResetSortButton} from "../shared/resetSortButton";
import EmailsPopover from "./emails-popover";
import LogPopover from "./log-popover";
import {AlarmInfo} from "./alarm-info";
import AcknowledgeModal from "../../modals/alarms/acknowledge";
import Alarm from "../../api/alarm";
import Toast from "../shared/toast";

const axisList = {
    x: 1,
    y: 2,
    z: 3,
};

const status = {
    0: {"title": "New", "class": "badge-info"},
    1: {"title": "Acknowledged", "class": "badge-warning"},
    2: {"title": "Ignored", "class": "badge-danger"},
    3: {"title": "Expired", "class": "badge-danger"}
};

const headersList = {
    "alertLevelName": {title: "Level", sort: true},
    "alertTriggeredDescription": {title: "Description", sort: true},
    "axis": {title: "Axis", sort: false},
    "equipmentName": {title: "Equipment", sort: true},
    "status": {title: "Status", sort: true},
    "timestamp": {title: "Created", sort: true},
    "log": {title: "Previous Triggers", sort: false},
    "sent_history": {title: "Emails Sent", sort: false},
    "acknowledge_by_user_id": {title: "Acknowledged by", sort: true},
    "acknowledged_timestamp": {title: "Acknowledged Time", sort: true}
};

class HistoryTable extends Component
{
    render() {
        const sort = this.props.sort || {};
        const filter = this.props.filter || {};
        const axisLabels = this.props.axisLabels || {};
        const settingsStates = this.props.settingsStates || {};
        const list = this.props.list || [];
        const {onSortChange, profile} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <div className="table-scroll">
                <table className="table table-hover tl-fixed">
                    <thead>
                        <tr>
                            {Object.keys(headersList).map((key) =>
                                listLen && headersList[key].sort
                                    ?
                                    <th key={key} onClick={() => onSortChange(key)}>
                                        <div className="cursor-pointer">
                                            {headersList[key].title}
                                            {(sort.field === key) ? <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")}/> : <i className="fa fa-sort"/>}
                                        </div>
                                    </th>
                                    :
                                    <th key={key}>{headersList[key].title}</th>)
                            }
                            <th className={"table-buttons-th"}>Action <ResetSortButton sort={sort} resetSort={() => onSortChange("")}/></th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.length > 0
                            ?
                            list.map((alarm) =>
                                <AlarmRow
                                    settingsStates={settingsStates}
                                    key={alarm.id}
                                    profile={profile}
                                    axisLabels={axisLabels}
                                    alarm={alarm}
                                    query={filter.query}
                                />
                            )
                            :
                            <tr>
                                <td colSpan={listLen} className="text-center text-info">
                                    {!filter.query ? "No alerts exist." : "No items match your search." }
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

HistoryTable.propTypes = {
    list: PropTypes.array,
    profile: PropTypes.object,
    settingsStates: PropTypes.object,
    sort: PropTypes.object,
    axisLabels: PropTypes.object,
    onSortChange: PropTypes.func,
    filter: PropTypes.object
};

const AlarmRow = ({alarm, profile, query, axisLabels, settingsStates}) => {
    const {user = {}, alertCondition = {}} = alarm;
    const {alertLevel = {}, installationPoint = {}} = alertCondition;
    const equipmentId = _get(installationPoint, "equipment.id") || alarm.equipmentId || "";
    const equipmentName = _get(installationPoint, "equipment.name") || alarm.equipmentName || "";
    const installationPointId = installationPoint.id || alarm.installationPointId || "";
    const installationPointType = installationPoint.point_type || alarm.installationPointType || "sensor";
    const installationPointName = installationPoint.name || alarm.installationPointName || "";
    const axisLabel = _get(axisLabels, [axisList[alarm.axis.toLowerCase()], alarm.installationPointId]);

    const emailLogWarning = _find(_get(alarm, "emailHistory", []), (item) => item.status > 1);

    return (
        <tr>
            <td><div className="badge badge-pill" style={{ background: alertLevel.color, color: "#fff" }}>{alertLevel.name}</div></td>
            <td>
                {Helper.highlight(alarm.description, query)}
                <AlarmInfo alarm={alarm} settingsStates={settingsStates}/>
            </td>
            <td>{axisLabel || alarm.axis.toUpperCase()}</td>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(equipmentName + " - " + installationPointName, query)}}/>
            <td><AlarmStatusCol alarm={alarm} profile={profile}/></td>
            <td data-order={alarm.timestamp}>{Helper.dateToUserFormat(alarm.timestamp, profile)}</td>
            <td className={`alarm-log-${alarm.id}`}>
                <span className={"cursor-pointer log-popover"} id={`popover-log-${alarm.id}`}>
                    <span className="fa-layers fa-fw">&nbsp;
                        <i className="fa fa-exclamation-triangle"/>
                        <span className="fa-layers-counter ml-2">{_get(alarm, "log", []).length}</span>
                    </span>
                    {_get(alarm, "log", []).length > 0 &&
                    <LogPopover
                        popoverProps={{
                            target: `popover-log-${alarm.id}`,
                            container: `.alarm-log-${alarm.id}`
                        }}
                        list={_get(alarm, "log", [])}
                    />
                    }
                </span>
            </td>
            <td className={`alarm-line-${alarm.id}` + (typeof emailLogWarning !== "undefined" ? " text-danger": "")}>
                <span className={"cursor-pointer emails-popover"} id={`emails-popover-${alarm.id}`}>
                    <span className="fa-layers fa-fw">&nbsp;
                        {/* <img className={"table-image-icon"} src={"/assets/pic/mail.svg"}/>&nbsp; */}
                        <MailIcon />
                        <span className="fa-layers-counter">{_get(alarm, "emailHistory", []).length}</span>
                    </span>
                    {_get(alarm, "emailHistory", []).length > 0 &&
                    <EmailsPopover
                        popoverProps={{
                            target: `emails-popover-${alarm.id}`,
                            container: `.alarm-line-${alarm.id}`
                        }}
                        list={_get(alarm, "emailHistory", [])}
                    />
                    }
                </span>
            </td>
            {alarm.ignore_type
                ?
                <td>System Ignore {alarm.notes && <i className={"fa fa-info-circle ml-1"} style={{color: "#449AFF"}} title={alarm.notes}/>}</td>
                :
                <td dangerouslySetInnerHTML={{__html: Helper.highlight(user.full_name, query)}}/>
            }
            <td data-order={alarm.acknowledged_timestamp}>{Helper.dateToUserFormat(alarm.acknowledged_timestamp, profile)}</td>
            <td>
                <div className="btn-group">
                    <Link
                        to={installationPointType === "sensor" ? `/chart/${equipmentId}#sensors=${installationPointId}` : `/chart/${equipmentId}`}
                        className="link link-primary"
                    >
                        <i className="fa fa-eye"/>
                        <span>View</span>
                    </Link>
                </div>
            </td>
        </tr>
    );
};

AlarmRow.propTypes = {
    alarm: PropTypes.object,
    settingsStates: PropTypes.object,
    profile: PropTypes.object,
    axisLabels: PropTypes.object,
    query: PropTypes.string
};

const AlarmStatusCol = ({alarm = {}, profile}) => {
    const [visible, setVisible] = useState(false);
    const [showEditNoteModal, setShowEditNoteModal] = useState(false);
    const [notes, setNotes] = useState(alarm.notes);

    const onEditNotes = (data) => {
        return Alarm.editNote(alarm.id, data).then(() => {
            setNotes(data.notes);
            setShowEditNoteModal(false);
            Toast.success("Alert note has been updated.");
        });
    };

    return (
        <Fragment>
            <div
                id={`popover-${alarm.id}`}
                className={`badge _badge ${get(status, [alarm.status, "class"], "")} round-badge mb-1`}
                // onClick={() => alarm.notes ? setVisible(!visible) : () => {}}
                style={{cursor: notes ? "pointer" : "default"}}
            >
                <span>{get(status, [alarm.status, "title"], "-")}</span>
            </div>
            {!!alarm.notes &&
                <UncontrolledPopover
                    placement="bottom"
                    isOpen={visible}
                    target={`popover-${alarm.id}`}
                    trigger="legacy"
                    toggle={() => setVisible(!visible)}
                >
                    <PopoverBody className="custom-scroll" style={{height: "auto", maxHeight: 450}}>
                        <span style={{color: "initial"}}>
                            {notes}
                            {(alarm.user && +profile.id === +alarm.user.id) &&
                                <button
                                    className={"link equipment-info-btn color-primary"}
                                    onClick={() => setShowEditNoteModal(true)}
                                >
                                    <i className="fa fa-pen" style={{margin:"0 5px"}}></i>
                                </button>
                            }
                        </span>
                    </PopoverBody>
                </UncontrolledPopover >
            }
            {showEditNoteModal &&
                <AcknowledgeModal
                    title="Edit Note"
                    showModal={true}
                    submitTitle="Save"
                    onSubmit={onEditNotes}
                    onClose={() => setShowEditNoteModal(false)}
                    note={notes}
                />
            }
        </Fragment>
    );
};

AlarmStatusCol.propTypes = {
    alarm: PropTypes.object,
    profile: PropTypes.object
};

const MailIcon = () => {
    return (
        <span className="table-image-icon mail-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 384">
                <path d="M394.67,149.33a117.33,117.33,0,1,0,58.68,219,10.66,10.66,0,1,0-10.69-18.46,96,96,0,1,1,48-83.17v10.67a21.33,21.33,0,1,1-42.67,0V234.67A10.63,10.63,0,0,0,430,227a53.32,53.32,0,1,0,4.51,74.8A42.6,42.6,0,0,0,512,277.33V266.67A117.47,117.47,0,0,0,394.67,149.33Zm0,149.33a32,32,0,1,1,32-32A32,32,0,0,1,394.67,298.67Z"/>
                <path d="M17.7,24.69l175.72,143a10.1,10.1,0,0,0,1.89,1.23,16.07,16.07,0,0,0,7.39,1.8c2.71,0,5.42-.71,9.38-3.1L387.65,24.7a10.66,10.66,0,0,0-.64-17A42.38,42.38,0,0,0,362.67,0h-320A42.34,42.34,0,0,0,18.33,7.67,10.66,10.66,0,0,0,17.7,24.69Z"/>
                <path d="M256,266.67C256,190.21,318.21,128,394.67,128a10.66,10.66,0,0,0,10.67-10.67V60.24a10.67,10.67,0,0,0-6.08-9.64A10.78,10.78,0,0,0,387.94,52L224.28,185.15l-1.87,1.24c-12.3,7.55-27.42,7.94-41.3-1.23L17.4,52A10.66,10.66,0,0,0,0,60.23V256a42.71,42.71,0,0,0,42.67,42.67H247a10.66,10.66,0,0,0,10.56-12.18A138.44,138.44,0,0,1,256,266.67Z"/>
            </svg>
        </span>
    );
};

export default HistoryTable;