import $ from "jquery";
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Modal} from "../../shared";
import PropTypes from "prop-types";
import {Loader} from "../../shared";
import Helper from "../../helpers/helper";
import ApiWhatsNew from "../../api/whatsNew";
import {get as _get} from "lodash";

class WhatsNewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            item: {
                title: "",
                description: "",
            },
            openImageModal: false,
            imageUrl: "",
        };
    }
    handleViewImage = (url) => {
        this.setState({
            openImageModal: true,
            imageUrl: url,
        });
    };
    componentDidMount() {
        const {id} = this.props;
        ApiWhatsNew.getItem(id).then(({item}) => {
            this.setState({
                item,
                loader: false,
            });
            $(".summernote-img-thumb").on("click", (e) => {
                this.handleViewImage($(e.target).attr("src"));
            });
        });
    }
    closeModal = () => {
        const {id} = this.props;
        const {item} = this.state;
        if (!_get(item, "viewed", []).length) {
            ApiWhatsNew.setViewed(id).then(({status}) => {
                if (status === "ok") {
                    this.closeModal();
                    this.props.updateCountActiveNews();
                }
            });
        }
        return this.props.history.push(Helper.deleteHashParams(["id", "modal"]));
    };
    handleSubmit = () => {
        this.closeModal();
    };

    render() {
        const {loader, item, openImageModal, imageUrl} = this.state;
        return (
            <React.Fragment>
                <Modal
                    showModal={true}
                    onClose={this.closeModal}
                    title={item.title}
                    parseTitleAsHtml
                    withoutSubmit={true}
                >
                    {loader ? (
                        <Loader />
                    ) : (
                        <React.Fragment>
                            <div
                                className="whats-new-description-modal-block"
                                dangerouslySetInnerHTML={{__html: item.description}}
                            ></div>
                        </React.Fragment>
                    )}
                </Modal>
                <Modal
                    title={"View Image"}
                    withoutFooter={true}
                    showModal={openImageModal}
                    onClose={() => this.setState({openImageModal: false, imageUrl: ""})}
                    size={"lg"}
                >
                    <div className="d-flex justify-content-center w-100">
                        <img
                            src={imageUrl}
                            alt=""
                        />
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

WhatsNewModal.propTypes = {
    history: PropTypes.object,
    id: PropTypes.number,
    updateCountActiveNews: PropTypes.func,
};

export default withRouter(WhatsNewModal);
