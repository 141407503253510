import React, {useState} from "react";
import {withGlobalStore} from "../../../../../stores/GlobalStore";
import DateSelector from "../../../components/chartsDateRange";
import PropTypes from "prop-types";
import ReadingTypesSortable from "../readingTypes/sortable";
import "./bottomSetting.scss";
import {useChartTypesStore} from "../../../../../stores/zustand/ChartSelectedChartTypeStore";
import {useChartTypesList} from "../../../hooks/useChartTypesList";

const BottomSettings = (props) => {
    const {equipment, user} = props;
    const chartTypes = useChartTypesStore();

    const [onChange, setOnChange] = useState(false);
    const {available, orderedSelectedCharts, sortedTypes, selectedCharts} = useChartTypesList();
    const toggleOnChange = () => {
        setOnChange((prev) => !prev);
    };

    return (
        <div className={"aside-bottom-settings"}>
            {!onChange ? (
                <div className={"d-flex mr-4"}>
                    <div className={"d-flex align-item-center mr-3"}>
                        <div className={"mr-3 setting-label"}>Selected:</div>
                        {orderedSelectedCharts.map((key, index) => (
                            <React.Fragment key={index}>
                                <div>{chartTypes[key].short}</div>
                                {selectedCharts.length > index + 1 && <div className={"separator"} />}
                            </React.Fragment>
                        ))}
                    </div>
                    <div className={"d-flex"}>
                        <div className={"mr-2 setting-label"}>Available:</div>
                        <span>{available} Other</span>
                    </div>
                </div>
            ) : (
                <div className={"aside-content"}>
                    <ReadingTypesSortable
                        equipment={equipment}
                        chartTypes={chartTypes}
                        chartOrderSettingsList={sortedTypes}
                    />
                </div>
            )}
            <div className={"circle-container position-relative"}>
                <div
                    className={"circle"}
                    onClick={toggleOnChange}
                >
                    <div>{onChange ? "Save" : "Change"}</div>
                </div>
            </div>
            <div className={"date-picker-container"}>
                <DateSelector user={user}/>
            </div>
        </div>
    );
};

BottomSettings.propTypes = {
    auth: PropTypes.object,
    equipment: PropTypes.object,
    user: PropTypes.object
};

export default withGlobalStore(BottomSettings);
