import Api from "./api";

const Mote = {
    list: (params) => {
        return Api.get("motes", {...params});
    },
    associate: (body) => {
        return Api.post("motes/associate", body);
    },
    validateSerial: (body) => {
        return Api.post("motes/validate-serial", body);
    },
    delete: (serial, unassignedMoteFromPoint) => {
        return Api.delete(`motes/${serial}` + (unassignedMoteFromPoint ? "?unassignedMoteFromPoint=1" : ""));
    },
    export: (params) => {
        return Api.source("motes/export", {...params});
    },
};

export default Mote;
