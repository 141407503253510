import {useQuery} from "@tanstack/react-query";
import ApiAlertCondition from "../../../api/alertCondition";
import {ALERT_CONDITIONS_API} from "./constants";
import {useMemo} from "react";
import {normalizeAlertCondition} from "../../../helpers/alertCondition";
import {get as _get, each as _each} from "lodash";
import {useSelectedTab} from "../../../stores/zustand/AlertConditionStore";
import {ALERT_TABS_NAME} from "../../../modals/alert/constants/tabs";

export const useAlertConditionListQuery = (chartType, installationPointIds, currentInstallPointId = null, equipment) => {
    const {data, ...rest} = useQuery(
        [ALERT_CONDITIONS_API.LIST, chartType, installationPointIds],
        () =>
            ApiAlertCondition.getList({
                chartType,
                installationPointIds,
            }),
        {
            cacheTime: 0,
        }
    );
    const alertType = useSelectedTab();

    const list = useMemo(() => ((data?.list || {})[+chartType] || []).map((alert) => normalizeAlertCondition(chartType, alert)) || [], [data?.list]);

    const groups = useMemo(() => ((data?.groups || {})[+chartType] || []).map((alert) => normalizeAlertCondition(chartType, alert)) || [], [data?.groups]);
    const alertConditionsCount = useMemo(
        () =>
            !list
                ? 0
                : !installationPointIds || +chartType === 14 || !installationPointIds?.indexOf
                ? list.length
                : list.filter(
                      (alertCondition) =>
                          installationPointIds.indexOf(alertCondition.installation_point_id) !== -1 && +alertCondition.isTachometerRpm !== 1
                  ).length,
        [list, installationPointIds]
    );

    const filteredList = useMemo(() => {
        if (!currentInstallPointId) {
            return list;
        }
        let filteredByInstallPoint = list;
        if (alertType === ALERT_TABS_NAME.LINEAR || alertType === ALERT_TABS_NAME.TACHOMETER_RPM) {
            filteredByInstallPoint = list.filter((item) => +item.installationPointId === currentInstallPointId);
        }
        return filteredByInstallPoint.map((i) => normalizeAlertCondition(chartType, i));
    }, [list, currentInstallPointId]);

    const getAlertConditionsGroupsFiltered = useMemo(() => () => getAlertConditionsGroups(), [groups]);

    const getAlertConditionsGroups = () => {
        let alertConditionsGroups = {};
        let inAllAxis = [];
        let temp = {};
        _each(groups, (alert) => {
            if (alert.group_id) {
                if (temp[alert.group_id]) {
                    if (+temp[alert.group_id] !== +alert.axis_id) {
                        inAllAxis.push(alert.group_id);
                    }
                } else {
                    temp[alert.group_id] = alert.axis_id;
                }
            }
        });
        _each(groups, (alert) => {
            if (alert.group_id) {
                const axisId = inAllAxis.indexOf(alert.group_id) !== -1 ? 0 : alert.axis_id;
                if (!alertConditionsGroups[axisId]) {
                    alertConditionsGroups[axisId] = {};
                }
                if (!alertConditionsGroups[axisId][alert.group_id]) {
                    alertConditionsGroups[axisId][alert.group_id] = {
                        id: alert.group_id,
                        points: [],
                        names: [],
                        alerts: {},
                    };
                }
                if (!alertConditionsGroups[axisId][alert.group_id]["alerts"][alert.alert_level_id]) {
                    alertConditionsGroups[axisId][alert.group_id]["alerts"][alert.alert_level_id] = normalizeAlertCondition(+chartType, alert);
                }
                if (alertConditionsGroups[axisId][alert.group_id]["points"].indexOf(alert.installation_point_id) === -1) {
                    alertConditionsGroups[axisId][alert.group_id]["points"].push(alert.installation_point_id);
                }

                const pointEquipmentId = _get(alert, "installationPoint.equipment.id");
                const pointEquipmentName = _get(alert, "installationPoint.equipment.name");
                const pointName = _get(alert, "installationPoint.name");
                const name = +pointEquipmentId === +(equipment || {}).id ? pointName : pointName.trim() + " (" + pointEquipmentName.trim() + ")";

                if (name && alertConditionsGroups[axisId][alert.group_id]["names"].indexOf(name) === -1) {
                    alertConditionsGroups[axisId][alert.group_id]["names"].push(name);
                }
            }
        });

        return alertConditionsGroups;
    };

    return {
        alertConditions: filteredList,
        alertConditionsGroup: groups,
        alertConditionsGroupNormalized: getAlertConditionsGroupsFiltered(),
        rawAlertConditions: data?.list,
        rawAlertConditionsGroup: data?.groups,
        alertConditionsCount,
        ...rest,
    };
};
