import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal} from "../../../shared";
import GlobalNotes from "../../../pages/notes";

import "../../../assets/scss/components/notes/notes-emails.scss";
class NotesModal extends Component
{
    render() {
        const {updateEquipmentNotesCount, history, equipmentName, updateRangeToChartMark, chartMarks, onClose} = this.props;

        return (
            <Modal
                {...this.props}
                size="xl"
                className={"custom-modal global-notes-modal"}
                title={equipmentName}
                withoutFooter={true}
            >
                <GlobalNotes
                    updateEquipmentNotesCount={updateEquipmentNotesCount}
                    updateRangeToChartMark={updateRangeToChartMark}
                    history={history}
                    chartMarks={chartMarks}
                    onCloseModal={onClose}
                    isModal={true}
                />
            </Modal>
        );
    }
}

NotesModal.propTypes = {
    showModal: PropTypes.bool,
    updateEquipmentNotesCount: PropTypes.func,
    history: PropTypes.object,
    equipmentName: PropTypes.string,
    updateRangeToChartMark: PropTypes.func,
    chartMarks: PropTypes.array,
    onClose: PropTypes.func
};

export default NotesModal;