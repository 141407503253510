import React, {Component} from "react";
import Toast from "../../../pages/shared/toast";
import HeaderSimple from "../../../shared/header/headerSimple/headerSimple";
import Loader from "../../../shared/loader/loader";
import SelectFormGroup, {InputFormGroup} from "../../../shared/formParts/formParts";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import SearchInput from "../../../pages/shared/search-input";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {
    get as _get,
    set as _set,
    each as _each,
    find as _find,
} from "lodash";
import WuaCustomSensorApi from "../../../api/wuaCustomSensor";
import WuaSensorApi from "../../../api/wuaSensor";

const formFields = [
    "hex",
    "sensor_hex",
    "wua_sensor_id",
];

class WUACustomSensorForm extends Component
{
    constructor(props) {
        super(props);

        this.wuaCustomSensorId = this.props.match.params.wuaCustomSensorId;

        this.state = {
            title: this.wuaCustomSensorId ? "Edit WUA Sensor" : "Add WUA Sensor",
            data: {},
            breadcrumbs: [
                {name: "Manage WUA Sensors", link: "/network/wua-custom-sensor"},
                {name: this.wuaCustomSensorId ? "Edit WUA Sensor" : "Add WUA Sensor"}
            ],
            loader: true,
            formErrors: {},
            globalSearchString: "",
            inProgress: false,
            wuaSensorsConfig: []
        };
    }

    getWuaCustomSensorObject(data = {}) {
        let wuaSensor = {};

        _each(formFields, (field) => {
            wuaSensor[field] = _get(data, field, null);
        });

        return wuaSensor;
    }

    componentDidMount() {
        Promise.all([
            this.fetchWuaSensors(),
            this.fetchWuaCustomSensor()
        ]).then(() => this.setState({loader: false}));
    }

    fetchWuaSensors = () => {
        return WuaSensorApi.getList().then(response => {
            if (response) {
                const {list = []} = response;
                this.setState({
                    loader: false,
                    wuaSensorsConfig: list
                });
            }
        });
    }

    fetchWuaCustomSensor = () => {
        if (!this.wuaCustomSensorId) {
            this.setState({data: this.getWuaCustomSensorObject()});
            return false;
        } else {
            return WuaCustomSensorApi
                .getView(this.wuaCustomSensorId)
                .then((response) => {
                    const result = _get(response, "result", {});
                    this.setState({
                        data: this.getWuaCustomSensorObject(result),
                    });
                });
        }
    }

    handleChangeGlobalSearch (globalSearchString) {
        this.setState({globalSearchString: globalSearchString});
    }

    onChange = (event) => {
        const data = {...this.state.data};

        const key = _get(event, "target.name", "");
        const val = _get(event, "target.value", "");

        _set(data, key, val);

        this.setState({data});
    }

    handleSubmit = () => {
        return this.wuaCustomSensorId ? this.onUpdate() : this.onCreate();
    }

    onCreate = () => {
        const {data} = this.state;

        this.setState({formErrors: {}, inProgress: true}, () => {
            WuaCustomSensorApi
                .create(data)
                .then(() => {
                    Toast.success("The WUA sensor has been created.");
                    this.props.history.push("/network/wua-custom-sensor");
                })
                .catch(response => {
                    this.setState({formErrors: response.errors || {}, inProgress: false});
                });
        });
    }

    onUpdate = () => {
        const {data} = this.state;

        this.setState({formErrors: {}, inProgress: true}, () => {
            WuaCustomSensorApi
                .update(this.wuaCustomSensorId, data)
                .then(() => {
                    Toast.success("The WUA sensor has been updated.");
                    this.setState({inProgress: false}, () => this.props.history.push(`/network/wua-custom-sensor/edit/${this.wuaCustomSensorId}`));
                })
                .catch(response => {
                    this.setState({formErrors: response.errors || {}, inProgress: false});
                });
        });
    }

    render() {
        const {
            data,
            breadcrumbs,
            globalSearchString,
            loader,
            inProgress,
            title,
            formErrors,
            wuaSensorsConfig
        } = this.state;
        const {auth} = this.props;

        if (!auth.userCan("manageAdapter")) {
            return "";
        }

        const currentWuaSensor = _find(wuaSensorsConfig, (wuaSensor) => +wuaSensor.id === +_get(data, "wua_sensor_id"));

        return (
            <div className="">
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">{title}</div>
                </div>
                {loader
                    ?
                    <div className="loader-fullscreen"><Loader/></div>
                    :
                    <div>
                        <div className="block">
                            <div className="block-body">
                                <div className={"row mb-2"}>
                                    <div className={"col-md-6"}>
                                        <InputFormGroup
                                            label={"Sensor Hex"}
                                            name={"hex"}
                                            value={_get(data, "hex")}
                                            errorMsg={_get(formErrors, "hex")}
                                            disabled={inProgress}
                                            isRequired={true}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className={"row mb-2"}>
                                    <div className={"col-md-6"}>
                                        <InputFormGroup
                                            label={"Adapter Hex"}
                                            name={"sensor_hex"}
                                            value={_get(data, "sensor_hex")}
                                            errorMsg={_get(formErrors, "sensor_hex")}
                                            disabled={inProgress}
                                            isRequired={true}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className={"row mb-2"}>
                                    <div className={"col-md-6"}>
                                        <SelectFormGroup
                                            label={"Sensor Configuration"}
                                            options={wuaSensorsConfig}
                                            optionValue={"id"}
                                            optionName={"name"}
                                            value={+_get(data, "wua_sensor_id")}
                                            valueName={"wua_sensor_id"}
                                            disabled={inProgress}
                                            errorMsg={_get(formErrors, "wua_sensor_id")}
                                            isRequired={true}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                {!!+_get(data, "wua_sensor_id") &&
                                    <div className={"row point-info wua-info p-2 py-4"}>
                                        <div className={"col-md-6"}>
                                            <InputFormGroup
                                                label={"Configuration Name"}
                                                value={_get(currentWuaSensor, "name")}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className={"col-md-6"}>
                                            <InputFormGroup
                                                label={"Reading Type"}
                                                value={_get(currentWuaSensor, "readingType.name") + "(" + _get(currentWuaSensor, "readingType.unit.unit")  + ")"}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className={"col-md-6"}>
                                            <InputFormGroup
                                                label={"Brand"}
                                                value={_get(currentWuaSensor, "brand")}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className={"col-md-6"}>
                                            <InputFormGroup
                                                label={"Model"}
                                                value={_get(currentWuaSensor, "model")}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className={"col-md-4"}>
                                            <InputFormGroup
                                                label={"Input Value Type"}
                                                value={_get(currentWuaSensor, "valueType.name")}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className={"col-md-4"}>
                                            <InputFormGroup
                                                label={"Ratio"}
                                                value={_get(currentWuaSensor, "ratio")}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className={"col-md-4"}>
                                            <InputFormGroup
                                                label={"Offset"}
                                                value={_get(currentWuaSensor, "offset")}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="text-right">
                            <Link to="/network/wua-custom-sensor" className="btn btn-sm btn-secondary">Cancel</Link>
                            <Button color="primary" size="sm" className="ml-2" disabled={inProgress} onClick={this.handleSubmit}>Save</Button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item ml-2">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

WUACustomSensorForm.propTypes = {
    title: PropTypes.string,
    auth: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
};

export default withGlobalStore(WUACustomSensorForm);