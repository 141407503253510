import { FFT_CHART_TYPES, fftAlertTypes, FREQUENCY_TYPES } from "../constants/constants";

export const normalizeAlertCondition = (chartType, alertCondition) => {
    if (!alertCondition) return;

    const axisId = alertCondition.axis_id || (alertCondition.readingType || {}).axis_id;
    const isChartFFT = FFT_CHART_TYPES.includes(chartType);

    let newAlert = {
        ...alertCondition,
        ...(isChartFFT ? {fftAlertType: alertCondition.fft_alert_type} : {}),
        groupId: alertCondition.group_id,
        alert_condition_id: alertCondition.id,
        alertLevelId: alertCondition.alert_level_id,
        fftAlertType: +alertCondition.fft_alert_type,
        chartType,
        installationPointId: alertCondition.installation_point_id,
        sms: (alertCondition.sms || 0).toString(),
        email: (alertCondition.email || 0).toString(),
        smsUsers: Object.values(alertCondition.smsUsers || {}),
        emailUsers: Object.values(alertCondition.emailUsers || {}),
        messenger: (alertCondition.messenger || 0).toString(),
        phone: (alertCondition.phone || 0).toString(),
        axis_id: axisId,
        axisId: axisId,
        rmsOd: alertCondition.rms_od ? "1" : "0",
        f_max: (alertCondition.fftSettings || {}).f_max,
        lines_of_resolution: (alertCondition.fftSettings || {}).lines_of_resolution,
    };

    if (+alertCondition.fft_alert_type === fftAlertTypes.ENVELOPE) {
        newAlert = {
            ...newAlert,
            existingEnvelopeData: (alertCondition.envelopeData || {}).data || [],
            fftEnvelopeType: (alertCondition.fft_envelope_type || 1).toString(),
            fftEnvelopePercent: (alertCondition.fft_envelope_percent || 5).toString(),
            fftEnvelopeStd: (alertCondition.fft_envelope_std || 1).toString(),
            fftEnvelopeSpectralWindow: alertCondition.fft_envelope_spectral_window_width ? "1" : "0",
            fftEnvelopeCalcType: (alertCondition.fft_envelope_calc_type || 1).toString(),
            fftEnvelopeSpectralWindowWidth: (alertCondition.fft_envelope_spectral_window_width || 0).toString(),
            fftEnvelopeMinimum: alertCondition.fft_envelope_minimum_value ? "1" : "0",
            fftEnvelopeMinimumValue: (alertCondition.fft_envelope_minimum_value || 0).toString(),
            followingType: +alertCondition.following_type,
            avgLastRCount: alertCondition.avg_last_r_count,
            avgLastDCount: alertCondition.avg_last_d_count,
            avgDateFrom: alertCondition.avg_date_from,
            avgDateTo: alertCondition.avg_date_to,
        };
    } else {
        newAlert = {
            ...newAlert,
            ...(+alertCondition.fft_alert_type === fftAlertTypes.BAND
                ? {
                      fftBandFrequencyType: alertCondition.fft_band_frequency_type || FREQUENCY_TYPES.HZ,
                      fftBandFrom: (alertCondition.fft_band_from || 0).toString(),
                      fftBandTo: (alertCondition.fft_band_to || 1500).toString(),
                      index: `${alertCondition.alert_level_id}-${alertCondition.id}`,
                  }
                : {}),
            ...(+alertCondition.is_tachometer_rpm === 1
                ? {
                      isTachometerRpm: "" + alertCondition.is_tachometer_rpm,
                      tachometerRpmFrom: "" + alertCondition.tachometer_rpm_from,
                      tachometerRpmTo: "" + alertCondition.tachometer_rpm_to,
                      index: `${alertCondition.alert_level_id}-${alertCondition.id}`,
                  }
                : {}),
            condition: alertCondition.condition,
            value: alertCondition.value,
            notificationType: alertCondition.notification_type,
            requireReadings: alertCondition.require_readings || "",
        };
    }

    return newAlert;
};
