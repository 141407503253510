import React from "react";
import PropTypes from "prop-types";
import Helper from "../../../helpers/helper";
import EmailTemplate from "./email-template";
import AlertActions from "./alert-actions";
import {AVG_READINGS_MAX_COUNT, FREQUENCY_TYPES, SERVER_DATETIME_FORMAT} from "../../../constants/constants";
import DatePickerInput from "../../../shared/datePickerInput/datePickerInput";
import moment from "moment";
import {withGlobalStore} from "../../../stores/GlobalStore";
import SelectWrapper from "../../../helpers/select-wrapper";
import RmsOdTemplate from "./rms-od-template";
import {useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import {get as _get} from "lodash";

const followingTypes = {
    1: "Current visible reading",
    2: "Recent readings",
    3: "Recent days",
    4: "Date range",
};

const EnvelopeAlertTemplate = ({isCurrent, isChanged, inProgress, stdDeviations, alert, user, users, level, update, remove, reset, save, setCurrent}) => {
    const hashParams = Helper.getHashParams();
    const chartType = hashParams.type || "1";
    const chartTypes = useChartTypesStore();

    const alias = _get(chartTypes, [chartType, "alias"]) || "";

    if (alert.fft_alert_type !== 2 && alert.fftAlertType !== 2) return "";
    return (
        <div
            className={`form-group mb-3 alert-level-block-big alert-level-block${isCurrent ? " current" : ""}`}
            onClick={() => (isCurrent ? {} : setCurrent(alert))}
        >
            <div className="row flex-xl-nowrap justify-content-between">
                <div className="col col-12 col-xl-auto mb-3 mb-xl-0">
                    <label className={level.name === "Caution" ? "level-label caution-level" : "level-label warning-level"}>{level.name || ""}</label>
                </div>
                <div className="col-12 col-md-6 col-xl-8 mb-3 mb-md-0">
                    <div className="row">
                        <div className="col-md-auto flex-grow-1 text-right">
                            <span className="label-horizontal-input d-block mt-2">Based on</span>
                        </div>
                        <div className="col-md-8">
                            <SelectWrapper
                                style={{
                                    width: "100%",
                                    display: "block",
                                }}
                                value={alert.followingType || ""}
                                onChange={(ev) => update({followingType: ev.target.value})}
                                disabled={!isCurrent}
                                name={"following-type"}
                            >
                                {Object.keys(followingTypes).map((index) => (
                                    <option
                                        key={index}
                                        value={index}
                                    >
                                        {followingTypes[index]}
                                    </option>
                                ))}
                            </SelectWrapper>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="form-group row mb-2">
                <div className="col-7">
                    {+alert.followingType === 2 && (
                        <div className="d-flex align-items-center">
                            <span className="mb-0 mr-4">Readings</span>
                            <input
                                type="number"
                                min={0}
                                max={AVG_READINGS_MAX_COUNT}
                                value={alert.avgLastRCount || ""}
                                step={1}
                                className="form-control request-form-control "
                                onChange={(ev) => update({avgLastRCount: ev.target.value})}
                            />
                        </div>
                    )}
                    {+alert.followingType === 3 && (
                        <div className="d-flex align-items-center">
                            <span className="mr-4">Day(s)</span>
                            <input
                                type="number"
                                min={0}
                                step={1}
                                value={alert.avgLastDCount || ""}
                                className="form-control request-form-control"
                                onChange={(ev) => update({avgLastDCount: ev.target.value})}
                            />
                        </div>
                    )}
                    {+alert.followingType === 4 && (
                        <div className="d-flex align-items-center">
                            <div className="mr-2">From</div>
                            <DatePickerInput
                                displayFormat={Helper.getUserDateFormat(user, true).date}
                                valueFormat={SERVER_DATETIME_FORMAT}
                                className="form-control request-form-control"
                                value={alert.avgDateFrom || ""}
                                onChangeHandler={(value) => update({avgDateFrom: value})}
                                maxDate={alert.avgDateTo || moment().format(SERVER_DATETIME_FORMAT)}
                                minDate={moment().add(-1, "year").format(SERVER_DATETIME_FORMAT)}
                                portalPosition={"date-picker-portal-modal"}
                            />
                            <span className="mr-2 ml-2">To</span>
                            <DatePickerInput
                                displayFormat={Helper.getUserDateFormat(user, true).date}
                                valueFormat={SERVER_DATETIME_FORMAT}
                                className="form-control request-form-control"
                                value={alert.avgDateTo || ""}
                                onChangeHandler={(value) => update({avgDateTo: value})}
                                minDate={alert.avgDateFrom || ""}
                                maxDate={moment().format(SERVER_DATETIME_FORMAT)}
                                portalPosition={"date-picker-portal-modal"}
                            />
                        </div>
                    )}
                </div>
                <div className="col-5">
                    {+alert.followingType !== 1 && (
                        <div className="form-group row-fft mb-2">
                            <div className="d-flex align-items-center mt-2">
                                <label className="form-radio">
                                    <input
                                        type="radio"
                                        value={1}
                                        checked={+alert.fftEnvelopeCalcType === 1}
                                        onChange={(ev) => update({fftEnvelopeCalcType: Helper.getInputValue(ev.target)})}
                                        disabled={!isCurrent}
                                    />
                                    <span />
                                    &nbsp;
                                </label>
                                <span>Average</span>
                                <label className="ml-4 form-radio">
                                    <input
                                        type="radio"
                                        value={2}
                                        checked={+alert.fftEnvelopeCalcType === 2}
                                        onChange={(ev) => update({fftEnvelopeCalcType: Helper.getInputValue(ev.target)})}
                                        disabled={!isCurrent}
                                    />
                                    <span />
                                    &nbsp;
                                </label>
                                <span>Peak</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="form-group row mb-2">
                <div className="col-12">
                    <div className="form-group row-fft mb-2">
                        <div className="d-flex align-items-center">
                            <label className="form-checkbox form-checkbox--alerts">
                                <input
                                    type="checkbox"
                                    value={1}
                                    checked={+alert.fftEnvelopeSpectralWindow === 1}
                                    onChange={(ev) => update({fftEnvelopeSpectralWindow: Helper.getInputValue(ev.target)})}
                                    disabled={!isCurrent}
                                />
                                <span />
                                &nbsp;
                            </label>
                            <input
                                type="number"
                                min={0}
                                step={1}
                                value={alert.fftEnvelopeSpectralWindowWidth || ""}
                                className="form-control form-control-inline request-form-control"
                                onChange={(ev) => update({fftEnvelopeSpectralWindowWidth: ev.target.value})}
                                disabled={!isCurrent || +alert.fftEnvelopeSpectralWindow !== 1}
                            />
                            <span>Spectral window width in {FREQUENCY_TYPES.HZ}</span>
                        </div>
                    </div>
                    <div className="form-group row-fft mb-2">
                        <div className="d-flex align-items-center">
                            <label className="form-checkbox form-checkbox--alerts">
                                <input
                                    type="checkbox"
                                    value={1}
                                    checked={+alert.fftEnvelopeMinimum === 1}
                                    onChange={(ev) => update({fftEnvelopeMinimum: Helper.getInputValue(ev.target)})}
                                    disabled={!isCurrent}
                                />
                                <span />
                                &nbsp;
                            </label>
                            <input
                                type="number"
                                min={0}
                                step={0.01}
                                value={alert.fftEnvelopeMinimumValue || ""}
                                className="form-control form-control-inline request-form-control"
                                onChange={(ev) => update({fftEnvelopeMinimumValue: ev.target.value})}
                                disabled={!isCurrent || +alert.fftEnvelopeMinimum !== 1}
                            />
                            <span> Minimum {level.name.toLowerCase()} value</span>
                        </div>
                    </div>
                    <div className="form-group row-fft mb-2">
                        <div className="d-flex align-items-center">
                            <label className="form-radio">
                                <input
                                    type="radio"
                                    value={1}
                                    checked={+alert.fftEnvelopeType === 1}
                                    onChange={(ev) => update({fftEnvelopeType: Helper.getInputValue(ev.target)})}
                                    disabled={!isCurrent}
                                />
                                <span />
                                &nbsp;
                            </label>
                            <input
                                type="number"
                                value={alert.fftEnvelopePercent || ""}
                                step={5}
                                className="form-control form-control-inline request-form-control"
                                onChange={(ev) => update({fftEnvelopePercent: ev.target.value})}
                                disabled={!isCurrent || +alert.fftEnvelopeType !== 1}
                            />
                            <span>Percent above base graph</span>
                        </div>
                    </div>
                    <div className={`form-group row-fft  mb-2 ${stdDeviations ? "" : "envelope-disabled"}`}>
                        <div className="d-flex align-items-center">
                            <label className="form-radio">
                                <input
                                    type="radio"
                                    value={2}
                                    checked={+alert.fftEnvelopeType === 2}
                                    onChange={(ev) => update({fftEnvelopeType: Helper.getInputValue(ev.target)})}
                                    disabled={!isCurrent || !stdDeviations}
                                />
                                <span />
                                &nbsp;
                            </label>
                            <input
                                type="number"
                                value={alert.fftEnvelopeStd || ""}
                                step={1}
                                className="form-control form-control-inline request-form-control"
                                onChange={(ev) => update({fftEnvelopeStd: ev.target.value})}
                                disabled={!isCurrent || +alert.fftEnvelopeType !== 2}
                            />
                            Standard deviations above base graph
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <RmsOdTemplate
                alert={alert}
                alias={alias}
                isCurrent={isCurrent}
                update={update}
            />
            <EmailTemplate
                isCurrent={isCurrent}
                users={users}
                alert={alert}
                level={level}
                update={update}
            />
            <div>
                <AlertActions
                    isEnvelope={true}
                    isCurrent={isCurrent}
                    inProgress={inProgress}
                    isChanged={isChanged}
                    reset={reset}
                    level={level}
                    save={save}
                    remove={remove}
                    alert={alert}
                />
            </div>
        </div>
    );
};

EnvelopeAlertTemplate.propTypes = {
    stdDeviations: PropTypes.bool,
    inProgress: PropTypes.bool,
    isCurrent: PropTypes.bool,
    isChanged: PropTypes.bool,
    alias: PropTypes.string,
    users: PropTypes.array,
    user: PropTypes.object,
    alert: PropTypes.object,
    level: PropTypes.object,
    versionType: PropTypes.number,
    setCurrent: PropTypes.func,
    update: PropTypes.func,
    remove: PropTypes.func,
    reset: PropTypes.func,
    save: PropTypes.func,
    specs: PropTypes.object,
};

export default withGlobalStore(EnvelopeAlertTemplate);
