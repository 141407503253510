import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal} from "../../../shared";
import {get as _get, filter as _filter, remove as _remove, includes as _includes, orderBy as _orderBy} from "lodash";
import ApiReading from "../../../api/reading";
import Helper from "../../../helpers/helper";

class DeleteReadingModal extends Component {
    constructor(props) {
        super(props);

        let selected = [];
        const points = _filter(_get(this.props, "points", {}), (point) => {
            const seriaID = _get(point, "series.userOptions.id", false);
            point.uniqueId = seriaID + "_" + _get(point, "category", false);
            if (this.props.isBatch) {
                selected.push(point.uniqueId);
            }
            return !_includes(seriaID, "tachSeria") && !_includes(seriaID, "ambientTemperature") && point.y !== null;
        });
        if (!this.props.isBatch && points.length === 1) {
            selected.push(_get(points, "0.series.userOptions.id"));
        }

        this.state = {
            inProgress: false,
            list: _orderBy(points, [this.props.isBatch ? "x" : "y"], [this.props.isBatch ? "asc" : "desc"]),
            selected: selected,
        };

        this.uniqueKey = this.props.isBatch ? "uniqueId" : "series.userOptions.id";

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getData = this.getData.bind(this);
        this.getTachData = this.getTachData.bind(this);
    }

    onChange(e) {
        const {selected} = this.state;
        const {value} = e.target;

        if (e.target.checked) {
            selected.push(value);
        } else {
            _remove(selected, (el) => {
                return el === value;
            });
        }

        this.setState({selected});
    }

    onSubmit() {
        this.setState({inProgress: true}, () => {
            if (this.props.chartTypes[this.props.chartType].type === "tach") {
                const data = this.getTachData();
                ApiReading.deleteTachReading(data)
                    .then(() => {
                        this.setState({inProgress: false}, () => {
                            this.props.onSubmit();
                        });
                    })
                    .catch(() => {
                        this.setState({inProgress: false});
                    });
            } else {
                const data = this.getData();

                this.props.onSubmit(data);
                this.setState({inProgress: false});
            }
        });
    }

    getData() {
        let readings = [];
        const {list, selected} = this.state;
        const {chartType} = this.props;

        list.map((item) => {
            const chartID = _get(item, "series.userOptions.id", false);
            const selectedId = _get(item, this.uniqueKey, false);
            if (_includes(selected, selectedId)) {
                const chartData = chartID.split("_");
                if (chartData.length === 2) {
                    const time = _get(item, "category", false);
                    readings.push({
                        time: time > 0 ? time / 1000 : time,
                        installationPointID: chartData[0],
                        chartType: chartType,
                        axisID: chartData[1],
                    });
                }
            }
        });

        return {
            readings: readings,
        };
    }

    getTachData() {
        let readings = [];
        const {list} = this.state;
        const item = list[0];
        const series = _get(item, "series", false);

        if (series) {
            const time = _get(item, "category", false);
            readings.push({
                time: time > 0 ? time / 1000 : time,
                installationPointID: series.userOptions.installationPointId,
            });
        }

        return {
            readings: readings,
            equipmentId: this.props.equipment.id,
        };
    }

    render() {
        const {inProgress, list, selected} = this.state;
        const {chartType, chartTypes} = this.props;

        return (
            <Modal
                {...this.props}
                size="xs"
                title={"Delete Data Reading"}
                onSubmit={this.onSubmit}
                submitTitle={"Delete"}
                inProgress={inProgress}
            >
                <React.Fragment>
                    <div className="alert-box">
                        {chartTypes[chartType].type === "tach" ? (
                            <div
                                className="alert alert-outline-danger"
                                role="alert"
                            >
                                <div className="alert-icon">
                                    <i className="fa fa-exclamation-triangle" />
                                </div>
                                <div className="alert-text text-left">
                                    Are you sure you want to delete tach reading?
                                    <br />
                                    This action is not reversible.
                                </div>
                            </div>
                        ) : (
                            <React.Fragment>
                                {selected.length > 0 ? (
                                    <div
                                        className="alert alert-outline-danger"
                                        role="alert"
                                    >
                                        <div className="alert-icon">
                                            <i className="fa fa-exclamation-triangle" />
                                        </div>
                                        <div className="alert-text text-left">
                                            Are you sure you want to delete this data?
                                            <br />
                                            This action is not reversible.
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="alert alert-outline-info"
                                        role="alert"
                                    >
                                        <div className="alert-icon">
                                            <i className="fa fa-exclamation-circle" />
                                        </div>
                                        <div className="alert-text text-left">Please select which data you want to delete.</div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    {chartTypes[chartType].type !== "tach" && (
                        <div className="form-group row">
                            <div className="col-12">
                                <div className="form-checkbox-list">
                                    {list.map((item) => {
                                        const checked = _includes(selected, _get(item, this.uniqueKey));
                                        return (
                                            <label
                                                key={_get(item, this.uniqueKey)}
                                                className="form-checkbox white-checkbox"
                                            >
                                                <input
                                                    type="checkbox"
                                                    onChange={this.onChange}
                                                    name={"selected"}
                                                    checked={checked}
                                                    value={_get(item, this.uniqueKey)}
                                                />
                                                <p>
                                                    {_get(item, "series.userOptions.name", "")}
                                                    &nbsp;
                                                    <b>
                                                        {_get(item, "y", "")}&nbsp;{_get(item, "series.userOptions.units", "")}
                                                    </b>
                                                    <div>{Helper.dateToFormat(item.x, item.series.userOptions.datetimeFormat)}</div>
                                                </p>
                                                {checked ? (
                                                    <span
                                                        style={{
                                                            backgroundColor: _get(item, "series.userOptions.color", "#000"),
                                                            borderColor: _get(item, "series.userOptions.color", "#000"),
                                                        }}
                                                    />
                                                ) : (
                                                    <span style={{borderColor: _get(item, "series.userOptions.color", "#000")}} />
                                                )}
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            </Modal>
        );
    }
}

DeleteReadingModal.propTypes = {
    points: PropTypes.array,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    chartType: PropTypes.any,
    chartTypes: PropTypes.object,
    equipment: PropTypes.object,
    currentChart: PropTypes.object,
    isBatch: PropTypes.bool,
};

export default DeleteReadingModal;
