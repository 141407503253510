import {AXIS_LIST, DATEFORMAT} from "../../../../../constants/constants";
import {get as _get} from "lodash";
import moment from "moment";
import Helper from "../../../../../helpers/helper";

//TODO think about refactor !Pass props and state is bad solution
export const getChartMarksPlotLines = ({chartMarks, props, selectedEquipment, equipmentItem, currentChartMarkId, isShowChartMarks = true, seriesList}) => {
    if (!chartMarks?.length || !isShowChartMarks) {
        return [];
    }

    const needSkipped = (chartMark) => {
        const existPoint = !chartMark.installation_point_id || selectedEquipment[equipmentItem.id].indexOf(chartMark.installation_point_id) >= 0;

        if (_get(chartMark, "isAlertChange", false) === true) {
            return chartMark.chartTypes.includes(+_get(props, "chartType")) &&
                   seriesList.selected.includes(chartMark.axisId?.toString()) && existPoint;
        }

        return  existPoint;
    };

    return chartMarks
        .filter((chartMark) => needSkipped(chartMark))
        .map((chartMark) => {
            const dnClass = +currentChartMarkId === +chartMark.id ? "" : "invisible";
            const color = _get(chartMark, "type") === 1 ? "#e3bb31" : "#46b67f";
            const iconClass = _get(chartMark, "type") === 1 ? "fa fa-info" : "fa fa-thumbtack fa-rotate-315";
            const infoClass = _get(chartMark, "type") === 1 ? "info" : "";
            const timestamp = +moment.utc(chartMark.timestamp).format("x");
            const buttons = `
                    <div class="chart-mark-label-btns">
                        <div data-id="${chartMark.id}" class="btn btn-sm chart-mark-edit btn-primary">Edit</div>
                        <div data-id="${chartMark.id}" class="btn btn-sm chart-mark-delete btn-danger">Delete</div>
                    </div>`;

            const showButtons = _get(chartMark, "type") === 1 ? "" : buttons;

            return {
                width: 2,
                value: timestamp,
                dashStyle: "Dot",
                color: color,
                label: {
                    style: {
                        "z-index": 2,
                    },
                    useHTML: true,
                    text: `
                            <div class="chart-mark-wrapper chart-mark-color ${infoClass}">
                                <span class="default-line-label">
                                    <i class="${iconClass}"></i>
                                </span>

                                <div class="chart-mark-block" style="border-color: ${color};" data-chart-mark-id="${chartMark.id}">
                                    <div class="chart-mark-label open-left ${dnClass}" style="border-color: ${color}; box-shadow: 1px 1px 3px ${color};">
                                        <div class="custom-scroll chart-mark-label-text">
                                            ${labelBody(chartMark, showButtons, timestamp, props.user)}
                                        </div>
                                    </div>
                                </div>
                            </div>`,
                    rotation: 0,
                },
            };
        });
};

const labelBody = (chartMark, showButtons, timestamp, user) => {
    const showFields = {
        "value": "Value",
        "condition": "Condition",
        "ignore_until": "Ignore Until",
        "fft_band_frequency_type": "Frequency Type",
        "fft_band_from": "From",
        "fft_band_to": "To",
        "require_readings": "Required Readings",
        "email": "Emails Sent"
    };

    const isAlertChange = _get(chartMark, "isAlertChange", false) === true;
    const dateFormat = Helper.dateToFormat(timestamp, _get(user, "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0]));
    const alertAxis = Object.keys(AXIS_LIST).includes(chartMark.axisId?.toString()) ? "(" + Helper.getAxisName(+chartMark.axisId).toUpperCase() + "-Axis)" : "";

    let values = "";
    Object.keys(showFields).filter((field) => {
        if (_get(chartMark.data, field) !== undefined) {
            values += "<b>" + showFields[field] + ":</b> ";
            if (_get(chartMark.data, field + ".old.value") !== undefined) {
                values += "Old: " + _get(chartMark.data, field+".old.value") + " ";
            }
            if (_get(chartMark.data, field + ".new.value") !== undefined) {
                values += "New: " + _get(chartMark.data, field+".new.value") + " ";
            }
            values += "<br/>";
        }
    });

    if (isAlertChange) {
        return (`
            <div>
                <div><b>${chartMark.title || ""} ${alertAxis}</b></div>
                <div>
                    ${values}
                </div>
                <div>changed by <strong>${chartMark.action_by || ""}</strong> <b>${dateFormat}</b></div>
            </div>
        `);
    }

    return (`<div class="chart-mark-label-text">
        <span>${dateFormat}</span>
        <b>${chartMark.name}</b>
        <p style="white-space: normal">${chartMark.notes || ""}</p>
    </div>
        ${showButtons}
    `);
};


export const getChartHistoriesPlotLines = ({chartHistories, equipmentItem, selectedEquipment, user, isShowChartMarks = true}) => {
    if (!chartHistories?.length || !isShowChartMarks) {
        return [];
    }
    return chartHistories
        .filter((chartHistory) => selectedEquipment[equipmentItem.id].indexOf(+chartHistory.installation_point_id) !== -1)
        .map((chartHistory) => {
            const timestamp = +moment.utc(chartHistory.time_create).format("x");

            let installationPoint = {};
            equipmentItem.installationPoints.map((ip) => {
                if (ip.id == chartHistory.installation_point_id) {
                    installationPoint = ip;
                }
                return ip;
            });

            let sensorHex = null;
            if (parseInt(chartHistory.sensor_id)) {
                sensorHex = parseInt(chartHistory.sensor_id).toString(16).toUpperCase();
            }

            return {
                width: 2,
                value: timestamp,
                dashStyle: "Dot",
                color: "#5867dd",
                label: {
                    style: {
                        "z-index": 2,
                    },
                    useHTML: true,
                    text: `
                        <div class="chart-mark-wrapper chart-mark-color primary">
                            <span class="default-line-label">
                                <i class="fa fa-info"></i>
                            </span>
                            <div class="chart-mark-block" style="border-color: #5867dd;" data-chart-mark-id="${chartHistory.id}">
                                <div class="chart-mark-label open-left invisible" style="border-color: #5867dd; box-shadow: 1px 1px 3px #5867dd;">
                                    <div class="chart-mark-label-text">
                                    <span>${installationPoint.id ? installationPoint.name + (sensorHex ? " (" + sensorHex + ")" : "") : ""}</span>
                                    <span>${Helper.dateToFormat(
                                        timestamp,
                                        _get(user, "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0])
                                    )}</span>
                                    <b>${chartHistory.comment}</b>
                                    </div>
                                </div>
                            </div>
                        </div>`,
                    rotation: 0,
                },
            };
        });
};
