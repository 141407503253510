import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {filter as _filter, find as _find, get as _get, groupBy as _groupBy, includes as _includes, maxBy as _maxBy} from "lodash";
import {useAlertConditionListQuery} from "../../../../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {useMemo} from "react";
import {useSelected} from "../helpers/useSelected";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useIsTachometer} from "../../../hooks/useChartTypes";

export const useAlertsBuilder = (props) => {
    const {equipmentItem} = useEquipmentByParams();
    const chartType = useChartStateStoreContext((state) => state.settings.chartType, shallow);
    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList, shallow);
    const showAlerts = useChartStateStoreContext((state) => state.settings.showAlerts, shallow);
    const selectedInstallPoints = useSelected();
    const isTachometer = useIsTachometer(chartType);
    const installationPointIds = useMemo(
        () => ((equipmentItem || {}).installationPoints || []).map((point) => point.id),
        [equipmentItem?.installationPoints]
    );
    const {alertConditions} = useAlertConditionListQuery(chartType, installationPointIds);
    const alerts = alertConditions ? alertConditions : [];

    return useMemo(() => {
        let plotLines = [];

        if (showAlerts && props.auth.userCan("showAlarms")) {
            const alertListByValue = _groupBy(
                _filter(
                    alerts,
                    (alert) =>
                        _includes(isTachometer ? installationPointIds : selectedInstallPoints || [], alert.installation_point_id) &&
                        +alert.group_id === 0 &&
                        +alert.isTachometerRpm !== 1 &&
                        (!seriesList.readingType.length ||
                            !alert.reading_type_id ||
                            _find(seriesList.readingType, (readingType) => +alert.reading_type_id === +readingType))
                ),
                "value"
            );

            if (!Object.keys(alertListByValue).length) {
                return plotLines;
            }

            for (let [value, alertList] of Object.entries(alertListByValue)) {
                const color = _get(
                    _maxBy(alertList, (alert) => _get(alert, "alertLevel.priority")),
                    "alertLevel.color",
                    ""
                );
                let text = "";

                for (let alert of alertList) {
                    const style = `background: ${alert.alertLevel.color}; height: 10px; width: 10px; margin-top: 10px;`;
                    const pointName = (_find(equipmentItem.installationPoints, ["id", alert.installation_point_id]) || {}).name || "";
                    text =
                        text +
                        `<span class="badge badge-square alert-badge-${chartType}" data-alert-id="${alert.id}" ` +
                        `data-installation-point-id="${alert.installation_point_id}" data-axis-id="${
                            alert.axis_id || (alert.readingType || {}).axis_id
                        }" ` +
                        `style="${style}" title="${pointName.replace(/"/g, "")}"></span> `;
                }

                plotLines.push({
                    color: color || alert.alertLevel.color,
                    width: 2,
                    label: {
                        align: "right",
                        useHTML: true,
                        text,
                        x: 10,
                    },
                    dashStyle: "longdash",
                    value: +value,
                });
            }
        }

        return plotLines;
    }, [alertConditions, showAlerts, selectedInstallPoints, seriesList.selected]);
};
