import Api from "./api";

const Sensor = {
    sensorList: params => Api.get("sensor/list", {...params}),
    get: (id, params = {}) => Api.get("sensor/" + id, {...params}),
    defaultFftSettings: id => Api.get("sensor/default-fft-settings/" + id),
    export: (params) => {
        return Api.source("sensor/export", {...params});
    },
};

export default Sensor;
