import React, {Component} from "react";
import PropTypes from "prop-types";
import Table from "./table";
import ApiUser from "../../api/user";
import Helper from "../../helpers/helper";
import {Loader, Pager} from "../../shared";
import SweetAlert from "react-bootstrap-sweetalert";
import UserResetPassword from "../../modals/user-reset";
import SearchInput from "../shared/search-input";
import {Link} from "react-router-dom";
import {withGlobalStore} from "../../stores/GlobalStore";
import LimitSelect from "../shared/limit-select";
import Toast from "../shared/toast";
import {
    get as _get
} from "lodash";
import {UncontrolledTooltip} from "reactstrap";
import { HeaderSimple } from "../../shared/header";
import {withLocationSelectStore} from "../../stores/LocationSelectStore";
import CollapseLocationSelect from "../../shared/collapseLocationSelect/collapseLocationSelect";

class Users extends Component
{
    constructor(props) {
        super(props);

        if (Helper.getHashParams().added) {
            Toast.success("User has been successfully added.");
            this.props.history.push(Helper.deleteHashParams(["added"]));
        } else if (Helper.getHashParams().updated) {
            Toast.success("User has been updated.");
            this.props.history.push(Helper.deleteHashParams(["updated"]));
        }

        this.state = {
            breadcrumbs: [{name: "Manage Users"}],
            loader: true,
            isEmailLoading: false,
            availablePermissionGroup: {},
            list: [],
            pagination: {
                page: 1,
                pages: 1,
                perpage: Helper.getPerPage("users"),
                total: 0,
            },
            sort: {
                field: "",
                sort: "asc",
            },
            filter: {
                query: "",
                location_id: "",
            },
            deleteUserId: null,
            globalSearchString: "",
            resendEmailUserId: null,
        };

        this.finished = false;

        this.handleFetch = this.handleFetch.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handlePagerChange = this.handlePagerChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleResetPassword = this.handleResetPassword.bind(this);
        this.onDeleteUser = this.onDeleteUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    componentDidMount() {
        Promise.all([
            this.handleFetch(),
        ]);
    }

    handleFetch() {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        return Promise.all([
            ApiUser.getSelfUsers({
                query: {
                    sort: this.state.sort,
                    filter: this.state.filter,
                    perpage: this.state.pagination.perpage,
                    page: this.state.pagination.page,
                    excludeWCDUsers: 1,
                    excludeCurrentUser: 1,
                    withoutWaitesUsers: Number(!_get(this.props.auth, "isWaitesAccount"))
                },
                signal: this.signal
            })
        ]).then(([response]) => {
            if (response) {
                const {users = {}} = response;
                this.setState({
                    loader: false,
                    pagination: {
                        page: users.current_page || 1,
                        pages: users.last_page || 1,
                        perpage: users.per_page || 10,
                        total: users.total || 0
                    },
                    list: users.data || []
                });
            }
        });
    }

    handleSortChange(field = "") {
        const {sort} = this.state;

        this.setState({
            sort: Object.assign({}, {
                field,
                sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"
            })
        }, this.handleFetch);
    }

    handlePagerChange(page = 1) {
        this.setState({loader: true, pagination: Object.assign({}, this.state.pagination, {page})}, this.handleFetch);
    }

    handleFilterChange(obj = {}) {
        this.finished = false;
        this.setState({
            filter: Object.assign({}, this.state.filter, obj),
            pagination: Object.assign({}, this.state.pagination, {page: 1}),
            loader: true
        }, this.handleFetch);
    }

    handleResetPassword(data)
    {
        this.setState({isEmailLoading: true});
        ApiUser.resetPassword(data).then(({status, message}) => {
            if (status === "ok") {
                this.setState({isEmailLoading: false});

                if (_get(data, "type") === "byEmail") {
                    Toast.success("Email was sent.");
                } else {
                    Toast.success(message);
                }

                this.props.history.push(Helper.deleteHashParams(["id", "modal", "type"]));
            } else {
                Toast.error(message);
            }
        });
    }

    onDeleteUser(deleteUserId) {
        this.setState({deleteUserId});
    }

    onResendEmail = (resendEmailUserId) => {
        this.setState({resendEmailUserId});
    }

    resendEmail = () => {
        ApiUser.resendEmail({id: this.state.resendEmailUserId}).then(() => {
            this.onResendEmail(null);
            Toast.success("The activation email has been resent.");
        });
    }

    deleteUser() {
        ApiUser.delete({id: this.state.deleteUserId}).then(() => {
            this.onDeleteUser(null);
            this.handleFetch();
        });
    }

    searchChange(value) {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }

        this.handleFilterChange({query: value});
    }

    handleLimitChange = e => {
        const {value} = e.target;
        Helper.setPerPage("users", value);
        this.setState({
            pagination: {
                ...this.state.pagination,
                page: 1,
                perpage: value
            }
        }, this.handleFetch);
    };

    render() {
        const {
            breadcrumbs,
            loader,
            list,
            pagination,
            sort,
            filter,
            deleteUserId,
            globalSearchString,
            isEmailLoading,
            resendEmailUserId
        } = this.state;

        const {auth, history, getLocationFullNameById, locationLoading} = this.props;

        const searchParams = Helper.getHashParams();

        return (
            <div>
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">Manage Users</div>
                    {auth.userCan("editUsers") &&
                        <div className="subheader-controls">
                            <div className="subheader-toolbar">
                                <AddUserBtn hasAzureStorage={auth.hasAzureStorage()}/>
                            </div>
                        </div>
                    }
                </div>
                <div className="block">
                    <div className="block-header">
                        <div className="block-label">
                            <div className="form-list form-list--row">
                                <div className="form-group form-group--inline">
                                    <div className="form-label"><label>Asset Tree Branch:</label></div>
                                    <CollapseLocationSelect
                                        className={"form-control form-control-sm"}
                                        selectName={"location_id"}
                                        value={+_get(filter, ["location_id"], 0)}
                                        onChange={(e) => {
                                            this.handleFilterChange({
                                                location_id: _get(e, "target.value", "")
                                            });
                                        }}
                                        emptyOptionLabel={"All"}
                                        style={{maxWidth:"200px", width:150}}
                                        checkStoreValue={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="top-pagination-block" className="block-pagination">
                            {pagination.total > 10 &&
                            <div className="limit-select-block">
                                <LimitSelect
                                    name={"perpage"}
                                    onChange={this.handleLimitChange}
                                    defaultValue={pagination.perpage}
                                    limits={[10, 20, 50, 100]}
                                />
                            </div>
                            }
                            {pagination.pages > 1 &&
                            <Pager
                                page={pagination.page}
                                pages={pagination.pages}
                                perPage={pagination.perpage}
                                onPagerChange={this.handlePagerChange}
                            />
                            }
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <div>
                                    <SearchInput
                                        placeholder="Search Users"
                                        query={filter.query}
                                        onChange={this.searchChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-body">
                        {loader || locationLoading
                            ?
                            <div className="loader-fullscreen"><Loader/></div>
                            :
                            <Table
                                list={list}
                                sort={sort}
                                onSortChange={this.handleSortChange}
                                onOpenEditUserModal={(id) => history.push(Helper.setHashParams({modal: "edit", id}))}
                                onResetPassword={(id) => history.push(Helper.setHashParams({modal: "reset", id}))}
                                onDeleteUser={this.onDeleteUser}
                                onResendEmail={this.onResendEmail}
                                query={filter.query}
                                getLocationFullNameById={getLocationFullNameById}
                            />
                        }
                    </div>
                    {deleteUserId &&
                        <SweetAlert
                            warning
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnBsStyle="default"
                            btnSize="xs"
                            title="DELETE USER"
                            onConfirm={this.deleteUser}
                            onCancel={() => this.onDeleteUser(null)}
                        >
                            Are you sure you want to delete this user?
                        </SweetAlert>
                    }
                    {resendEmailUserId &&
                        <SweetAlert
                            warning
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnBsStyle="default"
                            btnSize="xs"
                            title="Resend the activation email"
                            onConfirm={this.resendEmail}
                            onCancel={() => this.onResendEmail(null)}
                        >
                            Are you sure you want to resend the activation email for this user?
                        </SweetAlert>
                    }
                    {searchParams.modal === "reset" && !!searchParams.id &&
                        <UserResetPassword
                            title={"Reset password"}
                            showModal={true}
                            history={history}
                            userId={searchParams.id}
                            isEmailLoading={isEmailLoading}
                            handleSubmit={this.handleResetPassword}
                            submitTitle={"Reset"}
                            onClose={() => history.push(Helper.deleteHashParams(["id", "modal"]))}
                        />
                    }
                </div>
            </div>
        );
    }
}
const AddUserBtn = ({hasAzureStorage}) => {

    if (hasAzureStorage) {
        return <span className="btn btn-secondary btn-sm disabled" id="add-user-btn">
            <i className="fa fa-plus"/>
            <UncontrolledTooltip popperClassName="tooltip-md" placement="top" target="add-user-btn">
                You do not have permission to add users for this customer.
            </UncontrolledTooltip>
            Add User
        </span>;
    }

    return <Link
        to="users/add"
        className="btn btn-primary btn-sm">
        <i className="fa fa-plus"/> Add User
    </Link>;
};

AddUserBtn.propTypes = {
    hasAzureStorage: PropTypes.bool
};

Users.propTypes = {
    user: PropTypes.object,
    auth: PropTypes.object,
    history: PropTypes.object,
    getLocationFullNameById: PropTypes.func,
    locationLoading: PropTypes.bool
};

export default withLocationSelectStore(withGlobalStore(Users));