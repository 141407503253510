export const AVAILABLE_TIME_VALUES = [
    {
        value: "-2 hours",
        label: "Last 2 Hours",
    },
    {
        value: "-6 hours",
        label: "Last 6 Hours",
    },
    {
        value: "-12 hours",
        label: "Last 12 Hours",
    },
    {
        value: "-1 day",
        label: "Last Day",
    },
    {
        value: "-2 days",
        label: "Last 2 Days",
    },
    {
        value: "-4 days",
        label: "Last 4 Days",
    },
    {
        value: "-1 week",
        label: "Last Week",
    },
    {
        value: "-2 weeks",
        label: "Last 2 Weeks",
    },
    {
        value: "-1 month",
        label: "Last Month",
    },
    {
        value: "-3 months",
        label: "Last 3 Months",
    },
    {
        value: "-6 months",
        label: "Last 6 Months",
    },
    {
        value: "-1 year",
        label: "Last Year",
    },
    {
        value: "-2 years",
        label: "Last 2 Years",
    },
];

export const NOTES_TAB_DAY = "day";
export const NOTES_TAB_MONTH = "month";
export const NOTES_TAB_HALF_YEAR = "half_year";
export const NOTES_TAB_YEAR = "year";