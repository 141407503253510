import {
    map as _map,
    find as _find,
    mapValues as _mapValues,
    get as _get
} from "lodash";

export const getImageInstallationPoint = (equipmentImages) => {
    return _map(equipmentImages, function (image) {
        return {
            imgId: image.id,
            installationPointId: image.installation_point_id
        };
    });
};


export const getUpdatedImageData = (images, current, positions, flipTurns) => {
    let updatedImages = [], updatedPositions = {}, updatedFlipTurns = {}, newImageIndex = 0;

    images.forEach((image, imageIndex) => {
        if (imageIndex !== +current) {
            updatedImages[newImageIndex] = images[imageIndex];
            updatedPositions[newImageIndex] = positions[imageIndex];
            updatedFlipTurns[newImageIndex] = flipTurns[imageIndex];
            newImageIndex++;
        }
    });

    return { updatedImages, updatedPositions, updatedFlipTurns };
};

export const readFileAsDataUrl = (file) =>
    (new Promise((resolve, reject) => {
        if (!file) {
            return reject("File is empty");
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    }));

export const updateImageAfterUpload = (prevImages, response) => {
    let newImages = [];
    Object.values(_mapValues(response.images, "url")).forEach(imageUrl => {
        if (!_find(prevImages, url => url === imageUrl)) {
            newImages.push(imageUrl);
        }
    });
    return newImages;
};

export const updatePositionOnImageFlip = (positions, current, flipTurn) => {
    const currentPositionKeys = Object.keys(positions[current] || {});
    if ((currentPositionKeys).length) {
        currentPositionKeys.forEach(key => {
            const cTop = positions[current][key].top / positions[current][key].percent_top;
            const cLeft = positions[current][key].left / positions[current][key].percent_left;
            let percentLeft, percentTop;
            if (flipTurn === 0.25) {
                percentTop = positions[current][key].percent_left;
                percentLeft = 100 - positions[current][key].percent_top;
            } else {
                percentLeft = positions[current][key].percent_top;
                percentTop = 100 - positions[current][key].percent_left;
            }
            positions[current][key].percent_top = percentTop;
            positions[current][key].percent_left = percentLeft;
            positions[current][key].top = Math.round(cLeft * percentTop);
            positions[current][key].left = Math.round(cTop * percentLeft);
        });
    }
    return positions;
};

export const getMainIdAndFlipTurnsById = (images, equipment, main, flipTurns) => {
    let mainId;
    let flipTurnsById = {};

    images.forEach((imageUrl, imageIndex) => {
        const id = (_find(equipment.images, { url: imageUrl }) || {}).id;
        if (imageIndex === main) mainId = id;
        if (flipTurns[imageIndex]) flipTurnsById[id] = flipTurns[imageIndex];
    });
    return { mainId, flipTurnsById };
};

export const updateImagesPositions = (positions, equipment) =>{
    Object.keys(positions).forEach(imageIndex => {
        if (positions[imageIndex]) {
            Object.keys(positions[imageIndex]).forEach(pointIndex => {
                positions[imageIndex][pointIndex].equipment_image_id = _get(equipment.images, [ imageIndex, "id" ]) || null;
                positions[imageIndex][pointIndex].installation_point_id = +pointIndex === 999 ? 0 : _get(equipment.installationPoints, [ pointIndex, "id" ]) || null;
            });
        }
    });
    return positions;
};