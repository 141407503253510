import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get,
    find as _find
} from "lodash";
import {ALERT_LEVELS_LIGHTER_COLORS} from "../../../../constants/constants";

class AlertsRow extends Component
{
    getValue = (axisId, alertLevelId, readingTypeId) => {
        const {profile, pointIndex} = this.props;
        const alertConditions = _get(profile, "alertsConditions."+pointIndex);

        return (_find(alertConditions, {axisId, alertLevelId, readingTypeId}) || {}).value;
    }

    getUnit = () => {

    }

    render() {
        const {installationPoint, readingTypes, alertLevels, showModal, pointIndex} = this.props;

        return (
            <React.Fragment>
                <tr>
                    <td>{_get(installationPoint, "name")}</td>
                    {readingTypes.map((type, index) =>
                        <td key={index}>
                            <div>
                                <div
                                    className={"config-alert"}
                                    onClick={() => showModal(index, pointIndex)}
                                >
                                    <img src="/assets/img/profile/setting-icon-blue.svg" alt=""/>
                                    <span>Configure</span>
                                </div>
                                {Object.keys(_get(type, "axis", {})).map((axisId, index) =>
                                    <div className={"alert-axis"} key={index}>
                                        <span className={"axis-name"}>{_get(type, "axis."+axisId+".axisName", "")}</span>
                                        {alertLevels.map((level, index) => {
                                            const readingTypeId = _get(type, "axis."+axisId+".readingType");
                                            const conditionValue = this.getValue(+axisId, +level.id, +readingTypeId);
                                            const unit = _get(type, "unit");

                                            const emptyColor = _get(ALERT_LEVELS_LIGHTER_COLORS, _get(level, "name"));
                                            const styleEmpty = {
                                                background: emptyColor ? emptyColor : "#fff",
                                                color: _get(level, "color"),
                                                border: "1px solid " + _get(level, "color")
                                            };

                                            return (
                                                <span
                                                    key={index}
                                                    className={"alert-level"}
                                                    style={conditionValue ? {background: _get(level, "color")} : styleEmpty}
                                                >
                                                    <span>{conditionValue ? conditionValue : String.fromCharCode(8212)}</span>
                                                    {conditionValue &&
                                                        <span className={"ml-1"}>{unit}</span>
                                                    }
                                                </span>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </td>
                    )}
                </tr>
            </React.Fragment>
        );
    }
}

AlertsRow.propTypes = {
    installationPoint: PropTypes.object,
    pointIndex: PropTypes.number,
    readingTypes: PropTypes.array,
    alertLevels: PropTypes.array,
    showModal: PropTypes.func,
    profile: PropTypes.object
};

export default AlertsRow;