import React, {Component} from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import NameplateParameterForm from "../../../pages/forms/nameplate/parameter/form";
import NameplateParameterApi from "../../../api/nameplateParameter";
import {
    get as _get,
} from "lodash";

const columnList = {
    "name": {title: "Parameter Name", className: "col-10"},
    "actions": {title: "Actions", className: "col-2"}
};

class ParametersTable extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            editIndex: null,
            isDelete: false
        };
    }

    onEdit = (index) => {
        const {onCancelCreateNew} = this.props;
        this.setState({editIndex: index}, onCancelCreateNew);
    }

    onCancel = () => {
        const {onCancelCreateNew} = this.props;
        this.setState({editIndex: null}, onCancelCreateNew);
    }

    onSave = () => {
        const {updateParameterList, updateList} = this.props;

        updateList();
        updateParameterList().then(() => {
            this.onCancel();
        });
    }

    onDelete = (index) => {
        this.setState({isDelete: index});
    }

    deleteParameter = (index) => {
        const {parameters, updateParameterList, updateList} = this.props;

        NameplateParameterApi.delete(_get(parameters, "["+index+"].id"))
            .then(() => {
                updateParameterList().then(() => {
                    this.setState({isDelete: false}, () => updateList());
                });
            });
    }

    render() {

        const {editIndex, isDelete} = this.state;
        const {parameters, createNew} = this.props;

        return (
            <React.Fragment>
                {createNew &&
                    <React.Fragment>
                        <ParameterHeader/>
                        <NameplateParameterForm
                            columnList={columnList}
                            onSave={this.onSave}
                            onCancel={this.onCancel}
                        />
                    </React.Fragment>
                }
                <React.Fragment>
                    <ParameterHeader/>
                    <div className={"parameters-body custom-scroll"}>
                        {(parameters || []).length > 0
                            ?
                            parameters.map((parameter, index) =>
                                <React.Fragment key={index}>
                                    {editIndex === index
                                        ?
                                        <NameplateParameterForm
                                            columnList={columnList}
                                            parameter={parameter}
                                            onSave={this.onSave}
                                            onCancel={this.onCancel}
                                        />
                                        :
                                        <ParameterRow
                                            parameter={parameter}
                                            onEdit={() => this.onEdit(index)}
                                            onDelete={() => this.onDelete(index)}
                                        />
                                    }
                                </React.Fragment>
                            )
                            :
                            <div className={"row parameter-row"}>
                                <div className="col-12 text-center text-info">
                                    No parameters exist.
                                </div>
                            </div>
                        }
                    </div>
                </React.Fragment>
                {isDelete !== false &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title="DELETE PARAMETER"
                        openAnim={false}
                        closeAnim={false}
                        onConfirm={() => this.deleteParameter(isDelete)}
                        onCancel={() => this.onDelete(false)}
                    >
                        Are you sure you want to delete this parameter?
                        <br/>
                        This will also delete this parameter from all nameplates that include this parameter.
                    </SweetAlert>
                }
            </React.Fragment>
        );
    }
}

const ParameterHeader = () => (
    <div className={"row parameter-header"}>
        {Object.keys(columnList).map((key, index) =>
            <div key={index} className={"title " + columnList[key].className}>
                <span>{columnList[key].title}</span>
            </div>
        )}
    </div>
);

class ParameterRow extends Component
{
    render() {
        const {parameter, onEdit, onDelete} = this.props;

        return (
            <React.Fragment>
                <div className={"row parameter-row"}>
                    <div className={columnList.name.className}>
                        <span>{parameter.name}</span>
                    </div>
                    <div className={columnList.actions.className}>
                        <div className="btn-group btn-group-sm">
                            <button
                                className="link link-primary"
                                onClick={onEdit}
                            >
                                <i className="fa fa-pen"/> <span>Edit</span>
                            </button>
                            <button
                                className="link link-danger"
                                onClick={onDelete}
                            >
                                <i className="fa fa-times"/> <span>Delete</span>
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ParameterRow.propTypes = {
    parameter: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};


ParametersTable.propTypes = {
    parameters: PropTypes.array,
    updateParameterList: PropTypes.func,
    updateList: PropTypes.func,
    createNew: PropTypes.bool,
    onCancelCreateNew: PropTypes.func
};


export default ParametersTable;