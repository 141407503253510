import { getSeriesList } from "../../pages/chart/highcharts/helpers/getSeriesList";
import { get as _get } from "lodash";
import { useMemo } from "react";
import { useChartTypesStore } from "../../stores/zustand/ChartSelectedChartTypeStore";

export const useSeriesList = (chartType) => {
    const chartTypes = useChartTypesStore();

    return useMemo(() => {
        let seriesList = getSeriesList(chartType, false, chartTypes);

        const currentSelected = JSON.parse(localStorage.getItem("selectedSeries")) || {};
        const selectedForCurrentChartType = _get(currentSelected, +chartType, []);

        if (selectedForCurrentChartType.length) {
            seriesList.selected = selectedForCurrentChartType;
        }

        return seriesList;
    }, [chartType]);
};
