import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal} from "reactstrap";
import ViewActionItem from "./view";

class ActionItemModal extends Component
{

    render() {
        const {actionItemId, info, user, history, onClose, updateList} = this.props;

        return (
            <React.Fragment>
                <Modal
                    isOpen={true}
                    toggle={onClose}
                    centered={false}
                    className={"action-item-modal"}
                >
                    <ViewActionItem
                        actionItemId={actionItemId}
                        info={info}
                        user={user}
                        history={history}
                        onClose={onClose}
                        updateList={updateList}
                    />
                </Modal>
            </React.Fragment>
        );
    }
}

ActionItemModal.propTypes = {
    actionItemId: PropTypes.number,
    info: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    type: PropTypes.string,
    user: PropTypes.object,
    onClose: PropTypes.func,
    updateList: PropTypes.func
};

export default ActionItemModal;