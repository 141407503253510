import React, {Component} from "react";
import {Modal} from "../../shared";
import {withGlobalStore} from "../../stores/GlobalStore";
import {Button} from "reactstrap";
import PrivacyPolicySettingsModal from "./privacy-policy-settings";
import Api from "../../api/api";
import {get as _get} from "lodash";

class PrivacyPolicyModal extends Component{
    constructor(props) {
        super(props);

        this.state = {
            data: {
                strictlyNecessary: 1,
                functionality: 0,
                trackingPerformance: 0,
                targeting: 0
            },
            displayManageSettingsModal: false,
            displayModal: true,
            loading: false
        };
    }

    onChangeData = (ev) => {
        const {name, checked} = ev.target;
        const {data} = this.state;

        this.setState({
            data: {
                ...data,
                [name]: checked ? 1 : 0
            }
        });
    };

    toggleManageSettingsModal = (isOpen = null) => {
        const {state} = this.state;
        this.setState({
            displayManageSettingsModal: isOpen !== null ? isOpen : !state.displayManageSettingsModal
        });
    };

    saveData = (data = {}) => {
        this.setState({
            loading: true
        }, () => Api.post("privacy-policy/set-settings", {
            privacy_data: {
                strictly_necessary: _get(data, "strictlyNecessary", 1),
                functionality: _get(data, "functionality", 1),
                tracking_performance: _get(data, "trackingPerformance", 1),
                targeting: _get(data, "targeting", 1)
            }
        })
            .then(() => {
                window.location.reload();
            }));
    };



    render() {
        const {displayModal, displayManageSettingsModal, loading} = this.state;

        if (displayManageSettingsModal) {
            return <PrivacyPolicySettingsModal
                onChangeData={this.onChangeData}
                isOpen={true}
                onClose={this.toggleManageSettingsModal}
                data={this.state.data}
                saveData={this.saveData}
                loading={loading}
            />;
        }

        return (
            <Modal showModal={displayModal} withoutFooter={true}>
                <>
                    <div className="modal-img-logo">
                        <img className="logo-main" src="/assets/img/landing/top-logo-black.svg" alt="" />
                        <img className="modal-flag-img" src="/assets/img/flag-ukr-x2.png" alt=""/>
                    </div>
                    <h4>Your privacy</h4>
                    <p>By clicking “Accept All”, you agree to the storing of cookies
                        on your device to enhance site navigation, analyze site usage,
                        and assist in our marketing efforts.
                    </p>

                    <div className="modal-control-btns">
                        <Button
                            color={"black"}
                            onClick={() => this.saveData()}
                        >
                            {loading
                                ? <span><i className="fa fa-spinner"/> Processing</span>
                                : "Accept All"
                            }
                        </Button>
                        <Button
                            color="white"
                            onClick={this.toggleManageSettingsModal}
                        >
                            Manage Settings
                        </Button>
                    </div>
                </>
            </Modal>
        );
    }
}

export default withGlobalStore(PrivacyPolicyModal);