import {filter as _filter} from "lodash";
import {useInstallationPoint} from "../useInstallationPoint";
import {useMemo} from "react";
import {useAlertConditionListQuery} from "../../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {useInstallationPointIds} from "../../../../hooks/helpers/useInstallationPointIds";

export const useTachometer = (equipment, chartType) => {
    const installationPoint = useInstallationPoint(equipment, chartType);
    const installationPointId = installationPoint.id;
    const installationPointIds = useInstallationPointIds(equipment);
    const {alertConditions:alerts} = useAlertConditionListQuery(chartType, installationPointIds, installationPointId);

    const tachometersList = useMemo(() => _filter(alerts, (alertCondition) => +alertCondition?.isTachometerRpm === 1) || [], [alerts]);
    const tachometerList = useMemo(
        () => _filter(tachometersList, (item) => +item.installationPointId === +installationPointId),
        [tachometersList]
    );
    return {tachometerList, tachometersList};
};
