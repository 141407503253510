import React, {Component} from "react";
import PropTypes from "prop-types";
import {ValidationError} from "../../../shared";
import InstallationPointCustomTypeForm from "../../forms/installation-point-custom-type/form";
import {
    get as _get,
} from "lodash";
import InstallationPointSpeedSettingInput from "../../../shared/form/InstallationPointSpeedSettingInput";

class InstallationPointsStep extends Component
{
    render() {
        const {
            profile,
            onChange,
            formErrors,
            addInstallationPoint,
            removeInstallationPoint,
            instPointCustomTypes,
            fetchInstPointCustomType,
            validateStep,
            inProgress
        } = this.props;
        const installationPoints = _get(profile, "installationPoints", []);

        return (
            <React.Fragment>
                <div className={"installation-points-step"}>
                    <div className={"step-header"}>
                        <div className={"step-title text-uppercase"}>Add Installation Points</div>
                        {/*{!!installationPoints.length &&
                            <div
                                className="btn btn-sm cust-btn btn-primary"
                                onClick={addInstallationPoint}
                            >
                                <i className="fa fa-plus"/>
                                Add Installation Point
                            </div>
                        }*/}
                    </div>

                    <div className={"inst-points-wrapper"}>
                        {installationPoints.map((installationPoint, index) =>
                            <InstallationPointForm
                                key={index}
                                index={index}
                                profile={profile}
                                formErrors={formErrors}
                                onChange={onChange}
                                validateStep={validateStep}
                                instPointCustomTypes={instPointCustomTypes}
                                fetchInstPointCustomType={fetchInstPointCustomType}
                                removeInstallationPoint={removeInstallationPoint}
                                inProgress={inProgress}
                            />
                        )}

                        <div
                            className={"add-new-block"}
                            onClick={addInstallationPoint}
                        >
                            <img src="/assets/img/profile/add-new-icon.svg" alt=""/>
                            Add New Installation Point
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

const InstallationPointForm = ({
    index,
    profile,
    formErrors,
    onChange,
    validateStep,
    instPointCustomTypes,
    fetchInstPointCustomType,
    removeInstallationPoint,
    inProgress
}) => {

    return (
        <div className={"installation-point-wrapper"}>
            <NameField
                index={index}
                profile={profile}
                formErrors={formErrors}
                onChange={onChange}
                validateStep={validateStep}
                inProgress={inProgress}
            />

            <TypeField
                index={index}
                profile={profile}
                formErrors={formErrors}
                onChange={onChange}
                validateStep={validateStep}
                instPointCustomTypes={instPointCustomTypes}
                fetchInstPointCustomType={fetchInstPointCustomType}
                inProgress={inProgress}
            />

            <InstallationPointSpeedSettingInput
                nameRpm={`installationPoints.[${index}].speed`}
                nameRatio={`installationPoints.[${index}].speed_ratio`}
                valueRpm={_get(profile.installationPoints, [index, "speed"]) || ""}
                valueRatio={_get(profile.installationPoints, [index, "speed_ratio"]) || ""}
                onChange={onChange}
                disabled={inProgress}
                equipmentSpeedTypeId={+_get(profile, "general.equipmentSpeedTypeId")}
                hasTachometer={false}
            />

            <span className={"remove"} onClick={() => removeInstallationPoint(index)}>
                <i className="fas fa-times" />
            </span>
        </div>
    );
};

InstallationPointForm.propTypes = {
    index: PropTypes.number,
    profile: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    validateStep: PropTypes.func,
    instPointCustomTypes: PropTypes.array,
    fetchInstPointCustomType: PropTypes.func,
    removeInstallationPoint: PropTypes.func,
    inProgress: PropTypes.bool
};

const NameField = ({index, profile, formErrors, onChange, validateStep, inProgress}) => {
    const errorMsg = _get(formErrors, "installationPoints."+index+".name");
    const value = _get(profile, "installationPoints."+index+".name");

    let validationClass = "";
    if (errorMsg) {
        validationClass = " is-invalid";
    } else if (value && !inProgress) {
        validationClass = " valid";
    }

    return (
        <div className={"form-group"}>
            <label className={"form-label"}>Installation Point Name <span className="color-danger">*</span></label>
            <input
                autoComplete={"off"}
                className={"form-control" + validationClass}
                name={"installationPoints."+index+".name"}
                onChange={onChange}
                value={value}
                placeholder="Enter Point Name"
                onBlur={validateStep}
            />
            <ValidationError message={errorMsg}/>
        </div>
    );
};

NameField.propTypes = {
    index: PropTypes.number,
    profile: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool
};

const TypeField = ({index, profile, formErrors, onChange, validateStep, instPointCustomTypes, fetchInstPointCustomType, inProgress}) => {

    const errorMsg = _get(formErrors, "installationPoints."+index+".installation_point_custom_type_id");
    const value = _get(profile, "installationPoints."+index+".installation_point_custom_type_id");

    let validationClass = "";
    if (errorMsg) {
        validationClass = " is-invalid";
    } else if (value && !inProgress) {
        validationClass = " valid";
    }

    return (
        <div className={"form-group"}>
            <label>
                Installation Point Type
                <i
                    className={"fa fa-info-circle ml-1"}
                    style={{color: "#449AFF"}}
                    title={"Select an exist type or enter a new type"}
                />
            </label>
            <InstallationPointCustomTypeForm
                installationPointCustomTypes={instPointCustomTypes}
                name={"installationPoints."+index+".installation_point_custom_type_id"}
                value={value}
                onChange={(e) => {
                    onChange(e);
                    validateStep();
                }}
                updateInstallationPointCustomTypes={fetchInstPointCustomType}
                addClassName={"profile-inst-point-type" + validationClass}
            />
        </div>
    );
};

TypeField.propTypes = {
    index: PropTypes.number,
    profile: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    validateStep: PropTypes.func,
    instPointCustomTypes: PropTypes.array,
    fetchInstPointCustomType: PropTypes.func,
    inProgress: PropTypes.bool
};

InstallationPointsStep.propTypes = {
    profile: PropTypes.object,
    chartTypes: PropTypes.object,
    onChange: PropTypes.func,
    formErrors: PropTypes.object,
    addInstallationPoint: PropTypes.func,
    removeInstallationPoint: PropTypes.func,
    instPointCustomTypes: PropTypes.array,
    fetchInstPointCustomType: PropTypes.func,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool
};

export default InstallationPointsStep;