import {useQuery} from "@tanstack/react-query";
import Helper from "../../../helpers/helper";
import {ROUTES_API} from "./constants";
import RoutesApi from "../../../api/routes";

export const useRouteCollectorAssetQuery = () => {
    return useQuery({
        queryFn: () => RoutesApi.routeCollectorLocations(),
        queryKey: [ROUTES_API.GET_ROUTE_ASSET_COLLECTOR],
        select: (res) =>
            Helper.transformLocations(res.list).map((location) => {
                return {
                    label: location.full_name,
                    value: +location.id,
                    parent_id: location.parent_id,
                    id: +location.id,
                };
            }),
    });
};
