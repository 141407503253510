/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import DatePickerInput from "../../../../../../shared/datePickerInput/datePickerInput";
import {DATEPICKER_TYPE, ON_DEMAND_REASON} from "../../../../../../constants/constants";
import Helper from "../../../../../../helpers/helper";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {useChartActions, useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {dateListByPointSelector, timeListByPointDateSelector, useFFTTimestampsQuery} from "../../../../../../hooks/api/fftReadings/TimestampsQuery";
import useUserProfile from "../../../../../../hooks/api/Global/useUserProfile";
import {usePointData} from "../../../hooks/usePointsData";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {useCurrentDate} from "../../hooks/helpers/useCurrentDate";
import {useSelectedAxis} from "../../hooks/helpers/useSelectedAxis";
import {useUpdateCurrentDate} from "../../hooks/helpers/useUpdateCurrentDate";
import PropTypes from "prop-types";
import moment from "moment";
import {
    useSelectDatepickerUnixRangeTimestampSync,
    useSelectUnixDateRangeTimestampSync,
    useTimestampsSyncStoreActions,
} from "../../../store/sync/timestampsSyncStore";
import {getCalendarChunkByMonth} from "../../../../../../helpers/getCalendarChunkByMonth";

const DatepickerButton = ({fullScreenMode = false}) => {
    const [isOpen, setIsOpen] = useState(false);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);

    const selectedAxis = useSelectedAxis();
    const pointData = usePointData(selectedPoint);
    const currentDate = useCurrentDate();
    const updateCurrentDate = useUpdateCurrentDate();
    const {unixFrom, unixTo} = useSelectUnixDateRangeTimestampSync(selectedPoint, isImpactVue);
    const {setUnixDateRange, setDatepickerUnixRange} = useTimestampsSyncStoreActions();

    const {setLoader} = useChartActions();

    const localDates = useSelectDatepickerUnixRangeTimestampSync(selectedPoint, isImpactVue);

    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    const {data: user} = useUserProfile();

    const {
        isSuccess: datesLoaded,
        data: dates,
        isRefetching: isTimestampsRefetching,
        isPlaceholderData,
    } = useFFTTimestampsQuery({
        equipmentId: pointData.equipment_id,
        selectorFun: (data) => dateListByPointSelector(data, isImpactVue, pointData.id, selectedAxis),
        unixTo: localDates.unixTo,
        unixFrom: localDates.unixFrom,
        keepPreviousData: true,
    });

    const {data: lastDates} = useFFTTimestampsQuery({
        equipmentId: pointData.equipment_id,
        selectorFun: (data) => dateListByPointSelector(data, isImpactVue, pointData.id, selectedAxis),
        keepPreviousData: true,
        enabled: currentDate.date === "last",
    });

    const {
        isSuccess: timesLoaded,
        data: times,
        isRefetching,
    } = useFFTTimestampsQuery({
        equipmentId: pointData.equipment_id,
        selectorFun: (data) => timeListByPointDateSelector(data, isImpactVue, pointData.id, currentDate.date, selectedAxis),
        unixTo: localDates.unixTo,
        unixFrom: localDates.unixFrom,
        keepPreviousData: true,
    });

    const datesWithLast = currentDate.date === "last" ? lastDates : dates;

    const index = datesWithLast.indexOf(currentDate.date === "last" ? datesWithLast.at(-1) : currentDate.date);
    const nextDate = index + 1 < datesWithLast.length ? {date: datesWithLast.at(index + 1), time: "last"} : false;
    const prevDate = index - 1 >= 0 ? {date: datesWithLast.at(index - 1), time: "last"} : false;

    const datepickerValue = currentDate.date === "last" ? datesWithLast.at(-1) : currentDate.date;

    useEffect(() => {
        if (!datepickerValue) {
            return;
        }
        const {unixTo, unixFrom, isChanged} = getCalendarChunkByMonth({date: datepickerValue, unixFrom: localDates.unixFrom, unixTo: localDates.unixTo});

        if (!isChanged) {
            return;
        }

        setDatepickerUnixRange({
            isImpactVue,
            installationPointId: selectedPoint,
            unixFrom,
            unixTo,
        });
        setUnixDateRange({
            isImpactVue,
            installationPointId: selectedPoint,
            unixFrom,
            unixTo,
        });
        setLoader(true);
    }, [datepickerValue]);

    useEffect(() => {
        if (isRefetching) {
            setTimeout(() => setLoader(true), 300);
        }
    }, [isRefetching]);

    const isDataLoaded = datesLoaded && timesLoaded;

    const handleMonthChange = (month) => {
        const {isChanged, unixTo, unixFrom} = getCalendarChunkByMonth({date: month, unixFrom: localDates.unixFrom, unixTo: localDates.unixTo});

        if (!isChanged) {
            return;
        }

        setDatepickerUnixRange({
            isImpactVue,
            installationPointId: selectedPoint,
            unixFrom,
            unixTo,
        });
        setLoader(true);
    };

    const changeCurrentDate = (changedCurrentDate) => {
        const {isChanged, unixTo: updatedUnixTo, unixFrom: updatedUnixFrom} = getCalendarChunkByMonth({date: changedCurrentDate.date, unixFrom, unixTo});

        if (isChanged) {
            setUnixDateRange({
                isImpactVue,
                installationPointId: selectedPoint,
                unixFrom: updatedUnixFrom,
                unixTo: updatedUnixTo,
            });
        }

        updateCurrentDate(changedCurrentDate);
        setLoader(true);
    };

    const handleCloseModal = () => {
        if (moment(datepickerValue).unix() <= localDates.unixTo && moment(datepickerValue).unix() >= localDates.unixFrom) {
            return;
        }

        handleMonthChange(datepickerValue);
    };

    if (!isDataLoaded && !isPlaceholderData) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="btn-group-sm dropdown btn-default date-container">
                <button
                    type="button"
                    className="btn btn-default btn-fa-icon"
                    onClick={() => changeCurrentDate(prevDate)}
                    disabled={!prevDate || !!isLoading}
                >
                    <i className="fa fa-angle-left" />
                </button>
                <div className="d-inline-block position-relative">
                    <DatePickerInput
                        className="form-control"
                        value={datepickerValue}
                        type={DATEPICKER_TYPE.FFT}
                        availableDates={dates}
                        onChangeHandler={(date) => changeCurrentDate({date: date, time: "last"})}
                        replaceWithButton={true}
                        displayFormat={Helper.getUserDateFormat(user, true).date}
                        valueFormat={"YYYY-MM-DD"}
                        isLoading={isTimestampsRefetching}
                        onMonthChange={handleMonthChange}
                        portalPosition={fullScreenMode === true ? "date-picker-portal-modal" : null}
                        onCalendarClose={handleCloseModal}
                    />
                </div>
                <button
                    type="button"
                    className="btn btn-default btn-fa-icon"
                    onClick={() => changeCurrentDate(nextDate)}
                    disabled={!nextDate || !!isLoading}
                >
                    <i className="fa fa-angle-right" />
                </button>
            </div>
            {!!Object.values(times).length && (
                <Dropdown
                    size="sm"
                    isOpen={isOpen}
                    disabled={!!isLoading}
                    toggle={() => setIsOpen((prevDate) => !prevDate)}
                >
                    <DropdownToggle
                        caret
                        className="btn-default"
                        disabled={Object.keys(times).length < 2}
                    >
                        {currentDate.time === "last" ? Object.keys(times).at(-1) : currentDate.time}
                    </DropdownToggle>
                    <DropdownMenu className={Object.keys(times).length > 8 ? "custom-scroll" : ""}>
                        {Object.keys(times).map((time) => (
                            <DropdownItem
                                key={time}
                                onClick={() => changeCurrentDate({date: currentDate.date, time: time})}
                                disabled={time === (currentDate.time === "last" ? Object.keys(times).at(-1) : currentDate.time)}
                            >
                                {time}
                                {times[time].is_on_demand && times[time].on_demand_reason !== ON_DEMAND_REASON.IDLE_THRESHOLD && (
                                    <>
                                        {times[time].on_demand_reason === ON_DEMAND_REASON.LOCAL_ALARM ? (
                                            <span className="badge badge-info">On Demand by Alert</span>
                                        ) : (
                                            <span className="badge badge-info">On Demand</span>
                                        )}
                                    </>
                                )}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            )}
        </React.Fragment>
    );
};

const propTypes = {
    fullScreenMode: PropTypes.bool,
};
DatepickerButton.propTypes = propTypes;

export default DatepickerButton;
