import React from "react";
import PropTypes from "prop-types";
import {ToggleModal} from "../../../../shared";
import {withGlobalStore} from "../../../../stores/GlobalStore";
import hasOutliers from "../wrappers/hasOutliers";
import {ChartContext, ChartFactoryContext} from "./context/context";
import ChartWrapper from "./layouts/chartWrapper/chartWrapper";
import {WithChartLocalStore} from "./hoc/withChartLocalStore";
import {useChartStateStoreContext} from "./store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import ChartBody from "./components/chartBody/chartBody";
import "./twfFftChart.scss";

const propTypes = {
    syncFFTChart: PropTypes.object,
    chartType: PropTypes.string,
    showOutlierDialog: PropTypes.func,
    handleSetIdleThresholdModal: PropTypes.func,
    overlayContextMenuOptions: PropTypes.func,
};

const TwfFftChart = (props) => {
    const {chartType, showOutlierDialog, overlayContextMenuOptions} = props;
    const factoryResult = useChartStateStoreContext((state) => state.factory, shallow);
    const {chartModeList} = factoryResult;

    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const fullScreenMode = useChartStateStoreContext((state) => state.settings.fullScreenMode);

    return (
        <ChartFactoryContext.Provider value={factoryResult}>
            <ChartContext.Provider
                value={{
                    chartProps: props,
                }}
            >
                <React.Fragment>
                    <ToggleModal
                        centered={true}
                        size="full-screen"
                        showModal={true}
                        enabled={fullScreenMode}
                        title={""}
                        withoutFooter={true}
                        fftWaterfallFlag={chartMode === chartModeList.STACKED_WATERFALL}
                    >
                        <ChartWrapper>
                            <ChartBody
                                showOutlierDialog={showOutlierDialog}
                                handleSetIdleThresholdModal={props.handleSetIdleThresholdModal}
                                overlayContextMenuOptions={overlayContextMenuOptions}
                                chartType={chartType}
                            />
                        </ChartWrapper>
                    </ToggleModal>
                </React.Fragment>
            </ChartContext.Provider>
        </ChartFactoryContext.Provider>
    );
};

TwfFftChart.propTypes = propTypes;

export default withGlobalStore(WithChartLocalStore(hasOutliers(TwfFftChart)));
