import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiChartMarkFft from "../../../api/chartMarkFft";
import {CHART_MARK_API} from "./constants";
import {updateItemInList} from "../../../helpers/query";

export const useChartMarksUpdateMutation = (equipmentId, chartMarkId) => {
    const queryClient = useQueryClient();

    const updateChartMark = (data) => ApiChartMarkFft.update(equipmentId, chartMarkId, data);

    const mutation = useMutation(updateChartMark, {
        onSuccess: (data) => queryClient.setQueriesData([CHART_MARK_API.GET_CHART_MARK_FFT, equipmentId], (prev) => updateItemInList(prev, data)),
    });
    return mutation;
};
