import React from "react";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";

const DropzoneWrapper = (props) => {
    const {
        onDrop,
        images,
        flipTurns,
        onRemove,
        onFlipFile,
        onAssignPoints,
        mainPlaceholder,
        secondPlaceholder,
    } = props;

    return (
        <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <aside className="dropzone-thumb-wrapper">
                        {images.map((file, index) => (
                            <div className="dropzone-thumb-item" key={index}>
                                <div className="dropzone-thumb-img-wrapper">
                                    <div
                                        className="dropzone-thumb-img"
                                        style={{
                                            background: `url(${file}) no-repeat center center`,
                                            backgroundSize: "cover",
                                            transform: `rotate(${flipTurns[index] || 0}turn)`,
                                        }}
                                    />
                                </div>
                                <div className="dropzone-thumb-remove-wrapper">
                                    <span
                                        className="dropzone-thumb-remove"
                                        onClick={() => onRemove(index)}
                                    >
                                        <i className="fa fa-times-circle" />
                                    </span>
                                    <span
                                        className="dropzone-thumb-undo"
                                        onClick={() => onFlipFile(index)}
                                    >
                                        <i className="fa fa-undo" />
                                    </span>
                                    <span
                                        className="dropzone-thumb-redo"
                                        onClick={() => onFlipFile(index, true)}
                                    >
                                        <i className="fa fa-redo" />
                                    </span>
                                </div>
                                <div
                                    onClick={() => onAssignPoints(index)}
                                    className="btn btn-primary"
                                >
                                    Assign Points
                                </div>
                            </div>
                        ))}
                    </aside>
                    <div className="dropzone dropzone-custom" {...getRootProps()}>
                        <div>
                            <span className="dropzone-icon">
                                <i className="fa fa-cloud-upload-alt" />
                            </span>
                            <input {...getInputProps()} />
                            <p>
                                <b>
                                    {mainPlaceholder
                                        ? mainPlaceholder
                                        : "Drag-and-drop files or upload it."}
                                </b>
                            </p>
                            <p>
                                <span>{secondPlaceholder ? secondPlaceholder : ""}</span>
                            </p>
                        </div>
                    </div>
                </section>
            )}
        </Dropzone>
    );
};

DropzoneWrapper.propTypes = {
    images: PropTypes.array,
    flipTurns: PropTypes.object,
    onDrop: PropTypes.func,
    onAssignPoints: PropTypes.func,
    onFlipFile: PropTypes.func,
    onRemove: PropTypes.func,
    mainPlaceholder: PropTypes.string,
    secondPlaceholder: PropTypes.string,
};

export default DropzoneWrapper;
