import Api from "./api";

const Gateway = {
    list: (params) => {
        return Api.get("gateways", {...params});
    },
    view: (serial) => {
        return Api.get(`gateways/${serial}`);
    },
    update: (serial, body) => {
        return Api.post(`gateways/update/${serial}`, body);
    },
    attachImages: (serial, body) => {
        return Api.post(`gateways/attach-images/${serial}`, body);
    },
    detachImage: (serial, body) => {
        return Api.post(`gateways/detach-image/${serial}`, body);
    },
    detachAllImages: (serial, body) => {
        return Api.post(`gateways/detach-images/${serial}`, body);
    },
    export: (params) => {
        return Api.source("gateways/export", {...params});
    },
    channelStats: (gatewayId) => {
        return Api.get(`gateways/${gatewayId}/bridge-channel-stats`);
    },
    scanChannelStats: (body) => {
        return Api.post("gateways/scan-bridge-channel-stats", body);
    },
    changeChannel: (body) => {
        return Api.post("gateways/change-channel", body);
    },
    onFlipImage: (body) => {
        return Api.post("gateways/flip-image", body);
    },
    packetLoss: (serial, params) => Api.get(`gateways/${serial}/packet-loss`, {query: {...params}}),
};

export default Gateway;
