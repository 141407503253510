import {useMemo} from "react";
import {getChartMarksFftPlotLines} from "../../helpers/plotLines";
import {useSpeedSettings} from "../helpers/useSpeedSettings";
import {useChartMarksQuery} from "../../../../../../hooks/api/chartMarksFft/useChartMarksQuery";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import useUserProfile from "../../../../../../hooks/api/Global/useUserProfile";
import {useCurrentTimestampsInfo} from "../helpers/useCurrentTimestampsInfo";
import {useIsShowChartMarks} from "../../../store/sync/sharedStore";

export const useChartMarksBuilder = (equipment) => {
    const {chartMarksData} = useChartMarksQuery(equipment.id);
    const {currentSpeed} = useSpeedSettings();
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint, shallow);
    const {data: user} = useUserProfile();
    const timestamps = useCurrentTimestampsInfo();
    const isShowChartMarks = useIsShowChartMarks();

    return useMemo(() => {
        return getChartMarksFftPlotLines({
            chartMarksFft: chartMarksData,
            currentSpeed: currentSpeed,
            currentDate: `${timestamps.date} ${timestamps.time}`,
            selectedPoint: selectedPoint,
            user: user,
            isShowChartMarks,
        });
    }, [chartMarksData, currentSpeed, timestamps, isShowChartMarks]);
};
