import {useMemo} from "react";
import {useSignalStrengthQuery} from "../../../../hooks/api/signal/useSignalStrengthQuery";

export const useSignalStrengthSeries = (serial, type, timeRange, dateRange) => {
    const {data: response, isSuccess} = useSignalStrengthQuery(serial, type, timeRange, dateRange);

    return useMemo(() => {
        if (!isSuccess) {
            return [];
        }

        return [
            {
                name: "Signal",
                data: response.list,
            },
        ];
    }, [isSuccess, response]);
};
