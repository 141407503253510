import React, {useState} from "react";
import InstallationPointCustomTypeField from "../forms/parts/type";
import PropTypes from "prop-types";
import UniversalAdapterApi from "../../../api/universalAdapter";
import Toast from "../../../pages/shared/toast";
import EquipmentApi from "../../../api/equipment";
import InstallationPointApi from "../../../api/installationPoint";
import SweetAlert from "react-bootstrap-sweetalert";
import {ValidationError} from "../../../shared";
import SelectWrapper from "../../../helpers/select-wrapper";
import {get as _get, set as _set, omit as _omit, find as _find} from "lodash";
import {InputFormGroup} from "../../../shared/formParts/formParts";

const AdapterForm = ({installationPoint, equipmentId, afterUpdate, wuaSensorList}) => {
    const [isEdit, setIsEdit] = useState(false);

    return (
        <>
            {isEdit
                ?
                <EditAdapterForm
                    installationPoint={installationPoint}
                    equipmentId={equipmentId}
                    onCancel={() => setIsEdit(false)}
                    afterUpdate={() => afterUpdate().then(() => setIsEdit(false))}
                    wuaSensorList={wuaSensorList}
                />
                :
                <ViewAdapterForm
                    installationPoint={installationPoint}
                    onEdit={() => setIsEdit(true)}
                    equipmentId={equipmentId}
                    afterUpdate={afterUpdate}
                />
            }
        </>
    );
};

const EditAdapterForm = ({installationPoint, equipmentId, onCancel, afterUpdate, wuaSensorList}) => {
    const [adapter, setAdapter] = useState({
        name: "",
        sensor_id: _get(installationPoint, "sensor.sensor_hex"),
        installation_point_name: _get(installationPoint, "name"),
        installation_point_custom_type_id: _get(installationPoint, "installation_point_custom_type_id"),
        equipment_id: equipmentId,
        parameters: [{
            wua_sensor_id: _get(installationPoint, "universalAdapterParameter.wuaSensor.id"),
            sensor_position: 0,
            wua_interval: 1,
            wua_interval_type: 3,
        }]
    });
    const [inProgress, setInProgress] = useState(false);
    const [formErrors, setFormErrors] = useState({});


    const changeAdapterData = (event) => {
        const key = _get(event, "target.name");
        const value = _get(event, "target.value");

        updateAdapterData(key, value);
    };

    const updateAdapterData = (key, value) => {
        let newAdapter = {...adapter};

        _set(newAdapter, key, value);

        setAdapter(newAdapter);
        removeError(key);
    };

    const removeError = (key) => {
        const oldFormErrors = {...formErrors};

        const newFormErrors = _omit(oldFormErrors, key);

        setFormErrors(newFormErrors);
    };

    const onCreate = () => {
        setInProgress(true);

        UniversalAdapterApi
            .updateSingle(_get(installationPoint, "universalAdapter.id"), adapter)
            .then(response => {
                if (response.status === "ok") {
                    afterUpdate().then(() => {
                        setInProgress(false);
                        Toast.success("The universal adapter has been updated.");
                    });
                }
            })
            .catch(response => {
                if (response.status === 422) {
                    setInProgress(false);
                    setFormErrors(response.errors || {});
                }
            });
    };

    const currentWuaSensor = _find(wuaSensorList, (wuaSensor) => +wuaSensor.id === +_get(adapter, "parameters.0.wua_sensor_id"));

    return (
        <>
            <div className={"row align-items-center py-3"}>
                <div className={"col-3"}>
                    <input
                        className={"form-control" + (_get(formErrors, "installation_point_name") ? " is-invalid" : "")}
                        name={"installation_point_name"}
                        value={adapter.installation_point_name}
                        onChange={changeAdapterData}
                    />
                    <ValidationError message={_get(formErrors, "installation_point_name")}/>
                </div>
                <div className={"col-3"}>
                    <SelectWrapper
                        className={(_get(formErrors, "parameters[0].wua_sensor_id") ? " is-invalid" : "")}
                        name={"parameters[0].wua_sensor_id"}
                        value={_get(adapter, "parameters.0.wua_sensor_id")}
                        onChange={changeAdapterData}
                    >
                        {(wuaSensorList || []).length && <option value={""}>Select a Universal Adapter</option>}
                        {(wuaSensorList || []).length
                            ?
                            wuaSensorList.map((wuaSensor, index) =>
                                <option key={index} value={wuaSensor.id}>{wuaSensor.name}</option>
                            )
                            :
                            <option value={""}>No WUA Sensors</option>
                        }
                    </SelectWrapper>
                    <ValidationError message={_get(formErrors, "parameters[0].wua_sensor_id")}/>
                </div>
                <div className={"col-3"}>
                    <InstallationPointCustomTypeField
                        value={_get(adapter, "installation_point_custom_type_id")}
                        onChange={changeAdapterData}
                    />
                </div>
                <div className={"col-2 px-2"}>
                    <input
                        className={"form-control" + (formErrors.sensor_id ? " is-invalid" : "")}
                        name={"sensor_id"}
                        value={adapter.sensor_id}
                        onChange={changeAdapterData}
                        placeholder={"Enter Universal Adapter Sensor ID"}
                    />
                    <ValidationError message={formErrors.sensor_id}/>
                </div>
                <div className={"col-1"}>
                    {!inProgress &&
                        <>
                            <img className={"action-btn mr-4"} src={"/assets/img/check.svg"} onClick={onCreate}/>
                            <img className={"action-btn"} src={"/assets/img/close.svg"} onClick={onCancel} title="Cancel"/>
                        </>
                    }
                </div>
            </div>
            {currentWuaSensor &&
                <div className={"row point-info wua-info p-2 py-4"}>
                    <div className={"col-md-12"}>
                        <InputFormGroup
                            label={"Configuration Name"}
                            value={_get(currentWuaSensor, "name")}
                            disabled={true}
                        />
                    </div>

                    <div className={"col-md-12"}>
                        <InputFormGroup
                            label={"Input Value Type"}
                            value={_get(currentWuaSensor, "valueType.name")}
                            disabled={true}
                        />
                    </div>

                    {_get(currentWuaSensor, "valueType.value_key") !== "temperature" &&
                        <>
                            <div className={"col-md-6"}>
                                <InputFormGroup
                                    label={"Minimum Input Value"}
                                    value={_get(currentWuaSensor, "min_voltage")}
                                    disabled={true}
                                />
                            </div>
                            <div className={"col-md-6"}>
                                <InputFormGroup
                                    label={"Maximum Input Value"}
                                    value={_get(currentWuaSensor, "max_voltage")}
                                    disabled={true}
                                />
                            </div>
                        </>
                    }

                    <div className={"col-md-12"}>
                        <InputFormGroup
                            label={"Process Variable"}
                            value={_get(currentWuaSensor, "readingType.name") + " (" + _get(currentWuaSensor, "readingType.unit.unit")  + ")"}
                            disabled={true}
                        />
                    </div>

                    {_get(currentWuaSensor, "valueType.value_key") !== "temperature" &&
                        <>
                            <div className={"col-md-6"}>
                                <InputFormGroup
                                    label={"Process Variable Minimum Value"}
                                    value={_get(currentWuaSensor, "min_sensor_value")}
                                    disabled={true}
                                />
                            </div>
                            <div className={"col-md-6"}>
                                <InputFormGroup
                                    label={"Process Variable Maximum Value"}
                                    value={_get(currentWuaSensor, "max_sensor_value")}
                                    disabled={true}
                                />
                            </div>
                        </>
                    }
                </div>
            }
        </>
    );
};

EditAdapterForm.propTypes = {
    installationPoint: PropTypes.object,
    equipmentId: PropTypes.number,
    onCancel: PropTypes.func,
    afterUpdate: PropTypes.func,
    wuaSensorList: PropTypes.array,
};


const ViewAdapterForm = ({installationPoint, onEdit, afterUpdate, equipmentId}) => {
    const [onDelete, setOnDelete] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    const deleteInstallationPoint = () => {
        setInProgress(true);
        EquipmentApi.checkService(equipmentId).then(() =>
            InstallationPointApi
                .delete(installationPoint.id)
                .then(() => {
                    afterUpdate().then(() => {
                        Toast.success("The universal adapter has been deleted.");
                        setOnDelete(false);
                        setInProgress(false);
                    });
                })
        );
    };

    return (
        <div className={"row point-info p-2 py-4 align-items-center"}>
            <div className={"col-3 pl-4"}>{_get(installationPoint, "name")}</div>
            <div className={"col-3 pl-4"}>{_get(installationPoint, "universalAdapterParameter.wuaSensor.name")}</div>
            <div className={"col-3 pl-4"}>{_get(installationPoint, "installationPointCustomType.name")}</div>
            <div className={"col-2 px-2 pl-4"}>{_get(installationPoint, "sensor.sensor_hex")}</div>
            <div className={"col-1"}>
                {!inProgress &&
                    <>
                        <img className={"action-btn mr-4"} src={"/assets/img/pencil.svg"} onClick={onEdit} />
                        <img className={"action-btn"} src={"/assets/img/bucket.svg"} onClick={() => setOnDelete(true)} />
                    </>
                }
            </div>
            {onDelete &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title="DELETE INSTALLATION POINT"
                    openAnim={false}
                    closeAnim={false}
                    onConfirm={() => deleteInstallationPoint()}
                    onCancel={() => setOnDelete(false)}
                    disabled={inProgress}
                >
                    Are you sure you want to delete this installation point?
                </SweetAlert>
            }
        </div>
    );
};

ViewAdapterForm.propTypes = {
    installationPoint: PropTypes.object,
    onEdit: PropTypes.func,
    afterUpdate: PropTypes.func,
    equipmentId: PropTypes.number
};

AdapterForm.propTypes = {
    installationPoint: PropTypes.object,
    equipmentId: PropTypes.number,
    afterUpdate: PropTypes.func,
    wuaSensorList: PropTypes.array
};

export default AdapterForm;