import $ from "jquery";
import React, {Component} from "react";
import {get as _get, has as _has} from "lodash";
import PropTypes from "prop-types";
import AlarmFilterApi from "../../api/alarmFilter";
import Helper from "../../helpers/helper";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {withLocationSelectStore} from "../../stores/LocationSelectStore";
import CollapseLocationSelect from "../../shared/collapseLocationSelect/collapseLocationSelect";
import SelectWrapper from "../../helpers/select-wrapper";

class AlarmFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            levels: [],
            chartTypes: [],
            types: [],
            currentChartTypeAxes: [],
            chartTypeAxes: [],
            filter: {
                chartType: "",
                type: "",
                axis: "",
                level: "",
                location: props.getLocationId(),
            },
        };
    }

    componentDidMount() {
        this.initOptions();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.filter.chartType !== prevState.filter.chartType) {
            this.updateAxes();
        }
    }

    initOptions = () => {
        AlarmFilterApi.list().then((options) => {
            this.setState({
                levels: options.levels,
                chartTypes: options.chartTypes,
                types: options.types,
                chartTypeAxes: options.chartTypeAxes,
            });
        });

        const {customFilterElements = []} = this.props;
        let {filter} = this.state;

        if (customFilterElements.length) {
            customFilterElements.map((element) => {
                filter[_get(element, "name")] = "";
            });

            this.setState({filter});
        }
    };

    updateAxes = () => {
        if (this.state.filter.chartType === "") {
            this.setState({
                currentChartTypeAxes: [],
            });
        } else {
            this.setState({
                currentChartTypeAxes: this.getCurrentChartTypeAxes(),
            });
        }
    };

    getCurrentChartTypeAxes = () => {
        if (_has(this.state.chartTypeAxes, this.state.filter.chartType)) {
            return this.state.chartTypeAxes[this.state.filter.chartType];
        } else {
            return [];
        }
    };

    onSelect = (value) => {
        if (_has(value, "chartType")) {
            value.axis = "";
        }

        this.setState(
            {
                filter: {
                    ...this.state.filter,
                    ...value,
                },
            },
            () => {
                this.props.updated(this.state.filter);
            }
        );
    };

    filtersApplied = () => {
        const {filter} = this.state;
        const filterValues = Object.values(filter);

        const result = filterValues.filter((value) => value !== "");

        return result.length >= 1;
    };

    reset = () => {
        let {filter} = this.state;
        const filterKeys = Object.keys(filter);
        filterKeys.map((key) => (filter[key] = ""));

        this.setState({filter}, () => {
            this.props.updated(this.state.filter);
        });
    };

    handleAcknowledgeModal = () => {
        this.props.history.push(Helper.setHashParams({modalAlarm: "ack", selected: "all"}));
    };

    handleIgnoreModal = () => {
        this.props.history.push(Helper.setHashParams({modalAlarm: "ignore", selected: "all"}));
    };

    render() {
        const {levels, types, chartTypes, currentChartTypeAxes, filter} = this.state;
        const {customFilterElements = [], customClass = ""} = this.props;

        return (
            <div className={"form-list form-list--row " + customClass}>
                {this.props.showCheckboxes && (
                    <div className="form-group form-group--inline checkbox-drop">
                        <label className="form-checkbox form-checkbox--filter">
                            {this.props.selected.length > 0 ? (
                                <input
                                    className={"uncheck"}
                                    type="checkbox"
                                    checked="checked"
                                    onChange={() => this.props.handleCheckboxes(false)}
                                />
                            ) : (
                                <input
                                    checked={this.props.checkedAlarms === "all"}
                                    onChange={(e) => this.props.handleCheckboxes($(e.target).prop("checked") ? "all" : false)}
                                    type="checkbox"
                                />
                            )}
                            <span />
                        </label>
                        <UncontrolledDropdown className="dropdown-inline">
                            <DropdownToggle
                                tag="button"
                                className="btn btn-hover-default dropdown-toggle dropdown-toggle-split color-black no-padding"
                            >
                                Select
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => this.props.handleCheckboxes("all")}>All</DropdownItem>
                                <DropdownItem onClick={() => this.props.handleCheckboxes(false)}>None</DropdownItem>
                                {levels.map((level) => (
                                    <DropdownItem
                                        key={level.value}
                                        onClick={() => this.props.handleCheckboxes(level.value)}
                                    >
                                        {level.label}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )}
                <div className="form-group form-group--inline">
                    <div className="form-label">
                        <label>Asset Tree Branch:</label>
                    </div>
                    <CollapseLocationSelect
                        style={{
                            marginLeft: 5,
                            width: 150,
                        }}
                        className={"form-control form-control-sm"}
                        selectName={"location"}
                        value={+_get(filter, ["location"], 0)}
                        onChange={(e) => {
                            this.onSelect({
                                location: _get(e, "target.value", ""),
                            });
                        }}
                        emptyOptionLabel={"All"}
                        checkStoreValue={true}
                    />
                </div>
                <AlarmFilter
                    name="level"
                    value={filter.level}
                    label="Level"
                    options={levels}
                    selected={this.onSelect}
                />
                <AlarmFilter
                    name="chartType"
                    value={filter.chartType}
                    label="Chart Type"
                    options={chartTypes}
                    selected={this.onSelect}
                />
                <AlarmFilter
                    name="type"
                    value={filter.type}
                    label="Alert Type"
                    options={types}
                    selected={this.onSelect}
                />
                {currentChartTypeAxes.length > 0 && (
                    <AlarmFilter
                        name="axis"
                        value={filter.axis}
                        label="Axis"
                        options={currentChartTypeAxes}
                        selected={this.onSelect}
                    />
                )}
                {customFilterElements.length > 0 &&
                    customFilterElements.map((newElement, index) => (
                        <AlarmFilter
                            key={"new-element-filter-" + index}
                            name={_get(newElement, "name")}
                            value={_get(newElement, "value")}
                            label={_get(newElement, "label")}
                            options={_get(newElement, "options")}
                            selected={this.onSelect}
                        />
                    ))}
                {this.filtersApplied() && (
                    <div className="form-group form-group--inline">
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={this.reset}
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

AlarmFilters.propTypes = {
    updated: PropTypes.func,
    handleCheckboxes: PropTypes.func,
    checkedAlarms: PropTypes.any,
    selected: PropTypes.array,
    history: PropTypes.object,
    showCheckboxes: PropTypes.bool,

    // LocationSelectStore
    getLocationId: PropTypes.func,
    customFilterElements: PropTypes.array,
    customClass: PropTypes.string,
};

const AlarmFilter = ({name, value, label, options, selected}) => {
    return (
        <div
            className="form-group form-group--inline"
            data-qa={`${name}Filters`}
        >
            <div className="form-label">
                <label>{label}:</label>
            </div>
            <SelectWrapper
                style={{width: 100}}
                value={value}
                onChange={(e) => {
                    selected({
                        [name]: _get(e, "target.value", ""),
                    });
                }}
                name={name}
            >
                <option value="">All</option>
                {options.map((option) => (
                    <option
                        key={"level-" + option.value}
                        value={option.value}
                    >
                        {option.label}
                    </option>
                ))}
            </SelectWrapper>
        </div>
    );
};

AlarmFilter.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    options: PropTypes.array,
    selected: PropTypes.func,
};

export default withLocationSelectStore(AlarmFilters);
