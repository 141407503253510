import {cloneDeep as _cloneDeep, find as _find} from "lodash";
import {useSelectedByEquipment} from "../../../../stores/zustand/ChartSelectedEquipmentStore";
import {useMemo} from "react";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import {useGetPointById} from "../../../../hooks/helpers/usePointId";

export const usePointsData = (overlayPoints = []) => {
    const {equipmentItem} = useEquipmentByParams();
    const selectedEquipment = useSelectedByEquipment(equipmentItem.id);

    return useMemo(() => {
        let pointsData = [];
        for (let point of selectedEquipment) {
            let chartPoint = useGetPointById(point, equipmentItem);

            if (Object.values(chartPoint).length) {
                chartPoint = {...chartPoint, equipment_name: equipmentItem.name};
            }

            pointsData.push(chartPoint);
        }
        overlayPoints = overlayPoints.map((i) => i.installationPoint);
        pointsData = [...pointsData, ...overlayPoints];
        return pointsData;
    }, [equipmentItem.id, overlayPoints, selectedEquipment, equipmentItem.installationPoints]);
};

export const usePointData = (selectedPoint, overlayPoints = []) => {
    const {equipmentItem} = useEquipmentByParams();

    return useMemo(() => {
        let pointsData = _cloneDeep(overlayPoints) || [];
        for (let point of equipmentItem.installationPoints) {
            pointsData.push(point);
        }

        return _find(pointsData, {id: selectedPoint});
    }, [equipmentItem.id, selectedPoint, equipmentItem]);
};
