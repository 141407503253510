import React, {Component} from "react";
import PropTypes from "prop-types";
import BasePopover from "../../../shared/base-popover";
import {withGlobalStore} from "../../../../stores/GlobalStore";
import Helper from "../../../../helpers/helper";
import CommentsForm from "./comments-form";
import ApiDeviceAlertComments from "../../../../api/deviceAlertComments";
import CommentsBlock from "./comments-timeline-block";
import ApiDeviceAlert from "../../../../api/deviceAlert";
import SweetAlert from "react-bootstrap-sweetalert";

class CommentsPopover extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            loader: true,
            deletedId: null,
            updateComment: {}
        };
    }

    toggle = () => {
        this.setState({show: !this.state.show}, () => {
            this.props.history.push(Helper.deleteHashParams(["modal", "comment-edit", "comment_id"]));
        });
    };

    toggleAddComment = () => {
        this.props.history.push(Helper.setHashParams({modal: "comment-add"}));
    };

    fetchComments = () => {
        const {deviceAlertId} = this.props;

        return ApiDeviceAlert.comments(this.props.deviceAlertId, 100).then(({list: comments}) => {
            this.props.onActionCallback(deviceAlertId, comments);
        });
    };

    onSubmitCommentCreate = (data) => {
        const {user, deviceAlertId} = this.props;

        return ApiDeviceAlertComments.add({
            text: data.text,
            device_alert_id: deviceAlertId,
            user_name: user.name_first + " " + user.name_last,
            user_id: user.id
        }).then(({status, message}) => {
            if (status === "ok") {
                this.fetchComments();
            } else if (status === "error") {
                console.error(message);
            }
            this.props.history.push(Helper.deleteHashParams(["modal"]));
        });
    };

    onSubmitCommentEdit = (data, id) => {
        const {user, deviceAlertId} = this.props;
        const searchParams = Helper.getHashParams();

        this.setState({updateComment: {}});
        return ApiDeviceAlertComments.edit({
            text: data.text,
            device_alert_id: deviceAlertId,
            user_name: user.name_first + " " + user.name_last,
            user_id: user.id,
            id
        }).then(() => {
            this.setState({updateComment: {id: id, text: data.text}});
            this.fetchComments().then(() => {
                if (searchParams.modal === "comment-edit") {
                    this.props.history.push(Helper.deleteHashParams(["modal", "comment_id"]));
                } else {
                    this.props.history.push(Helper.deleteHashParams(["comment_id"]));
                }
            });
        });
    };

    onDelete = commentId => {
        this.setState({
            deletedId: commentId,
            show: false
        });
    };

    deleteComment = () => {
        ApiDeviceAlertComments.delete(this.state.deletedId).then(() => {
            this.onDelete(null);
            this.fetchComments();
        });
    };

    render() {
        const {show, deletedId} = this.state;
        const {user, history, comments, isHistory} = this.props;
        const searchParams = Helper.getHashParams();

        return (
            <React.Fragment>
                <BasePopover
                    {...this.props.popoverProps}
                    isOpen={show}
                    toggle={this.toggle}
                >
                    {!!(searchParams.modal === "comment-add" && !isHistory) &&
                    <CommentsForm
                        styleObject={{margin: "10px 0"}}
                        onClose={() => history.push(Helper.deleteHashParams(["modal"]))}
                        onSubmit={this.onSubmitCommentCreate}
                    />
                    }
                    {!!(searchParams.modal !== "comment-add" && !isHistory)&&
                    <div className={"d-flex justify-content-end align-items-center"}>
                        <i className="mr-3">Add Comment</i>
                        <button
                            title={"Add Comment"}
                            onClick={this.toggleAddComment}
                            className="btn in-blue-circle btn-elevate-hover btn-sm btn-circle btn-icon pull-right"
                        >
                            <i className="fa fa-plus"/>
                        </button>
                    </div>
                    }
                    {comments.length > 0 &&
                        <CommentsBlock
                            comments={comments}
                            user={user}
                            searchParams={searchParams}
                            history={history}
                            onSubmitEdit={this.onSubmitCommentEdit}
                            onSubmitCreate={this.onSubmitCommentCreate}
                            onDelete={this.onDelete}
                            isHistory={isHistory}
                        />
                    }
                </BasePopover>
                {deletedId &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title="DELETE Comment"
                        onConfirm={this.deleteComment}
                        onCancel={() => this.onDelete(null)}
                    >
                        Are you sure you want to delete this comment?
                    </SweetAlert>
                }
            </React.Fragment>
        );
    }
}
CommentsPopover.propTypes = {
    comments: PropTypes.array,
    deviceAlertId: PropTypes.number,
    popoverProps: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
    onActionCallback: PropTypes.func,
    isHistory: PropTypes.number
};
export default withGlobalStore(CommentsPopover);