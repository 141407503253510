import { useModal } from "react-modal-hook";
import EquipmentProfileModalRemove from "../components/equipment-profile-modal-remove";
import React from "react";
import { useHistory } from "react-router";
import { useModalHistory } from "../../../hooks/helpers/useModalHistory";
import { MODALS } from "../../../constants/modals";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import {useEquipmentInvalidate} from "../../../hooks/api/equipment/useEquipmentInvalidate";

export const useEquipmentProfileRemoveModal = () => {
    const history = useHistory();
    const {equipmentItem} = useEquipmentByParams();
    const invalidateEquipment = useEquipmentInvalidate(equipmentItem.id);
    const chartTypes = useChartTypesStore();
    const [showModal, hideModal] = useModal(() => (
        <EquipmentProfileModalRemove showModal={true} history={history} onClose={onHide} equipment={equipmentItem} chartTypes={chartTypes} invalidateEquipment={invalidateEquipment}/>
    ), [equipmentItem]);
    const { pushHistory, deleteHistory } = useModalHistory(
        MODALS.EQUIPMENT_PROFILE_REMOVE,
        showModal
    );

    const onShow = () => {
        pushHistory();
        showModal();
    };

    const onHide = () => {
        deleteHistory();
        hideModal();
    };

    return [onShow, onHide];
};
