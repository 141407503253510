import React, {Component} from "react";
import ValidationError from "../../../../layouts/ValidationError/ValidationError";
import PropTypes from "prop-types";
import NameplateParameterApi from "../../../../api/nameplateParameter";
import {
    get as _get,
    set as _set,
    omit as _omit
} from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";

class NameplateParameterForm extends Component
{
    constructor(props) {
        super(props);

        const emptyParameter = {
            name: ""
        };

        const parameter = props.parameter ? {...props.parameter} : {...emptyParameter};

        this.state = {
            parameter: parameter,
            formErrors: {},
            showUpdateConfirm: false
        };
    }

    changeData = (event) => {
        const key = _get(event, "target.name");
        const value = _get(event, "target.value");

        this.updateData(key, value);
    }

    updateData = (key, value) => {
        let parameter = {...this.state.parameter};

        _set(parameter, key, value);

        this.setState({parameter}, () => this.removeError(key));
    }

    removeError = (key) => {
        const oldFormErrors = {...this.state.formErrors};

        const formErrors = _omit(oldFormErrors, key);

        this.setState({formErrors});
    }

    isNew = () => {
        const {parameter} = this.state;
        return !_get(parameter, "id");
    }

    onSubmit = () => {
        if (!this.isNew()) {
            this.setState({showUpdateConfirm: true});
        } else {
            this.submitData();
        }
    }

    submitData = () => {
        const {onSave} = this.props;

        this.sendForm()
            .then(response => {
                if (response.status === "ok") {
                    onSave();
                }
            })
            .catch(response => {
                if (response.status === 422) {
                    this.setState({formErrors: response.errors || {}});
                }
            });
    }

    sendForm = () => {
        const {parameter} = this.state;

        return this.isNew() ? NameplateParameterApi.create(parameter) : NameplateParameterApi.update(_get(parameter, "id"), parameter);
    }

    cancelUpdate = () => {
        this.setState({showUpdateConfirm: false});
    }

    sendUpdate = () => {
        this.setState({showUpdateConfirm: false}, () => this.submitData());
    }

    render() {
        const {parameter, formErrors, showUpdateConfirm} = this.state;
        const {columnList, onCancel} = this.props;

        return (
            <React.Fragment>
                <div className={"row parameter-row"}>
                    <div className={"form-group mb-3 " + columnList.name.className}>
                        <input
                            className={"form-control" + (formErrors.name ? " is-invalid" : "")}
                            name="name"
                            value={parameter.name}
                            onChange={this.changeData}
                        />
                        <ValidationError message={formErrors.name}/>
                    </div>
                    <div className={"pt-2 " + columnList.actions.className}>
                        <div className="btn-group btn-group-sm">
                            <button
                                className="link link-primary"
                                onClick={this.onSubmit}
                            >
                                <i className="fa fa-save"/> <span>Save</span>
                            </button>
                            <button
                                className="link link-danger"
                                onClick={onCancel}
                            >
                                <i className="fa fa-times"/> <span>Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
                {showUpdateConfirm &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title="UPDATE PARAMETER"
                        openAnim={false}
                        closeAnim={false}
                        onConfirm={this.sendUpdate}
                        onCancel={this.cancelUpdate}
                    >
                        Are you sure you want to update this parameter?
                        <br/>
                        This will also update this parameter in all Nameplates that include this parameter.
                    </SweetAlert>
                }
            </React.Fragment>
        );
    }
}

NameplateParameterForm.propTypes = {
    parameter: PropTypes.object,
    onSave: PropTypes.func,
    columnList: PropTypes.object,
    onCreated: PropTypes.func,
    onCancel: PropTypes.func
};

export default NameplateParameterForm;