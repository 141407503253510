import { useModal } from "react-modal-hook";
import NotesModal from "../components/notes";
import { useHistory } from "react-router";
import React from "react";
import { useModalHistory } from "../../../hooks/helpers/useModalHistory";
import { MODALS } from "../../../constants/modals";
import {useChartSelectedRangeStoreActions} from "../../../stores/zustand/ChartSelectedRangeStore";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";

export const useNotesModal = ({handleNotesCount}) => {
    const history = useHistory();
    const {updateRangeToChartMark} = useChartSelectedRangeStoreActions();
    const {equipmentItem} = useEquipmentByParams();

    const [showModal, hideModal] = useModal(() => (
        <NotesModal
            showModal={true}
            history={history}
            updateEquipmentNotesCount={handleNotesCount}
            onClose={onHide}
            updateRangeToChartMark={updateRangeToChartMark}

        />
    ));
    const { pushHistory, deleteHistory } = useModalHistory(MODALS.NOTES, showModal, {
        equipment_id: equipmentItem.id,
    });

    const onShow = () => {
        pushHistory();
        showModal();
    };

    const onHide = () => {
        deleteHistory();
        hideModal();
    };

    return [onShow, onHide];
};
