import FrequencyConverter from "../../../../../helpers/frequency-converter";
import Highcharts from "highcharts";
import {BEARING_COLORS, BEARING_TYPES, CHART_VIEW_TYPE, DATEFORMAT, FREQUENCY_TYPES} from "../../../../../constants/constants";
import {get as _get, find as _find, findIndex as _findIndex} from "lodash";
import Helper from "../../../../../helpers/helper";
//TODO think about refactor !Pass props and state is bad solution
export const getFftBearings = ({bearing, bearingParam, color, speedSettings}) => {
    const plotLines = [];

    let val = FrequencyConverter.fromOrders(bearing[bearingParam], speedSettings.currentSpeed).toHz().value;
    if (!+bearing.plot_lines_count) {
        bearing.plot_lines_count = 1;
    }
    for (let i = bearing.plot_lines_count; i > 0; i--) {
        plotLines.push({
            color: color, // Color value
            dashStyle: "dash", // Style of the plot line. Default to solid
            value: val * i, // Value of where the line will appear
            width: 1, // Width of the line
            label: {
                useHTML: true,
                text: bearingParam.toUpperCase(),
                style: {
                    color: "white",
                    backgroundColor: color,
                    marginLeft: 5 + "px",
                    padding: "0 2px",
                    "font-size": "10px",
                },
                x: -5,
                y: 0,
            },
        });
    }

    return plotLines;
};

export const getTwfBearings = ({bearing, bearingParam, color, xMaxCacher, settings, speedSettings, series}) => {
    const {chartViewType} = settings;
    const {currentSpeed} = speedSettings;
    if (!+bearing.plot_lines_count) {
        bearing.plot_lines_count = 1;
    }

    const xMax = xMaxCacher.value;

    const plotLines = [];
    let val = 1 / FrequencyConverter.fromOrders(bearing[bearingParam], currentSpeed).toHz().value;
    //TODO create helper
    const isCircular = chartViewType === CHART_VIEW_TYPE.CIRCULAR;
    if (isCircular) {
        const oneLineBearingX = 360 / bearing[bearingParam];
        const cycleIteration = _get(series, "0.cycleIteration", 0);
        const maxLoop = cycleIteration * 360 + xMax;

        let i = oneLineBearingX;
        while (i <= maxLoop) {
            if (plotLines.length >= bearing.plot_lines_count) {
                break;
            }

            if (i > 360) {
                let tmpX = i;
                while (tmpX > 360) {
                    tmpX -= 360;
                }
                plotLines.push({
                    color: color, // Color value
                    dashStyle: "dash", // Style of the plot line. Default to solid
                    value: tmpX, // Value of where the line will appear
                    width: 1, // Width of the line
                });
                i += oneLineBearingX;
            } else {
                let tmpX = oneLineBearingX;
                while (tmpX <= 360) {
                    plotLines.push({
                        color: color, // Color value
                        dashStyle: "dash", // Style of the plot line. Default to solid
                        value: tmpX, // Value of where the line will appear
                        width: 1, // Width of the line
                    });
                    tmpX += oneLineBearingX;
                    i += oneLineBearingX;
                }
            }
        }
    } else {
        // for (let i = 1; i * val < xMax; i++) {
        for (let i = bearing.plot_lines_count; i > 0; i--) {
            plotLines.push({
                color: color, // Color value
                dashStyle: "dash", // Style of the plot line. Default to solid
                value: val * i, // Value of where the line will appear
                width: 1, // Width of the line
                label: {
                    useHTML: true,
                    text: bearingParam.toUpperCase(),
                    style: {
                        color: "white",
                        backgroundColor: color,
                        marginLeft: 5 + "px",
                        padding: "0 2px",
                        "font-size": "11px",
                    },
                    x: -5,
                    y: 0,
                },
            });
        }
    }

    return plotLines;
};

export const getRpmPlotLines = (xMaxCacher, showRpmPlotLines, currentSpeed = 0) => {
    const plotLines = [];
    if (!showRpmPlotLines) {
        return plotLines;
    }

    if (currentSpeed > 0) {
        const xMax = xMaxCacher.value;

        const period = 1 / FrequencyConverter.fromCpm(currentSpeed).toHz().value;

        for (let value = period; value < xMax; value += period) {
            plotLines.push({
                color: new Highcharts.Color("black").setOpacity(0.5).get(),
                dashStyle: "dash",
                value,
                width: 1,
            });
        }
    }
    return plotLines;
};

export const getBearingLines = ({xMaxCacher, settings, equipment, getBearingFn, speedSettings, series}) => {
    let plotLines = [];
    if (speedSettings?.currentSpeed > 0) {
        const installPoint = _find(equipment.installationPoints, {id: settings.selectedPoint});
        installPoint.bearings.map((bearing, bearingIndex) => {
            BEARING_TYPES.map((bearingParam, bearingParamIndex) => {
                if (_findIndex(settings.activeBearings, {name: bearing.name, type: bearingParam}) !== -1) {
                    const color = _get(BEARING_COLORS, bearingIndex * 4 + bearingParamIndex, "grey");
                    const context = {
                        bearing,
                        bearingParam,
                        color,
                        xMaxCacher,
                        settings,
                        speedSettings,
                        series,
                    };
                    plotLines = [...plotLines, ...getBearingFn(context)];
                }
            });
        });
    }

    return plotLines;
};

export const getFaultFrequenciesPlotLines = (faultFrequencies = [], currentSpeed, selectedFaultFrequencies, selectedPoint) => {
    let plotLines = [];
    if (!faultFrequencies.length || !selectedFaultFrequencies?.length) {
        return [];
    }
    faultFrequencies
        .filter(
            (faultFrequency) =>
                (currentSpeed > 0 || faultFrequency.frequency_type !== FREQUENCY_TYPES.ORDERS) &&
                selectedFaultFrequencies.indexOf(faultFrequency.id) >= 0 &&
                +faultFrequency.installation_point_id === +selectedPoint
        )
        .map((faultFrequency) => {
            const color = "#4696b6";
            const faultFrequencyHz = FrequencyConverter.fromType(faultFrequency.frequency_type, faultFrequency.frequency, currentSpeed)
                .toHz()
                .numberFormat();
            if (!+faultFrequency.plot_lines_count) {
                faultFrequency.plot_lines_count = 1;
            }
            for (let i = faultFrequency.plot_lines_count; i > 0; i--) {
                plotLines = [
                    ...plotLines,
                    {
                        width: 1,
                        value: faultFrequencyHz * i,
                        dashStyle: "Dot",
                        color: color,
                        label: {
                            useHTML: true,
                            text: faultFrequency.name.toUpperCase(),
                            style: {
                                color: "white",
                                backgroundColor: color,
                                marginLeft: 5 + "px",
                                padding: "0 2px",
                                "z-index": 2,
                                "font-size": "10px",
                            },
                            x: -5,
                            y: 0,
                        },
                    },
                ];
            }
        });
    return plotLines;
};

export const getChartMarksFftPlotLines = ({chartMarksFft, currentSpeed, selectedPoint, currentDate, user, isShowChartMarks = true}) => {
    if (!chartMarksFft?.length || !isShowChartMarks) {
        return [];
    }

    return chartMarksFft
        .filter(
            (chartMarkFft) =>
                (!chartMarkFft.installation_point_id || +chartMarkFft.installation_point_id === +selectedPoint) &&
                (!chartMarkFft.timestamp || chartMarkFft.timestamp === currentDate)
        )
        .map((chartMarkFft) => {
            const color = "#46b67f";
            return {
                width: 2,
                value: chartMarkFft.frequency,
                dashStyle: "Dot",
                color: color,
                label: {
                    style: {
                        "z-index": 2,
                    },
                    useHTML: true,
                    text: `
                        <div class="chart-mark-wrapper chart-mark-color">
                            <span class="default-line-label">
                                <i class="fa fa-thumbtack fa-rotate-315"></i>
                            </span>

                            <div class="chart-mark-block" style="border-color: ${color};">
                                <div class="chart-mark-label invisible open-left" style="border-color: ${color}; box-shadow: 1px 1px 3px ${color};">
                                    <div class="custom-scroll chart-mark-label-text">
                                        <span>Marked frequency:
                                            ${FrequencyConverter.fromHz(chartMarkFft.frequency).format()}
                                            ${FrequencyConverter.fromHz(chartMarkFft.frequency).toCpm().format()}
                                            ${
                                                currentSpeed > 0
                                                    ? FrequencyConverter.fromHz(chartMarkFft.frequency, currentSpeed).toOrders().format(2, {withX: true})
                                                    : ""
                                            }
                                        </span>
                                        <span>
                                            ${
                                                chartMarkFft.timestamp
                                                    ? Helper.dateToFormat(
                                                          chartMarkFft.timestamp,
                                                          _get(user, "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0])
                                                      )
                                                    : ""
                                            }
                                        </span>
                                       <b>${chartMarkFft.name}</b>
                                       <p style="white-space: normal">${chartMarkFft.notes || ""}</p>
                                    </div>
                                    <div class="chart-mark-label-btns">
                                        <div data-id="${chartMarkFft.id}" class="btn btn-sm chart-mark-edit btn-primary">Edit</div>
                                        <div data-id="${chartMarkFft.id}" class="btn btn-sm chart-mark-delete btn-danger">Delete</div>
                                    </div>
                                </div>
                            </div>
                        </div>`,
                    rotation: 0,
                },
            };
        });
};
