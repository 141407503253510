import Api from "./api";

const DeviceAlert = {
    getCounts: params => Api.get("device-alert/get-counts", {...params}),
    getList: params => Api.get("device-alert/get-list", {...params}),
    exportList: params => Api.source("device-alert/export-list", {...params}),
    acknowledge: data => Api.post("device-alert/acknowledge", data),
    ignore: data => Api.post("device-alert/ignore", data),
    comments: (id, limit, offset) => Api.get(`device-alert/${id}/comments?limit=${limit||3}&offset=${offset||0}`),
};

export default DeviceAlert;
