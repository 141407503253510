export const deleteItemFromAssetBranch = (assetTree, id) => {
    const filtered = [];

    if (!assetTree) {
        return null;
    }

    assetTree.forEach((child) => {
        let filteredSibling = child.children;

        if (child.children?.length) {
            filteredSibling = deleteItemFromAssetBranch(child.children, id);
        }

        if (child.id !== id) {
            filtered.push({...child, children: filteredSibling});
        }
    });

    return filtered;
};
