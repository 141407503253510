import React, {Component} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../stores/GlobalStore";
import StepsButtons from "./create/steps-buttons";
import Loader from "../../shared/loader/loader";
import {get as _get, each as _each} from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import {HeaderSimple} from "../../shared/header";

class EquipmentProfileCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cancelProfile: null,
        };
    }

    beforeCancel = () => {
        this.setState({
            cancelProfile: true,
        });
    };

    onConfirm = () => {
        const {history} = this.props;
        history.push("/equipment-profile");
    };

    onCancel = () => {
        this.setState({cancelProfile: null});
    };

    isSaveDisabled = () => {
        const {steps, currentStepIndex, profile, auth} = this.props;

        if (!auth.userCan("editEquipment")) {
            return true;
        }

        let isDone = true;
        _each(steps, (step) => {
            if (_get(step, "key") === "general" || _get(step, "key") === "installationPoints") {
                isDone = isDone && step.isDone(profile, currentStepIndex);
            }
        });

        return !(steps.length - 1 === currentStepIndex || isDone);
    };

    render() {
        const {cancelProfile} = this.state;
        const {
            loader,
            steps,
            currentStepIndex,
            setCurrentStepIndex,
            profile,
            chartTypes,
            onChange,
            formErrors,
            addInstallationPoint,
            removeInstallationPoint,
            instPointCustomTypes,
            fetchInstPointCustomType,
            readingTypes,
            alertLevels,
            validateStep,
            addBearing,
            removeBearing,
            defaultBearings,
            fetchBearings,
            onCreate,
            conditions,
            onSaveAsDraft,
            inProgress,
            isCreate,
            equipmentTypes,
            fetchEquipmentTypes,
            validationInProgress,
        } = this.props;

        const isSaveDisabled = this.isSaveDisabled();
        const breadcrumbs = [
            {name: "Equipment Profiles", link: "/equipment-profile"},
            {name: _get(profile, "id") ? "Edit " + _get(profile, "general.name") + " Equipment Profile" : "Add Equipment Profile"},
        ];
        return (
            <React.Fragment>
                <div className={"create-profile-page"}>
                    <div className="container-fluid">
                        <HeaderSimple
                            breadcrumbs={breadcrumbs}
                            hideGlobalSearch
                        />
                        <div className="wrapper-equipment custom-scroll">
                            <div className="block">
                                <div className="block-body">
                                    <div className={"profile-configurator" + (loader ? " loading" : "")}>
                                        {loader ? (
                                            <Loader />
                                        ) : (
                                            <React.Fragment>
                                                <div className={"profile-configurator-header"}>
                                                    <div className={"header-right"}>
                                                        <div className={"title mb-4"}>
                                                            {_get(profile, "id")
                                                                ? _get(profile, "general.name") + " Equipment Profile Configuration"
                                                                : "Add Equipment Profile Configuration"}
                                                        </div>
                                                        <StepsButtons
                                                            steps={steps}
                                                            currentStepIndex={currentStepIndex}
                                                            setCurrentStepIndex={setCurrentStepIndex}
                                                            inProgress={inProgress}
                                                            profile={profile}
                                                            formErrors={formErrors}
                                                        />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className={"step-top"}>
                                                    <div className={"step-title"}>{_get(steps, currentStepIndex + ".name")}</div>
                                                    <div className={"step-description"}>{_get(steps, currentStepIndex + ".description")}</div>
                                                </div>
                                                <hr />
                                                <div className={"step-body"}>
                                                    {React.cloneElement(_get(steps, currentStepIndex + ".template"), {
                                                        profile: profile,
                                                        chartTypes: chartTypes,
                                                        onChange: onChange,
                                                        formErrors: _get(formErrors, _get(steps, currentStepIndex + ".key")),
                                                        addInstallationPoint: addInstallationPoint,
                                                        removeInstallationPoint: removeInstallationPoint,
                                                        instPointCustomTypes: instPointCustomTypes,
                                                        fetchInstPointCustomType: fetchInstPointCustomType,
                                                        readingTypes: readingTypes,
                                                        alertLevels: alertLevels,
                                                        validateStep: validateStep,
                                                        addBearing: addBearing,
                                                        removeBearing: removeBearing,
                                                        defaultBearings: defaultBearings,
                                                        fetchBearings: fetchBearings,
                                                        conditions: conditions,
                                                        inProgress: validationInProgress,
                                                        equipmentTypes: equipmentTypes,
                                                        fetchEquipmentTypes: fetchEquipmentTypes,
                                                    })}
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {!loader && (
                                <div className="d-flex justify-content-end controls-btns-equipment">
                                    {inProgress ? (
                                        <div className={"btn btn-secondary mr-2"}>
                                            Cancel <i className="fa fa-spinner ml-2" />
                                        </div>
                                    ) : (
                                        <div
                                            // to={"/equipment-profile"}
                                            className={"btn btn-secondary mr-2"}
                                            onClick={this.beforeCancel}
                                        >
                                            Cancel
                                        </div>
                                    )}

                                    {!!(isCreate || _get(profile, "isDraft")) && (
                                        <React.Fragment>
                                            {inProgress ? (
                                                <div className={"btn v2-btn blue-border mr-2"}>
                                                    Save as Draft <i className="fa fa-spinner ml-2" />
                                                </div>
                                            ) : (
                                                <div
                                                    className={"btn v2-btn blue-border mr-2"}
                                                    onClick={onSaveAsDraft}
                                                >
                                                    Save as Draft
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}

                                    {!isSaveDisabled && (
                                        <div
                                            className={"btn btn-primary" + (inProgress ? " disabled" : "")}
                                            onClick={() => {
                                                if (!inProgress) {
                                                    onCreate();
                                                }
                                            }}
                                        >
                                            <React.Fragment>
                                                {inProgress && <i className="fa fa-spinner ml-2" />}
                                                Save
                                            </React.Fragment>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {cancelProfile && (
                            <SweetAlert
                                warning
                                showCancel
                                confirmBtnText="Yes"
                                cancelBtnBsStyle="default"
                                btnSize="xs"
                                openAnim={false}
                                closeAnim={false}
                                onConfirm={this.onConfirm}
                                onCancel={this.onCancel}
                            >
                                Are you sure you want to navigate away from this page? If you proceed, ALL of your changes will be lost. Click Yes to
                                continue, or click Cancel to stay on the current page.
                            </SweetAlert>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

EquipmentProfileCreate.propTypes = {
    loader: PropTypes.bool,
    steps: PropTypes.array,
    currentStepIndex: PropTypes.number,
    setCurrentStepIndex: PropTypes.func,
    profile: PropTypes.object,
    chartTypes: PropTypes.object,
    onChange: PropTypes.func,
    formErrors: PropTypes.object,
    addInstallationPoint: PropTypes.func,
    removeInstallationPoint: PropTypes.func,
    instPointCustomTypes: PropTypes.array,
    fetchInstPointCustomType: PropTypes.func,
    readingTypes: PropTypes.array,
    alertLevels: PropTypes.array,
    validateStep: PropTypes.func,
    addBearing: PropTypes.func,
    removeBearing: PropTypes.func,
    defaultBearings: PropTypes.array,
    fetchBearings: PropTypes.func,
    onCreate: PropTypes.func,
    conditions: PropTypes.object,
    onSaveAsDraft: PropTypes.func,
    inProgress: PropTypes.bool,
    isCreate: PropTypes.bool,
    equipmentTypes: PropTypes.array,
    fetchEquipmentTypes: PropTypes.func,
    validationInProgress: PropTypes.bool,
    auth: PropTypes.object,
    history: PropTypes.object,
};

export default withGlobalStore(EquipmentProfileCreate);
