import React, { useReducer } from "react";
import PropTypes from "prop-types";
import AssignPointsModal from "../../modals/equipment/assign-points";
import DropzoneWrapper from "./components/dropzoneWrapper";
import { get as _get, set as _set, setWith as _setWith, unset as _unset } from "lodash";
import Toast from "../../pages/shared/toast";
import { ACTION, reducer } from "./reducer/mainReducer";

const initialState = {
    assignPointsModal: {
        open: false,
        current: null,
    },
};

// TODO research about usage
const EquipmentImageDropzone = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { assignPointsModal } = state;
    const { images, colors, points } = props;

    const onDropFile = (files) => {
        let currentImages = [...images];

        Promise.all(
            Object.keys(files).map(
                (file) =>
                    new Promise((resolve, reject) => {
                        if (files[file]) {
                            let reader = new FileReader();
                            reader.readAsDataURL(files[file]);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = (error) => reject(error);
                        } else {
                            reject("File is empty");
                        }
                    })
            )
        ).then((images = []) => {
            images.forEach((image) => {
                currentImages.push({
                    data: image,
                    flipTurn: null,
                    positions: {},
                    imagePointIndex: null,
                });
            });
            if (currentImages.length > 10) {
                Toast.error("Images must be less than 10.");
                return;
            }

            onChange(currentImages);
        });
    };

    const onRemoveFile = (index) => {
        let currentImages = [...images];
        const { assignPointsModal } = state;

        currentImages.splice(index, 1);

        if (assignPointsModal.current === index) {
            dispatch({
                type: ACTION.SET_STATE,
                state: { assignPointsModal: { ...assignPointsModal, current: 0 } },
            });
        }

        onChange(currentImages);
    };

    const onChange = (images) => {
        const { onChange } = props;

        onChange({ target: { name: "images", value: images } });
    };

    const toggleAssignPointsModal = (index) => {
        let assignPointsModal = { ...state.assignPointsModal };

        dispatch({
            type: ACTION.SET_STATE,
            state: {
                assignPointsModal: {
                    open: !assignPointsModal.open,
                    current: !assignPointsModal.open ? index : null,
                },
            },
        });
    };

    const onFlipFile = (index, isRedo = false) => {
        let currentImages = [...images];
        let image = _get(currentImages, index);
        const flipTurn = _get(image, "flipTurn", 0) + (isRedo ? 0.25 : -0.25);
        let positions = _get(image, "positions", {});

        if (Object.keys(positions || {}).length) {
            Object.keys(positions).forEach((key) => {
                const cTop = positions[key].top / positions[key].percent_top;
                const cLeft = positions[key].left / positions[key].percent_left;
                let percentLeft, percentTop;
                if (isRedo) {
                    percentTop = positions[key].percent_left;
                    percentLeft = 100 - positions[key].percent_top;
                } else {
                    percentLeft = positions[key].percent_top;
                    percentTop = 100 - positions[key].percent_left;
                }
                positions[key].percent_top = percentTop;
                positions[key].percent_left = percentLeft;
                positions[key].top = Math.round(cLeft * percentTop);
                positions[key].left = Math.round(cTop * percentLeft);
            });
        }

        _set(currentImages, index + ".flipTurn", flipTurn);
        _set(currentImages, index + ".positions", positions);

        onChange(currentImages);
    };

    const setCurrentInstPointIndex = (indexImage, indexInstPoint) => {
        let currentImages = [...images];

        _set(currentImages, indexImage + ".imagePointIndex", indexInstPoint);

        onChange(currentImages);
    };

    const onSetPosition = (data = {}) => {
        let currentImages = [...images];

        if (data.clear === true) {
            _unset(currentImages, `${data.imageIndex}.positions.${data.pointIndex}`);
        } else {
            _setWith(
                currentImages,
                `${data.imageIndex}.positions.${data.pointIndex}`,
                data,
                Object
            );
        }

        onChange(currentImages);
    };

    const onSetCurrent = (index) =>
        dispatch({
            type: ACTION.SET_STATE,
            state: { assignPointsModal: { ...state.assignPointsModal, current: index } },
        });

    return (
        <React.Fragment>
            <DropzoneWrapper
                images={images.map((image) => {
                    return image.data;
                })}
                flipTurns={images.map((image) => {
                    return image.flipTurn;
                })}
                onDrop={onDropFile}
                onAssignPoints={toggleAssignPointsModal}
                onFlipFile={onFlipFile}
                onRemove={onRemoveFile}
                mainPlaceholder={"Drag and drop or upload files (up to 10)"}
            />
            {assignPointsModal.open && (
                <AssignPointsModal
                    assignPointsModal={assignPointsModal}
                    colors={colors}
                    images={images.map((image) => {
                        return image.data;
                    })}
                    points={points}
                    flipTurns={images.map((image) => {
                        return image.flipTurn;
                    })}
                    positions={images.map((image) => {
                        return image.positions;
                    })}
                    imageInstallationPoint={images.map((image) => {
                        return image.imagePointIndex;
                    })}
                    setCurrentInstPointIndex={setCurrentInstPointIndex}
                    onDrop={onDropFile}
                    onRemove={onRemoveFile}
                    onFlipFile={onFlipFile}
                    onSetCurrent={onSetCurrent}
                    onSetPosition={onSetPosition}
                    onClose={toggleAssignPointsModal}
                    onSubmit={toggleAssignPointsModal}
                />
            )}
        </React.Fragment>
    );
};

EquipmentImageDropzone.propTypes = {
    images: PropTypes.array,
    onChange: PropTypes.func,
    colors: PropTypes.array,
    points: PropTypes.array,
};

export default EquipmentImageDropzone;
