import React from "react";
import Select from "react-select";
import {useWuaReadingTypesQuery} from "../../../hooks/api/readingTypes/useWuaReadingTypesQuery";
import PropTypes from "prop-types";

export const WuaOverlayForm = ({chartType, data, addAnotherSensor, handleChangeEquipment, handleChangeInstallationPoint, removeAnotherSensor}) => {
    const {data: wuaReadingTypes, isLoading} = useWuaReadingTypesQuery({enabled: true, readingTypeId: chartType});

    return (
        <>
            {data.map((item, index) => (
                <div
                    className="row mb-2"
                    key={index}
                >
                    <div className="col-5 mr-1 align-items-center">
                        <Select
                            isLoading={isLoading}
                            options={wuaReadingTypes}
                            getOptionLabel={(item) => item.name}
                            getOptionValue={(item) => item.id}
                            value={data[index].equipment}
                            onChange={(e) => handleChangeEquipment(e, index)}
                            className={"react-select"}
                            classNamePrefix={"react-select"}
                        />
                    </div>
                    <div className="col-5 mr-1">
                        <Select
                            isLoading={isLoading}
                            isDisabled={!data?.[index]?.equipment?.installationPoints}
                            options={data?.[index]?.equipment?.installationPoints}
                            value={data?.[index]?.installationPoint}
                            getOptionLabel={(item) => item.name}
                            getOptionValue={(item) => item.sensor_id}
                            className={"react-select"}
                            classNamePrefix={"react-select"}
                            onChange={(e) => handleChangeInstallationPoint(e, index)}
                        />
                    </div>
                    {index > 0 && (
                        <div className={"col-1"}>
                            <span
                                className="remove-block position-relative mt-2"
                                onClick={() => removeAnotherSensor(index)}
                            >
                                <i className="fa fa-times-circle" />
                            </span>
                        </div>
                    )}
                </div>
            ))}
            {data.length <= 10 && (
                <span
                    className="add-installation-point-btn ml-0"
                    onClick={addAnotherSensor}
                >
                    <i className="fa fa-plus-circle"></i>
                    <span>Add another sensor</span>
                </span>
            )}
        </>
    );
};

WuaOverlayForm.propTypes = {
    chartType: PropTypes.number,
    data: PropTypes.array,
    addAnotherSensor: PropTypes.func,
    handleChangeEquipment: PropTypes.func,
    removeAnotherSensor: PropTypes.func,
    handleChangeInstallationPoint: PropTypes.func,
};
