import Api from "./api";

const ApiFaultFrequency = {
    create: (params) => {
        return Api.post(`equipment/${params.equipmentId}/fault-frequency`, {...params});
    },
    update: (equipmentId, faultFrequencyId, params) => {
        return Api.patch(`equipment/${equipmentId}/fault-frequency/${faultFrequencyId}`, {...params});
    },
    get: (equipmentId) => {
        return Api.get(`equipment/${equipmentId}/fault-frequency`);
    },
    delete: (equipmentId, faultFrequencyId) => {
        return Api.delete(`equipment/${equipmentId}/fault-frequency/${faultFrequencyId}`);
    },
};

export default ApiFaultFrequency;
