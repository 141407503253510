import React, {Component} from "react";
import PropTypes from "prop-types";
import {get as _get} from "lodash";
import Helper from "../../../helpers/helper";
import {Link} from "react-router-dom";
import TemplateParametersPopover from "./popover";

class Row extends Component
{
    render() {
        const {item, onDelete, query} = this.props;
        const parameters = _get(item, "parameters", []);

        return (
            <React.Fragment>
                <tr>
                    <td dangerouslySetInnerHTML={{__html: Helper.highlight(_get(item, "name"), query)}} />
                    <td className={`parameter-line-${item.id}`}>
                        <span className="cursor-pointer" id={`popover-parameter-${item.id}`} title={parameters.length ? "View Parameters" : ""}>
                            <span className="menu-count badge badge-info ml-2"><span>{parameters.length}</span></span>
                            {!!parameters.length &&
                                <TemplateParametersPopover
                                    popoverProps={{
                                        target: `popover-parameter-${item.id}`,
                                        container: `.parameter-line-${item.id}`
                                    }}
                                    parameters={parameters}
                                />
                            }
                        </span>
                    </td>
                    <td>
                        <Link
                            to={"/nameplates/templates/edit/" + item.id}
                            className="link link-primary"
                        >
                            <i className="fa fa-pencil-alt"/>
                            <span>Edit</span>
                        </Link>
                        <button className="link link-danger" onClick={() => onDelete(item.id)}>
                            <i className="fa fa-times"/>
                            <span>Delete</span>
                        </button>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

Row.propTypes = {
    item: PropTypes.object,
    props: PropTypes.object,
    query: PropTypes.string,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};

export default Row;