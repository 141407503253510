import Api from "./api";

const WuaSensorApi = {
    getList: params => Api.get("wua-sensors", {...params}),
    getView: id => Api.get(`wua-sensors/${id}`),
    create: data => Api.post("wua-sensors", data),
    update: (id, data) => Api.patch(`wua-sensors/${id}`, data),
    delete: id => Api.delete(`wua-sensors/${id}`),
    export: (params) => {
        return Api.source("wua-sensors/export", {...params});
    },
};

export default WuaSensorApi;