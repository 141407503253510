import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {UncontrolledTooltip} from "reactstrap";
import {withGlobalStore} from "../../../../../stores/GlobalStore";
import AssignPointsModal from "../../../../../modals/equipment/assign-points";
import {filter as _filter, setWith as _setWith, orderBy as _orderBy, findKey as _findKey, find as _find, get as _get} from "lodash";
import "./images.scss";
import "../../../../../assets/scss/components/equipment/equipment-slider.scss";
import {useEquipmentByParams} from "../../../../../hooks/api/equipment/useEquipmentQuery";

const useImagesPointPositions = (equipment) => {
    const equipmentImages = equipment.images || {};
    const equipmentPoints = equipment.installationPoints || {};

    let colors = [],
        images = [],
        positions = {};

    for (let key in equipmentPoints) {
        colors.push(equipmentPoints[key].color);
    }

    for (let [imageIndex, image] of Object.entries(equipmentImages)) {
        images.push(image.url);
        for (let [pointIndex, imagePoint] of Object.entries(image.positions || {})) {
            if (+imagePoint.installation_point_id === 0) {
                _setWith(
                    positions,
                    [imageIndex, 999],
                    {
                        ...imagePoint,
                        color: "#ffcd05",
                        pointIndex,
                        imageIndex,
                    },
                    Object
                );
            } else {
                for (let [equipmentPointIndex, equipmentPoint] of Object.entries(equipmentPoints)) {
                    if (+equipmentPoint.id === +imagePoint.installation_point_id) {
                        _setWith(
                            positions,
                            [imageIndex, equipmentPointIndex],
                            {
                                ...imagePoint,
                                color: colors[pointIndex],
                                pointIndex,
                                imageIndex,
                            },
                            Object
                        );
                    }
                }
            }
        }
    }

    return {
        colors,
        images,
        positions,
        points: equipmentPoints,
    };
};

const Images = (props) => {
    const {equipmentItem: equipment} = useEquipmentByParams();

    const [assignPointsModal, setAssignPointsModal] = useState({
        current: 0,
        open: false,
    });

    const [currentPhoto, setCurrentPhoto] = useState(0);

    const {colors, images, points, positions} = useImagesPointPositions(equipment);

    const toggleEditPositionsModal = (current) => {
        setAssignPointsModal({open: !assignPointsModal.open, current});
    };

    const {equipmentSelected} = props;

    let orderedImages = _orderBy(equipment?.images, (image) => +image.is_primary_image, "desc") || [];

    if ((equipmentSelected[equipment?.id] || []).length) {
        orderedImages = _filter(orderedImages, (image) => {
            if ((image.positions || []).length) {
                if (_find(image.positions, (position) => +position.installation_point_id === 0)) {
                    return true;
                }
                return !!(_filter(image.positions, (position) => equipmentSelected[equipment.id].indexOf(position.installation_point_id) !== -1) || [])
                    .length;
            }
            return false;
        });
    } else {
        orderedImages = _filter(orderedImages, (image) => !(image.positions || []).length);
    }

    orderedImages = orderedImages && orderedImages[0] ? orderedImages : [];

    const key = orderedImages[currentPhoto] ? currentPhoto : 0;

    let dividerText;
    if (!equipment?.images.length) {
        dividerText = "No Photos";
    } else if (!equipmentSelected[equipment.id]?.length) {
        dividerText = "Not Selected";
    } else if (!orderedImages.length || !((orderedImages[key] || {}).positions || []).length) {
        orderedImages[key] = _find(equipment.images, (image) => +image.is_primary_image === 1) || equipment.images[0];
        dividerText = "Not Assigned For Selected";
    } else if ((orderedImages[key].positions || []).length === 1 && +orderedImages[key].positions[0].installation_point_id === 0) {
        dividerText = "Equipment Photo";
    } else {
        dividerText = (
            orderedImages[key].positions.map(
                (position) => (_find(equipment.installationPoints, (point) => +point.id === +position.installation_point_id) || {}).name || ""
            ) || []
        ).join(", ");
    }

    const originalKey = orderedImages[key] ? _findKey(equipment.images, {url: orderedImages[key].url}) : 0;

    if ((orderedImages[key] || {}).installation_point_id) {
        dividerText = (_find(equipment.installationPoints, (point) => +point.id === +orderedImages[key].installation_point_id) || {}).name || "";
    }

    return (
        <Fragment>
            {orderedImages.length && orderedImages[key] ? (
                <div className="sliderWrapper equipment-slider">
                    <div className="slide-item">
                        {orderedImages.length > 1 && (
                            <Fragment>
                                <span
                                    className="sliderPrevBtn"
                                    onClick={() => setCurrentPhoto((key === 0 ? orderedImages.length : key) - 1)}
                                >
                                    <i className="fa fa-angle-left" />
                                </span>
                                <span
                                    className="sliderNextBtn"
                                    onClick={() => setCurrentPhoto(key === orderedImages.length - 1 ? 0 : key + 1)}
                                >
                                    <i className="fa fa-angle-right" />
                                </span>
                            </Fragment>
                        )}
                        <span
                            className={"equipment-photo-container"}
                            style={{position: "relative", display: "inline-block"}}
                        >
                            <div
                                className={"click-text"}
                                onClick={() => toggleEditPositionsModal(originalKey)}
                            >
                                Click to Edit
                            </div>
                            <img
                                className="previews-photos"
                                src={orderedImages[key].url + "&resize=150_200"}
                                alt={"Click to Edit"}
                            />
                            {(orderedImages[key].positions || []).map(
                                (position) =>
                                    +position.installation_point_id !== 0 && (
                                        <span
                                            className="img-tooltip-wrapper"
                                            id={`tooltip_${position.id}`}
                                            key={position.id}
                                            style={{
                                                position: "absolute",
                                                top: position.percent_top + "%",
                                                left: position.percent_left + "%",
                                            }}
                                        >
                                            <span
                                                className="img-tooltip"
                                                style={{
                                                    backgroundColor:
                                                        _get(
                                                            equipment.installationPoints.filter(
                                                                (installationPoints) => +installationPoints.id === +position.installation_point_id
                                                            ),
                                                            "0.color"
                                                        ) || "#31aee3",
                                                }}
                                            >
                                                {Object.keys(equipment.installationPoints).map((ipKey) => {
                                                    if (+equipment.installationPoints[ipKey].id === +position.installation_point_id) {
                                                        return +ipKey + 1;
                                                    }
                                                })}
                                            </span>
                                            <span
                                                className="img-tooltip-arrow"
                                                style={{
                                                    borderColor:
                                                        (_get(
                                                            equipment.installationPoints.filter(
                                                                (installationPoints) => +installationPoints.id === +position.installation_point_id
                                                            ),
                                                            "0.color"
                                                        ) || "#31aee3") + " transparent transparent transparent",
                                                }}
                                            />
                                            <UncontrolledTooltip
                                                placement="bottom"
                                                target={`#tooltip_${position.id}`}
                                            >
                                                {Object.keys(equipment.installationPoints).map((ipKey) => {
                                                    if (+equipment.installationPoints[ipKey].id === +position.installation_point_id) {
                                                        return equipment.installationPoints[ipKey].name;
                                                    }
                                                })}
                                            </UncontrolledTooltip>
                                        </span>
                                    )
                            )}
                        </span>
                    </div>
                    {(orderedImages[key].positions || []).length ? (
                        <div
                            className="divider"
                            title={dividerText}
                        >
                            {dividerText.length > 30 ? dividerText.substr(0, 29) + "..." : dividerText}
                        </div>
                    ) : (
                        <div className="divider">{dividerText}</div>
                    )}
                </div>
            ) : (
                <Fragment>
                    <span
                        className={"equipment-photo-container"}
                        style={{position: "relative", display: "inline-block"}}
                    >
                        <div
                            className={"click-text"}
                            onClick={() => toggleEditPositionsModal(originalKey)}
                        >
                            Click to Edit
                        </div>
                        <img
                            onClick={() => toggleEditPositionsModal(0)}
                            className="img-fluid"
                            style={{cursor: "pointer", margin: "15px 0"}}
                            src="/assets/img/default-equipment-bg.png"
                            alt=""
                        />
                    </span>
                    <div
                        className="divider"
                        style={{marginBottom: 10}}
                    >
                        {dividerText}
                    </div>
                </Fragment>
            )}
            {assignPointsModal.open && (
                <AssignPointsModal
                    assignPointsModal={assignPointsModal}
                    equipment={equipment}
                    colors={colors}
                    images={images}
                    points={points}
                    positions={positions}
                    onClose={toggleEditPositionsModal}
                />
            )}
        </Fragment>
    );
};

Images.propTypes = {
    auth: PropTypes.object,
    equipment: PropTypes.object,
    equipmentSelected: PropTypes.object,
    onSubmit: PropTypes.func,
    handleUpdateImages: PropTypes.func,
};

export default withGlobalStore(Images);
