import {RANGE_VALUES, useChartSelectedRangeStore} from "../../../../stores/zustand/ChartSelectedRangeStore";
import {useCurrentAlert} from "../../../../stores/CurrentAlertStore";
import moment from "moment/moment";
import {forEach as _forEach, cloneDeep as _cloneDeep} from "lodash";

const getTimestampForAlert = (alert, currentRange) => {
    const isFFT = alert.alertCondition.fft_alert_type === 1 || alert.alertCondition.fft_alert_type === 2;
    if (!isFFT) {
        const rangeSelectorsList = _cloneDeep(RANGE_VALUES);
        const timestamp = moment(alert.timestamp).toDate().getTime();

        const now = Date.now();

        const rangeObject = {
            range: currentRange,
            isCustom: false,
        };

        let isFound = true;

        if (timestamp < +(now - rangeObject.range)) {
            _forEach(rangeSelectorsList, (range, index) => {
                console.log(timestamp > +(now - range.mtime));
                if (timestamp > +(now - range.mtime)) {
                    rangeObject.range = range.mtime;
                    return;
                }
                if (index === rangeSelectorsList.length - 1) {
                    isFound = false;
                }
            });
        }

        if (!isFound) {
            rangeObject.isCustom = true;
            rangeObject.from = moment(alert.timestamp).utc().subtract(7, "days").unix();
            rangeObject.to = moment(alert.timestamp).utc().add(7, "days").unix();
        }

        return rangeObject;
    }
};

export const useChartRangeWithAlert = () => {
    const rangeSelector = useChartSelectedRangeStore();
    const currentAlert = useCurrentAlert();

    const isFFT = currentAlert?.alertCondition?.fft_alert_type === 1 || currentAlert?.alertCondition?.fft_alert_type === 2;

    if (isFFT) {
        return rangeSelector;
    }

    return currentAlert ? getTimestampForAlert(currentAlert, rangeSelector.range) : rangeSelector;
};
