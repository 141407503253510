import React from "react";
import PropTypes from "prop-types";
import "./formCheckbox.scss";
//TODO! remake another checkboxes

const FormCheckbox = ({ children, onChange, checked, checkboxColor = "#4b4b4b" }) => {
    return (
        <label className="checkbox">
            <input type="checkbox" checked={checked} onChange={onChange} />
            <span
                style={{
                    borderColor: checkboxColor,
                    ...(checked ? { backgroundColor: checkboxColor } : {}),
                }}
            />
            {children}
        </label>
    );
};

FormCheckbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    children: PropTypes.string,
    checkboxColor: PropTypes.string,
};
export default FormCheckbox;
