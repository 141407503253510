import React from "react";
import Breadcrumbs from "../../breadcrumbs/breadcrumbs";
import auth from "../../../services/auth";
import Helper from "../../../helpers/helper";
import PropTypes from "prop-types";
import {useHistory} from "react-router";
import EquipmentInfo from "./components/equipmentInfo";
import HeaderActionButtons from "./components/headerActionButtons";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useEquipmentBreadcrumbsStore} from "../../../stores/zustand/EquipmentBreadcrumbsStore";

const HeaderEquipment = (props) => {
    const {
        countActiveEquipmentAlarms,
        user,
        globalSearchString,
        handleChangeGlobalSearch,
        notesCount,
        selectedAlarms,
        setSelectedAlarms,
        excluded,
        handleNotesCount,
    } = props;
    const breadcrumbs = useEquipmentBreadcrumbsStore();
    const {equipmentItem: equipment} = useEquipmentByParams();
    const history = useHistory();

    const handleODModal = () => {
        history.push(Helper.setHashParams({modal: "request-od"}));
    };

    const handleActiveAlertsModal = () => {
        history.push(Helper.setHashParams({modal: "active-alarms"}));
    };

    const handleSpeedSettingsModal = () => {
        history.push(Helper.setHashParams({modal: "speed-settings"}));
    };

    const handleNotesModal = () => {
        history.push(Helper.setHashParams({modal: "notes", equipment_id: equipment.id}));
    };

    const handleEquipmentModal = () => {
        history.push(Helper.setHashParams({modal: "equipment"}));
    };

    const handleEquipmentProfileModal = () => {
        history.push(Helper.setHashParams({modal: "equipment-profile"}));
    };

    const handleEquipmentProfileModalRemove = () => {
        history.push(Helper.setHashParams({modal: "equipment-profile-remove"}));
    };

    const showNameplateModal = () => {
        history.push(Helper.setHashParams({modal: "nameplate"}));
    };

    const handleUniversalAdapterModal = () => {
        history.push(Helper.setHashParams({modal: "universal-adapter"}));
    };

    return (
        <div
            className="header equipment-page"
            id="k_header"
        >
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <div className="header__footer">
                <div className="header-left">
                    <EquipmentInfo
                        equipment={equipment}
                        showModal={handleEquipmentModal}
                    />
                </div>
                <div className="header-right">
                    <HeaderActionButtons
                        history={history}
                        equipment={equipment}
                        handleODModal={handleODModal}
                        handleActiveAlertsModal={handleActiveAlertsModal}
                        handleSpeedSettingsModal={handleSpeedSettingsModal}
                        countAlerts={countActiveEquipmentAlarms}
                        globalSearchString={globalSearchString}
                        handleChangeGlobalSearch={handleChangeGlobalSearch}
                        handleNotesModal={handleNotesModal}
                        handleEquipmentProfileModal={handleEquipmentProfileModal}
                        handleEquipmentProfileModalRemove={handleEquipmentProfileModalRemove}
                        notesCount={notesCount}
                        showNameplateModal={showNameplateModal}
                        handleUniversalAdapterModal={handleUniversalAdapterModal}
                        auth={auth}
                        user={user}
                        selectedAlarms={selectedAlarms}
                        setSelectedAlarms={setSelectedAlarms}
                        handleNotesCount={handleNotesCount}
                        excluded={excluded}
                    />
                </div>
            </div>
        </div>
    );
};
HeaderEquipment.propTypes = {
    equipment: PropTypes.object,
    breadcrumbs: PropTypes.array,
    countActiveEquipmentAlarms: PropTypes.any,
    user: PropTypes.any,
    globalSearchString: PropTypes.any,
    handleChangeGlobalSearch: PropTypes.any,
    notesCount: PropTypes.any,
    selectedAlarms: PropTypes.array,
    setSelectedAlarms: PropTypes.func,
    excluded: PropTypes.array,
    handleNotesCount: PropTypes.func,
};
export default HeaderEquipment;
