import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get,
    find as _find,
    omit as _omit,
    set as _set,
    map as _map
} from "lodash";
import NameplateApi from "../../../api/nameplate";

class NameplateDataHandler extends Component
{
    constructor(props) {
        super(props);

        const newData = {
            template_id: props.templateId,
            name: "",
            parameters: [],
            photos: []
        };

        this.state = {
            data: props.nameplate ? {...props.nameplate} : {...newData},
            formErrors: {},
            inProgress: false
        };
    }

    componentDidMount() {
        if (!this.props.nameplate && this.state.data.template_id) {
            this.updateParameters();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (+_get(prevState, "data.template_id", 0) !== +_get(this.state, "data.template_id", 0)
        ) {
            this.updateParameters();
        }
    }

    updateParameters = () => {
        const {templates} = this.props;
        let data = {...this.state.data};
        let template = _find(templates, {id: +_get(data, "template_id")});
        let templateParameters = _get(template, "parameters");

        let parameters = [];

        _map(templateParameters, (parameter, index) => {
            let newParameter = {
                id: _get(parameter, "parameter_id"),
                name: _get(parameter, "name", ""),
                value: _get(parameter, "value", ""),
                default_values: _get(parameter, "default_values", []),
                is_required: true
            };

            _set(parameters, index, newParameter);
        });

        _set(data, "parameters", parameters);

        this.setState({data});
    }

    addParameter = (parameter) => {
        let data = {...this.state.data};
        let parameters = [...data.parameters];

        let newParameter = {
            id: _get(parameter, "id"),
            name: _get(parameter, "name", ""),
            value: _get(parameter, "value", ""),
            default_values: _get(parameter, "default_values", []),
            is_required: false
        };

        _set(parameters, parameters.length, newParameter);

        _set(data, "parameters", parameters);

        this.setState({data});
    }

    deleteParameter = (index) => {
        let data = {...this.state.data};
        let parameters = [...data.parameters];

        parameters.splice(index, 1);
        _set(data, "parameters", parameters);

        this.setState({data, formErrors: {}});
    }

    onChange = (event) => {
        let data = {...this.state.data};
        const key = _get(event, "target.name");
        const value = _get(event, "target.value");

        this.removeError(key);

        _set(data, key, value);

        this.setState({data});
    }

    removeError = (key) => {
        const oldFormErrors = {...this.state.formErrors};

        const formErrors = _omit(oldFormErrors, key);

        this.setState({formErrors});
    }

    onSave = () => {
        const {onSuccessSave} = this.props;

        return this.sendData()
            .then((response) => {
                this.setState({inProgress: false});
                if (response.result && response.status === "ok") {
                    onSuccessSave(response.result);
                }
            })
            .catch(response => {
                this.setState({inProgress: false});
                if (response.status === 422) {
                    this.setState({formErrors: response.errors || {}});
                }
            });
    }

    sendData = () => {
        const {data} = this.state;
        const {nameplate} = this.props;
        const id = _get(nameplate, "id");

        this.setState({inProgress: true});
        return id ? NameplateApi.update(id, data) : NameplateApi.create(data);
    }

    render() {
        const {data, formErrors, inProgress} = this.state;

        return (
            <React.Fragment>
                {this.props.children(
                    data,
                    formErrors,
                    inProgress,
                    this.addParameter,
                    this.deleteParameter,
                    this.onChange,
                    this.onSave
                )}
            </React.Fragment>
        );
    }
}

NameplateDataHandler.propTypes = {
    children: PropTypes.any,
    nameplate: PropTypes.object,
    templates: PropTypes.array,
    templateId: PropTypes.number,
    onSuccessSave: PropTypes.func
};

export default NameplateDataHandler;
