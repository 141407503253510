import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import NotesTimelineBlock from "../../shared/notes-timeline-block";
import BasePopover from "../../shared/base-popover";
import ApiNote from "../../../api/note";
import ApiEquipment from "../../../api/equipment";
import Helper from "../../../helpers/helper";
import SweetAlert from "react-bootstrap-sweetalert";
import { withGlobalStore } from "../../../stores/GlobalStore";

const NotesPopover = (props) => {
    const [show, setShow] = useState(false);
    const [notes, setNotes] = useState([]);
    const [deletedId, setDeletedId] = useState(null);
    const { user, history } = props;

    useEffect(() => {
        if(show) {
            fetchNotes();
        }
    }, [show]);

    const toggle = () => {
        setShow((prev) => !prev);
        props.history.push(Helper.deleteHashParams(["modal", "note-edit", "id"]));
    };

    const fetchNotes = () => {
        return ApiEquipment.notes(props.equipmentId, 100).then(({ list: notes }) => {
            setNotes(notes);
            if (props.onActionCallback) {
                props.onActionCallback({ notes });
            }
        });
    };

    const onSubmitNoteCreate = (data) => {
        const { user, equipmentId } = props;

        return ApiNote.add({
            text: data.text,
            equipment_id: equipmentId,
            user_name: user.name_first + " " + user.name_last,
            user_id: user.id,
        }).then(({ status, message }) => {
            if (status === "ok") {
                fetchNotes();
            } else if (status === "error") {
                console.error(message);
            }
            props.history.push(Helper.deleteHashParams(["modal"]));
        });
    };

    const onDelete = (noteId) => {
        setDeletedId(noteId);
    };
    const deleteNote = () => {
        ApiNote.delete(deletedId).then(() => {
            onDelete(null);
            fetchNotes();
        });
    };

    const onSubmitNoteEdit = (data, id) => {
        const { user, equipmentId } = props;
        const searchParams = Helper.getHashParams();

        return ApiNote.edit({
            text: data.text,
            equipment_id: equipmentId,
            user_name: user.name_first + " " + user.name_last,
            user_id: user.id,
            id,
        }).then(() => {
            fetchNotes().then(() => {
                if (searchParams.modal === "note-edit") {
                    props.history.push(Helper.deleteHashParams(["modal", "id"]));
                } else {
                    props.history.push(Helper.deleteHashParams(["id"]));
                }
            });
        });
    };

    const searchParams = Helper.getHashParams();

    return (
        <React.Fragment>
            <BasePopover {...props.popoverProps} isOpen={show} toggle={toggle}>
                {notes.length > 0 && (
                    <NotesTimelineBlock
                        notes={notes}
                        user={user}
                        searchParams={searchParams}
                        history={history}
                        onSubmitEdit={onSubmitNoteEdit}
                        onSubmitCreate={onSubmitNoteCreate}
                        onDelete={onDelete}
                    />
                )}
            </BasePopover>
            {deletedId && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title="DELETE NOTE"
                    onConfirm={deleteNote}
                    onCancel={() => onDelete(null)}
                >
                    Are you sure you want to delete this note?
                </SweetAlert>
            )}
        </React.Fragment>
    );
};
NotesPopover.propTypes = {
    equipmentId: PropTypes.number,
    popoverProps: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
    onActionCallback: PropTypes.func,
};
export default withGlobalStore(NotesPopover);
