import React, {useRef} from "react";
import PropTypes from "prop-types";
import {useDrag, useDrop} from "react-dnd";
import {get} from "lodash";
import {Link} from "react-router-dom";
import ReadingTypeBlock from "./reading-type-block";
import {withGlobalStore} from "../../stores/GlobalStore";
import {upperFirst as _upperFirst} from "lodash";
import { EquipmentInfo } from "./components/EquipmentInfo";

const EquipmentBlock = (props) => {
    return props.dnd.isSorting ? <DnDBlock {...props}/> : <Block {...props}/>;
};

const DnDBlock = ({equipment, index, groupIndex, moveCard, auth}) => {
    const ref = useRef(null);

    let backgroundImage = "/assets/img/no-photo.png";

    if (equipment.images.length && get(equipment, "images.0.url")) {
        backgroundImage = get(equipment, "images.0.url") + "&resize=150_200";
    }

    const readingTypes = get(equipment, "readingTypes", {});
    const hasReadingTypes = Object.keys(readingTypes).length > 0;

    const [, drop] = useDrop({
        accept: "card",
        hover(item, monitor) {
            if (!ref.current || item.index === index || item.groupIndex !== groupIndex) return;
            const hoverBoundingRect = ref.current.getClientRects();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (item.index < index && hoverClientY < hoverMiddleY) return;
            if (item.index > index && hoverClientY > hoverMiddleY) return;
            moveCard(item.index, index);
            item.index = index;
        },
    });

    const [{isDragging}, drag] = useDrag({
        item: {type: "card", index, groupIndex},
        collect: monitor => ({isDragging: monitor.isDragging()})
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            className="col-md-6 col-xl-4 col-xxl-3 mb-3 equipment-card-link equipment-block"
            style={{
                cursor: "move",
                transform: "translate3d(0, 0, 0)",
                opacity: isDragging ? 0.5 : 1
            }}
        >
            <div className="eq-item eq-item-dnd">
                <div className="eq-item-wrapp">
                    <div className="eq-left">
                        <div className="eq-photo" >
                            <img src={backgroundImage} alt=""/>
                        </div>
                    </div>
                    <div className="eq-right">
                        {auth.userCan("showFullServiceEquipments") && auth.customer.system_version === "hybrid-service" && equipment.service &&
                            <div
                                className={`badge badge-${equipment.service === "full" ? "success" : "warning"} badge-service mb-1`}
                                style={{position: "absolute", bottom: 0, right: 0, marginRight: 2}}
                            >
                                <span>{_upperFirst(equipment.service)} Service</span>
                            </div>
                        }
                        <div className="eq-name">
                            <span className="eq-name-label"><b>{get(equipment, "name")}</b></span>
                        </div>
                        <div className="eq-information">
                            <EquipmentInfo equipment={equipment} auth={auth}/>
                            {(auth.userCan("showAlarms") && hasReadingTypes > 0) &&
                                <div className="eq-readings">
                                    {(Object.keys(readingTypes)).map((typeKey, index) =>
                                        <ReadingTypeBlock
                                            key={index}
                                            equipmentId={equipment.id}
                                            chartTypeId={parseInt(typeKey)}
                                            title={readingTypes[typeKey].label || "None"}
                                            name={readingTypes[typeKey].short || "None"}
                                            level={readingTypes[typeKey].alertLevel}
                                        />
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

DnDBlock.propTypes = {
    index: PropTypes.number,
    groupIndex: PropTypes.any,
    equipment: PropTypes.object,
    moveCard: PropTypes.func,
    auth: PropTypes.object
};

const Block = ({equipment, auth}) => {
    let backgroundImage = "/assets/img/no-photo.png";

    if (equipment.images.length && get(equipment, "images.0.url")) {
        backgroundImage = get(equipment, "images.0.url") + "&resize=150_200";
    }

    const readingTypes = get(equipment, "readingTypes", {});

    const hasReadingTypes = Object.keys(readingTypes).length > 0;

    return (
        <div className="col-md-6 col-xl-4 col-xxl-3 mb-3 equipment-card-link equipment-block">
            <div className="eq-item">
                <div className="eq-item-wrapp">
                    <div className="eq-left">
                        <Link to={`/chart/${get(equipment, "id")}`}>
                            <div className="eq-photo" >
                                <img src={backgroundImage} alt=""/>
                            </div>
                        </Link>
                    </div>
                    <div className="eq-right">
                        {auth.userCan("showFullServiceEquipments") && auth.customer.system_version === "hybrid-service" && equipment.service &&
                            <div
                                className={`badge badge-${equipment.service === "full" ? "success" : "warning"} badge-service mb-1`}
                                style={{position: "absolute", bottom: 0, right: 0, marginRight: 2}}
                            >
                                <span>{_upperFirst(equipment.service)} Service</span>
                            </div>
                        }
                        <div className="eq-name">
                            <span className="eq-name-label"><b>{get(equipment, "name")}</b></span>
                        </div>
                        <div className="eq-information">
                            <EquipmentInfo equipment={equipment} auth={auth} />
                            {(auth.userCan("showAlarms") && hasReadingTypes > 0) &&
                            <div className="eq-readings">
                                {(Object.keys(readingTypes)).map((typeKey, index) =>
                                    <ReadingTypeBlock
                                        key={index}
                                        equipmentId={equipment.id}
                                        chartTypeId={parseInt(typeKey)}
                                        title={readingTypes[typeKey].label || "None"}
                                        name={readingTypes[typeKey].short || "None"}
                                        level={readingTypes[typeKey].alertLevel}
                                    />
                                )}
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Block.propTypes = {
    equipment: PropTypes.object,
    auth: PropTypes.object,
};

EquipmentBlock.propTypes = {
    index: PropTypes.number,
    groupIndex: PropTypes.any,
    equipment: PropTypes.object,
    moveCard: PropTypes.func,
    dnd: PropTypes.object,
    auth: PropTypes.object
};

const InstPointsRow = ({equipment, auth}) => {
    return (
        <span>
            {equipment.alertingInstallationPointsCount ? (
                <span>
                    {auth.userCan("editEquipment")
                        ? <React.Fragment>
                            <span className="color-danger">
                                {equipment.alertingInstallationPointsCount}
                            </span>
                            ({equipment.installationPointsCount})
                        </React.Fragment>
                        : <React.Fragment>{equipment.installationPointsCount}</React.Fragment>
                    }

                </span>
            ) : (
                <span>{equipment.installationPointsCount}</span>
            )}
        </span>
    );
};

InstPointsRow.propTypes = {
    equipment: PropTypes.object,
    auth: PropTypes.object
};

export default withGlobalStore(EquipmentBlock);