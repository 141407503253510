import React, {useState} from "react";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {has as _has} from "lodash";
import {useHistory} from "react-router";
import Helper from "../../../../../../helpers/helper";
import auth from "../../../../../../services/auth";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import useAxisLabelQuery from "../../../../../../hooks/api/axisLabel/useAxisLabelQuery";
import {useAxisSyncStoreActions} from "../../../store/sync/axisSyncStore";
import {shallow} from "zustand/shallow";
import {useSelectedAxis} from "../../hooks/helpers/useSelectedAxis";

const SeriesMenu = () => {
    const {equipmentItem: equipment} = useEquipmentByParams();
    const {axisLabelsChart: axisLabels} = useAxisLabelQuery(equipment.id);
    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList, shallow);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint, shallow);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType, shallow);
    const {setLoader} = useChartStateStoreContext((state) => state.actions, shallow);
    const viewOnly = auth.equipmentViewOnly(equipment);
    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const {chartModeList} = useChartStateStoreContext((state) => state.factory, shallow);
    const {toggleActiveSeriesForSingleSelect} = useAxisSyncStoreActions();
    const selectedAxis = useSelectedAxis();

    const toggleActiveSeries = (installationPointId, axisId) => {
        const isWaterfall = chartMode === chartModeList.WATERFALL || chartMode === chartModeList.STACKED_WATERFALL;
        toggleActiveSeriesForSingleSelect(installationPointId, axisId, isWaterfall);
        setLoader(true);
    };
    const history = useHistory();

    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    const showAxisModal = () => {
        history.push(
            Helper.setHashParams({
                modal: "manage-axis",
                selected: [selectedPoint],
                chartType,
            })
        );
    };

    const [dropdownSeriesOpen, setDropdownSeriesOpen] = useState(false);

    const toggleSeriesOpen = () => {
        setDropdownSeriesOpen((prev) => !prev);
    };

    let axisId = selectedAxis;
    if (Array.isArray(axisId)) {
        axisId = axisId[0];
    }

    if (Object.keys(seriesList.list).length < 2) {
        return "";
    }

    return (
        <Dropdown
            size="sm"
            disabled={!!isLoading}
            isOpen={dropdownSeriesOpen}
            toggle={toggleSeriesOpen}
        >
            <DropdownToggle
                caret
                className="btn-default"
            >
                {selectedPoint && _has(axisLabels, [axisId, selectedPoint]) && axisLabels[axisId][selectedPoint]
                    ? axisLabels[axisId][selectedPoint]
                    : seriesList.list[axisId].axisName + " - Axis"}
            </DropdownToggle>
            <DropdownMenu>
                {Object.keys(seriesList.list).map((key) => (
                    <DropdownItem
                        key={key}
                        onClick={() => toggleActiveSeries(selectedPoint, key)}
                        disabled={key === axisId}
                    >
                        {selectedPoint && _has(axisLabels, [key, selectedPoint]) && axisLabels[key][selectedPoint]
                            ? axisLabels[key][selectedPoint]
                            : seriesList.list[key].axisName + " - Axis"}
                    </DropdownItem>
                ))}
                {!viewOnly && (
                    <React.Fragment>
                        <DropdownItem divider />
                        <DropdownItem onClick={showAxisModal}>Manage...</DropdownItem>
                    </React.Fragment>
                )}
            </DropdownMenu>
        </Dropdown>
    );
};

export default SeriesMenu;
