import {useChartStateStoreContext} from "../store/hooks/useStoreContext";
import {useEffect} from "react";
import {useSelectedAxis} from "./helpers/useSelectedAxis";
export const useChartScaleInit = (chartRef, dependencies) => {
    const {setInitMinMax} = useChartStateStoreContext((state) => state.actions);
    const selectedAxis = useSelectedAxis();

    const onChartScaleInit = (chartRef) => {
        if (chartRef.current.chart.yAxis[0].max || chartRef.current.chart.yAxis[0].min) {
            setInitMinMax({
                valueInitMax: +chartRef.current.chart.yAxis[0].max.toFixed(2),
                valueInitMin: +chartRef.current.chart.yAxis[0].min,
            });
        }
    };

    useEffect(() => {
        if (!chartRef.current || !chartRef.current?.chart) {
            return;
        }

        onChartScaleInit(chartRef);

        return () => {
            if (!chartRef.current || !chartRef.current?.chart) {
                return;
            }

            onChartScaleInit(chartRef);
        };
    }, [chartRef.current, dependencies, selectedAxis]);
};
