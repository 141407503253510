import $ from "jquery";
import React, {useEffect} from "react";
import {ContextMenu, ContextMenuTrigger} from "react-contextmenu";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import Helper from "../../../../../../helpers/helper";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import auth from "../../../../../../services/auth";
import {useChartRef} from "../../hooks/useChartRef";
import ChartHelper from "../../../../../../helpers/chart";
import PropTypes from "prop-types";
import {useDrawChartEffect} from "../../hooks/useDrawChartEffect";
import {useKeyDownEffect} from "../../hooks/useKeyDownEffect";
import {cloneDeep as _cloneDeep, get as _get} from "lodash";
import {useCurrentPointsRef} from "../../hooks/useCurrentPointsRef";
import {useDeleteChartMarkSimpleModal} from "../../../../../../modals/chartMarkSimple/hooks/useDeleteChartMarkSimpleModal";
import {useAddChartMark} from "../../../../../../modals/chartMarkSimple/hooks/useAddChartMark";
import {useChartSelectedEquipmentStore} from "../../../../../../stores/zustand/ChartSelectedEquipmentStore";
import {useHistory} from "react-router";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useAfterAlertScrollEffect} from "../../hooks/useAfterAlertScrollEffect";
import {Labels} from "../Labels/Labels";
import {ChartMarksButtons} from "./СhartMarksButtons";
import {useIsTachometer} from "../../../hooks/useChartTypes";

const ChartBody = (props) => {
    const history = useHistory();

    const {equipmentItem} = useEquipmentByParams();
    const isNoData = useChartStateStoreContext((state) => state.settings.isNoData);
    const selectedEquipment = useChartSelectedEquipmentStore();
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const currentPoints = useCurrentPointsRef();
    const [showDeleteChartMarkSimpleModal] = useDeleteChartMarkSimpleModal();
    const {setChartMarkId, setChartMarkDeleteId, updateSelectedSeriesList, setSelectedPoint} = useChartStateStoreContext(
        (state) => state.actions,
        shallow
    );

    const isTachometer = useIsTachometer(chartType);

    const {ChartContextMenu} = useChartStateStoreContext((state) => state.factory, shallow);
    const chartRef = useChartRef();
    const chartWrapperSelector = `.chart-block[data-chart-type=${chartType}]`;

    const chartOptions = useDrawChartEffect(props);

    useKeyDownEffect();

    useAfterAlertScrollEffect();

    useEffect(() => {
        if (!selectedEquipment[equipmentItem.id]) {
            return;
        }
        if (selectedEquipment[equipmentItem.id].length > 1) {
            updateSelectedSeriesList(selectedEquipment[equipmentItem.id]);
        } else {
            setSelectedPoint(selectedEquipment[equipmentItem.id][0]);
        }
    }, [selectedEquipment[equipmentItem.id]]);

    const [showModal] = useAddChartMark();

    useEffect(() => {
        if (auth.userCan("editAlarms")) {
            $(document).on("click", `${chartWrapperSelector} .highcharts-alert-action`, function () {
                history.push(Helper.setHashParams({modalAlarm: $(this).data("type"), id: $(this).data("id")}));
            });
            $(document).on("click", `${chartWrapperSelector} .alert-badge-${chartType}`, function () {
                history.push(
                    Helper.setHashParams({
                        modal: "alert",
                        type: chartType,
                        axisId: $(this).data("axisId"),
                        installationPointId: $(this).data("installationPointId"),
                        id: $(this).data("alertId"),
                    })
                );
            });
        }
        $(document).on("mouseover", `${chartWrapperSelector} .chart-alert-wrapper`, function () {
            let elem = $(this).find(".chart-alert-marker");
            if (elem.length && chartRef.current) {
                let elemLabel = elem.find(".chart-label");
                elemLabel.removeClass("invisible");

                let width = elemLabel.outerWidth() + 10;
                let clickPos = elem.offset().left - $(chartRef.current.chart.renderTo).offset().left;
                let chartWidth = chartRef.current.chart.chartWidth;

                if (chartWidth - clickPos < width) {
                    elemLabel.removeClass("open-left");
                    elemLabel.addClass("open-right");
                } else {
                    elemLabel.removeClass("open-right");
                    elemLabel.addClass("open-left");
                }
            }
        });
        $(document).on("mouseout", `${chartWrapperSelector} .chart-alert-wrapper`, function (event) {
            if (event.offsetX < 0 || event.offsetY < 0 || event.offsetX > 10 || event.offsetX > 10) $(this).find(".chart-label").addClass("invisible");
        });
        $(document).on("click", `${chartWrapperSelector} .chart-mark-delete`, (event) => {
            setChartMarkDeleteId($(event.target).data("id"));
            showDeleteChartMarkSimpleModal();
        });
        $(document).on("click", `${chartWrapperSelector} .chart-mark-edit`, (event) => {
            setChartMarkId($(event.target).data("id"));
            showModal();
        });
        $(document).on("mouseover", `${chartWrapperSelector} .chart-mark-wrapper`, function () {
            let elem = $(this).find(".chart-mark-block");
            if (elem.length && chartRef.current) {
                let elemLabel = elem.find(".chart-mark-label");
                let width = elemLabel.outerWidth() + 10;
                let clickPos = elem.offset().left - $(chartRef.current.chart.renderTo).offset().left;

                $(".chart-mark-label").addClass("invisible");
                elemLabel.removeClass("invisible");

                let chartWidth = chartRef.current.chart.chartWidth;

                if (chartWidth - clickPos < width) {
                    elemLabel.removeClass("open-left");
                    elemLabel.addClass("open-right");
                } else {
                    elemLabel.removeClass("open-right");
                    elemLabel.addClass("open-left");
                }
            }
        });
        $(document).on("mouseout", `${chartWrapperSelector}  .chart-mark-wrapper`, function () {
            $(this).find(".chart-mark-label").addClass("invisible");
        });

        return () => {
            $(document).off("mouseover", `${chartWrapperSelector} .chart-mark-wrapper`);
            $(document).off("mouseout", `${chartWrapperSelector} .chart-mark-wrapper`);
            $(document).off("mouseover", `${chartWrapperSelector} .chart-alert-wrapper`);
            $(document).off("mouseout", `${chartWrapperSelector} .chart-alert-wrapper`);
            // $(document).off("click", `${chartWrapperSelector} .chart-mark-delete`);
            $(document).off("click", `${chartWrapperSelector} .chart-mark-edit`);
        };
    }, [chartRef]);

    useEffect(() => {
        ChartHelper.initMovableTooltip(chartRef, chartWrapperSelector);
        return () => ChartHelper.detachMovableTooltip(chartWrapperSelector);
    }, []);

    const setCurrentPoints = () => {
        currentPoints.current = _cloneDeep(_get(chartRef, "current.chart.hoverPoints", {}));
    };

    return (
        <React.Fragment>
            {!isTachometer && <ChartMarksButtons />}
            <Labels />
            <ContextMenuTrigger
                id={"simpleChart-" + chartType}
                holdToDisplay={-1}
                disable={isNoData}
            >
                <HighchartsReact
                    containerProps={{
                        className: "simple-chart",
                    }}
                    ref={chartRef}
                    updateArgs={[true, true, false]}
                    highcharts={Highcharts}
                    constructorType={"chart"}
                    options={{...chartOptions}}
                />
            </ContextMenuTrigger>
            <ContextMenu
                id={"simpleChart-" + chartType}
                onShow={setCurrentPoints}
            >
                <ChartContextMenu />
            </ContextMenu>
        </React.Fragment>
    );
};

ChartBody.propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,
    fftFrequencyType: PropTypes.number,
    handleSetIdleThresholdModal: PropTypes.func,
    selected: PropTypes.object,
};

export default ChartBody;
