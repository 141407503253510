export const wrapChartSymbols = (Highcharts) => {
    /* Highcharts plugin to handle text symbols */
    function symbolWrap(proceed, symbol, x, y, w, h) {
        if (symbol.indexOf("text:") === 0) {
            var text = symbol.split(":")[1],
                svgElem = this.text(text, x, y + h).css({
                    fontFamily: "Flaticon",
                    fontSize: "16px",
                    cursor: "default",
                });

            if (svgElem.renderer.isVML) {
                svgElem.fillSetter = function (value, key, element) {
                    element.style.color = Highcharts.Color(value).get("rgb");
                };
            }
            return svgElem;
        }
        return proceed.apply(this, [].slice.call(arguments, 1));
    }

    Highcharts.wrap(Highcharts.SVGRenderer.prototype, "symbol", symbolWrap);

    Highcharts.setOptions({symbols: ["circle", "square", "diamond", "triangle", "triangle-down"]});
};
