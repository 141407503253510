import React, {useEffect, useRef} from "react";
import {ContextMenu, ContextMenuTrigger} from "react-contextmenu";
import ChartWaterfallPlot from "./components/waterfall-fft/ChartWaterfallPlot";
import ChartsFFTWaterfallList from "../../../ftt-waterfall-list";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useChartRef} from "../../hooks/useChartRef";
import ChartHelper from "../../../../../../helpers/chart";
import {useLockPoints} from "../../hooks/useLockPoints";
import PropTypes from "prop-types";
import {useChartEventEffect} from "../../hooks/useChartEventEffect";
import {useKeyDownEffect} from "../../hooks/useKeyDownEffect";
import HighchartMemoComponent from "./components/HighchartMemoComponent";
import {includes as _includes} from "lodash";

import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import useAxisLabelQuery from "../../../../../../hooks/api/axisLabel/useAxisLabelQuery";
import {useAfterAlertScrollEffect} from "../../hooks/useAfterAlertScrollEffect";

const ChartBody = (props) => {
    const {showOutlierDialog} = props;

    const {selectedPoint, chartMode, chartType, fftCursor, isNoData} = useChartStateStoreContext((state) => state.settings, shallow);

    const {setChartCursor} = useChartStateStoreContext((state) => state.actions, shallow);
    const {chartModeList, ChartContextMenu} = useChartStateStoreContext((state) => state.factory, shallow);
    const fftCursorRef = useRef(fftCursor);
    const chartRef = useChartRef();
    const lockPoints = useLockPoints();
    const chartWrapperSelector = `.chart-block[data-chart-type=${chartType}][data-point-id=${selectedPoint}]`;
    const {equipmentItem} = useEquipmentByParams();
    const {axisLabelsChart: axisLabels} = useAxisLabelQuery(equipmentItem.id);
    const changeCursorHandler = (fftCursor) => {
        ChartHelper.clearAdditionalPoints(chartRef.current.chart);
        chartRef.current.chart.isPointBPaused = false;
        lockPoints.current = [];
        setChartCursor(fftCursor);
    };

    useKeyDownEffect(changeCursorHandler);

    useAfterAlertScrollEffect();

    useChartEventEffect(chartRef);

    useEffect(() => {
        fftCursorRef.current = fftCursor;
    }, [fftCursor]);

    useEffect(() => {
        ChartHelper.initMovableTooltip(chartRef, chartWrapperSelector);
        return () => ChartHelper.detachMovableTooltip(chartWrapperSelector);
    }, []);

    return (
        <>
            {!_includes([chartModeList.WATERFALL, chartModeList.STACKED_WATERFALL, chartModeList.STACKED_WATERFALL_ZONE], chartMode) && (
                <>
                    <ContextMenuTrigger
                        id={"twfChart-" + chartType + "-" + selectedPoint}
                        holdToDisplay={-1}
                        disable={chartMode === chartModeList.WATERFALL || isNoData}
                    >
                        <HighchartMemoComponent />
                    </ContextMenuTrigger>
                    <ContextMenu id={"twfChart-" + chartType + "-" + selectedPoint}>
                        <ChartContextMenu showOutlierDialog={showOutlierDialog} />
                    </ContextMenu>
                </>
            )}
            {chartMode === chartModeList.WATERFALL && <ChartWaterfallPlot />}
            {chartMode === chartModeList.STACKED_WATERFALL && <ChartsFFTWaterfallList axisLabels={axisLabels} />}
        </>
    );
};

ChartBody.propTypes = {
    showOutlierDialog: PropTypes.func,
    handleSetIdleThresholdModal: PropTypes.func,
    overlayContextMenuOptions: PropTypes.func,
};

export default ChartBody;
