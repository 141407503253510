import React from "react";
import { useChartStateStoreContext } from "../../store/hooks/useStoreContext";
import { shallow } from "zustand/shallow";

const ChartModeDropdownButton = () => {
    const { chartModeList } = useChartStateStoreContext((state) => state.factory, shallow);
    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const {changeChartMode} = useChartStateStoreContext((state) => state.actions);
    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    const setChartMode = (chartMode) => {
        changeChartMode(chartMode);
    };
    const chartModes = Object.entries(chartModeList);

    return (
        <div className={"btn-group-sm dropdown btn-default " + (isLoading ? "disabled" : "")}>
            {chartModes.map(([key, val]) => (
                <button
                    key={key}
                    type="button"
                    className="btn btn-default"
                    onClick={() => setChartMode(val)}
                    disabled={chartMode === val}
                >
                    {val}
                </button>
            ))}
        </div>
    );
};

export default ChartModeDropdownButton;
