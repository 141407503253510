/* eslint-disable no-unused-vars */
import React from "react";
import {MenuItem} from "react-contextmenu";
import PropTypes from "prop-types";
import {useChartStateStoreContext, useSelectChartType} from "../../store/hooks/useStoreContext";
import auth from "../../../../../../services/auth";
import {useCurrentPointRef} from "../../hooks/useCurrentPointRef";
import {isEmpty as _isEmpty} from "lodash";
import {useDeleteReadingsSimple} from "../../../../../../modals/deleteReadingsSimple/hooks/useDeleteReadingsSimple";
import {useAddChartMark} from "../../../../../../modals/chartMarkSimple/hooks/useAddChartMark";
import {useFFTTimestampsQuery} from "../../../../../../hooks/api/fftReadings/TimestampsQuery";
import {useChartSelectedEquipmentStore} from "../../../../../../stores/zustand/ChartSelectedEquipmentStore";
import {MTIME_TWO_MONTH, useChartSelectedRangeStore} from "../../../../../../stores/zustand/ChartSelectedRangeStore";
import {useOtherEquipmentModal} from "../../../../../../modals/otherEquipmentModal/hooks/useOtherEquipmentModal";
import {useIdleThresholdModal} from "../../../../../../modals/installationPoint/hooks/useIdleThreshold";
import {useLocateNearestFFT} from "../../hooks/helpers/useLocateNearestFFT";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useIsShowFFTAcquisitionsTime, useShowFFTAcquisitionsTimeActions} from "../../../store/sync/fftAcquisitionsTimeStore";
import { useIsImpactVue, useIsTachometer } from "../../../hooks/useChartTypes";
import {useIsShowChartMarks, useSharedStoreActions} from "../../../store/sync/sharedStore";
import Toast from "../../../../../shared/toast";

export const ContextMenuSimpleContent = () => {
    const {equipmentItem: equipment} = useEquipmentByParams();
    const [handleOtherEquipmentOverlayModal] = useOtherEquipmentModal();
    const [showIdleThresholdModal] = useIdleThresholdModal();
    const locateNearestFFT = useLocateNearestFFT();
    const {overlayPoints, issetRpm, issetAmbient, showAmbient, issetFftTimestamps, showRPM, bulkDeleteReadingsMode} = useChartStateStoreContext(
        (state) => state.settings
    );

    const chartType = useSelectChartType();
    const isImpactVue = useIsImpactVue(chartType);

    const {toggleShowRpm, toggleShowAmbient, toggleBulkReadingsMode, toggleIssetFftTimestamps, clearOverlayPoints} = useChartStateStoreContext(
        (state) => state.actions
    );

    const isShowChartMarks = useIsShowChartMarks();
    const {toggleChartMarks} = useSharedStoreActions();

    const currentPoint = useCurrentPointRef().current;

    const isShowFFTAcquisitionsTime = useIsShowFFTAcquisitionsTime();
    const {toggleShowFFTAcquisitionsTime} = useShowFFTAcquisitionsTimeActions();

    const selectedEquipment = useChartSelectedEquipmentStore();
    const rangeSelectorDate = useChartSelectedRangeStore();

    const [showModal] = useAddChartMark();

    const {data: fftTimestamps, isSuccess: fftTimestampsLoader} = useFFTTimestampsQuery({equipmentId: equipment.id});
    const [showDeleteReadingModalSimple] = useDeleteReadingsSimple();
    const isTachometer = useIsTachometer(chartType);

    const setToggleFftData = () => {
        if (rangeSelectorDate.range <= MTIME_TWO_MONTH) {
            if (!fftTimestampsLoader) {
                if (_isEmpty(fftTimestamps)) {
                    toggleIssetFftTimestamps(false);
                } else {
                    toggleIssetFftTimestamps(true);
                }
            } else {
                toggleIssetFftTimestamps(true);
            }
            toggleShowFFTAcquisitionsTime();
        } else {
            Toast.info("A range of 2 months or less is required to display FFT acquisition times.");
        }
    };

    return (
        <React.Fragment>
            {equipment.installationPoints.map((point) => {
                if (selectedEquipment[equipment.id].indexOf(point.id) !== -1) {
                    return (
                        <MenuItem
                            key={`menu-item-${point.id}`}
                            onClick={() => locateNearestFFT({timestamp: currentPoint.x / 1000, installationPointId: point.id, isImpactVue})}
                        >
                            Locate nearest FFT for {point.name}
                        </MenuItem>
                    );
                }
            })}
            {auth.userCan("editSelfBearings") && <MenuItem onClick={showIdleThresholdModal}>Set idle threshold</MenuItem>}
            {!isTachometer && (
                <>
                    <MenuItem onClick={() => showModal()}> Mark this time</MenuItem>
                    <MenuItem
                        disabled={!issetRpm}
                        onClick={toggleShowRpm}
                    >
                        {issetRpm ? (showRPM ? "Hide" : "Show") : "No"} RPM data
                    </MenuItem>

                    <MenuItem
                        disabled={!issetFftTimestamps}
                        onClick={setToggleFftData}
                    >
                        {issetFftTimestamps ? (isShowFFTAcquisitionsTime ? "Hide" : "Show") : "No"} FFT acquisition times
                    </MenuItem>
                </>
            )}
            <MenuItem onClick={toggleChartMarks}>{isShowChartMarks ? "Hide" : "Show"} chart marks</MenuItem>
            {+chartType === 1 && (
                <MenuItem
                    disabled={!issetAmbient}
                    onClick={toggleShowAmbient}
                >
                    {issetAmbient ? (showAmbient ? "Hide" : "Show") : "No"} ambient temperature
                </MenuItem>
            )}
            {!isTachometer && (
                <>
                    <MenuItem onClick={handleOtherEquipmentOverlayModal}>
                        {overlayPoints.length ? "Edit equipment overlay" : "Overlay another equipment sensor"}
                    </MenuItem>
                    {!!overlayPoints.length && <MenuItem onClick={() => clearOverlayPoints()}>Disable overlay</MenuItem>}
                </>
            )}
            {auth.userCan("deleteReading") && (
                <React.Fragment>
                    <hr />
                    <MenuItem onClick={showDeleteReadingModalSimple}>Delete reading</MenuItem>
                    {!isTachometer && (
                        <MenuItem onClick={toggleBulkReadingsMode}>
                            {bulkDeleteReadingsMode ? "Cancel Bulk-delete Readings" : "Bulk-delete readings"}
                        </MenuItem>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

ContextMenuSimpleContent.propTypes = {
    auth: PropTypes.object,
    equipment: PropTypes.object,
    selected: PropTypes.array,
    currentSpeed: PropTypes.number,
    showRpmPlotLines: PropTypes.bool,
    isCircular: PropTypes.bool,
    toggleShowRpmPlotLines: PropTypes.func,
    showOverlayModal: PropTypes.func,
    handleOtherEquipmentOverlayModal: PropTypes.func,
    disableOverlay: PropTypes.func,
    toggleModalHandler: PropTypes.func,
    showOutlierDialog: PropTypes.func,
    handleSetIdleThresholdModal: PropTypes.func,
    currentDate: PropTypes.string,
    fftTimestamps: PropTypes.object,
};
