import React from "react";
import PropTypes from "prop-types";

const ValidationError = ({ message }) => {
    return message ? <div className="invalid-feedback">{message}</div> : "";
};

ValidationError.propTypes = {
    message: PropTypes.string,
};

export default ValidationError;