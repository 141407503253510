import {
    each as _each,
    set as _set,
    get as _get,
    find as _find
} from "lodash";

const Sings = {
    metric: {
        acceleration: "m/s*s",
        crestValue: "",
        displacement: "mm",
        temperature: "°C",
        velocity: "mm/sec"
    },
    us: {
        acceleration: "g",
        crestValue: "",
        displacement: "mil",
        temperature: "°F",
        velocity: "in/sec"
    }
};

const Converter = {
    getSing: function (use_metric, value_name) {
        let key = use_metric ? "us" : "metric";
        return Sings[key][value_name];
    },
    convertUnitsSettingsToMetricList: function(typesList, settings = {}){
        let list = [];
        _each(typesList, (value) => {
            if(
                value.personalSettings
                && ((!value.projects || (Array.isArray(value.projects) && !value.projects.length))
                || (Array.isArray(value.projects) && value.projects.includes("wwRemastered")))
            ){
                let readingTypeId = _get(value, "readingTypes.0", false);
                let item = {
                    label: value.label,
                    alias: value.alias,
                    units: value.unitsList,
                    selected: readingTypeId ? _get(settings, readingTypeId, "us") : "us"
                };
                list.push(item);
            }
        });
        return list;
    },
    convertMetricListToUnitsSettings: function(userMetric, settings, typesList){
        let list = {};
        _each(typesList, (type) => {
            if(type.readingTypes){
                _each(type.readingTypes, (readingType) => {
                    _set(
                        list,
                        readingType,
                        _get(_find(settings, {alias: type.alias}), "selected", userMetric ? "us" : "metric")
                    );

                });
            }
        });
        return list;
    }
};

export default Converter;