import React from "react";
import {Button} from "reactstrap";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useChartRef} from "../../hooks/useChartRef";

const FullscreenButton = () => {
    const chartRef = useChartRef();

    const fullScreenMode = useChartStateStoreContext((state) => state.settings.fullScreenMode);
    const {toggleFullScreen} = useChartStateStoreContext((state) => state.actions, shallow);

    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    const onClick = () => {
        toggleFullScreen();
        // Fire chart reflow after modal animation end
        setTimeout(() => {
            if (!chartRef.current) {
                return;
            }

            chartRef.current.chart.reflow();
        }, 1000);
    };

    return (
        <Button
            className="btn-fa-icon btn-sm pull-right btn-default"
            onClick={onClick}
            disabled={!!isLoading}
        >
            <i className={fullScreenMode ? "fa fa-times" : "fa fa-expand-arrows-alt"} />
        </Button>
    );
};

export default FullscreenButton;
