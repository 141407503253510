import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get,
} from "lodash";
import BearingForm from "./bearing/form";

class BearingStep extends Component
{
    render() {
        const {profile, addBearing, removeBearing, defaultBearings, fetchBearings, onChange, formErrors, validateStep, inProgress} = this.props;
        const bearings = _get(profile, "bearings", []);
        const installationPoints = _get(profile, "installationPoints", []);

        return (
            <React.Fragment>
                <div className={"bearings-step"}>
                    <div className={"step-header"}>
                        <div className={"step-title text-uppercase"}>Add Bearing</div>
                    </div>

                    <div className={"bearings-block"}>
                        {bearings.map((bearing, index) =>
                            <BearingForm
                                key={index}
                                index={index}
                                profile={profile}
                                installationPoints={installationPoints}
                                removeBearing={removeBearing}
                                defaultBearings={defaultBearings}
                                fetchBearings={fetchBearings}
                                onChange={onChange}
                                formErrors={formErrors}
                                validateStep={validateStep}
                                inProgress={inProgress}
                            />
                        )}

                        <div
                            className={"add-new-block bearing"}
                            onClick={addBearing}
                        >
                            <div className={"d-flex flex-column align-items-center"}>
                                <img src="/assets/img/profile/add-new-icon.svg" alt=""/>
                                <span className={"mt-2 dark-title"}>Add New Bearing</span>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

BearingStep.propTypes = {
    profile: PropTypes.object,
    addBearing: PropTypes.func,
    removeBearing: PropTypes.func,
    defaultBearings: PropTypes.array,
    fetchBearings: PropTypes.func,
    onChange: PropTypes.func,
    formErrors: PropTypes.object,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool
};

export default BearingStep;