import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get,
    find as _find,
    filter as _filter
} from "lodash";
import PhotosWrapper from "../../../shared/photosWrapper/photosWrapper";
import SelectWrapper from "../../../helpers/select-wrapper";

class SelectNameplateForm extends Component
{
    render() {
        const {templates, nameplates, selectedTemplateId, selectedNameplateId, onChangeNameplateId, onChangeTemplateId, onCreateNew} = this.props;

        const selectedNameplate = _find(nameplates, {id: selectedNameplateId});
        const photos = _get(selectedNameplate, "photos", []);
        const parameters = _get(selectedNameplate, "parameters", []);

        const filteredNameplates = _filter(nameplates, (nameplate) => +_get(nameplate, "template_id") === +selectedTemplateId);

        return (
            <React.Fragment>
                <div className={"nameplate-component-block"}>
                    <div className={"row"}>
                        <div className={"col-md-6 mb-2"}>
                            <strong>Template</strong>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <div className={"form-group mb-0"}>
                                <SelectWrapper
                                    style={{
                                        width:"100%",
                                        display:"block",
                                    }}
                                    name={"template_id"}
                                    value={selectedTemplateId}
                                    onChange={onChangeTemplateId}
                                >
                                    {/*{!selectedTemplateId && <option value={""}>Select...</option>}*/}
                                    {templates.map((template, index) =>
                                        <option key={index} value={template.id}>{template.name}</option>
                                    )}
                                </SelectWrapper>
                            </div>
                        </div>
                    </div>
                    {!!selectedTemplateId &&
                        <React.Fragment>
                            <div className={"row mt-2"}>
                                <div className={"col-md-6 mb-2"}>
                                    <strong>Nameplate</strong>
                                </div>
                            </div>
                            <div className={"row"}>
                                {filteredNameplates.length
                                    ?
                                    <div className={"col-md-6"}>
                                        <div className={"form-group mb-0"}>
                                            <SelectWrapper
                                                style={{
                                                    width:"100%",
                                                    display:"block",
                                                }}
                                                name={"template_id"}
                                                value={selectedNameplateId}
                                                onChange={onChangeNameplateId}
                                            >
                                                {/*{!selectedNameplateId && <option value={""}>Select...</option>}*/}
                                                {filteredNameplates.map((nameplate, index) =>
                                                    <option key={index} value={nameplate.id}>{nameplate.name}</option>
                                                )}
                                            </SelectWrapper>
                                        </div>
                                    </div>
                                    :
                                    <div className={"col-md-6"}>
                                        <div className={"form-group mb-0"}>
                                            <input
                                                disabled={true}
                                                className={"form-control"}
                                                title={"No nameplates exist for this template. To add one, click Create New."}
                                                value={"No nameplates exist for this template. To add one, click Create New."}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className={"col-md-6 mb-2"}>
                                    <div className="add-new-link mt-2 ml-0" onClick={onCreateNew}>
                                        <i className="fa fa-plus-circle"/>
                                        <span>Create New</span>
                                    </div>
                                </div>
                            </div>
                            {!!photos.length &&
                                <div className={"mt-3 w-50"}>
                                    <PhotosWrapper
                                        photos={photos}
                                        withPhotoModal={true}
                                        withDropzone={false}
                                        defaultPhoto={"picture.svg"}
                                        withoutDeleteModalBtn={true}
                                    />
                                </div>
                            }
                            {!!parameters.length &&
                                <div className={"nameplate-template-table mt-3"}>
                                    {parameters.map((parameter, index) =>
                                        <div key={index} className={"template-row"}>
                                            <div className={"template-col"}>
                                                <strong>{_get(parameter, "name")}</strong>
                                            </div>
                                            <div className={"template-col"}>
                                                {_get(parameter, "value")}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                        </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}

SelectNameplateForm.propTypes = {
    templates: PropTypes.array,
    nameplates: PropTypes.array,
    selectedNameplateId: PropTypes.number,
    selectedTemplateId: PropTypes.number,
    onChangeTemplateId: PropTypes.func,
    onChangeNameplateId: PropTypes.func,
    onCreateNew: PropTypes.func
};

export default SelectNameplateForm;