import React, {Component} from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";


class NoteFile extends Component
{
    constructor(props) {
        super(props);

    }

    onDropFile = (files) => {
        const {onAttachFile} = this.props;

        let fileObject = {
            file: "",
            fileName: ""
        };

        if (files[0]) {
            fileObject.fileName = files[0].name;
            fileObject.file = files[0];

            onAttachFile(fileObject);
        }
    }

    render() {
        const {fileName, onRemoveFile, withoutEdit, fileUrl, fileType, errorMsg} = this.props;

        return (
            <React.Fragment>
                <Dropzone
                    onDrop={this.onDropFile}
                    noDrag={true}
                    multiple={false}
                >
                    {({getRootProps, getInputProps}) => (
                        <React.Fragment>
                            <div className="d-flex note-file">
                                {!withoutEdit
                                    ?
                                    <div className="d-flex note-file-button"  {...getRootProps()} title={"Attach File"}>
                                        <span className="dropzone-icon"><i className="fa fa-cloud-upload-alt"/></span>
                                        <input {...getInputProps()} />
                                    </div>
                                    :
                                    <div className="d-flex note-file-button download">
                                        <span className="dropzone-icon"><i className="fa fa-cloud-download-alt"/></span>
                                    </div>
                                }
                                {fileName &&
                                <React.Fragment>
                                    {fileUrl
                                        ?
                                        <a
                                            href={fileUrl}
                                            className="link link-primary"
                                            style={{marginLeft: 0}}
                                            title={"Download File"}
                                            target={"_blank"}
                                            rel={"noreferrer"}
                                        >
                                            {fileName+(fileType && withoutEdit ? "."+fileType : "")}
                                        </a>
                                        :

                                        <div>{fileName+(fileType && withoutEdit ? "."+fileType : "")}</div>
                                    }
                                    {!withoutEdit &&
                                    <div className={"note-file-remove"} onClick={onRemoveFile}>
                                        <i className={"fa fa-times"} title={"Remove File"}/>
                                    </div>
                                    }
                                </React.Fragment>
                                }
                            </div>
                            {errorMsg &&
                                <div className={"note-file-error"}>{errorMsg}</div>
                            }
                        </React.Fragment>

                    )}
                </Dropzone>
            </React.Fragment>
        );
    }
}

NoteFile.propTypes = {
    fileName: PropTypes.string,
    fileType: PropTypes.string,
    fileUrl: PropTypes.string,
    withoutEdit: PropTypes.bool,
    onAttachFile: PropTypes.func,
    onRemoveFile: PropTypes.func,
    errorMsg: PropTypes.any
};

export default NoteFile;