import React, {Component, Fragment} from "react";
import {Modal} from "../../shared";
import PropTypes from "prop-types";
import {get as _get} from "lodash";


import "../../assets/scss/components/sliders/modal-slider.scss";
import Dropzone from "react-dropzone";

class PhotoModal extends Component {

    constructor(props) {
        super(props);
    }

    handleDeleteImage = () => {
        const {onDelete, photos, currentPhoto, onClose} = this.props;

        if (photos[currentPhoto])
            onDelete(photos[currentPhoto]);

        if (!photos.length) {
            onClose();
        }
    }

    render() {
        const {showModal, onClose, photos, currentPhoto, onNext, onPrev, withoutDeleteModalBtn, withList, attachPhotos, setCurrent} = this.props;

        const customBtns = (photos[currentPhoto] || {}).added_from !== "installApp" && !withoutDeleteModalBtn
            ?
            [{
                color: "danger",
                title: "Delete",
                callback: this.handleDeleteImage
            }]
            :
            [];

        return (
            <Modal
                showModal={showModal}
                onClose={onClose}
                size="xl"
                title={"Photos"}
                customBtns={customBtns}
                withoutFooter={true}
            >
                <Fragment>
                    <div className="sliderWrapper modalSlider">
                        <div className="modalSliderContainer">
                            {photos.length > 1 &&
                                <Fragment>
                                    <span
                                        className="sliderPrevBtn"
                                        onClick={onPrev}
                                    >
                                        <i className="fa fa-angle-left"/>
                                    </span>
                                    <span
                                        className="sliderNextBtn"
                                        onClick={onNext}
                                    >
                                        <i className="fa fa-angle-right"/>
                                    </span>
                                </Fragment>
                            }
                            <img
                                className="previews-photos"
                                src={_get(photos, [currentPhoto, "url"]) ?? _get(photos, [currentPhoto])}
                            />
                        </div>
                    </div>
                    {withList &&
                        <div className="assign-photo-list-wrapper">
                            <Dropzone
                                onDrop={attachPhotos}
                                accept="image/*"
                            >
                                {({getRootProps, getInputProps}) => (
                                    <span {...getRootProps()} className="assign-photo-list-item add-image-block">
                                        <input {...getInputProps()}/>
                                        <button
                                            onClick={() => {}}
                                            className="btn btn-secondary btn-circle btn-icon btn-add-image"
                                            style={{width: "2.5rem"}}
                                        >
                                            <i className="fa fa-plus"/>
                                        </button>
                                    </span>
                                )}
                            </Dropzone>
                            {photos.map((image, index) =>
                                <div
                                    onClick={() => setCurrent(index)}
                                    key={index}
                                    className={`assign-photo-list-item ${index === currentPhoto && "active-image"}`}
                                    style={{background: "#fff"}}
                                >
                                    <img src={image.url}/>
                                </div>
                            )}
                        </div>
                    }
                </Fragment>

            </Modal>
        );
    }
}

PhotoModal.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onPrev: PropTypes.func,
    onNext: PropTypes.func,
    photos: PropTypes.array,
    currentPhoto: PropTypes.number,
    withoutDeleteModalBtn: PropTypes.bool,
    withList: PropTypes.bool,
    attachPhotos: PropTypes.func,
    setCurrent: PropTypes.func
};

export default PhotoModal;