import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import ReadingIntervalInput from "./ReadingIntervalInput";

const ReadingIntervalForm = ({settings, onChange, showNotice, formErrors, validation, nodeSerials, isAdapter, isImpactVueEnabled}) => {

    return (
        <>
            {isAdapter
                ?
                    <ReadingIntervalInput
                        key={"wua"}
                        type={"wua"}
                        settings={settings}
                        onChange={onChange}
                        formErrors={formErrors}
                        validation={validation}
                        notice={showNotice}
                    />
                :
                <>
                    {["temperature", "rms", "fft", "impactvue_rms", "impactvue_fft"].map((type) => {
                        if (!isImpactVueEnabled && type === "impactvue_rms") {
                            return;
                        }
                        if (!isImpactVueEnabled && type === "impactvue_fft") {
                            return;
                        }

                        return (
                            <ReadingIntervalInput
                                key={type}
                                type={type}
                                settings={settings}
                                disabled={type === "temperature"}
                                onChange={onChange}
                                notice={type === "rms" ? showNotice : false}
                                formErrors={formErrors}
                                validation={validation}
                            />
                        );
                    })}

                    {!!nodeSerials.length &&
                        <div className="text-primary mb-2">
                            RMS and FFT intervals will be applied for all tethered sensors on the following node(s):
                            {nodeSerials.map((nodeSerial, index) =>
                                <React.Fragment key={index}>
                                    <Link
                                        to={{pathname: `/network/nodes/${nodeSerial}`}}
                                        className={"ml-2"}
                                    >
                                        {nodeSerial}
                                    </Link>
                                    {index + 1 !== nodeSerials.length &&
                                        <span>,</span>
                                    }
                                </React.Fragment>
                            )}
                        </div>
                    }
                </>
            }
        </>
    );
};

ReadingIntervalForm.propTypes = {
    settings: PropTypes.object,
    onChange: PropTypes.func,
    showNotice: PropTypes.func,
    formErrors: PropTypes.object,
    validation: PropTypes.object,
    nodeSerials: PropTypes.array,
    isAdapter: PropTypes.bool,
    isImpactVueEnabled: PropTypes.bool,
};

export default ReadingIntervalForm;