import React from "react";
import PropTypes from "prop-types";
import {Alert, UncontrolledTooltip} from "reactstrap";
import "./lossPacketsAlert.scss";
import {ALLOWABLE_PACKET_LOSS_PERCENTAGE} from "../../constants/constants";

export const LossPacketsAlert = ({lossPacketsPercent, isCollapsed  = false, id = "", className = ""}) => {
    if (lossPacketsPercent === null || +lossPacketsPercent < ALLOWABLE_PACKET_LOSS_PERCENTAGE) {
        return <></>;
    }

    if (isCollapsed) {
        return  <>
            <span id={"loss-packets-" + id} className={"text-danger icon-parent cursor-pointer " + className}>
                <i className={"fa fa-info-circle"}></i>
            </span>
            <UncontrolledTooltip placement="right" target={"loss-packets-" + id}>
                Due to poor connectivity to the device, the FFT may not be returned.
            </UncontrolledTooltip>
        </>;
    }

    return <Alert color="danger" className={className}>
        Due to poor connectivity to the device, the FFT may not be returned.
    </Alert>;
};

LossPacketsAlert.propTypes = {
    lossPacketsPercent: PropTypes.number,
    isCollapsed: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string
};