import React, {Component} from "react";
import cookies from "react-cookies";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";


const propTypes = {
    history: PropTypes.object
};

class ContactEmailAlert extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: true
        };

        this.onClose = this.onClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onClose() {
        var today = new Date();
        today.setDate(today.getDate() + 7);
        cookies.save("contactEmail", 1, {"expires": today});
        this.setState({showModal: false});
    }

    onSubmit() {
        this.setState({showModal: false}, () => this.props.history.push("/profile"));
    }

    render() {
        return (
            <SweetAlert
                show={this.state.showModal}
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnBsStyle="default"
                btnSize="xs"
                title="Contact Email Address"
                onConfirm={this.onSubmit}
                onCancel={this.onClose}
            >
                The Contact Email Address field is blank. Would you like to enter one now?
            </SweetAlert>
        );
    }

}

ContactEmailAlert.propTypes = propTypes;

export default withRouter(ContactEmailAlert);