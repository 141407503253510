import React, {Component} from "react";
import Creatable from "react-select/creatable/dist/react-select.esm";
import {ValidationError} from "../../../shared";
import selectStyles, {darkModeSelectStyles} from "./selectStyles";
import {
    find as _find,
} from "lodash";
import PropTypes from "prop-types";
import InfoTooltip from "../../../shared/infoTooltip/infoTooltip";

const isDarkTheme = localStorage.getItem("lightMode");

class InstallationPointCustomTypeFormContent extends Component
{
    onChangeSelect = (target, event) => {
        if (event.action === "create-option") {
            this.createInstPointCustomType(target.value, event.name);
        }
        if (event.action === "select-option") {
            this.updateInstPointCustomType(event.name, target.value);
        }
        if (event.action === "clear") {
            this.updateInstPointCustomType(event.name, "");
        }
    }

    createInstPointCustomType = (newType, name) => {
        const {onCreate} = this.props;

        onCreate(newType)
            .then((newId) => {
                this.updateInstPointCustomType(name, newId);
            });
    }

    updateInstPointCustomType = (name, value) => {
        const {onChange} = this.props;

        onChange({
            target: {
                name: name,
                value: value
            }
        });
    }

    render() {
        const {name, value, types, errorMsg} = this.props;

        return (
            <>
                <label>
                    Installation Point Type&nbsp;
                    <InfoTooltip iconClass="blue-tooltip">
                        Enter the installation point type for this asset (i.e. motor, gearbox, drive shaft, etc.).
                    </InfoTooltip>
                </label>
                <Creatable
                    className={"form-control-select" + (errorMsg ? " is-invalid" : "")}
                    name={name}
                    value={_find(types, {"value": value})}
                    styles={ isDarkTheme === "true" ? darkModeSelectStyles : selectStyles }
                    options={types}
                    isClearable={true}
                    placehoder={"Select Installation Point Type"}
                    onChange={this.onChangeSelect}
                    closeMenuOnScroll={(e) => e && e.target && e.target.classList && e.target.classList.contains("custom-scroll")}
                    menuPortalTarget={document.body}
                    menuPlacement={"auto"}
                    formatCreateLabel={(label) => "Create \"" + label + "\" (press enter to create new type)"}
                />
                <ValidationError message={errorMsg}/>
            </>
        );
    }
}

InstallationPointCustomTypeFormContent.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    types: PropTypes.array,
    errorMsg: PropTypes.string,
    onChange: PropTypes.func,
    onCreate: PropTypes.func
};

export default InstallationPointCustomTypeFormContent;