import Api from "./api";

const ApiReadingTypes = {
    list: () => Api.get("reading-types"),
    forAlerts: () => Api.get("reading-types/for-alerts"),
    /* Custom Reading Type CRUD */
    customList: () => Api.get("reading-types-custom"),
    create: (data) => Api.post("reading-types", data),
    update: (id, data) => Api.patch(`reading-types/${id}`, data),
    delete: id => Api.delete(`reading-types/${id}`),
};

export default ApiReadingTypes;
