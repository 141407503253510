import React from "react";
import SearchInput from "../../../pages/shared/search-input";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

const GlobalSearch = ({ globalSearchString ="", handleChangeGlobalSearch = () => {} }) => {
    const history = useHistory();

    return (
        <div className="global-search-wrapper">
            <div className="filter-item global-search">
                <SearchInput
                    history={history}
                    disabled={false}
                    placeholder="Global Search"
                    query={globalSearchString}
                    onChange={handleChangeGlobalSearch}
                    additionalClasses="form-control-sm"
                    handleSearch="global"
                />
            </div>
        </div>
    );
};

GlobalSearch.propTypes = {
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};
export default GlobalSearch;
