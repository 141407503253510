import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./breadcrumbs.scss";
import { useIsDarkTheme } from "../../hooks/helpers/theme";

const HomeIcon = () => (
    <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.4 13.8V9.00001H9.6V13.8H13.6V7.40001H16L8 0.200012L0 7.40001H2.4V13.8H6.4Z"
            fill="#B1B1B1"
        />
    </svg>
);

const ArrowIcon = () => (
    <svg
        width="8"
        height="10"
        viewBox="0 0 8 10"
        className="breadcrumbs__arrow"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.08341 0L0.916748 1.16667L4.75008 5L0.916748 8.83333L2.08341 10L7.08341 5L2.08341 0Z"
            fill="#B1B1B1"
        />
    </svg>
);

const Breadcrumbs = (props) => {
    const { breadcrumbs = [] } = props;

    const isDarkTheme = useIsDarkTheme();

    const breadcrumbsMap = useMemo(() => {
        const breadcrumbsMap = [];

        (breadcrumbs || []).forEach((breadcrumb, index) => {
            breadcrumbsMap.push(
                breadcrumb.link ? (
                    <div className="breadcrumbs__item" key={index}>
                        <ArrowIcon />
                        <Link to={breadcrumb.link}>{breadcrumb.name}</Link>
                    </div>
                ) : (
                    <div className="breadcrumbs__item" key={index}>
                        <ArrowIcon />
                        {breadcrumb.name}
                    </div>
                )
            );
        });
        return breadcrumbsMap;
    }, [breadcrumbs]);

    if ((breadcrumbs || []).length) {
        return (
            <>
                <div className={"breadcrumbs"}>
                    <div className="breadcrumbs__item">
                        <Link to="/">
                            <HomeIcon />
                        </Link>
                    </div>
                    {breadcrumbsMap}
                </div>
                <hr className={ `breadcrumbs__delimiter ${isDarkTheme ==="true" ? "breadcrumbs__delimiter_dark" :""}` } />
            </>
        );
    }
    return (
        <div className={"breadcrumbs"}>
            <div className="breadcrumbs__item">
                <Link to="/">
                    <HomeIcon />
                </Link>
            </div>
        </div>
    );
};

Breadcrumbs.propTypes = {
    breadcrumbs: PropTypes.array,
};

export default Breadcrumbs;
