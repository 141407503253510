import { useModal } from "react-modal-hook";
import Helper from "../../../helpers/helper";
import React from "react";
import { useHistory, useParams } from "react-router";
import ActiveModal from "../components/active";
import { useModalHistory } from "../../../hooks/helpers/useModalHistory";
import { MODALS } from "../../../constants/modals";
import useAxisLabelQuery from "../../../hooks/api/axisLabel/useAxisLabelQuery";

export const useActiveAlertsModal = ({
    user,
    selectedAlarms,
    setSelectedAlarms,
    excluded,
    equipment
}) => {
    const history = useHistory();
    const params = useParams();
    const { axisLabelsChart: axisLabels } = useAxisLabelQuery(+params.equipmentId);
    const [showModal, hideModal] = useModal(() => (
        <ActiveModal
            equipmentId={+params.equipmentId}
            equipment={equipment}
            user={user}
            selectedAlarms={selectedAlarms}
            setSelectedAlarms={setSelectedAlarms}
            excluded={excluded}
            showModal={true}
            history={history}
            onOpenAcknowledgeAlarmModal={(id) =>
                history.push(Helper.setHashParams({ modalAlarm: "ack", id: id }))
            }
            onOpenIgnoreAlarmModal={(id) =>
                history.push(Helper.setHashParams({ modalAlarm: "ignore", id: id }))
            }
            onClose={onHide}
            axisLabels={axisLabels}
        />
    ), [selectedAlarms]);
    const { pushHistory, deleteHistory } = useModalHistory(MODALS.ACTIVE_ALERTS, showModal);

    const onShow = () => {
        pushHistory();
        showModal();
    };

    const onHide = () => {
        deleteHistory();
        hideModal();
    };

    return [onShow, onHide];
};
