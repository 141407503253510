import {find as _find} from "lodash";
import $ from "jquery";
import Helper from "../../../../../helpers/helper";
import {useDraggableAlertBuilder} from "../../../../../hooks/factory/useDraggableAlertBuilder";
import {useSelectedTab} from "../../../../../stores/zustand/AlertConditionStore";
import {ALERT_TABS_NAME} from "../../../constants/tabs";

export const useAlertPlotLineBuilder = ({alert, otherAlerts, chartRef, updateAlertValue}) => {
    const currentTab = useSelectedTab();

    const draggablePlotLine = (ev) => {
        const axis = chartRef.current.chart.yAxis[0];
        const plotLine = _find(axis.plotLinesAndBands, {id: alert.alert_level_id});
        let yOffset, clickY, value;

        const start = (ev) => {
            $(document).bind({
                "mousemove.line": step,
                "mouseup.line": stop,
                "click.line": stop,
            });
            clickY = ev.pageY - plotLine.svgElem.translateY;
        };

        const step = (ev) => {
            if (value === axis.max || value === axis.min) return stop();
            yOffset = ev.pageY - clickY;
            value = Math.max(axis.min, Math.min(axis.max, axis.toValue(yOffset) - axis.toValue(0) + plotLine.options.value));
            yOffset = axis.toPixels(value + axis.toValue(0) - plotLine.options.value);
            plotLine.svgElem.translate(0, yOffset);
            updateAlertValue(Helper.numberFormat(value, 4));
            // updateValue(Helper.numberFormat(value, 4));
        };

        const stop = () => {
            $(document).unbind(".line");
            updateAlertValue(Helper.numberFormat(value, 4), true);
        };

        if (typeof plotLine.svgElem.translateY === "undefined") {
            plotLine.svgElem.translate(0, 0);
        }

        start(ev);
    };

    const plotLines = useDraggableAlertBuilder({alert, otherAlerts, onMouseDown: draggablePlotLine, enabled: currentTab !== ALERT_TABS_NAME.DELTA_T});
    return plotLines;
};
