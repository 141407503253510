import React from "react";

import "../../assets/scss/components/error-page/error.scss";
import auth from "../../services/auth";

export default function NotAccess() {
    return (
        <div className="app-item">
            <div className="app-content">
                <div className="main">
                    <div className="error-page">
                        <div className="error-page-content mr-3">
                            <div className="error-page-title not-permission-title">401 Error</div>
                            <div className="error-page-desc">You do not have permission to access this page.</div>
                            <div className="error-page-btns">
                                <SwitchCustomerBtn/>
                                <SignOutBtn/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const SwitchCustomerBtn = () => {
    return (auth.allowedCustomers.length > 1 && (
        <div className={"switch-customer-btn"}>
            <a onClick={() => auth.customerLogout()} className="btn btn-primary btn-sm btn-elevate mr-4">Switch Customer</a>
        </div>));
};

const SignOutBtn = () => {
    return (<div className={"sign-out-btn"}>
        <a onClick={() => auth.logout()} className="btn btn-primary btn-sm btn-elevate mr-4">Sign Out</a>
    </div>);
};
