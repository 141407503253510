import React, {Component} from "react";
import PropTypes from "prop-types";
import InstallationPointFinder from "./installation-point-finder";
import SensorCheckList from "./sensor-checklist";
import {
    get as _get,
    find as _find,
    each as _each
} from "lodash";

const propTypes = {
    value: PropTypes.array,
    groupId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    filterOutTachometers: PropTypes.bool,
    onChange: PropTypes.func,
    equipment: PropTypes.object,
};

class EquipmentSensorChecklists extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newEquipment: null,
            otherEquipment: [],
            addNewEquipment: false,
        };
    }

    onAddNewEquipment = () => {
        this.setState({addNewEquipment: true});
    }

    setNewEquipment = (newEquipment) => {
        this.setState({newEquipment});
    }

    saveNewEquipment = () => {
        const {newEquipment} = this.state;
        let otherEquipment = [...this.state.otherEquipment];

        otherEquipment.push(newEquipment);

        this.setState({newEquipment: null, addNewEquipment: false, otherEquipment});
    }

    cancelNewEquipment = () => {
        this.setState({newEquipment: null, addNewEquipment: false});
    }

    onChange = (ev) => {
        const {otherEquipment} = this.state;
        const {onChange, equipment} = this.props;
        const pointId = parseInt(ev.target.value);

        const currentPoints = _get(equipment, "installationPoints", []);
        const currentPoint = _find(currentPoints, {id: pointId}) || false;
        if (currentPoint) {
            onChange(currentPoint.id, currentPoint.name);
        } else {
            _each(otherEquipment, (equipment) => {
                const points = _get(equipment, "installationPoints", []);
                const point = _find(points, {id: pointId}) || false;
                if (point) {
                    onChange(point.id, equipment.name + "/" + point.name);
                    return false;
                }
            });
        }
    }

    render() {
        const {addNewEquipment, otherEquipment, newEquipment} = this.state;
        const {groupId, value, equipment} = this.props;

        return (
            <>
                <div>Current Equipment:</div>
                <SensorCheckList
                    className="form-control"
                    name="installationPointId"
                    installationPoints={_get(equipment, "installationPoints", [])}
                    onChange={this.onChange}
                    groupId={groupId}
                    value={value}
                    filterOutTachometers={true}
                />

                {otherEquipment.map((equipment) =>
                    <>
                        <div>{_get(equipment, "name")}:</div>
                        <SensorCheckList
                            className="form-control"
                            name="installationPointId"
                            installationPoints={_get(equipment, "installationPoints", [])}
                            onChange={this.onChange}
                            groupId={groupId}
                            value={value}
                            filterOutTachometers={true}
                        />
                    </>
                )}

                {!groupId &&
                    <>
                        {addNewEquipment
                            ?
                            <div>
                                <InstallationPointFinder
                                    setEquipment={this.setNewEquipment}
                                    filterOutEquipmentIds={[...(otherEquipment.map(equipment => +equipment.id) || []), ...[equipment.id]]}
                                />
                                <div className="d-flex justify-content-end buttons-row">
                                    <button className="btn-sm btn btn-secondary" onClick={this.cancelNewEquipment}>
                                        Cancel
                                    </button>
                                    <button
                                        className={"btn-sm btn btn-primary ml-2" + (newEquipment ? "" : " disabled")}
                                        onClick={newEquipment ? this.saveNewEquipment : () => {}}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                            :
                            <div
                                className="col-4 add-new-link-manage mt-2 mb-2"
                                onClick={this.onAddNewEquipment}
                            >
                                <i className="fa fa-plus-circle active"/>
                                <span>Add Equipment</span>
                            </div>
                        }
                    </>
                }
            </>
        );
    }
}

EquipmentSensorChecklists.propTypes = propTypes;

export default EquipmentSensorChecklists;