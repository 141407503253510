import React, {Component} from "react";
import PropTypes from "prop-types";
import FormValidator from "../../../helpers/form-validator";
import {ValidationError} from "../../../shared";
import {Loader} from "../../../shared";
import {
    get as _get,
    set as _set,
    concat as _concat
} from "lodash";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import SearchInput from "../../shared/search-input";
import {withGlobalStore} from "../../../stores/GlobalStore";
import ApiGateways from "../../../api/gateway";
import ApiRouters from "../../../api/router";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import SelectWrapper from "../../../helpers/select-wrapper";
import { HeaderSimple } from "../../../shared/header";
import Toast from "../../shared/toast";
import Helper from "../../../helpers/helper";
import ImageToolbar from "../../../widgets/ImageToolbar";

const rules = [
    {
        field: "serial",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "gateway_id",
        method: "isEmpty",
        validWhen: false,
        message: "Gateway cannot be empty."
    },
];

class RouterAdd extends Component
{
    constructor(props) {
        super(props);
        this.validator = new FormValidator(rules);
        this.state = {
            title: "Add Repeater",
            data: {
                serial: "",
                gateway_id: "",
                location_info: "",
                location_id: "",
                photos: [],
                flipTurnPhotos: []
            },
            breadcrumbs: [
                {name: "Manage Repeaters", link: "/network/routers"},
                {name: "Add Repeater"}
            ],
            gateways: [],
            loader: false,
            photoLoader: true,
            validation: this.validator.valid(),
            globalSearchString: "",
            defaultPhoto: []
        };

        this.handleGateways = this.handleGateways.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
    }

    componentDidMount() {
        this.setDefaultPhoto();
        this.handleGateways();
    }


    setDefaultPhoto() {
        const data = {...this.state.data};

        ApiRouters.defaultImage().then(({image}) => {
            data.photos = image;

            this.setState({
                data,
                defaultPhoto: image,
                photoLoader:false
            });
        });
    }


    handleGateways() {
        ApiGateways.list({query: {pagination: {page: 1, perpage: 100}}}).then(({list}) => {
            this.setState({gateways: list, data: {...this.state.data, gateway_id: _get(list, "0.gateway_id")}});
        });
    }

    handleChangeGlobalSearch (globalSearchString) {
        this.setState({globalSearchString: globalSearchString});
    }

    onChange(e) {
        const data = {...this.state.data};
        _set(data, _get(e, "target.name", ""), _get(e, "target.value", ""));
        const validation = this.validator.validate(data, _get(e, "target.name", ""));
        this.setState({data, validation});
    }

    handleSubmit() {
        const data = this.state.data;
        const validation = this.validator.validate(data);
        this.setState(
            {inProgress: true},
            () => {
                this.setState({validation}, () => {
                    if (validation.isValid) {
                        if (Helper.hasOnlyDefaultImage(data.photos)) {
                            data.photos = [];
                        }

                        ApiRouters.associate(data).then(resp => {
                            if (resp.status === "ok") {
                                this.props.history.push("/network/routers");
                                Toast.success("The repeater has been added.");
                            } else if (resp.errors) {
                                const validation = this.state.validation;

                                Object.keys(resp.errors).map(key => {
                                    validation[key].isValid = false;
                                    validation[key].message = resp.errors[key];
                                });

                                this.setState({validation, inProgress: false});
                            }
                        });
                    } else {
                        this.setState({inProgress: false});
                    }
                });
            }
        );
    }

    searchChange(value) {
        this.handleFilterChange({query: value});
    }

    attachImages = images => {
        const data = {...this.state.data};

        data.photos = Helper.hasOnlyDefaultImage(data.photos)
            ? images
            : _concat(data.photos, images);

        this.setState({data});
    }

    detachImage = image => {
        const data = {...this.state.data};
        const {defaultPhoto} = this.state;
        let localPhotos = [...data.photos];
        let localFlipTurnPhotos = [...data.flipTurnPhotos];

        const index = localPhotos.indexOf(image);
        if (index > -1) {
            localPhotos.splice(index, 1);
            localFlipTurnPhotos.splice(index, 1);

            data.photos = localPhotos.length
                ? localPhotos
                : defaultPhoto;

            data.flipTurnPhotos = localFlipTurnPhotos;

            this.setState({data});
        }
    };

    onFlipImage = (imageIndex, flipTurn) => {
        const data = {...this.state.data};
        data.flipTurnPhotos[imageIndex] = flipTurn;

        this.setState({data});
    };

    render() {
        const {
            breadcrumbs,
            loader,
            globalSearchString,
            title,
            inProgress,
            data,
            validation,
            gateways,
            photoLoader
        } = this.state;
        const {user} = this.props;

        const hasOnlyDefaultImage = Helper.hasOnlyDefaultImage(_get(data, "photos", []));

        return (
            <div className="">
                 <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">{title}</div>
                </div>
                {loader
                    ?
                    <div className="loader-fullscreen"><Loader/></div>
                    :
                    <div>
                        <div className="block">
                            <div className="block-body">
                                <div className="row">
                                    <div className="col-xl-4 col-md-5">

                                        <div className="form-group">
                                            <div className="gateway-edit">
                                                <div className="">
                                                    {photoLoader
                                                        ? <Loader/>
                                                        : <ImageToolbar
                                                            title={"Add Repeater Photo"}
                                                            images={data.photos}
                                                            onUploadImages={this.attachImages}
                                                            onDelete={this.detachImage}
                                                            withDropzone={true}
                                                            canEdit={!hasOnlyDefaultImage}
                                                            onFlipTurnImage={this.onFlipImage}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-1 col-md-1"></div>

                                    <div className="col-xl-7 col-md-6">
                                        <div className="form-group">
                                            <label className="text-right form-label">Serial Number: <span className="color-danger">*</span></label>
                                            <div className="">
                                                <input
                                                    className={"form-control" + (validation.serial.isValid || !validation.serial.message ? "" : " is-invalid")}
                                                    type="text"
                                                    name="serial"
                                                    defaultValue={data.serial}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                />
                                                <ValidationError message={validation.serial.message}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="text-right form-label">Gateway: <span className="color-danger">*</span></label>
                                            <div className="">
                                                <SelectWrapper
                                                    className={(validation.gateway_id.isValid || !validation.gateway_id.message ? "" : " is-invalid")}
                                                    name="gateway_id"
                                                    value={+data.gateway_id}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                >
                                                    {gateways.map(gateway => (
                                                        <option key={gateway.gateway_id} value={gateway.gateway_id}>{gateway.name ? gateway.name + " (" + gateway.serial + ")" : gateway.serial}</option>
                                                    ))}
                                                </SelectWrapper>
                                                <ValidationError message={validation.gateway_id.message}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="text-right form-label">Location:</label>
                                            <div className="">
                                                <input
                                                    className={"form-control"}
                                                    type="text"
                                                    name="location_info"
                                                    defaultValue={data.location_info}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="text-right form-label">Asset Tree Branch:</label>
                                            <div className="">
                                                <CollapseLocationSelect
                                                    className={"form-control"}
                                                    selectName={"location_id"}
                                                    value={+_get(data, "location_id", null)}
                                                    onChange={this.onChange}
                                                    emptyOptionLabel={"Select Location"}
                                                    allowedLocationIds={_get(user, "allowedLocationIds", [])}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                            <Link to="/network/routers" className="btn btn-sm btn-secondary">Cancel</Link>
                            <Button color="primary" size="sm" className="ml-2" disabled={this.state.inProgress} onClick={this.handleSubmit}>Save</Button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item ml-2">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

RouterAdd.propTypes = {
    title: PropTypes.string,
    auth: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object
};

export default withGlobalStore(RouterAdd);