import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import {Loader, Modal} from "../../../shared";
import Helper from "../../../helpers/helper";
import {withGlobalStore} from "../../../stores/GlobalStore";
import { SPEED_TYPE_LABELS, SPEED_TYPES } from "../../../constants/constants";
import {get as _get, set as _set, cloneDeep as _cloneDeep} from "lodash";
import SettingsForm from "../../tachometers/settings-form";
import NodeApi from "../../../api/node";

import "../../../assets/scss/components/speed-form/speed-form.scss";
import SelectWrapper from "../../../helpers/select-wrapper";
import auth from "../../../services/auth";
import {useTachometerUpdateMutation} from "../../../hooks/api/tachometer/useTachometerUpdateMutation";
import Toast from "../../../pages/shared/toast";
import {useSearchTachometerQuery} from "../../../hooks/api/tachometer/useSearchTachometerQuery";
import { useEquipmentByParams } from "../../../hooks/api/equipment/useEquipmentQuery";
import { useEquipmentInvalidate } from "../../../hooks/api/equipment/useEquipmentInvalidate";
import {useReadingsInvalidate} from "../../../hooks/api/fftReadings/useReadingsInvalidate";
import {useSelectedByEquipment} from "../../../stores/zustand/ChartSelectedEquipmentStore";
import InstallationPointSpeedSettingInput from "../../../shared/form/InstallationPointSpeedSettingInput";

const propTypes = {
    auth: PropTypes.object,
    onClose: PropTypes.func,
};

const SpeedSettingsModal = (props) => {
    const {equipmentItem} = useEquipmentByParams();
    const invalidateEquipment = useEquipmentInvalidate(equipmentItem.id);
    const invalidateReadings = useReadingsInvalidate();
    const selectedPoints = useSelectedByEquipment(equipmentItem.id);

    const [data, setData] = useState({
        equipmentSpeedTypeId: _get(equipmentItem, ["speedType", 0, "id"], SPEED_TYPES.FIXED),
        currentSpeed: equipmentItem.current_speed || "",
        tachometerNodeSerial: _get(equipmentItem, "tachometer.node.serial", ""),
        speedMin: equipmentItem.speed_min,
        speedMax: equipmentItem.speed_max,
        installationPoints: {},
    });
    const [tachometerSpeedSettings, setTachometerSpeedSettings] = useState(false);

    const [hasTachometer, setHasTachometer] = useState(!!equipmentItem.tachometer);
    const [nodeTriggerSettings, setNodeTriggerSettings] = useState(equipmentItem.tachometer ? equipmentItem.tachometer.nodeTriggerSetting : {});
    const tachometerSettingsBlock = useRef(null);

    const {tachometerNodeList, isLoading} = useSearchTachometerQuery(equipmentItem);
    const {mutateAsync: mutateTachometerUpdate, isLoading: inProgress} = useTachometerUpdateMutation(equipmentItem.id);

    const onChange = (e) => {
        const dataLocal = _cloneDeep(data);
        const key = e.target.name;
        const val = Helper.getInputValue(e.target);
        _set(dataLocal, key, val);

        if (key === "tachometerNodeSerial" && val) {
            NodeApi.view(val).then((node) => {
                setNodeTriggerSettings(node.node.nodeTriggerSettings);
            });
        }
        setData(dataLocal);
    };

    const hasTachometerToggle = () => {
        setHasTachometer((prev) => !prev);
        setTachometerSpeedSettings(false);

        setData({
            ...data,
            tachometerNodeSerial: hasTachometer ? "" : data.tachometerNodeSerial
        });
    };
    const updateValue = (value) => {
        setNodeTriggerSettings(value);
    };

    const tachometerSpeedSettingsToggle = () => {
        setTachometerSpeedSettings((prev) => !prev);
        if (tachometerSettingsBlock) {
            tachometerSettingsBlock.current.scrollIntoView();
        }
    };

    const onSubmit = () => {
        mutateTachometerUpdate(data)
            .then(() => {
                Toast.success("The speed settings have been updated.");
                selectedPoints.forEach((pointId) => {
                    invalidateReadings(pointId);
                });
                props.onClose();
            })
            .catch((response) => {
                if (response && response.errors && Object.values(response.errors).length) {
                    let message = Object.values(response.errors)[0][0];
                    Toast.error(message);
                }
            });
    };

    const readonly = !auth.userCan("editSelfBearings");

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Modal
            {...props}
            size="lg"
            title={"Speed Settings"}
            className={"custom-modal"}
            onSubmit={onSubmit}
            inProgress={inProgress}
            disableSubmit={tachometerSpeedSettings}
            withoutFooter={readonly}
        >
            <div className="row">
                <div className="form-group col-6 mb-2">
                    <label>Asset Code:</label>
                    <p className="mb-2">{equipmentItem.asset_code}</p>
                </div>
                <div className="form-group col-6 mb-2">
                    <label>Equipment:</label>
                    <p className="mb-2">{equipmentItem.name}</p>
                </div>
            </div>
            <hr />

            <div className="speed-settings-form">
                <div className="sub-title">
                    <span>Equipment Speed Settings</span>
                </div>
                <div className="form-group">
                    <label>Equipment Speed Type</label>
                    <SelectWrapper
                        name="equipmentSpeedTypeId"
                        defaultValue={data.equipmentSpeedTypeId}
                        onChange={onChange}
                        style={{
                            width: "100%",
                            display: "block",
                        }}
                        disabled={inProgress}
                    >
                        {Object.values(SPEED_TYPES).map((speedType) => (
                            <option
                                key={speedType}
                                value={speedType}
                            >
                                {SPEED_TYPE_LABELS[speedType]}
                            </option>
                        ))}
                    </SelectWrapper>
                </div>
                {(+data.equipmentSpeedTypeId === SPEED_TYPES.FIXED || +data.equipmentSpeedTypeId === SPEED_TYPES.RATIO) && (
                    <div className="form-group">
                        <label>{+data.equipmentSpeedTypeId === SPEED_TYPES.FIXED ? "Default Running Speed" : "Base Running Speed"}</label>
                        <input
                            className={"form-control"}
                            type="text"
                            name="currentSpeed"
                            value={data.currentSpeed}
                            onChange={onChange}
                            disabled={inProgress}
                        />
                    </div>
                )}
                {+data.equipmentSpeedTypeId === SPEED_TYPES.VARIABLE && (
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label>Minimum RPM:</label>
                            <div className="input-group">
                                <input
                                    className={"form-control"}
                                    type="text"
                                    name="speedMin"
                                    value={data.speedMin}
                                    onChange={onChange}
                                    disabled={inProgress}
                                />
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Maximum RPM:</label>
                            <div className="input-group">
                                <input
                                    className={"form-control"}
                                    type="text"
                                    name="speedMax"
                                    value={data.speedMax}
                                    onChange={onChange}
                                    disabled={inProgress}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {auth.userCan("editTachometers") && (
                    <>
                        {!hasTachometer && tachometerNodeList.length > 0 && (
                            <span
                                onClick={hasTachometerToggle}
                                className="add-new-link"
                            >
                                <i className="fa fa-plus-circle" />
                                <span>Add new tachometer</span>
                            </span>
                        )}
                        {tachometerNodeList.length > 0 && hasTachometer && (
                            <>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div
                                        className="form-group"
                                        style={{width: "100%"}}
                                    >
                                        <label>Tachometer</label>
                                        <SelectWrapper
                                            name="tachometerNodeSerial"
                                            defaultValue={data.tachometerNodeSerial}
                                            onChange={onChange}
                                            style={{
                                                width: "100%",
                                                display: "block",
                                            }}
                                            disabled={inProgress}
                                        >
                                            <option value="">None</option>
                                            {tachometerNodeList.map((tachometer) => (
                                                <option
                                                    key={tachometer.serial}
                                                    value={tachometer.serial}
                                                >
                                                    {tachometer.location_info || tachometer.serial}
                                                </option>
                                            ))}
                                        </SelectWrapper>
                                    </div>
                                </div>
                                {!tachometerSpeedSettings && (
                                    <span
                                        onClick={hasTachometerToggle}
                                        className="add-new-link"
                                    >
                                        <i className="fa fa-minus-circle" />
                                        <span>Remove tachometer</span>
                                    </span>
                                )}
                                <div ref={(el) => (tachometerSettingsBlock.current = el)}>
                                    {tachometerSpeedSettings && (
                                        <React.Fragment>
                                            <div className="sub-title">
                                                <span>Tachometer Settings</span>
                                            </div>
                                            <SettingsForm
                                                nodeTriggerSettings={nodeTriggerSettings}
                                                tachometerSpeedSettingsToggle={tachometerSpeedSettingsToggle}
                                                nodeSerial={data.tachometerNodeSerial}
                                                updateValue={updateValue}
                                                equipment={equipmentItem}
                                                invalidateEquipment={invalidateEquipment}
                                            />
                                        </React.Fragment>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                )}
                <div className="sub-title">
                    <span>Installation Point Speed Settings</span>
                </div>
                {equipmentItem.installationPoints.length > 0 ? (
                    equipmentItem.installationPoints.map((point, index) => (
                        <div
                            key={index}
                            className={point.point_type !== "sensor" ? "row d-none" : "row"}
                        >
                            <label className={"col-md-4 form-label ellipsis"}>{point.name}</label>

                            <div className={"col-md-8"}>
                                <InstallationPointSpeedSettingInput
                                    nameRpm={`installationPoints.[${point.id}].speed`}
                                    nameRatio={`installationPoints.[${point.id}].speed_ratio`}
                                    valueRpm={_get(data.installationPoints, [point.id, "speed"], point.speed) || ""}
                                    valueRatio={_get(data.installationPoints, [point.id, "speed_ratio"], point.speed_ratio) || ""}
                                    onChange={onChange}
                                    disabled={inProgress}
                                    equipmentSpeedTypeId={+data.equipmentSpeedTypeId}
                                    hasTachometer={!!data.tachometerNodeSerial}
                                />
                            </div>

                        </div>
                    ))
                ) : (
                    <div>Installation points not found</div>
                )}
            </div>
        </Modal>
    );
};

SpeedSettingsModal.propTypes = propTypes;

export default withGlobalStore(SpeedSettingsModal);
