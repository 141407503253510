import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";
import auth from "../../../services/auth";

const DeleteReading = ({ currentDate, onReadingDelete, onClose }) => {
    if (!auth.userCan("deleteReading")) {
        return <></>;
    }

    return (
        <SweetAlert
            warning
            showConfirm={true}
            title="DELETE Reading"
            customButtons={
                <React.Fragment>
                    <button
                        className="btn btn-sm btn-default mr-2"
                        // disabled={modal.deleteReadingFetch}
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-sm btn-primary__custom mr-2"
                        // disabled={modal.deleteReadingFetch}
                        onClick={onReadingDelete}
                    >
                        Yes
                    </button>
                </React.Fragment>
            }
            onCancel={onClose}
            onConfirm={() => null}
        >
            Are you sure you want to delete this reading? <br /> All TWF and FFT values ​​with all
            axes will be deleted related to this time ({currentDate}). This action is irreversible.
        </SweetAlert>
    );
};
DeleteReading.propTypes = {
    currentDate: PropTypes.string,
    onReadingDelete: PropTypes.func,
    onClose: PropTypes.func,
    equipment: PropTypes.object,
};
export default DeleteReading;
