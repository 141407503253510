import React, {Component, createContext, useContext} from "react";
import PropTypes from "prop-types";
import ApiLocations from "../api/location";
import {find as _find, get as _get, isUndefined} from "lodash";
import {withGlobalStore} from "./GlobalStore";
import Helper from "../helpers/helper";
import cookies from "react-cookies";

export const LocationSelectStoreContext = createContext();

class LocationSelectStore extends Component {
    constructor(props) {
        super(props);

        const storageUserId = Helper.getStorageItem("locationUserId");
        if (+storageUserId !== +_get(props.user, "id")) {
            Helper.setStorageItem("location", "");
        }
        Helper.setStorageItem("locationUserId", +_get(props.user, "id"));

        this.state = {
            locationList: [],
            locationTree: [],
            locationLoading: true,
            locationsWithFullName: [],
        };
    }

    componentDidMount() {
        this.fetch();
    }

    fetch = () => {
        ApiLocations.list().then(({list, status}) => {
            if (status === "ok") {
                const locationList = _get(list, "0.children", []);
                let sortedList = locationList;

                if (!isUndefined(cookies.load("auto-sort-asset-tree"))) {
                    sortedList = Helper.sortLocationList(sortedList);
                }
                this.setState({
                    locationList: sortedList,
                    locationTree: this.buildOptions(locationList),
                    locationLoading: false,
                    locationsWithFullName: Helper.transformLocations(sortedList),
                });
            }
        });
    };

    refreshLocation = () => {
        this.fetch();
    };

    buildOptions(list, storage = []) {
        (list || []).map((item) => {
            storage.push({id: item.id, name: item.name});
            if ((item.children || []).length) {
                this.buildOptions(item.children, storage);
            }
        });
        return storage;
    }

    setLocationId = (locationId) => {
        Helper.setStorageItem("location", locationId);
    };

    getLocationId = () => {
        return !+Helper.getStorageItem("location") ? "" : Helper.getStorageItem("location");
    };

    getLocationById = (location_id) => {
        const {locationTree} = this.state;
        return _find(locationTree, {id: parseInt(location_id)});
    };

    getLocationFullNameById = (location_id) => {
        const {locationsWithFullName} = this.state;
        return _get(_find(locationsWithFullName, {id: parseInt(location_id)}), "full_name");
    };

    getLocationName = () => {
        const {locationList, locationId} = this.state;

        const location = _find(locationList, {id: locationId});

        return _get(location, "name", "").trim();
    };

    render() {
        const {locationList, locationTree, locationLoading} = this.state;
        const {children} = this.props;

        return (
            <LocationSelectStoreContext.Provider
                value={{
                    locationList,
                    locationTree,
                    locationLoading,
                    setLocationId: this.setLocationId,
                    getLocationId: this.getLocationId,
                    getLocationById: this.getLocationById,
                    getLocationName: this.getLocationName,
                    refreshLocation: this.refreshLocation,
                    getLocationFullNameById: this.getLocationFullNameById
                }}
            >
                {children}
            </LocationSelectStoreContext.Provider>
        );
    }
}

function withLocationSelectStore(Component) {
    function ConnectedComponent(props) {
        const context = useContext(LocationSelectStoreContext);
        return (
            <Component
                {...props}
                {...context}
                locationList={props?.locationList || context?.locationList}
            />
        );
    };

    ConnectedComponent.propTypes = {
        locationList: PropTypes.array,
    };

    return ConnectedComponent;
}

LocationSelectStore.propTypes = {
    children: PropTypes.node,
    auth: PropTypes.object,
    user: PropTypes.object,
    onlyWithEquipment: PropTypes.bool,
};

export default withGlobalStore(withLocationSelectStore(LocationSelectStore));
export {withLocationSelectStore};
