import React, {memo} from "react";
import "./assetCollapseItem.scss";
import PropTypes from "prop-types";
import {useEquipmentListByAssetIdQuery} from "../../../../hooks/api/routes/useEquipmentListByAssetIdQuery";
import {Loader} from "../../../../shared";
import EquipmentCollapseItem from "../equipmentCollapseItem/EquipmentCollapseItem";
import DraggableList from "../../../../shared/drag/DraggableList";
import DraggableItem from "../../../../shared/drag/DraggableItem";
import {useDraggableList} from "../../hooks/useDraggableList";
import Select from "react-select";
import ValidationError from "../../../../layouts/ValidationError/ValidationError";

const ALL_SELECT = {
    value: "All",
    label: "All",
};

const AssetCollapseItem = ({title, items, onEquipmentChange, assetId, isSorting, onEquipmentDrop, errorMessage, isAllSelected = false}) => {
    const {data: equipmentList = [], isLoading: isEquipmentListLoading} = useEquipmentListByAssetIdQuery(assetId);

    const {listConfig, listItemConfig} = useDraggableList({isSorting, move: onEquipmentDrop});

    const options = equipmentList.map((item) => ({...item, value: item.id, label: item.name}));

    const optionsWithSelect = options.length && items.length < options.length ? [ALL_SELECT, ...options] : options;

    const handleEquipmentChange = (value) => {
        if (isAllSelected) {
            onEquipmentChange({equipments: value.slice(1), isAll: false});
            return;
        }
        if (value.includes(ALL_SELECT)) {
            onEquipmentChange({equipments: options, isAll: true});
            return;
        }
        onEquipmentChange({equipments: value, isAll: value?.length === equipmentList?.length});
    };

    if (isEquipmentListLoading) {
        return (
            <div className="asset-collapse-item">
                <div className="asset-collapse-item__header">
                    <div>{title}</div>
                    <div className="asset-collapse-item__controls">{isSorting && <i className="fas fa-bars"></i>}</div>
                </div>
                <Loader />
            </div>
        );
    }

    return (
        <div className="asset-collapse-item">
            <div className="asset-collapse-item__header">
                <div>{title}</div>
                <div className="asset-collapse-item__controls">{isSorting && <i className="fas fa-bars"></i>}</div>
            </div>

            {isSorting && isEquipmentListLoading && (
                <div className="asset-collapse-item__body">
                    <Loader />
                </div>
            )}

            {!isSorting && !isEquipmentListLoading && (
                <div className={"form-group"}>
                    <Select
                        className={`form-control-select ${errorMessage ? "is-invalid" : ""}`}
                        isMulti
                        placehoder={false}
                        isSearchable={false}
                        isClearable={false}
                        hideSelectedOptions={false}
                        options={optionsWithSelect}
                        value={isAllSelected || items.length === optionsWithSelect.length ? [ALL_SELECT] : items}
                        onChange={handleEquipmentChange}
                        isOptionDisabled={(option) => option.disabled === true}
                        d
                    />
                    <ValidationError message={errorMessage} />
                </div>
            )}
            {isSorting && !isEquipmentListLoading && !!items.length && (
                <div className="asset-collapse-item__body">
                    <DraggableList {...listConfig}>
                        {items.map((item, index) => (
                            <DraggableItem
                                key={index}
                                elementIndex={index}
                                {...listItemConfig}
                            >
                                <EquipmentCollapseItem value={item} />
                            </DraggableItem>
                        ))}
                    </DraggableList>
                </div>
            )}
        </div>
    );
};

AssetCollapseItem.propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    assetId: PropTypes.number,
    isSorting: PropTypes.bool,
    setDragFrom: PropTypes.func,
    onEquipmentChange: PropTypes.func,
    onEquipmentDelete: PropTypes.func,
    onDragChange: PropTypes.func,
    onEquipmentDrop: PropTypes.func,
    onAssetDelete: PropTypes.func,
    onAssetDrop: PropTypes.func,
    errorMessage: PropTypes.string,
    isAllSelected: PropTypes.bool,
};

export default memo(AssetCollapseItem);
