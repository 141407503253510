import React from "react";
import {MenuItem} from "react-contextmenu";
import PropTypes from "prop-types";
import {useDeleteReadingModal} from "../../../../../../modals/deleteReadings/hooks/useDeleteReadingModal";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import auth from "../../../../../../services/auth";
import {useSpeedSettings} from "../../hooks/helpers/useSpeedSettings";
import {useOverlayChartModal} from "../../../../../../modals/overlayModal/hooks/useOverlayChartModal";
import {useIsOverlayEnabled} from "../../hooks/helpers/useIsOverlayEnabled";
import {useIdleThresholdModal} from "../../../../../../modals/installationPoint/hooks/useIdleThreshold";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {useOverlayControls} from "../../hooks/helpers/useOverlayControls";
import {useCurrentDate} from "../../hooks/helpers/useCurrentDate";
import {useIsCircular} from "../../../hooks/useChartViewTypes";
import {useIsShowChartMarks, useSharedStoreActions} from "../../../store/sync/sharedStore";

export const ContextMenuTWFContent = (props) => {
    const {showOutlierDialog} = props;
    const currentDate = useCurrentDate();
    const {showRpmPlotLines, chartType, chartViewType} = useChartStateStoreContext((state) => state.settings, shallow);
    const isCircular = useIsCircular(chartViewType);
    const isImpactVue = useIsImpactVue(chartType);
    const {currentSpeed} = useSpeedSettings();
    const [handleSetIdleThresholdModal] = useIdleThresholdModal();
    const {toggleShowRpmPlotLines} = useChartStateStoreContext((state) => state.actions);
    const [showDeleteReadingModal] = useDeleteReadingModal();
    const [showOverlayModal] = useOverlayChartModal();
    const {disableOverlay} = useOverlayControls();

    const isOverlayEnabled = useIsOverlayEnabled();

    const {toggleChartMarks} = useSharedStoreActions();
    const isShowChartMarks = useIsShowChartMarks();

    return (
        <React.Fragment>
            {currentSpeed > 0 && !isCircular && (
                <React.Fragment>
                    <MenuItem onClick={() => toggleShowRpmPlotLines()}>{showRpmPlotLines ? "Hide" : "Show"} RPM interval</MenuItem>
                    <MenuItem divider />
                </React.Fragment>
            )}
            {auth.userCan("editSelfBearings") && <MenuItem onClick={handleSetIdleThresholdModal}>Set idle threshold</MenuItem>}
            <MenuItem onClick={toggleChartMarks}>{isShowChartMarks ? "Hide" : "Show"} chart marks</MenuItem>
            <MenuItem onClick={() => showOverlayModal()}>{isOverlayEnabled ? "Edit equipment overlay" : "Overlay another reading"}</MenuItem>
            {isOverlayEnabled && <MenuItem onClick={disableOverlay}>Disable overlay</MenuItem>}
            {auth.userCan("deleteReading") && (
                <React.Fragment>
                    <MenuItem divider />
                    <MenuItem onClick={showDeleteReadingModal}>Delete current reading</MenuItem>
                </React.Fragment>
            )}
            {!isImpactVue && <MenuItem onClick={() => showOutlierDialog(currentDate)}>Mark reading as outlier</MenuItem>}
        </React.Fragment>
    );
};

ContextMenuTWFContent.propTypes = {
    auth: PropTypes.object,
    showOverlayModal: PropTypes.func,
    overlayDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    disableOverlay: PropTypes.func,
    showOutlierDialog: PropTypes.func,
    handleSetIdleThresholdModal: PropTypes.func,
};
