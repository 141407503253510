import React, {Component} from "react";
import SearchInput from "../../shared/search-input";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../stores/GlobalStore";
import LimitSelect from "../../shared/limit-select";
import {Loader, Pager} from "../../../shared";
import Table from "./table";
import SweetAlert from "react-bootstrap-sweetalert";
import {Link} from "react-router-dom";

import "../../../assets/scss/components/nameplates/nameplate.scss";
import NameplateTemplateApi from "../../../api/nameplateTemplate";
import Toast from "../../shared/toast";
import { HeaderSimple } from "../../../shared/header";

class NameplateTemplates extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [
                {name: "Nameplates", link: "/nameplates"},
                {name: "Manage Templates"}
            ],
            loader: true,
            list: [],
            pagination: {
                page: 1,
                pages: 1,
                perpage: 10,
                total: 0
            },
            sort: {
                field: "",
                sort: "asc"
            },
            filter: {
                query: ""
            },
            globalSearchString: "",
            deleteTemplateId: ""
        };
    }

    componentDidMount() {
        Promise.all([
            this.handleFetch(),
        ]);
    }

    handleFetch = () => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        return NameplateTemplateApi.filteredList({
            query: {
                sort: this.state.sort,
                filter: this.state.filter,
                pagination: this.state.pagination
            },
            signal: this.signal
        }).then((response) => {
            if (response) {
                const {meta = [], list = {}} = response;
                this.setState({
                    loader: false,
                    pagination: Object.assign({}, this.state.pagination, meta),
                    list
                });
            }
        });
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    handleSortChange = (field = "") => {
        const {sort} = this.state;

        this.setState({
            sort: Object.assign({}, {
                field,
                sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"
            })
        }, this.handleFetch);
    }

    handlePagerChange = (page = 1) => {
        this.setState({
            loader: true,
            pagination: Object.assign({}, this.state.pagination, {page})
        }, this.handleFetch);
    }

    handleFilterChange = (obj = {}) => {
        this.finished = false;
        this.setState({
            filter: Object.assign({}, this.state.filter, obj),
            pagination: Object.assign({}, this.state.pagination, {page: 1}),
            loader: true
        }, this.handleFetch);
    }

    searchChange = (value) => {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }
        this.handleFilterChange({query: value});
    }

    handleLimitChange = e => {
        const {value} = e.target;
        this.setState({
            loader: true,
            pagination: {
                ...this.state.pagination,
                page: 1,
                perpage: value
            }
        }, this.handleFetch);
    };

    beforeDelete = (id) => {
        this.setState({deleteTemplateId: id});
    }

    onDelete = () => {
        const {deleteTemplateId} = this.state;

        NameplateTemplateApi
            .delete(deleteTemplateId)
            .then(() => {
                this.setState({deleteTemplateId: ""}, () => {
                    Toast.success("The template has been deleted.");
                    this.handleFetch();
                });
            });
    }

    cancelDelete = () => {
        this.setState({deleteTemplateId: ""});
    }

    render() {
        const {
            breadcrumbs,
            loader,
            list,
            pagination,
            sort,
            filter,
            globalSearchString,
            deleteTemplateId,
        } = this.state;

        return (
            <React.Fragment>
                <div>
                    <HeaderSimple
                        breadcrumbs={ breadcrumbs }
                        globalSearchString={ globalSearchString }
                        handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                    />
                    <div className="subheader">
                        <div className="subheader-title">Manage Templates</div>
                        <div className="subheader-controls">
                            <div className="subheader-toolbar">
                                <Link
                                    to="/nameplates/templates/add"
                                    className="btn btn-primary btn-sm"
                                >
                                    <i className="fa fa-plus"/> Add Template
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <div className="block-header">
                            <div className="block-label"/>
                            <div id="top-pagination-block" className="block-pagination">
                                {pagination.total > 10 &&
                                <div className="limit-select-block">
                                    <LimitSelect
                                        name={"perpage"}
                                        onChange={this.handleLimitChange}
                                        defaultValue={pagination.perpage}
                                        limits={[10, 20, 50, 100]}
                                    />
                                </div>
                                }
                                {pagination.pages > 1 &&
                                <Pager
                                    page={pagination.page}
                                    pages={pagination.pages}
                                    perPage={pagination.perpage}
                                    onPagerChange={this.handlePagerChange}
                                    query={filter.query}
                                />
                                }
                            </div>
                            <div className="block-toolbar">
                                <div className="block-toolbar-wrapper">
                                    <div>
                                        <SearchInput
                                            placeholder="Search Templates"
                                            query={filter.query}
                                            onChange={this.searchChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block-body">
                            {loader
                                ?
                                <Loader/>
                                :
                                <div className="nameplates-templates-table">
                                    <Table
                                        list={list}
                                        sort={sort}
                                        query={filter.query}
                                        onSortChange={this.handleSortChange}
                                        onEdit={() => {}}
                                        onDelete={this.beforeDelete}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    {deleteTemplateId &&
                        <SweetAlert
                            warning
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnBsStyle="default"
                            btnSize="xs"
                            title="DELETE TEMPLATE"
                            openAnim={false}
                            closeAnim={false}
                            onConfirm={this.onDelete}
                            onCancel={this.cancelDelete}
                        >
                            Are you sure you want to delete this template?
                        </SweetAlert>
                    }
                </div>
            </React.Fragment>
        );

    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item global-search">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

NameplateTemplates.propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object
};

export default withGlobalStore(NameplateTemplates);