import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// @import '~@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';

const ReactSwal = withReactContent(Swal);

const initialOptions = {
    icon: "warning",
    reverseButtons: true,
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "Cancel",
    title: ""
};

export const simple = (title, desc, type, timeout) => {
    ReactSwal.fire(title, desc, type);
    timeout && setTimeout(() => ReactSwal.close(), timeout);
};

export const fireSwal = (options, onConfirm = () => {}, onCancel = () => {}) =>
    ReactSwal.fire({
        ...initialOptions,
        ...options
    }).then(({isConfirmed}) =>
        isConfirmed ? onConfirm() : onCancel()
    );

export default simple;