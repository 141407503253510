import React, {Component} from "react";
import {Redirect} from "react-router";
import ApiErrorLog from "../../../api/errorLog";// eslint-disable-line

import PropTypes from "prop-types";

import StackTrace from "stacktrace-js";

class ErrorBoundary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            error: "",
            message: ""
        };
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        StackTrace.fromError(error).then(err => {
            ApiErrorLog.send({message: error.message, info: err.toString()}).then(() => {
                this.setState({
                    error: errorInfo.message,
                    message: err
                });
            });
        });
    }

    render() {
        const {hasError} = this.state;

        if (hasError) {
            return <Redirect to={{
                pathname: "/",
                search: "#alert=error"
            }} />;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.object
};

export default ErrorBoundary;
