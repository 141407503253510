import React, {useEffect, useReducer} from "react";
import {Loader, Modal} from "../../../shared";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import ApiAxisLabel from "../../../api/axisLabel";
import Helper from "../../../helpers/helper";
import {get as _get, cloneDeep as _cloneDeep} from "lodash";
import EquipmentApi from "../../../api/equipment";
import {AXIS_LIST} from "../../../constants/constants";
import {useAxisLabelInvalidate} from "../../../hooks/api/Chart/UseAxisLabelInvalidate";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import useAxisLabelQuery from "../../../hooks/api/axisLabel/useAxisLabelQuery";
import "./manageAxis.scss";

const initialState = {
    data: {},
    headData: {},
    reloadFlag: false,
    inProgress: false,
};

const reducer = (state, action) => ({...state, ...action});

const ManageAxisModal = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {data, headData, reloadFlag, inProgress} = state;

    const {history} = props;
    const {equipmentItem} = useEquipmentByParams();
    const {axisLabelsChart: axisLabels, isSuccess} = useAxisLabelQuery(equipmentItem.id);
    const axisLabelInvalidate = useAxisLabelInvalidate(equipmentItem.id);
    const searchParams = Helper.getHashParams();
    const selected = searchParams.selected && searchParams.selected.length ? searchParams.selected.split(",") : [];

    useEffect(() => {
        let tempData = {};
        (equipmentItem.installationPoints || []).forEach((installationPoint = {}) => {
            if (!selected.length || (selected.indexOf(installationPoint.id.toString()) !== -1 && isSuccess)) {
                Object.keys(AXIS_LIST).forEach((axisId) => {
                    if (!tempData[axisId]) tempData[axisId] = {};
                    tempData[axisId][installationPoint.id] =
                        _get(data, [axisId, installationPoint.id]) || _get(axisLabels, [axisId, installationPoint.id]) || "";
                });
            }
        });
        dispatch({data: tempData});
    }, [reloadFlag, axisLabels]);

    const updateData = (axisId, installationPointId, value = "") => {
        if (installationPointId) {
            dispatch({
                data: {...data, [axisId]: {...data[axisId], [installationPointId]: value}},
                headData: {...headData, [axisId]: ""},
            });
        } else {
            let tempData = _cloneDeep(data);
            Object.keys(tempData[axisId]).forEach((installationPointId) => (tempData[axisId][installationPointId] = value));
            dispatch({
                data: tempData,
                headData: {...headData, [axisId]: value},
            });
        }
    };

    const submit = () => {
        dispatch({inProgress: true});
        EquipmentApi.checkService(equipmentItem.id).then(() =>
            ApiAxisLabel.update({data}).then(() => {
                axisLabelInvalidate();
                history.push(Helper.deleteHashParams(["modal", "selected"]));
                props.onClose();
            })
        );
    };

    if (!isSuccess) {
        return <Loader />;
    }

    return (
        <Modal
            size="lg"
            {...props}
            showModal={true}
            submitTitle="Save"
            title="Manage Axes Names"
            className="custom-modal"
            inProgress={inProgress}
            onSubmit={submit}
            onClose={props.onClose}
        >
            <div className="row">
                <div className="col-md-12">
                    <table className="table table-hover axis-table mb-2">
                        <thead>
                            <tr>
                                <th style={{minWidth: 250, verticalAlign: "middle"}}>All Installation Points</th>
                                {Object.keys(AXIS_LIST).map((key) => (
                                    <th
                                        key={key}
                                        className="text-center"
                                    >
                                        {selected.length === 1 ? (
                                            AXIS_LIST[key]
                                        ) : (
                                            <div className="input-group input-group-sm">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <b>{AXIS_LIST[key]}</b>
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={_get(headData, key, "")}
                                                    onChange={(ev) => updateData(key, null, ev.target.value)}
                                                />
                                            </div>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {(equipmentItem.installationPoints || []).map(
                                (installationPoint = {}) =>
                                    (!selected.length || selected.indexOf(installationPoint.id.toString()) !== -1) && (
                                        <tr key={installationPoint.id}>
                                            <td>{installationPoint.name}</td>
                                            {Object.keys(AXIS_LIST).map((key) => (
                                                <th key={key}>
                                                    <div className="input-group input-group-sm">
                                                        {selected.length !== 1 && (
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text">{AXIS_LIST[key]}</div>
                                                            </div>
                                                        )}
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={_get(data, [key, installationPoint.id], "")}
                                                            onChange={(ev) => updateData(key, installationPoint.id, ev.target.value)}
                                                        />
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    )
                            )}
                        </tbody>
                    </table>
                </div>
                {!!selected.length && (
                    <div className="col-md-12 text-center">
                        <span
                            className="btn btn-link extend-axes-btn"
                            onClick={() => {
                                history.push(Helper.deleteHashParams(["selected"]));
                                dispatch({reloadFlag: !reloadFlag});
                            }}
                        >
                            Show all installation points
                        </span>
                    </div>
                )}
            </div>
        </Modal>
    );
};

ManageAxisModal.propTypes = {
    history: PropTypes.object,
    onClose: PropTypes.func,
};

export default withRouter(ManageAxisModal);
