export const ACTION = {
    SET_STATE: "setState",
};

export const reducer = (reducerState, action) => {
    const { state, type, callback = () => {} } = action;

    callback();
    switch (type) {
        case ACTION.SET_STATE:
            return { ...reducerState, ...state };
        default:
            throw new Error("Untyped dispatch call");
    }
};
