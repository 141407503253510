import {isChartPaused, pauseChart, unpauseChart} from "../../../../../helpers/holdMode";
import {useEffect} from "react";

export const useChartHoldEffect = (chartRef, cursor = null, lockPoints = null) => {
    const handleKeyDown = (e) => {
        if (!chartRef.current) {
            return;
        }

        if (e.keyCode === 72) {
            // H
            if (isChartPaused(chartRef.current)) {
                unpauseChart(chartRef.current);
                return;
            }
            pauseChart(chartRef.current, cursor, lockPoints);
        }
    };

    useEffect(() => {
        document.addEventListener("keyup", handleKeyDown);

        return () => {
            document.removeEventListener("keyup", handleKeyDown);
        };
    }, [cursor, lockPoints]);
};
