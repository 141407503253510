import React, {Component} from "react";
import PropTypes from "prop-types";
import ActionListApi from "../../../../api/actionList";
import {
    get as _get,
    set as _set,
} from "lodash";
import CompletionHeader from "./completion/header";
import CompletionFooter from "./completion/footer";
import CompletionForm from "./completion/form";
import FormValidator from "../../../../helpers/form-validator";
import ActionItemHelper from "../../../../helpers/action-item";

class CompletionView extends Component
{
    constructor(props) {
        super(props);

        const rulesWithoutReport = [
            {
                field: "description",
                method: description => !!description.replace(/<[^>]*>/gi, "").replace(/[\s?&nbsp;]+/, "").length,
                validWhen: true,
                message: "Description cannot be empty."
            },
            {
                field: "action",
                method: () => {
                    return this.state.withActions || this.state.noActions;
                },
                validWhen: true,
                message: "You must select one of the check boxes."
            },
        ];

        const rulesWithReport = [
            {
                field: "action",
                method: () => {
                    return this.state.withActions || this.state.noActions;
                },
                validWhen: true,
                message: "You must select one of the check boxes."
            },
            {
                field: "text",
                method: description => !!description.replace(/<[^>]*>/gi, "").replace(/[\s?&nbsp;]+/, "").length,
                validWhen: true,
                message: "Description cannot be empty."
            },
            {
                field: "ww_action_list_reason_group_id",
                method: "isEmpty",
                validWhen: false,
                message: "The Reason field is required."
            },
            {
                field: "ww_action_list_other_reason_group_text",
                method: "isEmpty",
                validWhen: false,
                skipOnEmpty: () => +this.state.dataWithReport.ww_action_list_reason_group_id !== ActionItemHelper.getOtherReasonTypeId(props.info),
                message: "The Other description field is required."
            },
            {
                field: "severity",
                method: "isEmpty",
                validWhen: false,
                message: "The Severity field is required."
            },
        ];

        this.validatorWithoutReport = new FormValidator(rulesWithoutReport);
        this.validatorWithReport = new FormValidator(rulesWithReport);

        const report = _get(props.actionItem, "wwReports.0");

        this.state = {
            dataWithoutReport: {
                action_item_id: _get(props, "actionItem.id"),
                description: _get(props.actionItem, "no_actions_description", ""),
                action: ""
            },
            dataWithReport: {
                action_item_id: _get(props, "actionItem.id"),
                report_id: _get(report, "id", null),
                is_resubmit_report: _get(report, "id", null),
                text: _get(report, "text"),
                // temp
                ww_action_list_reason_group_id: _get(report, "ww_action_list_reason_group_id", 16),
                ww_action_list_other_reason_group_text: _get(report, "ww_action_list_other_reason_group_text", "temp"),
                severity: _get(report, "severity", 1),
                //
                file_name: _get(report, "file", []) || [],
                action: ""
            },
            withActions: !!report,
            noActions: !report && +_get(props.actionItem, "is_no_actions") === 1,
            validation: {},
            inProgress: false
        };
    }

    changeNoActions = () => {
        const {noActions, dataWithReport, dataWithoutReport} = this.state;
        this.setState({
            noActions: !noActions,
            withActions: false,
            dataWithoutReport: {...dataWithoutReport, description: dataWithReport.text}
        }, () => {
            this.setState({validation: this.validate("action")});
        });
    }

    changeWithActions = () => {
        const {withActions, dataWithoutReport, dataWithReport} = this.state;
        this.setState({
            withActions: !withActions,
            noActions: false,
            dataWithReport: {...dataWithReport, text: dataWithoutReport.description}
        }, () => {
            this.setState({validation: this.validate("action")});
        });
    }

    onChangeDescription = (description) => {
        let {dataWithoutReport, dataWithReport, withActions} = this.state;

        _set(dataWithoutReport, "description", description);
        _set(dataWithReport, "text", description);

        this.setState({dataWithoutReport, dataWithReport}, () => {
            this.setState({validation: this.validate(withActions ?  "text" : "description")});
        });
    }

    onChangeDataWithReport = (event) => {
        const key = _get(event, "target.name");
        const value = _get(event, "target.value");
        let dataWithReport = {...this.state.dataWithReport};

        _set(dataWithReport, key, value);

        this.setState({dataWithReport}, () => {
            this.setState({validation: this.validate(key)});
        });
    }

    validate = (fieldName = undefined) => {
        const {withActions, dataWithoutReport, dataWithReport} = this.state;

        return withActions
            ? this.validatorWithReport.validate(dataWithReport, fieldName)
            : this.validatorWithoutReport.validate(dataWithoutReport, fieldName);
    }

    onSubmit = () => {
        const {noActions} = this.state;

        this.setState(
            {inProgress: true},
            () => {
                const validation = this.validate();
                this.setState({validation}, () => {
                    if (validation.isValid) {
                        noActions ? this.submitWithoutActions() : this.submitReport();
                    } else {
                        this.setState({inProgress: false});
                    }
                });
            }
        );
    }

    submitWithoutActions = () => {
        const {dataWithoutReport} = this.state;
        const {onSubmittedReport} = this.props;

        ActionListApi
            .saveWithoutReport(dataWithoutReport)
            .then((response) => {
                if (response.status === "ok") {
                    onSubmittedReport();
                }
            });
    }

    submitReport = () => {
        const {dataWithReport} = this.state;
        const {onSubmittedReport} = this.props;

        ActionListApi
            .saveReport(dataWithReport)
            .then((response) => {
                if (response.status === "ok") {
                    onSubmittedReport();
                }
            });
    }

    render() {
        const {dataWithoutReport, noActions, withActions, dataWithReport, validation, inProgress} = this.state;
        const {onCancelCompletion, actionItem, info} = this.props;

        return (
            <React.Fragment>
                <div className={"completion"}>
                    <CompletionHeader onCancelCompletion={onCancelCompletion} />

                    <CompletionForm
                        actionItem={actionItem}
                        info={info}
                        noActions={noActions}
                        dataWithoutReport={dataWithoutReport}
                        withActions={withActions}
                        dataWithReport={dataWithReport}
                        changeNoActions={this.changeNoActions}
                        changeWithActions={this.changeWithActions}
                        onChangeDescription={this.onChangeDescription}
                        onChangeDataWithReport={this.onChangeDataWithReport}
                        validation={validation}
                    />

                    <CompletionFooter onSubmit={this.onSubmit} onCancelCompletion={onCancelCompletion} inProgress={inProgress}/>
                </div>
            </React.Fragment>
        );
    }
}

CompletionView.propTypes = {
    actionItem: PropTypes.object,
    info: PropTypes.object,
    history: PropTypes.object,
    onCancelCompletion: PropTypes.func,
    onSubmittedReport: PropTypes.func
};

export default CompletionView;