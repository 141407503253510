import Api from "./api";

const Equipment = {
    get: id => Api.get("equipment/" + id),
    clone: (body) => Api.post("equipment/clone" , body),
    create: (body, type) => {
        if (type) {
            return Api.post("equipment/create" , body, {query: {type}});
        }
        return Api.post("equipment/create" , body);
    },
    update: (equipmentId, body) => Api.post("equipment/update/" + equipmentId, body),
    attachImages: (id, body) => Api.post("equipment-image/attach-images/" + id, body),
    removeImage: (eqId, imgId) => Api.post("equipment-image/detach-image/" + eqId, {id: imgId}),
    flipImages: (eqId, flipTurns) => Api.post("equipment-image/flip-images/" + eqId, {flipTurns}),
    setMainImage: (equipmentId, imgId) => Api.post("equipment-image/set-main-image/" + equipmentId, {id: imgId}),
    setMarkerPosition: (equipmentId, positions) => Api.post(`equipment-image/set-marker-position/${equipmentId}`, {positions: positions}),
    assignImageInstallationPoint: (equipmentId, imageInstallationPoint) => Api.post("equipment-image/assign-installation-point/" + equipmentId, {imageInstallationPoint}),
    dashboardList: (data, signal) => Api.get("dashboard", {query: {...data}, signal: signal}),
    notes: (id, limit, offset) => Api.get(`equipment/${id}/notes?limit=${limit||3}&offset=${offset||0}`),
    getTypes: () => Api.get("equipment/get-types"),
    searchTachometers: (query) => Api.get("equipment/search-tachometers", {query}),
    countNotes: id => Api.get("notes/count", {query: {equipment_id: id}}),
    delete: id => Api.delete("equipment/delete/" + id),
    changeSpeed: (id, data) => Api.post(`equipment/${id}/change-speed`, data),
    checkService: equipmentId => Api.get("equipment/check-service/" + equipmentId),
    notesCount: (id) => Api.get(`equipment/${id}/notes-count`),
    createNameplate: (equipmentId, nameplateId) => Api.post(`equipment/${equipmentId}/nameplate/${nameplateId}`),
    deleteNameplate: (equipmentId, nameplateId) => Api.delete(`equipment/${equipmentId}/nameplate/${nameplateId}`),
    customChartTypes: (id) => Api.get(`equipment/${id}/custom-chart-types`),
    universalAdaptersList: (id) => Api.get(`equipment/${id}/universal-adapter`),
    equipmentIdsByLocation: (query) => Api.get("equipment/equipment-ids-by-location", {query}),
};

export default Equipment;
