import { useContext, useMemo } from "react";
import Helper from "../../../../../helpers/helper";
import { useLocation } from "react-router";
import { ChartContext } from "../context/context";
import { useChartStateStoreContext } from "../store/hooks/useStoreContext";
import { useUserViewChartType } from "../../../../../stores/UserLocalSettingsStore";

export const isChartImpactVue = (chartType) =>
    useMemo(() => chartType === 21 || chartType === 22, [chartType]);

export const useSearchParams = () => {
    const location = useLocation();
    return useMemo(() => Helper.getHashParams(), [location]);
};
export const useIsDarkTheme = () => useMemo(() => localStorage.getItem("lightMode"), []);

export const useChartContext = () => useContext(ChartContext);

export const useChartHeight = () => {
    const fullScreenMode = useChartStateStoreContext((state) => state.settings.fullScreenMode);
    const viewChartType = useUserViewChartType();

    return useMemo(() => {
        return fullScreenMode
            ? window.innerHeight - 250
            : viewChartType === "blocks"
                ? 270
                : window.innerHeight * 0.5;
    }, [fullScreenMode, viewChartType]);
};
