import React from "react";
import PropTypes from "prop-types";
import Creatable from "react-select/creatable/dist/react-select.esm";
import {
    find as _find,
} from "lodash";
import InfoTooltip from "../infoTooltip/infoTooltip";
import {ValidationError} from "../index";
import useEquipmentTypes from "../../hooks/api/equipment/useEquipmentTypes";
import useAddEquipmentType from "../../hooks/api/equipment/useAddEquipmentType";
import { useIsDarkTheme } from "../../hooks/helpers/theme";

const selectStyles = {
    control: (provided) => ({
        ...provided,
        height: 32,
        minHeight: 32
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: "100000"
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#9E9E9E",
    }),
    option: (provided) => ({
        ...provided,
        padding: "0 7px"
    }),
    input: (provided) => ({
        ...provided,
        border: "0px !important"
    })
};

const darkModeSelectStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: "rgb(56, 56, 46)",
        height: 32,
        minHeight: 32
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: "100000"
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#ccc"
    }),
    singleValueLabel: (provided) => ({
        ...provided,
        color: "#ccc"
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#36342a"
    }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor:  isFocused
                ? "#777"
                : null,
            padding: "0 7px"
        };
    },
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    input: (provided) => ({
        ...provided,
        color: "#ccc"
    })
};

const propTypes = {
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    isValid: PropTypes.bool,
    validationError: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
};

const EquipmentTypeSelector = ({name, value, onChange, disabled, isValid = true, validationError = "", isRequired = false}) => {
    const {data: list, isSuccess} = useEquipmentTypes();
    const {mutateAsync: addEquipmentType, isLoading: isMutating} = useAddEquipmentType();

    const isDarkTheme = useIsDarkTheme();

    const onChangeSelect = (target, event) => {
        if (event.action === "create-option") {
            addEquipmentType(target.value).then((res) => {
                updateEquipmentTypes(event.name, res.result.id);
            });
        }
        if (event.action === "select-option") {
            updateEquipmentTypes(event.name, target.value);
        }
        if (event.action === "clear") {
            updateEquipmentTypes(event.name, "");
        }
    };

    const updateEquipmentTypes = (name, value) => {
        onChange({
            target: {
                name: name,
                value: value
            }
        });
    };

    return (
        <div className={"form-group"}>
            <label className="mr-1">
                <React.Fragment>
                    Equipment Type
                    {isRequired && <span className="color-danger">*</span>}
                </React.Fragment>
            </label>
            <InfoTooltip iconClass="blue-tooltip">
                Enter the equipment type for this asset (i.e. Fan, Pump, Conveyor, Mill, Compressor, etc.).
            </InfoTooltip>
            <Creatable
                classNamePrefix="react-select"
                className={"react-select"  + (isValid ? "" : " is-invalid")}
                name={name}
                value={_find(list, {"value": value})}
                options={list}
                isClearable={true}
                styles={ isDarkTheme === "true" ? darkModeSelectStyles : selectStyles }
                placehoder={"Select Equipment Type"}
                onChange={onChangeSelect}
                closeMenuOnScroll={(e) => e && e.target && e.target.classList && e.target.classList.contains("custom-scroll")}
                menuPortalTarget={document.body}
                menuPlacement={"auto"}
                formatCreateLabel={(label) => "Create \"" + label + "\" (press enter to create new type)"}
                isDisabled={disabled || !isSuccess || isMutating}
            />
            {!isValid && <ValidationError message={validationError}/>}
        </div>
    );
};

EquipmentTypeSelector.propTypes = propTypes;
export default EquipmentTypeSelector;