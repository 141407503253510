import {get as _get, cloneDeep as _cloneDeep} from "lodash";
import {removeChartNavigation} from "../../../helpers/removeChartNavigation";
import {CHART_CONFIG} from "../../../constants/chart";
import {usePacketLossQuery} from "../../../hooks/api/packetLoss/usePacketLossQuery";
import {useMemo} from "react";
import {useIsDarkTheme} from "../../../hooks/helpers/theme";
import {setDarkModeForChart} from "../../../helpers/setDarkModeForChart";
import useUserProfile, {profileUserSelector} from "../../../hooks/api/Global/useUserProfile";
import Helper from "../../../helpers/helper";
const baseOptions = removeChartNavigation(_cloneDeep(CHART_CONFIG));

export const usePacketLossTooltip = (serial, type, timeRange, dateRange) => {
    const {data: response, isSuccess} = usePacketLossQuery(serial, type, timeRange, dateRange);

    const isDarkMode = useIsDarkTheme();

    const {data: user} = useUserProfile(profileUserSelector);

    return useMemo(() => {
        if (!isSuccess) {
            return {};
        }
        let baseOptionsLocal = baseOptions;
        if (isDarkMode === "true") {
            baseOptionsLocal = setDarkModeForChart(baseOptions);
        }

        return {
            ...baseOptionsLocal.tooltip,
            enabled: true,
            formatter() {
                const data = _get(response.charts_data_info, this.x);
                const totalPackets = parseInt(data.totalPackets) + parseInt(data.packetsLost);
                const receivedPackets = data.totalPackets;

                return (
                    Helper.dateToUserFormat(this.x, user) +
                    `<br/> <span class='tooltip-icon'>●</span> ${receivedPackets}/${totalPackets} packets, ${this.y} % loss`
                );
            },
        };
    }, [isSuccess, user, response]);
};
