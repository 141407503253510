import {useQuery} from "@tanstack/react-query";
import AxisLabelApi from "../../../api/axisLabel";

const useAxisLabelQuery = (equipmentId) => {
    const {isSuccess, data: axisLabelsChart} = useQuery(
        ["AxisLabelQuery", equipmentId],
        () => AxisLabelApi.get(+equipmentId, (response) => response.data),
        {
            enabled: !!equipmentId,
            select: (res) => res.data,
        }
    );

    return {
        isSuccess,
        axisLabelsChart,
    };
};

export default useAxisLabelQuery;
