import React, {useEffect, useMemo, useRef, useState} from "react";
import {intersectionBy as _intersectionBy, filter as _filter, get as _get} from "lodash";
import {MTIME_TWO_MONTH, useBottomSettingsRangeSelector, useChartSelectedRangeStoreActions} from "../../../stores/zustand/ChartSelectedRangeStore";
import {useChartSelectedChartTypeStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import Toast from "../../shared/toast";
import {useIsShowFFTAcquisitionsTime} from "../highcharts/store/sync/fftAcquisitionsTimeStore";
import DatePicker from "react-datepicker";
import "./chartsDateRange.scss";
import PropTypes from "prop-types";
import Helper from "../../../helpers/helper";
import moment from "moment";
import {useCurrentAlertActions} from "../../../stores/CurrentAlertStore";
import {useHistory} from "react-router";
import {useChartRangeWithAlert} from "../highcharts/hooks/useChartRangeWithAlert";

const RangeSelector = (props) => {
    const {user} = props;
    const {setSelectedRange} = useChartSelectedRangeStoreActions();
    const selectedChartTypes = useChartSelectedChartTypeStore();
    const rangeSelectorDate = useChartRangeWithAlert();
    const bottomSettingsRangeSelector = useBottomSettingsRangeSelector();
    const isShowFFTAcquisitionsTime = useIsShowFFTAcquisitionsTime();
    const {clearCurrentAlert} = useCurrentAlertActions();
    const displayFormat = Helper.getUserDateFormat(user, true).date;
    const defaultMaxDate = Helper.unixToMilliseconds(moment().locale(user.language_iso_code).utc().add(user.timezone, "hours").unix());
    const history = useHistory();
    const [maxDate, setMaxDate] = useState(defaultMaxDate);

    const [showModalRange, setShowModalRange] = useState(false);
    const dateRangeRef = useRef(null);
    const [dateRange, setDateRange] = useState({startDate: null, endDate: null});

    const handleClick = (event) => {
        if (dateRangeRef.current && !dateRangeRef.current.contains(event.target)) {
            setShowModalRange(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    useEffect(() => {
        setDateRange({
            startDate: +rangeSelectorDate.isCustom ? Helper.unixToMilliseconds(rangeSelectorDate.from) : null,
            endDate: +rangeSelectorDate.isCustom ? Helper.unixToMilliseconds(rangeSelectorDate.to) : null,
        });
    }, [rangeSelectorDate?.from, rangeSelectorDate?.to]);

    const resetAlert = () => {
        if (!Helper.getHashParams().alertId) {
            return;
        }

        clearCurrentAlert();
        history.push(Helper.deleteHashParams(["sensors", "alertId"]));
    };

    const updateSelectedRange = (time) => {
        if (time > MTIME_TWO_MONTH && isShowFFTAcquisitionsTime) {
            return Toast.info("A range of 2 months or less is required to display FFT acquisition times.");
        } else {
            setSelectedRange({
                range: time,
                from: null,
                to: null,
                isCustom: false,
            });
            setDateRange({startDate: null, endDate: null});
            setShowModalRange(false);
            setMaxDate(defaultMaxDate);

            resetAlert();
        }
    };

    const handleDataPicker = (update) => {
        const startDate = _get(update, 0);
        const endDate = _get(update, 1);
        setDateRange({startDate: startDate, endDate: endDate});

        if (startDate && !endDate) {
            const availableSixMonthsTimestamp = Helper.unixToMilliseconds(moment(startDate).locale(user.language_iso_code).utc().add(6, "months").unix());
            setMaxDate(availableSixMonthsTimestamp < defaultMaxDate ? availableSixMonthsTimestamp : defaultMaxDate);
        }

        if (startDate && endDate) {
            setSelectedRange({
                range: null,
                from: moment(startDate).utc().startOf("day").unix(),
                to: moment(endDate).utc().endOf("day").unix(),
                isCustom: true,
            });

            setTimeout(() => {
                setShowModalRange(false);
            }, 1500);
            setMaxDate(defaultMaxDate);

            resetAlert();
        }
    };

    const filtered = useMemo(() => {
        return _filter(selectedChartTypes.checked, (type) => {
            return +type > 34;
        });
    }, [selectedChartTypes]);

    const showRange = useMemo(() => !!(filtered || []).length, [filtered]);

    return (
        <div
            className="date-picker"
            ref={dateRangeRef}
        >
            {(_intersectionBy(selectedChartTypes.checked, ["1", "2", "3", "4", "5", "6", "14"]).length > 0 || showRange) && (
                <div className="btn-group btn-group-sm dropdown">
                    {bottomSettingsRangeSelector.map((item) => (
                        <div
                            key={item.text}
                            onClick={() => updateSelectedRange(item.mtime)}
                            className={
                                item.mtime === rangeSelectorDate.range && !rangeSelectorDate.isCustom ? "date-picker-item current" : "date-picker-item"
                            }
                        >
                            {item.text}
                        </div>
                    ))}
                    <div
                        key={"custom-range"}
                        onClick={() => setShowModalRange(!showModalRange)}
                        className={"date-picker-item " + (rangeSelectorDate.isCustom ? "current" : "")}
                    >
                        Custom Range
                    </div>

                    <div className={"customer-range-block"}>
                        <div className={"date-picker-block " + (showModalRange ? "show-date-picker-block" : "")}>
                            <div className={"selected-date-range"}>
                                <DatePicker
                                    selectsRange={true}
                                    disabled={true}
                                    startDate={dateRange.startDate}
                                    endDate={dateRange.endDate}
                                    placeholderText={"From Date - To Date"}
                                    dateFormat={displayFormat === "DD/MM/YY" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                                    customInput={<input className={"form-control selected-input-date-range"} />}
                                />
                            </div>
                            <div className={"choosing-dates"}>
                                <DatePicker
                                    selectsRange={true}
                                    startDate={dateRange.startDate}
                                    selected={dateRange.startDate !== null ? dateRange.startDate : maxDate}
                                    endDate={dateRange.endDate}
                                    showOtherMonths
                                    onChange={(update) => {
                                        handleDataPicker(update);
                                    }}
                                    maxDate={maxDate}
                                    minDate={Helper.unixToMilliseconds(moment().utc().add(-2, "years").unix())}
                                    portalPosition={null}
                                    inline={true}
                                    selectsDisabledDaysInRange={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

RangeSelector.propTypes = {
    user: PropTypes.object,
};

export default RangeSelector;
