import React from "react";
import { useUserLocalSettingsStoreActions, useUserViewChartType } from "../../../../stores/UserLocalSettingsStore";

const SwitchBlock = () => {
    const viewChartType = useUserViewChartType();
    const {setViewChartType} = useUserLocalSettingsStoreActions();
    return (
        <div className="right-equipment-block">
            <div className="change-list-view">
                <i
                    title={"Two Column View"}
                    className={`block-visibility fa fa-th-large fa-2x ${
                        viewChartType === "blocks" ? "active" : ""
                    }`}
                    onClick={() => setViewChartType("blocks")}
                    aria-hidden="true"
                />
                <i
                    title={"Single Column View"}
                    className={`block-visibility fa fa-list fa-2x ${
                        viewChartType === "list" ? "active" : ""
                    }`}
                    onClick={() => setViewChartType("list")}
                    aria-hidden="true"
                />
            </div>
        </div>
    );
};

export default SwitchBlock;
