import React, {useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {isUndefined, isObject, isArray, find as _find, get as _get, isNull, isEmpty, toString} from "lodash";

const SelectWrapper = ({children, ...props}) => {
    const [newProps, setNewProps] = useState({});
    const [selectedOpt, setSelectedOpt] = useState({});

    const ref = useRef(null);

    const generateOptions = (child, storage = []) => {
        for (let i in child) {
            let iterableOpt = child[i];
            let tmpOptData = {};
            if (isObject(iterableOpt)) {
                if (isObject(iterableOpt.props)) {
                    for (let key in iterableOpt.props) {
                        switch (key) {
                            case "value":
                                tmpOptData.value = iterableOpt.props[key];
                                break;
                            case "children":
                                tmpOptData.label = iterableOpt.props[key];
                                break;
                            case "disabled":
                                tmpOptData.isDisabled = iterableOpt.props[key];
                                break;
                            case "selected":
                                tmpOptData.isSelected = iterableOpt.props[key];
                                break;
                        }
                    }
                    storage.push(tmpOptData);
                }
            }
            if (isArray(iterableOpt)) {
                storage.concat(generateOptions(iterableOpt, storage));
            }
        }
        return storage;
    };

    const options = useMemo(() => generateOptions(children), [children]);

    const data = useMemo(() => {
        return {value: props.value, defaultValue: props.defaultValue};
    }, [props.value, props.defaultValue]);

    useEffect(() => {
        let tmpNewProps = {};
        if (!isUndefined(props.disabled)) {
            tmpNewProps.isDisabled = props.disabled;
        }

        if (!isNull(_get(props, ["maxMenuHeight"], null))) {
            tmpNewProps.maxMenuHeight = _get(props, ["maxMenuHeight"]);
        }
        if (!isNull(_get(props, ["placeholder"], null))) {
            tmpNewProps.placeholder = _get(props, ["placeholder"]);
        }

        if (!isNull(_get(props, ["menuPosition"], null))) {
            tmpNewProps.menuPosition = _get(props, ["menuPosition"]);
        }

        tmpNewProps.styles = {
            ...tmpNewProps.styles,
            control: (provided) => ({
                ...provided,
                width: _get(props.style, ["width"], "auto"),
            }),
            container: (styles) => ({
                ...styles,
                display: _get(props.style, ["display"], "block"),
            }),
        };
        if (newProps !== tmpNewProps) {
            setNewProps(tmpNewProps);
        }
    }, [props.style, props.disabled]);

    useEffect(() => {
        let val = _get(data, ["value"], _get(data, ["defaultValue"], null));
        let findOpt = _find(options, {value: val}) || _find(options, {value: toString(val)});
        if (isNull(val)) {
            if (isEmpty(selectedOpt)) {
                if (isNull(_get(props, ["placeholder"], null))) {
                    setSelectedOpt(options[0]);
                } else {
                    setSelectedOpt(null);
                }
            }
        } else {
            if (findOpt) {
                setSelectedOpt(findOpt);
            } else {
                if (isNull(_get(props, ["placeholder"], null))) {
                    setSelectedOpt(options[0]);
                } else {
                    setSelectedOpt(null);
                }
            }
        }
    }, [data, options]);

    return (
        <React.Fragment>
            <Select
                {...newProps}
                name={_get(props, ["name"])}
                ref={ref}
                options={options}
                value={selectedOpt}
                onChange={(val) => {
                    setSelectedOpt(val);
                    if (!isNull(val) && !isNull(_get(props, ["onChange"], null))) {
                        props.onChange({
                            value: val.value,
                            label: val.label,
                            tagName: "INPUT",
                            type: "INPUT",
                            name: _get(props, ["name"]),
                            target: {
                                value: val.value,
                                label: val.label,
                                tagName: "INPUT",
                                type: "INPUT",
                                name: _get(props, ["name"]),
                            },
                        });
                    }
                }}
                isSearchable={false}
                className={"react-select " + _get(props, ["className"], "")}
                classNamePrefix={"react-select"}
                menuPortalTarget={_get(props, "menuPortalTarget", document.body)}
            />
        </React.Fragment>
    );
};

SelectWrapper.propTypes = {
    disabled: PropTypes.bool,
    className: PropTypes.any,
    style: PropTypes.any,
    children: PropTypes.any,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    menuPortalTarget: PropTypes.any,
    menuPosition: PropTypes.any,
};
export default SelectWrapper;
