import React, {Component} from "react";
import PropTypes from "prop-types";
import {Loader, Modal} from "../../../../shared";
import ParametersTable from "./table";
import ApiReadingTypes from "../../../../api/readingTypes";

class ParametersModal extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            createNew: false,
            loader: true,
            parametersList: []
        };
    }

    componentDidMount() {
        this.updateParameterList();
    }

    updateParameterList = () => {
        return ApiReadingTypes
            .customList()
            .then((response) => {
                this.setState({
                    loader: false,
                    parametersList: response.list || [],
                });
            });
    }

    onCreateNew = () => {
        this.setState({createNew: true});
    }

    onCancel = () => {
        this.setState({createNew: false});
    }

    getModalHeader = () => {
        const {createNew} = this.state;

        return createNew ? <React.Fragment /> : <ModalHeader onCreateNew={this.onCreateNew}/>;
    }

    render() {
        const {createNew, parametersList, loader} = this.state;
        const {onClose} = this.props;

        return (
            <React.Fragment>
                <Modal
                    showModal={true}
                    className={"adapter-parameters-modal"}
                    title={"Add Reading Type"}
                    header={this.getModalHeader()}
                    size={"lg"}
                    onSubmit={this.onSubmit}
                    onClose={onClose}
                    withoutFooter={true}
                >
                    {loader
                        ?
                        <Loader/>
                        :
                        <ParametersTable
                            parameters={parametersList}
                            updateParameterList={this.updateParameterList}
                            createNew={createNew}
                            onCancelCreateNew={this.onCancel}
                        />
                    }

                </Modal>
            </React.Fragment>
        );
    }
}

const ModalHeader = ({onCreateNew}) => (
    <React.Fragment>
        <div className="add-new-link m-0 ml-2" onClick={onCreateNew}>
            <i className="fa fa-plus-circle"/> Add new parameter type
        </div>
    </React.Fragment>
);

ModalHeader.propTypes = {
    onCreateNew: PropTypes.func,
};

ParametersModal.propTypes = {
    showModal: PropTypes.bool,
    parameters: PropTypes.array,
    updateParameterList: PropTypes.func,
    onClose: PropTypes.func
};

export default ParametersModal;