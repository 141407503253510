import React, {Component} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../stores/GlobalStore";
import SearchInput from "../shared/search-input";
import {Loader, Pager} from "../../shared";
import ApiGlobalNotes from "../../api/globalNotes";
import Table from "./table";
import LimitSelect from "../shared/limit-select";
import {
    get as _get,
    findIndex as _findIndex,
    forEach as _forEach,
    find as _find,
    omit as _omit
} from "lodash";
import DatePickerInput from "../../shared/datePickerInput/datePickerInput";
import Helper from "../../helpers/helper";
import NoteModal from "../../modals/notes/components/view";
import ApiUser from "../../api/user";
import ApiNote from "../../api/note";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiChartMark from "../../api/chartMark";
import Moment from "moment";
import Toast from "../shared/toast";
import CollapseLocationSelect from "../../shared/collapseLocationSelect/collapseLocationSelect";
import SelectWrapper from "../../helpers/select-wrapper";
import { HeaderSimple } from "../../shared/header";
import {NOTES_TAB_DAY, NOTES_TAB_HALF_YEAR, NOTES_TAB_MONTH, NOTES_TAB_YEAR} from "../network/constants/constans";

const dateFilters = {
    [NOTES_TAB_DAY]: "Last Day",
    [NOTES_TAB_MONTH]: "Last Month",
    [NOTES_TAB_HALF_YEAR]: "6 Month",
    [NOTES_TAB_YEAR]: "Year"
};

class GlobalNotes extends Component
{
    constructor(props) {
        super(props);

        const date = new Date();
        const fromDate = new Date(date.getFullYear(), date.getMonth() - 24, date.getDate());
        const toDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        const equipmentId = Helper.getHashParams().equipment_id;
        const equipment = Helper.getHashParams().modal === "notes" && equipmentId ? equipmentId : "";

        this.state = {
            breadcrumbs: [{name: "Notes"}],
            loader: true,
            list: [],
            pagination: {
                page: 1,
                pages: 1,
                perpage: 10,
                total: 0
            },
            sort: {
                field: "",
                sort: "asc"
            },
            filter: {
                query: "",
                equipment: equipment,
                location: "",
                user: "",
                fromDate: Moment(fromDate).format(Helper.getUserDateFormat(props.user, true).date),
                toDate: Moment(toDate).format(Helper.getUserDateFormat(props.user, true).date),
                notViewed: "0"
            },
            filterOptions: {
                users: [],
                equipments: [],
                locations: []
            },
            filteredEquipments: [],
            filterDate: NOTES_TAB_YEAR,
            showNoteModal: false,
            selectsListUsers: [],
            selectsListEquipments: [],
            globalSearchString: "",
            deleteNoteId: null,
            usersList: [],
            formErrors: {},
            countUnread: 0
        };

        this.finished = false;

        this.handleFetch = this.handleFetch.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handlePagerChange = this.handlePagerChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
    }

    componentDidMount() {
        Promise.all([
            this.handleSelectsList(),
            this.handleSelfUsers()
        ]).then(() => {
            this.handleFetch();
        });
    }

    handleFetch() {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        return ApiGlobalNotes.getList({
            query: {
                sort: this.state.sort,
                filter: this.getFilter(),
                pagination: this.state.pagination
            },
            signal: this.signal
        }).then(response => {
            if (response) {
                const {list = [], meta = {}} = response;
                this.setState({
                    loader: false,
                    pagination: Object.assign({}, this.state.pagination, meta),
                    list,
                    formErrors: {}
                }, () => {
                    if (!this.finished) {
                        this.finished = true;
                    }
                });
                this.handleCountUnread();
            }
        });
    }

    getFilter = () => {
        let filter = {...this.state.filter};
        const userDateFormat = Helper.getUserDateFormat(this.props.user, true).date;

        if (userDateFormat === "DD/MM/YY") {
            filter.fromDate = this.formatDate(filter.fromDate);
            filter.toDate = this.formatDate(filter.toDate);
        }

        return filter;
    }

    formatDate = (date) => {
        const dateArr = date.split("/");
        return dateArr[1] + "/" + dateArr[0] + "/" + dateArr[2];
    }

    handleSelectsList() {
        return ApiGlobalNotes
            .getSelectsList()
            .then((responseList = {}) => {
                if (responseList) {
                    this.setState({
                        selectsListEquipments: _get(responseList, "list.equipments", []),
                        filteredEquipments: _get(responseList, "list.equipments", [])
                    });
                }
            });
    }

    handleCountUnread() {
        return ApiGlobalNotes
            .getCount({query: {
                filter: this.getFilter()
                },
            }).then(({count}) => {
                this.setState({countUnread: count});
            });
    }

    handleSelfUsers = () => {
        return ApiUser
            .getSelfUsers({
                query: {
                    filter: {status: 1},
                    excludeWCDUsers: 1
                },
            })
            .then((responseUsers) => {
                if (responseUsers) {
                    let usersSelect = [];

                    _forEach(responseUsers.users, function (user) {
                        usersSelect.push({
                            name: user.name_first + " " + user.name_last,
                            value: user.id
                        });
                    });
                    this.setState({
                        selectsListUsers: usersSelect,
                        usersList: responseUsers.users || [],
                    });
                }
            });
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    handleSortChange(field = "") {
        const {sort} = this.state;

        this.setState({
            sort: Object.assign({}, {
                field,
                sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"
            })
        }, this.handleFetch);
    }

    handleLimitChange = e => {
        const {value} = e.target;
        this.setState({
            loader: true,
            pagination: {
                ...this.state.pagination,
                page: 1,
                perpage: +value
            }
        }, this.handleFetch);
    }

    handlePagerChange(page = 1) {
        this.setState({
            pagination: Object.assign({}, this.state.pagination, {page}),
            loader: true
        }, this.handleFetch);
    }

    handleSearch = (value) => {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }

        this.handleFilterChange({query: value});
    }

    handleFilterChange(obj = {}) {
        this.finished = false;
        this.setState({
            loader: true,
            filter: Object.assign({}, this.state.filter, obj),
            pagination: Object.assign({}, this.state.pagination, {page: 1})
        }, this.handleFetch);
    }

    changeFilter = (values, facilityId) => {
        const filterName = Object.keys(values)[0];
        const {selectsListEquipments} = this.state;
        const filterLocation = this.state.filter.location;
        let newFilteredEquipments = [...this.state.filteredEquipments];

        if (!filterLocation) {
            newFilteredEquipments = [...selectsListEquipments];
        }

        if (filterName === "equipment") {
            const index = selectsListEquipments.findIndex(item => +item.value === +values[filterName]);

            if (index > -1) {
                values = Object.assign({}, values, {location: selectsListEquipments[index].location_id});
            } else {
                values = Object.assign({}, values, {location: ""});
            }
        }

        if (filterName === "location") {
            newFilteredEquipments = selectsListEquipments.filter(item => {
                if (facilityId) {
                    return +item.facility_id === +facilityId;
                } else {
                    return +item.location_id === +values[filterName];
                }
            });

            values = Object.assign({}, values, {equipment: Helper.getHashParams().equipment_id || ""});
        }

        this.finished = false;
        this.setState({
            loader: true,
            pagination: {...this.state.pagination, page: 1},
            filter: {...this.state.filter, ...values},
            filteredEquipments: newFilteredEquipments
        }, this.handleFetch);
    };

    filtersApplied = () => {
        return (!this.props.isModal && this.state.filter.equipment !== "")
            || this.state.filter.location !== ""
            || this.state.filter.user !== "";
    };

    reset = () => {
        const {selectsListEquipments} = this.state;

        this.finished = false;

        this.setState({
            pagination: {...this.state.pagination, page: 1},
            filter: {
                ...this.state.filter,
                ...{
                    equipment: this.props.isModal ? this.state.filter.equipment : "",
                    location: "",
                    user: ""
                }},
            filteredEquipments: [...selectsListEquipments]
        }, this.handleFetch);
    };

    changeCurrentDateFrom = currentDate => {
        const filterNew = {
            fromDate: currentDate
        };

        this.setState({
            pagination: {...this.state.pagination, page: 1},
            filter: {...this.state.filter, ...filterNew},
            filterDate: null
        }, this.handleFetch);
    }

    changeCurrentDateTo = currentDate => {
        const filterNew = {
            toDate: currentDate
        };

        this.setState({
            pagination: {...this.state.pagination, page: 1},
            filter: {...this.state.filter, ...filterNew},
            filterDate: null
        }, this.handleFetch);
    }

    changeDateRange = type => {
        const filter = this.getFilterDateByType(type);

        this.setState({
            pagination: {...this.state.pagination, page: 1},
            filter: {...this.state.filter, ...filter},
            filterDate: type
        }, this.handleFetch);
    }

    getFilterDateByType = (type) => {
        const date = new Date();
        let fromDate = null;
        let toDate = null;

        switch (type) {
            case NOTES_TAB_YEAR:
                fromDate = new Date(date.getFullYear(), date.getMonth() - 12, date.getDate());
                toDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                break;
            case NOTES_TAB_HALF_YEAR:
                fromDate = new Date(date.getFullYear(), date.getMonth() - 6, date.getDate());
                toDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                break;
            case NOTES_TAB_DAY:
                fromDate = date;
                toDate = date;
                break;
            default:
                fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
                toDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        }

        return  {
            fromDate: Moment(fromDate).format(Helper.getUserDateFormat(this.props.user, true).date),
            toDate: Moment(toDate).format(Helper.getUserDateFormat(this.props.user, true).date)
        };
    }

    showNoteModal = () => {
        this.setState({
            showNoteModal: true,
            formErrors: {}
        });
    }

    closeNoteModal = () => {
        this.setState({
            showNoteModal: false,
            formErrors: {}
        });
    }

    setViewed = (note, callback = null) => {
        const {updateCountGlobalNotes, updateEquipmentNotesCount} = this.props;
        const list = [...this.state.list];

        const index = _findIndex(this.state.list, function(o) { return +o.id === +note.id; });

        if (index !== -1) {
            ApiGlobalNotes
                .setViewed(note.id)
                .then(({status}) => {
                    if (status === "ok") {
                        note.viewed = true;
                        list[index] = note;
                        updateCountGlobalNotes();
                        if (Helper.getHashParams().modal === "notes") {
                            updateEquipmentNotesCount();
                        }
                        this.setState({list}, () => {
                            if (callback && typeof callback === "function") {
                                callback();
                            }
                        });
                    }
                });
        }
    }

    onSubmitNoteCreate = (data) => {
        const {updateCountGlobalNotes, updateEquipmentNotesCount} = this.props;

        this.setState({formErrors: {}});
        let formData = new FormData();
        formData.append("file", data.file);
        formData.append("text", data.text);
        formData.append("equipment_id", data.equipment_id);
        formData.append("emailUsers", JSON.stringify(data.emailUsers));

        ApiNote.create(formData)
            .then(({status, result}) => {
                if (status === "ok" && result) {
                    this.setState({formErrors: {}});
                    this.handleFetch();
                    updateCountGlobalNotes();

                    if (Helper.getHashParams().modal === "notes") {
                        updateEquipmentNotesCount();
                    }

                    this.closeNoteModal();
                    Toast.success("The note has been added.");
                }
            })
            .catch(resp => {
                if (resp.status === 422) {
                    this.setState({formErrors: resp.errors || {}});
                }
            });
    }

    removeErrors = (key = null) => {
        const oldFormErrors = {...this.state.formErrors};

        const formErrors = key ? _omit(oldFormErrors, key) : {};

        this.setState({formErrors});
    }

    onDeleteNote = (id) => {
        this.setState({deleteNoteId: id});
    }

    deleteNote = () => {
        const {updateCountGlobalNotes, updateEquipmentNotesCount} = this.props;
        const {list, deleteNoteId} = this.state;

        const note = _find(list, ["id", deleteNoteId]);

        if (note) {
            (note.note_type === "chart_mark" ? ApiChartMark.delete(note.equipment_id, note.note_entity_id) : ApiNote.delete(note.note_entity_id))
                .then(() => {
                    this.setState({
                        deleteNoteId: null,
                    }, () => {
                        this.handleFetch();
                        updateCountGlobalNotes();

                        if (Helper.getHashParams().modal === "notes") {
                            updateEquipmentNotesCount();
                        }
                    });
                });
        }
    }

    onSubmitNoteEdit = (editedNote) => {
        if (editedNote.note_type === "chart_mark") {
            return this.submitEditTypeChartMark(editedNote);
        } else {
            return this.submitEditTypeNote(editedNote);
        }
    }

    submitEditTypeNote = (editedNote) => {
        this.setState({formErrors: {}});

        let formData = new FormData();
        formData.append("file", editedNote.file);
        formData.append("text", editedNote.text);
        formData.append("equipment_id", editedNote.equipment_id);

        return ApiNote.update(editedNote.note_entity_id, formData)
            .then(({status}) => {
                if (status === "ok") {
                    this.setState({formErrors: {}});
                    this.handleFetch();
                    Toast.success("The note has been updated.");
                }
            })
            .catch(resp => {
                if (resp.status === 422) {
                    this.setState({formErrors: resp.errors || {}});
                }
            });
    }

    submitEditTypeChartMark = (editedNote) => {
        const data = {
            name: editedNote.name,
            notes: editedNote.text,
            timestamp: editedNote.timestamp,
            user_id: editedNote.user_id,
            equipment_id: editedNote.equipment_id
        };

        return ApiChartMark.update(editedNote.equipment_id, editedNote.note_entity_id, data).then(() => {
            this.handleFetch();
        });
    }

    toChartMark = (chartMarkId) => {
        const chartMark = _find(this.props.chartMarks, chartMark => +chartMark.id === +chartMarkId);

        if (chartMark) {
            this.props.updateRangeToChartMark(chartMark, true);
            this.props.onCloseModal();
        }
    }

    markAsViewed = () => {
        const {updateCountGlobalNotes, updateEquipmentNotesCount} = this.props;

        ApiGlobalNotes
            .markAsViewed()
            .then(() => {
                updateCountGlobalNotes();
                if (Helper.getHashParams().modal === "notes") {
                    updateEquipmentNotesCount();
                }
                this.handleFetch();
            });
    }

    render() {
        const {
            breadcrumbs,
            loader,
            list,
            sort,
            filter,
            pagination,
            filterDate,
            showNoteModal,
            selectsListEquipments,
            selectsListUsers,
            globalSearchString,
            deleteNoteId,
            usersList,
            filteredEquipments,
            formErrors,
            countUnread
        } = this.state;

        const {user, history, isModal, auth} = this.props;

        return (
            <div>
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className={"subheader" + (isModal ? " subheader--no-after mb-2" : "")}>
                    {!isModal &&
                        <div className="subheader-title">Notes</div>
                    }
                    {!!isModal &&
                        <div className={"d-inline-block"}>
                            <i className="fa fa-info-circle mr-1" />
                            Notes
                        </div>
                    }
                    {!!(!loader && auth.userCan("editAlarms")) &&
                        <div className="subheader-controls">
                            <div className="subheader-toolbar">
                                <div
                                    className="btn btn-primary btn-sm"
                                    onClick={this.showNoteModal}
                                >
                                    <i className="fa fa-plus"/> Add Note
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className={!isModal ? "block" : ""}>
                    <div className={isModal ? "block" : ""}>
                        <div className="block-header response-header">
                            <div className="block-label">
                                <div className="form-list form-list--row">
                                    {isModal &&
                                        <div className="form-group form-group--inline">
                                            <div className="form-label">
                                                <label>Asset Tree Branch:</label>
                                            </div>
                                            <CollapseLocationSelect
                                                className={"form-control form-control-sm"}
                                                selectName={"location"}
                                                value={+_get(filter, ["location"], 0)}
                                                onChange={(e, facilityId) => {
                                                    this.changeFilter({
                                                        location: _get(e, "target.value", "")
                                                    }, facilityId);
                                                }}
                                                emptyOptionLabel={"All"}
                                                needMarkFacility={true}
                                            />
                                        </div>
                                    }
                                    {((filteredEquipments || []).length > 0 && !isModal) &&
                                        <NotesFilter
                                            name="equipment"
                                            value={filter.equipment}
                                            label="Equipment"
                                            options={filteredEquipments}
                                            selected={this.changeFilter}
                                        />
                                    }
                                    {(selectsListUsers.length > 0) &&
                                        <NotesFilter
                                            name="user"
                                            value={filter.user}
                                            label="Users"
                                            options={selectsListUsers}
                                            selected={this.changeFilter}
                                        />
                                    }
                                    {this.filtersApplied() &&
                                        <div className="form-group">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={this.reset}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    }
                                </div>
                                <div className="form-list form-list--row">
                                    <div className="form-group form-group--inline mr-2">
                                        <label>Created:</label>
                                    </div>
                                    <div className="mr-2">
                                        <div className="date-picker">
                                            <div className="btn-group btn-group-sm dropdown">
                                                {Object.keys(dateFilters).map((key) =>
                                                    <div
                                                        key={key}
                                                        className={"date-picker-item" + (key === filterDate ? " current" : "")}
                                                        onClick={() => this.changeDateRange(key)}
                                                    >
                                                        {dateFilters[key]}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{width: "102px"}}>
                                        <DatePickerInput
                                            className="form-control"
                                            value={filter.fromDate}
                                            onChangeHandler={this.changeCurrentDateFrom}
                                            displayFormat={Helper.getUserDateFormat(user, true).date}
                                            valueFormat={Helper.getUserDateFormat(user, true).date}
                                            minDate={Moment().subtract(2, "year").format(Helper.getUserDateFormat(user, true).date)}
                                            maxDate={Moment().format(Helper.getUserDateFormat(user, true).date)}
                                            portalPosition={"date-picker-portal-modal"}
                                        />
                                    </div>
                                    <span className="mx-2">-</span>
                                    <div style={{width: "102px"}}>
                                        <DatePickerInput
                                            className="form-control"
                                            value={filter.toDate}
                                            onChangeHandler={this.changeCurrentDateTo}
                                            displayFormat={Helper.getUserDateFormat(user, true).date}
                                            valueFormat={Helper.getUserDateFormat(user, true).date}
                                            minDate={Moment().subtract(2, "year").format(Helper.getUserDateFormat(user, true).date)}
                                            maxDate={Moment().format(Helper.getUserDateFormat(user, true).date)}
                                            placement="top-end"
                                            portalPosition={"date-picker-portal-modal"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block-header">
                            <div className="block-label">
                                <div className="form-list form-list--row">
                                    <div className="form-group form-group--inline">
                                        <label className={"form-checkbox " + (countUnread ? "" : " disabled")}>
                                            <input
                                                type="checkbox"
                                                checked={filter.notViewed === "1"}
                                                disabled={!countUnread}
                                                onChange={(e) => this.changeFilter({notViewed: Helper.getInputValue(e.target)})}
                                            /> Only Unread ({countUnread})<span/>
                                        </label>
                                    </div>
                                    {auth.userCan("editAlarms") &&
                                        <button className={"link" + (countUnread ? " color-primary" : " text-muted disabled")} onClick={this.markAsViewed}>
                                            Mark all as read
                                        </button>
                                    }
                                </div>
                            </div>
                            <div id="top-pagination-block" className="block-pagination">
                                {pagination.total > 10 &&
                                <div className="limit-select-block">
                                    <LimitSelect
                                        name={"perpage"}
                                        onChange={this.handleLimitChange}
                                        defaultValue={pagination.perpage}
                                        limits={[10, 20, 50, 100]}
                                    />
                                </div>
                                }
                                {pagination.pages > 1 &&
                                <Pager
                                    page={pagination.page}
                                    pages={pagination.pages}
                                    perPage={pagination.perpage}
                                    onPagerChange={this.handlePagerChange}
                                />
                                }
                            </div>
                            <div className="block-toolbar">
                                <div className="block-toolbar-wrapper">
                                    <div style={{minWidth: "130px"}}>
                                        <SearchInput
                                            onChange={this.handleSearch}
                                            query={filter.query || ""}
                                            placeholder="Search Notes"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="block-body">
                        {loader
                            ?
                            <Loader/>
                            :
                            <div>
                                <Table
                                    list={list}
                                    sort={sort}
                                    query={filter.query}
                                    history={history}
                                    isModal={isModal}
                                    onSortChange={this.handleSortChange}
                                    setViewed={this.setViewed}
                                    onDeleteNote={this.onDeleteNote}
                                    onSubmitNoteEdit={this.onSubmitNoteEdit}
                                    toChartMark={this.toChartMark}
                                    formErrors={formErrors}
                                    removeErrors={this.removeErrors}
                                    auth={auth}
                                />
                            </div>
                        }
                    </div>
                </div>
                {!!showNoteModal &&
                <NoteModal
                    showModal={true}
                    formErrors={formErrors}
                    removeErrors={this.removeErrors}
                    usersList={usersList}
                    selectEquipment={selectsListEquipments}
                    isModal={isModal}
                    onClose={this.closeNoteModal}
                    onSubmit={this.onSubmitNoteCreate}
                />
                }
                {deleteNoteId &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title="DELETE NOTE"
                    onConfirm={this.deleteNote}
                    onCancel={() => this.onDeleteNote(null)}
                >
                    Are you sure you want to delete this note?
                </SweetAlert>
                }
            </div>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item global-search">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

const NotesFilter = ({name, value, label, options, selected}) => {
    return (
        <div className="form-group form-group--inline">
            <div className="form-label">
                <label>{label}:</label>
            </div>
            <SelectWrapper
                style={{textTransform: "capitalize", maxWidth: "200px", width: 150}}
                value={value}
                onChange={(e) => {
                    selected({
                        [name]: _get(e, "target.value", "")
                    });
                }}
                name={name}
            >
                <option value="">All</option>
                {options.map(option =>
                    <option key={"level-" + option.value} value={option.value}>{option.name}</option>
                )}
            </SelectWrapper>
        </div>
    );
};

NotesFilter.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    options: PropTypes.array,
    selected: PropTypes.func,
};

GlobalNotes.propTypes = {
    auth: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
    isModal: PropTypes.bool,
    updateCountGlobalNotes: PropTypes.func,
    updateEquipmentNotesCount: PropTypes.func,
    updateRangeToChartMark: PropTypes.func,
    chartMarks: PropTypes.array,
    onCloseModal: PropTypes.func
};

export default withGlobalStore(GlobalNotes);
