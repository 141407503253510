import ChartHelper from "../../../helpers/chart";

export const wrapHoverPointSelection = (Highcharts) => {
    Highcharts.wrap(Highcharts.Pointer.prototype, "getHoverData", function (proceed) {
        const result = proceed.apply(this, [].slice.call(arguments, 1));
        if (result.hoverPoint) {
            const chart = result.hoverPoint.series.chart;
            // Loop over all the series of the chart
            Highcharts.each(chart.series, function (series) {
                // This one already exist
                if (
                    series === result.hoverPoint.series ||
                    series.visible === false ||
                    series.options.enableMouseTracking === false ||
                    series.data.length === 0 ||
                    series.points.length === 0
                ) {
                    return;
                }

                const index = ChartHelper.getNearestPointIndex(series, result.hoverPoint.x);

                let current = series.points[index];

                // Add the closest point to the array
                if (result.hoverPoints.indexOf(current) === -1) {
                    result.hoverPoints.push(current);
                }
            });
            chart.updatedHoverPoints = result.hoverPoints;
        }

        return result;
    });
};
