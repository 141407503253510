import React, {Component} from "react";
import PropTypes from "prop-types";
import ApiAlarms from "../../api/alarm";
import ApiAxisLabel from "../../api/axisLabel";
import AlarmFilters from "./alarm-filters";
import AcknowledgeModal from "../../modals/alarms/acknowledge";
import IgnoreModal from "../../modals/alarms/ignore";
import {Loader, Pager} from "../../shared";
import Table from "./table";
import Helper from "../../helpers/helper";
import SearchInput from "../shared/search-input";
import LimitSelect from "../shared/limit-select";
import {withGlobalStore} from "../../stores/GlobalStore";
import Toast from "../shared/toast";
import {withLocationSelectStore} from "../../stores/LocationSelectStore";
import { HeaderSimple } from "../../shared/header";
import {ExportToCsv} from "export-to-csv";
import {
    get as _get,
} from "lodash";

class Alarms extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{name: "Active Alerts"}],
            loader: true,
            list: [],
            settingsStates: {},
            axisLabels: {},
            pagination: {
                page: 1,
                pages: 1,
                perpage: Helper.getPerPage("alarms"),
                total: 0
            },
            sort: {
                field: "",
                sort: "asc"
            },
            filter: {
                chartType: "",
                type: "",
                axis: "",
                level: "",
                query: "",
                location: props.getLocationId(),
                status: 0
            },
            levels: [],
            readingTypeList: [],
            globalSearchString: "",
            checkedAlarms: false,
            selected: [],
            excluded: [],
            acknowledgeFormErrors: {}
        };

        this.finished = false;

        this.handleFetch = this.handleFetch.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handlePagerChange = this.handlePagerChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleSubmitAcknowledgeAlarmModal = this.handleSubmitAcknowledgeAlarmModal.bind(this);
        this.handleSubmitIgnoreAlarmModal = this.handleSubmitIgnoreAlarmModal.bind(this);
        this.bindSearch = this.bindSearch.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
    }

    componentDidMount() {
        this.handleFetch();
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    handleFetch() {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        Promise.all([
            ApiAlarms.getList({
                query: {
                    sort: this.state.sort,
                    filter: this.state.filter,
                    pagination: this.state.pagination
                },
                signal: this.signal
            }),
            ApiAxisLabel.get()
        ]).then(([alarms, axisLabels]) => {
            if (alarms) {
                const {meta, list, settingsStates} = alarms;
                this.setState({
                    loader: false,
                    pagination: Object.assign({}, this.state.pagination, meta),
                    list,
                    settingsStates,
                    selected: [],
                    axisLabels: axisLabels.data || {},
                    checkedAlarms: false,
                }, () => {
                    if (!this.finished) {
                        this.finished = true;
                    }
                    this.props.history.push(Helper.deleteHashParams(["id", "modal", "selected"]));
                });
            }

        });
    }

    handleSortChange(field = "") {
        const {sort} = this.state;

        this.setState({
            sort: Object.assign({}, {
                field,
                sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"
            })
        }, this.handleFetch);
    }

    handlePagerChange(page = 1) {
        this.setState({loader: true, pagination: Object.assign({}, this.state.pagination, {page})}, this.handleFetch);
    }

    handleFilterChange(obj = {}) {
        this.finished = false;
        this.setState({
            pagination: Object.assign({}, this.state.pagination, {page: 1}),
            filter: {
                ...this.state.filter,
                ...obj,
            },
            loader: true
        }, () => {
            this.handleFetch();
        });
    }

    handleSubmitAcknowledgeAlarmModal(data) {
        const {selected} = Helper.getHashParams();
        const {checkedAlarms, filter, excluded} = this.state;

        if (selected || checkedAlarms === "full-all") {
            data["ids"] = checkedAlarms === "full-all" ? "full-all" : this.state.selected;
            data["filters"] = filter;
            data["excluded"] = excluded;
        }

        this.setState({
            loader: true,
        });

        return ApiAlarms
            .acknowledge(data)
            .then(() => {
                this.setState({checkedAlarms: false, selected: []}, this.handleFetch);
                this.props.history.push(Helper.deleteHashParams(["modalAlarm"]));
                this.props.updateCountActiveAlarms();
                Toast.success("The alert has been acknowledged.");
            })
            .catch(response => {
                if (response.status === 422) {
                    this.setState({acknowledgeFormErrors: response.errors || {}});
                }
            });
    }

    handleSubmitIgnoreAlarmModal(data) {
        const {selected} = Helper.getHashParams();
        const {checkedAlarms, filter, excluded} = this.state;

        if (selected || checkedAlarms === "full-all") {
            data["ids"] = (checkedAlarms === "full-all") ? "full-all" : this.state.selected;
            data["filters"] = filter;
            data["excluded"] = excluded;
        }

        return ApiAlarms.ignore(data).then(() => {
            this.setState({checkedAlarms: false, selected: []}, this.handleFetch);
            this.props.history.push(Helper.deleteHashParams(["modalAlarm"]));
            this.props.updateCountActiveAlarms();
            Toast.success("The alert has been ignored.");
        });
    }

    bindSearch(value) {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }
        this.handleFilterChange({query: value});
    }
    handleLimitChange = e => {
        const {name, value} = e.target;
        Helper.setPerPage("alarms", value);
        this.setState({
            loader: true,
            pagination: {
                ...this.state.pagination,
                page: 1,
                [name]: value
            }
        }, this.handleFetch);
    };

    handleCheckboxesLevel = (selectType) => {
        let {list, selected} = {...this.state};
        selected = [];

        if (selectType === "all" || selectType === "full-all") {
            list.map(item => selected.push(item.id));
        } else {
            list.map(item => +item.alertCondition.alertLevel.id === +selectType && selected.push(item.id));
        }
        this.setState({checkedAlarms: selectType, selected});
    };

    handleCheckbox = (e) => {
        const {value} = e.target;
        let {selected, excluded, checkedAlarms} = {...this.state};

        if (checkedAlarms === "full-all") {
            const index = excluded.indexOf(parseInt(value));
            selected = [];
            if (index !== -1) {
                excluded.splice(index, 1);
            } else {
                excluded.push(parseInt(value));
            }
        } else {
            const index = selected.indexOf(parseInt(value));

            if (index !== -1) {
                selected.splice(index, 1);
            } else {
                selected.push(parseInt(value));
            }
        }

        this.setState({
            selected,
            excluded
        });
    };

    handleAcknowledgeModal= () => {
        this.props.history.push(Helper.setHashParams({modalAlarm: "ack", selected: "all"}));
        this.setState({acknowledgeFormErrors: {}});
    };

    handleIgnoreModal= () => {
        this.props.history.push(Helper.setHashParams({modalAlarm: "ignore", selected: "all"}));
    };

    clearAcknowledgeFormErrors = () => {
        this.setState({acknowledgeFormErrors: {}});
    }

    handleExport = () => {
        this.setState({ loader: true });
        ApiAlarms.export({
            query: {
                filter: this.state.filter,
            },
            signal: this.signal
        }).then( (response) => {
            const csvExporter = new ExportToCsv({
                filename: "Active Alerts",
                showLabels: true,
                headers: [
                    "Equipment",
                    "Installation Point Name",
                    "Level",
                    "Description",
                    "Axis",
                    "Created"
                ],
            });
            let data = [];
            let list = _get(response, "list", []);

            for(let i in list) {
                data.push([
                    _get(list[i], "equipmentName"),
                    _get(list[i], "installationPointName"),
                    _get(list[i], "alertLevelName"),
                    _get(list[i], "description"),
                    _get(list[i], "axis"),
                    _get(list[i], "timestamp"),
                ]);
            }
            this.setState({ loader: false });
            csvExporter.generateCsv(data);
        } );
    }

    render() {
        const {
            loader,
            breadcrumbs,
            list,
            axisLabels,
            pagination,
            sort,
            filter,
            globalSearchString,
            checkedAlarms,
            selected,
            settingsStates,
            excluded,
            acknowledgeFormErrors
        } = this.state;

        const {auth, history} = this.props;

        const searchParams = Helper.getHashParams();

        return (
            <div>
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">Active Alerts</div>
                </div>
                <div className="block">
                    <div className="block-header block-header-sticky">
                        <div className="block-label">
                            <AlarmFilters selectedLevel={checkedAlarms} showCheckboxes={auth.userCan("editAlarms")} history={history} selected={selected} checkedAlarms={checkedAlarms} updated={this.handleFilterChange} handleCheckboxes={this.handleCheckboxesLevel}/>
                            <div id="top-pagination-block" className="block-pagination">
                                {pagination.total > 10 &&
                                <div className="limit-select-block">
                                    <LimitSelect
                                        name={"perpage"}
                                        onChange={this.handleLimitChange}
                                        defaultValue={pagination.perpage}
                                        limits={[10, 20, 50, 100]}
                                    />
                                </div>
                                }
                                {pagination.pages > 1 &&
                                <Pager
                                    page={pagination.page}
                                    pages={pagination.pages}
                                    onPagerChange={this.handlePagerChange}
                                />
                                }
                            </div>
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <div style={{minWidth: "180px"}}>
                                    <SearchInput
                                        onChange={this.bindSearch}
                                        placeholder="Search Alerts"
                                        query={filter.query}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {(checkedAlarms || selected.length > 0 ) &&
                    <div className="block-header">
                        <div className="k-margin-b-20-tablet-and-mobile custom-alert-block">
                            <div className="alert alert-outline-warning fade show" role="alert">
                                <div className="alert-icon"><i className="flaticon-warning"/></div>
                                <div className="alert-text">
                                    <strong>{checkedAlarms === "full-all" ? (pagination.total - excluded.length) : selected.length}</strong> {selected.length < 2 ? "alert has" : "alerts have"} been selected.
                                    {(checkedAlarms === "full-all" || selected.length === pagination.total)
                                        ? <span onClick={() => this.handleCheckboxesLevel(false)}
                                            className={"font-weight-bold cursor-pointer"}> Clear selection</span>
                                        : <span onClick={() => this.handleCheckboxesLevel("full-all")}
                                            className={"font-weight-bold cursor-pointer"}> Click here to select all {pagination.total} alerts.</span>
                                    }
                                </div>
                                <div className="px-3 py-2">
                                    <button
                                        className="link color-success"
                                        onClick={this.handleAcknowledgeModal}
                                    >
                                        <i className="fa fa-check"/>
                                        <span>Acknowledge Selected</span>
                                    </button>
                                    <button
                                        className="link link-secondary"
                                        onClick={this.handleIgnoreModal}
                                    >
                                        <i className="fa fa-times"/>
                                        <span>Ignore Selected</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="block-body">
                        {loader
                            ?
                            <Loader/>
                            :
                            <div>
                                <Table
                                    settingsStates={settingsStates}
                                    list={list}
                                    sort={sort}
                                    selected={selected}
                                    axisLabels={axisLabels}
                                    selectedLevel={checkedAlarms}
                                    excluded={excluded}
                                    query={filter.query}
                                    handleCheckbox={this.handleCheckbox}
                                    onSortChange={this.handleSortChange}
                                    onOpenAcknowledgeAlarmModal={(id) => history.push(Helper.setHashParams({modalAlarm: "ack", id}))}
                                    onOpenIgnoreAlarmModal={(id) => history.push(Helper.setHashParams({modalAlarm: "ignore", id}))}
                                />
                                <button
                                    disabled={list.length > 0 ? false : true}
                                    title={list.length > 0 ? "" : "There is no data to export at this time."}
                                    onClick={ this.handleExport }
                                    className="btn v2-btn blue ml-3"
                                    style={{width:100}}
                                >
                                    <img src={"/assets/pic/icon-download.svg"} alt=""/>
                                    <span>Export</span>
                                </button>
                            </div>
                        }
                    </div>
                </div>
                {searchParams.modalAlarm === "ack" &&
                    <AcknowledgeModal
                        title="Acknowledge Alert"
                        showModal={true}
                        submitTitle="Acknowledge"
                        onSubmit={data => this.handleSubmitAcknowledgeAlarmModal(Object.assign({}, data, {id: searchParams.id}))}
                        onClose={() => history.push(Helper.deleteHashParams(["id", "modalAlarm", "selected"]))}
                        formErrors={acknowledgeFormErrors}
                        clearErrorsForm={this.clearAcknowledgeFormErrors}
                    />
                }
                {searchParams.modalAlarm === "ignore" &&
                    <IgnoreModal
                        title="Ignore Alert"
                        showModal={true}
                        submitTitle="Ignore"
                        onSubmit={data => this.handleSubmitIgnoreAlarmModal(Object.assign({}, data, {id: searchParams.id}))}
                        onClose={() => history.push(Helper.deleteHashParams(["id", "modalAlarm", "selected"]))}
                    />
                }
            </div>
        );
    }
}

Alarms.propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object,
    updateCountActiveAlarms: PropTypes.func,

    // LocationSelectStore
    getLocationId: PropTypes.func
};

export default withLocationSelectStore(withGlobalStore(Alarms));