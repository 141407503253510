import React, {Component} from "react";
import PropTypes from "prop-types";
import {ValidationError} from "../../../../shared";
import DatePickerInput from "../../../../shared/datePickerInput/datePickerInput";
import Helper from "../../../../helpers/helper";
import FormValidator from "../../../../helpers/form-validator";
import {
    get as _get,
    set as _set,
} from "lodash";
import ActionListApi from "../../../../api/actionList";
import moment from "moment";
import {Modal} from "reactstrap";
import Toast from "../../../shared/toast";
import {SERVER_DATETIME_FORMAT} from "../../../../constants/constants";

const rules = [
    {
        field: "date",
        method: "isEmpty",
        validWhen: false,
        message: "The Remind Me On This Date field is required."
    },
    {
        field: "description",
        method: description => !!description.replace(/<[^>]*>/gi, "").replace(/[\s?&nbsp;]+/, "").length,
        validWhen: true,
        message: "The Reason For Snooze field is required."
    }
];

class SnoozeModal extends Component
{
    constructor(props) {
        super(props);

        this.validator = new FormValidator(rules);

        this.state = {
            data: {
                action_item_id: +props.actionItemId,
                description: "",
                count_days: "",
                date: ""
            },
            validation: this.validator.valid(),
            inProgress: false
        };
    }

    onChange = (event) => {
        let data = {...this.state.data};
        const key = _get(event, "target.name");
        const value = _get(event, "target.value");

        _set(data, key, value);

        const validation = this.validator.validate(data, key);
        this.setState({data, validation});
    }

    onChangeDate = (date) => {
        let data = {...this.state.data};

        const day = moment(date.date).format("YYYY-MM-DD");
        const count_days = Helper.daysBetween(new Date(), new Date(day));

        _set(data, "count_days", count_days);
        _set(data, "date", date.date);

        const validation = this.validator.validate(data, "date");
        this.setState({data, validation});
    }

    onSave = () => {
        let data = {...this.state.data};

        if (!data.date) {
            const formats = Helper.getUserDateFormat(this.props.user, true);
            const currentDay = moment().add(1, "days").format(formats.date);
            data.count_days = 1;
            data.date = currentDay;
        }

        this.setState(
            {inProgress: true},
            () => {
                const validation = this.validator.validate(data);
                this.setState({data, validation}, () => {
                    if (validation.isValid) {
                        this.snooze();
                    } else {
                        this.setState({inProgress: false});
                    }
                });
            }
        );
    }

    snooze = () => {
        const {data} = this.state;
        const {onClose, fetchActionItem} = this.props;

        return ActionListApi
            .snooze(data)
            .then((response) => {
                if (response.status === "ok") {
                    fetchActionItem()
                        .then(() =>
                            this.setState({inProgress: false}, () => {
                                onClose();
                                Toast.success("The Action Item has been snoozed.");
                            })
                        );
                } else {
                    this.setState({inProgress: false});
                }
            });
    }

    render() {
        const {data, inProgress, validation} = this.state;
        const {onClose, user} = this.props;

        const formats = Helper.getUserDateFormat(user, true);
        const currentDay = moment().add(1, "days").format(formats.date);

        return (
            <React.Fragment>
                <Modal
                    isOpen={true}
                    toggle={onClose}
                    centered={false}
                    className={"snooze-modal modal-dialog-centered"}
                >
                    <div className={"profile-alerts-modal"}>
                        <div className={"profile-modal-header modal-header align-items-center"}>
                            <div className={"title snooze-title"}>Snooze Action Item</div>
                            <button type="button" onClick={onClose} className={"close close-snooze"}>
                                <img src="/assets/pic/close.svg" alt=""/>
                            </button>
                        </div>

                        <hr/>

                        <div className={"profile-modal-body"}>
                            <div className="form-group row">
                                <div className="col-12">
                                    <label className="form-label req-label-size">Remind Me On This Date <span className="color-danger">*</span></label>
                                    <div className="input-group date">
                                        <DatePickerInput
                                            className={"form-control" + (validation.date.isValid || !validation.date.message ? "" : " is-invalid")}
                                            type="text"
                                            name="date"
                                            value={data.date || currentDay}
                                            disabled={inProgress}
                                            onChangeHandler={date => this.onChangeDate({date})}
                                            displayFormat={Helper.getUserDateFormat(user, true).date}
                                            valueFormat={"YYYY-MM-DD"}
                                            minDate={moment().format(SERVER_DATETIME_FORMAT)}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="la la-calendar"/>
                                            </span>
                                        </div>
                                        <ValidationError message={validation.date.message}/>
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <label className="form-label req-label-size">Reason For Snooze <span className="color-danger">*</span></label>
                                    <textarea
                                        className={"form-control" + (validation.description.isValid || !validation.description.message ? "" : " is-invalid")}
                                        name="description"
                                        value={data.description}
                                        onChange={this.onChange}
                                        disabled={inProgress}
                                    />
                                    <ValidationError message={validation.description.message}/>
                                </div>
                            </div>
                        </div>

                        <div className={"profile-modal-bottom"}>
                            <div
                                onClick={onClose}
                                className="btn v2-btn grey-border mr-4"
                            >
                                Cancel
                            </div>
                            <div
                                className={"btn btn-primary"}
                                onClick={this.onSave}
                            >
                                Confirm
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

SnoozeModal.propTypes = {
    onClose: PropTypes.func,
    fetchActionItem: PropTypes.func,
    user: PropTypes.object,
    actionItemId: PropTypes.number
};

export default SnoozeModal;