import {Component, Fragment} from "react";
import PropTypes from "prop-types";
import TriggeredAlertConditionApi from "../../api/triggeredAlertCondition";
import React from "react";
import {Popover, PopoverHeader, PopoverBody} from "reactstrap";
import {kebabCase} from "lodash";
import AlertCondition from "./alert-condition";
import ReactDOM from "react-dom";
import {Loader} from "../../shared";

class ReadingTypeBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            loading: false,
            triggeredConditions: [],
        };

        this.onClick = this.onClick.bind(this);
        this.getBadgeClass = this.getBadgeClass.bind(this);
        this.getDivId = this.getDivId.bind(this);
        this.toggleVisibility = this.toggleVisibility.bind(this);
    }

    onClick() {
        if (this.state.visible) {
            this.toggleVisibility();
        } else {
            this.toggle();
        }
    }

    toggle() {
        this.toggleLoader();

        TriggeredAlertConditionApi.list(this.props.equipmentId, this.props.chartTypeId)
            .then(({list}) => {
                ReactDOM.unstable_batchedUpdates(() => {
                    this.setData(list || []);
                    this.toggleLoader();
                });
            })
            .catch(() => this.toggleLoader());
    }

    setData(triggeredConditions) {
        this.setState({triggeredConditions: triggeredConditions});
    }

    getBadgeClass() {
        let className = "badge _badge badge-warning round-badge mb-1";
        if (this.state.loading) {
            className += " badge-disabled";
        }

        return className;
    }

    getDivId() {
        let name = kebabCase(this.props.name);
        return `popover-${this.props.equipmentId}-${name}`;
    }

    toggleVisibility() {
        this.setState((prevState) => ({visible: !prevState.visible}));
    }

    toggleLoader() {
        this.setState((prevState) => ({loading: !prevState.loading}));
    }

    render() {
        const {equipmentId, level, name, title, opened, showPopover = true} = this.props;
        const {visible, triggeredConditions, loading} = this.state;
        let popoverStyle = {backgroundColor: !opened ? level.color || "red" : "#C1C1C1"};
        if (showPopover) {
            popoverStyle["cursor"] = "pointer";
        }
        return (
            <Fragment>
                <div
                    id={this.getDivId()}
                    className={this.getBadgeClass()}
                    onClick={this.onClick}
                    style={popoverStyle}
                >
                    <span>{name}</span>
                </div>
                {showPopover && (
                    <Popover
                        placement="bottom"
                        isOpen={visible}
                        target={this.getDivId()}
                        toggle={this.toggleVisibility}
                        popperClassName={"triggered-popover"}
                        trigger="legacy"
                        container={".app-page .app-grid"}
                    >
                        <PopoverHeader>{`${level.name} - ${title}`}</PopoverHeader>
                        <PopoverBody
                            className="custom-scroll"
                            style={{height: "auto", maxHeight: 450}}
                        >
                            <div className="k-list">
                                {loading && <Loader />}
                                {triggeredConditions.map(
                                    (condition) =>
                                        condition.lastAlertTriggered !== null && (
                                            <AlertCondition
                                                equipmentId={equipmentId}
                                                key={condition.lastAlertTriggered.id}
                                                condition={condition}
                                            />
                                        )
                                )}
                            </div>
                        </PopoverBody>
                    </Popover>
                )}
            </Fragment>
        );
    }
}

ReadingTypeBlock.propTypes = {
    equipmentId: PropTypes.any,
    chartTypeId: PropTypes.number,
    title: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string,
    level: PropTypes.object,
    opened: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    showPopover: PropTypes.bool,
};

export default ReadingTypeBlock;
