import { useTachometerQuery } from "../../../../../../hooks/api/tachometer/useTachometerQuery";
import { useMemo } from "react";
import { useChartStateStoreContext } from "../../store/hooks/useStoreContext";
import { DATEFORMAT } from "../../../../../../constants/constants";
import { get as _get } from "lodash";
import { useEquipmentByParams } from "../../../../../../hooks/api/equipment/useEquipmentQuery";

export const useRpmBuilder = (props) => {
    const {toggleIssetRpm} = useChartStateStoreContext((state) => state.actions);
    const showRPM = useChartStateStoreContext((state) => state.settings.showRPM);
    const {equipmentItem} = useEquipmentByParams();

    const maxRpm = useMemo(
        () => _get(equipmentItem, ["tachometer", "nodeTriggerSetting", "max_rpm"], null),
        [equipmentItem]
    );

    const readingOverMax = (reading) => {
        return maxRpm !== null && reading[1] !== null && reading[1] > maxRpm;
    };

    const {tachReadings, isSuccess} = useTachometerQuery(equipmentItem);
    return useMemo(() => {
        if (!isSuccess || !showRPM) {
            return [];
        }
        if (!tachReadings.length && isSuccess) {
            toggleIssetRpm(false);
            return [];
        }

        const readings = tachReadings.filter((reading) => !readingOverMax(reading));
        toggleIssetRpm(true);

        return [
            {
                name: "Tachometer RPM",
                data: readings,
                id: "tachSeria",
                datetimeFormat: _get(props.user, "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0]),
                units: "RPM",
                color: "rgba(135,135,145,0.5)",
                precision: 2,
                yAxis: 1,
                dataGrouping: {
                    approximation: "high",
                    forced: true,
                },
            },
        ];
    }, [tachReadings, showRPM]);
};
