import InstallationPointSettingsApi from "../../../api/InstallationPointSettings";
import { useQuery } from "@tanstack/react-query";
import Toastr from "../../../pages/shared/toast";
import { remove as _remove } from "lodash";

export const addPendingOnDemand = (settingId, pendingOnDemandsRef) => {
    pendingOnDemandsRef.current.push(settingId);
};

const useRequestOnDemandPending = (pendingOnDemandsRef) => {
    const { isLoading, isSuccess, error, data } = useQuery(
        ["RequestOnDemandPending"],
        () => InstallationPointSettingsApi.pendingOnDemands((response) => (response.data))
    );

    if(data && data.list.length > 0) {
        pendingOnDemandsRef.current = data.list;
    }

    const requestList = pendingOnDemandsRef.current.length > 0;

    useQuery(
        ["RequestOnDemandCheck"],
            () => InstallationPointSettingsApi.check(pendingOnDemandsRef.current, (response) => {
                for (const reading of response.data.list) {
                    Toastr.success("Click here to navigate to charts", "New on-demand reading", {
                        timeOut: 0,
                        extendedTimeOut: 0,
                        onclick: () =>
                            window.open(
                                `/chart/${reading.installationPoint.equipment_id}#sensors=${reading.installationPoint.id}&readingFftId=${reading.readingFFT_id}`
                            ),
                    });
                    _remove(pendingOnDemandsRef.current, (settingId) => +reading.setting_id === +settingId);
                }
            },
            {
                enabled: !!requestList,
                refetchInterval: 5 * 60 * 1000
            }
        )
    );

    return {
        isLoading,
        isSuccess,
        error,
        data,
        addPendingOnDemand
    };
};

export default useRequestOnDemandPending;
