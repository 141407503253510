import React from "react";
import PropTypes from "prop-types";
import Helper from "../../helpers/helper";
import {Link} from "react-router-dom";
import {get as _get} from "lodash";


const RouterTab = ({list, query}) => {

    if (!(list || []).length) {
        return (<div className={"row"}>
            <div className="col">
                <span className="text-center text-info" style={{display: "block"}}>No items match your search.</span>
            </div>
        </div>);
    }


    return (<div className={"row"}>
        {list.map(item => {
            return (
                <div
                    key={item.id}
                    className="custom-card col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-4 equipment-card-link"
                >
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div className="k-bg-metal pt-60 mb-3 equpment-list-photo" style={{
                                        background: "#fff"
                                    }}>
                                        <img src={_get(item, ["image", "url"]) ?? "/assets/img/default-equipment-bg.png"} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h5
                                        title={item.serial}
                                        className="card-title ellipsis-text"
                                        dangerouslySetInnerHTML={{
                                            __html: "Repeater serial: " + Helper.highlight(""+item.serial, query)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <p title={item.name} className="cart-text ellipsis-text">
                                        Radio Address: {item.radio_address}
                                    </p>
                                    <p
                                        title={item.id}
                                        className="cart-text ellipsis-text"
                                        dangerouslySetInnerHTML={{
                                            __html: "Repeater Id: " + Helper.highlight(""+item.id, query)
                                        }}
                                    />
                                </div>
                            </div>
                            <Link
                                to={{pathname: `/network/routers/${item.serial}`}}
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-eye"/> View
                            </Link>
                        </div>
                    </div>
                </div>
            );
        })}
    </div>);
};


RouterTab.propTypes = {
    list: PropTypes.array,
    query: PropTypes.string
};

export default RouterTab;