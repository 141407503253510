import Api from "./api";

const InstallationPointSettingsApi = {
    getList: (params) => Api.get("installation-point-setting", {...params}),
    export: (params) => Api.get("installation-point-setting/export", {...params}),
    updateHFDVue: (params) => Api.post("installation-point-setting/set-sensor-hfdvue", params),
    check: settingIds => Api.get("request-on-demand/check", {query: {settingIds}}),
    update: (id, data) => Api.patch(`installation-point/${id}/setting`, data),
    updateForEquipment: (equipmentId, data) => Api.post(`installation-point-setting/batch-update-for-equipment/${equipmentId}`, data),
    pendingOnDemands: () => Api.get("request-on-demand/pending"),
    getEstimatedTime: (installationPointId, requestId, query) =>
        Api.get(`request-on-demand/get-estimate-request/${installationPointId}/${requestId}`, {query}),
    getAxisFMax: (sensorType, xFMax, sensorVersion, params = {}) => Api.post("installation-point-setting/get-axis-f-max", {xFMax, sensorVersion, sensorType}, {...params}),
    batchHighPass: (data) => Api.post("installation-point-setting/batch-high-pass", data),
};

export default InstallationPointSettingsApi;