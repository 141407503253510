import {useMemo} from "react";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {forEach as _forEach} from "lodash";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useTachometerQuery} from "../../../../../../hooks/api/tachometer/useTachometerQuery";
import {useIsShowChartMarks} from "../../../store/sync/sharedStore";

export const useTriggersBuilder = () => {
    const showTriggers = useChartStateStoreContext((state) => state.settings.showTriggers);
    const {equipmentItem: equipment} = useEquipmentByParams();
    const isShowChartMarks = useIsShowChartMarks();

    const {tachTriggers} = useTachometerQuery(equipment);

    const triggersPlotlines = useMemo(() => {
        if (!tachTriggers?.length || !isShowChartMarks) {
            return [];
        }

        let plotLines = [];

        if (showTriggers) {
            _forEach(tachTriggers, (timestamp) => {
                const color = "#46b67f";
                plotLines.push({
                    width: 2,
                    value: timestamp,
                    dashStyle: "Dot",
                    color: color,
                });
            });
        }

        return plotLines;
    }, [showTriggers, tachTriggers, isShowChartMarks]);

    return triggersPlotlines;
};
