import React from "react";
import PropTypes from "prop-types";

const HelpZoom = (props) => {
    const { additionalTitle } = props;

    const addTitle = additionalTitle ? " - " + additionalTitle : "";

    return (
        <React.Fragment>
            <h5 className="chart-title mt-2 mb-2">Zoom functionality{addTitle}</h5>

            <p>
                To zoom in on a specific range within a chart, click a location on the chart and
                then drag and drop to the desired end point. A gray box is displayed to show the
                range that you select.
                <br />
                To return to the original chart, click Reset Zoom.
            </p>
        </React.Fragment>
    );
};

HelpZoom.propTypes = {
    additionalTitle: PropTypes.string,
};

export default HelpZoom;
