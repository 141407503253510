import React from "react";
import PropTypes from "prop-types";
import Helper from "../../../../helpers/helper";
import CommentsForm from "./comments-form";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";

const CommentsBlock = ({comments = [], user={}, searchParams, onSubmitEdit, onDelete, history, isHistory}) => (
    <div className="timeline">

        {comments.length
            ? comments.map(comment => {
                return (
                    (searchParams.modal === "comment-edit" && +searchParams.comment_id === +comment.id && !isHistory) ?
                        <CommentsForm
                            key={comment.id}
                            commentId={+comment.id}
                            styleObject={{marginBottom: "10px"}}
                            onClose={() => {
                                history.push(Helper.deleteHashParams(["modal", "comment_id"]));
                            }}
                            onSubmit={data => onSubmitEdit(data, comment.id)}
                        /> :
                        <Comment
                            key={comment.id}
                            comment={comment}
                            user={user}
                            onDelete={() => onDelete(comment.id)}
                            onEdit={() => history.push(Helper.setHashParams({modal: "comment-edit", comment_id: comment.id}))}
                            isHistory={isHistory}
                        />
                );
            })
            : <i className="font-weight-bold">Comments is empty</i>
        }
    </div>
);
CommentsBlock.propTypes = {
    comments: PropTypes.array,
    user: PropTypes.object,
    searchParams: PropTypes.object,
    history: PropTypes.object,
    onSubmitEdit: PropTypes.func,
    onDelete: PropTypes.func,
    isHistory: PropTypes.number
};
const Comment = ({comment, user, onEdit, onDelete, isHistory}) => (
    <div className="timeline-item">
        <div className="timeline-item__section">
            <div className="timeline-item__border bg-white">
                <div className="timeline-item__icon">
                    <img src="/assets/pic/icon-notes.svg" alt="" />
                </div>
            </div>
            <span className="timeline-item__datetime timeline-item__datetime--line">{Helper.dateToUserFormat(comment.created_at, user)}</span>
            {(+user.id === +comment.user_id && !isHistory) &&
            <UncontrolledDropdown className="btn-group-sm position-initial">
                <DropdownToggle
                    tag="button"
                    className="btn btn-icon btn-sm btn-icon-md btn-circle right-dropdown-btn-notes"
                >
                    <i className="fa fa-ellipsis-h"/>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-right">
                    <DropdownItem onClick={onEdit}><i className="fa fa-edit"/> Edit</DropdownItem>
                    <DropdownItem onClick={onDelete}><i className="fa fa-trash"/> Delete</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            }
        </div>
        {!!isHistory &&
            <div className="timeline-item__text">
                {+comment.comment_type === 1 &&
                    <div className="badge _badge badge-danger round-badge mb-1" style={{cursor: "default"}}>
                        <span>Ignored</span>
                    </div>
                }
                {+comment.comment_type === 2 &&
                    <div className="badge _badge badge-warning round-badge mb-1" style={{cursor: "default"}}>
                        <span>Acknowledged</span>
                    </div>
                }
            </div>
        }
        <span className="timeline-item__text" dangerouslySetInnerHTML={{__html: comment.text}}/>
        {!!comment.user_name.length &&
            <div className="timeline-item__info">
                {comment.user_name}
            </div>
        }

    </div>
);
Comment.propTypes = {
    comment: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    isHistory: PropTypes.number
};

export default CommentsBlock;