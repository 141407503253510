import React, {Component} from "react";
import FormValidator from "../../helpers/form-validator";
import {ValidationError} from "../../shared";
import ApiUser from "../../api/user";
import _ from "lodash";

const rules = [
    {
        field: "old_password",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "password",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "confirm_password",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "confirm_password",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
];

class ChangePasswordForm extends Component
{
    constructor(props) {
        super(props);

        this.validator = new FormValidator(rules);

        this.state = {
            data: {
                old_password: "",
                password: "",
                confirm_password: ""
            },
            inProgress: false,
            validation: this.validator.valid(),
            successUpdated: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.hideSuccessUpdated = this.hideSuccessUpdated.bind(this);
    }

    onChange(e) {
        const data = Object.assign({}, this.state.data, {[_.get(e, "target.name", "")]: _.get(e, "target.value", "")});
        const validation = this.validator.validate(data, _.get(e, "target.name", ""));
        this.setState({data, validation, sent: false});
    }

    hideSuccessUpdated() {
        this.setState({successUpdated: false});
    }

    onSave() {
        const validation = this.validator.validate(this.state.data);

        this.setState({validation}, () => {
            if (validation.isValid) {
                this.setState({inProgress: true}, () => {
                    ApiUser.changePassword(this.state.data).then(() => {
                        this.setState({
                            inProgress: false,
                            data: {
                                old_password: "",
                                password: "",
                                confirm_password: ""
                            },
                            successUpdated: true
                        });
                    }).catch(err => {
                        if (err.errors) {
                            const validation = this.state.validation;

                            Object.keys(err.errors).map(key => {
                                validation[key].isValid = false;
                                validation[key].message = err.errors[key][0];
                            });

                            this.setState({validation, inProgress: false});
                        }
                    });
                });
            }
        });
    }

    render() {
        const {inProgress, data, validation, successUpdated} = this.state;

        return (
            <div className="block">
                <div className="block-header">
                    <div className="block-label">
                        <h5 className="block-title mb-0">Change Password <small>change or reset your account password</small></h5>
                    </div>
                </div>
                <form className="form user-profile-form" onSubmit={e => {e.preventDefault(); this.onSave();}}>
                    <div className="block-body">
                        {successUpdated &&
                            <div className="alert alert-outline-success fade show">
                                <div className="alert-icon">
                                    <i className="flaticon-info"/>
                                </div>
                                <div className="alert-text">Your password has been changed. </div>
                                <div className="alert-close">
                                    <button
                                        className="close"
                                        type="button"
                                        aria-label="Close"
                                        onClick={this.hideSuccessUpdated}
                                    >
                                        <span aria-hidden="true"><i className="la la-close"/></span>
                                    </button>
                                </div>
                            </div>
                        }
                        <div className="form-group row">
                            <label className="col-3 form-label">Current Password</label>
                            <div className="col-6">
                                <input
                                    className={"form-control" + (validation.old_password.isValid || !validation.old_password.message ? "" : " is-invalid")}
                                    name="old_password"
                                    type="password"
                                    placeholder="Current password"
                                    value={data.old_password}
                                    onChange={this.onChange}
                                    disabled={inProgress}
                                />
                                <ValidationError message={validation.old_password.message}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-3 form-label">New Password</label>
                            <div className="col-6">
                                <input
                                    className={"form-control" + (validation.password.isValid || !validation.password.message ? "" : " is-invalid")}
                                    name="password"
                                    type="password"
                                    placeholder="New password"
                                    value={data.password}
                                    onChange={this.onChange}
                                    disabled={inProgress}
                                />
                                <ValidationError message={validation.password.message}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-3 form-label">Verify Password</label>
                            <div className="col-6">
                                <input
                                    className={"form-control" + (validation.confirm_password.isValid || !validation.confirm_password.message ? "" : " is-invalid")}
                                    name="confirm_password"
                                    type="password"
                                    placeholder="Verify password"
                                    value={data.confirm_password}
                                    onChange={this.onChange}
                                    disabled={inProgress}
                                />
                                <ValidationError message={validation.confirm_password.message}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-3"></label>
                            <div className="col-6 text-right">
                                <button
                                    className="btn btn-sm btn-danger"
                                    type="button"
                                    onClick={this.onSave}
                                    disabled={inProgress}
                                >
                                    {inProgress ? <span><i className="fa fa-spinner"></i> Processing</span> : "Change Password"}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default ChangePasswordForm;