import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get,
    set as _set,
    omit as _omit
} from "lodash";
import ApiReadingTypes from "../../../../api/readingTypes";
import {Modal} from "../../../../shared";
import Toast from "../../../shared/toast";
import { InputFormGroup } from "../../../../shared/formParts/formParts";

class UniversalAdapterParameterForm extends Component
{
    constructor(props) {
        super(props);

        const emptyParameter = {
            id: "",
            name: "",
            unit: ""
        };

        const parameter = props.parameter ? {...props.parameter} : {...emptyParameter};

        this.state = {
            parameter: parameter,
            formErrors: {},
            inProgress: false
        };
    }

    changeData = (event) => {
        const key = _get(event, "target.name");
        const value = _get(event, "target.value");

        this.updateData(key, value);
    }

    updateData = (key, value) => {
        let parameter = {...this.state.parameter};

        _set(parameter, key, value);

        this.setState({parameter}, () => this.removeError(key));
    }

    removeError = (key) => {
        const oldFormErrors = {...this.state.formErrors};

        const formErrors = _omit(oldFormErrors, key);

        this.setState({formErrors});
    }

    isNew = () => {
        const {parameter} = this.state;
        return !_get(parameter, "id");
    }

    onSubmit = () => {
        const {onSave} = this.props;

        this.setState({inProgress: true});
        this.submitForm()
            .then(response => {
                if (response.status === "ok") {
                    onSave().then(() => {
                        this.setState({inProgress: false});
                        Toast.success("The process variable has been "+(this.isNew() ? "added" : "updated")+".");
                    });
                }
            })
            .catch(response => {
                if (response.status === 422) {
                    this.setState({inProgress: false});
                    this.setState({formErrors: response.errors || {}});
                }
            });
    }

    submitForm = () => {
        const {parameter} = this.state;

        return this.isNew() ? ApiReadingTypes.create(parameter) : ApiReadingTypes.update(_get(parameter, "id"), parameter);
    }

    render() {
        const {parameter, formErrors, inProgress} = this.state;
        const {onCancel} = this.props;

        return (
            <Modal
                showModal={true}
                className={"adapter-parameters-modal"}
                title={"Add process variable"}
                size={"lg"}
                onSubmit={this.onSubmit}
                onClose={onCancel}
                disableSubmit={inProgress}
            >
                <div className={"row adapter-parameter-row"}>
                    <div className={"form-group mb-3 col-6"}>
                        <InputFormGroup
                            label="Process Variable"
                            name="name"
                            // className={"form-control" + (formErrors.name ? " is-invalid" : "")}
                            value={parameter.name}
                            errorMsg={formErrors.name}
                            isRequired={true}
                            onChange={this.changeData}
                        />
                    </div>
                    <div className={"form-group mb-3 col-6"}>
                        <InputFormGroup
                            label="Unit"
                            name="unit"
                            value={parameter.unit}
                            errorMsg={formErrors.unit}
                            isRequired={true}
                            onChange={this.changeData}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

UniversalAdapterParameterForm.propTypes = {
    parameter: PropTypes.func,
    onSave: PropTypes.func,
    columnList: PropTypes.object,
    onCreated: PropTypes.func,
    onCancel: PropTypes.func
};

export default UniversalAdapterParameterForm;