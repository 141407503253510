import React, {useState} from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {useSignalStrengthQuery} from "../../../../hooks/api/signal/useSignalStrengthQuery";
import Loader from "../../../../shared/loader/loader";
import ToggleModal from "../../../../modals/toggle-modal";
import {Button} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import {useSignalStrengthTooltip} from "../../hooks/signal/useSignalStrengthTooltip";
import {useSignalStrengthChartDirector} from "../../hooks/signal/useSignalStrengthChartDirector";
import {useSignalStrengthSeries} from "../../hooks/signal/useSignalStrengthSeries";
import {AVAILABLE_TIME_VALUES} from "../../constants/constans";

const SignalStrength = ({serial, type, label = "dBm"}) => {
    const [selectedDaysOption, setSelectedDaysOption] = useState(AVAILABLE_TIME_VALUES[0]);
    const [isFullScreenMode, setIsFullScreenMode] = useState(false);
    const [dateRange, setDateRange] = useState({startDate: null, endDate: null});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const {data: response, isSuccess, isFetching} = useSignalStrengthQuery(serial, type, selectedDaysOption.value, dateRange);

    const packetLossSeries = useSignalStrengthSeries(serial, type, selectedDaysOption.value, dateRange);

    const tooltip = useSignalStrengthTooltip(serial, type, selectedDaysOption.value, dateRange, label);

    const options = useSignalStrengthChartDirector({series: packetLossSeries, tooltip, label});

    if (isFetching || !isSuccess) {
        return <Loader />;
    }

    return (
        <ToggleModal
            centered={true}
            size="full-screen"
            showModal={true}
            enabled={isFullScreenMode}
            title={""}
            withoutFooter={true}
        >
            <div className="row">
                <div className={"col-5 mr-auto"}>
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setStartDate(update[0]);
                            setEndDate(update[1]);
                            if (update[0] && update[1]) {
                                setDateRange({startDate: moment(update[0]).format("YYYY-MM-DD"), endDate: moment(update[1]).format("YYYY-MM-DD")});
                            }
                        }}
                        maxDate={new Date()}
                        placeholderText={"Select Period"}
                        portalPosition={isFullScreenMode ? "date-picker-portal-modal" : null}
                        customInput={
                            <input
                                className={"form-control"}
                                style={{width: "180px"}}
                            />
                        }
                    />
                    {(startDate || endDate) && (
                        <Button
                            style={{margin: "0 5px"}}
                            className="btn-fa-icon btn-sm btn-default"
                            onClick={() => {
                                setStartDate(null);
                                setEndDate(null);
                                setDateRange({startDate: null, endDate: null});
                            }}
                        >
                            <i className={"fa fa-times"} />
                        </Button>
                    )}
                </div>
                <div className="col-4 mr-2">
                    <Select
                        name="charts-time-selector"
                        value={selectedDaysOption}
                        onChange={(val) => {
                            setSelectedDaysOption(val);
                        }}
                        options={AVAILABLE_TIME_VALUES}
                        clearable={false}
                        closeOnSelect={false}
                        className="react-select"
                        classNamePrefix="react-select"
                    />
                </div>
                <Button
                    className="btn-fa-icon btn-sm pull-right btn-default"
                    onClick={() => setIsFullScreenMode((prev) => !prev)}
                >
                    <i className={isFullScreenMode ? "fa fa-times" : "fa fa-expand-arrows-alt"} />
                </Button>
            </div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            <table className={"table border-0"}>
                <tbody>
                    <tr>
                        <td>
                            <span className="text-danger">
                                <b>MIN:</b>
                            </span>
                        </td>
                        <td>
                            {response.stats.min || <i>No Data</i>} {response.stats.min ? label : ""}
                        </td>
                        <td>
                            <span className="text-danger">
                                <b>MAX:</b>
                            </span>
                        </td>
                        <td>
                            {response.stats.max || <i>No Data</i>} {response.stats.max ? label : ""}
                        </td>
                        <td>
                            <span className="text-warning">
                                <b>AVG:</b>
                            </span>
                        </td>
                        <td>
                            {response.stats.avg || <i>No Data</i>} {response.stats.avg ? label : ""}
                        </td>
                        <td>
                            <span className="text-success">
                                <b>Current:</b>
                            </span>
                        </td>
                        <td>
                            {response.stats.latest || <i>No Data</i>} {response.stats.latest ? label : ""}
                        </td>
                    </tr>
                </tbody>
            </table>
        </ToggleModal>
    );
};

SignalStrength.propTypes = {
    serial: PropTypes.number,
    type: PropTypes.string,
    label: PropTypes.string
};

export default SignalStrength;
