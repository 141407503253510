/* eslint-disable react/prop-types */
import React, {useMemo, useReducer} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../../../stores/GlobalStore";
import {get as _get, filter as _filter, includes as _includes} from "lodash";

import "./intervalSettingsTab.scss";
import SettingsDataHandler from "../../../../shared/intervals/SettingsDataHandler";
import {ViewSettings} from "../../../../shared/intervals/ViewSettings";
import SettingsForm from "../../../../shared/intervals/SettingsForm";
import {ACTION, reducer} from "./reducer/mainReducer";
import Loader from "../../../../../shared/loader/loader";
import {useInstallPointListInvalidate} from "../../../../../hooks/api/installationPoints/useInstallPointListInvalidate";
import {useInstallationPointsListQuery} from "../../../../../hooks/api/installationPoints/useInstallationPointsListQuery";
import {useEquipmentByParams} from "../../../../../hooks/api/equipment/useEquipmentQuery";
const initialState = {
    loaded: false,
    list: [],
    triggers: {},
    viewColumnCount: 3,
    forceUpdate: false,
    inProgress: false,
    editId: null,
};
const IntervalSettingsTab = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {equipmentItem: equipment} = useEquipmentByParams();
    const {editId, forceUpdate} = state;
    const {auth, setModalTitle, currentChanged, invalidateEquipment} = props;
    const invalidateSimpleChartMarks = useInstallPointListInvalidate(equipment.id);

    const {installationPoints: listRaw, triggers, isLoading, isFetching, isSuccess} = useInstallationPointsListQuery(equipment.id);

    const {list, viewColumnCount} = useMemo(() => {
        let viewColumnCount = 3;
        const listFiltered = _filter(listRaw || [], (item) => {
            viewColumnCount === 3 &&
                (_includes(_get(triggers, "restriction.generic_sensor_types", []), _get(item, "sensor.version_type", false) + "") ||
                    _includes(_get(triggers, "restriction.wos_sensor_types", []), +_get(item, "sensor.version_type", false))) &&
                viewColumnCount++;
            return (item.point_type === "sensor" || item.point_type === "adapter") && _get(item, "sensor.version_name", "") !== "";
        });

        return {list: listFiltered, viewColumnCount};
    }, [listRaw]);

    const handleFetch = () => invalidateSimpleChartMarks();

    const setEdit = (id) => {
        dispatch({type: ACTION.SET_STATE, state: {editId: id}});
    };

    if (isLoading || isFetching) {
        return (
            <div className="installation-point-settings-list">
                <Loader />
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="installation-point-settings-list">
                <IntervalSettingsTabHeader viewColumnCount={viewColumnCount} />
                {isSuccess &&
                    list.map((item) => (
                        <div key={item.id}>
                            <SettingsBlock
                                auth={auth}
                                equipment={equipment}
                                triggersStore={triggers}
                                forceUpdate={forceUpdate}
                                installationPoint={item}
                                editId={editId}
                                viewColumnCount={viewColumnCount}
                                issetHDFVue={_get(item, "sensor.is_hfdvue", 0)}
                                setEdit={setEdit}
                                updateSettings={handleFetch}
                                setModalTitle={setModalTitle}
                                currentChanged={currentChanged}
                                invalidateEquipment={invalidateEquipment}
                            />
                        </div>
                    ))}
            </div>
        </React.Fragment>
    );
};

IntervalSettingsTab.propTypes = {
    auth: PropTypes.object,
    equipment: PropTypes.object,
    history: PropTypes.object,
    chartTypes: PropTypes.object,
    getChartMarks: PropTypes.func,
    setModalTitle: PropTypes.func,
    currentChanged: PropTypes.func,
    invalidateEquipment: PropTypes.func,
};

const IntervalSettingsTabHeader = ({viewColumnCount}) => {
    return (
        <div>
            <div className="interval-block">
                <div className="settings-preview-list d-flex justify-content-between interval-content">
                    <div className="settings-preview-item point-preview">
                        <div className={"title"}></div>
                    </div>
                    <div className="settings-preview-item intervals-preview">
                        <div className={"title"}>Intervals</div>
                    </div>
                    <div className="settings-preview-item fft-preview">
                        <div className={"row"}>
                            <div className={"col-3"}>
                                <div className={"title"}>FFT</div>
                            </div>
                            <div className={"col-3"}>
                                <div className={"title"}>FMax</div>
                            </div>
                            <div className={"col-3"}>
                                <div className={"title"}>LoR</div>
                            </div>
                            <div className={"col-3"}>
                                <div className={"title text-nowrap"}>RMS High-Pass</div>
                            </div>
                        </div>
                    </div>
                    {viewColumnCount === 4 && (
                        <div className="settings-preview-item triggers-preview">
                            <div className={"title"}>Trigger Setting</div>
                        </div>
                    )}
                    <a
                        className="pull-right intervals-settings"
                        style={{width: "13px"}}
                    ></a>
                </div>
            </div>
        </div>
    );
};

IntervalSettingsTabHeader.propTypes = {
    viewColumnCount: PropTypes.number,
};

const SettingsBlock = (props) => {
    const {auth, installationPoint, editId, updateSettings, triggersStore, forceUpdate, viewColumnCount, setEdit, equipment, invalidateEquipment} = props;

    const onEdit = () => {
        setEdit(installationPoint.id);
    };

    const onCancel = (setSettings) => {
        if (setSettings && typeof setSettings == "function") {
            setSettings();
        }
        setEdit(null);
        return Promise.resolve();
    };

    const sensorId = _get(installationPoint, "sensor_id");
    const sensorHex = _get(installationPoint, "sensor.hex_id", sensorId);

    const onUpdate = async () => {
        await invalidateEquipment();
        await updateSettings();
    };

    return (
        <SettingsDataHandler
            installationPoint={installationPoint}
            triggersStore={triggersStore}
            forceUpdate={forceUpdate}
            updateSettings={updateSettings}
            afterSubmit={onCancel}
        >
            {(props) => {
                const {
                    settings,
                    triggers,
                    onTriggersFormChange,
                    toggleFlag,
                    showNotice,
                    formErrors,
                    validation,
                    onChange,
                    getNodesSerials,
                    inProgress,
                    isImpactVueEnabled,
                    onSave,
                    setSettings,
                } = props;

                if (+editId !== +installationPoint.id) {
                    return (
                        <ViewSettings
                            auth={auth}
                            installationPoint={installationPoint}
                            triggers={triggers}
                            onEdit={onEdit}
                            viewColumnCount={viewColumnCount}
                            readonly={auth.equipmentViewOnly(equipment)}
                            withOverlay={!!editId && +editId !== +installationPoint.id}
                            updateSettings={onUpdate}
                        />
                    );
                }
                return (
                    <div className={"interval-block"}>
                        <div className={"settings-preview-list"}>
                            <div className={"d-flex settings-preview-item horizontal mb-4"}>
                                <div className={"title"}>{_get(installationPoint, "name")}</div>
                                {sensorId ? (
                                    <div>
                                        <span className={"sensor-id-title mr-1"}>Hex Code:</span>
                                        {sensorHex}
                                    </div>
                                ) : (
                                    <div>N/A</div>
                                )}

                                <span className={"interval-block-settings-actions"}>
                                    <a
                                        onClick={() => onCancel(setSettings)}
                                        className="pull-right intervals-settings"
                                    >
                                        Cancel
                                    </a>
                                    <a
                                        onClick={onSave}
                                        title="Set Intervals"
                                        className={"pull-right intervals-settings" + (inProgress ? " disabled" : "")}
                                    >
                                        {inProgress ? (
                                            <span>
                                                <i className="fa fa-spinner" /> Processing
                                            </span>
                                        ) : (
                                            "Save"
                                        )}
                                    </a>
                                </span>
                            </div>
                            <SettingsForm
                                settings={settings}
                                triggers={triggers}
                                onTriggersFormChange={onTriggersFormChange}
                                toggleFlag={toggleFlag}
                                installationPoint={installationPoint}
                                specs={_get(installationPoint, "modelSpec", {})}
                                sensorParameters={_get(installationPoint, "sensorParameters", {})}
                                onChange={onChange}
                                showNotice={showNotice}
                                formErrors={formErrors}
                                versionType={+_get(installationPoint, "sensor.version_type", 1)}
                                nodeSerials={getNodesSerials()}
                                isImpactVueEnabled={isImpactVueEnabled()}
                                validation={validation}
                            />
                        </div>
                    </div>
                );
            }}
        </SettingsDataHandler>
    );
};

SettingsBlock.propTypes = {
    auth: PropTypes.object,
    equipment: PropTypes.object,
    forceUpdate: PropTypes.bool,
    viewColumnCount: PropTypes.number,
    triggersStore: PropTypes.object,
    installationPoint: PropTypes.object.isRequired,
    editId: PropTypes.any,
    issetHDFVue: PropTypes.bool,
    setEdit: PropTypes.func,
    setModalTitle: PropTypes.func,
    updateSettings: PropTypes.func,
    currentChanged: PropTypes.func,
    invalidateEquipment: PropTypes.func,
};

export default withGlobalStore(IntervalSettingsTab);
