const selectStyles = {
    control: (provided) => ({
        ...provided,
        height: 32,
        minHeight: 32
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: "100000"
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#9E9E9E",
    }),
    option: (provided) => ({
        ...provided,
        padding: "0 7px"
    }),
};

const darkModeSelectStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: "rgb(56, 56, 46)",
        height: 32,
        minHeight: 32
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#ccc"
    }),
    singleValueLabel: (provided) => ({
        ...provided,
        color: "#ccc"
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#36342a"
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: "100000"
    }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused
                ? "#aaa"
                : null,
            color: isFocused
                ? "#333"
                : "#ddd",
            padding: "0 7px"
        };
    },
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    input: (provided) => ({
        ...provided,
        color: "#ccc"
    })
};

export default selectStyles;
export {selectStyles, darkModeSelectStyles};