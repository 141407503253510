import {useMutation, useQueryClient} from "@tanstack/react-query";
import RoutesApi from "../../../api/routes";
import {getRouteByIdQueryKeys} from "./useRouteByIdQuery";

export const useRouteUpdateMutation = (id) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data) => RoutesApi.update(id, data),
        onSuccess: async () => queryClient.invalidateQueries({queryKey: getRouteByIdQueryKeys({routeId: id})}),
    });
};
