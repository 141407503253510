import {useChartStateStoreContext} from "../store/hooks/useStoreContext";
import {useEffect} from "react";
import Highcharts from "highcharts";
import {toggleBandPoint, toggleBCursorPoint} from "../helpers/cursor";
import {get as _get} from "lodash";
import {useLockPoints} from "./useLockPoints";
import ChartHelper from "../../../../../helpers/chart";
import {isChartPaused} from "../../../../../helpers/holdMode";

export const useChartEventEffect = (chartRef) => {
    const fftCursor = useChartStateStoreContext((state) => state.settings.fftCursor);
    const lockPoints = useLockPoints();
    const {chartTypeName} = useChartStateStoreContext((state) => state.factory);
    const {setZoomed} = useChartStateStoreContext((state) => state.actions);

    const cursorClickEventHandler = (points) => {
        if (fftCursor === "harmonic") {
            chartRef.current.chart.isPaused = !chartRef.current.chart.isPaused;
            if (!chartRef.current.chart.isPaused) {
                lockPoints.current = [];
                return;
            }
        }
        if (chartRef.current.chart.isShowBCursor && chartRef.current.chart.isPaused) {
            chartRef.current.chart.isShowBCursor = false;
            chartRef.current.chart.isPaused = false;
            return;
        }

        if (chartRef.current.chart.isShowBCursor) {
            chartRef.current.chart.isPaused = true;
            return;
        }
        if (chartRef.current.chart.moveMode) {
            chartRef.current.chart.moveMode = false;
            return;
        }

        if (chartRef.current.chart.isPaused) {
            return;
        }
        if (!points && !_get(chartRef.current, "chart.isPointBPaused")) {
            return;
        }
        if (fftCursor === "harmonic") {
            if (lockPoints.current.length) {
                lockPoints.current = [];
            } else {
                lockPoints.current = points;
            }
        }

        if (fftCursor === "sideband") {
            toggleBandPoint(points, chartRef);
        }
        if (fftCursor === "bCursor") {
            lockPoints.current = toggleBCursorPoint(points, chartRef);
        }
        lockPoints.current = points;
    };
    function onChartClickEvent(e) {
        if (isChartPaused(chartRef.current)) {
            return;
        }
        if (
            // Workaround for reset zoom button
            e.target.textContent !== Highcharts.defaultOptions.lang.resetZoom &&
            e.target.className.baseVal !== "highcharts-button-box"
        ) {
            cursorClickEventHandler(e.xAxis[0].axis.chart.hoverPoints);
        }
    }

    const onChartSelectionEvent = (e) => {
        if (chartRef.current.chart.holdPoints) {
            setZoomed(!e.resetSelection);
            return;
        }
        if (fftCursor === "harmonic" && lockPoints.current.length) {
            setTimeout(() => ChartHelper.harmonicCursor(lockPoints.current, chartTypeName), 150);
        }
        if (fftCursor === "sideband" && lockPoints.current.length) {
            setTimeout(() => toggleBandPoint(lockPoints.current), 150);
        }
        if (fftCursor === "bCursor" && lockPoints.current.length) {
            setTimeout(() => toggleBCursorPoint(lockPoints.current, chartRef), 150);
        }
        if (fftCursor === "bCursor") {
            chartRef.current.chart.isPointBPaused = false;
            ChartHelper.clearAdditionalPoints(chartRef.current.chart);
            lockPoints.current = [];
        }
        setZoomed(!e.resetSelection);
    };

    const onSeriesPointClickEvent = (e) => {
        if (isChartPaused(chartRef.current)) {
            return;
        }

        e.stopImmediatePropagation();
        lockPoints.current = e.point.series.chart.hoverPoints;
        e.stopPropagation();
        cursorClickEventHandler(e.point.series.chart.hoverPoints);
    };

    useEffect(() => {
        if (!chartRef.current || !chartRef.current?.chart) {
            return;
        }
        Highcharts.addEvent(chartRef.current.chart, "click", onChartClickEvent);

        if (chartRef.current.chart.series[0]) {
            Highcharts.addEvent(chartRef.current.chart.series[0], "click", onSeriesPointClickEvent);
        }
        Highcharts.addEvent(chartRef.current.chart, "selection", onChartSelectionEvent);
        return () => {
            if (!chartRef.current || !chartRef.current?.chart) {
                return;
            }
            Highcharts.removeEvent(chartRef.current.chart, "click", onChartClickEvent);
            if (chartRef.current.chart.series[0]) {
                Highcharts.removeEvent(chartRef.current.chart.series[0], "click", onSeriesPointClickEvent);
            }
            Highcharts.removeEvent(chartRef.current.chart, "selection", onChartSelectionEvent);
        };
    }, [fftCursor, chartRef.current]);
};
