import {createStore} from "zustand";
import {useChartAbstractFactory} from "../hooks/useChartAbstractFactory";
import {useEffect, useMemo, useRef} from "react";
import {get as _get, cloneDeep as _cloneDeep} from "lodash";
import {immer} from "zustand/middleware/immer";
import {useEquipmentByParams} from "../../../../../hooks/api/equipment/useEquipmentQuery";
import {getSeriesList} from "../../helpers/getSeriesList";
import {useChartTypesStore} from "../../../../../stores/zustand/ChartSelectedChartTypeStore";
import {useSelectedByEquipment} from "../../../../../stores/zustand/ChartSelectedEquipmentStore";

export const useCreateChartStateStore = (props) => {
    const factoryResult = useChartAbstractFactory(props);
    const {equipmentItem} = useEquipmentByParams();
    const chartTypes = useChartTypesStore();
    const selectedInstallationPoints = useSelectedByEquipment(equipmentItem.id);

    const seriesListMemo = useMemo(() => {
        let seriesList = getSeriesList(props.chartType, false, chartTypes);
        const currentSelected = JSON.parse(localStorage.getItem("selectedSeries")) || {};
        const selectedForCurrentChartType = _get(currentSelected, +props.chartType, []);

        if (selectedForCurrentChartType.length) {
            seriesList.selected = selectedForCurrentChartType;
            seriesList.readingType = selectedForCurrentChartType.map((axisID) => seriesList.list[axisID].readingType);
        }

        return seriesList;
    }, [props.chartType]);

    const selectedPointMemo = useMemo(
        () => +props.selectedPoint || selectedInstallationPoints[0] || _get(equipmentItem, "installationPoints.0.id"),
        [props.selectedPoint, equipmentItem, selectedInstallationPoints, equipmentItem.installationPoints]
    );

    const store = useRef(null);
    useEffect(
        () => () => {
            if (store.current) {
                store.current.destroy();
            }
        },
        []
    );
    if (store.current) {
        return store.current;
    }
    store.current = createStore(
        immer((set, get) => ({
            factory: factoryResult,
            isLoading: true,
            settings: {
                deleteChartMarkId: null,
                chartMarkId: null,
                selectedPoint: selectedPointMemo,
                chartType: props.chartType,
                seriesList: seriesListMemo,
                fullScreenMode: false,
                points: [],
                showAlerts: true,
                showRPM: false,
                showAmbient: false,
                showFFTShapes: false,
                currentPoint: {},
                currentPoints: [],
                deleteReadingModal: false,
                helpModal: false,
                issetAmbient: true,
                isNameplatesOverlay: false,
                dropdownSeriesOpen: false,
                valueMax: "",
                valueMin: "",
                valueInitMax: "",
                valueInitMin: "",
                bulkDeleteReadingsMode: false,
                bulkDeleteReadingsPoints: [],
                overlayPoints: [],
                issetRpm: true,
                issetFftTimestamps: true,
                zoomed: false,
                currentChart: null,
                showOverMax: false,
                showTriggers: false,
                isNoData: false,
                softMinCurrent: null,
                softMaxCurrent: null,
            },
            actions: {
                setLoader: (isLoading) => {
                    set((state) => {
                        state.isLoading = isLoading;
                    });
                },
                setNoData: (isNoData) => {
                    set((state) => {
                        state.settings.isNoData = isNoData;
                    });
                },
                setChartMarkId(id) {
                    set((state) => {
                        state.settings.chartMarkId = id;
                    });
                },
                setZoomed(zoomed) {
                    set((state) => ({...state, settings: {...state.settings, zoomed}}));
                },
                setMinMax({valueMin, valueMax}) {
                    set((state) => ({...state, settings: {...state.settings, valueMax, valueMin}}));
                },
                setInitMinMax({valueInitMin, valueInitMax}) {
                    if (
                        get().settings.valueInitMin === "" ||
                        get().settings.valueInitMax === "" ||
                        get().settings.softMinCurrent !== null ||
                        get().settings.softMaxCurrent !== null
                    ) {
                        set((state) => ({...state, settings: {...state.settings, valueInitMax, valueInitMin}}));
                    }
                },
                toggleFullscreen() {
                    set((state) => {
                        state.settings.fullScreenMode = !state.settings.fullScreenMode;
                    });
                },
                toggleAlerts() {
                    set((state) => {
                        state.settings.showAlerts = !state.settings.showAlerts;
                        state.isLoading = true;
                    });
                },
                toggleShowRpm() {
                    set((state) => {
                        state.settings.showRPM = !state.settings.showRPM;
                    });
                },
                toggleShowAmbient() {
                    set((state) => {
                        state.settings.showAmbient = !state.settings.showAmbient;
                        state.isLoading = true;
                    });
                },
                toggleShowFFTShapes(flag = !get().settings.showFFTShapes) {
                    set((state) => {
                        state.settings.showFFTShapes = flag;
                        state.isLoading = true;
                    });
                },
                toggleBulkReadingsMode() {
                    set((state) => {
                        state.settings.bulkDeleteReadingsMode = !state.settings.bulkDeleteReadingsMode;
                    });
                },
                toggleIssetRpm(flag) {
                    set((state) => {
                        state.settings.issetRpm = flag;
                    });
                },
                toggleIssetFftTimestamps(flag) {
                    set((state) => {
                        state.settings.issetFftTimestamps = flag;
                    });
                },
                toggleIssetAmbient(flag) {
                    set((state) => {
                        state.settings.issetAmbient = flag;
                    });
                },
                clearOverlayPoints() {
                    set((state) => {
                        state.settings.overlayPoints = [];
                        state.isLoading = true;
                    });
                },
                setChartMarkDeleteId(id) {
                    set((state) => {
                        state.settings.deleteChartMarkId = id;
                    });
                },
                setOverlayPoints(overlayPoints, isNameplatesOverlay = false) {
                    set((state) => {
                        state.settings.overlayPoints = overlayPoints;
                        state.settings.isNameplatesOverlay = isNameplatesOverlay;
                        state.isLoading = true;
                    });
                },
                changeSelectedSeriesList(selected) {
                    if (!Array.isArray(selected)) {
                        selected = [selected];
                    }
                    selected = selected.sort();

                    set((state) => {
                        state.settings.seriesList.selected = selected;
                        state.settings.seriesList.readingType = selected.map((axisID) => get().settings.seriesList.list[axisID].readingType);
                        state.isLoading = true;
                    });
                },
                updateSelectedSeriesList(points) {
                    const seriesListUpdated = _cloneDeep(get().settings.seriesList);

                    if (points.length > 1) {
                        seriesListUpdated.selected = seriesListUpdated.selected.slice(0, 1);
                        seriesListUpdated.readingType = seriesListUpdated.selected.map((axisId) => seriesListUpdated.list[axisId].readingType);
                    }

                    set((state) => {
                        state.settings.seriesList = seriesListUpdated;
                        state.isLoading = true;
                    });
                },
                setBulkDeleteReadingPoints(e) {
                    const currentChart = _cloneDeep(get().settings.currentChart);
                    let selectedPoints = [];

                    const minTimestamp = _get(e, "target.xAxis.0.min");
                    const maxTimestamp = _get(e, "target.xAxis.0.max");

                    if (minTimestamp && maxTimestamp) {
                        const series = _get(currentChart, "current.chart.series", []);
                        series.map((serial) => {
                            _get(serial, "points", []).map((point) => {
                                if (_get(point, "category") >= minTimestamp && _get(point, "category") <= maxTimestamp) {
                                    selectedPoints.push(point);
                                }
                            });
                        });
                    }

                    set((state) => {
                        state.settings.bulkDeleteReadingsPoints = selectedPoints;
                        state.isLoading = true;
                    });
                },
                clearBulkDeleteReadingPoints() {
                    set((state) => {
                        state.settings.bulkDeleteReadingsPoints = [];
                        state.isLoading = false;
                    });
                },
                setSelectedPoint(point) {
                    set((state) => {
                        state.settings.selectedPoint = point;
                        state.isLoading = true;
                    });
                },
                setCurrentChart(chart) {
                    set((state) => {
                        state.settings.currentChart = chart;
                    });
                },
                toggleShowOverMax() {
                    set((state) => {
                        state.settings.showOverMax = !state.settings.showOverMax;
                    });
                },
                toggleTachometerTriggers() {
                    set((state) => {
                        state.settings.showTriggers = !state.settings.showTriggers;
                    });
                },
                setSoftMinMax(softMin, softMax) {
                    set((state) => {
                        state.settings.softMaxCurrent = softMax;
                        state.settings.softMinCurrent = softMin;
                    });
                },
            },
        }))
    );

    return store.current;
};
