import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { sortedLastIndexBy as _sortedLastIndexBy, zip as _zip, isEqual as _isEqual } from "lodash";
import ChartHelper from "../../../../../../../../helpers/chart";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

const isDarkTheme = localStorage.getItem("lightMode");

const propTypes = {
    onSliderChange: PropTypes.func,
    series: PropTypes.array,
    chartHeight: PropTypes.number,
    trendFrequency: PropTypes.string,
    frequencyType: PropTypes.string,
    precision: PropTypes.number,
    units: PropTypes.string,
    from: PropTypes.number,
    to: PropTypes.number,
};

class Trend3d extends PureComponent {
    constructor(props) {
        super(props);

        this.chartRef = React.createRef();
        this.readingsCache = {};

        this.state = {
            options: {
                chart:
                    isDarkTheme === "true"
                        ? {
                              linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                              stops: [
                                  [0, "#3a3934"],
                                  [1, "#36342a"],
                              ],
                          }
                        : {
                              type: "line",
                          },
                lang: {
                    noData: "Trend frequency not selected",
                },
                credits: {
                    enabled: false,
                },
                title: {
                    text: "",
                },
                plotOptions: {
                    series: {
                        events: {
                            legendItemClick: () => false,
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        formatter: ChartHelper.formatYAxis3dTrend,
                    },
                },
                legend: false,
                exporting: false,
                tooltip: {
                    backgroundColor: null,
                    borderWidth: 0,
                    shadow: false,
                    shape: "rect",
                    useHTML: true,
                    shared: true,
                    formatter: () =>
                        ChartHelper.formatTooltip3dTrend(
                            this.chartRef.current.chart,
                            props.precision,
                            props.units
                        ),
                },
            },
        };

        if (isDarkTheme === "true") {
            this.state.options.chart.backgroundColor = {
                linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                stops: [
                    [0, "#3a3934"],
                    [1, "#36342a"],
                ],
            };

            this.state.options.tooltip = {
                ...this.state.options.tooltip,
                backgroundColor: "rgba(54, 54, 50, 0.9)",
                style: {
                    color: "#ddd",
                },
            };
        }
    }

    componentDidMount() {
        this.redraw();
    }

    componentDidUpdate(prevProps) {
        if (
            !_isEqual(
                prevProps.series.map((series) => series.name),
                this.props.series.map((series) => series.name)
            ) ||
            prevProps.trendFrequency !== this.props.trendFrequency
        ) {
            this.redraw();
        }
    }

    redraw() {
        const { trendFrequency } = this.props;
        let trendPoints = [];
        let categories = [];

        if (!trendFrequency.length) {
            return;
        }

        this.props.series.forEach((series) => {
            if (series.name === "slice") {
                return;
            }
            let trendFrequencyY = this.getSeriesY(series, trendFrequency);
            trendPoints.push(trendFrequencyY);
            categories.push(series.meta.categoryName);
        });

        this.setState({
            options: {
                ...this.state.options,
                series: [
                    {
                        type: "line",
                        name: "Trend",
                        data: trendPoints,
                    },
                ],
                xAxis: {
                    ...this.state.options.xAxis,
                    categories,
                },
            },
        });
    }

    getSeriesY(series, x) {
        x = parseFloat(x);
        const data = _zip(series.y, series.z);
        const lastPoint = data[data.length - 1];
        if (lastPoint[0] < x) {
            return null;
        } else if (lastPoint[0] === x) {
            return lastPoint[1];
        }
        const firstPoint = data[0];
        if (firstPoint[0] > x) {
            return null;
        } else if (firstPoint[0] === x) {
            return firstPoint[1];
        }

        const nextPointIndex = _sortedLastIndexBy(data, { 0: x }, (point) => point[0]);
        const nextPoint = data[nextPointIndex];
        if (+x === nextPoint[0]) {
            return nextPoint[1];
        }
        const prevPointIndex = nextPointIndex - 1;
        const prevPoint = data[prevPointIndex];
        if (+x === prevPoint[0]) {
            return prevPoint[1];
        }

        const point = ChartHelper.getPointBetween(prevPoint, nextPoint, x);
        return point[1];
    }

    render() {
        const { options } = this.state;
        const { chartHeight, frequencyType, trendFrequency, from, to, onSliderChange } = this.props;

        return (
            <>
                <div className="row">
                    <label className="mb-0 col-md-12">Trend Frequency:</label>
                    <div className="input-group input-group-sm col-md-12">
                        <input
                            className="form-control request-form-control"
                            type="number"
                            value={trendFrequency}
                            onChange={(ev) => onSliderChange(ev.target.value)}
                            step={0.01}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text request-form-control">
                                {frequencyType}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row k-margin-15">
                    <Nouislider
                        onChange={onSliderChange}
                        step={0.01}
                        range={{ min: +from, max: +to }}
                        start={trendFrequency}
                        style={{width: "100%"}}
                    />
                </div>
                <div className="row">
                    <HighchartsReact
                        ref={this.chartRef}
                        containerProps={{ className: "fft-chart" }}
                        constructorType={"chart"}
                        highcharts={Highcharts}
                        options={{
                            ...options,
                            chart: {
                                ...options.chart,
                                height: chartHeight - 97,
                            },
                        }}
                    />
                </div>
            </>
        );
    }
}

Trend3d.propTypes = propTypes;

export default Trend3d;
