import {useEffect} from "react";
import Toast from "../../../../shared/toast";
import {CHART_TYPE} from "../../twf/hooks/useChartAbstractFactory";
import ChartHelper from "../../../../../helpers/chart";
import {useChartStateStoreContext} from "../../twf/store/hooks/useStoreContext";
import {useChartPeaks} from "../../twf/hooks/helpers/useChartPeaks";

export const useFftChartNavigationEffect = (chartRef) => {
    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const factory = useChartStateStoreContext((state) => state.factory);

    const {peaks, maxPeak} = useChartPeaks();

    const handleKeyDown = (e) => {
        if (!chartRef.current) {
            return;
        }

        if ((e.keyCode === 37 || e.keyCode === 39) && !chartRef.current.chart.isPaused) {
            // arrow left/right
            if (e.shiftKey === true && chartRef.current.chart.hoverPoints?.length > 1) {
                return Toast.info("Peak navigation only available for single axis");
            }

            if (chartMode == factory.chartModeList.TWF || factory.chartTypeName == CHART_TYPE.FFT) {
                ChartHelper.handleNavigationFftTwf(e, chartRef.current.chart, peaks, maxPeak);
            } else if (chartMode == factory.chartModeList.AUTOCORRELATION) {
                ChartHelper.handleNavigationAutoCorrelation(e, chartRef.current.chart);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [chartMode, peaks, maxPeak]);
};
