import {useTimestampsSyncStoreActions} from "../../../store/sync/timestampsSyncStore";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useIsImpactVue} from "../../../hooks/useChartTypes";

export const useUpdateCurrentDate = () => {
    const {setTimestampsForFft} = useTimestampsSyncStoreActions();
    const installationPointId = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);

    return (timestamps) => setTimestampsForFft(installationPointId, timestamps, isImpactVue);
};
