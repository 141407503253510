import React, {Component} from "react";
import PropTypes from "prop-types";
import SelectWrapper from "../../helpers/select-wrapper";

class AxisSelect extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const {value, onChange, disabled, name, className, axes} = this.props;
        return (
            <SelectWrapper
                className={className}
                name={name}
                onChange={onChange}
                value={value}
                disabled={disabled}
            >
                {axes.length &&
                axes.map(axis => <option key={axis.id} value={axis.id}>{axis.name}</option>)
                }
            </SelectWrapper>
        );
    }
}
AxisSelect.propTypes = {
    name: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    className: PropTypes.string,
    axes: PropTypes.array
};

export default AxisSelect;