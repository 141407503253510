import React from "react";
import PropTypes from "prop-types";

export const ResetSortButton = ({sort, resetSort}) => {
    if (!(sort.field || sort.column)) return "";

    return (
        <button onClick={resetSort} className="btn btn-sm btn-primary pull-right ml-2">
            Reset Sort
        </button>
    );
};

ResetSortButton.propTypes = {
    sort: PropTypes.object,
    resetSort: PropTypes.func
};