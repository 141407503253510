import React from "react";
import Chart from "./components/chart-template";
import SimpleChartTemplate from "./components/simpleChartTemplate";
import {filter as _filter, find as _find} from "lodash";
import {useChartComputed, useChartType} from "../../hooks/helpers/chart";
import PropTypes from "prop-types";
import {
    useAlertConditionStoreActions,
    useCurrentAlertCondition,
    useCurrentAlertGroup,
    useSelectedTab,
    useStdDeviations,
} from "../../../../stores/zustand/AlertConditionStore";
import useAlertLevelsList from "../../../../hooks/api/Alert/useAlertLevelList";
import {Loader} from "../../../../shared";
import {useFindCurrent, useSetAlert} from "../../hooks/helpers/alerts";
import {useTachometer} from "../../hooks/helpers/tachometer";
import $ from "jquery";
import {useAlertConditionListQuery} from "../../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {useInstallationPointIds} from "../../../../hooks/helpers/useInstallationPointIds";
import {useChartSelectedRangeStore} from "../../../../stores/zustand/ChartSelectedRangeStore";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import {useInstallationPoint} from "../../hooks/useInstallationPoint";
import {ALERT_TABS_NAME} from "../../constants/tabs";
import BandNavigationChart from "./components/band-navigation-chart";

const ChartTemplate = ({chartTypes}) => {
    const {equipmentItem: equipment} = useEquipmentByParams();
    const {chartType, isChartFFT, isBand, isEnvelope} = useChartType(chartTypes);
    const {axisId, readingType, hasAxis} = useChartComputed(chartTypes, equipment);

    const currentAlertCondition = useCurrentAlertCondition();
    const alertType = useSelectedTab();
    const {setCurrent, setCurrentIsChanged, setStdDeviations} = useAlertConditionStoreActions();
    const stdDeviations = useStdDeviations();
    const installationPointIds = useInstallationPointIds(equipment);
    const installationPoint = useInstallationPoint(equipment, chartType);
    const installationPointId = installationPoint.id;
    const {isSuccess: isAlertLevelsSuccess} = useAlertLevelsList();
    const {alertConditions, isSuccess: isAlertConditionalSuccess} = useAlertConditionListQuery(chartType, installationPointIds, installationPointId);
    const alertList = alertConditions;
    const currentAlertGroup = useCurrentAlertGroup();

    const setAlert = useSetAlert(chartTypes, equipment, alertType);
    const findCurrent = useFindCurrent(chartTypes, equipment, alertType);
    const {tachometerList: tachometerRpmAlertList} = useTachometer(equipment, chartType);

    const selectedRange = useChartSelectedRangeStore();

    const updateCurrent = (data, withoutChanges) => {
        setCurrent({...currentAlertCondition, ...data});
        if (!withoutChanges) {
            setCurrentIsChanged(true);
        }
    };

    const updateValue = (value, commit = false) => {
        if (commit) updateCurrent({value});
        else {
            const $inputValue = $(".current-alert-value");
            $inputValue && $inputValue.length && $inputValue.val(value);
        }
    };
    const updateStdDeviations = (tempStdDeviations) => {
        if (tempStdDeviations === stdDeviations) return true;
        setCurrent(!tempStdDeviations ? {...currentAlertCondition, fftEnvelopeType: "1"} : currentAlertCondition);
        setStdDeviations(!!tempStdDeviations);
    };

    const getOtherAlertsChart = (currentAlertCondition, level) => {
        if (alertType === ALERT_TABS_NAME.TACHOMETER_RPM) {
            const tachometerRpmAlerts = _filter(
                tachometerRpmAlertList,
                (alert) => +alert.id !== +currentAlertCondition.id && (hasAxis ? +alert.axis_id === axisId : +alert.reading_type_id === readingType)
            );
            return tachometerRpmAlerts || [setAlert(level) || {}];
        }

        if (alertType === ALERT_TABS_NAME.LINEAR) {
            const nearestLinearAlert = _filter(alertList, (l) => {
                return (
                    l.axisId === currentAlertCondition.axisId &&
                    l.id !== currentAlertCondition.id &&
                    l.is_tachometer_rpm !== 1 &&
                    !l.groupId &&
                    l.installationPointId === currentAlertCondition.installationPointId
                );
            });
            return nearestLinearAlert;
        }

        if (alertType === ALERT_TABS_NAME.DELTA_T) {
            const nearestDeltaTAlert = _filter(currentAlertGroup.alerts, (l) => {
                return l.axisId === currentAlertCondition.axisId && l.id !== currentAlertCondition.id;
            });

            return nearestDeltaTAlert;
        }

        return [findCurrent(level.id) || setAlert(level) || {}];
    };

    if (!isAlertConditionalSuccess || !isAlertLevelsSuccess) {
        return <Loader />;
    }

    if (isChartFFT) {
        if (Object.keys(currentAlertCondition).length) {
            return (
                <>
                    <Chart
                        alert={currentAlertCondition}
                        isBand={isBand}
                        isEnvelope={isEnvelope}
                        // currentDate={""}
                        chartDrawFlag={false}
                        update={updateCurrent}
                        updateValue={updateValue}
                        updateStdDeviations={updateStdDeviations}
                        selectedRange={selectedRange}
                    />
                    {isBand && (
                        <BandNavigationChart
                            equipment={equipment}
                            data={currentAlertCondition}
                            alert={currentAlertCondition}
                        />
                    )}
                </>
            );
        }
        return <></>;
    }
    return (
        <SimpleChartTemplate
            alert={currentAlertCondition}
            updateAlertValue={updateValue}
            otherAlerts={getOtherAlertsChart(
                currentAlertCondition,
                _find(alertList, (l) => +l.alertLevelId !== +currentAlertCondition.alertLevelId) || {}
            )}
        />
    );
};
ChartTemplate.propTypes = {
    chartTypes: PropTypes.array,
    equipment: PropTypes.object,
    readingOverMax: PropTypes.func,
};
export default ChartTemplate;
