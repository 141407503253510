import React from "react";
import {ReactComponent as Logo} from "../../assets/pic/icon-tm.svg";
import {useIsDarkTheme} from "../../hooks/helpers/theme";
import PropTypes from "prop-types";

const ImpactVueText = ({type}) => {
    const isDarkTheme = useIsDarkTheme() === "true";
    let text = "";
    if (type === "fft") {
        text = "FFT ";
    } else if (type === "rms") {
        text = "RMS ";
    }

    return (
        <p
            style={{position: "relative", top: 1}}
            className="d-inline-flex align-top m-0"
        >
            {text}ImpactVUE
            <Logo fill={isDarkTheme ? "#cccccc" : "#212529"} />
        </p>
    );
};

ImpactVueText.propTypes = {
    type: PropTypes.string,
};

export default ImpactVueText;
