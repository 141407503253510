import React from "react";
import PropTypes from "prop-types";

const InfoAlertText = ({children}) => {
    return (
        <div>
            <span className="secondary-text">
                <i className="fa fa-info-circle mr-1" />
                Alerts can be used to notify users when a metric data reaches a defined level.
                {children}
            </span>
        </div>
    );
};
InfoAlertText.propTypes = {
    children: PropTypes.node,
};
export default InfoAlertText;
