import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get
} from "lodash";
import PhotosWrapper from "../../../shared/photosWrapper/photosWrapper";

class ShowNameplate extends Component
{
    render() {
        const {nameplate, onDelete} = this.props;

        const parameters = _get(nameplate, "parameters", []);
        const photos = _get(nameplate, "photos", []);

        return (
            <React.Fragment>
                <div className={"nameplate-component-block"}>
                    <button
                        className={"link link-danger"}
                        style={{fontSize: "17px", position: "absolute", right: "4px"}}
                        onClick={() => {
                            onDelete(nameplate.id);
                        }}
                        title={"Delete"}
                    >
                        <i className="fa fa-times-circle"/>
                    </button>
                    <div><strong>{_get(nameplate, "name")}</strong></div>
                    {!!photos.length &&
                        <div className={"mt-3 w-50"}>
                            <PhotosWrapper
                                photos={photos}
                                withPhotoModal={true}
                                withDropzone={false}
                                defaultPhoto={"picture.svg"}
                                withoutDeleteModalBtn={true}
                            />
                        </div>
                    }
                    {!!parameters.length &&
                        <div className={"nameplate-template-table mt-3"}>
                            {parameters.map((parameter, index) =>
                                <div key={index} className={"template-row"}>
                                    <div className={"template-col"}>
                                        <strong>{_get(parameter, "name")}</strong>
                                    </div>
                                    <div className={"template-col"}>
                                        {_get(parameter, "value")}
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

ShowNameplate.propTypes = {
    nameplate: PropTypes.object,
    onDelete: PropTypes.func,
};

export default ShowNameplate;