import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import ApiAlertLevel from "../../api/alertLevel";
import ApiAlarms from "../../api/alarm";
import ApiAxisLabel from "../../api/axisLabel";
import ApiReadingTypes from "../../api/readingTypes";
import {Loader, Pager} from "../../shared";
import HistoryTable from "./history-table";
import SearchInput from "../shared/search-input";
import AlarmFilters from "./alarm-filters";
import LimitSelect from "../shared/limit-select";
import {withGlobalStore} from "../../stores/GlobalStore";
import {withLocationSelectStore} from "../../stores/LocationSelectStore";
import Helper from "../../helpers/helper";
import {HeaderSimple} from "../../shared/header";
import {useExportHistoricalAlertMutation} from "../../hooks/api/Alert/useExportHistoricalAlertMutation";
import {saveAsCsvFile} from "../../helpers/saveAsCsvFile";
import DatePickerInput from "../../shared/datePickerInput/datePickerInput";
import moment from "moment/moment";
import ValidationError from "../../layouts/ValidationError/ValidationError";

const AlarmsHistory = (props) => {
    const [startDay, setStartDay] = useState(() => moment().subtract(1, "month").format(Helper.getUserDateFormat(props.user, true).date));

    const [endDay, setEndDay] = useState(() => moment().format(Helper.getUserDateFormat(props.user, true).date));

    const [state, setState] = useState({
        breadcrumbs: [{name: "Triggered Alert History"}],
        loader: true,
        list: [],
        settingsStates: {},
        axisLabels: {},
        pagination: {
            page: 1,
            pages: 1,
            perpage: Helper.getPerPage("alarms-history"),
            total: 0,
        },
        sort: {
            field: "",
            sort: "asc",
        },
        filter: {
            chartType: "",
            type: "",
            axis: "",
            level: "",
            query: "",
            location: props.getLocationId(),
        },
        levels: [],
        readings: [],
        globalSearchString: "",
    });

    const controller = useRef(null);
    const signal = useRef(null);

    const finished = useRef(false);

    const handleSave = (data) => saveAsCsvFile(data, "History_Alerts");

    const {mutate: exportHistoricalAlertMutate, isLoading} = useExportHistoricalAlertMutation({onSuccess: handleSave});

    const momentStartDate = moment(endDay, Helper.getUserDateFormat(props.user, true).date);
    const momentEndDate = moment(startDay, Helper.getUserDateFormat(props.user, true).date);

    const isStartDateHigherEnd = momentStartDate < momentEndDate;

    const isHigherThanMonth = moment.duration(momentStartDate.diff(momentEndDate)).asDays() > 31;

    useEffect(() => {
        if (!state.loader) {
            return;
        }
        handleFetch();
    }, [state.loader]);

    const handleExportHistoricalAlert = () => {
        const parsedStartDay = moment(startDay, Helper.getUserDateFormat(props.user, true).date);
        const parsedEndDay = moment(endDay, Helper.getUserDateFormat(props.user, true).date);

        exportHistoricalAlertMutate({
            from: parsedStartDay.format("YYYY-MM-DD"),
            to: parsedEndDay.format("YYYY-MM-DD"),
            assetBranchId: filter.location,
            chartType: filter.chartType,
            type: filter.type,
            axis: filter.axis,
            level: filter.level,
            query: filter.query,
        });
    };

    const handleChangeGlobalSearch = (globalSearchString) => {
        setState((prev) => ({...prev, globalSearchString: globalSearchString}));
    };

    const handleSearchFetch = () => {
        controller.current = new window.AbortController();
        signal.current = controller.signal;

        ApiAlarms.getHistoryList({
            query: {
                sort: state.sort,
                filter: state.filter,
                pagination: state.pagination,
            },
            signal: signal.current,
        }).then((response) => {
            if (response) {
                setState((prev) => ({
                    ...prev,
                    loader: false,
                    list: response ? response.list : [],
                    settingsStates: response ? response.settingsStates : {},
                    pagination: Object.assign({}, state.pagination, response ? response.meta : {}),
                }));
                if (!finished.current) {
                    finished.current = true;
                }
            }
        });
    };

    const handleFetch = () => {
        Promise.all([ApiAlertLevel.getList(), ApiReadingTypes.list(), ApiAxisLabel.get()]).then(([levels, readings, axisLabels]) => {
            setState((prev) => ({
                ...prev,
                levels: levels.list,
                axisLabels: axisLabels.data || {},
                readings: [].concat(...Object.values(readings.list)),
            }));
            handleSearchFetch();
        });
    };

    const handleSortChange = (field = "") => {
        const {sort} = state;

        setState((prev) => ({
            ...prev,
            sort: Object.assign(
                {},
                {
                    field,
                    sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc",
                }
            ),
        }));
        handleSearchFetch();
    };

    const handlePagerChange = (page = 1) => {
        setState((prev) => ({...prev, loader: true, pagination: Object.assign({}, prev.pagination, {page})}));
    };

    const handleFilterChange = (obj = {}) => {
        finished.current = false;
        setState((prev) => ({
            ...prev,
            pagination: Object.assign({}, state.pagination, {page: 1}),
            filter: {
                ...prev.filter,
                ...obj,
            },
            loader: true,
        }));
    };

    const bindSearch = (value) => {
        if (!finished) {
            finished.current = true;
            controller.abort();
        }
        handleFilterChange({query: value});
    };
    const handleLimitChange = (e) => {
        const {name, value} = e.target;
        Helper.setPerPage("alarms-history", value);
        setState((prev) => ({
            ...prev,
            loader: true,
            pagination: {
                ...prev.pagination,
                page: 1,
                [name]: value,
            },
        }));
    };

    const {breadcrumbs, loader, list, axisLabels, pagination, sort, filter, settingsStates, globalSearchString} = state;

    const {user} = props;

    return (
        <div>
            <HeaderSimple
                breadcrumbs={breadcrumbs}
                globalSearchString={globalSearchString}
                handleChangeGlobalSearch={handleChangeGlobalSearch}
            />
            <div className="subheader">
                <div className="subheader-title">Triggered Alert History</div>
            </div>
            <div className="block">
                <div className="block-header">
                    <div className="block-label">
                        <AlarmFilters updated={handleFilterChange} />
                    </div>
                    <div
                        id="top-pagination-block"
                        className="block-pagination"
                    >
                        {pagination.total > 10 && (
                            <div className="limit-select-block">
                                <LimitSelect
                                    name={"perpage"}
                                    onChange={handleLimitChange}
                                    defaultValue={pagination.perpage}
                                    limits={[10, 20, 50, 100]}
                                />
                            </div>
                        )}
                        {pagination.pages > 1 && (
                            <Pager
                                page={pagination.page}
                                pages={pagination.pages}
                                onPagerChange={handlePagerChange}
                            />
                        )}
                    </div>
                    <div className="block-toolbar">
                        <div className="block-toolbar-wrapper">
                            <div style={{minWidth: "180px"}}>
                                <SearchInput
                                    onChange={bindSearch}
                                    query={filter.query}
                                    placeholder="Search Alerts"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-body">
                    {loader ? (
                        <Loader />
                    ) : (
                        <div>
                            <HistoryTable
                                list={list}
                                settingsStates={settingsStates}
                                profile={user}
                                axisLabels={axisLabels}
                                sort={sort}
                                filter={filter}
                                onSortChange={handleSortChange}
                            />
                        </div>
                    )}
                    {!loader && (
                        <div>
                            <div className={`form-list form-list--row ${isHigherThanMonth && "mb-3"}`}>
                                <div className="form-group">
                                    <span className="mr-2 ml-2">From</span>
                                </div>
                                <div className={"form-group show-error"}>
                                    <DatePickerInput
                                        value={startDay}
                                        fixedHeight
                                        className={`form-control request-form-control ${isStartDateHigherEnd && "is-invalid"}`}
                                        onChangeHandler={setStartDay}
                                        displayFormat={Helper.getUserDateFormat(props.user, true).date}
                                        valueFormat={Helper.getUserDateFormat(props.user, true).date}
                                        maxDate={moment().format(Helper.getUserDateFormat(user, true).date)}
                                        minDate={moment().subtract(12, "month").format(Helper.getUserDateFormat(props.user, true).date)}
                                    />
                                    {isStartDateHigherEnd && <ValidationError message="From Date must be earlier than End Date." />}
                                </div>
                                <div className="form-group">
                                    <span className="mr-2 ml-2">To</span>
                                </div>
                                <div className={"form-group show-error"}>
                                    <DatePickerInput
                                        value={endDay}
                                        fixedHeight
                                        className={`form-control request-form-control ${isHigherThanMonth && "is-invalid"}`}
                                        onChangeHandler={setEndDay}
                                        maxDate={moment().format(Helper.getUserDateFormat(user, true).date)}
                                        displayFormat={Helper.getUserDateFormat(props.user, true).date}
                                        valueFormat={Helper.getUserDateFormat(props.user, true).date}
                                        minDate={moment().subtract(12, "month").format(Helper.getUserDateFormat(props.user, true).date)}
                                    />
                                    {isHigherThanMonth && (
                                        <ValidationError message="The To date must be later than the From date and cannot exceed it by more than 31 days." />
                                    )}
                                </div>
                                <div className={"form-group"}>
                                    <button
                                        disabled={isLoading || isStartDateHigherEnd || isHigherThanMonth}
                                        onClick={handleExportHistoricalAlert}
                                        className="btn v2-btn blue ml-3"
                                        style={{width: 100}}
                                    >
                                        <img
                                            src={"/assets/pic/icon-download.svg"}
                                            alt=""
                                        />
                                        <span>Export</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

AlarmsHistory.propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,

    // LocationSelectStore
    getLocationId: PropTypes.func,
};

export default withLocationSelectStore(withGlobalStore(AlarmsHistory));
