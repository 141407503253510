import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, ValidationError} from "../shared";
import {
    get as _get,
    set as _set
} from "lodash";
import {withGlobalStore} from "../stores/GlobalStore";
import FormValidator from "../helpers/form-validator";
import CustomerApi from "../api/customer";
import cookies from "react-cookies";
import {INACTIVE_CUSTOMER_STATUS_TYPE} from "../constants/constants";

const rules = [
    {
        field: "customer_id",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
];

class CustomerSelectModal extends Component
{
    constructor(props) {
        super(props);

        this.validator = new FormValidator(rules);

        this.state = {
            data: {
                customer_id: "",
            },
            inProgress: false,
            validation: this.validator.valid(),
            showCancel: cookies.load(process.env.CUSTOMER_COOKIE_PREFIX + "customer_id_previous") ? false : true
        };
    }

    onChange = (e) => {
        const data = {...this.state.data};

        const name = _get(e, "target.name", "");
        const value = _get(e, "target.value", "");

        _set(data, name, value);

        const validation = this.validator.validate(data, name);

        this.setState({data, validation});
    }

    onClose = () => {
        const cookiesPrevId = cookies.load(process.env.CUSTOMER_COOKIE_PREFIX + "customer_id_previous");

        this.setState(
            {inProgress: true},
            () => {
                CustomerApi
                    .set({
                        customer_id: cookiesPrevId
                    })
                    .then(() => {
                        window.location.reload(false);
                    })
                    .catch(() => {
                        this.setState({inProgress: false});
                    });
            }
        );
    }

    onSave = () => {
        const validation = this.validator.validate(this.state.data);
        const {data} = this.state;

        this.setState(
            {inProgress: true},
            () => {
                this.setState({validation}, () => {
                    if (validation.isValid) {
                        CustomerApi
                            .set(data)
                            .then(() => {
                                window.location.reload(false);
                            })
                            .catch(() => {
                                this.setState({inProgress: false});
                            });
                    } else {
                        this.setState({inProgress: false});
                    }
                });
            }
        );
    }

    render() {
        const {inProgress, data, validation, showCancel} = this.state;
        const {auth} = this.props;

        return (
            <React.Fragment>
                <Modal
                    {...this.props}
                    showModal={true}
                    size="lg"
                    title={"SELECT A CUSTOMER TO VIEW"}
                    withoutCancel={showCancel}
                    onClose={this.onClose}
                    onSubmit={this.onSave}
                >
                    <div className="form-group">
                        <div className="">
                            <select
                                className={"form-control form-select" + (validation.customer_id.isValid || !validation.customer_id.message ? "" : " is-invalid")}
                                name="customer_id"
                                value={data.customer_id}
                                onChange={this.onChange}
                                disabled={inProgress}
                            >
                                <option>Select Customer</option>
                                {auth.allowedCustomers.map(customer => (
                                    <option key={customer.customer_id} disabled={+customer.status_type === INACTIVE_CUSTOMER_STATUS_TYPE} value={customer.customer_id}>{customer.name} ({customer.system_version})</option>
                                ))}
                            </select>
                            <ValidationError message={validation.customer_id.message}/>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}


CustomerSelectModal.propTypes = {
    auth: PropTypes.object,
};

export default withGlobalStore(CustomerSelectModal);