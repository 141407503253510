import Api from "./api";

const AlertGroupsApi = {
    get: id => Api.get("subscription-groups/" + id),
    tree: id => Api.get(`subscription-groups/${id}/tree`),
    list: query => Api.get("subscription-groups", {query}),
    store: data => Api.post("subscription-groups", data),
    update: (id, data) => Api.patch("subscription-groups/" + id, data),
    delete: id => Api.delete("subscription-groups/" + id),
    facilities: () => Api.get("subscription-group-options/facilities"),
    equipments: () => Api.get("subscription-group-options/equipment"),
    locations: () => Api.get("subscription-group-options/locations"),
    installationPoints: () => Api.get("subscription-group-options/installation-points")
};

export default AlertGroupsApi;