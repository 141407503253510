import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import Helper from "../helpers/helper";
import { get as _get } from "lodash";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const currentAlertStore = create(
    immer((set) => ({
        currentAlert: null,
        isScrolled: false,
        target: null,
        actions: {
            setCurrentAlert: (alert) => {
                let installationPoint = +_get(alert, "installationPointId");
                set((state) => {
                    state.currentAlert = alert;
                    state.isScrolled = false;
                    if(alert){
                        history.push(Helper.setHashParams({sensors: installationPoint, alertId: alert.id}));
                    }
                });
            },
            setIsScrolled: () => {
                set((state) => {
                    state.isScrolled = true;
                });
            },
            clearCurrentAlert: () => {
                set((state) => {
                    state.isScrolled = false;
                    state.target = null;
                    state.currentAlert = null;
                });
            },
            setTarget: (value) => {
                set((state) => {
                    state.target = value;
                });
            }
        },
    }))
);

export const useCurrentAlertActions = () => currentAlertStore((state) => state.actions);
export const useCurrentAlert = () => currentAlertStore((state) => state.currentAlert);
export const useCurrentAlertTarget = () => currentAlertStore((state) => state.target);
export const useCurrentAlertScrolled = () => currentAlertStore((state) => state.isScrolled);
