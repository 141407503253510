import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {useEquipmentByParams} from "../../../../../hooks/api/equipment/useEquipmentQuery";
import {get as _get} from "lodash";
import {UncontrolledTooltip} from "reactstrap";

const ShowTachTriggers = ({showTriggers, toggleTachometerTriggers}) => {
    const {equipmentItem: equipment} = useEquipmentByParams();

    const maxRpm = useMemo(() => _get(equipment, ["tachometer", "nodeTriggerSetting", "max_rpm"], null), [equipment]);

    return (
        <div className="chart-block-actions justify-content-end">
            <div
                className="pull-right"
                id="showOverMaxRpmControlModal"
            >
                <span
                    className={"alert-link"}
                    onClick={toggleTachometerTriggers}
                >
                    <label className="switch">
                        <input
                            readOnly={true}
                            type="checkbox"
                            checked={showTriggers ? "checked" : false}
                        />
                        <span className="slider round" />
                    </label>
                    Show Over Max RPM
                </span>
            </div>
            <div className="clearfix" />
            <UncontrolledTooltip target={"#showOverMaxRpmControlModal"}>{maxRpm === null ? "Max RPM Not Set" : `Max RPM: ${maxRpm}`}</UncontrolledTooltip>
        </div>
    );
};

ShowTachTriggers.propTypes = {
    showTriggers: PropTypes.bool,
    toggleTachometerTriggers: PropTypes.func,
};

export default ShowTachTriggers;
