import React, {Component} from "react";
import PropTypes from "prop-types";
import FormValidator from "../../../helpers/form-validator";
import {ValidationError} from "../../../shared";
import {Loader} from "../../../shared";
import {
    get as _get,
    set as _set,
} from "lodash";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import SearchInput from "../../shared/search-input";
import {withGlobalStore} from "../../../stores/GlobalStore";
import ApiGateways from "../../../api/gateway";
import ApiMotes from "../../../api/mote";
import WifiGroupsApi from "../../../api/wifiGroups";
import Toast from "../../shared/toast";
import {SENSOR_TYPES} from "../../../constants/constants";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import SelectWrapper from "../../../helpers/select-wrapper";
import { HeaderSimple } from "../../../shared/header";

class MoteAdd extends Component
{
    constructor(props) {
        super(props);

        this.rules = [
            {
                field: "serial",
                method: "isEmpty",
                validWhen: false,
                message: "This field is required."
            },
            {
                field: "gateway_id",
                method: "isEmpty",
                validWhen: false,
                skipOnEmpty: () => !!this.state.data.wifi_config_group_id || !this.state.moteObject,
                message: props.auth.userCan("manageWiFiGroup") ? "Gateway or wifi group field is required." : "This field is required."
            },
            {
                field: "wifi_config_group_id",
                method: "isEmpty",
                validWhen: false,
                skipOnEmpty: () => !!this.state.data.gateway_id || !this.state.moteObject,
                message: "This field is required."
            },
            {
                field: "confirm_flag",
                method: "isEmpty",
                validWhen: false,
                skipOnEmpty: () => !!this.state.data.confirm_flag,
                message: "TEst"
            },
        ];

        this.validator = new FormValidator(this.rules);

        this.state = {
            title: "Add Sensor Mote",
            data: {
                serial: "",
                gateway_id: "",
                location_info: "",
                location_id: "",
                wifi_config_group_id: "",
                confirm_flag: {}
            },
            breadcrumbs: [
                {name: "Manage Sensor Motes", link: "/network/motes"},
                {name: "Add Sensor Mote"}
            ],
            gateways: [],
            loader: false,
            validation: this.validator.valid(),
            globalSearchString: "",
            wifiGroups: [],
            confirmErrors: {},
            moteObject: false,
            disableLocation: false
        };

        this.handleGateways = this.handleGateways.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.setConfirmFlag = this.setConfirmFlag.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
    }

    componentDidMount() {
        Promise.all([
            this.handleGateways(),
            this.handleWifiGroups()
        ]).then(() => {
            this.setState({
                loader: false,
            });
        });
    }

    handleGateways() {
        return ApiGateways.list({query: {pagination: {page: 1, perpage: 100}}}).then(({list}) => {
            this.setState({gateways: list});
        });
    }

    handleWifiGroups = () => {
        return WifiGroupsApi.getList({query: {pagination: {page: 1, perpage: 100}}}).then(({list}) => {
            this.setState({wifiGroups: list});
        });
    }

    handleChangeGlobalSearch (globalSearchString) {
        this.setState({globalSearchString: globalSearchString});
    }

    onChange(e) {
        const data = {...this.state.data};

        const name = _get(e, "target.name", "");
        const value = _get(e, "target.value", "");

        _set(data, name, value);
        const validation = this.validator.validate(data, name);

        this.setState({data, validation});
    }

    handleSubmit() {
        const validation = this.validator.validate(this.state.data);
        this.setState(
            {inProgress: true},
            () => {
                this.setState({validation}, () => {
                    if (validation.isValid) {
                        ApiMotes.associate(this.state.data).then(resp => {
                            if (resp.status === "ok") {
                                this.props.history.push("/network/motes");
                                Toast.success("The sensor mote has been added.");
                            } else if (resp.errors) {
                                const validation = this.state.validation;

                                Object.keys(resp.errors.error).map(key => {
                                    if (typeof (validation[key]) !== "undefined") {
                                        validation[key].isValid = false;
                                        validation[key].message = resp.errors.error[key];
                                    }else{
                                        Toast.error("Something went wrong. The Motes have not been associated with the gateway.");
                                    }
                                });

                                this.setState({validation, inProgress: false, confirmErrors : resp.errors.confirm || {}});
                            }
                        });
                    } else {
                        this.setState({inProgress: false});
                    }
                });
            }
        );
    }

    searchChange(value) {
        this.handleFilterChange({query: value});
    }

    setConfirmFlag(flag, status){
        let oldData = {...this.state.data};
        if (status) {
            oldData.confirm_flag[flag] = flag;
        } else {
            delete oldData.confirm_flag[flag];
        }
        const validation = this.validator.validate(oldData, "confirm_flag");
        this.setState({data: oldData, validation});
    }

    validateSerial = () => {
        const validation = this.validator.validate(this.state.data);
        this.setState(
            {inProgress: true},
            () => {
                this.setState({validation}, () => {
                    if (validation.isValid) {
                        ApiMotes.validateSerial({serial: this.state.data.serial}).then(resp => {
                            if (resp.status === "ok") {
                                const locationId = _get(resp, "mote.moteSensors.0.installationPoint.equipment.location_id") || null;
                                this.setState({
                                    inProgress: false,
                                    moteObject: _get(resp, "mote"),
                                    data: {...this.state.data, ...{location_id: locationId}},
                                    disableLocation: !!locationId
                                });
                            } else if (resp.errors) {
                                const validation = this.state.validation;

                                Object.keys(resp.errors).map(key => {
                                    validation[key].isValid = false;
                                    validation[key].message = ((resp.errors[key]) ? resp.errors[key] : "A mote with serial number '"  + this.state.data.serial + "' does not exist.");
                                });

                                this.setState({validation, inProgress: false});
                            }
                        });
                    } else {
                        this.setState({inProgress: false});
                    }
                });
            }
        );
    }

    render() {
        const {
            breadcrumbs,
            loader,
            globalSearchString,
            title,
            inProgress,
            data,
            validation,
            gateways,
            wifiGroups,
            confirmErrors,
            moteObject
        } = this.state;
        const {user, auth} = this.props;
        const instPointErrors = [];
        const selfTestErrors = [];
        {
            for (let type in confirmErrors) {
                if (type == 1) {
                    instPointErrors.push(confirmErrors[type]);
                    continue;
                }
                if (type == 2) {
                    selfTestErrors.push(confirmErrors[type]);
                    continue;
                }
            }

        }
        return (
            <div className="">
                 <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">{title}</div>
                </div>
                {loader
                    ?
                    <div className="loader-fullscreen"><Loader/></div>
                    :
                    <div>
                        <div className="block">
                            <div className="block-body">
                                {!moteObject &&
                                    <div className="form-group">
                                        <label className="text-right form-label">Serial Number: <span className="color-danger">*</span></label>
                                        <div className="">
                                            <input
                                                className={"form-control" + (validation.serial.isValid || !validation.serial.message ? "" : " is-invalid")}
                                                type="text"
                                                name="serial"
                                                defaultValue={data.serial}
                                                onChange={this.onChange}
                                                disabled={inProgress}
                                            />
                                            <ValidationError message={validation.serial.message}/>
                                        </div>
                                    </div>
                                }
                                {moteObject &&
                                    <>
                                        <div className={"mb-2"}>
                                            <span>Serial Number: <b>{data.serial}</b></span>
                                            <span className={"ml-2"}>Sensor Type: <b>{_get(moteObject, "version_name")}</b></span>
                                        </div>
                                        {(auth.userCan("manageWiFiGroup") && +_get(moteObject, "moteSensors.0.version_type") === SENSOR_TYPES.WIFI)
                                            ?
                                            <div className="form-group">
                                                <label className="text-right form-label">Wifi Mote Group:</label>
                                                <div className="">
                                                    <SelectWrapper
                                                        className={(validation.wifi_config_group_id.isValid || !validation.wifi_config_group_id.message ? "" : " is-invalid")}
                                                        style={{
                                                            width:"100%",
                                                            display:"block",
                                                        }}
                                                        name="wifi_config_group_id"
                                                        value={+data.wifi_config_group_id}
                                                        onChange={this.onChange}
                                                        disabled={inProgress}
                                                        placeholder={"Select Wifi Mote Group"}
                                                    >
                                                        {wifiGroups.map(group => (
                                                            <option key={group.id} value={group.id}>{_get(group, "name")}</option>
                                                        ))}
                                                    </SelectWrapper>
                                                    <ValidationError message={validation.wifi_config_group_id.message}/>
                                                </div>
                                            </div>
                                            :
                                            <div className="form-group">
                                                <label className="text-right form-label">Gateway: <span className="color-danger">*</span></label>
                                                <div className="">
                                                    <SelectWrapper
                                                        className={(validation.gateway_id.isValid || !validation.gateway_id.message ? "" : " is-invalid")}
                                                        style={{
                                                            width:"100%",
                                                            display:"block",
                                                        }}
                                                        name="gateway_id"
                                                        value={+data.gateway_id}
                                                        onChange={this.onChange}
                                                        disabled={inProgress}
                                                        placeholder={"Select Gateway"}
                                                    >
                                                        {/*<option value="">Select Gateway</option>*/}
                                                        {gateways.map(gateway => (
                                                            <option key={gateway.gateway_id} value={gateway.gateway_id}>{gateway.name ? gateway.name + " (" + gateway.serial + ")" : gateway.serial}</option>
                                                        ))}
                                                    </SelectWrapper>
                                                    <ValidationError message={validation.gateway_id.message}/>
                                                </div>
                                            </div>
                                        }
                                        <div className="form-group">
                                            <label className="text-right form-label">Location:</label>
                                            <div className="">
                                                <input
                                                    className={"form-control"}
                                                    type="text"
                                                    name="location_info"
                                                    defaultValue={data.location_info}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="text-right form-label">Asset Tree Branch:</label>
                                            <div className="">
                                                <CollapseLocationSelect
                                                    className={"form-control"}
                                                    selectName={"location_id"}
                                                    value={+_get(data, "location_id", null)}
                                                    onChange={this.onChange}
                                                    emptyOptionLabel={"Select Location"}
                                                    allowedLocationIds={_get(user, "allowedLocationIds", [])}
                                                    disabled={this.state.disableLocation}
                                                />
                                            </div>
                                        </div>
                                        {!!instPointErrors.length &&
                                            <div className="form-group border-confirm-block">
                                                <div className="col-md-12 text-center">
                                                    <ValidationError display={""} message={validation.confirm_flag.message}/>
                                                    {instPointErrors.map((error, index) =>
                                                        <div className="text-error" key={index}>{error} </div>
                                                    )}
                                                    <div>
                                                        <label className="form-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                defaultChecked={false}
                                                                value={"clearInstPoint"}
                                                                onChange={(ev) => this.setConfirmFlag(ev.target.value, ev.target.checked)}
                                                            />Clear Installation Point association<span/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className="text-right">
                            {moteObject
                                ?
                                <>
                                    <Button className="btn btn-sm btn-secondary" disabled={this.state.inProgress} onClick={() => this.setState({moteObject: false})}>Cancel</Button>
                                    <Button color="primary" size="sm" className="ml-2" disabled={this.state.inProgress} onClick={this.handleSubmit}>Save</Button>
                                </>
                                :
                                <>
                                    <Link to="/network/motes" className="btn btn-sm btn-secondary" disabled={this.state.inProgress}>Cancel</Link>
                                    <Button color="primary" size="sm" className="ml-2" disabled={this.state.inProgress} onClick={this.validateSerial}>Next</Button>
                                </>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item ml-2">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

MoteAdd.propTypes = {
    title: PropTypes.string,
    auth: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object
};

export default withGlobalStore(MoteAdd);