import React, {Component} from "react";
import PropTypes from "prop-types";
import Creatable from "react-select/creatable/dist/react-select.esm";
import {ValidationError} from "../../../../shared";
import NameplateParameterApi from "../../../../api/nameplateParameter";
import {
    get as _get,
    find as _find
} from "lodash";

const selectStyles = {
    control: (provided) => ({
        ...provided,
        height: 32,
        minHeight: 32
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: "100000"
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#9E9E9E",
    }),
    option: (provided) => ({
        ...provided,
        padding: "0 7px"
    }),
};

const darkModeSelectStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: "rgb(56, 56, 46)",
        height: 32,
        minHeight: 32
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#ccc"
    }),
    singleValueLabel: (provided) => ({
        ...provided,
        color: "#ccc"
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#36342a"
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: "100000"
    }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused
                ? "#aaa"
                : null,
            color: isFocused
                ? "#333"
                : "#ddd",
            padding: "0 7px"
        };
    },
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    input: (provided) => ({
        ...provided,
        color: "#ccc"
    })
};

const isDarkTheme = localStorage.getItem("lightMode");

class NameplateParameterSelect extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            formErrors: {},
        };
    }

    onChangeSelect = (target, event) => {
        this.setState({formErrors: {}});
        if (event.action === "create-option") {
            this.createParameter(target.value, event.name);
        }
        if (event.action === "select-option") {
            this.updateParameter(event.name, target.value);
        }
        if (event.action === "clear") {
            this.updateParameter(event.name, "");
        }
    }

    createParameter = (newName, fieldName) => {
        const {updateParameters} = this.props;

        NameplateParameterApi
            .create({name: newName})
            .then((response) => {
                if (response.status === "ok" && response.result) {
                    const id = (response.result || {}).id || "";
                    updateParameters().then(() => this.updateParameter(fieldName, id));
                }
            })
            .catch(response => {
                if (response.status === 422) {
                    this.setState({formErrors: response.errors || {}});
                }
            });
    }

    updateParameter = (name, value) => {
        const {onChange} = this.props;

        onChange({
            target: {
                name: name,
                value: value
            }
        });
    }

    render() {
        const {formErrors} = this.state;
        const {parameters, name, value, errorMsg} = this.props;

        const options = (parameters || []).map(parameter => {
            return {label: parameter.name, value: +parameter.id};
        });

        return (
            <div className={"position-relative parameter-select-wrapper"}>
                <Creatable
                    className={"form-control-select" + (_get(formErrors, "name") || errorMsg ? " is-invalid" : "")}
                    name={name}
                    value={_find(options, {value: value})}
                    styles={ isDarkTheme === "true" ? darkModeSelectStyles : selectStyles }
                    options={options}
                    noOptionsMessage={() => {return "No options exist - you must enter a new value in the field and click enter on your keyboard.";}}
                    isClearable={true}
                    placehoder={"Select Parameter"}
                    onChange={this.onChangeSelect}
                    closeMenuOnScroll={(e) => e && e.target && e.target.classList && e.target.classList.contains("custom-scroll")}
                    menuPortalTarget={document.body}
                    menuPlacement={"auto"}
                    formatCreateLabel={(label) => "Create \"" + label + "\" (press enter to create new)"}
                />
                <ValidationError message={_get(formErrors, "name")}/>
            </div>
        );
    }
}

NameplateParameterSelect.propTypes = {
    parameters: PropTypes.array,
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    errorMsg: PropTypes.string,
    updateParameters: PropTypes.func
};

export default NameplateParameterSelect;