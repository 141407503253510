import React, {Component} from "react";
import PropTypes from "prop-types";
import NameplateComponent from "./component";
import InstallationPointApi from "../../../api/installationPoint";

class InstallationPointForm extends Component
{
    onCreateNameplate = (data) => {
        const {installationPoint} = this.props;

        return InstallationPointApi.createNameplate(installationPoint.id, data);
    }

    onDelete = (id) => {
        const {installationPoint} = this.props;

        return InstallationPointApi.deleteNameplate(installationPoint.id, id);
    }

    render() {
        const {
            installationPoint,
            templates,
            installationPointNameplates,
            reloadEquipment,
            updateTemplates,
            parametersList,
            updateParametersList,
            nameplatesList,
            updateNameplatesList
        } = this.props;

        return (
            <React.Fragment>
                <div className={"mb-3"}>
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <NameplateComponent
                                name={installationPoint.name}
                                templates={templates}
                                nameplates={installationPointNameplates}
                                nameplatesList={nameplatesList}
                                updateNameplatesList={updateNameplatesList}
                                reloadEquipment={reloadEquipment}
                                updateTemplates={updateTemplates}
                                onSave={this.onCreateNameplate}
                                onDelete={this.onDelete}
                                parametersList={parametersList}
                                updateParametersList={updateParametersList}
                            />
                        </div>
                    </div>
                    <hr/>
                </div>
            </React.Fragment>
        );
    }

}

InstallationPointForm.propTypes = {
    installationPoint: PropTypes.object,
    templates: PropTypes.array,
    installationPointNameplates: PropTypes.array,
    nameplatesList: PropTypes.array,
    updateNameplatesList: PropTypes.func,
    reloadEquipment: PropTypes.func,
    updateTemplates: PropTypes.func,
    parametersList: PropTypes.array,
    updateParametersList: PropTypes.func
};

export default InstallationPointForm;