import { useMemo } from "react";

export const usePagerRange = (page, perPage, total) => {
    const from = useMemo(() => (page - 1) * perPage + 1, [page, perPage]);
    const to = useMemo(
        () => ((page - 1) * perPage + perPage >= total ? total : (page - 1) * perPage + perPage),
        [total, perPage, page]
    );

    return { from, to };
};
