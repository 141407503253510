import {usePointsData} from "../../../hooks/usePointsData";
import {useSimpleChartSeriesBuilder} from "../../../../../../hooks/factory/useSimpleChartSeriesBuilder";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useAxisLabels} from "../helpers/useAxisLabels";

export const useSeriesBuilder = () => {
    const settings = useChartStateStoreContext((state) => state.settings, shallow);
    const pointsData = usePointsData(settings.overlayPoints);
    const labels = useAxisLabels();

    return useSimpleChartSeriesBuilder({
        chartType: settings.chartType,
        axisIds: settings.seriesList.selected,
        pointsData,
        labels,
    });
};
