import React, {useEffect, useReducer, useState} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {Loader, ValidationError} from "../../../shared";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import {
    get as _get,
    set as _set,
    filter as _filter,
    findKey as _findKey,
    find as _find,
    cloneDeep as _cloneDeep,
    isEqual as _isEqual,
    each as _each,
} from "lodash";
import Select from "react-select";
import UserApi from "../../../api/user";
import ReadingApi from "../../../api/reading";
import AlertLevelApi from "../../../api/alertLevel";
import AlertGroupsApi from "../../../api/alertGroups";
import {ALARM_EMAIL_FREQUENCY} from "../../../constants/constants";
import InfiniteScroll from "react-infinite-scroller";
import {customStyles} from "../../../helpers/select";

import "../../../assets/scss/components/alert-groups/alert-groups.scss";
import {HeaderSimple} from "../../../shared/header";

const isDarkTheme = localStorage.getItem("lightMode");

const typeList = {
    facilities: 1,
    locations: 2,
    equipments: 3,
    installationPoints: 4,
};
const keyList = {
    1: "facility_id",
    2: "location_id",
    3: "equipment_id",
    4: "installation_point_id",
};

const normalizedStructure = (facilities, structureTree, equipments, installationPoints) => {
    let locations = [];
    const calcLocation = (arr, location, parentId, parentName) => {
        const fullName = parentName ? parentName + "/" + location.name : location.name;
        arr.push({
            location_id: location.id,
            name: location.name,
            facility_id: location.facility_id,
            parent_id: parentId,
            fullName: fullName,
            isHidden: location.level_name === "facility",
        });
        if ((location.children || []).length) location.children.forEach((childLocation) => calcLocation(arr, childLocation, location.id, fullName));
    };
    structureTree[0].children.forEach((facility) => {
        if (_find(equipments, (equipment) => +equipment.location_id === +facility.id)) {
            locations.push({
                location_id: facility.id,
                name: facility.name,
                facility_id: facility.facility_id,
                parent_id: undefined,
                fullName: facility.name,
                isHidden: true,
            });
        }
        calcLocation(locations, facility);
    });
    return {facilities, locations, equipments, installationPoints};
};

const normalizedData = (group, selfUsers, chartTypes, alertLevelList) => {
    let data = {};
    if ((group || {}).id) {
        let groupChartTypes = [];
        let groupParticipants = [];
        let groupAlertLevels = [];
        if ((group.participants || []).length === (selfUsers.users || []).length) {
            groupParticipants.push({value: "*", label: "All Users"});
        } else {
            (selfUsers.users || []).forEach((user) => {
                if ((group.participants || []).indexOf(+user.id) !== -1) {
                    groupParticipants.push({value: user.id, label: user.full_name});
                }
            });
        }
        if (group.is_universal) {
            groupChartTypes.push({value: "*", label: "All Data Types"});
        } else {
            Object.keys(chartTypes || {}).forEach((key) => {
                if ((group.chartTypes || []).indexOf(+key) !== -1) {
                    groupChartTypes.push({value: key, label: chartTypes[key].label});
                }
            });
        }
        (group.alertLevels || []).forEach((levelId) => {
            const level = _find(alertLevelList || [], (level) => +level.id === +levelId);
            if (level) {
                groupAlertLevels.push({value: levelId, label: level.name});
            }
        });
        data = {
            name: group.name,
            participants: groupParticipants,
            frequency: group.frequency,
            chartTypes: groupChartTypes,
            alertLevels: groupAlertLevels,
        };
    }
    return data;
};

const normalizedTree = (group) => {
    let tree = {};
    if ((group || {}).id) {
        (group.tree || []).forEach((facility) => {
            if ((facility.equipment || []).length) {
                const location = _get(facility, "facilityLocations.0") || {};
                tree[+facility.facility_id] = {};
                tree[+facility.facility_id][+location.id] = {};
                facility.equipment.forEach((equipment) => {
                    if ((equipment.installationPoints || []).length) {
                        tree[+facility.facility_id][+location.id][+equipment.id] = {};
                        equipment.installationPoints.forEach((installationPoint) => {
                            if (!installationPoint.skipped) {
                                tree[+facility.facility_id][+location.id][+equipment.id][+installationPoint.id] = true;
                            }
                        });
                    } else if (!equipment.skipped) {
                        tree[+facility.facility_id][+location.id][+equipment.id] = true;
                    }
                });
            }
            if ((facility.locations || []).length) {
                if (!tree[+facility.facility_id]) tree[+facility.facility_id] = {};
                facility.locations.forEach((location) => {
                    if ((location.equipment || []).length) {
                        tree[+facility.facility_id][+location.id] = {};
                        location.equipment.forEach((equipment) => {
                            if ((equipment.installationPoints || []).length) {
                                tree[+facility.facility_id][+location.id][+equipment.id] = {};
                                equipment.installationPoints.forEach((installationPoint) => {
                                    if (!installationPoint.skipped) {
                                        tree[+facility.facility_id][+location.id][+equipment.id][+installationPoint.id] = true;
                                    }
                                });
                            } else if (!equipment.skipped) {
                                tree[+facility.facility_id][+location.id][+equipment.id] = true;
                            }
                        });
                    } else if (!location.skipped) {
                        tree[+facility.facility_id][+location.id] = true;
                    }
                });
            }
            if (!(facility.equipment || []).length && !(facility.locations || []).length) {
                tree[+facility.facility_id] = true;
            }
        });
    }
    return tree;
};

const normalizedUsers = (users, structure) => {
    const locationStructure = _get(structure, "locations", []);

    _each(users, (user) => {
        const locations = _get(user, "locations", {});
        let accessNames = "";

        if (_get(user, "is_super_admin") || !!_get(user, "all_facilities")) {
            accessNames = "All";
        } else if (!Object.keys(locations).length) {
            accessNames = "Not set";
        } else {
            _each(locations, (name, id) => {
                const currentLocation = _find(locationStructure, {location_id: +id}) || {};
                accessNames = accessNames ? accessNames + ", " + _get(currentLocation, "fullName", "-") : _get(currentLocation, "fullName", "-");
            });
        }

        user.accessNames = accessNames;
    });

    return users;
};

const initialState = {
    data: {},
    tree: {},
    users: [],
    chartTypes: {},
    structure: {},
    search: {},
    current: [],
    alertLevelList: [],
    hiddenList: {facilities: [], locations: []},
    validation: {},
    inProgress: false,
    loader: true,
};

const reducer = (state, action) => ({...state, ...action});

const AlertGroupsForm = ({match, history}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {users, chartTypes, data, tree, structure, search, current, alertLevelList, hiddenList, validation, inProgress, loader} = state;

    useEffect(() => {
        Promise.all([
            match.params.id ? AlertGroupsApi.get(match.params.id) : false,
            match.params.id ? AlertGroupsApi.tree(match.params.id) : false,
            UserApi.getSelfUsers({
                query: {
                    filter: {status: 1},
                    excludeWCDUsers: 1,
                },
            }),
            ReadingApi.getAlertTypesList(),
            AlertLevelApi.getForProfile(),
            AlertGroupsApi.facilities(),
            AlertGroupsApi.locations(),
            AlertGroupsApi.equipments(),
            AlertGroupsApi.installationPoints(),
        ]).then(([group, {tree}, selfUsers, {chartTypes}, alertLevel, {facilities}, {structureTree}, {equipments}, {installationPoints}]) => {
            if ((group || {}).id) group.tree = tree;

            const structure = normalizedStructure(facilities, structureTree, equipments, installationPoints);

            dispatch({
                tree: normalizedTree(group),
                data: normalizedData(group, selfUsers, chartTypes, alertLevel.list),
                users: normalizedUsers(selfUsers.users || [], structure),
                chartTypes: chartTypes || {},
                alertLevelList: alertLevel.list || [],
                structure: structure,
                loader: false,
            });
        });
    }, []);

    const updateData = (key, value) =>
        dispatch({
            data: {...data, [key]: value},
            validation: {...validation, [key]: ""},
        });

    const updateTree = (path) => {
        path = path.map((key) => +key);
        let tempData = _cloneDeep(tree);
        let isParentAll = false;

        for (let i = 1; i < path.length; i++) {
            if (_get(tempData, path.slice(0, i)) === true) isParentAll = true;
            if (!_get(tempData, path.slice(0, i))) {
                if (isParentAll) {
                    let tempPointData = false;
                    structure[_findKey(typeList, (typeVal) => typeVal === i)].forEach((tempItem) => {
                        if (+tempItem[keyList[i - 1]] === path[i - 2]) {
                            if (!tempPointData) tempPointData = {};
                            if (+tempItem[keyList[i]] === path[i - 1]) tempPointData[tempItem[keyList[i]]] = {};
                            else tempPointData[tempItem[keyList[i]]] = true;
                        }
                    });
                    _set(tempData, path.slice(0, i - 1), tempPointData);
                } else if (!_get(tempData, path.slice(0, i - 1))) {
                    _set(tempData, path.slice(0, i - 1), {});
                }
            }
        }

        if (isParentAll) {
            let tempPointData = false;
            structure[_findKey(typeList, (typeVal) => typeVal === path.length)].forEach((tempItem) => {
                if (+tempItem[keyList[path.length - 1]] === path[path.length - 2] && +tempItem[keyList[path.length]] !== path[path.length - 1]) {
                    if (!tempPointData) tempPointData = {};
                    tempPointData[tempItem[keyList[path.length]]] = true;
                }
            });
            _set(tempData, path.slice(0, path.length - 1), tempPointData);
        } else {
            if (!_get(tempData, path.slice(0, path.length - 1))) {
                _set(tempData, path.slice(0, path.length - 1), {});
            }
            _set(tempData, path, !_get(tempData, path));
            if (path.length > 1) {
                for (let i = path.length - 1; i > 0; i--) {
                    updateParentTree(tempData, path.slice(0, i), path[i]);
                }
            }
        }

        dispatch({tree: tempData, validation: {...validation, facilities: ""}});
    };

    const updateParentTree = (tempData, path, childKey) => {
        const structureChildKey = _findKey(typeList, (typeVal) => typeVal === path.length + 1);
        if (_get(tree, path) === true) {
            let tempPointData = false;
            structure[structureChildKey].forEach((tempItem) => {
                if (+tempItem[keyList[path.length]] === path[path.length - 1] && +tempItem[keyList[path.length + 1]] !== childKey) {
                    if (!tempPointData) tempPointData = {};
                    tempPointData[tempItem[keyList[path.length + 1]]] = true;
                }
            });
            _set(tempData, path, tempPointData);
        } else if (typeof _get(tempData, path) === "object") {
            if (
                _filter(_get(tempData, path), (tempItem) => tempItem === true).length ===
                _filter(structure[structureChildKey], (tempItem) => +tempItem[keyList[path.length]] === path[path.length - 1]).length
            ) {
                _set(tempData, path, true);
            } else if (_filter(_get(tempData, path), (tempItem) => !!tempItem).length === 0) {
                _set(tempData, path, false);
            }
        }
    };

    const updateCurrent = (ev, path) => {
        if (["SPAN", "INPUT"].indexOf(ev.target.tagName) === -1) {
            dispatch({current: _isEqual(current, path) ? path.slice(0, path.length - 1) : path});
        }
    };

    const updateSearch = (ev, key) => {
        const {value} = ev.target;
        dispatch({search: {...search, [key]: value}});
    };

    const submit = () => {
        let submitData = _cloneDeep(data);
        submitData.isUniversal = false;
        submitData.participants =
            _get(submitData, "participants.0.value") === "*"
                ? (users || []).map((user) => +user.id)
                : (submitData.participants || []).map((user) => +user.value);
        _get(submitData, "chartTypes.0.value") === "*"
            ? (submitData = {...submitData, isUniversal: true, chartTypes: []})
            : (submitData.chartTypes = (submitData.chartTypes || []).map((type) => +type.value));
        submitData.alertLevels = (submitData.alertLevels || []).map((type) => +type.value);
        submitData.facilities = [];
        submitData.locations = [];
        submitData.equipment = [];
        submitData.installationPoints = [];
        Object.keys(tree).forEach((facilityId) => {
            const facility = tree[facilityId];
            if (facility === false) return false;
            else if (facility === true) submitData.facilities.push(+facilityId);
            else
                Object.keys(facility).forEach((locationId) => {
                    const location = facility[locationId];
                    if (location === false) return false;
                    else if (location === true) submitData.locations.push(+locationId);
                    else
                        Object.keys(location).forEach((equipmetId) => {
                            const equipmet = location[equipmetId];
                            if (equipmet === false) return false;
                            else if (equipmet === true) submitData.equipment.push(+equipmetId);
                            else
                                Object.keys(equipmet).forEach((installationPointId) => {
                                    const installationPoint = equipmet[installationPointId];
                                    if (installationPoint === false) return false;
                                    else if (installationPoint === true) submitData.installationPoints.push(+installationPointId);
                                });
                        });
                });
        });
        dispatch({inProgress: true});
        (match.params.id ? AlertGroupsApi.update(match.params.id, submitData) : AlertGroupsApi.store(submitData))
            .then(() => history.push("/alert-groups"))
            .catch((resp) => {
                if (resp.status === 422) {
                    let respValidation = {};
                    Object.keys(resp.errors || {}).forEach((key) => (respValidation[key] = (resp.errors[key] || [])[0] || ""));
                    dispatch({validation: respValidation, inProgress: false});
                }
            });
    };
    const breadcrumbs = [{name: "Alert Groups", link: "/alert-groups"}, {name: (match.params.id ? "Edit" : "Add") + " Group"}];
    return (
        <div className="alert-groups-add">
            <HeaderSimple
                breadcrumbs={breadcrumbs}
                hideGlobalSearch
            />
            <div className="subheader">
                <div className="subheader-title">{match.params.id ? "Edit" : "Add"} Alert Group</div>
            </div>
            {loader ? (
                <div className="loader-fullscreen">
                    <Loader />
                </div>
            ) : (
                <React.Fragment>
                    <div className="block">
                        <div className="block-body">
                            <GeneralInfo
                                data={data}
                                users={users}
                                validation={validation}
                                updateData={updateData}
                            />
                            <AlertSettings
                                data={data}
                                chartTypes={chartTypes}
                                alertLevelList={alertLevelList}
                                validation={validation}
                                updateData={updateData}
                            />
                        </div>
                        <div className="form-group invalid-facilities-feedback">
                            <ValidationError message={validation.facilities} />
                        </div>
                        <Selects
                            tree={tree}
                            structure={structure}
                            search={search}
                            current={current}
                            validation={validation}
                            dispatch={dispatch}
                            updateTree={updateTree}
                            updateCurrent={updateCurrent}
                            updateSearch={updateSearch}
                        />
                        {!!_filter(tree, (tempItem) => !!tempItem).length && (
                            <SelectionSummary
                                hiddenList={hiddenList}
                                tree={tree}
                                data={data}
                                structure={structure}
                                dispatch={dispatch}
                                updateTree={updateTree}
                                updateData={updateData}
                            />
                        )}
                    </div>
                    <div className="text-right">
                        <Link
                            to="/alert-groups"
                            className="btn btn-sm btn-secondary"
                        >
                            Cancel
                        </Link>
                        <Button
                            color="primary"
                            size="sm"
                            className="ml-2"
                            disabled={inProgress}
                            onClick={submit}
                        >
                            Save
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

AlertGroupsForm.propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
};

const GeneralInfo = ({data, users, validation, updateData}) => {
    return (
        <React.Fragment>
            <span className="form-title-group">General Information</span>
            <div className="row">
                <div className="col-4">
                    <div className="form-group">
                        <label className="form-label">
                            Group Name <span className="color-danger">*</span>
                        </label>
                        <input
                            className={"form-control" + (validation.name ? " is-invalid" : "")}
                            type="text"
                            placeholder="Enter Group Name"
                            value={data.name || ""}
                            onChange={(ev) => updateData("name", ev.target.value)}
                        />
                        <ValidationError message={validation.name} />
                    </div>
                </div>
                <div className="col-8">
                    <div className="form-group">
                        <label className="form-label">
                            Group Participants <span className="color-danger">*</span>
                        </label>
                        <Select
                            className={"form-control-select" + (validation.participants ? " is-invalid" : "")}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            styles={customStyles}
                            value={data.participants || []}
                            options={[
                                {value: "*", label: "All Users"},
                                ...(users || []).map((user) => ({value: user.id, label: user.full_name, accessNames: "(" + user.accessNames + ")"})),
                            ]}
                            formatOptionLabel={({label, accessNames}, {context}) => (
                                <div className="d-flex">
                                    <div>{label}</div>
                                    {context === "menu" && <div style={{marginLeft: "10px", color: "#ccc"}}>{accessNames}</div>}
                                </div>
                            )}
                            onChange={(value, ev) => {
                                if (ev.action === "select-option") {
                                    if ((ev.option || {}).value === "*" || value.length === Object.keys(users || []).length)
                                        value = [{value: "*", label: "All Users"}];
                                    else value = value.filter((item) => item.value !== "*");
                                }
                                updateData("participants", value);
                            }}
                        />
                        <ValidationError message={validation.participants} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

GeneralInfo.propTypes = {
    updateData: PropTypes.func,
    users: PropTypes.array,
    validation: PropTypes.object,
    data: PropTypes.object,
};

const AlertSettings = ({data, chartTypes, alertLevelList, validation, updateData}) => {
    return (
        <React.Fragment>
            <span className="form-title-group">Alert Settings</span>
            <div className="row">
                <div className="col-3">
                    <div className="form-group">
                        <label className="form-label">
                            Alert Frequency <span className="color-danger">*</span>
                        </label>
                        <Select
                            className={"form-control-select" + (validation.frequency ? " is-invalid" : "")}
                            isSearchable={false}
                            styles={customStyles}
                            value={
                                ALARM_EMAIL_FREQUENCY[data.frequency] ? {value: data.frequency, label: ALARM_EMAIL_FREQUENCY[data.frequency]} : undefined
                            }
                            options={Object.keys(ALARM_EMAIL_FREQUENCY).map((key) => ({value: key, label: ALARM_EMAIL_FREQUENCY[key]}))}
                            onChange={({value}) => updateData("frequency", value)}
                        />
                        <ValidationError message={validation.frequency} />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label className="form-label">
                            Data Types <span className="color-danger">*</span>
                        </label>
                        <Select
                            className={"form-control-select" + (validation.chartTypes ? " is-invalid" : "")}
                            isMulti
                            isSearchable={false}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            styles={customStyles}
                            value={data.chartTypes || []}
                            options={[
                                {value: "*", label: "All Data Types"},
                                ...Object.keys(chartTypes).map((key) => ({value: key, label: chartTypes[key].label})),
                            ]}
                            onChange={(value, ev) => {
                                if (ev.action === "select-option") {
                                    if ((ev.option || {}).value === "*" || value.length === Object.keys(chartTypes).length)
                                        value = [{value: "*", label: "All Data Types"}];
                                    else value = value.filter((item) => item.value !== "*");
                                }
                                updateData("chartTypes", value);
                            }}
                        />
                        <ValidationError message={validation.chartTypes} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label className="form-label">
                            Alert Levels <span className="color-danger">*</span>
                        </label>
                        <Select
                            className={"form-control-select" + (validation.alertLevels ? " is-invalid" : "")}
                            isMulti
                            isSearchable={false}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            styles={customStyles}
                            value={data.alertLevels || []}
                            options={alertLevelList.map((level) => ({value: level.id, label: level.name}))}
                            onChange={(value) => updateData("alertLevels", value)}
                        />
                        <ValidationError message={validation.alertLevels} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

AlertSettings.propTypes = {
    updateData: PropTypes.func,
    alertLevelList: PropTypes.array,
    chartTypes: PropTypes.object,
    validation: PropTypes.object,
    data: PropTypes.object,
};

const Selects = ({structure, tree, current, search, dispatch, updateTree, updateCurrent, updateSearch}) => {
    const [dataLocationsLength, setDataLocationsLength] = useState(20);
    const [dataEquipmentsLength, setDataEquipmentsLength] = useState(20);
    const [dataSensorsLength, setDataSensorsLength] = useState(20);
    let currentFacilities = {};
    let currentLocations = {};
    let currentEquipments = {};
    let currentSensors = {};

    (structure.facilities || []).forEach((item) => {
        if (search.facilities && !item.name.toLowerCase().includes(search.facilities.toLowerCase())) return;
        currentFacilities[item.facility_id] = {...item, value: tree[item.facility_id] || false};
    });
    (structure.locations || []).forEach((item) => {
        if (current[0] && +current[0] !== +item.facility_id) return;
        if (currentFacilities[item.facility_id] === undefined) return;
        if (search.locations && !item.name.toLowerCase().includes(search.locations.toLowerCase())) return;
        currentLocations[item.location_id] = {...item, value: _get(tree, [item.facility_id, item.location_id]) || false};
        // currentLocations[item.location_id] = _get(tree, [item.facility_id, item.location_id])
        //     ? _get(tree, [item.facility_id, item.location_id])
        //     : currentFacilities[item.facility_id] === true;
    });
    (structure.equipments || []).forEach((item) => {
        if (current[1] && +current[1] !== +item.location_id) return;
        if (currentLocations[item.location_id] === undefined) return;
        if (search.equipments && !item.name.toLowerCase().includes(search.equipments.toLowerCase())) return;
        currentEquipments[item.equipment_id] = {...item, value: _get(tree, [item.facility_id, item.location_id, item.equipment_id]) || false};
    });
    (structure.installationPoints || []).forEach((item) => {
        if (current[2] && +current[2] !== +item.equipment_id) return;
        if (currentEquipments[item.equipment_id] === undefined) return;
        if (
            search.installationPoints &&
            !(
                item.name.toLowerCase().includes(search.installationPoints.toLowerCase()) ||
                (item.sensor_hex_id || "").toLowerCase().includes(search.installationPoints.toLowerCase())
            )
        )
            return;
        currentSensors[item.installation_point_id] = {
            ...item,
            value: _get(tree, [item.facility_id, item.location_id, item.equipment_id, item.installation_point_id]) || false,
        };
    });

    Object.keys(currentLocations).forEach((locationId) => {
        if (currentLocations[locationId].isHidden) {
            delete currentLocations[locationId];
        }
    });

    const isCheckedAllFacilities =
        Object.keys(currentFacilities).length > 0 &&
        _filter(currentFacilities, (tempItem) => tempItem.value === true).length === Object.keys(currentFacilities).length;
    const isCheckedAllLocations = false;
    const isCheckedAllEquipments = false;
    const isCheckedAllSensors = false;

    let tempLocationsLength = 0;
    let tempEquipmentsLength = 0;
    let tempSensorsLength = 0;

    // let locations = [];
    // const calcLocation = (arr, children, padding = 0) => {
    //     children.forEach(item => {
    //         if (currentLocations[item.location_id] === undefined) return "";
    //         const pointVal = (tree[item.facility_id] || {})[item.location_id];
    //         const isParentAll = tree[item.facility_id] === true;
    //         const itemChildren = _filter(structure.locations, tempItem => +item.location_id === +tempItem.parent_id);
    //
    //         arr.push(
    //             <div
    //                 key={item.location_id}
    //                 style={{padding: `8px 8px 8px ${8 + padding}px`, cursor: "pointer", ...(+current[1] === +item.location_id ? {background: "#c7e1ff"} : {})}}
    //                 onClick={ev => updateCurrent(ev, [item.facility_id, item.location_id])}
    //             >
    //                 <label className={`form-checkbox modal-checkbox${pointVal === true || isParentAll ? "" : " form-checkbox-dash"}`}>
    //                     <input
    //                         type="checkbox"
    //                         checked={isParentAll || !!pointVal}
    //                         onChange={() => updateTree([item.facility_id, item.location_id])}
    //                     /><span style={{top: -4, background: isParentAll || !!pointVal ? "#31AEE3" : "#fff"}}/>
    //                 </label>
    //                 <span><b>{item.name}</b></span>
    //             </div>
    //         );
    //
    //         if (itemChildren.length) {
    //             calcLocation(arr, itemChildren, padding/* + 20*/);
    //         }
    //     });
    // };
    // calcLocation(locations, _filter(structure.locations, item => !item.parent_id));

    return (
        <div
            style={{
                background: isDarkTheme === "true" ? "#36342a" : "#f0f0f0",
            }}
        >
            <div className="block-body">
                <div className="form-title-group">Group Selection</div>

                {!!_filter(tree, (tempItem) => !!tempItem).length && (
                    <div
                        style={{position: "absolute", top: 60, right: 25, cursor: "pointer"}}
                        onClick={() => dispatch({tree: {}})}
                    >
                        <span className="badge badge-danger">Clear</span>
                    </div>
                )}

                <div className="alert-groups-list">
                    {structure.facilities ? (
                        <div className="alert-groups-select">
                            <div className="mb-4">
                                <label className="form-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={isCheckedAllFacilities}
                                        onChange={() => {
                                            let tempData = {};
                                            Object.keys(currentFacilities).forEach((tempKey) => {
                                                tempData[tempKey] = !isCheckedAllFacilities;
                                            });
                                            dispatch({tree: {...tree, ...tempData}, facilities: ""});
                                        }}
                                    />{" "}
                                    <b>ALL FACILITIES</b>
                                    <span style={{background: isCheckedAllFacilities ? "#31AEE3" : "#f0f0f0"}} />
                                </label>
                            </div>
                            <div>
                                <div className="custom-search-block">
                                    <i className="fa fa-search fa-2x" />
                                    <input
                                        type="text"
                                        value={search.facilities || ""}
                                        onChange={(ev) => updateSearch(ev, "facilities")}
                                        className="form-control custom-search-input"
                                        placeholder="Search by Facility"
                                    />
                                </div>
                            </div>
                            <div className="custom-scroll">
                                {Object.keys(currentFacilities).length ? (
                                    Object.keys(currentFacilities).map((itemKey) => {
                                        const point = currentFacilities[itemKey];
                                        return (
                                            <div
                                                key={itemKey}
                                                style={{
                                                    padding: 8,
                                                    cursor: "pointer",
                                                    ...(+current[0] === +itemKey ? {background: isDarkTheme === "true" ? "#2765ac" : "#c7e1ff"} : {}),
                                                }}
                                                onClick={(ev) => updateCurrent(ev, [itemKey])}
                                            >
                                                <label className={`form-checkbox modal-checkbox${point.value === true ? "" : " form-checkbox-dash"}`}>
                                                    <input
                                                        type="checkbox"
                                                        checked={!!point.value}
                                                        onChange={() => updateTree([itemKey])}
                                                    />
                                                    <span style={{background: point.value ? "#31AEE3" : "#fff"}} />
                                                </label>
                                                <span>
                                                    <b>{point.name}</b>
                                                </span>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="text-center alert-no-results">
                                        <span>No Results</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="alert-groups-select" />
                    )}
                    {structure.locations ? (
                        <div className="alert-groups-select">
                            <div>
                                <label
                                    className="form-checkbox"
                                    style={{opacity: 0, cursor: "default"}}
                                >
                                    <input
                                        type="checkbox"
                                        checked={isCheckedAllLocations}
                                        onChange={() => {}}
                                    />{" "}
                                    <b>ALL ASSETS</b>
                                    <span style={{background: "#f0f0f0"}} />
                                </label>
                            </div>
                            <div>
                                <div className="custom-search-block">
                                    <i className="fa fa-search fa-2x" />
                                    <input
                                        type="text"
                                        value={search.locations || ""}
                                        onChange={(ev) => updateSearch(ev, "locations")}
                                        className="form-control custom-search-input"
                                        placeholder="Search by Asset Tree Branch"
                                    />
                                </div>
                            </div>
                            <div className="custom-scroll">
                                {Object.keys(currentLocations).length ? (
                                    <InfiniteScroll
                                        loadMore={() => setDataLocationsLength(dataLocationsLength + 10)}
                                        hasMore={dataLocationsLength < Object.keys(currentLocations).length}
                                        useWindow={false}
                                        loader=""
                                    >
                                        {Object.keys(currentLocations).map((itemKey) => {
                                            if (tempLocationsLength > dataLocationsLength) return "";
                                            const point = currentLocations[itemKey];
                                            const isParentAll = tree[point.facility_id] === true;
                                            tempLocationsLength++;

                                            return (
                                                <div
                                                    key={itemKey}
                                                    style={{padding: 8, cursor: "pointer", ...(+current[1] === +itemKey ? {background: "#c7e1ff"} : {})}}
                                                    onClick={(ev) => updateCurrent(ev, [point.facility_id, itemKey])}
                                                >
                                                    <label
                                                        className={`form-checkbox modal-checkbox${
                                                            point.value === true || isParentAll ? "" : " form-checkbox-dash"
                                                        }`}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={isParentAll || !!point.value}
                                                            onChange={() => updateTree([point.facility_id, itemKey])}
                                                        />
                                                        <span style={{background: isParentAll || !!point.value ? "#31AEE3" : "#fff"}} />
                                                    </label>
                                                    <span>
                                                        <b>{point.name}</b>
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </InfiniteScroll>
                                ) : (
                                    <div className="text-center alert-no-results">
                                        <span>No Results</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="alert-groups-select" />
                    )}
                    {structure.equipments ? (
                        <div className="alert-groups-select">
                            <div>
                                <label
                                    className="form-checkbox modal-checkbox"
                                    style={{opacity: 0, cursor: "default"}}
                                >
                                    <input
                                        type="checkbox"
                                        checked={isCheckedAllEquipments}
                                        onChange={() => {}}
                                    />{" "}
                                    <b>ALL EQUIPMENT</b>
                                    <span style={{background: "#f0f0f0"}} />
                                </label>
                            </div>
                            <div>
                                <div className="custom-search-block">
                                    <i className="fa fa-search fa-2x" />
                                    <input
                                        type="text"
                                        value={search.equipments || ""}
                                        onChange={(ev) => updateSearch(ev, "equipments")}
                                        className="form-control custom-search-input"
                                        placeholder="Search by Equipment"
                                    />
                                </div>
                            </div>
                            <div className="custom-scroll">
                                {Object.keys(currentEquipments).length ? (
                                    <InfiniteScroll
                                        loadMore={() => setDataEquipmentsLength(dataEquipmentsLength + 10)}
                                        hasMore={dataEquipmentsLength < Object.keys(currentEquipments).length}
                                        useWindow={false}
                                        loader=""
                                    >
                                        {Object.keys(currentEquipments).map((itemKey) => {
                                            if (tempEquipmentsLength > dataEquipmentsLength) return "";
                                            const point = currentEquipments[itemKey];
                                            const isParentAll =
                                                tree[point.facility_id] === true || (tree[point.facility_id] || {})[point.location_id] === true;
                                            tempEquipmentsLength++;

                                            return (
                                                <div
                                                    key={itemKey}
                                                    style={{padding: 8, cursor: "pointer", ...(+current[2] === +itemKey ? {background: "#c7e1ff"} : {})}}
                                                    onClick={(ev) => updateCurrent(ev, [point.facility_id, point.location_id, itemKey])}
                                                >
                                                    <label
                                                        className={`form-checkbox modal-checkbox${
                                                            point.value === true || isParentAll ? "" : " form-checkbox-dash"
                                                        }`}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={isParentAll || !!point.value}
                                                            onChange={() => updateTree([point.facility_id, point.location_id, itemKey])}
                                                        />
                                                        <span style={{background: isParentAll || !!point.value ? "#31AEE3" : "#fff"}} />
                                                    </label>
                                                    <span>
                                                        <b>{point.name}</b>
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </InfiniteScroll>
                                ) : (
                                    <div className="text-center alert-no-results">
                                        <span>No Results</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="alert-groups-select" />
                    )}
                    {structure.installationPoints ? (
                        <div className="alert-groups-select">
                            <div>
                                <label
                                    className="form-checkbox modal-checkbox"
                                    style={{opacity: 0, cursor: "default"}}
                                >
                                    <input
                                        type="checkbox"
                                        checked={isCheckedAllSensors}
                                        onChange={() => {}}
                                    />{" "}
                                    <b>ALL SENSORS</b>
                                    <span style={{background: "#f0f0f0"}} />
                                </label>
                            </div>
                            <div>
                                <div className="custom-search-block">
                                    <i className="fa fa-search fa-2x" />
                                    <input
                                        type="text"
                                        value={search.installationPoints || ""}
                                        onChange={(ev) => {
                                            updateSearch(ev, "installationPoints");
                                            setDataSensorsLength(20);
                                        }}
                                        className="form-control custom-search-input"
                                        placeholder="Search by Sensor"
                                    />
                                </div>
                            </div>
                            <div className="custom-scroll">
                                {Object.keys(currentSensors).length ? (
                                    <InfiniteScroll
                                        loadMore={() => setDataSensorsLength(dataSensorsLength + 10)}
                                        hasMore={dataSensorsLength < Object.keys(currentSensors).length}
                                        useWindow={false}
                                        loader=""
                                    >
                                        {Object.keys(currentSensors).map((itemKey) => {
                                            if (tempSensorsLength > dataSensorsLength) return "";
                                            const point = currentSensors[itemKey];
                                            // const isParentAll = currentEquipments[item.equipment_id] === true;
                                            const isParentAll =
                                                tree[point.facility_id] === true ||
                                                (tree[point.facility_id] || {})[point.location_id] === true ||
                                                ((tree[point.facility_id] || {})[point.location_id] || {})[point.equipment_id] === true;
                                            tempSensorsLength++;

                                            return (
                                                <div
                                                    key={itemKey}
                                                    style={{padding: 8}}
                                                >
                                                    <label
                                                        className={`form-checkbox modal-checkbox${
                                                            point.value === true || isParentAll ? "" : " form-checkbox-dash"
                                                        }`}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={isParentAll || !!point.value}
                                                            onChange={() =>
                                                                updateTree([point.facility_id, point.location_id, point.equipment_id, itemKey])
                                                            }
                                                        />
                                                        <span style={{background: isParentAll || !!point.value ? "#31AEE3" : "#fff"}} />
                                                    </label>
                                                    <span>
                                                        <b>{`${point.name} (${point.sensor_hex_id || "---"})`}</b>
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </InfiniteScroll>
                                ) : (
                                    <div className="text-center alert-no-results">
                                        <span>No Results</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="alert-groups-select" />
                    )}
                </div>
            </div>
        </div>
    );
};

Selects.propTypes = {
    dispatch: PropTypes.func,
    updateTree: PropTypes.func,
    updateCurrent: PropTypes.func,
    updateSearch: PropTypes.func,
    current: PropTypes.array,
    structure: PropTypes.object,
    search: PropTypes.object,
    tree: PropTypes.object,
};

const SelectionSummary = ({dispatch, updateTree, updateData, hiddenList, tree, data, structure}) => {
    const hiddenAll = hiddenList.facilities.length === _filter(tree, (point) => typeof point === "object").length;
    return (
        <div className="block-body">
            {_filter(tree, (tempItem) => tempItem === true).length !== structure.facilities.length && (
                <div style={{marginBottom: 10}}>
                    <span className={"form-title-group"}>Selection Summary</span>
                    {!!_filter(tree, (tempItem) => typeof tempItem === "object").length && (
                        <button
                            className="btn btn-primary btn-sm btn-elevate ml-2"
                            onClick={() => {
                                let tempHiddenList = {facilities: [], locations: []};
                                if (!hiddenAll) {
                                    Object.keys(tree).forEach((facilityId) => {
                                        if (typeof tree[facilityId] === "object") tempHiddenList.facilities.push(+facilityId);
                                    });
                                }
                                dispatch({hiddenList: tempHiddenList});
                            }}
                            style={{fontSize: "0.875rem", height: 25, lineHeight: 0.75}}
                        >
                            {hiddenAll ? "Expand" : "Hide"} All
                        </button>
                    )}
                </div>
            )}
            {(data.chartTypes || []).length > 0 && (
                <div style={{marginBottom: 10}}>
                    {data.chartTypes.map((type) => (
                        <span
                            key={type.value}
                            className="badge badge-warning round-badge"
                        >
                            <b>{type.label}</b>
                            <DeleteBtn
                                onClick={() =>
                                    updateData(
                                        "chartTypes",
                                        data.chartTypes.filter((item) => item.value !== type.value)
                                    )
                                }
                            />
                        </span>
                    ))}
                </div>
            )}
            <div className="row">
                {_filter(tree, (tempItem) => tempItem === true).length === structure.facilities.length ? (
                    <div className="col-12">
                        <span>
                            <b>All Facilities Selected</b>
                        </span>
                    </div>
                ) : (
                    Object.keys(tree).map((key) => (
                        <Tree
                            key={key}
                            facilityId={key}
                            tree={tree}
                            data={data}
                            structure={structure}
                            dispatch={dispatch}
                            hiddenList={hiddenList}
                            updateTree={updateTree}
                        />
                    ))
                )}
            </div>
        </div>
    );
};

SelectionSummary.propTypes = {
    dispatch: PropTypes.func,
    updateTree: PropTypes.func,
    updateData: PropTypes.func,
    hiddenList: PropTypes.object,
    structure: PropTypes.object,
    tree: PropTypes.object,
    data: PropTypes.object,
};

const Tree = ({facilityId, tree, structure, hiddenList, updateTree, dispatch}) => {
    const facilityVal = tree[facilityId];
    if (facilityVal === false || (typeof facilityVal === "object" && !Object.keys(facilityVal).length)) return "";

    const hasLocations = typeof facilityVal === "object" && !!Object.keys(facilityVal).length;
    const isFacilityHidden = hiddenList.facilities.indexOf(+facilityId) !== -1;
    const facility = _find(structure.facilities, (item) => +item.facility_id === +facilityId) || {};

    return (
        <div className="col-3">
            <span>
                {hasLocations && (
                    <span
                        className="btn-visible-tree"
                        onClick={() =>
                            dispatch({
                                hiddenList: {
                                    ...hiddenList,
                                    facilities: isFacilityHidden
                                        ? _filter(hiddenList.facilities, (tempFacilityId) => tempFacilityId !== +facilityId)
                                        : [...hiddenList.facilities, +facilityId],
                                },
                            })
                        }
                    >
                        <i className={`fa fa-arrow-${isFacilityHidden ? "down" : "up"}`} />
                    </span>
                )}
                <b>{facility.name || "---"}</b>
                <DeleteBtn onClick={() => updateTree([+facilityId])} />
            </span>
            {!isFacilityHidden &&
                hasLocations &&
                Object.keys(facilityVal).map((locationId) => {
                    const locationVal = facilityVal[locationId];
                    if (locationVal === false) return "";

                    const hasEquipments = typeof locationVal === "object" && !!Object.keys(locationVal).length;
                    const isLocationHidden = hiddenList.locations.indexOf(+locationId) !== -1;
                    const location = _find(structure.locations, (item) => +item.location_id === +locationId) || {};

                    return (
                        <div
                            key={locationId}
                            style={{paddingLeft: 20}}
                        >
                            <span>
                                {hasEquipments && (
                                    <span
                                        className="btn-visible-tree"
                                        onClick={() =>
                                            dispatch({
                                                hiddenList: {
                                                    ...hiddenList,
                                                    locations: isLocationHidden
                                                        ? _filter(hiddenList.locations, (tempLocationId) => tempLocationId !== +locationId)
                                                        : [...hiddenList.locations, +locationId],
                                                },
                                            })
                                        }
                                    >
                                        <i className={`fa fa-arrow-${isLocationHidden ? "down" : "up"}`} />
                                    </span>
                                )}
                                {location.isHidden ? "Facility Equipments" : location.name || "---"}
                                <DeleteBtn onClick={() => updateTree([+facilityId, +locationId])} />
                            </span>
                            {!isLocationHidden &&
                                hasEquipments &&
                                Object.keys(locationVal).map((equipmentId) => {
                                    const equipmentVal = locationVal[equipmentId];
                                    if (equipmentVal === false) return "";

                                    let installationPointLengthLabel = "";
                                    //installationPointLengthLabel = " (All Sensors)";
                                    if (equipmentVal !== true) {
                                        const checked = _filter(equipmentVal, (tempItem) => tempItem === true).length;
                                        const total = _filter(structure.installationPoints, (tempItem) => +tempItem.equipment_id === +equipmentId).length;
                                        installationPointLengthLabel = ` (${checked} of ${total} Sensors)`;
                                    }
                                    const equipment = _find(structure.equipments, (item) => +item.equipment_id === +equipmentId) || {};

                                    return (
                                        <div
                                            key={equipmentId}
                                            style={{paddingLeft: 40}}
                                        >
                                            <span>
                                                {(equipment.name || "---") + installationPointLengthLabel}
                                                <DeleteBtn onClick={() => updateTree([+facilityId, +locationId, +equipmentId])} />
                                            </span>
                                        </div>
                                    );
                                })}
                        </div>
                    );
                })}
        </div>
    );
};

Tree.propTypes = {
    facilityId: PropTypes.any,
    hiddenList: PropTypes.object,
    updateTree: PropTypes.func,
    dispatch: PropTypes.func,
    structure: PropTypes.object,
    data: PropTypes.object,
    tree: PropTypes.object,
};

const VisibleBtn = ({visible, onClick}) => (
    <span
        onClick={onClick}
        style={{cursor: "pointer", paddingLeft: 10}}
    >
        <i className={`fa fa-angle-${visible ? "up" : "down"}`} />
    </span>
);

VisibleBtn.propTypes = {
    visible: PropTypes.bool,
    onClick: PropTypes.func,
};

const DeleteBtn = ({onClick}) => (
    <span
        title="Delete"
        onClick={onClick}
        style={{cursor: "pointer"}}
    >
        {" "}
        <i className="fa fa-times" />
    </span>
);

DeleteBtn.propTypes = {
    onClick: PropTypes.func,
};

export default withGlobalStore(AlertGroupsForm);
