import Loader from "../../../shared/loader/loader";
import React, {useEffect, useState} from "react";
import {Modal} from "../../../shared";
import InstallationPoints from "../../../modules/installationPoint/formNew/InstallationPoints";
import WuaSensorApi from "../../../api/wuaSensor";
import InstallationPointFinder from "../../shared/installation-point-finder";
import {
    get as _get,
} from "lodash";
import PropTypes from "prop-types";
import InstallationPointCustomTypeStore
    from "../../../modules/installationPointCustomType/stores/InstallationPointCustomTypeStore";

const AssociateAdapterModal = ({onClose, afterUpdate}) => {
    const [wuaSensorList, setWuaSensorList] = useState([]);
    const [progress, setProggress] = useState(true);
    const [equipmentId, setEquipmentId] = useState(null);

    useEffect(() => {
        updateWuaSensorList();
    }, []);

    const updateWuaSensorList = () => {
        return WuaSensorApi
            .getList()
            .then((response) => {
                setWuaSensorList(response.list || []);
                setProggress(false);
            });
    };

    return (
        <Modal
            showModal={true}
            className="edit-installation-point-modal"
            title={"Associate Universal Adapter"}
            size={"lg"}
            withoutSubmit={true}
            onClose={onClose}
        >
            {progress
                ?
                <Loader/>
                :
                <>
                    <div className={"mb-4"}>
                        <InstallationPointFinder
                            setEquipment={(equipment) => setEquipmentId(_get(equipment, "id") || null)}
                        />
                    </div>

                    {equipmentId &&
                        <InstallationPointCustomTypeStore>
                            <InstallationPoints
                                equipmentId={equipmentId}
                                wuaSensorList={wuaSensorList}
                                updateWuaSensorList={updateWuaSensorList}
                                defaultActiveTab={"adaptors"}
                                defaultWithAddNew={true}
                                defaultHideTab={true}
                                afterUpdate={afterUpdate}
                            />
                        </InstallationPointCustomTypeStore>
                    }
                </>
            }
        </Modal>
    );
};

AssociateAdapterModal.propTypes = {
    onClose: PropTypes.func,
    afterUpdate: PropTypes.func
};

export default AssociateAdapterModal;