import React, {Component} from "react";
import SearchInput from "../../shared/search-input";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../stores/GlobalStore";
import LimitSelect from "../../shared/limit-select";
import {Loader, Pager} from "../../../shared";
import AlertConditionLogApi from "../../../api/alertConditionLog";
import Table from "./table";
import Helper from "../../../helpers/helper";
import { HeaderSimple } from "../../../shared/header";
import AlarmFilters from "../alarm-filters";
import {withLocationSelectStore} from "../../../stores/LocationSelectStore";
import "../../../assets/scss/components/alert-groups/alarm-log.scss";

class Index extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{name: "Alert Change Log"}],
            loader: true,
            list: [],
            actionByList: [],
            pagination: {
                page: 1,
                pages: 1,
                perpage: Helper.getPerPage("alert-change-log"),
                total: 0
            },
            sort: {
                field: "",
                sort: "desc"
            },
            filter: {
                query: "",
                chartType: "",
                type: "",
                axis: "",
                level: "",
                location: props.getLocationId(),
                actionBy: ""
            },
            globalSearchString: "",
        };
    }

    componentDidMount() {
        this.handleFetch();
    }

    handleFetch = () => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        return AlertConditionLogApi.getList({
            query: {
                sort: this.state.sort,
                filter: this.state.filter,
                pagination: this.state.pagination
            },
            signal: this.signal
        }).then(response => {
            if (response) {
                const {list = [], meta = {}, actionByList = []} = response;
                this.setState({
                    loader: false,
                    actionByList,
                    pagination: Object.assign({}, this.state.pagination, meta),
                    list
                }, () => {
                    if (!this.finished) {
                        this.finished = true;
                    }
                });
            }
        });
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    handleSortChange = (field = "") => {
        const {sort} = this.state;

        this.setState({
            sort: Object.assign({}, {
                field,
                sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"
            }),
            loader: true
        }, this.handleFetch);
    }

    handlePagerChange = (page = 1) => {
        this.setState({loader: true, pagination: Object.assign({}, this.state.pagination, {page})}, this.handleFetch);
    }

    handleSearch = (value) => {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }

        this.handleFilterChange({query: value});
    };

    handleLimitChange = e => {
        const {value} = e.target;
        Helper.setPerPage("alert-change-log", value);
        this.setState({
            loader: true,
            pagination: {
                ...this.state.pagination,
                page: 1,
                perpage: value
            }
        }, this.handleFetch);
    };

    handleFilterChange = (obj = {}) => {
        this.finished = false;
        this.setState({
            loader: true,
            filter: Object.assign({}, this.state.filter, obj),
            pagination: Object.assign({}, this.state.pagination, {page: 1})
        }, this.handleFetch);
    }

    render() {
        const {
            breadcrumbs,
            loader,
            list,
            pagination,
            sort,
            filter,
            globalSearchString,
            actionByList
        } = this.state;

        return (
            <div>
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">Alert Change Log</div>
                    <div className="subheader-controls d-flex" />
                </div>
                <div className="block">
                    <div className="block-header block-header-sticky">
                        <div className="block-label">
                            <AlarmFilters
                                customFilterElements={[{
                                    "name": "actionBy",
                                    "value": filter.actionBy,
                                    "label": "Action By",
                                    "options": actionByList
                                }]}
                                customClass={"alarm-log-filter"}
                                updated={this.handleFilterChange}
                            />
                            <div id="top-pagination-block" className="block-pagination">
                                {pagination.total > 10 &&
                                    <div className="limit-select-block">
                                        <LimitSelect
                                            name={"perpage"}
                                            onChange={this.handleLimitChange}
                                            defaultValue={pagination.perpage}
                                            limits={[10, 20, 50, 100]}
                                        />
                                    </div>
                                }
                                {pagination.pages > 1 &&
                                    <Pager
                                        page={pagination.page}
                                        pages={pagination.pages}
                                        perPage={pagination.perpage}
                                        onPagerChange={this.handlePagerChange}
                                    />
                                }
                            </div>
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <div style={{minWidth: "210px"}}>
                                    <SearchInput
                                        placeholder="Search Alert Changes"
                                        query={filter.query}
                                        onChange={this.handleSearch}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-body">
                        {loader
                            ?
                            <Loader/>
                            :
                            <div>
                                <Table
                                    list={list}
                                    sort={sort}
                                    query={filter.query}
                                    onSortChange={this.handleSortChange}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item global-search">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

Index.propTypes = {
    history: PropTypes.object,
    auth: PropTypes.object,

    getLocationId: PropTypes.func
};

export default withLocationSelectStore(withGlobalStore(Index));