import { useModal } from "react-modal-hook";
import BearingModal from "../Components/bearing";
import React from "react";
import {
    useChartContext,
    useSearchParams,
} from "../../../pages/chart/highcharts/twf/hooks/chartHooks";
import auth from "../../../services/auth";
import { useChartStateStoreContext } from "../../../pages/chart/highcharts/twf/store/hooks/useStoreContext";
import { useModalHistory } from "../../../hooks/helpers/useModalHistory";
import { MODALS } from "../../../constants/modals";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useEquipmentInvalidate} from "../../../hooks/api/equipment/useEquipmentInvalidate";

export const useBearingModal = () => {
    const { drawChart } = useChartContext();
    const {equipmentItem} = useEquipmentByParams();
    const invalidateEquipment = useEquipmentInvalidate(equipmentItem.id);

    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);

    const [showModal, hideModal] = useModal(() => {
        const searchParams = useSearchParams();

        if (
            +searchParams.selectedPoint !== +selectedPoint ||
            +searchParams.chartType !== +chartType
        ) {
            return <></>;
        }
        return (
            <BearingModal
                installationPointId={+selectedPoint}
                showModal={true}
                drawChart={drawChart}
                onClose={onHide}
                auth={auth}
                equipment={equipmentItem}
                invalidateEquipment={invalidateEquipment}
            />
        );
    }, [selectedPoint, chartType, equipmentItem]);

    const { pushHistory, deleteHistory } = useModalHistory(MODALS.BEARING, showModal, {
        selectedPoint: selectedPoint,
        chartType: chartType,
    });

    const onShow = () => {
        pushHistory();
        showModal();
    };

    const onHide = () => {
        deleteHistory();
        hideModal();
    };

    return [onShow, onHide];
};
