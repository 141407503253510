import {filter as _filter, get as _get, find as _find, cloneDeep as _cloneDeep} from "lodash";
import Helper from "../../../../../../helpers/helper";
import useAlarmList from "../../../../../../hooks/api/Alarm/UseAlarmListQuery";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useSelectedByEquipment} from "../../../../../../stores/zustand/ChartSelectedEquipmentStore";
import {useMemo} from "react";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {fftAlertTypes, FREQUENCY_TYPES} from "../../../../../../constants/constants";
import moment from "moment";
import {useCurrentTimestampsInfo} from "../helpers/useCurrentTimestampsInfo";
import {useCurrentDate} from "../helpers/useCurrentDate";
import FrequencyConverter from "../../../../../../helpers/frequency-converter";
import {useSpeedSettings} from "../helpers/useSpeedSettings";
import auth from "../../../../../../services/auth";
import {useSelectedAxis} from "../helpers/useSelectedAxis";
import {useChartSelectedChartTypeStore} from "../../../../../../stores/zustand/ChartSelectedChartTypeStore";
import {useIsShowChartMarks} from "../../../store/sync/sharedStore";

export const useActiveAlertAnnotation = () => {
    const {equipmentItem} = useEquipmentByParams();
    const {activeAlarms} = useAlarmList(equipmentItem.id);
    const activeInstallationPoints = useSelectedByEquipment(equipmentItem.id);
    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const {fulfilledTimestamp} = useCurrentTimestampsInfo();
    const currentDate = useCurrentDate();
    const {currentSpeed} = useSpeedSettings();
    const selectedAxis = useSelectedAxis();
    const selectedChartTypes = useChartSelectedChartTypeStore();
    const isShowChartMarks = useIsShowChartMarks();

    return useMemo(() => {
        if (!isShowChartMarks) {
            return [];
        }
        let triggered = [];
        let alertsMarkers = _filter(activeAlarms, (alert) => {
            return (
                (+_get(alert, "alertCondition.fft_alert_type", false) === +fftAlertTypes.BAND ||
                    +_get(alert, "alertCondition.fft_alert_type", false) === +fftAlertTypes.ENVELOPE) &&
                +_get(alert, "alertCondition.fft_type", false) === +chartType &&
                _find(selectedAxis, (val) => +val === +_get(alert, "alertCondition.axis_id")) &&
                moment(alert.timestamp).toDate().getTime() === moment(fulfilledTimestamp).toDate().getTime() &&
                +selectedPoint === +alert.installationPointId
            );
        });

        alertsMarkers.forEach((alertLoc) => {
            const alert = _cloneDeep(alertLoc);
            let xTriggered = _get(alert, "readingFrequency");
            if (!xTriggered) {
                let xTriggeredUnits = _get(alert, "alertCondition.fft_band_frequency_type", FREQUENCY_TYPES.HZ);
                if (xTriggeredUnits === FREQUENCY_TYPES.ORDERS && !(currentSpeed > 0)) {
                    xTriggered = 0;
                } else {
                    xTriggered = _get(alert, "alertCondition.fft_band_to", 0);
                    xTriggered = FrequencyConverter.fromType(xTriggeredUnits, xTriggered, currentSpeed).toHz().value;
                }
            }

            const toPush = {
                draggable: "",
                labels: [
                    {
                        className: "chart-alert-wrapper",
                        allowOverlap: true,
                        backgroundColor: alert.alertLevelColor || "green",
                        borderWidth: 0,
                        padding: 5,
                        borderColor: "white",
                        borderRadius: 5,
                        point: {
                            xAxis: 0,
                            yAxis: 0,
                            x: xTriggered,
                            y: +_get(alert, "readingValue"),
                        },
                        x: 0,
                        y: 4,
                        useHTML: true,
                        format:
                            "<div class=\"chart-alert-marker\" style=\"border-color: " +
                            alert.alertLevelColor +
                            ";\">" +
                            "    <div class=\"chart-label invisible open-left\" style=\"border-color: " +
                            alert.alertLevelColor +
                            "; box-shadow: 1px 1px 3px " +
                            alert.alertLevelColor +
                            ";\">" +
                            "        <div class=\"chart-label-hover\" style=\"box-shadow: 0 0 0 10px " +
                            alert.alertLevelColor +
                            ";\"></div>" +
                            "        <div class=\"chart-alert-label-text\">" +
                            "           <span>" +
                            alert.description +
                            "</span>" +
                            "           <span>Peak at:" +
                            "               <b style=\"color: " +
                            alert.alertLevelColor +
                            "\">" +
                            Helper.numberFormat(alert.readingValue, 4) +
                            " " +
                            seriesList.units +
                            "</b>" +
                            "           </span>" +
                            "        </div>" +
                            (auth.userCan("editAlarms")
                                ? "       <div class=\"chart-label-btns\">" +
                                  "           <div data-id=\"" +
                                  alert.id +
                                  "\" data-type=\"ignore\" class=\"btn btn-sm highcharts-alert-action btn-secondary btn-secondary__custom mr-2\">Ignore</div>" +
                                  "           <div data-id=\"" +
                                  alert.id +
                                  "\" data-type=\"ack\" class=\"btn btn-sm highcharts-alert-action btn-primary\">Acknowledge</div>" +
                                  "       </div>"
                                : "") +
                            "    </div>" +
                            "</div>",
                    },
                ],
            };
            triggered.push(_cloneDeep(toPush));
        });

        return triggered;
    }, [activeInstallationPoints, activeAlarms, currentDate, selectedChartTypes, currentSpeed, selectedAxis, isShowChartMarks]);
};
