import React, {Component} from "react";
import PropTypes from "prop-types";
import AlertSettingForm from "./alert-setting-form";
import ActionItemsWrapper from "./action-items-wrapper";

class NotificationsForm extends Component
{
    render() {
        const {user, auth, updateUser} = this.props;

        return (
            <div className={"user-profile-form"}>
                <AlertSettingForm user={user} auth={auth} />
                <ActionItemsWrapper user={user} updateUser={updateUser}/>
            </div>
        );
    }
}

NotificationsForm.propTypes = {
    user: PropTypes.object,
    auth: PropTypes.object,
    updateUser: PropTypes.func
};

export default NotificationsForm;