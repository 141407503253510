import {Component} from "react";
import PropTypes from "prop-types";
import {
    cloneDeep as _cloneDeep,
    each as _each,
    filter as _filter,
    find as _find,
    get as _get,
    includes as _includes,
    isEqual as _isEqual,
    omit as _omit,
    set as _set,
    setWith as _setWith,
} from "lodash";
import Toast from "../toast";
import InstallationPointSettingsApi from "../../../api/InstallationPointSettings";
import swal from "../swal";
import {GENERIC_TRIGGER_TYPE, genericInitialState, WOS_TRIGGER_TYPE, wosInitialState} from "../../../modals/trigger-settings/stores";

const defaultIntervals = {
    temperature_interval: 15,
    temperature_interval_type: 2,
    rms_interval: 1,
    rms_interval_type: 3,
    fft_interval: 1,
    fft_interval_type: 4,
    impactvue_rms_interval: 1,
    impactvue_rms_interval_type: 4,
    impactvue_fft_interval: 3,
    impactvue_fft_interval_type: 4,
};

const timeMultiplier = {
    1: 1,
    2: 60,
    3: 3600,
    4: 86400,
    5: 604800,
};

class SettingsDataHandler extends Component {
    constructor(props) {
        super(props);

        const defaultComponentState = {
            loaded: false,
            inProgress: false,
            settings: {},
            triggers: {
                toggles: {
                    [WOS_TRIGGER_TYPE]: false,
                    [GENERIC_TRIGGER_TYPE]: false,
                },
                data: {},
                form: {},
                restriction: {
                    wos_sensor_types: [],
                    wos_sensor_version: "",
                    generic_sensor_types: [],
                },
            },
            showNotice: false,
            formErrors: {},
            currentVersionType: null,
            wasChange: false,
        };

        this.state = {...defaultComponentState, ...{defaultComponentState: defaultComponentState}};

        this.finished = false;
        this.onSaveOne = this.onSaveOne.bind(this);
        this.onSaveBatch = this.onSaveBatch.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.setSettings();
    }

    componentDidUpdate(prevProps) {
        if (this.props.forceUpdate !== prevProps.forceUpdate) {
            const triggers = this.getTriggers();
            this.setState({triggers: triggers});
        }
        if (!_isEqual(this.props.installationPoint, prevProps.installationPoint)) {
            this.setSettings();
        }
    }

    toggleFlag = (key, value) => {
        this.setState({
            ...this.state,
            wasChange: true,
            triggers: {
                ...this.state.triggers,
                toggles: {
                    ...this.state.triggers.toggles,
                    [key]: value,
                },
            },
        });
    };

    onTriggersFormChange = (e) => {
        const {name, value, type} = e.target;
        let validateValue = value;

        if (type === "number") {
            let {value, min, max} = e.target;
            validateValue = Math.max(Number(min), Math.min(Number(max), Number(value)));
        }

        const newFormData = _cloneDeep(this.state.triggers.form);
        _set(newFormData, name, validateValue);

        this.setState({
            ...this.state,
            wasChange: true,
            triggers: {
                ...this.state.triggers,
                form: {...newFormData},
            },
        });
    };

    getTriggers = () => {
        let triggers = {};

        if (_get(this.props, "triggersStore", false)) {
            let form = Object.assign(
                {
                    [GENERIC_TRIGGER_TYPE]: genericInitialState,
                    [WOS_TRIGGER_TYPE]: wosInitialState,
                },
                _get(_get(this.props, "triggersStore.data", {}), _get(this.props, "installationPoint.id", false), {})
            );

            triggers = {
                ...this.state.triggers,
                toggles: {
                    ...this.state.triggers.toggles,
                    [GENERIC_TRIGGER_TYPE]: !!_get(
                        _get(this.props, "triggersStore.data", {}),
                        [_get(this.props, "installationPoint.id", false), GENERIC_TRIGGER_TYPE],
                        false
                    ),
                    [WOS_TRIGGER_TYPE]: !!_get(
                        _get(this.props, "triggersStore.data", {}),
                        [_get(this.props, "installationPoint.id", false), WOS_TRIGGER_TYPE],
                        false
                    ),
                },
                form: _get(this.props, "installationPoint.id", false) ? form : {},
                data: _get(this.props, "triggersStore.data", {}),
                restriction: _get(this.props, "triggersStore.restriction", {}),
            };
        }

        return triggers;
    };

    setSettings = () => {
        if (this.isBatchSettings()) {
            const batchSettings = this.getBatchSettings();
            this.setState({
                ...this.state.defaultComponentState,
                ...{
                    settings: batchSettings,
                    currentVersionType: _get(Object.keys(batchSettings), "0"),
                },
            });
        } else {
            this.setState({
                ...this.state.defaultComponentState,
                ...{
                    settings: _cloneDeep(_get(this.props, "installationPoint.settings", {})),
                    triggers: this.getTriggers(),
                },
            });
        }
    };

    isBatchSettings = () => {
        return !!Object.keys(this.props.equipment || {}).length;
    };

    getBatchSettings = () => {
        const {equipment} = this.props;
        let batchSettings = {};

        if (equipment) {
            let installationPoints = _get(equipment, "installationPoints", []);

            _each(installationPoints, (installationPoint) => {
                const versionType = _get(installationPoint, "sensor.version_type");
                if (!_includes(+versionType, Object.keys(batchSettings)) && versionType) {
                    const modelSpec = _get(installationPoint, "settings.fft_settings");
                    const isSupportHfdvue = this.isImpactVueEnabled();
                    const defaultFftSettings = {
                        fmax: _get(installationPoint, "sensorParameters.defaultFMax"),
                        lines_of_resolution: _get(installationPoint, "sensorParameters.defaultLor"),
                    };
                    let fftSetting = {};

                    _setWith(fftSetting, "moteFMax", _get(installationPoint, "settings.moteFMax"), Object);

                    _each(modelSpec, (axisData) => {
                        const axisName = this.getAxisName(+axisData.axis_id);
                        let axisFftSettings = {
                            ...defaultFftSettings,
                            ...{
                                axis_id: axisData.axis_id,
                                axis_name: axisName,
                            },
                        };

                        if (isSupportHfdvue) {
                            axisFftSettings = {
                                ...axisFftSettings,
                                ...{
                                    hfdvue_fmax: 1000,
                                    hfdvue_lines_of_resolution: 128,
                                },
                            };
                        }

                        if (+axisData.axis_id !== 1) {
                            axisFftSettings = {
                                ...axisFftSettings,
                                ...{fmax: _get(fftSetting, "moteFMax." + defaultFftSettings.fmax + "." + axisName + "_fmax") || defaultFftSettings.fmax},
                            };
                        }

                        _setWith(fftSetting, "fft_settings." + (axisData.axis_id || 0), axisFftSettings, Object);
                    });

                    _setWith(batchSettings, versionType, {...defaultIntervals, ...fftSetting}, Object);
                }
            });
        }

        return batchSettings;
    };

    getAxisName = (axisId) => {
        let axisName = "x";

        switch (axisId) {
            case 2:
                axisName = "y";
                break;
            case 3:
                axisName = "z";
                break;
        }

        return axisName;
    };

    onChange = (e) => {
        const {name, value} = e.target;
        const {currentVersionType} = this.state;
        let checkArr = name.split(".");

        const settingKey = this.isBatchSettings() ? currentVersionType + "." + name : name;

        if (checkArr.length > 1) {
            let settings = Object.assign({}, this.state.settings);
            _setWith(settings, settingKey, value, Object);
            this.setState({settings: settings, wasChange: true}, () => {
                if (_includes(checkArr, "fmax")) {
                    this.updateYZFMax(value);
                }
            });
        } else {
            let settings = Object.assign({}, this.state.settings);
            _setWith(settings, settingKey, value, Object);
            const checkSettings = this.isBatchSettings() ? _get(settings, currentVersionType) : settings;
            const minInterval = _get(checkSettings, "minInterval");
            const minIntervalReadable = this.getDurationReadable(minInterval);

            let lowerThanMinInterval = false;
            let notMultipleOfReading = false;
            let noticeMessages = [];

            if (this.needCheckOnMin(name)) {
                if (this.lowerThanMinInterval(checkSettings, "rms")) {
                    noticeMessages.push("The minimum RMS interval is " + minIntervalReadable + ".");
                    lowerThanMinInterval = true;
                }
                if (this.lowerThanMinInterval(checkSettings, "fft")) {
                    noticeMessages.push("The minimum FFT interval is " + minIntervalReadable + ".");
                    lowerThanMinInterval = true;
                }
                if (this.lowerThanMinInterval(checkSettings, "impactvue_rms")) {
                    noticeMessages.push("The minimum ImpactVue RMS interval is " + minIntervalReadable + ".");
                    lowerThanMinInterval = true;
                }
                if (this.lowerThanMinInterval(checkSettings, "impactvue_fft")) {
                    noticeMessages.push("The minimum ImpactVue FFT interval is " + minIntervalReadable + ".");
                    lowerThanMinInterval = true;
                }
                if (this.lowerThanMinInterval(checkSettings, "wua")) {
                    noticeMessages.push("The minimum WUA interval is " + minIntervalReadable + ".");
                    lowerThanMinInterval = true;
                }
            }

            if (this.needCheckOnMultiples(name) && !this.isBatchSettings()) {
                const temperatureInterval = _get(checkSettings, "temperature_interval");
                const temperatureIntervalType = _get(checkSettings, "temperature_interval_type");
                const temperatureText = this.getDurationReadable(minInterval).trim();

                const rmsInterval = _get(checkSettings, "rms_interval");
                const rmsIntervalType = _get(checkSettings, "rms_interval_type");
                const rmsIntervalMultiplier = this.getIntervalTypeMultiplier(rmsIntervalType);
                if (!this.isMultipleOfMinInterval(minInterval, rmsInterval * rmsIntervalMultiplier)) {
                    let nearestSuitableValuesString = this.getNearestSuitableValues(minInterval, rmsInterval * rmsIntervalMultiplier);
                    noticeMessages.push(
                        `The RMS interval must be a multiple of the temperature interval (${temperatureText}). ${
                            temperatureInterval * timeMultiplier[temperatureIntervalType] < rmsInterval * timeMultiplier[rmsIntervalType]
                                ? "Suitable values: " + nearestSuitableValuesString + "."
                                : ""
                        }`
                    );
                    notMultipleOfReading = true;
                }

                const fftInterval = _get(checkSettings, "fft_interval");
                const fftIntervalType = _get(checkSettings, "fft_interval_type");
                const fftIntervalMultiplier = this.getIntervalTypeMultiplier(fftIntervalType);
                if (!this.isMultipleOfMinInterval(minInterval, fftInterval * fftIntervalMultiplier)) {
                    let nearestSuitableValuesString = this.getNearestSuitableValues(minInterval, fftInterval * fftIntervalMultiplier);
                    noticeMessages.push(
                        `The FFT interval must be a multiple of the temperature interval (${temperatureText}). ${
                            temperatureInterval * timeMultiplier[temperatureIntervalType] < fftInterval * timeMultiplier[fftIntervalType]
                                ? "Suitable values: " + nearestSuitableValuesString + "."
                                : ""
                        }`
                    );
                    notMultipleOfReading = true;
                }

                const impactvueRmsInterval = _get(checkSettings, "impactvue_rms_interval");
                const impactvueRmsIntervalType = _get(checkSettings, "impactvue_rms_interval_type");
                const impactvueRmsIntervalMultiplier = this.getIntervalTypeMultiplier(impactvueRmsIntervalType);
                if (!this.isMultipleOfMinInterval(minInterval, impactvueRmsInterval * impactvueRmsIntervalMultiplier)) {
                    let nearestSuitableValuesString = this.getNearestSuitableValues(minInterval, impactvueRmsInterval * impactvueRmsIntervalMultiplier);
                    noticeMessages.push(
                        `The ImpactVue RMS interval must be a multiple of the temperature interval (${temperatureText}). ${
                            temperatureInterval * timeMultiplier[temperatureIntervalType] < impactvueRmsInterval * timeMultiplier[impactvueRmsIntervalType]
                                ? "Suitable values: " + nearestSuitableValuesString + "."
                                : ""
                        }`
                    );
                    notMultipleOfReading = true;
                }

                const impactvueFftInterval = _get(checkSettings, "impactvue_fft_interval");
                const impactvueFftIntervalType = _get(checkSettings, "impactvue_fft_interval_type");
                const impactvueFftIntervalMultiplier = this.getIntervalTypeMultiplier(impactvueFftIntervalType);
                if (!this.isMultipleOfMinInterval(minInterval, impactvueFftInterval * impactvueFftIntervalMultiplier)) {
                    let nearestSuitableValuesString = this.getNearestSuitableValues(minInterval, impactvueFftInterval * impactvueFftIntervalMultiplier);
                    noticeMessages.push(
                        `The ImpactVue FFT interval must be a multiple of the temperature interval (${temperatureText}). ${
                            temperatureInterval * timeMultiplier[temperatureIntervalType] < impactvueFftInterval * timeMultiplier[impactvueFftIntervalType]
                                ? "Suitable values:" + nearestSuitableValuesString + "."
                                : ""
                        }`
                    );
                    notMultipleOfReading = true;
                }

                const wuaInterval = _get(checkSettings, "wua_interval");
                const wuaIntervalType = _get(checkSettings, "wua_interval_type");
                const wuaIntervalMultiplier = this.getIntervalTypeMultiplier(wuaIntervalType);
                if (!this.isMultipleOfMinInterval(minInterval, wuaInterval * wuaIntervalMultiplier)) {
                    let nearestSuitableValuesString = this.getNearestSuitableValues(minInterval, wuaInterval * wuaIntervalMultiplier);
                    noticeMessages.push(
                        `The WUA interval must be a multiple of the temperature interval (${temperatureText}). ${
                            temperatureInterval * timeMultiplier[temperatureIntervalType] < wuaInterval * timeMultiplier[wuaIntervalType]
                                ? "Suitable values:" + nearestSuitableValuesString + "."
                                : ""
                        }`
                    );
                    notMultipleOfReading = true;
                }
            }
            const showNotice = lowerThanMinInterval || notMultipleOfReading;

            this.setState({
                settings: settings,
                wasChange: true,
                showNotice: showNotice ? noticeMessages.join("\n") : false,
            });
        }
    };

    updateYZFMax = (fMax) => {
        const {currentVersionType} = this.state;

        let settings = Object.assign({}, this.state.settings);

        const batchKey = this.isBatchSettings() ? currentVersionType + "." : "";
        const moteFMax = _get(settings, batchKey + "moteFMax." + fMax);

        const fftSettings = this.isBatchSettings() ? _get(settings, currentVersionType + ".fft_settings", {}) : _get(settings, "fft_settings", {});

        Object.keys(fftSettings).map((settingKey) => {
            if (+fftSettings[settingKey].axis_id !== 1) {
                const name = "fft_settings." + fftSettings[settingKey].axis_id + ".fmax";
                const key = this.isBatchSettings() ? currentVersionType + "." + name : name;
                _setWith(settings, key, +_get(moteFMax, fftSettings[settingKey].axis_name + "_fmax") || fMax, Object);
            }
        });

        this.setState({settings: settings, wasChange: true});
    };

    lowerThanMinInterval = (settings, key) => {
        const interval = +_get(settings, key + "_interval");
        const type = +_get(settings, key + "_interval_type");
        const minInterval = _get(settings, "minInterval", 900);

        if (+interval === 0 && +type === 0) {
            return false;
        }

        return interval * this.getIntervalTypeMultiplier(type) < minInterval;
    };

    getIntervalTypeMultiplier = (type) => {
        switch (type) {
            case 2:
                return 60;
            case 3:
                return 3600;
            case 4:
                return 86400;
            case 5:
                return 604800;
            default:
                return 1;
        }
    };

    getDurationReadable = (seconds) => {
        let duration = seconds;
        let durationReadable = "";

        if (duration >= 604800) {
            let days = Math.floor(duration / 604800);
            duration -= days * 604800;
            durationReadable += days + " week" + (days > 1 ? "s" : "") + " ";
        }

        if (duration >= 86400) {
            let days = Math.floor(duration / 86400);
            duration -= days * 86400;
            durationReadable += days + " day" + (days > 1 ? "s" : "") + " ";
        }

        if (duration >= 3600) {
            let hours = Math.floor(duration / 3600);
            duration -= hours * 3600;
            durationReadable += hours + " hour" + (hours > 1 ? "s" : "") + " ";
        }

        if (duration >= 60) {
            let minutes = Math.floor(duration / 60);
            duration -= minutes * 60;
            durationReadable += minutes + " minute" + (minutes > 1 ? "s" : "") + " ";
        }

        if (duration > 0) {
            durationReadable += duration + " second" + (duration > 1 ? "s" : "");
        }

        return durationReadable;
    };

    needCheckOnMin = (name) => {
        return [
            "rms_interval",
            "rms_interval_type",
            "fft_interval",
            "fft_interval_type",
            "impactvue_rms_interval",
            "impactvue_rms_interval_type",
            "impactvue_fft_interval",
            "impactvue_fft_interval_type",
            "wua_interval_type",
            "wua_interval",
        ].includes(name);
    };

    needCheckOnMultiples = (name) => {
        return [
            "rms_interval",
            "rms_interval_type",
            "fft_interval",
            "fft_interval_type",
            "impactvue_rms_interval",
            "impactvue_rms_interval_type",
            "impactvue_fft_interval",
            "impactvue_fft_interval_type",
            "wua_interval_type",
            "wua_interval",
        ].includes(name);
    };

    isMultipleOfMinInterval = (intervalInSeconds, valueInSeconds) => {
        return valueInSeconds % intervalInSeconds === 0;
    };

    getNearestSuitableValues = (intervalInSeconds, valueInSeconds) => {
        let loopIterator = Math.floor(valueInSeconds / intervalInSeconds);

        let suitableValues = this.getDurationReadable((loopIterator || 1) * intervalInSeconds);

        if (loopIterator > 0) {
            suitableValues += " or " + this.getDurationReadable(++loopIterator * intervalInSeconds);
        }

        return suitableValues;
    };

    onSave = () => {
        const {wasChange, showNotice} = this.state;

        if (!wasChange) {
            Toast.info("No changes were made.");
            return;
        }

        if (showNotice) {
            return false;
        }

        this.isBatchSettings() ? this.onSaveBatch() : this.onSaveOne();
    };

    onSaveOne = () => {
        const {settings, triggers} = this.state;
        const {installationPoint, updateSettings, afterSubmit} = this.props;
        let form;

        if (_get(triggers, "form", false)) {
            form = {
                id: installationPoint.id,
            };

            if (triggers.toggles[WOS_TRIGGER_TYPE]) {
                form[WOS_TRIGGER_TYPE] = triggers.form[WOS_TRIGGER_TYPE];
            }

            if (triggers.toggles[GENERIC_TRIGGER_TYPE]) {
                form[GENERIC_TRIGGER_TYPE] = triggers.form[GENERIC_TRIGGER_TYPE];
            }
        }

        this.setState({inProgress: true}, () => {
            InstallationPointSettingsApi.update(installationPoint.id, {
                ...settings,
                triggers: form,
            })
                .then((data) => {
                    if (data.status === "ok") {
                        updateSettings().then(() => {
                            if (afterSubmit && typeof afterSubmit === "function") {
                                afterSubmit().then(() => {
                                    this.setState({inProgress: false});
                                    Toast.success("The intervals have been updated.");
                                });
                            } else {
                                this.setState({inProgress: false}, () => {
                                    Toast.success("The intervals have been updated.");
                                });
                            }
                        });
                    }
                })
                .catch((response) => {
                    if (response.status === 422) {
                        this.setState({formErrors: response.errors || {}, inProgress: false});
                    } else {
                        swal("", "Cannot save intervals. Please contact administrator.", "error");
                        this.setState({inProgress: false});
                    }
                });
        });
    };

    onSaveBatch = () => {
        let {settings} = this.state;
        const {equipment, updateSettings} = this.props;

        const defaultSettings = this.getBatchSettings();

        _each(settings, (setting, type) => {
            if (_isEqual(setting, _get(defaultSettings, type))) {
                settings = _omit(settings, type);
            }
        });

        this.setState({inProgress: true}, () => {
            InstallationPointSettingsApi.updateForEquipment(equipment.id, settings)
                .then((data) => {
                    if (data.status === "ok") {
                        updateSettings().then(() => {
                            this.setState({inProgress: false});
                            Toast.success("The intervals have been updated.");
                        });
                    }
                })
                .catch((response) => {
                    if (response.status === 422) {
                        this.setState({formErrors: response.errors || {}, inProgress: false});
                    } else {
                        swal("", "Cannot save intervals. Please contact administrator.", "error");
                        this.setState({inProgress: false});
                    }
                });
        });
    };

    isImpactVueEnabled = () => {
        const {installationPoint, equipment} = this.props;

        if (this.isBatchSettings()) {
            return +_filter(equipment.installationPoints, (installationPoint) => +_get(installationPoint, "sensor.is_hfdvue", 0)).length;
        }

        return !!_get(installationPoint, "sensor.is_hfdvue", 0) && _get(installationPoint, "sensor.supportHfdvue", 0);
    };

    setCurrentVersionType = (currentVersionType) => {
        this.setState({currentVersionType});
    };

    getNodesSerials = () => {
        const {installationPoint, equipment} = this.props;

        let nodeSerials = [];

        if (this.isBatchSettings()) {
            _each(equipment.installationPoints, (installationPoint) => {
                const nodeSerial = _get(installationPoint, "sensor.node_serial");
                if (!_includes(nodeSerials, nodeSerial) && !_get(installationPoint, "sensor.is_mote")) {
                    nodeSerials.push(nodeSerial);
                }
            });
        } else {
            if (!_get(installationPoint, "sensor.is_mote")) {
                nodeSerials.push(_get(installationPoint, "sensor.node_serial"));
            }
        }

        return nodeSerials;
    };

    getCurrentModelSpec = () => {
        const {currentVersionType} = this.state;
        const {equipment} = this.props;

        const findInstallationPoint = _find(equipment.installationPoints, (point) => +_get(point, "sensor.version_type") === +currentVersionType);

        return (findInstallationPoint || {}).modelSpec || {};
    };

    getCurrentSensorParameters = () => {
        const {currentVersionType} = this.state;
        const {equipment} = this.props;

        const findInstallationPoint = _find(equipment.installationPoints, (point) => +_get(point, "sensor.version_type") === +currentVersionType);

        return (findInstallationPoint || {}).sensorParameters || {};
    };

    render() {
        const {installationPoint, settings, showNotice, formErrors, validation, currentVersionType, inProgress, triggers} = this.state;
        const {children} = this.props;

        return children({
            settings,
            triggers,
            specs: _get(installationPoint, "modelSpec", {}),
            sensorParameters: _get(installationPoint, "sensorParameters", {}),
            showNotice,
            formErrors,
            validation,
            currentVersionType,
            inProgress,
            versionType: +_get(installationPoint, "sensor.version_type", 1),
            onChange: this.onChange,
            toggleFlag: this.toggleFlag,
            onTriggersFormChange: this.onTriggersFormChange,
            onSave: this.onSave,
            isImpactVueEnabled: this.isImpactVueEnabled,
            getNodesSerials: this.getNodesSerials,
            isBatchSettings: this.isBatchSettings,
            setCurrentVersionType: this.setCurrentVersionType,
            getCurrentModelSpec: this.getCurrentModelSpec,
            getCurrentSensorParameters: this.getCurrentSensorParameters,
            setSettings: this.setSettings,
        });
    }
}

SettingsDataHandler.propTypes = {
    installationPoint: PropTypes.object,
    triggersStore: PropTypes.object,
    forceUpdate: PropTypes.bool,
    equipment: PropTypes.object,
    updateSettings: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    afterSubmit: PropTypes.func,
};

export default SettingsDataHandler;
