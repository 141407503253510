import React, {Component} from "react";
import PropTypes from "prop-types";
import Creatable from "react-select/creatable/dist/react-select.esm";
import {ValidationError} from "../../../shared";
import {
    get as _get,
    find as _find
} from "lodash";

const selectStyles = {
    control: (provided) => ({
        ...provided,
        height: 32,
        minHeight: 32
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: "100000"
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#9E9E9E",
    }),
    option: (provided) => ({
        ...provided,
        padding: "0 7px"
    }),
};

const darkModeSelectStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: "rgb(56, 56, 46)",
        height: 32,
        minHeight: 32
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#ccc"
    }),
    singleValueLabel: (provided) => ({
        ...provided,
        color: "#ccc"
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#36342a"
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: "100000"
    }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused
                ? "#aaa"
                : null,
            color: isFocused
                ? "#333"
                : "#ddd",
            padding: "0 7px"
        };
    },
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "0.4rem 0.25rem",
    }),
    input: (provided) => ({
        ...provided,
        color: "#ccc"
    })
};

const isDarkTheme = localStorage.getItem("lightMode");

class ValueSelect extends Component
{
    constructor(props) {
        super(props);

        this.select = React.createRef();
    }

    handleFocus = () => {
        this.select.state.inputValue = this.props.value;
    };

    onChangeSelect = (target, event) => {
        if (event.action === "create-option") {
            this.updateValue(event.name, target.value);
        }
        if (event.action === "select-option") {
            this.updateValue(event.name, target.value);
        }
        if (event.action === "clear") {
            this.updateValue(event.name, "");
        }
    }

    updateValue = (name, value) => {
        const {onChange} = this.props;
        onChange({
            target: {
                name: name,
                value: value
            }
        });
    }

    render() {
        const {name, value, errorMsg, parameter} = this.props;
        const defaultValues = _get(parameter || [], "default_values", []);

        const options = (defaultValues || []).map(value => {
            return {label: value, value: value};
        });

        return (
            <React.Fragment>
                <Creatable
                    ref={ref => {
                        this.select = ref;
                    }}
                    className={"form-control-select" + (errorMsg ? " is-invalid" : "")}
                    name={name}
                    value={_find(options, {value: value})}
                    styles={ isDarkTheme === "true" ? darkModeSelectStyles : selectStyles }
                    options={options}
                    noOptionsMessage={() => {return "No options exist - you must enter a new value in the field and click enter on your keyboard.";}}
                    isClearable={true}
                    blurInputOnSelect={true}
                    onChange={this.onChangeSelect}
                    onFocus={this.handleFocus}
                    closeMenuOnScroll={(e) => e && e.target && e.target.classList && e.target.classList.contains("custom-scroll")}
                    menuPortalTarget={document.body}
                    menuPlacement={"auto"}
                    formatCreateLabel={(label) => "Create \"" + label + "\" (press enter to create new)"}
                />
                <ValidationError message={errorMsg}/>
            </React.Fragment>
        );
    }
}

ValueSelect.propTypes = {
    parameter: PropTypes.object,
    name: PropTypes.string,
    value: PropTypes.any,
    errorMsg: PropTypes.string,
    onChange: PropTypes.func
};

export default ValueSelect;