import React from "react";
import PropTypes from "prop-types";


const TachometerRpmTemplate = ({alert, isCurrent, update, validErrorMsg}) => {
    return (
        <div className="row mt-2 align-items-start" style={{minHeight: "30px"}}>
            <div className="col-md-5">
                <span className="d-block mt-2">Tachometer RPM (From / To)</span>
            </div>
            <div className="col-md-7">
                <div className="row">
                    <div className="col-md-6 mt-2 mt-md-0">
                        <input
                            className={`form-control request-form-control ${(alert.tachometerRpmFrom === "" && validErrorMsg && isCurrent) ? "is-invalid"
                                : validErrorMsg && isCurrent && !Number.isInteger(+alert.tachometerRpmFrom) ? "is-invalid"
                                    : alert.tachometerRpmFrom < 0 ? "is-invalid" : (alert.tachometerRpmFrom !== "" && +alert.tachometerRpmFrom > +alert.tachometerRpmTo) && validErrorMsg && isCurrent ? "is-invalid" : ""}`}
                            value={alert.tachometerRpmFrom ? alert.tachometerRpmFrom.toString() : ""}
                            onChange={ev => update({tachometerRpmFrom: ev.target.value, tachometer_rpm_from: ev.target.value})}
                            disabled={!isCurrent}
                            type={"number"}
                            placeholder="From"
                            min={0}
                            step={1}
                        />

                        {alert.tachometerRpmFrom === "" && validErrorMsg && isCurrent &&
                        <span className="d-block color-danger mt-1 text-right small-error-msg">This field is required and only accepts integers.</span>
                        }

                        {alert.tachometerRpmFrom !== "" && validErrorMsg && isCurrent && !Number.isInteger(+alert.tachometerRpmFrom) &&
                            <span className="d-block color-danger mt-1 text-right small-error-msg">This field is required and only accepts integers.</span>
                        }

                        {(alert.tachometerRpmFrom !== "" && +alert.tachometerRpmFrom > +alert.tachometerRpmTo) && validErrorMsg && isCurrent &&
                        <span className="small-error-msg d-block color-danger mt-1 text-right">The &rdquo;From&rdquo; value must be less than the &rdquo;To&rdquo; value.</span>
                        }

                        {+alert.tachometerRpmFrom <= 0 && validErrorMsg && isCurrent &&
                        <span className="small-error-msg d-block color-danger mt-1 text-right">The &rdquo;From&rdquo; value must be more than 0</span>
                        }
                    </div>
                    <div className="col-md-6 mt-2 mt-md-0">
                        <input
                            className={`form-control request-form-control ${(alert.tachometerRpmTo === "" && validErrorMsg && isCurrent) ? "is-invalid" : validErrorMsg && isCurrent && !Number.isInteger(+alert.tachometerRpmTo) ? "is-invalid" : (alert.tachometerRpmTo !== "" && +alert.tachometerRpmTo < +alert.tachometerRpmFrom) && validErrorMsg && isCurrent ? "is-invalid" : ""}`}
                            value={alert.tachometerRpmTo ? alert.tachometerRpmTo.toString() : ""}
                            onChange={ev => update({tachometerRpmTo: ev.target.value, tachometer_rpm_to: ev.target.value})}
                            disabled={!isCurrent}
                            type={"number"}
                            placeholder="To"
                            min={0}
                            step={1}
                        />

                        {alert.tachometerRpmTo === "" && validErrorMsg && isCurrent &&
                        <span className="d-block color-danger mt-1 text-right small-error-msg">This field is required and only accepts integers.</span>
                        }

                        {alert.tachometerRpmTo !== "" && validErrorMsg && isCurrent && !Number.isInteger(+alert.tachometerRpmTo) &&
                            <span className="d-block color-danger mt-1 text-right small-error-msg">This field is required and only accepts integers.</span>
                        }

                        {(alert.tachometerRpmTo !== "" && +alert.tachometerRpmTo < +alert.tachometerRpmFrom) && validErrorMsg && isCurrent &&
                        <span className="small-error-msg d-block color-danger mt-1 text-right">The &rdquo;To&rdquo; value must be higher than the &rdquo;From&rdquo; value.</span>
                        }

                        {+alert.tachometerRpmTo <= 0 && validErrorMsg && isCurrent &&
                        <span className="small-error-msg d-block color-danger mt-1 text-right">The &rdquo;To&rdquo; value must be more than 0</span>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

TachometerRpmTemplate.propTypes = {
    alert: PropTypes.object,
    isCurrent: PropTypes.bool,
    validErrorMsg: PropTypes.bool,
    update: PropTypes.func,
};

export default TachometerRpmTemplate;