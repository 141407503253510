import React from "react";
import PropTypes from "prop-types";
import AlertModal from "../../../modals/alert/modal";
import AcknowledgeModal from "../../../modals/alarms/acknowledge";
import Helper from "../../../helpers/helper";
import IgnoreModal from "../../../modals/alarms/ignore";
import AddChartMarkModal from "../../../modals/chartMarkSimple/components/addChartMark";
import EquipmentModal from "../../../modals/equipment/equipment-modal";
import EquipmentCloneModal from "../../../modals/equipment-clone";
import SweetAlert from "react-bootstrap-sweetalert";
import {ACTION} from "../reducer/mainReducer";
import EquipmentApi from "../../../api/equipment";
import {useHistory} from "react-router";
import ApiAlarm from "../../../api/alarm";
import auth from "../../../services/auth";
import {useChartSelectedEquipmentStore} from "../../../stores/zustand/ChartSelectedEquipmentStore";
import {useEquipmentInvalidate} from "../../../hooks/api/equipment/useEquipmentInvalidate";
import Toast from "../../shared/toast";

const ModalWrapper = (props) => {
    const {children, dispatch, equipment, state, chartTypes, deleteEquipment, selectedAlarms, selectedType} = props;
    const searchParams = Helper.getHashParams();
    const history = useHistory();

    const equipmentSelected = useChartSelectedEquipmentStore();
    const invalidateEquipment = useEquipmentInvalidate(equipment.id);
    const handleSubmitAcknowledgeAlarmModal = (data) => {
        const {selected} = Helper.getHashParams();
        const {selectedAlarms, queryFilters, selectedType, excluded} = state;
        const {equipment} = props;

        if ((selected && selected === "all") || selectedType === "full-all") {
            data["ids"] = selectedType === "full-all" ? "full-all" : selectedAlarms;
            data["filters"] = queryFilters;
            data["excluded"] = excluded;
            data["equipment_id"] = equipment.id;
        }

        return EquipmentApi.checkService(equipment.id).then(() =>
            ApiAlarm.acknowledge(data).then(() => {
                history.push(Helper.deleteHashParams(["id", "modalAlarm", "selected"]));
                dispatch({
                    type: ACTION.SET_STATE,
                    state: {selectedAlarms: [], selectedType: null},
                    callback: props.updateAlertsList,
                });
                props.updateCountActiveAlarms();
                Toast.success("The alert has been acknowledged.");
            })
        );
    };
    const handleSubmitIgnoreAlarmModal = (data) => {
        const {selected} = Helper.getHashParams();
        const {selectedAlarms, queryFilters, selectedType, excluded} = state;
        const {equipment} = props;

        if ((selected && selected === "all") || selectedType === "full-all") {
            data["ids"] = selectedType === "full-all" ? "full-all" : selectedAlarms;
            data["filters"] = queryFilters;
            data["excluded"] = excluded;
            data["equipment_id"] = equipment.id;
        }

        return EquipmentApi.checkService(equipment.id).then(() =>
            ApiAlarm.ignore(data).then(() => {
                history.push(Helper.deleteHashParams(["id", "modalAlarm", "selected"]));
                props.updateCountActiveAlarms();
                dispatch({
                    type: ACTION.SET_STATE,
                    state: {selectedAlarms: [], selectedType: null},
                    callback: props.updateAlertsList,
                });
                Toast.success("The alert has been ignored.");
            })
        );
    };

    const handleEquipmentModal = () => {
        history.push(Helper.setHashParams({modal: "equipment"}));
    };

    const closeEquipmentModal = () => {
        history.push(Helper.deleteHashParams(["modal"]));
    };

    const handleEquipmentCloneModal = () => {
        history.push(Helper.setHashParams({modal: "clone-equipment"}));
    };
    const chartTypeExists = (type) => {
        return Object.keys(props.chartTypes[type] || {}).length > 0;
    };

    const isAlertOpened = () => {
        const searchParams = Helper.getHashParams();
        return searchParams.modal === "alert" && chartTypeExists(searchParams.type);
    };

    const closeModal = () => {
        dispatch({
            type: ACTION.SET_STATE,
            state: {selectedAlarms: [], selectedType: null},
            callback: () => history.push(Helper.deleteHashParams(["id", "modal", "selected", "timestamp"])),
        });
    };

    const handleDeleteEquipmentModal = () => {
        closeEquipmentModal();
        dispatch({type: ACTION.SET_STATE, state: {deleteEquipment: true}});
    };

    const handleCancelDeleteEquipmentModal = () => {
        dispatch({
            type: ACTION.SET_STATE,
            state: {deleteEquipment: false},
            callback: handleEquipmentModal,
        });
    };

    const handleDeleteEquipment = () => {
        EquipmentApi.delete(props.equipment.id).then(() => {
            window.location.href = "/";
        });
    };

    return (
        <>
            {children}
            <React.Fragment>
                {auth.userCan("editAlarms") && (
                    <>
                        {isAlertOpened() && <AlertModal />}
                        {searchParams.modalAlarm === "ack" && (!!searchParams.id || selectedAlarms.length > 0 || selectedType !== null) && (
                            <AcknowledgeModal
                                title="Acknowledge Alert"
                                showModal={true}
                                submitTitle="Acknowledge"
                                onSubmit={(data) => handleSubmitAcknowledgeAlarmModal(Object.assign({}, data, {id: searchParams.id}))}
                                onClose={() => history.push(Helper.deleteHashParams(["id", "modalAlarm"]))}
                            />
                        )}
                        {searchParams.modalAlarm === "ignore" && (!!searchParams.id || selectedAlarms.length > 0 || selectedType !== null) && (
                            <IgnoreModal
                                title="Ignore Alert"
                                showModal={true}
                                submitTitle="Ignore"
                                onSubmit={(data) => handleSubmitIgnoreAlarmModal(Object.assign({}, data, {id: searchParams.id}))}
                                onClose={() => history.push(Helper.deleteHashParams(["id", "modalAlarm"]))}
                            />
                        )}
                    </>
                )}
                {searchParams.modal === "edit-chart-mark" && !!searchParams.id && state.chartMarksLoaded && (
                    <AddChartMarkModal
                        chartMarkId={+searchParams.id}
                        equipmentId={+props.match.params.equipmentId}
                        chartMarks={props.chartMarks}
                        showModal={true}
                        history={history}
                        equipmentSelected={equipmentSelected}
                        onClose={closeModal}
                        timestamp={+Helper.getHashParams().timestamp}
                        addChartMark={props.addChartMark}
                        updateChartMark={props.updateChartMark}
                    />
                )}
                {searchParams.modal === "equipment" && (
                    <EquipmentModal
                        showModal={true}
                        equipment={equipment}
                        auth={auth}
                        onClone={handleEquipmentCloneModal}
                        onClose={closeEquipmentModal}
                        onDelete={handleDeleteEquipmentModal}
                        invalidateEquipment={invalidateEquipment}
                        chartTypes={chartTypes}
                    />
                )}
                {!auth.equipmentViewOnly(equipment) && (
                    <>
                        {searchParams.modal === "clone-equipment" && <EquipmentCloneModal equipment={equipment} />}
                        {deleteEquipment && (
                            <SweetAlert
                                danger
                                title="DELETE EQUIPMENT"
                                showCancel
                                confirmBtnText="Yes"
                                cancelBtnBsStyle="default"
                                btnSize="xs"
                                onCancel={handleCancelDeleteEquipmentModal}
                                onConfirm={handleDeleteEquipment}
                            >
                                {equipment.installationPoints.length > 0 || !!equipment.isLastEquipmentOnRoute ? (
                                    <div role="alert">
                                        <div className="alert-text">
                                            {equipment.installationPoints.length > 0 && (
                                                <>
                                                    If you delete this equipment, all installation <br />
                                                    points and associated data will also be <br />
                                                    removed. <br />
                                                    <br />
                                                </>
                                            )}
                                            {!!equipment.isLastEquipmentOnRoute && (
                                                <>
                                                    This equipment is the only item in the {equipment?.routeNames?.join(", ")} Route
                                                    {equipment?.routeNames?.length > 1 && "s"}. <br />
                                                    Deleting this equipment will also remove the {equipment?.routeNames?.join(", ")} Route
                                                    {equipment?.routeNames?.length > 1 && "s"}. <br /> <br />
                                                </>
                                            )}
                                            Are you sure you want to delete this equipment?
                                        </div>
                                    </div>
                                ) : (
                                    <React.Fragment>Are you sure you want to delete this equipment?</React.Fragment>
                                )}
                            </SweetAlert>
                        )}
                    </>
                )}
            </React.Fragment>
        </>
    );
};

ModalWrapper.propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
    equipment: PropTypes.object,
    state: PropTypes.object,
    chartTypes: PropTypes.object,
    user: PropTypes.object,
    deleteEquipment: PropTypes.bool,
    addChartMark: PropTypes.func,
    updateChartMark: PropTypes.func,
    chartMarks: PropTypes.object,
    updateAlertsList: PropTypes.func,
    match: PropTypes.object,
    updateCountGlobalNotes: PropTypes.func,
    updateCountActiveAlarms: PropTypes.func,
    handleNotesCount: PropTypes.func,
    selectedAlarms: PropTypes.array,
    selectedType: PropTypes.number,
};

export default ModalWrapper;
