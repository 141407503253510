import { useMemo } from "react";
import ChartHelper from "../../../../../helpers/chart";
import { ContextMenuSimpleContent } from "../components/contextMenu/simpleMenu";

export const CHART_TYPE = {
    RMS: "RMS"
};

export const useChartAbstractFactory = (props) => {
    return useMemo(() => {
        return {
            chartTooltipFormatter: +props.chartType === 14 ? ChartHelper.formatTachTooltip : ChartHelper.formatTooltip,
            chartTypeName: CHART_TYPE.RMS,
            ChartContextMenu: ContextMenuSimpleContent,
        };
    }, [props.chartType]);
};
