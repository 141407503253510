import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Modal} from "../shared";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {withRouter} from "react-router";
import Helper from "../helpers/helper";

const BatteryVoltageModal = props => {
    const {node, history} = props;

    if (!node) return "";

    return (
        <Modal
            size="xl"
            {...props}
            showModal={true}
            title={"Battery Voltage" + ""}
            className="custom-modal"
            withoutSubmit={true}
            onClose={() => history.push(Helper.deleteHashParams(["modal", "serial"]))}
        >
            <div className="mb-2"/>
            <Chart node={node}/>
        </Modal>
    );
};

const initialOptions = {
    title: {
        text: null
    },
    chart: {
        animation: false,
        zoomType: false,
        panning: true,
        backgroundColor: "#FFFFFF",
        height: window.innerHeight * 0.5,
    },
    credits: {
        enabled: false
    },
    plotOptions: {
        line: {
            lineWidth: 1,
            states: {
                hover: {
                    lineWidth: 1
                }
            }
        },
        series: {
            states: {
                inactive: {
                    opacity: 1
                }
            },
            animation: false,
            connectNulls: false
        }
    },
    yAxis: {
        opposite: false,
        title: {
            align: "middle",
            text: "Voltage"
        }
    },
    xAxis: {
        ordinal: false,
        crosshair: true,
        type: "datetime"
    },
    tooltip: {
        backgroundColor: "rgba(255, 255, 255, .85)",
        valueDecimals: 3,
        valueSuffix: " V",
        followPointer: true,
        animation: false,
        shared: true,
        split: true
    },
    legend: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    series: []
};

const Chart = ({node}) => {
    const [options, setOptions] = useState(initialOptions);
    const batteryList = (node.battery_voltage || {}).list || {};

    useEffect(() => {
        let series = [];
        Object.keys(batteryList).forEach(key => {
            series.push({
                name: key,
                data: batteryList[key],
                dataGrouping: {
                    approximation: "high",
                    forced: true
                }
            });
        });
        setOptions({...options, series});
    }, [node.serial]);

    return (
        <HighchartsReact
            containerProps={{className: "battery-voltage"}}
            highcharts={Highcharts}
            constructorType="chart"
            options={options}
        />
    );
};

Chart.propTypes = {
    node: PropTypes.object
};

BatteryVoltageModal.propTypes = {
    node: PropTypes.object,
    history: PropTypes.object
};

export default withRouter(BatteryVoltageModal);