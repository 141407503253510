import {useQuery} from "@tanstack/react-query";
import {ALERT_API} from "./constants";
import ApiAlertCondition from "../../../api/alertCondition";


const useGroupEmailUsersQuery = (params) => {
    const {
        isSuccess,
        data: groupEmailUserList,
        ...rest
    } = useQuery([ALERT_API.ALERT_CONDITION_GROUP_EMAIL_USERS, params], () =>  ApiAlertCondition.getGroupEmailUsers(params), {select: (response) => response.result});

    return {
        isSuccess,
        groupEmailUserList,
        ...rest,
    };
};

export default useGroupEmailUsersQuery;
