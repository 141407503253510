import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import ApiGateways from "../../../api/gateway";
import {
    get as _get,
    map as _map
} from "lodash";
import Helper from "../../../helpers/helper";
import Loader from "../../../shared/loader/loader";
import Toast from "../../shared/toast";
import "../../../assets/scss/components/gateway/gateway.scss";
import SweetAlert from "react-bootstrap-sweetalert";

const BridgeChannelStats = (props) => {

    const {user, gatewayId, selectedChannel, onChange, usedChannelsAtFacility} = props;

    const [loader, setLoader] = useState(true);
    const [confirmAlert, setConfirmAlert] = useState(false);
    const [channelToChange, setChannelToChange] = useState(null);
    const [channelStats, setChannelStats] = useState([]);

    useEffect(() => {
        Promise.all([ApiGateways.channelStats(gatewayId)]).then(([statData]) => {
            setLoader(false);
            setChannelStats(_get(statData, "channelStats"));
        });
    }, []);

    const scanBridgeChannelStats = () => {
        setLoader(true);
        setConfirmAlert(false);

        Promise.all([ApiGateways.scanChannelStats({gateway_id: gatewayId})]).then(([scanResponse]) => {
            if (_get(scanResponse, "status") === "ok") {
                Toast.success("The channels of the gateway are currently being scanned. Please note that this may take up to 30 minutes.");
            } else {
                Toast.error("Failed to scan. Try again.");
            }

            setLoader(false);
        });
    };

    const handleChange = (event) => {
        const targetName = _get(event, "target.name", "");
        const targetValue = _get(event, "target.value");

        if (targetName === "changeChannel") {
            setChannelToChange(targetValue);
        }
    };

    const assignChannel = () => {
        setLoader(true);

        Promise.all([ApiGateways.changeChannel({gateway_id: gatewayId, channel_number: channelToChange})]).then(([response]) => {
            if (_get(response, "status") === "ok") {
                onChange({
                    target: {
                        name: "channel_number",
                        value: channelToChange,
                    }
                });
                Toast.success("The channel has been changed.");
            } else {
                Toast.error(_get(response, "message") ?? "Failed to change channel. Try again.");
            }

            setLoader(false);
            setChannelToChange(null);
        });
    };

    return (
        <div>
            <div className="subheader">
                <div className="subheader-title">
                    <span>Bridge channel stats</span>
                </div>
                <div className="subheader-controls">
                </div>
            </div>
            <div className="block">
                <div className="block-body">
                    <div className="row">
                        <div className="col-md-12">
                            <ScanButton disabled={loader} onClick={() => setConfirmAlert(true)}/>
                            {loader ? <Loader/> :
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th colSpan={8}><h5>Channel Stats</h5></th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th>Channel</th>
                                            <th>Channel Quality</th>
                                            <th>Interference Duty</th>
                                            <th>Interference Avg</th>
                                            <th>Raw Avg</th>
                                            <th># of Samples</th>
                                            <th>Collected (Date/Time)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {channelStats && channelStats.length > 0
                                            ? channelStats.map((stat) => {
                                                return <ChannelStatRow
                                                    key={_get(stat, "channel")}
                                                    user={user}
                                                    onChange={handleChange}
                                                    selectedChannel={selectedChannel}
                                                    stat={stat}/>;
                                            })
                                            : <tr>
                                                <td colSpan={8} className="text-center text-info">
                                                    No channel stats exist.
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {confirmAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title=""
                    onConfirm={scanBridgeChannelStats}
                    onCancel={() => setConfirmAlert(false)}
                >
                    Are you sure you want to submit a request to scan the channels of the gateway?
                </SweetAlert>
            )}

            {channelToChange !== null && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title=""
                    onConfirm={assignChannel}
                    onCancel={() => setChannelToChange(null)}
                >

                    {_get(usedChannelsAtFacility, channelToChange) &&
                        (<React.Fragment>
                            <p>
                                The following gateway(s) are also assigned to this channel and can cause communication
                                problems if the gateways are within physical range of each other:
                            </p>

                            <div>
                                {_map(_get(usedChannelsAtFacility, channelToChange), (serial) => {
                                    return <p>{serial}</p>;
                                })}
                            </div>
                        </React.Fragment>)
                    }

                    Are you sure you want to change the channel from {selectedChannel} to {channelToChange}?
                </SweetAlert>
            )}
        </div>
    );
};

BridgeChannelStats.propTypes = {
    user: PropTypes.object,
    gatewayId: PropTypes.number,
    selectedChannel: PropTypes.number,
    usedChannelsAtFacility: PropTypes.array,
    onChange: PropTypes.func
};

const ChannelStatRow = (props) => {

    const {stat, user, selectedChannel, onChange} = props;
    const channel = +_get(stat, "channel");

    const changeChannelBlock = () => {
        if (+selectedChannel === channel) {
            return checkboxForm(true, true);
        }

       return checkboxForm();
    };

    const checkboxForm = (checked = false, disabled = false) => {
        return (<label className="form-checkbox text-center">
            <input className={"form-checkbox"}
                   type="checkbox"
                   checked={checked}
                   disabled={disabled}
                   onChange={() =>
                       onChange({
                           target: {
                               name: "changeChannel",
                               value: channel,
                           }
                       })}
            />
            <span
                className={"sensor-checkbox-wrapper"}
                style={{ backgroundColor: checked ? "rgb(30, 144, 255)" : "inherit", borderColor: "rgb(30, 144, 255)"  }}
            />
        </label>);
    };

    const channelQualityStars = (channelQuality) => {
        return (
            <span>
                 {[...Array(5).keys()].map((number) => {
                     if ((number + 1) <= channelQuality) {
                        return (<i style={{color: "#ffc72a"}} className="fas fa-star"></i>);
                     } else {
                         return (<i className="far fa-star"></i>);
                     }
                 })}
            </span>
        );
    };

    return (
        <tr style={{"borderRadius": "100%"}}>
            <td>{changeChannelBlock()}</td>
            <td>{channel}</td>
            <td>{channelQualityStars(_get(stat, "channelQuality"))}</td>
            <td>{_get(stat, "interferenceDuty")}</td>
            <td>{_get(stat, "interferenceAvg")}</td>
            <td>{_get(stat, "rawAvg")}</td>
            <td>{_get(stat, "numSamples")}</td>
            <td>{_get(stat, "collectedAt") ? Helper.dateToUserFormat(_get(stat, "collectedAt"), user) : ""}</td>
        </tr>
    );
};

ChannelStatRow.propTypes = {
    user: PropTypes.object,
    stat: PropTypes.object,
    selectedChannel: PropTypes.number,
    onChange: PropTypes.func
};

const ScanButton = ({onClick, disabled}) => {
    return <button
        disabled={disabled}
        className="btn btn-sm btn-primary scan-channel-btn"
        onClick={onClick}
    >
        <span>Scan</span>
    </button>;
};

ScanButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

export default BridgeChannelStats;