import Helper from "./helper";
import {FREQUENCY_TYPES} from "../constants/constants";

export default class FrequencyConverter {
    constructor(units) {
        this.units = units;
    }

    static fromHz(value, speed) {
        const converter = new FrequencyConverter();
        converter._setUnits(new Hz(value, speed));
        return converter;
    }

    static fromCpm(value, speed) {
        const converter = new FrequencyConverter();
        converter._setUnits(new Cpm(value, speed));
        return converter;
    }

    static fromOrders(value, speed) {
        const converter = new FrequencyConverter();
        return converter._setUnits(new Orders(value, speed));
    }

    static fromType(type = FREQUENCY_TYPES.HZ, value, speed) {
        switch (type) {
            case FREQUENCY_TYPES.HZ:
                return FrequencyConverter.fromHz(value, speed);
            case FREQUENCY_TYPES.CPM:
                return FrequencyConverter.fromCpm(value, speed);
            case FREQUENCY_TYPES.ORDERS:
                return FrequencyConverter.fromOrders(value, speed);
        }
        throw new Error("Unknown frequency type");
    }

    _setUnits(units) {
        this.units = units;
        return this;
    }

    toHz() {
        return this._setUnits(this.units.toHz());
    }

    toCpm() {
        return this._setUnits(this.units.toCpm());
    }

    toOrders() {
        return this._setUnits(this.units.toOrders());
    }

    toType(type) {
        switch (type) {
            case FREQUENCY_TYPES.HZ:
                return this.toHz();
            case FREQUENCY_TYPES.CPM:
                return this.toCpm();
            case FREQUENCY_TYPES.ORDERS:
                return this.toOrders();
        }
        throw new Error("Unknown frequency type");
    }

    numberFormat(precision, options) {
        return this.units.numberFormat(precision, options);
    }

    format(precision, options) {
        return this.units.format(precision, options);
    }

    get value() {
        return this.units.value;
    }
}

class Hz {
    constructor(value, speed) {
        this.value = +value;
        this.speed = +speed;
    }

    toHz() {
        return this;
    }

    toCpm() {
        return new Cpm(this.value * 60, this.speed);
    }

    toOrders() {
        if (isNaN(this.speed) || this.speed === 0) {
            throw new Error("Running speed not set");
        }
        const orderValue = (this.value * 60) / this.speed;
        return new Orders(orderValue, this.speed);
    }

    numberFormat(precision, {isInThousands = false} = {}) {
        const value = Helper.numberFormat(this.value, precision);
        return isInThousands ? value / 1000 : value;
    }

    format(precision) {
        return `<b>${this.numberFormat(precision)}</b> <b>${FREQUENCY_TYPES.HZ}</b>`;
    }
}

class Cpm {
    constructor(value, speed) {
        this.value = +value;
        this.speed = +speed;
    }

    toHz() {
        return new Hz(this.value / 60, this.speed);
    }

    toCpm() {
        return this;
    }

    toOrders() {
        if (isNaN(this.speed) || this.speed === 0) {
            throw new Error("Running speed not set");
        }
        const ordersValue = this.value / this.speed;
        return new Orders(ordersValue, this.speed);
    }

    numberFormat(precision, {isInThousands = false} = {}) {
        const value = Helper.numberFormat(this.value, precision);
        return isInThousands ? value / 1000 : value;
    }

    format(precision) {
        return `<b>${this.numberFormat(precision)}</b> <b>${FREQUENCY_TYPES.CPM}</b>`;
    }
}

class Orders {
    constructor(value, speed) {
        this.value = +value;
        this.speed = +speed;
    }

    toHz() {
        if (isNaN(this.speed) || this.speed === 0) {
            throw new Error("Running speed not set");
        }
        return new Hz((this.value * this.speed) / 60, this.speed);
    }

    toCpm() {
        if (isNaN(this.speed) || this.speed === 0) {
            throw new Error("Running speed not set");
        }
        return new Cpm(this.value * this.speed, this.speed);
    }

    toOrders() {
        return this;
    }

    numberFormat(precision = 2, {isInThousands = false, withX = false, rounded = false} = {}) {
        let convertedValue = Helper.numberFormat(this.value, precision);

        if (isInThousands) {
            convertedValue *= 1000;
        }

        return (rounded ? Math.round(convertedValue) : convertedValue) + (withX ? "x" : "");
    }

    format(precision, options) {
        return `<b>${this.numberFormat(precision, options)}</b> <b>${FREQUENCY_TYPES.ORDERS}</b>`;
    }
}
