import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiAlertCondition from "../../../api/alertCondition";
import { ALERT_CONDITIONS_API } from "./constants";

export const useAlertConditionDeleteMutation = (chartType) => {
    const queryClient = useQueryClient();

    return useMutation((data) => ApiAlertCondition.delete(data), {
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [ALERT_CONDITIONS_API.LIST, chartType] });
        },
    });
};
