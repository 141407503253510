export const SUPER_ADMIN_PERMISSION = "superAdmin";
export const MANAGE_WIFI_GROUP_PERMISSION = "manageWiFiGroup";
export const MY_DASHBOARD_ACCESS_PERMISSION = "myDashboardAccess";
export const DASHBOARD_ACCESS_PERMISSION = "dashboardAccess";
export const MANAGE_FULL_SERVICE_EQUIPMENTS_PERMISSION = "manageFullServiceEquipments";
export const MANAGE_SELF_SERVICE_EQUIPMENT_PERMISSION = "editEquipment";
export const SHOW_FULL_SERVICE_EQUIPMENTS_PERMISSION = "showFullServiceEquipments";
export const SHOW_SERVICE_NOTIFICATIONS_PERMISSION = "showServiceNotifications";
export const EDIT_ALARMS_PERMISSION = "editAlarms";
export const SHOW_ALARMS_PERMISSION = "showAlarms";
export const MANAGE_SUBSCRIPTIONS_PERMISSION = "manageSubscriptions";

export const WW2_FRONTEND_GROUP = "ww2-frontend";
export const WW2_DASHBOARD_GROUP = "ww2-dashboard";
export const GLOBAL_GROUP = "global";

export const DASHBOARD_ACCESS_BLOCK = "dashboard_access";
