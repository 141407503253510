import React from "react";
import {ValidationError} from "../../../../shared";
import Select from "react-select";
import {customStyles} from "../../../../helpers/select";
import PropTypes from "prop-types";

const GeneralInfo = ({data, users, validation, updateData}) => {
    return (
        <React.Fragment>
            <span className="form-title-group">General Information</span>
            <div className="row">
                <div className="col-4">
                    <div className="form-group">
                        <label className="form-label">Group Name <span className="color-danger">*</span></label>
                        <input
                            className={"form-control" + (validation.name ? " is-invalid" : "")}
                            type="text"
                            placeholder="Enter Group Name"
                            value={data.name || ""}
                            onChange={ev => updateData("name", ev.target.value)}
                        />
                        <ValidationError message={validation.name}/>
                    </div>
                </div>
                <div className="col-8">
                    <div className="form-group">
                        <label className="form-label">Group Participants <span className="color-danger">*</span></label>
                        <Select
                            className={"form-control-select" + (validation.participants ? " is-invalid" : "")}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            styles={customStyles}
                            value={data.participants || []}
                            options={[
                                {value: "*", label: "All Users"},
                                ...((users || []).map(user =>
                                    ({value: user.id, label: user.full_name, accessNames: "(" + user.accessNames + ")"})
                                ))
                            ]}
                            formatOptionLabel={({label, accessNames}, {context}) => (
                                <div style={{display: "flex"}}>
                                    <div>{label}</div>
                                    {context === "menu" &&
                                        <div style={{marginLeft: "10px", color: "#ccc"}}>
                                            {accessNames}
                                        </div>
                                    }
                                </div>
                            )}
                            onChange={(value, ev) => {
                                if (ev.action === "select-option") {
                                    if ((ev.option || {}).value === "*" || value.length === Object.keys(users || []).length)
                                        value = [{value: "*", label: "All Users"}];
                                    else value = value.filter(item => item.value !== "*");
                                }
                                updateData("participants", value);
                            }}
                        />
                        <ValidationError message={validation.participants}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

GeneralInfo.propTypes = {
    updateData: PropTypes.func,
    users: PropTypes.array,
    validation: PropTypes.object,
    data: PropTypes.object
};

export default GeneralInfo;