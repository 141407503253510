import {forEach as _forEach, get as _get, map as _map} from "lodash";
import {usePointsData} from "../../../hooks/usePointsData";
import {useNodes} from "../../../hooks/useNodes";
import {useAmbientQuery} from "../../../../../../hooks/api/ambient/useAmbientQuery";
import {useMemo} from "react";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {DATEFORMAT, MOTES_LOT_DATA} from "../../../../../../constants/constants";
import {useChartSelectedRangeStore} from "../../../../../../stores/zustand/ChartSelectedRangeStore";

export const useAmbientBuilder = (props, settings) => {
    const pointData = usePointsData(settings.overlayPoints);
    const nodes = useNodes(pointData);
    const {range, from, to} = useChartSelectedRangeStore();

    const {toggleIssetAmbient} = useChartStateStoreContext((state) => state.actions);
    const showAmbient = useChartStateStoreContext((state) => state.settings.showAmbient);

    const {data: ambientData, isSuccess} = useAmbientQuery(nodes);

    return useMemo(() => {
        if (!isSuccess) {
            return [];
        }
        if (!ambientData.length && isSuccess) {
            toggleIssetAmbient(false);
            return [];
        }
        toggleIssetAmbient(true);
        if (!showAmbient || parseInt(props.chartType) !== 1) {
            return [];
        }
        return _map(ambientData, ({installationPoint, readings}) => {
            let ambientText = "Ambient temp from node " + installationPoint.sensor.node.serial;
            if (MOTES_LOT_DATA.includes(installationPoint.sensor.node.lot_data)) {
                ambientText = "Ambient temp from repeaters";
                const routers = (installationPoint.sensor.node.gateway || {}).routers;
                if (routers) {
                    ambientText += " (";
                    _forEach(routers, (router, index) => {
                        ambientText += router.serial + (index + 1 < routers.length ? "," : ")");
                    });
                }
            }
            return {
                name: ambientText,
                data: readings,
                id: "ambientTemperature_" + installationPoint.sensor.node.node_id,
                datetimeFormat: _get(props.user, "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0]),
                units: settings.seriesList.units,
                color: "rgba(0,0,0,0.8)",
                yAxis: 0,
                precision: 2,
                dataGrouping: {
                    approximation: "high",
                    forced: true,
                },
            };
        });
    }, [isSuccess, showAmbient, from, to, range]);
};
