import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiChartMarkFft from "../../../api/chartMarkFft";
import {CHART_MARK_API} from "./constants";
import {deleteItemFromListById} from "../../../helpers/query";

export const useChartMarksDeleteMutation = (equipmentId) => {
    const queryClient = useQueryClient();

    const deleteChartMark = (deleteChartMarkFftId) => ApiChartMarkFft.delete(equipmentId, deleteChartMarkFftId);

    const mutation = useMutation(deleteChartMark, {
        onSuccess: (_, deleteChartMarkFftId) => {
            queryClient.setQueriesData([CHART_MARK_API.GET_CHART_MARK_FFT, equipmentId], (prev) => deleteItemFromListById(prev, deleteChartMarkFftId));
        },
    });
    return mutation;
};
