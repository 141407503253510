import React, {memo} from "react";
import PropTypes from "prop-types";

const EquipmentCollapseItem = ({value}) => {
    return (
        <div className="equipment-collapse">
            <div className="equipment-collapse__select-wrapper">{value.label}</div>
            <div className="equipment-collapse__controls">
                <i className="fas fa-bars"></i>
            </div>
        </div>
    );
};

EquipmentCollapseItem.propTypes = {
    value: PropTypes.object,
};

export default memo(EquipmentCollapseItem);
