import React from "react";
import PropTypes from "prop-types";
import Helper from "../../helpers/helper";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";

const NotesBlock = ({notes = [], user={}, onDelete, history}) => (
    <div className="timeline">
        {notes.length
            ? notes.map(note =>
                <Note
                    key={note.id}
                    note={note}
                    user={user}
                    onDelete={() => onDelete(note.id)}
                    onEdit={() => history.push(Helper.setHashParams({modal: "note-edit", id: note.id}))}
                />
            )
            : <i className="font-weight-bold">Notes is empty</i>
        }
    </div>
);
NotesBlock.propTypes = {
    notes: PropTypes.array,
    user: PropTypes.object,
    searchParams: PropTypes.object,
    history: PropTypes.object,
    onSubmitEdit: PropTypes.func,
    onDelete: PropTypes.func
};
const Note = ({note, user, onEdit, onDelete}) => (
    <div className="timeline-item">
        <div className="timeline-item__section">
            <div className="timeline-item__border bg-white">
                <div className="timeline-item__icon">
                    <img src="/assets/pic/icon-notes.svg" alt="" />
                </div>
            </div>
            <span className="timeline-item__datetime timeline-item__datetime--line">{Helper.dateToUserFormat(note.created_at, user)}</span>
            {user.id === note.user_id &&
            <UncontrolledDropdown className="btn-group-sm position-initial">
                <DropdownToggle
                    tag="button"
                    className="btn btn-icon btn-sm btn-icon-md btn-circle right-dropdown-btn-notes"
                >
                    <i className="fa fa-ellipsis-h"/>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-right">
                    <DropdownItem onClick={onEdit}><i className="fa fa-edit"/> Edit</DropdownItem>
                    <DropdownItem onClick={onDelete}><i className="fa fa-trash"/> Delete</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            }
        </div>
        <span className="timeline-item__text" dangerouslySetInnerHTML={{__html: note.text}}/>
        {!!note.user_name.length &&
            <div className="timeline-item__info">
                {note.user_name}
            </div>
        }
    </div>
);
Note.propTypes = {
    note: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};

export default NotesBlock;