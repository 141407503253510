import React, {Component} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../stores/GlobalStore";
import {Loader} from "../../shared";
import SearchInput from "../shared/search-input";
import NameplateParameterApi from "../../api/nameplateParameter";
import NameplateTemplateApi from "../../api/nameplateTemplate";
import NameplateDataHandler from "../forms/nameplate/nameplate-data-handler";
import CreateNameplateForm from "../forms/nameplate/create";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import ParametersModal from "../../modals/nameplates/parameters/modal";
import Toast from "../shared/toast";
import { HeaderSimple } from "../../shared/header";

class NameplateAdd extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            title: "Add Nameplate",
            breadcrumbs: [
                {name: "Nameplates", link: "/nameplates"},
                {name: "Add Nameplate"}
            ],
            loader: true,
            parametersList: [],
            templates: [],
            globalSearchString: "",
            manageParametersModal: false
        };
    }

    componentDidMount() {
        Promise
            .all([
                this.handleFetchParameters(),
                this.fetchTemplates()
            ])
            .then(() => this.setState({loader: false}));
    }

    handleFetchParameters = () => {
        return NameplateParameterApi
            .list()
            .then((response) => {
                if (response) {
                    const {list = []} = response;
                    this.setState({
                        parametersList: list
                    });
                }
            });
    }

    fetchTemplates = () => {
        return NameplateTemplateApi
            .list()
            .then(response => {
                if (response) {
                    const {list} = response;
                    const templates = list || [];
                    this.setState({templates});
                }
            });
    }

    onSuccessSave = () => {
        const {history} = this.props;

        Toast.success("The nameplate has been created.");
        history.push("/nameplates");
    }

    onShowParametersModal = () => {
        this.setState({manageParametersModal: true});
    }

    onCloseParametersModal = () => {
        this.setState({manageParametersModal: false});
    }

    render() {
        const {
            breadcrumbs,
            loader,
            globalSearchString,
            title,
            templates,
            parametersList,
            manageParametersModal
        } = this.state;

        return (
            <React.Fragment>
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />

                <div className="subheader">
                    <div className="subheader-title">{title}</div>
                </div>
                {loader
                    ?
                    <div className="loader-fullscreen"><Loader/></div>
                    :
                    <NameplateDataHandler
                        onSuccessSave={this.onSuccessSave}
                        templates={templates}
                    >
                        {(
                            data,
                            formErrors,
                            inProgress,
                            addParameter,
                            deleteParameter,
                            onChange,
                            onSave
                        ) =>
                            <div>
                                <div className="block">
                                    <div className="block-body nameplate-form-wrapper">
                                        <CreateNameplateForm
                                            data={data}
                                            formErrors={formErrors}
                                            addParameter={addParameter}
                                            deleteParameter={deleteParameter}
                                            onChange={onChange}
                                            onSave={onSave}
                                            templates={templates}
                                            parametersList={parametersList}
                                            updateParametersList={this.handleFetchParameters}
                                        />
                                    </div>
                                </div>
                                <div className="text-right">
                                    <Link to="/nameplates" className="btn btn-sm btn-secondary" disabled={inProgress}>Cancel</Link>
                                    <Button color="primary" size="sm" className="ml-2" onClick={onSave} disabled={inProgress}>Save</Button>
                                </div>
                            </div>
                        }
                    </NameplateDataHandler>
                }
                {manageParametersModal &&
                    <ParametersModal
                        showModal={manageParametersModal}
                        parameters={parametersList}
                        updateParameterList={this.handleFetchParameters}
                        updateList={() => {}}
                        onClose={this.onCloseParametersModal}
                    />
                }
            </React.Fragment>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item ml-2">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

NameplateAdd.propTypes = {
    title: PropTypes.string,
    auth: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object
};

export default withGlobalStore(NameplateAdd);