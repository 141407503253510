import React, {Component} from "react";
import PropTypes from "prop-types";
import {AI_COLOR} from "../../../../../constants/constants";
import {
    get as _get,
    includes as _includes
} from "lodash";
import {withGlobalStore} from "../../../../../stores/GlobalStore";

class ViewHeader extends Component
{
    render() {
        const {actionItem, info, onSnooze, onClose, auth} = this.props;
        const urgency = _get(info, "action_list_urgencies."+_get(actionItem, "urgency")+".name");

        const state = +_get(actionItem, "state");
        const isArchive = _includes([5, 9], state);
        const isTroubleshootingHold = +_get(actionItem, "is_troubleshooting_hold") === 1;

        const showSnoozeBtn = !_includes([3, 4, 5, 6], +actionItem.state) && !isArchive && !isTroubleshootingHold;
        const statusName = _get(info, "action_list_states."+_get(actionItem, "state"));

        return (
            <React.Fragment>
                <div className={"action-item-head action-item-line"}>
                    <div className={"d-flex justify-content-between align-items-start"}>
                        <div className={"ai-title"}>{_get(actionItem, "title")}</div>

                        <div className={"actions actions-right"}>
                            {!!(showSnoozeBtn && auth.userCan("editEquipment")) &&
                                <button
                                    onClick={onSnooze}
                                    title="Snooze"
                                    className="btn btn-sm snooze-button"
                                >
                                    <img src="/assets/pic/bell.svg" alt="bell"/>
                                </button>
                            }
                            <div
                                onClick={onClose}
                                className={"close btn btn-sm"}
                            >
                                <img src="/assets/pic/close.svg" alt="close"/>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div
                            className="badge badge-pill"
                            style={{background: _get(AI_COLOR, urgency), color: "#fff"}}
                        >
                            {urgency}
                        </div>
                        <div
                            className="badge badge-pill ml-2 archive-urgency"
                            style={{borderColor: "grey", color: "grey"}}
                        >
                            {statusName}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ViewHeader.propTypes = {
    actionItem: PropTypes.object,
    info: PropTypes.object,
    onSnooze: PropTypes.func,
    onClose: PropTypes.func,
    auth: PropTypes.object
};

export default withGlobalStore(ViewHeader);